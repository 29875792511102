import { Tabs as MUITabs, Tab as MUITab } from '@mui/material';

const DetailsTabs = ({ children, className = "", onChange, value }) => {
  return (
    <MUITabs value={value} onChange={(_, newValue) => {
      onChange && onChange(newValue);
    }}
      className={className}
      style={{ borderBottom: 'none', overflow: 'auto', height: '38px', minHeight: '38px' }}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ style: { display: 'none' } }}
    >
      {Array.isArray(children) && children.map((child, index) =>
        <MUITab variant="details" label={child} key={index}
          disabled={child.props && child.props.disabled}
        />
      )}
      {!Array.isArray(children) && !!children &&
        <MUITab variant="details" label={children} key={0}
          disabled={children.props && children.props.disabled}
        />
      }
    </MUITabs>
  );
};

export default DetailsTabs;
