import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import {UserFilters, useUsers} from "~/Data/Users";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "~/Hooks";

export const UserCombobox = <T,>(
    props: {
      excludeUsers?: number[];
      filters?: UserFilters;
    } & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 200);

  const users = useUsers({
    onlyInactive: false,
    search: searchDebounce,
    ...props.filters
  });

  const userIds = useMemo(
      () =>
          users.data
              ?.flat()
              .map((user) => user.id)
              .filter((id) => !(props.excludeUsers || []).includes(id)) || [],
      [users.data, props.excludeUsers],
  );

  const [userLabels, setUserLabels] = useState<Record<string, string>>({});

  useEffect(() => {
    let labels = {};
    users.data
        ?.flat()
        .forEach((user) => (labels[user.id] = `${user.name} ${user.surname} (${user.email})`));
    setUserLabels((old) => ({ ...old, ...labels }));
  }, [userIds]);

  const getLabel = (id: number) => userLabels[id];

  const onSearchChange = (val: string) => {
    setSearch(val);
    users.setSize(1);
  };

  const onScrollToBottom = () => {
    users.setSize(users.size + 1);
  };

  return (
      <FormCombobox
          {...props}
          data={userIds}
          getLabel={getLabel}
          onSearchChange={onSearchChange}
          onScrollToBottom={onScrollToBottom}
          loading={users.isLoading}
          listLoading={!users.isLoading && users.isValidating}
          data-cy = {"formComboBox"}
      />
  );
};
