import "./EmptyState.scss";

export const EmptyState = ({ title, description, image }) => {
  return (
    <div className={'EmptyStateContainer'}>
      <div className={'EmptyStateCenterContainer'}>
        {image}
        <>
          <div className={'text-lg mt-xl'}> {title} </div>
          <div className={'EmptyStateDescription mt-md'}> {description} </div>
        </>
      </div>
    </div>
  );
};
