import { FC } from "react";
import API, {
  CompanySetCodesRequest,
  CompanyType,
  CompanyUsersView,
} from "~/API";
import { Button, ConfirmDialog, FormInput, Input } from "~/Components/UI";
import { useFormData, useMutate } from "~/Hooks";
import CompanyView from "~/Components/Views/CompanyView";
import Toast from "~/Components/Toast";
import { useLocation } from "~/Data/Locations";
import * as z from "zod";
import { zodMsg } from "~/Utils";

const validation = z.object({
  code: z.string().min(1, zodMsg("error.required", ["Code"])),
  locationsCodes: z
    .record(z.string())
    .refine((d) => !Object.keys(d).find((l) => d[l].length == 0), {
      message: "",
      path: ["locationCodes"],
    }),
});

export const CompanySetCodesButton: FC<{
  company: CompanyUsersView;
  mutateCompany: () => Promise<any>;
}> = (props) => {
  const form = useFormData<CompanySetCodesRequest>(
    {
      code: "",
      locationsCodes: props.company.locations.reduce((map, id) => {
        map[id] = "";
        return map;
      }, {}),
    },
    {
      validation,
    },
  );

  const isCustomer = props.company.type === CompanyType.BUYER;

  const save = useMutate(async () => {
    if (form.isValid()) {
      try {
        const company = await API.setCompanyAndLocationsCodes(
          { id: props.company.id },
          form.data,
        );
        Toast.Success(
          `Synchronized ${isCustomer ? "Customer" : "Vendor"} "${company.legalName}" including ${isCustomer ? "Ship-Tos" : "Ship-Froms"}`,
        );
        await props.mutateCompany();
      } catch (error) {
        Toast.ApiError(error);
        throw error;
      }
    } else {
      throw new Error("Form is not valid");
    }
  });

  return (
    <ConfirmDialog
      title={
        isCustomer
          ? "title.connect.customer.w.msr"
          : "title.connect.vendor.w.msr"
      }
      trigger={<Button label="title.connect.company.w.msr" />}
      onConfirm={save.mutate}
      confirmText="title.connect.company"
    >
      <div className="h-max-8xl overflow-auto">
        <table className="table-border-collapse">
          <tbody>
            <tr>
              <td className="py-xs px-sm text-bold">
                {isCustomer ? "Customer" : "Vendor"}
              </td>
              <td className="py-xs px-sm w-max-9xl">
                <CompanyView id={props.company.id} />
              </td>
              <td className="py-xs px-sm">
                <FormInput
                  id="code"
                  formData={form}
                  placeholder={
                    isCustomer ? "title.customer.code" : "title.vendor.code"
                  }
                />
              </td>
            </tr>

            {props.company.locations.map((locationId) => (
              <tr key={locationId}>
                <td className="py-xs px-sm text-bold">
                  {isCustomer ? "Ship-To" : "Ship-From"}
                </td>
                <td className="py-xs px-sm w-max-9xl">
                  <LocationNameAndAddress id={locationId} />
                </td>
                <td className="py-xs px-sm">
                  <Input
                    id="locationCode"
                    placeholder={
                      isCustomer ? "title.ship.to.code" : "title.ship.from.code"
                    }
                    value={form.data?.locationsCodes?.[locationId] || ""}
                    onChange={(e) =>
                      form.setData({
                        ...form.data,
                        locationsCodes: {
                          ...form.data.locationsCodes,
                          [locationId]: e.target.value,
                        },
                      })
                    }
                    error={
                      form.errors?.locationsCodes?._errors &&
                      form.data?.locationsCodes?.[locationId].length == 0
                        ? "Code is required"
                        : undefined
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ConfirmDialog>
  );
};

const LocationNameAndAddress = ({ id }) => {
  const location = useLocation(id);

  if (location.isLoading || location.data == undefined) return null;

  if (!location.data.addressStreet) return <>{location.data.name}</>;

  return (
    <>
      {location.data.name}, {location.data.addressStreet}
    </>
  );
};
