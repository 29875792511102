import {FC} from "react";
import { Card } from "~/Components/UI";
import {cn} from "~/Utils";

export const CatalogueSpecificationCard: FC<{ attrib: Record<string, string>}> = ({ attrib }) => {
  return (
      <Card className="mt-md text-left">
        <div className="pb-md text-xl no-wrap">Specifications</div>

        <div className="grid grid-auto-fit-9xl">
          <SpecificationRow
            label="Item Weight"
            description={attrib.ItemWeight && `${attrib.ItemWeight} lbs`}
          />
          <SpecificationRow
            label="Width x Length"
            description={
              (attrib.ActualWidth ? `${attrib.ActualWidth} in` : '-')
              + ' x '
              + (attrib.ActualLength ? `${attrib.ActualLength} in` : '-')
            }
          />
          <SpecificationRow
            label="Thickness"
            description={attrib.ActualThickness ? `${attrib.ActualThickness} in` : "-"}
          />
          <SpecificationRow
            label="Color/Finish"
            description={attrib.ColorAndFinish}
          />
          <SpecificationRow
            label="Material"
            description={attrib.Material}
          />
          <SpecificationRow
            label="Wood Species"
            description={attrib.WoodSpecies}
          />
        </div>
      </Card>
  );
}

const SpecificationRow: FC<{
  label: string;
  description: string;
}> = (props) => (
  <div className="grid grid-2">
    <div
      className={cn("py-sm px-md text-bold no-wrap text-right bg-black-11-5")}
    >
      {props.label}
    </div>
    <div className={cn("py-sm px-md rounded-r-xxs")}>{props.description || "-"}</div>
  </div>
);