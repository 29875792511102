import { FC } from "react";
import { cn } from "~/Utils";
import {usePublicUser} from "~/Data/Users";
import {Show} from "~/Components/UI";
import {useAttachmentThumbnail} from "~/Data/Attachments";
import {UserAccountPublicView, UserAccountView} from "~/API";

export const UserAvatar: FC<{
  id: number;
  className?: string;
}> = (props) => {
  const user = usePublicUser(props.id);

  return (
    <UserAvatarDirect
      user={user.data}
      className={props.className}
    />
  );
}

export const UserAvatarDirect: FC<{
  user?: UserAccountView | UserAccountPublicView
  className?: string;
}> = (props) => {
  const name = props.user?.name || '';
  const surname = props.user?.surname || '';
  const initials = name?.charAt(0) + surname?.charAt(0);
  const color = randomColor(name + surname);

  return (
    <Show
      when={props.user?.photoAttachmentId !== null && props.user?.photoAttachmentId !== undefined}
      fallback={<UserAvatarText label={initials} className={cn(color, props.className)} />}
    >
      <UserAvatarImage attachmentId={props.user?.photoAttachmentId} className={props.className} />
    </Show>
  );
}

const AvatarColors = [
  "bg-red-0",
  "bg-blue-0",
  "bg-blue-1",
  "bg-blue-3",
  "bg-green-0",
  "bg-green-1",
  "bg-red-1",
  "bg-yellow-1",
  "bg-orange-0",
  "bg-orange-1",
  "bg-pink-1",
  "bg-purple-4",
  "bg-black-4",
]

const randomColor = (hash: string) => {
  let hashVal = 0;
  for (let i = 0; i < hash.length; i++) {
    hashVal = hash.charCodeAt(i) * 17351 + ((hashVal << 5) - hashVal);
  }
  return AvatarColors[Math.abs(hashVal) % AvatarColors.length];
}

export const UserAvatarText: FC<{
  label: string;
  className?: string;
}> = (props) => (
  <div
    className={cn(
      "w-xl h-xl text-semibold upper-case flex-row align-center justify-center color-black-12 rounded-full",
      props.className,
    )}
  >
    {props.label}
  </div>
);

export const UserAvatarImage: FC<{ attachmentId: number, className: string }> = (props) => {
  const thumbnail = useAttachmentThumbnail(props.attachmentId);
  if (thumbnail.isLoading)
    return <></>

  return (
    <img src={thumbnail.data} className={cn("w-xl h-xl rounded-full object-contain", props.className)}  alt="profile picture"/>
  );
};
