import { FC, ReactNode } from "react";
import {
  LocalizedMessage,
  LocalizedMessageKey,
} from "~/Locales";
import { Dialog } from "./Dialog";
import { Button } from "~/Components/UI";
import { useMutate, useToggle } from "~/Hooks";

export const DeleteDialog: FC<{
  onDelete: () => Promise<any>;
  deleteText?: LocalizedMessageKey;
  title: LocalizedMessageKey;
  trigger: ReactNode;
  children: ReactNode;
}> = (props) => {
  const deleteCall = useMutate(props.onDelete);
  const open = useToggle(false);

  const onDeleteClicked = () => deleteCall.mutate().then(open.toggleFalse);

  return (
    <Dialog
      open={open}
      trigger={props.trigger}
      title={<LocalizedMessage id={props.title} />}
    >
      <div className="desktop-w-min-9xl flex-col">
        {props.children}

        <div className="flex-row justify-end gap-sm mt-2xl">
          <Button
            label={props.deleteText || "title.delete"}
            onClicked={onDeleteClicked}
            loading={deleteCall.loading}
            color="red"
            dataCy="DialogConfirmButton"
          />
          <Button
            label="title.cancel"
            onClicked={open.toggleFalse}
            disabled={deleteCall.loading}
            color="gray"
            dataCy="DialogCancelButton"
          />
        </div>
      </div>
    </Dialog>
  );
};
