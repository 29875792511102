import { useEffect, useRef, useState } from "react";
import {
  AudioTrackPublication,
  LocalVideoTrack,
  Participant as TwilioParticipant,
  RemoteVideoTrack,
  VideoTrackPublication
} from "twilio-video";
import { VideoCallUserPhoto } from "./UserPhoto";
import { classNames } from "react-ui-basics/Tools";
import "./LocalParticipant.scss";
import { VClog } from "./VideoCallHelper";


type LocalParticipantProps = {
  userId: number;
  participant?: TwilioParticipant;
  isLocalCameraOn?: boolean;
  isScreenSharingOn?: boolean;
}

export const LocalParticipant = ({
  userId,
  participant,
  isLocalCameraOn,
  isScreenSharingOn
}: LocalParticipantProps) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | RemoteVideoTrack>();

  const videoRef = useRef();
  const audioRef = useRef();

  const updateTracks = (participant: TwilioParticipant) => {
    if (participant) {
      setVideoTracks(trackPubsToTracks(participant.videoTracks));
      setAudioTracks(trackPubsToTracks(participant.audioTracks));
    }
  }

  useEffect(() => {

    if (participant) {
      updateTracks(participant);
      // maybe update tracks here?
      participant.on("trackDisabled", (track) => {
        VClog("### [-VideoCall-] trackDisabled, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackEnabled", (track) => {
        VClog("### [-VideoCall-] trackEnabled, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackSubscribed", (track) => {
        VClog("### [-VideoCall-] trackSubscribed, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackUnsubscribed", (track) => {
        VClog("### [-VideoCall-] trackUnsubscribed, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackPublished", (track) => {
        VClog("### [-VideoCall-] trackPublished, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackUnpublished", (track) => {
        VClog("### [-VideoCall-] trackUnpublished, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackStarted", (track) => {
        VClog("### [-VideoCall-] trackStarted, participant = ", participant.identity)
        updateTracks(participant)
      });
      participant.on("trackStopped", (track) => {
        VClog("### [-VideoCall-] trackStopped, participant = ", participant.identity)
        updateTracks(participant)
      });
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant?.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    // Screen sharing has priority over user's camera
    let videoTrack: LocalVideoTrack | RemoteVideoTrack = videoTracks.find((videoTrack) => videoTrack.name === 'screen') || videoTracks[0];
    setVideoTrack(videoTrack);

  }, [videoTracks]);

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      // maybe update tracks here?
      // videoTrack.on('disabled', () => trackDisabled(videoTrack));
      // videoTrack.on('enabled', () => trackEnabled(videoTrack));

      return () => {
        videoTrack.detach()
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  if (!participant) {
    return (
      <div className="LocalParticipant">
        <div className={'VideoCallUserImage'}>
          <VideoCallUserPhoto userId={userId}/>
        </div>
      </div>
    )
  }

  const shouldDisplayUserPhoto = videoTrack ? (!isLocalCameraOn && !isScreenSharingOn) : true;

  const VideoCallContainerClassNames = classNames("VideoCallContainer",
    (videoTrack?.name === 'screen') ? "" : "Camera",
    shouldDisplayUserPhoto ? "Hidden" : ""
  );

  return (
    <div className="LocalParticipant">
      <div className="UserName">You</div>
      {shouldDisplayUserPhoto &&
        <div className={'VideoCallUserImageContainer'}><VideoCallUserPhoto userId={userId} /></div>}

      {videoTrack && <video className={VideoCallContainerClassNames}
        ref={videoRef} autoPlay={true} />}
      <audio ref={audioRef} autoPlay={true} muted={true} />
      {/* mute local audio (otherwise we'll hear ourselves)*/}
    </div>
  );
};


export const trackPubsToTracks = (trackMap: Map<string, VideoTrackPublication | AudioTrackPublication>) => {
  return Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null)
}
