import { FC, useEffect, useMemo, useState } from "react";
import { Button, Checkbox } from "~/Components/UI";
import {ToggleProps, useMutate} from "~/Hooks";
import API, { CompanyType } from "~/API";
import LocationView from "~/Components/Locations/LocationView";
import { LinearLoader } from "~/Components/Loaders";
import { useAppSelector } from "~/StoreTypes";
import Toast from "~/Components/Toast";
import {useCurrentUser} from "~/Reducers/User";
import {useCompany} from "~/Data/Companies/Companies";
import {trackEvent} from "~/Services/Tracking";

export const CatalogueExternalAddForm: FC<{
  showDialog: ToggleProps,
  clearSelection: () => void,
  totalCount: number,
}> = (props) => {
  const currentUser = useCurrentUser();
  const userCompany = useCompany(currentUser.companyId);
  const selectedCategories = useAppSelector(
    (state) => state.cataloguePage.groups,
  );
  const isCustomer = userCompany.data?.type == CompanyType.BUYER;
  const [selectedShipTos, setSelectedShipTos] = useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    if (isCustomer) {
      setSelectedShipTos(
        currentUser.locations.reduce(
          (p, c) => ({ ...p, [c]: false }),
          {},
        ),
      );
    } else {
      setSelectedShipTos({});
    }
  }, [currentUser, isCustomer]);

  const selectAllValue = useMemo(() => {
    if (Object.keys(selectedShipTos).every((k) => selectedShipTos[k])) {
      return true;
    }
    if (Object.keys(selectedShipTos).every((k) => !selectedShipTos[k])) {
      return false;
    }
    return "indeterminate";
  }, [selectedShipTos]);

  const saveCall = useMutate(async () => {
    await API.addCatalogueProductsToCompany(
      {},
      {
        companyId: currentUser.companyId,
        categoryNames: Object.keys(selectedCategories).filter(
          (c) => selectedCategories[c].selected === true,
        ),
        productIds: Object.keys(selectedCategories)
          .filter((c) => selectedCategories[c].selected === "indeterminate")
          .map((c) =>
            Object.keys(selectedCategories[c].products)
              .filter((p) => selectedCategories[c].products[p])
              .flat(),
          )
          .flat()
          .map(p => +p),
        locationIds: isCustomer
          ? Object.keys(selectedShipTos)
              .filter((k) => selectedShipTos[k])
              .map((k) => +k)
          : [null],
      },
    );
    Toast.Success("Successfully Added Products!");
    props.showDialog.toggleFalse();
    props.clearSelection();
    trackEvent("Added My Products From Catalogue");
  });

  const selectAll = (value: boolean) => {
    setSelectedShipTos(
      Object.keys(selectedShipTos).reduce((p, c) => ({ ...p, [c]: value }), {}),
    );
  };

  const selectLocation = (id: number, value: boolean) => {
    setSelectedShipTos({
      ...selectedShipTos,
      [id]: value,
    });
  };

  return (
    <div className="desktop-w-9xl flex-col gap-md">
      {userCompany.data != undefined && isCustomer && (
        <>
          {currentUser.locations.length > 1 && (
            <Checkbox
              value={selectAllValue}
              id="all"
              onChange={selectAll}
              label="title.select.all"
            />
          )}
          <div className="flex-col gap-xxs h-max-6xl overflow-auto">
            {currentUser.locations.map((l) => (
              <Checkbox
                value={selectedShipTos[l]}
                id={`${l}`}
                onChange={(val) => selectLocation(l, val)}
              >
                <LocationView showCode id={l} />
              </Checkbox>
            ))}
          </div>
        </>
      )}

      {!isCustomer && (
        <div className="text-semibold">Add {props.totalCount} products to my products?</div>
      )}

      <LinearLoader loading={userCompany.isLoading} />
      {saveCall.error && <div className="color-red-1">Something went wrong!</div>}
      <Button
        onClicked={saveCall.mutate}
        loading={saveCall.loading}
        disabled={userCompany.data == undefined || isCustomer && selectAllValue == false}
        label="title.add.to.my.products"
      />
    </div>
  );
};