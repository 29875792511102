import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {LC, ls} from "~/Locales";
import { offersRootPath } from "~/Services/Routes";
import DashboardEmptyState from "../DashboardEmptyState";
import { OfferView } from "~/API";
import PriceView from "~/Components/Views/PriceView";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import { useOfferValue } from "~/Reducers/Offers";
import { IncotermName } from "~/Components/Old/IncotermSelect";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import { OfferStatusBadge } from "~/Components/Offers/OfferStatusBadge";
import {DashboardMobileField, DashboardTable} from "~/Pages/Dashboard/Components/DashboardTable";
import {LinearLoader} from "~/Components/Loaders";
import {Button, Card, Show} from "~/Components/UI";
import {useDashboardOffers} from "~/Data/Offers";
import {useIsInternalUser, useIsUFPAM, useIsUFPBuyer, useIsVendor} from "~/Reducers/User";
import {useIsMobile} from "~/Hooks";

const columnHelper = createColumnHelper<OfferView>();
const columns = [
  columnHelper.accessor("id", {
    header: ls("title.o.id"),
    cell: (data) => <div className="text-semibold">O-{data.getValue()}</div>,
  }),
  columnHelper.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <OfferStatusBadge status={data.getValue()} />,
  }),
  columnHelper.accessor("buyerName", {
    header: ls("title.buyer"),
    cell: (data) => <div className="text-truncate">
      {data.getValue()}
    </div>,
  }),
  columnHelper.accessor("incoterm", {
    id: "incoterm",
    header: ls("title.incoterm"),
    cell: (data) => <div className="text-truncate">
      <IncotermName incoterm={data.getValue()} />
    </div>,
  }),
  columnHelper.display({
    id: "value",
    header: ls("title.value"),
    cell: (data) => {
      const value = useOfferValue(data.row.original);
      return (
        <PriceView
          price={value}
          currency={data.row.original.currency}
          decimals={0}
        />
      );
    },
  }),
  columnHelper.accessor("dateIncoDestination", {
    header: ls("title.expected.delivery"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
  columnHelper.accessor("validityDate", {
    header: ls("title.validity.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
];

const DashboardVendorOffers: FC = () => {
  const history = useHistory();
  const isAm = useIsUFPAM();
  const isBuyer = useIsUFPBuyer();
  const isVendor = useIsVendor();
  const isInternalUser = useIsInternalUser();
  const offers = useDashboardOffers(isAm, isBuyer, isVendor, isInternalUser);

  const showMobileTable = useIsMobile();

  const onShowAll = () => {
    history.push(offersRootPath);
  };

  const onRowClick = (row: OfferView) => {
    history.push(offersRootPath + row.id);
  }

  const table = useReactTable({
    data: offers.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const showEmptyState = offers.data?.length === 0 && !offers.isLoading;
  const showList = !offers.isLoading && !showEmptyState;
  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC id="title.my.offers" />
      </div>

      <LinearLoader loading={offers.isLoading} />
      <Show when={showEmptyState}>
        <DashboardEmptyState
          title="No Offers."
          description1="Once you have an offer, it will be visible here."
        />
      </Show>

      {showList && (
        <>
          {!showMobileTable && (
            <DashboardTable table={table} onRowClick={onRowClick} />
          )}
          {showMobileTable && (
            <div className={"orders-table-mobile"}>
              {offers.data?.map((offer) => (
                <OfferCellMobile key={offer.id} offer={offer} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-sm"
            onClicked={onShowAll}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardVendorOffers;

const OfferCellMobile = ({ offer }: { offer: OfferView }) => {
  const history = useHistory();

  const {
    id,
    status,
    currency,
    validityDate,
  } = offer;

  const value = useOfferValue(offer);

  const onOpen = useCallback(() => {
    history.push(offersRootPath + id);
  }, [id, history]);

  return (
    <div className="border-b-1 border-black-10 py-md flex-col gap-sm" onClick={onOpen}>
      <div className="flex-row w-full">
        <div className="text-truncate flex-col flex mt-xxs mr-xxs">
          O-{id}
        </div>

        <DashboardMobileField label="title.value">
            <PriceView price={value} currency={currency} decimals={0} />
        </DashboardMobileField>

        <OfferStatusBadge status={status} />
      </div>

      <DashboardMobileField label="title.delivery.date">
        <DateTimeView date={validityDate} />
      </DashboardMobileField>
    </div>
  );
};
