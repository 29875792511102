import {Button} from "~/Components/UI";
import { useHistory } from "react-router-dom";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";

const ErrorPage: FC<{ eventId: string }> = ({ eventId }) => {
  usePageTitle("Error");
  const history = useHistory();

  const onRefresh = () => history.go(0);

  return (
    <div className="m-2xl text-left">
      <div className="text-3xl">Something Went Wrong!</div>
      <div className="text-lg color-text-2 mt-sm">
        Our team has been automatically alerted of the error. Please refresh the
        page and try again.
      </div>
      <div className="mt-sm color-text-2">Event ID: {eventId}</div>
      <Button className="mt-md" onClicked={onRefresh} label="title.refresh" />
    </div>
  );
};

export default ErrorPage;
