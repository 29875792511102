import API, { Length } from "~/API";
import { useEffect, useState } from 'react';
import { composedItems } from './Utils/Compose';
import {AppDispatch, RootState, useAppDispatch, useAppSelector} from "~/StoreTypes";

export const ACTIONS_GET_LENGTHS_START = 'ACTIONS_GET_LENGTHS_START';
export const ACTIONS_GET_LENGTHS_SUCCESS = 'ACTIONS_GET_LENGTHS_SUCCESS';
export const ACTIONS_GET_LENGTHS_ERROR = 'ACTIONS_GET_LENGTHS_ERROR';
export const ACTIONS_UPDATE_LENGTH_SUCCESS = 'ACTIONS_UPDATE_LENGTH_SUCCESS';
export const ACTIONS_CREATE_LENGTH_SUCCESS = 'ACTIONS_CREATE_LENGTH_SUCCESS';

const lengthsSelector = (state: RootState) => state.lengths;

const useLengths = (): LengthsState => useAppSelector(lengthsSelector);

const useLengthKeys = () => {
  const [keys, setKeys] = useState([]);
  const lengths = useLengths();
  useEffect(() => {
    setKeys(Object.keys(lengths).map(lengthId => Number(lengthId)));
  }, [lengths]);
  return keys;
};

export const useLengthLabels = () => {
  const [labels, setLabels] = useState({});
  const lengths = useLengths();
  const lengthKeys = useLengthKeys();
  useEffect(() => {
    setLabels(lengthKeys.reduce(
      (result, option) => {
        const length = lengths[option];
        if (length) {
          result[option] = length.value;
        }
        return result;
      }, {}
    ));
  }, [lengthKeys]);
  return labels;
};

export const getLengths = (filters = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_LENGTHS_START });
    const lengths = await API.getLengths(filters);
    dispatch({ type: ACTIONS_GET_LENGTHS_SUCCESS, lengths, offset: filters.offset });
    return lengths;
  }
  catch (error) {
    dispatch({ type: ACTIONS_GET_LENGTHS_ERROR, error });
  }
};

export const getLengthByProductGroup = (groupId: number, filters = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_LENGTHS_START });
    const lengths = await API.getLengthsByProductGroup({ groupId, ...filters });
    dispatch({ type: ACTIONS_GET_LENGTHS_SUCCESS, lengths });
    return lengths;
  }
  catch (error) {
    dispatch({ type: ACTIONS_GET_LENGTHS_ERROR, error });
  }
};

export const getLengthsById = (lengthIds: number[]) => async (dispatch: AppDispatch) => {
  try {
    const promises: Promise<Length>[] = lengthIds.map(lengthId => API.getLength({ id: lengthId }));
    const lengths = await Promise.all(promises);
    dispatch({ type: 'ACTIONS_GET_ARRAY_OF_LENGTHS_SUCCESS', lengths });
    return lengths;

  } catch (error) {
    return [];
  }
};

export const useLengthsByIds = (lengthIds: number[]) => {
  const [lengths, setLengths] = useState<Length[]>([]);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const fetchLengths = async () => {
      const fetchedLengths = await dispatch(getLengthsById(lengthIds));
      setLengths(fetchedLengths);  
    };
    
    lengthIds && fetchLengths();
  }, [lengthIds, dispatch]);

  return lengths;
}

export interface LengthsState {
  [id: number]: Length;
}

const INITIAL_STATE: LengthsState = {};

const reducer = (state: LengthsState, action: any): LengthsState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_LENGTHS_SUCCESS:
    case 'ACTIONS_GET_ARRAY_OF_LENGTHS_SUCCESS':
      return { ...state, ...composedItems(action.lengths) };

    case 'ACTIONS_GET_LENGTH_SUCCESS':
    case ACTIONS_CREATE_LENGTH_SUCCESS:
      return { ...state, [action.length.id]: action.length };

    case ACTIONS_UPDATE_LENGTH_SUCCESS:
      const length = action.length;
      return { ...state, [length.id]: { ...state[length.id], ...length } };
    
    default:
      return state;
  }
};

export default reducer;
