import { LC } from "~/Locales";
import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  useIsInternalCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import CompanyView from "~/Components/Views/CompanyView";
import { FC } from "react";

const OrderCompany: FC<{
  orderId: number;
  isSales?: boolean;
}> = ({ orderId, isSales }) => {
  if (!!isSales) return <SalesOrderCustomer orderId={orderId} />;
  return <PurchaseOrderVendor orderId={orderId} />;
};

export default OrderCompany;

const SalesOrderCustomer: FC<{ orderId: number }> = ({ orderId }) => {
  const order = useSalesOrder(orderId);
  const isAdminOrSales = useIsInternalUser();

  return (
    <div className="flex-row align-start justify-between py-lg mx-lg border-b-1 border-black-10">
      <div className="flex-col text-left">
        <div className="text-sm color-text-3">
          <LC id={isAdminOrSales ? "title.customer" : "title.seller"} />
        </div>
        <div className="text-lg mt-xxs">
          {isAdminOrSales ? (
            <CompanyView id={order.data?.companyId} />
          ) : (
            "UFP International, LLC"
          )}
        </div>
      </div>
    </div>
  );
};

const PurchaseOrderVendor: FC<{ orderId: number }> = ({ orderId }) => {
  const isAdminOrSales = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();

  const purchaseOrder = usePurchaseOrder(orderId, isInternalCustomer);
  const vendorName = purchaseOrder ? purchaseOrder.data?.vendorName : null;

  const canSeeVendorName = isAdminOrSales || isInternalCustomer;

  const titleId = isInternalCustomer
    ? "title.supplier"
    : isAdminOrSales
      ? "title.vendor"
      : "title.buyer";

  return (
    <div className="flex-row align-start justify-between py-lg mx-lg border-b-1 border-black-10">
      <div className="flex-col text-left">
        <div className="text-sm color-text-3">
          <LC id={titleId} />
        </div>
        <div className="text-lg mt-xxs">
          {canSeeVendorName ? vendorName : "UFP International, LLC"}
        </div>
      </div>
    </div>
  );
};
