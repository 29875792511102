import {AppDispatch, useAppSelector} from "~/StoreTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const leftMenuSlice = createSlice({
  name: "LeftMenu",
  initialState: { open: false },
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    }
  },
});

export const useLeftMenu = () => useAppSelector(state => state.leftMenu.open);

export const openLeftMenu = () => (dispatch: AppDispatch) => dispatch(leftMenuSlice.actions.setOpen(true));
export const closeLeftMenu = () => (dispatch: AppDispatch) => dispatch(leftMenuSlice.actions.setOpen(false));

export default leftMenuSlice.reducer;
