import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  joinCallWithUser,
  useUniqueVideoCallRoomName,
} from "~/Reducers/VideoCall";
import { VideoCallConfirmationDialog } from "~/Components/Old/VideoCall/VideoCallLink";
import {
  chatsRootPathExternal,
  videoCallPath,
} from "~/Services/Routes";
import {useAppDispatch} from "~/StoreTypes";
import {Button} from "~/Components/UI";
import {TbMessageCircle, TbVideo} from "react-icons/tb";
import {useChatCreateDirectWithUser, useChatsAllUnreadCount} from "~/Data/Chats";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

export const DashboardVideoButton: FC<{
  userId: number;
}> = ({ userId }) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const roomName = useUniqueVideoCallRoomName(userId);

  const getDirectChatId = useChatCreateDirectWithUser(userId);

  const onJoinCallClick = async () => {
    setIsConfirmationDialogOpen(false);
    if (userId) {
      const chatId = await getDirectChatId();
      const linkToVideoCall = `${chatsRootPathExternal}${chatId}/${videoCallPath}/${roomName}`; // await getLinkToVideoCall(userId, chatId, roomName);

      dispatch(joinCallWithUser(userId, chatId));
      history.push(linkToVideoCall);
    }
  };

  const onButtonClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  return (
    <>
      <Button
        variant="text"
        color="gray"
        leftIcon={<TbVideo size={16} />}
        onClicked={onButtonClick}
        label="title.video"
      />
      {isConfirmationDialogOpen && (
        <VideoCallConfirmationDialog
          userId={userId}
          onConfirm={onJoinCallClick}
          onClose={() => setIsConfirmationDialogOpen(false)}
        />
      )}
    </>
  );
};

export const DashboardChatButton: FC<{
  userId: number;
}> = ({ userId }) => {
  const history = useHistory();
  const allChatsUnread = useChatsAllUnreadCount();

  const getDirectChatId = useChatCreateDirectWithUser(userId);

  const onButtonClick = async () => {
    if (userId) {
      const chatId = await getDirectChatId();
      history.push(`${chatsRootPathExternal}${chatId}`);
    }
  };

  return (
    <Button
      variant="text"
      color="gray"
      leftIcon={<TbMessageCircle size={16} />}
      onClicked={onButtonClick}
      label="title.chats"
      rightIcon={<ChatUnreadBadge unread={allChatsUnread.data?.unreadCount || 0} />}
    />
  );
};
