import { FC } from "react";
import { usePageTitle } from "~/Hooks";
import {LC} from "~/Locales";
import {Case, Match} from "~/Components/UI";

const EndpointErrorPage: FC<{
  status: number;
  item: string;
}> = (props) => {
  usePageTitle("Error");

  return (
    <div className="m-2xl text-left">
      <div className="text-3xl">Something Went Wrong!</div>
      <div className="text-lg color-text-2 mt-sm">
        <Match on={props.status}>
          <Case v={404}>
            <LC id="error.not.found">
              {props.item}
            </LC>
          </Case>
          <Case v={403}>
            <LC id="error.permission">
              {props.item}
            </LC>
          </Case>
          <Case v={500}>
            <LC id="error.internal">
              {props.item}
            </LC>
          </Case>
          <Case default>
            There was an error getting the page data.
          </Case>
        </Match>
      </div>
    </div>
  );
};

export default EndpointErrorPage;
