import {OfferProductView, ProductV2UOM, QuoteView} from "~/API";
import React, { memo, useCallback, Fragment, useMemo } from "react";
import { useParams } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import { getContainers } from "~/Utils/UnitConversion";
import { formattedNumber } from '~/Utils/PriceCalculation';
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { useIncotermUsesAddress } from "~/Reducers/Incoterms";
import { useLengthsByIds } from "~/Reducers/Lengths";
import { ProductType, ProductTypeLabel, ProductTypeCode, productTypesArray } from "~/Reducers/Products";
import { useProductPublic } from "~/Reducers/ProductsPublic";
import { useIsNew } from "../../Components/OffersHelper";
import { useOrderedQuotesForOffer, useProductQuantityInOrderedQuotes } from "./Actions";
import Button from "~/Components/Old/Button";
import CompanyView from "~/Components/Views/CompanyView";
import { DeleteIcon, ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import { IncotermName } from "~/Components/Old/IncotermSelect";
import LengthSelect from "~/Components/Old/LengthSelect";
import LocationName from "~/Components/Old/LocationName";
import PortView from "~/Components/Ports/PortView";
import TextField from "~/Components/Old/TextField";
import { UOMSelect } from "~/Components/Old/UOMSelect";
import UserName from "~/Components/Old/UserName";
import { calculateOfferProductsTotalValue } from "../../Components/OffersHelper";
import { LengthsView } from "~/Components/Old/LengthsView";
import { classNames } from "react-ui-basics/Tools";
import './OfferDetailsSummarySectionTable.scss';
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";


const READ_COL_COUNT = 11;
const EDIT_COL_COUNT = 12;

const OfferDetailsSummarySectionTable = (props) => {
  const { readOnly, currency, products } = props;

  const currencySymbol = useFindCurrencySymbol(currency);

  if (!products.length) return (
    <div>There are no Products in this offer yet. Add your first Product now!</div>
  );

  const totalValue = calculateOfferProductsTotalValue(products);
  const totalValueFormatted = formattedNumber(totalValue);

  const columnsClassName = readOnly
    ? 'OfferDetailsSummarySectionTableReadOnly'
    : 'OfferDetailsSummarySectionTableEditable';

  return (
    <table className={classNames('basic-table OfferDetailsSummarySectionTable', columnsClassName)}>
      {productTypesArray.map((productType, index) => {
        const filteredProducts = products.filter((product) => product.productType === productType);
        return filteredProducts.length > 0 &&
          <Fragment key={index}>
            <thead>
              <tr>
                <td className={'text-xl'} colSpan={readOnly ? READ_COL_COUNT : EDIT_COL_COUNT}>
                  <LocalizedMessage id={'title.offer.summary'} /> -
                  <span className={'text-lg text-left color-blue-1'}> {ProductTypeLabel[productType]}</span>
                </td>
              </tr>
            </thead>
            <OfferProductsTable {...props} products={filteredProducts} productType={productType} />
          </Fragment>;
      })}

      <tbody>
        <tr className={'text-xl upper-case border-none'}>
          <td /><td /><td /><td /><td /><td /><td /><td /><td />
          <td><LocalizedMessage id={'title.total'} /></td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </table>
  );
};

export default OfferDetailsSummarySectionTable;


const OfferProductsTable = (props) => {
  const { products, onProductFieldChange, onRemoveProduct, currency, productType = null, readOnly } = props;
  const { id: offerId } = useParams<{id: string}>();
  const isNew = useIsNew();

  const orderedQuotes = useOrderedQuotesForOffer(isNew ? null : Number(offerId));

  const sortedProducts = products.sort((a, b) => a.id - b.id);
  const currencySymbol = useFindCurrencySymbol(currency);

  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const filteredProducts = productType ? sortedProducts.filter(p => p.productType === productType) : sortedProducts;

  const totalValue = calculateOfferProductsTotalValue(filteredProducts);
  const totalValueFormatted = formattedNumber(totalValue);

  return (
    <>
      <thead>
        <tr>
          {readOnly && <th />}
          <th><LocalizedMessage id={'title.code'} /></th>
          <th><LocalizedMessage id={'title.description'} /></th>
          {!readOnly && <th><LocalizedMessage id={'title.type'} /></th>}
          <th>{showLength && <LocalizedMessage id={'title.length'} />}</th>
          <th><LocalizedMessage id={'title.uom'} /></th>
          <th><LocalizedMessage id={'title.containers'} /></th>
          <th><LocalizedMessage id={'title.quantity'} /></th>
          <th><LocalizedMessage id={'title.sold.quantity'} /></th>
          <th />
          <th><LocalizedMessage id={'title.price'} /></th>
          <th className={'text-right'}><LocalizedMessage id={'title.subtotal'} /></th>
          {!readOnly && <th><LocalizedMessage id={'title.actions'} /></th>}
        </tr>
      </thead>

      <tbody>
        {filteredProducts.map((product, index) =>
          <OfferProductRow key={index} {...{ product, currency, onProductFieldChange, onRemoveProduct, readOnly, orderedQuotes }} />
        )}

        <tr className={'text-lg border-none'}>
          <td /><td /><td /><td /><td /><td /><td /><td /><td />
          <td><LocalizedMessage id={'title.subtotal'} /></td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </>
  );
};

interface OfferProductRowProps {
  product: OfferProductView;
  currency: string;
  onProductFieldChange: (productId, event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveProduct: (productId) => void;
  readOnly: boolean;
  orderedQuotes: QuoteView[];
}

const OfferProductRow = memo<OfferProductRowProps>((props) => {
  const { product, currency, onProductFieldChange, onRemoveProduct, readOnly = false, orderedQuotes } = props;
  const [expanded, toggleExpanded] = useToggle();

  const soldQuantity = useProductQuantityInOrderedQuotes(orderedQuotes, product.productCode, product.uom);
  const currencySymbol = useFindCurrencySymbol(currency);

  const {
    id = '',
    productCode = '',
    productActLength = '',
    quantity = 1,
    uom = '',
    price = 0,
    lengthIds = [],
    productGroupId = '',
    productType = '',
  } = product;

  const productTypeCode = ProductTypeCode[productType];

  const onFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onProductFieldChange(id, event);
  }, [id, onProductFieldChange]);

  const onRemoveClick = useCallback(() => {
    onRemoveProduct(id);
  }, [id, onRemoveProduct]);

  const subtotal = quantity * price;

  const subtotalFormatted = formattedNumber(subtotal);
  const priceRounded = price && Number(price).toFixed(2);

  const isUomReadOnly = productType === ProductType.LINEAR_FOOTAGE || productType === ProductType.SPECIAL_PRODUCTS;

  const isPanels = productType === ProductType.PANELS;
  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;
  const showLengthForPanels = isPanels && showLength;
  const showLengthForOthers = !isPanels && showLength;
  const lengths = useLengthsByIds(lengthIds);
  const productData = useProductPublic(product?.productId);
  const isLumber = productType === ProductType.LUMBER;
  const uomIsLf = uom == ProductV2UOM.LF;
  const limitLengths = uomIsLf && isLumber;

  return (
    <>
      <tr className={'OfferSummaryProductRow'}>
        {readOnly && <td onClick={soldQuantity ? toggleExpanded : null} className={'ArrowCell'}>
          {soldQuantity
            ? <>
              {expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}
            </>
            : null
          }
        </td>}
        <td> {productCode} </td>
        <td>
          <ProductCatalogueDescriptionLink
            description={product.productDescription}
            productCode={product.productCode}
            hasSyndigo={product.hasSyndigo}
            productId={product.productId}
          />
        </td>
        {!readOnly && <td> {productTypeCode}</td>}
        {readOnly && <td>
          {showLength
            ? <LengthsView lengthIds={lengthIds} />
            : '-'
          }
        </td>}
        {!readOnly &&
          <td>
            {showLengthForPanels &&
              <>
                {readOnly
                  ? <div>{productActLength}</div>
                  : <TextField className={'ValueField'} value={productActLength} disabled={true} />
                }
              </>
            }
            {showLengthForOthers &&
              <>
                {readOnly
                  ? <LengthsView lengthIds={lengthIds} />
                  : <LengthSelect id={'lengthIds'}
                    value={lengthIds}
                    multiple={!limitLengths}
                    className={'ValueField'}
                    byGroup={productGroupId}
                    onChange={onFieldChange}
                    inlineSelected
                  />
                }
              </>
            }
            {!showLength && '-'}
          </td>
        }
        <td>
          {readOnly
            ? <div className={'flex-row align-center AlignItemsCenter'}> {uom} </div>
            : <UOMSelect id="uom"
              className={'ValueField'}
              value={uom}
              productType={productType}
              onChange={onFieldChange}
              disabled={isUomReadOnly}
              required={true}
            />
          }
        </td>
        <td>{getContainers(productData, lengths?.[0], quantity, uom as ProductV2UOM).toFixed(2)}</td>
        <td>
          {readOnly
            ? <div className={'flex-row align-center AlignItemsCenter'}> {quantity} </div>
            : <TextField id="quantity"
              type={'number'} hideArrows float
              className={'ValueField MidsizeField'}
              value={quantity}
              onChange={onFieldChange}
              min={0} max={99999999}
            />}
        </td>
        <td>{soldQuantity}</td>
        <td className={'text-right'}>
          <div className={'flex-row align-center justify-end'}>
            {readOnly
              ? <div className={'flex-row align-center text-right color-orange-1 mr-xxs text-bold'}> {priceRounded} </div>
              : <TextField id="price"
                type={'number'} hideArrows float
                className={'ValueField BigField mr-xxs'}
                inputStyle={{ 'textAlign': 'right' }}
                value={price}
                onChange={onFieldChange}
              />}
          </div>
        </td>
        <td>{currency}/{uom}</td>
        <td className={'text-right color-orange-1 no-wrap'}>
          <b>{currencySymbol}{subtotalFormatted}</b>
        </td>

        {!readOnly && <td>
          <div className={'flex-row align-center'}>
            <Button view={'text'}
              className={'ActionButton DeleteButtonText'}
              onClick={onRemoveClick}>
              <DeleteIcon size={16} />
            </Button>
          </div>
        </td>}
      </tr>

      {readOnly && expanded &&
        <ProductSubRows {...{ orderedQuotes, productCode }} />
      }
    </>
  );
});

const ProductSubRows = ({ orderedQuotes, productCode }) => {
  const quotes = useMemo(() => orderedQuotes.filter(quote =>
    quote.products?.some(product => product.productCode === productCode)
  ), [orderedQuotes, productCode]);

  return (
    <tr>
      <td colSpan={11} className={'SubStringCell'}>
        <table className={'OfferDetailsProductSubTable'}>
          <tbody>
            <tr>
              <th><LocalizedMessage id={'title.q.id'} /></th>
              <th><LocalizedMessage id={'title.customer'} /></th>
              <th><LocalizedMessage id={'title.ship.to'} /></th>
              <th><LocalizedMessage id={'title.ufp.am'} /></th>
              <th><LocalizedMessage id={'title.length'} /></th>
              <th><LocalizedMessage id={'title.sold.quantity'} /></th>
              <th><LocalizedMessage id={'title.uom'} /></th>
              <th><LocalizedMessage id={'title.incoterm'} /></th>
            </tr>
            {quotes.map(quote => <ProductSubRow key={quote.id} {...{ quote, productCode }} />)}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const ProductSubRow = ({ quote, productCode }) => {
  const products = useMemo(() => quote.products?.filter(product => product.productCode === productCode), [quote, productCode]);
  return (
    <>
      {products.map((product, index) => <ProductSubRowItem key={index} {...{ quote, product }} />)}
    </>
  );
};

const ProductSubRowItem = ({ quote, product }) => {
  const { id, customerCompanyId, shipToLocationId, accountManagerUserId,
    incoterm, incotermAddressDestination, incotermPortIdDestination } = quote;
  const { productType, lengthIds, quantity, uom } = product;

  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const canShowAddressField = useIncotermUsesAddress(incoterm);

  return (
    <tr>
      <td>{`Q-${id}`}</td>
      <td><CompanyView id={customerCompanyId} /></td>
      <td><LocationName id={shipToLocationId} /></td>
      <td><UserName id={accountManagerUserId} /></td>
      <td>{showLength
        ? <LengthsView {...{ lengthIds }} />
        : '-'
      }</td>
      <td>{quantity}</td>
      <td>{uom}</td>
      <td>
        <IncotermName incoterm={incoterm} />
        {canShowAddressField
          ? incotermAddressDestination ? `/${incotermAddressDestination}` : ''
          : incotermPortIdDestination ? <>/<PortView id={incotermPortIdDestination} /></> : ''}
      </td>
    </tr>
  );
};
