import {createColumnHelper} from "@tanstack/react-table";
import {LocationView} from "~/API";
import {ActiveField} from "~/Components/UI";
import { ls } from "~/Locales";
import {smartJoin} from "~/Utils";
import {PercentageView} from "~/Components/Views/PercentageView";

const columnHelper = createColumnHelper<LocationView>();

export const getLocationTableColumns = (isCustomer: boolean) => [
  columnHelper.accessor("active", {
    header: ls("title.active"),
    cell: (data) => <ActiveField active={data.getValue()} />,
    size: 0,
  }),
  columnHelper.accessor("code", {
    header: ls("title.code"),
    sortId: "code",
    minSize: 100,
  }),
  columnHelper.accessor("customerCode", {
    header: ls(isCustomer ? "title.customer" : "title.vendor"),
    sortId: "company_code",
    minSize: 100,
  }),
  columnHelper.accessor("name", {
    header: ls("title.name"),
    sortId: "name",
    minSize: 250,
    cell: ({ getValue }) => (
      <div className="text-no-wrap">
        {getValue()}
      </div>
    ),
  }),
  columnHelper.display({
    id: "address",
    header: ls("company.address"),
    minSize: 500,
    cell: ({ row: { original } }) => (
      <div className="text-no-wrap">
        {smartJoin(
          [
            original.addressStreet,
            original.addressStreet2,
            original.addressCity,
            original.addressRegion,
            original.addressZip,
            original.addressCountry,
          ],
          ", ",
        )}
      </div>
    ),
  }),
  columnHelper.accessor("portName", {
    header: ls("title.port"),
    sortId: "port_name",
    minSize: 100,
    cell: ({ getValue }) => (
      <div className="text-no-wrap">
        {getValue()}
      </div>
    ),
  }),
  columnHelper.display({
    id: "am",
    sortId: "account_manager_name",
    header: ls("title.account.manager"),
    minSize: 200,
    cell: ({ row: { original } }) => (
      <div className="text-no-wrap">
        {original.accountManagerName}
        {" "}
        {original.accountManagerSurname}
      </div>
    ),
  }),
  columnHelper.accessor("defaultMarginAsFraction", {
    header: ls("title.margin"),
    sortId: "default_margin_as_fraction",
    cell: ({ getValue }) => <PercentageView fractionalValue={getValue()} />,
    size: 0,
  }),
  columnHelper.accessor("paymentTermsDescription", {
    header: ls("title.payment.terms"),
    sortId: "payment_terms_description",
    minSize: 200,
    cell: ({ getValue }) => (
      <div className="text-no-wrap">
        {getValue()}
      </div>
    ),
  }),
];

