import {FC} from "react";
import {usePublicUser} from "~/Data/Users";


const UserEmail: FC<{ id: number }> = ({ id }) => {
  const user = usePublicUser(id);

  if (!id || !user.data) return <>-</>;

  return <>{user.data.email}</>;
};

export default UserEmail;
