import { FC } from "react";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {MyProductsTab} from "~/Pages/Products/MyProductsPage/MyProductsTab";

export const MyProductsPage: FC = () => (
    <StaticPage title="My Products">
      <PageTitle title="title.my.products" type="menu" />
      <MyProductsTab
        type="page"
      />
    </StaticPage>
  );
