import { RequestForQuoteStatus } from "~/API";
import { FC, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LocalizedMessage, ls } from "~/Locales";
import {
  offersRootPath,
  quotesRootPath,
  requestsRootPath,
} from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import {
  useIsAdmin,
  useIsCustomer,
  useIsInternalUser,
  useIsUFPAM,
  useIsUFPBuyer,
} from "~/Reducers/User";
import {
  useIsMatching,
  useIsOfferRequested,
  useIsReadOnly,
} from "./RequestsHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import {trackEvent} from "~/Services/Tracking";
import Toast from "~/Components/Toast";

const RequestBottomButtons: FC<{
  save: (status: RequestForQuoteStatus) => Promise<void>;
  status: RequestForQuoteStatus;
  disableSendOfferRequestButton: boolean;
  onBack: () => void;
  toggleShowSendOfferRequestDialog: () => void;
  onCopyRequest: () => void;
  hasChanges: boolean;
  loading: boolean;
}> = (props) => {
  const readOnly = useIsReadOnly();
  const showMatchingTable = useIsMatching();

  if (showMatchingTable) {
    return <RequestOfferButton {...props} />;
  } else if (!readOnly) {
    return <SaveButton {...props} />;
  } else {
    return <DetailsButtons {...props} />;
  }
};

export default RequestBottomButtons;

const useCurrentUserType = () => {
  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const isUFPAM = useIsUFPAM();
  const isCustomer = useIsCustomer();

  if (isAdmin) return "Admin";
  if (isUFPBuyer) return "Buyer";
  if (isUFPAM) return "AM";
  if (isCustomer) return "Customer";
  return "";
};

const DetailsButtons: FC<{
  save: (status: RequestForQuoteStatus) => Promise<void>;
  status: RequestForQuoteStatus;
  onCopyRequest: () => void;
}> = (props) => {
  const readOnlyButtons = useReadOnlyButtons(props);

  return <BottomButtonsPanel>{readOnlyButtons}</BottomButtonsPanel>;
};

const useReadOnlyButtons = (props: {
  save: (status: RequestForQuoteStatus) => Promise<void>;
  status: RequestForQuoteStatus;
  onCopyRequest: () => void;
}) => {
  const userType = useCurrentUserType();

  return useMemo(
    () =>
      [
        {
          // copy request
          statuses: {
            [RequestForQuoteStatus.DRAFT_AM]: ["Admin", "AM"],
            [RequestForQuoteStatus.DRAFT_CUSTOMER]: ["Admin", "Customer"],
            [RequestForQuoteStatus.REVIEW]: ["Admin", "AM"],
            [RequestForQuoteStatus.SENT_TO_BUYER]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFER_REQUESTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFERED]: ["Admin", "AM"],
            [RequestForQuoteStatus.QUOTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.ORDERED]: ["Admin", "AM"],
            [RequestForQuoteStatus.ARCHIVED]: ["Admin", "AM"],
          },
          render: <CopyRequestButton key="copy" {...props} />,
        },
        {
          // Update request
          statuses: {
            [RequestForQuoteStatus.DRAFT_AM]: ["Admin", "AM"],
            [RequestForQuoteStatus.DRAFT_CUSTOMER]: ["Admin", "Customer"],
            [RequestForQuoteStatus.REVIEW]: ["Admin", "AM"],
            [RequestForQuoteStatus.SENT_TO_BUYER]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFER_REQUESTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFERED]: ["Admin", "AM"],
            [RequestForQuoteStatus.QUOTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.ORDERED]: ["Admin", "AM"],
          },
          render: <UpdateStatusButton key={"update"} {...props} />,
        },
        {
          // Send to customer/sales
          statuses: {
            [RequestForQuoteStatus.DRAFT_AM]: ["Admin", "AM"],
            [RequestForQuoteStatus.DRAFT_CUSTOMER]: ["Admin", "Customer"],
          },
          render: <SendToCustomerOrSalesButton key={"send"} {...props} />,
        },
        {
          // Create new Quote
          statuses: {
            [RequestForQuoteStatus.REVIEW]: ["Admin", "AM"],
            [RequestForQuoteStatus.SENT_TO_BUYER]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFER_REQUESTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.OFFERED]: ["Admin", "AM"],
            [RequestForQuoteStatus.QUOTED]: ["Admin", "AM"],
            [RequestForQuoteStatus.ORDERED]: ["Admin", "AM"],
          },
          render: <CreateQuoteButton key={"createQuote"} />,
        },
        {
          // Forward to Buyer
          statuses: {
            [RequestForQuoteStatus.REVIEW]: ["Admin", "AM"],
          },
          render: <ForwardToBuyerButton key={"forward"} {...props} />,
        },
        {
          // Create Offer
          statuses: {
            [RequestForQuoteStatus.SENT_TO_BUYER]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.OFFER_REQUESTED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.OFFERED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.QUOTED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.ORDERED]: ["Admin", "Buyer"],
          },
          render: <CreateOfferButton key={"createOffer"} />,
        },
        {
          // Find Vendors
          statuses: {
            [RequestForQuoteStatus.SENT_TO_BUYER]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.OFFER_REQUESTED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.OFFERED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.QUOTED]: ["Admin", "Buyer"],
            [RequestForQuoteStatus.ORDERED]: ["Admin", "Buyer"],
          },
          render: <FindVendorsButton key={"findVendors"} />,
        },
      ]
        .filter((it) => it.statuses[props.status]?.includes(userType))
        .map((it) => it.render),
    [props, userType],
  );
};

// Buttons

const CopyRequestButton: FC<{
  onCopyRequest: () => void;
}> = (props) => {
  const onClick = () => {
    props.onCopyRequest();
    trackEvent("Request for Quote Copied");
  }

  return (
    <Button view={"secondary"} onClick={onClick}>
      <LocalizedMessage id={"title.copy.request"} />
    </Button>
  );
}

const UpdateStatusButton: FC = () => {
  const history = useHistory();
  const { id: requestId } = useParams<{ id: string }>();

  const onEdit = useCallback(() => {
    history.replace(`${requestsRootPath}${requestId}/edit`);
  }, [history, requestId]);

  return (
    <Button view={"secondary"} onClick={onEdit}>
      <LocalizedMessage id={"title.update.request"} />
    </Button>
  );
};

const SendToCustomerOrSalesButton: FC<{
  save: (status: RequestForQuoteStatus) => Promise<void>;
}> = ({ save }) => {
  const isInternalUser = useIsInternalUser();
  const [showSendToSalesRequestDialog, toggleShowSendToSalesRequestDialog] =
    useToggle();

  const onSend = useCallback(async () => {
    if (isInternalUser) {
      await save(RequestForQuoteStatus.REVIEW);
      Toast.Success(ls("title.request.id.sent.to.customer.successfully"));
      trackEvent("Request for Quote sent to Customer");
    } else {
      toggleShowSendToSalesRequestDialog();
    }
  }, [save, isInternalUser, toggleShowSendToSalesRequestDialog]);

  const onConfirmSendToSalesRequest = useCallback(async () => {
    try {
      await save(RequestForQuoteStatus.REVIEW);
      Toast.Success(ls("title.request.id.sent.to.sales.successfully"));
      toggleShowSendToSalesRequestDialog();
      trackEvent("Request for Quote sent to Sales");
    } catch {}
  }, [save, toggleShowSendToSalesRequestDialog]);

  return (
    <>
      <Button onClick={onSend} data-cy={"SendToCustomerOrSalesButton"}>
        <LocalizedMessage
          id={isInternalUser ? "title.send.to.customer" : "title.send.to.sales"}
        />
      </Button>

      {showSendToSalesRequestDialog && (
        <ConfirmationDialog
          title={<LocalizedMessage id={"title.send.request.to.sales"} />}
          description={
            <LocalizedMessage id={"phrase.send.request.to.sales.1"} />
          }
          description2={""}
          confirmTitle={<LocalizedMessage id={"title.yes"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onConfirm={onConfirmSendToSalesRequest}
          onClose={toggleShowSendToSalesRequestDialog}
        />
      )}
    </>
  );
};

const CreateQuoteButton: FC = () => {
  const history = useHistory();
  const { id: requestId } = useParams<{ id: string }>();

  const onCreateNewQuote = useCallback(() => {
    history.push(`${quotesRootPath}new?requestId=${requestId}`);
    trackEvent("Quote Created for Request for Quote");
  }, [history, requestId]);

  return (
    <Button onClick={onCreateNewQuote} data-cy={"CreateQuoteButton"}>
      <LocalizedMessage id={"title.create.quote"} />
    </Button>
  );
};

const ForwardToBuyerButton: FC<{
  save: (status: RequestForQuoteStatus) => Promise<void>;
}> = ({ save }) => {
  const onForwardToBuyer = useCallback(async () => {
    await save(RequestForQuoteStatus.SENT_TO_BUYER);
    Toast.Success(ls("title.request.id.forwarded.to.buyer"));
    trackEvent("Request for Quote Forwarded to Buyer");
  }, [save]);

  return (
    <Button onClick={onForwardToBuyer} data-cy={"ForwardToBuyerButton"}>
      <LocalizedMessage id={"title.forward.request.to.buyer"} />
    </Button>
  );
};

const CreateOfferButton: FC = () => {
  const history = useHistory();
  const { id: requestId } = useParams<{ id: string }>();

  const onOfferCreate = useCallback(() => {
    history.push(`${offersRootPath}new?requestId=${requestId}`);
    trackEvent("Offer Created from Request for Quote");
  }, [history, requestId]);

  return (
    <Button onClick={onOfferCreate} data-cy={"CreateOfferButton"}>
      <LocalizedMessage id={"title.create.offer"} />
    </Button>
  );
};

const FindVendorsButton: FC = () => {
  const history = useHistory();
  const { id: requestId } = useParams<{ id: string }>();

  const onShowMatchingTable = useCallback(() => {
    history.push(`${requestsRootPath}${requestId}/matching`);
  }, [history, requestId]);

  return (
    <Button onClick={onShowMatchingTable}>
      <LocalizedMessage id={"title.find.vendors"} />
    </Button>
  );
};

const RequestOfferButton: FC<{
  disableSendOfferRequestButton: boolean;
  onBack: () => void;
  toggleShowSendOfferRequestDialog: () => void;
}> = ({
  disableSendOfferRequestButton,
  onBack,
  toggleShowSendOfferRequestDialog,
}) => {
  return (
    <BottomButtonsPanel>
      <Button
        disabled={disableSendOfferRequestButton}
        onClick={toggleShowSendOfferRequestDialog}
      >
        <LocalizedMessage id={"title.request.offer"} />
      </Button>
      <Button view={"secondary"} onClick={onBack}>
        <LocalizedMessage id={"title.cancel"} />
      </Button>
    </BottomButtonsPanel>
  );
};

const SaveButton: FC<{
  onBack: () => void;
  save: (status: RequestForQuoteStatus) => Promise<void>;
  status: RequestForQuoteStatus;
  hasChanges: boolean;
  loading: boolean;
}> = ({ onBack, save, status, hasChanges, loading }) => {
  const isOfferRequested = useIsOfferRequested(status);
  // const newStatus = isOfferRequested ? RequestForQuoteStatus.OFFER_REQUESTED : status;

  const showSendToBuyer =
    status === RequestForQuoteStatus.SENT_TO_BUYER || isOfferRequested;
  // const messageId = showSendToBuyer ? 'title.request.id.sent.to.buyer.successfully' : 'title.request.id.saved.successfully';
  const buttonTitleId = showSendToBuyer
    ? "title.save.and.resend.to.buyer"
    : "title.save";

  const onSave = useCallback(async () => {
    await save(status);
    trackEvent("request for Quote Updated");
  }, [save, status]);

  return (
    <BottomButtonsPanel>
      <Button
        view={"primary"}
        onClick={onSave}
        disabled={!hasChanges || !!loading}
        data-cy={"SaveRequestButton"}
      >
        <LocalizedMessage id={buttonTitleId} />
      </Button>
      <Button view={"secondary"} onClick={onBack}>
        <LocalizedMessage id={"title.cancel"} />
      </Button>
    </BottomButtonsPanel>
  );
};
