const scacToSslMap: Map<string, string> = new Map([
    ['AGYL01', 'AGILITY LOGISTICS CORPORATION'],
    ['ABBZ01', 'Alabama State Docks Dept'],
    ['AXBK01', 'ALEXANDER & BLAKE'],
    ['AQTG01', 'ALLOG TRANSPORTES INTERNACIONAL'],
    ['AYQG01', 'ALLY GLOBAL LOGISTICS LLC'],
    ['AHPN01', 'AM SHIPPING INC'],
    ['APRL01', 'AMERICAN PRESIDENT LINE LTD'],
    ['APLU', 'AMERICAN PRESIDENT LINE LTD'],
    ['ALGO01', 'AMTRANS LOGISTICS'],
    ['ATOH01', 'ANTIOCH COMMERCIAL LTD'],
    ['ASWR01', 'ASF LOGISTICS INC'],
    ['ASGO01', 'ASIA SHIPPING TRASNPORTES'],
    ['BMLI01', 'B&M LOGISTICA INTERNATIONAL'],
    ['BGIWO1', 'BGI WORLDWIDE LOGISTICS INC'],
    ['BUEI01', 'BLUE LINE AGENCIAMENTO DE CARG'],
    ['BORDL1', 'BORDERLINE LOGISTICS INC'],
    ['BRILL1', 'BRILLIANT GROUP LOGISTICS CORP'],
    ['CRCD01', 'CARRIER CREDIT SERVICES'],
    ['CETRP1', 'CENTAURI TRANSPORT'],
    ['EGLA01', 'CEVA FREIGHT LLC'],
    ['CGRLW1', 'CG RAILWAY LLC'],
    ['CGYU01', 'CHANGYUAN GLOBAL CROSS-BORDER'],
    ['CMGM01', 'CMA CGM'],
    ['CMDU', 'CMA CGM'],
    ['CMTIS1', 'COMMONWEALTH INDEPENDENT STATE'],
    ['CPXG01', 'CONCEPTS IN FREIGHT INC'],
    ['CSNQ01', 'COSCO Shipping Line'],
    ['COSU', 'COSCO Shipping Line'],
    ['CRSML1', 'CROSSMOTION LOGISTICS SA DE CV'],
    ['CWYL01', 'Crowley Liner Services'],
    ['CAMN', 'Crowley Liner Services'],
    ['AIEP01', 'DHL GLOBAL FORWARDING'],
    ['DYLON1', 'DYLO INC'],
    ['EASYS1', 'EASY SHIPPING GLOBAL LOGISTICA'],
    ['ESAB01', 'ESA-BEL TRADE LLC'],
    ['EVGN01', 'Evergreen Line'],
    ['EGLV', 'Evergreen Line'],
    ['FDXR01', 'FEDEX TRADE NETWORKS TRANSPORT'],
    ['FRSY01', 'FR MEYERS SOHN NORTH AMERERICA'],
    ['GBCM01', 'GLOBAL CEMENT PRODUCTS INC'],
    ['GBRV01', 'GLOBAL ROVER LOGISTICS SRL'],
    ['HBSU01', 'HAMBURG SUD'],
    ['SUDU', 'HAMBURG SUD'],
    ['HAPG01', 'Hapag-Lloyd'],
    ['HLCU', 'Hapag-Lloyd'],
    ['PDIT01', 'HOST TERMINALS INC'],
    ['HTGP01', 'HYDE SHIPPING'],
    ['HYBU', 'HYDE SHIPPING'],
    ['ISMA01', 'ISLAND MARINE AGENCY'],
    ['KSLA01', 'KESTREL LINER AGENCIES LLP'],
    ['KGOV01', 'KING OCEAN'],
    ['KOSL', 'KING OCEAN'],
    ['KRONU1', 'KRONUS SIA'],
    ['KCCN01', 'KUEHNE + NAGEL INC'],
    ['KHNN', 'KUEHNE + NAGEL INC'],
    ['LAUF01', 'LAUFER GROUP INTERNATIONAL LTD'],
    ['LUSA01', 'LEMAN USA INC'],
    ['LQAP01', 'LINEA PENINSULAR'],
    ['MBOR01', 'M BORDER SOULTIONS INC'],
    ['MCKV01', 'Maersk Line'],
    ['MAEU', 'Maersk Line'],
    ['MQTG01', 'MARITIME LOGISTICS DEL PERU SA'],
    ['MESI01', 'Mediterranean Shipping Company (MSC)'],
    ['MEDU', 'Mediterranean Shipping Company (MSC)'],
    ['MXHN01', 'MOTHERLINES INC'],
    ['ONWX01', 'Ocean Network Express (ONE)'],
    ['ONEY', 'Ocean Network Express (ONE)'],
    ['ORIEN1', 'Orient Overseas Container Line (OOCL)'],
    ['OOLU', 'Orient Overseas Container Line (OOCL)'],
    ['OSCB01', 'OSLO CARIBBEAN CARRIERS LLC'],
    ['PHWH01', 'PASHA HAWAII HOLDING LLC'],
    ['PEGAS1', 'PEGASUS MARITIME INC'],
    ['PORTL1', 'PORTLINK LOGISTICA MULTIMODAL'],
    ['PROLO1', 'PROLOG INTERNATIONAL SHIPPING'],
    ['PROSH1', 'PROSHIPPING GROUP CORP NY'],
    ['RYXS01', 'ROYAL CARGO DO BRASIL'],
    ['SFGI01', 'SAFE LOGISTICS'],
    ['SNKA01', 'SCHENKER OF CANADA LIMITED'],
    ['SBMN01', 'SEABOARD MARINE'],
    ['SMLU', 'SEABOARD MARINE'],
    ['GGSP01', 'SEACOR LINES'],
    ['SILG', 'SEACOR LINES'],
    ['MCKV01', 'SeaLand'],
    ['SEAU', 'SeaLand'],
    ['SMXIN1', 'SMX INTERNATIONAL LTDA'],
    ['TSQY01', 'THE SCOULAR COMPANY'],
    ['TDLN01', 'TRADELANES INC'],
    ['TSCW', 'TROPICAL SHIPPING	TZSH01'],
    ['YMLU', 'YANG MING'],
    ['ZIMI01', 'ZIM Integrated Shipping'],
    ['ZIMU', 'ZIM Integrated Shipping']
]);


export function resolveScac(scac: string): string {
    const ssl = scacToSslMap.get(scac);
    if (ssl) {
        return ssl;
    } else {
        return scac;
    }
}