import {ChatMessage, ChatMessageType} from '~/API';
import {FC, Fragment, useEffect, useMemo} from 'react';
import {
  customRequestsSellerRootPath,
  offersRootPath,
  ordersPathPurchase,
  ordersPathSales,
  ordersRootPath,
  productsBuyerRootPath,
  productsCMSRootPath,
  productsSellerRootPath,
  requestsBuyerRootPath,
  requestsCMSRootPath,
  requestsSellerRootPath,
} from '~/Services/Routes';
import {extractUserName, getUserPublic, useUsers} from '~/Reducers/Users';
import {localizedString} from '~/Locales';
import {useAppDispatch} from "~/StoreTypes";
import {useCurrentUser, useIsInternalCustomer} from "~/Reducers/User";
import {cn} from "~/Utils";
import {ChatMessageVideoCall} from "~/Components/Chats/ChatMessageVideoCall";
import {useSalesOrder} from "~/Data/Orders/SalesOrders";
import {usePurchaseOrder} from "~/Data/Orders/PurchaseOrders";
import {For} from "~/Components/UI";

const URL_REGEX = /(https?:\/\/)[-a-zA-Z0-9À-ž@:%._+~#=;]{1,256}\.[a-z0-9à-ž]{2,6}\b([-a-zA-Z0-9À-ž@:%_+.~#?&/=;]*[-a-zA-Z0-9À-ž@:%_+~#?&/=;])*/g;

export const ChatMessageParsed: FC<{
  message: ChatMessage;
  linkClasses: string;
  textOnly?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const { text = '', mentionedUserIds = [] } = props.message || undefined;
  const currentUser = useCurrentUser();

  const isMyMessage = currentUser.id === props.message.userAccountId;
  
  useEffect(() => {
    mentionedUserIds.forEach((userId: number) => {
      dispatch(getUserPublic(userId));
    });
  }, []);

  const { parts, links, noLinks } = useMemo(() => {
    let find: RegExpExecArray | null;
    let prevIndex = 0;

    const parts = [];
    const links = [];
    while ((find = URL_REGEX.exec(text)) !== null) {
      let link = find[0];
      links.push(link);

      if (prevIndex !== find.index || prevIndex === 0) {
        parts.push(text.substring(prevIndex, find.index));
      }
      prevIndex = find.index + find[0].length;
    }

    parts.push(text.substring(prevIndex));

    return { parts, links, noLinks: prevIndex === 0 };
  }, [props.message]);

  if (props.message?.type === ChatMessageType.CALL_INVITATION) {
    return <ChatMessageVideoCall message={props.message} isMyMessage={isMyMessage} hideButtons={props.textOnly} />
  }

  if (props.message?.type === ChatMessageType.OFFER_FEEDBACK) {
    const feedback = JSON.parse(props.message?.text);

    if(props.textOnly)
      return !!feedback?.value ? "I'm interested in this offer." : "I'm not interested in this offer."

    return <div>
    <span className="text-bold">{`${!!feedback?.value ? "I'm interested in this offer." : "I'm not interested in this offer."}`}</span>
      <div>{feedback.message}</div>
    </div>
  }

  if (props.message?.type === ChatMessageType.QUOTE_FEEDBACK) {
    const feedback = JSON.parse(props.message?.text);

    if(props.textOnly)
      return !!feedback?.value ? "I'm interested in this quote." : "I'm not interested in this quote."

    return (
      <div>
        <span className="text-bold">{`${!!feedback?.value ? "I'm interested in this quote." : "I'm not interested in this quote."}`}</span>
        <div>{feedback.message}</div>
      </div>
    );
  }

  if (props.message?.type === ChatMessageType.FIELD_CHANGE) {
    const changes = JSON.parse(props.message?.text);

    if(props.textOnly)
      return changes.title

    return <div>
      <span>{changes.title}</span>
      <ul>
        <For each={changes.data}>
          {(change: any, i) => (
            <li key={i}>
                <span className="text-bold">{change.property}:</span>
                <s className="opacity-70 mx-xxs">{change.oldValue}</s>
                <span>{change.newValue}</span>
            </li>
          )}
        </For>
      </ul>
    </div>
  }

  if (noLinks || props.textOnly) return <TextWithMentions text={text} mentionedUserIds={mentionedUserIds} linkClassName={''} />;

  return (
    <>
      {parts.map((part, index) => {
        const linkText = links.length > index ? createLinkText(links[index]) : '';
        return (
          <Fragment key={index}>
            <TextWithMentions text={part} mentionedUserIds={mentionedUserIds} linkClassName={props.linkClasses} />
            {(links.length > index) && <a href={links[index]} target="_blank" rel="noreferrer noopener">{linkText}</a>}
          </Fragment>
        );
      })}
    </>
  )
};

const TextWithMentions: FC<{
  text: string;
  mentionedUserIds: number[];
  linkClassName?: string
}> = ({ text = '', mentionedUserIds = [], linkClassName }) => {
  const currentUser = useCurrentUser();
  const users = useUsers();
  const userNames = useMemo(() => {
    return mentionedUserIds.reduce((acc, userId) => {
      const username = extractUserName(userId, users);
      if (!!username) acc[userId] = username;
      return acc;
    }, {});
  }, [users, mentionedUserIds]);

  const MATCH_REGEX = useMemo(() => {
    let regexString = '';
    mentionedUserIds.forEach((userId, index) => {
      if (index !== 0) regexString += '|';
      regexString += `@${userId}`;
    });
    if (!regexString) return null;
    return new RegExp(regexString.replaceAll('[', '\\['), 'g');
  }, [mentionedUserIds]);

  const { parts, mentions, noMentions } = useMemo(() => {
    if (!MATCH_REGEX) return { parts: [text], mentions: [], noMentions: true };

    let find: RegExpExecArray | null;
    let prevIndex = 0;
    const parts = [];
    const mentions = [];
    while ((find = MATCH_REGEX.exec(text)) !== null) {
      const mention = find[0];
      const mentionedId = mention.slice(1);
      mentions.push(<span className={cn(currentUser.id === +mentionedId && linkClassName, "text-bold")}>{`@${userNames[mentionedId]}`}</span>);

      if (prevIndex !== find.index || prevIndex === 0) {
        parts.push(text.substring(prevIndex, find.index));
      }
      prevIndex = find.index + find[0].length;
    }
    parts.push(text.substring(prevIndex));
    return { parts, mentions, noMentions: prevIndex === 0 };
  }, [text, userNames, MATCH_REGEX]);

  if (noMentions) return <>{text}</>;

  return (
    <span>
      {parts.map((part, index) => {
        return (
          <span key={index}>
            {part}
            {(mentions.length > index) && mentions[index]}
          </span>
        );
      })}
    </span>
  );
};

const createLinkText = (link: string) => {
  const parts = link.split('/');
  if (parts.length > 1) {
    const id = parts[parts.length - 1];
    if (!!id?.length) {
      // Products
      if (link.includes(productsBuyerRootPath) || link.includes(productsSellerRootPath) || link.includes(productsCMSRootPath)) {
        return `${localizedString('title.product')} ${localizedString('title.ID')} - ${id}`;
      }

      // Requests
      if (link.includes(customRequestsSellerRootPath) || link.includes(requestsBuyerRootPath)
        || link.includes(requestsSellerRootPath) || link.includes(requestsCMSRootPath)) {
        return `${localizedString('title.request')} ${localizedString('title.ID')} - ${id}`;
      }

      // Deliveries
      if (link.includes(ordersRootPath + ordersPathSales)) return <SalesOrderLinkText deliveryId={id} />;
      if (link.includes(ordersRootPath + ordersPathPurchase)) return <PurchaseOrderLinkText orderId={id} />;

      // Offers
      if (link.includes(offersRootPath)) return <OfferLinkText offerId={id} />;
    }
  }

  // Other
  return link;
};

const SalesOrderLinkText = ({ deliveryId }) => {
  const delivery = useSalesOrder(deliveryId);
  return <>{`Sales Order ${localizedString('title.ID')} - ${delivery.data?.number || deliveryId}`}</>;
};
const PurchaseOrderLinkText = ({ orderId }) => {
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(orderId, internalCustomer);
  return <>{`Purchase Order ${localizedString('title.ID')} - ${order.data?.number || orderId}`}</>;
};
const OfferLinkText = ({ offerId }) => {
  return <>{`Offer ${localizedString('title.ID')} - ${offerId}`}</>;
};

export const extractLinksFromText = (text: string) => {
  let find: RegExpExecArray | null;
  const links = [];
  while ((find = URL_REGEX.exec(text)) !== null) {
    links.push(find[0]);
  }
  return [...new Set(links)].map(link => {
    const linkText = createLinkText(link);
    return <a href={link} target="_blank" rel="noreferrer noopener">{linkText}</a>;
  });
};
