import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { ProductType } from "~/Reducers/Products";
import { ls } from "~/Locales";

export const ProductTypeCombobox = <T,>(
  props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => (
  <FormCombobox {...props} data={productTypes} getLabel={productTypeLabel} />
);

const productTypes = Object.values(ProductType);

const productTypeLabel = (productType: ProductType) => {
  switch (productType) {
    case ProductType.ALL:
      return ls("title.all");
    case ProductType.LUMBER:
      return ls("title.lumber");
    case ProductType.PANELS:
      return ls("title.panels");
    case ProductType.LINEAR_FOOTAGE:
      return ls("title.linear.foot");
    case ProductType.SPECIAL_PRODUCTS:
      return ls("title.specialities");
    case ProductType.FINANCE:
      return ls("title.finance");
  }
};
