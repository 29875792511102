import {LocationFilters, useLocations} from "~/Data/Locations";
import API, {LocationView} from "~/API";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";

export const LocationCombobox = <T,>(
  props: {
    filters?: LocationFilters
  } & ExtendDataCombobox<T>,
) => (
  <ApiDataCombobox<T, any, LocationView>
    {...props}
    getLabel={(l) => `(${l.code}) ${l.name}`}
    getId={(l) => l.id}
    useListData={useLocations}
    getSingle={(id) => API.getLocation({ id })}
    filters={{ query: "ACTIVE == true", ...props.filters }}
  />
);
