import API, {
  OffersByStatusSummary,
  OfferStatus,
  OfferUpdate,
  OfferView,
} from "~/API";
import {
  deleteOffer,
  isRequestedOfferStatus,
  updateOffer,
  ACTIONS_CREATE_OFFER_SUCCESS,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS,
  ACTIONS_GET_REQUESTED_OFFERS_ERROR,
  ACTIONS_GET_REQUESTED_OFFERS_START,
  ACTIONS_GET_REQUESTED_OFFERS_SUCCESS,
} from "~/Reducers/Offers";
import { useSearchParams } from "~/Services/Routes";
import {
  useIsAdmin,
  useIsUFPBuyer,
} from "~/Reducers/User";
import { useMemo } from "react";
import {AppDispatch, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";
import {ls} from "~/Locales";

const EMPTY_BY_GROUP_PAGE_DATA = { offers: [], loading: false, error: null };

export const useRequestedOffersPageReduxState = () =>
  useAppSelector((state) => state.requestedOffersPage);
export const useRequestedOffersPageByGroupReduxState = (groupId: string) =>
  useAppSelector((state) => state.requestedOffersPage.offersByGroups[groupId]) ||
  EMPTY_BY_GROUP_PAGE_DATA;

export const useShouldShowArchiveButton = (status: OfferStatus) => {
  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const isArchived =
    status === OfferStatus.O_ARCHIVED || status === OfferStatus.RFO_ARCHIVED;
  return (isUFPBuyer || isAdmin) && !isArchived;
};

export const getRequestedOffersStatusesTotalInfo =
  (filters = { status: [] }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_START" });
      const status = !filters.status?.length
        ? [
            OfferStatus.RFO_DRAFT,
            OfferStatus.RFO_DRAFT_VENDOR,
            OfferStatus.RFO_SENT,
            OfferStatus.RFO_DECLINED,
            OfferStatus.RFO_ARCHIVED,
          ]
        : filters.status;
      const statuses = await API.getOffersByStatusSummary({
        ...filters,
        status,
      });
      dispatch({
        type: "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_SUCCESS",
        statuses,
      });
    } catch (error) {
      dispatch({
        type: "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_ERROR",
        error,
      });
    }
  };

export const deleteRFO =
  (offerId: number, groupId: string, onDelete: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(deleteOffer(offerId));
      dispatch({
        type: "ACTIONS_REQUESTED_OFFERS_PAGE_DELETE_OFFER",
        offerId,
        groupId,
      });
      onDelete();
      Toast.Success(ls("phrase.rfo.deleted.successfully", offerId));
    } catch {}
  };

export const archiveRFO =
  (
    offer: {
      id: number;
    } & OfferUpdate,
    groupId: string,
    onArchive: () => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(updateOffer(offer));
      dispatch({
        type: "ACTIONS_REQUESTED_OFFERS_PAGE_ARCHIVE_OFFER",
        offerId: offer.id,
        groupId,
      });
      onArchive();
      Toast.Success(ls("phrase.rfo.archived.successfully", offer.id));
    } catch {}
  };

export const clearData = () => ({
  type: "ACTIONS_REQUESTED_OFFERS_PAGE_CLEAR_DATA",
});

export enum RequestedOffersPageTableTypes {
  REQUESTED_OFFERS_PAGE_NONE = "REQUESTED_OFFERS_PAGE_NONE",
  REQUESTED_OFFERS_PAGE_STATUS = "REQUESTED_OFFERS_PAGE_STATUS",
}

export const useDefaultPageFilters = () => {
  const [searchParams] = useSearchParams();
  const withExpired =
    searchParams?.onlyArchivedOrDeliveredOrExpired?.toLowerCase() === "true";

  return useMemo(
    () => ({
      search: "",
      onlyMy: true,
      tableType: RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_NONE,
      limit: 40,
      status: [],
      offer: [],
      customer: [],
      shipTo: [],
      buyer: [],
      accountManager: [],
      product: [],
      productGroup: [],
      port: [],
      incoterm: [],
      vendor: [],
      valueFrom: "",
      valueTo: "",
      validityDateFrom: "",
      validityDateTo: "",
      withChangesOnly: false,
      onlyArchivedOrDeliveredOrExpired: false,
      withoutArchivedOrDelivered: !withExpired,
      withExpired,
    }),
    [withExpired],
  );
};

export interface RequestedOffersPageState {
  ids: number[];
  statuses: OffersByStatusSummary[];
  offers: OfferView[];
  offersByGroups: {
    [id: string]: {
      ids: number[];
      offers: OfferView[];
      loading: boolean;
      error: any;
    };
  };
  loading: boolean;
  error: any;
}

// Reducer
const INITIAL_STATE: RequestedOffersPageState = {
  ids: [], // - ids
  statuses: [], // - quote statuses info
  offers: [],
  offersByGroups: {},
  loading: false,
  error: null,
};

const reducer = (state: RequestedOffersPageState, action: any) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case ACTIONS_GET_REQUESTED_OFFERS_START:
    case "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_START":
      return { ...state, loading: true, error: null };

    case ACTIONS_GET_REQUESTED_OFFERS_SUCCESS: {
      const loadedIds = action.offers.map((offer) => offer.id);
      return {
        ...state,
        offers: !!action.offset
          ? [...state.offers, ...action.offers]
          : action.offers,
        ids: !!action.offset
          ? [...new Set([...state.ids, ...loadedIds])]
          : loadedIds,
        loading: false,
        error: null,
      };
    }

    case ACTIONS_GET_REQUESTED_OFFERS_ERROR:
    case "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_ERROR":
      return { ...state, loading: false, error: action.error };

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START:
      return {
        ...state,
        offersByGroups: {
          ...state.offersByGroups,
          [action.groupId]: {
            ...state.offersByGroups[action.groupId],
            loading: true,
            error: null,
          },
        },
      };

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS: {
      const oldOffers = state.offersByGroups[action.groupId]?.offers || [];
      const oldIds = state.offersByGroups[action.groupId]?.ids || [];
      const loadedIds = action.offers.map((offer) => offer.id);

      return {
        ...state,
        offersByGroups: {
          ...state.offersByGroups,
          [action.groupId]: {
            offers: !!action.offset
              ? [...oldOffers, ...action.offers]
              : action.offers,
            ids: !!action.offset
              ? [...new Set([...oldIds, ...loadedIds])]
              : loadedIds,
            loading: false,
            error: null,
          },
        },
      };
    }

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR:
      return {
        ...state,
        offersByGroups: {
          ...state.offersByGroups,
          [action.groupId]: {
            ...state.offersByGroups[action.groupId],
            loading: false,
            error: action.error,
          },
        },
      };

    case "ACTIONS_REQUESTED_OFFERS_PAGE_DELETE_OFFER": {
      if (!!action.groupId) {
        const updatedData = {
          ...state.offersByGroups[action.groupId],
          offers: state.offersByGroups[action.groupId]?.offers?.filter(
            (offer) => offer.id !== action.offerId,
          ),
          ids: state.offersByGroups[action.groupId]?.ids?.filter(
            (id) => id !== action.offerId,
          ),
        };
        return {
          ...state,
          offersByGroups: {
            ...state.offersByGroups,
            [action.groupId]: updatedData,
          },
        };
      } else {
        return {
          ...state,
          ids: state.ids.filter((id) => id !== action.offerId),
        };
      }
    }

    case "ACTIONS_REQUESTED_OFFERS_PAGE_ARCHIVE_OFFER": {
      if (!!action.groupId) {
        const updatedGroupData = {
          ...state.offersByGroups[action.groupId],
          offers: state.offersByGroups[action.groupId]?.offers?.filter(
            (offer) => offer.id !== action.offerId,
          ),
          ids: state.offersByGroups[action.groupId]?.ids?.filter(
            (id) => id !== action.offerId,
          ),
        };
        return {
          ...state,
          offersByGroups: {
            ...state.offersByGroups,
            [action.groupId]: updatedGroupData,
          },
        };
      } else {
        return state;
      }
    }

    case ACTIONS_CREATE_OFFER_SUCCESS:
      return isRequestedOfferStatus(action.offer.status)
        ? {
            ...state,
            offers: [action.offer, ...state.offers],
            ids: [action.offer.id, ...state.ids],
          }
        : state;

    case "ACTIONS_REQUESTED_OFFERS_PAGE_GET_STATUSES_SUCCESS":
      return {
        ...state,
        statuses: action.statuses,
        loading: false,
        error: null,
      };

    case "ACTIONS_REQUESTED_OFFERS_PAGE_CLEAR_DATA":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
