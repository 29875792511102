import useSWR from "swr";
import API, {ProductGroupView} from "~/API";
import useSWRInfinite from "swr/infinite";

export const useProductGroup = (id: number | undefined | null) =>
  useSWR(
    id == null ? null : ["api/product/group/", id],
    () => API.getProductGroup({ id }),
    { revalidateOnFocus: false }
  );

export const getListKey = (
  filters: { search: string; onlyMy: boolean; productType: string },
  offset: number,
  previousPageData: ProductGroupView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/rl/panels", ...filters, offset };
};

const PAGE_SIZE = 40;

export const useProductGroups = (filters: {
  search: string;
  onlyMy: boolean;
  productType: string;
}) =>
  useSWRInfinite(
    (i, p) => getListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getProductGroups({
        ...filters,
        offset: key.offset,
        limit: PAGE_SIZE,
      }),
  );

