import API, { Order, UserGroupPermissionsView } from "~/API";
import { useEffect, useState } from "react";
import { continuousIncludes } from "~/Utils";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import {composedItems} from "./Utils/Compose";

export const ACTIONS_GET_USER_GROUPS_START = 'ACTIONS_GET_USER_GROUPS_START';
export const ACTIONS_GET_USER_GROUPS_SUCCESS = 'ACTIONS_GET_USER_GROUPS_SUCCESS';
export const ACTIONS_GET_USER_GROUPS_ERROR = 'ACTIONS_GET_USER_GROUPS_ERROR';
export const ACTIONS_CREATE_USER_GROUP_SUCCESS = 'ACTIONS_CREATE_USER_GROUP_SUCCESS';
export const ACTIONS_UPDATE_USER_GROUP_SUCCESS = 'ACTIONS_UPDATE_USER_GROUP_SUCCESS';

const userGroupsSelector = (state: RootState) => state.userGroups;

export const useUserGroups = (): UserGroupsState =>
  useAppSelector(userGroupsSelector);

const useUserGroupKeys = () => {
  const [keys, setKeys] = useState([]);
  const { userGroups } = useUserGroups();
  useEffect(() => {
    setKeys(Object.keys(userGroups).map((locationId) => Number(locationId)));
  }, [userGroups]);
  return keys;
};

export const useUserGroupLabels = () => {
  const [labels, setLabels] = useState({});
  const { userGroups } = useUserGroups();
  const userGroupKeys = useUserGroupKeys();
  useEffect(() => {
    setLabels(
      userGroupKeys.reduce(
        (a, option) => ({ ...a, [option]: userGroups[option]?.name }),
        {},
      ),
    );
  }, [userGroupKeys]);
  return labels;
};

interface GetUserGroupsParams {
  search?: string;
  query?: string;
  orderBy?: string;
  order?: Order;
  offset?: number;
  limit?: number;
}

export const getUserGroups =
  (params: GetUserGroupsParams = { search: "" }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: ACTIONS_GET_USER_GROUPS_START });
      const data = await API.getUserGroups(params);
      const filtered = data.filter((it) =>
        continuousIncludes(
          it.name.toUpperCase(),
          (params?.search || "").toUpperCase?.(),
        ),
      );
      dispatch({ type: ACTIONS_GET_USER_GROUPS_SUCCESS, data: filtered });
      return data;
    } catch (error) {
      dispatch({ type: ACTIONS_GET_USER_GROUPS_ERROR, error });
    }
  };

// reducer

export interface UserGroupsState {
  loading: boolean;
  error: any;
  userGroups: { [id: number]: UserGroupPermissionsView };
}

const INITIAL_STATE: UserGroupsState = {
  loading: false,
  error: null,
  userGroups: {},
};

const reducer = (state: UserGroupsState, action: any): UserGroupsState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case ACTIONS_GET_USER_GROUPS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case ACTIONS_GET_USER_GROUPS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case ACTIONS_GET_USER_GROUPS_SUCCESS:
      return { ...state, userGroups: composedItems(action.data), loading: false, error: null };

    case ACTIONS_UPDATE_USER_GROUP_SUCCESS:
      const newGroups = {...state.userGroups};
      newGroups[action.data.id] = action.data;
      return { ...state, userGroups: newGroups };

    case "ACTIONS_GET_USER_GROUP_SUCCESS":
    case ACTIONS_CREATE_USER_GROUP_SUCCESS:
      return { ...state, userGroups: {...state.userGroups, [action.userGroup.id]: action.userGroup} };

    default:
      return state;
  }
};

export default reducer;
