import React, { useMemo } from "react";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import useSWR from "swr";
import API from "~/API";

export enum ProductContexts {
  PRODUCT_TYPE = "PRODUCT - PRODUCT TYPE",
  PRODUCT_CATEGORY = "PRODUCT - PRODUCT CATEGORY",
  SPECIES = "PRODUCT - SPECIES",
  SURFACE_CODE = "PRODUCT - SURFACE CODE",
  FINISH = "PRODUCT - FINISH",
  GRADE = "PRODUCT - GRADE",
  DRYNESS_CODE = "PRODUCT - DRYNESS CODE",
  CONSTRUCTION = "PRODUCT - CONSTRUCTION",
  TYPE = "PRODUCT - TYPE",
  CLASS_CODE = "PRODUCT - CLASS CODE",
  NOMINAL_ACTUAL = "PRODUCT - NOMINAL-ACTUAL",
  STANDARD_UNIT_OF_MEASURE = "PRODUCT - STANDARD UNIT OF MEASURE",
}

export const DictionaryCombobox = <T,>(
  props: { context: string } & Omit<
    FormComboboxProps<T, number>,
    "getLabel" | "data"
  >,
) => {
  const definitions = useSWR(
    ["api/translations/", props.context],
    () => API.getTranslationsByContext({ context: props.context }),
    { revalidateOnFocus: false }
  );

  const keys = useMemo(
    () => definitions.data?.map((d) => d.en) || [],
    [definitions.data],
  );

  return <FormCombobox {...props} data={keys} getLabel={(l) => l} loading={definitions.isLoading} />;
};
