import { FC } from "react";
import { Card, FormInput, FormSwitch } from "~/Components/UI";
import { FormReturn } from "~/Hooks";
import { ProductUfp, ProductV2ProductType } from "~/API";
import {
  DictionaryCombobox,
  ProductContexts,
} from "~/Components/Comboboxes/DictionaryCombobox";

export const GeneralProductDetailsSection: FC<{
  form: FormReturn<Partial<ProductUfp>>;
  canEdit: boolean;
}> = (props) => {
  const isFinance = props.form.data.productType == ProductV2ProductType.FINANCE;

  return (
    <Card className="flex-col gap-md">
      <div className="text-lg">General</div>

      <FormSwitch
        id="active"
        formData={props.form}
        label="title.active"
        disabled={!props.canEdit}
      />

      <div className="flex-row gap-md flex-wrap">
        <FormInput
          id="msrProductTypeCode"
          label="title.type.code"
          formData={props.form}
          disabled
          containerClassName="w-7xl"
        />

        <FormInput
          id="msrDescription"
          label="title.description.MSR"
          formData={props.form}
          disabled
          containerClassName="flex"
        />
      </div>

      <div className="flex-row gap-md flex-wrap">
        <DictionaryCombobox
          id="productType"
          label="title.product.type"
          context={ProductContexts.PRODUCT_TYPE}
          formData={props.form}
          containerClassName="w-7xl"
          disabled={!props.canEdit}
        />

        <FormInput
          id="description"
          label="title.description"
          formData={props.form}
          containerClassName="flex"
          disabled={!props.canEdit}
        />
      </div>

      <div className="text-lg mt-lg">Product Group</div>
      <FormInput id="productGroup" formData={props.form} disabled />

      { !isFinance && (<>
      <div className="flex-row gap-md flex-wrap">
        <DictionaryCombobox
          id="productCategory"
          label="title.category"
          context={ProductContexts.PRODUCT_CATEGORY}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="species"
          label="title.species"
          context={ProductContexts.SPECIES}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="surfaceCode"
          label="title.surface.code"
          context={ProductContexts.SURFACE_CODE}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="finish"
          label="title.finish"
          context={ProductContexts.FINISH}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="grade"
          label="title.grade"
          context={ProductContexts.GRADE}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="drynessCode"
          label="title.dryness.code"
          context={ProductContexts.DRYNESS_CODE}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="construction"
          label="title.construction"
          context={ProductContexts.CONSTRUCTION}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="type"
          label="title.type"
          context={ProductContexts.TYPE}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />

        <DictionaryCombobox
          id="classCode"
          label="title.class.code"
          context={ProductContexts.CLASS_CODE}
          formData={props.form}
          showClear
          clearValue=""
          disabled={!props.canEdit}
        />
      </div>
      </>)}
    </Card>
  );
};
