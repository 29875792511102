import {useCallback, useState, FC} from 'react';
import { useToggle } from "~/Hooks/Old";
import { LocalizedMessage } from "~/Locales";
import { createQuoteFeedback } from "~/Reducers/Quotes";
import { useCurrentUser } from "~/Reducers/User";
import Button from '~/Components/Old/Button';
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import TextField from "~/Components/Old/TextField";
import {useAppDispatch} from "~/StoreTypes";
import Toast from "~/Components/Toast";

const QuoteFeedbackPanel: FC<{
  quoteId: number,
  onBack?: () => void,
}> = ({ quoteId, onBack }) => {
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const userName = currentUser.name;

  const [feedbackComment, setFeedbackComment] = useState('');
  const [showFeedbackDialog, toggleFeedbackDialog] = useToggle(false);

  const onLikeClick = useCallback(async () => {
    try {
      await dispatch(createQuoteFeedback(quoteId, { value: true, message: feedbackComment }));
      Toast.Success("Feedback sent.");
    } catch (e) {
      Toast.ApiError(e);
    }
  }, [quoteId]);

  const onFieldChange = useCallback((event) => {
    setFeedbackComment(event.target.value);
  }, [setFeedbackComment]);

  const onConfirmSendFeedback = useCallback(async () => {
    try {
      await dispatch(createQuoteFeedback(quoteId, { value: false, message: feedbackComment }));
      toggleFeedbackDialog();
      setFeedbackComment('');
      Toast.Success("Feedback sent.");
      onBack?.();
    } catch (e) {
      Toast.ApiError(e);
    }
  }, [feedbackComment, quoteId, onBack]);

  return (
    <>
      <div className="flex-row mobile-flex-col align-center justify-between px-lg py-md mb-lg gap-sm rounded-xxs bg-blue-8 color-blue-1">
        <div className="text-bold">Hello {userName}, is this quote interesting for you?</div>
        <div className="flex-row align-center">
          <Button onClick={onLikeClick} className="ml-lg">
            <LocalizedMessage id="title.i.am.interested" />
          </Button>
          <Button onClick={toggleFeedbackDialog} className="ml-lg">
            <LocalizedMessage id="title.not.interested" />
          </Button>
        </div>
      </div>

      {showFeedbackDialog &&
        <ConfirmationDialog
          title={'Feedback'}
          description={`Thanks for the feedback. What is the reason for your negative review?`}
          description2={''}
          confirmTitle={'Send feedback'}
          closeTitle={<LocalizedMessage id={'title.cancel'} />}
          onConfirm={onConfirmSendFeedback}
          onClose={toggleFeedbackDialog}
        >
          <TextField id="externalComment"
            placeholder={'write your feedback here'}
            value={feedbackComment}
            onChange={onFieldChange}
            multiline
            rows={3}
          />
        </ConfirmationDialog>
      }
    </>);
};

export default QuoteFeedbackPanel;
