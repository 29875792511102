import { useCallback } from "react";
import { OfferStatus } from "~/API";
import { requestedOffersRootPath } from "~/Services/Routes";
import Button from "~/Components/Old/Button";
import { useHistory } from "react-router-dom";
import { useToggle } from "~/Hooks/Old";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { LocalizedMessage } from "~/Locales";
import Toast from "~/Components/Toast";


export const ReOpenRequestButton = ({ offerId, saveOffer, status }) => {
  const history = useHistory();
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const onConfirm = useCallback(async () => {
    try {
      await saveOffer(OfferStatus.RFO_SENT);
      Toast.Success(`Request ${offerId} re-opened`);
      history.replace(`${requestedOffersRootPath}${offerId}/edit`);
    } catch (e) {
      console.error(e);
    }
  }, [saveOffer, offerId, history]);

  return <>
    <Button view={'secondary'} onClick={toggleConfirmSubmitDialog}>
      Re-open Request
    </Button>

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Re-open Request'}
        description={
          status === OfferStatus.RFO_ARCHIVED
            ? 'Do you really want to re-open your archived Request?'
            : 'Do you really want to re-open this Request?'
        }
        description2={''}
        confirmTitle={'re-open'}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={onConfirm}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>;
}


