import useSWRInfinite from "swr/infinite";
import API, {NewProductSales} from "~/API";


export interface NewCompanyProductSalesFilters {
    from: string,
    to: string,
    limit?: number,
    offset?: number,
    companyId?: number,
    shipToId?: number,
    productId?: number,
}

const PAGE_SIZE = 15;

const getNewCompanyProductSalesKey = (
    filters: NewCompanyProductSalesFilters,
    offset: number,
    previousPageData: NewProductSales[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/v3/products/companyProducts/sales", filters, offset };
};
export const useNewProductSales = (filters: NewCompanyProductSalesFilters) =>
    useSWRInfinite(
        (i, p) => getNewCompanyProductSalesKey(filters, i * PAGE_SIZE, p),

        (key) =>
            API.getNewCompanyProductSales({
                ...filters,
                offset: key.offset,
                limit: PAGE_SIZE,
            }),

        {
            revalidateFirstPage: false,
        },
    );

