import { useLocation } from "~/Reducers/Locations";

/**
* @deprecated please use `~/Components/Location/LocationView`
**/
const LocationName = ({ id, showCode = false, showLocationCode = false }) => {
  const location = useLocation(id);

  if (!id || !location) return null;
  let prefix='';
  if(showCode) {
    prefix = location?.code + ' - ';
  } else if(showLocationCode) {
    prefix = location?.locationCode + ' - ';
  }
  return <>{prefix}{location?.name}</>;
};

export default LocationName;
