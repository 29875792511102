import { useState, useMemo, FC } from "react";
import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import {
  useOrderItems,
  useOrderItemsTotalPackages,
} from "~/Reducers/OrdersItems";
import { LC, ls } from "~/Locales";
import OrderPositionVolume from "./OrderPositionVolume";
import OrderPositionPricePerUnit from "./OrderPositionPricePerUnit";
import PriceView from "~/Components/Views/PriceView";
import {DeliveryItem} from "~/API";
import {TbChevronDown, TbChevronUp} from "react-icons/tb";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Table} from "~/Components/UI";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";

const OrderItems: FC<{ orderId: number }> = ({ orderId }) => {
  const items = useOrderItems(orderId) || [];
  const [showMore, setShowMore] = useState(false);

  const onShowHideMore = () => {
    setShowMore(!showMore);
  };

  const itemsToShow = useMemo(
    () => (showMore ? items : items.slice(0, 6)),
    [items, showMore],
  );

  if (itemsToShow.length === 0) return null;

  return (
    <div className="flex-col p-lg text-left overflow-hidden">
      <div className="text-xl color-black-1">
        <LC id="title.items" />
      </div>
      <PositionsTable items={itemsToShow} orderId={orderId} />
      {items.length > 6 && (
        <div
          className="cursor-pointer color-blue-1 mt-md"
          onClick={onShowHideMore}
        >
          <LC
            id={showMore ? "title.view.less.items" : "title.view.all.items"}
          />
          {showMore && <TbChevronUp size={16} />}
          {!showMore && <TbChevronDown size={16} />}
        </div>
      )}
    </div>
  );
};

export default OrderItems;

const PositionsTable: FC<{
  items: DeliveryItem[];
  orderId: number;
}> = ({ items, orderId }) => {
  const salesOrder = useSalesOrder(orderId);
  const { currency, totalSalesForCommission } = salesOrder.data || {};
  const totalPackages = useOrderItemsTotalPackages(orderId);

  const columns = useMemo(
    () => createColumns(currency, totalPackages, totalSalesForCommission),
    [currency, totalPackages, totalSalesForCommission]
  );
  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex-col overflow-x-auto w-full mt-lg">
     <Table table={table} />
    </div>
  );
};

const columnHelper = createColumnHelper<DeliveryItem>();

const createColumns = (currency: string, totalPkgs: number, totalSales: number) => [
  columnHelper.accessor("productCode", {
    header: () => ls("title.product"),
    footer: () => <div className="text-bold text-lg"><LC id="title.total"/></div>
  }),

  columnHelper.accessor("productType", {
    header: () => ls("title.type"),
  }),

  columnHelper.accessor("description", {
    header: () => ls("title.description"),
    cell: ({ row: { original } }) => (
      <span className="text-no-wrap">
        <ProductCatalogueDescriptionLink
          description={original.description}
          productCode={original.productCode}
          hasSyndigo={original.hasSyndigo}
          productId={original.productId}
        />
      </span>
    )
  }),

  columnHelper.accessor("lengthCode", {
    header: () => ls("title.length"),
  }),

  columnHelper.accessor("piecesPerPackage", {
    header: () => <div className="text-right">{ls("title.pieces.per.package")}</div>,
    cell: (data) => (
      <div className="text-right">
        {data.getValue() || "-"}
      </div>
    ),
  }),

  columnHelper.accessor("pkgTotal", {
    header: () => <div className="text-right">{ls("title.total.packages")}</div>,
    cell: (data) => (
      <div className="text-right">
        {data.getValue() || "-"}
      </div>
    ),
    footer: () => <div className="text-right">{totalPkgs}</div>,
  }),

  columnHelper.display({
    id: "totalPieces",
    header: () => <div className="text-right">{ls("title.total.pieces")}</div>,
    cell: (data) => (
    <div className="text-right">
      {
      data.row.original.pkgTotal && data.row.original.piecesPerPackage
        ? data.row.original.pkgTotal * data.row.original.piecesPerPackage
        : "-"
      }
    </div>),
  }),

  columnHelper.display({
    id: "volume",
    header: () => <div className="text-right">{ls("title.volume")}</div>,
    cell: (data) => (
      <div className="text-right">
        <OrderPositionVolume
          volume={data.row.original.vol}
          volumeUom={data.row.original.volUom}
          metricQuantity={data.row.original.metricVol}
          priceUom={data.row.original.priceUnit}
        />
      </div>
    )
  }),

  columnHelper.display({
    id: "price",
    header: () => <div className="text-right">{ls("title.price")}</div>,
    cell: (data) =>
      <div className="text-right">
        <OrderPositionPricePerUnit
          priceUom={data.row.original.priceUnit}
          currency={currency}
          price={data.row.original.price}
        />
      </div>
    }),

  columnHelper.accessor("extension", {
    header: () => ls("title.subtotal"),
    cell: (data) =>
      <div className="text-right">
        <PriceView price={data.getValue() || 0} currency={currency}/>
      </div>,
    footer: () =>
      <div className="text-right">
        <PriceView price={totalSales || 0} currency={currency}/>
      </div>
  }),
];
