import {
  ChangeEvent,
  KeyboardEventHandler,
  useMemo,
  useRef,
  useState,
} from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import {cn, onScrollToBottom} from "~/Utils";
import {Case, For, Match, Show} from "~/Components/UI";
import { LC, LocalizedMessageKey } from "~/Locales";
import * as RSelect from "@radix-ui/react-select";
import {
  TbCheck,
  TbSearch,
  TbSelector,
  TbSquare,
  TbSquareCheckFilled, TbSquareMinusFilled,
  TbX
} from "react-icons/tb";
import { FormReturn, useDebounceCall, useToggle } from "~/Hooks";
import { Button } from "~/Components/UI";
import {LinearLoader} from "~/Components/Loaders";

export interface ComboboxProps<T> {
  id: string;
  data: T[];
  value?: T | T[];
  setValue: (value: T | T[]) => void;
  getLabel: (item: T) => string;
  multiple?: boolean;
  multipleDisplayLimit?: number;
  placeholder?: LocalizedMessageKey;
  label?: LocalizedMessageKey;
  error?: string;
  align?: "start" | "center" | "end";
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  listLoading?: boolean;
  showSelectAll?: boolean;
  showClear?: boolean;
  clearValue?: any;
  onSearchChange?: (value: string) => void;
  onScrollToBottom?: () => void;
  disabled?: boolean;
  required?: boolean;
  dataCy?: string;
}

export const Combobox = <T = any,>(props: ComboboxProps<T>) => {
  const open = useToggle();
  const [search, setSearch] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);
  const focusRefs = useRef<any[]>([]);

  const onOpenChanged = (_isOpen: boolean) => {
    setSearch("");
    props.onSearchChange?.("");
  };

  const options: ("ALL" | T)[] = useMemo(
    () => [
      ...((props.showSelectAll ? ["ALL"] : []) as ["ALL"]),
      ...props.data.filter((item) =>
        props.getLabel(item)?.toLowerCase().includes(search.toLowerCase()),
      ),
    ],
    [props.data, props.getLabel, search],
  );

  const allOptionsSelected = props.multiple && props.data.every(d => (props.value as T[])?.includes(d));
  const noOptionsSelected = props.multiple && props.data.every(d => !(props.value as T[])?.includes(d));

  const onSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    props.onSearchChange?.(e.target.value);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === "ArrowDown") {
      e.preventDefault();
      const newIndex = Math.min(focusIndex + 1, options.length - 1);
      setFocusIndex(newIndex);
      focusRefs.current[newIndex]?.scrollIntoView({ block: "nearest" });
    } else if (e.code === "ArrowUp") {
      e.preventDefault();
      const newIndex = Math.max(focusIndex - 1, 0);
      setFocusIndex(newIndex);
      focusRefs.current[newIndex]?.scrollIntoView({ block: "nearest" });
    } else if (e.code == "Escape") {
      e.preventDefault();
      open.toggleFalse();
    } else if (e.code === "Enter") {
      e.preventDefault();
      onSelect(options[focusIndex]);
    } else {
      setFocusIndex(0);
      focusRefs.current[0]?.scrollIntoView({ block: "nearest" });
    }
  };

  const onSelect = (item: "ALL" | T) => {
    if (item == "ALL") {
      if (allOptionsSelected) {
        props.setValue([]);
      } else {
        props.setValue([...props.data]);
      }
    } else {
      if (!props.multiple) props.setValue(item);
      else {
        if ((props.value as T[])?.includes(item))
          props.setValue((props.value as T[]).filter((v) => v != item));
        else props.setValue([...((props.value || []) as T[]), item]);
      }
    }

    if (!props.multiple) open.toggleFalse();
  };

  const onClearSearchClicked = () => {
    setSearch("");
    props.onSearchChange?.("");
  };

  const onClearValueClicked = (e: any) => {
    e.stopPropagation();
    props.setValue(props.clearValue);
  };

  const onClearListValueClicked = (e: any, val: T) => {
    e.stopPropagation();
    props.setValue((props.value as T[]).filter((v) => v != val));
  };

  const toggleDebounce = useDebounceCall(open.toggleFalse, 100);

  const onScroll = onScrollToBottom(() => {
    props.onScrollToBottom?.();
  }, 64);

  return (
    <div
      className={cn("flex-col", props.containerClassName)}
      data-cy={props.dataCy}
    >
      <Show when={props.label !== undefined}>
        <div className="text-left ml-md mb-xxs color-black-6 text-sm">
          <LC id={props.label} />
          {props.required && " *"}
        </div>
      </Show>
      <Popover open={open.value} onOpenChange={onOpenChanged}>
        <PopoverTrigger
          disabled={props.disabled}
          onClick={open.toggle}
          className={cn(
            "border-1 rounded-xxs px-md py-xs flex-row justify-between align-center",
            "focus-outline-2 focus-outline-blue-1 focus-border-clear",
            props.disabled
              ? "color-text-2 bg-black-11 cursor-disabled"
              : "color-text-1 hover-bg-black-11-5 cursor-pointer bg-black-12",
            props.error
              ? "border-red-1 color-red-1"
              : "border-black-9 color-black-7",
            open.value && "outline-2 outline-blue-1 border-clear",
            props.className,
          )}
        >
          <Show
            when={
              !props.value ||
              (props.multiple && (props.value as T[]).length == 0)
            }
          >
            <div className="text-italics mr-md color-text-3">
              <LC id={props.placeholder || "title.select"} />
            </div>
          </Show>

          <span className="text-truncate">
            {props.value && !props.multiple && props.getLabel(props.value as T)}
          </span>

          {props.multiple && (props.value as T[])?.length > 0 && (
            <span className="overflow-hidden flex flex-row flex-wrap gap-xxs mr-xxs">
              {props.value &&
                props.multiple &&
                (props.value as T[]).slice(0, (props.multipleDisplayLimit || 5)).map((val, i) => (
                  <div
                    key={i}
                    className="rounded-xxs bg-black-10 pl-xs py-xxs flex-row overflow-hidden align-center"
                  >
                    <span className="text-truncate text-sm">
                      {props.getLabel(val)}
                    </span>
                    {props.showClear && (
                      <Button
                        onClicked={(e) => onClearListValueClicked(e, val)}
                        leftIcon={<TbX size={12} />}
                        variant="text"
                        color="gray"
                        disabled={props.disabled}
                      />
                    )}
                  </div>
                ))}
              {props.value && props.multiple && (props.value as T[]).length > (props.multipleDisplayLimit || 5) && (
                <div className="color-text-2 py-xxs pl-xs">
                  + {(props.value as T[]).length - (props.multipleDisplayLimit || 5)} more
                </div>
              )}
            </span>
          )}

          <Show
            when={
              props.value !== undefined &&
              props.value !== props.clearValue &&
              (!props.multiple || (props.value as T[])?.length !== 0) &&
              props.showClear &&
              !props.disabled
            }
            fallback={
              <RSelect.Icon asChild>
                <TbSelector className="color-black-7 ml-xs" size={16} />
              </RSelect.Icon>
            }
          >
            <Button
              onClicked={onClearValueClicked}
              color="gray"
              className="ml-xs"
              leftIcon={<TbX size={12} />}
            />
          </Show>
        </PopoverTrigger>
        <PopoverContent
          onEscapeKeyDown={toggleDebounce}
          onPointerDownOutside={toggleDebounce}
          onInteractOutside={toggleDebounce}
          align={props.align}
          className="w-min-6xl w-max-9xl h-max-9xl"
        >
          <div className="flex-row color-text-2 align-center gap-xs px-sm py-xs border-b-1 border-black-9 mb-xs">
            <TbSearch size={20} />
            <input
              value={search}
              placeholder="Search"
              className="w-full border-none"
              role="combobox"
              onChange={onSearchChanged}
              autoComplete="off"
              onKeyDown={onKeyDown}
            />
            <Button
              variant="text"
              color="gray"
              leftIcon={<TbX size={16} />}
              onClicked={onClearSearchClicked}
            />
          </div>
          <LinearLoader loading={props.loading} />
          <div
            className="p-xxs overflow-y-auto"
            onScroll={onScroll}
            role="listbox"
            tabIndex={-1}
            data-cy={`${props.dataCy}Container`}
          >
            <For each={options}>
              {(item, i) => (
                <div
                  role="option"
                  tabIndex={-1}
                  aria-selected={focusIndex == i}
                  key={i}
                  onMouseOver={() => setFocusIndex(i)}
                  onClick={() => onSelect(item)}
                  ref={(el) => (focusRefs.current[i] = el)}
                  className={cn(
                    "flex-row p-xs rounded-xxs align-start gap-sm",
                    "cursor-pointer focus-outline-none",
                    focusIndex == i && "bg-blue-10 color-blue-1",
                  )}
                >
                  {item != "ALL" && (
                    <>
                      <div>
                        {props.multiple && (
                          <Show
                            when={
                              props.value === item ||
                              (props.multiple &&
                                (props.value as T[])?.includes(item))
                            }
                            fallback={<TbSquare size={20} />}
                          >
                            <TbSquareCheckFilled
                              className="color-blue-1"
                              size={20}
                            />
                          </Show>
                        )}
                        {!props.multiple && (
                          <Show
                            when={props.value === item}
                            fallback={<div className="w-md" />}
                          >
                            <TbCheck size={16} />
                          </Show>
                        )}
                      </div>
                      {props.getLabel(item)}
                    </>
                  )}

                  {item == "ALL" && (
                    <>
                      <div>
                        <Match on={true}>
                          <Case v={allOptionsSelected}>
                            <TbSquareCheckFilled
                              className="color-blue-1"
                              size={20}
                            />
                          </Case>
                          <Case v={noOptionsSelected}>
                            <TbSquare size={20} />
                          </Case>
                          <Case default>
                            <TbSquareMinusFilled
                              className="color-blue-1"
                              size={20}
                            />
                          </Case>
                        </Match>
                      </div>

                      <LC id="title.select.all" />
                    </>
                  )}
                </div>
              )}
            </For>
            <LinearLoader loading={props.listLoading} />
          </div>
        </PopoverContent>
      </Popover>
      <Show when={props.error !== undefined}>
        <div className="text-left ml-md mt-xxs color-red-1">{props.error}</div>
      </Show>
    </div>
  );
};

export interface FormComboboxProps<T, K>
  extends Omit<ComboboxProps<K>, "value" | "setValue" | "error"> {
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormCombobox = <T = any, K = any>(
  props: FormComboboxProps<T, K>,
) => {
  return (
    <Combobox<K>
      {...props}
      value={props.formData.data[props.id] as K}
      error={props.formData.errors[props.id]?._errors.join(", ")}
      setValue={(value) => props.formData.onDataChange(props.id, value)}
      required={props.formData.isFieldRequired(props.id, props.multiple ? [] : "") || props.required}
    />
  );
};
