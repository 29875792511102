import API from "~/API";
import { useGetEntitiesForId } from "~/Reducers/Utils/Entity";

export const useRequestMatchingVendors = (requestId: number, params: {}) =>
  useGetEntitiesForId(
    { id: requestId, ...params },
    API.getRequestForQuoteMatchingVendors,
  );

export const useRequestMatchingProducts = (
  requestId: number,
  params: {
    companyId: number;
  },
) =>
  useGetEntitiesForId(
    { id: requestId, ...params },
    API.getRequestForQuoteMatchingProductsForCompany,
  );
