import { FC } from "react";
import { usePublicUser } from "~/Data/Users";
import { UserAccountPublicView } from "~/API";
import {Skeleton} from "~/Components/Loaders";

export const UserName: FC<{ id: number }> = (props) => {
  const user = usePublicUser(props.id);

  if (user.isLoading)
    return <Skeleton className="w-max-6xl w-full h-line-md" />;

  return <>{userNameFromUser(user.data)}</>;
};

export const userNameFromUser = (user: UserAccountPublicView | undefined) => {
  if (!!user) {
    if (!user.active) return `${user.name} ${user.surname} (Inactive)`;
    const nameParts = [];
    if (!!user.name) nameParts.push(user.name);
    if (!!user.surname) nameParts.push(user.surname);
    return nameParts.join(" ").trim();
  }
  return "";
};
