import { FC, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import {
  useQuote,
  addFollower,
  removeFollower,
} from "~/Reducers/Quotes";
import { useRouter } from "~/Reducers/Router";
import {
  useCurrentUser,
  useIsInternalUser,
} from "~/Reducers/User";
import DetailsBackButton from "../../../Components/Old/DetailsBackButton";
import {FollowButton} from "~/Components/Entities/FollowButton";
import UserParticipantList from "~/Components/Users/UserParticipantList";
import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import QuoteDetailsGeneralTab from "./QuoteDetailsGeneralTab";
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import QuoteChat from "./Components/QuoteChat";
import QuoteChatButton from "./Components/QuoteChatButton";
import { usePageTitle } from "~/Hooks";
import {useAppDispatch} from "~/StoreTypes";
import {useUsersList} from "~/Data/Users";
import {getQuoteChatId} from "~/Data/Chats";
import {trackEvent} from "~/Services/Tracking";

const QuoteDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();
  const { id: quoteIdFromUrl, edit } = useParams<{
    id: string;
    edit: string;
  }>();
  const quoteId = quoteIdFromUrl === "new" ? null : Number(quoteIdFromUrl);
  usePageTitle(quoteId == null ? "New Quote" : `Quote #${quoteIdFromUrl}`);
  const chat = getQuoteChatId(quoteId);

  const { members, shipToLocationId } = useQuote(quoteId) || {
    members: undefined,
    shipToLocationId: undefined,
  };
  const membersList = useUsersList(members || []);

  const [showChat, toggleShowChat] = useToggle(false);

  const onBack = useCallback(() => {
    if (!!quoteId && !!edit) {
      history.replace(`${quotesRootPath}${quoteId}`);
    } else if (!router.hasHistory) {
      history.replace(quotesRootPath);
    } else {
      history.goBack();
    }
  }, [router, history, quoteId, edit]);

  const onAddMember = useCallback(
    (userId) => {
      dispatch(addFollower(quoteId, userId));
      trackEvent("Quote Follower Added");
    },
    [dispatch, quoteId],
  );

  const onRemoveMember = useCallback(
    (userId) => {
      dispatch(removeFollower(quoteId, userId));
      trackEvent("Quote Follower Removed");
    },
    [dispatch, quoteId],
  );

  const isMember = useMemo(
    () => members?.some((memberId) => memberId === currentUser.id),
    [members, currentUser],
  );
  const showChatPanel = !!quoteId && isMember;
  const showMemberList = showChatPanel && isInternalUser;
  const onFollow = useCallback(
    async () => onAddMember(currentUser.id),
    [onAddMember, currentUser],
  );
  const onUnfollow = useCallback(
    async () => onRemoveMember(currentUser.id),
    [onRemoveMember, currentUser],
  );

  return (
    <>
      <div className={"page-view"}>
        <QuoteDetailsGeneralTab
          {...{ onBack, showChat, toggleShowChat }}
          pageHeader={
            <>
              <PageTitleContainer
                menuButton={<DetailsBackButton {...{ onBack }} />}
              />
              <div
                className={"flex-row flex-wrap align-center mx-lg mt-lg mb-xs"}
              >
                <div className="text-xl">
                  <LocalizedMessage id={"title.quote.id.with.id"}>
                    {quoteId}
                  </LocalizedMessage>
                </div>
                <SpaceFiller />
                {!!quoteId && (
                  <FollowButton
                    {...{ isMember, onFollow, onUnfollow }}
                    chat={chat.data}
                    followLabel="title.follow.quote"
                    unfollowDialogTitle="phrase.unfollow.quote.confirm.1"
                    unfollowDialogText="phrase.unfollow.quote.confirm.2"
                  />
                )}
                {showMemberList && (
                  <UserParticipantList
                    members={membersList.data || []}
                    onAddMember={onAddMember}
                    onRemoveMember={onRemoveMember}
                  />
                )}
                {showChatPanel && (
                  <QuoteChatButton {...{ quoteId, showChat, toggleShowChat }} />
                )}
              </div>
            </>
          }
        />
      </div>

      {showChatPanel && (
        <RightPanel visible={showChat}>
          <QuoteChat onClose={toggleShowChat} quoteId={quoteId} />
        </RightPanel>
      )}
    </>
  );
};

export default QuoteDetailsPage;
