import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { useEffect, useMemo, useState } from "react";
import API, {PortType} from "~/API";
import {usePorts} from "~/Data/Ports";

export const PortCombobox = <T,>(
    props: {
      filters?: {
        inactive?: boolean;
        type?: PortType[];
      }
    } & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const ports = usePorts(props.filters || {});

  const portIds = useMemo(
      () => ports.data?.flat().map((port) => port.id) || [],
      [ports.data],
  );

  const [labels, setLabels] = useState<Record<string, string>>(
      {},
  );

  useEffect(() => {
    let newLabels = {};
    ports.data
        ?.flat()
        .forEach((comp) => (newLabels[comp.id] = `(${comp.code}) ${comp.name}`));
    setLabels((old) => ({ ...old, ...newLabels }));
  }, [portIds]);

  // If there is a default port, manually load, as it might not be in the first page grabbed above
  useEffect(() => {
    addDefault().then();
  }, []);
  const addDefault = async () => {
    if(props.multiple) {
      const port = await Promise.all((props.formData.data[props.id] as number[]).map(id => API.getPortById({ id })));
      setLabels((old) => {
        let labels = {};
        port.forEach(loc => labels[loc.id] = `(${loc.code}) ${loc.name}`);
        return {
          ...old,
          ...labels
        }
      });
    } else {
      const port = await API.getPortById({ id: props.formData.data[props.id] as number });
      setLabels((old) => ({ ...old, [port.id]: `(${port.code}) ${port.name}`}));
    }
  }

  const getLabel = (id: number) => labels[id];

  return (
      <FormCombobox
          {...props}
          data={portIds}
          getLabel={getLabel}
      />
  );
};