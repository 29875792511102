import { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/Components/UI";
import { ls } from "~/Locales";
import { useCompany } from "~/Data/Companies/Companies";
import { useCurrentUser } from "~/Reducers/User";
import API, {CompanyType, ProductPublicView} from "~/API";
import LocationView from "~/Components/Locations/LocationView";
import { useFormData, useMutate, useToggle } from "~/Hooks";
import Toast from "~/Components/Toast";
import {LengthByProductGroupCombobox} from "~/Components/Comboboxes/LengthByProductGroupCombobox";
import { UOMComboboxForm } from "~/Components/Comboboxes/UOMCombobox";
import { ProductType } from "~/Reducers/Products";
import {useMyProducts} from "~/Data/Products/MyProducts";
import {trackEvent} from "~/Services/Tracking";

export const CatalogueDetailsAddMyProducts: FC<{
  product: ProductPublicView;
}> = (props) => {
  const showDialog = useToggle();
  const currentUser = useCurrentUser();
  const userCompany = useCompany(currentUser.companyId);
  const isCustomer = userCompany.data?.type == CompanyType.BUYER;
  const [selectedShipTos, setSelectedShipTos] = useState<
    Record<number, boolean>
  >({});
  const form = useFormData({ lengthIds: [], uom: props.product.standardUnitOfMeasure });

  const myProducts = useMyProducts();
  const myProductsProduct = useMemo(
    () => myProducts.data?.find((p) => (p.productId == props.product.id)),
    [myProducts.data],
  );

  const selectAllValue = useMemo(() => {
    if (Object.keys(selectedShipTos).every((k) => selectedShipTos[k])) {
      return true;
    }
    if (Object.keys(selectedShipTos).every((k) => !selectedShipTos[k])) {
      return false;
    }
    return "indeterminate";
  }, [selectedShipTos]);

  useEffect(() => {
    if (isCustomer) {
      setSelectedShipTos(
        currentUser.locations.reduce((p, c) => ({ ...p, [c]: false }), {}),
      );
    } else {
      setSelectedShipTos({});
    }
  }, [currentUser, isCustomer]);

  const selectAll = (value: boolean) => {
    setSelectedShipTos(
      Object.keys(selectedShipTos).reduce((p, c) => ({ ...p, [c]: value }), {}),
    );
  };

  const selectLocation = (id: number, value: boolean) => {
    setSelectedShipTos({
      ...selectedShipTos,
      [id]: value,
    });
  };

  const selectedLocations = isCustomer
    ? Object.keys(selectedShipTos)
        .filter((k) => selectedShipTos[k])
        .map((k) => +k)
    : [null];

  const saveCall = useMutate(async () => {
    await API.createMyProducts(
      {},
      selectedLocations.map((l) => ({
        productId: props.product.id,
        locationId: l,
        ...form.data,
      })),
    );
    await myProducts.mutate();
    Toast.Success("Successfully Added Products!");
    showDialog.toggleFalse();
    trackEvent("Added My Product From Catalogue Details");
  });
  
  if(!myProducts.isLoading && myProductsProduct != undefined)
    return (
      <Tooltip>
        <TooltipContent>
          Already added to "My Products"
        </TooltipContent>
        <TooltipTrigger>
          <Button label="title.add.to.my.products" disabled />
        </TooltipTrigger>
      </Tooltip>
    );

  return (
    <Dialog
      open={showDialog}
      trigger={
        <Button
          label="title.add.to.my.products"
          loading={myProducts.data == undefined}
        />
      }
      title={ls("title.add.to.my.products")}
    >
      <div className="desktop-w-9xl flex-col gap-md">
        {userCompany.data != undefined && isCustomer && (
          <>
            {currentUser.locations.length > 1 && (
              <Checkbox
                value={selectAllValue}
                id="all"
                onChange={selectAll}
                label="title.select.all"
              />
            )}
            <div className="flex-col gap-xxs h-max-6xl overflow-auto">
              {currentUser.locations.map((l) => (
                <Checkbox
                  value={selectedShipTos[l]}
                  id={`${l}`}
                  onChange={(val) => selectLocation(l, val)}
                >
                  <LocationView showCode id={l} />
                </Checkbox>
              ))}
            </div>
          </>
        )}

        <LengthByProductGroupCombobox
          id="lengthIds"
          formData={form}
          label="title.lengths"
          groupId={props.product.productGroupId}
          multiple
          showClear
          clearValue={[]}
        />

        <UOMComboboxForm
          id="uom"
          formData={form}
          label="title.uom"
          productType={props.product.productType as ProductType}
        />

        <Button
          onClicked={saveCall.mutate}
          loading={saveCall.loading}
          label="title.add.to.my.products"
          disabled={
            userCompany.data == undefined ||
            (isCustomer && selectAllValue == false)
          }
        />
      </div>
    </Dialog>
  );
};
