import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import {
  addOnCostsRootPath,
  dutyCostsRootPath,
  portsRootPath,
  seaFreightCostsRootPath,
} from "~/Services/Routes";
import DestinationsPage from "./Destinations/DestinationsPage";
import LogisticsCostsPage from "./LogisticsCosts/LogisticsCostsPage";
import AddOnCostsPage from "./AddOns/AddOnCostsPage";
import DutiesCostPage from "./Duties/DutiesCostPage";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import {StaticPage} from "~/Components/Layout/Pages";

const LogisticsPage: FC = () => (
  <StaticPage>
    <PageTabContainer title="title.logistics" showNav>
      <PageTab route={portsRootPath} label="title.destinations" />
      <PageTab route={seaFreightCostsRootPath} label="title.logistics.costs" />
      <PageTab route={addOnCostsRootPath} label="title.add.on" />
      <PageTab route={dutyCostsRootPath} label="title.duties.costs" />
    </PageTabContainer>

    <Switch>
      <Route path={portsRootPath}>
        <DestinationsPage />
      </Route>
      <Route path={seaFreightCostsRootPath}>
        <LogisticsCostsPage />
      </Route>
      <Route path={addOnCostsRootPath}>
        <AddOnCostsPage />
      </Route>
      <Route path={dutyCostsRootPath}>
        <DutiesCostPage />
      </Route>
    </Switch>
  </StaticPage>
);

export default LogisticsPage;
