import { FC, useMemo } from "react";
import { Button, Dialog } from "~/Components/UI";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import { ProductCategoryView } from "~/API";
import { LocalizedMessageKey, ls } from "~/Locales";
import { clearCategories } from "~/Pages/Products/CataloguePage/CataloguePageReducer";
import { useToggle } from "~/Hooks";
import { useIsInternalUser } from "~/Reducers/User";
import { CatalogueInternalAddForm } from "~/Pages/Products/CataloguePage/Components/CatalogueInternalAddForm";
import { CatalogueExternalAddForm } from "~/Pages/Products/CataloguePage/Components/CatalogueExternalAddForm";

export const CatalogueButtons: FC<{
  categories: ProductCategoryView[]
}> = (props) => {
  const selected = useAppSelector((state) => state.cataloguePage.groups);
  const dispatch = useAppDispatch();
  const showDialog = useToggle();
  const isInternalUser = useIsInternalUser();

  const total = useMemo(() => {
    let total = 0;

    Object.keys(selected).forEach((k) => {
      // all selected, just use product count
      if (selected[k].selected === true) {
        total += props.categories.find((c) => c.name == k).productsCount;
      } else if (selected[k].selected === "indeterminate") {
        Object.keys(selected[k].products).forEach((p) => {
          total += selected[k].products[p] ? 1 : 0;
        });
      }
    });

    return total;
  }, [props.categories, selected]);

  const onClear = () => {
    dispatch(clearCategories());
  };

  const titleLabel = ls(isInternalUser ? "title.add.to.company" : "title.add.to.my.products");
  const totalLabel = `${total ? `(${total})` : ""}`;
  const buttonLabel = `${titleLabel} ${totalLabel}` as LocalizedMessageKey;

  return (
    <>
      {total > 0 && (
        <Button
          label="title.clear.selection"
          variant="text"
          color="gray"
          onClicked={onClear}
        />
      )}
      <Button
        onClicked={showDialog.toggleTrue}
        label={buttonLabel}
        disabled={total == 0}
      />
      <Dialog
        trigger={<div />}
        title={titleLabel}
        open={showDialog}
      >
        {isInternalUser ? (
          <CatalogueInternalAddForm
            showDialog={showDialog}
            clearSelection={onClear}
          />
        ) : (
          <CatalogueExternalAddForm
            showDialog={showDialog}
            clearSelection={onClear}
            totalCount={total}
          />
        )}
      </Dialog>
    </>
  );
};
