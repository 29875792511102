import { useCurrentUser, useIsInternalUser } from "~/Reducers/User";
import { useMemo } from "react";
import { PurchaseOrderView, SalesOrderView } from "~/API";
import { useChatOrderExternal, useChatOrderInternal } from "~/Data/Chats";

export const useChatUnreadCountForOrder = (
  order: SalesOrderView | PurchaseOrderView,
  isPurchase: boolean,
) => {
  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();

  const { id } = order;
  
  const externalChat = useChatOrderExternal(isInternalUser ? id : null, isPurchase);
  const internalChat = useChatOrderInternal(id, isPurchase, isInternalUser);

  return useMemo(() => {
    // no read marks if not following order
    if (!order.members.includes(currentUser.id))
      return 0;

    const internalCount = internalChat.data?.unread || 0;
    const externalCount = externalChat.data?.unread || 0;

    // only show external if the user is external
    if (!isInternalUser) return externalCount;

    return internalCount + externalCount;
  }, [externalChat.data, internalChat.data]);
};

