import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


export interface CataloguePageState {
  groups: Record<string, {
    id: number;
    selected: boolean | "indeterminate";
    products: Record<number, boolean>;
  }>;
}

const initialState: CataloguePageState = {
  groups: {},
};

export const cataloguePageSlice = createSlice({
  name: "cataloguePage",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<string[]>) => {
      state.groups = action.payload.reduce(
        (p, c) => ({ ...p, [c]: { selected: false, products: state.groups[c]?.products || {} } }),
        {}
      );
    },
    clearCategories: (state) => {
      state.groups = Object.keys(state.groups).reduce(
        (prev, groupId) => ({ ...prev, [groupId]: {
          selected: false,
          products: state.groups[groupId]?.products
          ? Object.keys(state.groups[groupId].products).reduce(
              (prodPrev, product) => ({ ...prodPrev, [product]: false }),
              {}
          )
          : {}
        }}),
        {}
      )
    },
    setProducts: (state, action: PayloadAction<{ group: string, products: number[] }>) => {
      state.groups[action.payload.group].products = action.payload.products.reduce(
        (p,c) => ({ ...p, [c]: state.groups[action.payload.group]?.products[c] || state.groups[action.payload.group].selected == true }),
        {}
      )
    },
    toggleProduct: (state, action: PayloadAction<{ groupId: string, productId: number }>) => {
      const { groupId, productId } = action.payload;
      state.groups[groupId].products[productId] = !state.groups[groupId].products[productId];

      // set the group selected to match the state of selected products
      if (Object.keys(state.groups[groupId].products).every(k => state.groups[groupId].products[k])) {
        state.groups[groupId].selected = true;
      } else if (Object.keys(state.groups[groupId].products).every(k => !state.groups[groupId].products[k])) {
        state.groups[groupId].selected = false;
      } else {
        state.groups[groupId].selected = "indeterminate";
      }
    },
    toggleGroup: (state, action: PayloadAction<string>) => {
      const groupId = action.payload;
      if (state.groups[groupId].selected == true) {
        state.groups[groupId].selected = false;
        Object.keys(state.groups[groupId].products).forEach(p => {
          state.groups[groupId].products[p] = false;
        });
      } else {
        state.groups[groupId].selected = true;
        Object.keys(state.groups[groupId].products).forEach(p => {
          state.groups[groupId].products[p] = true;
        });
      }
    },
  },
});

export const {
  setCategories,
  clearCategories,
  setProducts,
  toggleProduct,
  toggleGroup,
} = cataloguePageSlice.actions;

export default cataloguePageSlice.reducer;
