import { FC, useMemo, useState } from "react";
import { Button, FormSelect, Table } from "~/Components/UI";
import { useCompaniesMetrics } from "~/Pages/Metrics/Data/CompaniesMetricsData";
import { PERIODS } from "../Data/MetricsData";
import dayjs from "dayjs";
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import { useFormData } from "~/Hooks";
import {
  MetricsCard,
  MetricsHeader, MetricStat, MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import {LinearLoader } from "~/Components/Loaders";
import { CompanyUsersView, TimeSeriesCategoricalMetric } from "~/API";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TbArrowBackUp } from "react-icons/tb";
import DateView from "~/Components/Views/DateView";
import { useCompanies } from "~/Data/Companies/Companies";
import { UserName } from "~/Components/Users/UserName";
import {ListJoin} from "~/Components/ListJoin";

export const CompaniesMetricsCard: FC = () => {
  const filterForm = useFormData<{
    period: PERIODS;
  }>({
    period: PERIODS.SIX_MONTHS,
  });

  const companyMetrics = useCompaniesMetrics(filterForm.data.period);

  const totalPeriod = companyMetrics.data?.reduce(
      (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.count, 0),
      0,
  );

  const vendorIndex = companyMetrics.data?.[0].data.findIndex(d => d.category == "SELLER");
  const totalVendors = companyMetrics.data?.reduce(
      (prev: number, cur) => prev + cur.data[vendorIndex].count, 0
  );

  const customerIndex = companyMetrics.data?.[0].data.findIndex(d => d.category == "BUYER");
  const totalCustomers = companyMetrics.data?.reduce(
      (prev: number, cur) => prev + cur.data[customerIndex].count, 0
  );

  const [detailsIndex, setDetailsIndex] = useState<number | undefined>();
  const onBarClicked = (_: any, index: number) => setDetailsIndex(index);
  const onBack = () => setDetailsIndex(undefined);

  return (
      <MetricsCard>
        {detailsIndex != undefined && (
            <CompaniesList
                metrics={companyMetrics.data[detailsIndex]}
                onBack={onBack}
            />
        )}
        {detailsIndex == undefined && (
            <>
              <MetricsHeader title="New Companies">
                <FormSelect
                    id="period"
                    formData={filterForm}
                    data={Object.values(PERIODS)}
                    getLabel={(id) => id}
                />
              </MetricsHeader>

              <MetricStatContainer>
                <MetricStat
                  label={`New companies in the last ${filterForm.data.period}`}
                  loading={companyMetrics.isLoading}
                >
                  {totalPeriod}
                </MetricStat>
                <MetricStat
                  label={`New vendors in the last ${filterForm.data.period}`}
                  loading={companyMetrics.isLoading}
                >
                  {totalVendors}
                </MetricStat>
                <MetricStat
                  label={`New customers in the last ${filterForm.data.period}`}
                  loading={companyMetrics.isLoading}
                >
                  {totalCustomers}
                </MetricStat>
              </MetricStatContainer>

              <BarChart
                  dataset={companyMetrics.data || [{ period: "", data: [] }]}
                  xAxisFormat={(date: string) =>
                      dayjs(date?.slice(0, 10)).format("MMM")
                  }
                  categoryFormat={(label: string) =>
                      label == "SELLER" ? "Vendors" : "Customers"
                  }
                  tooltipLabelFormat={(date: string) =>
                      dayjs(date?.slice(0, 10)).format("MMMM YYYY")
                  }
                  onbarClicked={onBarClicked}
                  showTotal
                  showLegend
                  width="100%"
                  loading={companyMetrics.isLoading}
                  height={vars.size["7xl"]}
              />
            </>
        )}
      </MetricsCard>
  );
};

const companyHelper = createColumnHelper<CompanyUsersView>();

const columns = [
  companyHelper.accessor("dateConnectedMsr", {
    header: "Date",
    cell: (data) => <DateView date={data.getValue()} />,
  }),
  companyHelper.accessor("legalName", {
    header: "Name",
    cell: (data) => <div className="text-no-wrap">{data.getValue()}</div>,
  }),
  companyHelper.accessor("type", {
    header: "Type",
    cell: (data) => (data.getValue() == "BUYER" ? "Customer" : "Vendor"),
  }),
  companyHelper.accessor("accountManagerUserId", {
    header: "AM/Buyer",
    cellMemo: ({ value,  row }) => (
      <div className="text-no-wrap">
        {row.type === "SELLER" && (<UserName id={value} />)}
        {row.type === "BUYER" && (
          <ListJoin
            data={row.locationAccountManagers}
            render={({ data }) => <UserName id={data} />}
          />
        )}
      </div>
    ),
  }),
];

const CompaniesList: FC<{
  onBack: () => void;
  metrics: TimeSeriesCategoricalMetric;
}> = (props) => {
  const dateOnboardedTo = useMemo(() => {
    return dayjs(props.metrics.period.slice(0, 10))
        .endOf("month")
        .format("YYYY-MM-DD");
  }, [props.metrics.period]);

  const companies = useCompanies({
    dateOnboardedFrom: props.metrics.period.slice(0, 10),
    dateOnboardedTo,
    query: "TYPE != null",
  });

  const table = useReactTable({
    data: companies.data?.flat() || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onScrollToBottom = () => companies.setSize(companies.size + 1);

  return (
      <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
        <Button
            variant="text"
            color="gray"
            onClicked={props.onBack}
            leftIcon={<TbArrowBackUp size={16} />}
            label="title.back"
        />
        <div className="text-xl">
          New Companies{" "}
          {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
        </div>
        <LinearLoader loading={companies.isLoading} />
        <Table
            table={table}
            onScrolledBottom={onScrollToBottom}
            className="w-full"
        />
      </div>
  );
};
