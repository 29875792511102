import React, { useRef, forwardRef, ReactNode, CSSProperties } from "react";

interface ScrollableForPaginationProps {
  children: ReactNode;
  onScrolledToBottom?: () => void;
  style?: CSSProperties;
  className?: string;
  estimatedElementHeight?: number;
  numberOfElementsBeforeLoading?: number;
}

/**
 * @deprecated please use the new `<Table />` component or `useScrolledToBottom()` hook
 **/
const ScrollableForPagination = forwardRef<HTMLDivElement, ScrollableForPaginationProps>(
  ({ children, onScrolledToBottom, estimatedElementHeight,  numberOfElementsBeforeLoading, ...props }, ref) => {
    const scrolledToBottom = useRef(false);

    const onScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
      const positionFromBottom = currentTarget.scrollHeight - currentTarget.offsetHeight - currentTarget.scrollTop;
      if (positionFromBottom <= (estimatedElementHeight ?? 16) * (numberOfElementsBeforeLoading ?? 5)) {
        !scrolledToBottom.current && onScrolledToBottom?.();
        scrolledToBottom.current = true;
      } else {
        scrolledToBottom.current = false;
      }
    };

    return (
      <div {...props} onScroll={onScroll} ref={ref}>{children}</div>
    );
  }
);

export default ScrollableForPagination;

// old version, for reference

// import {useRef, forwardRef} from "react";
//
//
// const ScrollableForPagination = forwardRef(({children, onScrolledToBottom, ...props}, ref) => {
//   const scrolledToBottom = useRef(false);
//
//   const onScroll = ({target}) => {
//     const position = target.scrollTop / (target.scrollHeight - target.offsetHeight);
//     if (position >= 0.98) {
//       !scrolledToBottom.current && onScrolledToBottom?.();
//       scrolledToBottom.current = true;
//     } else {
//       scrolledToBottom.current = false;
//     }
//   };
//
//   return (
//     <div {...props} onScroll={onScroll} ref={ref}>{children}</div>
//   );
// });
//
// export default ScrollableForPagination;
