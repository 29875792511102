import { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { useRouter } from "~/Reducers/Router";
import Button from "./Button";
import { BackArrowIcon } from "./Icons";
import { classNames } from "react-ui-basics/Tools";


const DetailsBackButton = ({ parentURL = '', className = '', onBack = null }) => {
  const history = useHistory();
  const router = useRouter();

  const onBackClick = useCallback(() => {
    if (!router.hasHistory) {
      history.replace(parentURL);
    } else {
      history.goBack();
    }
  }, [router, history, parentURL]);

  return (
    <div className={classNames(className)}>
      <Button view={'text'} className={'CloseButtonText px-0'}
        leftIcon={<BackArrowIcon size={16} />}
        onClick={onBack || onBackClick}
      >
        <LocalizedMessage id={'title.back'} />
      </Button>
    </div>
  );
};

export default DetailsBackButton;
