import {FC} from "react";
import {usePort} from "~/Data/Ports";


const PortView: FC<{
  id: number;
  className?: string;
}> = ({ id, className }) => {
  const port = usePort(id);
  return <div className={className}>{port.data?.name || "-"}</div>;
};

export default PortView;
