import { useMemo } from "react";
import API, {Order, RequestForQuoteStatus, RequestForQuoteView} from "~/API";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

export const useDashboardRequestsForQuote = () =>
  useSWR(
    "api/rfq/dashboard",
    async () =>
      await API.getRequestsForQuotes({
        offset: 0,
        limit: 10,
        onlyMy: true,
      }),
  );

export const useRequestForQuoteValue = (request: RequestForQuoteView) => {
  return useMemo(() => {
    if (!Array.isArray(request?.products)) return 0;
    let sum = 0;
    request.products
      .filter((product) => !!product.active)
      .forEach((product) => {
        sum += (product.quantity || 0) * (product.price || 0);
      });
    return sum;
  }, [request?.products]);
};

export interface RequestFilters {
    query?: string;
    search?: string;
    status?: Array<RequestForQuoteStatus>;
    customer?: Array<number>;
    productCode?: Array<number>;
    valueFrom?: string;
    valueTo?: string;
    validityDateFrom?: string;
    validityDateTo?: string;
    quoteCreationDateFrom?: string;
    quoteCreationDateTo?: string;
    shipTo?: Array<number>;
    product?: Array<number>;
    productGroup?: Array<number>;
    port?: Array<number>;
    accountManager?: Array<number>;
    follower?: Array<number>;
    incoterm?: Array<string>;
    onlyMy?: boolean;
    withExpired?: boolean;
    onlyArchivedOrDeliveredOrExpired?: boolean;
    withoutArchivedOrDelivered?: boolean;
    orderBy?: string;
    order?: Order;
    offset?: number;
    limit?: number;
}

const PAGE_SIZE = 15;

const getRequestListKey = (
    filters: RequestFilters,
    offset: number,
    previousPageData: RequestForQuoteView[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/requests", filters, offset };
};

export const useRequestsForQuotes = (filters: RequestFilters) =>
    useSWRInfinite(
        (i, p) => getRequestListKey(filters, i * PAGE_SIZE, p),
        (key) =>
            API.getRequestsForQuotes({
                ...filters,
                offset: key.offset,
                limit: PAGE_SIZE,
            }),
        {
            revalidateFirstPage: false,
        },
    );
