import { FC, useMemo, useState } from "react";
import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import { LC, LocalizedMessageKey } from "~/Locales";
import dayjs from "dayjs";
import { SalesOrderView } from "~/API";
import { KeyOfType } from "~/Utils";
import {TbChevronDown, TbChevronUp} from "react-icons/tb";

type EventType = KeyOfType<SalesOrderView, string>;
const EVENT_LIST: EventType[] = [
  "dateContractSigned",
  "datePrepaymentReceived",
  "dateLcOpeningReceived",
  "dateOrderPlacedWithSupplier",
  "dateSawmillConfirmation",
  "dateLogisticsBooked",
  "dateLogisticsConfirmed",
  "dateExportDocumentsDrafted",
  "datePickup",
  "dateLoadingCompleted",
  "dateExportDocumentsApproved",
  "dateExportDocumentsSubmitted",
  "dateVesselDeparture",
  "dateBlDrafted",
  "dateBlApproved",
  "dateAllDocumentsFinalized",
  "dateAllOriginalsAvailableOnline",
  "dateFullPaymentReceived",
  "dateOriginalDocumentsSent",
  "dateOriginalDocumentsDelivered",
  "dateArrivedPod",
];

const OrderEvents: FC<{ orderId: number }> = ({ orderId }) => {
  const order = useSalesOrder(orderId);
  const delivery = order.data;
  const [showLogs, setShowLogs] = useState(false);

  const onShowHideLogs = () => {
    setShowLogs(!showLogs);
  };

  const sortedEvents = useMemo(() => {
    if (delivery === undefined) return [];

    return EVENT_LIST.filter((event) => !!delivery[event]).sort((a, b) => {
      if (delivery[a] > delivery[b]) return -1;
      if (delivery[b] > delivery[a]) return 1;
      return 0;
    });
  }, [delivery]);
  const eventsArray = showLogs ? sortedEvents : sortedEvents.slice(0, 6);

  if (eventsArray.length === 0) return null;

  return (
    <div className="flex-col p-lg text-left">
      <div className="text-xl color-black-1">
        <LC id="title.event.log" />
      </div>
      <Events delivery={delivery} events={eventsArray} />
      {eventsArray.length > 6 && (
        <div
          className="cursor-pointer flex-row align-center color-blue-1 mt-md ml-lg"
          onClick={onShowHideLogs}
        >
          <LC
            id={showLogs ? "title.view.less.events" : "title.view.all.events"}
          />
          {showLogs && <TbChevronUp size={16} />}
          {!showLogs && <TbChevronDown size={16} />}
        </div>
      )}
    </div>
  );
};

export default OrderEvents;

const Events: FC<{
  delivery: SalesOrderView;
  events: EventType[];
}> = ({ delivery, events }) => (
  <table className="table table-border-collapse w-full overflow-hidden mt-lg">
    <tbody>
      <tr className="text-semibold color-text-3 border-b-1 border-black-10 upper-case">
        <th className=" px-sm py-xs">
          <LC id="title.date" />
        </th>
        <th className="px-sm py-xs">
          <LC id="title.events" />
        </th>
      </tr>
      {events.map((event, index) => {
        const date = dayjs(delivery[event]).format("DD MMM YYYY");
        return (
          <tr key={index}>
            <td className="w-0 p-sm py-xs text-no-wrap">{date}</td>
            <td className="p-sm py-xs text-no-wrap">
              <LC id={`delivery.event.${event}` as LocalizedMessageKey} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);
