import { FC } from "react";
import { AttachmentRef, LandingPage } from "~/API";
import { UploadAttachments } from "~/Utils/Forms";
import {
  Button,
  ButtonFileUpload,
  FormInput,
  FormRadioGroup,
  FormSwitch,
} from "~/Components/UI";
import { LandingPagePreview } from "./LandingPagePreview";
import { LC } from "~/Locales";
import { useFormData, useMutate } from "~/Hooks";
import { z } from "zod";
import { zodMsg } from "~/Utils";
import {useUpdateLandingPage} from "~/Data/Admin/LandingPages";
import {trackEvent} from "~/Services/Tracking";

const editFormValidation = z.object({
  title: z.string().min(1, zodMsg("error.non.empty", ["Title"])),
});

const EditMode: FC<{
  attachments: any;
  editingFalse: () => void;
  landingPage: LandingPage;
  loadAttachments: () => void;
}> = (props) => {
  const editForm = useFormData(props.landingPage, { validation: editFormValidation });

  const save = useUpdateLandingPage();

  const onSaveClicked = () => {
    if (editForm.isValid()) save
      .call(editForm.data)
      .then(props.editingFalse)
      .then(() => trackEvent("Landing Page Updated"));
  };

  const upload = useMutate((files: File[]) =>
    UploadAttachments(files, AttachmentRef.LANDING_PAGE, props.landingPage.id),
  );

  const onUpload = async (files: File[]) => {
    await upload.mutate(files);
    props.loadAttachments();
  };

  return (
    <div className="desktop-w-min-11xl flex-col gap-md">
      <div className="ml-sm text-bold">Preview:</div>
      <LandingPagePreview
        attachment={props.attachments[editForm.data.selectedImage]}
        title={editForm.data.title}
      />

      <FormInput<LandingPage>
        id="title"
        formData={editForm}
        placeholder="title.title"
        label="title.title"
      />

      <div className="mb-md">
        <div className="text-bold flex-row justify-between align-baseline mb-xxs">
          <LC id="title.selected.image" />:
          <ButtonFileUpload
            onSubmitted={onUpload}
            acceptedFileTypes={["image/*"]}
            label="title.upload"
            isDisabled={save.isMutating || upload.loading}
          />
        </div>

        <FormRadioGroup<LandingPage, number>
          formData={editForm}
          id="selectedImage"
          data={props.attachments.map((_a: any, i: number) => i)}
          getLabel={(item) => props.attachments[item].name}
        />
      </div>

      <FormSwitch<LandingPage>
        label="title.active"
        id="active"
        formData={editForm}
      />

      <div className="flex-row justify-end gap-sm mt-xl">
        <Button
          onClicked={onSaveClicked}
          label="title.save"
          loading={save.isMutating}
          disabled={upload.loading}
        />
        <Button
          label="title.cancel"
          color="gray"
          onClicked={props.editingFalse}
          disabled={save.isMutating || upload.loading}
        />
      </div>
    </div>
  );
};

export default EditMode;
