import { LocalizedMessage } from "~/Locales";
import Button from "~/Components/Old/Button";
import {FC, MouseEventHandler} from "react";
import {TbCircleArrowUpFilled} from "react-icons/tb";
import Vars from "~/Styles/Vars";
import {useAppSelector} from "~/StoreTypes";

import './Banner.scss';

const UpdateBanner: FC = () => {
  const { newVersion, ignoreVersion, currentVersion } = useAppSelector(state => state.updateBanner);

  const refresh: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const show = !!newVersion && newVersion !== currentVersion && newVersion !== ignoreVersion;
  return <div className={`banner ${show ? 'show' : ''}`}>
    <div className="flex-row gap-sm color-blue-9 align-center text-bold">
      <TbCircleArrowUpFilled size={Vars.size.lg} />
      <LocalizedMessage id={'app.new.version'}/>
    </div>

    <Button view="gray" onClick={refresh}>
      <LocalizedMessage id={'app.update'}/>
    </Button>
  </div>
}

export default UpdateBanner;