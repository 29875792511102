import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, ButtonFileUpload, For, Input } from "~/Components/UI";
import {TbCircleX, TbCircleXFilled, TbFile, TbLink, TbSend} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { useFormData } from "~/Hooks";
import { useChatCreateMessage } from "~/Data/Chats";
import { Attachment, NewMessage, UserAccountPublicView } from "~/API";
import { useCurrentUser } from "~/Reducers/User";
import vars from "~/Styles/Vars";
import { cn } from "~/Utils";
import { UserCard } from "~/Components/Users/UserCard";
import { useAsyncAttachHandler, useDetachHandler } from "~/Utils/Forms";
import {trackEvent} from "~/Services/Tracking";

export const ChatInput: FC<{
  chatId: number;
  participants: UserAccountPublicView[];
}> = (props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const location = useLocation();
  const currentUser = useCurrentUser();
  const createMsg = useChatCreateMessage(props.chatId, currentUser);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef, location.pathname]);

  const msgForm = useFormData<NewMessage>({
    text: "",
    attachments: [],
    mentionedUserIds: [],
  });

  // auto-size input, max of 5 lines show before scrolling
  useEffect(() => {
    inputRef.current.style.height = "0px";
    const scrollHeight = inputRef.current.scrollHeight;
    const minHeight = vars.lineHeight.md;
    const maxHeight = vars.lineHeight.md * 5;
    const lineHeight =
      Math.min(maxHeight, Math.max(minHeight, scrollHeight)) - 4; // scrollHeight adds 4px for some reason
    inputRef.current.style.height = lineHeight + "px";
  }, [msgForm.data.text]);

  const [cursorPosition, setCursorPosition] = useState(0);
  const [mentionCursorPosition, setMentionCursorPosition] = useState(0);
  const participantsToShow = useFilterParticipantsToShow(
    inputRef,
    cursorPosition,
    props.participants,
  );

  const onInputChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCursorPosition(event.target.selectionEnd);
    msgForm.onDataChange("text", event.target.value);
    setMentionCursorPosition(0);
  };

  const onMentionUser = (selectedUser: UserAccountPublicView) => {
    const { newText, newCursorPosition } = replaceFilterTextWithUsername(
      `${selectedUser.name} ${selectedUser.surname}`,
      inputRef.current.value,
      cursorPosition,
    );
    msgForm.onDataChange("mentionedUserIds", [
      ...msgForm.data.mentionedUserIds,
      selectedUser.id,
    ]);
    setCursorPosition(newCursorPosition);
    msgForm.onDataChange("text", newText);
    inputRef.current.value = newText;
    inputRef.current.selectionStart = newCursorPosition;
    inputRef.current.selectionEnd = newCursorPosition;
    inputRef.current.focus();
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    // submit on ctrl + enter
    if (event.ctrlKey && event.key === "Enter") {
      event.preventDefault();
      onSendClicked();
    }

    if (!!participantsToShow.length) {
      if (event.key === "Enter") {
        event.preventDefault();
        onMentionUser(participantsToShow[mentionCursorPosition]);
      } else if (event.key === "ArrowUp") {
        //Up arrow
        event.preventDefault();
        let newIndex = mentionCursorPosition - 1;
        if (newIndex < 0) newIndex = participantsToShow.length - 1;
        setMentionCursorPosition(newIndex);
      } else if (event.key === "ArrowDown") {
        //Down arrow
        event.preventDefault();
        let newIndex = mentionCursorPosition + 1;
        if (newIndex >= participantsToShow.length) newIndex = 0;
        setMentionCursorPosition(newIndex);
      }
    }
  };

  const onSendClicked = () => {
    const lowerCaseMessage = msgForm.data.text.toLocaleLowerCase();
    const mentionedUserIds = msgForm.data.mentionedUserIds.filter((it) => {
      const userName = props.participants.find((p) => p.id == it);
      return (
        !!userName &&
        lowerCaseMessage.includes(
          "@" + `${userName.name} ${userName.surname}`.toLocaleLowerCase(),
        )
      );
    });

    const mentionedIdsToSend = [...new Set(mentionedUserIds)];
    let messageWithUpdatedMentions = msgForm.data.text;

    mentionedIdsToSend.forEach((id) => {
      const userName = props.participants.find((p) => p.id == id);
      messageWithUpdatedMentions = messageWithUpdatedMentions.replaceAll(
        `@${userName.name} ${userName.surname}`,
        `@${id}`,
      );
    });

    createMsg.call({
      mentionedUserIds: mentionedIdsToSend,
      text: messageWithUpdatedMentions,
      attachments: msgForm.data.attachments,
    });
    msgForm.clear();
    trackEvent("Chat Message Sent");
  };

  const setAttachments = (attachments: Attachment[]) =>
    msgForm.onDataChange("attachments", attachments);
  const attachHandler = useAsyncAttachHandler(
    msgForm.data.attachments,
    setAttachments,
    () => ({
      restriction: "REFERENCE",
    }),
  );
  const detachHandler = useDetachHandler(
    msgForm.data.attachments,
    setAttachments,
  );

  return (
    <div className="sticky bottom-0 flex-0 flex-row flex-wrap gap-sm bg-black-12 pt-sm pb-xxs px-xxs align-end">
      <ButtonFileUpload
        acceptedFileTypes={["*"]}
        multiple
        onSubmitted={attachHandler}
        className="p-xs"
        icon={<TbLink size={20} />}
      />
      <div className="flex relative">
        <div
          className={cn(
            "absolute bottom-100p mb-xxs bg-black-12 rounded-xxs shadow-lg border-1 border-black-9 px-xs py-xxs flex-col gap-xxs",
            participantsToShow.length == 0 && "hide",
          )}
        >
          <For each={participantsToShow}>
            {(p, i) => (
              <button
                onClick={() => onMentionUser(p)}
                key={p.id}
                className={cn(
                  "border-none focus-bg-black-10 hover-bg-black-10 px-xs py-xxs rounded-xxs cursor-pointer",
                  i == mentionCursorPosition ? "bg-black-10" : "bg-none",
                )}
              >
                <UserCard id={p.id} />
              </button>
            )}
          </For>
        </div>
        <div className="flex flex-col">
          <div className="flex-row w-full flex-wrap gap-sm">
            <For each={msgForm.data.attachments}>
              {(attach) => (
                <div
                  className="relative flex-row w-max-6xl align-center gap-xxs my-xs p-xs rounded-xxs bg-black-10"
                  key={attach.id}
                >
                  <div className="flex-row align-center">
                    <TbFile size={16} />
                  </div>
                  <span className="text-truncate">{attach.name}</span>
                  <Button
                    leftIcon={<TbCircleXFilled size={16} />}
                    color="gray"
                    variant="text"
                    className="absolute -top-sm -right-sm"
                    onClicked={() => detachHandler(attach)}
                  />
                </div>
              )}
            </For>
          </div>
          <Input
            ref={inputRef}
            id="text"
            onChange={onInputChange}
            value={msgForm.data.text}
            containerClassName="flex"
            placeholder="phrase.chat.input"
            onKeyDown={onKeyDown}
            multiline
            data-cy={"chatTextBox"}
          />
        </div>
      </div>
      <Button
        onClicked={onSendClicked}
        loading={createMsg.isMutating}
        leftIcon={<TbSend size={20} />}
        className="p-xs"
        disabled={msgForm.data.text.length == 0 && msgForm.data.attachments.length === 0}
        data-cy={"sendButton"}
      />
    </div>
  );
};

const getMentionFilterText = (
  textAreaRef: MutableRefObject<HTMLTextAreaElement>,
  cursorPosition: number,
) => {
  const position = Math.min(
    textAreaRef.current?.value?.length || 0,
    cursorPosition,
  );
  if (!textAreaRef.current?.value?.length || position === 0) {
    return "";
  }
  const subString = textAreaRef.current.value.slice(0, position);
  const indexOfChar = subString.indexOf("@");
  if (indexOfChar === -1) {
    return "";
  }
  const parts = subString.split("@");
  return parts[parts.length - 1];
};

const useFilterParticipantsToShow = (
  textAreaRef: MutableRefObject<HTMLTextAreaElement>,
  cursorPosition: number,
  participants: UserAccountPublicView[],
) => {
  const currentUser = useCurrentUser();
  const position = Math.min(
    textAreaRef.current?.value?.length || 0,
    cursorPosition,
  );
  if (position === 0) return [];
  if (textAreaRef.current.value?.[position - 1] === "@")
    return participants.filter((p) => p.id !== currentUser.id);

  const filterText = getMentionFilterText(textAreaRef, cursorPosition);

  return participants
    .filter((p) => p.id !== currentUser.id)
    .filter((it) => {
      const userName = `${it.name} ${it.surname}`.toLocaleLowerCase();
      return (
        userName.includes(filterText.toLocaleLowerCase()) && !!filterText.length
      );
    });
};

const replaceFilterTextWithUsername = (
  userName: string,
  text: string,
  cursorPosition: number,
) => {
  const subString = text.slice(0, cursorPosition);
  const partsArray = subString.split("@");
  let newCursorPosition = cursorPosition;
  let newText = text;

  if (partsArray.length > 1) {
    const newPartsArray = text.split("@").slice(0, partsArray.length - 1);

    const firstSubString = newPartsArray.join("@") + "@" + userName + " ";
    newCursorPosition = firstSubString.length + 1;
    const secondSubString = text.slice(cursorPosition);
    newText = firstSubString + secondSubString;
  }

  return { newText, newCursorPosition };
};
