import "~/Styles/base.scss";
import "~/Styles/basePage.scss";
import "~/Styles/baseFilter.scss";
import "~/Styles/margins.scss";
import "~/Styles/padding.scss";
import "~/Styles/text.scss";
import "~/Styles/layout.scss";
import "~/Styles/colors.scss";
import "~/Styles/animations.scss";
import "~/Styles/borders.scss";

import React, { FC, useEffect } from "react";
import {
  getCurrentUser,
  hasAnyOfInternalPermissions,
  useCurrentUser,
} from "./Reducers/User";
import MUITheme from "./Styles/MUITheme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UpdateBanner from "~/Components/Banners/UpdateBanner";
import SilentModeBanner from "~/Components/Banners/SilentModeBanner";
import LoggedInAsAnotherUserBanner from "~/Components/Banners/LoggedInAsAnotherUserBanner";
import IncomingVideoCallDialog from "~/Components/Old/VideoCall/IncomingVideoCallDialog";
import BuildInfoLabel from "~/Components/Banners/BuildInfoLabel";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import SignInPage from "./Pages/LoginPage/SignInPage";
import ForgotPasswordPage from "./Pages/LoginPage/ForgotPasswordPage";
import SetNewPasswordPage from "./Pages/LoginPage/SetNewPasswordPage";
import PasswordResetExpiredPage from "./Pages/LoginPage/ErrorPages/PasswordResetExpiredPage";
import PasswordResetAlreadyPage from "./Pages/LoginPage/ErrorPages/PasswordResetAlreadyPage";
import PasswordResetInvalidPage from "./Pages/LoginPage/ErrorPages/PasswordResetInvalidPage";
import {
  addOnCostsRootPath,
  companiesRootPath,
  dictionaryRootPath,
  dutyCostsRootPath,
  lengthsRootPath,
  locationsRootPath,
  myProductsRootPath,
  offersRootPath,
  ordersPathCompleted,
  ordersPathOngoing,
  ordersRootPath,
  passwordRequirementsResetPath,
  portsRootPath,
  productCatalogueRootPath,
  productGroupsRootPath,
  productsRootPath,
  quotesRootPath,
  randomLengthsRootPath,
  requestedOffersRootPath,
  requestsRootPath,
  seaFreightCostsImportRootPath,
  seaFreightCostsRootPath,
  usersRootPath,
} from "./Services/Routes";
import ResetPasswordForRequirementsPage from "./Pages/LoginPage/ResetPasswordForRequirementsPage";
import CompleteRegistrationPage from "./Pages/LoginPage/CompleteRegistrationPage";
import RegistrationExpiredPage from "./Pages/LoginPage/ErrorPages/RegistrationExpiredPage";
import RegistrationAlreadyPage from "./Pages/LoginPage/ErrorPages/RegistrationAlreadyPage";
import RegistrationInvalidPage from "./Pages/LoginPage/ErrorPages/RegistrationInvalidPage";
import {
  ProductDetailsPage,
  ProductNewPage,
} from "./Pages/Products/ProductsPage/DetailsPage/ProductDetailsPage";
import LeftMenu from "~/Components/Layout/LeftMenu/LeftMenu";
import { ErrorBoundary } from "@sentry/react";
import ErrorPage from "./Pages/ErrorPages/ErrorPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import ProductsRootPage from "./Pages/Products/ProductsRootPage";
import ProductGroupDetailsPage from "./Pages/Products/GroupsPage/DetailsPage/ProductGroupDetailsPage";
import { CataloguePage } from "./Pages/Products/CataloguePage/CataloguePage";
import { MyProductsPage } from "./Pages/Products/MyProductsPage/MyProductsPage";
import { ProductCatalogueDetailsPage } from "~/Pages/Products/CataloguePage/DetailsPage/ProductCatalogueDetailsPage";
import OrdersPage from "./Pages/Orders/OrdersPage";
import OrderDetailsPage from "./Pages/Orders/DetailsPage/OrderDetailsPage";
import OffersDetailsPage from "./Pages/Offers/DetailsPage/OfferDetailsPage";
import OffersPage from "./Pages/Offers/OffersPage";
import RequestedOffersPage from "./Pages/Requests/OffersPage/RequestedOffersPage";
import QuoteDetailsPage from "./Pages/Quotes/DetailsPage/QuoteDetailsPage";
import QuotesPage from "./Pages/Quotes/QuotesPage";
import RequestDetailsPage from "./Pages/Requests/DetailsPage/RequestDetailsPage";
import RequestsPage from "./Pages/Requests/RequestsPage";
import CompanyRootPage from "./Pages/Companies/CompanyRootPage";
import CompanyDetailsPage from "./Pages/Companies/CompanyDetailsPage/CompanyDetailsPage";
import LocationDetailsPage from "./Pages/Companies/LocationDetailsPage/LocationDetailsPage";
import UsersPage from "~/Pages/Users/UsersPage";
import NewUserPage from "~/Pages/Users/DetailsPage/NewUserPage";
import UserDetailsPage from "~/Pages/Users/DetailsPage/UserDetailsPage";
import LogisticsPage from "./Pages/Logistics/LogisticsPage";
import UserGroupsPage from "./Pages/Admin/UserGroups/UserGroupsPage";
import ChatsPage from "./Pages/Chats/ChatsPage";
import EmailsPage from "./Pages/Admin/Emails/EmailsPage";
import LandingPagesPage from "./Pages/Admin/LandingPages/LandingPagesPage";
import JobSchedulerPage from "./Pages/Admin/JobSchedulerPage";
import MyProfilePage from "./Pages/MyProfile/MyProfilePage";
import NoMatchPage from "./Pages/ErrorPages/NoMatchPage";
import NoServerPage from "./Pages/ErrorPages/NoServerPage";
import Environment from "./Services/Environment";
import { initTracking } from "./Services/Tracking";
import { useAppDispatch } from "./StoreTypes";
import { setRouterHasHistory } from "./Reducers/Router";
import { Toaster } from "react-hot-toast";
import { logoutCurrentUser } from "~/Data/CurrentUser";
import { MetricsPage } from "~/Pages/Metrics/MetricsPage";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import { FeatureFlagsPage } from "~/Pages/Admin/FeatureFlags/FeatureFlagsPage";
import { loadEnabledFlags } from "~/Reducers/FeatureFlags";
import ImportLogisticsCostsTab from "~/Pages/Logistics/ImportLogisticsCosts/ImportLogisticsCostsTab";

const cookiesHasToken = () => {
  const cookies = document.cookie.split(";");
  const hasToken = !!cookies?.find?.((item) => {
    const trimmed = item.trim();
    return trimmed.startsWith("token=") && trimmed.length > 6;
  });
  return hasToken || Environment.isLocalhost;
};

const publicRoutes = [
  "/sign-in",
  "/forgot-password",
  "/set-new-password",
  "/password-reset-expired",
  "/password-reset-already-accepted",
  "/password-reset-invalid",
  passwordRequirementsResetPath,
  "/registration-expired",
  "/registration-already-accepted",
  "/registration-invalid",
];

const App: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const location = useLocation();
  const [initialLocation, setInitialLocation] = React.useState("");

  const currentUser = useCurrentUser();
  const userLoading = currentUser.id === undefined;
  const userError = currentUser.loginAsUserFailed;
  const userSignedIn = !!currentUser.id;
  const isInternalUser = hasAnyOfInternalPermissions(currentUser?.permissions);

  useEffect(() => {
    dispatch(loadEnabledFlags);

    // require user to be signed in for non-public routes
    if (!publicRoutes.includes(location.pathname)) {
      if (!cookiesHasToken()) {
        history.push("/sign-in");
      } else {
        dispatch(
          getCurrentUser(
            (user) => {
              initTracking(user.id, user);
            },
            (_, code) => {
              if (code === 403) {
                logoutCurrentUser(dispatch);
                history.push("/sign-in");
              }
            },
          ),
        );
      }
    }

    const unRegCallback = history.listen((location, action) => {
      dispatch(setRouterHasHistory(location.pathname, action));
    });

    return () => {
      unRegCallback();
    };
  }, []);

  useEffect(() => {
    // save after every route change so logging out and in
    // will take you to the same page again
    if (!publicRoutes.includes(location.pathname)) {
      setInitialLocation(location.pathname);
    }
  }, [location.pathname]);

  const RerouteIndexToDashboard: FC = () => {
    if (userSignedIn) history.push("/dashboard");

    return <></>;
  };

  return (
    <div className="app" id={"mainTBElementInDOM"}>
      <Toaster position="top-right" toastOptions={{ duration: 5_000 }} />
      <MUITheme>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={{
            fieldMonthPlaceholder: (params) =>
              params.contentType === "digit" ? "MM" : params.format,
          }}
        >
          <UpdateBanner />
          <SilentModeBanner />
          <LoggedInAsAnotherUserBanner />
          <IncomingVideoCallDialog />
          <BuildInfoLabel />

          <Switch>
            <Route path="/sign-in">
              <SignInPage initialLocation={initialLocation} />
            </Route>

            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route path="/set-new-password">
              <SetNewPasswordPage />
            </Route>
            <Route path="/password-reset-expired">
              <PasswordResetExpiredPage />
            </Route>
            <Route path="/password-reset-already-accepted">
              <PasswordResetAlreadyPage />
            </Route>
            <Route path="/password-reset-invalid">
              <PasswordResetInvalidPage />
            </Route>

            <Route path={passwordRequirementsResetPath}>
              <ResetPasswordForRequirementsPage />
            </Route>
            <Route path="/complete-registration">
              <CompleteRegistrationPage />
            </Route>
            <Route path="/registration-expired">
              <RegistrationExpiredPage />
            </Route>
            <Route path="/registration-already-accepted">
              <RegistrationAlreadyPage />
            </Route>
            <Route path="/registration-invalid">
              <RegistrationInvalidPage />
            </Route>

            <Route path="/company/:companyAlias/offer/:id">
              <ProductDetailsPage />
            </Route>
            <Route path="*">
              <div className={"app-main"}>
                {userSignedIn && (
                  <>
                    <LeftMenu />
                    <ErrorBoundary
                      fallback={({ eventId }) => (
                        <ErrorPage eventId={eventId} />
                      )}
                    >
                      <Switch>
                        <Route path="/dashboard" exact>
                          {userSignedIn && <DashboardPage />}
                        </Route>

                        {/* Products */}
                        <Route path={productsRootPath + "new/:id"}>
                          <ProductNewPage />
                        </Route>
                        <Route path={productsRootPath + ":id"}>
                          <ProductDetailsPage />
                        </Route>
                        <Route path={productsRootPath}>
                          <ProductsRootPage />
                        </Route>

                        <Route path={productGroupsRootPath + ":id/:tabType"}>
                          <ProductGroupDetailsPage />
                        </Route>
                        <Route path={productGroupsRootPath + ":id"}>
                          <ProductGroupDetailsPage />
                        </Route>
                        <Route path={productGroupsRootPath}>
                          <ProductsRootPage />
                        </Route>

                        <Route path={productCatalogueRootPath + ":id"}>
                          <ProductCatalogueDetailsPage />
                        </Route>
                        <Route path={productCatalogueRootPath}>
                          <CataloguePage />
                        </Route>

                        <Route path={dictionaryRootPath}>
                          <ProductsRootPage />
                        </Route>
                        <Route path={lengthsRootPath}>
                          <ProductsRootPage />
                        </Route>
                        <Route path={randomLengthsRootPath}>
                          <ProductsRootPage />
                        </Route>

                        <Route path={myProductsRootPath}>
                          <MyProductsPage />
                        </Route>

                        {/* Orders */}
                        <Route
                          path={ordersRootPath + ":type/" + ordersPathOngoing}
                        >
                          <OrdersPage />
                        </Route>
                        <Route
                          path={ordersRootPath + ":type/" + ordersPathCompleted}
                        >
                          <OrdersPage />
                        </Route>
                        <Route path={ordersRootPath + ":type/:id"}>
                          <OrderDetailsPage />
                        </Route>
                        <Route path={ordersRootPath + ":type"}>
                          <OrdersPage />
                        </Route>
                        <Route path={ordersRootPath}>
                          <OrdersPage />
                        </Route>

                        {/* Offers */}
                        <Route path={`${offersRootPath}:id`}>
                          <OffersDetailsPage />
                        </Route>
                        <Route path={offersRootPath}>{<OffersPage />}</Route>

                        {/* Requested Offers aka Offers (RFO status)*/}
                        <Route path={`${requestedOffersRootPath}:id`}>
                          <OffersDetailsPage />
                        </Route>
                        <Route path={requestedOffersRootPath}>
                          {<RequestedOffersPage />}
                        </Route>

                        {/* Quotes */}
                        {isInternalUser && (
                          <Route path={`${quotesRootPath}:id/:edit`}>
                            <QuoteDetailsPage />
                          </Route>
                        )}
                        <Route path={`${quotesRootPath}:id`}>
                          <QuoteDetailsPage />
                        </Route>
                        <Route path={quotesRootPath}>
                          <QuotesPage />
                        </Route>

                        {/* Requests */}
                        <Route path={`${requestsRootPath}:id`}>
                          <RequestDetailsPage />
                        </Route>
                        <Route path={requestsRootPath}>
                          <RequestsPage />
                        </Route>

                        <Route path={[
                          companiesRootPath + "customers",
                          companiesRootPath + "customers/locations",
                          companiesRootPath
                        ]} exact>
                          <CompanyRootPage type="customers" />
                        </Route>
                        <Route path={[
                          companiesRootPath + "vendors",
                          companiesRootPath + "vendors/locations",
                        ]} exact>
                          <CompanyRootPage type="vendors" />
                        </Route>
                        <Route path={companiesRootPath + ":id"}>
                          <CompanyDetailsPage />
                        </Route>
                        <Route path={locationsRootPath + ":id"}>
                          <LocationDetailsPage />
                        </Route>
                        <Route path={usersRootPath + "new"}>
                          <NewUserPage />
                        </Route>
                        <Route path={usersRootPath + ":id"}>
                          <UserDetailsPage />
                        </Route>
                        <Route path={usersRootPath}>
                          <UsersPage />
                        </Route>

                        <Route path={[
                          portsRootPath,
                          seaFreightCostsRootPath,
                          addOnCostsRootPath,
                          dutyCostsRootPath,
                        ]}>
                          <LogisticsPage />
                        </Route>
                        <Route path={seaFreightCostsImportRootPath}>
                          <ImportLogisticsCostsTab />
                        </Route>

                        <Route path="/chats">
                          <ChatsPage />
                        </Route>
                        <Route path="/profile">
                          <MyProfilePage />
                        </Route>

                        <Route path="/metrics">
                          <MetricsPage />
                        </Route>

                        <Route path="/user-groups">
                          <UserGroupsPage />
                        </Route>
                        <Route path="/emails">
                          <EmailsPage />
                        </Route>
                        <Route path="/landing-pages">
                          <LandingPagesPage />
                        </Route>
                        <Route path="/job-scheduler">
                          <JobSchedulerPage />
                        </Route>
                        <Route path="/feature-flags">
                          <FeatureFlagsPage />
                        </Route>

                        <Route exact path="/">
                          <RerouteIndexToDashboard />
                        </Route>
                        <Route path="*">
                          <NoMatchPage />
                        </Route>
                      </Switch>
                    </ErrorBoundary>
                  </>
                )}
                {!userSignedIn && (
                  <>
                    {userLoading && !userError && (
                      <>
                        <LeftMenu />
                        <LoadingPage />
                      </>
                    )}
                    {userError && <NoServerPage />}
                  </>
                )}
              </div>
            </Route>
          </Switch>
        </LocalizationProvider>
      </MUITheme>
    </div>
  );
};

export default App;
