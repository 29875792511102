import {FC, ReactNode, useState} from "react";
import {Button, Dialog, For} from "~/Components/UI";
import {useAttachment, useAttachmentFull, useAttachmentThumbnail} from "~/Data/Attachments";
import {cn} from "~/Utils";
import {TbDownload} from "react-icons/tb";
import API from "~/API";
import {isImage} from "~/Utils/Attachments";

export const AttachmentGallery: FC<{
  trigger: ReactNode;
  defaultAttachment: number;
  attachments: number[];
}> = (props) => {
  const [selectedAttachment, setSelectedAttachment] = useState(props.defaultAttachment);

  const onOpenChange = () => {
    setSelectedAttachment(props.defaultAttachment);
  }

  return (
    <Dialog trigger={props.trigger} onOpenChange={onOpenChange}>
      <GalleryBody
        defaultAttachment={props.defaultAttachment}
        attachments={props.attachments}
        selectedAttachment={selectedAttachment}
        setSelectedAttachment={setSelectedAttachment}
      />
    </Dialog>
  )
}

const GalleryBody: FC<{
  defaultAttachment: number;
  attachments: number[];
  selectedAttachment: number;
  setSelectedAttachment: (selectedAttachment: number) => void;
}> = (props) => {
  const attach = useAttachmentFull(props.selectedAttachment);
  const downloadUrl = API.urlAttachmentDownload(props.selectedAttachment);

  return (
    <div className="w-90vw h-max-90vh flex-col gap-sm">
      <div className="flex h-min-0 flex-row relative">
        <img src={attach.data} className="flex h-min-0 fit-contain rounded-xxs"/>
        <Button
          leftIcon={<TbDownload size={24} />}
          className="absolute bottom-0 right-0 m-md"
          label="title.download"
          asChild
        >
          <a href={downloadUrl} download="filename" target="_blank" />
        </Button>
      </div>
      <div className="flex-row flex-wrap gap-sm justify-center">
        <For each={props.attachments}>
          {(a) => (
            <GallerySelector
              attachment={a}
              active={props.selectedAttachment == a}
              selectedAttachment={props.selectedAttachment}
              setSelectedAttachment={props.setSelectedAttachment}
            />
          )}
        </For>
      </div>
    </div>
  );
};

const GallerySelector: FC<{
  attachment: number;
  active: boolean;
  selectedAttachment: number;
  setSelectedAttachment: (selectedAttachment: number) => void;
}> = (props) => {
  const attachThumb = useAttachmentThumbnail(props.attachment);
  const attach = useAttachment(props.attachment);

  const onClick = () => {
    props.setSelectedAttachment(props.attachment);
  }

  if(!isImage(attach.data?.name))
    return <></>

  return (
    <img
      onClick={onClick}
      src={attachThumb.data}
      className={cn("w-4xl h-4xl rounded-xxs fit-cover cursor-pointer", props.active && "outline-4 outline-blue-1")}
    />
  )
};