import { Fragment, memo, useMemo } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import {
  getPathForPO,
  quotesRootPath,
  requestsRootPath,
} from "~/Services/Routes";
import { useQuoteAsReferenceText } from "~/Reducers/Quotes";
import { useRequestAsReferenceText } from "~/Reducers/RequestsForQuote";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  purchaseOrderAsReferenceText,
} from "~/Data/Orders/PurchaseOrders";
import Button from "~/Components/Old/Button";
import {useIsInternalCustomer} from "~/Reducers/User";

const SHORT_LIST_LENGTH = 4;

const OfferReference = ({
  requestForQuoteId,
  purchaseOrders = [],
  quotes = [],
  showDash = true,
  simple = false,
}) => {
  const [showMore, toggleShowMore] = useToggle(false);

  const requestCount = !!requestForQuoteId ? 1 : 0;
  const shouldShowMoreButton =
    requestCount + quotes.length + purchaseOrders.length > SHORT_LIST_LENGTH;

  const quotesToShow = useMemo(() => {
    if (showMore || !shouldShowMoreButton) return quotes;
    return quotes.slice(0, SHORT_LIST_LENGTH - requestCount);
  }, [shouldShowMoreButton, quotes, showMore, requestForQuoteId]);

  const ordersToShow = useMemo(() => {
    if (showMore || !shouldShowMoreButton) return purchaseOrders;
    return purchaseOrders.slice(
      0,
      Math.max(SHORT_LIST_LENGTH - requestCount - quotesToShow.length, 0),
    );
  }, [
    shouldShowMoreButton,
    quotes,
    purchaseOrders,
    showMore,
    requestForQuoteId,
  ]);

  if (!requestForQuoteId && !quotes.length && !purchaseOrders.length) {
    return !!showDash ? <>{"-"}</> : null;
  }

  if (simple)
    return (
      <>
        {!!requestForQuoteId && (
          <a
            href={`${requestsRootPath}${requestForQuoteId}`}
            target="_blank"
            rel="noreferrer noopener"
            onClick={stopPropagation}
          >
            {`RQ-${requestForQuoteId}`}
          </a>
        )}
        {quotes.map((quoteId, index) => (
          <Fragment key={quoteId}>
            {(index !== 0 || !!requestForQuoteId) && <>{","}&nbsp;</>}
            <a
              href={`${quotesRootPath}${quoteId}`}
              target="_blank"
              rel="noreferrer noopener"
              onClick={stopPropagation}
            >
              {`Q-${quoteId}`}
            </a>
          </Fragment>
        ))}
        {purchaseOrders.map((poId, index) => (
          <Fragment key={poId}>
            {(index !== 0 || !!requestForQuoteId || quotes.length !== 0) && (
              <>{","}&nbsp;</>
            )}
            <PurchaseOrderReferenceTextSimple purchaseOrderId={poId} />
          </Fragment>
        ))}
      </>
    );

  return (
    <div className={"flex-col"}>
      {!!requestForQuoteId && (
        <RequestReferenceText requestId={requestForQuoteId} />
      )}
      {quotesToShow.map((quoteId, index) => (
        <QuoteReferenceText
          key={quoteId}
          quoteId={quoteId}
          addBR={index !== 0 || !!requestForQuoteId}
        />
      ))}
      {ordersToShow.map((poId, index) => (
        <PurchaseOrderReferenceText
          key={poId}
          purchaseOrderId={poId}
          addBR={
            index !== 0 || !!requestForQuoteId || quotesToShow.length !== 0
          }
        />
      ))}
      {shouldShowMoreButton && (
        <div>
          <Button
            view={"text"}
            onClick={toggleShowMore}
            className={"px-0 mt-xs"}
          >
            <LocalizedMessage
              id={showMore ? "title.show.less" : "title.show.more"}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default OfferReference;

const stopPropagation = (event: any) => event.stopPropagation();

const RequestReferenceText = memo<{
  requestId: number;
}>(({ requestId }) => {
  const text = useRequestAsReferenceText(requestId);
  return (
    <a
      href={`${requestsRootPath}${requestId}`}
      target="_blank"
      rel="noreferrer noopener"
      onClick={stopPropagation}
    >
      {text}
    </a>
  );
});

const QuoteReferenceText = memo<{
  quoteId: number;
  addBR?: boolean;
}>(({ quoteId, addBR }) => {
  const text = useQuoteAsReferenceText(quoteId);
  return (
    <>
      {addBR && <br />}
      <a
        href={`${quotesRootPath}${quoteId}`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {text}
      </a>
    </>
  );
});

const PurchaseOrderReferenceText = memo<{
  purchaseOrderId: number;
  addBR?: boolean;
}>(({ purchaseOrderId, addBR }) => {
  const internalCustomer = useIsInternalCustomer();
  const po = usePurchaseOrder(purchaseOrderId, internalCustomer);
  const text = purchaseOrderAsReferenceText(po.data);
  return (
    <>
      {addBR && <br />}
      <a
        href={getPathForPO(purchaseOrderId)}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {text}
      </a>
    </>
  );
});

const PurchaseOrderReferenceTextSimple = memo<{
  purchaseOrderId: number;
}>(({ purchaseOrderId }) => {
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(purchaseOrderId, internalCustomer);

  if (order.isLoading) return <></>;

  return (
    <a
      href={getPathForPO(purchaseOrderId)}
      target="_blank"
      rel="noreferrer noopener"
      onClick={stopPropagation}
    >
      {`PO-${order.data.number}`}
    </a>
  );
});
