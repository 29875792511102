import { FormCombobox, FormComboboxProps } from "~/Components/UI";

const rlTypesLumber = [
  "SOUTHERN PINE, KILN DRIED EAST PT",
  "SOUTHERN PINE, KILN DRIED EAST #2 PT",
  "SOUTHERN PINE, KILN DRIED EAST 5/4 X 6 RED PT",
  "SOUTHERN PINE, KILN DRIED CENTRAL BRITE",
  "SOUTHERN PINE, KILN DRIED WEST #2 BRITE",
];

const rlTypesPanel = [
  "SOUTHERN PLYWOOD SANDING (EASTSIDE) / (WESTSIDE) BC",
  "SOUTHERN PLYWOOD, SHEATHING - RATED EAST - CDX",
  "SOUTHERN PLYWOOD, SHEATHING - MIL-GRADE EAST",
];

export const RLLumberTypeSelect = <T,>(
  props: {} & Omit<FormComboboxProps<T, string>, "getLabel" | "data">,
) => <FormCombobox {...props} data={rlTypesLumber} getLabel={(i) => i} />;


export const RLPanelTypeSelect = <T,>(
  props: {} & Omit<FormComboboxProps<T, string>, "getLabel" | "data">,
) => <FormCombobox {...props} data={rlTypesPanel} getLabel={(i) => i} />;
