import { FC, useMemo } from "react";
import { useUserPublic } from "~/Reducers/Users";
import { stringToColor } from "~/Utils/Forms";
import './UserNameCircle.scss';

interface UserNameCircleProps {
  userId: number;
  zIndex?: number;
  size?: number;
  onClick?: () => void;
}

const UserNameCircle: FC<UserNameCircleProps> = ({ userId, zIndex = 0, size = 34 }) => {
  const memberLabel = useUserLabel(userId);
  const color = stringToColor(memberLabel + userId);

  const style = {
    borderRadius: size / 2 + 1,
    height: size,
    width: size,
    minWidth: size,
    zIndex: zIndex
  };
  const subContainerstyle = {
    color: color,
    borderColor: color,
    backgroundColor: color + '33',
    borderRadius: size / 2 + 1,
    height: size - 4,
    width: size - 4,
    minWidth: size - 4,
    zIndex: zIndex
  };

  return (
    <div className={'UserNameCircleTagContainer'} style={style}>
      <div className={'UserNameCircleSubContainer text-semibold'} style={subContainerstyle}>
        {memberLabel}
      </div>
    </div>
  );
};

export default UserNameCircle;

const useUserLabel = (userId) => {
  const user = useUserPublic(userId);

  const labels = useMemo(() => {
    const name = (user?.name || '').replace('[test]', '').trim();
    const surname = user?.surname || '';
    return (name?.charAt(0) + surname?.charAt(0));
  }, [userId, user]);

  return labels;
};
