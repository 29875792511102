import { resolveScac } from "~/Data/Scac";
import OrderShippingDetails from "../OrderShippingDetails";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  obtainShipViaType,
  ShipViaType,
} from "~/Data/ShipVia";
import { FC } from "react";
import {useIsInternalCustomer} from "~/Reducers/User";

const PurchaseOrderShippingDetails: FC<{ orderId: number }> = ({ orderId }) => {
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(orderId, internalCustomer);

  const {
    shipVia,
    importSteamshipLine,
    importSailingVessel,
    importSailingVoyage,
    importVessel,
    importVoyage,
    importContainer,
    importBol,
    importPortOfLoad,
    importPortOfDestination,
    promiseDate,
    promiseDateRevised,
    promiseDateConfirmed,
  } = order.data || {};

  const shipViaType = obtainShipViaType(shipVia);

  if (shipViaType !== ShipViaType.Sailing) {
    return null;
  }

  const vessel = importSailingVessel || importVessel;
  const voyage = importSailingVoyage || importVoyage;
  const vesselNumbers = vessel
    ? vessel + (voyage?.length && " - ") + voyage
    : undefined;

  const shippingDetails = {
    shippingLine: importSteamshipLine
      ? resolveScac(importSteamshipLine)
      : undefined,
    vesselAndVoyage: vesselNumbers || undefined,
    containerNumbers: importContainer ? importContainer.split(",") : undefined,
    waybillNumber: importBol || undefined,
    loadPort: importPortOfLoad,
    dischargePort: importPortOfDestination,
    promiseDate,
    promiseDateRevised,
    promiseDateConfirmed,
  };
  return <OrderShippingDetails shippingDetails={shippingDetails} />;
};

export default PurchaseOrderShippingDetails;
