import useSWR from "swr";
import API, {OfferStatus} from "~/API";

const offerStatusListRFO = [
  OfferStatus.RFO_DRAFT,
  OfferStatus.RFO_DRAFT_VENDOR,
  OfferStatus.RFO_SENT,
  OfferStatus.RFO_DECLINED,
  OfferStatus.RFO_ARCHIVED,
];

export const useDashboardRequestsForOffers = () =>
  useSWR(
    "api/rfo/dashboard",
    async () =>
      await API.getOffers({
        offset: 0,
        limit: 10,
        onlyMy: true,
        status: offerStatusListRFO,
      }),
  );
