import useSWR from "swr";
import API, { CompanyUsersView, Order } from "~/API";
import useSWRInfinite from "swr/infinite";

export const useCompany = (id: number | undefined | null) =>
    useSWR(
        id == null ? null : ["api/company", id],
        async () => await API.getCompany({ id }),
    );

const getCompaniesKey = (
  filters: CompanyFilters,
  offset: number,
  previousPageData: CompanyUsersView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/companies", filters, offset };
};

const PAGE_SIZE = 15;

export interface CompanyFilters {
  query?: string;
  search?: string;
  company?: Array<number>;
  location?: Array<number>;
  buyer?: Array<number>;
  withEmptyBuyersOnly?: boolean;
  onlyMy?: boolean;
  inactive?: boolean;
  orderBy?: string;
  order?: Order;
  offset?: number;
  limit?: number;
  dateOnboardedTo?: string;
  dateOnboardedFrom?: string;
}

export const useCompanies = (filters: CompanyFilters, pageSize?: number) =>
  useSWRInfinite(
    (i, p) => getCompaniesKey(filters, i * (pageSize || PAGE_SIZE), p),
    (key) =>
      API.getCompanies({
        ...filters,
        offset: key.offset,
        limit: pageSize || PAGE_SIZE,
      }),
  );
