import {FC} from "react";
import {Notification, NotificationType} from "~/API";
import {TbBellFilled} from "react-icons/tb";
import {NotificationCard, useLink} from "~/Components/Notifications/NotificationCard";
import {useChatMessage} from "~/Data/Chats";

export const ChangeNotification: FC<{
  notification: Notification;
  markRead: () => void;
  refetchNotifications: () => void;
}> = ({ notification, markRead, refetchNotifications }) => {
  const link = useLink(notification.data.refs, NotificationType.FIELD_CHANGE);
  const message = useChatMessage(Number(notification.data.refs?.["CHAT_MESSAGE"]?.[0]));
  const data = JSON.parse(message?.data?.text || "{}");

  return (
    <NotificationCard
      icon={<TbBellFilled size={16} />}
      link={link}
      markRead={markRead}
      dateCreated={notification.dateCreated}
      read={notification.read}
      notificationId = {notification.id}
      refetchNotifications={refetchNotifications}
    >
      {data?.title}:{" "}
      {data?.data?.map(v => v.property).join(", ")}
    </NotificationCard>
  );
};
