import {
  useIsInternalUser,
} from "~/Reducers/User";
import "./OfferChat.scss";
import { ChatViewEntity } from "~/Components/Chats/ChatViewEntity";
import {useChatOffer} from "~/Data/Chats";

const OfferChat = ({ onClose, offerId }) => {
  const isInternalUser = useIsInternalUser();

  const chatInternal = useChatOffer(offerId, true, isInternalUser);
  const chatExternal = useChatOffer(offerId, false, isInternalUser);

  return (
    <ChatViewEntity
      onClose={onClose}
      chatExternal={chatExternal.data}
      chatInternal={chatInternal.data}
    />
  );
};

export default OfferChat;
