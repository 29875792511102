import { GroupPermission } from "~/API";
import { useState, useEffect } from "react";
import { useUserLabels, getUsersByPermissions } from "~/Reducers/Users";
import SelectWithoutPaging from "./SelectWithoutPaging";
import { useAppDispatch } from "~/StoreTypes";


const AccountManagerSelect = (props) => {
  const dispatch = useAppDispatch();
  const [userKeys, setUserKeys] = useState([]);
  const userLabels = useUserLabels();

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async () => {
    try {
      const permissions = props.permissions || [GroupPermission.CAN_BE_SALES_CONTACT_PERSON];
      const managerList = await dispatch(getUsersByPermissions(permissions));
      setUserKeys(managerList.map(user => user.id));
    } catch {
    }
  };

  let data = userKeys.filter(it => !props.except || !props.except.includes(it));
  return (
    <SelectWithoutPaging {...props}
      data={data}
      labels={userLabels}
    />
  );
};

export default AccountManagerSelect;
