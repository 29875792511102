import { Button } from "~/Components/UI";
import { OfferStatus } from "~/API";
import { offersRootPath } from "~/Services/Routes";
import { useHistory } from "react-router-dom";
import Toast from "~/Components/Toast";


export const ReOpenOfferButton = ({ offerId, saveOffer }) => {
  const history = useHistory();

  return (
    <Button
      onClicked={async () => {
        await saveOffer(OfferStatus.O_BUYER_REVIEW);
        Toast.Success(`Offer ${offerId} re-opened`);
        history.replace(`${offersRootPath}${offerId}/edit`);
      }}
      label="offer.reopen"
    />
  );
}