import { FC, ReactNode } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { TbSquareXFilled } from "react-icons/tb";
import vars from "../../../Styles/Vars";
import { ToggleProps, useToggle } from "~/Hooks";
import { Button } from "~/Components/UI";

export interface DialogProps {
  children?: ReactNode;
  trigger: ReactNode;
  title?: ReactNode;
  onOpenChange?: (open: boolean) => void;
  open?: ToggleProps;
  dataCy?: string;
}

export const Dialog: FC<DialogProps> = (props) => {
  const open = useToggle(false);
  const onOpenClick = (event: any) => {
    event?.stopPropagation();
    (props.open || open).toggleTrue();
  };
  const onCloseClick = () => (props.open || open).toggleFalse();

  return (
    <DialogPrimitive.Root
      onOpenChange={props.onOpenChange}
      open={props.open?.value || open.value}
      data-cy={props.dataCy}
    >
      <DialogPrimitive.Trigger onClick={onOpenClick} asChild>
        {props.trigger}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0 bg-opacity-75-black-0 grid desktop-place-items-center overflow-y-auto animate-dialog-overlay">
          <DialogPrimitive.Content
            className="bg-black-12 rounded-xxs px-lg py-md max-h-full desktop-m-lg animate-dialog-content shadow-lg"
            onEscapeKeyDown={onCloseClick}
            onPointerDownOutside={onCloseClick}
            onInteractOutside={onCloseClick}
          >
            <div className="flex-row justify-between mb-lg">
              <DialogPrimitive.Title className="text-xl text-bold p-0" asChild>
                <div>{props.title}</div>
              </DialogPrimitive.Title>
              <DialogPrimitive.Close tabIndex={-1} onClick={onCloseClick} asChild>
                <Button
                  leftIcon={<TbSquareXFilled size={vars.size.lg} />}
                  color="gray"
                  variant="text"
                  data-cy={"dialogCloseButton"}
                />
              </DialogPrimitive.Close>
            </div>
            <DialogPrimitive.Description asChild>
              <div>{props.children}</div>
            </DialogPrimitive.Description>
          </DialogPrimitive.Content>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};
