import { FC } from "react";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  ordersPathCompleted,
  ordersPathOngoing,
  ordersPathPurchase,
  ordersPathSales,
  ordersRootPath,
} from "~/Services/Routes";
import {
  useIsInternalCustomer,
  useIsInternalUser,
  useIsVendor,
} from "~/Reducers/User";
import SalesOrdersTab from "./SalesOrdersTab";
import PurchaseOrdersTab from "./PurchaseOrdersTab";
import {PageTab, PageTabContainer} from "~/Components/Nav/PageTabs";

const OrdersPage: FC = () => {
  const isInternalUser = useIsInternalUser();

  const isVendor = useIsVendor();
  const isInternalCustomer = useIsInternalCustomer();
  const showPO = isVendor || isInternalCustomer;

  const getOrdersTabPath = (isSales: boolean) => {
    if (isInternalUser) {
      return (
        ordersRootPath +
        (isSales ? ordersPathSales : ordersPathPurchase)
      );
    } else {
      return (
        ordersRootPath + // /orders
        (showPO ? ordersPathPurchase : ordersPathSales) +
        "/" + // /purchase or /sales
        (isSales ? ordersPathOngoing : ordersPathCompleted)
      ); // /ongoing or /completed
    }
  };

  return (
    <div className="static-view">
      <PageTabContainer title={isInternalUser ? "title.orders" : "title.my.orders"} showNav>
        <PageTab route={getOrdersTabPath(true)} label={isInternalUser ? "title.sales.orders" : "title.ongoing"} />
        <PageTab route={getOrdersTabPath(false)} label={isInternalUser ? "title.purchase.orders" : "title.completed"} />
      </PageTabContainer>

      <Switch>
        <Redirect
          exact // path = "/orders"
          // scenario 1:
          // path = "/orders"
          // isInternalUser: redirect -> "/orders/sales"
          // other users: redirect -> "/orders/sales/ongoing"
          from={ordersRootPath}
          to={
            isInternalUser
              ? ordersRootPath + ordersPathSales
              : `${ordersRootPath}${showPO ? ordersPathPurchase : ordersPathSales}/${ordersPathOngoing}`
          }
        />

        <Route
          path={`${ordersRootPath}:type/:status`}
          render={(routeProps) => {
            const type = routeProps.match.params?.type?.toLowerCase();
            const isOnlyCompleted = routeProps.match.params?.status?.toLowerCase() == ordersPathCompleted ? true : undefined;
            if (type === ordersPathPurchase) {
              return <PurchaseOrdersTab isOnlyCompleted={isOnlyCompleted} />;
            }
            return <SalesOrdersTab isOnlyCompleted={isOnlyCompleted} />;
          }}
        />

        <Route
          path={`${ordersRootPath}:type/`}
          render={(routeProps) => {
            // scenario 2:
            // path = "/orders/sales"
            // isInternalUser: stay on "/orders/{sales|purchase}/"
            // other users: redirect -> "/orders/{sales|purchase}/ongoing"
            if (isInternalUser) {
              const type = routeProps.match.params?.type?.toLowerCase();
              if (type === ordersPathPurchase) {
                return <PurchaseOrdersTab />;
              }
              return <SalesOrdersTab />;
            } else {
              const orderTypePath = showPO
                ? ordersPathPurchase
                : ordersPathSales;
              return (
                <Redirect
                  to={`${ordersRootPath}${orderTypePath}/${ordersPathOngoing}`}
                />
              );
            }
          }}
        />
      </Switch>
    </div>
  );
};

export default OrdersPage;
