import dayjs from "dayjs";
import React, { FC, useMemo } from "react";

const DateView: FC<{ date: string }> = ({ date }) => {
  const dateFormatted = useMemo(() => dayjs(date).format("MM/DD/YYYY"), [date]);

  return <span>{date ? dateFormatted : "-"}</span>;
};

export default DateView;

export const DateTimeView: FC<{ date: string }> = ({ date }) => {
  const dateTimeFormatted = useMemo(
    () => dayjs(date).format("MM/DD/YYYY HH:mm"),
    [date],
  );

  return <span>{date ? dateTimeFormatted : "-"}</span>;
};

export const DateTimeOrdinal: FC<{ date: string }> = ({ date }) => {
  const dateTimeFormatted = useMemo(
    () => dayjs(date).format("MMM Do hh:mm a"),
    [date],
  );
  return <span>{date ? dateTimeFormatted : "-"}</span>;
};

export const DateViewRelative: FC<{ date: string }> = (props) => (
  <>{dayjs(props.date).fromNow()}</>
)
