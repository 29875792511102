import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { usersRootPath } from "~/Services/Routes";
import { FC } from "react";
import { LocationView, UserAccountView } from "~/API";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useFormData } from "~/Hooks";
import { useUsers } from "~/Data/Users";
import { UsersTableColumns } from "~/Pages/Users/Components/UsersTable";
import { Card, FormInput, Table } from "~/Components/UI";
import { LinearLoader } from "~/Components/Loaders";

const LocationDetailsUsersTab: FC<{
  location: LocationView;
}> = (props) => {
  const history = useHistory();
  const locationUserIds = props.location?.users || [];
  const filters = useFormData({ search: "" });

  const users = useUsers(
    {
      ...filters.data,
      company: [props.location.companyId],
      query: "ACTIVE == 'TRUE'",
    },
    100,
  );

  const data = useMemo(
    () =>
      (users.data?.flat() || []).filter((u) => locationUserIds.includes(u.id)),
    [users.data, locationUserIds],
  );

  const onScrolledToBottom = () => users.setSize(users.size + 1);
  const onRowClicked = (row: UserAccountView) => {
    history.push(`${usersRootPath}${row.id}`);
  };

  const table = useReactTable({
    data,
    columns: UsersTableColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        loginAs: false,
        companyId: false,
      },
      columnPinning: {
        right: ["controls"],
      },
    },
  });

  return (
    <Card className="">
      <div className="flex-row gap-md p-md">
        <FormInput
          id="search"
          formData={filters}
          className="desktop-w-min-8xl"
          placeholder="title.search"
          showClear
          type="search"
        />
      </div>

      <LinearLoader loading={users.isValidating} />

      <Table
        table={table}
        onScrolledBottom={onScrolledToBottom}
        onRowClick={onRowClicked}
      />
    </Card>
  );
};

export default LocationDetailsUsersTab;
