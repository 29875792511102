import React, {FC} from 'react';
import Environment from "~/Services/Environment";
import BuildInfo from "../../BuildInfo";
import dayjs from "dayjs";

import './BuildInfoLabel.scss';

const BuildInfoLabel: FC = () => {
  if (Environment.isProduction)
    return null;

  if (BuildInfo.branch.toLowerCase().startsWith('prototype'))
    return null;

  return <div className={'build-info-label'}>
    <strong className="mr-xxs">Revision: </strong>
    {BuildInfo.revision.substring(0, 8)}
    <strong className="ml-lg mr-xxs">Build Date:</strong>
    {dayjs(BuildInfo.buildTime).format('DD MMM YYYY hh:mm a')}
  </div>
}

export default BuildInfoLabel;