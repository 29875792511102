import {
  Attachment,
  MyProduct,
  OfferStatus,
  OfferUpdate,
  OfferView,
  GroupPermission,
  ProductUfp,
} from "~/API";
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import { deepCopyOf } from "~/Utils";
import {LocalizedMessage, LocalizedMessageKey, localizedString, ls} from "~/Locales";
import {
  offersRootPath,
  requestedOffersRootPath,
  useIsRFOPath,
  useSearchParams,
} from "~/Services/Routes";
import { useFormState, useToggle } from "~/Hooks/Old";
import {useAppDispatch, useAppSelector} from "~/StoreTypes";
import {
  isComboIncoterm,
  useIncotermUsesAddress,
} from "~/Reducers/Incoterms";
import {
  locationAddress,
  useLocation as useReduxLocation,
  useLocationAddress,
  useLocations,
} from "~/Reducers/Locations";
import {
  createOffer,
  generateTodayPlusSevenDaysDateTimeInISO,
  getDateTimeEndOfTheDayInISO,
  getOffer,
  getOfferAttachments,
  isOfferDraftStatus,
  isOfferExpired,
  updateOffer,
  useOffer,
} from "~/Reducers/Offers";
import {
  useCurrentUser,
  useIsAdmin,
  useIsInternalUser,
  useIsUFPAM,
  useIsVendor,
} from "~/Reducers/User";
import { findImageAndPDFAttachments } from "~/Utils/Attachments";
import {
  destinationNonPortTypes,
  destinationPortTypes,
} from "~/Data/Destinations";
import {
  OfferStatusLabel,
  useIsDraft,
  useIsEdit,
  useIsMatching,
  useIsNew,
  useIsVendorConfirmationRequested,
} from "../Components/OffersHelper";
import { getQuoteAttachments, useQuotes } from "~/Reducers/Quotes";
import {
  getRequestForQuoteAttachments,
  useRequestsForQuote,
} from "~/Reducers/RequestsForQuote";
import CompanyView from "~/Components/Views/CompanyView";
import DatePicker from "~/Components/Old/DatePicker";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import LocationName from "~/Components/Old/LocationName";
import LocationSelect from "~/Components/Old/LocationSelect";
import ImagesList from "~/Components/Old/ImagesList";
import IncotermDestinationLabel from "~/Components/Old/IncotermDestinationLabel";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import Notification from "~/Components/Old/Notification";
import OfferFeedbackPanel from "./Components/OfferFeedbackPanel";
import PortSelect from "~/Components/Old/PortSelect";
import ProductImagesGallery from "~/Components/Old/ImagesGallery";
import ProductsList from "./Components/ProductsList";
import QuoteSelect from "~/Components/Old/QuoteSelect";
import RequestSelect from "~/Components/Old/RequestSelect";
import Select from "~/Components/Old/Select";
import TextField from "~/Components/Old/TextField";
import UnsavedChangesPrompt from "~/Components/Old/UnsavedChangesPrompt";
import UserName from "~/Components/Old/UserName";
import UserSelect from "~/Components/Old/UserSelect";
import VendorSelect from "~/Components/Old/VendorSelect";
import { WarningBanner } from "~/Components/Old/WarningBanner";
import OfferReference from "../Components/OfferReference";
import { OfferDetailsButtonsPanel } from "./Components/ButtonsPanel/OfferDetailsButtonsPanel";
import OfferDetailsSummarySectionTable from "./Components/OfferDetailsSummarySectionTable";
import OfferDetailsSummarySectionTableVendor from "./Components/OfferDetailsSummarySectionTableVendor";
import OrderedQuotesTable from "./Components/OrderedQuotesTable";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import "./OfferDetailsTab.scss";
import { usePort } from "~/Reducers/Ports";
import Toast from "~/Components/Toast";
import {invalidateChats} from "~/Data/Chats";
import {mutate} from "swr";
import {trackEvent} from "~/Services/Tracking";
import {Card} from "~/Components/UI";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "~/Components/UI/Tabs";
import {OfferMatchingTable} from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingTable";
import {matchingQuotesLength} from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingReducer";

const currencyArray = ["USD", "EUR"];
const buyerSelectByPermissions = [
  GroupPermission.CAN_BE_SALES_CONTACT_PERSON,
  GroupPermission.CAN_BE_ADMIN,
];

const useDefaultOffer = () => {
  const isRFO = useIsRFOPath();

  return useMemo(
    () => ({
      status: isRFO ? OfferStatus.RFO_DRAFT : OfferStatus.O_DRAFT,
      currency: currencyArray[0],
      validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
    }),
    [isRFO],
  );
};
const FieldWithLabel: FC<{ label: LocalizedMessageKey, children: ReactNode, dataCy?: String}> = ({ label, children, dataCy }) => {
  return (
      <div className="flex-col flex">
        <div className="text-sm color-text-2 mb-xxs text-left">
          <LocalizedMessage id={label} />
        </div>
        <div className="text-left color-text-1 flex-col" data-cy={dataCy}>
          {children}
        </div>
      </div>
  )
}
const OfferDetailsTab = ({ onBack }) => {
  const dispatch = useAppDispatch();
  const { id: offerIdFromUrl } = useParams<{ id: string }>();
  const locationsReduxState = useLocations();
  const quotesReduxState = useQuotes();
  const requestsReduxState = useRequestsForQuote();

  const isInternalUser = useIsInternalUser();
  const isVendor = useIsVendor();
  const isUFPAM = useIsUFPAM();

  const isNew = useIsNew();
  const isEdit = useIsEdit();
  const isMatching = useIsMatching();
  const offerId = isNew ? null : Number(offerIdFromUrl);
  const isRFO = useIsRFOPath();

  const history = useHistory();

  const defaultOffer = useDefaultOffer();
  const initFormData = isNew ? defaultOffer : {};

  const offer = useOffer(offerId, true) || ({} as OfferView);

  const [formData, setFormData, hasChanges] = useFormState<OfferView>(
    offer,
    initFormData as OfferView,
  );

  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState(false);

  const [showSendQuotesDialog, toggleShowSendQuotesDialog] = useToggle();
  const disableSendQuotesButton = useAppSelector((state) => matchingQuotesLength(state) == 0);

  const vendorCompanyIdRef = useRef(null);
  const shipFromRef = useRef(null);
  const incotermPortIdDestinationRef = useRef(null);
  const incotermAddressDestinationRef = useRef(null);
  const incotermRef = useRef(null);
  const currencyRef = useRef(null);
  const dateIncoDestinationRef = useRef(null);

  const [requiredFieldsWarningMessage, setRequiredFieldsWarningMessage] =
    useState(null);

  const isAdmin = useIsAdmin();
  const currentUser = useCurrentUser();

  const [showProductInvalidAlert, setShowProductInvalidAlert] = useState(false);
  const [redColorDateIncoDestination, setRedColorDateIncoDestination] =
    useState(false);

  const imageGallery = useRef<any>(null);

  const {
    vendorCompanyId = null,
    buyerUserId = null,
    shipFromLocationId = null,
    incoterm = "",
    incotermPortIdDestination = null,
    incotermAddressDestination = "",
    dateIncoDestination = "",
    validityDate = null,
    externalComment = "",
    internalComment = "",
    products = [],
    currency = currencyArray[0],
    status,
    requestForQuoteId = null,
    quotes = [],
    purchaseOrders = [],
    dateCreated,
    dateUpdated,
    createdByUserAccountId,
    updatedByUserAccountId,
  } = formData;

  const isConfirmationRequested = useIsVendorConfirmationRequested(status);

  const isDraft = useIsDraft(status);

  const canEdit =
    isAdmin ||
    offer.buyerUserId === currentUser.id ||
    (isVendor && offer.vendorCompanyId === currentUser.companyId && isDraft);

  const isComboIncotermSelected = isComboIncoterm(incoterm);
  const canShowAddressField =
    useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const canShowPortField =
    !useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const shipFrom = useReduxLocation(shipFromLocationId);
  const shipFromAddress = useLocationAddress(shipFromLocationId);

  const [searchParams] = useSearchParams();

  const { requestId: requestIdFromUrl, quoteId: quoteIdFromUrl } = searchParams;

  useEffect(() => {
    if (offerId) {
      dispatch(getOfferAttachments(offerId))
        .then((attachments) => {
          setAttachments(attachments);
        })
        .catch((error) => {
          Toast.ApiError(error);
        });
    }
  }, [offerId, isNew, isEdit, isMatching, dispatch]);

  const isOfferValid = useCallback(
    (extraValidations = false) => {
      const isVendorValid = extraValidations
        ? !!vendorCompanyId
        : !isRFO || !!vendorCompanyId;
      if (!isVendorValid) vendorCompanyIdRef.current?.jiggleIfInvalid();
      if (!incoterm) incotermRef.current?.jiggleIfInvalid();
      if (!incotermPortIdDestination)
        incotermPortIdDestinationRef.current?.jiggleIfInvalid();
      if (!incotermAddressDestination)
        incotermAddressDestinationRef.current?.jiggleIfInvalid();
      setRedColorDateIncoDestination(!dateIncoDestination);
      if (!dateIncoDestination)
        dateIncoDestinationRef.current?.jiggleIfInvalid();

      if (extraValidations && !shipFrom) shipFromRef.current?.jiggleIfInvalid();
      if (extraValidations && !currency) currencyRef.current?.jiggleIfInvalid();

      const isDestinationValid = canShowAddressField
        ? !!incotermAddressDestination
        : !!incotermPortIdDestination;

      const warnings = [
        { condition: !isVendorValid, message: "Vendor" },
        { condition: !incoterm, message: "Incoterm" },
        { condition: !isDestinationValid, message: "Incoterm Destination" },
        { condition: !dateIncoDestination, message: "Expected Delivery" },
        {
          condition: formData.products.length === 0,
          message: "Add at least one product",
        },
        { condition: extraValidations && !shipFrom, message: "Ship From" },
        { condition: extraValidations && !currency, message: "Currency" },
        {
          condition:
            formData.products.some((it) => !it.quantity) ||
            formData.products.length === 0,
          message: "All products must have quantity",
        },
        {
          condition:
            formData.products.some((it) => !it.uom) ||
            formData.products.length === 0,
          message: "All products must have Unit Of Measure",
        },
        {
          condition:
            formData.products.some((it) => !it.price) ||
            formData.products.length === 0,
          message: "All products must have price",
        },
      ]
        .filter((warning) => warning.condition)
        .map((warning) => (
          <>
            {warning.message}
            <br />
          </>
        ));

      const warningMessage =
        warnings.length > 0 ? (
          <>
            {extraValidations
              ? "In order to request vendor confirmation, enter all required fields:"
              : "In order to submit the offer to sales, enter all required fields:"}
            <br />
            {warnings}
          </>
        ) : null;
      setRequiredFieldsWarningMessage(warningMessage);

      if (
        !dateIncoDestination ||
        !incoterm ||
        !isDestinationValid ||
        !isVendorValid ||
        (extraValidations && (!shipFrom || !currency))
      ) {
        Toast.Error("Enter all required fields to submit offer.");
        return false;
      }
      if (formData.products.length === 0) {
        Toast.Error("Add at least one product to submit offer.");
        return false;
      }
      // formData.products - all products must have quantity > 0
      if (formData.products.some((it) => !it.quantity)) {
        Toast.Error("All products must have quantity.");
        return false;
      }
      if (formData.products.some((it) => !it.uom)) {
        Toast.Error("All products must have a Unit of Measure.");
        return false;
      }
      if (formData.products.some((it) => !it.price)) {
        Toast.Error("All products must have price.");
        return false;
      }
      return true;
    },
    [
      formData,
      isRFO,
      vendorCompanyId,
      incoterm,
      incotermPortIdDestination,
      currency,
      incotermAddressDestination,
      dateIncoDestination,
      canShowAddressField,
      shipFrom,
    ],
  );

  const saveOffer = useCallback(
    async (offerStatus: OfferStatus) => {
      const offerToCreateOrUpdate = { ...formData, status: offerStatus };
      if (offerToCreateOrUpdate.dateIncoDestination)
        offerToCreateOrUpdate.dateIncoDestination =
          offerToCreateOrUpdate.dateIncoDestination.substring(0, 10); //only date

      if (isVendor) {
        offerToCreateOrUpdate.vendorCompanyId = currentUser.companyId;
        if (
          offerStatus === OfferStatus.RFO_DRAFT_VENDOR &&
          status === OfferStatus.RFO_SENT
        ) {
          offerToCreateOrUpdate.attachments = [];
        }
      }

      const isNewStatusDraft = isOfferDraftStatus(offerStatus);

      if (!isNewStatusDraft) {
        const valid = isOfferValid();
        if (!valid) {
          throw Error("Offer Form Invalid");
        }
      }

      const apiFunc = !!offer?.id ? updateOffer : createOffer;

      try {
        setLoading(true);

        const productsWithoutUuids = formData?.products?.map((product) => {
          const isDigitsOnly = /^\d+$/.test(product.id.toString()); // is not uuid
          return isDigitsOnly ? product : { ...product, id: null };
        });
        const offerToCreateOrUpdateWithProducts = {
          ...offerToCreateOrUpdate,
          products: productsWithoutUuids,
        };

        const offer = await dispatch(
          apiFunc(
            offerToCreateOrUpdateWithProducts as { id: number } & OfferUpdate,
          ),
        );

        // const offerIdToShow = isNew ? '' : offerId;
        invalidateChats(mutate)
        // console.log('### ✅saved offer = offer')

        if (isNew && offer?.id) {
          const urlParamsArray = [];
          if (requestIdFromUrl)
            urlParamsArray.push(`requestId=${requestIdFromUrl}`);
          if (quoteIdFromUrl) urlParamsArray.push(`quoteId=${quoteIdFromUrl}`);
          const urlParams =
            urlParamsArray.length !== 0 ? `?${urlParamsArray.join("&")}` : "";
          history.replace(
            `${isRFO ? requestedOffersRootPath : offersRootPath}${offer.id}/edit${urlParams}`,
          );
        }
      } catch (error) {
        Toast.ApiError(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      formData,
      isNew,
      isVendor,
      isOfferValid,
      dispatch,
      history,
      offer?.id,
      currentUser.companyId,
      requestIdFromUrl,
      quoteIdFromUrl,
      isRFO,
      status,
    ],
  );

  const onSaveOffer = useCallback(async () => {
    await saveOffer(
      isConfirmationRequested ? OfferStatus.O_CLOSING : status
    );

    if(!isDraft)
      Toast.Success(ls(isConfirmationRequested
        ? "phrase.vendor.confirmation.requested"
        : "phrase.offer.id.submitted.successfully"
        , [offerId]));

    const typeName = isRFO ? "Request for Offer" : "Offer";
    trackEvent(
      isNew ? `${typeName} Created` : (
        isDraft ? `${typeName} Saved` : (
          isConfirmationRequested ? `${typeName} Confirmation Requested` : `${typeName} Submitted`
        )
      )
    );
  }, [saveOffer, status, isDraft, isConfirmationRequested]);

  const onQuotesCreated = useCallback(async () => {
    // await saveOffer(OfferStatus.O_QUOTED, '');
    history.replace(`${offersRootPath}${offerId}`);
    dispatch(getOffer(offerId));
  }, [history, offerId, dispatch]);

  useEffect(() => {
    isNew && onSaveOffer();
  }, [isNew]);

  const copyOffer = useCallback(() => {
    history.replace(`${offersRootPath}new`);
    setFormData((data) => ({
      ...data,
      status: OfferStatus.O_DRAFT,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      createdByUserAccountId: currentUser.id,
      updatedByUserAccountId: currentUser.id,
      dateIncoDestination: undefined,
      validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
      quotes: [],
      purchaseOrders: [],
      attachments: [],
      unreadChatMessages: 0,
    }));

    trackEvent("Offer Copied");
  }, []);

  // ----------- Fields change ------------
  const onFormDataChange = useCallback(
    (updatedData) => {
      setFormData((formData) => ({ ...formData, ...updatedData }));
    },
    [setFormData],
  );

  const onAttachmentListChange = useCallback(
    (newAttachments: Attachment[]) => {
      setFormData((formData) => ({
        ...formData,
        attachments: newAttachments.map((it) => it.id),
      }));
      setAttachments(newAttachments);
    },
    [setFormData],
  );

  const updateAttachmentsFromSelectedRequest = useCallback(
    (requestId) => {
      if (!!requestId) {
        dispatch(getRequestForQuoteAttachments(requestId)).then(
          onAttachmentListChange,
        );
      }
    },
    [dispatch, onAttachmentListChange],
  );

  const onFieldChange = useCallback(
    ({ target }) => {
      if (target.id === "vendorCompanyId") {
        // reset port if vendor changed
        onFormDataChange({
          [target.id]: target.value,
          incoterm: "",
          shipFromLocationId: null,
          incotermAddressDestination: "",
          incotermPortIdDestination: null,
        });
      } else if (target.id === "shipFromLocationId") {
        // fill all ship-from related fields
        const shipFromLocation = locationsReduxState[target.value];
        onFormDataChange({
          [target.id]: target.value,
          incoterm: shipFromLocation?.incoterm,
          incotermAddressDestination: locationAddress(shipFromLocation),
          incotermPortIdDestination: shipFromLocation?.portId,
        });
      } else if (target.id === "validityDate") {
        if (target.value) {
          // set validityDate to 23:59:59
          const dateTimeEndOfTheDay = getDateTimeEndOfTheDayInISO(target.value);
          onFormDataChange({ [target.id]: dateTimeEndOfTheDay });
        }
      } else if (
        target.id === "incoterm" &&
        !!target.value &&
        !!shipFromLocationId
      ) {
        // fill address and port by preferred value if it's empty
        const dataToUpdate = { [target.id]: target.value } as any;
        if (!incotermAddressDestination)
          dataToUpdate.incotermAddressDestination = shipFromAddress;
        if (
          !incotermPortIdDestination &&
          isComboIncoterm(shipFrom?.incoterm) === isComboIncotermSelected
        ) {
          dataToUpdate.incotermPortIdDestination = shipFrom?.portId;
        } else if (
          incotermPortIdDestination &&
          isComboIncoterm(shipFrom?.incoterm) !== isComboIncotermSelected
        ) {
          dataToUpdate.incotermPortIdDestination = "";
        }
        onFormDataChange(dataToUpdate);
      } else if (target.id === "requestForQuoteId") {
        if (!!offerId && isDraft) {
          const request = requestsReduxState[target.value];
          if (!!request) {
            onFormDataChange({
              [target.id]: target.value,
              incoterm: request.incoterm,
              incotermPortIdDestination: request.incotermPortIdDestination,
              incotermAddressDestination: request.incotermAddressDestination,
              dateIncoDestination: request.expectedDeliveryDate,
              validityDate: request.validityDate,
              externalComment: request.externalComment,
              internalComment: request.internalComment,
              currency: request.currency,
              products: request.products,
            });
            updateAttachmentsFromSelectedRequest(request.id);
          } else {
            onFormDataChange({ [target.id]: target.value });
          }
        } else {
          onFormDataChange({ [target.id]: target.value });
        }
      } else {
        onFormDataChange({ [target.id]: target.value });
      }
    },
    [
      onFormDataChange,
      shipFromAddress,
      shipFrom,
      shipFromLocationId,
      incotermAddressDestination,
      incotermPortIdDestination,
      locationsReduxState,
      offerId,
      requestsReduxState,
      updateAttachmentsFromSelectedRequest,
      isDraft,
      isComboIncotermSelected,
    ],
  );

  const onQuotesFieldSelect = useCallback(
    (selectedQuoteId) => {
      // Remove quote id
      if (quotes.includes(selectedQuoteId)) {
        onFormDataChange({
          quotes: quotes.filter((it) => it !== selectedQuoteId),
        });

        // Add quote id and pre-fill data
      } else {
        const quote = quotesReduxState[selectedQuoteId];
        if (quote && isDraft) {
          dispatch(getQuoteAttachments(selectedQuoteId)).then(
            onAttachmentListChange,
          );
          onFormDataChange({
            quotes: [...quotes, selectedQuoteId],
            incoterm: quote.incoterm,
            incotermPortIdDestination: quote.incotermPortIdDestination,
            incotermAddressDestination: quote.incotermAddressDestination,
            dateIncoDestination: quote.expectedDeliveryDate,
            validityDate: quote.validityDate,
            externalComment: quote.externalComment,
            internalComment: quote.internalComment,
            currency: quote.currency,
            products: quote.products,
          });
        } else {
          onFormDataChange({ quotes: [...quotes, selectedQuoteId] });
        }
      }
    },
    [
      quotes,
      quotesReduxState,
      onFormDataChange,
      dispatch,
      isDraft,
      onAttachmentListChange,
    ],
  );

  // ----------- Products ------------
  const onProductAdd = useCallback(
    (product: MyProduct | ProductUfp, isMyProduct: boolean) => {
      const lengthIds = isMyProduct
        ? (product as MyProduct).lengthIds
        : [];

      const newProduct = {
        id: uuid(),
        productId: isMyProduct
          ? (product as MyProduct).productId
          : (product as ProductUfp).id,
        productCode: product.productCode,
        productGroupId: product.productGroupId,
        productDescription: product.description,
        productType: product.productType,
        productActThick: product.msrActThick,
        productActWidth: product.msrActWidth,
        productActLength: product.msrActLength,
        uom: isMyProduct
          ? (product as MyProduct).uom
          : (product as ProductUfp).standardUnitOfMeasure,
        lengthIds,
        quantity: 1,
        price: product.price,
        currency: product.currency,
        unitsContainer: product.unitsContainer,
      };
      onFormDataChange({ products: [...products, newProduct] });
    },
    [onFormDataChange, products],
  );

  const onProductFieldChange = useCallback(
    (productId, event) => {
      const { target } = event;
      let { id, value } = target;
      if (target.id == "lengthIds" && !Array.isArray(target.value)) {
        value = [value];
      }

      const productToUpdate = products.find(
          (product) => product.id === productId,
      );
      let updatedProduct = { ...productToUpdate, [id]: value };
      if (
          target.id == "uom" &&
          value == "LF" &&
          productToUpdate.lengthIds.length > 1
      ) {
        updatedProduct = {
          ...updatedProduct,
          lengthIds: [productToUpdate.lengthIds[0]],
        };
      }

      const updatedProducts = products.map((product) =>
        product.id === productId ? updatedProduct : product,
      );
      onFormDataChange({ products: updatedProducts });
    },
    [onFormDataChange, products],
  );

  const onRemoveProduct = useCallback(
    (productId) => {
      const updatedProducts = products.filter(
        (product) => product.id !== productId,
      );
      onFormDataChange({ products: updatedProducts });
    },
    [onFormDataChange, products],
  );

  const onDiscard = useCallback(() => {
    if (!!offer?.id) setFormData(deepCopyOf(offer));
  }, [offer, setFormData]);

  useEffect(() => {
    if (isEdit) {
      if (requestIdFromUrl) {
        onFieldChange({
          target: { id: "requestForQuoteId", value: Number(requestIdFromUrl) },
        });
      } else if (quoteIdFromUrl) {
        onQuotesFieldSelect(Number(quoteIdFromUrl));
      }
    }
  }, [requestIdFromUrl, quoteIdFromUrl, isEdit]);

  const isMyOffer = offer.buyerUserId === currentUser.id;

  // only visible to AM and Admins
  const shouldShowFeedbackPanel =
    (isUFPAM || isAdmin) &&
    !isRFO &&
    !isNew &&
    !isEdit &&
    !isMyOffer &&
    status !== OfferStatus.O_ARCHIVED &&
    status !== OfferStatus.O_DELIVERED;

  const todayDate = new Date();

  const isExpired = isOfferExpired(validityDate);
  const isInVendorProgress = status === OfferStatus.RFO_DRAFT_VENDOR;

  const attachmentsForGallery = useMemo(
    () => findImageAndPDFAttachments(attachments),
    [attachments],
  );
  const port = usePort(incotermPortIdDestination);

  // read only
  if (!isNew && !isEdit)
    return (
      <div className={"OfferDetailsTab"}>
        {shouldShowFeedbackPanel && (
          <OfferFeedbackPanel buyerId={buyerUserId} offerId={offerId} />
        )}
        {isExpired && <WarningBanner>This Offer is expired.</WarningBanner>}
        {isInVendorProgress && !isVendor && (
          <WarningBanner>
            This RFO is currently in progress by Vendor
          </WarningBanner>
        )}
        <div className={"OfferFieldsContainer mb-lg"}>
          <div className={"OfferFieldsBlock"}>
            <div className={"Title"}>
              <LocalizedMessage id={"title.status"} />
            </div>
            <div className={"Value"}>
              <OfferStatusLabel offerStatus={status} />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.buyer"} />
            </div>
            <div className={"Value"}>
              <UserName id={buyerUserId} />
            </div>

            {!isVendor && (
              <>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.vendor"} />
                </div>
                <div className={"Value"}>
                  <CompanyView id={vendorCompanyId} />
                </div>
              </>
            )}
            <div className={"Title"}>
              <LocalizedMessage id={"title.ship.from"} />
            </div>
            <div className={"Value"}>
              <LocationName id={shipFromLocationId} />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.incoterm.-.loading"} />
            </div>
            <div className={"Value"}>
              <IncotermDestinationLabel
                incoterm={incoterm}
                address={incotermAddressDestination}
                portName={port?.name}
              />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.currency"} />
            </div>
            <div className={"Value"}>{currency}</div>
          </div>

          <div className={"OfferFieldsBlock"}>
            <div className={"flex-row align-center flex-wrap CreationDatesGap"}>
              <div className={"flex-col"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.date.creation"} />
                </div>
                <div className={"Value"}>
                  {dayjs(dateCreated).format("DD MMM YYYY")}
                  &nbsp;
                  <UserName
                    id={createdByUserAccountId}
                    className={"flex text-truncate"}
                  />
                </div>
              </div>
              <div className={"flex-col"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.date.last.updated"} />
                </div>
                <div className={"Value"}>
                  {dayjs(dateUpdated).format("DD MMM YYYY")}
                  &nbsp;
                  <UserName
                    id={updatedByUserAccountId}
                    className={"flex text-truncate"}
                  />
                </div>
              </div>
            </div>
            {isInternalUser && (
              <>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.reference"} />
                </div>
                <div className={"Value"}>
                  <OfferReference
                    {...{ requestForQuoteId, purchaseOrders, quotes }}
                  />
                </div>
              </>
            )}

            <div className={"Title"}>
              <LocalizedMessage id={"title.expected.delivery"} />
            </div>
            <div className={"Value"}>
              <DateView date={dateIncoDestination} />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.validity.date"} />
            </div>
            <div className={"Value"}>
              <DateTimeView date={validityDate} />
            </div>

            <div className={"Title"}>
              {isVendor
                ? "Comment"
                : "External Comment (ATTENTION: VISIBLE TO CUSTOMER):"}
            </div>
            <div className={"Value text-multiline"}>{externalComment}</div>

            {!isVendor && (
              <>
                <div className={"Title"}>
                  Internal Comment (ATTENTION: VISIBLE TO UFP):
                </div>
                <div className={"Value text-multiline"}>{internalComment}</div>
              </>
            )}
          </div>

          {attachments?.length > 0 && (
            <div className={"OfferFieldsBlock"}>
              <ImagesList
                images={attachments}
                onChange={onAttachmentListChange}
                readOnly={true}
                onClick={(e, index) =>
                  imageGallery.current.showGallery(
                    attachmentsForGallery,
                    e.target,
                    index,
                  )
                }
              />
            </div>
          )}
        </div>

        <div className={"OfferFieldsContainer px-lg mb-lg"}>
          <div className={"OfferFieldsBlock px-0 scroll-x-container"}>
            {isInternalUser && (
              <OfferDetailsSummarySectionTable
                readOnly={true}
                products={products}
                currency={currency}
                onProductFieldChange={onProductFieldChange}
                onRemoveProduct={onRemoveProduct}
              />
            )}
            {!isInternalUser && (
              <OfferDetailsSummarySectionTableVendor
                readOnly={true}
                products={products}
                currency={currency}
                onProductFieldChange={onProductFieldChange}
                onRemoveProduct={onRemoveProduct}
              />
            )}
            {!isMatching && !isNew && isInternalUser && (
              <OrderedQuotesTable {...{ products }} />
            )}
          </div>
        </div>

        {!isVendor && isMatching && (
          <Card className="text-left">
            <Tabs defaultValue="matching" className="flex-col gap-lg">
              <div className="flex-row flex-wrap gap-md text-xl px-md pt-md">
                <span>Quote Customers</span>
                <TabsList>
                  <TabsTrigger value="matching">Matching Customers</TabsTrigger>
                  <TabsTrigger value="all">All My Customers</TabsTrigger>
                </TabsList>
              </div>

              <TabsContent value="matching" className="overflow-x-auto p-md">
                <OfferMatchingTable
                  offerId={offerId}
                  offer={formData}
                  inMyProducts={true}
                  offerProducts={products}
                  showSendQuotesDialog={showSendQuotesDialog}
                  toggleShowSendQuotesDialog={toggleShowSendQuotesDialog}
                  onQuotesCreated={onQuotesCreated}
                />
              </TabsContent>
              <TabsContent value="all" className="overflow-x-auto p-md">
                <OfferMatchingTable
                  offerId={offerId}
                  offer={formData}
                  inMyProducts={false}
                  offerProducts={products}
                  showSendQuotesDialog={showSendQuotesDialog}
                  toggleShowSendQuotesDialog={toggleShowSendQuotesDialog}
                  onQuotesCreated={onQuotesCreated}
                />
              </TabsContent>
            </Tabs>
          </Card>
        )}

        <OfferDetailsButtonsPanel
          {...{
            saveOffer,
            copyOffer,
            onBack,
            isOfferValid,
            offerId,
            toggleShowSendQuotesDialog,
            disableSendQuotesButton,
            hasChanges,
            loading,
            status,
            isExpired,
            vendorCompanyId,
          }}
        />

        <ProductImagesGallery ref={imageGallery} />

        {showProductInvalidAlert && (
          <Notification
            type={"error"}
            onClose={() => setShowProductInvalidAlert(false)}
          >
            {"This field can not be empty. Please make sure to choose a value."}
          </Notification>
        )}
      </div>
    );

  // edit
  return (
    <div className={"OfferDetailsTab"}>
      <UnsavedChangesPrompt
        onSave={onSaveOffer}
        {...{ hasChanges: hasChanges && isEdit && !loading, onDiscard, onBack }}
      />
      {requiredFieldsWarningMessage && (
        <WarningBanner>{requiredFieldsWarningMessage}</WarningBanner>
      )}

      {shouldShowFeedbackPanel && (
        <OfferFeedbackPanel buyerId={buyerUserId} offerId={offerId} />
      )}
      {isExpired && <WarningBanner>This Offer is expired.</WarningBanner>}
      <div className={"OfferFieldsContainer mb-lg"}>
        <div className={"OfferFieldsBlock"}>
          <div className={"Title"}>
            <LocalizedMessage id={"title.status"} />
          </div>
          <div className={"Value mb-2xl"}>
            <OfferStatusLabel offerStatus={status} />
          </div>

          {isVendor ? (
            <div className={"mb-2xl"}>
              <div className={"Title"}>
                <LocalizedMessage id={"title.buyer"} />
              </div>
              <div className={"Value"}>
                <UserName id={buyerUserId} />
              </div>
            </div>
          ) : (
            <>
              <UserSelect
                id={"buyerUserId"}
                label={localizedString("title.buyer")}
                value={buyerUserId}
                onChange={onFieldChange}
                byPermissions={buyerSelectByPermissions}
                disabled={isVendor}
              />
            </>
          )}

          <VendorSelect
            id="vendorCompanyId"
            className={"VendorCompanySelect"}
            label={localizedString("title.vendor")}
            value={vendorCompanyId}
            onChange={onFieldChange}
            selectRef={vendorCompanyIdRef}
            disabled={isVendor}
            required
            data-cy={"VendorSelect"}
          />

          <LocationSelect
            id="shipFromLocationId"
            showCustomerCodes
            label={localizedString("title.ship.from")}
            selectRef={shipFromRef}
            disabled={!vendorCompanyId}
            companyId={vendorCompanyId}
            value={shipFromLocationId}
            onChange={onFieldChange}
            required
          />

          <IncotermSelect
            id={"incoterm"}
            required
            label={localizedString("title.incoterm.-.loading")}
            selectRef={incotermRef}
            value={incoterm}
            onChange={onFieldChange}
          />

          {canShowAddressField && (
            <TextField
              id={"incotermAddressDestination"}
              value={incotermAddressDestination}
              className={"ValueField"}
              label={localizedString("title.port.of.loading")}
              onChange={onFieldChange}
              required
              ref={incotermAddressDestinationRef}
              disabled={isComboIncotermSelected}
            />
          )}
          {canShowPortField && (
            <PortSelect
              id={"incotermPortIdDestination"}
              label={localizedString("title.port.of.loading")}
              value={incotermPortIdDestination}
              onChange={onFieldChange}
              selectRef={incotermPortIdDestinationRef}
              byType={
                isComboIncotermSelected
                  ? destinationNonPortTypes
                  : destinationPortTypes
              }
              required
            />
          )}
          <Select
            id="currency"
            label={localizedString("title.currency")}
            value={currency}
            selectRef={currencyRef}
            onChange={onFieldChange}
            data={currencyArray}
            required
          />
        </div>

        <div className={"OfferFieldsBlock"}>
          {isInternalUser && (
            <>
              <RequestSelect
                id={"requestForQuoteId"}
                label={localizedString("title.request.reference")}
                value={requestForQuoteId}
                showForReference
                onChange={onFieldChange}
                includeValue={requestForQuoteId}
              />
              <QuoteSelect
                id={"quotes"}
                label={localizedString("title.quote.reference")}
                value={quotes}
                onSelect={onQuotesFieldSelect}
                showForOfferReference
                referenceParentId={offerId}
                multiple
              />
            </>
          )}

          <FieldWithLabel label="title.expected.delivery" dataCy="ExpectedDeliveryDate">
            <DatePicker
              id={"dateIncoDestination"}
              required
              className={redColorDateIncoDestination && "red-select"}
              mindate={todayDate}
              date={dateIncoDestination}
              onChange={onFieldChange}
              inputRef={dateIncoDestinationRef}
              data-cy={"dateIncoDestination"}
            />
          </FieldWithLabel>

          <FieldWithLabel label="title.validity.date" dataCy="ValidityDate">
            <DatePicker
              id={"validityDate"}
              className={"ValueField MidsizeField"}
              mindate={todayDate}
              date={validityDate}
              onChange={onFieldChange}
              data-cy={"validityDate"}
            />
          </FieldWithLabel>

          <TextField
            id="externalComment"
            label={
              isVendor
                ? "Comment"
                : "External Comment (ATTENTION: VISIBLE TO CUSTOMER):"
            }
            value={externalComment}
            onChange={onFieldChange}
            multiline
            rows={4}
          />

          {!isVendor && (
            <TextField
              id="internalComment"
              label={"Internal Comment (ATTENTION: VISIBLE TO UFP):"}
              value={internalComment}
              onChange={onFieldChange}
              multiline
              rows={4}
            />
          )}
        </div>

        <div className={"OfferFieldsBlock"}>
          <ImagesList
            images={attachments}
            onChange={onAttachmentListChange}
            readOnly={false}
            onClick={(e, index) =>
              imageGallery.current.showGallery(
                attachmentsForGallery,
                e.target,
                index,
              )
            }
          />
        </div>
      </div>

      <div className={"OfferFieldsContainer px-lg mb-lg"}>
        <div className={"OfferFieldsBlock px-0"}>
          <div className="scroll-x-container">
            <OfferDetailsSummarySectionTable
              readOnly={false}
              products={products}
              currency={currency}
              onProductFieldChange={onProductFieldChange}
              onRemoveProduct={onRemoveProduct}
            />
          </div>

          <div className={"horizontal-line"} />
          <div className={"text-xl text-left ml-md"}>
            {vendorCompanyId ? (
              <div className={"flex-row align-center"}>
                Products of &nbsp; <CompanyView id={vendorCompanyId} />
              </div>
            ) : (
              <LocalizedMessage id={"title.all.products"} />
            )}
          </div>

          {vendorCompanyId ? (
            <ProductsList
              type="my_products"
              onProductAdd={onProductAdd}
              companyId={vendorCompanyId}
              canEdit={canEdit}
            />
          ) : (
            <>
              {!isVendor && (
                <ProductsList
                  type="all"
                  onProductAdd={onProductAdd}
                  canEdit={canEdit}
                />
              )}
            </>
          )}
        </div>
      </div>

      <OfferDetailsButtonsPanel
        {...{
          saveOffer,
          isOfferValid,
          offerId,
          onBack,
          onSaveOffer,
          toggleShowSendQuotesDialog,
          disableSendQuotesButton,
          hasChanges,
          loading,
          status,
          copyOffer,
          isExpired,
          vendorCompanyId,
          // onSubmitOffer,
          // onEdit,
          // onRequestVendorConfirmation,
          // onShowMatching,
          // onVendorCreateOfferToUFP,
          // onDeclineOffer
        }}
      />

      <ProductImagesGallery ref={imageGallery} />

      {showProductInvalidAlert && (
        <Notification
          type={"error"}
          onClose={() => setShowProductInvalidAlert(false)}
        >
          {"This field can not be empty. Please make sure to choose a value."}
        </Notification>
      )}

      {/*{showSubmitConfirmation &&*/}
      {/*  <ConfirmationDialog*/}
      {/*    title={isVendor*/}
      {/*      ? 'Send Offer to Purchasing Manager'*/}
      {/*      : isRFO ? 'Send Request to Vendor' : 'Send Offer to AM'*/}
      {/*    }*/}
      {/*    description={*/}
      {/*      <>*/}
      {/*        {isVendor*/}
      {/*          ? 'Do you want to send this offer to your Purchasing Manager?'*/}
      {/*          : 'Do you want to send this offer to your Account Manager?'*/}
      {/*        }*/}
      {/*        <UserName id={accountManagerUserId} />*/}
      {/*      </>}*/}
      {/*    description2={''}*/}
      {/*    confirmTitle={<LocalizedMessage id={'title.send.offer'} />}*/}
      {/*    closeTitle={<LocalizedMessage id={'title.cancel'} />}*/}
      {/*    onConfirm={confirmOfferSubmit}*/}
      {/*    onClose={toggleConfirmSubmitDialog}*/}
      {/*  />*/}
      {/*}*/}
    </div>
  );
};

export default OfferDetailsTab;
