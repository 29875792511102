import {useEffect, useMemo, useState} from "react";
import {useUserGroups} from "~/Data/Admin/UserGroups";
import {FormCombobox, FormComboboxProps} from "~/Components/UI";

export const UserGroupCombobox = <T,>(
    props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
    const groups = useUserGroups("ACTIVE == 'TRUE'");

  const ids = useMemo(
      () => groups.data?.map((group) => group.id) || [],
      [groups.data],
  );

  const [labels, setLabels] = useState<Record<string, string>>(
      {},
  );

  useEffect(() => {
    let newLabels = {};
    groups.data?.forEach((g) => (newLabels[g.id] = `${g.name}`));
    setLabels((old) => ({ ...old, ...newLabels }));
  }, [ids]);

  const getLabel = (id: number) => labels[id];

  return (
      <FormCombobox
          {...props}
          data={ids}
          getLabel={getLabel}
      />
  );
}