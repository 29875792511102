import API, {
  LocationType, GroupPermission,
  PurchaseOrderPlantView,
  PurchaseOrderStatus,
  PurchaseOrderView,
} from "~/API";
import {FC, useCallback, useEffect, useMemo} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  getPathForPO,
  getPathForSO,
} from "~/Services/Routes";
import { LC, ls } from "~/Locales";
import { useIsMobile } from "~/Hooks/useIsMobile";
import {
  useIsInternalCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import PriceView from "~/Components/Views/PriceView";
import { useAMUserNameStrings } from "~/Reducers/Users";
import { OrderEmptyState } from "./SalesOrdersTab";
import {params, useDebounce, useFormData, usePageTitle, useToggle} from "~/Hooks";
import {
  Button,
  Case,
  Checkbox,
  FormCheckbox,
  FormInput,
  HoverCard, HoverCardContent,
  HoverCardTrigger,
  Match,
  Show,
  Table
} from "~/Components/UI";
import {LinearLoader} from "~/Components/Loaders";
import {ToggleFilterButton} from "~/Components/Entities/ToggleFilterButton";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {PurchaseOrderStatusBadge} from "~/Components/Orders/PurchaseOrderStatusBadge";
import DateView from "~/Components/Views/DateView";
import {FilterPanel} from "~/Components/Entities/FilterPanel";
import {PurchaseOrdersFilters, usePurchaseOrders} from "~/Data/Orders/PurchaseOrders";
import {CompanyCombobox} from "~/Components/Companies/CompanyCombobox";
import {LocationCombobox} from "~/Components/Companies/LocationCombobox";
import {UserByPermissionCombobox} from "~/Components/Users/UserByPermissionCombobox";
import {FormDateInputRange} from "~/Components/UI/Forms/DateInput";
import PurchaseOrderStatusSelect from "~/Pages/Orders/Components/PurchaseOrderStatusSelect";
import {onScrollToBottom, trimUndefined} from "~/Utils";
import * as z from "zod";
import {useSortState} from "~/Hooks/useSortState";
import {useAppDispatch, useAppSelector} from "~/StoreTypes";
import {clearSelectedOrders, isOrderSelected, toggleSelectedOrder} from "~/Pages/Orders/OrdersPageReducer";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import OrderDocumentsUploadDialog from "~/Pages/Orders/DetailsPage/Components/OrderDocumentsUploadDialog";
import {TbUpload} from "react-icons/tb";
import {OrderDocumentHoverTable} from "~/Pages/Orders/Components/OrderDocumentHoverTable";

const statusesOpen = [
  PurchaseOrderStatus.REQUESTED,
  PurchaseOrderStatus.ORDERED,
  PurchaseOrderStatus.PARTIAL,
  PurchaseOrderStatus.INBOUND,
  PurchaseOrderStatus.ACKNOWLEDGED,
];
const statusesCompleted = [PurchaseOrderStatus.COMPLETE];

const routeParamsParser = z.object({
  search: params.string,
  vendor: params.numberArray,
  status: params.enumArray(PurchaseOrderStatus),
  onlyMyOrders: params.bool,
  shipFrom: params.numberArray,
  shipTo: params.numberArray,
  accountManager: params.numberArray,
  deliveryDateFrom: params.dateTime,
  deliveryDateTo: params.dateTime,
});

const PurchaseOrdersTab: FC<{ isOnlyCompleted?: boolean }> = (props) => {
  usePageTitle("Purchase Orders");

  const showFilters = useToggle();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const isMobile = useIsMobile();

  const filters = useFormData<PurchaseOrdersFilters>({
    search: "",
    vendor: [],
    status: statusesOpen,
    onlyMyOrders: true,
    shipFrom: [],
    shipTo: [],
    accountManager: [],
    deliveryDateFrom: "",
    deliveryDateTo: "",
  }, {
    routeParser: routeParamsParser,
    onChange: (_oldData, newData) => {
      dispatch(clearSelectedOrders());
      return newData;
    },
  });
  const searchDebounced = useDebounce(filters.data.search);
  const sort = useSortState();

  const orders = usePurchaseOrders(
    trimUndefined({
      ...filters.data,
      search: searchDebounced,
      status: props.isOnlyCompleted ? statusesCompleted : filters.data.status,
      ...sort.data,
    }),
    isInternalUser,
    isInternalCustomer
  );
  const ordersList = useMemo(() => orders.data?.flat() || [], [orders.data]);
  const onScrolledToBottom = () => orders.setSize(orders.size + 1);
  const mobileScrolledToBottom = onScrollToBottom(onScrolledToBottom, 256);

  const emptyState = ordersList.length === 0 && !orders.isLoading && !orders.error;

  const onOpen = (row: PurchaseOrderPlantView & PurchaseOrderView) => {
    history.push({ pathname: getPathForPO(row.id) });
  };

  // clear when page loads in case they are navigating from another page to avoid have
  // orders selected that are no longer on the page
  useEffect(() => {
    dispatch(clearSelectedOrders());
  }, []);

  const onClearSelection = () => dispatch(clearSelectedOrders());

  const selectedOrders = useAppSelector(state => state.ordersPageState.selectedOrders);
  const onUploadDocuments = async (attachments: any[]) => {
    await API.createMultiplePurchaseOrderDocuments({}, {
      documents: attachments.map((a) => ({
        type: a.type,
        attachmentId: a.id,
        number: 1,
      })),
      orderIds: selectedOrders
    });
    await orders.mutate();
    dispatch(clearSelectedOrders());
  }

  const toggleCompletedOrders = (val: boolean) => {
    if (val) {
      filters.onDataChange("status", statusesCompleted);
    } else {
      filters.onDataChange("status", statusesOpen);
    }
  };
  const isOnlyCompletedOrders =
    JSON.stringify(filters.data.status) === JSON.stringify(statusesCompleted);

  const table = useReactTable({
    data: ordersList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["select"],
      },
      columnVisibility: {
        select: isInternalUser,
        numberInternal: isInternalUser || isInternalCustomer,
        numberExternal: !isInternalUser && !isInternalCustomer,
        vendorName: isInternalUser,
        shipFromName: !isInternalCustomer,
        locationName: isInternalCustomer,
        shipToName: isInternalUser,
        totalCosts: isInternalUser,
        documentCount: isInternalUser,
        importEtaPortDate: isInternalUser,
        poNumber: isInternalUser,
        incoTerms: !isInternalUser,
        scheduledDeliveryDate: !isInternalUser,
      },
    },
  });

  return (
    <div className="flex-col flex overflow-hidden">
      <div className="flex-row gap-lg flex overflow-hidden">
        <div className="flex-col overflow-hidden flex bg-black-12 rounded-xxs">
          <Match on={isMobile}>
            <Case v={false}>
              <div className="table-top-bar">
                <FormInput
                  id="search"
                  formData={filters}
                  showClear
                  placeholder="placeholder.order.list.search"
                  className="w-min-9xl"
                  type="search"
                />

                <Show when={isInternalUser}>
                  <FormCheckbox
                    id="onlyMyOrders"
                    formData={filters}
                    label="title.show.my.orders"
                  />
                  <Checkbox
                    id="completed"
                    value={isOnlyCompletedOrders}
                    onChange={toggleCompletedOrders}
                    label="title.completed"
                  />
                </Show>

                <div className="ml-auto">
                  <ToggleFilterButton onClicked={showFilters.toggle} />
                </div>
              </div>
            </Case>

            <Case v={true}>
              <div className="table-top-bar flex-row border-b-1 border-black-9 p-sm">
                <FormInput
                  id="search"
                  formData={filters}
                  placeholder="placeholder.search"
                  showClear
                  containerClassName="flex"
                  type="search"
                />
                <ToggleFilterButton onClicked={showFilters.toggle} />
              </div>
            </Case>
          </Match>

          <LinearLoader loading={orders.isValidating} />
          {emptyState && <OrderEmptyState />}
          {!emptyState && !isMobile && (
            <Table
              table={table}
              onRowClick={onOpen}
              onScrolledBottom={onScrolledToBottom}
              sort={sort}
              scrollBottomOffset={400}
              className="flex"
            />
          )}
          {!emptyState && isMobile && (
            <div className="overflow-auto" onScroll={mobileScrolledToBottom}>
              <div className="flex-col w-full text-left bg-color-black-12">
                {ordersList.map((order) => (
                  <OrderRowMobile key={order.id} order={order} />
                ))}
              </div>
            </div>
          )}
        </div>

        <FilterPanel open={showFilters}>
          <CompanyCombobox
            id="vendor"
            formData={filters}
            label="title.vendor"
            placeholder="title.all"
            filters={{ query: "TYPE == 'SELLER' && active == true" }}
            multiple
            showClear
            clearValue={[]}
          />

          <PurchaseOrderStatusSelect
            id="status"
            formData={filters}
            label="title.status"
            placeholder="title.all"
            multiple
            showClear
            clearValue={[]}
          />

          <LocationCombobox
            id="shipFrom"
            formData={filters}
            label="title.ship.from"
            placeholder="title.all"
            multiple
            showClear
            clearValue={[]}
            filters={{ type: LocationType.SHIP_FROM }}
          />

          <LocationCombobox
            id="shipTo"
            formData={filters}
            label="title.ship.to"
            placeholder="title.all"
            multiple
            showClear
            clearValue={[]}
            filters={{ type: LocationType.SHIP_TO }}
          />

          <UserByPermissionCombobox
            id="accountManager"
            formData={filters}
            label="title.buyer"
            placeholder="title.all"
            multiple
            showClear
            clearValue={[]}
            permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
          />

          <FormDateInputRange
            label="title.delivery.date"
            idFrom="deliveryDateFrom"
            idTo="deliveryDateTo"
            formData={filters}
            showClear
          />

          <Button color="gray" label="title.clear" onClicked={filters.clear} />
        </FilterPanel>
      </div>
      {isInternalUser && (
        <BottomButtonsPanel>
          {selectedOrders.length > 0 && (
            <Button
              label="title.clear.selection"
              color="gray"
              variant="text"
              onClicked={onClearSelection}
            />
          )}

          <OrderDocumentsUploadDialog
            desc={ls("order.multi.docs.desc", selectedOrders.length)}
            onSave={onUploadDocuments}
            trigger={
              <Button
                leftIcon={<TbUpload size={16} />}
                label="order.upload.docs"
                disabled={selectedOrders.length == 0}
              />
            }
          />
        </BottomButtonsPanel>
      )}
    </div>
  );
};

export default PurchaseOrdersTab;

const mcLetter = (val: string) => val ? `-${val[0]}` : "";

const columnHelper = createColumnHelper<PurchaseOrderView & PurchaseOrderPlantView>();

const columns = [
  columnHelper.display({ //INTERNAL ONLY
    id: "select",
    header: "",
    size: 0,
    cellMemo: ({ row }) => {
      const dispatch = useAppDispatch();
      const value = useAppSelector((state) => isOrderSelected(state, row.id));
      const onClick = () => {
        dispatch(toggleSelectedOrder(row.id));
      }

      return (
        <Checkbox
          value={value}
          onChange={onClick}
        />
      );
    }
  }),
  columnHelper.display({ // INTERNAL USER OR CUSTOMER
    id: "numberInternal",
    sortId: "number",
    header: () => ls("title.po.#"),
    cellMemo: ({ row }) => <div className="text-no-wrap">{`${row.number}${mcLetter(row.masterChild)}`}</div>,
    size: 0,
  }),
  columnHelper.display({ // EXTERNAL ONLY
    id: "numberExternal",
    sortId: "number",
    header: () => ls("title.your.order.#"),
    cellMemo: ({ row }) => <div className="text-no-wrap">{`${row.number}${mcLetter(row.masterChild)}`}</div>,
    size: 0,
  }),
  columnHelper.accessor("status", {
    sortId: "status",
    header: () => ls("title.status"),
    cellMemo: ({ value }) => <PurchaseOrderStatusBadge status={value} />,
  }),
  columnHelper.accessor("vendorName", { // INTERNAL USER ONLY
    sortId: "vendor_name",
    minSize: 400,
    header: () => ls("title.vendor"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("shipFromName", { // NOT INTERNAL CUSTOMER
    sortId: "ship_from_name",
    minSize: 200,
    header: () => ls("title.ship.from"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("locationName", { // ONLY INTERNAL CUSTOMER
    sortId: "location_name",
    minSize: 200,
    header: () => ls("title.location"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("shipToName", { // INTERNAL ONLY
    sortId: "ship_to_name",
    minSize: 200,
    header: () => ls("title.ship.to"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.display({
    id: "managers",
    sortId: "buyer_name",
    header: () => ls("title.buyer"),
    cellMemo: ({ row }) => {
      const managers = useAMUserNameStrings(row.accountManagerName, row.buyerName);
      return (<div className="text-no-wrap">{managers}</div>);
    },
  }),
  columnHelper.display({ // INTERNAL USER ONLY
    id: "totalCosts",
    sortId: "mill_cost_total",
    header: () => ls("title.total.costs"),
    cellMemo: ({ row }) => (
      <PriceView price={row.millCostTotal} currency={row.currency}/>
    ),
  }),
  columnHelper.accessor("documentCount", { // INTERNAL USER ONLY
    sortId: "document_count",
    header: () => ls("title.scanned.documents"),
    cellMemo: ({ value, row }) => (
      <HoverCard>
        <HoverCardTrigger>
          <div className="color-blue-1 cursor-pointer">
            {value}
          </div>
        </HoverCardTrigger>
        <HoverCardContent>
          <div onClick={(e) => e.stopPropagation()}>
            <OrderDocumentHoverTable id={row.id} isSalesOrder={false} />
          </div>
        </HoverCardContent>
      </HoverCard>
    )
  }),
  columnHelper.accessor("importEtaPortDate", { // INTERNAL USER ONLY
    sortId: "import_eta_port_date",
    header: () => ls("title.delivery.date"),
    cellMemo: ({ value }) => <DateView date={value}/>,
  }),
  columnHelper.display({ // INTERNAL USER ONLY
    id: "soNumber",
    sortId: "order_number",
    header: () => ls("title.so.#"),
    cellMemo: ({ row }) => {
      const { salesOrderId, orderNumber } = row;
      return (<div>
          {!!salesOrderId && !!orderNumber && orderNumber !== "0" ? (
          <Link
            to={getPathForSO(salesOrderId)}
            className={"Link"}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {orderNumber}
          </Link>
        ) : !!orderNumber && orderNumber !== "0" ? (
          orderNumber
        ) : (
          "-"
        )}
      </div>);
    }
  }),
  columnHelper.display({ // INTERNAL USER ONLY
    id: "incoTerms",
    sortId: "import_inco_terms",
    header: () => ls("title.destination"),
    cellMemo: ({ row }) => `${row.importIncoTerms} - ${row.importIncoTermsLoc}`,
  }),
  columnHelper.accessor("scheduledDeliveryDate", { // NOT INTERNAL USER
    sortId: "scheduled_delivery_date",
    header: () => ls("title.delivery.date"),
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
];

const OrderRowMobile: FC<{
  order: PurchaseOrderView & PurchaseOrderPlantView;
}> = ({ order }) => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();

  const {
    number,
    status,
    shipToName,
    importIncoTerms,
    importIncoTermsLoc,
    locationName,
    shipFromName,
    masterChild,
  } = order;

  const onOpenOrder = useCallback(() => {
    history.push({ pathname: getPathForPO(order.id) });
  }, [history]);

  return (
    <div
      className="flex-col px-lg py-md border-b-1 border-black-9 overflow-hidden"
      onClick={onOpenOrder}
    >
      <div className="flex-row w-full">
        <div className="text-truncate flex-col flex mr-xxs">
          <div className="text-sm">
            <LC id="title.po.#" />
          </div>
          <div className="text-no-wrap mt-xxs">
            {number ? `${number}${mcLetter(masterChild)}` : "-"}
          </div>
        </div>

        <div>
          <PurchaseOrderStatusBadge status={status} />
        </div>
      </div>

      <div className="text-sm mt-xs">
        <LC
          id={isInternalCustomer ? "title.location" : "title.ship.from"}
        />
      </div>
      <div className="text-truncate mt-xxs">
        {isInternalCustomer ? locationName : shipFromName}
      </div>

      {isInternalUser && (
        <div className="text-sm mt-xs">
          <LC id="title.ship.to" />
        </div>
      )}
      {isInternalUser && (
        <div className="text-truncate mt-xxs">{shipToName || "-"}</div>
      )}

      <div className="text-sm mt-xs">
        <LC id="title.destination" />
      </div>
      <div className="text-truncate mt-xxs">{`${importIncoTerms} - ${importIncoTermsLoc}`}</div>
    </div>
  );
};
