import API, {CreateOfferRequest, OfferStatus, OfferUpdate, OfferView} from '~/API';
import {useCallback, useMemo} from 'react';
import {composedItems} from './Utils/Compose';
import {useGetEntity} from "./Utils/Entity";
import {isRFO, localizedOfferStatusString, useOfferStatusSuffix} from '~/Pages/Offers/Components/OffersHelper';
import dayjs from "dayjs";
import {useIsInternalUser, useIsUFPAM, useIsUFPBuyer, useIsVendor} from "./User";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";

export const ACTIONS_GET_OFFERS_START = 'ACTIONS_GET_OFFERS_START';
export const ACTIONS_GET_OFFERS_SUCCESS = 'ACTIONS_GET_OFFERS_SUCCESS';
export const ACTIONS_GET_OFFERS_ERROR = 'ACTIONS_GET_OFFERS_ERROR';

export const ACTIONS_GET_REQUESTED_OFFERS_START = 'ACTIONS_GET_REQUESTED_OFFERS_START';
export const ACTIONS_GET_REQUESTED_OFFERS_SUCCESS = 'ACTIONS_GET_REQUESTED_OFFERS_SUCCESS';
export const ACTIONS_GET_REQUESTED_OFFERS_ERROR = 'ACTIONS_GET_REQUESTED_OFFERS_ERROR';

export const ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START = 'ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START';
export const ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS = 'ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS';
export const ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR = 'ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR';

export const ACTIONS_CREATE_OFFER_SUCCESS = 'ACTIONS_CREATE_OFFER_SUCCESS';
export const ACTIONS_CREATE_REQUESTED_OFFER_SUCCESS = 'ACTIONS_CREATE_REQUESTED_OFFER_SUCCESS';
export const ACTIONS_UPDATE_OFFER_SUCCESS = 'ACTIONS_UPDATE_OFFER_SUCCESS';
export const ACTIONS_GET_OFFER_ERROR = 'ACTIONS_GET_OFFER_ERROR';
export const ACTIONS_DELETE_OFFER_SUCCESS = 'ACTIONS_DELETE_OFFER_SUCCESS';

// ==================List of offer statuses excluding RFO (Requested Offer) statuses =============
const offerStatusList = [
  OfferStatus.O_DRAFT,
  OfferStatus.O_DRAFT_VENDOR,
  OfferStatus.O_BUYER_REVIEW,
  OfferStatus.O_SALES_REVIEW,
  OfferStatus.O_QUOTED,
  OfferStatus.O_IN_PROGRESS,
  OfferStatus.O_QUOTE_CLOSED,
  OfferStatus.O_CLOSING,
  OfferStatus.O_VENDOR_CONFIRMED,
  OfferStatus.O_ORDERED,
  OfferStatus.O_DELIVERED,
  OfferStatus.O_ARCHIVED,
];
// Vendors can't see O_DRAFT status
const offerStatusListVendor = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT
].includes(status));

// AM(aka Sales) can't see O_DRAFT, O_DRAFT_Vendor, O_Buyer_Review statuses
const offerStatusListAM = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT,
  OfferStatus.O_DRAFT_VENDOR,
  OfferStatus.O_BUYER_REVIEW
].includes(status));

// Internal users can't see O_DRAFT_Vendor status
const offerStatusListInternalUsers = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT_VENDOR
].includes(status));

export const useOfferStatusList = () => {
  const isAm = useIsUFPAM();
  const isBuyer = useIsUFPBuyer();
  const isVendor = useIsVendor();
  const isInternalUser = useIsInternalUser();

  if (isAm && !isBuyer) return offerStatusListAM;
  if (isVendor) return offerStatusListVendor;
  if (isInternalUser) return offerStatusListInternalUsers
  return []; // Logistics?... not defined yet
}


// ======================= List of offer statuses for RFO (Requested Offer) =======================
const offerStatusListRFO = [
  OfferStatus.RFO_DRAFT,
  OfferStatus.RFO_DRAFT_VENDOR,
  OfferStatus.RFO_SENT,
  OfferStatus.RFO_DECLINED,
  OfferStatus.RFO_ARCHIVED,
];

// Vendors can't see RFO_DRAFT status
const offerStatusListRFOVendor = offerStatusListRFO.filter(status => ![
  OfferStatus.RFO_DRAFT
].includes(status));

// AM(aka Sales) can't see RFO_DRAFT status
const offerStatusListRFOAM = offerStatusListRFO.filter(status => ![
  OfferStatus.RFO_DRAFT,
].includes(status));

export const useOfferStatusListRFO = () => {
  const isAm = useIsUFPAM()
  const isVendor = useIsVendor();
  const isInternalUser = useIsInternalUser();

  if (isAm) return offerStatusListRFOAM;
  if (isVendor) return offerStatusListRFOVendor;
  if (isInternalUser) return offerStatusListRFO

  return []; // Logistics?... not defined yet
}


export const isOfferDraftStatus = (status: OfferStatus) => {
  return [
    OfferStatus.RFO_DRAFT,
    OfferStatus.RFO_DRAFT_VENDOR,
    OfferStatus.O_DRAFT,
    OfferStatus.O_DRAFT_VENDOR,
  ].includes(status);
};


export const isRequestedOfferStatus = (status: OfferStatus) => { // aka RFO
  return offerStatusListRFO.includes(status);
};


export const isOfferExpired = (validityDate: string) => {
  if (!validityDate) return false;
  return dayjs(validityDate).isBefore(dayjs());
}

export const getDateTimeEndOfTheDayInISO = (date: Date) => {
  const dateTimeEndOfTheDay = new Date(date);
  dateTimeEndOfTheDay.setHours(23, 59, 59);
  return dateTimeEndOfTheDay.toISOString();
}

export const generateTodayPlusSevenDaysDateTimeInISO = (): string => {
  const now = dayjs();

  // Add 7 days + hours till end of the day
  const sevenDaysLaterEndOfDay = now.add(7, 'days').endOf('day');
  return sevenDaysLaterEndOfDay.toISOString();
};

const offersSelector = (state: RootState) => state.offers;
const offerSelectorCreator = (offerId: number) => (state: RootState) => state.offers[offerId];

export const useOffers = (): OffersState => useAppSelector(offersSelector);
export const useOffer = (offerId: number, force = false) => useGetEntity(offerId, getOffer, offerSelectorCreator, force);

const useOfferKeys = () => {
  const offers = useOffers();
  return useMemo(() => Object.keys(offers), [offers]);
};

export const useOfferValue = (offer: OfferView) => {
  return useMemo(() => {
    if (!Array.isArray(offer?.products)) return 0;
    let sum = 0;
    offer.products.forEach(product => {
      sum += (product.quantity || 0) * (product.price || 0);
    });
    return sum;
  }, [offer?.products]);
};

export const useOfferProductCodes = (offer: OfferView) => {
  return useMemo(() => {
    if (!Array.isArray(offer?.products)) return '-';
    return [...new Set(offer.products.map(product => product.productCode))].join(', ');
  }, [offer?.products]);
};

export const useOfferAsReferenceText = (offerId: number) => {
  const offer = useOffer(offerId);
  const suffix = useOfferStatusSuffix();
  return useMemo(() => offerAsReferenceText(offer, suffix), [offer, suffix]);
};

export const useOfferAsReferenceLabels = () => {
  const offers = useOffers();
  const keys = useOfferKeys();
  const suffix = useOfferStatusSuffix();
  return useMemo(() => keys.reduce((a, key) => {
    a[key] = offerAsReferenceText(offers[key], suffix);
    return a;
  }, {}), [keys, offers, suffix]);
};

const offerAsReferenceText = (offer: OfferView, suffix: string) => {
  if (!offer) return '';
  const { id, dateCreated, status, vendorCompanyName, shipFromName } = offer;
  const dateString = dayjs(dateCreated).format('DD MMM YYYY');
  const prefix = isRFO(status) ? 'RO' : 'O';
  const statusString = localizedOfferStatusString(status, suffix);
  return `${prefix}-${id} - ${dateString} - ${statusString} - ${vendorCompanyName} - ${shipFromName}`;
};

interface GetOffersFilters {
  offset: number;
  [key: string]: any;
}
export const getOffers = (filters: GetOffersFilters = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_OFFERS_START });
    const offers = await API.getOffers(filters);
    dispatch({ type: ACTIONS_GET_OFFERS_SUCCESS, offers, offset: filters.offset });
    return offers;
  } catch (error) {
    dispatch({ type: ACTIONS_GET_OFFERS_ERROR, error });
  }
};

export const useGetOffersExcludingRFOStatuses = () => {
  const offerStatusList = useOfferStatusList();

  return useCallback((filters = { offset: 0, status: [] }) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: ACTIONS_GET_OFFERS_START });

      const status = !filters.status?.length
        ? offerStatusList
        : filters.status;
      const offers = await API.getOffers({ ...filters, status });
      dispatch({ type: ACTIONS_GET_OFFERS_SUCCESS, offers, offset: filters.offset });
      return offers;
    } catch (error) {
      dispatch({ type: ACTIONS_GET_OFFERS_ERROR, error });
    }
  }, [offerStatusList]);
}

interface GetRequestedOffersFilters {
  offset: number;
  [key: string]: any;
}

export const getRequestedOffers = (filters: GetRequestedOffersFilters = { offset: 0, status: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_START });

    const status = !filters.status?.length
      ? offerStatusListRFO
      : filters.status;
    const offers = await API.getOffers({ ...filters, status });
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_SUCCESS, offers, offset: filters.offset });
    return offers;
  } catch (error) {
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_ERROR, error });
  }
};

export const getRequestedOffersByStatus = (filters = { offset: 0, status: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START, groupId: filters.status?.[0] });
    const offers = await API.getOffers(filters);
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS, offers, groupId: filters.status?.[0], offset: filters.offset });
    return offers;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR, error, groupId: filters.status?.[0] });
  }
};

export const getOffer = (offerId: number) => async (dispatch: AppDispatch) => {
  try {
    const offer = await API.getOffer({ id: offerId });
    dispatch({ type: 'ACTIONS_GET_OFFER_SUCCESS', offer });
    return offer;
  } catch (error) {
  }
};

export const createOffer = (params: CreateOfferRequest) => async (dispatch: AppDispatch) => {
  try {
    const offer = await API.createOffer({}, params);
    dispatch({ type: ACTIONS_CREATE_OFFER_SUCCESS, offer });
    return offer;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const updateOffer = (params: {id: number} & OfferUpdate) => async (dispatch: AppDispatch) => {
  try {
    const offer = await API.updateOffer({ id: params.id }, params);
    dispatch({ type: ACTIONS_UPDATE_OFFER_SUCCESS, offer });
    return offer;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const deleteOffer = (offerId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.deleteOffer({ id: offerId });
    dispatch({ type: ACTIONS_DELETE_OFFER_SUCCESS, offerId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const getOfferAttachments = (offerId: number) => async (dispatch: AppDispatch) => {
  try {
    return await API.getOfferAttachments({ id: offerId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const addFollower = (offerId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.addOfferMember({ id: offerId, userId });
    dispatch({ type: 'ACTIONS_ADD_OFFER_MEMBER_SUCCESS', offerId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const removeFollower = (offerId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.removeOfferMember({ id: offerId, userId });
    dispatch({ type: 'ACTIONS_REMOVE_OFFER_MEMBER_SUCCESS', offerId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const markOfferRead = (offerId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.postOfferReadMark({ offerId });
    dispatch({ type: "ACTIONS_MARK_OFFER_READ", offerId });
    console.log("sent");
  } catch (e) { } // ignore error
}

export interface OffersState {
  [id: number]: OfferView;
}

const INITIAL_STATE: OffersState = {};

const reducer = (state: OffersState, action: any): OffersState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_OFFERS_SUCCESS:
    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS:
      return { ...state, ...composedItems(action.offers) };

    case 'ACTIONS_GET_OFFER_SUCCESS':
    case ACTIONS_CREATE_OFFER_SUCCESS:
    case ACTIONS_CREATE_REQUESTED_OFFER_SUCCESS:
      return { ...state, [action.offer.id]: action.offer };

    case ACTIONS_UPDATE_OFFER_SUCCESS:
      const offer = action.offer;
      return { ...state, [offer.id]: { ...state[offer.id], ...offer } };

    case ACTIONS_DELETE_OFFER_SUCCESS:
      const offerId = action.offerId;
      const newState = { ...state };
      delete newState[offerId];
      return newState;

    case 'ACTIONS_ADD_OFFER_MEMBER_SUCCESS': {
      if (!state[action.offerId]) return state;
      const updatedOffer = {
        ...state[action.offerId],
        members: [...new Set([...state[action.offerId].members, action.userId])]
      };
      return { ...state, [action.offerId]: updatedOffer };
    }

    case 'ACTIONS_REMOVE_OFFER_MEMBER_SUCCESS': {
      if (!state[action.offerId]) return state;
      const updatedOffer = {
        ...state[action.offerId],
        members: state[action.offerId].members?.filter(member => member !== action.userId)
      };
      return { ...state, [action.offerId]: updatedOffer };
    }

    case 'ACTIONS_MARK_OFFER_READ': {
      if (!state[action.offerId]) return state;
      const updatedOffer = {
        ...state[action.offerId],
        readMark: true
      };
      return { ...state, [action.offerId]: updatedOffer };
    }

    default:
      return state;
  }
};

export default reducer;
