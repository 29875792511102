import { PERIODS, periodToInterval } from "./MetricsData";
import useSWRImmutable from "swr/immutable";
import API from "~/API";

export interface SalesOrderTransactionsFilters {
  period: PERIODS;
  company: number | undefined,
}

export const UseSalesOrderTransactionMetrics = (filters: SalesOrderTransactionsFilters) =>
  useSWRImmutable(
    ["api/metrics/sales/transactions", filters],
    async () => API.getNumberOfSalesOrderTransactionsMetrics({
      ...periodToInterval(filters.period),
      company: filters.company || "" as any,
    }),
  );

export interface PurchaseOrderTransactionsFilters {
  period: PERIODS;
  company: number | undefined;
}

export const UsePurchaseOrderTransactionMetrics = (filters: PurchaseOrderTransactionsFilters) =>
  useSWRImmutable(
    ["api/metrics/purchase/transactions", filters],
    async () => API.getNumberOfPurchaseOrderTransactionsMetrics({
      ...periodToInterval(filters.period),
      company: filters.company || "" as any,
    }),
  );