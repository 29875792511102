import { Route, Switch } from "react-router-dom";
import { companiesRootPath } from "~/Services/Routes";
import { FC } from "react";
import { MyProductsTab } from "~/Pages/Products/MyProductsPage/MyProductsTab";

const CompanyDetailsMyProductsTab: FC<{ isVendor: boolean, id: number }> = (props) => {
  return (
    <Switch>
      <Route path={`${companiesRootPath}:id/:tabType/`}>
        <MyProductsTab type="company" companyId={props.id} />
      </Route>
    </Switch>
  );
};

export default CompanyDetailsMyProductsTab;
