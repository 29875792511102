import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  companiesRootPath,
  companiesPathCustomers,
  companiesPathVendors,
} from "~/Services/Routes";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import { CompanyTabs } from "~/Pages/Companies/Components/CompanyTabs";
import { LocationTabs } from "~/Pages/Companies/Components/LocationTabs";

const CompanyRootPage: FC<{ type: "customers" | "vendors" }> = (props) => (
  <StaticPage>
    <PageTabContainer title="title.companies" showNav>
      <PageTab
        route={companiesRootPath + companiesPathCustomers}
        label="title.customers"
      />
      <PageTab
        route={companiesRootPath + companiesPathVendors}
        label="title.vendors"
      />
    </PageTabContainer>

    <Switch>
      <Redirect
        from={companiesRootPath}
        to={`${companiesRootPath}customers`}
        exact
      />
      <Route path="*/locations">
        <LocationTabs type={props.type} />
      </Route>
      <Route>
        <CompanyTabs type={props.type} key={props.type} />
      </Route>
    </Switch>
  </StaticPage>
);

export default CompanyRootPage;
