import { Link } from "react-router-dom";
import { LC } from "~/Locales";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  useIsInternalCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import {cn} from "~/Utils";
import {
  getPathForSO,
  offersRootPath,
} from "~/Services/Routes";
import {UserName} from "~/Components/Users/UserName";
import PriceView from "~/Components/Views/PriceView";
import { PurchaseOrderPlantView, PurchaseOrderView } from "~/API";
import { FC } from "react";
import { Divider, HDivider } from "~/Components/UI";
import {PurchaseOrderStatusBadge} from "~/Components/Orders/PurchaseOrderStatusBadge";

const PurchaseOrderHeader: FC<{ orderId: number }> = ({ orderId }) => {
  const isInternal = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const purchaseOrder = usePurchaseOrder(orderId, isInternalCustomer);

  const {
    number,
    vendorConfirmation,
    masterPo,
    status,
    buyerUserAccountId,
    accountManagerUserId,
    importIncoTerms,
    importIncoTermsLoc,
    orderNumber,
    offerId,
    salesOrderId,
    invoiceTermsDescription,
    currency,
    millCostTotal,
    plantCostTotal,
    shipToName,
    shipFromName,
    shipFromCode,
  } = (purchaseOrder.data as PurchaseOrderView & PurchaseOrderPlantView) || {};

  const costTotal = isInternalCustomer ? plantCostTotal : millCostTotal;

  return (
    <div className="flex-row flex-wrap p-sm text-left">
      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.ship.from" />
        </div>
        <div className="mt-xxs">{shipFromCode + " - " + shipFromName}</div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.po.#" />
        </div>
        <div className="mt-xxs">{number || "-"}</div>
        {!isInternal && (
          <>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.your.order.#" />
            </div>
            <div className="mt-xxs">{vendorConfirmation || "-"}</div>
          </>
        )}
        {isInternal && (
          <>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.master.po.#" />
            </div>
            <div className="mt-xxs">{masterPo || "-"}</div>
          </>
        )}
      </div>

      <HDivider className="mobile-hide" />

      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.status" />
        </div>
        <div className="my-xxs">
          <PurchaseOrderStatusBadge status={status} />
        </div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.buyer" />
        </div>
        <div className="mt-xxs w-full">
          <UserName id={accountManagerUserId} />
        </div>
        {buyerUserAccountId !== accountManagerUserId && (
          <div className="mt-xxs w-full">
            <UserName id={buyerUserAccountId} />
          </div>
        )}

        {isInternal && (
          <>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.vendor.confirmation.#" />
            </div>
            <div className="mt-xxs">{vendorConfirmation || "-"}</div>
          </>
        )}
        {!isInternal && (
          <>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.destination" />
            </div>
            <div className="mt-xxs">{`${importIncoTerms} - ${importIncoTermsLoc}`}</div>
          </>
        )}
      </div>
      <HDivider className="mobile-hide" />

      {isInternal && (
        <>
          <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
            <div className="text-sm color-text-3">
              <LC id="title.ship.to" />
            </div>
            <div className="mt-xxs">{shipToName || "-"}</div>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.destination" />
            </div>
            <div className="mt-xxs">{`${importIncoTerms} - ${importIncoTermsLoc}`}</div>
            <div className="text-sm mt-md color-text-3">
              <LC id="title.sales.order.#" />
            </div>
            <div className="mt-xxs">
              {!!salesOrderId && !!orderNumber && orderNumber !== "0" ? (
                <Link to={getPathForSO(salesOrderId)} className={"Link"}>
                  {orderNumber}
                </Link>
              ) : !!orderNumber && orderNumber !== "0" ? (
                orderNumber
              ) : (
                "-"
              )}
            </div>
          </div>
          <HDivider className="mobile-hide" />
        </>
      )}

      <div className="flex flex-col align-start my-sm mx-lg mobile-flex-basis-full">
        {isInternal && (
          <>
            <div className="text-sm color-text-3">
              <LC id="title.invoice.terms" />
            </div>
            <div className="mt-xxs">{invoiceTermsDescription}</div>
          </>
        )}
        <div
          className={cn("text-sm color-text-3", isInternal && "mt-md")}
        >
          <LC id="title.amount" />
        </div>
        <div className="mt-xxs w-full flex-row justify-between">
          <LC id="title.total.sales.value" />:
          <div className="color-orange-1">
            <PriceView price={costTotal || 0} currency={currency} />
          </div>
        </div>

        <div className="text-sm mt-md color-text-3">
          <LC id="title.offer.#" />
        </div>
        <div className="mt-xxs">
          {!!offerId && offerId !== 0 ? (
            <a href={`${offersRootPath}${offerId}`} target="_blank">
              O-{offerId}
            </a>
          ) : (
            "-"
          )}
        </div>
      </div>

      <Divider />
    </div>
  );
};

export default PurchaseOrderHeader;
