import API, {
  RequestForQuoteStatus,
  RequestForQuoteUpdate,
  RequestsForQuotesByShipToSummary,
  RequestsForQuotesByStatusSummary,
} from "~/API";
import { useMemo } from "react";
import { useSearchParams } from "~/Services/Routes";
import { composedItems } from "~/Reducers/Utils/Compose";
import {
  ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_ERROR,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_START,
  ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_ERROR,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_START,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_SUCCESS,
} from "~/Reducers/RequestsForQuote";
import {
  deleteRequestForQuote,
  updateRequestForQuote,
} from "~/Reducers/RequestsForQuote";
import { useIsAdmin, useIsUFPAM } from "~/Reducers/User";
import {AppDispatch, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";
import {ls} from "~/Locales";

const EMPTY_BY_GROUP_PAGE_DATA = { ids: [], loading: false, error: null };

export const useShouldShowArchiveButton = (status: RequestForQuoteStatus) => {
  const isAdmin = useIsAdmin();
  const isUFPAM = useIsUFPAM();
  const isArchived = status === RequestForQuoteStatus.ARCHIVED;
  return (isUFPAM || isAdmin) && !isArchived;
};

export const useRequestsForQuotePageReduxState = () =>
  useAppSelector((state) => state.requestsForQuotePage);
export const useRequestsForQuotePageByGroupReduxState = (groupId: string) =>
  useAppSelector(
    (state) => state.requestsForQuotePage.requestIdsByGroups[groupId],
  ) || EMPTY_BY_GROUP_PAGE_DATA;

export const useShipTo = (shipToId: string) =>
  useAppSelector((state) => state.requestsForQuotePage.shipTos[shipToId]);

export const getRequestsForQuoteStatusesTotalInfo =
  (filters: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_START" });
      const statuses = await API.getRequestsForQuotesByStatusSummary(filters);
      dispatch({
        type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_SUCCESS",
        statuses,
      });
    } catch (error) {
      dispatch({
        type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_ERROR",
        error,
      });
    }
  };

export const deleteRequest =
  (requestId: number, groupId: string, onDelete: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(deleteRequestForQuote(requestId));
      dispatch({
        type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_DELETE_REQUEST",
        requestId,
        groupId,
      });
      onDelete();
      Toast.Success(ls("phrase.rfq.deleted.successfully", requestId));
    } catch {}
  };

export const archiveRequest =
  (
    request: {
      id: number;
    } & RequestForQuoteUpdate,
    groupId: string,
    onArchive: () => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(updateRequestForQuote(request));
      dispatch({
        type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_ARCHIVE_REQUEST",
        requestId: request.id,
        groupId,
      });
      onArchive();
      Toast.Success(ls("phrase.rfq.archived.successfully", request.id));
    } catch {}
  };

export const clearData = () => ({
  type: "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_CLEAR_DATA",
});

export enum RequestsPageTableTypes {
  REQUESTS_PAGE_NONE = "REQUESTS_PAGE_NONE",
  REQUESTS_PAGE_STATUS = "REQUESTS_PAGE_STATUS",
  REQUESTS_PAGE_SHIP_TO = "REQUESTS_PAGE_SHIP_TO",
}

export const useDefaultPageFilters = () => {
  const [searchParams] = useSearchParams();
  const withExpired =
    searchParams?.onlyArchivedOrDeliveredOrExpired?.toLowerCase() === "true";

  return useMemo(
    () => ({
      search: "",
      onlyMy: true,
      tableType: RequestsPageTableTypes.REQUESTS_PAGE_NONE,
      limit: 40,
      status: [],
      offer: [],
      customer: [],
      shipTo: [],
      accountManager: [],
      product: [],
      productGroup: [],
      port: [],
      incoterm: [],
      valueFrom: "",
      valueTo: "",
      validityDateFrom: "",
      validityDateTo: "",
      withChangesOnly: false,
      onlyArchivedOrDeliveredOrExpired: false,
      withoutArchivedOrDelivered: !withExpired,
      withExpired,
    }),
    [withExpired],
  );
};

export interface RequestsForQuotePageState {
  ids: number[];
  statuses: RequestsForQuotesByStatusSummary[];
  requestIdsByGroups: {
    [id: string]: { ids: number[]; loading: boolean; error: any };
  };
  shipTos: { [key: string]: RequestsForQuotesByShipToSummary };
  loading: boolean;
  error: any;
}

// Reducer
const INITIAL_STATE: RequestsForQuotePageState = {
  ids: [], // - ids
  statuses: [], // - quote statuses info
  requestIdsByGroups: {},
  shipTos: {},
  loading: false,
  error: null,
};

const reducer = (
  state: RequestsForQuotePageState,
  action: any,
): RequestsForQuotePageState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case ACTIONS_GET_REQUESTS_FOR_QUOTE_START:
    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_START":
    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_START:
      return { ...state, loading: true, error: null };

    case ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS: {
      const loadedIds = action.requests.map((request) => request.id);
      return {
        ...state,
        ids: !!action.offset
          ? [...new Set([...state.ids, ...loadedIds])]
          : loadedIds,
        loading: false,
        error: null,
      };
    }

    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_SUCCESS: {
      const loadedIds = action.locations.map((location) => location.id);
      return {
        ...state,
        ids: !!action.offset
          ? [...new Set([...state.ids, ...loadedIds])]
          : loadedIds,
        shipTos: { ...state.shipTos, ...composedItems(action.locations) },
        loading: false,
        error: null,
      };
    }

    case ACTIONS_GET_REQUESTS_FOR_QUOTE_ERROR:
    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_ERROR":
    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_ERROR:
      return { ...state, loading: false, error: action.error };

    case ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START:
      return {
        ...state,
        requestIdsByGroups: {
          ...state.requestIdsByGroups,
          [action.groupId]: {
            ...state.requestIdsByGroups[action.groupId],
            loading: true,
            error: null,
          },
        },
      };

    case ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS: {
      const loadedIds = action.requests.map((request) => request.id);
      const oldIds = state.requestIdsByGroups[action.groupId]?.ids || [];
      return {
        ...state,
        requestIdsByGroups: {
          ...state.requestIdsByGroups,
          [action.groupId]: {
            ids: !!action.offset
              ? [...new Set([...oldIds, ...loadedIds])]
              : loadedIds,
            loading: false,
            error: null,
          },
        },
      };
    }

    case ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR:
      return {
        ...state,
        requestIdsByGroups: {
          ...state.requestIdsByGroups,
          [action.groupId]: {
            ...state.requestIdsByGroups[action.groupId],
            loading: false,
            error: action.error,
          },
        },
      };

    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_DELETE_REQUEST": {
      if (!!action.groupId) {
        const updatedData = {
          ...state.requestIdsByGroups[action.groupId],
          ids: state.requestIdsByGroups[action.groupId]?.ids?.filter(
            (id) => id !== action.requestId,
          ),
        };
        return {
          ...state,
          requestIdsByGroups: {
            ...state.requestIdsByGroups,
            [action.groupId]: updatedData,
          },
        };
      } else {
        const updatedData = {
          ...state,
          ids: state.ids.filter((id) => id !== action.requestId),
        };
        return updatedData;
      }
    }

    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_ARCHIVE_OFFER": {
      if (!!action.groupId) {
        const updatedGroupData = {
          ...state.requestIdsByGroups[action.groupId],
          ids: state.requestIdsByGroups[action.groupId]?.ids?.filter(
            (id) => id !== action.requestId,
          ),
        };
        return {
          ...state,
          requestIdsByGroups: {
            ...state.requestIdsByGroups,
            [action.groupId]: updatedGroupData,
          },
        };
      } else {
        return state;
      }
    }

    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_GET_STATUSES_SUCCESS":
      return {
        ...state,
        statuses: action.statuses,
        loading: false,
        error: null,
      };

    case "ACTIONS_REQUESTS_FOR_QUOTE_PAGE_CLEAR_DATA":
      return INITIAL_STATE;

    case ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS: {
      if (
        !state.statuses.length &&
        !Object.keys(state.requestIdsByGroups).length &&
        !Object.keys(state.shipTos).length
      ) {
        return {
          ...state,
          ids: [action.request.id, ...state.ids],
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
