import { FC, useMemo } from "react";
import API, { RandomLengthLumberView } from "~/API";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  editControlsColumn,
  FormInput,
  Table,
} from "~/Components/UI";
import { ls } from "~/Locales";
import useSWRInfinite from "swr/infinite";
import PriceView from "~/Components/Views/PriceView";
import DateView from "~/Components/Views/DateView";
import { useIsAdmin } from "~/Reducers/User";
import { RLLumberTypeSelect } from "./RandomLengthTypeSelect";
import {PriceEdit, PriceEditView} from "./PriceEditView";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {LinearLoader} from "~/Components/Loaders";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {useSortState} from "~/Hooks/useSortState";
import {trimUndefined} from "~/Utils";

const getListKey = (
  filters: { search: string },
  offset: number,
  previousPageData: RandomLengthLumberView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/rl/lumber", ...filters, offset };
};

const PAGE_SIZE = 40;

const useRLLumber = (filters: any) =>
  useSWRInfinite(
    (i, p) => getListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getRandomLengthLumber({
        ...filters,
        offset: key.offset,
        limit: PAGE_SIZE,
      }),
  );

export const RLLumberTable: FC<{ search: string }> = (props) => {
  const sort = useSortState();
  const lumber = useRLLumber(
    trimUndefined({ search: props.search, ...sort.data })
  );
  const isAdmin = useIsAdmin();

  const onScrolledToBottom = () => lumber.setSize(lumber.size + 1);

  const data = useMemo(() => lumber.data?.flat() || [], [lumber.data]);

  const editRow = useFormRowEdit<RandomLengthLumberView>("id", async (data) => {
    await API.updateRandomLengthLumber(
      { id: data.id },
      data,
    );
    await lumber.mutate();
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editRow,
    },
    initialState: {
      columnVisibility: {
        edit: isAdmin,
      },
      columnPinning: {
        right: ["editControls"],
      },
    },
  });

  return (
    <>
      {editRow.rowIndex != null && <UnsavedChangesDialog onSave={editRow.submit.mutate} />}
      <LinearLoader loading={lumber.isLoading} />
      <Table
        table={table}
        onScrolledBottom={onScrolledToBottom}
        sort={sort}
      />
    </>
  );
};

const columnHelper = createColumnHelper<RandomLengthLumberView>();

const columns = [
  columnHelper.accessor("rlType", {
    header: ls("title.rl.type"),
    sortId: "rl_type",
    minSize: 300,
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">{value}</div>
    ),
    editCell: ({ editRow }) => (
      <RLLumberTypeSelect
        id="rlType"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("productCode", {
    header: ls("title.code"),
    sortId: "product_code",
    minSize: 100,
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("productDescription", {
    header: ls("title.description"),
    sortId: "product_desc",
    minSize: 400,
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("productDimension", {
    header: ls("title.dimension"),
    minSize: 150,
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.accessor("dateUpdated", {
    header: ls("title.date.last.updated"),
    sortId: "date_updated",
    minSize: 100,
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
  columnHelper.accessor("price8Inches", {
    header: "8'",
    sortId: "price_8_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price8InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price8Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price10Inches", {
    header: "10'",
    sortId: "price_10_inches",
    minSize: 75,
    cellMemo: ({ value, row}) => (
      <PriceEditView
        value={value}
        editUserName={row.price10InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price10Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price12Inches", {
    header: "12'",
    sortId: "price_12_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price12InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price12Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price14Inches", {
    header: "14'",
    sortId: "price_14_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price14InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price14Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price16Inches", {
    header: "16'",
    sortId: "price_16_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price16InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price16Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price18Inches", {
    header: "18'",
    sortId: "price_18_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price18InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price18Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price20Inches", {
    header: "20'",
    sortId: "price_20_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price20InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price20Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price22Inches", {
    header: "22'",
    sortId: "price_22_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price22InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price22Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("price24Inches", {
    header: "24'",
    sortId: "price_24_inches",
    minSize: 75,
    cellMemo: ({ value, row }) => (
      <PriceEditView
        value={value}
        editUserName={row.price24InchesUpdatedByUserFullName}
      />
    ),
    editCell: ({ editRow }) => (
      <PriceEdit
        rowId="price24Inches"
        editRow={editRow}
      />
    ),
  }),
  columnHelper.accessor("total", {
    header: ls("title.total.$.mbf"),
    minSize: 100,
    cellMemo: ({ value }) => <PriceView price={value} />,
  }),
  columnHelper.accessor("oneByTwoPrimeAdder", {
    header: ls("title.1/2.prime.adder"),
    sortId: "one_by_two_prime_adder",
    minSize: 150,
    cellMemo: ({ value }) => (
      <PriceView price={value} />
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="oneByTwoPrimeAdder"
        formData={editRow.form}
        type="number"
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("inboundFreight", {
    header: ls("title.inbound.freight.$.mbf"),
    sortId: "inbound_freight",
    minSize: 150,
    cellMemo: ({ value }) => (
      <PriceView price={value} />
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="inboundFreight"
        formData={editRow.form}
        type="number"
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("milling", {
    header: ls("title.milling.$.mbf"),
    sortId: "milling",
    minSize: 150,
    cellMemo: ({ value }) => (
      <PriceView price={value} />
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="milling"
        formData={editRow.form}
        type="number"
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("treating", {
    header: ls("title.treating.$.mbf"),
    sortId: "treating",
    minSize: 150,
    cellMemo: ({ value }) => (
      <PriceView price={value} />
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="treating"
        formData={editRow.form}
        type="number"
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("outboundFreight", {
    header: ls("title.outbound.freight.$.mbf"),
    sortId: "outbound_freight",
    minSize: 150,
    cellMemo: ({ value }) => (
      <PriceView price={value} />
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="outboundFreight"
        formData={editRow.form}
        type="number"
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code8Inches", {
    header: "8'",
    sortId: "code_8_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code8Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code10Inches", {
    header: "10'",
    sortId: "code_10_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code10Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code12Inches", {
    header: "12'",
    sortId: "code_12_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code12Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code14Inches", {
    header: "14'",
    sortId: "code_14_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code14Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code16Inches", {
    header: "16'",
    sortId: "code_16_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code16Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code18Inches", {
    header: "18'",
    sortId: "code_18_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code18Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code20Inches", {
    header: "20'",
    sortId: "code_20_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code20Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code22Inches", {
    header: "22'",
    sortId: "code_22_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code22Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("code24Inches", {
    header: "24'",
    sortId: "code_24_inches",
    minSize: 75,
    cellMemo: ({ value }) => (
      value || "-"
    ),
    editCell: ({ editRow }) => (
      <FormInput
        id="code24Inches"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  editControlsColumn(columnHelper)
];
