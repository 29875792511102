import {AppDispatch, useAppSelector} from "~/StoreTypes";


export const useRouter = () => useAppSelector(state => state.router);

export const setRouterHasHistory = (location: string, action: any) => (dispatch: AppDispatch) => {//console.warn(`${action}: ${location}`);
  if (action === 'REPLACE') {
    dispatch({ type: 'ACTIONS_ROUTER_REPLACE_HISTORY', location });
  } else {
    dispatch({ type: 'ACTIONS_ROUTER_PUSH_HISTORY', location });
  }
};

export interface RouterState {
  hasHistory: boolean;
  lastLocation: string;
  previousLocation: string;
}

// reducer
const INITIAL_STATE: RouterState = {
  hasHistory: false,
  lastLocation: '',
  previousLocation: '',
};

const reducer = (state: RouterState, action: any) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ACTIONS_ROUTER_PUSH_HISTORY':
      return {
        ...state,
        hasHistory: true,
        lastLocation: action.location,
        previousLocation: state.lastLocation
      };

    case 'ACTIONS_ROUTER_REPLACE_HISTORY':
      return {
        ...state,
        lastLocation: action.location,
      };

    default:
      return state;
  }
};

export default reducer;
