import SelectWithPaging from "./SelectWithPaging";
import useFetchMissingItems from "./useFetchMissingItems";
import {
  getProductGroupsPublic,
  useProductGroupsPublic,
  useProductGroupsPublicLabels
} from "~/Reducers/ProductGroupsPublic";


const ProductGroupsSelectPublic = (props) => {
  const productGroups = useProductGroupsPublic();
  useFetchMissingItems(props.value, productGroups, getProductGroupsPublic);

  return <SelectWithPaging {...props}
    getData={getProductGroupsPublic}
    useDataLabels={useProductGroupsPublicLabels}
  />;
};

export default ProductGroupsSelectPublic;
