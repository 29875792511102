import { LocalizedMessage } from "~/Locales";
import Button from "~/Components/Old/Button";
import { ChatIcon } from "~/Components/Old/Icons";
import Vars from "~/Styles/Vars";
import { FC } from "react";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {useRequestForQuote} from "~/Reducers/RequestsForQuote";

const RequestChatButton: FC<{
  requestId: number;
  showChat: boolean;
  toggleShowChat: () => void;
}> = ({ requestId, showChat, toggleShowChat }) => {
  const request = useRequestForQuote(requestId);
  return (
    <div className={"flex-row align-center ChatButton"}>
      <Button
        view={"text"}
        className={"px-0 mx-sm"}
        leftIcon={<ChatIcon size={16} />}
        rightIcon={<ChatUnreadBadge unread={request.unreadChatMessages} />}
        style={{ color: showChat ? Vars.colors.blue1 : Vars.colors.text1 }}
        onClick={toggleShowChat}
      >
        <LocalizedMessage id={"title.chat"} />
      </Button>
    </div>
  );
};

export default RequestChatButton;
