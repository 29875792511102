import { PERIODS, periodToInterval } from "./MetricsData";
import useSWRImmutable from "swr/immutable";
import API from "~/API";

export interface QuotesToOrdersFilters {
    period: PERIODS;
    am?: number;
    company?: number;
}

export const UseQuotesToOrdersMetrics = (filters: QuotesToOrdersFilters) =>
    useSWRImmutable(
        ["api/metrics/quotes/converted", filters],
        async () => API.getQuotesToOrdersMetrics({
            ...periodToInterval(filters.period),
            am: filters.am || "" as any,
            company: filters.company || "" as any,
        }),
    );
