import {FC, ReactNode} from "react";

const DashboardEmptyState: FC<{
  title: ReactNode;
  description1?: ReactNode;
  description2?: ReactNode;
}> = (props) => {
  const { title, description1, description2 } = props;
  return (
    <div className="flex-col w-full h-full align-center justify-center">
      <div className="text-lg">{title}</div>
      {!!description1 && (
        <div className="color-text-2 mt-md">{description1}</div>
      )}
      {!!description2 && (
        <div className="color-text-2 mt-md">{description2}</div>
      )}
    </div>
  );
};

export default DashboardEmptyState;
