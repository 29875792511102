import { useParams } from "react-router-dom";
import { useIsVendor } from "~/Reducers/User";
import SalesOrderDetailsPage from "./SalesOrderDetailsPage";
import PurchaseOrderDetailsPage from "./PurchaseOrderDetailsPage";
import { ordersPathPurchase } from "~/Services/Routes";
import { FC } from "react";

const OrderDetailsPage: FC = () => {
  const isVendor = useIsVendor();
  const { type } = useParams<{ type: string }>();

  if (isVendor || type?.toLowerCase() === ordersPathPurchase)
    return <PurchaseOrderDetailsPage />;

  return <SalesOrderDetailsPage />;
};

export default OrderDetailsPage;
