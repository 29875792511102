import { FC, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestsRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import {
  addFollower,
  removeFollower,
  useRequestForQuote,
} from "~/Reducers/RequestsForQuote";
import { useRouter } from "~/Reducers/Router";
import {
  useCurrentUser,
  useIsInternalUser,
} from "~/Reducers/User";
import {
  useIsMatching,
  useIsNew,
  useIsReadOnly,
} from "./Components/RequestsHelper";
import DetailsBackButton from "~/Components/Old/DetailsBackButton";
import {FollowButton} from "~/Components/Entities/FollowButton";
import UserParticipantList from "~/Components/Users/UserParticipantList";
import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import RequestDetailsGeneralTab from "./RequestDetailsGeneralTab";
import RequestChat from "./Components/RequestChat";
import RequestChatButton from "./Components/RequestChatButton";
import "./RequestDetailsPage.scss";
import {useAppDispatch} from "~/StoreTypes";
import {useUsersList} from "~/Data/Users";
import {getRequestForQuoteChatId} from "~/Data/Chats";
import {trackEvent} from "~/Services/Tracking";

const RequestDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();
  const { id: requestId } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const isEdit = !useIsReadOnly();
  const isMatching = useIsMatching();
  const chat = getRequestForQuoteChatId(Number(requestId));
  const { members } = useRequestForQuote(isNew ? null : Number(requestId)) || {};
  const membersList = useUsersList(members || []);

  const [showChat, toggleShowChat] = useToggle(false);

  const onBack = useCallback(() => {
    if (!isNew && (isEdit || isMatching)) {
      history.replace(`${requestsRootPath}${requestId}`);
    } else if (!router.hasHistory) {
      history.replace(requestsRootPath);
    } else {
      history.goBack();
    }
  }, [router, history, isNew, isEdit, requestId]);

  const onAddMember = useCallback(
    (userId) => {
      dispatch(addFollower(Number(requestId), userId));
      trackEvent("Request Follower Added");
    },
    [requestId, dispatch],
  );

  const onRemoveMember = useCallback(
    (userId) => {
      dispatch(removeFollower(Number(requestId), userId));
      trackEvent("Request Follower Removed");
    },
    [requestId, dispatch],
  );

  const isMember = useMemo(
    () => members?.some((memberId) => memberId === currentUser.id),
    [members, currentUser],
  );
  const showRightPanel = !isNew && isMember;
  const showMemberList = showRightPanel && isInternalUser;
  const onFollow = useCallback(
    async () => onAddMember(currentUser.id),
    [onAddMember, currentUser],
  );
  const onUnfollow = useCallback(
    async () => onRemoveMember(currentUser.id),
    [onRemoveMember, currentUser],
  );

  return (
    <>
      <div className={"scroll-view"}>
        <PageTitleContainer
          title={""}
          menuButton={<DetailsBackButton onBack={onBack} />}
        />
        <div className={"flex-row align-center mt-lg mx-lg mb-xs"}>
          <div className={"text-xl"}>
            <LocalizedMessage id={"title.request.id.with.id"}>
              {requestId}
            </LocalizedMessage>
          </div>
          <SpaceFiller />
          {!isNew && (
            <FollowButton
              {...{ isMember, onFollow, onUnfollow }}
              chat={chat.data}
              followLabel="title.follow.request"
              unfollowDialogTitle="phrase.unfollow.request.confirm.1"
              unfollowDialogText="phrase.unfollow.request.confirm.2"
            />
          )}
          {showMemberList && (
            <UserParticipantList
              members={membersList.data || []}
              onAddMember={onAddMember}
              onRemoveMember={onRemoveMember}
            />
          )}
          {showRightPanel && (
            <RequestChatButton
              {...{ requestId: Number(requestId), showChat, toggleShowChat }}
            />
          )}
        </div>

        <RequestDetailsGeneralTab {...{ onBack }} />
      </div>

      {showRightPanel && (
        <RightPanel visible={showChat}>
          <RequestChat onClose={toggleShowChat} requestId={Number(requestId)} />
        </RightPanel>
      )}
    </>
  );
};

export default RequestDetailsPage;
