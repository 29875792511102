import { QuoteStatus } from "~/API";
import { localizedString } from "~/Locales";
import SelectWithoutPaging from "./SelectWithoutPaging";

const statusKeys = Object.values(QuoteStatus);
const labels = statusKeys.reduce((a, key) => {
  a[key] = localizedString(`quote.status.${key}`);
  return a;
}, {});

const QuoteStatusSelect = (props) => {
  return (
    <SelectWithoutPaging
      {...props}
      data={statusKeys}
      labels={labels}
      withFilter={false}
      skipLabelSorting
    />
  );
};

export default QuoteStatusSelect;
