import * as Sentry from "@sentry/react";
import App from "./App";
import { Provider } from "react-redux";
import {BrowserRouter} from "react-router-dom";
import Network from "./Services/Network";
import BuildInfo from "./BuildInfo";
import {sentryConfig, sentryFeedback} from "./Services/Tracking";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from 'history';
import Store from "~/Store";

// @ts-ignore
window.buildInfo = BuildInfo;

const history = createBrowserHistory();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history: history as any
    }),
    sentryFeedback
  ],
  ...sentryConfig,
});
Network.initializeAPI();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
