import { FC, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {LC} from "~/Locales";
import { useAppDispatch } from "~/StoreTypes";
import { useCurrentUser } from "~/Reducers/User";
import {LoginData} from "~/API";
import { passwordRequirementsResetPath } from "~/Services/Routes";
import BaseLoginPage from "./BaseLoginPage";
import environment from "~/Services/Environment";
import {useFormData, usePageTitle} from "~/Hooks";
import {z} from "zod";
import {zodMsg} from "~/Utils";
import {Button, FormInput} from "~/Components/UI";
import {useSignIn} from "~/Data/CurrentUser";

const formValidation = z.object({
  email: z.string()
    .min(1, zodMsg("error.non.empty", ["Email"]))
    .email(zodMsg("error.invalid.email")),
  password: z.string().min(1, zodMsg("error.non.empty", ["Password"])),
});

const SignInPage: FC<{ initialLocation: string }> = ({ initialLocation }) => {
  usePageTitle("Sign In");

  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const signIn = useSignIn(dispatch);

  const loginForm = useFormData<LoginData>({
    email: "",
    password: "",
  }, { validation: formValidation });

  const onLoginClicked = async () => {
    if (!loginForm.isValid())
      return;

    signIn
      .call(loginForm.data)
      .then((user) => {
        history.push("/dashboard");
        if (user.needsPasswordReset) {
          history.push(passwordRequirementsResetPath);
        } else {
          if (initialLocation && initialLocation !== "") {
            history.push(initialLocation);
          } else {
            history.push("/dashboard");
          }
        }
      });
  };

  useEffect(() => {
    // skip sign-in page if user is already signed in
    // local dev skips b/c token doesn't clear on refresh
    if (!!currentUser.id && !environment.isLocalhost) {
      history.push("/dashboard");
    }
  }, [currentUser.id]);

  return (
    <BaseLoginPage>
      <div className="text-2xl">
        <LC id="title.log.in" />
      </div>

      {signIn.error && (
        <div className="bg-red-7 color-red-1 px-sm py-xs mt-md rounded-xxs">
          <LC id="phrase.username.password.not.correct" />
        </div>
      )}

      <div className="flex-col gap-md mt-md">
        <FormInput id="email" type="email" formData={loginForm} label="title.email" />
        <FormInput id="password" type="password" formData={loginForm} label="title.password" />
      </div>

      <Button
        onClicked={onLoginClicked}
        loading={signIn.isMutating}
        className="w-full mt-lg"
        label="title.log.in"
        dataCy="login-btn"
      />

      <div className="text-sm mt-md w-full text-center">
        <LC id="phrase.dont.have.account">
          <a
            href="https://pages.insightly.services/TBregister"
            target="_blank"
            className="Link"
            rel="noreferrer"
          >
            <LC id="title.register" />
          </a>
        </LC>
      </div>

      <div className="text-sm mt-md w-full text-center">
        <Link to="/forgot-password">
          <LC id={"forgot.password.short"} />
        </Link>
      </div>
    </BaseLoginPage>
  );
};

export default SignInPage;
