import PriceView from "~/Components/Views/PriceView";
import { FC } from "react";

const OrderPositionPricePerUnit: FC<{
  priceUom: string | null;
  price: number | null;
  currency: string | null;
}> = ({ priceUom, price, currency }) => {
  if (priceUom && price) {
    return (
      <span className="color-orange-1 text-no-wrap">
        <PriceView price={price} currency={currency} /> / {priceUom}
      </span>
    );
  } else if (price) {
    return <PriceView price={price} currency={currency} />;
  } else {
    return <span>-</span>;
  }
};

export default OrderPositionPricePerUnit;
