import {FC, ReactNode} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {LC, LocalizedMessageKey} from "~/Locales";
import {useUserPreferences} from "~/Reducers/UserPreferences";
import {cn} from "~/Utils";
import {Button, Show} from "~/Components/UI";
import {NotificationButton} from "~/Components/Notifications/NotificationButton";
import {useAppDispatch} from "~/StoreTypes";
import {openLeftMenu} from "~/Reducers/LeftMenu";
import {TbMenu2} from "react-icons/tb";

export const PageTabContainer: FC<{
  title?: LocalizedMessageKey | ReactNode;
  showNav?: boolean;
  className?: string;
  children: ReactNode;
}> = (props) => {
  const dispatch = useAppDispatch();
  const settings = useUserPreferences();
  const showBellNotifications = !!settings.showBellNotifications;

  const onLeftMenuClicked = () => dispatch(openLeftMenu());

  return (
    <div
      className={cn(
        "flex-row gap-xs flex-wrap align-center mx-sm mb-xs",
        props.className,
      )}
    >
      <Show when={props.showNav}>
        <Button
          color="gray"
          variant="text"
          className="desktop-hide"
          onClicked={onLeftMenuClicked}
          leftIcon={<TbMenu2 size={20}/>}
        />
      </Show>
      <Show when={props.title}>
        <div className="color-text-1 text-xl text-semibold mx-lg" data-cy="page-title">
          <LC id={props.title as LocalizedMessageKey} />
        </div>
      </Show>
    <div className="mobile-hide overflow-x-auto flex-row gap-xs p-xxs align-center">
        {props.children}
      </div>
      <Show when={showBellNotifications && props.showNav}>
        <div className="flex flex-row justify-end align-center">
          <NotificationButton/>
        </div>
      </Show>
      <div className="desktop-hide w-full flex-row overflow-x-auto p-xxs">
        {props.children}
      </div>
    </div>
  );
};

export const PageTab: FC<{
  route: string;
  label: LocalizedMessageKey;
  rightIcon?: ReactNode;
  replace?: boolean;
}> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const isActive = location.pathname.includes(props.route);
  const onClick = () => {
    if (props.replace)
      history.replace(props.route);
    else
      history.push(props.route);
  };

  return (
    <button
      onClick={onClick}
      aria-label={`Tab to ${props.label}`}
      className={cn(
        "rounded-xxs px-md py-xs text-bold cursor-pointer border-none focus-outline-2 no-wrap flex-row align-center gap-xs",
        isActive && "bg-blue-8 color-blue-1 focus-outline-blue-1",
        !isActive &&
        "bg-none color-black-7 hover-color-black-6 focus-outline-black-4",
      )}
    >
      <LC id={props.label} />
      <Show when={props.rightIcon}>
        {props.rightIcon}
      </Show>
    </button>
  );
};
