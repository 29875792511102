import {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useCurrentUser, useIsInternalUser } from "~/Reducers/User";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger, Show,
} from "~/Components/UI";
import { TbPlus } from "react-icons/tb";
import { cn } from "~/Utils";
import { UserAvatar, UserAvatarText } from "~/Components/Users/UserAvatar";
import CompanyView from "~/Components/Views/CompanyView";
import {UserCard} from "~/Components/Users/UserCard";
import {useFormData} from "~/Hooks";
import {UserAccountPublicView} from "~/API";
import {UserComboboxByCompany} from "~/Components/Users/UserComboboxByCompany";

const UserParticipantList: FC<{
  members: UserAccountPublicView[];
  onAddMember: (member: number) => void;
  onRemoveMember: (member: number) => void;
  disableAddUsers?: boolean;
  includeCompany?: number;
}> = (props) => {
  let shownMembers = props.members;
  let collapsedUsers = 0;
  if (props.members.length > 5) {
    shownMembers = props.members.slice(0, 4);
    collapsedUsers = props.members.length - shownMembers.length;
  }

  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();
  const canAddMembers =
    !props.disableAddUsers && (isInternalUser || props.members.findIndex(m => m.id === currentUser.id) !== -1);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <button
            className={cn(
              "m-xxs flex-row pl-sm pr-md py-xs",
              "border-none bg-none  rounded-xxs",
              "hover-bg-black-10 focus-outline-2 focus-outline-black-4 cursor-pointer",
            )}
          >
            {canAddMembers && (
              <div className="w-lg">
                <UserAvatarText label="+" className="bg-black-7" />
              </div>
            )}
            {shownMembers.map((member) => (
              <div className="w-lg" key={member.id}>
                <UserAvatar
                  id={member.id}
                  className="outline-2 outline-black-12"
                />
              </div>
            ))}
            {collapsedUsers > 0 && (
              <div className="w-lg">
                <UserAvatarText
                  label={`+${collapsedUsers}`}
                  className="bg-black-7"
                />
              </div>
            )}
          </button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-min-9xl h-max-9xl flex-row w-full p-xs">
          <MemberListDropDown
            {...{
              members: props.members,
              onAddMember: props.onAddMember,
              onRemoveMember: props.onRemoveMember,
              canAddMembers: canAddMembers,
              includeCompany: props.includeCompany
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default UserParticipantList;

const MemberListDropDown: FC<{
  members: UserAccountPublicView[];
  onAddMember: (member: number) => void;
  onRemoveMember: (member: number) => void;
  canAddMembers: boolean;
  includeCompany?: number;
}> = (props) => {
  const companyId = useRef(-1);
  const isInternalUser = useIsInternalUser();

  const addUserForm = useFormData<{ user: number | undefined }>({
    user: undefined
  });

  const onAdd = () => {
    props.onAddMember(addUserForm.data.user!);
    addUserForm.clear();
  }

  const memberList = useMemo(() => {
    if (props.members === undefined)
      return [];
    return [...props.members].sort((a, b) => {
      const aName = a?.companyId || "";
      const bName = b?.companyId || "";
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }, [props.members]);

  companyId.current = -1;

  return (
    <div className="flex-col gap-xs py-xxs">
      <Show when={props.canAddMembers}>
        <div className="flex-row w-full mb-md">
          <UserComboboxByCompany
            id="user"
            formData={addUserForm}
            containerClassName="flex"
            placeholder="title.select.user"
            excludeUsers={props.members.map(p => p.id)}
            includeCompany={props.includeCompany}
            showClear
          />
          <Button
            onClicked={onAdd}
            leftIcon={<TbPlus size={16} />}
            disabled={!addUserForm.data.user}
            label="title.add"
            className="ml-xs"
          />
        </div>
      </Show>

      {memberList.map((user, i) => {
        const showCompanyTitle = companyId.current !== user?.companyId && isInternalUser;
        companyId.current = user?.companyId;
        return (
          <Fragment key={user.id}>
            {showCompanyTitle && (
              <div className={cn("text-semibold", i != 0 && "mt-lg")}>
                <CompanyView id={companyId.current} />
              </div>
            )}
            <MemberRow userId={user.id} onRemoveMember={props.onRemoveMember} canRemove={props.canAddMembers} />
          </Fragment>
        );
      })}
    </div>
  );
};

const MemberRow: FC<{
  userId: number;
  onRemoveMember: (member: number) => void;
  canRemove: boolean;
}> = (props) => {
  const onRemove = useCallback(
    () => props.onRemoveMember(props.userId),
    [props.userId, props.onRemoveMember],
  );

  return (
    <div className="flex-row">
      <UserCard id={props.userId} />
      {props.canRemove && (
        <Button
          variant="text"
          color="red"
          onClicked={onRemove}
          label="title.remove"
        />
      )}
    </div>
  );
};
