const origin = window.location.origin;
const isProduction = (origin.indexOf('app.timberbase.com') !== -1);
const isTest = (origin.indexOf('app.test.timberbase.com') !== -1);
const isStage = (origin.indexOf('stage.timberbase.com') !== -1);
const isDebug = !isProduction;

const isLocalhost = (origin.indexOf('localhost') !== -1 || origin.indexOf('192.168') !== -1 || origin.indexOf('127.0.0.1') !== -1);

const component = {
  isDebug,
  isTest,
  isProduction,
  isLocalhost,
  isStage,
};

export default component;
