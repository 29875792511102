import {DependencyList, useEffect, useRef} from "react";
import {isDifferent} from "react-ui-basics/Tools";
import {deepCopyOf} from "~/Utils";

export const useDeepCompare = <T extends DependencyList> (effect: (previousDependencyValues: T) => void, deps: T) => {
  const prevDeps = useRef<T>();
  const counter = useRef(0);

  const isDiff = isDifferent(deps, prevDeps.current);
  if (isDiff) {
    counter.current++;
  }

  let prev = prevDeps.current
  prevDeps.current = deepCopyOf(deps);

  useEffect(() => effect(prev), [counter.current]);
};
