import API, {
  CreateUserRequest,
  Notification,
  Order,
  GroupPermission,
  Registration,
  UpdatePasswordBody,
  UserAccountUpdate,
  UserEmailSettingsView
} from "~/API";
import useSWRImmutable from "swr/immutable";
import useSWRInfinite from "swr/infinite";
import {useMutateHook} from "~/Hooks";
import useSWR from "swr";

export const usePublicUser = (id: number | null) =>
    useSWRImmutable(
        id == null ? null : ["api/user/public", id],
        async () => await API.getUserPublicInfo({ id }),
    );

export const useUser = (id: number | null) =>
  useSWR(
    id == null ? null : ["api/user", id],
    async () => await API.getUser({ id }),
    {revalidateOnFocus:false}
  );

const getUserListKey = (
    filters: UserFilters,
    offset: number,
    previousPageData: Notification[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/users", filters, offset };
};

const PAGE_SIZE = 15;

export interface UserFilters {
    query?: string;
    search?: string;
    orderBy?: string;
    order?: Order;
    offset?: number;
    limit?: number;
    onlyMy?: boolean;
    company?: Array<number>;
    onlyInactive?: boolean;
    contry?: Array<string>;
    userGroup?: Array<number>;
    lastSeenFrom?: string;
    lastSeenTo?: string;
    dateRegisteredFrom?: string;
    dateRegisteredTo?: string;
    status?: Array<Registration>;
}

export const useUsers = (filters: UserFilters, pageSize?: number) =>
    useSWRInfinite(
        (i, p) => getUserListKey(filters, i * (pageSize || PAGE_SIZE), p),
        (key) =>
            API.getUsers({
                ...filters,
                offset: key.offset,
                limit: (pageSize || PAGE_SIZE),
            }),
    );

export const useUsersByPermission = (
    permissionTypes: GroupPermission[],
    search?: string,
) =>
    useSWRImmutable(
        ["api/users/by/permissions", permissionTypes, search],
        async () =>
            API.getUsersByPermissions({
                permissionTypes,
                search: search || "",
            }),
    );

export const useUsersList = (users: number[]) =>
    useSWRImmutable(["api/users/list", ...users], async () =>
        Promise.all(users.map(async (id) => API.getUserPublicInfo({ id }))),
    );

export const useUserGroup = (id: number) =>
  useSWRImmutable(
    ["api/user/groups", id],
    async () => API.getUserGroup({ id })
  );

export const useChangeUserPassword = (id: number) =>
  useMutateHook<UpdatePasswordBody, any, any>(
    ["api/user/password/change", id],
    async (args) => API.updateUserPassword({ id }, args)
  );

export const useUpdateUserSettings = (id: number) =>
  useMutateHook<UserEmailSettingsView, any, any>(
    ["api/user/", id],
    async (args) => API.updateUserEmailSettings({ id }, args)
  );

export const useRemoveUser = (id: number) =>
  useMutateHook(
    ["api/user", id],
    async () => API.removeUser({ id })
  );

export const useUpdateUser = () =>
  useMutateHook<UserAccountUpdate, any, any>(
    ["api/user/update"],
    async (args) => API.updateUser({ id: args.id }, args),
  );

export const useCreateUser = () =>
  useMutateHook<CreateUserRequest, any, any>(
    ["api/user/create"],
    async (args) => API.createUser({}, args),
  );
