import Button from "~/Components/Old/Button";
import { useAppDispatch } from "~/StoreTypes";
import { savePreferences, useUserPreferences } from "~/Reducers/UserPreferences";
import Vars from "~/Styles/Vars";
import {FC, MouseEventHandler} from "react";
import {TbBellMinusFilled} from "react-icons/tb";

import './Banner.scss';

const SilentModeBanner: FC = () => {
  const dispatch = useAppDispatch();

  const stopSilentMode: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    dispatch(savePreferences({ silentMode: false }));
  };

  const userPreferences = useUserPreferences();
  const show = !!userPreferences.silentMode;

  return show
    ? <div className={`banner banner-red ${show ? 'show' : ''}`}>
      <div className="flex-row gap-sm color-red-6 align-center text-bold">
        <TbBellMinusFilled size={Vars.size.lg} />
        You are in Silent Mode! All notifications and sounds are muted
      </div>

      <Button view="gray" onClick={stopSilentMode}>
        Stop Silent Mode
      </Button>
    </div>
    : null;
}

export default SilentModeBanner;