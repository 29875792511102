import { RLPanelCodeType } from "~/API";
import { localizedString } from "~/Locales";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";


const statusKeys = Object.values(RLPanelCodeType);
const labels = statusKeys.reduce((a, key) => { a[key] = localizedString(`rl.panel.code.type.${key}`); return a; }, {});

export const RLPanelCodeTypeSelect = <T,>(
  props: {} & Omit<FormComboboxProps<T, string>, "getLabel" | "data">,
) => {
  return (
    <FormCombobox {...props} data={statusKeys} getLabel={(i) => labels[i]} />
  );
};
