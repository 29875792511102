import { LocalizedMessage } from "~/Locales";
import { useIsValidIncoterm, useIncotermKeys, useIncotermLabels } from "~/Reducers/Incoterms";
import SelectWithoutPaging from "./SelectWithoutPaging";


const IncotermSelect = (props) => {
  const keys = useIncotermKeys();
  const labels = useIncotermLabels();

  return <SelectWithoutPaging {...props}
    data={keys}
    labels={labels}
    skipLabelSorting
    data-cy={"IncotermSelect"}
  />;
};

export default IncotermSelect;


export const IncotermName = ({ incoterm }) => {
  const isValidIncoterm = useIsValidIncoterm(incoterm);
  if (isValidIncoterm) return <LocalizedMessage id={`incoterm.${incoterm}`} />;
  return <>{incoterm}</>;
};
