import {FC, ReactNode} from "react";
import {usePageTitle} from "~/Hooks";
import {cn} from "~/Utils";

export const StaticPage: FC<{ children: ReactNode, title?: string }> = ({ children, title }) => {
  usePageTitle(title)

  return (<div className="flex desktop-p-lg mobile-p-sm overflow-hidden flex-col">
    {children}
  </div>)
}

export const ScrollPage: FC<{ children: ReactNode, title: string, className?: string }> = ({ children, title, className }) => {
  usePageTitle(title);

  return (<div className={cn("flex desktop-p-lg mobile-p-sm overflow-y-auto", className)}>
    {children}
  </div>)
}