import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { ShipVia, ShipViaDomestic } from "~/Reducers/Incoterms";
import {useMemo, useState} from "react";
import { ShipViaUnitsCombobox } from "~/Components/Comboboxes/ShipViaUnitsComboBox";
import { useIsFlagEnabled } from "~/Reducers/FeatureFlags";
import { FeatureFlag } from "~/API";

interface ShipViaComboboxProps
  extends Omit<FormComboboxProps<{ shipVia: string, unitTypeCode: string }, any>, "getLabel" | "data" | "id"> {
  isDomestic: boolean;
  showUnits: boolean;
}

export const ShipViaCombobox: React.FC<ShipViaComboboxProps> = (props) => {
    const {isDomestic, showUnits} = props;

    const areUnitTypesEnabled = useIsFlagEnabled(FeatureFlag.SHIP_VIA_UNIT_TYPES);

    const[defaultUnitType, setDefaultUnitType] = useState("")

    const handleDefaultUnitType = (value: string) => {
        setDefaultUnitType(value)
        props.formData.data.unitTypeCode = value;
    };

    const shipVias = useMemo(() => {
        if (isDomestic === true) return Object.keys(ShipViaDomestic);
        return Object.keys(ShipVia);
    }, [props.isDomestic]);

    const getLabel = (shipVia: string) => {
        if (isDomestic === true) return ShipViaDomestic[shipVia]
        return ShipVia[shipVia]
    };

    return (
      <>
        <FormCombobox {...props} data={shipVias} getLabel={getLabel} id="shipVia" />
        {showUnits && areUnitTypesEnabled && (
          <>
            <ShipViaUnitsCombobox
              {...props}
              id={"unitTypeCode"}
              defaultUnitType={defaultUnitType}
              setDefaultUnitType={handleDefaultUnitType}
              formData={props.formData}
              label="title.ship.via.unit.type"
            />
          </>
        )}
      </>
    );
};
