import {
  FormInput,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/Components/UI";
import { LC } from "~/Locales";
import PriceView from "~/Components/Views/PriceView";
import {FormRowEditHook} from "~/Hooks/useFormRowEdit";

export const PriceEditView = (props: {
  editUserName: string;
  value: number;
}) => (
  <Tooltip>
    <TooltipTrigger>
      <div className="cursor-pointer">
        <PriceView price={props.value} />
      </div>
    </TooltipTrigger>
    <TooltipContent>
      <LC id="title.edited.by" /> {props.editUserName}
    </TooltipContent>
  </Tooltip>
);

export const PriceEdit = <T,>(props: {
  editRow: FormRowEditHook<T>;
  rowId: keyof T & string;
}) => (
  <FormInput<Partial<T>>
    id={props.rowId}
    formData={props.editRow.form}
    type="number"
    className="w-min-4xl"
  />
);
