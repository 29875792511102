import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LC, ls } from "~/Locales";
import {
  getPathForPO,
  ordersPathPurchase,
  ordersRootPath,
  useIsCompaniesPath,
} from "~/Services/Routes";
import { useIsInternalCustomer, useIsInternalUser } from "~/Reducers/User";
import DashboardEmptyState from "../DashboardEmptyState";
import PriceView from "~/Components/Views/PriceView";
import { useChatUnreadCountForOrder } from "../DashboardHelper";
import { useAMUserNameStrings } from "~/Reducers/Users";
import { PurchaseOrderPlantView, PurchaseOrderView } from "~/API";
import { LinearLoader } from "~/Components/Loaders";
import { Button, Card } from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DateView from "~/Components/Views/DateView";
import { ChatUnreadBadge } from "~/Components/Chats/ChatUnreadBadge";
import { PurchaseOrderStatusBadge } from "~/Components/Orders/PurchaseOrderStatusBadge";
import {DashboardMobileField, DashboardTable} from "~/Pages/Dashboard/Components/DashboardTable";
import { useDashboardPurchaseOrders } from "~/Data/Orders/PurchaseOrders";
import {useIsMobile} from "~/Hooks";

const columnHelperInternal = createColumnHelper<PurchaseOrderPlantView>();
const columnsInternal = [
  columnHelperInternal.accessor("number", {
    header: ls("title.order.#"),
    cell: (data) => <div className="text-semibold">{data.getValue()}</div>,
  }),
  columnHelperInternal.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <PurchaseOrderStatusBadge status={data.getValue()} />,
  }),
  columnHelperInternal.accessor("locationName", {
    header: ls("title.location"),
    cell: (data) => <div className="text-truncate">{data.getValue()},</div>,
  }),
  columnHelperInternal.accessor("buyerName", {
    header: ls("title.buyer"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelperInternal.display({
    id: "destination",
    header: ls("title.destination"),
    cell: (data) => (
      <div className="text-truncate">{`${data.row.original.importIncoTerms} - ${data.row.original.importIncoTermsLoc}`}</div>
    ),
  }),
  columnHelperInternal.accessor("scheduledDeliveryDate", {
    header: ls("title.delivery.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
  columnHelperInternal.accessor("plantCostTotal", {
    header: ls("title.amount"),
    cell: (data) => <PriceView price={data.getValue() || 0} />,
  }),
  columnHelperInternal.display({
    id: "unreadCount",
    header: ls("title.chat"),
    cell: (data) => {
      const unreadCount = useChatUnreadCountForOrder(data.row.original, true);
      return <ChatUnreadBadge unread={unreadCount} />;
    },
  }),
];

const columnHelperExternal = createColumnHelper<PurchaseOrderView>();
const columnsExternal = [
  columnHelperExternal.accessor("number", {
    header: ls("title.order.#"),
    cell: (data) => <div className="text-semibold">{data.getValue()}</div>,
  }),
  columnHelperExternal.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <PurchaseOrderStatusBadge status={data.getValue()} />,
  }),
  columnHelperExternal.accessor("buyerName", {
    header: ls("title.buyer"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelperExternal.accessor("shipFromName", {
    header: ls("title.ship.from"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelperExternal.accessor("scheduledDeliveryDate", {
    header: ls("title.delivery.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
  columnHelperExternal.accessor("millCostTotal", {
    header: ls("title.amount"),
    cell: (data) => <PriceView price={data.getValue() || 0} />,
  }),
  columnHelperExternal.display({
    id: "unreadCount",
    header: ls("title.chat"),
    cell: (data) => {
      const unreadCount = useChatUnreadCountForOrder(data.row.original, true);
      return <ChatUnreadBadge unread={unreadCount} />;
    },
  }),
];

const DashboardPurchaseOrders: FC = () => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const isCompaniesHub = useIsCompaniesPath();
  const orders = useDashboardPurchaseOrders(isInternalCustomer, isInternalUser);

  const showMobileTable = useIsMobile();

  const onShowPurchaseOrders = () => {
    history.push({ pathname: ordersRootPath + ordersPathPurchase });
  };

  const onRowClick = (row: PurchaseOrderView & PurchaseOrderPlantView) => {
    history.push(getPathForPO(row.id));
  };

  const table = useReactTable({
    data: orders.data || [],
    columns: isInternalCustomer ? columnsInternal : columnsExternal,
    getCoreRowModel: getCoreRowModel(),
  });

  const showEmptyState = orders.data?.length === 0 && !orders.isLoading;
  const showList = !orders.isLoading && !showEmptyState;
  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC
          id={
            isCompaniesHub || isInternalUser
              ? "title.purchase.orders"
              : "title.my.orders"
          }
        />
      </div>

      <LinearLoader loading={orders.isLoading} />
      {showEmptyState && <EmptyState />}

      {showList && (
        <>
          {!showMobileTable && (
            <DashboardTable table={table} onRowClick={onRowClick} />
          )}
          {showMobileTable && (
            <div className={"orders-table-mobile"}>
              {orders.data?.map((order) => (
                <OrderCellMobile key={order.id} order={order} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-md"
            onClicked={onShowPurchaseOrders}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardPurchaseOrders;

const EmptyState = () => {
  const isCompaniesHub = useIsCompaniesPath();
  if (isCompaniesHub)
    return <DashboardEmptyState title="No Purchase Orders" description1="" />;

  return (
    <DashboardEmptyState
      title={<LC id="phrase.no.orders.onging.customer.1" />}
      description1={<LC id="phrase.no.orders.onging.customer.2" />}
    />
  );
};

const OrderCellMobile: FC<{
  order: PurchaseOrderView & PurchaseOrderPlantView;
}> = ({ order }) => {
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const history = useHistory();

  const {
    id,
    number,
    vendorConfirmation,
    status,
    accountManagerName,
    buyerName,
    shipFromName,
    importIncoTerms,
    importIncoTermsLoc,
    currency,
    millCostTotal,
    plantCostTotal,
    locationName,
  } = order;
  const unreadCount = useChatUnreadCountForOrder(order, true);

  const costTotal = isInternalCustomer ? plantCostTotal : millCostTotal;

  const managers = useAMUserNameStrings(accountManagerName, buyerName);

  const onOpen = useCallback(() => {
    history.push(getPathForPO(id));
  }, [id]);

  return (
    <div
      className="border-b-1 border-black-10 py-md flex-col gap-sm"
      onClick={onOpen}
    >
      <div className="flex-row w-full">
        <DashboardMobileField label="title.po.#">
          <div className="text-truncate mt-xxs">{number || "-"}</div>
        </DashboardMobileField>

        {!isInternalCustomer && (
          <DashboardMobileField label={isInternalUser ? "title.vendor.order.#" : "title.your.order.#"}>
            <div className="text-truncate mt-xxs">{vendorConfirmation}</div>
          </DashboardMobileField>
        )}

        <div className="text-truncate flex-col mr-xxs">
          <PurchaseOrderStatusBadge status={status} />
          <ChatUnreadBadge unread={unreadCount} />
        </div>
      </div>

      {!isInternalCustomer && (
        <DashboardMobileField label="title.buyer">
          <div className="text-truncate mt-xxs">{managers}</div>
        </DashboardMobileField>
      )}

      {isInternalCustomer && (
        <DashboardMobileField label="title.location">
          <div className="text-truncate mt-xxs">{locationName}</div>
        </DashboardMobileField>
      )}

      {!isInternalCustomer && (
        <DashboardMobileField label="title.ship.from">
          <div className="text-truncate mt-xxs">{shipFromName}</div>
        </DashboardMobileField>
      )}

      <div className="flex-row w-full mt-xs">
        <DashboardMobileField label="title.destination">
          <div className="text-truncate mt-xxs">{`${importIncoTerms} - ${importIncoTermsLoc}`}</div>
        </DashboardMobileField>

        <DashboardMobileField label="title.amount">
            <PriceView price={costTotal || 0} currency={currency} />
        </DashboardMobileField>
      </div>
    </div>
  );
};
