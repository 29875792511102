import { useMemo } from "react";
import {ProductType, unitsForFinance, unitsForLumber, unitsForPanels} from "~/Reducers/Products";
import Select from "./Select";

export const UOMSelect = ({ productType, ...props }) => {
  const unitsOfMeasure = useMemo(() => {
      if(productType === ProductType.LUMBER)
        return unitsForLumber;
      if(productType === ProductType.FINANCE)
        return unitsForFinance;

      return unitsForPanels;
    },
    [productType]);

  return <Select {...props}
    data={unitsOfMeasure}
  />;
};
