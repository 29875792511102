import { FC } from "react";
import { Dialog, FormInput, Button } from "~/Components/UI";
import { useFormData, useToggle } from "~/Hooks";
import { LC } from "~/Locales";
import { UserGroup } from "~/API";
import { z } from "zod";
import { zodMsg } from "~/Utils";
import {useCreateUserGroup} from "~/Data/Admin/UserGroups";
import {trackEvent} from "~/Services/Tracking";

const addFormValidation = z.object({
  name: z.string().min(1, zodMsg("error.non.empty", ["Name"])),
});

const AddUserGroup: FC = () => {
  const open = useToggle(false);

  const addForm = useFormData<UserGroup>(
    {
      active: true,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      id: 0,
      name: "",
    },
    {
      validation: addFormValidation,
    }
  );

  const create = useCreateUserGroup();

  const onCreateClicked = () => {
    if (addForm.isValid()) create
      .call(addForm.data)
      .then(open.toggleFalse)
      .then(() => trackEvent("User Group Added"));
  };

  return (
    <Dialog
      title={<LC id="user.group.add" />}
      open={open}
      trigger={<Button label="user.group.add" />}
    >
      <div className="desktop-w-min-9xl flex-col gap-sm">
        <FormInput<UserGroup>
          id="name"
          formData={addForm}
          label="title.name"
          placeholder="title.name"
        />

        <div className="flex-row justify-end">
          <Button
            label="title.create"
            onClicked={onCreateClicked}
            loading={create.isMutating}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AddUserGroup;
