import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestsRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  getRequestsForQuoteByStatus,
  isRequestExpired,
  useRequestForQuote,
  useRequestForQuoteProductCodes,
  useRequestForQuoteValue,
} from "~/Reducers/RequestsForQuote";
import { useIsInternalUser } from "~/Reducers/User";
import {
  getRequestsForQuoteStatusesTotalInfo,
  useDefaultPageFilters,
  useRequestsForQuotePageByGroupReduxState,
  useRequestsForQuotePageReduxState,
} from "../../Reducer";
import {
  RequestShowUnread,
  RequestStatusLabel,
  useIsDraft,
} from "../../DetailsPage/Components/RequestsHelper";
import Button from "~/Components/Old/Button";
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import RequestArchiveDeleteButtons from "../RequestArchiveDeleteButtons";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useFilters } from "~/Components/Old/useFilters";
import { classNames } from "react-ui-basics/Tools";
import "./RequestsPageTableWithSubrows.scss";
import { DateTimeView } from "~/Components/Views/DateView";
import RequestReference from "../RequestReference";
import { useAMUserNameStrings } from "~/Reducers/Users";
import {
  RequestForQuoteStatus,
  RequestsForQuotesByStatusSummary,
} from "~/API";
import {useAppDispatch} from "~/StoreTypes";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

const RequestsPageByStatusTable: FC<{
  filters: any;
}> = ({ filters }) => {
  const dispatch = useAppDispatch();

  const scrollRef = useRef();

  useEffect(() => {
    dispatch(getRequestsForQuoteStatusesTotalInfo(filters));
  }, [filters]);

  const { statuses, loading } = useRequestsForQuotePageReduxState();

  return (
    <ScrollableForPagination
      ref={scrollRef}
      className={"flex bg-black-12 overflow-auto"}
    >
      {loading && (
        <div className={"progress-bar"}>
          <LinearProgress />
        </div>
      )}

      <table className={"basic-table RequestsPageTableWithSubrows"}>
        {statuses.map((status) => (
          <StatusRow key={status.status} {...{ status }} />
        ))}
      </table>
    </ScrollableForPagination>
  );
};

export default RequestsPageByStatusTable;

const StatusRow: FC<{
  status: RequestsForQuotesByStatusSummary;
}> = ({ status }) => {
  const [expanded, expandCollapse] = useToggle(false);

  const { numberOfRequests, totalValue, currency } = status;
  const currencySymbol = useFindCurrencySymbol(currency);

  const isInternalUser = useIsInternalUser();

  return (
    <>
      <thead>
        <tr onClick={expandCollapse} className={"ClickableHeaderOrRow"}>
          <th>
            {expanded ? (
              <ArrowDownIcon size={26} />
            ) : (
              <ArrowRightIcon size={26} />
            )}
          </th>
          <th colSpan={2}>
            <RequestStatusLabel status={status.status} alwaysShowColor />
          </th>
          <th colSpan={2}>
            <div className={"flex-row align-center"}>
              <LocalizedMessage id={"title.number.of.requests"} />: &nbsp;
              <div className={"color-orange-1"}>{numberOfRequests}</div>
            </div>
          </th>
          <th>
            <div className={"flex-row align-center"}>
              <LocalizedMessage id={"title.total.value"} />: &nbsp;
              <div
                className={"color-orange-1"}
              >{`${currencySymbol} ${formattedNumber(totalValue)}`}</div>
            </div>
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
          {!isInternalUser && <th />}
        </tr>
      </thead>
      {expanded && <RequestsForStatus status={status.status} />}
    </>
  );
};

const ITEMS_PER_PAGE = 10;
const filtersToExclude = ["status", "limit"];

const RequestsForStatus: FC<{
  status: RequestForQuoteStatus;
}> = ({ status }) => {
  const pageDataByGroupId = useRequestsForQuotePageByGroupReduxState(status);

  const defaultPageFilters = useDefaultPageFilters();
  const pageFilters = useMemo(
    () => ({
      ...defaultPageFilters,
      status: [status],
      limit: ITEMS_PER_PAGE,
    }),
    [status, defaultPageFilters],
  );
  const pageDataByStatusReduxFunc = useCallback(
    () => pageDataByGroupId,
    [pageDataByGroupId],
  );

  const { onScrolledToBottom, canLoadMore } = useFilters(
    pageFilters,
    getRequestsForQuoteByStatus,
    pageDataByStatusReduxFunc,
    null,
    null,
    filtersToExclude,
  );

  const { ids = [], loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  const isInternalUser = useIsInternalUser();

  return (
    <tbody>
      <tr className={"RequestRowAsSubrow"}>
        <th />
        <th>
          <LocalizedMessage id={"title.rq.id"} />
        </th>
        <th />
        {isInternalUser && (
          <th>
            <LocalizedMessage id={"title.customer"} />
          </th>
        )}
        <th>
          <LocalizedMessage id={"title.ship.to"} />
        </th>
        <th>
          <LocalizedMessage id={"title.salesperson"} />
        </th>
        <th>
          <LocalizedMessage id={"title.products"} />
        </th>
        <th>
          <LocalizedMessage id={"title.product.code"} />
        </th>
        {!isInternalUser && (
          <>
            <th />
            <th />
            <th />
          </>
        )}
        {isInternalUser && (
          <th>
            <LocalizedMessage id={"title.reference"} />
          </th>
        )}
        <th className={"text-right"}>
          <LocalizedMessage id={"title.value"} />
        </th>
        <th>
          <LocalizedMessage id={"title.validity.date"} />
        </th>
        <th>
          <LocalizedMessage id={"title.actions"} />
        </th>
        <th />
      </tr>
      {ids.map((requestId) => (
        <RequestRow key={requestId} groupId={status} {...{ requestId }} />
      ))}

      {showAdditionalRow && (
        <tr className={"RequestRowAsSubrow"}>
          {!showLoader && (
            <td colSpan={13} className={"MoreButtonCell"}>
              <Button
                view={"text"}
                className={classNames(
                  "w-full",
                  showTryAgain && "DeleteButtonText",
                )}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage
                  id={showTryAgain ? "title.try.again" : "title.get.more"}
                />
              </Button>
            </td>
          )}
          {showLoader && (
            <td colSpan={13}>
              <LinearProgress />
            </td>
          )}
        </tr>
      )}
    </tbody>
  );
};

const RequestRow = ({ requestId, groupId }) => {
  const history = useHistory();
  const request = useRequestForQuote(requestId) || undefined;
  const value = useRequestForQuoteValue(requestId);
  const productCodes = useRequestForQuoteProductCodes(requestId);
  const {
    customerCompanyName,
    shipToName,
    currency,
    products,
    status,
    validityDate,
    quotes,
    offers,
    accountManagerName,
    shipToAccountManagerName,
    unreadChatMessages,
  } = request || {};
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = useIsDraft(status);

  const isInternalUser = useIsInternalUser();

  const onOpen = useCallback(
    () => history.push(`${requestsRootPath}${requestId}`),
    [history, requestId],
  );

  const isExpired = isRequestExpired(validityDate);

  const showReference = !!quotes?.length || !!offers?.length;

  const managers = useAMUserNameStrings(
    accountManagerName,
    shipToAccountManagerName,
  );

  return (
    <tr className={"RequestRowAsSubrow"} onClick={onOpen}>
      <td
        className={classNames(
          RequestShowUnread(request?.readMark, isExpired) ? "row-unread" : "",
        )}
      />
      <td className={"no-wrap"}>{`RQ-${requestId}`}</td>
      <td>{isExpired && "Expired"}</td>
      {isInternalUser && <td>{customerCompanyName}</td>}
      <td>{shipToName}</td>
      <td>{managers}</td>
      <td>{`${products?.length || 0}`}</td>
      <td>{productCodes}</td>
      {!isInternalUser && (
        <>
          <td />
          <td />
          <td />
        </>
      )}
      {isInternalUser && (
        <td>
          {showReference ? (
            <RequestReference {...{ offers, quotes }} simple />
          ) : (
            "-"
          )}
        </td>
      )}
      <td className={"text-right no-wrap color-orange-1"}>
        {`${currencySymbol} ${formattedNumber(value) || "0"}`}
      </td>
      <td>
        <DateTimeView date={validityDate} />
      </td>
      <td>
        <div className={"flex-row align-center justify-center"}>
          <RequestArchiveDeleteButtons {...{ request, groupId }} />
        </div>
      </td>
      <td>
        {!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}
      </td>
    </tr>
  );
};
