
export enum ProductType {
  ALL = '',
  LUMBER = 'LUMBER',
  PANELS = 'PANELS',
  LINEAR_FOOTAGE = 'LINEAR_FOOTAGE',
  SPECIAL_PRODUCTS = 'SPECIAL_PRODUCTS',
  FINANCE = 'FINANCE',
}

export const productTypesArray = [ProductType.ALL, ProductType.LUMBER, ProductType.PANELS, ProductType.LINEAR_FOOTAGE, ProductType.SPECIAL_PRODUCTS, ProductType.FINANCE];

export const ProductTypeCode = {
  [ProductType.LUMBER]: 'LU',
  [ProductType.PANELS]: 'PA',
  [ProductType.LINEAR_FOOTAGE]: 'LF',
  [ProductType.SPECIAL_PRODUCTS]: 'SP',
  [ProductType.FINANCE]: 'FI',
}

export const ProductTypeLabel = {
  [ProductType.LUMBER]: 'Lumber',
  [ProductType.PANELS]: 'Panels',
  [ProductType.LINEAR_FOOTAGE]: 'Linear Footage',
  [ProductType.SPECIAL_PRODUCTS]: 'Special Products',
  [ProductType.FINANCE]: 'Finance',
}

export const unitsForLumber = ['MBF', 'CBM', 'PCS', 'LF']; // MBF: 1000 board feet, CBM: cubic meter, PCS: pieces
export const unitsForPanels = ['MSF', 'CBM', 'PCS']; // MSF: 1000 square feet, CBM: cubic meter, PCS: pieces
export const unitsForFinance = ['FI'];

export const unitsForLinearFoot = 'LF';
export const unitsForSpecialProducts = 'PCS';
