import { ls, LocalizedMessageKey } from "~/Locales";
import {UIEventHandler, useRef} from "react";

export const cn = (...classes: (string | undefined)[]) =>
  classes.filter(Boolean).join(" ");

export const zodMsg = (message: LocalizedMessageKey, ...args: any[]) => ({
  message: ls(message, ...args),
});

export const smartJoin = (arrayToJoin: any[] | any = [], separator = ',') => {
  if (Array.isArray(arrayToJoin)) {
    return arrayToJoin.filter(it => it?.length > 0).join(separator);
  }
  return '';
};

export const unique = (a: object[]) => {
  let prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];

  return a.filter(function(item: any) {
    let type = typeof item;
    if(type in prims)
      return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
    else
      return objs.indexOf(item) >= 0 ? false : objs.push(item);
  });
};

export const flipObject = (data: object) => {
  return Object.keys(data).reduce((obj, key) => {
    obj[data[key]] = key;
    return obj;
  }, {});
}

export const deepCopyOf = <T,>(obj: T): T => {
  if (Array.isArray(obj)) {
    return forceCast<T>(obj.map((item) => deepCopyOf(item)));
  } else if (typeof obj === "object" && !!obj) {
    const retObject = {};
    Object.keys(obj).forEach((key) => (retObject[key] = deepCopyOf(obj[key])));
    return forceCast<T>(retObject);
  }

  return obj;
};

export const shallowCopyOf = <T,>(obj: T): T => {
  if (Array.isArray(obj)) {
    return [...obj] as T;
  } else if (typeof obj === "object" && !!obj) {
    return { ...obj };
  }

  return obj;
};

export const trimUndefined = <T,>(obj: T): Partial<T> => {
  return Object.keys(obj)
    .filter((k) => obj[k] != undefined)
    .reduce((p, v) => ({ ...p, [v]: obj[v] }), {});
};

const forceCast = <T,>(input: any): T => {
  // @ts-ignore <-- forces TS compiler to compile this as-is
  return input;
};

export type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: any;
};

export const continuousIncludes = (value: string, inc: string) => {
  const length = inc.length;
  let from = -1;
  for (let i = 0; i < length; i++) {
    from = value.indexOf(inc[i], from+1);
    if (from === -1)
      return false;
  }
  return true;
};

// TODO: it ignores words order. can be improved
export const includesWords = (wordsString: string, searchString: string) => {
  if (!!wordsString && !!searchString) {
    const words = wordsString.toLowerCase().split(' ');
    return words.every(word => searchString.toLowerCase().includes(word));
  }

  return false;
}

export const validPassword = (password: string) => {
  if (password.length < 8) return false; // length
  if (!password.match(/[a-z]/g)) return false; // lowercase
  if (!password.match(/[A-Z]/g)) return false; // uppercase
  if (!password.match(/[0-9]/g)) return false; // digit
  if (!password.match(/[!`~@#$%^&*()\-+=\[\]{};:'"<,>.?/]/g)) return false; // symbol

  return true;
}

export const onScrollToBottom = (onBottom: () => void, offset = 0): UIEventHandler<HTMLDivElement> => {
  const fired = useRef(false);

  return ({ currentTarget }) => {
    const positionFromBottom = currentTarget.scrollHeight - currentTarget.offsetHeight - currentTarget.scrollTop;
    if (positionFromBottom <= offset) {
      if (!fired.current) {
        fired.current = true;
        onBottom();
      }
    } else if (fired.current) {
      fired.current = false;
    }
  }
}
