import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { AddOnCostLabel, useAddOnCosts } from "~/Data/AddOnCosts";
import { FC } from "react";
import { Skeleton } from "~/Components/Loaders";
import { AddOnCostType } from "~/API";

export const AddOnCostCombobox = <T,>(
  props: {
    type?: AddOnCostType;
  } & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const costs = useAddOnCosts();

  const labels =
    costs.data?.reduce(
      (p, c) => ({
        ...p,
        [c.id]:
          (props.type == undefined || c.type == props.type) && c.currency != ""
            ? AddOnCostLabel(c)
            : "Invalid Addon!",
      }),
      {},
    ) || {};

  const data =
    costs.data
      ?.filter((c) => props.type == undefined || c.type == props.type)
      .filter((c) => c.currency != "")
      .map((d) => d.id) || [];

  return <FormCombobox {...props} data={data} getLabel={(id) => labels[id]} />;
};

export const AddOnCostView: FC<{ id: number }> = (props) => {
  const costs = useAddOnCosts();

  if (costs.isLoading)
    return <Skeleton className="w-max-5xl w-full h-line-md" />;

  return AddOnCostLabel(costs.data.find((c) => c.id == props.id));
};
