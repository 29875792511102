import { FC, ReactNode } from "react";
import { Case, Match, Show } from "~/Components/UI";
import { ChatType, ChatUnreadView } from "~/API";
import {
  TbClipboardList,
  TbMessage2,
  TbReportMoney,
  TbUsers,
} from "react-icons/tb";
import { BiReceipt } from "react-icons/bi";
import { cn } from "~/Utils";
import {useCurrentUser} from "~/Reducers/User";
import {UserAvatar} from "~/Components/Users/UserAvatar";

export const ChatsIcon: FC<{ chat: ChatUnreadView }> = (props) => {
  const currentUser = useCurrentUser();

  return (
    <Match on={props.chat.type}>
      <Case v={ChatType.DIRECT}>
        <Show
          when={props.chat.participants.length > 2}
          fallback={
            <UserAvatar
              id={props.chat.participants.find((p) => p !== currentUser.id)}
            />
          }
        >
          <IconCircle className="bg-yellow-1 color-black-12">
            <TbUsers size={22} />
          </IconCircle>
        </Show>
      </Case>
      <Case v={ChatType.QUOTE}>
        <IconCircle className="bg-red-1 color-red-6">
          <TbClipboardList size={22} />
        </IconCircle>
      </Case>
      <Case v={ChatType.SALES_ORDER}>
        <IconCircle className="bg-blue-1 color-blue-9">
          <BiReceipt size={22} />
        </IconCircle>
      </Case>
      <Case v={ChatType.PURCHASE_ORDER}>
        <IconCircle className="bg-purple-1 color-purple-9">
          <BiReceipt size={22} />
        </IconCircle>
      </Case>
      <Case v={ChatType.OFFER}>
        <IconCircle className="bg-orange-1 color-orange-8">
          <TbReportMoney size={22} />
        </IconCircle>
      </Case>
      <Case default>
        <IconCircle className="bg-yellow-1 color-black-12">
          <TbMessage2 size={22} />
        </IconCircle>
      </Case>
    </Match>
  );
};

const IconCircle: FC<{
  children: ReactNode;
  className: string;
}> = (props) => (
  <div
    className={cn(
      "w-xl h-xl flex-row align-center justify-center rounded-full",
      props.className,
    )}
  >
    {props.children}
  </div>
);
