import { RequestForQuoteStatus, RequestForQuoteView } from "~/API";
import { FC, useCallback } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import { useIsDraft } from "../DetailsPage/Components/RequestsHelper";
import {
  archiveRequest,
  deleteRequest,
  useShouldShowArchiveButton,
} from "../Reducer";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import DeleteDialog from "~/Components/Old/DeleteDialog";
import { DeleteIcon, InboxArchiveIcon } from "~/Components/Old/Icons";
import {useAppDispatch} from "~/StoreTypes";
import {trackEvent} from "~/Services/Tracking";

const RequestArchiveDeleteButtons: FC<{
  request: RequestForQuoteView;
  groupId?: string | null;
}> = ({ request, groupId = null }) => {
  const dispatch = useAppDispatch();
  const { id, status } = request || {};
  const isDraft = useIsDraft(status);

  const [showDeleteConfirmation, toggleDeleteDialog] = useToggle();
  const [showArchiveConfirmation, toggleArchiveDialog] = useToggle();

  const onConfirmDelete = useCallback(() => {
    dispatch(deleteRequest(id, groupId, toggleDeleteDialog));
    trackEvent("Request for Quote Deleted");
  }, [id, groupId, dispatch, toggleDeleteDialog]);

  const onConfirmArchive = useCallback(() => {
    dispatch(
      archiveRequest(
        { ...request, status: RequestForQuoteStatus.ARCHIVED } as any,
        groupId,
        toggleArchiveDialog,
      ),
    );
    trackEvent("Request for Quote Archived");
  }, [request, groupId, dispatch, toggleArchiveDialog]);

  const shouldShowArchiveButton = useShouldShowArchiveButton(status);

  return (
    <>
      {isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton DeleteButtonText"}
          onClick={(e) => {
            e.stopPropagation();
            toggleDeleteDialog();
          }}
        >
          <DeleteIcon size={20} />
        </Button>
      )}

      {!isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton ArchiveButton"}
          onClick={(e) => {
            e.stopPropagation();
            toggleArchiveDialog();
          }}
        >
          <InboxArchiveIcon size={20} />
        </Button>
      )}

      {showDeleteConfirmation && (
        <DeleteDialog
          title={<LocalizedMessage id={"title.delete.request"} />}
          description={
            <LocalizedMessage id={"phrase.delete.rfq"}>
              {id}
            </LocalizedMessage>
          }
          description2={
            <LocalizedMessage id={"phrase.this.actions.can.not.be.undone"} />
          }
          confirmTitle={<LocalizedMessage id={"title.delete"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onDelete={onConfirmDelete}
          onClose={toggleDeleteDialog}
        />
      )}
      {showArchiveConfirmation && (
        <ConfirmationDialog
          title={<LocalizedMessage id={"title.archive.request"} />}
          description={
            <LocalizedMessage id={"phrase.archive.rfq"}>
              {id}
            </LocalizedMessage>
          }
          description2={""}
          confirmTitle={<LocalizedMessage id={"title.archive"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onConfirm={onConfirmArchive}
          onClose={toggleArchiveDialog}
        />
      )}
    </>
  );
};

export default RequestArchiveDeleteButtons;
