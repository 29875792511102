import API, { AttachmentStatus } from '~/API';
import { Component } from 'react';
import { LocalizedMessage } from "~/Locales";
import { useAttachment } from '~/Reducers/Attachments';
import { findImageAndPDFAttachments, findNonImageAttachments } from '~/Utils/Attachments';
import Button from "./Button";
import { CloseIcon, DeleteIcon } from './Icons';
import ProductAttachmentUploadDialog from "./ProductAttachmentUploadDialog";
import { classNames, orNoop } from "react-ui-basics/Tools";
import Scrollable, { SCROLLBAR_MODE_HIDDEN } from "react-ui-basics/Scrollable";
import './ImagesList.scss';
import 'react-ui-basics/Scrollable.css';
import DNDContainer from "react-ui-basics/DNDContainer";
import { DNDDraggable } from "react-ui-basics";
import DroppableList from "react-ui-basics/DroppableList";


class ImagesList extends Component {
  state = {
    selectedImageIndex: 0,
  };

  onMainImageClick = (event) => {
    this.props.onClick?.(event, this.state.selectedImageIndex);
  };

  render() {
    const { images, messageToShow, className, readOnly = false } = this.props;
    const {
      draggableInitializer,
      droppableInitializer,
      draggableEnhancer,
      onScroll,
      selectedImageIndex,
      showPhotoUploadDialog,
    } = this.state;

    const attachmentsWithPreview = findImageAndPDFAttachments(images);
    const otherAttachments = findNonImageAttachments(images);

    const showPreviewImage = Array.isArray(attachmentsWithPreview) && attachmentsWithPreview.length !== 0;
    return (
      <div className={classNames('ImagesList', className)}>
        <div className={'ImagesContainer'}>
          {showPreviewImage &&
            <>
              <MainAttachmentPreview attachmentId={attachmentsWithPreview[selectedImageIndex]?.id} onClick={this.onMainImageClick} />
              <div className={'text-sm ImageIndexContainer'}>{`${selectedImageIndex + 1}/${attachmentsWithPreview.length}`}</div>
              {messageToShow && <div className={'text-2xl MessageToShow'}> {messageToShow} </div>}
            </>
          }
          {!showPreviewImage &&
            <div className={'NoPhotoMainContainer'}>
              <div className={'text-lg mx-lg'}>
                <LocalizedMessage id={'phrase.no.photos.or.files.yet.1'} />
              </div>
              {!readOnly &&
                <div className={'NoPhotoDescription mt-md mx-lg'}>
                  <LocalizedMessage id={'phrase.no.photos.or.files.yet.2'} />
                </div>
              }
            </div>
          }
          <DNDContainer className="right"
            provideDraggableInitializer={draggableInitializer => this.setState({ draggableInitializer })}
            provideDroppableInitializer={droppableInitializer => this.setState({ droppableInitializer })}
          >
            <Scrollable autoScrollTop={false} ref={scrollable => this.scrollable = scrollable} onScroll={onScroll}
              className={classNames('CarouselContainer', readOnly ? 'WithoutUploadButton' : '')}
              horizontalScrollBarMode={SCROLLBAR_MODE_HIDDEN}
            >
              <DroppableList className={'flex-col'}
                provideDraggableEnhancer={draggableEnhancer => this.setState({ draggableEnhancer })}
                provideScrollListener={onScroll => this.setState({ onScroll })}
                getScrollPosition={() => this.scrollable.getScroll()}
                initializer={droppableInitializer} onDrop={(data, o, n) => {
                  let list = [...attachmentsWithPreview];
                  list.splice(o, 1);
                  list.splice(n, 0, data.id);
                  this.props.onChange?.([...new Set([...list, ...otherAttachments])]);
                }}
              >
                {!showPreviewImage &&
                  [1, 2, 3, 4].map(it => <div key={it} className={'NoPhotoSmallContainer mb-xs'} />)
                }
                {showPreviewImage && attachmentsWithPreview.map((image, index) =>
                  <DNDDraggable id={image?.id} key={image?.id} data={{ id: image?.id }}
                    copy={(initializer, __, it) => initializer(it)}
                    initializer={orNoop(draggableEnhancer)(draggableInitializer) || draggableInitializer}
                    onClick={(e) => this.setState({ selectedImageIndex: index })}
                  >
                    <SmallAttachmentPreview attachmentId={image?.id} isSelected={index === selectedImageIndex} />
                    {!readOnly && <Button className={'DeleteButton'} onClick={() => {
                      const list = [...new Set([...attachmentsWithPreview, ...otherAttachments])]
                        .filter(it => it.id !== image?.id);
                      this.props.onChange?.(list);
                    }}>
                      <CloseIcon size={14} />
                    </Button>
                    }
                  </DNDDraggable>
                )}
              </DroppableList>
            </Scrollable>
            {!readOnly &&
              <Button className={'AddPhotoButton'} onClick={() => this.setState({ showPhotoUploadDialog: true })}>
                <LocalizedMessage id={'title.upload'} />
              </Button>}
          </DNDContainer>
        </div>

        {!!otherAttachments.length && <div className={'text-lg mt-lg'}><LocalizedMessage id={'title.attachments'} /></div>}
        {otherAttachments.map(attachment =>
          <div className={'flex-row align-center mt-xs text-left'}>
            <a href={API.urlAttachmentDownload(attachment.id)} target="_blank" rel="noreferrer noopener">
              {attachment.name}
            </a>
            {!readOnly &&
              <Button view={'text'}
                className={'ActionButton DeleteButtonText'}
                onClick={() => {
                  const list = [...new Set([...attachmentsWithPreview, ...otherAttachments])]
                    .filter(it => it.id !== attachment.id);
                  this.props.onChange?.(list);
                }}>
                <DeleteIcon size={16} />
              </Button>
            }
          </div>
        )}

        {showPhotoUploadDialog &&
          <ProductAttachmentUploadDialog
            restriction={'PUBLIC'}
            onUpload={(attachments) => {
              this.setState({ showPhotoUploadDialog: false });
              this.props.onChange?.([...images, ...attachments]);
            }}
            onClose={() => this.setState({ showPhotoUploadDialog: false })}
          />
        }
      </div>
    );
  }
}

export default ImagesList;


const MainAttachmentPreview = ({ attachmentId, onClick }) => {
  const attachment = useAttachment(attachmentId);
  if (attachment?.status !== AttachmentStatus.READY) {
    return (
      <div className={'NoPhotoMainContainer'}>
        Loading...
      </div>
    );
  }

  return (
    <img
      className={'MainImage'}
      alt=""
      src={API.urlImageThumbnailLarge(attachmentId)}
      onClick={onClick}
    />
  );
};

const SmallAttachmentPreview = ({ attachmentId, isSelected }) => {
  const attachment = useAttachment(attachmentId);
  if (attachment?.status !== AttachmentStatus.READY) {
    return (
      <div className={classNames('NoPhotoSmallContainer', isSelected && 'SelectedImage')}>
        Loading...
      </div>
    );
  }

  return (
    <img
      draggable={false}
      className={classNames('CarouselImage', isSelected && 'SelectedImage')}
      alt=""
      src={API.urlImageThumbnail(attachmentId)}
    />
  );
};
