import { PurchaseOrderStatus } from "~/API";
import { ls } from "~/Locales";


// Helper
export const isFutureDate = (date: string) => {
  if (date) {
    if (new Date(date).getTime() < Date.now()) return true;
  }
  return false;
};

export const orderStatuses = ['INQUIRY', 'RESERVED', 'COMPLETE'];

export const purchaseOrderStatuses = Object.keys(PurchaseOrderStatus).map(key => PurchaseOrderStatus[key]);

export const purchaseOrderStatusColors = {
  'REQUESTED': '#a4a5ad',
  'ORDERED': '#777983',
  'PARTIAL': '#EE752F',
  'INBOUND': '#3F87DE',
  'COMPLETE': '#8BC34A',
  'ACKNOWLEDGED': '#79abe8',
};

export const purchaseOrderBgStatusColors = {
  'REQUESTED': '#f7f9f9',
  'ORDERED': '#f1f4f4',
  'PARTIAL': '#FDF1EA',
  'INBOUND': '#ecf3fc',
  'COMPLETE': '#f3f9ed',
  'ACKNOWLEDGED': '#ecf3fc',
};

export const orderStatusLabels = {
  'INQUIRY': ls('delivery.status.INQUIRY'),
  'RESERVED': ls('delivery.status.RESERVED'),
  'COMPLETE': ls('delivery.status.COMPLETE'),
};

export const purchaseOrderStatusLabels = {
  'REQUESTED': ls('delivery.status.REQUESTED'),
  'ORDERED': ls('delivery.status.ORDERED'),
  'PARTIAL': ls('delivery.status.PARTIAL'),
  'INBOUND': ls('delivery.status.INBOUND'),
  'COMPLETE': ls('delivery.status.COMPLETE'),
  'ACKNOWLEDGED': ls('delivery.status.ACKNOWLEDGED'),
};
