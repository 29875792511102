import { FC } from "react";
import { For } from "~/Components/UI";
import { useAttachment, useAttachmentThumbnail } from "~/Data/Attachments";
import { TbDownload, TbFile } from "react-icons/tb";
import { cn } from "~/Utils";
import API from "~/API";
import { AttachmentGallery } from "~/Components/AttachmentGallery";
import {isImage} from "~/Utils/Attachments";

export const ChatAttachments: FC<{ attachments: number[] }> = (props) => {
  return (
    <div className="flex-row gap-sm flex-wrap">
      <For each={props.attachments}>
        {(attach) => <AttachmentView key={attach} attachment={attach} attachments={props.attachments} />}
      </For>
    </div>
  );
};

const AttachmentView: FC<{ attachment: number, attachments: number[] }> = (props) => {
  const attachData = useAttachment(props.attachment);

  if (attachData.data?.hasThumbnail && isImage(attachData.data?.name)) {
    return <AttachmentThumbnail attachment={props.attachment} attachments={props.attachments} />;
  }

  const onAttachmentClick = () => {
    window.open(API.urlAttachmentDownload(props.attachment));
  };

  return (
    <div
      className={cn(
        "bg-black-2 text-semibold flex-row align-center gap-xxs rounded-xxs",
        "px-sm py-xs color-black-12 hover-bg-black-3 cursor-pointer",
      )}
      onClick={onAttachmentClick}
    >
      <TbFile size={16} />
      {attachData.data?.name}
      <TbDownload size={16} />
    </div>
  );
};

const AttachmentThumbnail: FC<{ attachment: number, attachments: number[] }> = (props) => {
  const attachThumb = useAttachmentThumbnail(props.attachment);

  return (
    <AttachmentGallery
      trigger={
        <img
          src={attachThumb.data}
          className="w-full h-max-8xl rounded-xxs cursor-pointer"
        />
      }
      defaultAttachment={props.attachment}
      attachments={props.attachments}
    />
  );
};
