import BaseLoginPage from "../BaseLoginPage";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";

const RegistrationInvalidPage: FC = () => {
  usePageTitle("Registration");

  return (
    <BaseLoginPage>
      <p>Hello!</p>

      <div>
        It appears that the confirmation link you clicked on is not a valid
        link. To obtain a new link, please send us an email at{" "}
        <a href="mailto:hello@timberbase.com">hello@timberbase.com</a>,
        informing us of your situation.
      </div>

      <p>We appreciate your patience and understanding.</p>

      <div>Best regards,</div>
      <div>The TimberBase Team</div>
    </BaseLoginPage>
  );
};

export default RegistrationInvalidPage;
