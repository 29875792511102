import { FC } from "react";
import { Link } from "react-router-dom";
import { LC, ls } from "~/Locales";
import { companiesRootPath } from "~/Services/Routes";
import { isComboIncoterm } from "~/Reducers/Incoterms";
import { useCurrentUser, useIsAdmin, useIsInternalUser } from "~/Reducers/User";
import {
  destinationNonPortTypes,
  destinationPortTypes,
} from "~/Data/Destinations";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import API, {
  GroupPermission,
  LocationStatus,
  LocationType,
  LocationView,
} from "~/API";
import Toast from "~/Components/Toast";
import {
  Button,
  Card,
  FormInput,
  FormSwitch,
  FormSwitchDescriptive, Input,
} from "~/Components/UI";
import {useFormData, useMutate} from "~/Hooks";
import { CountryCombobox } from "~/Components/Comboboxes/CountryCombobox";
import { PortCombobox } from "~/Components/Ports/PortCombobox";
import { IncotermCombobox } from "~/Components/Comboboxes/IncotermCombobox";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import { TbExternalLink } from "react-icons/tb";
import { useCompany } from "~/Data/Companies/Companies";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {useUser} from "~/Data/Users";
import {Skeleton} from "~/Components/Loaders";
import {decimalToHundredthPercent, hundredthToDecimalPercent} from "~/Utils/UnitConversion";

const LocationDetailsGeneralTab: FC<{
  location: LocationView;
  mutateLocation: () => Promise<LocationView>;
}> = (props) => {
  const isAdmin = useIsAdmin();
  const isInternalUser = useIsInternalUser();
  const currentUser = useCurrentUser();

  const form = useFormData({
    ...props.location,
    defaultMarginAsFraction: decimalToHundredthPercent(props.location.defaultMarginAsFraction)
  });

  const company = useCompany(props.location.companyId);
  const { accountManagerUserId: buyerUserId } = company.data || {};
  const buyer = useUser(buyerUserId);

  const isShipFrom = props.location.type == LocationType.SHIP_FROM;
  const isInternal = company.data?.isInternal ?? false;

  const save = useMutate(async () => {
    try {
      await API.updateLocation({ id: props.location.id }, {
        ...form.data,
        defaultMarginAsFraction: hundredthToDecimalPercent(form.data.defaultMarginAsFraction)
      });

      const loc = await props.mutateLocation();
      form.setData({
        ...loc,
        defaultMarginAsFraction: decimalToHundredthPercent(loc.defaultMarginAsFraction)
      }, true);
    } catch (error) {
      Toast.ApiError(error);
      throw error;
    }
  });

  const canEdit =
    isAdmin ||
    currentUser.id === props.location.accountManagerUserId ||
    currentUser.id === buyerUserId ||
    props.location.additionalResponsibles?.includes(currentUser.id);
  const canEditAll =
    props.location.status !== LocationStatus.MSR_CONFIRMED && canEdit;
  const canShowLocationCode = isInternal && isInternalUser;
  const isComboImcotermSelected = isComboIncoterm(form.data.incoterm);

  return (
    <>
      {form.hasChanges && !save.loading && (
        <UnsavedChangesDialog onSave={save.mutate} />
      )}
      <Card className="desktop-flex-row mobile-flex-col gap-md mb-md align-center">
        <div className="color-text-2">
          <LC id={isShipFrom ? "title.vendor" : "title.customer"} />
        </div>
        {company.data && (
          <div className="text-semibold">{company.data?.code} - {company.data?.legalName}</div>
        )}
        {!company.data && (
          <Skeleton className="w-7xl h-line-md" />
        )}

        <Button
          asChild
          label="title.view"
          leftIcon={<TbExternalLink size={16} />}
          className="ml-auto"
          color="gray"
        >
          <Link
            to={`${companiesRootPath}${props.location.companyId}`}
          />
        </Button>
      </Card>
      <div className="desktop-flex-row mobile-flex-col flex-wrap gap-md text-left">
        <Card className="flex-col gap-md desktop-w-min-10xl py-lg">
          <div className="text-lg">
            <LC id="title.general" />
          </div>

          <FormSwitch
            id="active"
            formData={form}
            label="title.active"
            disabled={!canEdit}
          />

          <div className="flex-row align-center gap-sm">
            <FormInput
              id="code"
              formData={form}
              disabled
              label={isShipFrom ? "title.ship.from.code" : "title.ship.to.code"}
            />
            <FormInput
              id="name"
              formData={form}
              disabled={!canEditAll}
              label={isShipFrom ? "title.ship.from.name" : "title.ship.to.name"}
              containerClassName="flex"
            />
          </div>

          {isShipFrom && (
            <Input
              id="buyer"
              value={`${buyer.data?.name || ""} ${buyer.data?.surname || ""}`}
              label="title.buyer"
              disabled
            />
          )}

          {!isShipFrom && (
            <UserByPermissionCombobox
              id="accountManagerUserId"
              formData={form}
              label="title.account.manager"
              disabled={isShipFrom || !canEdit}
              permissions={[GroupPermission.CAN_BE_SELLER]}
            />
          )}

          <UserByPermissionCombobox
            id="additionalResponsibles"
            formData={form}
            multiple
            permissions={[GroupPermission.CAN_BE_SELLER]}
            excludeUsers={[form.data.accountManagerUserId]}
            label="title.additional.responsibles"
            disabled={isShipFrom || !canEdit}
          />

          {canShowLocationCode && (
            <FormInput
              id="locationCode"
              formData={form}
              disabled={!isAdmin}
              label="title.location.code"
            />
          )}
        </Card>

        <Card className="flex-col gap-md desktop-w-min-10xl py-lg">
          <div className="text-lg">
            <LC id="title.preferences" />
          </div>

          <IncotermCombobox
            id="incoterm"
            formData={form}
            label="title.incoterm"
            disabled={!canEdit}
          />

          <PortCombobox
            id="portId"
            formData={form}
            label={
              isComboImcotermSelected
                ? "title.destination"
                : "title.preferred.port"
            }
            disabled={!canEdit}
            filters={{
              type: isComboImcotermSelected
                ? destinationNonPortTypes
                : destinationPortTypes,
            }}
          />

          {!isShipFrom && (
            <FormInput
              id="defaultMarginAsFraction"
              formData={form}
              label="title.default.margin"
              disabled={!canEdit}
              rightIcon={<div className="mx-md color-text-3">%</div>}
              type="number"
            />
          )}

          {isShipFrom && (
            <FormSwitchDescriptive
              id="hidden"
              formData={form}
              label="title.hidden"
              description={ls("phrase.ship.from.hidden")}
            />
          )}

          <FormInput
            id="paymentTermsDescription"
            label="title.payment.term"
            formData={form}
            disabled={!canEditAll}
          />
        </Card>

        <Card className="flex-col gap-md desktop-w-min-10xl py-lg">
          <div className="text-lg">
            <LC
              id={
                isShipFrom ? "title.ship.from.address" : "title.ship.to.address"
              }
            />
          </div>
          <FormInput
            id="addressStreet"
            formData={form}
            label="title.add.1"
            disabled={!canEditAll}
          />
          <FormInput
            id="addressStreet2"
            formData={form}
            label="title.add.2"
            disabled={!canEditAll}
          />
          <FormInput
            id="addressCity"
            formData={form}
            label="title.city"
            disabled={!canEditAll}
          />

          <div className="flex-row align-center gap-md">
            <FormInput
              id="addressRegion"
              formData={form}
              label="title.state"
              disabled={!canEditAll}
              containerClassName="flex"
            />
            <FormInput
              id="addressZip"
              formData={form}
              label="title.postcode"
              disabled={!canEditAll}
            />
          </div>

          <CountryCombobox
            id="addressCountry"
            formData={form}
            label="title.country"
            disabled={!canEditAll}
          />
        </Card>

        {!isShipFrom && (
          <Card className="flex-col gap-md desktop-w-min-10xl py-lg">
            <div className="text-lg">
              <LC id="title.bill.to.address" />
            </div>

            <div className="flex-row align-center gap-md">
              <FormInput
                id="billToCode"
                formData={form}
                label="title.bill.to.code"
                disabled
              />
              <FormInput
                id="billToName"
                formData={form}
                disabled={!canEditAll}
                label="title.bill.to.name"
                containerClassName="flex"
              />
            </div>

            <FormInput
              id="billToAddressStreet1"
              formData={form}
              disabled={!canEditAll}
              label="title.add.1"
            />

            <FormInput
              id="billToAddressStreet2"
              formData={form}
              disabled={!canEditAll}
              label="title.add.2"
            />

            <FormInput
              id="billToAddressCity"
              formData={form}
              disabled={!canEditAll}
              label="title.city"
            />

            <div className="flex-row align-center gap-md">
              <FormInput
                id="billToAddressRegion"
                formData={form}
                disabled={!canEditAll}
                label="title.state"
                containerClassName="flex"
              />
              <FormInput
                id="billToAddressZip"
                formData={form}
                disabled={!canEditAll}
                label="title.postcode"
              />
            </div>

            <CountryCombobox
              id="billToAddressCountry"
              formData={form}
              label="title.country"
              disabled={!canEditAll}
            />
          </Card>
        )}
      </div>

      {canEdit && (
        <BottomButtonsPanel>
          <Button
            onClicked={save.mutate}
            disabled={!form.hasChanges}
            loading={save.loading}
            label="title.save.changes"
          />
        </BottomButtonsPanel>
      )}
    </>
  );
};

export default LocationDetailsGeneralTab;
