import React, {FC, useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { productsRootPath } from "~/Services/Routes";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {mutateProductV3, useProductV3} from "~/Data/Products/Products";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import { GeneralProductDetailsSection } from "./Components/GeneralProductDetailsSection";
import { FlagsProductDetailsSection } from "./Components/FlagsProductDetailsSection";
import { SizingProductDetailsSection } from "./Components/SizingProductDetailsSection";
import { MSRProductDetailsSection } from "./Components/MSRProductDetailsSection";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { Button } from "~/Components/UI";
import {useFormData, useMutate} from "~/Hooks";
import API, {
  ProductUfp,
  ProductV2ActualNominal,
  ProductV2ProductType,
  ProductV2UOM,
} from "~/API";
import {ProductType, ProductTypeCode} from "~/Reducers/Products";
import * as z from "zod";
import { DateTimeView } from "~/Components/Views/DateView";
import {flipObject, zodMsg} from "~/Utils";
import {mutate} from "swr";
import useSWRImmutable from "swr/immutable";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {useIsAdmin} from "~/Reducers/User";
import {trackEvent} from "~/Services/Tracking";

export const ProductDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const product = useProductV3(+id);

  if (product.error)
    return (
      <EndpointErrorPage
        status={product.error?.status}
        item="product"
      />
    );

  if (product.isLoading) return <LoadingPage />;

  return (
    <ProductDetailsPageLoaded
      id={id}
      product={product.data}
    />
  );
};

export const ProductNewPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const product = useSWRImmutable(
      ["api/msr/product", id],
      () => API.getProductFromMsr({ code: id })
    );

  const productData = useMemo(() => (product.data == undefined ? undefined : {
    ...product.data,
    productType: flipObject(ProductTypeCode)[product.data.msrProductTypeCode],
  }), [product.data]);

  if (product.error)
    return (
      <EndpointErrorPage
        status={product.error?.status}
        item="product"
      />
    );

  if (product.isLoading) return <LoadingPage />;

  return (
    <ProductDetailsPageLoaded
      id={id}
      isNew
      product={productData}
    />
  );
}


const ProductDetailsPageLoaded: FC<{
  id: string;
  isNew?: boolean;
  product: ProductUfp;
}> = (props) => {
  const history = useHistory();
  const isAdmin = useIsAdmin();

  const form = useFormData<ProductUfp>({
    ...props.product,
    ...(!props.isNew ? {} : {
      productGroup: getProductGroup(props.product),
      standardUnitOfMeasure: getProductUOM(props.product.msrProductTypeCode),
      productType: getProductType(props.product.msrProductTypeCode),
      ...(props.product.productType == ProductV2ProductType.FINANCE ? FinanceReset : {}),
    })
  }, {
    validation: productValidation,
    onChange: (_old, newVal) => {
      const isFinance = newVal.productType == ProductV2ProductType.FINANCE;
      return {
        ...newVal,
        productGroup: getProductGroup(newVal),
        ...(isFinance ? FinanceReset : {}),
      };
    },
  });

  const saveCreateCall = useMutate(async () => {
    if (props.isNew)
      return await API.createProduct({}, form.data);
    else
      return await API.updateProductV3({ id: +props.id }, form.data);
  });

  const onSave = async () => {
    if (form.isValid()) {
      const newProduct = await saveCreateCall.mutate();
      await mutateProductV3(mutate, +props.id);
      trackEvent(props.isNew ? "Product Created" : "Product Updated");

      if (props.isNew)
        history.replace(`${productsRootPath}${newProduct.id}`);
      else
        form.setData(newProduct, true);
    }
  };

  const canEdit = isAdmin;

  return (
    <ScrollPage title={props.isNew ? `New Product ${props.id}` : `Product ${props.product.productCode}`}>
      {form.hasChanges && !saveCreateCall.loading && <UnsavedChangesDialog onSave={onSave} />}
      <PageTitle type="back" parentUrl={productsRootPath} />
      <div className=" text-xl flex-row flex-wrap align-baseline mx-lg mt-lg mb-xs">
        {!!props.isNew ? (
          `New Product (${props.id})`
        ) : (
          <>
            <LocalizedMessage id={"title.product.with.id"}>
              {props.product.productCode}
            </LocalizedMessage>
            <div className="ml-md color-text-3 text-md text-normal">
              Last Updated: <DateTimeView date={props.product.dateUpdated} />
            </div>
          </>
        )}
      </div>

      <div className="flex-col gap-lg text-left">
        <GeneralProductDetailsSection form={form} canEdit={canEdit} />

        <div className="flex-row gap-lg flex-wrap">
          <FlagsProductDetailsSection form={form} canEdit={canEdit} />
          <SizingProductDetailsSection form={form} canEdit={canEdit} />
        </div>

        <MSRProductDetailsSection form={form} />
      </div>

      { canEdit &&
        <BottomButtonsPanel>
          <Button
            label={props.isNew ? "title.create.product" : "title.save.changes"}
            disabled={!form.hasChanges}
            loading={saveCreateCall.loading}
            onClicked={onSave}
          />
        </BottomButtonsPanel>
      }
    </ScrollPage>
  );
};

const getProductType = (val: string) => ({
  LU: ProductV2ProductType.LUMBER,
  PL: ProductV2ProductType.PANELS,
  LF: ProductV2ProductType.LINEAR_FOOTAGE,
  FI: ProductV2ProductType.FINANCE,
  SP: ProductV2ProductType.SPECIAL_PRODUCTS
})[val];

const getProductUOM = (val: string) => ({
  LU: ProductV2UOM.MBF,
  LF: ProductV2UOM.LF,
  PL: ProductV2UOM.MSF,
  SP: ProductV2UOM.PCS,
  FI: ProductV2UOM.FI,
})[val];

const getProductGroup = (val: ProductUfp) => {
  const substrings = {
    [ProductType.LUMBER]: [
      val.productCategory,
      val.species,
      val.surfaceCode,
      val.finish,
      val.grade,
      val.drynessCode,
    ],
    [ProductType.PANELS]: [
      val.productCategory,
      val.species,
      val.grade,
      val.construction,
    ],
    [ProductType.LINEAR_FOOTAGE]: [val.productCategory, val.type],
    [ProductType.SPECIAL_PRODUCTS]: [
      val.productCategory,
      val.species,
      val.classCode,
    ],
    [ProductType.FINANCE]: ["FINANCE" as any],
  };
  return (
    substrings[val.productType]
      ?.filter?.((it) => !!it && it !== "N/A")
      .join(" - ") || val.productGroup
  );
};

const FinanceReset: Partial<ProductUfp> = {
  productGroup: "Finance",
  productCategory: "",
  species: "",
  surfaceCode: "",
  finish: "",
  grade: "",
  drynessCode: "",
  construction: "",
  type: "",
  classCode: "",
  nominalActual: ProductV2ActualNominal.ACTUAL,
  standardUnitOfMeasure: ProductV2UOM.FI,
  unitsContainer: 0,
  unitsTL: 0,
  rlPriceId: undefined,
  pcsBdl: 0,
  foreignToForeign: false,
};

const productValidation = z
  .object({
    productType: z.nativeEnum(ProductV2ProductType),
    description: z.string().min(1, zodMsg("error.required", ["Description"])),
    foreignToForeign: z.boolean(),
    exportManufacturing: z.boolean(),
    isImport: z.boolean(),
    isExport: z.boolean(),
    isDomestic: z.boolean(),
    productCategory: z.string().optional(),
    species: z.string().optional(),
    surfaceCode: z.string().optional(),
    finish: z.string().optional(),
    grade: z.string().optional(),
    drynessCode: z.string().optional(),
    construction: z.string().optional(),
    type: z.string().optional(),
    classCode: z.string().optional(),
    nominalActual: z.string().min(1, zodMsg("error.required", ["Nominal Actual"])),
    standardUnitOfMeasure: z.string().min(1, zodMsg("error.required", ["Standard UOM"])),
    unitsContainer: z.any(),
    unitsTL: z.any(),
  })
  .refine(
    (d) =>
      d.foreignToForeign ||
      d.exportManufacturing ||
      d.isImport ||
      d.isExport ||
      d.isDomestic,
    {
      message: "Please mark at least one flag.",
      path: ["flag"],
    },
  )
  .refine((d) => d.productType == "FINANCE" || d.productCategory != "", {
    message: "Category is Required",
    path: ["productCategory"],
  })
  .refine(
    (d) =>
      d.productType == "FINANCE" ||
      d.productType == "LINEAR_FOOTAGE" ||
      d.species != "",
    {
      message: "Species is Required",
      path: ["species"],
    },
  )
  .refine((d) => d.productType != "LUMBER" || d.surfaceCode != "", {
    message: "Surface Code is Required",
    path: ["surfaceCode"],
  })
  .refine((d) => d.productType != "LUMBER" || d.finish != "", {
    message: "Finish is Required",
    path: ["finish"],
  })
  .refine(
    (d) =>
      d.productType == "LINEAR_FOOTAGE" ||
      d.productType == "SPECIAL_PRODUCTS" ||
      d.productType == "FINANCE" ||
      d.grade != "",
    {
      message: "Grade is Required",
      path: ["grade"],
    },
  )
  .refine((d) => d.productType != "LUMBER" || d.drynessCode != "", {
    message: "Dryness is Required",
    path: ["drynessCode"],
  })
  .refine((d) => d.productType != "PANELS" || d.construction != "", {
    message: "Construction is Required",
    path: ["construction"],
  })
  .refine((d) => d.productType != "LINEAR_FOOTAGE" || d.type != "", {
    message: "Type is Required",
    path: ["type"],
  })
  .refine((d) => d.productType == "FINANCE" || (d.unitsContainer != "" && !isNaN(Number(d.unitsContainer))), {
    message: "Units/Container is Required",
    path: ["unitsContainer"],
  })
  .refine((d) => d.productType == "FINANCE" || (d.unitsTL != "" && !isNaN(Number(d.unitsContainer))), {
    message: "Units/TL is Required",
    path: ["unitsTL"],
  })
  .refine((d) => d.productType != "SPECIAL_PRODUCTS" || d.classCode != "", {
    message: "Class Code is Required",
    path: ["classCode"],
  });
