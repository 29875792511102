import {Button, Case, Dialog, FormInput, Match} from "~/Components/UI";
import {FC} from "react";
import {useFormData, useToggle} from "~/Hooks";
import {LC} from "~/Locales";
import * as z from "zod";
import {validPassword, zodMsg} from "~/Utils";
import {useChangeUserPassword} from "~/Data/Users";

const formValidation = z
  .object({
    currentPassword: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Current password"])),
    newPassword: z
      .string()
      .refine((p) => validPassword(p), zodMsg("password.req.desc")),
    confirmPassword: z
      .string()
  })
  .refine((d) => d.confirmPassword === d.newPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export const UserChangePasswordButton: FC<{
  userId: number;
  email: string;
}> = (props) => {
  const open = useToggle();
  const changePassword = useChangeUserPassword(props.userId);

  const resetForm = useFormData({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }, { validation: formValidation });

  const onOpenChange = () => {
    resetForm.clear();
  }

  const onSave = async () => {
    if (resetForm.isValid()) {
      await changePassword.call(resetForm.data);
      open.toggleFalse();
    }
  }

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={<LC id="title.change.password" />}
      trigger={
        <Button
          label="title.change.password"
          color="gray"
        />
      }
    >
      <div className="flex-col gap-md desktop-w-9xl">
        {/* This is a small hack to make browsers correctly save new email/password combinations when the */}
        {/* form is closed. Otherwise, it recognizes user group select (in the background) as the username  */}
        {/* when saving to password manager ¯\_(ツ)_/¯ */}
        <input
          id="username"
          hidden={true}
          aria-hidden={true}
          value={props.email}
          autoComplete="username"
        />

        <FormInput
          id="currentPassword"
          formData={resetForm}
          label="title.current.password"
          type="password"
          autoComplete="current-password"
        />

        <FormInput
          id="newPassword"
          formData={resetForm}
          label="title.new.password"
          type="password"
          autoComplete="new-password"
        />

        <FormInput
          id="confirmPassword"
          formData={resetForm}
          label="title.confirm.password"
          type="password"
          autoComplete="new-password"
        />

        {changePassword.error && (
          <div className="color-red-1">
            <Match on={changePassword.error.status}>
              <Case v={403}>
                Current password is incorrect!
              </Case>
              <Case default>
                There as an error making the request!
              </Case>
            </Match>
          </div>
        )}

        <Button
          label="title.save"
          onClicked={onSave}
          loading={changePassword.isMutating}
        />
      </div>
    </Dialog>
  )
}