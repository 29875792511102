import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { useEffect, useMemo, useState } from "react";
import API, { GroupPermission, UserAccountView } from "~/API";
import { useCurrentUser, useIsInternalUser } from "~/Reducers/User";

// shows:
// 1. other users in current users company
// 2. all internal users if the current user is internal
// 3. all users from included company prop
export const UserComboboxByCompany = <T,>(
  props: {
    excludeUsers?: number[];
    includeCompany?: number;
  } & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const isInternalUser = useIsInternalUser();
  const currentUser = useCurrentUser();

  const getUsers = async () => {
    try {
      setUsersLoading(true);
      let newUsers: UserAccountView[] = [];
      if (props.includeCompany)
        newUsers = await API.getCompanyUsers({id: props.includeCompany!});

      const currentUserCompanies = isInternalUser
        ? await API.getUsersByPermissions({
          permissionTypes: [
            GroupPermission.CAN_BE_ADMIN,
            GroupPermission.CAN_BE_SELLER,
            GroupPermission.CAN_BE_BUYER,
            GroupPermission.CAN_BE_LOGISTICS,
          ],
        })
        : await API.getCompanyUsers({id: currentUser.companyId});

      newUsers = [...newUsers, ...currentUserCompanies].filter(n => !props.excludeUsers?.includes(n.id));
      setUsers(newUsers);
    } finally {
      setUsersLoading(false);
    }
  }

  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState<UserAccountView[]>([]);
  useEffect(() => {
    getUsers();
  }, []);

  const userIds = useMemo(
    () =>
      users
        .map((user) => user.id)
        .filter((id) => !(props.excludeUsers || []).includes(id)) || [],
    [users, props.excludeUsers],
  );

  const [userLabels, setUserLabels] = useState<Record<string, string>>({});

  useEffect(() => {
    let labels = {};
    users.forEach(
        (user) =>
          (labels[user.id] = `${user.name} ${user.surname} (${user.email})`),
      );
    setUserLabels((old) => ({ ...old, ...labels }));
  }, [userIds]);

  const getLabel = (id: number) => userLabels[id];

  return (
    <FormCombobox
      {...props}
      data={userIds}
      getLabel={getLabel}
      loading={usersLoading}
    />
  );
};
