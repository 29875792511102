import {FC, ReactNode} from "react";
import LandingPage from "./LandingPage";
import {getLocale} from "~/Locales";

export interface BaseLoginPageProps {
  children: ReactNode;
}

const BaseLoginPage: FC<BaseLoginPageProps> = ({ children }) => {
  const landingName = 'signup' + (getLocale() !== 'en' ? '-' + getLocale() : '');

  return (
    <div className={'flex-row flex bg-black-11-5'}>
      <div className={'flex-col align-center p-2xl bg-black-12 gap-md desktop-w-min-8xl desktop-w-max-9xl mobile-w-full'}>
        <div className={'flex-row w-full'}>
          <img className={'Logo'} alt="" src="../../../assets/logo.svg" width="137" height="32"/>
        </div>
        <div className={'flex text-left mt-3xl w-full desktop-w-max-8xl'}>
          {children}
        </div>
      </div>

      <LandingPage name={landingName}/>
    </div>
  )
}

export default BaseLoginPage;