import { OfferProductView, ProductV2UOM } from "~/API";
import React, { memo, useCallback, Fragment } from "react";
import { LocalizedMessage } from "~/Locales";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { formattedNumber } from '~/Utils/PriceCalculation';
import { ProductType, ProductTypeLabel, ProductTypeCode, productTypesArray } from "~/Reducers/Products";
import Button from "~/Components/Old/Button";
import { DeleteIcon } from "~/Components/Old/Icons";
import LengthSelect from "~/Components/Old/LengthSelect";
import TextField from "~/Components/Old/TextField";
import { UOMSelect } from "~/Components/Old/UOMSelect";
import { calculateOfferProductsTotalValue } from "../../Components/OffersHelper";
import { LengthsView } from "~/Components/Old/LengthsView";
import { classNames } from "react-ui-basics/Tools";
import './OfferDetailsSummarySectionTableVendor.scss';
import { getContainers } from "~/Utils/UnitConversion";
import { useLengthsByIds } from "~/Reducers/Lengths";
import { useProductPublic } from "~/Reducers/ProductsPublic";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";


const OfferDetailsSummarySectionTableVendor = (props) => {
  const { readOnly, currency, products } = props;

  const currencySymbol = useFindCurrencySymbol(currency);

  if (!products.length) return (
    <div>There are no Products in this offer yet. Add your first Product now!</div>
  );

  const totalValue = calculateOfferProductsTotalValue(products);
  const totalValueFormatted = formattedNumber(totalValue);

  const columnsClassName = readOnly
    ? 'OfferDetailsSummarySectionTableReadOnlyVendor'
    : 'OfferDetailsSummarySectionTableEditableVendor';

  return (
    <table className={classNames('basic-table OfferDetailsSummarySectionTableVendor', columnsClassName)}>
      {productTypesArray.map((productType, index) => {
        const filteredProducts = products.filter((product) => product.productType === productType);
        return filteredProducts.length > 0 &&
          <Fragment key={index}>
            <thead>
              <tr>
                <td className={'text-lg'} colSpan={readOnly ? 9 : 11}>
                  <LocalizedMessage id={'title.offer.summary'} /> -
                  <span className={'text-lg text-left color-blue-1'}> {ProductTypeLabel[productType]}</span>
                </td>
              </tr>
            </thead>
            <OfferProductsTable {...props} products={filteredProducts} productType={productType} data-cy={"OfferProductsTable"}/>
          </Fragment>;
      })}

      <tbody>
        <tr className={'text-xl upper-case'}>
          <td /><td /><td /><td /><td /><td /><td />
          {!readOnly && <td />}
          <td><LocalizedMessage id={'title.total'} /></td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </table>
  );
};

export default OfferDetailsSummarySectionTableVendor;


const OfferProductsTable = (props) => {
  const { products, onProductFieldChange, onRemoveProduct, currency, productType = null, readOnly } = props;

  const sortedProducts = products.sort((a, b) => a.id - b.id);
  const currencySymbol = useFindCurrencySymbol(currency);

  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const filteredProducts = productType ? sortedProducts.filter(p => p.productType === productType) : sortedProducts;

  const totalValue = calculateOfferProductsTotalValue(filteredProducts);
  const totalValueFormatted = formattedNumber(totalValue);

  return (
    <>
      <thead>
        <tr>
          <th><LocalizedMessage id={'title.code'} /></th>
          <th><LocalizedMessage id={'title.description'} /></th>
          {!readOnly && <th><LocalizedMessage id={'title.type'} /></th>}
          <th>{showLength && <LocalizedMessage id={'title.length'} />}</th>
          <th><LocalizedMessage id={'title.uom'} /></th>
          <th><LocalizedMessage id={'title.containers'} /></th>
          <th><LocalizedMessage id={'title.quantity'} /></th>
          <th />
          <th><LocalizedMessage id={'title.price'} /></th>
          <th className={'text-right'}><LocalizedMessage id={'title.subtotal'} /></th>
          {!readOnly && <th><LocalizedMessage id={'title.actions'} /></th>}
        </tr>
      </thead>

      <tbody>
        {filteredProducts.map((product, index) =>
          <OfferProductRow key={index} {...{ product, currency, onProductFieldChange, onRemoveProduct, readOnly }} />
        )}

        <tr className={'text-lg'}>
          <td /><td /><td /><td /><td /><td /><td />
          {!readOnly && <td />}
          <td><LocalizedMessage id={'title.subtotal'} /></td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </>
  );
};

interface OfferProductRowProps {
  product: OfferProductView;
  currency: string;
  onProductFieldChange: (productId, event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveProduct: (productId) => void;
  readOnly: boolean;
}

const OfferProductRow = memo<OfferProductRowProps>((props) => {
  const { product, currency, onProductFieldChange, onRemoveProduct, readOnly = false } = props;

  const currencySymbol = useFindCurrencySymbol(currency);

  const {
    id = '',
    productCode = '',
    productDescription = '',
    productActLength = '',
    quantity = 1,
    uom = '',
    price = 0,
    lengthIds = [],
    productGroupId = '',
    productType = '',
  } = product;

  const productTypeCode = ProductTypeCode[productType];

  const onFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onProductFieldChange(id, event);
  }, [id, onProductFieldChange]);

  const onRemoveClick = useCallback(() => {
    onRemoveProduct(id);
  }, [id, onRemoveProduct]);

  const subtotal = quantity * price;

  const subtotalFormatted = formattedNumber(subtotal);
  const priceRounded = price && Number(price).toFixed(2);

  const isUomReadOnly = productType === ProductType.LINEAR_FOOTAGE || productType === ProductType.SPECIAL_PRODUCTS;

  const isPanels = productType === ProductType.PANELS;
  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;
  const showLengthForPanels = isPanels && showLength;
  const showLengthForOthers = !isPanels && showLength;
  const lengths = useLengthsByIds(lengthIds)
  const productData = useProductPublic(product?.productId)
  const isLumber = productType === ProductType.LUMBER;
  const uomIsLf = uom == ProductV2UOM.LF;
  const limitLengths = uomIsLf && isLumber;


  return (
    <tr className={'OfferSummaryProductRow'}>
      <td> {productCode} </td>
      <td>
        <ProductCatalogueDescriptionLink
          description={product.productDescription}
          productCode={product.productCode}
          productId={product.productId}
          hasSyndigo={product.hasSyndigo}
        />
      </td>
      {!readOnly && <td> {productTypeCode}</td>}
      {readOnly && <td>
        {showLength
          ? <LengthsView lengthIds={lengthIds} />
          : '-'
        }
      </td>}
      {!readOnly &&
        <td>
          {showLengthForPanels &&
            <>
              {readOnly
                ? <div>{productActLength}</div>
                : <TextField className={'ValueField'} value={productActLength} disabled={true} />
              }
            </>
          }
          {showLengthForOthers &&
            <>
              {readOnly
                ? <LengthsView lengthIds={lengthIds} />
                : <LengthSelect id={'lengthIds'}
                  value={lengthIds}
                  multiple={!limitLengths}
                  className={'ValueField'}
                  byGroup={productGroupId}
                  onChange={onFieldChange}
                  inlineSelected
                />
              }
            </>
          }
          {!showLength && '-'}
        </td>
      }
      <td>
        {readOnly
          ? <div className={'flex-row align-center AlignItemsCenter'}> {uom} </div>
          : <UOMSelect id="uom"
            className={'ValueField'}
            value={uom}
            productType={productType}
            onChange={onFieldChange}
            disabled={isUomReadOnly}
          />
        }
      </td>
      <td>{getContainers(productData, lengths?.[0], quantity, uom as ProductV2UOM).toFixed(2)}</td>
      <td>
        {readOnly
          ? <div className={'flex-row align-center AlignItemsCenter'}> {quantity} </div>
          : <TextField id="quantity"
            type={'number'} hideArrows float
            className={'ValueField MidsizeField'}
            value={quantity}
            onChange={onFieldChange}
            min={0} max={99999999}
          />}
      </td>
      <td className={'text-right'}>
        <div className={'flex-row align-center justify-end'}>
          {readOnly
            ? <div className={'flex-row align-center text-right color-orange-1 mr-xxs text-bold'}> {priceRounded} </div>
            : <TextField id="price"
              type={'number'} hideArrows float
              className={'ValueField BigField mr-xxs'}
              inputStyle={{ 'textAlign': 'right' }}
              value={price}
              onChange={onFieldChange}
            />}
        </div>
      </td>
      <td>{currency}/{uom}</td>
      <td className={'text-right color-orange-1 no-wrap'}>
        <b>{currencySymbol}{subtotalFormatted}</b>
      </td>

      {!readOnly && <td>
        <div className={'flex-row align-center'}>
          <Button view={'text'}
            className={'ActionButton DeleteButtonText'}
            onClick={onRemoveClick}>
            <DeleteIcon size={16} />
          </Button>
        </div>
      </td>}
    </tr>
  );
});
