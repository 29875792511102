import {QuoteStatus} from "~/API";
import {useMemo, useState} from "react";
import { LocalizedMessage, ls } from "~/Locales";
import {useIsAdmin, useIsCustomer, useIsUFPAM} from "~/Reducers/User";
import {useIsNew, useIsReadOnly} from "../../Components/QuotesHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import Button from "~/Components/Old/Button";
import ConfirmationDialog, {ConfirmationDialogProps} from "~/Components/Old/ConfirmationDialog";
import {AMConfirmQuoteButton} from "./AMConfirmQuoteButton";
import {useCompany} from "~/Reducers/Companies";
import Toast from "~/Components/Toast";


interface QuoteBottomButtonsProps {
  onSave: any,
  onBack: any,
  quoteId: number,
  onEdit: any,
  onRFOCreate: any,
  onShowMatchingTable: any,
  hasChanges: boolean,
  loading: boolean,
  status: QuoteStatus,
  customerCompanyId: number | undefined,
  hasOfferReference: boolean,
  saveQuote: (status: QuoteStatus) => Promise<void>,
  copyQuote: () => void,
  isVendorDomestic: boolean
}

const QuoteBottomButtons = (props: QuoteBottomButtonsProps) => {
  const { onSave, onBack, onEdit, hasChanges, loading, status, customerCompanyId, saveQuote, copyQuote, quoteId, isVendorDomestic } = props;

  const readOnly = useIsReadOnly();
  const isNew = useIsNew();

  const isAdmin = useIsAdmin();
  const isUFPAM = useIsUFPAM();
  const isCustomer = useIsCustomer();

  const disableSaveButton = (!hasChanges || loading) && !isNew;

  const customerCompany = useCompany(customerCompanyId);

  const isDomestic = isVendorDomestic || customerCompany?.isInternal || false;

  const amCanConfirm = useMemo(() => {
    if (customerCompany === undefined)
      return false;

    if (customerCompany.requireOrderApproval) {
      return (status === QuoteStatus.TO_BE_CONFIRMED);
    } else {
      return (status === QuoteStatus.TO_BE_CONFIRMED || status === QuoteStatus.INTERESTED || status === QuoteStatus.SENT);
    }
  }, [customerCompany, status]);

  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialogProps>();

  // hide bar if there's no buttons to show
  if(readOnly && !(isAdmin || isUFPAM) && !(isCustomer && (status === QuoteStatus.INTERESTED || status === QuoteStatus.SENT)))
    return <></>

  return <BottomButtonsPanel>
    {confirmationDialog && <ConfirmationDialog
      {...confirmationDialog}
      onConfirm={() => {
        confirmationDialog.onConfirm();
        setConfirmationDialog(null);
      }}
      onClose={() => setConfirmationDialog(null)}
    />}
    {!readOnly &&
      <>
        <Button view={'primary'} onClick={onSave} disabled={disableSaveButton} data-cy={"CreateQuoteButton"}>
          <LocalizedMessage
            id={isNew ? 'title.create.quote' : (status === QuoteStatus.DRAFT ? 'title.save' : 'title.save.and.submit.to.customer')} />
        </Button>
        <Button view={'secondary'} onClick={onBack}>
          <LocalizedMessage id={'title.cancel'} />
        </Button>
      </>
    }
    {readOnly &&
      <>
        {(isAdmin || isUFPAM) &&
          <>
            <Button view='primary' onClick={copyQuote}>
              <LocalizedMessage id="title.copy.quote" />
            </Button>
            {status === QuoteStatus.DRAFT &&
              <Button onClick={() => {
                saveQuote(QuoteStatus.SENT)
                  .then(() => Toast.Success(ls("phrase.quote.submitted.successfully")));
              }} data-cy={"SubmitToCustomerButton"}>
                <LocalizedMessage id={'title.submit.quote.to.customer'} />
              </Button>
            }
            {amCanConfirm && (
              <>
                <AMConfirmQuoteButton quoteId={quoteId} isDomestic={isDomestic} />
                <Button onClick={() => saveQuote(QuoteStatus.ARCHIVED)}>
                  <LocalizedMessage id={'title.decline.quote'} />
                </Button>
              </>
            )}
            {status === QuoteStatus.ARCHIVED &&
              <Button onClick={() => saveQuote(QuoteStatus.SENT)}>
                <LocalizedMessage id={'title.reopen.quote'} />
              </Button>
            }
            {(status !== QuoteStatus.DELIVERED && status !== QuoteStatus.ARCHIVED && status !== QuoteStatus.ORDERED) &&
              <Button view={'secondary'} onClick={onEdit}>
                <LocalizedMessage id={'title.update.quote'} />
              </Button>
            }
          </>
        }
        {isCustomer && (status === QuoteStatus.INTERESTED || status === QuoteStatus.SENT) && (
          <>
            <Button onClick={() => saveQuote(QuoteStatus.ARCHIVED)}>
              <LocalizedMessage id={'title.decline.quote'} />
            </Button>
            <Button onClick={() => setConfirmationDialog({
              title: 'Quote Confirmation',
              description: 'Do you want to confirm this quote? An order will be created as soon as the account manager confirmed your quote.',
              confirmTitle: <LocalizedMessage id={'title.confirm'} />,
              closeTitle: <LocalizedMessage id={'title.close'} />,
              onConfirm: () => saveQuote(QuoteStatus.TO_BE_CONFIRMED)
            }
            )}>
              <LocalizedMessage id={'title.confirm.quote'} />
            </Button>
          </>
        )}
        {/*{(isAdmin || isUFPBuyer) &&*/}
        {/*  <>*/}
        {/*    <Button onClick={onRFOCreate} disabled={hasOfferReference}>*/}
        {/*      <LocalizedMessage id={'title.create.offer'} />*/}
        {/*    </Button>*/}
        {/*    <Button onClick={onShowMatchingTable}>*/}
        {/*      <LocalizedMessage id={'title.find.vendors'} />*/}
        {/*    </Button>*/}
        {/*  </>*/}
        {/*}*/}
      </>
    }
  </BottomButtonsPanel>;
};

export default QuoteBottomButtons;
