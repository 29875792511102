import { FC } from "react";
import { ls } from "~/Locales";
import {PurchaseOrderStatus} from "~/API";
import { cn } from "~/Utils";

export const orderStatusData: Record<
  PurchaseOrderStatus,
  { label: string; className: string }
> = {
  VOID: { label: "VOID", className: "bg-black-8 color-black-12" },
  REQUESTED: {
    label: ls("delivery.status.REQUESTED"),
    className: "bg-pink-9 color-pink-1",
  },
  ACKNOWLEDGED: {
    label: ls("delivery.status.ACKNOWLEDGED"),
    className: "bg-purple-9 color-purple-1",
  },
  ORDERED: {
    label: ls("delivery.status.ORDERED"),
    className: "bg-blue-8 color-blue-0",
  },
  INBOUND: {
    label: ls("delivery.status.INBOUND"),
    className: "bg-orange-8 color-orange-0",
  },
  PARTIAL: {
    label: ls("delivery.status.PARTIAL"),
    className: "bg-orange-8 color-orange-0",
  },
  COMPLETE: {
    label: ls("delivery.status.COMPLETE"),
    className: "bg-green-8 color-green-0",
  },
};

export const PurchaseOrderStatusBadge: FC<{ status?: PurchaseOrderStatus }> = (
  props,
) => {
  if (props.status == null)
    return <></>

  const data = orderStatusData[props.status];

  return (
    <div className="flex-row">
      <div
        className={cn(
          "py-xxs px-sm text-semibold text-sm rounded-sm text-truncate",
          data.className,
        )}
      >
        {data.label}
      </div>
    </div>
  );
};
