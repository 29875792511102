import { FC } from "react";
import {Registration} from "~/API";
import { cn } from "~/Utils";
import {LC, LocalizedMessageKey} from "~/Locales";

export const regStatusData: Record<Registration, string> = {
  NOT_SENT: "bg-black-9 color-black-4",
  EMAIL_SENT: "bg-orange-8 color-orange-0",
  REGISTERED: "bg-green-8 color-green-0",
};

export const UserStatusBadge: FC<{ status: Registration }> = (props) => {
  const classes = regStatusData[props.status];

  return (
    <div className="flex-row">
      <div
        className={cn("py-xxs px-sm text-semibold text-sm rounded-sm text-truncate", classes)}
      >
        <LC id={`user.account.status.${props.status}` as LocalizedMessageKey} />
      </div>
    </div>
  );
};