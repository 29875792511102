import { FC, useMemo } from "react";
import { Card, Input, Table } from "~/Components/UI";
import { FormReturn } from "~/Hooks";
import { ProductGroupView, ProductUfp } from "~/API";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ls } from "~/Locales";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import { setProductPrice } from "~/Pages/Products/GroupsPage/Reducer";

export const ProductsProductGroupSection: FC<{
  form: FormReturn<ProductGroupView>;
  products: ProductUfp[];
  canEdit: boolean;
}> = (props) => {
  const columns = useMemo(() => getColumns(props.canEdit), [props.canEdit]);
  const table = useReactTable({
    data: props.products,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card className="flex-col gap-md">
      <div className="text-lg">Products</div>
      <Table table={table} />
    </Card>
  );
};

const columnHelper = createColumnHelper<ProductUfp>();
const getColumns = (canEdit: boolean) => [
  columnHelper.accessor("productCode", {
    cell: (data) => data.getValue(),
    header: ls("title.code"),
    size: 0,
  }),
  columnHelper.accessor("description", {
    cell: (data) => data.getValue(),
    header: ls("title.description"),
  }),
  columnHelper.accessor("price", {
    cell: (data) => {
      const productPrices = useAppSelector(
        (state) => state.productGroupsPage.productPrices,
      );
      const dispatch = useAppDispatch();
      const onChange = (e: any) =>
        dispatch(
          setProductPrice({ key: data.row.original.id, price: e.target.value }),
        );

      return (
        <Input
          id="price"
          value={`${productPrices[data.row.original.id]}`}
          type="number"
          onChange={onChange}
          disabled={!canEdit}
        />
      );
    },
    header: ls("title.price"),
  }),
];
