import {FC, Fragment, memo} from "react";
import {
  requestsRootPath,
  offersRootPath,
  requestedOffersRootPath, getPathForSO
} from "~/Services/Routes";
import { useOfferAsReferenceText } from "~/Reducers/Offers";
import { useRequestAsReferenceText } from "~/Reducers/RequestsForQuote";
import {useSalesOrder} from "~/Data/Orders/SalesOrders";
import {LocalizedMessage, LocalizedMessageKey} from "~/Locales";
import {Skeleton} from "~/Components/Loaders";


interface QuoteReferenceProps {
  requestForQuoteId: number;
  offerId: number;
  showDash?: boolean;
  simple?: boolean;
  salesOrderIds: number[];
}

const QuoteReference: FC<{
  requestForQuoteId: number,
  offerId: number,
  salesOrderIds: number[],
  showDash?: boolean,
  simple?: boolean,
}> = ({ requestForQuoteId, offerId, salesOrderIds, showDash = true, simple = false }: QuoteReferenceProps) => {
  if (!requestForQuoteId && !offerId && (!salesOrderIds || salesOrderIds.length === 0)) {
    return !!showDash ? <>{'-'}</> : null;
  }

  if (simple) return (
    <>
      {requestForQuoteId &&
        <a href={`${requestsRootPath}${requestForQuoteId}`} target="_blank" rel="noreferrer noopener">
          {`RQ-${requestForQuoteId}`}
        </a>
      }

      {offerId &&
        <>
          {requestForQuoteId && offerId && <>,&nbsp;</>}
          <a href={`${offersRootPath}${offerId}`}>
            {`O-${offerId}`}
          </a>
        </>}
    </>
  );

  return (
    <div className={'flex-col'}>
      {requestForQuoteId && <RequestReferenceText requestId={requestForQuoteId} />}

      {requestForQuoteId && offerId && <br />}
      {offerId && <OfferReferenceText {...{ offerId }} />}

      {(requestForQuoteId || offerId) && salesOrderIds && salesOrderIds.length !== 0 && <br/>}
      {salesOrderIds.map((orderId, i) => (
          <Fragment key={i}>
            {(i !== salesOrderIds.length - 1 || i !== 0) && <br/>}
            <SalesOrderReference orderId={orderId}/>
          </Fragment>
      ))}
    </div >
  );
};

export default QuoteReference;


const stopPropagation = (event: any) => event.stopPropagation();

const RequestReferenceText = memo<{ requestId: number }>(({ requestId }) => {
  const text = useRequestAsReferenceText(requestId);
  return (
    <a href={`${requestsRootPath}${requestId}`} target="_blank" rel="noreferrer noopener" onClick={stopPropagation}>
      {text}
    </a>
  );
});

const OfferReferenceText = memo<{ offerId: number }>(({ offerId }) => {
  const text = useOfferAsReferenceText(offerId);
  const rootPath = text.startsWith('RO') ? requestedOffersRootPath : offersRootPath;
  return (
    <a href={`${rootPath}${offerId}`} target="_blank" rel="noreferrer noopener" onClick={stopPropagation}>
      {text}
    </a>
  );
});

const SalesOrderReference = memo<{ orderId: number }>(({ orderId }) => {
  const order = useSalesOrder(orderId);

  if (order.isLoading) {
    return <Skeleton className="w-6xl h-line-md" />
  }

  if (order.error) {
    return (
      <a href={getPathForSO(orderId)} target="_blank" rel="noreferrer noopener">
        Sales Order - Order not Found
      </a>
    );
  }

  const buyerStatusName = order.data.buyerStatus ? <LocalizedMessage id={`delivery.status.${order.data.buyerStatus}` as LocalizedMessageKey} /> : '-';

  return (
    <a href={getPathForSO(orderId)} target="_blank" rel="noreferrer noopener">
      {`Sales Order - ${order.data.number} - `}
      {buyerStatusName}
    </a>
  )
})
