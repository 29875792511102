import { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";

/**
* @deprecated please use `~/Components/Nav/UnsavedChangesDialog`
**/
const UnsavedChangesPrompt = ({
  hasChanges,
  onDiscard = null,
  onSave,
  onBack = null,
}) => (
  <ChangeURLPrompt
    hasChanges={hasChanges}
    onDiscard={onDiscard}
    onSave={onSave}
    onBack={onBack}
    title="Save Changes?"
    description="Looks like you have unsaved changes. Do you want to save them?"
    confirmTitle="Save"
    closeTitle="Don't save"
  />
);

export const LeaveVideoCallPrompt = ({ shouldDisplay }) => (
  <ChangeURLPrompt
    hasChanges={shouldDisplay}
    shouldStayOnDiscard={true}
    title="Leave Video Call?"
    description="You are in the video call. Do you want to leave?"
    confirmTitle="Leave"
    closeTitle="Stay"
    // onDiscard={()=>{}}
  />
);

const ChangeURLPrompt = ({
  hasChanges,
  onDiscard,
  shouldStayOnDiscard = false,
  onSave,
  onBack,
  title,
  description,
  confirmTitle,
  closeTitle,
}) => {
  const history = useHistory();
  const [promptProps, setPromptProps] = useState({ showDialog: false });
  const [leaveWithoutChanges, setLeaveWithoutChanges] = useState(false);

  const promptIfHasChanges = !!hasChanges && !leaveWithoutChanges;

  useEffect(() => {
    if (!promptIfHasChanges) return;

    const beforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = false;
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [promptIfHasChanges]);

  useEffect(() => {
    if (!!leaveWithoutChanges) {
      const { locationToLeave, actionToLeave } = promptProps;
      if (!locationToLeave && !actionToLeave) {
        onBack?.();
      } else if (actionToLeave === "POP") {
        history.goBack();
      } else if (actionToLeave === "REPLACE") {
        history.replace(locationToLeave);
      } else {
        history.push(locationToLeave);
      }
    }
  }, [leaveWithoutChanges, promptProps]);

  const onSaveChanges = async () => {
    try {
      await onSave?.();
      setLeaveWithoutChanges(true);
    } catch {}
  };

  const onDiscardChanges = () => {
    onClose();
    // special case for video call. clicking on "Discard" mean stay on the same page
    if (!shouldStayOnDiscard) {
      setLeaveWithoutChanges(true);
      onDiscard?.();
    }
  };

  const onClose = () => {
    setPromptProps({ ...promptProps, showDialog: false });
  };

  return (
    <>
      <Prompt
        when={promptIfHasChanges}
        message={(location, action) => {
          if (!promptIfHasChanges) return true;
          setPromptProps({
            showDialog: true,
            locationToLeave: location,
            actionToLeave: action,
          });
          return false;
        }}
      />

      {promptProps.showDialog && (
        <ConfirmationDialog
          onDiscard={onDiscardChanges}
          {...{
            title,
            description,
            confirmTitle,
            closeTitle,
            onClose,
            onConfirm: onSaveChanges,
          }}
        />
      )}
    </>
  );
};

export default UnsavedChangesPrompt;
