import {forwardRef, MouseEventHandler, ReactNode, useMemo} from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import {
  LocalizedMessage,
  LocalizedMessageKey,
} from "~/Locales";
import { TbLoader2 } from "react-icons/tb";
import { cn } from "~/Utils";
import { Show } from "~/Components/UI";

const colorStyles = {
  blue: {
    filled: {
      active:
        "cursor-pointer focus-outline-blue-0 border-none bg-blue-2 hover-bg-blue-1 color-black-12",
      disabled: "cursor-disabled border-none bg-blue-9 color-blue-2",
    },
    outline: {
      active:
        "cursor-pointer bg-none focus-outline-blue-0 border-1 color-blue-2 border-blue-2 hover-bg-blue-10",
      disabled: "cursor-disabled bg-none border-1 color-blue-6 border-blue-6",
    },
    text: {
      active:
        "cursor-pointer bg-none focus-outline-blue-6 border-none color-blue-2 hover-color-blue-0",
      disabled: "cursor-disabled bg-none border-none color-blue-6",
    },
  },
  red: {
    filled: {
      active:
        "cursor-pointer border-none focus-outline-red-0 bg-red-2 hover-bg-red-1 color-red-7",
      disabled: "cursor-disabled border-none bg-red-6 color-red-3",
    },
    outline: {
      active:
        "cursor-pointer bg-none border-1 focus-outline-red-0 color-red-2 border-red-2 hover-bg-red-10",
      disabled: "cursor-disabled bg-none border-1 color-red-6 border-red-6",
    },
    text: {
      active:
        "cursor-pointer bg-none border-none focus-outline-red-6 color-red-2 hover-color-red-0",
      disabled: "cursor-disabled bg-none border-none color-red-6",
    },
  },
  green: {
    filled: {
      active:
        "cursor-pointer border-none bg-green-2 hover-bg-green-1 color-green-10",
      disabled: "cursor-disabled border-none bg-green-10 color-green-2",
    },
    outline: {
      active:
        "cursor-pointer bg-none border-1 color-green-2 border-green-2 hover-bg-green-10",
      disabled: "cursor-disabled bg-none border-1 color-green-7 border-green-7",
    },
    text: {
      active:
        "cursor-pointer bg-none border-none color-green-2 hover-color-green-0",
      disabled: "cursor-disabled bg-none border-none color-green-0",
    },
  },
  gray: {
    filled: {
      active:
        "cursor-pointer border-none focus-outline-gray-4 bg-black-10 hover-bg-black-9 color-black-4",
      disabled: "cursor-disabled border-none bg-black-7 color-black-10",
    },
    outline: {
      active:
        "cursor-pointer bg-none border-1 focus-outline-gray-9 color-black-4 border-black-4 hover-bg-black-11",
      disabled: "cursor-disabled bg-none border-1 color-black-9 border-black-9",
    },
    text: {
      active:
        "cursor-pointer bg-none border-none focus-outline-gray-4 color-black-4 hover-color-black-2",
      disabled: "cursor-disabled bg-none border-none color-black-10",
    },
  },
};

export interface ButtonProps {
  label?: LocalizedMessageKey;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  asChild?: boolean;
  loading?: boolean;
  onClicked?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  color?: "blue" | "red" | "green" | "gray";
  variant?: "filled" | "outline" | "text";
  className?: string;
  children?: ReactNode;
  dataCy?: string;
}

export const Button = forwardRef<any, ButtonProps>((props, ref) => {
  const otherProps = useMemo(() => {
    const {
      label,
      leftIcon,
      rightIcon,
      asChild,
      loading,
      onClicked,
      disabled,
      color,
      variant,
      className,
      dataCy,
      ...other
    } = props;
    return other;
  }, [props]);

  const Comp = props.asChild ? Slot : "button";

  const onCompClick = (e: any) => {
    e.stopPropagation();
    if (props.disabled) return;
    props.onClicked?.(e);
  };

  const state = props.disabled || props.loading ? "disabled" : "active";
  let colorClasses =
    colorStyles[props.color || "blue"][props.variant || "filled"][state];

  return (
    <Comp
      ref={ref}
      onClick={onCompClick}
      className={cn(
        colorClasses,
        props.className,
        "flex-row p-0 m-0 align-center gap-sm justify-center rounded-xxs focus-outline-2 transition-background",
        props.label === undefined ? "p-xxs" : "px-lg py-xs",
      )}
      data-cy={props.dataCy}
      {...otherProps}
    >
      <Show when={props.leftIcon && !props.loading}>{props.leftIcon}</Show>

      <Show when={props.loading}>
        <TbLoader2 size={16} className="animate-spin" />
      </Show>

      <Show when={props.label !== undefined}>
        <div className="upper-case text-center text-sm text-bold">
          <LocalizedMessage id={props.label} />
        </div>
      </Show>

      <Slottable>{props.children}</Slottable>

      <Show when={props.rightIcon && !props.loading}>{props.rightIcon}</Show>
    </Comp>
  );
});
