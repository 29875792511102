export const ArrowRightIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.1147 8.00005L6.2287 6.11472L7.17137 5.17139L10 8.00005L7.17137 10.8287L6.2287 9.88539L8.1147 8.00005Z" />
</svg>;

export const ArrowLeftIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M7.88533 8.00005L9.77133 9.88539L8.82867 10.8287L6 8.00005L8.82867 5.17139L9.77133 6.11472L7.88533 8.00005Z" />
</svg>;

export const ArrowUpIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M7.99999 7.88533L6.11466 9.77133L5.17133 8.82867L7.99999 6L10.8287 8.82867L9.88533 9.77133L7.99999 7.88533Z" />
</svg>;

export const ArrowDownIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.00005 9.99985L5.17139 7.17118L6.11472 6.22852L8.00005 8.11452L9.88539 6.22852L10.8287 7.17118L8.00005 9.99985Z" />
</svg>;

export const ChatIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M6.67 2h2.66a5.33 5.33 0 010 10.67V15c-3.33-1.33-8-3.33-8-7.67A5.33 5.33 0 016.67 2zM8 11.33h1.33a4 4 0 100-8H6.67a4 4 0 00-4 4C2.67 9.74 4.3 11.31 8 13v-1.66z" />
</svg>;

export const UploadIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 40 40" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M6.66667 31.6654H33.3333V19.9987H36.6667V33.332C36.6667 33.7741 36.4911 34.198 36.1785 34.5105C35.866 34.8231 35.442 34.9987 35 34.9987H5C4.55798 34.9987 4.13405 34.8231 3.82149 34.5105C3.50893 34.198 3.33334 33.7741 3.33334 33.332V19.9987H6.66667V31.6654ZM23.3333 14.9987V24.9987H16.6667V14.9987H8.33334L20 3.33203L31.6667 14.9987H23.3333Z" />
</svg>;

export const MenuIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
</svg>;

export const BackArrowIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M5.21869 7.33312H13.3334V8.66645H5.21869L8.79469 12.2425L7.85202 13.1851L2.66669 7.99979L7.85202 2.81445L8.79469 3.75712L5.21869 7.33312Z" />
</svg>;

export const FilterIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M6.66667 12H9.33333V10.6667H6.66667V12ZM2 4V5.33333H14V4H2ZM4 8.66667H12V7.33333H4V8.66667Z" />
</svg>;

export const CloseIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" />
</svg>;

export const CancelIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 10.586L14.828 7.757L16.243 9.172L13.414 12L16.243 14.828L14.828 16.243L12 13.414L9.172 16.243L7.757 14.828L10.586 12L7.757 9.172L9.172 7.757L12 10.586Z" />
</svg>;

export const AlertIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  {props.alt && <title>{props.alt}</title>}
  <path d="M8.00001 14.6663C4.31801 14.6663 1.33334 11.6817 1.33334 7.99967C1.33334 4.31767 4.31801 1.33301 8.00001 1.33301C11.682 1.33301 14.6667 4.31767 14.6667 7.99967C14.6667 11.6817 11.682 14.6663 8.00001 14.6663ZM8.00001 13.333C9.4145 13.333 10.7711 12.7711 11.7712 11.7709C12.7714 10.7707 13.3333 9.41416 13.3333 7.99967C13.3333 6.58519 12.7714 5.22863 11.7712 4.22844C10.7711 3.22824 9.4145 2.66634 8.00001 2.66634C6.58552 2.66634 5.22897 3.22824 4.22877 4.22844C3.22858 5.22863 2.66668 6.58519 2.66668 7.99967C2.66668 9.41416 3.22858 10.7707 4.22877 11.7709C5.22897 12.7711 6.58552 13.333 8.00001 13.333V13.333ZM7.33334 9.99967H8.66668V11.333H7.33334V9.99967ZM7.33334 4.66634H8.66668V8.66634H7.33334V4.66634Z" />
</svg>;

export const InfoIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.00004 14.6663C4.31804 14.6663 1.33337 11.6817 1.33337 7.99967C1.33337 4.31767 4.31804 1.33301 8.00004 1.33301C11.682 1.33301 14.6667 4.31767 14.6667 7.99967C14.6667 11.6817 11.682 14.6663 8.00004 14.6663ZM8.00004 13.333C9.41453 13.333 10.7711 12.7711 11.7713 11.7709C12.7715 10.7707 13.3334 9.41416 13.3334 7.99967C13.3334 6.58519 12.7715 5.22863 11.7713 4.22844C10.7711 3.22824 9.41453 2.66634 8.00004 2.66634C6.58555 2.66634 5.229 3.22824 4.2288 4.22844C3.22861 5.22863 2.66671 6.58519 2.66671 7.99967C2.66671 9.41416 3.22861 10.7707 4.2288 11.7709C5.229 12.7711 6.58555 13.333 8.00004 13.333ZM7.33337 4.66634H8.66671V5.99967H7.33337V4.66634ZM7.33337 7.33301H8.66671V11.333H7.33337V7.33301Z" />
</svg>;

export const CheckIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 32 32" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M13.333 20.2295L25.589 7.97217L27.4757 9.8575L13.333 24.0002L4.84766 15.5148L6.73299 13.6295L13.333 20.2295Z" />
</svg>;

export const ShowPassIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M17.882 19.2966C16.1232 20.4121 14.0828 21.0029 12 20.9996C6.60803 20.9996 2.12203 17.1196 1.18103 11.9996C1.61106 9.6703 2.78266 7.54247 4.52103 5.93358L1.39203 2.80758L2.80703 1.39258L22.606 21.1926L21.191 22.6066L17.881 19.2966H17.882ZM5.93503 7.34958C4.57604 8.58517 3.62935 10.2084 3.22303 11.9996C3.53532 13.366 4.16229 14.6407 5.05403 15.7222C5.94577 16.8036 7.07766 17.662 8.35958 18.2289C9.64151 18.7958 11.0381 19.0556 12.4381 18.9877C13.8382 18.9198 15.203 18.5259 16.424 17.8376L14.396 15.8096C13.5327 16.3534 12.5102 16.5877 11.4963 16.474C10.4823 16.3604 9.53707 15.9055 8.8156 15.184C8.09413 14.4625 7.63926 13.5173 7.52559 12.5033C7.41193 11.4894 7.64621 10.4669 8.19003 9.60358L5.93503 7.34958ZM12.914 14.3276L9.67203 11.0856C9.49409 11.5385 9.45222 12.0335 9.55154 12.5099C9.65085 12.9863 9.88705 13.4234 10.2311 13.7675C10.5752 14.1116 11.0123 14.3478 11.4887 14.4471C11.9651 14.5464 12.4601 14.5045 12.913 14.3266L12.914 14.3276ZM20.807 16.5916L19.376 15.1616C20.0445 14.2089 20.5204 13.1348 20.777 11.9996C20.5053 10.8093 19.9943 9.68672 19.2752 8.70007C18.5561 7.71342 17.6438 6.88331 16.5939 6.26018C15.544 5.63706 14.3783 5.23395 13.1678 5.07534C11.9572 4.91674 10.727 5.00596 9.55203 5.33758L7.97403 3.75958C9.22103 3.26958 10.58 2.99958 12 2.99958C17.392 2.99958 21.878 6.87958 22.819 11.9996C22.5126 13.6653 21.8239 15.2372 20.807 16.5916ZM11.723 7.50758C12.3595 7.46824 12.9971 7.56464 13.5936 7.7904C14.19 8.01615 14.7316 8.36609 15.1826 8.81703C15.6335 9.26797 15.9835 9.8096 16.2092 10.406C16.435 11.0025 16.5314 11.6401 16.492 12.2766L11.722 7.50758H11.723Z" />
</svg>;

export const HidePassIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M11.9999 3C17.3919 3 21.8779 6.88 22.8189 12C21.8789 17.12 17.3919 21 11.9999 21C6.60791 21 2.12191 17.12 1.18091 12C2.12091 6.88 6.60791 3 11.9999 3ZM11.9999 19C14.0394 18.9996 16.0183 18.3068 17.6128 17.0352C19.2072 15.7635 20.3228 13.9883 20.7769 12C20.3211 10.0133 19.2048 8.24 17.6105 6.97003C16.0162 5.70005 14.0382 5.00853 11.9999 5.00853C9.96161 5.00853 7.9836 5.70005 6.38928 6.97003C4.79497 8.24 3.67868 10.0133 3.22291 12C3.677 13.9883 4.79258 15.7635 6.38705 17.0352C7.98152 18.3068 9.96044 18.9996 11.9999 19ZM11.9999 16.5C10.8064 16.5 9.66184 16.0259 8.81793 15.182C7.97401 14.3381 7.49991 13.1935 7.49991 12C7.49991 10.8065 7.97401 9.66193 8.81793 8.81802C9.66184 7.97411 10.8064 7.5 11.9999 7.5C13.1934 7.5 14.338 7.97411 15.1819 8.81802C16.0258 9.66193 16.4999 10.8065 16.4999 12C16.4999 13.1935 16.0258 14.3381 15.1819 15.182C14.338 16.0259 13.1934 16.5 11.9999 16.5ZM11.9999 14.5C12.6629 14.5 13.2988 14.2366 13.7677 13.7678C14.2365 13.2989 14.4999 12.663 14.4999 12C14.4999 11.337 14.2365 10.7011 13.7677 10.2322C13.2988 9.76339 12.6629 9.5 11.9999 9.5C11.3369 9.5 10.701 9.76339 10.2321 10.2322C9.7633 10.7011 9.49991 11.337 9.49991 12C9.49991 12.663 9.7633 13.2989 10.2321 13.7678C10.701 14.2366 11.3369 14.5 11.9999 14.5Z" />
</svg>;

export const SuccessIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8C1.3335 4.318 4.31816 1.33333 8.00016 1.33333C11.6822 1.33333 14.6668 4.318 14.6668 8C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41448 13.3335 8C13.3335 6.58551 12.7716 5.22895 11.7714 4.22876C10.7712 3.22856 9.41465 2.66666 8.00016 2.66666C6.58567 2.66666 5.22912 3.22856 4.22893 4.22876C3.22873 5.22895 2.66683 6.58551 2.66683 8C2.66683 9.41448 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3355 10.6667L4.50683 7.838L5.4495 6.89533L7.3355 8.78133L11.1062 5.00999L12.0495 5.95266L7.3355 10.6667Z" />
</svg>;

export const WarningIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.57756 1.99999L14.9282 13C14.9867 13.1013 15.0175 13.2163 15.0175 13.3333C15.0175 13.4503 14.9867 13.5653 14.9282 13.6666C14.8697 13.768 14.7856 13.8522 14.6842 13.9107C14.5829 13.9692 14.4679 14 14.3509 14H1.64956C1.53254 14 1.41758 13.9692 1.31623 13.9107C1.21489 13.8522 1.13073 13.768 1.07222 13.6666C1.01371 13.5653 0.982909 13.4503 0.98291 13.3333C0.982911 13.2163 1.01372 13.1013 1.07223 13L7.42289 1.99999C7.48141 1.89865 7.56557 1.8145 7.66691 1.75599C7.76825 1.69749 7.88321 1.66669 8.00023 1.66669C8.11725 1.66669 8.2322 1.69749 8.33355 1.75599C8.43489 1.8145 8.51905 1.89865 8.57756 1.99999ZM2.80423 12.6667H13.1962L8.00023 3.66666L2.80423 12.6667ZM7.33356 10.6667H8.66689V12H7.33356V10.6667ZM7.33356 5.99999H8.66689V9.33332H7.33356V5.99999Z" />
</svg>;

export const DownloadIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M8.66665 6.66667H12L7.99998 10.6667L3.99998 6.66667H7.33331V2H8.66665V6.66667ZM2.66665 12.6667H13.3333V8H14.6666V13.3333C14.6666 13.5101 14.5964 13.6797 14.4714 13.8047C14.3464 13.9298 14.1768 14 14 14H1.99998C1.82317 14 1.6536 13.9298 1.52858 13.8047C1.40355 13.6797 1.33331 13.5101 1.33331 13.3333V8H2.66665V12.6667Z" />
</svg>;

export const DeleteIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M11.3334 4.00065H14.6667V5.33398H13.3334V14.0007C13.3334 14.1775 13.2631 14.347 13.1381 14.4721C13.0131 14.5971 12.8435 14.6673 12.6667 14.6673H3.33337C3.15656 14.6673 2.98699 14.5971 2.86197 14.4721C2.73695 14.347 2.66671 14.1775 2.66671 14.0007V5.33398H1.33337V4.00065H4.66671V2.00065C4.66671 1.82384 4.73695 1.65427 4.86197 1.52925C4.98699 1.40422 5.15656 1.33398 5.33337 1.33398H10.6667C10.8435 1.33398 11.0131 1.40422 11.1381 1.52925C11.2631 1.65427 11.3334 1.82384 11.3334 2.00065V4.00065ZM12 5.33398H4.00004V13.334H12V5.33398ZM6.00004 7.33398H7.33337V11.334H6.00004V7.33398ZM8.66671 7.33398H10V11.334H8.66671V7.33398ZM6.00004 2.66732V4.00065H10V2.66732H6.00004Z" />
</svg>;

export const PlusIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M7.33337 7.33398V3.33398H8.66671V7.33398H12.6667V8.66732H8.66671V12.6673H7.33337V8.66732H3.33337V7.33398H7.33337Z" />
</svg>;

export const InboxArchiveIcon = (props) => <svg {...props} width={props.size} height={props.size} viewBox="0 0 24 24" fill="currentColor"
  className={props.className} xmlns="http://www.w3.org/2000/svg"
>
  <path d="M4 3H20L22 7V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V7.004L4 3ZM20 9H4V19H20V9ZM19.764 7L18.764 5H5.237L4.237 7H19.764ZM13 14H16L12 18L8 14H11V10H13V14Z" />
</svg>;

export const VideoMicIcon = (props) => <svg {...props} width={props.size} height={props.size}
  fill="none" stroke="currentColor">
  <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
  <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
  <line x1="12" y1="19" x2="12" y2="23"></line>
  <line x1="8" y1="23" x2="16" y2="23"></line>
</svg>;

export const VideoMicOffIcon = (props) => <svg {...props} width={props.size} height={props.size}
  fill="none" stroke="currentColor">
  <line x1="1" y1="1" x2="23" y2="23"></line>
  <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path>
  <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path>
  <line x1="12" y1="19" x2="12" y2="23"></line>
  <line x1="8" y1="23" x2="16" y2="23"></line>
</svg>;

export const VideoEndCallIcon = (props) => <svg {...props} width={props.size} height={props.size}
  viewBox="0 0 24 24" fill="currentColor">
  <g clipPath="url(#clip0_163_249956)">
    <path d="M14.6347 11.1227C12.9101 10.6492 11.0899 10.6492 9.36533 11.1227L9.60133 12.5373C9.63927 12.7648 9.59251 12.9983 9.46991 13.1936C9.3473 13.389 9.15735 13.5326 8.936 13.5973C7.47784 14.0249 6.12668 14.7563 4.97133 15.7433C4.7909 15.8973 4.55908 15.9775 4.32209 15.9682C4.0851 15.9588 3.86035 15.8604 3.69267 15.6927L0.717999 12.718C0.5529 12.553 0.454893 12.3326 0.442947 12.0994C0.431002 11.8663 0.505968 11.637 0.653333 11.456C0.97 11.066 1.308 10.692 1.66667 10.3333C7.37333 4.62667 16.6267 4.62667 22.3333 10.3333C22.692 10.692 23.03 11.066 23.3467 11.456C23.494 11.637 23.569 11.8663 23.5571 12.0994C23.5451 12.3326 23.4471 12.553 23.282 12.718L20.3073 15.6927C20.1396 15.8604 19.9149 15.9588 19.6779 15.9682C19.4409 15.9775 19.2091 15.8973 19.0287 15.7433C17.8733 14.7563 16.5222 14.0249 15.064 13.5973C14.8426 13.5326 14.6527 13.389 14.5301 13.1936C14.4075 12.9983 14.3607 12.7648 14.3987 12.5373L14.6347 11.1227V11.1227ZM16.754 9.87933L16.392 12.0507C17.5274 12.4671 18.5972 13.0443 19.5687 13.7647L21.3267 12.0067C21.22 11.892 21.1107 11.7787 20.9993 11.6673C16.0293 6.696 7.97067 6.696 3 11.6667C2.88867 11.778 2.77933 11.8913 2.67333 12.0067L4.43133 13.7647C5.40279 13.0443 6.47258 12.4671 7.608 12.0507L7.246 9.87933C7.75175 9.65663 8.2726 9.46994 8.80467 9.32067L8.86533 9.304C10.9171 8.74057 13.0829 8.74057 15.1347 9.304L15.1953 9.32067C15.7274 9.46994 16.2483 9.65663 16.754 9.87933V9.87933Z" />
  </g>
  <defs>
    <clipPath id="clip0_163_249956">
      <rect width="24" height="24" />
    </clipPath>
  </defs>
</svg>;

export const VideoCameraIcon = (props) => <svg {...props} width={props.size} height={props.size}
  viewBox="0 0 24 24" fill="currentColor">
  <path d="M18 7C18 5.897 17.103 5 16 5H4C2.897 5 2 5.897 2 7V17C2 18.103 2.897 19 4 19H16C17.103 19 18 18.103 18 17V13.667L22 17V7L18 10.333V7ZM16.002 17H4V7H16L16.001 11.999L16 12L16.001 12.001L16.002 17Z" />
</svg>;

export const VideoCameraOffIcon = (props) => <svg {...props} width={props.size} height={props.size}
  viewBox="0 0 24 24" fill="currentColor">
  <path d="M18 6.707C18 5.604 17.103 4.707 16 4.707H6.414L3.707 2L2.293 3.414L20.293 21.414L21.707 20L18 16.293V13.374L22 16.707V6.707L18 10.04V6.707ZM16 14.293L8.414 6.707H16V14.293ZM4 18.707H14.879L12.879 16.707H4V7.828L2.145 5.972C2.05026 6.20552 2.00105 6.455 2 6.707V16.707C2 17.81 2.897 18.707 4 18.707Z" />
</svg>;

export const EyeIcon = (props) => <svg {...props} width={props.size} height={props.size}
  viewBox="0 0 24 24" fill="currentColor">
  <g clipPath="url(#clip0_29_235638)">
    <path d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z" />
  </g>
  <defs>
    <clipPath id="clip0_29_235638">
      <rect width="24" height="24" />
    </clipPath>
  </defs>
</svg>;

export const MonitorIcon = (props) => <svg {...props} width={props.size} height={props.size}
  viewBox="0 0 24 24" fill="none" stroke="currentColor"
  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
  <line x1="8" y1="21" x2="16" y2="21"></line>
  <line x1="12" y1="17" x2="12" y2="21"></line>
</svg>;

