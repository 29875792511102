import { useEffect, useState } from "react";
import vars from "~/Styles/Vars";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= vars.mobileScreen);

  useEffect(() => {
    const onResize = () => setIsMobile(window.innerWidth <= vars.mobileScreen);

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return isMobile;
};
