import API, { AddOnCost, AddOnCostType, GroupPermission, Order } from "~/API";
import { FC, ReactNode, useMemo, useState } from "react";
import { LC, LocalizedMessageKey, ls } from "~/Locales";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { Button, Card, Input, Table } from "~/Components/UI";
import { useMutate, usePageTitle } from "~/Hooks";
import useSWR from "swr";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Toast from "~/Components/Toast";
import { UnsavedChangesDialog } from "~/Components/Nav/UnsavedChangesDialog";
import { trackEvent } from "~/Services/Tracking";
import { useHasPermissions } from "~/Reducers/User";

const AddOnCostsPage: FC = () => {
  usePageTitle("Add-Ons");

  const canEdit = useHasPermissions([
    GroupPermission.CAN_BE_ADMIN,
    GroupPermission.CAN_BE_LOGISTICS,
  ]);

  const costs = useSWR(
    ["api/addon/costs"],
    () =>
      API.getAddOnCosts({
        limit: 1000,
        orderBy: "DATE_CREATED",
        order: Order.ASC,
      }),
    { revalidateOnFocus: false },
  );

  const [costsEdited, setCostsEdited] = useState<{
    [id: number]: AddOnCost;
  }>({});

  const save = useMutate(async () => {
    const updatedCostsEdited = { ...costsEdited };
    const updatedCosts = Object.values(costsEdited);

    try {
      for (const cost of updatedCosts) {
        await API.updateAddOnCost({ id: cost.id }, cost);
        delete updatedCostsEdited[cost.id];
      }
      await costs.mutate();
      trackEvent("Add-On Costs Updated");
    } catch (e) {
      Toast.ApiError(e);
      throw e;
    } finally {
      setCostsEdited(updatedCostsEdited);
    }
  });

  const onCostChange = (updatedCost: AddOnCost) => {
    setCostsEdited((oldState) => ({
      ...oldState,
      [updatedCost.id]: updatedCost,
    }));
  };

  const data: AddOnCost[] = (costs.data || []).map((c) => {
    if (costsEdited[c.id] == undefined) {
      return c;
    } else {
      return costsEdited[c.id];
    }
  });

  const hasChanges = Object.keys(costsEdited).length > 0;

  if (costs.isLoading) return <LoadingPage />;

  return (
    <div className="overflow-auto">
      {hasChanges && <UnsavedChangesDialog onSave={save.mutate} />}

      <div className="flex-col gap-md">
        <CostsTable
          title={<LC id="title.imports" />}
          costs={data.filter((c) => c.type == AddOnCostType.IMPORTS)}
          {...{ onCostChange, costsEdited, canEdit }}
        />

        <CostsTable
          title={<LC id="title.f2f" />}
          costs={data.filter((c) => c.type == AddOnCostType.F2F)}
          {...{ onCostChange, costsEdited, canEdit }}
        />

        <CostsTable
          title={<LC id="title.export" />}
          costs={data.filter((c) => c.type == AddOnCostType.EXPORT)}
          {...{ onCostChange, costsEdited, canEdit }}
        />

        <CostsTable
          title={<LC id="title.export.manufacturing" />}
          costs={data.filter(
            (c) => c.type == AddOnCostType.EXPORT_MANUFACTURING,
          )}
          {...{ onCostChange, costsEdited, canEdit }}
        />
      </div>

      <BottomButtonsPanel>
        <Button
          onClicked={save.mutate}
          loading={save.loading}
          disabled={!hasChanges || !canEdit}
          label="title.save.changes"
        />
      </BottomButtonsPanel>
    </div>
  );
};

export default AddOnCostsPage;

const CostsTable: FC<{
  title: ReactNode;
  costs: AddOnCost[];
  onCostChange: (updatedCost: any) => void;
  costsEdited: { [id: number]: AddOnCost };
  canEdit: boolean;
}> = (props) => {
  const data = useMemo(() => props.costs, [props.costs]);
  const columns = useMemo(() => getColumns(props.canEdit), [props.canEdit]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      onFieldChange: props.onCostChange,
    } as any,
  });

  return (
    <Card>
      <div className="text-lg text-left ml-lg my-xs">{props.title}</div>
      <Table table={table} />
    </Card>
  );
};

const columnHelper = createColumnHelper<AddOnCost>();

const getColumns = (canEdit: boolean) => [
  columnHelper.accessor("category", {
    header: ls("title.add.on.category"),
    cellMemo: ({ value }) => (
      <LC id={`addon.category.${value}` as LocalizedMessageKey} />
    ),
    size: 300,
  }),
  columnHelper.accessor("price", {
    header: ls("title.accrual"),
    cell: ({ getValue, row, table }) => (
      <Input
        id="price"
        rightIcon={
          !!row.original.currency ? (
            <div className="px-sm color-text-3">
              {useFindCurrencySymbol(row.original.currency)}
            </div>
          ) : null
        }
        value={getValue().toString()}
        onChange={({ target }) =>
          (table.options.meta as any)?.onFieldChange({
            ...row.original,
            price: target.value,
          })
        }
        type="number"
        disabled={!row.original.active || !canEdit}
      />
    ),
    size: 150,
  }),
  columnHelper.accessor("per", {
    header: ls("title.per"),
    cellMemo: ({ value }) => <LC id={`addon.per.${value}`} />,
    size: 0,
  }),
  columnHelper.accessor("description", {
    header: ls("title.description"),
    size: 300,
  }),
];
