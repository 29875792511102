import { PERIODS, periodToInterval } from "./MetricsData";
import useSWRImmutable from "swr/immutable";
import API from "~/API";

export interface RFQToQuotesFilters {
    period: PERIODS;
    am?: number;
    company?: number;
}

export const UseRFQToQuotes = (filters: RFQToQuotesFilters) =>
    useSWRImmutable(
        ["api/metrics/rfqs", filters],
        async () => API.getRequestsToQuotesMetrics({
            ...periodToInterval(filters.period),
            am: filters.am || "" as any,
            company: filters.company || "" as any,
        }),
    );
