import { FC } from "react";
import { MyProductsTab } from "~/Pages/Products/MyProductsPage/MyProductsTab";
import {LocationView} from "~/API";

const LocationMyProductsTab: FC<{
  location: LocationView
}> = (props) => (
  <MyProductsTab type="location" companyId={props.location.companyId} locationId={props.location.id} />
);

export default LocationMyProductsTab;
