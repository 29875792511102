import { FC, useMemo } from "react";
import { LocalizedMessage } from "~/Locales";
import { useCompany } from "~/Reducers/Companies";
import {
  useLocations,
  useCompanyLocations,
} from "~/Reducers/Locations";
import { useUserPublic } from "~/Reducers/Users";
import { useCurrentUser } from "~/Reducers/User";
import { DashboardVideoButton } from "~/Pages/Dashboard/Components/DashboardButtons";
import {UserAvatarDirect} from "~/Components/Users/UserAvatar";
import {UserName} from "~/Components/Users/UserName";

const QuoteDetailsManagers: FC<{
  showChat: boolean;
  toggleShowChat: () => void;
}> = ({ showChat, toggleShowChat }) => {
  const currentUser = useCurrentUser();
  const company = useCompany(currentUser.companyId);
  const locations = useLocations();
  const companyLocationIds = useCompanyLocations(currentUser.companyId) || [];

  const filteredLocationIds = useMemo(
    () =>
      companyLocationIds.filter((locId) =>
        currentUser.locations?.includes(locId),
      ),
    [currentUser, companyLocationIds],
  );

  const managerIds = useMemo(() => {
    const ids = filteredLocationIds
      .map((locationId) => locations[locationId]?.accountManagerUserId)
      .filter((id) => !!id); // remove undefined/null values
    return [...new Set(ids)]; // remove duplicates
  }, [filteredLocationIds, locations]);

  if (
    !!company?.accountManagerUserId &&
    !managerIds.includes(company?.accountManagerUserId)
  ) {
    managerIds.push(company.accountManagerUserId);
  }
  if (!managerIds.length) return null;

  return (
    <>
      {managerIds.map((managerId, index) => (
        <Manager key={managerId} {...{ managerId, showChat, toggleShowChat }} />
      ))}
    </>
  );
};

export default QuoteDetailsManagers;

const Manager: FC<{ managerId: number }> = ({ managerId }) => {
  const companyManager = useUserPublic(managerId) || undefined;
  const name = companyManager?.name;
  const companyManagerId = companyManager?.id;

  return (
    <div
      className={
        "bg-black-12 flex-row flex-wrap align-start color-text-2 rounded-xxs px-lg py-sm mb-lg"
      }
    >
      <div className={"flex-row flex flex-wrap"}>
        <div className={"flex-row align-center flex"}>
          <UserAvatarDirect user={companyManager} />
          {!!name && (
            <div className={"text-left ml-md"}>
              <div className={"flex-row align-center"}>
                <div className={"flex-row align-center"}>
                  <div className="text-lg">
                    <UserName id={managerId} />
                  </div>
                  &nbsp;
                  <span className="text-md color-text-2">
                    (<LocalizedMessage id={"title.account.manager"} />)
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={"flex-row align-center"}>
          <div className="mr-lg" />
          <DashboardVideoButton userId={companyManagerId} />
          {/*{!!quoteId && <QuoteChatButton {...{ quoteId, showChat, toggleShowChat }} />}*/}
        </div>
      </div>
    </div>
  );
};
