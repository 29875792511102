import { Fragment, useMemo, useState } from "react";
import AddUserGroup from "./Components/AddUserGroup";
import {UserGroupPermissionsView} from "~/API";
import {useDebounce, useToggle} from "~/Hooks";
import { ActiveField, FullPageCard, Input, Table } from "~/Components/UI";
import { LinearLoader } from "~/Components/Loaders";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  useUserGroups,
} from "~/Data/Admin/UserGroups";
import { continuousIncludes } from "~/Utils";
import UserGroupCard from "~/Pages/Admin/UserGroups/Components/UserGroupCard";
import {StaticPage} from "~/Components/Layout/Pages";
import {PageTitle} from "~/Components/Nav/PageTitle";

const columnHelper = createColumnHelper<UserGroupPermissionsView>();

const columns = [
  columnHelper.accessor("active", {
    header: "",
    cell: (data) => <ActiveField active={data.getValue()} />,
  }),
  columnHelper.accessor("name", {
    cell: (data) => data.getValue(),
  }),
  columnHelper.accessor("permissions", {
    cell: (data) => {
      return (
        <>
          {data.getValue().slice(0, 3).map((id, index) => (
            <Fragment key={id}>
              {id}
              {index < data.getValue().length - 1 ? ", " : ""}
            </Fragment>
          ))}
          {data.getValue().length > 3 &&
            `and ${data.getValue().length - 3} more`}
        </>
      );
    },
  }),
];

const UserGroupsPage = () => {
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search);

  const onSearchChange = ({ target }) => setSearch(target.value);

  const userGroups = useUserGroups();

  const userGroupsFiltered = useMemo(
    () =>
      userGroups.data?.filter((it) =>
        continuousIncludes(
          it.name.toUpperCase(),
          searchDebounce.toUpperCase?.(),
        ),
      ) || [],
    [searchDebounce, userGroups.data],
  );

  const table = useReactTable({
    data: userGroupsFiltered,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [openId, setOpenId] = useState<number | undefined>();
  const cardOpen = useToggle();
  const onRowClicked = (_row: UserGroupPermissionsView, index: number) => {
    cardOpen.toggleTrue();
    setOpenId(index);
  };

  return (
    <StaticPage title="User Groups">
      <PageTitle title="title.user.groups" type="menu" />

      <FullPageCard>
        <div className="flex-row p-md gap-sm">
          <AddUserGroup />
          <Input
            id="search"
            type="search"
            showClear
            value={search}
            onChange={onSearchChange}
            data-cy="SearchField"
            placeholder="placeholder.search"
            className="desktop-w-8xl"
          />
        </div>

        <LinearLoader loading={userGroups.isLoading} />
        <Table table={table} onRowClick={onRowClicked} />
        { openId !== undefined &&
          <UserGroupCard userGroup={userGroupsFiltered[openId]} open={cardOpen} />
        }
      </FullPageCard>
    </StaticPage>
  );
};

export default UserGroupsPage;
