import { FC, Ref, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestedOffersRootPath } from "~/Services/Routes";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { useIncotermUsesAddress } from "~/Reducers/Incoterms";
import {
  isOfferExpired,
  useOfferProductCodes,
  useOfferValue,
} from "~/Reducers/Offers";
import { useIsInternalUser } from "~/Reducers/User";
import { useRequestedOffersPageReduxState } from "../../Reducer";
import { useIsDraft } from "../RequestedOffersHelper";
import { IncotermName } from "~/Components/Old/IncotermSelect";
import LinearProgress from "~/Components/Old/LinearProgress";
import {
  OfferShowUnread,
  OfferStatusLabel,
} from "~/Pages/Offers/Components/OffersHelper";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import RFOArchiveDeleteButtons from "../RFOArchiveDeleteButtons";
import OfferReference from "~/Pages/Offers/Components/OfferReference";
import { DateTimeView } from "~/Components/Views/DateView";
import { useIsMobile } from "~/Hooks/useIsMobile";
import { OfferView } from "~/API";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

const RequestedOffersPageRequestsTable: FC<{
  scrollRef: Ref<any>;
  onScrolledToBottom: () => void;
}> = ({ scrollRef, onScrolledToBottom }) => {
  const isInternalUser = useIsInternalUser();
  const { offers, loading } = useRequestedOffersPageReduxState();
  const isMobile = useIsMobile();

  return (
    <ScrollableForPagination
      estimatedElementHeight={50}
      numberOfElementsBeforeLoading={10}
      ref={scrollRef}
      className={"flex bg-black-12 overflow-auto"}
      onScrolledToBottom={onScrolledToBottom}
    >
      {loading && (
        <div className={"progress-bar"}>
          <LinearProgress />
        </div>
      )}

      {!isMobile && (
        <table className={"basic-table"}>
          <thead>
            <tr>
              <th>
                <LocalizedMessage id={"title.ro.id"} />
              </th>
              <th>
                <LocalizedMessage id={"title.status"} />
              </th>
              {isInternalUser && (
                <th>
                  <LocalizedMessage id={"title.ship.to"} />
                </th>
              )}
              <th>
                <LocalizedMessage
                  id={!isInternalUser ? "title.buyer" : "title.vendor"}
                />
              </th>
              {!isInternalUser && (
                <th>
                  <LocalizedMessage id={"title.incoterm.destination"} />
                </th>
              )}
              {isInternalUser && (
                <th>
                  <LocalizedMessage id={"title.buyer"} />
                </th>
              )}
              <th>
                <LocalizedMessage id={"title.products"} />
              </th>
              <th>
                <LocalizedMessage id={"title.product.code"} />
              </th>
              {isInternalUser && (
                <th>
                  <LocalizedMessage id={"title.reference"} />
                </th>
              )}
              <th className={"text-right"}>
                <LocalizedMessage id={"title.value"} />
              </th>
              <th>
                <LocalizedMessage id={"title.validity.date"} />
              </th>
              <th>
                <LocalizedMessage id={"title.actions"} />
              </th>
              <th />
            </tr>
          </thead>
          <tbody data-cy="requests-list">
            {offers.map((offer) => (
              <OfferRow key={offer.id} {...{ offer }} />
            ))}
          </tbody>
        </table>
      )}
      {isMobile &&
        offers.map((offer) => <OfferRowMobile key={offer.id} offer={offer} />)}
    </ScrollableForPagination>
  );
};

export default RequestedOffersPageRequestsTable;

const OfferRow: FC<{
  offer: OfferView;
}> = ({ offer }) => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const value = useOfferValue(offer);
  const productCodes = useOfferProductCodes(offer);
  const {
    status,
    vendorCompanyName,
    currency,
    products,
    requestForQuoteId,
    quotes,
    purchaseOrders,
    incoterm,
    incotermAddressDestination,
    incotermPortIdDestination,
    incotermPortName,
    buyerName,
    validityDate,
    unreadChatMessages,
    shipToName,
    accountManagerName,
  } = offer;

  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = useIsDraft(status);

  const onOpen = useCallback(
    () => history.push(`${requestedOffersRootPath}${offer.id}`),
    [history, offer],
  );

  const canShowAddressField = useIncotermUsesAddress(incoterm);

  const isExpired = isOfferExpired(validityDate);

  return (
    <tr
      onClick={onOpen}
      className={OfferShowUnread(offer.readMark, isExpired) ? "row-unread" : ""}
    >
      <td className={"no-wrap"}>{`RO-${offer.id}`}</td>
      <td>
        {isExpired ? (
          "Expired"
        ) : (
          <OfferStatusLabel offerStatus={status} offerRead={offer.readMark} />
        )}
      </td>
      {isInternalUser && <td>{shipToName}</td>}
      {isInternalUser && <td>{vendorCompanyName}</td>}
      {!isInternalUser && <td>{buyerName}</td>}
      {!isInternalUser && (
        <td>
          <div className={"flex-row align-center"}>
            <IncotermName incoterm={incoterm} />
            {canShowAddressField ? (
              incotermAddressDestination ? (
                `/${incotermAddressDestination}`
              ) : (
                ""
              )
            ) : incotermPortIdDestination ? (
              <>/{incotermPortName}</>
            ) : (
              ""
            )}
          </div>
        </td>
      )}
      {isInternalUser && <td>{accountManagerName}</td>}
      <td>{`${products?.length || 0}`}</td>
      <td>{productCodes}</td>
      {isInternalUser && (
        <td>
          <OfferReference
            {...{ requestForQuoteId, purchaseOrders, quotes }}
            simple
          />
        </td>
      )}
      <td className={"text-right no-wrap color-orange-1"}>
        {`${currencySymbol} ${formattedNumber(value) || "0"}`}
      </td>
      <td>
        <DateTimeView date={validityDate} />
      </td>
      <td>
        <div className={"flex-row align-center justify-center"}>
          <RFOArchiveDeleteButtons {...{ offer }} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}</td>
    </tr>
  );
};

const OfferRowMobile: FC<{
  offer: OfferView;
}> = ({ offer }) => {
  const isExpired = isOfferExpired(offer.validityDate);
  const value = useOfferValue(offer);
  const history = useHistory();
  const currencySymbol = useFindCurrencySymbol(offer.currency);
  const isInternalUser = useIsInternalUser();
  const canShowAddressField = useIncotermUsesAddress(offer.incoterm);

  const openRQ = () => history.push(`${requestedOffersRootPath}${offer.id}`);

  return (
    <div className="p-md flex-col br-bottom-black-10" onClick={openRQ}>
      <div className="flex flex-row justify-between align-center">
        <div>Request {offer.id}</div>

        <div className="flex-row gap-sm">
          <div className="bg-blue-10 rounded-xxs px-xs py-xxs">
            {isExpired ? (
              "Expired"
            ) : (
              <OfferStatusLabel
                offerStatus={offer.status}
                offerRead={offer.readMark}
                offerIsExpired={isExpired}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between align-start gap-lg mt-lg">
        {isInternalUser && (
          <>
            <div className={"flex-col flex text-left"}>
              <div className={"text-sm color-text-3"}>
                <LocalizedMessage id={"title.ship.to.name"} />
              </div>
              <div className={"text-pre-wrap mt-xxs"}>
                {offer.shipToName || "-"}
              </div>
            </div>

            <div className={"flex-col flex text-left"}>
              <div className={"text-sm color-text-3"}>
                <LocalizedMessage id={"title.vendor.name"} />
              </div>
              <div className={"text-pre-wrap mt-xxs"}>
                {offer.vendorCompanyName || "-"}
              </div>
            </div>
          </>
        )}

        {!isInternalUser && (
          <>
            <div className={"flex-col flex text-left"}>
              <div className={"text-sm color-text-3"}>
                <LocalizedMessage id={"title.buyer"} />
              </div>
              <div className={"text-pre-wrap mt-xxs"}>
                {offer.buyerName || "-"}
              </div>
            </div>

            <div className={"flex-col flex text-left"}>
              <div className={"text-sm color-text-3"}>
                <LocalizedMessage id={"title.incoterm.destination"} />
              </div>
              <div className={"text-pre-wrap mt-xxs"}>
                <IncotermName incoterm={offer.incoterm} />
                {canShowAddressField ? (
                  offer.incotermAddressDestination ? (
                    `/${offer.incotermAddressDestination}`
                  ) : (
                    ""
                  )
                ) : offer.incotermPortIdDestination ? (
                  <>/{offer.incotermPortName}</>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={"flex flex-row justify-between align-center gap-lg mt-lg"}
      >
        <div className={"text-pre-wrap mt-xxs color-orange-1"}>
          {`${currencySymbol} ${formattedNumber(value) || "0"}`}
        </div>
        <RFOArchiveDeleteButtons {...{ offer }} />
      </div>
    </div>
  );
};
