import {FC, useEffect} from "react";
import {useParams} from "react-router-dom";
import {ChatView} from "~/Components/Chats/ChatView";
import {markChatNotificationsRead} from "~/Data/Notifications";

export const ChatLayout: FC<{ internal: boolean }> = (props) => {
  const params = useParams<{ chatId: string }>();
  const chatId = Number(params.chatId);

  useEffect(() => {
    markChatNotificationsRead(chatId);
  }, []);

  return (
    <ChatView chatId={chatId} internal={props.internal} />
  );
}