import { OfferStatus } from "~/API";
import { useMemo } from "react";
import SelectWithPaging from "./SelectWithPaging";
import useFetchMissingItems from "./useFetchMissingItems";
import { getOffer, getOffers, useOffers, useOfferAsReferenceLabels } from "~/Reducers/Offers";


const OfferSelect = (props) => {
  const { showForQuoteReference = false, showForRequestReference = false,
    includeValue = null, referenceParentId = null, ...componentProps } = props;
  const offers = useOffers();

  useFetchMissingItems(props.value, offers, getOffer);

  const filter = useMemo(() => {
    const filterToReturn = { query: '' };
    if (showForQuoteReference || showForRequestReference) {
      filterToReturn.query = '((';
      Object.values(OfferStatus)
        .filter(it => it !== OfferStatus.O_DRAFT
          && it !== OfferStatus.O_DRAFT_VENDOR
          && it !== OfferStatus.O_ARCHIVED
          && it !== OfferStatus.RFO_DRAFT
          && it !== OfferStatus.RFO_DRAFT_VENDOR
          && it !== OfferStatus.RFO_ARCHIVED
        ).forEach(it => {
          if (filterToReturn.query.length > 2) {
            filterToReturn.query = filterToReturn.query + ' || ';
          }
          filterToReturn.query = filterToReturn.query + `STATUS == '${it}'`;
        });
      filterToReturn.query = filterToReturn.query + ')';

      if (showForQuoteReference) {
        filterToReturn.query = filterToReturn.query + ')';
        if (includeValue) {
          filterToReturn.query = filterToReturn.query
          + ` || ID == ${includeValue}`;
        }

      } else if (showForRequestReference) {
        filterToReturn.query = filterToReturn.query
          + ` && REQUEST_FOR_QUOTE_ID == null)`;
        if (referenceParentId) {
          filterToReturn.query = filterToReturn.query + ` || REQUEST_FOR_QUOTE_ID == ${referenceParentId}`;
        }
      }

      else {
        filterToReturn.query = filterToReturn.query + ')';
      }
    }

    return filterToReturn;
  }, [showForQuoteReference, showForRequestReference, includeValue, referenceParentId]);

  return <SelectWithPaging {...componentProps}
    getData={getOffers}
    useDataLabels={useOfferAsReferenceLabels}
    filter={{ orderBy: 'DATE_CREATED', ...filter, ...props.filter }}
  />;
};

export default OfferSelect;
