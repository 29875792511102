import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { cn } from "~/Utils";
import { TbUpload } from "react-icons/tb";
import Toast from "~/Components/Toast";
import {useMutate} from "~/Hooks";
import {LinearLoader} from "~/Components/Loaders";

const preventDefault = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

export const DragAndDropUpload: FC<{
  onFilesUploaded: (files: File[]) => Promise<any>;
  acceptedFileTypes: string[];
  className?: string;
  multiple?: boolean;
}> = (props) => {
  const [dragging, setDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);
  const uploadMutate = useMutate(props.onFilesUploaded);

  // nested elements in our drag component will cause flickering as those will fire the drag events as well.
  // So keep track of how many levels deep it is, and only set dragging to false when it leaves the outermost layer.
  // it's a ref to allow the value to be read in the same func it's getting set without requiring a re-rendering first.
  const dragCount = useRef(0);

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files);
    uploadMutate.mutate(files);
  };

  const handleDragIn = (e: DragEvent) => {
    preventDefault(e);
    dragCount.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    preventDefault(e);
    dragCount.current--;
    if (dragCount.current == 0) setDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    preventDefault(e);
    setDragging(false);
    dragCount.current = 0;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files: File[] = Array.from(e.dataTransfer.files);
      const filesAllowed = files.filter((f: File) => props.acceptedFileTypes.some((a) => f.name.endsWith(a)));
      const filesDisallowed = files.filter((f) => !filesAllowed.includes(f));

      if (filesDisallowed.length > 0) {
        Toast.Error(
          `Invalid file types on: ${filesDisallowed.map((f) => f.name).join(", ")}`
        );
      }

      if(filesAllowed.length > 0) {
        uploadMutate.mutate(filesAllowed);
      }
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    const h = dropRef.current;
    h.addEventListener("dragenter", handleDragIn);
    h.addEventListener("dragleave", handleDragOut);
    h.addEventListener("dragover", preventDefault);
    h.addEventListener("drop", handleDrop);

    return () => {
      h.removeEventListener("dragenter", handleDragIn);
      h.removeEventListener("dragleave", handleDragOut);
      h.removeEventListener("dragover", preventDefault);
      h.removeEventListener("drop", handleDrop);
    };
  }, [dropRef.current, handleDragIn, handleDragOut, preventDefault, handleDrop]);

  return (
    <div
      ref={dropRef}
      className={cn(
        props.className,
        "flex flex-row w-full rounded-xxs overflow-hidden border-dashed-2",
        !dragging && !uploadMutate.loading && "border-black-9 hover-bg-black-11-5 color-black-6",
        dragging && !uploadMutate.loading && "border-blue-1 bg-blue-9 color-blue-1",
        uploadMutate.loading && "border-black-10 color-black-9",
      )}
    >
      <label
        htmlFor="dropzone-file"
        className="px-lg py-md flex-row flex w-full align-center justify-center cursor-pointer"
      >
        <div className="flex-col gap-sm justify-center align-center">
          <LinearLoader loading={uploadMutate.loading} />
          <TbUpload size={22} />
          <span>
            <strong>Click to Upload</strong> or <strong>Drag and Drop</strong>
          </span>
          <span>
            Allowed File Type(s): {props.acceptedFileTypes.map((a) => a.replace(".", "").toUpperCase()).join(", ")}
          </span>
        </div>
        <input
          id="dropzone-file"
          type="file"
          accept={props.acceptedFileTypes.join(", ")}
          onChange={handleFileSelected}
          multiple={props.multiple}
          className="hide"
          disabled={uploadMutate.loading}
        />
      </label>
    </div>
  );
};
