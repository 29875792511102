import { useCallback } from 'react';
import { LocalizedMessage, localizedString } from '~/Locales';
import { useSearchParams } from '~/Services/Routes';
import { OffersPageTableTypes } from '../Reducer';
import { FilterIcon, CloseIcon } from '~/Components/Old/Icons';
import Button from "~/Components/Old/Button";
import DatePicker from '~/Components/Old/DatePicker';
import IconButton from "~/Components/Old/IconButton";
import OfferStatusSelect from '~/Components/Old/OfferStatusSelect';
import PortSelect from '~/Components/Old/PortSelect';
import VendorSelect from '~/Components/Old/VendorSelect';
import { useIsInternalUser } from "~/Reducers/User";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import TextField from '~/Components/Old/TextField';
import UserSelectPublic from "~/Components/Old/UserSelectPublic";
import ProductSelectPublic from "~/Components/Old/ProductSelectPublic";
import ProductGroupsSelectPublic from "~/Components/Old/ProductGroupsSelectPublic";
import { useIsMobile } from "~/Hooks/useIsMobile";
import Switch from "~/Components/Old/Switch";


const MOBILE_CLEAR_BUTTON_ID = 'mobileClearButton';

const Filters = ({ showHideFilters, filters, setFilter, pageFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearParameters = useCallback(({ target }) => {
    setSearchParams({
      ...pageFilters,
      tableType: searchParams.tableType || OffersPageTableTypes.OFFERS_PAGE_NONE,
      onlyMy: filters.onlyMy
    });
    (target.id === MOBILE_CLEAR_BUTTON_ID) && showHideFilters?.();
  }, [searchParams.tableType, setSearchParams, pageFilters, filters.onlyMy, showHideFilters]);

  const { status, buyer, product, productGroup, port, vendor, incoterm,
    shipFrom, valueFrom, valueTo, dateIncoDestinationFrom, dateIncoDestinationTo,
    validityDateFrom, validityDateTo } = filters;

  const isInternalUser = useIsInternalUser();
  const isMobile = useIsMobile();

  return (
    <div className={'FiltersRightPanelMainContainer'}>
      <div className={'FiltersRowContainer FiltersTitleContainer mb-md'}>
        <div className={'FiltersRowContainer'}>
          <FilterIcon className={'TitleIcon'} size={16} />
          <LocalizedMessage id={'title.filter'} />
        </div>
        <IconButton onClick={showHideFilters}>
          <CloseIcon size={20} />
        </IconButton>
      </div>

        { isMobile &&
            <>
                <div className={'mt-md flex-row align-center'}>
                    <Switch
                        id={'onlyArchivedOrDeliveredOrExpired'}
                        value={filters.onlyArchivedOrDeliveredOrExpired}
                        onChange={setFilter}
                    />
                    <div className={'ml-xs'}><LocalizedMessage id={'title.inactive'} /></div>
                </div>

                <div className={'my-md flex-row align-center'}>
                    <Switch
                        id={'onlyMy'}
                        value={filters.onlyMy}
                        onChange={setFilter}
                    />
                    <div className={'ml-xs'}>My offers</div>
                </div>
            </>
        }

      <OfferStatusSelect id={'status'} label={localizedString('title.status')}
        value={status}
        onChange={setFilter}
        requestedOnly={false}
        multiple
        showAll
      />

      <IncotermSelect id={'incoterm'} label={'Incoterm'}
        className={'mt-xs'}
        value={incoterm}
        onChange={setFilter}
        multiple
        showAll
      />

      <PortSelect id={'port'} label={localizedString('title.port.of.destination')}
        className={'mt-xs'}
        value={port}
        onChange={setFilter}
        multiple
        showAll
      />

      {isInternalUser && <VendorSelect id={'vendor'} label={localizedString('title.vendor')}
        className={'mt-xs'}
        value={vendor}
        onChange={setFilter}
        multiple
        showAll
      />}

      <UserSelectPublic id={'buyer'} label={localizedString('title.buyer')}
        className={'mt-xs'}
        value={buyer}
        onChange={setFilter}
        multiple
        showAll
      />

      <ProductSelectPublic id={'product'} label={localizedString('title.product')}
        className={'mt-xs'}
        value={product}
        onChange={setFilter}
        multiple
        showAll
      />

      <ProductGroupsSelectPublic id={'productGroup'} label={localizedString('title.product.group')}
        className={'mt-xs'}
        value={productGroup}
        onChange={setFilter}
        multiple
        showAll
      />

      {isInternalUser &&
        <>
          <PortSelect id={'shipFrom'} label={localizedString('title.ship.from')}
            className={'mt-xs'}
            value={shipFrom}
            onChange={setFilter}
            multiple
            showAll
          />

          <div className={'text-sm color-text-2 text-left mt-xs'}><LocalizedMessage id={'title.value'} /></div>
          <div className={'flex-row align-center mt-xxs'}>
            <TextField id={'valueFrom'} type={'number'} hideArrows
              value={valueFrom}
              onChange={setFilter}
              max={999999999}
            />
            &nbsp;{'-'}&nbsp;
            <TextField id={'valueTo'} type={'number'} hideArrows
              value={valueTo}
              onChange={setFilter}
              max={999999999}
            />
          </div>

          <div className={'text-sm color-text-2 text-left mt-xs'}><LocalizedMessage id={'title.date.inco.destination'} /></div>
          <div className={'flex-row align-center mt-xxs'}>
            <DatePicker id={'dateIncoDestinationFrom'} date={dateIncoDestinationFrom} onChange={setFilter} />
            &nbsp;{'-'}&nbsp;
            <DatePicker id={'dateIncoDestinationTo'} date={dateIncoDestinationTo} onChange={setFilter} endOfDay />
          </div>

          <div className={'text-sm color-text-2 text-left mt-xs'}><LocalizedMessage id={'title.validity.date'} /></div>
          <div className={'flex-row align-center mt-xxs'}>
            <DatePicker id={'validityDateFrom'} date={validityDateFrom} onChange={setFilter} />
            &nbsp;{'-'}&nbsp;
            <DatePicker id={'validityDateTo'} date={validityDateTo} onChange={setFilter} endOfDay />
          </div>
        </>
      }

      <div className={'ButtonsContainer mt-sm'}>
        <Button view={'text'} onClick={clearParameters}>
          <LocalizedMessage id={'title.clear'} />
        </Button>
      </div>

      <div className={'ButtonsContainerMobile mt-sm'}>
        <Button className={'ApplyButton'} onClick={showHideFilters}>
          <LocalizedMessage id={'title.apply'} />
        </Button>
        <Button id={MOBILE_CLEAR_BUTTON_ID} view={'text'} onClick={clearParameters}>
          <LocalizedMessage id={'title.clear'} />
        </Button>
      </div>
    </div>
  );
};

export default Filters;
