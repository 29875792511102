import { FC, ReactElement, ReactNode, useMemo } from "react";

export const Match: FC<{ on: any; children: ReactElement<CaseProps>[] }> = (
  props,
) => {
  const child = useMemo(
    () =>
      props.children.find((child: any) => child.props.v === props.on) ||
      props.children.find((child: any) => child.props.default),
    [props.on, props.children],
  );

  return <>{child}</>;
};

export interface CaseProps {
  v?: any;
  default?: boolean;
  children: ReactNode;
}

export const Case: FC<CaseProps> = (props) => {
  return <>{props.children}</>;
};

export const Show: FC<{ when: any; fallback?: ReactNode; children: ReactNode }> = ({
  when,
  fallback,
  children,
}) => {
  return <>{when ? children : fallback}</>;
};

export const For = <T extends any>(props: {
  each: T[] | undefined;
  fallback?: ReactNode;
  children: (val: T, index?: number) => ReactElement;
}) => {
  if (!props.each) return <>{props.fallback}</>;
  return <>{props.each.map((val, i) => props.children(val, i))}</>;
};
