import API, { Attachment, AttachmentStatus } from '~/API';
import { useGetEntity_deprecated } from "./Utils/Entity";
import {AppDispatch, RootState} from "~/StoreTypes";

export const ACTIONS_ATTACHMENT_UPDATED = 'ACTIONS_ATTACHMENT_UPDATED';
export const ACTIONS_GET_PRODUCT_ATTACHMENTS_SUCCESS = 'ACTIONS_GET_PRODUCT_ATTACHMENTS_SUCCESS';

// hooks
const attachmentSelectorCreator = (attachmentId: number) => (state: RootState) => state.attachments[attachmentId];

export const useAttachment = (attachmentId: number, force = false) => useGetEntity_deprecated(attachmentId, getAttachment, attachmentSelectorCreator, force);

// action creators
const ACTIONS_GET_ATTACHMENT_SUCCESS = 'ACTIONS_GET_ATTACHMENT_SUCCESS';

const getAttachment = (id: number, onSuccess?: () => void, onError?: () => void) => (dispatch: AppDispatch) => {
  API.getAttachment({ id })
    .then((data) => {
      if (data) {
        dispatch({ type: ACTIONS_GET_ATTACHMENT_SUCCESS, data });
        onSuccess?.();
      }
    })
    .catch(() => {
      onError?.();
    });
};

export const addUploadedAttachmentsToReduxState = (attachments: AttachmentsState) =>
  ({ type: 'ACTIONS_ADD_UPLOADED_ATTACHMENTS', attachments });

export interface AttachmentsState {
  [id: number]: Attachment;
}
// reducer
const INITIAL_STATE: AttachmentsState = {};

const reducer = (state: AttachmentsState, action: any): AttachmentsState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_ATTACHMENT_SUCCESS:
      return { ...state, [action.data.id]: action.data };

    case ACTIONS_ATTACHMENT_UPDATED:
      return { ...state, [action.data.id]: action.data };

    case ACTIONS_GET_PRODUCT_ATTACHMENTS_SUCCESS:
    case 'ACTIONS_ADD_UPLOADED_ATTACHMENTS':
      return mergedUploadedAttachments(state, action.attachments);

    default:
      return state;
  }
};

export default reducer;

const mergedUploadedAttachments = (state: AttachmentsState, newAttachments = []) => {
  const attachments = { ...state };
  newAttachments.forEach(attachment => {
    if (attachments[attachment.id]?.status !== AttachmentStatus.READY) {
      attachments[attachment.id] = attachment;
    }
  });
  return attachments;
};
