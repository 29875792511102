import { OfferStatus, OfferUpdate, OfferView } from "~/API";
import { FC, useCallback } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import {
  archiveOffer,
  deleteOfferEntity,
  useShouldShowArchiveButton,
} from "../Reducer";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import DeleteDialog from "~/Components/Old/DeleteDialog";
import { DeleteIcon, InboxArchiveIcon } from "~/Components/Old/Icons";
import {useAppDispatch} from "~/StoreTypes";
import {trackEvent} from "~/Services/Tracking";

const OfferArchiveDeleteButtons: FC<{
  offer: OfferView;
  groupId?: number | null;
}> = ({ offer, groupId = null }) => {
  const dispatch = useAppDispatch();
  const { status } = offer;
  const isDraft = status === OfferStatus.O_DRAFT;

  const [showDeleteConfirmation, toggleDeleteDialog] = useToggle();
  const [showArchiveConfirmation, toggleArchiveDialog] = useToggle();

  const onConfirmDelete = useCallback(() => {
    dispatch(deleteOfferEntity(offer.id, groupId, toggleDeleteDialog));
    trackEvent("Offer Deleted");
  }, [offer.id, groupId, toggleDeleteDialog, dispatch]);

  const onConfirmArchive = useCallback(() => {
    dispatch(
      archiveOffer(
        { ...offer, status: OfferStatus.O_ARCHIVED } as {
          id: number;
        } & OfferUpdate,
        groupId,
        toggleArchiveDialog,
      ),
    );
    trackEvent("Offer Archived");
  }, [groupId, toggleArchiveDialog, offer, dispatch]);

  const shouldShowArchiveButton = useShouldShowArchiveButton(status);

  return (
    <>
      {isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton DeleteButtonText"}
          onClick={(e) => {
            e.stopPropagation();
            toggleDeleteDialog();
          }}
        >
          <DeleteIcon size={20} />
        </Button>
      )}

      {!isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton ArchiveButton"}
          onClick={(e) => {
            e.stopPropagation();
            toggleArchiveDialog();
          }}
        >
          <InboxArchiveIcon size={20} />
        </Button>
      )}

      {showDeleteConfirmation && (
        <DeleteDialog
          title={"Delete Offer"}
          description={`Are you sure you want to delete offer ${offer.id}?`}
          description2={
            <LocalizedMessage id={"phrase.this.actions.can.not.be.undone"} />
          }
          confirmTitle={<LocalizedMessage id={"title.delete"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onDelete={onConfirmDelete}
          onClose={toggleDeleteDialog}
        />
      )}

      {showArchiveConfirmation && (
        <ConfirmationDialog
          title={"Archive Offer"}
          description={`Are you sure you want to archive offer ${offer.id}?`}
          description2={""}
          confirmTitle={<LocalizedMessage id={"title.archive"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onConfirm={onConfirmArchive}
          onClose={toggleArchiveDialog}
        />
      )}
    </>
  );
};

export default OfferArchiveDeleteButtons;
