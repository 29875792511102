import { FC, useEffect, useState } from "react";
import { useLeftMenu, closeLeftMenu } from "~/Reducers/LeftMenu";
import { useIsAdmin, useIsInternalUser } from "~/Reducers/User";
import { useIsMobile } from "~/Hooks";
import { ArrowLeftIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import {
  AdminSection,
  BottomSection,
  ExternalUserMyViewSection,
  LeftMenuButton,
  UFPMyViewSection,
} from "./LeftMenuSections";
import { Drawer } from "vaul";
import { cn } from "~/Utils";
import { useAppDispatch } from "~/StoreTypes";

const LeftMenu: FC = () => {
  const dispatch = useAppDispatch();
  const [minimized, setMinimized] = useState(false);

  const open = useLeftMenu();

  const onCloseMenu = () => dispatch(closeLeftMenu());
  const minimizeMaximize = () => setMinimized(!minimized);

  const mobileUser = useIsMobile();

  // expand when switching from desktop to mobile to prevent rendering issue
  useEffect(() => {
    if (mobileUser) setMinimized(false);
  }, [mobileUser]);

  return (
    <>
      {!mobileUser && (
        <nav
          className={cn(
            "h-full flex-col bg-black-12 overflow-y-auto",
            minimized ? "w-3xl" : "w-7xl",
          )}
        >
          <LeftMenuSections {...{ minimized, minimizeMaximize }} />
        </nav>
      )}
      {mobileUser && (
        <Drawer.Root open={open} onOpenChange={onCloseMenu} direction="left">
          <Drawer.Portal>
            <Drawer.Overlay className="fixed inset-0 bg-opacity-75-black-0" />
            <Drawer.Content className="bg-black-12 h-fill fixed top-0 bottom-0 left-0 left-0 overflow-y-auto w-75p">
              <LeftMenuSections {...{ minimized, minimizeMaximize }} />
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      )}
    </>
  );
};

export default LeftMenu;

const LeftMenuSections: FC<{
  minimized: boolean;
  minimizeMaximize: () => void;
}> = ({ minimized, minimizeMaximize }) => {
  const isAdmin = useIsAdmin();
  const isInternalUser = useIsInternalUser();
  const mobileUser = useIsMobile();

  return (
    <>
      <div className="px-sm my-md flex-row justify-start">
        {minimized ? (
          <img src="/icons/favicon.svg" width="38" height="24" alt="" />
        ) : (
          <img alt="" src="/assets/logo_long.svg" width="147" height="32" />
        )}
      </div>

      <div className="flex flex-col justify-between overflow-y-auto overflow-hidden">
        <div>
          {!isInternalUser && (
            <ExternalUserMyViewSection minimized={minimized} />
          )}
          {isInternalUser && <UFPMyViewSection minimized={minimized} />}
          {isAdmin && <AdminSection minimized={minimized} />}
        </div>

        <div>
          <BottomSection minimized={minimized} />
          {!mobileUser && (
            <div className="mt-lg flex-col">
              <LeftMenuButton
                onClick={minimizeMaximize}
                icon={
                  minimized ? (
                    <ArrowRightIcon size={24} />
                  ) : (
                    <ArrowLeftIcon size={24} />
                  )
                }
                label="Collapse"
                minimized={minimized}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
