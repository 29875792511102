import { useMemo, useState } from 'react';
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import './DateRangePicker.scss';
import './DatePicker.scss';
import { FormControl } from '@mui/material';
import TextField from "./TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";


const pad = num => (num < 10 ? '0' : '') + num;

const toIsoString = (date, endOfDay) => {
    const tzo = date.utcOffset();
    const dif = tzo >= 0 ? '+' : '-';

    return date.year() +
        '-' + pad(date.month() + 1) +
        '-' + pad(date.date()) +
        'T' + pad(endOfDay ? 23 : date.hour()) +
        ':' + pad(endOfDay ? 59 : date.minute()) +
        ':' + pad(endOfDay ? 59 : date.second()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
};


/**
* @deprecated please use `~/Components/UI`
**/
const DatePicker = (props) => {
    const [open, setOpen] = useState(false);

    const { id, date, label, disabled, mindate, endOfDay, inputRef, formControlSx, dataCy, ...inputProps } = props;

    const onChange = (date) => {
        props.onChange && props.onChange({
            target: {
                id,
                value: toIsoString(date, endOfDay)
            }
        });
    };

    const value = useMemo(() => {
        if (dayjs(date).isValid()) {
            return dayjs(date);
        }
        let v = date ? new Date(date) : null;
        v = v && !Number.isNaN(v.getTime()) ? v : null;
        if (v == null) { return null }
        return dayjs(v);
    }, [date]);

    return (
       <FormControl sx={formControlSx ?? null}>
            <MuiDatePicker
                sx={{
                    width: "100%",
                    "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        color: "#1c1f31",
                        fontSize: '14px',
                        lineHeight: '18px'}, //styles the input text
                }}
                label={label ?? null}
                minDate={mindate ? dayjs(mindate) : null}
                value={date ? value : null }
                onChange={onChange}
                format={'DD MMM YYYY'}
                slotProps={{
                    textField: { size: 'small'}
                }}
                data-cy = {dataCy}
            />
        </FormControl>
    );
};
export default DatePicker;
