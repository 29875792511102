import {FC} from "react";
import {Button, Dialog, FormInput} from "~/Components/UI";
import { ls } from "~/Locales";
import {useFormData, useMutate, useToggle} from "~/Hooks";
import API from "~/API";
import Toast from "~/Components/Toast";
import {trackEvent} from "~/Services/Tracking";

export const AddCompanyDialog: FC<{
  isCustomers: boolean;
  refreshData: () => void;
}> = (props) => {
  const form = useFormData({ code: "" });
  const open = useToggle();

  const saveCall = useMutate(async () => {
    if (props.isCustomers)
      await API.importCustomer({ customerCode: form.data.code });
    else
      await API.importVendor({ vendorCode: form.data.code });
    open.toggleFalse();
    props.refreshData();
    trackEvent(`Added ${props.isCustomers ? "Customer" : "Vendor"}`);
    Toast.Success(`Successfully added ${props.isCustomers ? "customer" : "vendor"} ${form.data.code}!`);
  });

  return (
    <Dialog
      open={open}
      onOpenChange={form.clear}
      title={ls(
        props.isCustomers
          ? "customer.add"
          : "vendor.add"
      )}
      trigger={
        <Button
          label={
            props.isCustomers
              ? "title.add.customer"
              : "title.add.vendor"
          }
        />
      }
    >
      <div className="desktop-w-min-8xl flex-col gap-md">
        <FormInput
          id="code"
          formData={form}
          label={props.isCustomers ? "title.customer.code" : "title.vendor.code"}
          placeholder="title.code"
        />

        {saveCall.error && (
          <div className="color-red-1">
            Something Went Wrong!
          </div>
        )}

        <Button
          label="title.sync.from.msr"
          onClicked={saveCall.mutate}
          loading={saveCall.loading}
          disabled={!form.hasChanges}
        />
      </div>
    </Dialog>
  );
}