import { FC, useMemo, useState } from "react";
import {
  MetricsCard,
  MetricsHeader,
  MetricStat,
  MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import { useFormData } from "~/Hooks";
import { PERIODS } from "~/Pages/Metrics/Data/MetricsData";
import { Button, FormSelect, Table } from "~/Components/UI";
import dayjs from "dayjs";
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import { useLeadsToSalesMetricsData } from "~/Pages/Metrics/Data/LeadsToSalesMetricsData";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import PriceView from "~/Components/Views/PriceView";
import { SalesOrderView, TimeSeriesCategoricalMetric } from "~/API";
import { TbArrowBackUp } from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import CompanyView from "~/Components/Views/CompanyView";
import DateView from "~/Components/Views/DateView";
import { SalesOrderLink } from "~/Components/Orders/OrderLinks";
import { useSalesOrders } from "~/Data/Orders/SalesOrders";
import {useIsInternalUser} from "~/Reducers/User";

interface Filters {
  period: PERIODS;
  dataKey: "value" | "count";
}

export const LeadsToSalesMetricsCard: FC = () => {
  const filterForm = useFormData<Filters>({
    period: PERIODS.SIX_MONTHS,
    dataKey: "value",
  });

  const metrics = useLeadsToSalesMetricsData(filterForm.data.period);

  const onDataKeyChange = (val: string) =>
    filterForm.onDataChange("dataKey", val);

  const totalPeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.count, 0),
    0,
  );

  const totalValuePeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.value, 0),
    0,
  );

  const [detailsIndex, setDetailsIndex] = useState<number | undefined>();
  const onBarClicked = (_: any, index: number) => setDetailsIndex(index);
  const onBack = () => setDetailsIndex(undefined);

  return (
    <MetricsCard>
      {detailsIndex != undefined && (
        <OrdersList metrics={metrics.data[detailsIndex]} onBack={onBack} />
      )}
      {detailsIndex == undefined && (
        <>
          <MetricsHeader title="Leads to Sales">
            <FormSelect
              id="period"
              formData={filterForm}
              data={Object.values(PERIODS)}
              getLabel={(id) => id}
            />
          </MetricsHeader>
          <MetricStatContainer>
            <MetricStat
              label={`Leads converted to sales in the last ${filterForm.data.period}`}
              loading={metrics.isLoading}
            >
              {totalPeriod}
            </MetricStat>
            <MetricStat label={`Total in the last ${filterForm.data.period}`} loading={metrics.isLoading}>
              ${totalValuePeriod?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </MetricStat>
          </MetricStatContainer>
          <BarChart
            dataset={metrics.data || [{ period: "", data: [] }]}
            xAxisFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMM")
            }
            tooltipLabelFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMMM YYYY")
            }
            tooltipDataFormat={
              filterForm.data.dataKey == "value"
                ? (val: number) => <PriceView price={val} />
                : undefined
            }
            onbarClicked={onBarClicked}
            width="100%"
            height={vars.size["7xl"]}
            dataKey={filterForm.data.dataKey}
            loading={metrics.isLoading}
          />

          <div className="flex-row align-center justify-end">
            <Tabs
              id="dataKey"
              value={filterForm.data.dataKey}
              onValueChange={onDataKeyChange}
            >
              <TabsList>
                <TabsTrigger value="count">Count</TabsTrigger>
                <TabsTrigger value="value">Value</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </>
      )}
    </MetricsCard>
  );
};

const ordersHelper = createColumnHelper<SalesOrderView>();

const columns = [
  ordersHelper.accessor("dateCreated", {
    header: "Date",
    cell: (data) => <DateView date={data.getValue()} />,
    size: 0,
  }),
  ordersHelper.accessor("id", {
    header: "ID",
    cell: (data) => <SalesOrderLink id={data.getValue()} />,
    size: 32,
  }),
  ordersHelper.accessor("companyId", {
    header: "Customer",
    cell: (data) => <CompanyView id={data.getValue()} />,
  }),
];

const OrdersList: FC<{
  onBack: () => void;
  metrics: TimeSeriesCategoricalMetric;
}> = (props) => {
  const isInternal = useIsInternalUser();

  const dateTo = useMemo(() => {
    return dayjs(props.metrics.period.slice(0, 10))
      .endOf("month")
      .toISOString();
  }, [props.metrics.period]);

  const orders = useSalesOrders(isInternal, {
    companyCreateDateFrom: props.metrics.period,
    companyCreateDateTo: dateTo,
  });

  const table = useReactTable({
    data: orders.data?.flat() || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onScrollToBottom = () => orders.setSize(orders.size + 1);

  return (
    <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
      <Button
        variant="text"
        color="gray"
        onClicked={props.onBack}
        leftIcon={<TbArrowBackUp size={16} />}
        label="title.back"
      />
      <div className="text-xl">
        Leads to Sales{" "}
        {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
      </div>
      <LinearLoader loading={orders.isLoading} />
      <Table
        table={table}
        onScrolledBottom={onScrollToBottom}
        className="w-full"
      />
    </div>
  );
};
