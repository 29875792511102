import { FC } from "react";
import { LC } from "~/Locales";
import { Button } from "~/Components/UI";
import { TbSquareXFilled } from "react-icons/tb";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import { ChatUnreadBadge } from "~/Components/Chats/ChatUnreadBadge";
import { ChatView } from "~/Components/Chats/ChatView";
import { ChatUnreadView } from "~/API";
import {useIsUserChatParticipant} from "~/Data/Chats";
import {
  useCurrentUser,
  useIsExternalUser,
  useIsInternalUser,
} from "~/Reducers/User";

export const ChatViewEntity: FC<{
  onClose: () => void;
  chatExternal?: ChatUnreadView;
  chatInternal?: ChatUnreadView;
  showInternal?: boolean;
}> = (props) => {
  const currentUser = useCurrentUser();
  const isExternalUser = useIsExternalUser();
  const isInternalUser = useIsInternalUser();

  const isExternalChatParticipant = useIsUserChatParticipant(
    props.chatExternal,
    currentUser.id,
  );

  const showExternal = isExternalChatParticipant && (isExternalUser || isInternalUser);

  return (
    <div className="flex-col flex overflow-hidden h-full">
      <div className="flex-row gap-sm justify-between px-md py-sm align-center">
        <div className="text-xl">
          <LC id="title.chat" />
        </div>
        <Button
          leftIcon={<TbSquareXFilled size={24} />}
          color="gray"
          variant="text"
          onClicked={props.onClose}
        />
      </div>
      {isInternalUser && (
        <Tabs
          defaultValue="internal"
          className="flex flex-col overflow-hidden h-full"
        >
          <TabsList className="mx-sm">
            <TabsTrigger
              value="internal"
              className="flex-row align-center gap-sm"
            >
              <LC id="title.internal" />
              <ChatUnreadBadge unread={props.chatInternal?.unread || 0} />
            </TabsTrigger>
            <TabsTrigger
              value="external"
              className="flex-row align-center gap-sm"
            >
              <LC id="title.external" />
              <ChatUnreadBadge unread={props.chatExternal?.unread || 0} />
            </TabsTrigger>
          </TabsList>
          <TabsContent value="internal" className="h-full overflow-hidden">
            <div className="flex-row overflow-hidden h-full">
              {props.chatInternal &&
                <ChatView chatId={props.chatInternal?.id} hideTitle />
              }
            </div>
          </TabsContent>
          <TabsContent value="external" className="h-full overflow-hidden">
            <div className="flex-row overflow-hidden h-full">
              {props.chatExternal &&
                <ChatView chatId={props.chatExternal?.id} hideTitle />
              }
            </div>
          </TabsContent>
        </Tabs>
      )}
      {props.showInternal && props.chatInternal && !showExternal && (
        <div className="flex flex-row overflow-hidden">
          <ChatView chatId={props.chatInternal?.id} hideTitle />
        </div>
      )}
      {!isInternalUser && props.chatExternal && showExternal && (
        <div className="flex flex-row overflow-hidden">
          <ChatView chatId={props.chatExternal?.id} hideTitle />
        </div>
      )}
      {!isInternalUser && !props.showInternal && !showExternal && (
        <div className="flex flex-col align-center justify-center">
          <div className="text-bold">You are not participant of the chat.</div>
          <div>Follow this order to join the chat</div>
        </div>
      )}
    </div>
  );
};
