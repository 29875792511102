import { FC } from "react";
import { Card, FormCheckbox } from "~/Components/UI";
import { FormReturn } from "~/Hooks";
import {ProductUfp, ProductV2ProductType} from "~/API";

export const FlagsProductDetailsSection: FC<{
  form: FormReturn<Partial<ProductUfp>>;
  canEdit: boolean;
}> = (props) => {
  const isFinance = props.form.data.productType == ProductV2ProductType.FINANCE;

  return (
    <Card className="flex-col gap-md w-min-8xl">
      <div className="text-lg">Flags</div>

      {props.form.errors?.["flag"] && (
        <div className="color-red-1 text-bold">Please Select at least one flag.</div>
      )}

      <div className="flex flex-row gap-md">
        <div className="flex flex-col flex-wrap gap-md">
          <FormCheckbox
            formData={props.form}
            id="isImport"
            label="title.import"
            disabled={!props.canEdit}
          />
          <FormCheckbox
            formData={props.form}
            id="isExport"
            label="title.export"
            disabled={!props.canEdit}
          />
          <FormCheckbox
            formData={props.form}
            id="exportManufacturing"
            label="title.export.manufacturing"
            disabled={!props.canEdit}
          />
        </div>
        <div className="flex flex-col gap-md">
          <FormCheckbox
            formData={props.form}
            id="isDomestic"
            label="title.domestic"
            disabled={!props.canEdit}
          />
          <FormCheckbox
            formData={props.form}
            id="foreignToForeign"
            label="title.foreign.to.foreign"
            disabled={isFinance || !props.canEdit}
          />
        </div>
      </div>
    </Card>
  );
};
