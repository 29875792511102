import {FC, MutableRefObject, useEffect, useRef, useState} from "react";
import { FormReturn, useToggle } from "~/Hooks";
import {TbCalendarEvent, TbChevronLeft, TbChevronRight, TbX} from "react-icons/tb";
import { cn } from "~/Utils";
import { DayPicker } from "react-day-picker";
import {Button, Popover, PopoverContent, PopoverTrigger, Show} from "~/Components/UI";
import dayjs from "dayjs";
import {LC, LocalizedMessageKey} from "~/Locales";
import "./DateInput.scss";

import objectSupport from "dayjs/plugin/objectSupport";
dayjs.extend(objectSupport);

export interface DateInputProps {
  id?: string;
  value: string;
  onChange: (evt: any) => void;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  containerClassName?: string;
  label?: LocalizedMessageKey;
  showClear?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const dateValueChanged = (
  setDate: (value: string) => void,
  max: number,
  nextRef?: MutableRefObject<any>,
) => {
  return (evt) => {
    setDate(evt.target.value);

    if (
      evt.target.value.toString().length == max.toString().length &&
      nextRef != null
    )
      // workaround to let react re-render (so latest value shows in blur func) wait 1 ms
      setTimeout(() => nextRef.current?.focus(), 1);
  };
};

export const DateInput: FC<DateInputProps> = (props) => {
  const open = useToggle();

  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const dayRef = useRef(null);

  const [rawMonth, setRawMonth] = useState(dayjs(props.value).format("MM"));
  const [rawDay, setRawDay] = useState(dayjs(props.value).format("DD"));
  const [rawYear, setRawYear] = useState(dayjs(props.value).format("YYYY"));

  useEffect(() => {
    setRawMonth(dayjs(props.value).format("MM"));
    setRawDay(dayjs(props.value).format("DD"));
    setRawYear(dayjs(props.value).format("YYYY"));
  }, [props.value]);

  const monthFocused = useToggle();
  const dayFocused = useToggle();
  const yearFocused = useToggle();

  const onMonthChanged = dateValueChanged(setRawMonth, 12, dayRef);
  const onDayChanged = dateValueChanged(setRawDay, 31, yearRef);
  const onYearChanged = dateValueChanged(setRawYear, 9999);

  const changeDateText = (after: () => void) => {
    return () => {
      const newDate = dayjs({
        year: rawYear,
        month: +rawMonth - 1,
        day: rawDay,
      });
      if(newDate.isValid()) {
        setRawMonth(newDate.format("MM"));
        setRawDay(newDate.format("DD"));
        setRawYear(newDate.format("YYYY"));
        props.onChange({
          target: {
            value: newDate.toISOString(),
            id: props.id,
            name: props.id,
          },
        });
      }
      after();
    };
  };

  const changeDateCalendar = (date: Date) => {
    const newDate = dayjs(date);
    setRawMonth(newDate.format("MM"));
    setRawDay(newDate.format("DD"));
    setRawYear(newDate.format("YYYY"));
    props.onChange({
      target: {
        value: newDate.toISOString(),
        id: props.id,
        name: props.id,
      },
    });
    open.toggleFalse();
  };

  const onMonthBlur = changeDateText(monthFocused.toggleFalse);
  const onDayBlur = changeDateText(dayFocused.toggleFalse);
  const onYearBlur = changeDateText(yearFocused.toggleFalse);

  const inputFocused =
    monthFocused.value || dayFocused.value || yearFocused.value;

  const selectAll = (event) => event.target.select();

  const onClear = () => {
    props.onChange({
      target: {
        value: "",
        id: props.id,
        name: props.id,
      },
    })
  }

  const calendarPickedDate = (() => {
    const djs = dayjs(props.value);
    if(djs.isValid())
      return djs.toDate();
    else
      return undefined;
  })()

  return (
    <div className={cn("flex-col", props.containerClassName)} onClick={(e) => e.stopPropagation()}>
      <Show when={props.label !== undefined}>
        <div className="text-left ml-md mb-xxs color-black-6 text-sm">
          <LC id={props.label} />
          {props.required && " *"}
        </div>
      </Show>
      <div
        className={cn(
          "flex-row align-center",
          "rounded-xxs py-xxs px-xs",
          !inputFocused && "border-1 border-black-9",
          inputFocused && "border-1 border-clear outline-2 outline-blue-1",
          props.disabled ? "bg-black-11 color-text-2 cursor-disabled" : "bg-black-12",
        )}
      >
        <input
          type="number"
          placeholder="MM"
          className={cn(
            "text-mono hide-number-spinner border-none placeholder-color-black-7",
            props.disabled && "color-text-2"
          )}
          style={{ width: "2ch" }}
          value={rawMonth}
          onChange={onMonthChanged}
          onFocus={(evt) => {
            selectAll(evt);
            monthFocused.toggleTrue();
          }}
          onBlur={onMonthBlur}
          ref={monthRef}
          disabled={props.disabled}
        />
        <span className="text-mono">/</span>
        <input
          type="number"
          placeholder="DD"
          className={cn(
            "text-mono hide-number-spinner border-none placeholder-color-black-7",
            props.disabled && "color-text-2"
          )}
          style={{ width: "2ch" }}
          value={rawDay}
          onChange={onDayChanged}
          onFocus={(evt) => {
            selectAll(evt);
            dayFocused.toggleTrue();
          }}
          onBlur={onDayBlur}
          ref={dayRef}
          disabled={props.disabled}
        />
        <span className="text-mono">/</span>
        <input
          type="number"
          placeholder="YYYY"
          className={cn(
            "text-mono hide-number-spinner border-none placeholder-color-black-7",
            props.disabled && "color-text-2"
          )}
          style={{ width: "4ch" }}
          value={rawYear}
          onChange={onYearChanged}
          onFocus={(evt) => {
            selectAll(evt);
            yearFocused.toggleTrue();
          }}
          onBlur={onYearBlur}
          ref={yearRef}
          disabled={props.disabled}
        />

        <div className="w-min-xs flex" />

        <Show when={props.showClear && props.value != ""}>
          <Button
            color="gray"
            leftIcon={<TbX size={12} />}
            onClicked={onClear}
            disabled={props.disabled}
          />
        </Show>

        <Popover open={open.value} onOpenChange={() => open.toggle()}>
          <PopoverTrigger
            className={cn(
              "rounded-xxs pr-xxs flex-row align-center justify-center",
              "bg-none border-none",
              props.disabled
                ? "color-text-2 cursor-disabled"
                : "color-black-3 hover-color-black-1 focus-outline-2 focus-outline-black-9 cursor-pointer"
            )}
          >
            <TbCalendarEvent size={22} />
          </PopoverTrigger>
          <PopoverContent className="h-max-unset" align="end">
            <DayPicker
              selected={calendarPickedDate}
              onDayClick={changeDateCalendar}
              fromDate={props.minDate}
              toDate={props.maxDate}
              defaultMonth={calendarPickedDate}
              className={cn("p-sm", props.className)}
              disabled={props.disabled}
              classNames={{
                months: "flex-col mobile-flex-row gap-sm",
                caption: "flex justify-center relative align-center",
                caption_label: "text-semibold text-lg text-center",
                nav: "absolute left-0 right-0 top-0 bottom-0 flex-row align-center justify-between",
                nav_button: cn(
                  "h-xl w-xl bg-transparent hover-bg-black-10 cursor-pointer",
                  "flex-row align-center justify-center rounded-xxs border-none",
                ),
                table: "mt-md w-full border-collapse py-xxs",
                head_row: "flex-row",
                head_cell: "color-text-2 w-xl",
                row: "flex-row mt-xs",
                cell: "w-xl h-xl text-center flex-row align-center justify-center text-sm cursor-pointer",
                day: "calendar-day",
                day_today: "calendar-today",
                day_disabled: "calendar-disabled",
              }}
              components={{
                IconLeft: () => (
                  <TbChevronLeft size={17} className="color-black-3" />
                ),
                IconRight: () => (
                  <TbChevronRight size={18} className="color-black-3" />
                ),
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export interface FormDateInputProps<T = any>
  extends Omit<DateInputProps, "id" | "value" | "onChange"> {
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormDateInput: FC<FormDateInputProps> = (props) => {
  return (
    <DateInput
      {...props}
      value={props.formData.data[props.id]}
      onChange={(val) => props.formData.onDataChange(props.id, val.target.value)}
      required={props.formData.isFieldRequired(props.id, "") || props.required}
    />
  );
}

export interface FormDateInputRangeProps<T = any> {
  label?: LocalizedMessageKey;
  idFrom: keyof T & string;
  idTo: keyof T & string;
  formData: FormReturn<T>;
  showClear?: boolean;
  required?: boolean;
}
export const FormDateInputRange = <T = any>(
  props: FormDateInputRangeProps<T>,
) => {
    return (
      <div className="flex-col">
        <div className="text-left ml-md mb-xxs color-black-6 text-sm">
          <LC id={props.label} />
          {props.formData.isFieldRequired(props.idFrom, "") || props.required && " *"}
        </div>
        <div className="flex-row align-center gap-xxs">
          <FormDateInput
            id={props.idFrom}
            formData={props.formData}
            showClear={props.showClear}
            containerClassName="flex"
          />
          <span>-</span>
          <FormDateInput
            id={props.idTo}
            formData={props.formData}
            showClear={props.showClear}
            containerClassName="flex"
          />
        </div>
      </div>
    )
}