import API, {
  RequestForQuoteProductView,
  RequestsForQuotesMatchingResult,
} from "~/API";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { LocalizedMessage, ls } from "~/Locales";
import { useRequestMatchingVendors } from "./Actions";
import { getRequestForQuote } from "~/Reducers/RequestsForQuote";
import Checkbox from "~/Components/Old/Checkbox";
import VendorRow from "./VendorRow";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { EmptyState } from "~/Components/Old/EmptyStates/EmptyState";
import { EmptyProductsImageLazy } from "~/Components/Old/EmptyStates/EmptyStatesLazy";
import "./RequestMatchingTable.scss";
import Toast from "~/Components/Toast";
import {useAppDispatch} from "~/StoreTypes";

const RequestMatchingTable: FC<{
  currency: string;
  requestId: number;
  requestProducts: RequestForQuoteProductView[];
  showSendOfferRequestDialog: boolean;
  toggleShowSendOfferRequestDialog: () => void;
  setDisableSendOfferRequestButton: (disabled: boolean) => void;
}> = (props) => {
  const {
    currency,
    requestId,
    requestProducts,
    showSendOfferRequestDialog,
    toggleShowSendOfferRequestDialog,
    setDisableSendOfferRequestButton,
  } = props;

  const dispatch = useAppDispatch();

  const [vendors, loading] = useRequestMatchingVendors(requestId, {});

  const [formData, setFormData] = useState<
    (RequestsForQuotesMatchingResult & {
      products: any[];
    })[]
  >([]);

  useEffect(() => {
    setFormData(convertVendorsToFormData(vendors));
  }, [vendors]);

  const numberOfSelectedVendors = useMemo(() => {
    return formData.reduce((acc, vendor) => {
      return (
        acc +
        (!!vendor.products.filter((product) => product.selected).length ? 1 : 0)
      );
    }, 0);
  }, [formData]);

  useEffect(() => {
    const someProductsSelected = formData.some((vendor) =>
      vendor.products.some((product) => product.selected),
    );
    setDisableSendOfferRequestButton(!someProductsSelected);
  }, [formData]);

  // ---------------- handlers ----------------
  const onProductsListLoaded = useCallback(
    (vendorIndex: number, newProducts: any[]) => {
      const newFormData = [...formData];
      if (vendorIndex >= 0 && vendorIndex < newFormData.length) {
        newFormData[vendorIndex].products = newProducts;
      }
      setFormData(newFormData);
    },
    [formData],
  );

  const onProductFieldChange = useCallback(
    (
      vendorIndex: number,
      productIndex: number,
      fieldName: string,
      value: any,
    ) => {
      const newFormData = [...formData];
      const vendorToUpdate = getVendorByIndex(vendorIndex);
      const productToUpdate = getProductByIndex(vendorIndex, productIndex);
      if (vendorToUpdate) {
        vendorToUpdate.products[productIndex] = {
          ...productToUpdate,
          [fieldName]: value,
        };
        setFormData(newFormData);
      }
    },
    [formData],
  );

  const onVendorFieldChange = useCallback(
    (vendorIndex: number, updatedFields: any) => {
      const newFormData = [...formData];
      const vendorToUpdate = getVendorByIndex(vendorIndex);
      if (vendorToUpdate) {
        newFormData[vendorIndex] = { ...vendorToUpdate, ...updatedFields };
        setFormData(newFormData);
      }
    },
    [formData],
  );

  // ---------------- getters ----------------
  const getVendorByIndex = (vendorIndex: number) => {
    if (vendorIndex >= 0 && vendorIndex < formData.length) {
      return formData[vendorIndex];
    }
  };

  const getProductByIndex = (vendorIndex: number, productIndex: number) => {
    const vendor = getVendorByIndex(vendorIndex);
    const products = vendor?.products;
    if (productIndex >= 0 && productIndex < products?.length) {
      return products[productIndex];
    }
  };

  const getProductsByIndex = useCallback(
    (vendorIndex: number) => {
      const vendor = getVendorByIndex(vendorIndex);
      return vendor?.products;
    },
    [formData],
  );

  const unSelectAllProducts = () => {
    const newFormData = [...formData];
    newFormData.forEach((vendor) => {
      vendor.products = vendor.products.map((product) => ({
        ...product,
        selected: false,
      }));
    });
    setFormData(newFormData);
  };

  const selectAllProducts = () => {
    const newFormData = [...formData];
    newFormData.forEach((vendor) => {
      vendor.products = vendor.products.map((product) => ({
        ...product,
        selected: true,
      }));
    });
    setFormData(newFormData);
  };

  const onConfirmSendOfferRequest = useCallback(async () => {
    const arrayOfCreateOfferFromOfferRequests = formData
      .map((vendor) => {
        return {
          vendorCompanyId: vendor.companyId,
          shipFromLocationId: vendor.locationId,
          incoterm: vendor.incoterm,
          incotermAddressDestination: vendor.address,
          incotermPortIdDestination: vendor.portId,
          externalComment: "",
          internalComment: "",
          currency: currency,
          products: vendor.products
            .filter((product) => !!product.selected)
            .map((product) => {
              const requestProduct = requestProducts.find(
                (it) => it.id === product.requestForQuoteProductId,
              );
              return {
                productId: product.productId,
                uom: product.uom,
                lengthIds: requestProduct.lengthIds,
                quantity: product.quantity ? Number(product.quantity) : 0,
                price: product.price,
              };
            }),
        };
      })
      .filter((vendor) => vendor.products.length > 0);

    try {
      const offers = await API.createOffersFromRequestForQuote(
        { id: requestId },
        arrayOfCreateOfferFromOfferRequests as any[],
      );
      Toast.Success(ls("phrase.n.requests.for.offer.created.successfully", offers.length));
      unSelectAllProducts();
      toggleShowSendOfferRequestDialog();
      dispatch(getRequestForQuote(requestId));
    } catch (error) {
      Toast.ApiError(error);
    }
  }, [
    formData,
    requestProducts,
    requestId,
    currency,
    toggleShowSendOfferRequestDialog,
    dispatch,
    unSelectAllProducts,
  ]);

  const someVendorsSelected = formData.some((vendor) => {
    return vendor.products.some((product) => product.selected);
  });
  const allVendorsSelected = formData.every((vendor) => {
    return vendor.products.every((product) => product.selected);
  });
  const noneVendorsSelected = formData.every((vendor) => {
    return vendor.products.every((product) => !product.selected);
  });

  const onCheckboxChange = () => {
    if (allVendorsSelected) {
      unSelectAllProducts();
    } else if (noneVendorsSelected || someVendorsSelected) {
      selectAllProducts();
    }
  };

  const greyCheckbox = someVendorsSelected && !allVendorsSelected;

  const showEmptyState = !loading && !vendors?.length;

  return (
    <>
      {showEmptyState && <RequestMatchingEmptyState />}

      {!showEmptyState && (
        <table className={"basic-table NoTopOffset RequestMatchingTable"}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  value={someVendorsSelected}
                  className={greyCheckbox ? "GreyCheckbox" : ""}
                  onChange={onCheckboxChange}
                />
              </th>
              <th></th>
              <th>
                <LocalizedMessage id={"title.id"} />
              </th>
              <th>
                <LocalizedMessage id={"title.vendor"} />
              </th>
              <th>
                <LocalizedMessage id={"title.company"} />
              </th>
              <th>
                <LocalizedMessage id={"title.products"} />
              </th>
              <th>
                <LocalizedMessage id={"title.offer.received"} />
              </th>
              <th>
                <LocalizedMessage id={"title.last.order"} />
              </th>
              <th colSpan={2}>
                <LocalizedMessage id={"title.incoterm"} />
              </th>
              <th colSpan={2}>
                <LocalizedMessage id={"title.destination"} />
              </th>
            </tr>
          </thead>

          <tbody>
            {formData?.map((vendor, groupIndex) => (
              <VendorRow
                key={groupIndex}
                products={getProductsByIndex(groupIndex)}
                {...{
                  groupIndex,
                  requestId,
                  currency,
                  vendor,
                  requestProducts,
                  onProductsListLoaded,
                  onProductFieldChange,
                  onVendorFieldChange,
                }}
              />
            ))}
          </tbody>
        </table>
      )}

      {showSendOfferRequestDialog && (
        <ConfirmationDialog
          title={"Send Request for Offers"}
          description={`Do you want to send your request to offer to ${numberOfSelectedVendors} ${numberOfSelectedVendors === 1 ? "vendor" : "vendors"}?`}
          description2={""}
          confirmTitle={"Send Request for Offers"}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onConfirm={onConfirmSendOfferRequest}
          onClose={toggleShowSendOfferRequestDialog}
        />
      )}
    </>
  );
};

export default RequestMatchingTable;

const convertVendorsToFormData = (
  vendors: RequestsForQuotesMatchingResult[],
): (RequestsForQuotesMatchingResult & {
  products: any[];
})[] => vendors.map((vendor) => ({ ...vendor, products: [] }));

const RequestMatchingEmptyState: FC = () => {
  return (
    <EmptyState
      title={"No Products"}
      description={
        'Your vendors do not have the requested products saved as "my products". You can add products to their "my products" profile'
      }
      image={<EmptyProductsImageLazy />}
    />
  );
};
