import { FC } from "react";
import {
  MetricsCard,
  MetricsHeader,
  MetricStat,
  MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import { useFormData } from "~/Hooks";
import { PERIODS } from "~/Pages/Metrics/Data/MetricsData";
import { FormSelect } from "~/Components/UI";
import dayjs from "dayjs";
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import {UseSalesOrderTransactionMetrics} from "~/Pages/Metrics/Data/TransactionsMetricData";
import {Tabs, TabsList, TabsTrigger} from "~/Components/UI/Tabs";
import PriceView from "~/Components/Views/PriceView";
import {CompanyCombobox} from "~/Components/Companies/CompanyCombobox";

interface Filters {
  period: PERIODS;
  company: number | undefined;
  dataKey: "count" | "value";
}

export const SalesOrderTransactionsMetricsCard: FC = () => {
  const filterForm = useFormData<Filters>({
    period: PERIODS.SIX_MONTHS,
    company: undefined,
    dataKey: "count",
  });

  const onDataKeyChange = (val: string) =>
    filterForm.onDataChange("dataKey", val);

  const metrics = UseSalesOrderTransactionMetrics({
    period: filterForm.data.period,
    company: filterForm.data.company,
  });

  const totalPeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.filter((p) => p.category == "TimberBase").reduce((p, c) => p + c.count, 0),
    0,
  );

  const totalMSRPeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.filter((p) => p.category == "MSR").reduce((p, c) => p + c.count, 0),
    0,
  );

  const percentageFormat = (val: number) => {
    if(isNaN(val) || val == undefined)
      return '0.00%';

    return `${val?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  }

  const tooltipDataFormat = (value: number, data: any) => {
    const total = data[0].payload.data.reduce((p, c) => p + c[filterForm.data.dataKey], 0);

    if (filterForm.data.dataKey == "count")
      return `${value} (${percentageFormat(value / total * 100 || 0)})`

    return (
      <>
        <PriceView price={value} />
        {` (${percentageFormat(value / total * 100 || 0)})`}
      </>
    )
  }
  return (
    <MetricsCard>
      <MetricsHeader title="Sales Orders By Platform">
        <FormSelect
          id="period"
          formData={filterForm}
          data={Object.values(PERIODS)}
          getLabel={(id) => id}
        />
        <CompanyCombobox
          id="company"
          formData={filterForm}
          filters={{ query: "TYPE == 'BUYER' && ACTIVE == true" }}
          placeholder="title.all.customers"
          showClear
          className="w-max-6xl"
        />
      </MetricsHeader>
      <MetricStatContainer>
        <MetricStat
          label={`TimberBase Sales Orders in the last ${filterForm.data.period}`}
          loading={metrics.isLoading}
        >
          {totalPeriod}
        </MetricStat>
        <MetricStat
          label={`of orders in the last ${filterForm.data.period}`}
          loading={metrics.isLoading}
        >
          {percentageFormat(totalPeriod / (totalMSRPeriod + totalPeriod) * 100)}
        </MetricStat>
      </MetricStatContainer>
      <BarChart
        dataset={metrics.data || [{period: "", data: []}]}
        dataKey={filterForm.data.dataKey}
        xAxisFormat={(date: string) => dayjs(date?.slice(0, 10)).format("MMM")}
        tooltipLabelFormat={(date: string) =>
          dayjs(date?.slice(0, 10)).format("MMMM YYYY")
        }
        tooltipDataFormat={tooltipDataFormat}
        width="100%"
        height={vars.size["7xl"]}
        loading={metrics.isLoading}
      />

      <div className="flex-row align-center justify-end">
        <Tabs
          id="dataKey"
          value={filterForm.data.dataKey}
          onValueChange={onDataKeyChange}
        >
          <TabsList>
            <TabsTrigger value="count">Count</TabsTrigger>
            <TabsTrigger value="value">Value</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
    </MetricsCard>
);
};
