import API, {Attachment, AttachmentRestriction} from '~/API';
import {FC, useEffect, useState} from "react";
import {Button, ButtonFileUpload, Show} from "~/Components/UI";
import {cn} from "~/Utils";
import {TbCamera} from "react-icons/tb";
import {FormReturn} from "~/Hooks";
import {useAsyncAttachHandler} from "~/Utils/Forms";

const UserProfilePhoto: FC<{
  id: string,
  userId: number,
  userForm: FormReturn<any>,
  disabled?: boolean,
  className?: string
}> = (props) => {
  const [attachments, setAttachments] = useState([]);
  const [uploadedFile, setUploadedFile] = useState<string | undefined>();

  const attachmentsChanged = (ats: Attachment[]) => {
    setAttachments(ats);
    props.userForm.onDataChange(props.id, ats[0].id);
  };

  const uploadFiles = (files: File[]) => {
    attachHandler(files);
    setUploadedFile(URL.createObjectURL(files[0]));
  }

  const attachHandler = useAsyncAttachHandler(
    attachments,
    attachmentsChanged,
    () => ({
      restriction: AttachmentRestriction.REFERENCE,
      ref: "USER_ACCOUNT",
      refId: props.userId,
      active: true,
    }),
  );

  const onRemove = () => {
    props.userForm.onDataChange(props.id, null);
    setUploadedFile(undefined);
  };

  const photoId = props.userForm.data[props.id];

  useEffect(() => {
    if(!!photoId)
      setUploadedFile(API.urlAttachmentThumbnail(photoId));
  }, []);

  return (
    <div className={cn('flex-row gap-md align-center', props.className)}>
      <ButtonFileUpload
        acceptedFileTypes={["image/*"]}
        onSubmitted={uploadFiles}
        icon={<div/>}
        asChild
      >
        <Show
          when={photoId != null}
          fallback={<PhotoPlaceholder />}
        >
          <img
            className={cn(
              "w-5xl h-5xl rounded-full flex-row align-center justify-center",
              "cursor-pointer hover-brightness-70"
            )}

            alt="User Profile Image"
            src={uploadedFile}
          />
        </Show>
      </ButtonFileUpload>
      <Show when={!props.disabled}>
        {photoId && <Button color="red" onClicked={onRemove} label="title.remove.photo"/>}
      </Show>
    </div>
  );
};

const PhotoPlaceholder = (props: any) => (
  <div
    className={cn(
      "w-5xl h-5xl rounded-full flex-row align-center justify-center",
      "hover-bg-black-9 cursor-pointer bg-black-10 color-text-3"
    )}
    {...props}
  >
    <TbCamera size={42}/>
  </div>
);

export default UserProfilePhoto;
