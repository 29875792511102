import { useUserPublic, useUserPublicAvatarUrl } from "~/Reducers/Users";
import {
  acceptVideoCall, useLinkToVideoCall
} from "~/Reducers/VideoCall";
import { useUserPreferences } from "~/Reducers/UserPreferences";
import {useAppDispatch} from "~/StoreTypes";

let notifications: Notification[] = [];

export const useShowVideoCallNotification = (userIdFrom: number, chatId: number, chatMessageId: number) => {
  const { name, surname } = useUserPublic(userIdFrom) || {};
  const userAvatarUrl = useUserPublicAvatarUrl(userIdFrom);
  const linkToVideoCall = useLinkToVideoCall(userIdFrom, chatId);
  const dispatch = useAppDispatch();

  const userPreferences = useUserPreferences();
  const silentMode = !!userPreferences.silentMode;

  if (!('Notification' in window)) {
    console.warn('This browser does not support notifications.');
    return;
  }

  return async () => {
    const isLocalhost = window.location.hostname === 'localhost';
    let icon: string;
    try {
      // we can't use localhost as an image source because of cross-origin issues
      icon = isLocalhost ? '' : await resizeImage(userAvatarUrl, 192, 192);
    } catch (error) {
      console.error('Failed to resize image:', error);
    }

    const title = 'Incoming Video Call';
    if (Notification.permission === 'granted' && !silentMode) {
      const options = {
        body: `from ${name} ${surname}\nClick to join the call`,
        icon: icon ?? '',
        // actions only work via service worker. ignore for now
        // actions: [
        //   { action: "accept", title: "Accept" },
        //   { action: "deny", title: "Deny" },
        // ],
        data: { userIdFrom, chatId },
      };

      const notification = new Notification(title, options);
      notification.onclick = async (event) => {
        event.preventDefault();
        notification.close();
        dispatch(acceptVideoCall(userIdFrom, chatMessageId));
        window.open(linkToVideoCall, '_blank');
      };
      notifications.push(notification);
    } else {
      console.error('Notification permission not granted.');
    }
  };
};

export const dismissAllNotifications = () => {
  notifications.forEach((notification) => {
    notification.close();
  });
  notifications = [];
};

export const requestNotificationsPermission = () => {
  if (!("Notification" in window)) {
    console.warn('This browser does not support desktop notification.');

  } else if (Notification.permission === 'granted') {
    // console.log('Notification permission granted.');

  } else if (Notification.permission !== 'denied') {
    try {
      Notification.requestPermission()
        .then((permission) => {
          if (permission !== 'granted') {
            console.error('Notification permission denied.');
          }
        });
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
      // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
      // instead.
      if (error instanceof TypeError) {
        Notification.requestPermission((permission) => {
          if (permission !== 'granted') {
            console.error('Notification permission denied.');
          }
        }).then();
      }
    }
  }
};

// we need to resize the image to 192x192 because that's the maximum size allowed by Chrome Notifications
async function resizeImage(url: string, width: number, height: number): Promise<string> {
  return new Promise((resolve: (dataUrl: string) => void, reject: (reason?: any) => void) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, width, height);
      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };
    img.onerror = (event: ErrorEvent) => {
      reject(new Error(`Failed to load image: ${event}`));
    };
    img.src = url;
  });
}
