import { useState } from "react"
import useSWRMutation from "swr/mutation";

export interface MutateReturn<T, U extends unknown[]> {
  loading: boolean;
  error: any;
  mutate: (...args: U) => Promise<T>;
}

export const useMutate = <T, U extends unknown[]>(
  callback: (...args: U) => Promise<T>,
): MutateReturn<T, U> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(undefined);

  const mutate = async (...args: U) => {
    setLoading(true);
    setError(undefined);

    try {
      const val = await callback(...args);
      setLoading(false);
      return val;
    } catch (e) {
      setError(e);
      setLoading(false);
      throw e;
    }
  };

  return { loading, error, mutate };
};

export const useMutateHook = <A, D, R = any>(keys: any, mutate: (args: A) => Promise<R>, optimistic?: (oldData: D, args: A) => D) => {
  const mut = useSWRMutation(keys, async (_: any, { arg }: { arg: { data: A }}) =>
    await mutate(arg.data)
  );

  const call = async (data: A) =>
    mut.trigger({ data }, {
      optimisticData: optimistic == undefined ? undefined : (currentData: D) => optimistic(currentData, data),
      rollbackOnError: true
    });


  return {
    call,
    ...mut
  };
}