import { FC, useEffect, useState } from "react";
import BaseLoginPage from "./BaseLoginPage";
import { LC } from "~/Locales";
import { useHistory } from "react-router-dom";
import { useFormData, usePageTitle } from "~/Hooks";
import { useSetNewPassword } from "~/Data/CurrentUser";
import { Button, FormInput, Input } from "~/Components/UI";
import {z} from "zod";
import {validPassword, zodMsg} from "~/Utils";

const formValidation = z
  .object({
    password: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Password"]))
      .refine((p) => validPassword(p), zodMsg("password.req.desc")),
    confirmPassword: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Confirm Password"])),
  })
  .refine((d) => d.confirmPassword === d.password, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const SetNewPasswordPage: FC = () => {
  usePageTitle("Password Reset");
  const history = useHistory();
  const setPassword = useSetNewPassword();

  const [resetToken, setResetToken] = useState("");
  const [email, setEmail] = useState("");

  const resetForm = useFormData({
    password: "",
    confirmPassword: "",
  }, { validation: formValidation });

  useEffect(() => {
    let token = window.location.hash;
    token = token && token.substring(1);
    const decoded = atob(token.replaceAll("*", "=")).split("|");

    setResetToken(token);
    setEmail(decoded[0]);
  }, []);

  const onBack = () => history.push("/sign-in");

  const onSubmit = () => {
    if (resetForm.isValid())
      setPassword
        .call({ password: resetForm.data.password, resetToken })
        .then(() => history.push("/sign-in"));
  };

  return (
    <BaseLoginPage>
      <div className="text-2xl">
        <LC id="new.password.title" />
      </div>

      <div className="flex-col gap-sm mt-lg">
        <Input
          id="email"
          value={email}
          label="title.email"
          disabled
        />

        <FormInput
          id="password"
          formData={resetForm}
          label="title.your.new.password"
          type="password"
        />
        <FormInput
          id="confirmPassword"
          formData={resetForm}
          label="confirm.your.password"
          type="password"
        />
      </div>

      <Button
        loading={setPassword.isMutating}
        onClicked={onSubmit}
        className="w-full mt-lg"
        label="new.password.submit"
      />

      <Button
        color="gray"
        className="w-full mt-md"
        onClicked={onBack}
        label="title.back.to.login"
      />
    </BaseLoginPage>
  );
};

export default SetNewPasswordPage;
