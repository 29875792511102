import { FC } from "react";
import { FilterPanel } from "~/Components/Entities/FilterPanel";
import { Button, FormCombobox } from "~/Components/UI";
import { FormReturn, ToggleProps } from "~/Hooks";
import { ProductV3Filters } from "~/Data/Products/Products";
import useSWRImmutable from "swr/immutable";
import API from "~/API";
import { LinearLoader } from "~/Components/Loaders";

const ProductFilters: FC<{
  filterForm: FormReturn<ProductV3Filters>;
  showFilters: ToggleProps;
}> = (props) => {
  const filters = useSWRImmutable(
    ["api/products/v3/filters"],
    () => API.getProductFilters(),
  );

  return (
    <FilterPanel open={props.showFilters}>
      <LinearLoader loading={filters.isLoading} />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.group || []}
        formData={props.filterForm}
        id="group"
        label="title.product.group"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.category || []}
        formData={props.filterForm}
        id="category"
        label="title.category"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.species || []}
        formData={props.filterForm}
        id="species"
        label="title.species"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.surface || []}
        formData={props.filterForm}
        id="surface"
        label="title.surface"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.finish || []}
        formData={props.filterForm}
        id="finish"
        label="title.finish"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.grade || []}
        formData={props.filterForm}
        id="grade"
        label="title.grade"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.dryness || []}
        formData={props.filterForm}
        id="dryness"
        label="title.dryness"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.construction || []}
        formData={props.filterForm}
        id="construction"
        label="title.construction"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.classCode || []}
        formData={props.filterForm}
        id="classCode"
        label="title.class.code"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <FormCombobox
        getLabel={(d) => d}
        data={filters.data?.lengthCode || []}
        formData={props.filterForm}
        id="lengthCode"
        label="title.length.code"
        placeholder="title.all"
        clearValue={[]}
        showClear
        multiple
      />

      <Button
        color="gray"
        label="title.clear"
        onClicked={props.filterForm.clear}
      />
    </FilterPanel>
  );
};

export default ProductFilters;
