import {MapNotificationRefToStringArray, Notification, NotificationRef} from "~/API";
import {TbMessage} from "react-icons/tb";
import {LocalizedMessage} from "~/Locales";
import {NotificationCard, useLink} from "~/Components/Notifications/NotificationCard";
import {useSalesOrder} from "~/Data/Orders/SalesOrders";
import {usePurchaseOrder} from "~/Data/Orders/PurchaseOrders";
import {FC} from "react";
import {useIsInternalCustomer} from "~/Reducers/User";

export const ChatNotification: FC<{
  notification: Notification;
  markRead: () => void;
  count: number;
  refetchNotifications: () => void;
}> = ({notification, markRead, count, refetchNotifications}) => {
  const link = useLink(notification.data.refs, notification.data.type);
  const text = useTextByRef(notification.data.refs);

  if (link === "/") {
    console.log("unknown notification ref: ", notification.data);
    return null;
  }

  const preposition =
    !!notification.data.refs[NotificationRef.PRODUCT_SELLER] ||
    !!notification.data.refs[NotificationRef.MARKETPLACE_USER] ||
    !!notification.data.refs[NotificationRef.DELIVERY_BUYER] ||
    !!notification.data.refs[NotificationRef.PURCHASE_ORDER]
      ? "notifications.im"
      : "notifications.in";

  return (
    <NotificationCard
      icon={<TbMessage size={20}/>}
      link={link}
      dateCreated={notification.dateCreated}
      markRead={markRead}
      read={notification.read}
      notificationId = {notification.id}
      refetchNotifications = {refetchNotifications}
    >
      <b>
        {count === 1 && (
          <LocalizedMessage id={"notifications.new.message"} />
        )}
        {count !== 1 && (
          <LocalizedMessage id={"notifications.new.messages"}>
            {count}
          </LocalizedMessage>
        )}
      </b>
      &nbsp; <LocalizedMessage id={preposition} /> &nbsp;
      {text}
    </NotificationCard>
  );
}

const SalesOrderTextByRef = ({ deliveryId }) => {
  const delivery = useSalesOrder(deliveryId) || undefined;
  return (
    <LocalizedMessage id={"notifications.sales.order"}>
      <b>{delivery.data?.number || deliveryId}</b>
    </LocalizedMessage>
  );
};

const PurchaseOrderTextByRef = ({ deliveryId }) => {
  const internalCustomer = useIsInternalCustomer();
  const delivery = usePurchaseOrder(deliveryId, internalCustomer);
  return (
    <LocalizedMessage id={"notifications.purchase.order"}>
      <b>{delivery.data?.number || deliveryId}</b>
    </LocalizedMessage>
  );
};

const useTextByRef = (refs: MapNotificationRefToStringArray) => {
  if (refs[NotificationRef.DELIVERY_BUYER])
    return (
      <SalesOrderTextByRef
        deliveryId={refs[NotificationRef.DELIVERY_BUYER]?.[0]}
      />
    );
  if (refs[NotificationRef.PURCHASE_ORDER])
    return (
      <PurchaseOrderTextByRef
        deliveryId={refs[NotificationRef.PURCHASE_ORDER]?.[0]}
      />
    );
  if (refs[NotificationRef.PRODUCT_SELLER])
    return (
      <LocalizedMessage id={"notifications.product"}>
        <b>{refs[NotificationRef.PRODUCT_SELLER]?.[0]}</b>
      </LocalizedMessage>
    );
  if (refs[NotificationRef.REQUEST_BUYER])
    return (
      <LocalizedMessage id={"notifications.offer"}>
        <b>{refs[NotificationRef.REQUEST_BUYER]?.[0]}</b>
      </LocalizedMessage>
    );
  if (refs[NotificationRef.REQUEST_SELLER])
    return (
      <LocalizedMessage id={"notifications.request"}>
        <b>{refs[NotificationRef.REQUEST_SELLER]?.[0]}</b>
      </LocalizedMessage>
    );
  if (refs[NotificationRef.REQUEST])
    return (
      <LocalizedMessage id={"notifications.request"}>
        <b>{refs[NotificationRef.REQUEST]?.[0]}</b>
      </LocalizedMessage>
    );
  if (refs[NotificationRef.CHAT])
    return (
      <LocalizedMessage id={"notifications.chat"}>
        <b>{refs[NotificationRef.USER]}</b>
      </LocalizedMessage>
    );
  return "";
};
