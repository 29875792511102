import { OfferStatus } from "~/API";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { requestedOffersRootPath } from "~/Services/Routes";
import { useIsVendor } from "~/Reducers/User";
import { useIsReadOnly } from "../../../../Components/OffersHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { Button } from "~/Components/UI";
import { OfferDetailsButtonsPanelProps } from "../OfferDetailsButtonsPanel";
import { VendorSubmitToPurchasingButton } from "../SharedComponents/VendorSubmitToPurchasingButton";
import { SaveOfferButton } from "../SharedComponents/SaveOfferButton";
import { UpdateRFOButton } from "../SharedComponents/UpdateRFOButton";


const VendorCreateOfferToUFPButton = ({ offerId, saveOffer, loading }) => {
  const history = useHistory();

  const onVendorCreateOfferToUFP = useCallback(async () => {
    try {
      await saveOffer(OfferStatus.RFO_DRAFT_VENDOR, `Offer ${offerId} created`);
      history.replace(`${requestedOffersRootPath}${offerId}/edit`);
    } catch (e) {
      console.log('error saving offer = ', e);
    }
  }, [saveOffer, offerId, history]);

  return (
    <Button
      onClicked={onVendorCreateOfferToUFP}
      disabled={loading}
      data-cy={"VendorCreateOfferToUFPButton"}
      label="title.create.offer"
    />
  )
}

const VendorDeclineRequestButton = ({ offerId, saveOffer, loading }) => {
  const history = useHistory();

  const onDeclineRequest = useCallback(async () => {
    try {
      await saveOffer(OfferStatus.RFO_DECLINED, `Request ${offerId} declined`);
      history.push(requestedOffersRootPath);
    } catch (e) {
      console.log('error declining offer = ', e);
    }
  }, [saveOffer, offerId, history]);

  return (
    <Button
      color="gray"
      onClicked={onDeclineRequest}
      disabled={loading}
      label="request.decline"
    />
  )
}

export const ExternalUsersRFOButtonsPanel = (props: OfferDetailsButtonsPanelProps) => {
  const {
    saveOffer,
    onBack,
    isOfferValid,
    offerId,
    hasChanges,
    loading,
    status,
  } = props;

  const readOnly = useIsReadOnly();
  const isVendor = useIsVendor();

  const SaveButton = ({ label = null }) =>
    <SaveOfferButton hasChanges={hasChanges}
      label={label}
      loading={loading}
      saveOffer={saveOffer}
      status={status} />;

  let buttonsJSX = null;

  if (isVendor) {
    if (readOnly) {
      if (status === OfferStatus.RFO_SENT) {
        buttonsJSX = <>
          <VendorCreateOfferToUFPButton offerId={offerId} saveOffer={saveOffer} loading={loading} />
          <VendorDeclineRequestButton offerId={offerId} saveOffer={saveOffer} loading={loading} />
        </>
      } else if (status === OfferStatus.RFO_DRAFT_VENDOR) {
        buttonsJSX = <>
          <UpdateRFOButton offerId={offerId} />
          <VendorSubmitToPurchasingButton offerId={offerId} saveOffer={saveOffer} isOfferValid={isOfferValid} />
        </>
      }
    }
    // RFO Details Edit Page
    else {
      if (status === OfferStatus.RFO_DRAFT_VENDOR) {
        buttonsJSX = <>
          <SaveButton />
          <VendorSubmitToPurchasingButton offerId={offerId} saveOffer={saveOffer} isOfferValid={isOfferValid} />
          <Button
            color="gray"
            onClicked={onBack}
            label="title.cancel"
          />
        </>
      }

    }
  }

  return buttonsJSX ? <BottomButtonsPanel>{buttonsJSX}</BottomButtonsPanel> : null;
}