import { useUserName } from "~/Reducers/Users";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { videoCallPath } from "~/Services/Routes";
import "./VideoCallLink.scss";


export const VideoCallConfirmationDialog = ({ userId, onConfirm, onClose }) => {
  const userName = useUserName(userId);
  return <ConfirmationDialog
    title={'Video Call'}
    description={`Start video call and invite ${userName}?`}
    confirmTitle={'Invite'}
    closeTitle={'Cancel'}
    onConfirm={onConfirm}
    onClose={onClose}
  />;
};

// helper
export const generateVideoCallLink = (pathname: string, roomName: string) => {
  if (!pathname || !roomName) {
    return null;
  }
  const isVideoCallAlready = pathname?.includes(videoCallPath);
  if (isVideoCallAlready) {

    // remove "/" from the end of the pathname
    if (pathname.endsWith("/")) {
      pathname = pathname.substring(0, pathname.length - 1);
    }
    if (pathname.split("/").length === 3) {
      pathname = `${pathname}/${videoCallPath}`;
    }

    // check if the roomName is the same
    const roomNameInPath = pathname?.split("/").pop();
    if (roomNameInPath === roomName) {
      return null;
    } else {
      // replace the roomName in the path
      const path = pathname?.split("/");
      path.pop();
      return `${path.join("/")}/${roomName}`;
    }
  } else {
    const path = pathname?.endsWith("/") ? pathname : pathname + "/";
    return `${path}${videoCallPath}/${roomName}`;
  }
};
