import { FC } from "react";
import { Card, FormInput, Input } from "~/Components/UI";
import { FormReturn } from "~/Hooks";
import { GroupPermission, ProductGroupView } from "~/API";
import { CurrencyCombobox } from "~/Components/Comboboxes/CurrencyCombobox";
import { UOMComboboxForm } from "~/Components/Comboboxes/UOMCombobox";
import { IncotermCombobox } from "~/Components/Comboboxes/IncotermCombobox";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import { FormDateInput } from "~/Components/UI/Forms/DateInput";
import { PortCombobox } from "~/Components/Ports/PortCombobox";
import { LengthCombobox } from "~/Components/Comboboxes/LengthCombobox";
import { useAppDispatch } from "~/StoreTypes";
import { setAllProductPrices } from "~/Pages/Products/GroupsPage/Reducer";

export const GeneralProductGroupSection: FC<{
  form: FormReturn<ProductGroupView>;
  canEdit: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();

  const priceChange = (e: any) => {
    props.form.onChange(e);
    dispatch(setAllProductPrices(e.target.value));
  };

  return (
    <Card className="flex-col gap-md">
      <div className="text-lg">General</div>

      <div className="flex-row gap-md flex-wrap">
        <IncotermCombobox
          id="incoterm"
          formData={props.form}
          label="title.incoterm"
          containerClassName="flex"
          showClear
          clearValue={""}
          disabled={!props.canEdit}
        />
        <PortCombobox
          id="portOfLoadingId"
          label="title.port.of.loading"
          formData={props.form}
          containerClassName="flex"
          showClear
          clearValue={null}
          disabled={!props.canEdit}
        />
        <FormDateInput
          id="validityDate"
          formData={props.form}
          label="title.validity.date"
          containerClassName="flex"
          disabled={!props.canEdit}
        />
      </div>

      <div className="flex-row gap-md flex-wrap">
        <UOMComboboxForm
          productType={props.form.data.type as any}
          id="unitOfMeasure"
          formData={props.form}
          label="title.uom"
          showClear
          clearValue={""}
          containerClassName="flex"
          disabled={!props.canEdit}
        />
        <FormInput
          id="minOrderQuantity"
          type="number"
          label="title.min.order.quantity"
          formData={props.form}
          containerClassName="flex"
          disabled={!props.canEdit}
          rightIcon={
            <div className="mx-md color-text-3">
              {props.form.data.unitOfMeasure}
            </div>
          }
        />
        <FormInput
          id="productGroupMargin"
          type="number"
          label="title.product.group.margin"
          formData={props.form}
          containerClassName="flex"
          rightIcon={<div className="mx-md color-text-3">%</div>}
          disabled={!props.canEdit}
        />
      </div>

      <UserByPermissionCombobox
        id="buyerUserIds"
        permissions={[GroupPermission.CAN_BE_BUYER]}
        clearValue={[]}
        label="title.buyers"
        multiple
        showClear
        formData={props.form}
        disabled={!props.canEdit}
      />

      <LengthCombobox
        id="lengthIds"
        multiple
        showClear
        clearValue={[]}
        label="title.lengths"
        formData={props.form}
        disabled={!props.canEdit}
      />

      <div className="flex-row gap-md flex-wrap">
        <Input
          id="price"
          value={props.form.data.price.toString()}
          onChange={priceChange}
          label="title.price"
          type="number"
          containerClassName="flex"
          disabled={!props.canEdit}
        />
        <CurrencyCombobox
          id="currency"
          formData={props.form}
          label="title.currency"
          showClear
          clearValue=""
          containerClassName="flex"
          disabled={!props.canEdit}
        />
        <div className="flex" />
      </div>
    </Card>
  );
};
