import API, {CreateQuoteFeedbackRequest, CreateQuoteRequest, OfferStatus, QuoteUpdate, QuoteView} from '~/API';
import {useMemo} from 'react';
import {LocalizedMessageKey, localizedString} from '~/Locales';
import {composedItems} from './Utils/Compose';
import {useGetEntity} from "./Utils/Entity";
import dayjs from "dayjs";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";

export const ACTIONS_GET_QUOTES_START = 'ACTIONS_GET_QUOTES_START';
export const ACTIONS_GET_QUOTES_SUCCESS = 'ACTIONS_GET_QUOTES_SUCCESS';
export const ACTIONS_GET_QUOTES_ERROR = 'ACTIONS_GET_QUOTES_ERROR';
export const ACTIONS_CREATE_QUOTE_SUCCESS = 'ACTIONS_CREATE_QUOTE_SUCCESS';
export const ACTIONS_GET_QUOTES_BY_GROUP_START = 'ACTIONS_GET_QUOTES_BY_GROUP_START';
export const ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS = 'ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS';
export const ACTIONS_GET_QUOTES_BY_GROUP_ERROR = 'ACTIONS_GET_QUOTES_BY_GROUP_ERROR';

export const ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_START = 'ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_START';
export const ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_SUCCESS = 'ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_SUCCESS';
export const ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_ERROR = 'ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_ERROR';

export const ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_START = 'ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_START';
export const ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_SUCCESS = 'ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_SUCCESS';
export const ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_ERROR = 'ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_ERROR';

export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_START = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_START';
export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_SUCCESS = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_SUCCESS';
export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_ERROR = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_ERROR';

export const isQuoteExpired = (validityDate: string) => {
  if (!validityDate) return false;
  return dayjs(validityDate).isBefore(dayjs());
}

const quotesSelector = (state: RootState) => state.quotes;
const quoteSelectorCreator = (quoteId: number) => (state: RootState) => state.quotes[quoteId];

export const useQuote = (quoteId: number, force = false): QuoteView => useGetEntity(quoteId, getQuote, quoteSelectorCreator, force);
export const useQuotes = () => useAppSelector(quotesSelector);

const useQuoteKeys = () => {
  const quotes = useQuotes();
  return useMemo(() => Object.keys(quotes), [quotes]);
};

export const useQuoteValue = (quoteId: number) => {
  const quote = useQuote(quoteId);

  return useMemo(() => {
    if (!Array.isArray(quote?.products)) return 0;
    let sum = 0;
    quote.products.filter(product => !!product.active).forEach(product => {
      sum += (product.quantity || 0) * (product.price || 0);
    });
    return sum;
  }, [quote?.products]);
};

export const useQuoteProductCodes = (quoteId: number) => {
  const quote = useQuote(quoteId);

  return useMemo(() => {
    if (!Array.isArray(quote?.products)) return '-';
    return [...new Set(quote.products.filter(product => !!product.active).map(product => product.productCode))].join(', ');
  }, [quote?.products]);
};

export const useQuoteAsReferenceText = (quoteId: number) => {
  const quote = useQuote(quoteId);
  return useMemo(() => quoteAsReferenceText(quote), [quote]);
};

export const useQuoteAsReferenceLabels = () => {
  const quotes = useQuotes();
  const keys = useQuoteKeys();
  return useMemo(() => keys.reduce((a, key) => {
    a[key] = quoteAsReferenceText(quotes[key]);
    return a;
  }, {}), [keys, quotes]);
};

const quoteAsReferenceText = (quote: QuoteView) => {
  if (!quote) return '';
  const { id, dateCreated, status, customerCompanyName, shipToName } = quote as QuoteView;
  const dateString = dayjs(dateCreated).format('DD MMM YYYY');
  const statusString = localizedString(('quote.status.' + status) as LocalizedMessageKey);
  return `Q-${id} - ${dateString} - ${statusString} - ${customerCompanyName} - ${shipToName}`;
};

interface GetQuoteFilters {
  offset: number;
  [key: string]: any;
}

export const getQuotes = (filters: GetQuoteFilters = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_START });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_SUCCESS, quotes, offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_ERROR, error });
  }
};

export const getQuotesByStatus = (filters = { offset: 0, status: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_START, groupId: filters.status?.[0] });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS, quotes, groupId: filters.status?.[0], offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_ERROR, error, groupId: filters.status?.[0] });
  }
};

export const getQuotesForOffer = (filters = { offset: 0, offer: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_START, groupId: `O${filters.offer?.[0]}` });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS, quotes, groupId: `O${filters.offer?.[0]}`, offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_ERROR, error, groupId: `O${filters.offer?.[0]}` });
  }
};

export const getQuotesForRequest = (filters = { offset: 0, request: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_START, groupId: `R${filters.request?.[0]}` });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS, quotes, groupId: `R${filters.request?.[0]}`, offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_ERROR, error, groupId: `R${filters.request?.[0]}` });
  }
};

export const getQuotesForCustomer = (filters = { offset: 0, customer: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_START, groupId: filters.customer?.[0] });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS, quotes, groupId: filters.customer?.[0], offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_ERROR, error, groupId: filters.customer?.[0] });
  }
};

export const getQuotesForShipTo = (filters = { offset: 0, shipTo: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_START, groupId: filters.shipTo?.[0] });
    const quotes = await API.getQuotes(filters);
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS, quotes, groupId: filters.shipTo?.[0], offset: filters.offset });
    return quotes;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_QUOTES_BY_GROUP_ERROR, error, groupId: filters.shipTo?.[0] });
  }
};

export const RequestStatuses: OfferStatus[] = [
  OfferStatus.RFO_DRAFT,
  OfferStatus.RFO_DRAFT_VENDOR,
  OfferStatus.RFO_DECLINED,
  OfferStatus.RFO_SENT,
  OfferStatus.RFO_ARCHIVED,
];

export const getReferenceGroupsForQuotes = (filters = { offset: undefined }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_START });
    const offers = await API.getQuotesByOfferSummary(filters);
    const quoteReferences = offers.map(offer => ({ ...offer, type: RequestStatuses.includes(offer.status) ? "REQUEST" : "OFFER" }));
    dispatch({ type: ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_SUCCESS, quoteReferences, offset: filters.offset });
    return quoteReferences;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_ERROR, error });
  }
};

export const getCustomerGroupsForQuotes = (filters = { offset: undefined }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_START });
    const companies = await API.getQuotesByCustomerSummary(filters);
    dispatch({ type: ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_SUCCESS, companies, offset: filters.offset });
    return companies;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_ERROR, error });
  }
};

export const getShipToGroupsForQuotes = (filters = { offset: undefined }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_START });
    const locations = await API.getQuotesByShipToSummary(filters);
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_SUCCESS, locations, offset: filters.offset });
    return locations;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_ERROR, error });
  }
};

export const getQuote = (quoteId: number) => async (dispatch: AppDispatch) => {
  try {
    const quote = await API.getQuote({ id: quoteId });
    dispatch({ type: 'ACTIONS_GET_QUOTE_SUCCESS', quote });
    return quote;
  } catch (error) {
  }
};

export const createQuote = (params: CreateQuoteRequest) => async (dispatch: AppDispatch) => {
  try {
    const quote = await API.createQuote({}, params);
    dispatch({ type: ACTIONS_CREATE_QUOTE_SUCCESS, quote });
    return quote;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const updateQuote = (params: {id: number} & QuoteUpdate) => async (dispatch: AppDispatch) => {
  try {
    const quote = await API.updateQuote({ id: params.id }, params);
    dispatch({ type: 'ACTIONS_UPDATE_QUOTE_SUCCESS', quote });
    return quote;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const deleteQuote = (quoteId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.deleteQuote({ id: quoteId });
    dispatch({ type: 'ACTIONS_DELETE_QUOTE_SUCCESS', quoteId });
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const getQuoteAttachments = (quoteId: number) => async (_: AppDispatch) => {
  try {
    return await API.getQuoteAttachments({ id: quoteId });
  } catch (error) {
  }
};

export const addFollower = (quoteId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.addQuoteMember({ id: quoteId, userId });
    dispatch({ type: 'ACTIONS_ADD_QUOTE_MEMBER_SUCCESS', quoteId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const removeFollower = (quoteId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.removeQuoteMember({ id: quoteId, userId });
    dispatch({ type: 'ACTIONS_REMOVE_QUOTE_MEMBER_SUCCESS', quoteId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const markQuoteRead = (quoteId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.postQuoteReadMark({ quoteId });
    dispatch({ type: "ACTIONS_MARK_QUOTE_READ", quoteId });
  } catch (e) { } // ignore error
}

export const createQuoteFeedback = (quoteId: number, params: CreateQuoteFeedbackRequest) => async (dispatch: AppDispatch) => {
  try {
    const quote = await API.createQuoteFeedback({ id: quoteId }, params);
    dispatch({ type: 'ACTIONS_CREATE_QUOTE_FEEDBACK_SUCCESS', quote });
  } catch (error) {
    throw error;
  }
};

// reducer
export interface QuotesState {
  [id: number]: QuoteView;
}

const INITIAL_STATE: QuotesState = {
  // contains quoteId: {quote} key-values
  // such as:
  // 1: quote  //where 1 is quoteId
};

const reducer = (state: QuotesState, action: any): QuotesState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_QUOTES_SUCCESS:
    case ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS:
      return { ...state, ...composedItems(action.quotes) };

    case 'ACTIONS_GET_QUOTE_SUCCESS':
    case 'ACTIONS_UPDATE_QUOTE_SUCCESS':
    case 'ACTIONS_CREATE_QUOTE_FEEDBACK_SUCCESS': {
      if (action.quote) {
        return {
          ...state,
          [action.quote.id]: action.quote,
        };
      }
      return state;
    }

    case 'ACTIONS_ADD_QUOTE_MEMBER_SUCCESS': {
      if (!state[action.quoteId]) return state;
      const updatedQuote = {
        ...state[action.quoteId],
        members: [...new Set([...state[action.quoteId].members, action.userId])]
      };
      return { ...state, [action.quoteId]: updatedQuote };
    }

    case 'ACTIONS_REMOVE_QUOTE_MEMBER_SUCCESS': {
      if (!state[action.quoteId]) return state;
      const updatedQuote = {
        ...state[action.quoteId],
        members: state[action.quoteId].members?.filter(member => member !== action.userId)
      };
      return { ...state, [action.quoteId]: updatedQuote };
    }

    case 'ACTIONS_MARK_QUOTE_READ': {
      if (!state[action.quoteId]) {
        console.log("not found")
        return state;
      }
      const updatedQuote = {
        ...state[action.quoteId],
        readMark: true,
      };
      return { ...state, [action.quoteId]: updatedQuote };
    }

    case 'ACTIONS_DELETE_QUOTE_SUCCESS': {
      const updatedState = { ...state };
      delete updatedState[action.quoteId];
      return updatedState;
    }

    default:
      return state;
  }
};

export default reducer;
