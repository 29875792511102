import { FC } from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/Components/UI";
import { TbBellFilled } from "react-icons/tb";
import NotificationsList from "~/Components/Notifications/NotificationsList";
import {useNotificationCount} from "~/Data/Notifications";
import {useUserPreferences} from "~/Reducers/UserPreferences";

export const NotificationButton: FC = () => {
  const unread = useNotificationCount();
  const userPrefs = useUserPreferences();

  const countText = unread.data?.unreadCount > 99 ? "99+" : unread.data?.unreadCount;

  if (!userPrefs.showBellNotifications)
    return <></>

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          color="gray"
          variant="text"
          leftIcon={<TbBellFilled size={24} />}
          className="relative mx-lg"
        >
          {unread.data?.unreadCount !== 0 && (
            <div className="absolute right-n-1-3 top-0 bg-orange-1 rounded-xxs px-xxs color-black-12 text-sm">
              {countText}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="h-min-9xl h-max-11xl flex-col">
        <NotificationsList />
      </PopoverContent>
    </Popover>
  );
};
