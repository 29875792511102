import API, {CompanyType, CompanyUsersView} from '~/API';
import {useEffect, useState} from "react";
import {composedItems} from './Utils/Compose';
import {useGetEntity_deprecated} from "./Utils/Entity";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";

export const ACTIONS_GET_COMPANIES_SUCCESS = 'ACTIONS_GET_COMPANIES_SUCCESS';

// hooks
const companiesSelector = (state: RootState) => state.companies;
const companySelectorCreator = (companyId: number) => (state: RootState) => state.companies[companyId];

export const useCompanies = () => useAppSelector(companiesSelector);

const useCompanyKeys = () => {
  const [keys, setKeys] = useState([]);
  const companies = useCompanies();
  useEffect(() => {
    setKeys(Object.keys(companies).map(companyId => Number(companyId)));
  }, [companies]);
  return keys;
};

export const useCompanyLabels = () => {
  const [labels, setLabels] = useState({});
  const companies = useCompanies();
  const companyKeys = useCompanyKeys();
  useEffect(() => {
    setLabels(companyKeys.reduce(
      (a, option) => ({ ...a, [option]: `${companies[option]?.code} - ${companies[option]?.legalName}` }), {}
    ));
  }, [companyKeys]);
  return labels;
};

export const useCompany = (companyId: number, force = false) => useGetEntity_deprecated(companyId, getCompany, companySelectorCreator, force);

export const getCompany = (companyId: number, onSuccess?: () => void, onError?: (error: any) => void) => (dispatch: AppDispatch) => {
  companyId && API.getCompany({ id: companyId })
    .then((company) => {
      dispatch({ type: 'ACTIONS_GET_COMPANY_SUCCESS', company });
      onSuccess?.();
    })
    .catch(onError);
};

export const getCustomerCompaniesForSelect = (filters = { offset: 0 }) =>
  getCompaniesForSelect({ ...filters, query: `TYPE == '${CompanyType.BUYER}' && ACTIVE == true` });

export const getVendorCompaniesForSelect = (filters = { offset: 0 }) =>
  getCompaniesForSelect({ ...filters, query: `TYPE == '${CompanyType.SELLER}' && ACTIVE == true` });

const getCompaniesForSelect = (filters = { offset: 0 } as any) => async (dispatch: AppDispatch) => {
  try {
    const companies = await API.getCompanies(filters);
    dispatch({ type: 'ACTIONS_GET_COMPANIES_FOR_SELECT_SUCCESS', companies, offset: filters.offset });
    return companies;
  } catch (error) { }
};

// reducer
export interface CompaniesState {
  [id: number]: CompanyUsersView;
}

const INITIAL_STATE: CompaniesState = {};

const reducer = (state: CompaniesState, action: any) => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_COMPANIES_SUCCESS:
    case 'ACTIONS_GET_COMPANIES_FOR_SELECT_SUCCESS':
      return { ...state, ...composedItems(action.companies) };

    case 'ACTIONS_COMPANIES_DELETE':
      const newState = {...state};
      delete newState[action.companyId];
      return newState;

    case 'ACTIONS_GET_COMPANY_SUCCESS':
    case 'ACTIONS_UPDATE_COMPANY_SUCCESS':
      return { ...state, [action.company.id]: action.company };

    default:
      return state;
  }
};

export default reducer;