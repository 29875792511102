import {Combobox, ComboboxProps, FormCombobox, FormComboboxProps} from "~/Components/UI";
import {
  ProductType,
  unitsForFinance,
  unitsForLumber,
  unitsForPanels,
} from "~/Reducers/Products";
import { useMemo } from "react";

export const UOMComboboxForm = <T,>(
  props: { productType: ProductType } & Omit<
    FormComboboxProps<T, number>,
    "getLabel" | "data"
  >,
) => {
  const unitsOfMeasure = useMemo(() => {
    if (props.productType === ProductType.LUMBER) return unitsForLumber;
    if (props.productType === ProductType.FINANCE) return unitsForFinance;

    return unitsForPanels;
  }, [props.productType]);
  const getLabel = (d: string) => d;

  return <FormCombobox {...props} data={unitsOfMeasure} getLabel={getLabel} />;
};


export const UOMCombobox = (
  props: { productType: ProductType } & Omit<
    ComboboxProps<string>,
    "getLabel" | "data"
  >,
) => {
  const unitsOfMeasure = useMemo(() => {
    if (props.productType === ProductType.LUMBER) return unitsForLumber;
    if (props.productType === ProductType.FINANCE) return unitsForFinance;

    return unitsForPanels;
  }, [props.productType]);
  const getLabel = (d: string) => d;

  return <Combobox {...props} data={unitsOfMeasure} getLabel={getLabel} />;
};
