import { DocumentType } from "~/API";


export const canUserUploadDoc = (docType: DocumentType, isInternal: boolean, isVendor: boolean) => {
  if (isInternal) return true;
  const fieldName = isVendor ? 'vUpload' : 'cUpload';
  return DOCS_PERMISSIONS[docType]?.[fieldName];
}

const DOCS_PERMISSIONS = {
  [DocumentType.PO]:                              { cUpload: true, vUpload: true },
  [DocumentType.DIB]:                             { cUpload: true, vUpload: true },
  [DocumentType.PAYMENT_CONFIRMATION_PREPAYMENT]: { cUpload: true, vUpload: false },
  [DocumentType.PAYMENT_CONFIRMATION]:            { cUpload: true, vUpload: false },
  [DocumentType.OTHER]:                           { cUpload: true, vUpload: true },
  [DocumentType.DRAFT]:                           { cUpload: true, vUpload: true },

  [DocumentType.C]:  { cUpload: false, vUpload: false },
  [DocumentType.P]:  { cUpload: false, vUpload: true },
  [DocumentType.SAWMILL_ORDER_CONFIRMATION]:      { cUpload: false, vUpload: true },
  [DocumentType.COMMISSION_INVOICE_TO_SELLER]:    { cUpload: false, vUpload: false },

  [DocumentType.PHYTO_DRAFT]: { cUpload: false, vUpload: false },
  [DocumentType.COO_DRAFT]:   { cUpload: false, vUpload: false },
  [DocumentType.BL_DRAFT]:    { cUpload: false, vUpload: false },
  [DocumentType.SWB_DRAFT]:   { cUpload: false, vUpload: false },

  [DocumentType.SAWMILL_PACKING_LIST]: { cUpload: false, vUpload: false },
  [DocumentType.SAWMILL_INVOICE]:      { cUpload: false, vUpload: false },
  [DocumentType.PL]:                   { cUpload: false, vUpload: true },
  [DocumentType.I]:                    { cUpload: false, vUpload: true },
  [DocumentType.PHYTO_ORIGINAL]:       { cUpload: false, vUpload: true },
  [DocumentType.COO_ORIGINAL]:         { cUpload: false, vUpload: true },
  [DocumentType.BL_ORIGINAL]:          { cUpload: false, vUpload: true },
  [DocumentType.SWB_ORIGINAL]:         { cUpload: false, vUpload: true },
  [DocumentType.HEAT_TREATMENT_RECORDS]: { cUpload: false, vUpload: true },
  [DocumentType.INSURANCE_POLICY]:       { cUpload: false, vUpload: true },
  [DocumentType.CN]:                     { cUpload: false, vUpload: false },
  [DocumentType.SHIPPERS_LETTER_OF_INSTRUCTIONS]: { cUpload: false, vUpload: false },
  [DocumentType.PACKAGING_DECLARATION]: { cUpload: false, vUpload: true },
};

export const UPLOADABLE_DOCS_KEYS = [
  DocumentType.DRAFT,
  DocumentType.P,
  DocumentType.SAWMILL_ORDER_CONFIRMATION,
  DocumentType.SAWMILL_PACKING_LIST,
  DocumentType.PAYMENT_CONFIRMATION,
  DocumentType.SHIPPING_SUPPORTING_DOCS,
  DocumentType.OTHER,
];

export const ALL_DOCS_KEYS = [
  DocumentType.C,
  DocumentType.P,
  DocumentType.PO,
  DocumentType.SAWMILL_ORDER_CONFIRMATION,
  DocumentType.DIB,
  DocumentType.PAYMENT_CONFIRMATION_PREPAYMENT,
  DocumentType.PAYMENT_CONFIRMATION,
  DocumentType.COMMISSION_INVOICE_TO_SELLER,
  DocumentType.OTHER,
  DocumentType.SAWMILL_PACKING_LIST,
  DocumentType.SAWMILL_INVOICE,
  DocumentType.PL,
  DocumentType.I,
  DocumentType.PHYTO_ORIGINAL,
  DocumentType.COO_ORIGINAL,
  DocumentType.BL_ORIGINAL,
  DocumentType.SWB_ORIGINAL,
  DocumentType.HEAT_TREATMENT_RECORDS,
  DocumentType.INSURANCE_POLICY,
  DocumentType.CN,
  DocumentType.SHIPPERS_LETTER_OF_INSTRUCTIONS,
  DocumentType.PACKAGING_DECLARATION,
];
