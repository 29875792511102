import { FC, useEffect, useMemo, useRef } from "react";
import {For, Show} from "~/Components/UI";
import ChatTitle from "~/Components/Chats/ChatTitle";
import {
  useChat,
  useChatAddParticipant,
  useChatMessages, useChatParticipants,
  useChatRemoveParticipant,
} from "~/Data/Chats";
import { ChatMessageType, ChatMessageView, ChatType } from "~/API";
import { cn } from "~/Utils";
import { DateViewRelative } from "~/Components/Views/DateView";
import { useCurrentUser, useIsInternalUser } from "~/Reducers/User";
import { UserAvatar, UserAvatarText } from "~/Components/Users/UserAvatar";
import { compareDateHours } from "~/Utils/Dates";
import UserParticipantList from "~/Components/Users/UserParticipantList";
import {
  ArchiveChatButton,
  LeaveChatButton,
} from "~/Pages/Chats/Components/ChatButtons";
import { ChatInput } from "~/Components/Chats/ChatInput";
import { Spinner } from "~/Components/Loaders";
import { ChatMessageParsed } from "~/Components/Chats/ChatMessageParsed";
import {ChatAttachments} from "~/Components/Chats/ChatAttachments";
import {VideoCallButton} from "~/Components/Chats/VideoCallButton";

export const ChatView: FC<{
  chatId: number;
  hideTitle?: boolean;
  internal?: boolean;
}> = (props) => {
  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();

  const removeUser = useChatRemoveParticipant(props.chatId);
  const addUser = useChatAddParticipant(props.chatId);

  const chat = useChat(props.chatId);
  const chatMessages = useChatMessages(props.chatId);
  const participants = useChatParticipants(props.chatId);
  const lastChatId = useRef(null);
  const lastTime = useRef(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const isParticipant = chat.data?.participants?.includes(currentUser.id) || false;
  const isDirect = chat.data?.type === ChatType.DIRECT;

  const participantId =
    isDirect && chat?.data.participants?.filter((id) => id !== currentUser.id)[0];

  const onRemoveUser = async (userId: number) => {
    await removeUser.call({ userId });
  };

  const onAddUser = async (userId: number) => {
    await addUser.call({ userAccountId: userId });
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [chatMessages.data]);

  if (chat.isLoading)
    return (
      <div className="flex flex-col align-center justify-center">
        <Spinner loading size={32} />
      </div>
    );

  return (
    <div className="flex flex-col gap-sm">
      <Show when={!props.hideTitle}>
        <div className="flex-0 desktop-flex-row mobile-flex-col justify-between align-baseline py-sm px-md border-b-1 border-black-10">
          <div className="text-lg text-bold flex-shrink-0">
            <ChatTitle chat={chat.data} showLink />
          </div>

          <div className="flex-row flex-wrap align-center gap-sm">
            <UserParticipantList
              disableAddUsers={isDirect || !isInternalUser}
              members={participants.data || []}
              onAddMember={onAddUser}
              onRemoveMember={onRemoveUser}
            />

            <Show when={isDirect}>
              <VideoCallButton userId={participantId} disabled={!isParticipant} chatId={props.chatId} />
            </Show>

            <Show when={!isDirect && isInternalUser}>
              <ArchiveChatButton chat={chat.data} />

              <Show when={isParticipant}>
                <LeaveChatButton
                  chatId={chat.data.id}
                  internal={props.internal}
                />
              </Show>
            </Show>
          </div>
        </div>
      </Show>

      <Show when={chat.data.archived}>
        <div className="p-sm bg-orange-10 color-orange-1 rounded-xxs text-bold">
          This Chat is Archived
        </div>
      </Show>

      <Show when={chat.data.archived === false && !isParticipant}>
        <div className="p-sm bg-orange-10 color-orange-1 rounded-xxs text-bold">
          You are not a Member of This Chat
        </div>
      </Show>

      <div className="flex-row justify-center h-full overflow-y-auto">
        <div className="flex-col w-full gap-sm w-max-12xl">
          <div className="flex flex-col px-md">
            <For each={chatMessages.data}>
              {(message, i) => {
                let showDate = true;
                if (lastTime.current !== null) {
                  showDate = compareDateHours(
                    lastTime.current,
                    message.dateCreated,
                    (diff) => diff > 2,
                  );
                }
                const showUser =
                  message.userAccountId !== lastChatId.current ||
                  showDate ||
                  i == 0;
                lastChatId.current = message.userAccountId;
                lastTime.current = message.dateCreated;
                return (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    showUserInfo={showUser}
                  />
                );
              }}
            </For>
            <div ref={bottomRef} />
          </div>

          <Show when={isParticipant && !chat.data.archived}>
            <ChatInput chatId={props.chatId} participants={participants.data || []} data-cy={"chatInput"} />
          </Show>
        </div>
      </div>
    </div>
  );
};

const ChatMessage: FC<{
  message: ChatMessageView;
  showUserInfo: boolean;
}> = (props) => {
  const currentUser = useCurrentUser();

  const isMyMessage = currentUser.id === props.message.userAccountId;
  const isSystemMessage = props.message.type === ChatMessageType.SYSTEM_MESSAGE;
  const isFieldChange = props.message.type == ChatMessageType.FIELD_CHANGE;
  const isOfferFeedback = props.message.type === ChatMessageType.OFFER_FEEDBACK;
  const isQuoteFeedback = props.message.type === ChatMessageType.QUOTE_FEEDBACK;
  const isVideoCall = props.message.type === ChatMessageType.CALL_INVITATION;
  const isFeedback = isOfferFeedback || isQuoteFeedback;
  const isFromPlatform = isSystemMessage || isFieldChange;

  const userName = isFromPlatform
    ? "TimberBase -"
    : isMyMessage
      ? ""
      : `${props.message.userName} ${props.message.userSurname} - `;

  const text = useMemo(() => {
    if (!isFeedback) return { value: false, message: "" };
    return JSON.parse(props.message.text);
  }, [props.message.text]);
  const { value: feedbackApprove } = text;

  let messageClasses = "bg-black-10 color-text-1";
  if (isFromPlatform) messageClasses = "bg-purple-9 color-purple-0";
  if (isMyMessage) messageClasses = "bg-blue-1 color-blue-10";
  if (isVideoCall) messageClasses = "bg-blue-10 color-blue-0";
  if (isFeedback && feedbackApprove)
    messageClasses = "bg-green-8 color-green-0";
  if (isFeedback && !feedbackApprove) messageClasses = "bg-red-6 color-red-0";

  return (
    <div
      className={cn(
        "grid grid-0-1fr gap-xxs w-max-10xl text-left",
        props.showUserInfo ? "mt-xl" : "mt-xxs",
        isMyMessage ? "ml-auto" : "mr-auto",
      )}
    >
      <Show when={props.showUserInfo} fallback={<div className="w-xl" />}>
        <div />
        <div
          className={cn(
            "color-text-2 flex-row gap-xxs ml-xs align-baseline",
            isMyMessage && "justify-end mr-xxs",
          )}
        >
          <span>{userName}</span>
          <span>
            <DateViewRelative date={props.message.dateUpdated} />
          </span>
        </div>
        <Show when={!isMyMessage} fallback={<div />}>
          <Show
            when={!isFromPlatform}
            fallback={<UserAvatarText label="TB" className="bg-purple-1 my-xxs" />}
          >
            <UserAvatar id={props.message.userAccountId} className="my-xxs" />
          </Show>
        </Show>
      </Show>
      <div className={cn("flex-row", isMyMessage && "justify-end")}>
        <div className={cn("p-sm rounded-xxs flex-col text-multiline", messageClasses)}>
          <ChatMessageParsed
            message={props.message}
            linkClasses="color-orange-1"
          />
          <ChatAttachments attachments={props.message.attachments} />
        </div>
      </div>
    </div>
  );
};
