import SelectWithoutPaging from "./SelectWithoutPaging";
import { useUserPublicKeys, useUserPublicLabels } from "~/Reducers/UsersPublic";

// For this select we simply fetch all users
const UserSelectPublic = (props) => {
  const labels = useUserPublicLabels();
  const keys = useUserPublicKeys();

  return <SelectWithoutPaging {...props}
    data={keys}
    labels={labels}
    skipLabelSorting
  />;

};

export default UserSelectPublic;
