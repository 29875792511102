import * as Sentry from "@sentry/react";
import {configureStore} from "@reduxjs/toolkit";
import {initWsHandlers} from "~/Reducers/Utils/InitWS";

import attachments from "~/Reducers/Attachments";
import seaFreightCosts from "~/Reducers/SeaFreightCosts";
import companies from "~/Reducers/Companies";
import locations from "~/Reducers/Locations";
import ordersItems from "~/Reducers/OrdersItems";
import purchaseOrdersItems from "~/Reducers/PurchaseOrdersItems";
import userReducer from "~/Reducers/User";
import leftMenu from "~/Reducers/LeftMenu";
import productsPublic from "~/Reducers/ProductsPublic";
import productGroupsPublic from "~/Reducers/ProductGroupsPublic";
import quotes from "~/Reducers/Quotes";
import incoterms from "~/Reducers/Incoterms";
import ports from "~/Reducers/Ports";
import lengths from "~/Reducers/Lengths";
import currencies from "~/Reducers/Currencies";
import users from "~/Reducers/Users";
import {usersPublic} from "~/Reducers/UsersPublic";
import productGroups from "~/Reducers/ProductGroups";
import userGroups from "~/Reducers/UserGroups";
import offers from "~/Reducers/Offers";
import ordersPageState from "~/Pages/Orders/OrdersPageReducer";
import offerMatchingState from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingReducer";
import requestedOffersPage from "~/Pages/Requests/OffersPage/Reducer";
import requestsForQuote from "~/Reducers/RequestsForQuote";
import requestsForQuotePage from "~/Pages/Requests/Reducer";
import productGroupsPage from "~/Pages/Products/GroupsPage/Reducer";
import cataloguePage from "~/Pages/Products/CataloguePage/CataloguePageReducer";
import quotesPage from "~/Pages/Quotes/Reducer";
import offersPage from "~/Pages/Offers/Reducer";
import updateBanner from "~/Components/Banners/reducer";
import userPreferences from "~/Reducers/UserPreferences";
import videoCall from "~/Reducers/VideoCall";
import featureFlags from "~/Reducers/FeatureFlags";
import router from "~/Reducers/Router";
import {mutate} from "swr";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    attachments,
    seaFreightCosts,
    companies,
    locations,
    ordersItems,
    purchaseOrdersItems,
    userReducer,
    leftMenu,
    productsPublic,
    productGroupsPublic,
    quotes,
    incoterms,
    ports,
    lengths,
    currencies,
    users,
    usersPublic,
    productGroups,
    userGroups,
    ordersPageState,
    offers,
    offerMatchingState,
    requestedOffersPage,
    requestsForQuote,
    requestsForQuotePage,
    productGroupsPage,
    cataloguePage,
    quotesPage,
    offersPage,
    updateBanner,
    featureFlags,

    userPreferences,
    videoCall,

    router,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export default store;

initWsHandlers(store.dispatch, mutate);
