import {FC} from "react";
import {Card, FormInput, FormSwitch} from "~/Components/UI";
import {FormReturn} from "~/Hooks";
import {ProductUfp, ProductV2ProductType} from "~/API";
import {DictionaryCombobox, ProductContexts} from "~/Components/Comboboxes/DictionaryCombobox";



export const SizingProductDetailsSection: FC<{
  form: FormReturn<Partial<ProductUfp>>;
  canEdit: boolean;
}> = (props) => {
  const isFinance = props.form.data.productType == ProductV2ProductType.FINANCE;

  return (
    <Card className="flex-col gap-md w-min-8xl">
      <div className="text-lg">Dimensions/Pricing</div>

      <div className="flex-row gap-md flex-wrap">
        <DictionaryCombobox
          id="nominalActual"
          label="title.nominal.actual"
          context={ProductContexts.NOMINAL_ACTUAL}
          formData={props.form}
          containerClassName="flex"
          disabled={isFinance || !props.canEdit}
        />

        <DictionaryCombobox
          id="standardUnitOfMeasure"
          label="title.standard.unit.of.measure"
          context={ProductContexts.STANDARD_UNIT_OF_MEASURE}
          formData={props.form}
          containerClassName="flex"
          disabled={isFinance || !props.canEdit}
        />
      </div>

      <div className="flex-row gap-md flex-wrap">
        <FormInput
          id="unitsContainer"
          label="title.units.per.container"
          formData={props.form}
          type="number"
          containerClassName="flex"
          disabled={isFinance || !props.canEdit}
          autoComplete="off"
        />

        <FormInput
          id="unitsTL"
          label="title.units.per.tl"
          formData={props.form}
          type="number"
          containerClassName="flex"
          disabled={isFinance || !props.canEdit}
          autoComplete="off"
        />
      </div>

        <FormSwitch
          id="rlPriceId"
          formData={props.form}
          label="title.rl.price.id"
          disabled={isFinance || !props.canEdit}
        />

        <FormInput
          id="pcsBdl"
          label="title.pcs.per.bdl"
          formData={props.form}
          type="number"
          disabled={isFinance || !props.canEdit}
          autoComplete="off"
        />

    </Card>
);
}