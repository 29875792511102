import { CompanyType } from "~/API";
import { useParams } from "react-router-dom";
import { useIsCompaniesPath } from "~/Services/Routes";
import { useCompany } from "~/Reducers/Companies";
import {
  useIsAdmin,
  useIsCustomer,
  useIsInternalCustomer,
  useIsInternalUser,
  useIsVendor,
} from "~/Reducers/User";
import DashboardSalesOrders from "./Tables/DashboardSalesOrders";
import DashboardPurchaseOrders from "./Tables/DashboardPurchaseOrders";
import DashboardCustomerRequests from "./Tables/DashboardCustomerRequests";
import DashboardCustomerQuotes from "./Tables/DashboardCustomerQuotes";
import DashboardVendorRequests from "./Tables/DashboardVendorRequests";
import DashboardVendorOffers from "./Tables/DashboardVendorOffers";
import { FC } from "react";

const DashboardTables: FC = () => {
  const isCompaniesHub = useIsCompaniesPath();

  const isVendor = useIsVendor();
  const isInternalCustomer = useIsInternalCustomer();
  const isCustomer = useIsCustomer();
  const isInternalUser = useIsInternalUser();
  const isAdmin = useIsAdmin();

  const { id: companyId } = useParams<{ id: string }>();

  const company = useCompany(Number(companyId));
  const isVendorCompany = company?.type === CompanyType.SELLER;

  const showSalesOrders =
    ((isInternalUser || !isVendor) && !isInternalCustomer && !isCompaniesHub) ||
    (isCompaniesHub && !isVendorCompany);
  const showPurchaseOrders =
    ((isInternalUser || isVendor || isInternalCustomer) && !isCompaniesHub) ||
    (isCompaniesHub && isVendorCompany);

  const showCustomerRequests = isCustomer || isAdmin;
  const showCustomerQuotes = isCustomer || isAdmin;
  const showVendorRequests = isVendor || isAdmin;

  return (
    <div className="grid grid-auto-fit-11xl gap-lg text-left">
      {showSalesOrders && (
        <DashboardSalesOrders />
      )}

      {showPurchaseOrders && (
        <DashboardPurchaseOrders />
      )}

      {showCustomerQuotes && (
        <DashboardCustomerQuotes />
      )}

      {showVendorRequests && (
        <DashboardVendorOffers />
      )}

      {showCustomerRequests && (
        <DashboardCustomerRequests />
      )}

      {showVendorRequests && (
        <DashboardVendorRequests />
      )}
    </div>
  );
};

export default DashboardTables;
