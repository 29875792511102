import { FC, useState } from "react";
import BaseLoginPage from "./BaseLoginPage";
import { Button, FormInput } from "~/Components/UI";
import { LC } from "~/Locales";
import { useHistory } from "react-router-dom";
import { z } from "zod";
import { zodMsg } from "~/Utils";
import { useResetPassword } from "~/Data/CurrentUser";
import { useFormData, usePageTitle } from "~/Hooks";

const formValidation = z.object({
  email: z
    .string()
    .min(1, zodMsg("error.non.empty", ["Email"]))
    .email(zodMsg("error.invalid.email")),
});

const ForgotPasswordPage: FC = () => {
  usePageTitle("Password Reset");
  const history = useHistory();
  const passwordReset = useResetPassword();

  const [submitted, setSubmitted] = useState(false);
  const form = useFormData({ email: "" }, { validation: formValidation });

  const onBack = () => history.goBack();

  const onSubmit = () => {
    if (form.isValid())
      passwordReset.call(form.data).then(() => setSubmitted(true));
  };

  return (
    <BaseLoginPage>
      {!submitted && (
        <>
          <div className="text-2xl">
            <LC id="forgot.password.long" />
          </div>

          <div className="text-sm my-md">
            <LC id="forgot.password.we.will.help.reset.password" />
          </div>

          <FormInput
            id="email"
            label="title.email"
            formData={form}
            type="email"
          />

          <Button
            loading={passwordReset.isMutating}
            onClicked={onSubmit}
            className="w-full mt-lg"
            label="forgot.password.submit"
          />
        </>
      )}

      {submitted && (
        <>
          <div className="text-2xl Title">
            <LC id="forgot.password.submitted.title" />
          </div>
          <div className="mt-lg">
            <LC id="forgot.password.submitted.to">
              <b>{form.data.email}</b>
            </LC>
          </div>
          <div className="mt-lg">
            <LC id="forgot.password.submitted.description" />
          </div>
        </>
      )}

      <Button
        color="gray"
        className="w-full mt-md"
        onClicked={onBack}
        label="title.back"
      />
    </BaseLoginPage>
  );
};

export default ForgotPasswordPage;
