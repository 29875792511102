import { QuoteStatus, QuoteView } from "~/API";
import { useCallback, useEffect, useState } from "react";
import { convertVolume } from "~/Utils/UnitConversion";
import { useAppDispatch } from "~/StoreTypes";
import { getLengthsById } from "~/Reducers/Lengths";
import { getProductPublic } from "~/Reducers/ProductsPublic";
import { getQuotes } from "~/Reducers/Quotes";

const EMPTY_ARRAY = [];

export const useOrderedQuotesForOffer = (offerId: number) => {
  const dispatch = useAppDispatch();
  const [offeredQuotes, setOfferedQuotes] = useState<QuoteView[]>(EMPTY_ARRAY);

  useEffect(() => {
    if (!!offerId) {
      dispatch(
        getQuotes({
          offer: [offerId],
          status: [QuoteStatus.ORDERED, QuoteStatus.DELIVERED],
          offset: 0,
        }),
      ).then((quotes) => {
        Array.isArray(quotes) && setOfferedQuotes(quotes);
      });
    }
  }, [offerId]);

  return offeredQuotes;
};

export const useProductQuantityInOrderedQuotes = (
  quotes = [],
  productCode,
  uom,
) => {
  const dispatch = useAppDispatch();
  const [sum, setSum] = useState(0);

  const calculateSum = useCallback(async () => {
    let acc = 0;
    for (const quote of quotes) {
      for (const quoteProduct of quote.products) {
        if (quoteProduct.productCode === productCode) {
          const product = await dispatch(getProductPublic(quoteProduct.id));
          const lengths = await dispatch(
            getLengthsById(quoteProduct.lengthIds),
          );
          acc += convertVolume(
            product,
            lengths?.[0],
            quoteProduct.quantity,
            quoteProduct.uom,
            uom,
          );
        }
      }
    }
    setSum(acc);
  }, [quotes, productCode, uom]);

  useEffect(() => {
    calculateSum();
  }, [quotes, productCode, uom]);

  return sum;
};
