import { ReactNode } from "react";
import toastPrimitive from "react-hot-toast";
import { Button } from "./UI";
import {
  TbAlertSquareRoundedFilled,
  TbInfoSquareRoundedFilled,
  TbSquareRoundedCheckFilled,
  TbX,
} from "react-icons/tb";
import { cn } from "~/Utils";
import * as Sentry from "@sentry/react";
import { FetchError } from "~/API/ReST";

const toast = (icon: ReactNode, title: string, desc: string) => {
  toastPrimitive.custom((t) => (
    <div
      className={cn(
        t.visible ? "animate-toast-show" : "animate-toast-hide",
        "bg-black-12 p-sm flex-row gap-md justify-between align-start",
        "rounded-xs shadow-lg border-1 border-black-10 w-full w-max-9xl",
      )}
    >
      <div>{icon}</div>
      <div className="flex-col text-left flex">
        <div className="text-bold text-lg">{title}</div>
        <div className="color-text-2 overflow-wrap-anywhere text-pre-wrap mt-xxs">
          {desc}
        </div>
      </div>

      <Button
        variant="text"
        color="gray"
        leftIcon={<TbX size={20} />}
        onClicked={() => toastPrimitive.dismiss(t.id)}
      />
    </div>
  ));
};

const Error = (desc: string) => {
  toast(
    <TbAlertSquareRoundedFilled size={24} className="color-red-1" />,
    "Something went wrong!",
    desc,
  );
};

const Success = (desc: string) => {
  toast(
    <TbSquareRoundedCheckFilled size={24} className="color-green-1" />,
    "Success!",
    desc,
  );
};

const Info = (title: string, desc: string) => {
  toast(
    <TbInfoSquareRoundedFilled size={24} className="color-text-1" />,
    title,
    desc,
  );
};

const ApiError = (error: FetchError) => {
  Sentry.captureException(error);
  try {
    const parsedError = JSON.parse(error.responseText);
    const message = parsedError?.message;
    Error(message);
  } catch {
    // default error messages when the server doesn't return one
    if (error.status === 404) {
      Error("The Server Could not be reached.");
    } else if (error.status === 403) {
      Error("You are not authorized to preform that action.");
    } else {
      Error("There was an internal server error.");
    }
  }
};

export default {
  Error,
  Success,
  Info,
  ApiError,
};
