import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDeepCompare} from "~/Hooks";
import SelectLoaderRow from "./SelectLoaderRow";
import Select from "./Select";
import {useAppDispatch} from "~/StoreTypes";


const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const ITEMS_PER_PAGE_COUNT = 20;
const LOADING_CELL_KEY = 'LOADING';
const ALL_MULTISELECT_VALUE = ['0'];

const SelectWithPaging = (props) => {
  const {
    showAll = false, showSelectNone = false, exclude = EMPTY_ARRAY,
    getData, getDataByEntityId, useDataLabels, entityId = null, filter = EMPTY_OBJECT, selectRef, ...selectProps
  } = props;

  const dispatch = useAppDispatch();
  const [itemIds, setItemIds] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const getDataTimeout = useRef();
  const selectRefInner = useRef();
  const listScroll = useRef();
  const scrolledToBottom = useRef(false);

  const [requestFilter, setRequestFilter] = useState(filter);
  useDeepCompare(() => {
    setRequestFilter(filter);
  }, [filter]);

  const getMoreItems = useCallback(async (offset) => {
    if (props.disabled)
      return;

    setLoading(true);
    const filterOffset = typeof offset === 'number' ? offset : itemIds.length;
    const params = {...filter, search, offset: filterOffset, limit: ITEMS_PER_PAGE_COUNT};

    try {
      const loadedData = (!!entityId && !!getDataByEntityId)
        ? await dispatch(getDataByEntityId(entityId, params))
        : await dispatch(getData(params));

      const loadedIds = loadedData.map(it => it.id);
      setItemIds(!!filterOffset ? [...new Set([...itemIds, ...loadedIds])] : loadedIds);
      if (!filterOffset) listScroll.current.scrollTo(0, 0);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [props.disabled, dispatch, entityId, filter, getData, getDataByEntityId, itemIds, search]);

  const onScroll = useCallback(({currentTarget}) => {
    const position = currentTarget.scrollTop / (currentTarget.scrollHeight - currentTarget.offsetHeight);
    if (position >= 0.98) {
      !scrolledToBottom.current && getMoreItems();
      scrolledToBottom.current = true;
    } else {
      scrolledToBottom.current = false;
    }
  }, [getMoreItems]);

  useEffect(() => {
    return () => clearTimeout(getDataTimeout.current);
  }, []);

  useEffect(() => {
    clearTimeout(getDataTimeout.current);
    getDataTimeout.current = setTimeout(() => getMoreItems(0), 300);
  }, [search, entityId, requestFilter]);

  const onFilter = useCallback(() => true, []);

  const onFilterInputChange = useCallback((filterValue) => setSearch(filterValue), []);

  const keys = useMemo(() => {
    const filteredIds = itemIds.filter(id => !exclude.includes(id));
    const finalArray = [];
    if (!!showAll) finalArray.push('0');
    if (!!showSelectNone) finalArray.push('SELECT_NONE');
    finalArray.push(...filteredIds);
    if (loading) finalArray.push(LOADING_CELL_KEY);
    return finalArray;
  }, [showAll, showSelectNone, itemIds, loading, exclude]);

  const dataLabels = useDataLabels();
  const labels = useMemo(() => ({'0': 'All', 'SELECT_NONE': 'Select None', ...dataLabels}), [dataLabels]);

  const currentValue = useMemo(() => {
    if (!!props.multiple && !!showAll && !showSelectNone && !props.value?.length) return ALL_MULTISELECT_VALUE;
    if (!props.multiple && !!showAll && !props.value?.length) return '0';
    return props.value;
  }, [props.value, props.multiple, showAll, showSelectNone]);

  const onChange = useCallback((event) => {
    const {target} = event;
    const single = !props.multiple;

    if (single) {
      const newValue = String(target.value);
      const selectAll = !!showAll && (newValue === '0' || !target.value);

      if (selectAll) {
        props?.onChange?.({...event, target: {...target, value: ''}});
      } else {
        props?.onChange?.(event);
      }

    } else {
      const newValue = target.value;
      const deselectAll = !!showSelectNone && newValue?.includes('SELECT_NONE');
      const selectAll = !!showAll && (
        (!currentValue?.includes('0') && newValue?.includes('0'))
        || (currentValue?.includes('0') && currentValue?.length === 1 && newValue?.includes('0') && newValue?.length === 1)
      );

      if (deselectAll || selectAll) {
        props?.onChange?.({...event, target: {...target, value: EMPTY_ARRAY}});

      } else if (currentValue.includes('0') && newValue.includes('0') && newValue?.length > 1) {
        props?.onChange?.({...event, target: {...target, value: newValue?.filter(it => it !== '0')}});

      } else {
        props?.onChange?.(event);
      }
    }
  }, [currentValue, props.multiple, props.onChange, showAll, showSelectNone]);

  return <Select
    {...selectProps}
    value={currentValue}
    onChange={onChange}
    selectRef={selectRef || selectRefInner}
    data={keys}
    onScroll={onScroll}
    labels={labels}
    skipLabelSorting
    filter={onFilter}
    onFilterInputChange={onFilterInputChange}
    childComponent={childComponent}
  />;
};

export default SelectWithPaging;

const childComponent = (props, id) => {
  if (id === LOADING_CELL_KEY) return <SelectLoaderRow key={"loader"}/>;

  return (
    <li {...props} key={`${id}`}>
      {props.key}
    </li>
  )
}
