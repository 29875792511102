import { QuoteStatus } from "~/API";
import { useMemo } from "react";
import {
  useQuotes,
  getQuote,
  getQuotes,
  useQuoteAsReferenceLabels
} from "~/Reducers/Quotes";
import SelectWithPaging from "./SelectWithPaging";
import useFetchMissingItems from "./useFetchMissingItems";


const QuoteSelect = ({ showForOfferReference = false, showForRequestReference = false, referenceParentId = null, ...props }) => {
  const quotes = useQuotes();

  useFetchMissingItems(props.value, quotes, getQuote);

  const filter = useMemo(() => {
    const filterToReturn = { query: '' };
    if (showForOfferReference || showForRequestReference) {
      filterToReturn.query = '((';
      Object.values(QuoteStatus).filter(it => it !== QuoteStatus.DRAFT && it !== QuoteStatus.ARCHIVED)
        .forEach(it => {
          if (filterToReturn.query.length > 2) {
            filterToReturn.query = filterToReturn.query + ' || ';
          }
          filterToReturn.query = filterToReturn.query + `STATUS == '${it}'`;
        });

      filterToReturn.query = filterToReturn.query + ')';

      if (showForOfferReference) {
        filterToReturn.query = filterToReturn.query
          + ` && OFFER_ID == null) || OFFER_ID == ${referenceParentId}`;
      } else if (showForRequestReference) {
        filterToReturn.query = filterToReturn.query
          + ` && REQUEST_FOR_QUOTE_ID == null) || REQUEST_FOR_QUOTE_ID == ${referenceParentId}`;
      } else {
        filterToReturn.query = filterToReturn.query + ')';
      }
    }

    return filterToReturn;
  }, [showForOfferReference, showForRequestReference, referenceParentId]);

  return <SelectWithPaging {...props}
    getData={getQuotes}
    useDataLabels={useQuoteAsReferenceLabels}
    filter={{ orderBy: 'DATE_CREATED', ...filter, ...props.filter }}
  />;
};

export default QuoteSelect;
