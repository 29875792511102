import { FC, ReactNode } from "react";
import {cn} from "~/Utils";

export const FullPageCard: FC<{ children: ReactNode }> = (props) => (
  <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
    <div className="flex flex-col overflow-hidden">{props.children}</div>
  </div>
);

export const Card: FC<{ children: ReactNode; className?: string }> = (props) => (
  <div className={cn("flex overflow-hidden py-md px-lg bg-black-12 rounded-xxs", props.className)}>
    {props.children}
  </div>
)
