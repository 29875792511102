import useSWRInfinite from "swr/infinite";
import API, {MyProduct} from "~/API";


export interface CompanyProductsByLocationFilters {
    locationId: number,
    query?: string,
    search?: string,
    offset?: number,
    limit?: number
}

const PAGE_SIZE = 15;

const getLocationProductsKey = (
    filters: CompanyProductsByLocationFilters,
    offset: number,
    previousPageData: MyProduct[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: `/v3/products/companyProducts/byLocation/${filters.locationId}`, filters, offset };
};
export const useLocationProducts = (filters: CompanyProductsByLocationFilters) =>
    useSWRInfinite(
        (i, p) => getLocationProductsKey(filters, i * PAGE_SIZE, p),
        (key) =>
            API.getCompanyProductsByLocation({
                ...filters,
                offset: key.offset,
                limit: PAGE_SIZE,
            }),
        {
            revalidateFirstPage: false,
        },
    );

