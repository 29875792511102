import {FC} from "react";
import {GroupPermission} from "~/API";
import {LC} from "~/Locales";
import {FormInput, FormSwitch} from "~/Components/UI";
import {CountryCombobox} from "~/Components/Comboboxes/CountryCombobox";
import {UserByPermissionCombobox} from "~/Components/Users/UserByPermissionCombobox";
import {PortCombobox} from "~/Components/Ports/PortCombobox";
import {IncotermCombobox} from "~/Components/Comboboxes/IncotermCombobox";
import {FormReturn} from "~/Hooks";

export const LocationForm: FC<{
  formData: FormReturn<any>;
  isShipFrom: boolean;
}> = (props) => {
  let {
    locationUseCompanyAddress = false,
    billToUseLocationAddress = false,
  } = props.formData.data || {};

  return (
    <>
      <div className="flex-col gap-sm flex mt-lg">
        <FormInput
          id="locationName"
          formData={props.formData}
          label={props.isShipFrom ? "title.ship.from.name" : "title.ship.to.name"}
        />

        {!props.isShipFrom && (
          <>
            <UserByPermissionCombobox
              id="locationAccountManagerUserId"
              formData={props.formData}
              label="title.buyer"
              showClear
              permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
            />

            <UserByPermissionCombobox
              id="locationAdditionalResponsibles"
              formData={props.formData}
              label="title.additional.responsibles"
              multiple
              showClear
              clearValue={[]}
              permissions={[GroupPermission.CAN_BE_SELLER]}
              excludeUsers={[props.formData.data.accountManagerUserId]}
            />
          </>
        )}

        <div
          className={
            "text-semibold mt-2xl flex-row align-center justify-between text-left"
          }
        >
          <LC
            id={
              props.isShipFrom ? "title.ship.from.address" : "title.ship.to.address"
            }
          />
          <FormSwitch
            id="locationUseCompanyAddress"
            formData={props.formData}
            label="use.default"
          />
        </div>

        <FormInput
          id="locationAddressStreet"
          label="title.address.1"
          formData={props.formData}
          disabled={locationUseCompanyAddress}
        />

        <FormInput
          id="locationAddressStreet2"
          formData={props.formData}
          label="title.address.2"
          disabled={locationUseCompanyAddress}
        />

        <FormInput
          id="locationAddressCity"
          formData={props.formData}
          label="title.city"
          disabled={locationUseCompanyAddress}
        />

        <div className="gap-sm flex-row">
          <FormInput
            id="locationAddressRegion"
            formData={props.formData}
            label="title.state"
            disabled={locationUseCompanyAddress}
            containerClassName="flex"
          />

          <FormInput
            id="locationAddressZip"
            formData={props.formData}
            label="title.postcode"
            disabled={locationUseCompanyAddress}
          />
        </div>

        <CountryCombobox
          id="locationAddressCountry"
          formData={props.formData}
          disabled={locationUseCompanyAddress}
          label="title.country"
        />

        <div className="text-semibold text-left mt-2xl">
          <LC id="title.preferences" />
        </div>

        <IncotermCombobox
          id="locationIncoterm"
          formData={props.formData}
          label="title.incoterm"
        />

        <PortCombobox
          id="locationPortId"
          formData={props.formData}
          label="title.preferred.port"
          filters={{ inactive: false }}
        />

        <FormInput
          id="locationDefaultMarginAsFraction"
          formData={props.formData}
          label="title.default.margin"
          rightIcon={<div className="color-text-3 mx-md">%</div>}
          max={1000}
          type="number"
        />

        <FormInput
          id="locationPaymentTermsDescription"
          formData={props.formData}
          label="title.payment.term"
        />

        <div
          className={
            "text-semibold mt-2xl flex-row align-center justify-between text-left"
          }
        >
          <LC id="title.bill.to.address" />

          <FormSwitch
            id="billToUseLocationAddress"
            formData={props.formData}
            label="use.default"
          />
        </div>

        <FormInput
          id="billToName"
          formData={props.formData}
          label="title.bill.to.name"
        />

        <FormInput
          id="billToAddressStreet1"
          formData={props.formData}
          label="title.address.1"
          disabled={billToUseLocationAddress}
        />

        <FormInput
          id="billToAddressStreet2"
          formData={props.formData}
          label="title.address.2"
          disabled={billToUseLocationAddress}
        />

        <FormInput
          id="billToAddressCity"
          formData={props.formData}
          label="title.city"
          disabled={billToUseLocationAddress}
        />

        <div className="gap-sm flex-row">
          <FormInput
            id="billToAddressRegion"
            formData={props.formData}
            label="title.state"
            disabled={billToUseLocationAddress}
            containerClassName="flex"
          />
          <FormInput
            id="billToAddressZip"
            formData={props.formData}
            label="title.postcode"
            disabled={billToUseLocationAddress}
          />
        </div>

        <CountryCombobox
          id="billToAddressCountry"
          formData={props.formData}
          disabled={billToUseLocationAddress}
          label="title.country"
        />
      </div>
    </>
  );
};
