import { LocationType, GroupPermission } from "~/API";
import { FC, useCallback } from "react";
import {
  LocalizedMessage,
  localizedString,
} from "~/Locales";
import { RequestsPageTableTypes } from "../Reducer";
import { CloseIcon, FilterIcon } from "~/Components/Old/Icons";
import Button from "~/Components/Old/Button";
import CustomerSelect from "~/Components/Old/CustomerSelect";
import DatePicker from "~/Components/Old/DatePicker";
import IconButton from "~/Components/Old/IconButton";
import LocationSelect from "~/Components/Old/LocationSelect";
import PortSelect from "~/Components/Old/PortSelect";
import RequestStatusSelect from "~/Components/Old/RequestStatusSelect";
import TextField from "~/Components/Old/TextField";
import UserSelect from "~/Components/Old/UserSelect";
import {
  useIsCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import ProductSelectPublic from "~/Components/Old/ProductSelectPublic";
import ProductGroupsSelectPublic from "~/Components/Old/ProductGroupsSelectPublic";
import Switch from "~/Components/Old/Switch";
import { useIsMobile } from "~/Hooks/useIsMobile";

const MOBILE_CLEAR_BUTTON_ID = "mobileClearButton";

const internalUsersPermissions = [
  GroupPermission.CAN_BE_SALES_CONTACT_PERSON,
  GroupPermission.CAN_BE_SELLER,
  GroupPermission.CAN_BE_ADMIN,
];

const Filters: FC<{
  showHideFilters: () => void;
  filters: any;
  setFilter: (val: any) => void;
  pageFilters: any;
  resetFiltersTo: (filters: any) => void;
}> = ({ showHideFilters, filters, setFilter, pageFilters, resetFiltersTo }) => {
  const clearParameters = useCallback(
    ({ target }) => {
      resetFiltersTo({
        ...pageFilters,
        tableType:
          filters.tableType || RequestsPageTableTypes.REQUESTS_PAGE_NONE,
        onlyMy: filters.onlyMy,
      });
      target.id === MOBILE_CLEAR_BUTTON_ID && showHideFilters?.();
    },
    [resetFiltersTo, pageFilters, filters.tableType, filters.onlyMy],
  );

  const {
    status,
    customer,
    shipTo,
    product,
    productGroup,
    port,
    incoterm,
    accountManager,
    valueFrom,
    valueTo,
    validityDateFrom,
    validityDateTo,
  } = filters;

  const isInternalUser = useIsInternalUser();
  const isCustomer = useIsCustomer();
  const isMobile = useIsMobile();

  return (
    <div className={"FiltersRightPanelMainContainer"}>
      <div className={"FiltersRowContainer FiltersTitleContainer mb-md"}>
        <div className={"FiltersRowContainer"}>
          <FilterIcon className={"TitleIcon"} size={16} />
          <LocalizedMessage id={"title.filter"} />
        </div>
        <IconButton onClick={showHideFilters}>
          <CloseIcon size={20} />
        </IconButton>
      </div>

      {isMobile && (
        <>
          <div className={"mt-md flex-row align-center"}>
            <Switch
              id={"onlyArchivedOrDeliveredOrExpired"}
              value={filters.onlyArchivedOrDeliveredOrExpired}
              onChange={setFilter}
            />
            <div className={"ml-xs"}>
              <LocalizedMessage id={"title.inactive"} />
            </div>
          </div>

          <div className={"my-md flex-row align-center"}>
            <Switch id={"onlyMy"} value={filters.onlyMy} onChange={setFilter} />
            <div className={"ml-xs"}>
              <LocalizedMessage id={"title.show.only.my.requests"} />
            </div>
          </div>
        </>
      )}

      <RequestStatusSelect
        id={"status"}
        label={localizedString("title.status")}
        value={status}
        onChange={setFilter}
        multiple
        showAll
      />

      {!isCustomer && (
        <CustomerSelect
          id={"customer"}
          label={localizedString("title.customer")}
          className={"mt-xs"}
          value={customer}
          onChange={setFilter}
          multiple
          showAll
        />
      )}

      <LocationSelect
        id={"shipTo"}
        label={localizedString("title.ship.to")}
        className={"mt-xs"}
        value={shipTo}
        onChange={setFilter}
        filter={{ type: LocationType.SHIP_TO }}
        showCustomerCodes
        multiple
        showAll
      />

      {!isCustomer && (
        <UserSelect
          id={"accountManager"}
          label={localizedString("title.account.manager")}
          className={"mt-xs"}
          value={accountManager}
          onChange={setFilter}
          byPermissions={internalUsersPermissions}
          multiple
          showAll
        />
      )}

      <ProductSelectPublic
        id={"product"}
        label={localizedString("title.product")}
        className={"mt-xs"}
        value={product}
        onChange={setFilter}
        multiple
        showAll
      />

      {!isCustomer && (
        <ProductGroupsSelectPublic
          id={"productGroup"}
          label={localizedString("title.product.group")}
          className={"mt-xs"}
          value={productGroup}
          onChange={setFilter}
          multiple
          showAll
        />
      )}

      <PortSelect
        id={"port"}
        label={localizedString("title.destination")}
        className={"mt-xs"}
        value={port}
        onChange={setFilter}
        multiple
        showAll
      />

      {isCustomer && (
        <IncotermSelect
          id={"incoterm"}
          label={"Incoterm"}
          className={"mt-xs"}
          value={incoterm}
          onChange={setFilter}
          multiple
          showAll
        />
      )}

      {isInternalUser && (
        <>
          <div className={"text-sm color-text-2 text-left mt-xs"}>
            <LocalizedMessage id={"title.value"} />
          </div>
          <div className={"flex-row align-center mt-xxs"}>
            <TextField
              id={"valueFrom"}
              type={"number"}
              hideArrows
              value={valueFrom}
              onChange={setFilter}
              max={999999999}
            />
            &nbsp;{"-"}&nbsp;
            <TextField
              id={"valueTo"}
              type={"number"}
              hideArrows
              value={valueTo}
              onChange={setFilter}
              max={999999999}
            />
          </div>

          <div className={"text-sm color-text-2 text-left mt-xs"}>
            <LocalizedMessage id={"title.validity.date"} />
          </div>
          <div className={"flex-row align-center mt-xxs"}>
            <DatePicker
              id={"validityDateFrom"}
              date={validityDateFrom}
              onChange={setFilter}
            />
            &nbsp;{"-"}&nbsp;
            <DatePicker
              id={"validityDateTo"}
              date={validityDateTo}
              onChange={setFilter}
              endOfDay
            />
          </div>
        </>
      )}

      <div className={"ButtonsContainer mt-sm"}>
        <Button view={"text"} onClick={clearParameters}>
          <LocalizedMessage id={"title.clear"} />
        </Button>
      </div>

      <div className={"ButtonsContainerMobile mt-sm"}>
        <Button className={"ApplyButton"} onClick={showHideFilters}>
          <LocalizedMessage id={"title.apply"} />
        </Button>
        <Button
          id={MOBILE_CLEAR_BUTTON_ID}
          view={"text"}
          onClick={clearParameters}
        >
          <LocalizedMessage id={"title.clear"} />
        </Button>
      </div>
    </div>
  );
};

export default Filters;
