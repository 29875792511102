import {CompanyFilters, useCompanies} from "~/Data/Companies/Companies";
import API, {Company} from "~/API";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";

export const CompanyCombobox = <T,>(
  props: {
    filters?: CompanyFilters
  } & ExtendDataCombobox<T>,
) => (
  <ApiDataCombobox<T, any, Company>
    {...props}
    getLabel={(c) => `${c.code} - ${c.legalName}`}
    getId={(c) => c.id}
    useListData={useCompanies}
    getSingle={(id) => API.getCompany({ id })}
    filters={{ query: "ACTIVE == true", ...props.filters }}
  />
);
