import API, {LoginResponse, GroupPermission} from '~/API';
import { setLastUserId, saveUserPreferences } from '~/Services/UserPreferences';
import {Locale, setLocale} from "~/Locales";
import { requestNotificationsPermission } from "~/Utils/Notifications";
import LocalStorage from "~/Services/LocalStorage";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import * as Sentry from "@sentry/react";
import {FC, ReactNode} from "react";

export const ACTIONS_UPDATE_CURRENT_USER_SUCCESS = 'ACTIONS_UPDATE_CURRENT_USER_SUCCESS';
export const ACTIONS_SIGN_IN_SUCCESS = 'ACTIONS_SIGN_IN_SUCCESS';
export const ACTIONS_USER_LOG_OUT = 'ACTIONS_USER_LOG_OUT';

// hooks
export const useIsAdmin = () => {
  const user = useCurrentUser();
  return userHasAccessCMSPermission(user);
};

export const useIsCustomer = () => {
  const currentUser = useCurrentUser();
  return (userHasCanBeCustomerPermission(currentUser));
};

export const useIsInternalCustomer = () => {
  const currentUser = useCurrentUser();
  return (userIsInternalCustomer(currentUser));
};

export const useIsVendor = () => {
  const currentUser = useCurrentUser();
  return (userHasCanBeVendorPermission(currentUser));
};

export const useIsExternalUser = () => {
  const currentUser = useCurrentUser();
  return (userHasCanBeCustomerPermission(currentUser) || userHasCanBeVendorPermission(currentUser));
};

export const useIsUFPBuyer = () => {
  const currentUser = useCurrentUser();
  return userHasUFPBuyerPermission(currentUser);
};

export const useIsUFPAM = () => {
  const currentUser = useCurrentUser();
  return userHasUFPAMPermission(currentUser);
};

export const useIsLogistics = () => {
  const currentUser = useCurrentUser();
  return (userHasCanBeLogisticsPermission(currentUser));
};

const currentUserSelector = (state: RootState) => state.userReducer;
export const useCurrentUser = () => useAppSelector(currentUserSelector);

export const useIsInternalUser = () => {
  const currentUser = useCurrentUser();
  return hasAnyOfInternalPermissions(currentUser?.permissions);
};

export const useHasPermissions = (permissions: GroupPermission[], all?: boolean) => {
  const currentUser = useCurrentUser();
  if (all)
    return permissions.every(p => currentUser.permissions.includes(p));
  return permissions.some(p => currentUser.permissions.includes(p));
};

export const HasPermissions: FC<{
  permissions: GroupPermission[];
  all?: boolean;
  children: ReactNode;
}> = (props) => {
  const hasPermission = useHasPermissions(props.permissions, props.all);
  if (hasPermission)
    return props.children;
  return null;
}

export const isCurrentUserInternal = (currentUser: UserReducerState) => {
  return hasAnyOfInternalPermissions(currentUser?.permissions);
};

// internal users
const userHasUFPBuyerPermission = (user: UserReducerState) => hasUFPBuyerPermission(user?.permissions);
const userHasUFPAMPermission = (user: UserReducerState) => hasUFPAMPermission(user?.permissions);
const userHasCanBeLogisticsPermission = (user: UserReducerState) => hasLogisticsPermission(user?.permissions);
export const userHasAccessCMSPermission = (user: UserReducerState) => hasAdminPermission(user?.permissions);

const hasAdminPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_ADMIN);
const hasUFPAMPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_SELLER);
const hasUFPBuyerPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_BUYER);
const hasLogisticsPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_LOGISTICS);

// external users
const userHasCanBeCustomerPermission = (user: UserReducerState) => hasCustomerPermission(user?.permissions);
const userHasCanBeVendorPermission = (user: UserReducerState) => hasVendorPermission(user?.permissions);
export const userIsInternalCustomer = (user: UserReducerState) =>
  (user.isCompanyInternal && !hasAnyOfInternalPermissions(user?.permissions));

const hasCustomerPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_CUSTOMER);
const hasVendorPermission = (permissions: Array<GroupPermission>) => !!permissions?.includes(GroupPermission.CAN_BE_VENDOR);

export const hasAnyOfInternalPermissions = (permissions: Array<GroupPermission>) => {
  return (hasAdminPermission(permissions) || hasUFPAMPermission(permissions)
    || hasUFPBuyerPermission(permissions) || hasLogisticsPermission(permissions));
};

export const getCurrentUser = (onSuccess?: (user: LoginResponse) => void, onError?: (error: any, status: number) => void) => (dispatch: AppDispatch) => {
  API.getSelf()
      .then((user) => {
        API.setToken(user.token);
        API.getWs().connect();
        dispatch({ type: 'ACTIONS_GET_SELF_SUCCESS', user });
        setLocale(user.language.toLowerCase() as Locale);
        setLastUserId(user.id);
        saveUserPreferences(user);
        requestNotificationsPermission();
        onSuccess && onSuccess(user);
      })
      .catch((error) => {
        dispatch({ type: 'ACTIONS_GET_SELF_ERROR', error, code: error.status });
        onError && onError(error, error.status);
      });
};

const init = () => {
  try {
    const dataText = sessionStorage.getItem('LoginAsUser');
    if (dataText) {
      const user = JSON.parse(dataText);
      API.setToken(user.token);
      return { ...user, loginAsUser: true };
    }
  } catch (e) {
    console.error(e);
  }

  try {
    const loginAsUser = LocalStorage.getItem('LoginAsUser');
    if (loginAsUser) {
      sessionStorage.setItem('LoginAsUser', loginAsUser);
      LocalStorage.setItem('LoginAsUser', '');
      const user = JSON.parse(loginAsUser);
      API.setToken(user.token);
      return { ...user, loginAsUser: true, loginAsUserFailed: false };
    }
  } catch (e) {
    console.error(e);
    return { loginAsUserFailed: true };
  }
  return {};
};

export interface UserReducerState extends LoginResponse {
  loginAsUser: boolean;
  loginAsUserFailed: boolean;
}

// reducer
const INITIAL_STATE: UserReducerState = init();

const reducer = (state: UserReducerState, action: any): UserReducerState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ACTIONS_GET_SELF_SUCCESS':
    case ACTIONS_SIGN_IN_SUCCESS: {
      if (state.loginAsUser && state.id === action.user.id)
        return { ...action.user, loginAsUser: true };

      if (action.user) {
        Sentry.setUser({
          id: action.user.id,
          email: action.user.email,
          username: `${action.user.name} ${action.user.surname}`,
        });
      }

      return action.user;
    }

    case 'ACTIONS_GET_SELF_ERROR':
      return {
        ...state,
        loginAsUserFailed: true
      }

    case ACTIONS_UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        ...action.user,
      };

    case 'ACTIONS_USER_SIGNUP_SUCCESS':
      return {
        ...state,
        email: action.email,
      };

    case ACTIONS_USER_LOG_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
