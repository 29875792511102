import { PureComponent } from 'react';
import { classNames } from "react-ui-basics/Tools";
import { ShowPassIcon, HidePassIcon, CloseIcon, CancelIcon, ArrowUpIcon, ArrowDownIcon } from '~/Components/Old/Icons';
import './TextField.scss';


/**
 * @Deprecated new class at `~/Components/UI/Input`
**/
export default class TextField extends PureComponent {
  state = {
    showError: false,
    showPassword: false,
    shakeLabel: false,
    delayBeforeShaking: false
  };

  inputValue = '';

  componentDidMount() {
    const { type, max, float, allowRange, allowSpecCharacters } = this.props;
    this.inputValue = this.props.value ? String(this.props.value) : '';
    if (type === 'number') {
      if (allowSpecCharacters) {
        const regExp = /^([\d\s/-]?)+$/;
        this.setInputFilter(this.inputRef, value => regExp.test(value));
      } else {
        const length = (max && String(max).length) || 4;
        let decimalPart = '';
        if (!!float) {
          const decimalPartSize = typeof float === 'number' ? float : 2;
          decimalPart = `(\\.${(new Array(decimalPartSize + 1)).join('\\d{0,1}')})?`;
        }
        const numberRegExp = `\\d{0,${length}}${decimalPart}`;
        let regExp = new RegExp(`^${numberRegExp}$`); // Allow digits only, using a RegExp
        if (allowRange) regExp = new RegExp(`^${numberRegExp}( *- *${numberRegExp})?$`);
        const negativeRegExp = /^-?1?$/;
        this.setInputFilter(this.inputRef, value => regExp.test(value) || negativeRegExp.test(value));
      }
    }
    this.inputLabel?.addEventListener('animationend',
      () => {
        this.setState({
          shakeLabel: false,
          delayBeforeShaking: false
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.inputValue = this.props.value ? String(this.props.value) : '';
      this.jiggleIfInvalid();
    }
  }

  setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  changeNumberValue = (increase = true) => {
    const { min, max } = this.inputRef;
    const canChangeValue = !isNaN(this.inputRef.value) && !isNaN(parseFloat(this.inputRef.value));
    let newValue = '';
    if (canChangeValue) {
      const numberValue = parseFloat(this.inputRef.value);
      if (increase) {
        newValue = String(max ? Math.min(numberValue + 1, max) : numberValue + 1);
      } else {
        newValue = String(min ? Math.max(numberValue - 1, min) : numberValue - 1);
      }
    } else {
      newValue = String(min ? min : 0);
    }
    this.onChange({ target: { id: this.inputRef.id, value: newValue } });
  };

  onInvalid = () => this.setState({ showError: true });

  onChange = (event) => {
    if (event.target.type === "text") {
      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
      });
    }

    this.inputValue = event.target.value;
    this.setState({ showError: false });
    this.props.onChange && this.props.onChange(event);
  };

  jiggleIfInvalid = (shouldDelay) => {
    if (this.props.required && this.inputValue.trim() === '') {
      this.setState({
        showError: true,
        shakeLabel: true,
        delayBeforeShaking: !!shouldDelay
      });
    } else {
      this.setState({
        showError: false,
        shakeLabel: false,
      });
    }
  };

  onBlur = (event) => {
    this.jiggleIfInvalid();
    this.props.onBlur?.(event);
  };

  render() {
    const { showClearButton, inputRef, hideDisabledStyle, multiline, hideArrows, onClick, onClear,
      float, allowSpecCharacters, inputStyle, rightButton, ...inputProps } = this.props;
    const { className, style, label, errorLabel, required, type, value, disabled } = this.props;
    const { showError, shakeLabel, delayBeforeShaking } = this.state;

    this.inputValue = typeof value === 'string' ? value : this.inputValue;

    const mainContainerStyle = classNames('TBTextFieldMainContainer', className);

    const showErrorLabel = required && errorLabel && showError;
    const showErrorButton = required && showError && type !== 'password' && this.inputValue.length !== 0;

    const inputContainerClassName = classNames('TBTextFieldInputContainer');
    const inputClassName = classNames('TBTextFieldInput',
      multiline && 'TBTextFieldTextArea',
      showError && 'TBTextFieldInputErrorStyle',
      (disabled && !hideDisabledStyle) && 'TBTextFieldInputDisabled',
      (type === 'password' || (type === 'number' && !hideArrows) || showErrorButton || !!rightButton) && 'TBTextFieldInputContainerRButton');
    const labelClassName = classNames('text-sm TBTextFieldLabel',
      required && 'required', showError && 'invalid', shakeLabel && 'shake', shakeLabel && !delayBeforeShaking && 'withoutDelay');

    inputProps.value = !inputProps.value && inputProps.value !== undefined ? '' : inputProps.value;

    let correctType = type;
    if (type === 'number' || this.state.showPassword) correctType = 'text';
    
    return (
      <div className={mainContainerStyle} style={style}>
        {label &&
          <div ref={label => this.inputLabel = label}
            className={labelClassName}>
            {label}
          </div>
        }
        <div className={inputContainerClassName}
          onClick={this.props.onClick}
        >
          {multiline &&
            <textarea rows={5} {...inputProps} className={inputClassName} style={inputStyle}
              type={correctType}
              ref={ref => {
                this.inputRef = ref;
                if (typeof inputRef === 'function') inputRef(ref);
                if (typeof inputRef === 'object') inputRef.current = ref;
              }}
              onChange={this.onChange}
              onInvalid={this.onInvalid}
              onBlur={this.onBlur}
            />
          }
          {!multiline &&
            <input {...inputProps} className={inputClassName} style={inputStyle}
              type={correctType}
              ref={ref => {
                this.inputRef = ref;
                if (typeof inputRef === 'function') {
                  inputRef(ref);
                } else if (!!inputRef) {
                  inputRef.current = ref;
                }
              }}
              onChange={this.onChange}
              onInvalid={this.onInvalid}
              onBlur={this.onBlur}
            />
          }

          {<div className={'TBTextFieldRightButtonContainer mr-xxs'}>
            {rightButton}
          </div>}

          {showClearButton &&
            <CloseIcon size={18} className={'TBTextFieldRightButtonContainer TBTextClearButton'}
              onClick={() => {
                const oldValue = this.inputRef.value;
                this.inputRef.value = '';
                this.onChange({ target: this.inputRef });
                this.props.onClear?.(oldValue);
              }}
            />
          }

          {type !== 'password' && type !== 'number' && showErrorButton && !showClearButton &&
            <CancelIcon size={24} className={'TBTextFieldRightButtonContainer ErrorColor'}
              onClick={() => {
                this.inputRef.value = '';
                this.onChange({ target: this.inputRef });
              }}
            />
          }

          {type === 'password' && this.state.showPassword &&
            <HidePassIcon size={24} className={'TBTextFieldRightButtonContainer'}
              onClick={() => this.setState({ showPassword: !this.state.showPassword })}
            />
          }

          {type === 'password' && !this.state.showPassword &&
            <ShowPassIcon size={24} className={'TBTextFieldRightButtonContainer'}
              onClick={() => this.setState({ showPassword: !this.state.showPassword })}
            />
          }

          {type === 'number' && !hideArrows &&
            <div className={'TBTextFieldNumberArrowsContainer'}>
              <div className={classNames('TBTextFieldNumberArrow', disabled && 'TBTextFieldNumberArrowDisabled')}
                onMouseDown={(event) => {
                  event.preventDefault();
                  clearInterval(this.arrowCycle);
                  this.changeNumberValue(true);
                  this.cycleTimeout = setTimeout(() => {
                    this.arrowCycle = setInterval(() => this.changeNumberValue(true), 100);
                  }, 500);
                }}
                onMouseUp={(event) => {
                  event.preventDefault();
                  clearTimeout(this.cycleTimeout);
                  clearInterval(this.arrowCycle);
                }}
                onMouseOut={() => {
                  clearTimeout(this.cycleTimeout);
                  clearInterval(this.arrowCycle);
                }}
              >
                <ArrowUpIcon size={24} />
              </div>
              <div className={classNames('TBTextFieldNumberArrow', disabled && 'TBTextFieldNumberArrowDisabled')}
                onMouseDown={(event) => {
                  event.preventDefault();
                  clearInterval(this.arrowCycle);
                  this.changeNumberValue(false);
                  this.cycleTimeout = setTimeout(() => {
                    this.arrowCycle = setInterval(() => this.changeNumberValue(false), 100);
                  }, 500);
                }}
                onMouseUp={(event) => {
                  event.preventDefault();
                  clearTimeout(this.cycleTimeout);
                  clearInterval(this.arrowCycle);
                }}
                onMouseOut={() => {
                  clearTimeout(this.cycleTimeout);
                  clearInterval(this.arrowCycle);
                }}
              >
                <ArrowDownIcon size={24} />
              </div>
            </div>
          }
        </div>
        {showErrorLabel &&
          <div className={'text-sm TBTextFieldErrorLabel'}>{errorLabel}</div>
        }
      </div>
    );
  }
}
