import React, { Component } from 'react';
import Button from "./Button";
import IconButton from "./IconButton";
import { CloseIcon } from './Icons';
import './DeleteDialog.scss';

/**
 * @Deprecated new dialog at `~/Components/UI/Dialogs/DeleteDialog`
**/
export default class DeleteDialog extends Component {
  onDelete = () => {
    this.props.onDelete?.();
  };

  onClose = () => {
    this.props.onClose?.();
  };

  onDiscard = () => {
    if (this.props.onDiscard) {
      this.props.onDiscard();
    } else {
      this.onClose();
    }
  };

  render() {
    const { title, description, description2, confirmTitle, closeTitle, onDelete, onDiscard, children } = this.props;

    return (
      <div className={'DeleteDialogMainContainer'} onClick={(event => event.stopPropagation())}>
        <div className={'ScrollContainer'}>
          <div className={'DialogContainer'}>
            <Button className={'CloseIcon'} onClick={this.onClose}>
              <CloseIcon size={16} />
            </Button>

            <div className={'text-xl text-bold'}>{title}</div>
            {description && <div className={'mt-lg'}>{description}</div>}
            {description2 && <div>{description2}</div>}

            <div>{children}</div>

            {(!!onDelete || !!onDiscard) &&
              <div className={'ButtonsContainer mt-lg'}>
                <Button view={'secondary'} onClick={this.onDiscard}>{closeTitle}</Button>
                <Button className={'DeleteDialogDeleteButton'} onClick={this.onDelete}>{confirmTitle}</Button>
              </div>
            }
          </div>
        </div>

        <div className={'DialogContainerMobile'}>
          <div className={'ModalTitleContainer'}>
            <div className={'text-xl'}>{title}</div>
            <IconButton onClick={this.onClose}>
              <CloseIcon size={24} />
            </IconButton>
          </div>

          <div className={'ScrollContainerMobile'}>
            {description && <div className={'text-lg'}>{description}</div>}
            {description2 && <div className={'text-lg'}>{description2}</div>}

            {(!!onDelete || !!onDiscard) &&
              <div className={'mt-lg'}>
                <Button view={'secondary'} className={'mt-md w-full'} onClick={this.onDiscard}>{closeTitle}</Button>
                <Button className={'w-full DeleteDialogDeleteButton'} onClick={this.onDelete}>{confirmTitle}</Button>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
