import { FC, ReactNode } from "react";
import {
    LocalizedMessage,
    LocalizedMessageKey,
} from "~/Locales";
import { Dialog } from "./Dialog";
import { Button } from "~/Components/UI";
import { useMutate, useToggle } from "~/Hooks";

export const ConfirmDialog: FC<{
    onConfirm: () => Promise<void>;
    confirmText?: LocalizedMessageKey;
    title: LocalizedMessageKey;
    trigger: ReactNode;
    children: ReactNode;
}> = (props) => {
    const confirmCall = useMutate(props.onConfirm);
    const open = useToggle(false);

    const onConfirmClicked = () => confirmCall.mutate().then(open.toggleFalse);

    return (
        <Dialog
            open={open}
            trigger={props.trigger}
            title={<LocalizedMessage id={props.title} />}
        >
            <div className="desktop-w-min-9xl flex-col">
                {props.children}

                <div className="flex-row justify-end gap-sm mt-2xl">
                    <Button
                        label={props.confirmText || "phrase.confirm.dialog.button.title"}
                        onClicked={onConfirmClicked}
                        loading={confirmCall.loading}
                        color="blue"
                        dataCy="DialogConfirmButton"
                    />
                    <Button
                        label="title.cancel"
                        onClicked={open.toggleFalse}
                        disabled={confirmCall.loading}
                        color="gray"
                        dataCy="DialogCancelButton"
                    />
                </div>
            </div>
        </Dialog>
    );
};
