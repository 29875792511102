import {useState} from "react";
import {FormReturn, useFormData} from "~/Hooks/useFormData";
import {MutateReturn, useMutate} from "~/Hooks/useMutate";

export interface FormRowEditHook<T> {
  rowIndex: number | null;
  setRowIndex: (id: any, data: T) => void;
  form: FormReturn<Partial<T>>;
  submit: MutateReturn<any, []>
  reset: () => void;
  idColumn: keyof T & string;
}

export const useFormRowEdit = <T,>(
  idColumn: keyof T & string,
  save: (data: T) => Promise<any>,
  options?: {
    onChange?: (oldData: T, newData: T) => T;
  }
): FormRowEditHook<T> => {
  const [rowIndex, setEditableRowIndex] = useState<any | null>(null);
  const form = useFormData<Partial<T>>({}, options);

  // set the index and fill the form with the current data
  const setRowIndex = (id: any, data: T) => {
    setEditableRowIndex(id);
    form.setData(data);
  };

  const reset = () => {
    setEditableRowIndex(null);
    form.setData({}, true);
  }

  const submit = useMutate(async () => {
    await save(form.data as T);
    reset();
  });

  return {
    rowIndex,
    setRowIndex,
    form,
    reset,
    submit,
    idColumn
  };
}