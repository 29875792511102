import { useEffect, useMemo } from "react";
import { useLocation } from "~/Reducers/Locations";
import { getUserPublic, useUserLabels, useUsers } from "~/Reducers/Users";
import Select from "./Select";
import {useAppDispatch} from "~/StoreTypes";


const UserByLocationSelect = ({ locationId, ...props }) => {
  const dispatch = useAppDispatch();
  const location = useLocation(locationId);
  const users = useUsers();
  const labels = useUserLabels();

  useEffect(() => {
    if (Array.isArray(props.value)) {
      props.value.forEach(userId => {
        if (!users[userId]) dispatch(getUserPublic(userId));
      });
    } else {
      if (!users[props.value]) dispatch(getUserPublic(props.value));
    }
  }, [props.value]);

  const keys = useMemo(() => location?.users || [], [location]);

  useEffect(() => {
    keys.forEach(userId => dispatch(getUserPublic(userId)));
  }, [keys]);

  const keysToShow = useMemo(() => keys.filter(key => !users[key]?.deleted), [keys, users]);

  return (
    <Select {...props}
      data={keysToShow}
      labels={labels}
    />
  );
};

export default UserByLocationSelect;
