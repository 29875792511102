import {
  orderStatuses,
  orderStatusLabels,
} from "~/Data/Orders/OrderStatuses";
import {FormCombobox, FormComboboxProps} from "~/Components/UI";

const OrderStatusSelect= <T,>(
    props: Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  return (
    <FormCombobox
      {...props}
      data={orderStatuses}
      getLabel={(val) => orderStatusLabels[val]}
    />
  );
};

export default OrderStatusSelect;
