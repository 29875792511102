import API, { Translation } from "~/API";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { useIsAdmin } from "~/Reducers/User";
import { PlusIcon } from "~/Components/Old/Icons";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ActiveField,
  Button,
  Dialog, editControlsColumn,
  FormCheckbox,
  FormCombobox,
  FormInput,
  Table,
} from "~/Components/UI";
import DateView from "~/Components/Views/DateView";
import { TbTrash } from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import {
  params,
  useDebounce,
  useFormData,
  useMutateHook,
  usePageTitle,
  useToggle,
} from "~/Hooks";
import { ls } from "~/Locales";
import useSWRInfinite from "swr/infinite";
import { AddTranslationForm } from "./AddTranslationForm";
import * as z from "zod";
import { DeletePopover } from "~/Components/DeletePopover";
import useSWRImmutable from "swr/immutable";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {trackEvent} from "~/Services/Tracking";
import {useSortState} from "~/Hooks/useSortState";
import {trimUndefined} from "~/Utils";

const getTranslationListKey = (
  filters: { search: string; property: string },
  offset: number,
  previousPageData: Translation[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/translations", ...filters, offset };
};

const PAGE_SIZE = 40;

const useDeleteTranslation = (id: number) =>
  useMutateHook<void, void, Translation>("api/translations", () =>
    API.deleteTranslation({ id }),
  );

const useTranslations = (filters: any) =>
  useSWRInfinite(
    (i, p) => getTranslationListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getTranslations({ ...filters, offset: key.offset, limit: PAGE_SIZE }),
    { revalidateOnFocus: false },
  );

const useTranslationProperties = () =>
  useSWRImmutable("api/translations/properties", API.getTranslationContexts);

const columnHelper = createColumnHelper<Translation>();

const getColumns = (refreshData: () => void) => [
  columnHelper.accessor("suggest", {
    header: ls("title.active"),
    size: 0,
    sortId: "suggest",
    cellMemo: ({ value }) => (
      <ActiveField active={value} />
    ),
    editCell: ({ editRow }) => (
      <FormCheckbox
        id="suggest"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("context", {
    header: ls("title.property"),
    sortId: "context",
  }),
  columnHelper.accessor("en", {
    header: ls("title.value"),
    sortId: "en",
    editCell: ({ editRow }) => (
      <FormInput
        id="en"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("dateUpdated", {
    header: ls("title.date.last.updated"),
    size: 0,
    sortId: "date_updated",
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
  editControlsColumn(columnHelper),
  columnHelper.display({
    id: "display",
    header: "",
    size: 24,
    cellMemo: ({ row }) => {
      const deleteTranslation = useDeleteTranslation(row.id);

      return (
        <div className="flex-row gap-xxs">
          <DeletePopover
            align="end"
            deleteCall={() => deleteTranslation.call().then(() => {
              refreshData();
              trackEvent("Delete Dictionary Value");
            })}
            trigger={
              <Button
                color="red"
                leftIcon={<TbTrash size={16} />}
                variant="text"
              />
            }
          >
            Are you sure you want to delete "{row.en}"?
          </DeletePopover>
        </div>
      );
    },
  }),
];

const routeParser = z.object({
  search: params.string,
  property: params.string,
});

export const DictionaryTab: FC = () => {
  usePageTitle("Dictionary");

  const isAdmin = useIsAdmin();

  const filterForm = useFormData<{
    search?: string;
    property?: string;
  }>({ search: "" }, { routeParser });
  const searchDebounce = useDebounce(filterForm.data.search);
  const sort = useSortState();

  const translations = useTranslations(trimUndefined({
    search: searchDebounce,
    property: filterForm.data.property || "",
    ...sort.data,
  }));

  const scrollRef = useRef(null);
  useEffect(() => {
    translations.setSize(1).then();
    scrollRef.current?.scrollTo?.(0, 0);
  }, [searchDebounce, filterForm.data.property]);

  const onScrolledToBottom = () => translations.setSize(translations.size + 1);

  const data = useMemo(
    () => (translations.data || []).flat(),
    [translations.data],
  );

  const editRow = useFormRowEdit<Translation>("id", async (data) => {
    await API.updateTranslation(
      { id: data.id },
      data as Translation,
    );
    await translations.mutate();
    trackEvent("Dictionary Value Updated");
  });

  const addOpen = useToggle();

  const contexts = useTranslationProperties();

  const columns = useMemo(
    () => getColumns(translations.mutate),
    [translations.mutate],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editRow,
    },
    initialState: {
      columnVisibility: {
        edit: isAdmin,
      },
      columnPinning: {
        right: ["edit", "delete"],
      },
    },
  });

  return (
    <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
      {editRow.rowIndex != null && <UnsavedChangesDialog onSave={editRow.submit.mutate} />}
      <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
        {isAdmin && (
          <Dialog
            title={ls("title.add.value")}
            open={addOpen}
            trigger={
              <Button
                leftIcon={<PlusIcon size={16} />}
                label="title.add.value"
              />
            }
          >
            <AddTranslationForm
              refreshData={translations.mutate}
              open={addOpen}
              contexts={contexts.data || []}
            />
          </Dialog>
        )}

        <FormInput
          id="search"
          formData={filterForm}
          placeholder="title.search"
          type="search"
          className="desktop-w-min-9xl"
          showClear
        />

        <FormCombobox
          formData={filterForm}
          id="property"
          data={contexts.data || []}
          placeholder="title.property"
          getLabel={(d) => d}
          className="desktop-w-min-8xl"
          showClear
          align="end"
        />
      </div>

      <LinearLoader loading={translations.isValidating} />
      <Table
        table={table}
        scrollRef={scrollRef}
        onScrolledBottom={onScrolledToBottom}
        sort={sort}
      />
    </div>
  );
};

export default DictionaryTab;
