import API, {CreateDocumentRequest, DocumentType, DocumentView, DocumentViewSource,} from "~/API";
import {FC, useMemo} from "react";
import {LC, LocalizedMessageKey, ls,} from "~/Locales";
import {useIsInternalUser} from "~/Reducers/User";
import OrderDocumentsUploadDialog, {DocumentTypeSelect} from "./OrderDocumentsUploadDialog";
import {useToggle} from "~/Hooks";
import {Button, DeleteDialog, editControlsColumn, Table} from "~/Components/UI";
import {TbEye, TbTrash, TbUpload} from "react-icons/tb";
import {DateTimeView} from "~/Components/Views/DateView";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {useSalesOrderDocumentCreate, useSalesOrderDocumentDelete} from "~/Data/Documents/SalesOrderDocuments";
import {usePurchaseOrderDocumentCreate, usePurchaseOrderDocumentDelete} from "~/Data/Documents/PurchaseOrderDocuments";
import {LinearLoader} from "~/Components/Loaders";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import Toast from "~/Components/Toast";
import useSWR from "swr";
import {cn} from "~/Utils";

const OrderDocuments: FC<{
  orderId: number;
  isSales?: boolean;
}> = ({ orderId, isSales }) => {
  const isInternalUser = useIsInternalUser();
  const showDocuments = useToggle();

  const orderDocuments = useSWR(showDocuments.value ? ["api/orders/documents", orderId, isSales] : null, async () => {
    if (isSales)
      return API.getSalesOrderDocuments({ id: orderId });
    return API.getPurchaseOrderDocuments({ id: orderId });
  }, {
    revalidateOnFocus: false,
  });

  const documents = useMemo(() => {
    const docs = orderDocuments.data || [];
    return docs
      .filter((it) => it.active)
      .sort(
        (a, b) =>
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
      );
  }, [orderDocuments.data]);

  const editRow = useFormRowEdit<DocumentView>("id", async (data) => {
    try {
      await API.editOrderDocument({}, data);
      await orderDocuments.mutate();
    } catch (e) {
      Toast.ApiError(e);
      throw e;
    }
  });

  const columns = useMemo(
      () => createOrderDocumentColumns(isSales, orderDocuments.mutate),
      [isSales, orderDocuments.mutate]
    );

  const table = useReactTable({
    data: documents,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: { actions: isInternalUser }
    },
    meta: {
      editRow
    }
  });

  const salesOrderCreate = useSalesOrderDocumentCreate(orderId);
  const purchaseOrderCreate = usePurchaseOrderDocumentCreate(orderId);
  const onSave = async (attachments: any[]) => {
    try {
      const attachmentRequests: CreateDocumentRequest[] = attachments.map((attach) => ({
        type: attach.type,
        attachmentId: attach.id,
        number: 1,
      }));

      if (isSales)
        await salesOrderCreate.call({ attachments: attachmentRequests });
      else
        await purchaseOrderCreate.call({ attachments: attachmentRequests });

      await orderDocuments.mutate();
    } catch (error) {
      Toast.ApiError(error);
      throw error; // rethrow to stop the dialog from closing
    }
  };

  return (
    <div className="flex-col p-lg text-left overflow-hidden">
      <div className="flex-row flex-wrap align-baseline justify-between mb-sm">
        <div className="text-xl color-black-1">
          <LC id="title.documents" />
        </div>
        <div className="flex-row align-center">
          {showDocuments.value && (
            <OrderDocumentsUploadDialog
              onSave={onSave}
              trigger={
                <Button
                  color="blue"
                  leftIcon={<TbUpload size={20} />}
                  className="mr-sm"
                  label="title.upload.documents"
                />
              }
            />
          )}
          <Button
            color="gray"
            onClicked={showDocuments.toggle}
            leftIcon={<TbEye size={20} />}
            label={showDocuments.value ? "title.hide.documents": "title.view.documents"}
          />
        </div>
      </div>

      {showDocuments.value && (
        <div className="flex-col overflow-x-auto w-full">
          <LinearLoader loading={orderDocuments.isLoading} />
          <Table table={table} className="w-full" />
        </div>
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<DocumentView>();

export const createOrderDocumentColumns = (isSales: boolean, refreshDocuments: () => Promise<any>, canEdit: boolean = true) => [
  columnHelper.display({
    id: "documentName",
    size: 1024,
    header: () => ls("title.document"),
    cellMemo: ({ row }) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          row.source == DocumentViewSource.TB ? API.urlAttachmentOpen(row.attachmentId) : row.url
        }
        className={cn(row.type == DocumentType.DRAFT && "color-black-6")}
      >
        <LC
          id={`contract.docs.name.${row.type}` as LocalizedMessageKey}
        />
        {row.name && <>&nbsp;({row.name})</>}
      </a>
    ),
    editCell: ({ editRow }) => (
      <div className="text-no-wrap flex-row gap-sm align-center">
        <DocumentTypeSelect
          value={editRow.form.data.type}
          checkSellerOnlyFlag={true}
          onChange={(_id, value) => editRow.form.onDataChange("type", value)}
        />
        <span>
          {editRow.form.data.name}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("dateUpdated", {
    header: () => ls("title.update.date"),
    cellMemo: ({ value }) => (
      <span className="text-no-wrap">
        <DateTimeView date={value} />
      </span>
    ),
    size: 0,
  }),
  ...(canEdit ? [
    editControlsColumn(columnHelper, (row) => row.url == undefined), // only allow editing on TB docs
    columnHelper.display({
      id: "actions",
      size: 0,
      cell: ({ row: { original } }) => {
        const deleteSalesOrderDocs = useSalesOrderDocumentDelete(original.id);
        const deletePurchaseOrderDocs = usePurchaseOrderDocumentDelete(original.id);

        const onConfirmDelete = async () => {

          if(isSales)
            await deleteSalesOrderDocs.call({ docId: original.id });

          await deletePurchaseOrderDocs.call({ docId: original.id });
          await refreshDocuments();
        };

        return (
          <div>
            {original.source == DocumentViewSource.TB && (
              <DeleteDialog
                title="title.delete.document"
                onDelete={onConfirmDelete}
                trigger={
                  <Button
                    variant="text"
                    color="red"
                    leftIcon={<TbTrash size={16} />}
                  />
                }
              >
                <div className="text-bold">
                  <LC id="phrase.document.delete.alert.1" />
                </div>
                <div>
                  <LC id="phrase.document.delete.alert.2" />
                </div>
              </DeleteDialog>
            )}
          </div>
        )
      },
    }),
  ] : [])
];

export default OrderDocuments;
