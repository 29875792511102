import { FC, useMemo } from "react";
import {
  ActiveField,
  Button,
  DropdownButton,
  FormCheckbox,
  FormInput, FormSwitch,
  Table,
  TableCell,
} from "~/Components/UI";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import { LC, ls } from "~/Locales";
import {params, useDebounce, useFormData, usePageTitle, useToggle} from "~/Hooks";
import {TbFilter, TbTrash} from "react-icons/tb";
import {
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import API, {CompanyUsersView, GroupPermission, Order} from "~/API";
import {CompanyFilters, useCompanies} from "~/Data/Companies/Companies";
import { LinearLoader } from "~/Components/Loaders";
import {smartJoin, trimUndefined} from "~/Utils";
import {useSortState} from "~/Hooks/useSortState";
import { UserName } from "~/Components/Users/UserName";
import { PercentageView } from "~/Components/Views/PercentageView";
import { companiesRootPath, locationsRootPath } from "~/Services/Routes";
import { useHistory } from "react-router-dom";
import { DeletePopover } from "~/Components/DeletePopover";
import { useIsAdmin } from "~/Reducers/User";
import { useCompanyLocations } from "~/Data/Companies/CompanyLocations";
import Toast from "~/Components/Toast";
import { AddCompanyDialog } from "~/Pages/Companies/Components/AddCompanyDialog";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import {FilterPanel} from "~/Components/Entities/FilterPanel";
import * as z from "zod";

const routeParser = z.object({
  search: params.string,
  onlyMy: params.bool,
  inactive: params.bool,
  buyer: params.numberArray,
  withEmptyBuyersOnly: params.bool,
  orderBy: params.string,
  order: params.enum(Order),
});

export const CompanyTabs: FC<{ type: "customers" | "vendors" }> = (props) => {
  const history = useHistory();
  const filtersOpen = useToggle();
  const filters = useFormData<CompanyFilters>({
    search: "",
    onlyMy: false,
    inactive: false,
    buyer: [],
    withEmptyBuyersOnly: false,
  }, { routeParser });
  const searchDebounce = useDebounce(filters.data.search);
  const isCustomers = props.type == "customers";
  const isAdmin = useIsAdmin();
  usePageTitle(isCustomers ? "Customers" : "Vendors");

  const sort = useSortState(filters);
  const companies = useCompanies(
    trimUndefined({
      ...filters.data,
      search: searchDebounce,
      query: isCustomers ? "TYPE == 'BUYER'" : "TYPE == 'SELLER'",
      ...sort.data,
    }),
    40,
  );
  const data = useMemo(() => companies.data?.flat() || [], [companies.data]);
  const onScrolledToBottom = () => companies.setSize(companies.size + 1);

  const onRowClicked = (row: CompanyUsersView) => {
    history.push(`${companiesRootPath}${row.id}/general`);
  };

  const onLocationClicked = () => {
    history.push(`${companiesRootPath}${props.type}/locations`);
  };

  const onDelete = async (id: number) => {
    try {
      await API.deleteCompany({ id });
      await companies.mutate();
    } catch (e) {
      Toast.ApiError(e);
    }
  };

  const columns = useMemo(() => getColumns(isCustomers, onDelete), [isCustomers, onDelete]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        accountManagerUserId: !isCustomers,
        defaultMarginAsFraction: !isCustomers,
        controls: isAdmin,
      },
      columnPinning: {
        left: ["dropdown"],
      },
    },
  });

  return (
    <div className="flex-row gap-lg flex overflow-hidden">
      <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
        <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
          {isAdmin && (
            <AddCompanyDialog
              isCustomers={isCustomers}
              refreshData={companies.mutate}
            />
          )}

          <FormInput
            id="search"
            formData={filters}
            showClear
            type="search"
            placeholder="title.search"
            className="desktop-w-min-8xl"
          />

          <FormCheckbox
            id="inactive"
            formData={filters}
            label="title.inactive"
          />
          <FormCheckbox
            id="onlyMy"
            formData={filters}
            label={
              isCustomers
                ? "title.show.only.my.customers"
                : "title.show.only.my.vendors"
            }
          />

          <Tabs value="company">
            <TabsList className="mx-sm">
              <TabsTrigger value="company">
                <LC id={isCustomers ? "title.customers" : "title.vendors"} />
              </TabsTrigger>
              <TabsTrigger value="location" onClick={onLocationClicked}>
                <LC id={isCustomers ? "title.ship.tos" : "title.ship.froms"} />
              </TabsTrigger>
            </TabsList>
          </Tabs>

          {!isCustomers && (
            <Button
              label="title.filter"
              onClicked={filtersOpen.toggle}
              color="gray"
              leftIcon={<TbFilter size={16} />}
              className="ml-auto"
            />
          )}
        </div>

        <LinearLoader loading={companies.isValidating} />

        <Table
          table={table}
          onScrolledBottom={onScrolledToBottom}
          onRowClick={onRowClicked}
          renderSubComponent={CompanySubTable}
          sort={sort}
        />
      </div>
      {!isCustomers && (
        <FilterPanel open={filtersOpen}>
          <UserByPermissionCombobox
            id="buyer"
            formData={filters}
            permissions={[GroupPermission.CAN_BE_BUYER]}
            multiple
            showClear
            clearValue={[]}
            placeholder="title.buyer"
          />
          <FormSwitch
            id="withEmptyBuyersOnly"
            formData={filters}
            label="title.blank.buyer"
          />
          <Button
            label="title.clear"
            color="gray"
            onClicked={filters.clear}
          />
        </FilterPanel>
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<CompanyUsersView>();

const getColumns = (isCustomers: boolean, onDelete: (id: number) => Promise<void>) => [
  columnHelper.display({
    id: "dropdown",
    cell: DropdownButton,
    size: 0,
  }),
  columnHelper.accessor("active", {
    header: ls("title.active"),
    cell: (data) => <ActiveField active={data.getValue()} />,
    size: 0,
  }),
  columnHelper.accessor("code", {
    header: ls("title.code"),
    sortId: "code",
    minSize: 100,
  }),
  columnHelper.accessor("legalName", {
    header: ls(isCustomers ? "title.customer.name" : "title.vendor.name"),
    sortId: "legal_name",
    minSize: 250,
    cell: ({ getValue }) => (
      <div className="text-no-wrap">
        {getValue()}
      </div>
    ),
  }),
  columnHelper.display({
    id: "address",
    header: ls("company.address"),
    minSize: 500,
    cell: ({ row: { original } }) => (
      <div className="text-no-wrap">
        {smartJoin(
          [
            original.addressStreet,
            original.addressStreet2,
            original.addressCity,
            original.addressRegion,
            original.addressZip,
            original.addressCountry,
          ],
          ", ",
        )}
      </div>
    ),
    size: 400,
  }),
  columnHelper.accessor("accountManagerUserId", {
    header: ls("title.buyer"),
    sortId: "account_manager_name",
    minSize: 200,
    cell: ({ getValue }) => <UserName id={getValue()} />
  }),
  columnHelper.accessor("defaultMarginAsFraction", {
    header: ls("title.margin"),
    sortId: "default_margin_as_fraction",
    minSize: 200,
    cell: ({ getValue }) => <PercentageView fractionalValue={getValue()} />
  }),
  columnHelper.display({
    id: "controls",
    cell: ({ row: { original } }) => (
      <>
        {!original.code && (
          <DeletePopover
            deleteCall={() => onDelete(original.id)}
            trigger={
              <Button
                color="red"
                variant="text"
                leftIcon={<TbTrash size={16} />}
              />
            }
            align="end"
          >
            Are you sure you want to delete company {original.legalName}?
          </DeletePopover>
        )}
      </>
    ),
    size: 0,
  }),
];

const CompanySubTable: FC<{
  row: Row<CompanyUsersView>;
}> = (props) => {
  const locations = useCompanyLocations(props.row.original.id);
  const history = useHistory();

  const onRowClicked = (id: number) => {
    history.push(`${locationsRootPath}${id}/general`);
  }

  if (locations.isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <LinearLoader loading={locations.isLoading} />
        </td>
      </tr>
    );

  return (
    <>
      {locations.data.map(l => (
        <tr
          className="bg-black-11-5 color-text-2 hover-bg-black-11 cursor-pointer"
          onClick={() => onRowClicked(l.id)}
          key={l.id}
        >
          <TableCell colSpan={2}/>
          <TableCell>
            {l.code}
          </TableCell>
          <TableCell>
            {l.name}
          </TableCell>
          <TableCell className="text-no-wrap">
            {smartJoin(
              [
                l.addressStreet,
                l.addressStreet2,
                l.addressCity,
                l.addressRegion,
                l.addressZip,
                l.addressCountry,
              ],
              ", ",
            )}
          </TableCell>
          <TableCell colSpan={4}/>
        </tr>
      ))}
    </>
  );
}
