import {
  ChangeEventHandler,
  forwardRef,
  HTMLInputAutoCompleteAttribute,
  HTMLInputTypeAttribute,
  KeyboardEventHandler, ReactNode,
  Ref,
  useState
} from "react";
import { LC, ls, LocalizedMessageKey } from "~/Locales";
import { cn } from "~/Utils";
import { FormReturn } from "~/Hooks";
import { Show } from "~/Components/UI";
import { TbX } from "react-icons/tb";

export interface InputProps {
  label?: LocalizedMessageKey;
  placeholder?: LocalizedMessageKey;
  id: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
  containerClassName?: string;
  type?: HTMLInputTypeAttribute;
  autoComplete?: HTMLInputAutoCompleteAttribute;
  error?: string;
  showClear?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  min?: number;
  max?: number;
  step?: string | number;
  rightIcon?: ReactNode;
  required?: boolean;
  dataCy?: string;
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
  const [focus, setFocus] = useState(false);

  const onClearClicked = () => {
    props.onChange?.({
      target: {
        value: "",
        id: props.id,
        name: props.id,
      },
    } as any);
  };

  const onFocus = () => setFocus(true);
  const onBlur = () => {
    setFocus(false);
    let newVal = props.value;
    if(props.max && newVal != "") {
      if(props.type == "number")
        newVal = Math.min(props.max, +props.value).toString();
      else
        newVal = newVal.slice(0, props.max);
    }

    if(props.min && newVal != "") {
      if(props.type == "number")
        newVal = Math.max(props.min, +props.value).toString();
      else
        newVal = newVal.slice(0, props.max);
    }

    if (props.value != newVal)
      props.onChange({ target: { id: props.id, value: newVal } } as any);
  }

  return (
    <div className={cn("flex-col", props.containerClassName)} onClick={(e) => e.stopPropagation()}>
      <Show when={props.label !== undefined}>
        <label
          htmlFor={props.id}
          className="ml-md mb-xxs color-black-6 text-sm"
        >
          <LC id={props.label} />
          {props.required && " *"}
        </label>
      </Show>
      <div
        className={cn(
          "border-1 placeholder-text-italics w-min-0",
          "rounded-xxs flex-row justify-between align-center",
          "h-min-xl",
          props.error
            ? "border-red-1 placeholder-color-red-1"
            : "border-black-9 placeholder-color-black-7",
          focus && "outline-2 outline-blue-1 border-clear",
          !focus && !props.disabled && "hover-bg-black-11-5",
          props.disabled
            ? "bg-black-11 color-text-2"
            : "bg-black-12 color-text-1",
          props.className,
        )}
      >
        <Show when={!props.multiline}>
          <input
            className={cn(
              "bg-none border-none w-full px-sm",
              props.disabled && "color-text-2"
            )}
            type={props.type || "text"}
            id={props.id}
            name={props.id}
            value={props.value}
            onChange={props.onChange}
            placeholder={ls(props.placeholder)}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            onFocus={onFocus}
            onBlur={onBlur}
            aria-disabled={props.disabled}
            tabIndex={props.disabled ? -1 : 0}
            onKeyDown={props.onKeyDown}
            ref={ref as Ref<HTMLInputElement>}
            min={props.min}
            max={props.max}
            step={props.step}
            data-cy={props.dataCy}
          />
        </Show>
        <Show when={props.multiline}>
          <textarea
            className="flex bg-none border-none w-full px-lg resize-none"
            id={props.id}
            name={props.id}
            value={props.value}
            onChange={props.onChange}
            placeholder={ls(props.placeholder)}
            disabled={props.disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            aria-disabled={props.disabled}
            tabIndex={props.disabled ? -1 : 0}
            onKeyDown={props.onKeyDown}
            ref={ref as Ref<HTMLTextAreaElement>}
            data-cy={props.dataCy}
          />
        </Show>
        {props.rightIcon}
        <Show when={props.showClear && props.value !== ""}>
          <TbX
            onClick={onClearClicked}
            className="color-black-7 hover-color-black-4 cursor-pointer mx-xxs"
            size={20}
          />
        </Show>
      </div>
      <Show when={props.error !== undefined}>
        <div className="ml-md mt-xxs color-red-1">{props.error}</div>
      </Show>
    </div>
  );
});

export interface FormInputProps<T = any> extends Omit<InputProps, "value" | "onChange>" | "error" | "id" | "onChange"> {
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormInput = <T = any,>(props: FormInputProps<T> & { inputRef?: Ref<HTMLInputElement | HTMLTextAreaElement> }) => (
  <Input
    value={(props.formData.data[props.id] || "").toString()}
    onChange={props.formData.onChange}
    error={props.formData.errors[props.id]?._errors?.join(", ")}
    ref={props.inputRef}
    required={props.formData.isFieldRequired(props.id, "") || props.required}
    {...props}
  />
);

export interface FormInputRangeProps<T = any> extends Omit<FormInputProps, "id"> {
  idFrom: keyof T & string;
  idTo: keyof T & string;
}

export const FormInputRange = <T = any>(
  props: FormInputRangeProps<T>,
) => {
  return (
    <div className="flex-col">
      <div className="text-left ml-md mb-xxs color-black-6 text-sm">
        <LC id={props.label} />
        {props.formData.isFieldRequired(props.idFrom, "") || props.required && " *"}
      </div>
      <div className="flex-row align-center gap-xxs">
        <FormInput
          {...props}
          label={undefined}
          id={props.idFrom}
          showClear={props.showClear}
          containerClassName="flex"
        />
        <span>-</span>
        <FormInput
          {...props}
          label={undefined}
          id={props.idTo}
          formData={props.formData}
          showClear={props.showClear}
          containerClassName="flex"
        />
      </div>
    </div>
  )
}