import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  acceptVideoCall,
  declineVideoCall,
  useUniqueVideoCallRoomName,
  VideoCallInvitationChatMessageStatus,
  VideoCallInvitationChatMessageText,
} from "~/Reducers/VideoCall";
import { generateVideoCallLink } from "~/Components/Old/VideoCall/VideoCallLink";
import { useCurrentUser } from "~/Reducers/User";
import { useAppDispatch } from "~/StoreTypes";
import { Button } from "~/Components/UI";
import { ChatMessage } from "~/API";
import { UserName } from "~/Components/Users/UserName";

export const ChatMessageVideoCall: FC<{
  message: ChatMessage;
  isMyMessage: boolean;
  hideButtons?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const text = useMemo(
    () => JSON.parse(props.message.text) as VideoCallInvitationChatMessageText,
    [props.message.text],
  );
  const { userIdFrom, userIdTo, status } = text;
  const userId = props.isMyMessage ? userIdTo : userIdFrom;

  const currentUserId = useCurrentUser()?.id;
  const myVideoCallInvitation =
    currentUserId === userIdTo || currentUserId === userIdFrom;

  const [isMessageExpired, setIsMessageExpired] = useState(false);

  useEffect(() => {
    const expireTime = 45 * 1000; // 45 seconds
    const messageCreationTime = new Date(props.message.dateCreated).getTime();
    const currentTime = new Date().getTime();
    const timeToExpire = messageCreationTime + expireTime - currentTime;

    if (timeToExpire > 0) {
      const timer = setTimeout(() => {
        setIsMessageExpired(true);
      }, timeToExpire);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setIsMessageExpired(true);
    }
  }, [props.message]);

  const messageHasStatus =
    status === VideoCallInvitationChatMessageStatus.ACCEPTED ||
    status === VideoCallInvitationChatMessageStatus.DECLINED;

  const shouldDisplayButtons = !messageHasStatus && !isMessageExpired;

  const roomName = useUniqueVideoCallRoomName(userId);

  const onJoinCallClick = () => {
    dispatch(acceptVideoCall(userId, props.message.id));
    const videoCallLink = generateVideoCallLink(pathname, roomName);
    videoCallLink && history.push(videoCallLink);
  };

  const onDeclineCallClick = () => {
    dispatch(declineVideoCall(userId, props.message.id));
  };

  if (!myVideoCallInvitation) {
    return (
      <span>
        <span className="text-bold">
          <UserName id={userIdFrom} />
        </span>
        <>{" "}Invited</>
        <span className="text-bold">
          {" "}
          <UserName id={userIdTo} />
        </span>
        <>{" "}to a video call.</>
      </span>
    );
  }

  return (
    <>
      {props.isMyMessage && (
        <span>
          <>You invited</>
          <span className="text-bold">
            {" "}<UserName id={userId} />
          </span>
          {" "}to a video call.
          {status === VideoCallInvitationChatMessageStatus.DECLINED && (
            <>
              <div className="text-bold">
                {" "}<UserName id={userId} />
              </div>
              <span>
                {" "}declined the call.
              </span>
            </>
          )}
          {status === VideoCallInvitationChatMessageStatus.ACCEPTED && (
            <>
              <div className="text-bold">
                {" "}<UserName id={userId} />
              </div>
              <span>
                {" "}accepted the call.
              </span>
            </>
          )}
        </span>
      )}
      {shouldDisplayButtons && !props.hideButtons && !props.isMyMessage && (
        <>
          <div className="text-bold">
            <UserName id={userId} /> Invited you to a video call
          </div>

          <div className="flex-row gap-sm mt-sm">
            <Button onClicked={onJoinCallClick} label="title.join" />
            <Button
              color="red"
              onClicked={onDeclineCallClick}
              label="title.decline"
            />
          </div>
        </>
      )}
      {!shouldDisplayButtons && !props.isMyMessage && (
        <span>
          <span className="text-bold"><UserName id={userId} /></span>
          <>{" "}invited you to a video call</>
          {status === VideoCallInvitationChatMessageStatus.DECLINED && (
            <>{" "}but you declined it.</>
          )}
          {status === VideoCallInvitationChatMessageStatus.ACCEPTED && (
            <>{" "}and you accepted it.</>
          )}
          {isMessageExpired && !messageHasStatus && (
            <>{" "}but you didn't answer the call.</>
          )}
        </span>
      )}
    </>
  );
};
