import { FC } from "react";
import { UserGroupPermissionsView } from "~/API";
import { Case, Dialog, Match } from "~/Components/UI";
import UserGroupDisplayForm from "./UserGroupDisplayForm";
import UserGroupEditForm from "./UserGroupEditForm";
import {ToggleProps, useToggle} from "~/Hooks";

const UserGroupCard: FC<{
  userGroup: UserGroupPermissionsView;
  open: ToggleProps;
}> = (props) => {
  const editing = useToggle(false);

  const onOpenChange = (_open: boolean) => editing.toggleFalse();

  return (
    <Dialog
      open={props.open}
      trigger={<></>}
      title={props.userGroup.name}
      onOpenChange={onOpenChange}
    >
      <Match on={editing.value}>
        <Case v={false}>
          <UserGroupDisplayForm
            userGroup={props.userGroup}
            editingTrue={editing.toggleTrue}
          />
        </Case>

        <Case v={true}>
          <UserGroupEditForm
            userGroup={props.userGroup}
            editingFalse={editing.toggleFalse}
          />
        </Case>
      </Match>
    </Dialog>
  );
};

export default UserGroupCard;
