import {useMutateHook} from "~/Hooks";
import API, {LoginData, LoginResponse, UserAccountUpdate} from "~/API";
import Toast from "~/Components/Toast";
import {Locale, ls, setLocale} from "~/Locales";
import {AppDispatch} from "~/StoreTypes";
import {saveUserPreferences, setLastUserId} from "~/Services/UserPreferences";
import {ACTIONS_SIGN_IN_SUCCESS, ACTIONS_UPDATE_CURRENT_USER_SUCCESS, ACTIONS_USER_LOG_OUT} from "~/Reducers/User";
import {savePreferences} from "~/Reducers/UserPreferences";
import {requestNotificationsPermission} from "~/Utils/Notifications";
import {initTracking} from "~/Services/Tracking";
import {cache} from "swr/_internal";

export const useResetPassword = () => useMutateHook<{ email: string }, any>(
  "passwordReset",
  async (args) => {
    try {
      await API.resetPassword({}, args);
    } catch (error) {
        if (error.status === 404) {
          Toast.Error(ls('forgot.password.error.404'));
        }
        if (error.status === 403) {
          Toast.Error(ls('forgot.password.error.403', 'hello@timberbase.com'))
        }
        throw error;
    }
  },
);

export const useSetNewPassword = () => useMutateHook<{ password: string, resetToken: string }, any>(
  "setNewPassword",
  async (args) => {
    try {
      await API.setNewPassword({}, args);
    } catch (error) {
      Toast.ApiError(error);
      throw error;
    }
  }
);

export const useUpdateCurrentUser = (dispatch: AppDispatch) => useMutateHook<UserAccountUpdate, void, LoginResponse>(
  "api/user/self",
  async (args) => {
    try {
      const user = await API.updateUser({ id: args.id }, args) as LoginResponse;
      API.setToken(user.token);
      saveUserPreferences(user);
      setLocale(user.language.toLowerCase() as Locale);
      dispatch({ type: ACTIONS_UPDATE_CURRENT_USER_SUCCESS, user });
      return user;
    } catch (error) {
      Toast.ApiError(error);
      throw error;
    }
  }
);

export const useSignIn = (dispatch: AppDispatch) => useMutateHook<LoginData, void, LoginResponse>(
  "api/user/self",
  async (args) => {
    const user = await API.login({}, args);

    API.setToken(user.token);
    API.getWs().connect();
    dispatch({ type: ACTIONS_SIGN_IN_SUCCESS, user });
    setLocale(user.language.toLowerCase() as Locale);
    dispatch(savePreferences({ showBellNotifications: user.showBellNotifications }));
    setLastUserId(user.id);
    saveUserPreferences(user);
    requestNotificationsPermission();
    initTracking(user.id, user);

    return user;
  }
);

export const logoutCurrentUser = (dispatch: AppDispatch) => {
  setLastUserId(null);
  API.setToken('');
  document.cookie = "token=";
  dispatch({ type: ACTIONS_USER_LOG_OUT });

  // clear out all cached API calls. Can fix issues caused by switching to a user account with different permissions
  // @ts-ignore
  cache.clear?.();
}
