import { FC } from "react";
import { formattedNumber } from "~/Utils/PriceCalculation";

interface PercentageLabelProps {
  fractionalValue: number | null;
  decimals?: number;
}

export const PercentageView: FC<PercentageLabelProps> = ({
  fractionalValue,
  decimals = 2,
}) => {
  if (fractionalValue == null) return <></>;
  const percentageFormatted = formattedNumber(
    fractionalValue * 100.0,
    decimals,
  );

  return <span>{percentageFormatted} %</span>;
};
