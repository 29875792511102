import BaseLoginPage from "../BaseLoginPage";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";

const RegistrationExpiredPage: FC = () => {
  usePageTitle("Registration");

  return (
    <BaseLoginPage>
      <p>Hello!</p>

      <div>
        It appears that the confirmation link you clicked on has expired. For
        security reasons, our confirmation links are only valid for 7 days. To
        obtain a new link, please send us an email at{" "}
        <a href="mailto:hello@timberbase.com">hello@timberbase.com</a>,
        informing us of your situation. We will promptly provide you with a new
        confirmation link so that you may continue with your registration.
      </div>

      <p>
        We appreciate your patience and understanding. We are excited to have
        you join TimberBase!
      </p>

      <div>Best regards,</div>
      <div>The TimberBase Team</div>
    </BaseLoginPage>
  );
};

export default RegistrationExpiredPage;
