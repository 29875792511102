import { FC, Fragment, memo, useCallback } from "react";
import { LocalizedMessage } from "~/Locales";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { formattedNumber } from "~/Utils/PriceCalculation";
import {
  ProductType,
  ProductTypeLabel,
  productTypesArray,
} from "~/Reducers/Products";
import { useIsInternalUser } from "~/Reducers/User";
import { useIsNew } from "./RequestsHelper";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import { DeleteIcon } from "~/Components/Old/Icons";
import LengthSelect from "~/Components/Old/LengthSelect";
import { LengthsView } from "~/Components/Old/LengthsView";
import TextField from "~/Components/Old/TextField";
import { UOMSelect } from "~/Components/Old/UOMSelect";
import { calculateQuoteProductsTotalValue } from "~/Pages/Quotes/Components/QuotesHelper";
import { classNames } from "react-ui-basics/Tools";
import "./RequestDetailsSummarySectionTable.scss";
import {getContainers, UOM} from "~/Utils/UnitConversion";
import { useLengthsByIds } from "~/Reducers/Lengths";
import { useProductPublic } from "~/Reducers/ProductsPublic";
import { ProductV2UOM, QuoteProductView } from "~/API";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";

const RequestDetailsSummarySectionTable: FC<{
  readOnly: boolean;
  products: QuoteProductView[];
  onProductFieldChange: (id: number, evt: any) => void;
  onRemoveProduct: (id: number) => void;
  currency: string;
}> = memo((props) => {
  const { readOnly, products, currency } = props;
  const isNew = useIsNew();
  const isInternalUser = useIsInternalUser();
  const currencySymbol = useFindCurrencySymbol(currency);

  if (!products.length)
    return (
      <div className={"mt-md"}>
        There are no Products in this request yet. Add your first Product now!
      </div>
    );

  const totalValue = calculateQuoteProductsTotalValue(products as any[]);
  const totalValueFormatted = formattedNumber(totalValue);

  const columnsClassName = readOnly
    ? "RequestDetailsSummarySectionTableReadOnly"
    : "RequestDetailsSummarySectionTableEditable";

  return (
    <table
      className={classNames(
        "basic-table RequestDetailsSummarySectionTable",
        columnsClassName,
      )}
    >
      {productTypesArray.map((productType, index) => {
        const filteredProducts = products.filter(
          (product) => product.productType === productType,
        );
        return (
          filteredProducts.length > 0 && (
            <Fragment key={index}>
              <thead>
                <tr>
                  <td
                    className={"text-xl"}
                    colSpan={!readOnly ? 12 : isInternalUser ? 9 : 8}
                  >
                    <LocalizedMessage id={"title.request.summary"} /> -
                    <span className={"text-lg text-left color-blue-1"}>
                      {" "}
                      {ProductTypeLabel[productType]}
                    </span>
                  </td>
                </tr>
              </thead>
              <ProductsByTypeTable
                {...props}
                products={filteredProducts}
                productType={productType}
              />
            </Fragment>
          )
        );
      })}

      <tbody>
        <tr className={"text-xl upper-case border-none"}>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          {!readOnly && !isNew && <td />}
          <td>
            <LocalizedMessage id={"title.total"} />
          </td>
          <td className={"text-right color-orange-1 no-wrap"}>
            <b>
              {currencySymbol}
              {totalValueFormatted}
            </b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </table>
  );
});

export default RequestDetailsSummarySectionTable;

const ProductsByTypeTable = memo<{
  products: QuoteProductView[];
  onProductFieldChange?: (id: number, evt: any) => void;
  onRemoveProduct?: (id: number) => void;
  currency: string;
  productType: ProductType;
  readOnly: boolean;
}>((props) => {
  const {
    products,
    onProductFieldChange,
    onRemoveProduct,
    currency,
    productType,
    readOnly,
  } = props;
  const isNew = useIsNew();
  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const sortedProducts = products.sort((a, b) => a.id - b.id);
  const currencySymbol = useFindCurrencySymbol(currency);

  const totalValue = calculateQuoteProductsTotalValue(sortedProducts);
  const totalValueFormatted = formattedNumber(totalValue);

  return (
    <>
      <thead>
        <tr>
          {!readOnly && !isNew && <th />}
          <th>
            <LocalizedMessage id={"title.code"} />
          </th>
          <th>
            <LocalizedMessage id={"title.description"} />
          </th>
          <th>{showLength && <LocalizedMessage id={"title.length"} />}</th>
          <th>
            <LocalizedMessage id={"title.uom"} />
          </th>
          <th>
            <LocalizedMessage id={"title.quantity"} />
          </th>
          <th>
            <LocalizedMessage id={"title.containers"} />
          </th>
          <th />
          <th>
            <LocalizedMessage id={"title.price"} />
          </th>
          <th className={"text-right"}>
            <LocalizedMessage id={"title.subtotal"} />
          </th>
          {!readOnly && (
            <th>
              <LocalizedMessage id={"title.actions"} />
            </th>
          )}
        </tr>
      </thead>

      <tbody>
        {sortedProducts.map((product) => (
          <OfferProductRow
            key={product.id}
            product={product}
            currency={currency}
            onProductFieldChange={onProductFieldChange}
            onRemoveProduct={onRemoveProduct}
            readOnly={readOnly}
          />
        ))}

        <tr className={"text-lg border-none"}>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          {!readOnly && !isNew && <td />}
          <td>
            <LocalizedMessage id={"title.subtotal"} />
          </td>
          <td className={"text-right color-orange-1 no-wrap"}>
            <b>
              {currencySymbol}
              {totalValueFormatted}
            </b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </>
  );
});

const OfferProductRow: FC<{
  product: QuoteProductView;
  currency: string;
  onProductFieldChange: (id: number, evt: any) => void;
  onRemoveProduct: (id: number) => void;
  readOnly: boolean;
}> = (props) => {
  const { product, currency, onProductFieldChange, onRemoveProduct, readOnly } =
    props;
  const isNew = useIsNew();
  const currencySymbol = useFindCurrencySymbol(currency);

  const {
    id = -1,
    productCode = "",
    productActLength = "",
    quantity = 1,
    uom = "",
    price = 0,
    lengthIds = [],
    productType = "",
    active,
    productGroupId = null,
  } = product;

  const onFieldChange = useCallback(
    (event) => {
      onProductFieldChange(id, event);
    },
    [id, onProductFieldChange],
  );

  const onActiveCheckboxChange = useCallback(
    (event) => {
      const { target } = event;
      onProductFieldChange(id, {
        ...event,
        target: { id: "active", value: target.checked },
      });
    },
    [id, onProductFieldChange],
  );

  const onRemoveClick = useCallback(() => {
    onRemoveProduct(id);
  }, [id, onRemoveProduct]);

  const subtotal = quantity * price;

  const subtotalFormatted = formattedNumber(subtotal);
  const priceRounded = price && Number(price).toFixed(2);

  const isUomReadOnly =
    productType === ProductType.LINEAR_FOOTAGE ||
    productType === ProductType.SPECIAL_PRODUCTS;

  const isPanels = productType === ProductType.PANELS;
  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;
  const showLengthForPanels = isPanels && showLength;
  const showLengthForOthers = !isPanels && showLength;

  const lengths = useLengthsByIds(lengthIds);
  const productData = useProductPublic(product?.productId);

  const isLumber = productType === ProductType.LUMBER;
  const uomIsLf = uom === UOM.LF;
  const limitLengths = uomIsLf && isLumber;

  return (
    <>
      <tr className={classNames(!active && "OfferSummaryProductRowReadOnly")}>
        {!readOnly && !isNew && (
          <td>
            <Checkbox value={active} onChange={onActiveCheckboxChange} />
          </td>
        )}
        <td> {productCode} </td>
        <td>
          <ProductCatalogueDescriptionLink
            description={product.productDescription}
            productCode={product.productCode}
            hasSyndigo={product.hasSyndigo}
            productId={product.productId}
          />
        </td>
        {readOnly && (
          <td>{showLength ? <LengthsView lengthIds={lengthIds} /> : "-"}</td>
        )}
        {!readOnly && (
          <td>
            {showLengthForPanels && (
              <TextField
                className={"ValueField"}
                value={productActLength}
                disabled={true}
              />
            )}
            {showLengthForOthers && active && (
              <LengthSelect
                id={"lengthIds"}
                disabled={!active}
                value={lengthIds}
                multiple={!limitLengths}
                className={"ValueField"}
                byGroup={productGroupId}
                onChange={onFieldChange}
                inlineSelected
              />
            )}
            {showLengthForOthers && !active && (
              <LengthsView lengthIds={lengthIds} />
            )}
            {!showLength && "-"}
          </td>
        )}
        <td>
          {readOnly ? (
            <div className={"flex-row align-center AlignItemsCenter"}>
              {" "}
              {uom}{" "}
            </div>
          ) : (
            <UOMSelect
              id="uom"
              className={"ValueField"}
              value={uom}
              productType={productType}
              onChange={onFieldChange}
              disabled={isUomReadOnly}
            />
          )}
        </td>
        <td>
          {readOnly ? (
            <div className={"flex-row align-center AlignItemsCenter"}>
              {" "}
              {quantity}{" "}
            </div>
          ) : (
            <TextField
              id="quantity"
              type={"number"}
              hideArrows
              float
              className={"ValueField MidsizeField"}
              value={quantity}
              onChange={onFieldChange}
              min={0}
              max={99999999}
            />
          )}
        </td>
        <td>
          {getContainers(
            productData,
            lengths?.[0],
            quantity,
            uom as ProductV2UOM,
          ).toFixed(2)}
        </td>
        <td className={"text-right"}>
          <div className={"flex-row align-center justify-end"}>
            {readOnly ? (
              <div
                className={
                  "flex-row align-center text-right color-orange-1 mr-xxs text-bold"
                }
              >
                {" "}
                {priceRounded}{" "}
              </div>
            ) : (
              <TextField
                id="price"
                type={"number"}
                hideArrows
                float
                className={"ValueField BigField mr-xxs"}
                inputStyle={{ textAlign: "right" }}
                value={price}
                onChange={onFieldChange}
              />
            )}
          </div>
        </td>
        <td>
          {currency}/{uom}
        </td>
        <td className={"text-right color-orange-1 no-wrap"}>
          <b>
            {currencySymbol}
            {subtotalFormatted}
          </b>
        </td>
        {!readOnly && (
          <td>
            <div className={"flex-row align-center"}>
              <Button
                view={"text"}
                className={"ActionButton DeleteButtonText"}
                onClick={onRemoveClick}
              >
                <DeleteIcon size={16} />
              </Button>
            </div>
          </td>
        )}
      </tr>
    </>
  );
};
