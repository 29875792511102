import { EmptyState } from "~/Components/Old/EmptyStates/EmptyState";
import { EmptyRequestsImageLazy } from "~/Components/Old/EmptyStates/EmptyStatesLazy";


const QuotesPageEmptyState = () => {
  return (
    <EmptyState title={'No Quotes'}
      description={'There are no quotes matching your search criteria.'}
      image={<EmptyRequestsImageLazy />}
    />
  );
};

export default QuotesPageEmptyState;
