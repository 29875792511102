import React, { useEffect, useState } from "react";
import Video from "twilio-video";
import { LocalParticipant } from "./LocalParticipant";
import { LocalAudioTrack } from "twilio-video/tsdef/LocalAudioTrack";
import { LocalVideoTrack } from "twilio-video/tsdef/LocalVideoTrack";
import { useToggle } from "~/Hooks/Old";
import {
  MonitorIcon,
  VideoCameraIcon,
  VideoCameraOffIcon,
  VideoEndCallIcon,
  VideoMicIcon,
  VideoMicOffIcon,
} from "~/Components/Old/Icons";
import UserName from "~/Components/Old/UserName";
import useScreenShareToggle from "./useScreenShareToggle";
import { useCurrentUser } from "~/Reducers/User";
import { LeaveVideoCallPrompt } from "~/Components/Old/UnsavedChangesPrompt";
import { Tooltip } from "@mui/material";
import { RemoteParticipant } from "./RemoteParticipant";
import {
  stopCalling,
  useVideoCallState,
} from "~/Reducers/VideoCall";
import { classNames } from "react-ui-basics/Tools";
import "./VideoCall.scss";
import { parseError, VClog } from "./VideoCallHelper";
import {useAppDispatch} from "~/StoreTypes";

export const Room = ({ roomName, userId, token, handleEndCall }) => {
  const [room, setRoom] = useState<Video.Room | null>(null);
  const [remoteParticipant, setRemoteParticipant] =
    useState<Video.Participant>(null);
  const dispatch = useAppDispatch();

  const { isCalling, isVideoCallOn } = useVideoCallState();

  const updateRemoteParticipant = () => {
    const rp = room?.participants?.values()?.next()?.value;
    setRemoteParticipant(rp);
  };

  useEffect(updateRemoteParticipant, [room?.participants]);

  const participantConnected = (participant) => {
    setRemoteParticipant(participant);

    // room?.participants?.forEach(participant => {
    //   participant?.tracks?.forEach(publication => {
    //     publication?.on('unsubscribed', () => {
    //       VClog('### ❌ publication unsubscribed')
    //       /* Hide the associated <video> element and show an avatar image. */
    //     });
    //   });
    // });
  };

  const participantDisconnected = (participant) => {
    // VClog(`### 🔴 remoteParticipant (${participant.identity}) disconnected. `);
    updateRemoteParticipant();
  };

  const onConnectedToRoom = (room: Video.Room) => {
    setRoom(room);
    VClog(`### 🔵 Connected to Room: ${room}`);

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on("participantConnected", () =>
      VClog("### [VideoCall]room participantConnected"),
    );
    room.on("participantDisconnected", () =>
      VClog("### [VideoCall]room participantDisconnected"),
    );
    room.on("disconnected", (room, error) => {
      VClog("### [VideoCall]room disconnected: error", error);
      VClog("### [VideoCall]room disconnected: room", room);
      endCall();
    });
    room.on("reconnected", () => VClog("### [VideoCall]room reconnected"));
    room.on("reconnecting", () => VClog("### [VideoCall]room reconnecting"));
    room.on("trackDisabled", () => VClog("### [VideoCall]room trackDisabled"));
    room.on("trackEnabled", () => VClog("### [VideoCall]room trackEnabled"));
    room.on("trackPublished", () =>
      VClog("### [VideoCall]room trackPublished"),
    );
    room.on("trackPublishPriorityChanged", () =>
      VClog("### [VideoCall]room trackPublishPriorityChanged"),
    );
    room.on("trackStarted", () => VClog("### [VideoCall]room trackStarted"));
    room.on("trackSubscribed", () =>
      VClog("### [VideoCall]room trackSubscribed"),
    );
    room.on("trackSubscriptionFailed", () =>
      VClog("### [VideoCall]room trackSubscriptionFailed"),
    );
    room.on("trackUnpublished", () =>
      VClog("### [VideoCall]room trackUnpublished"),
    );
    room.on("trackUnsubscribed", () =>
      VClog("### [VideoCall]room trackUnsubscribed"),
    );
    room.on("trackSwitchedOff", () =>
      VClog("### [VideoCall]room trackSwitchedOff"),
    );
    room.on("trackSwitchedOn", () =>
      VClog("### [VideoCall]room trackSwitchedOn"),
    );
  };

  const stopTracks = (room) => {
    room.localParticipant.tracks.forEach((trackPublication) => {
      // note: we only use LocalAudioTrack | LocalVideoTrack, not LocalDataTrack
      // LocalDataTrack doesn't have stop() method. it's for sending messages, which we don't use.
      // export type LocalTrack = LocalAudioTrack | LocalVideoTrack | LocalDataTrack;
      const track = trackPublication.track as LocalAudioTrack | LocalVideoTrack;
      track.stop();
      track.detach().forEach((element) => element.remove());
      room.localParticipant.unpublishTrack(track);
    });
  };

  const cleanUpRoom = () => {
    stopPlayingDialToneSound();
    if (room) {
      // && room.localParticipant.state === 'connected') {
      // VClog('### ❗️onRoomUnmount: disconnecting room')
      stopTracks(room);
      room.disconnect();
      setTimeout(() => {
        setRoom(null);
        dispatch(stopCalling);
      }, 100);
    }
  };

  const [prevIsVideoCallOn, setPrevIsVideoCallOn] = useState(isVideoCallOn);
  useEffect(() => {
    if (prevIsVideoCallOn === true && isVideoCallOn === false) {
      cleanUpRoom();
    }
    setPrevIsVideoCallOn(isVideoCallOn);
  }, [isVideoCallOn]);

  const [isAudioPermitted, setIsAudioPermitted] = useState(null);
  const [isVideoPermitted, setIsVideoPermitted] = useState(null);

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const [hasVideoInputDevices, setHasVideoInputDevices] = useState(null);
  const [hasAudioInputDevices, setHasAudioInputDevices] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      const isCameraPermissionDenied = await isPermissionDenied("camera");
      VClog("### isCameraPermissionDenied", isCameraPermissionDenied);
      isMounted && setIsVideoPermitted(!isCameraPermissionDenied);

      const isMicrophonePermissionDenied =
        await isPermissionDenied("microphone");
      VClog("### isMicrophonePermissionDenied", isMicrophonePermissionDenied);
      isMounted && setIsAudioPermitted(!isMicrophonePermissionDenied);

      const {
        audioInputDevices,
        videoInputDevices,
        hasAudioInputDevices,
        hasVideoInputDevices,
      } = await getDeviceInfo();
      VClog("### audioInputDevices", audioInputDevices);
      VClog("### videoInputDevices", videoInputDevices);
      VClog("### hasAudioInputDevices", hasAudioInputDevices);
      VClog("### hasVideoInputDevices", hasVideoInputDevices);

      isMounted && setHasVideoInputDevices(hasVideoInputDevices);
      isMounted && setHasAudioInputDevices(hasAudioInputDevices);
    };
    getInfo();

    return () => {
      isMounted = false;
    };
  }, []);

  // old implementation, requests persmissions on page load
  // useEffect(() => {
  //   navigator.mediaDevices.getUserMedia({ audio: true })
  //     .then(() => setIsAudioPermitted(true))
  //     .catch(() => setIsAudioPermitted(false));
  //   navigator.mediaDevices.getUserMedia({ video: true })
  //     .then(() => setIsVideoPermitted(true))
  //     .catch(() => setIsVideoPermitted(false));
  // }, []);

  const shouldAcquireVideo = hasVideoInputDevices && isVideoPermitted;
  const shouldAcquireAudio = !!hasAudioInputDevices && isAudioPermitted;

  const isAudioAndVideoPermissionDecided =
    isAudioPermitted !== null &&
    isVideoPermitted !== null &&
    hasVideoInputDevices !== null &&
    hasAudioInputDevices !== null;

  useEffect(() => {
    if (isAudioAndVideoPermissionDecided && roomName && token) {
      VClog("### 🟢 Video.connect");

      // detect safari or chrome
      // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );
      VClog("### isSafari = ", isSafari);

      VClog("### isAudioPermitted", isAudioPermitted);
      VClog("### isVideoPermitted", isVideoPermitted);
      VClog("### hasVideoInputDevices = ", hasVideoInputDevices);
      VClog("### hasAudioInputDevices = ", hasAudioInputDevices);
      VClog("### shouldAcquireVideo", shouldAcquireVideo);
      VClog("### shouldAcquireAudio", shouldAcquireAudio);

      Video.connect(token, {
        name: roomName,
        video: shouldAcquireVideo,
        audio: shouldAcquireAudio,
        preferredVideoCodecs: isSafari ? ["VP8"] : ["H264"],
        // VClogLevel: 'debug',
      })
        .catch((error) => {
          const { headline, message } = parseError(
            isAudioPermitted,
            isVideoPermitted,
            error,
          );
          setErrorTitle(headline);
          setErrorDescription(message);
          VClog("### Error = ", headline);
          VClog("### Error message = ", message);
        })
        .then((room: Video.Room) => {
          if (room) {
            onConnectedToRoom(room);
          } else {
            console.error(
              "### Error: Could not initialize the video call room",
            );
          }
        });
    }
  }, [
    roomName,
    token,
    isAudioPermitted,
    isVideoPermitted,
    hasAudioInputDevices,
    hasVideoInputDevices,
  ]);

  const [isCameraOn, toggleCameraOn] = useToggle(true);

  const onCameraIconClick = () => {
    // disable camera when screen sharing
    !isSharingScreen && toggleCameraOn();
  };

  useEffect(() => {
    room?.localParticipant?.videoTracks?.forEach((trackPublication) => {
      if (isCameraOn) {
        trackPublication.track.enable();
      } else {
        trackPublication.track.disable();
      }
    });
  }, [isCameraOn]);

  const [isAudioMuted, toggleIsAudioMuted] = useToggle(false);
  const onMuteClick = () => toggleIsAudioMuted();

  useEffect(() => {
    room?.localParticipant?.audioTracks?.forEach((trackPublication) => {
      isAudioMuted
        ? trackPublication.track.disable()
        : trackPublication.track.enable();
    });
  }, [isAudioMuted]);

  const [shouldLeaveVideoCall, setShouldLeaveVideoCall] = useState(false);

  const onEndCallButtonClick = () => {
    endCall();
  };

  const endCall = () => {
    cleanUpRoom();
    setShouldLeaveVideoCall(true);
  };

  useEffect(() => {
    if (shouldLeaveVideoCall) {
      handleEndCall();
    }
  }, [shouldLeaveVideoCall, handleEndCall]);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    (error) => {
      console.error("### Screen Share Error", error);
    },
  );

  const currentUserId = useCurrentUser()?.id;
  const waitingForRecipientToJoin = !remoteParticipant;

  const dialToneAudioRef = React.useRef<HTMLAudioElement>(
    new Audio("/assets/sounds/dial_tone.mp3"),
  );
  dialToneAudioRef.current.addEventListener("error", (e) => {
    console.error("### AUDIO Error ", e);
  });

  const startPlayingDialToneSound = () => {
    dialToneAudioRef.current.volume = 0.3;
    isCalling && dialToneAudioRef.current.play();
    dialToneAudioRef.current.loop = true;
  };

  const stopPlayingDialToneSound = () => {
    dialToneAudioRef.current && dialToneAudioRef.current.pause();
  };

  useEffect(() => {
    isCalling ? startPlayingDialToneSound() : stopPlayingDialToneSound();
  }, [isCalling]);

  useEffect(() => {
    if (isCalling && remoteParticipant) {
      dispatch(stopCalling);
    }
  }, [remoteParticipant, isCalling]);

  return (
    <>
      <div className="VideoCallRoom">
        {errorTitle && (
          <>
            <div className={"VideoWarningText"}>
              <strong> {errorTitle} </strong>{" "}
            </div>
            <div className={"VideoWarningText"}> {errorDescription} </div>
          </>
        )}
        {isAudioAndVideoPermissionDecided && shouldAcquireAudio === false && (
          <div className={"VideoWarningText"}>
            {" "}
            Microphone is disabled in your browser. Please enable it and restart
            the call{" "}
          </div>
        )}
        {isAudioAndVideoPermissionDecided && shouldAcquireVideo === false && (
          <div className={"VideoWarningText"}>
            {" "}
            Camera is disabled in your browser. Please enable it and restart the
            call{" "}
          </div>
        )}
        {room ? (
          <div className="VideoCallParticipantsContainer">
            {waitingForRecipientToJoin && (
              <div className="NoRemoteParticipant">
                Waiting for <UserName id={userId} /> to join
              </div>
            )}
            <div className="VideoCallParticipantsFrame">
              <div className="RemoteParticipantContainer">
                <RemoteParticipant
                  userId={userId}
                  key={remoteParticipant?.sid}
                  participant={remoteParticipant}
                />
                <div className="LocalParticipantContainer">
                  <LocalParticipant
                    userId={currentUserId}
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    isLocalCameraOn={isCameraOn}
                    isScreenSharingOn={isSharingScreen}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <StartingVideoCallView />
        )}
        <div className="VideoCallButtonsContainer">
          <MicButton muted={isAudioMuted} onClick={onMuteClick} />
          <CameraButton on={isCameraOn} onClick={onCameraIconClick} />
          <ScreenSharingButton
            on={isSharingScreen}
            onClick={toggleScreenShare}
          />
          <EndCallButton onClick={onEndCallButtonClick} />
        </div>
        {isVideoCallOn && (
          <LeaveVideoCallPrompt shouldDisplay={!shouldLeaveVideoCall} />
        )}
      </div>
    </>
  );
};

const StartingVideoCallView = () => (
  <div className={"StartingCallView"}>
    <div>Starting Video Call...</div>
  </div>
);

const MicButton = ({ onClick, muted }) => {
  return (
    <div className="VideoCallButton" onClick={onClick}>
      {muted ? (
        <VideoMicOffIcon className="VideoIcon" size={24} />
      ) : (
        <VideoMicIcon className="VideoIcon" size={24} />
      )}
    </div>
  );
};

const CameraButton = ({ onClick, on }) => {
  return (
    <div className="VideoCallButton" onClick={onClick}>
      {on ? (
        <VideoCameraIcon className="VideoIcon" size={24} />
      ) : (
        <VideoCameraOffIcon className="VideoIcon" size={24} />
      )}
    </div>
  );
};

const ScreenSharingButton = ({ onClick, on }) => {
  const buttonStyle = classNames(
    "VideoCallButton ScreenSharingButton",
    on ? "BlueBackgroundColor" : "",
  );
  return (
    <Tooltip
      title={on ? "Stop Screen Sharing" : "Share Screen"}
      placement={"top"}
    >
      <div className={buttonStyle} onClick={onClick}>
        {on ? (
          <MonitorIcon className="VideoIcon RedColor" size={24} />
        ) : (
          <MonitorIcon className="VideoIcon" size={24} />
        )}
      </div>
    </Tooltip>
  );
};

const EndCallButton = ({ onClick }) => {
  return (
    <div className="VideoCallButton EndCallButton" onClick={onClick}>
      <VideoEndCallIcon className="VideoIcon EndCallIcon" size={28} />
    </div>
  );
};

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
async function isPermissionDenied(name: "camera" | "microphone") {
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({
        name: permissionName,
      });
      return result.state === "denied";
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
    videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
    audioOutputDevices: devices.filter(
      (device) => device.kind === "audiooutput",
    ),
    hasAudioInputDevices: devices.some(
      (device) => device.kind === "audioinput",
    ),
    hasVideoInputDevices: devices.some(
      (device) => device.kind === "videoinput",
    ),
  };
}
