import { FC } from "react";
import { ChatViewEntity } from "~/Components/Chats/ChatViewEntity";
import {useChatQuote} from "~/Data/Chats";
import {useIsInternalUser} from "~/Reducers/User";

const QuoteChat: FC<{
  onClose: () => void;
  quoteId: number;
}> = ({ onClose, quoteId }) => {
  const isInternalUser = useIsInternalUser();

  const chatInternal = useChatQuote(quoteId, true, isInternalUser);
  const chatExternal = useChatQuote(quoteId, false, isInternalUser);

  return (
    <ChatViewEntity
      onClose={onClose}
      chatExternal={chatExternal.data}
      chatInternal={chatInternal.data}
    />
  );
};

export default QuoteChat;
