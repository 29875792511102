import {CreateRequestForQuoteRequest, GroupPermission, RequestForQuoteStatus, RequestForQuoteView,} from "~/API";
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {useAppDispatch} from "~/StoreTypes";
import {useHistory, useParams} from "react-router-dom";
import {useFormState, useToggle} from "~/Hooks/Old";
import {LC, LocalizedMessage, LocalizedMessageKey, localizedString} from "~/Locales";
import {requestsRootPath} from "~/Services/Routes";
import {isComboIncoterm, useIncotermUsesAddress} from "~/Reducers/Incoterms";
import {getLocation, locationAddress, useLocation, useLocationAddress, useLocations,} from "~/Reducers/Locations";
import {
  generateTodayPlusSevenDaysDateTimeInISO,
  getDateTimeEndOfTheDayInISO,
  getOfferAttachments,
  useOffers,
} from "~/Reducers/Offers";
import {getQuoteAttachments, useQuotes} from "~/Reducers/Quotes";
import {
  createRequestForQuote,
  getRequestForQuoteAttachments,
  isRequestExpired,
  markRequestRead,
  updateRequestForQuote,
  useRequestForQuote,
} from "~/Reducers/RequestsForQuote";
import {useAMUserNames, useUserNames} from "~/Reducers/Users";
import {HasPermissions, useCurrentUser, useIsCustomer, useIsInternalUser,} from "~/Reducers/User";
import {findImageAndPDFAttachments} from "~/Utils/Attachments";
import {destinationNonPortTypes, destinationPortTypes,} from "~/Data/Destinations";
import {RequestStatusLabel, useIsDraft, useIsMatching, useIsNew, useIsReadOnly,} from "./Components/RequestsHelper";
import CompanyView from "~/Components/Views/CompanyView";
import CustomerSelect from "~/Components/Old/CustomerSelect";
import DatePicker from "~/Components/Old/DatePicker";
import ImagesList from "~/Components/Old/ImagesList";
import IncotermDestinationLabel from "~/Components/Old/IncotermDestinationLabel";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import LocationName from "~/Components/Old/LocationName";
import LocationSelect from "~/Components/Old/LocationSelect";
import OfferSelect from "~/Components/Old/OfferSelect";
import PortSelect from "~/Components/Old/PortSelect";
import ProductImagesGallery from "~/Components/Old/ImagesGallery";
import QuoteSelect from "~/Components/Old/QuoteSelect";
import RequestReference from "../Components/RequestReference";
import Select from "~/Components/Old/Select";
import TextField from "~/Components/Old/TextField";
import UnsavedChangesPrompt from "~/Components/Old/UnsavedChangesPrompt";
import UserByLocationSelect from "~/Components/Old/UserByLocationSelect";
import UserName from "~/Components/Old/UserName";
import RequestBottomButtons from "./Components/RequestBottomButtons";
import RequestDetailsSummarySectionTable from "./Components/RequestDetailsSummarySectionTable";
import RequestMatchingTable from "./Components/RequestMatching/RequestMatchingTable";
import {classNames} from "react-ui-basics/Tools";
import dayjs from "dayjs";
import "./RequestDetailsGeneralTab.scss";
import {WarningBanner} from "~/Components/Old/WarningBanner";
import {usePort} from "~/Reducers/Ports";
import {invalidateChats} from "~/Data/Chats";
import {mutate} from "swr";
import {Card} from "~/Components/UI";
import ProductsList from "~/Pages/Offers/DetailsPage/Components/ProductsList";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "~/Components/UI/Tabs";

const currencyArray = ["USD", "EUR"];

const useEmptyRequest = () => {
  const isInternalUser = useIsInternalUser();

  return useMemo(
    () => ({
      status: isInternalUser
        ? RequestForQuoteStatus.DRAFT_AM
        : RequestForQuoteStatus.DRAFT_CUSTOMER,
      currency: "USD",
      customerContactUserAccountIds: [],
      products: [],
      validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
    }),
    [isInternalUser],
  );
};

const FieldWithLabel: FC<{ label: LocalizedMessageKey, children: ReactNode, dataCy?: String}> = ({ label, children, dataCy }) => {
  return (
      <div className="flex-col flex">
        <div className="text-sm color-text-2 mb-xxs text-left">
          <LocalizedMessage id={label} />
        </div>
        <div className="text-left color-text-1 flex-col" data-cy={dataCy}>
          {children}
        </div>
      </div>
  )
}

const RequestDetailsGeneralTab = ({ onBack }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const isInternalUser = useIsInternalUser();
  const isCustomer = useIsCustomer();
  const { id: requestIdFromUrl } = useParams<{ id: string }>();
  const locations = useLocations();

  const offersReduxState = useOffers();
  const quotesReduxState = useQuotes();

  const isNew = useIsNew();

  const requestId = isNew ? null : requestIdFromUrl;
  const request: RequestForQuoteView | undefined =
    useRequestForQuote(Number(requestId)) || undefined;

  useEffect(() => {
    if (!request?.readMark && !isNew)
      dispatch(markRequestRead(Number(requestId))).then();
  }, []);

  const defaultRequest = useEmptyRequest();
  const initFormData = isNew ? defaultRequest : {};

  const [formData, setFormData, hasChanges] = useFormState<
    Partial<RequestForQuoteView>
  >(request, initFormData);
  const [attachments, setAttachments] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showSendOfferRequestDialog, toggleShowSendOfferRequestDialog] =
    useToggle();
  const [disableSendOfferRequestButton, setDisableSendOfferRequestButton] =
    useState(true);

  const imageGallery = useRef(null);

  const readOnly = useIsReadOnly();
  const showMatchingTable = useIsMatching();

  const onAttachmentListChange = useCallback(
    (newAttachments) => {
      setFormData((formData) => ({
        ...formData,
        attachments: newAttachments.map((it) => it.id),
      }));
      setAttachments(newAttachments);
    },
    [setFormData],
  );

  const {
    status = RequestForQuoteStatus.DRAFT_AM,
    quotes = [],
    offers = [],
    customerCompanyId,
    shipToLocationId,
    accountManagerUserId,
    customerContactUserAccountIds = [], //
    incoterm,
    incotermAddressDestination,
    incotermPortIdDestination,
    currency,
    expectedDeliveryDate = "", //
    validityDate = "",
    dateCreated,
    dateUpdated,
    externalComment,
    internalComment, //
    products = [],
    createdByUserAccountId,
    updatedByUserAccountId,
  } = formData;

  const shipTo = useLocation(shipToLocationId);
  const shipToAddress = useLocationAddress(shipToLocationId);
  const isComboIncotermSelected = isComboIncoterm(incoterm);
  const canShowAddressField =
    useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const canShowPortField =
    !useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const contactPersonCustomer = useUserNames(customerContactUserAccountIds);
  const port = usePort(incotermPortIdDestination);

  const isDraft = useIsDraft(status);

  useEffect(() => {
    if (!!requestId) {
      dispatch(getRequestForQuoteAttachments(Number(requestId))).then(
        (attachments) => {
          setAttachments(attachments || []);
        },
      );
    } else if (isNew && isCustomer) {
      fillFieldsByCustomerData().then();
    }
  }, [requestId, readOnly, isNew]);

  const onDiscard = useCallback(() => {
    if (!!request?.id) setFormData({ ...request });
  }, [request, setFormData]);

  const save = useCallback(
    async (requestStatus: RequestForQuoteStatus) => {
      const requestToUpdate = { ...formData, status: requestStatus };

      if (requestToUpdate.expectedDeliveryDate)
        requestToUpdate.expectedDeliveryDate =
          requestToUpdate.expectedDeliveryDate.substring(0, 10); //only date

      try {
        setLoading(true);
        let updatedRequest: RequestForQuoteView;
        if (isNew) {
          updatedRequest = await dispatch(
            createRequestForQuote(
              requestToUpdate as CreateRequestForQuoteRequest,
            ),
          );
        } else {
          updatedRequest = await dispatch(
            updateRequestForQuote(requestToUpdate as any),
          );
        }
        invalidateChats(mutate);
        if (isNew) setFormData({});

        history.replace(`${requestsRootPath}${updatedRequest.id}`);
      } finally {
        setLoading(false);
      }
    },
    [formData, attachments, isNew, history, dispatch],
  );

  const onSave = useCallback(async () => {
    await save(status);
  }, [save, status]);

  const onCopyRequest = useCallback(async () => {
    history.replace(`${requestsRootPath}new/edit`);
    setFormData(formData => ({
      ...formData,
      status: isInternalUser
        ? RequestForQuoteStatus.DRAFT_AM
        : RequestForQuoteStatus.DRAFT_CUSTOMER,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      expectedDeliveryDate: undefined,
      validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
      createdByUserAccountId: currentUser.id,
      updatedByUserAccountId: currentUser.id,
      attachments: [],
      unreadMessagesCount: 0,
      products: formData.products.map((prod => ({
        ...prod,
        calculatedPrice: undefined,
      })))
    }));
  }, [currentUser, formData, setFormData, history]);

  const onFormDataChange = useCallback(
    (updatedData) => {
      setFormData({ ...formData, ...updatedData });
    },
    [formData, setFormData],
  );

  const fillFieldsByCustomerData = useCallback(async () => {
    try {
      const location = await dispatch(
        getLocation(
          currentUser.locations?.length === 1 ? currentUser.locations[0] : null,
        ),
      );
      onFormDataChange({
        customerCompanyId: currentUser.companyId,
        shipToLocationId: location?.id,
        customerContactUserAccountIds: [currentUser.id],
        incoterm: location?.incoterm || "",
        incotermAddressDestination: locationAddress(location),
        incotermPortIdDestination: location?.portId,
      });
    } catch {}
  }, [dispatch, currentUser, onFormDataChange]);

  const onFieldChange = useCallback(
    ({ target }) => {
      if (target.id === "customerCompanyId") {
        // reset port if customer changed
        onFormDataChange({
          [target.id]: target.value,
          incoterm: "",
          shipToLocationId: "",
          incotermAddressDestination: "",
          incotermPortIdDestination: "",
          customerContactUserAccountIds: [],
        });
      } else if (target.id === "shipToLocationId") {
        // reset manager and auto-fill other fields if ship-to changed
        const shipToLocation = locations[target.value];
        onFormDataChange({
          [target.id]: target.value,
          incoterm: shipToLocation?.incoterm,
          incotermAddressDestination: locationAddress(shipToLocation),
          incotermPortIdDestination: shipToLocation?.portId,
          customerContactUserAccountIds: [],
        });
      } else if (target.id === "validityDate") {
        if (target.value) {
          // set validityDate to 23:59:59
          const dateTimeEndOfTheDay = getDateTimeEndOfTheDayInISO(target.value);
          onFormDataChange({ [target.id]: dateTimeEndOfTheDay });
        }
      } else if (
        target.id === "incoterm" &&
        !!target.value &&
        !!shipToLocationId
      ) {
        // fill address and port by preferred value if it's empty
        const dataToUpdate: { [key: string]: any } = {
          [target.id]: target.value,
        };
        if (!incotermAddressDestination)
          dataToUpdate.incotermAddressDestination = shipToAddress;
        if (
          !incotermPortIdDestination &&
          isComboIncoterm(shipTo?.incoterm) === isComboIncotermSelected
        ) {
          dataToUpdate.incotermPortIdDestination = shipTo?.portId;
        } else if (
          incotermPortIdDestination &&
          isComboIncoterm(shipTo?.incoterm) !== isComboIncotermSelected
        ) {
          dataToUpdate.incotermPortIdDestination = "";
        }
        onFormDataChange(dataToUpdate);
      } else {
        onFormDataChange({ [target.id]: target.value });
      }
    },
    [
      onFormDataChange,
      shipToAddress,
      shipTo,
      shipToLocationId,
      incotermAddressDestination,
      incotermPortIdDestination,
      locations,
      isComboIncotermSelected,
    ],
  );

  const onOffersFieldSelect = useCallback(
    (selectedOfferId) => {
      // Remove offer id
      if (offers.includes(selectedOfferId)) {
        onFormDataChange({
          offers: offers.filter((it) => it !== selectedOfferId),
        });

        // Add offer id and pre-fill data
      } else {
        const offer = offersReduxState[selectedOfferId];
        if (offer && isDraft) {
          dispatch(getOfferAttachments(selectedOfferId)).then(
            onAttachmentListChange,
          );
          onFormDataChange({
            offers: [...offers, selectedOfferId],
            incoterm: offer.incoterm,
            incotermPortIdDestination: offer.incotermPortIdDestination,
            incotermAddressDestination: offer.incotermAddressDestination,
            currency: offer.currency,
            expectedDeliveryDate: offer.dateIncoDestination,
            validityDate: offer.validityDate,
            externalComment: offer.externalComment,
            internalComment: offer.internalComment,
            products: offer.products,
          });
        } else {
          onFormDataChange({ offers: [...offers, selectedOfferId] });
        }
      }
    },
    [
      offers,
      offersReduxState,
      onFormDataChange,
      dispatch,
      isDraft,
      onAttachmentListChange,
    ],
  );

  const onQuotesFieldSelect = useCallback(
    (selectedQuoteId) => {
      // Remove quote id
      if (quotes.includes(selectedQuoteId)) {
        onFormDataChange({
          quotes: quotes.filter((it) => it !== selectedQuoteId),
        });

        // Add quote id and pre-fill data
      } else {
        const quote = quotesReduxState[selectedQuoteId];
        if (quote && isDraft) {
          dispatch(getQuoteAttachments(selectedQuoteId)).then(
            onAttachmentListChange,
          );
          onFormDataChange({
            quotes: [...quotes, selectedQuoteId],
            incoterm: quote.incoterm,
            incotermPortIdDestination: quote.incotermPortIdDestination,
            incotermAddressDestination: quote.incotermAddressDestination,
            expectedDeliveryDate: quote.expectedDeliveryDate,
            validityDate: quote.validityDate,
            externalComment: quote.externalComment,
            internalComment: quote.internalComment,
            currency: quote.currency,
            customerCompanyId: quote.customerCompanyId,
            shipToLocationId: quote.shipToLocationId,
            products: quote.products,
          });
        } else {
          onFormDataChange({ quotes: [...quotes, selectedQuoteId] });
        }
      }
    },
    [
      quotes,
      quotesReduxState,
      onFormDataChange,
      dispatch,
      isDraft,
      onAttachmentListChange,
    ],
  );

  const onProductAdd = useCallback(
    (product, isMyProduct) => {
      const productId = isMyProduct ? product.productId : product.id;
      const lengthIds = isMyProduct ? product.lengthIds : [];

      const newProduct = {
        id: -new Date().getTime(),
        productId,
        productCode: product.productCode,
        productGroupId: product.productGroupId,
        productDescription: product.description,
        productType: product.productType,
        productActThick: product.msrActThick,
        productActWidth: product.msrActWidth,
        productActLength: product.msrActLength,
        uom: isMyProduct ? product.uom : product.standardUnitOfMeasure,
        lengthIds,
        quantity: 1,
        price: "",
        currency: product.currency,
        unitsContainer: product.unitsContainer,
        active: true,
      };
      onFormDataChange({ products: [...products, newProduct] });
    },
    [onFormDataChange, products],
  );

  const onProductFieldChange = useCallback(
    (id, event) => {
      const { target } = event;
      let { id: field, value } = target;
      if (target.id == "lengthIds" && !Array.isArray(target.value)) {
        value = [value];
      }

      const productToUpdate = products.find((product) => product.id === id);
      let updatedProduct = { ...productToUpdate, [field]: value };
      if (
          target.id == "uom" &&
          value == "LF" &&
          productToUpdate.lengthIds.length > 1
      ) {
        updatedProduct = {
          ...updatedProduct,
          lengthIds: [productToUpdate.lengthIds[0]],
        };
      }


      const updatedProducts = products.map((product) =>
        product.id === id ? updatedProduct : product,
      );
      onFormDataChange({ products: updatedProducts });
    },
    [onFormDataChange, products],
  );

  const onRemoveProduct = useCallback(
    (id) => {
      const updatedProducts = products.filter((product) => product.id !== id);
      onFormDataChange({ products: updatedProducts });
    },
    [onFormDataChange, products],
  );

  const todayDate = new Date();
  const isExpired = isRequestExpired(validityDate);

  const managers = useAMUserNames(accountManagerUserId, shipToLocationId);

  const attachmentsForGallery = useMemo(
    () => findImageAndPDFAttachments(attachments),
    [attachments],
  );

  // View
  if (readOnly)
    return (
      <div className={classNames("RequestDetailsGeneralTab")}>
        {isExpired && <WarningBanner>This Request is expired.</WarningBanner>}

        <div className={"flex-row align-center DataSectionContainer"}>
          <div className={"DataFieldsBlock"}>
            <div className={"Title"}>
              <LocalizedMessage id={"title.customer"} />
            </div>
            <div className={"Value"}>
              <CompanyView id={customerCompanyId} />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.ship.to"} />
            </div>
            <div className={"Value"}>
              <LocationName id={shipToLocationId} />
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.contact.person.customer"} />
            </div>
            <div className={"Value"}>{contactPersonCustomer || "-"}</div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.incoterm.destination"} />
            </div>
            <div className={"Value"}>
              <IncotermDestinationLabel
                incoterm={incoterm}
                address={incotermAddressDestination}
                portName={port?.name}
              />
            </div>

            <div className={"flex-row align-center ValueField"}>
              <div className={"flex"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.currency"} />
                </div>
                <div className={"Value"}>{currency}</div>
              </div>
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.expected.delivery"} />
            </div>
            <div className={"Value"}>
              {!!expectedDeliveryDate
                ? dayjs(expectedDeliveryDate).format("DD MMM YYYY")
                : "-"}
            </div>

            <div className={"Title"}>
              <LocalizedMessage id={"title.validity.date"} />
            </div>
            <div className={"Value"}>
              {!!validityDate ? dayjs(validityDate).format("DD MMM YYYY") : "-"}
            </div>
          </div>

          <div className={"DataFieldsBlock"}>
            <div className={"flex-row align-center flex-wrap CreationDatesGap"}>
              <div className={"flex-col"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.date.creation"} />
                </div>
                <div className={"Value"}>
                  {dayjs(dateCreated).format("DD MMM YYYY")}
                  &nbsp;
                  <UserName
                    id={createdByUserAccountId}
                    className={"flex text-truncate"}
                  />
                </div>
              </div>
              <div className={"flex-col"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.date.last.updated"} />
                </div>
                <div className={"Value"}>
                  {dayjs(dateUpdated).format("DD MMM YYYY")}
                  &nbsp;
                  <UserName
                    id={updatedByUserAccountId}
                    className={"flex text-truncate"}
                  />
                </div>
              </div>
            </div>
            <div className={"Title"}>
              <LocalizedMessage id={"title.status"} />
            </div>
            <div className={"Value"}>
              <RequestStatusLabel {...{ status }} />
            </div>
            <div className={"Title"}>
              <LocalizedMessage id={"title.salesperson"} />
            </div>
            <div className={"Value"}>{managers}</div>

            {isInternalUser && (
              <>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.reference"} />
                </div>
                <div className={"Value"}>
                  <RequestReference {...{ quotes, offers }} />
                </div>
              </>
            )}

            <div className={"Title"}>
              {isInternalUser
                ? "External Comment (ATTENTION: VISIBLE TO CUSTOMER):"
                : "Comment"}
            </div>
            <div className={"Value text-multiline"}>
              {externalComment || "-"}
            </div>

            {isInternalUser && (
              <>
                <div className={"Title"}>
                  {"Internal Comment (ATTENTION: VISIBLE TO UFP):"}
                </div>
                <div className={"Value text-multiline"}>
                  {internalComment || "-"}
                </div>
              </>
            )}
          </div>

          <div className={"DataFieldsBlock"}>
            <ImagesList
              images={attachments}
              onChange={onAttachmentListChange}
              readOnly={readOnly}
              onClick={(e, index) =>
                imageGallery.current.showGallery(
                  attachmentsForGallery,
                  e.target,
                  index,
                )
              }
            />
          </div>
        </div>

        <Card className="mt-lg">
          <RequestDetailsSummarySectionTable
            products={products as any[]}
            currency={currency}
            onProductFieldChange={onProductFieldChange}
            onRemoveProduct={onRemoveProduct}
            readOnly={readOnly}
          />
        </Card>

        {showMatchingTable && (
          <div className={"DataSectionContainer mt-lg mb-lg"}>
            <div className={"DataFieldsBlock px-0 scroll-x-container"}>
              <div className={"text-lg text-left ml-md"}>
                <LocalizedMessage id={"title.matching.my.vendors"} />
              </div>
              <RequestMatchingTable
                requestId={Number(requestId)}
                requestProducts={formData.products}
                currency={currency}
                showSendOfferRequestDialog={showSendOfferRequestDialog}
                toggleShowSendOfferRequestDialog={
                  toggleShowSendOfferRequestDialog
                }
                setDisableSendOfferRequestButton={
                  setDisableSendOfferRequestButton
                }
              />
            </div>
          </div>
        )}

        <ProductImagesGallery ref={imageGallery} />

        <RequestBottomButtons
          {...{
            save,
            hasChanges,
            onBack,
            loading,
            status,
            disableSendOfferRequestButton,
            toggleShowSendOfferRequestDialog,
            onCopyRequest,
          }}
        />
      </div>
    );

  // Edit
  return (
    <div className={classNames("RequestDetailsGeneralTab")}>
      <UnsavedChangesPrompt
        hasChanges={hasChanges && !isNew && !loading}
        {...{ onSave, onDiscard, onBack }}
      />

      {isExpired && <WarningBanner>This Request is expired.</WarningBanner>}

      <div className={"flex-row align-center DataSectionContainer"}>
        <div className={"DataFieldsBlock"}>
          {isNew && (
            <>
              <div className={"EditFieldMargin"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.status"} />
                </div>
                <div className={"Value"}>
                  <RequestStatusLabel {...{ status }} />
                </div>
              </div>

              <CustomerSelect
                id={"customerCompanyId"}
                className={"ValueField"}
                label={localizedString("title.customer")}
                value={customerCompanyId}
                onChange={onFieldChange}
              />
            </>
          )}

          {!isNew && (
            <div className={"EditFieldMargin"}>
              <div className={"Title"}>
                <LocalizedMessage id={"title.customer"} />
              </div>
              <div className={"Value"}>
                <CompanyView id={customerCompanyId} />
              </div>
            </div>
          )}

          {(isNew || isCustomer) && (
            <>
              <LocationSelect
                id={"shipToLocationId"}
                className={"ValueField"}
                label={localizedString("title.ship.to")}
                disabled={!customerCompanyId}
                companyId={customerCompanyId}
                value={shipToLocationId}
                onChange={onFieldChange}
                showCustomerCodes
              />

              <UserByLocationSelect
                id={"customerContactUserAccountIds"}
                className={"ValueField"}
                label={localizedString("title.contact.person.customer")}
                disabled={!shipToLocationId}
                locationId={shipToLocationId}
                value={customerContactUserAccountIds}
                onChange={onFieldChange}
                multiple
              />
            </>
          )}

          {!isNew && !isCustomer && (
            <>
              <div className={"EditFieldMargin"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.ship.to"} />
                </div>
                <div className={"Value"}>
                  <LocationName id={shipToLocationId} />
                </div>
              </div>

              <div className={"EditFieldMargin"}>
                <div className={"Title"}>
                  <LocalizedMessage id={"title.contact.person.customer"} />
                </div>
                <div className={"Value"}>{contactPersonCustomer || "-"}</div>
              </div>
            </>
          )}

          <div className={"flex-row align-center ValueField"}>
            <IncotermSelect
              id={"incoterm"}
              className={"flex"}
              label={localizedString("title.incoterm")}
              value={incoterm}
              onChange={onFieldChange}
            />
          </div>

          {canShowAddressField && (
            <TextField
              id={"incotermAddressDestination"}
              value={incotermAddressDestination}
              className={"ValueField"}
              label={localizedString("title.address")}
              onChange={onFieldChange}
              disabled={isComboIncotermSelected}
            />
          )}
          {canShowPortField && (
            <PortSelect
              id={"incotermPortIdDestination"}
              label={localizedString(
                isComboIncotermSelected ? "title.destination" : "title.port",
              )}
              value={incotermPortIdDestination}
              onChange={onFieldChange}
              byType={
                isComboIncotermSelected
                  ? destinationNonPortTypes
                  : destinationPortTypes
              }
            />
          )}

          <div className={"flex-row align-center ValueField"}>
            <Select
              id={"currency"}
              className={"flex"}
              label={localizedString("title.currency")}
              value={currency}
              onChange={onFieldChange}
              data={currencyArray}
              required
            />
          </div>

          {!isNew && (
            <>
              <FieldWithLabel label="title.expected.delivery" dataCy="ExpectedDeliveryDate">
                <DatePicker id="expectedDeliveryDate" required
                            mindate={todayDate}
                            date={expectedDeliveryDate}
                            onChange={onFieldChange}
                            data-cy={"ExpectedDelivery"}
                            className={"w-full"}
                />
              </FieldWithLabel>

              <FieldWithLabel label="title.validity.date" dataCy="ValidityDate">
                <DatePicker
                    id={"validityDate"}
                    className={"ValueField MidsizeField"}
                    mindate={todayDate}
                    date={validityDate}
                    onChange={onFieldChange}
                    data-cy={"validityDate"}
                />
              </FieldWithLabel>
            </>
          )}
        </div>

        <div className={"DataFieldsBlock"}>
          {!isNew && (
            <div className={"EditFieldMargin"}>
              <div className={"Title"}>
                <LocalizedMessage id={"title.status"} />
              </div>
              <div className={"Value"}>
                <RequestStatusLabel {...{ status }} />
              </div>
            </div>
          )}

          {isInternalUser && (
            <>
              <OfferSelect
                id={"offers"}
                label={localizedString("title.offer.reference")}
                value={offers}
                onSelect={onOffersFieldSelect}
                referenceParentId={requestId}
                showForRequestReference
                multiple
              />
              <QuoteSelect
                id={"quotes"}
                label={localizedString("title.quote.reference")}
                value={quotes}
                onSelect={onQuotesFieldSelect}
                referenceParentId={requestId}
                showForRequestReference
                multiple
              />
            </>
          )}

          {isNew && (
            <>
              <FieldWithLabel label="title.expected.delivery" dataCy="ExpectedDeliveryDate">
                <DatePicker id="expectedDeliveryDate" required
                            mindate={todayDate}
                            date={expectedDeliveryDate}
                            onChange={onFieldChange}
                            data-cy={"ExpectedDelivery"}
                            className={"w-full"}
                />
              </FieldWithLabel>

              <FieldWithLabel label="title.validity.date" dataCy="ValidityDate">
                <DatePicker
                    id={"validityDate"}
                    className={"ValueField MidsizeField"}
                    mindate={todayDate}
                    date={validityDate}
                    onChange={onFieldChange}
                    data-cy={"validityDate"}
                />
              </FieldWithLabel>
            </>
          )}

          <TextField
            id="externalComment"
            label={
              isInternalUser
                ? "External Comment (ATTENTION: VISIBLE TO CUSTOMER):"
                : "Comment"
            }
            value={externalComment}
            onChange={onFieldChange}
            multiline
            rows={5}
          />
          {isInternalUser && (
            <TextField
              id="internalComment"
              label={"Internal Comment (ATTENTION: VISIBLE TO UFP):"}
              value={internalComment}
              onChange={onFieldChange}
              multiline
              rows={5}
            />
          )}
        </div>

        <div className={"DataFieldsBlock"}>
          <ImagesList
            images={attachments}
            onChange={onAttachmentListChange}
            readOnly={readOnly}
            onClick={(e, index) =>
              imageGallery.current.showGallery(
                attachmentsForGallery,
                e.target,
                index,
              )
            }
          />
        </div>
      </div>

      <Card className="mt-lg">
        <RequestDetailsSummarySectionTable
          products={products as any[]}
          currency={currency}
          onProductFieldChange={onProductFieldChange}
          onRemoveProduct={onRemoveProduct}
          readOnly={readOnly}
        />
      </Card>

      <Card className="mt-lg">
          <Tabs defaultValue="my">
            <div className="flex-row gap-lg ml-md align-center mt-md">
              <div className="text-xl">
                <LC id="title.add.products" />
              </div>
              <HasPermissions
                permissions={[
                  GroupPermission.CAN_BE_ADMIN,
                  GroupPermission.CAN_BE_VENDOR,
                  GroupPermission.CAN_BE_SELLER
                ]}
              >
                <TabsList>
                  <TabsTrigger value="my">
                    <LC id="title.ship.to.products" />
                  </TabsTrigger>
                  <TabsTrigger value="all">
                    <LC id="title.all.products" />
                  </TabsTrigger>
                </TabsList>
              </HasPermissions>
            </div>
            <TabsContent value="my">
              <ProductsList
                onProductAdd={onProductAdd}
                locationId={shipToLocationId}
                canEdit
                addProductTitle="title.add"
                type="my_products"
              />
            </TabsContent>
            <TabsContent value="all">
              <ProductsList
                onProductAdd={onProductAdd}
                canEdit
                addProductTitle="title.add"
                type="all"
              />
            </TabsContent>
          </Tabs>
      </Card>

      <ProductImagesGallery ref={imageGallery} />

      <RequestBottomButtons
        {...{
          save,
          onBack,
          hasChanges,
          loading,
          status,
          disableSendOfferRequestButton,
          toggleShowSendOfferRequestDialog,
          onCopyRequest,
        }}
      />
    </div>
  );
};

export default RequestDetailsGeneralTab;
