import { FC, useEffect, useMemo } from "react";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {
  Button,
  Card,
  Checkbox,
  FormInput,
  Table,
  TableCell,
} from "~/Components/UI";
import {FormReturn, useDebounce, useFormData, useToggle} from "~/Hooks";
import { ProductTypeCombobox } from "~/Components/Products/ProductTypeCombobox";
import { TbChevronDown, TbChevronRight, TbFilter } from "react-icons/tb";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import API, {ProductCategoryView, ProductV2ProductType} from "~/API";
import { ls } from "~/Locales";
import { LinearLoader } from "~/Components/Loaders";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import useSWRImmutable from "swr/immutable";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import {
  setCategories,
  setProducts,
  toggleGroup,
  toggleProduct,
} from "~/Pages/Products/CataloguePage/CataloguePageReducer";
import { CatalogueButtons } from "~/Pages/Products/CataloguePage/Components/CatalogueButtons";
import ProductFilters from "~/Pages/Products/ProductsPage/Components/ProductFilters";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";

export const CataloguePage: FC = () => {
  const dispatch = useAppDispatch();
  const filters = useFormData({
    search: "",
    productType: "",
    group: [],
    category: [],
    species: [],
    surface: [],
    finish: [],
    grade: [],
    dryness: [],
    construction: [],
    classCode: [],
    lengthCode: [],
  }, {
    onChange: (_, data) => {
      table.resetExpanded();
      return data;
    }
  });
  const searchDebounce = useDebounce(filters.data.search);
  const filtersOpen = useToggle();

  const filtersObj = {
    search: searchDebounce,
    query: filters.data.productType
      ? `PRODUCT_TYPE == '${filters.data.productType}'`
      : "",
    ...filters.data,
  };
  const categories = useSWRImmutable(
    ["api/product/categories", filtersObj],
    () => API.getProductsCategories(filtersObj),
    { revalidateOnMount: true },
  );

  useEffect(() => {
    if (categories.data != undefined)
      dispatch(setCategories(categories.data.map((d) => d.name)));
  }, [categories.data]);

  const data = useMemo(() => categories.data || [], [categories.data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <StaticPage title="Catalogue">
      <PageTitle type="menu" title="title.catalogue" />

      <div className="flex flex-row gap-lg overflow-hidden">
        <div className="flex flex-col">
          <Card className="text-left flex-col gap-sm">
            <div className="flex-row gap-md">
              <FormInput
                id="search"
                formData={filters}
                type="search"
                showClear
                placeholder="placeholder.search"
                className="desktop-w-min-8xl"
              />
              <ProductTypeCombobox
                id="productType"
                formData={filters}
                clearValue=""
                showClear
                placeholder="title.product.type"
                className="desktop-w-min-6xl"
              />
              <Button
                label="title.filter"
                color="gray"
                onClicked={filtersOpen.toggle}
                leftIcon={<TbFilter size={16} />}
                className="ml-auto"
              />
            </div>
            <LinearLoader loading={categories.isLoading} />

            <Table table={table} renderSubComponent={(props) => <CategoryProducts row={props.row} filters={filters} />} />
          </Card>

          <BottomButtonsPanel>
            <CatalogueButtons categories={categories.data || []} />
          </BottomButtonsPanel>
        </div>

        <ProductFilters filterForm={filters} showFilters={filtersOpen} />
      </div>
    </StaticPage>
  );
};

const columnHelper = createColumnHelper<ProductCategoryView>();

const columns = [
  columnHelper.display({
    id: "check",
    cell: (data) => {
      const groupId = data.row.original.name;
      const selected = useAppSelector(
        (state) => state.cataloguePage.groups[groupId]?.selected,
      );
      const dispatch = useAppDispatch();
      const onSelect = () => dispatch(toggleGroup(groupId));

      return <Checkbox value={selected} onChange={onSelect} />;
    },
    size: 0,
  }),
  columnHelper.display({
    id: "expander",
    cell: (data) => (
      <Button
        color="gray"
        variant="text"
        leftIcon={
          data.row.getIsExpanded() ? (
            <TbChevronDown size={16} />
          ) : (
            <TbChevronRight size={16} />
          )
        }
        onClicked={() => data.row.toggleExpanded()}
      />
    ),
    size: 0,
  }),
  columnHelper.accessor("productsCount", {
    header: "#",
    size: 0,
  }),
  columnHelper.accessor("name", {
    header: ls("title.product.category"),
    size: 256,
  }),
  columnHelper.display({
    id: "productGroup",
    header: ls("title.product.group"),
    size: 512,
  }),
  columnHelper.display({
    id: "productLength",
    header: ls("title.length"),
    size: 0,
  }),
  columnHelper.display({ id: "productUom", header: ls("title.uom"), size: 0 }),
];

const CategoryProducts: FC<{ row: Row<ProductCategoryView>, filters: FormReturn<any> }> = (props) => {
  const dispatch = useAppDispatch();
  const group = props.row.original.name;
  const selectedProducts = useAppSelector(
    (state) => state.cataloguePage.groups[group]?.products,
  );

  const products = useSWRImmutable(
    ["api/products/categories", group],
    () =>
      API.getProductsPublicV3({
        ...props.filters.data,
        limit: 1000,
        active: true,
        category: [group],
      }),
    { revalidateOnMount: true },
  );

  useEffect(() => {
    if (products.data != undefined)
      dispatch(
        setProducts({ group, products: products.data.map((d) => d.id) }),
      );
  }, [products.data]);

  if (products.isLoading)
    return (
      <tr>
        <td colSpan={7}>
          <LinearLoader loading />
        </td>
      </tr>
    );

  return (
    <>
      {products.data.map((p) => {
        const onSelect = () =>
          dispatch(toggleProduct({ groupId: group, productId: p.id }));

        return (
          <tr className="bg-black-11-5 color-black-4" key={p.id}>
            <TableCell />
            <TableCell>
              <Checkbox
                value={selectedProducts[p.id] || false}
                onChange={onSelect}
              />
            </TableCell>
            <TableCell>{p.productCode}</TableCell>
            <TableCell>
              <ProductCatalogueDescriptionLink
                description={p.description}
                hasSyndigo={p.hasSyndigo}
                productCode={p.productCode}
                productId={p.id}
              />
            </TableCell>
            <TableCell>{p.productGroup}</TableCell>
            <TableCell>
              {[
                ProductV2ProductType.LINEAR_FOOTAGE.toString(),
                ProductV2ProductType.PANELS.toString(),
              ].includes(p.productType)
                ? p.msrActLength
                : ""}
            </TableCell>
            <TableCell>{p.standardUnitOfMeasure}</TableCell>
          </tr>
        );
      })}
    </>
  );
};
