import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import {
  dictionaryRootPath,
  lengthsRootPath,
  productGroupsRootPath,
  productsRootPath,
  randomLengthsRootPath,
} from "~/Services/Routes";
import DictionaryTab from "./DictionaryPage/DictionaryTab";
import ProductGroupsTab from "./GroupsPage/ProductGroupsTab";
import LengthsTab from "./LengthsPage/LengthsTab";
import RandomLengthsTab from "./RandomLengthsPage/RandomLengthsTab";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import ProductsTab from "~/Pages/Products/ProductsPage/ProductsTab";
import {useIsAdmin, useIsUFPAM, useIsUFPBuyer} from "~/Reducers/User";

const ProductsRootPage: FC = () => {
  const isAdmin = useIsAdmin();
  const isBuyer = useIsUFPBuyer();
  const isAM = useIsUFPAM();

  return (
    <StaticPage>
      <PageTabContainer title="title.products" showNav>
        <PageTab label="title.products" route={productsRootPath} />
        <PageTab label="title.product.groups" route={productGroupsRootPath} />
        {(isAdmin || isBuyer || isAM) &&
          <PageTab label="title.random.lengths" route={randomLengthsRootPath} />
        }
        <PageTab label="title.lengths" route={lengthsRootPath} />
        <PageTab label="title.dictionary" route={dictionaryRootPath} />
      </PageTabContainer>

      <div className="full-page-table-card">
        <Switch>
          <Route path={productsRootPath}>
            <ProductsTab />
          </Route>

          <Route path={productGroupsRootPath}>
            <ProductGroupsTab />
          </Route>

          {(isAdmin || isBuyer || isAM) &&
            <Route path={randomLengthsRootPath}>
              <RandomLengthsTab />
            </Route>
          }

          <Route path={lengthsRootPath}>
            <LengthsTab />
          </Route>

          <Route path={dictionaryRootPath}>
            <DictionaryTab />
          </Route>
        </Switch>
      </div>
    </StaticPage>
  );
};

export default ProductsRootPage;
