import {AppDispatch} from "~/StoreTypes";

const IGNORE_APP_VERSION = 'IGNORE_APP_VERSION';
const NEW_APP_VERSION_DETECTED = 'NEW_APP_VERSION_DETECTED';

export const newAppVersionDetected = (version: string) => (dispatch: AppDispatch) => dispatch({ type: NEW_APP_VERSION_DETECTED, version });

export interface UpdateBannerState {
  currentVersion?: string;
  newVersion?: string;
  ignoreVersion?: string;
}

export default (state: UpdateBannerState, action: any) => {
  state = state || {};
  switch (action.type) {
    case NEW_APP_VERSION_DETECTED:
      if (state.currentVersion)
        return { ...state, newVersion: action.version };
      else
        return { ...state, currentVersion: action.version };

    case IGNORE_APP_VERSION:
      return { ...state, ignoreVersion: action.version };

    default:
      return state;
  }
};
