import {useDispatch, useSelector} from "react-redux";
import Store from "~/Store";

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch<any>;

export const useAppSelector = useSelector.withTypes<RootState>();

// type dispatch so we get typescript with actions, doesn't work with older reducers
export const useAppDispatch = useDispatch.withTypes<any>();
// export const useAppDispatch = useDispatch.withTypes<AppDispatch>();