import {Order} from "~/API";
import {useState} from "react";
import {FormReturn} from "~/Hooks/useFormData";

export interface SortState {
  sortClicked: (val: string) => void;
  reset: () => void;
  data: { orderBy: string | undefined, order: Order };
}

/**
 * @param storeInForm will store write state in the given form and init from it, used for saving alongside form in url (optional)
**/
export const useSortState = (
  storeInForm?: FormReturn<any>,
): SortState => {
  const saveToForm = (data) => {
    storeInForm?.setData({ ...storeInForm.data, ...data }, false, true);
  }

  const [orderBy, setOrderBy] = useState(storeInForm?.data.orderBy || undefined);
  const [order, setOrder] = useState(storeInForm?.data.order || Order.DESC);


  return {
    sortClicked: (val) => {
      if (orderBy == val) {
        if (order == Order.ASC) {
          setOrder(Order.DESC);
          saveToForm({ order: Order.DESC });
        } else {
          setOrderBy(undefined);
          saveToForm({ orderBy: "" });
        }
      } else {
        setOrderBy(val);
        setOrder(Order.ASC);
        saveToForm({ orderBy: val, order: Order.ASC });
      }
    },
    reset: () => {
      setOrderBy(undefined);
      setOrder(Order.ASC);
      saveToForm({ orderBy: "", order: Order.ASC });
    },
    data: {
      orderBy,
      // undefined so backend uses default when no orderBy is selected
      order: orderBy == undefined ? undefined : order
    }
  }
}