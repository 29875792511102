import React, {useMemo} from "react";
import { classNames, isFunction } from "react-ui-basics/Tools";
import { Autocomplete, TextField } from "@mui/material";
import { unique } from "~/Utils";

const sortedData = (data, labels, filterValue) => {
  let labelFor;
  if (!!labels) {
    labelFor = isFunction(labels) ? labels : (key) => labels?.[key];
  } else {
    labelFor = (key) => String(key);
  }

  return [...data].sort((a, b) => {
    const indexA = labelFor(a)
      ?.toLowerCase?.()
      ?.indexOf(filterValue.toLowerCase());
    const indexB = labelFor(b)
      ?.toLowerCase?.()
      ?.indexOf(filterValue.toLowerCase());
    if (indexA < 0 && indexB > -1) return 1;
    if (indexB < 0 && indexA > -1) return -1;
    if (indexB < indexA) return 1;
    if (indexA < indexB) return -1;
    return 0;
  });
};

/**
 * @Deprecated new components at `~/Components/UI/Select` or `~/Components/UI/Combobox`
**/
class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      shakeLabel: false,
      delayBeforeShaking: false,
      isActive: true,
      filterValue: "",
      data: props.data,
    };
  }

  componentDidMount() {
    const selectLabel = this.select?.el?.querySelector("label");
    selectLabel?.addEventListener("animationend", () => {
      this.setState({
        shakeLabel: false,
        delayBeforeShaking: false,
      });
    });

    if (this.props.selectRef) {
      const { selectRef } = this.props;
      if (typeof selectRef === "function") selectRef(this);
      if (typeof selectRef === "object") selectRef.current = this;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, labels, skipLabelSorting } = this.props;
    const { filterValue } = this.state;
    if (prevProps.data !== data) {
      this.setState({
        data: skipLabelSorting ? data : sortedData(data, labels, filterValue),
      });
    } else if (
      !skipLabelSorting &&
      (prevProps.labels !== labels || prevState.filterValue !== filterValue)
    ) {
      this.setState({ data: sortedData(data, labels, filterValue) });
    }
  }

  jiggleIfInvalid = (shouldDelay) => {
    const { value, multiple } = this.props;
    const stringInvalid = typeof value === "string" && value?.trim?.() === "";
    const singleInvalid = !multiple && (stringInvalid || !value);
    const multipleInvalid = !!multiple && !value?.length;
    if (singleInvalid || multipleInvalid) {
      this.setState({
        showError: true,
        shakeLabel: true,
        delayBeforeShaking: !!shouldDelay,
      });
    }
  };

  onChangeHandler = (evt, selected) => {
    const { onChange, id, value, onSelect } = this.props;
    this.setState({ showError: false });
    onChange?.({ target: { id, value: selected } });
    onSelect?.(
      selected.find((v) => !value.includes(v)) ??
        value.find((v) => !selected.includes(v)),
    );
  };

  render() {
    const {
      className,
      id,
      value,
      label,
      placeholder,
      labels,
      multiple,
      required,
      onFilterInputChange,
      onScroll,
      childComponent,
      disabled,
      dataCy,
    } = this.props;

    const { data } = this.state;

    const getOptionLabel = (optionId) => {
      if (labels) {
        return labels[optionId] ?? `${optionId}`;
      }

      return `${optionId}`;
    };

    let options = data.map((d) => `${d}`);
    if (multiple) options = options.concat(value);
    else if (value !== null && value !== undefined && value !== "") {
      options.push(`${value}`);
    }
    options = unique(options);

    const shownValue = (() => {
      if (!value || value === "")
        return multiple ? [] : null;
      return multiple ? value : `${value}`;
    })();

    return (
      <div
        className={classNames(className, "flex-col")}
        onClick={this.onSelect}
      >
        {label && (
          <div className={"text-left color-text-2 text-sm mb-xxs"} id={"label"}>
            {label}
            {required && " *"}
          </div>
        )}
        <Autocomplete
          className={classNames(disabled ? "bg-black-11 rounded-xxs" : "")}
          ref={(el) => (this.select = el)}
          disableCloseOnSelect={multiple}
          disableClearable={required}
          id={id}
          disabled={disabled}
          onChange={this.onChangeHandler}
          multiple={multiple}
          value={shownValue}
          options={options}
          data-cy={dataCy}
          getOptionLabel={getOptionLabel}
          ListboxProps={{ onScroll }}
          renderOption={(props, option) => {
            if (childComponent !== undefined)
              return childComponent(props, option);

            return (
              <li {...props} key={`${option}`}>
                {getOptionLabel(option)}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              placeholder={placeholder}
              onChange={(evt) => onFilterInputChange?.(evt.target.value)}
              {...params}
            />
          )}
        />
      </div>
    );
  }
}

export default Select;
