import {FC, useMemo} from "react";
import useSWR from "swr";
import API, {DocumentView} from "~/API";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {createOrderDocumentColumns} from "~/Pages/Orders/DetailsPage/Components/OrderDocuments";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {LinearLoader} from "~/Components/Loaders";
import {Table} from "~/Components/UI";

export const OrderDocumentHoverTable: FC<{ id: number, isSalesOrder?: boolean }> = (props) => {
  const orderDocuments = useSWR(
    ["api/orders/documents", props.id, true, false],
    async () => {
      if (props.isSalesOrder)
        return API.getSalesOrderDocuments({ id: props.id, includeOnbaseDocs: false });
      return API.getPurchaseOrderDocuments({ id: props.id, includeOnbaseDocs: false });
    },
    { revalidateOnFocus: false }
  );

  const editRow = useFormRowEdit<DocumentView>("id", async () => {});
  const data = useMemo(() => orderDocuments.data?.filter((d) => d.active) || [], [orderDocuments.data]);
  const columns = useMemo(() => createOrderDocumentColumns(true, async () => {}, false), []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: { actions: false }
    },
    meta: { editRow }
  });

  return (
    <div className="w-max-11xl">
      <LinearLoader loading={orderDocuments.isValidating} />
      <Table table={table} />
    </div>
  );
};
