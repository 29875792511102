import './BottomButtonsPanel.scss';
import {cn} from "~/Utils";
import {FC, ReactNode} from "react";


const BottomButtonsPanel: FC<{
  children?: ReactNode;
  className?: string;
}> = (props) => {
  return (
    <div className={cn('BottomButtonsPanelMainContainer', props.className)}>
      {props.children}
    </div>
  );
};

export default BottomButtonsPanel;
