import { FC, useEffect, useState } from 'react';
import { useCurrentUser } from "~/Reducers/User";
import {
  endCallWithUser, setVideoCallOff, setVideoCallOn, useVideoCallState,
  useVideoCallToken,
} from "~/Reducers/VideoCall";
import "./VideoCall.scss";
import { Room } from "./Room";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { videoCallPath } from "~/Services/Routes";
import {useAppDispatch} from "~/StoreTypes";

// based on https://www.twilio.com/blog/video-chat-react-hooks tutorial

export const VideoCall: FC = () => {
  const { callId: videoCallRoomName } = useParams<{ chatId: string, callId: string }>();
  const currentUser = useCurrentUser();

  const currentUserId = currentUser?.id;
  // videoCallRoomName = "13-15"
  // if currentUserId is 13, then userId is 15
  // if currentUserId is 15, then userId is 13
  const userId = videoCallRoomName.split('-').map(x => parseInt(x)).find(x => x !== currentUserId);
  const dispatch = useAppDispatch();

  const [token, setToken] = useVideoCallToken(videoCallRoomName);

  const history = useHistory();
  const { pathname } = useLocation();

  const handleEndCall = () => {
    setToken(null);
    dispatch(endCallWithUser(userId));
    removeVideoCallFromUrl();
  }

  const removeVideoCallFromUrl = () => {
    if (pathname.includes(videoCallPath)) {
      const newPath = pathname.replace(`/${videoCallPath}/${videoCallRoomName}`, '');
      history.push(newPath);
    }
  }

  const { isVideoCallOn } = useVideoCallState();

  // set "videoCallOn" when component is mounted and remove it when component is unmounted
  // this component is mounted when there's videoCallPath in the url
  useEffect(() => {
    dispatch(setVideoCallOn);
    return () => {
      dispatch(setVideoCallOff)
    }
  }, []);

  const [prevIsVideoCallOn, setPrevIsVideoCallOn] = useState(isVideoCallOn);

  // remove videoCallRoomName from url when video call is ended
  useEffect(() => {
    if (prevIsVideoCallOn === true && isVideoCallOn === false) {
      removeVideoCallFromUrl();
    }
    setPrevIsVideoCallOn(isVideoCallOn);
  }, [isVideoCallOn]);


  return (
    <Room roomName={videoCallRoomName}
          userId={userId}
          token={token}
          handleEndCall={handleEndCall}/>
  )
};

