import API, {Order, ProductUfp} from "~/API";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import {ScopedMutator} from "swr/_internal";

export interface ProductV3Filters {
    search?: string,
    query?: string,
    orderBy?: string,
    order?: Order,
    offset?: number,
    limit?: number,
    group?: Array<string>,
    groupId?: Array<number>,
    category?: Array<string>,
    species?: Array<string>,
    surface?: Array<string>,
    finish?: Array<string>,
    grade?: Array<string>,
    dryness?: Array<string>,
    construction?: Array<string>,
    type?: Array<string>,
    classCode?: Array<string>,
    lengthCode?: Array<string>,
    productType?: string,
    active?: boolean
}

const PAGE_SIZE = 15;

export const useProductV3 = (id: number | undefined | null) =>
    useSWR(
        id == null ? null : ["api/v3/products", id],
        async () => await API.getProductV3({ id }),
        { revalidateOnFocus: false }
    );

export const useProductPublic = (id: number | undefined | null) =>
  useSWR(
    id == null ? null : ["api/v3/product/public", id],
    async () => await API.getProductPublicV3({ id }),
    { revalidateOnFocus: false }
  );

export const mutateProductV3 = (mutate: ScopedMutator, id: number) => mutate(["api/v3/products", id]);

const getProductsV3Key = (
    filters: ProductV3Filters,
    offset: number,
    previousPageData: ProductUfp[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/v3/products", filters, offset };
};

export const useProductsV3 = (filters: ProductV3Filters, pageSize?: number) =>
    useSWRInfinite(
        (i, p) => getProductsV3Key(filters, i * (pageSize || PAGE_SIZE), p),
        (key) =>
            API.getProductsV3({
                ...filters,
                offset: key.offset,
                limit: (pageSize || PAGE_SIZE),
            }),
        {
            revalidateFirstPage: true,
        },
    );
