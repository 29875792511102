import {
  getLengths,
  getLengthByProductGroup,
  useLengthLabels,
  useLengthsByIds
} from "~/Reducers/Lengths";
import SelectWithPaging from "./SelectWithPaging";
import { classNames } from "react-ui-basics/Tools";
import "./LengthSelect.scss";
import { useMemo } from "react";


const LengthSelect = ({ byGroup = null, ...props }) => {
  
  // props.value can be a single id or an array of ids
  const lengthIds = useMemo(() => {
      return !!props.value
        ? props.multiple ? props.value : [props.value]
        : null;
    }, [props.value]
  )
  
  useLengthsByIds(lengthIds)
  
  return <SelectWithPaging {...props}
    getData={getLengths}
    entityId={byGroup}
    getDataByEntityId={getLengthByProductGroup}
    useDataLabels={useLengthLabels}
    className={classNames('LengthSelect', props.className)}
  />;
};

export default LengthSelect;
