import BaseLoginPage from "../BaseLoginPage";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";

const PasswordResetAlreadyPage: FC = () => {
  usePageTitle("Password Reset");

  return (
    <BaseLoginPage>
      <p>Hello!</p>

      <div>
        It appears the password reset link you've tried to use has already been
        used. To keep your account secure, our reset links are only usable one
        time. Don't worry, you can easily request a new one:
        <ol type="1">
          <li>
            Head back to the <a href="/forgot-password">password reset page</a>{" "}
            on our platform.
          </li>
          <li>
            Enter your email, and we'll promptly send you a new reset link.
          </li>
        </ol>
      </div>

      <p>
        If you run into any issues or need assistance, feel free to reach out to
        us at <a href="mailto:hello@timberbase.com">hello@timberbase.com</a>. We
        appreciate your patience and understanding.
      </p>

      <div>Best regards,</div>
      <div>The TimberBase Team</div>
    </BaseLoginPage>
  );
};

export default PasswordResetAlreadyPage;
