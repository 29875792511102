import { loadUserPreferences, saveUserPreferences } from '~/Services/UserPreferences';
import {AppDispatch, useAppSelector} from "~/StoreTypes";


export const useUserPreferences = () => useAppSelector(state => state.userPreferences);

export const savePreferences = (preferences: { lastUserId?: string } & any, saveLocally = true) => (dispatch: AppDispatch) => {
  if (saveLocally) saveUserPreferences(preferences);
  dispatch({ type: 'ACTIONS_USER_PREFS_SAVE', preferences });
};

export interface UserPreferencesState {
  showProductsList: boolean;
  showPriceConfigurator: boolean;
  lastUserId: string;
  showBellNotifications: boolean;
  silentMode: boolean;
}

const defaultState: UserPreferencesState = {
  showProductsList: false,
  showPriceConfigurator: true,
  lastUserId: "",
  showBellNotifications: true,
  silentMode: false
};

const INITIAL_STATE = {
  ...defaultState,
  ...loadUserPreferences(),
}

const reducer = (state: UserPreferencesState, action: any) => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case 'ACTIONS_USER_PREFS_LOAD':
    case 'ACTIONS_USER_PREFS_SAVE':
      return {
        ...state,
        ...action.preferences,
      };
    default:
      return state;
  }
};

export default reducer;
