import { FC, useEffect } from "react";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {Button, Card, FormSwitchDescriptive} from "~/Components/UI";
import API, { FeatureFlag } from "~/API";
import { ls } from "~/Locales";
import useSWR from "swr";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import {useFormData, useMutateHook} from "~/Hooks";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import {loadEnabledFlags} from "~/Reducers/FeatureFlags";
import {useAppDispatch} from "~/StoreTypes";
import {trackEvent} from "~/Services/Tracking";


export const FeatureFlagsPage: FC = () => {
  const dispatch = useAppDispatch();
  const flags = useSWR(
      ["api/featureFlags/all"],
      () => API.getAllFeatureFlags()
    );
  const saveFlags = useMutateHook(
      ["api/featureFlags/all"],
      (args) => API.updateFeatureFlags({}, args),
      (_old, args) => args
    );

  const form = useFormData<Partial<Record<FeatureFlag, boolean>>>({});

  useEffect(() => {
    form.setData(
      flags.data || {},
      true,
    );
  }, [flags.data]);

  const onSave = async () => {
    await saveFlags.call(form.data);
    await dispatch(loadEnabledFlags);
    trackEvent("Feature Flags Updated");
  }

  if (flags.isLoading) return <LoadingPage />;

  return (
    <StaticPage title="Feature Flags">
      <PageTitle title="feature.flags" type="menu" />
      <Card className="text-left flex-col gap-md">
        {Object.values(FeatureFlag).map((flag) =>  (
          <FormSwitchDescriptive
            id={flag}
            formData={form}
            label={`feature.flag.${flag}`}
            description={ls(`feature.flag.desc.${flag}`)}
            key={flag}
          />
        ))}
      </Card>
      <BottomButtonsPanel>
        <Button
          label="title.save.changes"
          onClicked={onSave}
          disabled={!form.hasChanges}
        />
      </BottomButtonsPanel>
    </StaticPage>
  );
};
