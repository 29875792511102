import RBCheckbox from 'react-ui-basics/Checkbox';
import './Switch.scss';
import { classNames } from "react-ui-basics/Tools";


/**
 * @deprecated new component in `~/Components/UI/Switch`
 **/
const Switch = ({className = undefined, checked = undefined, ...props}) => {
  const updatedProp = (checked !== undefined) ? {...props, value: checked} : {...props};
  return (
    <RBCheckbox className={classNames("Switch", className)} {...updatedProp}/>
  );
};

export default Switch;
