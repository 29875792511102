import {useAppSelector} from "~/StoreTypes";

const INITIAL_STATE = [
  { "id": 1, "code": "USD", "symbol": "$" },
  { "id": 2, "code": "INR", "symbol": "₹" },
  { "id": 4, "code": "VND", "symbol": "₫" },
  { "id": 5, "code": "TWD", "symbol": "NT$" },
  { "id": 6, "code": "EUR", "symbol": "€" },
  { "id": 7, "code": "CNY", "symbol": "¥" },
];

const defaultCurrencySymbol = '$';

export const useCurrencies = () => useAppSelector(state => state?.currencies);
export const findCurrencySymbol = (currency: string, currencies: CurrenciesState) => currencies.find(item => item.code === currency)?.symbol || defaultCurrencySymbol;
export const useFindCurrencySymbol = (currency: string) => findCurrencySymbol(currency, useCurrencies());

interface Currency {
  id: number,
  code: string,
  symbol: string,
}

export type CurrenciesState = Array<Currency>

const reducer = (state: CurrenciesState, _: any): CurrenciesState => {
  state = state || INITIAL_STATE;
  return state;
};

export default reducer;
