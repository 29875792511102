import {LocalizedMessageKey, localizedString} from "~/Locales";
import {
  useIsValidIncoterm,
  useIncotermUsesAddress,
  isComboIncoterm
} from "~/Reducers/Incoterms";
import {FC} from "react";


const IncotermDestinationLabel: FC<{
  incoterm: string,
  address: string,
  portName?: string
}> = ({ incoterm, address, portName }) => {
  const isComboIncotermSelected = isComboIncoterm(incoterm);
  const canShowAddressField = useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const canShowPortField = !useIncotermUsesAddress(incoterm) || isComboIncotermSelected;

  const isValidIncoterm = useIsValidIncoterm(incoterm);
  const incotermString = isValidIncoterm ? localizedString(`incoterm.${incoterm}` as LocalizedMessageKey) : '';

  const label = incotermString
    + (canShowAddressField && address ? `/${address}` : '')
    + (canShowPortField && portName ? `/${portName}` : '');

  return (<>{label}</>);
};

export default IncotermDestinationLabel;
