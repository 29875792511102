import API, {Notification, NotificationType} from "~/API";
import {FC, useEffect, useMemo, useState} from "react";
import {TbReportMoney} from "react-icons/tb";
import {NotificationCard, useLink} from "~/Components/Notifications/NotificationCard";

export const OfferNotification: FC<{
  notification: Notification;
  markRead: () => void;
  refetchNotifications: () => void;
}> = ({ notification, markRead, refetchNotifications }) => {
  const link = useLink(notification.data.refs, NotificationType.OFFER_FEEDBACK);
  const [chatMessage, setChatMessage] = useState(null);

  useEffect(() => {
    const messageId = notification.data?.refs?.["CHAT_MESSAGE"]?.[0];
    if (!!messageId) {
      API.getChatMessage({ id: messageId ? Number(messageId) : null }).then((message) => {
        setChatMessage(message);
      });
    }
  }, []);

  const text = useMemo(() => {
    const offerId = notification.data?.refs?.["OFFER"]?.[0];
    const username = notification.data?.refs?.["USER"]?.[0];
    if (!chatMessage)
      return `${username} has answered in the Offer O${offerId}`;
    let textJSON: any;
    try {
      textJSON = JSON.parse(chatMessage.text) || {};
    } catch {
      return `${username} has answered in the Offer O${offerId}`;
    }
    const value = textJSON?.value;
    if (!!value) return `${username} is interested in the Offer O${offerId}`;
    return `${username} is not interested in the Offer O${offerId}`;
  }, [chatMessage, notification]);

  return (
    <NotificationCard
      icon={<TbReportMoney size={20} />}
      link={link}
      dateCreated={notification.dateCreated}
      markRead={markRead}
      read={notification.read}
      notificationId = {notification.id}
      refetchNotifications={refetchNotifications}
    >
      {text}
    </NotificationCard>
  );
};
