import {ProductV3Filters, useProductsV3} from "~/Data/Products/Products";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";
import API, {ProductUfp} from "~/API";

export const ProductsComboBox = <T,>(
  props: {
      filters?: ProductV3Filters,
  } & ExtendDataCombobox<T>,
) => (
  <ApiDataCombobox<T, any, ProductUfp>
    {...props}
    getLabel={(p) => `${p.productCode} - ${p.description}`}
    getId={(c) => c.id}
    useListData={useProductsV3}
    getSingle={async (id) => API.getProductV3({ id })}
    filters={{ active: true, ...props.filters  }}
  />
);
