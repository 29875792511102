import {FC} from "react";
import {Button, Dialog, FormCombobox, FormInput} from "~/Components/UI";
import { ls } from "~/Locales";
import {TbPlus} from "react-icons/tb";
import {useFormData, useMutate, useToggle} from "~/Hooks";
import API, {PortType} from "~/API";
import {CountryCombobox} from "~/Components/Comboboxes/CountryCombobox";
import * as z from "zod";
import {zodMsg} from "~/Utils";
import {trackEvent} from "~/Services/Tracking";

const validation = z.object({
  type: z.nativeEnum(PortType),
  city: z.string().min(1, zodMsg("error.required", ["Location"])),
  country: z.string().min(1, zodMsg("error.required", ["Country"])),
  code: z.string().min(1, zodMsg("error.required", ["Code"])),
});

export const AddDestinationDialog: FC<{
  refreshPorts: () => Promise<any>;
}> = (props) => {
  const open = useToggle();
  const form = useFormData({
    active: true,
    type: PortType.PORT,
    city: "",
    country: "",
    code: ""
  }, { validation });

  const addPort = useMutate(async () => {
    if (form.isValid()) {
      await API.addPort({}, form.data);
      await props.refreshPorts();
      open.toggleFalse();
      trackEvent("Destination Added");
    }
  });

  return (
    <Dialog
      title={ls("title.add.destination")}
      trigger={
        <Button
          leftIcon={<TbPlus size={16} />}
          label="title.add.destination"
        />
      }
      open={open}
      onOpenChange={form.clear}
    >
      <div className="desktop-w-8xl flex-col gap-md">
        <FormInput
          id="city"
          formData={form}
          label="title.location"
        />

        <FormInput
          id="code"
          formData={form}
          label="title.code"
        />

        <FormCombobox
          id="type"
          formData={form}
          getLabel={(t: PortType) => ls(`port.type.${t}`)}
          data={Object.keys(PortType)}
          label="title.type"
        />

        <CountryCombobox
          id="country"
          formData={form}
          label="title.country"
        />

        <Button
          label="title.add.destination"
          onClicked={addPort.mutate}
          loading={addPort.loading}
        />
      </div>
    </Dialog>
  );
}