import API, {ProductPublicView} from "~/API";
import {composedItems} from "./Utils/Compose";
import {deepCopyOf} from "~/Utils";
import {useGetEntity, useGetEntityList} from "./Utils/Entity";
import {useMemo} from "react";
import {AppDispatch, RootState} from "~/StoreTypes";


const PUBLIC_PRODUCTS_PER_PAGE_COUNT = 40;

const productsPublicSelector = (state: RootState) => state.productsPublic;
const productPublicSelectorCreator = (productId: number) => (state: RootState): ProductPublicView => state.productsPublic[productId];

export const useProductsPublic = () => useGetEntityList(getProductsPublic, productsPublicSelector);

export const useProductPublic = (productId: number) => useGetEntity(productId, getProductPublic, productPublicSelectorCreator);

interface ProductsPublicFilters {
  offset?: number;
  limit?: number;
  groupId?: number[];
}

export const getProductsPublic = (filters: ProductsPublicFilters = {}) => async (dispatch: AppDispatch) => {
  const params = {
    ...filters,
    offset: filters?.offset || 0,
    limit: filters?.limit || PUBLIC_PRODUCTS_PER_PAGE_COUNT,
  };

  try {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCTS_START' });
    const products = await API.getProductsPublicV3(params);
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCTS_SUCCESS', products, offset: params.offset });
    return products;
  } catch (error) {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCTS_ERROR', error });
  }
};

export const getProductPublic = (productId: number) => async (dispatch: AppDispatch) => {
  try {
    const product = await API.getProductPublicV3({ id: productId });
    dispatch({ type: 'ACTIONS_GET_PRODUCT_PUBLIC_SUCCESS', product });
    return deepCopyOf(product);
  } catch (error) {
  }
};

const useProductPublicKeys = () => {
  const products = useProductsPublic();
  return useMemo(() => Object.keys(products), [products]);
};

export const useProductPublicCodeLabels = () => {
  const products = useProductsPublic();
  const productKeys = useProductPublicKeys();
  return useMemo(() => {
    return productKeys.reduce((a, option) => {
      const product = products[option];
      return ({
        ...a,
        [option]: `${product?.productCode} (${product?.description})`
      });
    }, {})
  }, [productKeys]);
};

export interface ProductsPublicState {
  [id: number]: ProductPublicView;
  error?: any;
}

// reducer
const INITIAL_STATE: ProductsPublicState = {};

const reducer = (state = INITIAL_STATE, action: any): ProductsPublicState => {
  switch (action.type) {
    case 'ACTIONS_GET_PUBLIC_PRODUCTS_START':
      return { ...state, error: null };
    case 'ACTIONS_GET_PUBLIC_PRODUCTS_SUCCESS':
      const loadedProducts = action.products.map((product: ProductPublicView) => (!!state[product.id] ? { ...state[product.id], ...product } : product));
      return { ...state, ...composedItems(loadedProducts) };
    case 'ACTIONS_GET_PRODUCT_PUBLIC_SUCCESS':
      return { ...state, [action.product.id]: action.product };
    case 'ACTIONS_GET_PUBLIC_PRODUCTS_ERROR':
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export default reducer;