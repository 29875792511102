import { FC, useEffect } from "react";
import { DateTimeView } from "~/Components/Views/DateView";
import {Button, For, FormInput, Show} from "~/Components/UI";
import Toast from "~/Components/Toast";
import { LinearLoader } from "~/Components/Loaders";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { useJobs, useTriggerJob } from "~/Data/Admin/Jobs";
import {ScrollPage} from "~/Components/Layout/Pages";
import {useFormData} from "~/Hooks";
import {trackEvent} from "~/Services/Tracking";

const JobSchedulerPage: FC = () => {
  const jobs = useJobs();
  const triggerJob = useTriggerJob();

  useEffect(() => {
    if (jobs.error !== undefined) {
      Toast.ApiError(jobs.error);
    }
  }, []);

  const onTriggerJob = (jobKey: string) => {
    triggerJob
      .call({ jobKey })
      .then(() => Toast.Success("Job Triggered Successfully!"))
      .then(() => trackEvent("Job Triggered Manually"))
      .catch(Toast.ApiError);
  };

  const filterForm = useFormData({
    search: ""
  });

  const filteredJobs = jobs.data?.filter(j => j.jobKey.toLowerCase().includes(filterForm.data.search.toLowerCase())) || undefined;

  return (
    <ScrollPage title="Job Scheduler">
      <PageTitle type="menu" title="title.job.scheduler" />
      <FormInput
        id="search"
        formData={filterForm}
        placeholder="title.search"
        className="w-max-8xl mb-sm"
        showClear
      />
      <LinearLoader loading={jobs.isLoading} />
      <div className="flex-col gap-lg mb-md">
        <For each={filteredJobs}>
          {(job) => (
            <div key={job.jobKey} className="rounded-xxs bg-black-12 p-lg">
              <div className="flex-row flex-wrap gap-sm align-baseline justify-between">
                <div className="text-lg text-bold text-left">{job.jobKey}</div>
                <Button
                  label="title.trigger"
                  loading={triggerJob.isMutating}
                  disabled={job.isLocked}
                  onClicked={() => onTriggerJob(job.jobKey)}
                />
              </div>
              <div className="flex-row flex-wrap gap-sm text-left mt-lg">
                <div className="flex flex-col gap-sm">
                  <div className="flex-row gap-sm">
                    <div className="text-bold">Last Started:</div>
                    <DateTimeView date={job.lastStarted} />
                  </div>
                  <div className="flex-row gap-sm">
                    <div className="text-bold">Last Finished:</div>
                    <DateTimeView date={job.lastFinished} />
                  </div>
                  <div className="flex-row gap-sm">
                    <div className="text-bold">Is Locked (Running):</div>
                    <div>{job.isLocked ? "true" : "false"}</div>
                  </div>
                  <div className="flex-row gap-sm">
                    <div className="text-bold">Lock Expiration:</div>
                    <div>{job.lockExpirationSeconds} second(s)</div>
                  </div>
                </div>

                <Show when={job.auditInfo !== undefined}>
                  <div className="flex flex-col gap-sm">
                    <For each={job.auditInfo?.split(",")}>
                      {(info) => (
                        <div key={info} className="flex-row gap-sm">
                          <div className="text-bold">
                            {info.trim().split("=")[0]}:
                          </div>
                          <div>{info.trim().split("=")[1]}</div>
                        </div>
                      )}
                    </For>
                  </div>
                </Show>
              </div>
            </div>
          )}
        </For>
      </div>
    </ScrollPage>
  );
};

export default JobSchedulerPage;
