import { LocalizedMessage } from "~/Locales";
import Button from "./Button";
import { FilterIcon } from "./Icons";
import './FiltersButton.scss';


const FiltersButton = ({ showFilters, toggleShowFilters, showFilterDot = false }) => {
  return (
    <Button className={'FiltersButtonMainContainer'}
      view={'text'}
      leftIcon={<FilterIcon size={16} />}
      style={{ color: showFilters ? '#3F87DE' : '#1C1F31' }}
      onClick={toggleShowFilters}
    >
      {showFilterDot &&
        <div className={'FiltersButtonHasFilterDot'}
          style={{ backgroundColor: showFilters ? '#3F87DE' : '#1C1F31' }}
        />
      }
      <LocalizedMessage id={'title.filter'} />
    </Button>
  );
};

export default FiltersButton;
