import API, { Notification, NotificationType } from "~/API";
import { FC, useEffect, useMemo, useState } from "react";
import {TbClipboardList} from "react-icons/tb";
import {
  NotificationCard,
  useLink,
} from "~/Components/Notifications/NotificationCard";

export const QuoteNotification: FC<{
  notification: Notification;
  markRead: () => void;
  refetchNotifications: () => void;
}> = ({ notification, markRead, refetchNotifications }) => {
  const link = useLink(notification.data.refs, NotificationType.QUOTE_FEEDBACK);
  const [chatMessage, setChatMessage] = useState(null);

  useEffect(() => {
    const messageId = notification.data?.refs?.["CHAT_MESSAGE"]?.[0];
    if (!!messageId) {
      API.getChatMessage({ id: messageId ? Number(messageId) : null }).then(
        (message) => {
          setChatMessage(message);
        },
      );
    }
  }, []);

  const text = useMemo(() => {
    const quoteId = notification?.data?.refs?.["QUOTE"]?.[0];
    const username = notification?.data?.refs?.["USER"]?.[0];
    if (!chatMessage)
      return `${username} has answered in the Quote Q${quoteId}`;
    let textJSON: any;
    try {
      textJSON = JSON.parse(chatMessage.text) || {};
    } catch {
      return `${username} has answered in the Quote Q${quoteId}`;
    }
    const value = textJSON?.value;
    if (!!value) return `${username} is interested in the Quote Q${quoteId}`;
    return `${username} is not interested in the Quote Q${quoteId}`;
  }, [chatMessage, notification]);

  return (
    <NotificationCard
      icon={<TbClipboardList size={20} />}
      markRead={markRead}
      dateCreated={notification.dateCreated}
      link={link}
      read={notification.read}
      notificationId={notification.id}
      refetchNotifications={refetchNotifications}
    >
      {text}
    </NotificationCard>
  );
};
