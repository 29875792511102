import React, {
  FC, useEffect,
} from "react";
import API, {UserAccountView, UserEmailSettingsView} from "~/API";
import {LC} from "~/Locales";
import {FormReturn} from "~/Hooks";
import {FormSwitchDescriptive} from "~/Components/UI";
import useSWR from "swr";

interface UserDetailsEmailSettingsProps {
    user: UserAccountView,
    emailForm: FormReturn<UserEmailSettingsView>,
    isCurrentUser: boolean,
    isVendor: boolean
}

const UserDetailsEmailSettings: FC<UserDetailsEmailSettingsProps> =
    ({user, emailForm, isCurrentUser, isVendor}: UserDetailsEmailSettingsProps) => {
    const settings = useSWR(["api/user/email/settings", user.id], () => API.getUserEmailSettings({ id: user.id }));

    useEffect(() => {
      if(settings.data != null)
        emailForm.setData(settings.data, true);
    }, [settings.data]);

    return (
        <div className="flex-col gap-md">
          <div className="text-semibold text-left mt-3xl text-xl">
            <LC id="title.user.email.settings"/>
          </div>

          <FormSwitchDescriptive
            id="chatEmailOptOut"
            invertValue
            formData={emailForm}
            disabled={settings.isLoading || !isCurrentUser}
            label="title.user.email.chat"
            description="Notifies you when you have have unread chat messages."
          />

          <FormSwitchDescriptive
            id="orderNewEmailOptOut"
            invertValue
            formData={emailForm}
            disabled={settings.isLoading || !isCurrentUser}
            label="title.user.email.opt.out.new.order"
            description="Notifies you when you have a new order in your account."
          />

          <FormSwitchDescriptive
            id="orderUpdateEmailOptOut"
            invertValue
            formData={emailForm}
            disabled={settings.isLoading || !isCurrentUser}
            label="title.user.email.opt.out.update.order"
            description="Notifies you when when an order in your account has been updated."
          />

          <FormSwitchDescriptive
            id="quoteNewEmailOptOut"
            invertValue
            formData={emailForm}
            disabled={settings.isLoading || !isCurrentUser}
            label="title.user.email.opt.out.new.quote"
            description="Notifies you when when you have a new quote in your account."
          />

          <FormSwitchDescriptive
            id="quoteUpdateEmailOptOut"
            invertValue
            formData={emailForm}
            disabled={settings.isLoading || !isCurrentUser}
            label="title.user.email.opt.out.update.quote"
            description="Notifies you when when a quote in your account has been updated."
          />

          {isVendor && (
            <FormSwitchDescriptive
              id="promiseDateEmailOptOut"
              invertValue
              formData={emailForm}
              disabled={settings.isLoading || !isCurrentUser}
              label="title.user.email.opt.out.promise.date"
              description="Notifies you when when an upcoming promise date is unconfirmed."
            />
          )}
        </div>
    )

}

export default UserDetailsEmailSettings;
