import IconButton from "./IconButton";
import React from "react";
import { connect } from 'react-redux'
import { openLeftMenu } from "~/Reducers/LeftMenu";
import "./LeftMenuIcon.scss";
import { MenuIcon } from "./Icons";

const LeftMenuIcon = (props) => (
  <div className="LeftMenuButton">
    <IconButton className={props.className} onClick={()=>props.openLeftMenu()}>
      <MenuIcon size={24} />
    </IconButton>
  </div>
);

export default connect(null, { openLeftMenu })(LeftMenuIcon);
