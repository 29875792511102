import { FC, useMemo } from "react";
import { LC } from "~/Locales";
import { useCompany } from "~/Reducers/Companies";
import {
  useCurrentUser,
  useIsInternalCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import { useCompanyLocations, useLocations } from "~/Reducers/Locations";
import { useUserPublic } from "~/Reducers/Users";
import DashboardTables from "./Components/DashboardTables";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { ScrollPage } from "~/Components/Layout/Pages";
import { Card } from "~/Components/UI";
import {
  DashboardChatButton,
  DashboardVideoButton,
} from "~/Pages/Dashboard/Components/DashboardButtons";
import {UserName} from "~/Components/Users/UserName";
import {UserAvatarDirect} from "~/Components/Users/UserAvatar";

const DashboardPage: FC = () => {
  const currentUser = useCurrentUser();

  return (
    <ScrollPage title="Dashboard">
      <PageTitle
        titleComp={
          <div className="text-xl">
            <LC id="title.welcome.back">{currentUser.name}</LC>
          </div>
        }
        type="menu"
      />
      <DashboardHeader />
      <DashboardTables />
    </ScrollPage>
  );
};

export default DashboardPage;

const DashboardHeader: FC = () => {
  const isInternalUser = useIsInternalUser();

  return (
    <div className="grid grid-auto-fit-11xl gap-md">
      {!isInternalUser && <Managers />}
    </div>
  );
};

const Managers: FC = () => {
  const currentUser = useCurrentUser();
  const company = useCompany(currentUser.companyId);
  const locations = useLocations();
  const companyLocationIds = useCompanyLocations(currentUser.companyId) || [];

  const filteredLocationIds = useMemo(
    () =>
      companyLocationIds.filter((locId) =>
        currentUser.locations?.includes(locId),
      ),
    [currentUser, companyLocationIds],
  );

  const managerIds = useMemo(() => {
    const ids = filteredLocationIds
      .map((locationId) => locations[locationId]?.accountManagerUserId)
      .filter((id) => !!id); // remove undefined/null values
    return [...new Set(ids)]; // remove duplicates
  }, [filteredLocationIds, locations]);

  if (
    !!company?.accountManagerUserId &&
    !managerIds.includes(company?.accountManagerUserId)
  ) {
    managerIds.push(company.accountManagerUserId);
  }
  if (!managerIds.length) return null;

  return (
    <Card className="flex-col mb-lg">
      {managerIds.map((managerId) => (
        <Manager key={managerId} {...{ managerId }} />
      ))}
    </Card>
  );
};

const Manager: FC<{ managerId: number }> = ({ managerId }) => {
  const isInternalCustomer = useIsInternalCustomer();
  const companyManager = useUserPublic(managerId);
  const { name, id: companyManagerId } = companyManager || {};

  return (
    <div className="desktop-flex-row mobile-flex-col gap-md justify-between w-full">
      <div className="flex-row align-center flex">
        <UserAvatarDirect user={companyManager} />
        {!!name && (
          <div className="text-lg ManagerName flex-row align-center ml-md">
            <UserName id={managerId} />
            &nbsp;
            <span className="color-text-3">
              (
              <LC
                id={
                  isInternalCustomer ? "title.buyer" : "title.account.manager"
                }
              />
              )
            </span>
          </div>
        )}
      </div>

      <div className="flex-row align-center">
        <DashboardVideoButton userId={companyManagerId} />
        <DashboardChatButton userId={companyManagerId} />
      </div>
    </div>
  );
};
