import { LC } from "~/Locales";
import DateView from "~/Components/Views/DateView";
import { FC } from "react";
import { TbAlertCircleFilled, TbCircleCheckFilled } from "react-icons/tb";
import { Divider, HDivider, Tooltip, TooltipContent, TooltipTrigger } from "~/Components/UI";

const OrderShippingDetails: FC<{
  shippingDetails: {
    shippingLine: string;
    vesselAndVoyage: string;
    containerNumbers: string[];
    waybillNumber: string;
    loadPort: string;
    dischargePort: string;
    promiseDate?: string;
    promiseDateRevised?: string;
    promiseDateConfirmed?: boolean;
  };
}> = ({ shippingDetails }) => {
  const {
    shippingLine,
    vesselAndVoyage,
    containerNumbers,
    waybillNumber,
    loadPort,
    dischargePort,
    promiseDate,
    promiseDateRevised,
    promiseDateConfirmed,
  } = shippingDetails;

  const PromiseDateIcon = () => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex-col align-center">
          { promiseDateConfirmed
            ? <TbCircleCheckFilled className="color-green-1" size={24} />
            : <TbAlertCircleFilled className="color-orange-1" size={20} />
          }
        </div>
      </TooltipTrigger>
      <TooltipContent>
        { promiseDateConfirmed
          ? <>Confirmed by Vendor</>
          : <>Not Confirmed by Vendor</>
        }
      </TooltipContent>
    </Tooltip>
  );
  };

  return (
    <div className="flex-row flex-wrap p-sm text-left">
      <Divider className="mobile-hide" />

      <div className="flex flex-col align-start px-lg py-sm mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.port.of.loading" />
        </div>
        <div className="ValueColor mt-xxs">{loadPort || "-"}</div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.port.of.discharge" />
        </div>
        <div className="ValueColor mt-xxs">{dischargePort || "-"}</div>
      </div>

      <HDivider className="mobile-hide" />

      <div className="flex flex-col align-start px-lg py-sm mobile-flex-basis-full">
        <div className="text-sm color-text-3">
          <LC id="title.shipping.line" />
        </div>
        <div className="ValueColor mt-xxs">{shippingLine || "-"}</div>
        <div className="text-sm mt-md color-text-3">
          <LC id="title.vessel.voyage" />
        </div>
        <div className="ValueColor mt-xxs">{vesselAndVoyage || "-"}</div>
      </div>

      <HDivider className="mobile-hide" />

      <div className="flex align-start px-lg py-sm flex-row mobile-flex-basis-full">
        <div className="flex-col align-start">
          <div className="text-sm color-text-3">Equipment No(s)</div>
          <div className="ValueColor mt-xxs">
            {containerNumbers
              ? containerNumbers.map((cn) => <div key={cn}>{cn}</div>)
              : "-"}
          </div>

          {promiseDate && (
            <>
              <div className="text-sm mt-md color-text-3">
                <LC id="title.promised.ship.date" />
              </div>
              <div className="mt-xxs flex-row align-center gap-sm">
                <DateView date={promiseDate} />
                {!promiseDateRevised && <PromiseDateIcon />}
              </div>
            </>
          )}

          {promiseDateRevised && (
            <>
              <div className="text-sm mt-md color-text-3">
                <LC id="title.revised.promised.ship.date" />
              </div>
              <div className="mt-xxs flex-row align-center gap-sm">
                <DateView date={promiseDateRevised} />
                <PromiseDateIcon />
              </div>
            </>
          )}
        </div>
      </div>

      <HDivider className="mobile-hide" />

      <div className="flex flex-col align-start px-lg py-sm justify-between mobile-flex-basis-full">
        <div className="flex-col align-start">
          <div className="text-sm color-text-3">
            <LC id="title.bl.number" />
          </div>
          <div className="mt-xxs">{waybillNumber || "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderShippingDetails;
