import React from "react";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";

export const CurrencyCombobox = <T,>(
  props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const keys = ["USD", "EUR"];
  const getLabel = (d: string) => d;

  return <FormCombobox {...props} data={keys} getLabel={getLabel} />;
};
