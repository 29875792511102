import API, {CompanyStatus, CompanyType, CompanyUsersView, GroupPermission} from "~/API";
import {FC} from "react";
import {LC} from "~/Locales";
import {useCurrentUser, useIsAdmin} from "~/Reducers/User";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import {CompanySetCodesButton} from "./CompanySetCodesButton";
import {Button, Card, FormInput, FormSwitch} from "~/Components/UI";
import {useFormData, useMutate} from "~/Hooks";
import {UserByPermissionCombobox} from "~/Components/Users/UserByPermissionCombobox";
import Toast from "~/Components/Toast";
import CompanyDetailsAccountsReceivablesCard from "./CompanyDetailsAccountReceivablesCard";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {trackEvent} from "~/Services/Tracking";
import {decimalToHundredthPercent, hundredthToDecimalPercent} from "~/Utils/UnitConversion";

const CompanyDetailsGeneralTab: FC<{
  company: CompanyUsersView;
  mutateCompany: () => Promise<CompanyUsersView>;
}> = (props) => {
  const isAdmin = useIsAdmin();
  const currentUser = useCurrentUser();
  const isVendor = props.company.type == CompanyType.SELLER;
  const isCompanyAccountManager = props.company.accountManagerUserId == currentUser.id;

  const form = useFormData<Partial<CompanyUsersView>>({
    ...props.company,
    defaultMarginAsFraction: decimalToHundredthPercent(props.company.defaultMarginAsFraction)
  });

  const save = useMutate(async () => {
    try {
      await API.updateCompany({ id: props.company.id }, {
        ...form.data as CompanyUsersView,
        defaultMarginAsFraction: hundredthToDecimalPercent(form.data.defaultMarginAsFraction)
      });
      const newComp = await props.mutateCompany();
      form.setData({
        ...newComp,
        defaultMarginAsFraction: decimalToHundredthPercent(newComp.defaultMarginAsFraction),
      }, true);
      trackEvent("Company Updated");
    } catch (e) {
      Toast.ApiError(e);
    }
  });

  const canEdit = isCompanyAccountManager || isAdmin;
  const msrConfirmed = props.company.status == CompanyStatus.MSR_CONFIRMED;
  const canEditAll = !msrConfirmed && canEdit;

  return (
    <>
      {form.hasChanges && !save.loading && (
        <UnsavedChangesDialog
          onSave={save.mutate}
        />
      )}
      <div className="desktop-flex-row mobile-flex-col flex-wrap gap-lg text-left">
        <Card className="flex-col gap-md py-lg desktop-w-min-10xl">
          <div className="text-lg">
            <LC id="title.general" />
          </div>

          <div className="flex-row align-center gap-md">
            <FormSwitch
              id="active"
              formData={form}
              disabled={!isAdmin}
              label="title.active"
            />

            {props.company.active && !form.data.active && (
              <div className="ml-sm color-orange-1 bg-orange-10 py-xxs px-xs rounded-xxs">
                <strong>Warning:</strong> This will also inactivate the company's
                users and locations!
              </div>
            )}
          </div>

          <div className="flex-row gap-md align-center">
            <FormInput
              id="code"
              formData={form}
              label={isVendor ? "title.vendor.code" : "title.customer.code"}
              disabled
            />
            <FormInput
              id="legalName"
              formData={form}
              label="company.legal.name"
              disabled={!canEditAll}
              containerClassName="flex"
            />
          </div>

          <FormSwitch
            id="isInternal"
            formData={form}
            disabled={!isAdmin}
            label={isVendor ? "title.internal.vendor" : "title.internal.customer"}
          />

          <FormSwitch
            id="isDomestic"
            formData={form}
            label="title.domestic.company"
            disabled={!isAdmin}
          />

          <FormSwitch
            id="requireOrderApproval"
            formData={form}
            label="title.require.approval"
            disabled={!isAdmin}
          />

          {isVendor && (
            <>
              <UserByPermissionCombobox
                id="accountManagerUserId"
                formData={form}
                label="title.buyer"
                disabled={!canEdit}
                permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
                showClear
                clearValue={null}
              />

              <UserByPermissionCombobox
                id="additionalResponsibles"
                formData={form}
                label="title.additional.responsibles"
                disabled={!canEdit}
                excludeUsers={[form.data.accountManagerUserId]}
                permissions={[GroupPermission.CAN_BE_BUYER]}
                showClear
                multiple
                clearValue={[]}
              />
            </>
          )}

          {isVendor && (
            <FormInput
              id="defaultMarginAsFraction"
              formData={form}
              label="title.default.margin"
              disabled={!canEdit}
              rightIcon={<div className="mx-md color-text-3">%</div>}
              type="number"
            />
          )}
        </Card>

        {!isVendor && msrConfirmed && (
          <CompanyDetailsAccountsReceivablesCard company={props.company} />
        )}

        <Card className="flex-col gap-md py-lg desktop-w-min-10xl">
          <div className="text-lg">
            <LC id="company.address" />
          </div>

          <FormInput
            id="addressStreet"
            formData={form}
            label="title.address.1"
            disabled={!canEditAll}
          />
          <FormInput
            id="addressStreet2"
            formData={form}
            label="title.address.2"
            disabled={!canEditAll}
          />
          <FormInput
            id="addressCity"
            formData={form}
            label="title.city"
            disabled={!canEditAll}
          />

          <div className="flex-row gap-md mt-md">
            <FormInput
              id="addressRegion"
              formData={form}
              label="title.state"
              disabled={!canEditAll}
              containerClassName="flex"
            />
            <FormInput
              id="addressZip"
              formData={form}
              label="title.zip"
              disabled={!canEditAll}
            />
          </div>

          <FormInput
            id="addressCountry"
            formData={form}
            disabled={!canEditAll}
            label="title.country"
          />
        </Card>
      </div>

      {canEdit && (
        <BottomButtonsPanel>
          {!msrConfirmed && (
            <CompanySetCodesButton
              company={props.company}
              mutateCompany={async () => {
                const comp = await props.mutateCompany();
                form.setData(comp, true);
              }}
            />
          )}

          <Button
            onClicked={save.mutate}
            loading={save.loading}
            disabled={!form.hasChanges}
            label="title.save.changes"
          />
        </BottomButtonsPanel>
      )}
    </>
  );
};

export default CompanyDetailsGeneralTab;
