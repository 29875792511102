import { FC } from "react";
import { Button } from "~/Components/UI/Buttons/Button";
import {TbDownload, TbPlayerPlayFilled} from "react-icons/tb";
import { cn } from "~/Utils";
import { Dialog } from "~/Components/UI/Dialogs/Dialog";
import { ls } from "~/Locales";

export interface GalleryItem {
  thumbnailUrl: string;
  imageUrl: string;
  downloadUrl?: string;
  format: string;
  fileName: string;
}

export const Gallery: FC<{
  containerClassName?: string;
  imageClassName?: string;
  selectedIndex: number;
  setSelectedIndex: (val: number) => void;
  showFullscreenDialog?: boolean;
  items: GalleryItem[];
}> = (props) => {
  if (props.items.length == 0)
    return (
      <div className="flex flex-col align-center justify-center text-lg color-text-2">
        {ls("title.image.coming.soon")}
      </div>
    );

  return (
    <div className={cn("flex flex-col gap-sm", props.containerClassName)}>
      <div className="flex h-min-0 flex-row relative justify-center">
        {props.showFullscreenDialog && (
          <Dialog
            trigger={
            <div>
              <GalleryMainImage
                item={props.items[props.selectedIndex]}
                className={props.imageClassName}
                canFullscreen
                urlAddition="&size=1080&filetype=webp"
              />
            </div>
            }
          >
            <Gallery
              {...props}
              containerClassName="w-90vw h-max-90vh"
              imageClassName="w-full"
              showFullscreenDialog={false}
            />
          </Dialog>
        )}
        {!props.showFullscreenDialog && (
          <GalleryMainImage
            item={props.items[props.selectedIndex]}
            className={props.imageClassName}
            urlAddition="filetype=webp"
          />
        )}
        {props.items[props.selectedIndex].downloadUrl && (
          <Button
            leftIcon={<TbDownload size={24} />}
            className="absolute bottom-0 right-0 m-md"
            label="title.download"
            asChild
          >
            <a
              href={props.items[props.selectedIndex].downloadUrl}
              download="filename"
              target="_blank"
            />
          </Button>
        )}
      </div>
      <div className="flex-row flex-wrap gap-sm justify-start">
        {props.items.map((item, i) => (
          <GallerySelector
            item={item}
            index={i}
            key={i}
            selectedIndex={props.selectedIndex}
            setSelectedIndex={props.setSelectedIndex}
          />
        ))}
      </div>
    </div>
  );
};

const GalleryMainImage: FC<{
    item: GalleryItem;
    className: string;
    canFullscreen?: boolean;
    urlAddition?: string;
  }> = (props) => {
  if (props.item.format == "mp4")
    return (
      <video
        controls
        autoPlay
        loop
        disablePictureInPicture
        muted
        onClick={(e) => e.stopPropagation()}
        className={cn(
          "flex h-min-0 rounded-xxs",
          props.className
        )}
      >
        <source src={props.item.imageUrl} type="video/mp4" />
      </video>
    );

  // default to image
  return (
    <img
      alt={props.item.fileName}
      src={props.item.imageUrl + props.urlAddition}
      className={cn(
        "flex h-min-0 fit-contain rounded-xxs",
        props.canFullscreen && "cursor-zoom",
        props.className,
      )}
    />
  );
};

const GallerySelector: FC<{
  item: GalleryItem;
  index: number;
  selectedIndex: number;
  setSelectedIndex: (val: number) => void;
}> = (props) => {
  const onClick = () => {
    props.setSelectedIndex(props.index);
  };

  return (
    <div onClick={onClick} className={cn(
      "relative w-4xl h-4xl rounded-xxs overflow-hidden cursor-pointer",
        props.index == props.selectedIndex && "outline-4 outline-blue-1",
    )}>
      <img
        src={props.item.thumbnailUrl}
        alt={props.item.fileName}
        className="w-full h-full fit-cover"
      />
      {props.item.format == "mp4" && (
        <>
          <div className="bg-black-0 opacity-70 absolute top-0 bottom-0 left-0 right-0" />
          <TbPlayerPlayFilled className="absolute center color-black-12" size={24} />
        </>
      )}
    </div>
  );
};
