import {Length, OfferProductView, ProductPublicView, ProductV2UOM, QuoteProductView,} from "~/API";

export enum UOM {
  MBF = "MBF",
  CBM = "CBM",
  PCS = "PCS",
  MSF = "MSF",
  LF = "LF",
}

// when only multiplying/dividing these numbers by 100 I was having floating precision errors.
// This is a slightly hacky way to make them precise to the 00.00%
export const decimalToHundredthPercent = (decimalPercent: number) => +(decimalPercent * 100).toFixed(2);
export const hundredthToDecimalPercent = (hundredthPercent: number) => +(hundredthPercent / 100).toFixed(4); // 4 to include (0-99%) and 2 additional decimals

const toFixedAndTrimZero = (value: number, fractionDigits: number) => {
  let s = value.toFixed(fractionDigits);
  if (fractionDigits === 0)
    return s;

  let end = s.length;
  while (s[end - 1] === '0') {
    end--;
  }
  if (s[end - 1] === '.')
    end--;

  if (end !== s.length)
    return s.substring(0, end);
  else
    return s;
};

export const getDimensionsString = (actThick: number, actWidth: number, actLength: number, units: UOM | ProductV2UOM, lengths?: Length[]) => {
  if (lengths && lengths.length > 0) {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `${
        toFixedAndTrimZero(actThick, 2)
      }" x ${
        toFixedAndTrimZero(actWidth, 2)
      }" x ${
        lengths.map(it => toFixedAndTrimZero(it.actual, 0)).join(', ')
      }'`;

    if (units === UOM.CBM)
      return `${
        toFixedAndTrimZero(actThick * 25.4, 0)
      } x ${
        toFixedAndTrimZero(actWidth * 25.4, 0)
      } x ${
        lengths.map(it => toFixedAndTrimZero(it.actual * 304.8, 0)).join(', ')
      } mm`;
  } else if (actLength) {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `${toFixedAndTrimZero(actThick, 2)} x ${toFixedAndTrimZero(actWidth, 2)} x ${toFixedAndTrimZero(actLength, 0)}"`;

    if (units === UOM.CBM)
      return `${toFixedAndTrimZero(actThick * 25.4, 0)} x ${toFixedAndTrimZero(actWidth * 25.4, 0)} x ${toFixedAndTrimZero(actLength * 25.4, 0)} mm`;
  } else {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `${toFixedAndTrimZero(actThick, 2)} x ${toFixedAndTrimZero(actWidth, 2)}"`;

    if (units === UOM.CBM)
      return `${toFixedAndTrimZero(actThick * 25.4, 0)} x ${toFixedAndTrimZero(actWidth * 25.4, 0)} mm`;
  }
  return '';
};

export const getLengthString = (actLength: number, units: UOM | ProductV2UOM, lengths?: Length[]) => {
  if (lengths && lengths.length > 0) {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `${lengths.map(it => toFixedAndTrimZero(it.actual, 0)).join(', ')}'`;

    if (units === UOM.CBM)
      return `${
        lengths.map(it => toFixedAndTrimZero(it.actual * 304.8, 0)).join(', ')
      } mm`;
  } else if (actLength) {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `${toFixedAndTrimZero(actLength, 0)}"`;

    if (units === UOM.CBM)
      return `${toFixedAndTrimZero(actLength * 25.4, 0)} mm`;
  } else {
    if (units === UOM.MBF || units === UOM.PCS || units === UOM.LF || units === UOM.MSF)
      return `-`;

    if (units === UOM.CBM)
      return `-`;
  }
  return '';
};

export const getDimensionsStringForProductUfp = (product: ProductPublicView, units: UOM | ProductV2UOM, lengths?: Length[]) => {
  // console.log('units', units);
  // console.log('standardUnitOfMeasure', product.standardUnitOfMeasure);
  return getDimensionsString(product.msrActThick, product.msrActWidth, product.msrActLength, units, lengths);
};

export const getLengthStringForProductUfp = (product: ProductPublicView, units: UOM | ProductV2UOM, lengths?: Length[]) => {
  return getLengthString(product.msrActLength, units, lengths);
};

export const getDimensionsStringForQuoteProduct = (product: QuoteProductView | OfferProductView, units: UOM | ProductV2UOM, lengths?: Length[]) => {
  return getDimensionsString(product.productActThick, product.productActWidth, product.productActLength, units, lengths);
};
export const getNumberOfPieces = (product: ProductPublicView, quantity: number, length?: Length) => {
  if (product.standardUnitOfMeasure === UOM.MBF || product.standardUnitOfMeasure === UOM.MSF || product.standardUnitOfMeasure === UOM.LF)
    return quantity / getMbfOrMsfPerPcs(product, length);
  if (product.standardUnitOfMeasure === UOM.CBM)
    return quantity / getProductCbmPerPcs(product, length);
  if (product.standardUnitOfMeasure === UOM.PCS)
    return quantity;

  return 0;
};

export const getContainers = (product: ProductPublicView, length: Length | null, quantity: number, uom: UOM | ProductV2UOM) => {
  if (!product || !uom || !quantity) return 0;

  const volume = getVolumeInBaseUom(product, length, quantity, uom);

  const containers = volume / product.unitsContainer;
  return containers || 0;
};

export const getVolumeInBaseUom = (product: ProductPublicView, length: Length | null, quantity: number, uom: UOM | ProductV2UOM) => {
  return convertVolume(product, length, quantity, uom, product.standardUnitOfMeasure as UOM);
};

export const convertVolume = (product: ProductPublicView, length: Length | null, quantity: number, fromUOM: UOM | ProductV2UOM, toUOM: UOM | ProductV2UOM) => {
  // pieces = quantity /volumePerPiece
  // pieces = quantityBase / basePerPiece = quantityTarget / targetPerPiece
  // quantityBase = quantityTarget / targetPerPiece * basePerPiece
  if (fromUOM === toUOM) return quantity;

  const fromUomPerPcs = getVolumePerPiece(product, length, fromUOM);
  const toUomPerPcs = getVolumePerPiece(product, length, toUOM);
  return quantity / fromUomPerPcs * toUomPerPcs;
};

export const getVolumePerPiece = (product: ProductPublicView, length: Length | null, units: UOM | ProductV2UOM) => {
  if (units === UOM.CBM)
    return getProductCbmPerPcs(product, length);
  if (units === UOM.MBF || units === UOM.MSF)
    return getMbfOrMsfPerPcs(product, length);
  if (units === UOM.PCS)
    return 1;
  if (units === UOM.LF)
    return length ? (length.nominal ? length.nominal : 0) : 0;

  return 0;
};

export const getMbfOrMsfPerPcs = (product: ProductPublicView, length?: Length) => {
  if (product.productType === "PANELS")
    return MSFperPCS(product.msrActLength, product.msrActWidth);

  return MBFperPCS(
    product.nominalActual === 'NOMINAL',
    product.msrNomThick,
    product.msrNomWidth,
    length?.nominal,
    product.msrActThick,
    product.msrActWidth,
    length?.actual
  );
};

export const MBFperPCS = (isNominal: boolean, nomThickness: number, nomWidth: number, nomLength: number, actThickness: number, actWidth: number, actLength: number) => {
  if (isNominal)
    return nomThickness * nomWidth * (!nomLength || nomLength === 0.0 ? 1.0 : nomLength) / 12.0 / 1000.0;
  else
    return actThickness * actWidth * (!actLength || actLength === 0.0 ? 1.0 : actLength) / 12.0 / 1000.0;
};

export const MSFperPCS = (actLength: number, actWidth: number) => {
  return (actLength / 12 * actWidth / 12) / 1000;
};


export const getCBMperPCS = (actThickness: number, actWidth: number, actLength: number) => {
  return actThickness * 25.4 * actWidth * 25.4 * (!actLength || actLength === 0.0 ? 1.0 : actLength) * 304.8 / 1000000000;
};

export const getProductCbmPerPcs = (product: ProductPublicView, length?: Length) => {
  if (product.productType === "PANELS")
    return product.msrActThick * 25.4 * product.msrActWidth * 25.4 * product.msrActLength * 25.4 / 1000000000;

  return getCBMperPCS(product.msrActThick, product.msrActWidth, length?.actual);
};

export const getActualLength = (length: Length | null, units: UOM) => {
  if (units === UOM.MBF)
    return toFixedAndTrimZero(length?.actual || 1, 4) + "'";
  if (units === UOM.CBM)
    return toFixedAndTrimZero((length?.actual || 1) * 304.8, 0) + "mm";

  return length.value;
};
