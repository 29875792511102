import useSWR from "swr";
import API, {Order, PurchaseOrderPlantView, PurchaseOrderStatus, PurchaseOrderView} from "~/API";
import { useMutateHook } from "~/Hooks";
import useSWRInfinite from "swr/infinite";
import dayjs from "dayjs";
import {LocalizedMessageKey, ls} from "~/Locales";


export const purchaseOrderAsReferenceText = (order: PurchaseOrderView) => {
  if (!order) return '';
  const { number, status, dateCreated, shipToName } = order;
  const dateString = dayjs(dateCreated).format('DD MMM YYYY');
  const statusString = ls(('delivery.status.' + status) as LocalizedMessageKey);
  return `PO-${number} - ${dateString} - ${statusString} - ${shipToName}`;
};

export const useDashboardPurchaseOrders = (
  isInternalCustomer: boolean,
  isInternalUser: boolean,
) =>
  useSWR(["api/po/dashboard", isInternalCustomer, isInternalUser], async () => {
    if (isInternalCustomer)
      return await API.getPlantPurchaseOrders({ limit: 10 });
    if (isInternalUser)
      return await API.getPurchaseOrders({ limit: 10, onlyMyOrders: true });
    // external users (default)
    return await API.getPurchaseOrdersVendor({ limit: 10 });
  });

export const usePurchaseOrder = (id: number, isInternalCustomer: boolean) =>
  useSWR(["api/purchase/order", id], async () => {
    if (isInternalCustomer)
      return (await API.getPlantPurchaseOrder({ id })) as PurchaseOrderView &
        PurchaseOrderPlantView;

    return (await API.getPurchaseOrder({ id })) as PurchaseOrderView &
      PurchaseOrderPlantView;
  });

export interface PurchaseOrdersFilters {
  search?: string;
  accountManager?: Array<number>;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  shipFrom?: Array<number>;
  shipTo?: Array<number>;
  status?: Array<PurchaseOrderStatus>;
  vendor?: Array<number>;
  onlyMyOrders?: boolean;
  query?: string;
  orderBy?: string;
  order?: Order;
}

const getPOListKey = (
  filters: PurchaseOrdersFilters,
  internalUser: boolean,
  internalCustomer: boolean,
  offset: number,
  previousPageData: (PurchaseOrderView & PurchaseOrderPlantView)[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/purchase/orders", filters, offset, internalUser, internalCustomer };
};

const PAGE_SIZE = 40;

export const usePurchaseOrders = (filters: PurchaseOrdersFilters, isInternalUser: boolean, isInternalCustomer: boolean) =>
  useSWRInfinite(
    (i, p) => getPOListKey(filters, isInternalUser, isInternalCustomer, i * PAGE_SIZE, p),
    (key) => {
      if (isInternalUser)
        return API.getPurchaseOrders({
          ...filters,
          offset: key.offset,
          limit: PAGE_SIZE,
        }) as unknown as Promise<(PurchaseOrderPlantView & PurchaseOrderView)>;
      if (isInternalCustomer)
        return API.getPlantPurchaseOrders({
          ...filters,
          offset: key.offset,
          limit: PAGE_SIZE,
        }) as unknown as Promise<(PurchaseOrderPlantView & PurchaseOrderView)>;

      return API.getPurchaseOrdersVendor({
        ...filters,
        offset: key.offset,
        limit: PAGE_SIZE,
      }) as unknown as Promise<(PurchaseOrderPlantView & PurchaseOrderView)>;
    }
  );

export const useAddPurchaseOrderMember = (
  orderId: number,
  isInternalCustomer: boolean,
) =>
  useMutateHook<number, any, any>(
    ["api/purchase/order", orderId],
    async (args) => {
      if (isInternalCustomer)
        return API.addPlantPurchaseOrderMember({ id: orderId, userId: args });
      else return API.addPurchaseOrderMember({ id: orderId, userId: args });
    },
  );

export const useRemovePurchaseOrderMember = (
  orderId: number,
  isInternalCustomer: boolean,
) =>
  useMutateHook<number, any, any>(
    ["api/purchase/order", orderId],
    async (args) => {
      if (isInternalCustomer)
        return API.removePlantPurchaseOrderMember({
          id: orderId,
          userId: args,
        });
      else return API.removePurchaseOrderMember({ id: orderId, userId: args });
    },
  );

export const usePurchaseOrderUpdatePromiseDate = (
  orderId: number,
) =>
  useMutateHook<{ promiseDate: string }, any, any>(
    ["api/purchase/order", orderId],
    async (args) => {
      return API.updatePromiseDate({ id: orderId }, args);
    },
  );

export const usePurchaseOrderConfirmPromiseDate = (
  orderId: number,
) =>
  useMutateHook(
    ["api/purchase/order", orderId],
    async () => {
      return API.confirmPromiseDate({ id: orderId });
    },
  );
