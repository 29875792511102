import API from "~/API";
import {useMemo} from "react";
import {composedItems} from "./Utils/Compose";
import {useGetEntityList} from "./Utils/Entity";
import {AppDispatch, RootState} from "~/StoreTypes";


const usersPublicSelector = (state: RootState) => state.usersPublic;
const useUsersPublic = () => useGetEntityList(getUFPInternalUsersPublic, usersPublicSelector);

export const useUserPublicKeys = () => {
  const users = useUsersPublic();
  return useMemo(() => Object.keys(users), [users]);
};

export const useUserPublicLabels = () => {
  const users = useUsersPublic();
  const userKeys = useUserPublicKeys();

  return useMemo(() => {
    return userKeys?.reduce((labels, userId) => {
      const user = users[userId];
      if (!user) return labels;
      return {...labels, [userId]: user.name + ' ' + user.surname};
    }, {});
  }, [users, userKeys]);
};


const getUFPInternalUsersPublic = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_USERS_START' });
    // returns UFP users public! (API.getPublicUsers is a bit deceiving).
    const users = await API.getPublicUsers();
    dispatch({ type: 'ACTIONS_GET_PUBLIC_USERS_SUCCESS', users });
    return users;

  } catch (error) {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_USERS_ERROR', error });
  }
};

const initialState = {}

export const usersPublic = (state = initialState, action: any) => {
  switch (action.type) {
    case 'ACTIONS_GET_PUBLIC_USERS_START':
      return { ...state };

    case 'ACTIONS_GET_PUBLIC_USERS_SUCCESS':
      return { ...state, ...composedItems(action.users) };

    case 'ACTIONS_GET_PUBLIC_USERS_ERROR':
      return { ...state, error: action.error };
    default:
      return state;
  }
}