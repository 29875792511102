import { useEffect } from "react";
import {useAppDispatch} from "~/StoreTypes";


const useFetchMissingItems = (value, items, getItemFunc) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Array.isArray(value)) {
      value.forEach(itemId => {
        if (!items[itemId]) dispatch(getItemFunc(itemId));
      });
    } else {
      if (value && !items[value]) dispatch(getItemFunc(value));
    }
  }, [value, items, getItemFunc]);
};

export default useFetchMissingItems;