import { OfferStatus } from "~/API";
import { useCallback } from "react";
import { useHistory, useParams } from 'react-router-dom';
import {LocalizedMessageKey} from "~/Locales";
import { offersRootPath, requestedOffersRootPath, useIsRFOPath } from "~/Services/Routes";
import { Button } from "~/Components/UI";
import { SaveOfferCallbackType } from "../OfferDetailsButtonsPanel";


interface SaveButtonProps {
  hasChanges: boolean;
  loading: boolean;
  saveOffer: SaveOfferCallbackType;
  status: OfferStatus;
  label?: LocalizedMessageKey;
}

export const SaveOfferButton = ({ hasChanges, loading, saveOffer, status, label }: SaveButtonProps) => {
  const history = useHistory();
  const { id: offerId } = useParams<{id: string}>();
  const isRFO = useIsRFOPath();

  const onSaveClick = useCallback(async () => {
    try {
      await saveOffer(status);
      history.replace(`${isRFO ? requestedOffersRootPath : offersRootPath}${offerId}`);
    } catch { }
  }, [saveOffer, status, history, isRFO, offerId]);

  return (
    <Button
      onClicked={onSaveClick}
      disabled={!hasChanges || !!loading}
      data-cy={"SaveOfferButton"}
      label={label || "title.save"}
    />
  );
};