import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import './IncomingVideoCallDialog.scss';
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import {
  acceptVideoCall,
  declineVideoCall, useLinkToVideoCall,
  useVideoCallState
} from "~/Reducers/VideoCall";
import { useUserName } from "~/Reducers/Users";
import { useShowVideoCallNotification } from "~/Utils/Notifications";
import { useUserPreferences } from "~/Reducers/UserPreferences";
import {useAppDispatch} from "~/StoreTypes";


const IncomingVideoCallDialog = () => {

  const dispatch = useAppDispatch();
  const { shouldShowIncomingVideoCallDialog, userIdFrom, chatMessageId, chatId } = useVideoCallState();

  const history = useHistory();
  const userName = useUserName(userIdFrom);
  const linkToVideoCall = useLinkToVideoCall(userIdFrom, chatId);

  const onConfirm = async () => {
    dispatch(acceptVideoCall(userIdFrom, chatMessageId));
    history.push(linkToVideoCall);
    // open in a new window w/o address bar and browser controls
    // window.open(videoCallLink, '_blank', 'location=no,menubar=no,status=no,toolbar=no');
    stopPlayingRingSound();
  }
  const onClose = () => {
    dispatch(declineVideoCall(userIdFrom, chatMessageId));
    stopPlayingRingSound();
  }

  const userPreferences = useUserPreferences();

  const audio = useMemo(() => new Audio('/assets/sounds/ring_tone.mp3'), []);
  audio.addEventListener('error', (e) => {
    console.error('### AUDIO === ', e);
  });
  const startPlayingRingSound = () => {
    if (!userPreferences.silentMode) {
      audio.play();
      audio.loop = true;
    }
  };
  const shouldPlayRingSound = shouldShowIncomingVideoCallDialog;

  const stopPlayingRingSound = () => {
    audio.pause();
  };

  useEffect(() => {
    shouldPlayRingSound ? startPlayingRingSound() : stopPlayingRingSound();
  }, [shouldPlayRingSound]);

  const showVideoCallNotification = useShowVideoCallNotification(userIdFrom, chatId, chatMessageId);
  const prevShouldShowIncomingVideoCallDialog = React.useRef(shouldShowIncomingVideoCallDialog);
  useEffect(() => {
    if (prevShouldShowIncomingVideoCallDialog.current === false && shouldShowIncomingVideoCallDialog === true) {
      showVideoCallNotification();
    }
    prevShouldShowIncomingVideoCallDialog.current = shouldShowIncomingVideoCallDialog;
  }, [shouldShowIncomingVideoCallDialog]);

  if (shouldShowIncomingVideoCallDialog) {
    return (
      <ConfirmationDialog
        title={'Incoming Video Call'}
        description={userName + ' is calling you. Do you want to accept the call?'}
        confirmTitle={'Accept'}
        closeTitle={'Decline'}
        onConfirm={onConfirm}
        onClose={onClose}
      />);
  } else return null;

};

export default IncomingVideoCallDialog;
