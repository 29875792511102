import {FC} from "react";
import {Button, Dialog, FormInput} from "~/Components/UI";
import { ls } from "~/Locales";
import {TbPlus} from "react-icons/tb";
import {useFormData, useMutate, useToggle} from "~/Hooks";
import API, {CreateDutyCostRequest} from "~/API";
import * as z from "zod";
import {zodMsg} from "~/Utils";
import {trackEvent} from "~/Services/Tracking";

const validation = z.object({
  htsNumber: z.string().min(1, zodMsg("error.required", ["HTS #"])),
});

export const AddDutyDialog: FC<{
  refreshDuties: () => Promise<any>;
}> = (props) => {
  const open = useToggle();
  const form = useFormData<CreateDutyCostRequest>({
    active: true,
    htsNumber: "",
  }, { validation });

  const addPort = useMutate(async () => {
    if (form.isValid()) {
      await API.addDutyCost({}, form.data);
      await props.refreshDuties();
      open.toggleFalse();
      trackEvent("Duty Cost Created");
    }
  });

  return (
    <Dialog
      title={ls("title.add.duty")}
      trigger={
        <Button
          leftIcon={<TbPlus size={16} />}
          label="title.add.duty"
        />
      }
      open={open}
      onOpenChange={form.clear}
    >
      <div className="desktop-w-10xl flex-col gap-md">
        <FormInput
          id="htsNumber"
          label="hts.number"
          formData={form}
        />

        <FormInput
          id="dutyRate"
          type="number"
          label="duty.rate"
          rightIcon={<div className="color-text-3 px-md">%</div>}
          formData={form}
        />

        <FormInput
          id="section301AdditionalTariffChina"
          type="number"
          label="section.301.tariff"
          rightIcon={<div className="color-text-3 px-md">%</div>}
          formData={form}
        />

        <FormInput
          id="softwoodLumberFeeMbf"
          type="number"
          label="softwood.lumber.fee.per.mbf"
          rightIcon={<div className="color-text-3 px-md">$</div>}
          formData={form}
        />

        <FormInput
          id="softwoodLumberFeeCbm"
          type="number"
          label="softwood.lumber.fee.per.cbm"
          rightIcon={<div className="color-text-3 px-md">$</div>}
          formData={form}
        />

        <FormInput
          id="addCvdNotCalculated"
          label="add.cvd.not.calculated"
          formData={form}
        />

        <FormInput
          id="brazilUnderGspNotCalculated"
          label="brazil.under.gsp.not.calculated"
          formData={form}
        />

        <Button
          label="title.add.duty"
          onClicked={addPort.mutate}
          loading={addPort.loading}
        />
      </div>
    </Dialog>
  );
}