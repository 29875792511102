import { FC, useRef, useState } from "react";
import {
  ProductType,
  unitsForLinearFoot,
  unitsForLumber,
  unitsForPanels,
  unitsForSpecialProducts,
} from "~/Reducers/Products";
import { useToggle } from "~/Hooks";
import {Button, Card, ConfirmDialog, Dialog, Tooltip, TooltipContent, TooltipTrigger} from "~/Components/UI";
import {TbAlertTriangle, TbCircleCheck, TbInfoCircle, TbUpload} from "react-icons/tb";
import { ls } from "~/Locales";
import API, {CompanyUsersView} from "~/API";
import {useCompanyLocations} from "~/Data/Locations";

const allowedExtensions = ["csv"];

export interface ImportProductsRow {
  companyId: number;
  productCode: string;
  productId?: number | null;
  productDescription?: number | null;
  productGroupId?: number | null;
  productType?: number | null;
  lengthValues?: string | null;
  lengthIds?: number[] | null;
  locationId?: number | null;
  locationCode?: string | null;
  locationName?: string | null;
  uom: string;
  invalid: boolean;
  invalidMsg?: string;
}

const ImportProductsTab: FC<{
  company: CompanyUsersView;
}> = (props) => {
  const fileInputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [saveErrorMsg, setSaveErrorMsg] = useState(null);
  const success = useToggle();
  const [parsedData, setParsedData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [fileName, setFileName] = useState("");

  const companyLocations = useCompanyLocations(props.company.id);

  const locationCodeMap = new Map();
  companyLocations.data?.forEach((l) => {
    const val = { id: l.id, name: l.name };
    locationCodeMap.set(l.code, val);
  });

  function resetAll() {
    fileInputRef.current.value = null;
    clearData();
  }

  function clearData() {
    setErrorMsg("");
    setParsedData(null);
    setFileName(null);
  }

  const handleFileChange = (event) => {
    clearData();
    const file = event.target.files[0];
    if (file && isValidFileExtension(file)) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvContent = e.target.result;
        const importProductsRows = await parseData(csvContent.toString());
        const productMap = await getProductsFromCodes(importProductsRows);
        const finalParsedData = await validateParsedData(
          importProductsRows,
          productMap,
        );
        setParsedData(finalParsedData);
      };
      reader.readAsText(file);
    }
  };

  function isValidFileExtension(file: File): boolean {
    if (!file) return false;
    const fileExtension = file?.type.split("/")[1];
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMsg("File is not in csv format.");
      return false;
    }
    setFileName(file.name);
    return true;
  }

  const parseData = async (data: string): Promise<ImportProductsRow[]> => {
    const lines = data.split("\n");

    const headers = lines.shift().trim().toLowerCase().split(",");
    if (!validateHeaders(headers)) return null;

    const parsedData: ImportProductsRow[] = [];

    for (let i = 0; i < lines.length; i++) {
      const values = lines[i].trim().split(",");
      if (values.length === headers.length) {
        // Create an object with keys from headers and corresponding values
        let rowData: ImportProductsRow = {
          companyId: props.company.id,
          productCode: values[headers.indexOf("productcode")].trim(),
          uom: values[headers.indexOf("uom")].trim().toUpperCase(),
          locationCode: values[headers.indexOf("locationcode")]
            .trim()
            .toUpperCase(),
          lengthValues: values[headers.indexOf("lengths")].trim(),
          invalid: false,
          invalidMsg: "",
        };
        parsedData.push(rowData);
      }
    }
    return parsedData;
  };

  function validateHeaders(headers: string[]): boolean {
    if (!headers.includes("productcode")) {
      setErrorMsg("No productCode header found.");
      return false;
    }
    if (!headers.includes("uom")) {
      setErrorMsg("No uom header found.");
      return false;
    }
    if (!headers.includes("locationcode")) {
      setErrorMsg("No locationCode header found.");
      return false;
    }
    if (!headers.includes("lengths")) {
      setErrorMsg("No lengths header found.");
      return false;
    }
    return true;
  }

  const getProductsFromCodes = async (data: ImportProductsRow[]) => {
    if (!data) return null;

    let query = "";
    for (let i = 0; i < data.length; i++) {
      query += "PRODUCT_CODE == " + data[i].productCode;
      if (i < data.length - 1) {
        query += " || ";
      }
    }
    const params = { query: query, offset: 0, limit: data.length };

    try {
      const loadedData = await API.getProductsV3(params);
      const productMap = new Map();
      for (let j = 0; j < loadedData.length; j++) {
        const vals = {
          productId: loadedData[j].id,
          description: loadedData[j].description,
          productType: loadedData[j].productType,
          productGroupId: loadedData[j].productGroupId,
        };
        productMap.set(loadedData[j].productCode, vals);
      }
      return productMap;
    } catch (e) {
      setErrorMsg("Error fetching product codes: " + e.message);
      return null;
    }
  };

  const validateParsedData = async (
    parsedData: ImportProductsRow[],
    productMap: Map<string, any>,
  ): Promise<ImportProductsRow[]> => {
    if (!parsedData) return null;
    const data = [...parsedData];

    for (let i = 0; i < data.length; i++) {
      data[i] = validateLocations(data[i]);

      if (!productMap.has(data[i].productCode)) {
        data[i].invalid = true;
        data[i].invalidMsg +=
          `Product not found for code: ${data[i].productCode}. `;
      } else {
        data[i] = setRowProductData(data[i], productMap);
        data[i] = validateUom(data[i], productMap);
        data[i] = await validateLengths(data[i], productMap);
      }
    }
    setValidated(allRowsValid(data));
    return data;
  };

  function setRowProductData(
    row: ImportProductsRow,
    productMap: Map<string, any>,
  ): ImportProductsRow {
    const data = Object.assign({}, row);
    data.productId = productMap.get(data.productCode).productId;
    data.productDescription = productMap.get(data.productCode).description;
    data.productType = productMap.get(data.productCode).productType;
    return data;
  }

  const validateLengths = async (
    row: ImportProductsRow,
    productMap: Map<string, any>,
  ): Promise<ImportProductsRow> => {
    if (!row) return row;
    const data = Object.assign({}, row);
    if (!isEmpty(data.lengthValues) && !data.invalid) {
      if (productMap.get(data.productCode).productType == ProductType.PANELS) {
        data.invalid = true;
        data.invalidMsg =
          "Cannot enter lengths manually for product type: PANELS.";
        return data;
      }
      try {
        const groupId = productMap.get(data.productCode).productGroupId;
        const loadedLengths = await API.getLengthsByProductGroup({ groupId });
        const lengthMap = new Map<string, number>();
        for (let j = 0; j < loadedLengths.length; j++) {
          lengthMap.set(loadedLengths[j].value, loadedLengths[j].id);
        }
        return setLengthIds(data, lengthMap);
      } catch (e) {
        data.invalid = true;
        data.invalidMsg += `Error validating lengths for productCode: ${data.productCode}, lengths: ${data.lengthValues}. `;
      }
    }
    if (
      productMap.get(data.productCode).productType == ProductType.LINEAR_FOOTAGE
    ) {
      data.invalid = true;
      data.invalidMsg =
        "Length must be entered for product type: LINEAR_FOOTAGE";
    }
    return data;
  };

  function setLengthIds(
    row: ImportProductsRow,
    lengthMap: Map<string, number>,
  ): ImportProductsRow {
    const data = Object.assign({}, row);
    const lengthIds = [];
    const rowLengthValues = data.lengthValues.trim().split(";");
    for (const lengthValue of rowLengthValues) {
      if (lengthMap.has(lengthValue)) {
        lengthIds.push(lengthMap.get(lengthValue));
      } else {
        data.invalid = true;
        data.invalidMsg += `Length value: ${lengthValue} not found for product group. `;
      }
    }
    data.lengthIds = lengthIds;
    return data;
  }

  function validateUom(
    row: ImportProductsRow,
    productMap: Map<string, any>,
  ): ImportProductsRow {
    const data = Object.assign({}, row);
    const prodMapValue = productMap.get(row.productCode);
    if (prodMapValue.productType === ProductType.SPECIAL_PRODUCTS) {
      if (isEmpty(data.uom)) {
        data.uom = unitsForSpecialProducts;
      } else {
        if (data.uom !== unitsForSpecialProducts) {
          data.invalid = true;
          data.invalidMsg += `UOM for SPECIAL_PRODUCTS must be ${unitsForSpecialProducts}. `;
        }
      }
    } else if (prodMapValue.productType === ProductType.LINEAR_FOOTAGE) {
      if (isEmpty(data.uom)) {
        data.uom = unitsForLinearFoot;
      } else {
        if (data.uom !== unitsForLinearFoot) {
          data.invalid = true;
          data.invalidMsg += `UOM for SPECIAL_PRODUCTS must be ${unitsForLinearFoot}. `;
        }
      }
    } else if (prodMapValue.productType === ProductType.LUMBER) {
      if (isEmpty(data.uom) || !unitsForLumber.includes(data.uom)) {
        data.invalid = true;
        data.invalidMsg += `UOM for LUMBER must be one of: [${unitsForLumber}].`;
      }
    } else if (prodMapValue.productType === ProductType.PANELS) {
      if (isEmpty(data.uom) || !unitsForPanels.includes(data.uom)) {
        data.invalid = true;
        data.invalidMsg += `UOM for PANELS must be one of: [${unitsForPanels}].`;
      }
    }
    return data;
  }

  function validateLocations(row: ImportProductsRow): ImportProductsRow {
    const data = Object.assign({}, row);
    if (isEmpty(data.locationCode)) {
      return data;
    }
    if (locationCodeMap.has(data.locationCode)) {
      data.locationId = locationCodeMap.get(data.locationCode).id;
      data.locationName = locationCodeMap.get(data.locationCode).name;
    } else {
      data.invalid = true;
      data.invalidMsg += `Location code: ${data.locationCode}, not found for company. `;
    }
    return data;
  }

  function allRowsValid(parsedData: ImportProductsRow[]): boolean {
    for (const row of parsedData) {
      if (row.invalid) {
        setErrorMsg("One or more rows are invalid. Fix csv and re-upload.");
        return false;
      }
    }
    return true;
  }

  const confirmImport = async () => {
    try {
      await API.createCompanyProducts({}, parsedData);
      success.toggleTrue();
      clearData();
    } catch (e) {
      setSaveErrorMsg(`Unable to submit bulk import request. Error: ${e.message}`);
      throw e;
    }
  };

  function isEmpty(str) {
    return !str || str.length === 0;
  }

  return (
    <Card className="flex-col gap-md">
      <div className="p-sm flex-row gap-md align-center">
        <label
          htmlFor="file-upload"
          className="flex-row gap-xxs text-bold hover-color-black-2 color-black-4 cursor-pointer"
        >
          <TbUpload size={16} />
          <div>Upload CSV File</div>
        </label>
        <input
          id="file-upload"
          className={"hide"}
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileChange}
        />
        {fileName && <div className={"text-lg"}>Import File: {fileName}</div>}
        {errorMsg && <div className={"color-red-1 text-lg"}>{errorMsg}</div>}
        {success.value && (
          <div className={"text-lg"}>File imported successfully!</div>
        )}

        <Dialog
          trigger={
            <Button
              label="title.help"
              leftIcon={<TbInfoCircle size={20} />}
              color="gray"
              className="ml-auto"
            />
          }
          title={ls("title.help")}
        >
          <div className="text-left desktop-w-10xl flex-col">
            <div className="text-lg">CSV Formatting Tips</div>
            <ol>
              <li>
                CSV file must have the following headers: "productCode,
                locationCode, lengths, uom".
              </li>
              <li>
                Length entries should be separated with a semicolon. Example:
                "6;8;10".
              </li>
              <li>
                If adding a product to multiple location codes, create a new
                row for each location.
              </li>
              <li>Values for locationCode are optional.</li>
              <li>
                Length values are optional except for LINEAR_FOOTAGE type
                products.
              </li>
              <li>
                If using Excel, ensure that code columns are formatted as text
                to avoid removal of leading 0s.
              </li>
            </ol>

            <div className="text-lg">Import Instructions</div>
            <ol>
              <li>Click "Upload CSV File" and select your csv.</li>
              <li>
                The csv will be automatically validated If it is good, click
                the "Submit" button, and then "Confirm" in the dialog.
              </li>
              <li>
                If there are validation errors, edit the csv to fix them and
                re-upload it.
              </li>
              <li>
                Hover over the X icon in the VALID column to view row-specific
                validation errors.
              </li>
            </ol>
          </div>
        </Dialog>
      </div>

      <div className={"flex bg-black-12 rounded-borders overflow-auto"}>
        {parsedData && (
          <>
            <table className={"basic-table"}>
              <thead>
                <tr>
                  <th>Valid</th>
                  <th>Product Code</th>
                  <th>Product Type</th>
                  <th>Product Description</th>
                  <th>Lengths</th>
                  <th>UOM</th>
                  <th>Location Code</th>
                  <th>Location Name</th>
                </tr>
              </thead>

              <tbody>
                {parsedData.map((entry: ImportProductsRow) => (
                  <ProductImportRow
                    key={parsedData.indexOf(entry)}
                    row={entry}
                  />
                ))}
                <tr>
                  <td>
                    <Button
                      color="gray"
                      onClicked={() => {
                        resetAll();
                      }}
                      disabled={false}
                      label="title.cancel"
                    />
                  </td>
                  <td>
                    <ConfirmDialog
                      title="title.import.products"
                      onConfirm={confirmImport}
                      confirmText="title.confirm"
                      trigger={
                        <Button label="title.submit" disabled={!validated} />
                      }
                    >
                      <div className="desktop-w-max-10xl">
                        Import {parsedData.length} products?
                      {saveErrorMsg && (
                        <div className="color-red-1 overflow-wrap-anywhere">
                          {saveErrorMsg.slice(0, 400)}
                        </div>
                      )}
                      </div>
                    </ConfirmDialog>
                  </td>
                  <td colSpan={6} />
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Card>
  );
};

export default ImportProductsTab;

const ProductImportRow: FC<{ row: ImportProductsRow }> = ({ row }) => {
  return (
    <tr>
      <td>
        {row.invalid && (
          <Tooltip>
            <TooltipTrigger>
              <div className="color-red-1 flex-row justify-center">
                <TbAlertTriangle size={20} />
              </div>
            </TooltipTrigger>
            <TooltipContent align="start">
              <div>
                {row.invalidMsg}
              </div>
            </TooltipContent>
          </Tooltip>
        )}
        {!row.invalid && (
          <TbCircleCheck
            className="color-green-1 w-full"
            size={20}
          />
        )}
      </td>
      <td>{row.productCode}</td>
      <td>{row.productType}</td>
      <td>{row.productDescription}</td>
      <td>{row.lengthValues}</td>
      <td>{row.uom}</td>
      <td>{row.locationCode}</td>
      <td>{row.locationName}</td>
    </tr>
  );
};
