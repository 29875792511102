import API, {Order, SeaFreightCost, SeaFreightCostImportRow} from "~/API";
import { useMutateHook } from "~/Hooks";
import useSWRInfinite from "swr/infinite";
import {trimUndefined} from "~/Utils";

export const useValidateLogisticsCostssImport = () =>
  useMutateHook<SeaFreightCostImportRow[], SeaFreightCostImportRow[]>(
    "api/seaFreightCosts/import/validate",
    async (data) => await API.validateSeaFreightCostsImport({}, data),
  );

export const useImportLogisticsCost = () =>
  useMutateHook<SeaFreightCostImportRow[], SeaFreightCostImportRow[]>(
    "api/seaFreightCosts/import",
    async (data) => await API.importSeaFreightCosts({}, data),
  );

export interface LogisticsFilters {
  query?: string;
  orderBy?: string;
  order?: Order;
  offset?: number;
  limit?: number;
  search?: string;
  portOfLoading?: Array<number>;
  portOfDischarge?: Array<number>;
  onlyEmptyPrice?: boolean;
  inactive?: boolean;
  incotermLoading?: Array<string>;
  incotermDischarge?: Array<string>;
  costPer40From?: string;
  costPer40To?: string;
  transitTime?: string;
  currency?: Array<string>;
  category?: Array<string>;
  destinationType?: Array<string>;
  vendor?: string;
  validityDateFrom?: string;
  validityDateTo?: string;
  lastChangedFrom?: string;
  lastChangedTo?: string;
}

const PAGE_SIZE = 40;

const getLogisticsKey = (
  filters: LogisticsFilters,
  offset: number,
  previousPageData: SeaFreightCost[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/logistics", filters, offset };
};

export const useLogisticsCosts = (
  filters: LogisticsFilters,
  pageSize?: number,
) =>
  useSWRInfinite(
    (i, p) => getLogisticsKey(filters, i * (pageSize || PAGE_SIZE), p),
    (key) =>
      API.getSeaFreightCosts({
        ...trimUndefined(filters) as any,
        offset: key.offset,
        limit: pageSize || PAGE_SIZE,
      }),
  );
