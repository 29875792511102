import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Email } from "~/API";
import { ls } from "~/Locales";
import {ActiveField, FormInput, FullPageCard, Table} from "~/Components/UI";
import {useDebounce, useFormData, useToggle} from "~/Hooks";
import { LinearLoader } from "~/Components/Loaders";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTimeView } from "~/Components/Views/DateView";
import {EmailFilters, useEmails} from "~/Data/Admin/Emails";
import EmailCard from "~/Pages/Admin/Emails/EmailCard";
import { UserName } from "~/Components/Users/UserName";
import {StaticPage} from "~/Components/Layout/Pages";
import {UserCombobox} from "~/Components/Users/UserCombobox";
import {FormDateInputRange} from "~/Components/UI/Forms/DateInput";
import {useSortState} from "~/Hooks/useSortState";


const columnHelper = createColumnHelper<Email>();

const columns = [
  columnHelper.accessor("sentSuccessfully", {
    header: () => ls("email.sentSuccessfully"),
    cell: (data) => (<ActiveField active={data.getValue()}/>),
    sortId: "sent_successfully",
    size: 0
  }),
  columnHelper.accessor("trigger", {
    header: () => ls("email.subject"),
    cell: (data) => data.getValue(),
    size: 400,
    sortId: "trigger" ,
  }),
  columnHelper.accessor("to", {
    header: () => ls("email.to"),
    cell: (data) => data.getValue(),
    sortId: "to",
  }),
  columnHelper.accessor("userAccountId", {
    header: () => ls("title.user"),
    cell: (data) => <UserName id={data.getValue()} />,
    sortId: "name",
  }),
  columnHelper.accessor("dateCreated", {
    header: () => ls("title.date"),
    cell: (data) => <DateTimeView date={data.getValue()} />,
    sortId: "date_created",
  }),
];

const EmailsPage: FC = () => {
  const sort = useSortState();
  const filters = useFormData<EmailFilters>({
    search: "",
    toUser: undefined,
    createDateTo: "",
    createDateFrom: "",
  });
  const searchDebounce = useDebounce(filters.data.search);

  const scrollRef = useRef(null);
  useEffect(() => {
    emails.setSize(1).then();
    scrollRef.current?.scrollTo?.(0, 0);
  }, [filters.data, sort.data.order, sort.data.orderBy]);

  const emails = useEmails({
    ...filters.data,
    ...sort.data,
    search: searchDebounce,
  });

  const onScrolledToBottom = () => emails.setSize(emails.size + 1);

  const [openId, setOpenId] = useState<number | undefined>();
  const cardOpen = useToggle();
  const onRowClicked = (_row: Email, index: number) => {
    cardOpen.toggleTrue();
    setOpenId(index);
  };

  const emailsData = useMemo(() => emails.data?.flat() || [], [emails.data]);
  const table = useReactTable({
    data: emailsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StaticPage title="Emails">
      <PageTitle title="title.emails" type="menu" />

      <FullPageCard>
        <div className="flex-row p-md gap-sm align-center">
          <FormInput
            id="search"
            formData={filters}
            placeholder={"placeholder.search"}
            showClear
            className="desktop-w-8xl"
            type="search"
          />
          <UserCombobox
            id="toUser"
            formData={filters}
            placeholder="email.to.user"
            showClear
            className="desktop-w-min-6xl"
          />
          <FormDateInputRange
            idFrom="createDateFrom"
            idTo="createDateTo"
            showClear
            formData={filters}
          />
        </div>

        <LinearLoader loading={emails.isValidating} />
        <Table
          table={table}
          onRowClick={onRowClicked}
          scrollRef={scrollRef}
          onScrolledBottom={onScrolledToBottom}
          scrollBottomOffset={400}
          sort={sort}
        />

        {emailsData.length > 0 && openId !== undefined && (
          <EmailCard email={emailsData[openId]} open={cardOpen} />
        )}
      </FullPageCard>
    </StaticPage>
  );
};

export default EmailsPage;
