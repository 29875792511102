import API, {Length} from "~/API";
import {useLengths} from "~/Data/Products/Lengths";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";

export const LengthCombobox = <T,>(
    props: {
    } & ExtendDataCombobox<T>,
) => (
      <ApiDataCombobox<T, any, Length>
          {...props}
          getLabel={(l) => l.value}
          getId={(l) => l.id}
          useListData={useLengths}
          getSingle={(id) => API.getLength({ id })}
          filters={{ inactive: false }}
      />
  );