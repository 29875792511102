import './RightPanel.scss';
import {FC, ReactNode} from "react";
import {cn} from "~/Utils";

const RightPanel: FC<{
  visible: boolean;
  children: ReactNode;
  className?: string;
}> = (props) => {
  const { visible, children, className } = props;
  return (
    <div className={cn('right-panel', !visible && 'right-panel-closed', className)}>
      {!!visible && <>{children}</>}
    </div>
  );
};

export default RightPanel;
