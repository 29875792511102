import API, {Port, PortType, UpsertPortRequest} from "~/API";
import {FC, useEffect, useMemo, useRef} from "react";
import { useIsAdmin, useIsLogistics } from "~/Reducers/User";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {
  ActiveField,
  editControlsColumn,
  FormCheckbox,
  FormCombobox,
  FormInput,
  Table
} from "~/Components/UI";
import {params, useDebounce, useFormData, usePageTitle} from "~/Hooks";
import {usePortsPaged} from "~/Data/Ports";
import {LinearLoader} from "~/Components/Loaders";
import {CountryCombobox} from "~/Components/Comboboxes/CountryCombobox";
import { ls } from "~/Locales";
import {countryCodeToLabel} from "~/Data/Countries";
import {AddDestinationDialog} from "./Components/AddDestinationDialog";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {useSortState} from "~/Hooks/useSortState";
import * as z from "zod";
import {trackEvent} from "~/Services/Tracking";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
  country: params.stringArray,
});

const DestinationsPage: FC = () => {
  usePageTitle("Destinations");

  const isAdmin = useIsAdmin();
  const isLogistics = useIsLogistics();
  const canEdit = isAdmin || isLogistics;

  const filters = useFormData<{
    search?: string;
    inactive?: boolean;
    country?: string[];
  }>({
    search: "",
    inactive: false,
    country: [],
  }, { routeParser });
  const searchDebounce = useDebounce(filters.data.search);

  const sort = useSortState();

  const ports = usePortsPaged({
    ...filters.data,
    ...sort.data,
    search: searchDebounce,
  });

  const scrollRef = useRef(null);
  useEffect(() => {
    ports.setSize(1).then();
    scrollRef.current?.scrollTo?.(0, 0);
  }, [searchDebounce, filters.data.inactive]);
  const onScrolledToBottom = () => ports.setSize(ports.size + 1);

  const rowForm = useFormRowEdit<Port>("id", async (data) => {
    await API.updatePort({ id: data.id }, data as UpsertPortRequest);
    await ports.mutate();
    trackEvent("Destination Updated");
  });

  const data = useMemo(
    () => ports.data?.flat() || [],
    [ports.data, filters.data]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editRow: rowForm,
    },
    initialState: {
      columnVisibility: {
        edit: canEdit,
      },
      columnPinning: {
        right: ["edit"],
      },
    },
  });

  return (
    <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
      <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
        {canEdit && (
          <AddDestinationDialog
            refreshPorts={ports.mutate}
          />
        )}
        <FormInput
          id="search"
          formData={filters}
          showClear
          placeholder="title.search"
          className="desktop-w-min-8xl"
          type="search"
        />
        <FormCheckbox
          id="inactive"
          formData={filters}
          label="title.inactive"
        />
        <CountryCombobox
          id="country"
          formData={filters}
          multiple
          showClear
          align="end"
          clearValue={[]}
          placeholder="title.all.countries"
          containerClassName="ml-auto"
        />
      </div>

      <LinearLoader loading={ports.isValidating} />
      <Table
        table={table}
        sort={sort}
        scrollRef={scrollRef}
        onScrolledBottom={onScrolledToBottom}
      />
    </div>
  );
};

export default DestinationsPage;


const columnHelper = createColumnHelper<Port>();

const columns = [
  columnHelper.accessor("active", {
    header: ls("title.active"),
    size: 0,
    cellMemo: ({ value }) => (
      <ActiveField active={value} />
    ),
    editCell: ({ editRow }) => (
      <FormCheckbox id="active" formData={editRow.form} />
    ),
  }),
  columnHelper.display({
    id: "name",
    header: ls("title.name"),
    sortId: "name",
    cellMemo: ({ row }) => {
      const name = usePortName(row);
      return (
        <div className="text-no-wrap">{name}</div>
      );
    },
  }),
  columnHelper.accessor("city", {
    header: ls("title.location"),
    sortId: "city",
    size: 256,
    editCell: ({ editRow }) => (
      <FormInput
        id="city"
        formData={editRow.form}
        className="w-min-6xl"
      />
    ),
  }),
  columnHelper.accessor("code", {
    header: ls("title.code"),
    sortId: "code",
    size: 0,
    editCell: ({ editRow }) => (
      <FormInput
        id="code"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("type", {
    header: ls("title.type"),
    sortId: "type",
    cellMemo: ({ value }) => (
      ls(`port.type.${value}`)
    ),
    editCell: ({ editRow }) => (
      <FormCombobox
        id="type"
        formData={editRow.form}
        getLabel={(t: PortType) => ls(`port.type.${t}`)}
        data={Object.keys(PortType)}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("country", {
    header: ls("title.country"),
    sortId: "country",
    cellMemo: ({ value }) => (
      <>
        {countryCodeToLabel(value)} ({value})
      </>
    ),
    editCell: ({ editRow }) => (
      <CountryCombobox
        id="country"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  editControlsColumn(columnHelper)
];

const usePortName = (port: Port) => {
  return useMemo(() => {
    if (port?.type === PortType.PORT)
      return `${port?.city || ""} - ${port?.code || ""} - ${port?.type || ""} - ${countryCodeToLabel(port?.country)}`;
    return `${port?.city || ""} - ${port?.type || ""} - ${countryCodeToLabel(port?.country)}`;
  }, [port.type, port.city, port.code, port.country]);
};
