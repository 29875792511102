import React from "react";
import {LC, LocalizedMessageKey} from "~/Locales";

interface OrderTitleContainerProps {
  orderNumber: string;
  masterChild: string | undefined;
  labelKey: LocalizedMessageKey
}

const OrderTitleContainer: React.FC<OrderTitleContainerProps> = ({orderNumber, masterChild, labelKey}) => {
  return (
    <LC id={labelKey}>
      {orderNumber + (masterChild ? ` (${masterChild})` : '')}
    </LC>
  )
};

export default OrderTitleContainer;