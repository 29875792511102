import { Component } from 'react';
import API from '~/API';
import { ImageGalleryContainer } from "react-ui-basics/ImageGallery";
import 'react-ui-basics/ImageGallery.css';
import 'react-ui-basics/SpinningProgress.css';
import 'react-ui-basics/Button.css';
import 'react-ui-basics/Animated.css';
import './ImagesGallery.scss';
import { isImage } from './Attachment';
import { DownloadIcon } from './Icons';
import TBButton from './Button';
import { classNames } from "react-ui-basics/Tools";
import { Button, MaterialIcon } from "react-ui-basics";


class ProductImagesGallery extends Component {
  render() {
    const { embedded, comment, messageToShow } = this.props;
    return (
      <ImageGalleryContainer {...this.props} loop={true} ref={it => this.gallery = it}
        indicator={(selected, length) =>
          <div className={'indicator'}>
            {Array.from({ length }, (_, i) => <div key={i}
              className={classNames('dot', selected === i && 'selected')} />)}
          </div>
        }
      >
        {!embedded && <>
          <div className="header">
            <span className="title">Gallery</span>
            <Button onClick={() => this.gallery.close()} flat={true} round={true}>
              <MaterialIcon icon={'close'} />
            </Button>
          </div>
          {comment && <div className={'overlay'}>{comment}</div>}
          <Button className={'close'} onClick={() => this.gallery.close()}>
            <MaterialIcon icon={'close'} />
          </Button>
        </>}
        {messageToShow &&
          <div className={'text-2xl MessageToShow'}> {messageToShow} </div>
        }
      </ImageGalleryContainer>
    );
  }

  downloadAttachment = (attachmentId) => {
    window.open(API.urlAttachmentDownload(attachmentId));
  };

  showGallery = (attachments, element, index, overlay, desktop = true) => {
    let withPreviews = desktop;
    let offset = desktop ? null : 0;
    let offsetTop = desktop ? null : 56;

    const images = attachments.map((attachment, i) => {
      const overlayToShow = Array.isArray(overlay) ? overlay[i] : (!!overlay && <div className={'overlay'}>{overlay}</div>);
      return ({
        id: i,
        previewUrl: API.urlAttachmentThumbnail(attachment.id),
        url: isImage(attachment.name) ? API.urlAttachmentOpen(attachment.id) : API.urlImageThumbnailLarge(attachment.id),
        el: i === index ? element : null,
        overlay: <div className={'OverlayContainer'}>
          {overlayToShow}
          <TBButton className={'ImagesGalleryDownloadButton'} onClick={() => this.downloadAttachment(attachment.id)}>
            <DownloadIcon size={16} />
          </TBButton>
        </div>,
        offset,
        offsetTop,
      });
    });

    this.gallery.show({
      images,
      index: index || 0,
      withPreviews,
    });
  };

  scrollTo = (index) => {
    this.gallery.scrollTo(index);
  };

  close = () => {
    this.gallery.close();
  };
}

export default ProductImagesGallery;
