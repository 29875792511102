import { useMemo } from "react";
import API, {Order, QuoteStatus, QuoteView} from "~/API";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

export const useDashboardQuotes = () =>
    useSWR(
        "api/quotes/dashboard",
        async () =>
            await API.getQuotes({
                offset: 0,
                limit: 10,
                onlyMy: true,
            }),
    );

export const useQuoteProductCodes = (quote: QuoteView) => {
    return useMemo(() => {
        if (!Array.isArray(quote?.products)) return "-";
        return [
            ...new Set(
                quote.products
                    .filter((product) => !!product.active)
                    .map((product) => product.productCode),
            ),
        ].join(", ");
    }, [quote?.products]);
};

export const useQuoteValue = (quote: QuoteView) => {
    return useMemo(() => {
        if (!Array.isArray(quote?.products)) return 0;
        let sum = 0;
        quote.products
            .filter((product) => !!product.active)
            .forEach((product) => {
                sum += (product.quantity || 0) * (product.price || 0);
            });
        return sum;
    }, [quote?.products]);
};

export interface QuotesFilters {
    query?: string;
    search?: string;
    status?: Array<QuoteStatus>;
    offer?: Array<number>;
    customer?: Array<number>;
    shipTo?: Array<number>;
    buyer?: Array<number>;
    accountManager?: Array<number>;
    valueFrom?: string;
    valueTo?: string;
    validityDateFrom?: string;
    validityDateTo?: string;
    createDateFrom?: string;
    createDateTo?: string;
    orderCreationDateFrom?: string;
    orderCreationDateTo?: string;
    product?: Array<number>;
    productGroup?: Array<number>;
    vendor?: Array<number>;
    port?: Array<number>;
    incoterm?: Array<string>;
    withChangesOnly?: boolean;
    onlyMy?: boolean;
    onlyNotLinkedToOffer?: boolean;
    onlyNotLinkedToRFQ?: boolean;
    onlyArchivedOrDeliveredOrExpired?: boolean;
    withoutArchivedOrDelivered?: boolean;
    withExpired?: boolean;
    orderBy?: string;
    order?: Order;
    offset?: number;
    limit?: number;
}

const PAGE_SIZE = 15;

const getQuotesListKey = (
    filters: QuotesFilters,
    offset: number,
    previousPageData: QuoteView[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/quotes", filters, offset };
};

export const useQuotes = (filters: QuotesFilters) =>
    useSWRInfinite(
        (i, p) => getQuotesListKey(filters, i * PAGE_SIZE, p),
        (key) =>
            API.getQuotes({
                ...filters,
                offset: key.offset,
                limit: PAGE_SIZE,
            }),
        {
            revalidateFirstPage: false,
        },
    );
