import { FC, ReactNode, useCallback, useMemo } from "react";
import Select from "./Select";

const ALL_MULTISELECT_VALUE = ["0"];

export interface SelectWithoutPagingProps {
  id?: string;
  data?: any[];
  value?: string | string[];
  multiple?: boolean;
  onChange?: (event: any) => void;
  showAll?: boolean;
  exclude?: string[];
  showSelectNone?: boolean;
  label?: string;
  className?: string;
  labels?: Record<string, ReactNode>;
  withFilter?: boolean;
  skipLabelSorting?: boolean;
  usePortal?: boolean;
  listPortalPosition?: "left" | "right";
}

const SelectWithoutPaging: FC<SelectWithoutPagingProps> = (props) => {
  const {
    showAll = false,
    exclude = [],
    showSelectNone = false,
    ...selectProps
  } = props;

  const keys = useMemo(() => {
    const finalArray = [];
    if (!!showAll) finalArray.push("0");
    if (!!showSelectNone && !!props.multiple) finalArray.push("SELECT_NONE");
    finalArray.push(...props.data);
    return finalArray.filter((id) => !exclude.includes(id));
  }, [showAll, showSelectNone, exclude, props.data, props.multiple]);

  const labels = useMemo(
    () => ({ "0": "All", SELECT_NONE: "Select None", ...props.labels }),
    [props.labels],
  );

  const currentValue = useMemo(() => {
    if (
      !!props.multiple &&
      !!showAll &&
      !showSelectNone &&
      !props.value?.length
    )
      return ALL_MULTISELECT_VALUE;
    if (!props.multiple && !!showAll && !props.value?.length) return "0";
    return props.value;
  }, [props.value, props.multiple, showAll, showSelectNone]);

  const onChange = useCallback(
    (event) => {
      const { target } = event;
      const single = !props.multiple;

      if (single) {
        const newValue = String(target.value);
        const selectAll = !!showAll && (newValue === "0" || !target.value);

        if (selectAll) {
          props?.onChange({ ...event, target: { ...target, value: "" } });
        } else {
          props?.onChange(event);
        }
      } else {
        const newValue = target.value;
        const deselectAll =
          !!showSelectNone && newValue?.includes("SELECT_NONE");
        const selectAll =
          !!showAll &&
          ((!currentValue?.includes("0") && newValue?.includes("0")) ||
            (currentValue?.includes("0") &&
              currentValue?.length === 1 &&
              newValue?.includes("0") &&
              newValue?.length === 1));

        if (deselectAll || selectAll) {
          props?.onChange({ ...event, target: { ...target, value: [] } });
        } else if (
          currentValue.includes("0") &&
          newValue.includes("0") &&
          newValue?.length > 1
        ) {
          props?.onChange({
            ...event,
            target: { ...target, value: newValue?.filter((it) => it !== "0") },
          });
        } else {
          props?.onChange(event);
        }
      }
    },
    [currentValue, props.multiple, props.onChange, showAll, showSelectNone],
  );

  return (
    <Select
      {...selectProps}
      value={currentValue}
      onChange={onChange}
      data={keys}
      labels={labels}
    />
  );
};

export default SelectWithoutPaging;
