import { useToggle } from "~/Hooks/Old";
import React, { useCallback } from "react";
import { OfferStatus } from "~/API";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { LocalizedMessage } from "~/Locales";
import Toast from "~/Components/Toast";


export const VendorConfirmOfferButton = ({ offerId, saveOffer }) => {
  // const history = useHistory();
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const onConfirm = useCallback(async () => {
    try {
      await saveOffer(OfferStatus.O_VENDOR_CONFIRMED);
      Toast.Success(`Offer ${offerId} sent to purchasing manager for confirmation`);
      // history.replace(`${requestedOffersRootPath}${offerId}/edit`);
    } catch (e) {
      console.error(e);
    }
  }, [saveOffer, offerId]);

  return <>
    <Button onClick={toggleConfirmSubmitDialog} data-cy={"VendorConfirmOfferButton"}>
      Confirm Offer
    </Button>

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Offer Confirmation'}
        description={'Do you want to confirm this Offer? '}
        description2={'An order will be created as soon as the purchasing manager confirmed your offer as well.'}
        confirmTitle={'Confirm Offer'}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={onConfirm}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>;
}

