import API, {OfferStatus, OfferUpdate} from "~/API";
import {
  isRequestedOfferStatus,
  useOfferStatusList,
  deleteOffer,
  updateOffer,
  ACTIONS_CREATE_OFFER_SUCCESS,
  ACTIONS_DELETE_OFFER_SUCCESS,
  ACTIONS_GET_OFFER_ERROR,
  ACTIONS_GET_OFFERS_START,
  ACTIONS_GET_OFFERS_SUCCESS,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS,
  ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR,
} from "~/Reducers/Offers";
import { useIsAdmin, useIsUFPBuyer } from "~/Reducers/User";
import { useCallback } from "react";
import Toast from "~/Components/Toast";
import {ls} from "~/Locales";
import {AppDispatch, useAppSelector} from "~/StoreTypes";


export enum OffersPageTableTypes {
  OFFERS_PAGE_NONE = 'OFFERS_PAGE_NONE',
  OFFERS_PAGE_STATUS = 'OFFERS_PAGE_STATUS',
}

const EMPTY_BY_GROUP_PAGE_DATA = { ids: [], loading: false, error: null };

export const useShouldShowArchiveButton = (status: OfferStatus) => {
  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const isArchived = status === OfferStatus.O_ARCHIVED || status === OfferStatus.O_DELIVERED;
  return (isUFPBuyer || isAdmin) && !isArchived;
};

export const useOffersPageReduxState = () => useAppSelector(state => state.offersPage);
export const useOffersPageByGroupReduxState = (groupId: number) =>
  useAppSelector(state => state.offersPage.offerIdsByGroups[groupId]) || EMPTY_BY_GROUP_PAGE_DATA;

export const deleteOfferEntity = (offerId: number, groupId: number, onDelete: () => void) => async (dispatch: AppDispatch) => {
  try {
    await dispatch(deleteOffer(offerId));
    dispatch({ type: 'ACTIONS_OFFERS_PAGE_DELETE_OFFER', offerId, groupId });
    onDelete();
    Toast.Success(ls('phrase.offer.deleted.successfully', offerId))
  } catch (err) { Toast.ApiError(err); }
};

export const archiveOffer = (offer: { id: number } & OfferUpdate, groupId: number, onArchive: () => void) => async (dispatch: AppDispatch) => {
  try {
    await dispatch(updateOffer(offer));
    dispatch({ type: 'ACTIONS_OFFERS_PAGE_ARCHIVE_OFFER', offerId: offer.id, groupId });
    onArchive();
    Toast.Success(ls('phrase.offer.archived.successfully', offer.id))
  } catch (err) { Toast.ApiError(err); }
};

export const clearData = () => ({ type: 'ACTIONS_OFFERS_PAGE_CLEAR_DATA' });

export const useGetOffersByStatusSummary = () => {
  const offerStatusList = useOfferStatusList();

  return useCallback((filters = { offset: 0, status: [] }) => async dispatch => {
    try {
      dispatch({ type: 'ACTIONS_OFFERS_PAGE_GET_STATUSES_START' });

      const status = !filters.status?.length ? offerStatusList : filters.status;
      const statuses = await API.getOffersByStatusSummary({ ...filters, status });
      dispatch({ type: 'ACTIONS_OFFERS_PAGE_GET_STATUSES_SUCCESS', statuses });
    } catch (error) {
      dispatch({ type: 'ACTIONS_OFFERS_PAGE_GET_STATUSES_ERROR', error });
    }
  }, [offerStatusList]);
};

// reducer
export type OffersPageState = {
  ids: number[],
  statuses: any[],
  offerIdsByGroups: {},
  loading: boolean,
  error: any,
};

const INITIAL_STATE: OffersPageState = {
  ids: [], // - offer list ids
  statuses: [], // - offer statuses info
  offerIdsByGroups: {},
  loading: false,
  error: null,
};

const reducer = (state: OffersPageState, action: any): OffersPageState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case ACTIONS_GET_OFFERS_START:
    case 'ACTIONS_OFFERS_PAGE_GET_STATUSES_START':
      return { ...state, loading: true, error: null };

    case 'ACTIONS_OFFERS_PAGE_GET_STATUSES_SUCCESS':
      return { ...state, statuses: action.statuses, loading: false, error: null };

    case ACTIONS_GET_OFFERS_SUCCESS:
      const loadedIds = action.offers.map(offer => offer.id);
      return {
        ...state,
        ids: !!action.offset ? [...new Set([...state.ids, ...loadedIds])] : loadedIds,
        loading: false,
        error: null
      };

    case ACTIONS_GET_OFFER_ERROR:
      return { ...state, loading: false, error: action.error };

    case ACTIONS_DELETE_OFFER_SUCCESS:
      return { ...state, ids: state.ids.filter(id => id !== action.offerId) };

    case ACTIONS_CREATE_OFFER_SUCCESS:
      return isRequestedOfferStatus(action.offer.status)
        ? state
        : { ...state, ids: [action.offer.id, ...state.ids] };

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_START:
      return {
        ...state,
        offerIdsByGroups: {
          ...state.offerIdsByGroups,
          [action.groupId]: { ...state.offerIdsByGroups[action.groupId], loading: true, error: null }
        }
      };

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_SUCCESS: {
      const loadedIds = action.offers.map(offer => offer.id);
      const oldIds = state.offerIdsByGroups[action.groupId]?.ids || [];
      return {
        ...state,
        offerIdsByGroups: {
          ...state.offerIdsByGroups,
          [action.groupId]: {
            ids: !!action.offset ? [...new Set([...oldIds, ...loadedIds])] : loadedIds,
            loading: false,
            error: null
          }
        }
      };
    }

    case ACTIONS_GET_REQUESTED_OFFERS_BY_GROUP_ERROR:
      return {
        ...state,
        offerIdsByGroups: {
          ...state.offerIdsByGroups,
          [action.groupId]: { ...state.offerIdsByGroups[action.groupId], loading: false, error: action.error }
        }
      };

    case 'ACTIONS_OFFERS_PAGE_DELETE_OFFER': {
      if (!!action.groupId) {
        const updatedData = {
          ...state.offerIdsByGroups[action.groupId],
          ids: state.offerIdsByGroups[action.groupId]?.ids?.filter(id => id !== action.offerId),
        };
        return {
          ...state,
          offerIdsByGroups: { ...state.offerIdsByGroups, [action.groupId]: updatedData }
        };

      } else {
        return {
          ...state,
          ids: state.ids.filter((id) => id !== action.offerId),
        };
      }
    }

    case 'ACTIONS_OFFERS_PAGE_ARCHIVE_OFFER': {
      if (!!action.groupId) {
        const updatedGroupData = {
          ...state.offerIdsByGroups[action.groupId],
          ids: state.offerIdsByGroups[action.groupId]?.ids?.filter(id => id !== action.offerId),
        };
        return {
          ...state,
          offerIdsByGroups: {
            ...state.offerIdsByGroups,
            [action.groupId]: updatedGroupData,
          }
        };

      } else {
        return state;
      }
    }

    case 'ACTIONS_OFFERS_PAGE_CLEAR_DATA':
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
