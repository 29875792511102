import useSWR from "swr";
import API from "~/API";

export enum ShipViaType {
  Trucking, Sailing, Pickup, Rail, Other
}

const shipViaTypeMap: Map<string, ShipViaType> = new Map([
  ['T', ShipViaType.Trucking], // Truck
  ['LTL', ShipViaType.Trucking], //LTL
  ['IV', ShipViaType.Trucking], // Van del y to cust
  ['PT', ShipViaType.Trucking], // Plant Truck
  ['CON', ShipViaType.Sailing], // Container
  ['V', ShipViaType.Sailing], // Vessel
  ['C', ShipViaType.Sailing], // Courier
  ['CPU', ShipViaType.Pickup], // Courier
  ['R', ShipViaType.Rail],
  ['LTL', ShipViaType.Trucking],
  ['BT', ShipViaType.Rail]
]);


export function obtainShipViaType(shipVia: string): ShipViaType {
  const type = shipViaTypeMap.get(shipVia);
  if(type !== undefined) {
    return type;
  } else {
    return ShipViaType.Other;
  }
}

export const useShipViaUnitTypes = () =>
    useSWR(["api/shipvia/units/active"], async () => await API.getAllActiveShipViaUnitTypes());