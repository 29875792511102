import React from "react";
import OrderTitleContainer from "../OrderTitleContainer";

interface PurchaseOrderTitleContainerProps {
  isInternalUser: boolean;
  orderNumber: string;
  masterChild: string | undefined;
}

const PurchaseOrderTitleContainer: React.FC<
  PurchaseOrderTitleContainerProps
> = ({ isInternalUser, orderNumber, masterChild }) => {
  const labelKey = isInternalUser
    ? "title.purchase.order.#.with.number"
    : "title.order.#.with.number";
  return (
    <OrderTitleContainer
      orderNumber={orderNumber}
      masterChild={masterChild}
      labelKey={labelKey}
    />
  );
};

export default PurchaseOrderTitleContainer;
