import API, {CreateRequestForQuoteRequest, RequestForQuoteUpdate, RequestForQuoteView} from '~/API';
import {useMemo} from 'react';
import {composedItems} from './Utils/Compose';
import {useGetEntity} from "./Utils/Entity";
import {LocalizedMessageKey, localizedString} from '~/Locales';
import dayjs from "dayjs";
import {useRequestStatusSuffix} from '~/Pages/Requests/DetailsPage/Components/RequestsHelper';
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";

export const ACTIONS_GET_REQUESTS_FOR_QUOTE_START = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_START';
export const ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS';
export const ACTIONS_GET_REQUESTS_FOR_QUOTE_ERROR = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_ERROR';
export const ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START';
export const ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS';
export const ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR = 'ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR';
export const ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS = 'ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS';

export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_START = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_START';
export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_SUCCESS = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_SUCCESS';
export const ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_ERROR = 'ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_ERROR';

export const isRequestExpired = (validityDate: string) => {
  if (!validityDate) return false;
  return dayjs(validityDate).isBefore(dayjs());
};

const requestsForQuoteSelector = (state: RootState) => state.requestsForQuote;
const requestForQuoteSelectorCreator = (requestId: number) => (state: RootState) => state.requestsForQuote[requestId];

export const useRequestForQuote = (requestId: number, force = false): RequestForQuoteView => useGetEntity(requestId, getRequestForQuote, requestForQuoteSelectorCreator, force);
export const useRequestsForQuote = () => useAppSelector(requestsForQuoteSelector);

const useRequestForQuoteKeys = () => {
  const requests = useRequestsForQuote();
  return useMemo(() => Object.keys(requests), [requests]);
};

export const useRequestForQuoteValue = (requestId: number) => {
  const request = useRequestForQuote(requestId);

  return useMemo(() => {
    if (!Array.isArray(request?.products)) return 0;
    let sum = 0;
    request.products.filter(product => !!product.active).forEach(product => {
      sum += (product.quantity || 0) * (product.price || 0);
    });
    return sum;
  }, [request?.products]);
};

export const useRequestForQuoteProductCodes = (requestId: number) => {
  const request = useRequestForQuote(requestId);

  return useMemo(() => {
    if (!Array.isArray(request?.products)) return '-';
    return [...new Set(request.products.filter(product => !!product.active).map(product => product.productCode))].join(', ');
  }, [request?.products]);
};

export const useRequestAsReferenceText = (requestId: number) => {
  const request = useRequestForQuote(requestId);
  const suffix = useRequestStatusSuffix();
  return useMemo(() => requestAsReferenceText(request, suffix), [request, suffix]);
};

export const useRequestForQuoteAsReferenceLabels = () => {
  const requests = useRequestsForQuote();
  const keys = useRequestForQuoteKeys();
  const suffix = useRequestStatusSuffix();
  return useMemo(() => keys.reduce((a, key) => {
    a[key] = requestAsReferenceText(requests[key], suffix);
    return a;
  }, {}), [keys, requests, suffix]);
};

const requestAsReferenceText = (request: RequestForQuoteView, suffix: string) => {
  if (!request) return '';
  const { id, dateCreated, status, customerCompanyName, shipToName } = request;
  const dateString = dayjs(dateCreated).format('DD MMM YYYY');
  const statusString = localizedString(`request.status.${suffix}.${status}` as LocalizedMessageKey);
  return `RQ-${id} - ${dateString} - ${statusString} - ${customerCompanyName} - ${shipToName}`;
};

interface RequestsForQuoteFilters {
  offset: number;
  [key: string]: any;
}
export const getRequestsForQuote = (filters : RequestsForQuoteFilters = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_START });
    const requests = await API.getRequestsForQuotes(filters);
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS, requests, offset: filters.offset });
    return requests;
  } catch (error) {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_ERROR, error });
  }
};

export const getRequestsForQuoteByStatus = (filters = { offset: 0, status: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START, groupId: filters.status?.[0] });
    const requests = await API.getRequestsForQuotes(filters);
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS, requests, groupId: filters.status?.[0], offset: filters.offset });
    return requests;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR, error, groupId: filters.status?.[0] });
  }
};

export const getRequestsForQuoteForShipTo = (filters = { offset: 0, shipTo: [] }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_START, groupId: filters.shipTo?.[0] });
    const requests = await API.getRequestsForQuotes(filters);
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS, requests, groupId: filters.shipTo?.[0], offset: filters.offset });
    return requests;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_ERROR, error, groupId: filters.shipTo?.[0] });
  }
};

export const getShipToGroupsForRequestsForQuote = (filters = { offset: undefined }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_START });
    const locations = await API.getRequestsForQuotesByShipToSummary(filters);
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_SUCCESS, locations, offset: filters.offset });
    return locations;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_SHIP_TO_GROUPS_FOR_REQUESTS_FOR_QUOTE_ERROR, error });
  }
};

export const getRequestForQuote = (requestId: number) => async (dispatch: AppDispatch) => {
  try {
    const request = await API.getRequestForQuote({ id: requestId });
    dispatch({ type: 'ACTIONS_GET_REQUEST_FOR_QUOTE_SUCCESS', request });
    return request;

  } catch (error) { }
};

export const createRequestForQuote = (params: CreateRequestForQuoteRequest) => async (dispatch: AppDispatch) => {
  try {
    const request = await API.createRequestForQuote({}, params);
    dispatch({ type: ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS, request });
    return request;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const updateRequestForQuote = (params: {id: number} & RequestForQuoteUpdate) => async (dispatch: AppDispatch) => {
  try {
    const request = await API.updateRequestForQuote({ id: params.id }, params);
    dispatch({ type: 'ACTIONS_UPDATE_REQUEST_FOR_QUOTE_SUCCESS', request });
    return request;
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const deleteRequestForQuote = (requestId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.deleteRequestForQuote({ id: requestId });
    dispatch({ type: 'ACTIONS_DELETE_REQUEST_FOR_QUOTE_SUCCESS', requestId });
  } catch (error) {
    Toast.ApiError(error);
    throw error;
  }
};

export const getRequestForQuoteAttachments = (requestId: number) => async (_dispatch: AppDispatch) => {
  try {
    return await API.getRequestForQuoteAttachments({ id: requestId });
  } catch (error) { }
};

export const addFollower = (requestId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.addRequestForQuoteMember({ id: requestId, userId });
    dispatch({ type: 'ACTIONS_ADD_REQUEST_FOR_QUOTE_MEMBER_SUCCESS', requestId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const removeFollower = (requestId: number, userId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.removeRequestForQuoteMember({ id: requestId, userId });
    dispatch({ type: 'ACTIONS_REMOVE_REQUEST_FOR_QUOTE_MEMBER_SUCCESS', requestId, userId });
  } catch (error) {
    Toast.ApiError(error);
  }
};

export const markRequestRead = (requestId: number) => async (dispatch: AppDispatch) => {
  try {
    await API.postRequestForQuoteReadMark({ requestId });
    dispatch({ type: "ACTIONS_MARK_REQUEST_FOR_QUOTE_READ", requestId });
  } catch (e) { } // ignore error
}

// reducer
export interface RequestsState {
  [id: number]: RequestForQuoteView;
}

const INITIAL_STATE: RequestsState = {};

const reducer = (state: RequestsState, action: any): RequestsState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_REQUESTS_FOR_QUOTE_SUCCESS:
    case ACTIONS_GET_REQUESTS_FOR_QUOTE_BY_GROUP_SUCCESS:
      return { ...state, ...composedItems(action.requests) };

    case 'ACTIONS_GET_REQUEST_FOR_QUOTE_SUCCESS':
    case ACTIONS_CREATE_REQUEST_FOR_QUOTE_SUCCESS:
    case 'ACTIONS_UPDATE_REQUEST_FOR_QUOTE_SUCCESS':
      return { ...state, [action.request.id]: action.request };

    case 'ACTIONS_ADD_REQUEST_FOR_QUOTE_MEMBER_SUCCESS': {
      if (!state[action.requestId]) return state;
      const updatedRequest = {
        ...state[action.requestId],
        members: [...new Set([...state[action.requestId].members, action.userId])]
      };
      return { ...state, [action.requestId]: updatedRequest };
    }

    case 'ACTIONS_REMOVE_REQUEST_FOR_QUOTE_MEMBER_SUCCESS': {
      if (!state[action.requestId]) return state;
      const updatedRequest = {
        ...state[action.requestId],
        members: state[action.requestId].members?.filter(member => member !== action.userId)
      };
      return { ...state, [action.requestId]: updatedRequest };
    }

    case 'ACTIONS_DELETE_REQUEST_FOR_QUOTE_SUCCESS': {
      const updatedState = { ...state };
      delete updatedState[action.requestId];
      return updatedState;
    }

    case 'ACTIONS_MARK_REQUEST_FOR_QUOTE_READ': {
      if (!state[action.requestId]) return state;
      const updatedRequest = {
        ...state[action.requestId],
        readMark: true
      };
      return { ...state, [action.requestId]: updatedRequest };
    }

    default:
      return state;
  }
};

export default reducer;
