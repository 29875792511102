import API, {ProductGroupView} from '~/API';
import {composedItems} from './Utils/Compose';
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";

export const ACTIONS_GET_PRODUCT_GROUPS_SUCCESS = 'ACTIONS_GET_PRODUCT_GROUPS_SUCCESS';
export const ACTIONS_UPDATE_PRODUCT_GROUP_SUCCESS = 'ACTIONS_UPDATE_PRODUCT_GROUP_SUCCESS';
export const ACTIONS_CREATE_PRODUCT_GROUP_SUCCESS = 'ACTIONS_CREATE_PRODUCT_GROUP_SUCCESS';

const productGroupsSelector = (state: RootState) => state.productGroups;

export const useProductGroups = (): ProductGroupsState => useAppSelector(productGroupsSelector);

export const getProductGroup = (groupId: number, onSuccess?: () => void, onError?: (error: any) => void) => async (dispatch: AppDispatch) => {
  try {
    const productGroup = await API.getProductGroup({ id: groupId });
    dispatch({ type: 'ACTIONS_GET_PRODUCT_GROUP_SUCCESS', productGroup });
    onSuccess?.();
    return productGroup;
  } catch (error) {
    Toast.ApiError(error);
    onError?.(error);
  }
};

export interface ProductGroupsState {
  [id: number]: ProductGroupView;
}

const INITIAL_STATE: ProductGroupsState = {};

const reducer = (state: ProductGroupsState, action: any): ProductGroupsState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_PRODUCT_GROUPS_SUCCESS:
      return { ...state, ...composedItems(action.productGroups) };

    case 'ACTIONS_GET_PRODUCT_GROUP_SUCCESS':
    case ACTIONS_CREATE_PRODUCT_GROUP_SUCCESS:
      return { ...state, [action.productGroup.id]: action.productGroup };

    case ACTIONS_UPDATE_PRODUCT_GROUP_SUCCESS:
      const productGroup = action.productGroup;
      return { ...state, [productGroup.id]: { ...state[productGroup.id], ...productGroup } };
    
    default:
      return state;
  }
};

export default reducer;
