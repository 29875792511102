import Environment from "./Environment";
import {feedbackIntegration} from "@sentry/react";
import {LoginResponse} from "~/API";
import Clarity from '@microsoft/clarity';

export const sentryConfig = (() => {
    if(Environment.isStage)
        return {
            environment: "stage",
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 0,
            tracesSampleRate: 0,
            sampleRate: 0,
        };
    if(Environment.isTest)
        return {
            environment: "test",
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1,
            tracesSampleRate: 0.1,
        };
    if(Environment.isProduction)
        return {
            environment: "production",
            replaysSessionSampleRate: 0.05,
            replaysOnErrorSampleRate: 0.1,
            tracesSampleRate: 0.05,
        };

    // default to disabled (localhost)
    return {
        enabled: false
    }
})();

export const sentryFeedback = feedbackIntegration({
    autoInject: false,
    colorScheme: "light",
    showBranding: false,
    showName: false,
    showEmail: false,
});

let clarityInitialized = false;

export const trackEvent = (event: string) => {
    if (clarityInitialized)
        Clarity.event(event);
}

export const initTracking = (id: number, user: LoginResponse) => {
    const userIdString = `${id}`;

    if (!Environment.isLocalhost) {
        const clarityId = Environment.isProduction
            ? import.meta.env.VITE_CLARITY_PROJECT_ID
            : import.meta.env.VITE_CLARITY_PREPROD_PROJECT_ID;

        // Init analytics tracking
        console.debug(`Init clarity project: ${clarityId}`);
        Clarity.init(clarityId);
        Clarity.identify(userIdString, undefined, undefined, user.email);
        Clarity.setTag("User Permissions", user.permissions);
        Clarity.setTag("User Company Internal", `${user.isCompanyInternal}`);
        Clarity.setTag("User Company", user.companyId?.toString() || "");
        clarityInitialized = true;
    }

    if (id && Environment.isProduction) {
        if (window['verbose']) {
            console.log('### [TrackingService identify] userId = ' + userIdString);
        }
        ((i, s, o, g, r, a, m) => {
            i['UserlaneCommandObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            };
            a = s.createElement(o);
            m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })
        (window, document, 'script', 'https://cdn.userlane.com/userlane.js', 'Userlane');
        window['Userlane']?.('identify', id, {
            user_groups: [user.userGroupId],
            user_group: user.userGroupId,
        });
        // initialize Userlane with your Property ID
        window['Userlane']?.('init', 'e90x62x10y');
    }
};
