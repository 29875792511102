import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  obtainShipViaType,
  ShipViaType,
} from "~/Data/ShipVia";
import { GraphicIcon, GraphicLine, LineSpacer } from "../OrderGraphic";
import { FC } from "react";
import { BiReceipt } from "react-icons/bi";
import {TbAnchor, TbCalendarCheck, TbShip, TbTrain, TbTruck} from "react-icons/tb";
import { Case, Match } from "~/Components/UI";
import {useIsInternalCustomer} from "~/Reducers/User";

const PurchaseOrderGraphic: FC<{ orderId: number }> = ({ orderId }) => {
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(orderId, internalCustomer);
  const {
    shipVia,
    requestDate,
    orderedDate,
    importArrivalNoticeDate,
    importCustomsReleaseDate,
    scheduledDeliveryDate,
  } = order.data || {};

  const shipViaType = obtainShipViaType(shipVia);

  return (
    <div className="flex-col mx-lg overflow-hidden">
      <div className="w-full overflow-auto my-lg relative">
        <div className="flex-row justify-between text-left w-full w-min-11xl">
          <div className="flex-row absolute top-0 right-0 bottom-0 left-0 justify-center align-center w-min-11xl">
            <LineSpacer />
            <Match on={shipViaType}>
              <Case v={ShipViaType.Trucking}>
                <GraphicLine date={requestDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={importCustomsReleaseDate} />
              </Case>

              <Case v={ShipViaType.Rail}>
                <GraphicLine date={requestDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={importCustomsReleaseDate} />
              </Case>

              <Case v={ShipViaType.Sailing}>
                <GraphicLine date={requestDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={importArrivalNoticeDate} />
                <GraphicLine date={importArrivalNoticeDate} />
                <GraphicLine date={importCustomsReleaseDate} />
              </Case>

              <Case v={ShipViaType.Pickup}>
                <GraphicLine date={requestDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={orderedDate} />
                <GraphicLine date={scheduledDeliveryDate} />
              </Case>

              <Case default>
                <GraphicLine date={requestDate} />
                <GraphicLine date={orderedDate} />
              </Case>
            </Match>
            <LineSpacer />
          </div>

          <Match on={shipViaType}>
            <Case v={ShipViaType.Trucking}>
              <GraphicIcon label="title.order.requested" date={requestDate}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.order.placed" date={orderedDate}>
                <TbCalendarCheck size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.eta" date={importCustomsReleaseDate}>
                <TbTruck size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Rail}>
              <GraphicIcon label="title.order.requested" date={requestDate}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.order.placed" date={orderedDate}>
                <TbCalendarCheck size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.eta" date={importCustomsReleaseDate}>
                <TbTrain size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Sailing}>
              <GraphicIcon label="title.order.requested" date={requestDate}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.order.confirmed" date={orderedDate}>
                <TbCalendarCheck size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.ets" date={importArrivalNoticeDate}>
                <TbShip size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.eta" date={importCustomsReleaseDate}>
                <TbAnchor size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Pickup}>
              <GraphicIcon label="title.order.requested" date={requestDate}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.order.placed" date={orderedDate}>
                <TbCalendarCheck size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.eta" date={scheduledDeliveryDate}>
                <TbTruck size={24} />
              </GraphicIcon>
            </Case>

            <Case default>
              <GraphicIcon label="title.order.requested" date={requestDate}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.order.confirmed" date={orderedDate}>
                <TbCalendarCheck size={24} />
              </GraphicIcon>
            </Case>
          </Match>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderGraphic;
