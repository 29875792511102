import { useUserPublicAvatarUrl } from "~/Reducers/Users";
import UserNameCircle from "~/Components/Old/UserNameCircle";

export const VideoCallUserPhoto = ({ userId }) => {
  const userAvatarUrl = useUserPublicAvatarUrl(userId);
  return (
    <>
      {userAvatarUrl
        ? <img className={'VideoCallUserImage'}
               style={{ zIndex: 0 }}
               src={userAvatarUrl}
               alt=""
        />
        : <UserNameCircle userId={userId} size={100}/>
      }
    </>
  );
};