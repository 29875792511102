import { useToggle } from "~/Hooks/Old";
import React from "react";
import { OfferStatus } from "~/API";
import { Button } from "~/Components/UI";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { LocalizedMessage } from "~/Locales";
import { offersRootPath } from "~/Services/Routes";
import { useHistory } from "react-router-dom";
import Toast from "~/Components/Toast";


export const RequestVendorConfirmationButton = ({ offerId, saveOffer, isOfferValid }) => {
  const history = useHistory();
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const onClick = () => {
    const valid = isOfferValid(true);
    if (valid) {
      toggleConfirmSubmitDialog();
    } else {
      if (!history.location.pathname.includes('edit')) {
        history.replace(`${offersRootPath}${offerId}/edit`);
      }
    }
  };

  const onConfirm = async () => {
    try {
      saveOffer(OfferStatus.O_CLOSING);
      Toast.Success(`Offer ${offerId} set to Vendor for confirmation`);
      // history.replace(`${requestedOffersRootPath}${offerId}/edit`);
    } catch (e) {
      console.error(e);
    }
  }

  return <>
    <Button
      onClicked={onClick}
      data-cy={"RequestVendorConfirmationButton"}
      label="request.vendor.confirmation"
    />

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Request confirmation from Vendor'}
        description={'Do you want to request the Vendor to confirm the offer,'}
        description2={'so that you can confirm it as well and create Purchase Order in the next step?'}
        confirmTitle={'Request Confirmation'}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={onConfirm}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>;
}