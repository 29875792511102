import { useCallback, useEffect, useState } from "react";
import { useCurrentUser, useIsInternalUser } from "./User";
import API, {ChatMessage, ChatMessageType} from "~/API";
import { getChatsPath, videoCallPath } from "~/Services/Routes";
import { dismissAllNotifications } from "~/Utils/Notifications";
import { checkIfUserIsInternal } from "./Users";
import {useChat} from "~/Data/Chats";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";

export interface VideoCallInvitationChatMessageText {
  userIdFrom: number;
  userIdTo: number;
  status: VideoCallInvitationChatMessageStatus;
}

export enum VideoCallInvitationChatMessageStatus {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
}


const videoCallSelector = (state: RootState) => state.videoCall;
export const useVideoCallState = () => useAppSelector(videoCallSelector);

export const useVideoCallToken = (roomName: string) => {
  const [token, setToken] = useState(null);

  const currentUser = useCurrentUser();

  useEffect(() => {
    const getToken = async () => {
      const params = {
        name: roomName,
        userIdentity: currentUser.name + '-' + currentUser.id, // identity should be unique
      }
      try {
        const data = await API.joinRoom({}, params);
        setToken(data.token);
      } catch (e) {
        console.log(e);
      }
    };
    roomName && currentUser && getToken();
  }, [roomName, currentUser]);

  return [token, setToken];
}

export const useUniqueVideoCallRoomName = (userId: number) => {
  return generateUniqueVideoCallRoomName(userId, useCurrentUser()?.id);
}

export const generateUniqueVideoCallRoomName = (userId: number, currentUserId: number) => {
  if (!!userId && !!currentUserId) {
    return userId < currentUserId ? `${userId}-${currentUserId}` : `${currentUserId}-${userId}`;
  } else {
    return null;
  }
}

export const receivedVideoCallEnded = () => (dispatch: AppDispatch) => {
  dispatch({ type: 'ACTIONS_VIDEO_CALL_ENDED_RECEIVED' });
}

export const videoCallInvitationUpdate = (message: ChatMessage) => (dispatch: AppDispatch, getState: () => RootState) => {
  const currentUserId = getState().userReducer?.id;

  const chatMessageText = JSON.parse(message.text) as VideoCallInvitationChatMessageText;
  if (message.type === ChatMessageType.CALL_INVITATION
    && chatMessageText.userIdTo === currentUserId
    && !!chatMessageText.status) {
    dismissAllNotifications();
  }

  dispatch({ type: 'ACTIONS_VIDEO_CALL_INVITATION_UPDATED', message, currentUserId });
}

export const acceptVideoCall = (userId: number, chatMessageId: number) => async (dispatch: AppDispatch) => {
  dismissAllNotifications();
  dispatch({ type: 'ACTIONS_VIDEO_ACCEPT_CALL' });
  await API.acceptCall({}, { userAccountId: userId, chatMessageId })
}
export const declineVideoCall = (userId: number, chatMessageId: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: 'ACTIONS_VIDEO_DENY_CALL' });
  await API.declineCall({}, { userAccountId: userId, chatMessageId })
  await API.endCall({}, { userAccountId: userId });
}

export const joinCallWithUser = (userId: number, chatId: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: 'ACTIONS_VIDEO_START_CALL' })
  await API.callUser({}, { userAccountId: userId, chatId: chatId });
}

export const useLinkToVideoCall = (userId: number, chatId: number) => {
  const roomName = useUniqueVideoCallRoomName(userId);
  const chat = useChat(chatId);
  const chatsPath = getChatsPath(chat.data?.internal);
  return `${chatsPath}${chatId}/${videoCallPath}/${roomName}`;
}

export const useGetLinkToVideoCall = (userId: number) => {
  const roomName = useUniqueVideoCallRoomName(userId);
  const isSelfInternal = useIsInternalUser();

  return useCallback(async (chatId: number) => {
    let isChatInternal: boolean;

    if (isSelfInternal) {
      const isUserInternal = await checkIfUserIsInternal(userId);
      isChatInternal = isUserInternal && isSelfInternal;
    }
    else {
      // for external users - always create external chat
      isChatInternal = false;
    }

    const chatsPath = getChatsPath(isChatInternal);
    return !!chatsPath && `${chatsPath}${chatId}/${videoCallPath}/${roomName}`;

  }, [userId, roomName, isSelfInternal]);
}

export const endCallWithUser = (userId: number) => async (dispatch: AppDispatch) => {
  dispatch(stopCalling);
  await API.endCall({}, { userAccountId: userId });
}

export const stopCalling = ({ type: 'ACTIONS_VIDEO_STOP_CALLING' });
export const setVideoCallOn = ({ type: 'ACTIONS_VIDEO_CALL_ON' });
export const setVideoCallOff = ({ type: 'ACTIONS_VIDEO_CALL_OFF' });

export interface VideoCallState {
  shouldShowIncomingVideoCallDialog: boolean,
  isCalling: boolean,
  isVideoCallOn: boolean,
  userIdFrom?: number,
  userIdTo?: number,
  chatMessageId?: number,
  chatId?: number,
}

const INITIAL_STATE: VideoCallState = {
  shouldShowIncomingVideoCallDialog: false,
  isCalling: false,
  isVideoCallOn: false,
  userIdFrom: null,
  userIdTo: null,
  chatMessageId: null,
  chatId: null,
};

const reducer = (state: VideoCallState, action: any): VideoCallState => {
  state = state || INITIAL_STATE;
  // console.log('### action.type = ', action.type, action);
  switch (action.type) {
    case 'ACTIONS_VIDEO_START_CALL':
      return {
        ...state,
        isCalling: true,
      }
    case 'ACTIONS_VIDEO_CALL_ON':
      return {
        ...state,
        isVideoCallOn: true,
      }
    case 'ACTIONS_VIDEO_CALL_OFF':
      return {
        ...state,
        isVideoCallOn: false,
        isCalling: false,
      }
    case 'ACTIONS_VIDEO_STOP_CALLING':
      return {
        ...state,
        isCalling: false,
      }
    case 'ACTIONS_VIDEO_CALL_ENDED_RECEIVED':
      return INITIAL_STATE;

    case 'ACTIONS_VIDEO_CALL_INVITATION_UPDATED':
      const chatMessage = action.message;
      const chatMessageText = JSON.parse(chatMessage.text) as VideoCallInvitationChatMessageText;
      if (chatMessage.type === ChatMessageType.CALL_INVITATION
        && chatMessageText.userIdTo === action.currentUserId
        && !chatMessageText.status) {
        return {
          ...state,
          shouldShowIncomingVideoCallDialog: true,
          userIdFrom: chatMessageText.userIdFrom,
          chatMessageId: chatMessage.id,
          chatId: chatMessage.chatId,
          userIdTo: chatMessageText.userIdTo,
        }
      } else {
        return {
          ...state,
          shouldShowIncomingVideoCallDialog: false,
        }
      }
    case 'ACTIONS_VIDEO_ACCEPT_CALL':
      return {
        ...state,
        shouldShowIncomingVideoCallDialog: false,
      }
    case 'ACTIONS_VIDEO_DENY_CALL':
      return {
        ...state,
        shouldShowIncomingVideoCallDialog: false,
      }
    default:
      return state;
  }
};

export default reducer;
