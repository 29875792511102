import { useHistory } from "react-router-dom";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";
import {Button} from "~/Components/UI";

const NoServerPage: FC = () => {
  usePageTitle("Error");
  const history = useHistory();

  const onRefresh = () => history.go(0);

  return (
    <div className="m-2xl text-left">
      <div className="text-3xl">Server Could not be Reached</div>
      <div className="text-lg color-text-2 mt-sm">
        Our backend services could not be reached. Please check your internet
        connection and refresh the page.
      </div>
      <div className="text-lg color-text-2">
        If you're still experiencing issues, we could be down for maintenance,
        or experiencing an outage.
      </div>
      <Button className="mt-md" onClicked={onRefresh} label="title.refresh" />
    </div>
  );
};

export default NoServerPage;
