import { useEffect, useState } from "react";
import {AppDispatch, RootState, useAppDispatch, useAppSelector} from "~/StoreTypes";


type SingleEntityLoaderFunction = (id: number) => (dispatch: AppDispatch, state?: RootState) => Promise<any>;
type SelectorFunction<T> = (state: RootState) => T;
type CreateSelectorFunction<T> = (id: number) => SelectorFunction<T>;

const requests = {};

export const useGetEntity = <T>(id: number, entityLoader: SingleEntityLoaderFunction, selectorCreator: CreateSelectorFunction<T>, force = false): T => {
  const entity = useAppSelector(selectorCreator(id));
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if ((!entity || force) && id) {

      const requestKey = id + " " + selectorCreator;
      if (!!requests[requestKey]) return;

      requests[requestKey] = 'iAmLoading';

      const loadEntity = async () => {
        try {
          await dispatch(entityLoader(id));
        } catch (error) {
          console.log('Error while fetching data:', error);
        } finally {
          delete requests[requestKey]
        }
      }

      loadEntity().then();
    }
  }, [id, force, entityLoader]);
  
  return entity;
};

type EntityListLoaderFunction<E> = (params?: any) => (dispatch: AppDispatch) => Promise<E>;

export const useGetEntityList = <T, E>(entityListLoader: EntityListLoaderFunction<E>,
                                    selector: SelectorFunction<T>,
                                    force = false) => {
  const entityList = useAppSelector(selector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!entityListLoader) return;

    if (isEmpty(entityList) || force) {
      const requestKey = "list_" + entityListLoader;

      if (!!requests[requestKey]) return;

      const loadList = async () => {
        requests[requestKey] = 'iAmLoading';
        try {
          await dispatch(entityListLoader());
        } catch (error) {
          console.log('Error while fetching data:', error);
        } finally {
          delete requests[requestKey]
        }
      }

      loadList().then();
    }
  }, [force, entityListLoader]);

  return entityList;
};


type GetEntitiesParams = {
  id: number,
  [key: string]: any
}

type APIFunction<T> = (params: object) => Promise<T[]>;

export const useGetEntitiesForId = <T>(params: GetEntitiesParams, apiFunction: APIFunction<T>): [T[], boolean] => {
  const [entities, setEntities] = useState<T[]>([]);
  const [loading, setLoading] = useState(!!params?.id);

  useEffect(() => {
    !!params?.id && getData();
  }, [params?.id]);

  const getData = async () => {
    try {
      setLoading(true);
      const result = await apiFunction(params);
      setEntities(result);
    } catch (error) {
      console.log('Error while fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return [entities, loading];
};

// Old version, using callbacks. Deprecated.

type SingleEntityLoaderFunction_deprecated = (id: any, onSuccess: Function, onError: Function) => Function;

export const useGetEntity_deprecated = <T>(id: number, entityLoader: SingleEntityLoaderFunction_deprecated, selectorCreator: CreateSelectorFunction<T>, force = false): T => {
  const entity = useAppSelector(selectorCreator(id));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((!entity || force) && id) {
      
      const requestKey = id + " " + selectorCreator;
      if (!!requests[requestKey]) return;
      
      requests[requestKey] = 'iAmLoading';
      dispatch(entityLoader(id,
        () => delete requests[requestKey],
        () => delete requests[requestKey]
      ));
    }
  }, [id, force, entityLoader]);
  
  return entity;
};

const isEmpty = (obj: any) => obj && Object.keys(obj).length === 0;
