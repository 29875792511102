import { orNoop } from "react-ui-basics/Tools";
import API, {
  Attachment,
  AttachmentRef,
  AttachmentRestriction,
} from "~/API";
import { useCallback, useRef } from "react";

export const useDetachHandler = (
  attachments: Attachment[],
  setAttachments: (attachments: Attachment[]) => void,
) => {
  const attachmentsRef = useRef<Attachment[]>();
  attachmentsRef.current = attachments;

  return useCallback(
    (file) => {
      orNoop(file.cancel)();

      const list = [...attachmentsRef.current];
      const i = list.indexOf(file);
      if (i !== -1) list.splice(i, 1);

      setAttachments(list);
    },
    [setAttachments],
  );
};

export const UploadAttachments = async (
  files: File[],
  ref: AttachmentRef,
  refId: number,
) => {
  return Promise.all(
    files.map(async (file) => {
      const attach = await API.createAttachment(
        {},
        {
          name: file.name,
          size: file.size,
          active: true,
          restriction: AttachmentRestriction.PUBLIC,
          ref,
          refId,
        },
      );

      await API.uploadAttachment({ id: attach.id }, { file }, 0);
    }),
  );
};

export const useAsyncAttachHandler = (
  attachments: Attachment[],
  setAttachments: (attachments: Attachment[]) => void,
  createProps: () => any,
  uploadProps?: () => any,
) => {
  return async (acceptedFiles: File[]) => {
    const newAttachments = await Promise.all(
      acceptedFiles.map(async (file) => {
        try {
          const attachment = await API.createAttachment({}, {
            name: file.name,
            size: file.size,
            ...(orNoop(createProps)() || {}),
          });

          return API.uploadAttachment(
            { id: attachment.id },
            { file, ...(orNoop(uploadProps)() || {}) },
          );
        } catch (e) {
          console.error(e);
          return undefined;
        }
      })
    );

    setAttachments([...attachments, ...newAttachments.filter((a) => a != undefined)]);
  }
};

const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];

export const fileSizeString = (fileSizeInBytes: number | any) => {
  if (typeof fileSizeInBytes !== "number") return "0 B";
  let i = -1;
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) * 17351 + ((hash << 5) - hash);
  }

  const maxColorValue = 512;
  const colors = [
    (hash >> 0) & 0xff,
    (hash >> 8) & 0xff,
    (hash >> (2 * 8)) & 0xff,
  ];
  const valueDiff = colors[0] + colors[1] + colors[2] - maxColorValue;
  if (valueDiff > 0) {
    const diff = valueDiff / 3;
    colors[0] -= diff;
    colors[1] -= diff;
    colors[2] -= diff;
  }

  let color = "#";
  for (const c of colors) {
    color += ("00" + c.toString(16)).slice(-2);
  }
  return color;
}
