import API, {Port, UpsertPortRequest} from '~/API';
import {useMemo} from "react";
import {composedItems} from './Utils/Compose';
import {useGetEntity_deprecated} from "./Utils/Entity";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";

export const ACTIONS_GET_PORTS_START = 'ACTIONS_GET_PORTS_START';
export const ACTIONS_GET_PORTS_SUCCESS = 'ACTIONS_GET_PORTS_SUCCESS';
export const ACTIONS_GET_PORTS_ERROR = 'ACTIONS_GET_PORTS_ERROR';
export const ACTIONS_UPDATE_PORT_SUCCESS = 'ACTIONS_UPDATE_PORT_SUCCESS';
export const ACTIONS_CREATE_PORT_SUCCESS = 'ACTIONS_CREATE_PORT_SUCCESS';

const portsSelector = (state: RootState) => state.ports;
const portSelectorCreator = (portId: number) => (state: RootState) => state.ports[portId];

export const extractPortName = (port: Port, showCodes = false) => {
  return !!port && `${showCodes ? '(' + port.code + ')' : ''} ${port.name} - ${port.city}`;
};

const usePorts = () => useAppSelector(portsSelector);
export const usePort = (portId: number, force = false) => useGetEntity_deprecated(portId, getPort, portSelectorCreator, force);

export const usePortLabels = (showCodes = false) => {
  const ports = usePorts();
  return useMemo(
    () => Object.keys(ports).reduce((a, key) => ({...a, [ports[key].id]: extractPortName(ports[key], showCodes)}), {}),
    [ports, showCodes]
  );
};

export const getPorts = (filters: any = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_PORTS_START });
    const ports = await API.getAllPorts(filters);
    dispatch({ type: ACTIONS_GET_PORTS_SUCCESS, ports, offset: filters.offset, filters });
    return ports;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_PORTS_ERROR, error });
  }
};

const getPort = (portId: number, onSuccess?: () => void, onError?: (error: any) => void) => async (dispatch: AppDispatch) => {
  API.getPortById({ id: portId })
    .then((port) => {
      dispatch({ type: 'ACTIONS_GET_PORT_SUCCESS', port });
      onSuccess?.();
    }).catch(onError);
};

export const createOrUpdatePort = (params: UpsertPortRequest & { id?: number }) => async (dispatch: AppDispatch) => {
  const apiFunc = !!params?.id ? API.updatePort : API.addPort;
  try {
    const port = await apiFunc({ id: params?.id }, params);
    dispatch({ type: !!params?.id ? ACTIONS_UPDATE_PORT_SUCCESS : ACTIONS_CREATE_PORT_SUCCESS, port });
    return port;
  } catch (error) {
    Toast.ApiError(error);
  }
};

export type PortsState = {
  [id: number]: Port;
};

// reducer
const INITIAL_STATE: PortsState = {};

const reducer = (state: PortsState, action: any): PortsState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ACTIONS_GET_ACTIVE_PORTS_SUCCESS':
    case ACTIONS_GET_PORTS_SUCCESS:
      return { ...state, ...composedItems(action.ports) };

    case 'ACTIONS_GET_PORT_SUCCESS':
    case ACTIONS_UPDATE_PORT_SUCCESS:
    case ACTIONS_CREATE_PORT_SUCCESS:
      return { ...state, [action.port.id]: action.port };

    default:
      return state;
  }
};

export default reducer;
