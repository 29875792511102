import { FC, useMemo, useState } from "react";
import { Button, FormSelect, Table } from "~/Components/UI";
import { BarChart } from "~/Components/Charts/BarChart";
import { useFormData } from "~/Hooks";
import vars from "~/Styles/Vars";
import {useUserMetricsData} from "~/Pages/Metrics/Data/UserMetricsData";
import dayjs from "dayjs";
import { LinearLoader } from "~/Components/Loaders";
import { TimeSeriesCategoricalMetric, UserAccountView } from "~/API";
import { TbArrowBackUp } from "react-icons/tb";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useUsers } from "~/Data/Users";
import DateView from "~/Components/Views/DateView";
import UserGroupName from "~/Components/Users/UserGroupName";
import {
  MetricsCard, MetricsHeader,
  MetricStat,
  MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import {FILTER_ALL, PERIODS} from "~/Pages/Metrics/Data/MetricsData";

export const NewUserMetricsCard: FC = () => {
  const filterForm = useFormData<{
    group: number;
    period: PERIODS;
  }>({
    group: FILTER_ALL,
    period: PERIODS.SIX_MONTHS,
  });

  const endpointData = useUserMetricsData(filterForm.data.period);

  const dataset = useMemo(() => {
    const data = endpointData.data || [];

    if (filterForm.data.group === FILTER_ALL) {
      return data.map((d) => ({
        period: d.period,
        data: [
          {
            category: "Total",
            count: Object.values(d.data).reduce(
              (p: number, c) => p + c.count,
              0,
            ),
          },
        ],
      }));
    }

    return data.map((d) => ({
      period: d.period,
      data: [
        {
          category: "Total",
          count: d.data.find((p) => p.categoryId === filterForm.data.group)
            .count,
        },
      ],
    }));
  }, [filterForm.data, endpointData]);

  const userGroups = (endpointData.data || [
    { period: "", data: [] },
  ])[0].data.map((d) => ({ id: d.categoryId, label: d.category }));

  const getUserGroupLabel = (id: number) => {
    if (id == FILTER_ALL) return "All User Groups";
    return userGroups.find((u) => u.id == id)?.label || "";
  };

  const lastPeriodGroupLabel = useMemo(() => {
    if (filterForm.data.group === FILTER_ALL) return "user";
    return getUserGroupLabel(filterForm.data.group);
  }, [filterForm.data, userGroups]);

  const totalCount = useMemo(() => {
    return dataset.reduce(
      (prev: number, period) => prev + period.data[0].count,
      0,
    );
  }, [dataset]);

  const quarterTotalCount = useMemo(() => {
    return dataset
      .slice(-4)
      .reduce((prev: number, period) => prev + period.data[0].count, 0);
  }, [dataset]);

  const [activeDetailsPeriod, setActiveDetailsPeriod] = useState<
    number | undefined
  >();
  const onBarClicked = (_: any, index: number) => setActiveDetailsPeriod(index);
  const onBack = () => setActiveDetailsPeriod(undefined);

  return (
    <MetricsCard>
      {activeDetailsPeriod != undefined && (
        <UserList
          onBack={onBack}
          metrics={endpointData.data[activeDetailsPeriod]}
          userGroup={filterForm.data.group}
        />
      )}
      {activeDetailsPeriod == undefined && (
        <>
          <MetricsHeader title="New Users">
              <FormSelect
                id="group"
                formData={filterForm}
                data={[FILTER_ALL, ...userGroups.map((u) => u.id)]}
                getLabel={getUserGroupLabel}
              />
              <FormSelect
                id="period"
                formData={filterForm}
                data={Object.values(PERIODS)}
                getLabel={(id) => id}
              />
          </MetricsHeader>

          <MetricStatContainer>
            <MetricStat
              label={
                <span>
                  New {formatPlural(lastPeriodGroupLabel, totalCount)} in the
                  last {filterForm.data.period}
                </span>
              }
              loading={endpointData.isLoading}
            >
              {totalCount}
            </MetricStat>

            <MetricStat
              label={
                <span>
                  New {formatPlural(lastPeriodGroupLabel, quarterTotalCount)} in
                  the last 4 months
                </span>
              }
              loading={endpointData.isLoading}
            >
              {quarterTotalCount}
            </MetricStat>
          </MetricStatContainer>

          <BarChart
            dataset={dataset}
            xAxisFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMM")
            }
            tooltipLabelFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMMM YYYY")
            }
            width="100%"
            height={vars.size["7xl"]}
            loading={endpointData.isLoading}
            onbarClicked={onBarClicked}
          />
        </>
      )}
    </MetricsCard>
  );
};

const userHelper = createColumnHelper<UserAccountView>();

const columns = [
  userHelper.accessor("dateRegistered", {
    header: "Date",
    cell: (data) => <DateView date={data.getValue()} />,
    size: 0,
  }),
  userHelper.display({
    id: "name",
    header: "Name",
    cell: (data) => (
      <div className="text-truncate">{`${data.row.original.name} ${data.row.original.surname}`}</div>
    ),
  }),
  userHelper.accessor("userGroupId", {
    header: "User Group",
    cell: (data) => <UserGroupName id={data.getValue()} className="text-no-wrap" />,
    size: 88,
  }),
];

const UserList: FC<{
  onBack: () => void;
  metrics: TimeSeriesCategoricalMetric;
  userGroup: number;
}> = (props) => {
  const dateRegisteredTo = useMemo(() => {
    return dayjs(props.metrics.period.slice(0, 10))
      .endOf("month")
      .format("YYYY-MM-DD");
  }, [props.metrics.period]);

  const users = useUsers({
    dateRegisteredFrom: props.metrics.period.slice(0, 10),
    dateRegisteredTo,
    userGroup: props.userGroup == FILTER_ALL ? [] : [props.userGroup],
  });

  const table = useReactTable({
    // TODO: users with no group aren't counted in metrics so filter them out
    // TODO: long term we should prevent users from being made/updated without a group
    data: users.data?.flat().filter((u) => u.userGroupId != null) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onScrollToBottom = () => users.setSize(users.size + 1);

  return (
    <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
      <Button
        variant="text"
        color="gray"
        onClicked={props.onBack}
        leftIcon={<TbArrowBackUp size={16} />}
        label="title.back"
      />
      <div className="text-xl">
        New Users{" "}
        {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
      </div>
      <LinearLoader loading={users.isLoading} />
      <Table
        table={table}
        onScrolledBottom={onScrollToBottom}
        className="w-full"
      />
    </div>
  );
};

const formatPlural = (val: string, count: number) =>
  val + (val.endsWith("s") || count < 2 ? "" : "s");