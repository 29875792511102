import { useCallback, useEffect, useState } from "react";
import { getPorts, usePortLabels } from "~/Reducers/Ports";
import SelectWithoutPaging from "./SelectWithoutPaging";
import {useAppDispatch} from "~/StoreTypes";


const PortSelect = ({ showInactive = false, byType = null, showCodes = false, ...props }) => {
  const dispatch = useAppDispatch();
  const [keys, setKeys] = useState([]);

  const labels = usePortLabels(showCodes);

  const getPortList = useCallback(async () => {
    const inactive = !!showInactive ? null : false;
    const filters = !!byType ? { ...props.filter, type: byType, inactive } : { ...props.filter };
    try {
      const ports = await dispatch(getPorts(filters));
      setKeys(ports.map(port => port.id));
    } catch { }
  }, [showInactive, byType, props.filter]);

  useEffect(() => {
    getPortList();
  }, [getPortList]);

  return <SelectWithoutPaging {...props}
    data={keys}
    labels={labels}
    skipLabelSorting
  />;
};

export default PortSelect;
