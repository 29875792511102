import { FC } from "react";
import { ToggleProps, useFormData, useMutateHook } from "~/Hooks";
import { Button, FormInput, FormSwitch } from "~/Components/UI";
import API, { Length } from "~/API";
import {trackEvent} from "~/Services/Tracking";

const useAddTranslation = () =>
  useMutateHook<Length, void, Length>("api/lengths", (args) =>
    API.createLength({}, args),
  );

export const LengthAddForm: FC<{
  open: ToggleProps;
  refreshData: () => void;
}> = (props) => {
  const addForm = useFormData<Partial<Length>>({
    sopo: false,
    unit: "FT",
    active: false,
  });
  const add = useAddTranslation();

  const onAddClicked = () =>
    add.call(addForm.data as Length).then(() => {
      props.open.toggleFalse();
      props.refreshData();
      trackEvent("Length Added");
    });

  return (
    <div className="desktop-w-9xl flex-col gap-md">
      <FormSwitch label="title.active" formData={addForm} id="active" />
      <FormInput
        id="unit"
        formData={addForm}
        disabled
        label="title.unit"
        className="desktop-w-min-9xl"
      />
      <FormInput
        id="value"
        formData={addForm}
        label="title.length"
        placeholder="title.length"
        className="desktop-w-min-9xl"
      />
      <FormInput
        id="nominal"
        formData={addForm}
        label="title.nominal"
        placeholder="title.nominal"
        step="0.0001"
        className="desktop-w-min-9xl"
      />
      <FormInput
        id="actual"
        formData={addForm}
        label="title.actual"
        placeholder="title.actual"
        step="0.0001"
        className="desktop-w-min-9xl"
      />

      <Button
        label="title.add"
        loading={add.isMutating}
        onClicked={onAddClicked}
      />
    </div>
  );
};
