import API, {
  GroupPermission,
  Registration,
  UserAccountView,
  UserAccountPublicView,
} from '~/API';
import {useEffect, useMemo, useState} from 'react';
import {add3digitCodesToArray} from '~/Data/Countries';
import {localizedString} from '~/Locales';
import {composedItems} from './Utils/Compose';
import {useGetEntity_deprecated} from "./Utils/Entity";
import {hasAnyOfInternalPermissions} from "./User";
import {useLocation} from "~/Reducers/Locations";
import {AppDispatch, RootState, useAppDispatch, useAppSelector} from "~/StoreTypes";

export const ACTIONS_GET_USERS_START = 'ACTIONS_GET_USERS_START';
export const ACTIONS_GET_USERS_SUCCESS = 'ACTIONS_GET_USERS_SUCCESS';
export const ACTIONS_GET_USERS_ERROR = 'ACTIONS_GET_USERS_ERROR';
export const ACTIONS_CREATE_USER_SUCCESS = 'ACTIONS_CREATE_USER_SUCCESS';
export const ACTIONS_REMOVE_USER_SUCCESS = 'ACTIONS_REMOVE_USER_SUCCESS';

const userNameFromUser = (user: UserAccountPublicView) => {
  if (!!user) {
    if (!user.active) return `${user.name} ${user.surname} (Inactive)`;
    const nameParts = [];
    if (!!user.name) nameParts.push(user.name);
    if (!!user.surname) nameParts.push(user.surname);
    return nameParts.join(' ').trim();
  }
  return '';
};

const buildLabelForDropdown = (userId: number, users: UsersState) => {
  const prefix = users[userId].sr ? `${users[userId].sr} - ` : '';
  return `${prefix}${extractUserName(userId, users)}`;
};

export const extractUserName = (userId: number, users: UsersState) => {
  return userNameFromUser(users[userId]);
};

export const useUserNames = (ids = EMPTY_ARRAY) => {
  const users = useUsers();

  useGetUserPublicInfoByIds(ids);

  return useMemo(() => {
    const names = [];
    ids.forEach(userId => {
      const name = extractUserName(userId, users);
      if (!!name?.length) names.push(name);
    });
    return names.join(', ');
  }, [users, ids]);
};

export const useAMUserNames = (amUserId: number, locationId: number) => {
  const location = useLocation(locationId);
  const managerIds = useMemo(() => {
    const ids = [];
    amUserId && ids.push(amUserId);
    location?.accountManagerUserId && location?.accountManagerUserId !== amUserId && ids.push(location?.accountManagerUserId);
    return ids;
  }, [amUserId, location?.accountManagerUserId]);

  return useUserNames(managerIds);
};

export const useAMUserNameStrings = (amUserName: string, shipToAmUserName: string) => {
  let val = amUserName ? [amUserName] : [];
  if (shipToAmUserName)
    val.push(shipToAmUserName);

  return val.join(", ");
};

export const formatRegistrationStatus = (status: Registration) => {
  if (Object.values(Registration).includes(status)) {
    return localizedString(`user.account.status.${status}`);
  }
  return status;
};

const hasFullUserInfo = (user: UserAccountView) => !!user?.userGroupId;
const hasUserPublicInfo = (user: UserAccountView) => !!user;

// hooks
const usersSelector = (state: RootState) => state.users;
const userFullInfoSelectorCreator = (userId: number) => (state: RootState) => hasFullUserInfo(state.users[userId]) ? state.users[userId] : null;
const userPublicInfoSelectorCreator = (userId: number) => (state: RootState) => hasUserPublicInfo(state.users[userId]) ? state.users[userId] : null;

export const useUsers = (): UsersState => useAppSelector(usersSelector);

export const useUser = (userId: number, force = false) => useGetEntity_deprecated(userId, getUser, userFullInfoSelectorCreator, force);
export const useUserPublic = (userId: number, force = false): UserAccountPublicView => useGetEntity_deprecated(userId, getUserPublic, userPublicInfoSelectorCreator, force);

export const useUserPublicAvatarUrl = (userId: number) => {
  const photoAttachmentId = useUserPublic(userId)?.photoAttachmentId;
  return photoAttachmentId ? API.urlAttachmentThumbnail(photoAttachmentId) : null;
};

export const useUserName = (userId: number) => {
  const user = useUserPublic(userId);
  return userNameFromUser(user);
};

const useUserKeys = () => {
  const users = useUsers();
  const [userKeys, setUserKeys] = useState([]);
  useEffect(() => {
    setUserKeys(Object.keys(users));
  }, [users]);
  return userKeys;
};

export const useUserLabels = () => {
  const users = useUsers();
  const userKeys = useUserKeys();
  const [userLabels, setUserLabels] = useState({});
  useEffect(() => {
    setUserLabels(userKeys.reduce((a, option) => ({ ...a, [option]: buildLabelForDropdown(option, users) }), {}));
  }, [userKeys]);
  return userLabels;
};

export const useUserLabelsWithCompany = () => {
  const users = useUsers();
  const userKeys = useUserKeys();

  const [userLabels, setUserLabels] = useState({});
  useEffect(() => {
    setUserLabels(userKeys.reduce((a, option) => {
      const user = users[option];
      return ({ ...a, [user.id]: `${extractUserName(user.id, users)} (${user.email})` });
    }, {}));
  }, [userKeys]);
  return userLabels;
};


export const checkIfUserIsInternal = async (userId: number) => {
  const userPermissions = await getUserPermissions(userId) as GroupPermission[];
  return hasAnyOfInternalPermissions(userPermissions);
};

const getUserPermissions = async (userId: number) => {
  return API.getUserUserPermissions({ id: userId});
};

const useGetUserPublicInfoByIds = (ids: any[]) => {
  const dispatch = useAppDispatch();
  const users = useUsers();
  const [usersById, setUsersById] = useState([]);

  useEffect(() => {
    (ids || []).forEach(id => (users[id] || dispatch(getUserPublic(id))));
  }, [dispatch, ids]);

  useEffect(() => {
    ids && ids.length > 0 && setUsersById((ids || []).map(id => users[id]).filter(Boolean));
  }, [ids]);

  return usersById;
};

const EMPTY_ARRAY = [];

// action creators
export const getUser = (userId: number, onSuccess?: () => void, onError?: (error: any) => void) => (dispatch: AppDispatch) => {
  userId && API.getUser({ id: userId })
    .then((user) => {
      dispatch({ type: 'ACTIONS_GET_USER_SUCCESS', user });
      onSuccess?.();
    })
    .catch(onError);
};

export const getUserPublic = (userId: number, onSuccess?: () => void, onError?: (error: any) => void) => async (dispatch: AppDispatch) => {
  try {
    const user = await API.getUserPublicInfo({ id: userId });
    // dirty hack: save user to two different reducers
    dispatch({ type: 'ACTIONS_GET_USER_SUCCESS', user });
    dispatch({ type: 'ACTIONS_GET_PUBLIC_USER_SUCCESS', user });
    onSuccess?.();
    return user;
  } catch (error) {
    onError?.(error);
  }
};

export const getUsers = (filters: any = { offset: 0, country: undefined }) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ACTIONS_GET_USERS_START });
    const updatedFilters = { ...filters };
    if (filters.country) updatedFilters.country = add3digitCodesToArray(filters.country);
    const users = await API.getUsers(updatedFilters);
    dispatch({ type: ACTIONS_GET_USERS_SUCCESS, users, offset: filters.offset });
    return users;

  } catch (error) {
    dispatch({ type: ACTIONS_GET_USERS_ERROR, error });
  }
};

export const getUsersByPermissions = (permissionTypes: GroupPermission[] = [], params: any = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    const users = await API.getUsersByPermissions({ permissionTypes, ...params });
    dispatch({ type: 'ACTIONS_GET_USERS_BY_PERMISSIONS_SUCCESS', users });
    return users;
  } catch { }
};

export const getCompanyUsers = (companyId: number, params: any = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    const users = await API.getCompanyUsers({ id: companyId, ...params });
    dispatch({ type: 'ACTIONS_GET_COMPANY_USERS_SUCCESS', users });
    return users;
  } catch { }
};

// reducer
export interface UsersState {
  [id: number]: UserAccountView;
}
const INITIAL_STATE: UsersState = {};

const reducer = (state: UsersState, action: any): UsersState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_USERS_SUCCESS:
    case 'ACTIONS_GET_COMPANY_USERS_SUCCESS':
    case 'ACTIONS_GET_USERS_BY_PERMISSIONS_SUCCESS': {
      const loadedUsers = action.users.map((user: UserAccountView) => (!!state[user.id] ? { ...state[user.id], ...user } : user));
      return { ...state, ...composedItems(loadedUsers) };
    }

    case 'ACTIONS_GET_USER_SUCCESS':
    case 'ACTIONS_UPDATE_USER_SUCCESS':
      return {
        ...state,
        [action.user.id]: !!state[action.user.id] ? { ...state[action.user.id], ...action.user } : action.user
      };

    case ACTIONS_CREATE_USER_SUCCESS:
      return { ...state, [action.user.id]: action.user };

    default:
      return state;
  }
};

export default reducer;
