import {QuotesByStatusSummary, QuoteStatus} from "~/API";
import {FC, useCallback, useEffect, useMemo, useRef} from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  useQuote,
  useQuoteValue,
  useQuoteProductCodes,
  getQuotesByStatus,
  isQuoteExpired
} from "~/Reducers/Quotes";
import { useIsCustomer } from "~/Reducers/User";
import {
  useQuotesPageReduxState,
  useQuotesPageByGroupReduxState,
  getQuoteStatusesTotalInfo,
  useDefaultPageFilters, QuotesFilters
} from "../../Reducer";
import Button from "~/Components/Old/Button";
import { DateTimeView } from "~/Components/Views/DateView";
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import {QuoteShowUnread, QuoteStatusLabel} from "../QuotesHelper";
import QuotesPageEmptyState from "../QuotesPageEmptyState";
import QuoteArchiveDeleteButton from "../QuoteArchiveDeleteButtons";
import QuoteReference from "../QuoteReference";
import { useFilters } from "~/Components/Old/useFilters";
import { classNames } from "react-ui-basics/Tools";
import './QuotesPageTableWithSubrows.scss';
import {useAMUserNameStrings} from "~/Reducers/Users";
import {useAppDispatch} from "~/StoreTypes";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";


const QuotesPageByStatusTable: FC<{ filters: QuotesFilters }> = ({ filters }) => {
  const dispatch = useAppDispatch();

  const scrollRef = useRef();

  useEffect(() => {
    dispatch(getQuoteStatusesTotalInfo(filters));
  }, [filters]);

  const { statuses, loading } = useQuotesPageReduxState();
  const emptyState = statuses?.length === 0 && !loading;

  return emptyState
    ? <QuotesPageEmptyState />
    : <>
        {loading && <div className={'progress-bar'}><LinearProgress /></div>}
        <ScrollableForPagination ref={scrollRef} className={'flex bg-black-12 overflow-auto'}>
          <table className={'basic-table basic-table-clickable QuotesPageTableWithSubrows'}>
            {statuses.map(status => <StatusRow key={status.status} {...{ status }} />)}
          </table>
        </ScrollableForPagination>
    </>
};

export default QuotesPageByStatusTable;


const StatusRow: FC<{ status: QuotesByStatusSummary }> = ({ status }) => {
  const [expanded, expandCollapse] = useToggle(false);

  const { numberOfQuotes, totalValue, currency } = status;
  const currencySymbol = useFindCurrencySymbol(currency);

  return (
    <>
      <thead>
        <tr onClick={expandCollapse} className={'ClickableHeaderOrRow'}>
          <th>{expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}</th>
          <th colSpan={2}><QuoteStatusLabel status={status.status} alwaysShowColor /></th>
          <th colSpan={2}>
            <div className={'flex-row align-center'}>
              <LocalizedMessage id={'title.number.of.quotes'} />:
              &nbsp;
              <div className={'color-orange-1'}>{numberOfQuotes}</div>
            </div>
          </th>
          <th colSpan={2}>
            <div className={'flex-row align-center'}>
              <LocalizedMessage id={'title.total.value'} />:
              &nbsp;
              <div className={'color-orange-1'}>{`${currencySymbol} ${formattedNumber(totalValue)}`}</div>
            </div>
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
        </tr>
      </thead>
      {expanded && <QuotesForStatus status={status.status} />}
    </>
  );
};

const QUOTES_PER_PAGE = 10;
const filtersToExclude = ['status', 'limit'];

const QuotesForStatus: FC<{ status: QuoteStatus }> = ({ status }) => {
  const pageDataByGroupId = useQuotesPageByGroupReduxState(status);

  const defaultPageFilters = useDefaultPageFilters();

  const pageFilters = useMemo(() => (
    { ...defaultPageFilters, status: [status], limit: QUOTES_PER_PAGE })
    , [status, defaultPageFilters]);
  const pageDataByStatusReduxFunc = useCallback(() => pageDataByGroupId, [pageDataByGroupId]);

  const { onScrolledToBottom, canLoadMore } = useFilters(pageFilters, getQuotesByStatus, pageDataByStatusReduxFunc, null, null, filtersToExclude);

  const { ids = [], loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  const isCustomer = useIsCustomer();

  return (
    <tbody>
      <tr className={'QuoteHeaderRowAsSubrow'}>
        <td />
        <td><LocalizedMessage id={'title.q.id'} /></td>
        <td />
        <td><LocalizedMessage id={'title.ship.to'} /></td>
        {!isCustomer && <td><LocalizedMessage id={'title.customer'} /></td>}
        <td><LocalizedMessage id={'title.salesperson'} /></td>
        <td><LocalizedMessage id={'title.products'} /></td>
        <td><LocalizedMessage id={'title.product.code'} /></td>
        {isCustomer && <><td /> <td /></>}
        {!isCustomer && <td><LocalizedMessage id={'title.reference'} /></td>}
        <td className={'text-right'}><LocalizedMessage id={'title.value'} /></td>
        <td><LocalizedMessage id={'title.validity.date'} /></td>
        <td><LocalizedMessage id={'title.actions'} /></td>
        <td />
      </tr>

      {ids.map(quoteId => <QuoteRow key={quoteId} status={status} {...{ quoteId }} />)}

      {showAdditionalRow &&
        <tr className={'QuoteRowAsSubrow'}>
          {!showLoader &&
            <td colSpan={13} className={'MoreButtonCell'}>
              <Button view={'text'} className={classNames('w-full', showTryAgain && 'DeleteButtonText')}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage id={showTryAgain ? 'title.try.again' : 'title.get.more'} />
              </Button>
            </td>
          }
          {showLoader && <td colSpan={13}><LinearProgress /></td>}
        </tr>
      }
    </tbody>
  );
};

const QuoteRow: FC<{ quoteId: number, status: QuoteStatus }> = ({ quoteId, status }) => {
  const history = useHistory();
  const quote = useQuote(quoteId) || undefined;
  const value = useQuoteValue(quoteId);
  const productCodes = useQuoteProductCodes(quoteId);
  const { customerCompanyName, shipToName, offerId, currency, offerProductCount, products, salesOrderIds,
    requestForQuoteId, validityDate, accountManagerName, shipToAccountManagerName } = quote;
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = status === QuoteStatus.DRAFT;

  const onOpen = useCallback(() => history.push(`${quotesRootPath}${quoteId}`), [quoteId, history]);
  const isCustomer = useIsCustomer();

  const isExpired = isQuoteExpired(validityDate);
  const managers = useAMUserNameStrings(accountManagerName, shipToAccountManagerName);

  return (
    <tr className={'QuoteRowAsSubrow'} onClick={onOpen}>
      <td className={classNames(QuoteShowUnread(quote.readMark, isExpired) ? "row-unread" : "")}/>
      <td>{`Q-${quoteId}`}</td>
      <td>{isExpired && 'Expired'}</td>
      <td>{shipToName}</td>
      {!isCustomer && <td>{customerCompanyName}</td>}
      <td>{managers}</td>
      <td>{`${products?.length || 0}`} {!isCustomer && `/${offerProductCount}`}</td>
      <td>{productCodes}</td>
      {isCustomer && <><td /> <td /></>}

      {!isCustomer && <td><QuoteReference offerId={offerId} salesOrderIds={salesOrderIds} requestForQuoteId={requestForQuoteId} simple /></td>}
      <td className={'text-right no-wrap color-orange-1'}>
        {`${currencySymbol} ${formattedNumber(value) || '0'}`}
      </td>
      <td><DateTimeView date={validityDate} /></td>
      <td>
        <div className={'flex-row align-center justify-center'}>
          <QuoteArchiveDeleteButton quote={quote} groupId={status} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={quote.unreadMessagesCount} />}</td>
    </tr>
  );
};
