import { LC, ls } from "~/Locales";
import PriceView from "~/Components/Views/PriceView";
import { useOrderInvoices } from "~/Reducers/OrderInvoices";
import {Spinner} from "~/Components/Loaders";
import { FC } from "react";
import { DeliveryInvoice } from "~/API";
import {Show, Table} from "~/Components/UI";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";

const OrderInvoices: FC<{ orderId: number }> = ({ orderId }) => {
  const [invoices, loading] = useOrderInvoices(orderId);

  const table = useReactTable({
    data: invoices || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex-col p-lg text-left overflow-hidden">
      <div className="text-xl color-black-1">
        <LC id="title.invoices" />
      </div>
      <Show when={!loading} fallback={<Spinner loading={true} className="mx-auto" />}>
        <Table table={table} className="mt-md" />
      </Show>
    </div>
  );
};

const columnHelper = createColumnHelper<DeliveryInvoice>();

const columns = [
  columnHelper.accessor("invoiceNumber", {
    header: () => ls("title.invoice.number"),
  }),
  columnHelper.accessor("invoiceAmount", {
    header: () => ls("title.amount"),
    cell: (data) => <PriceView price={data.getValue()} currency={data.row.original.currency} />,
    size: 0
  }),
  columnHelper.accessor("remainingAmount", {
    header: () => ls("title.invoice.open"),
    cell: (data) => <PriceView price={data.getValue()} currency={data.row.original.currency} />,
    size: 0
  }),
  columnHelper.accessor("paymentCount", {
    header: () => ls("title.invoice.payment.count"),
    size: 0
  }),
  columnHelper.accessor("paid", {
    header: () => ls("title.invoice.status"),
    cell: (data) => data.getValue() ? "Paid" : "Open",
    size: 0
  }),
];

export default OrderInvoices;
