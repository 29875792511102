import useSWRImmutable from "swr/immutable";
import API, {
    AddChatParticipantRequest,
    Chat,
    ChatMessageType,
    ChatMessageView,
    ChatType,
    ChatUnreadView,
    ChatView,
    CreateDirectChatRequest,
    NewMessage, 
    NotificationLevelEnum, 
} from "~/API";
import useSWR, {mutate} from "swr";
import useSWRInfinite from "swr/infinite";
import {useMutateHook} from "~/Hooks";
import {useCurrentUser, useIsInternalCustomer, useIsInternalUser, UserReducerState} from "~/Reducers/User";
import {useCallback, useMemo} from "react";
import {checkIfUserIsInternal} from "~/Reducers/Users";
import {ScopedMutator} from "swr/_internal";

export const useChatMessage = (id: number) =>
  useSWRImmutable(["api/chat/message", id], () => API.getChatMessage({ id }));

export const useChatsAllUnreadCount = () =>
  useSWR("api/chat/unread/count", async () => API.getUnreadCountForAllChats());

export interface ChatFilters {
  search: string;
  showArchived: boolean;
  showUnreadOnly: boolean;
  company: number | undefined;
  user: number | undefined;
  showAllChats: boolean;
}

const getChatListKey = (
  filters: ChatFilters,
  internal: boolean,
  offset: number,
  previousPageData: ChatUnreadView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/chats", ...filters, offset, internal };
};

const PAGE_SIZE = 20;

export const useChats = (filters: ChatFilters, internal: boolean) =>
  useSWRInfinite(
    (i, p) => getChatListKey(filters, internal, i * PAGE_SIZE, p),
    (key) =>
      API.getChats({
        ...filters,
        user: filters.user || [],
        company: filters.company || [],
        internal: key.internal,
        offset: key.offset,
        limit: PAGE_SIZE,
        notEmptyEntityChatIgnoreSystemMessage: true,
      } as any),
  );

export const useChat = (id?: number) =>
  useSWR(id == undefined ? null : ["api/chat", id], async () => API.getChat({ id }));

export const useChatOrderInternal = (orderId: number | null, isPurchase: boolean, isUserInternal: boolean) =>
  useSWR(orderId == null || !isUserInternal ? null : ["api/chat/order/internal", orderId, isPurchase], async () => {
    if (isPurchase)
      return API.getInternalPurchaseOrderChat({ id: orderId } as any);

    return API.getInternalSalesOrderChat({ id: orderId } as any);
    },
  );

export const useChatOrderExternal = (orderId: number | null, isPurchase: boolean) =>
  useSWR(orderId == null ? null : ["api/chat/order/external", orderId, isPurchase], async () => {
    if (isPurchase)
      return API.getExternalPurchaseOrderChat({ id: orderId } as any);

    return API.getExternalSalesOrderChat({ id: orderId } as any);
    },
  );

export const useChatOrderUnread = (orderId: number, isPurchase: boolean) => {
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();

  const internalChat = useChatOrderInternal(orderId, isPurchase, isInternalUser || isInternalCustomer);
  const externalChat = useChatOrderExternal(!isInternalCustomer ? orderId : null, isPurchase);

  const internalCount = (isInternalUser || isInternalCustomer) ? internalChat.data?.unread || 0 : 0;
  const externalCount = externalChat.data?.unread || 0;

  return internalCount + externalCount;
}

export const useChatQuote = (quoteId: number | null, isInternal: boolean, isInternalUser: boolean) =>
  useSWR(quoteId == null || (isInternal && !isInternalUser) ? null : ["api/chat/quote", quoteId, isInternal], async () => {
      if (isInternal)
        return API.getInternalQuoteChat({ id: quoteId } as any);

      return API.getExternalQuoteChat({ id: quoteId } as any);
    },
  );

export const useChatRequestForQuote = (quoteId: number | null, isInternal: boolean, isInternalUser: boolean) =>
  useSWR(quoteId == null || (isInternal && !isInternalUser) ? null : ["api/chat/rfq", quoteId, isInternal], async () => {
      if (isInternal)
        return API.getInternalRequestForQuoteChat({ id: quoteId });

      return API.getExternalRequestForQuoteChat({ id: quoteId });
    },
  );

export const useChatOffer = (offerId: number | null, isInternal: boolean, isInternalUser: boolean) =>
  useSWR(offerId == null || (isInternal && !isInternalUser) ? null : ["api/chat/offer", offerId, isInternal], async () => {
      if (isInternal)
        return API.getInternalOfferChat({ id: offerId } as any);

      return API.getExternalOfferChat({ id: offerId } as any);
    },
  );

export const useChatMessages = (id: number) =>
  useSWR(["api/chat/messages", id], async () => API.getChatMessages({ id }));

export const useChatCreateMessage = (
  chatId: number,
  currentUser: UserReducerState,
) =>
  useMutateHook<NewMessage, ChatMessageView[], ChatMessageView>(
    ["api/chat/messages", chatId],
    async (args) => await API.createChatMessage({ id: chatId }, args),
    (oldData, args) => [
      ...oldData,
      {
        id: 10000,
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        type: ChatMessageType.TEXT,
        chatId,
        userAccountId: currentUser.id,
        userSurname: currentUser.surname,
        userName: currentUser.name,
        attachments: args.attachments.map((a) => a.id),
        mentionedUserIds: args.mentionedUserIds,
        text: args.text,
      },
    ],
  );

export const useChatAddParticipant = (id: number) =>
  useMutateHook<AddChatParticipantRequest, ChatUnreadView>(
    ["api/chat", id],
    async (args) => {
      await API.addChatParticipant({ id }, args);
      await mutate(["api/chat/participants", id]);
    },
    (oldData, args) => ({
      ...oldData,
      participants: [...oldData.participants, args.userAccountId],
    }),
  );

export const useChatRemoveParticipant = (id: number) =>
  useMutateHook<{ userId: number }, ChatUnreadView>(
    ["api/chat", id],
    async (args) => {
      await API.removeChatParticipant({ id, ...args });
      await mutate(["api/chat/participants", id]);
    },
    (oldData, args) => ({
      ...oldData,
      participants: oldData.participants.filter((p) => p !== args.userId),
    }),
  );

export const useChatParticipants = (id: number) =>
  useSWR(["api/chat/participants", id], async () =>
    API.getChatParticipants({ id }),
  );

export const useIsUserChatParticipant = (chat?: ChatUnreadView, userId?: number) => {
  return useMemo(() => {
    return userId && chat?.participants?.includes(userId);
  }, [chat, userId]);
}

export const useChatUpdate = (id: number) =>
  useMutateHook<Chat, Chat>(
    ["api/chat", id],
    async (args) => {
      return await API.updateChat({ id }, args);
    },
    (_oldData, args) => args,
  );

export const useChatCreate = () =>
  useMutateHook<CreateDirectChatRequest, any, ChatView>(
    "api/chat/create",
    async (args) => API.createChat({}, args),
  );

export const useChatCreateDirectWithUser = (userId: number) => {
  const isSelfInternal = useIsInternalUser();
  const currentUser = useCurrentUser();

  return useCallback(async () => {
    let isChatInternal: boolean;

    if (isSelfInternal) {
      const isUserInternal = await checkIfUserIsInternal(userId);
      isChatInternal = isUserInternal && isSelfInternal;
    }
    else {
      // for external users - always create external chat
      isChatInternal = false;
    }
    return (await API.createChat({}, { title: "", internal: isChatInternal, type: ChatType.DIRECT, participants: [userId, currentUser.id] })).id;
  }, [userId, isSelfInternal]);

};

export const getChatParticipantNotificationLevel = (id?: number | null, chatId?: number | null) =>
  useSWR(
    [undefined, null].includes(id) || [undefined, null].includes(chatId)
      ? null
      : ["api/notification/{id}/getLevel", id, chatId],
    () => API.getChatParticipantNotificationLevel({ chatId }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const useUpdateChatParticipant = (id: number, chatId: number) =>
  useMutateHook<NotificationLevelEnum | null, NotificationLevelEnum>(
    ["api/notification/{id}/getLevel", id, chatId],
    (args) => API.updateChatParticipant({ chatId }, { updateLevel: args }),
    (_oldData, args) => args,
  );

export const getPurchaseOrderChatId = (id: number | null) => useSWR(
    id == null ? null : ["api/purchaseorders/{id}/chat/id", id],
    () => API.getPurchaseOrderChatId({ id }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const getSalesOrderChatId = (id: number | null) => useSWR(
    id == null ? null : ["api/salesorders/{id}/chat/id", id],
    () => API.getSalesOrderChatId({ id }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const getQuoteChatId = (id: number | null) => useSWR(
    id == null ? null : ["api/quote/{id}/chat/id", id],
    () => API.getQuoteChatId({ id }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const getRequestForQuoteChatId = (id: number | null) => useSWR(
    id == null ? null : ["api/requestsForQuotes/{id}/chat/id", id],
    () => API.getRequestForQuoteChatId({ id }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const getOfferChatId = (id: number | null) => useSWR(
    id == null ? null : ["api/offers/{id}/chat/id", id],
    () => API.getOfferChatId({ id }),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

export const invalidateChats = (mutate: ScopedMutator) => {
  mutate(key => typeof key === "object" && key[0] === "api/chat");
}