import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import {useEffect, useMemo} from "react";
import {useShipViaUnitTypes} from "~/Data/ShipVia";

interface ShipViaUnitsComboboxProps extends Omit<FormComboboxProps<any, any>, "getLabel" | "data"> {
    defaultUnitType: string
    setDefaultUnitType: (value: string) => void;
}

export const ShipViaUnitsCombobox: React.FC<ShipViaUnitsComboboxProps> = (props) => {
    const {defaultUnitType, setDefaultUnitType} = props;

    const {data: unitTypeList, error} = useShipViaUnitTypes();

    useEffect(() => {
        const shipVia = props.formData.data.shipVia;
        const unitType = unitTypeList?.find(it => it.shipVia === shipVia)?.defaultUnitType || null;
        setDefaultUnitType(unitType);
    }, [props.formData.data.shipVia]);


    const unitTypes = useMemo(() => {
        if (!unitTypeList || !props.formData.data.shipVia) return null;
        return unitTypeList.filter(it => it.shipVia === props.formData.data.shipVia)[0] || null
    }, [props.formData.data.shipVia]);

    const unitTypeValues = useMemo(() => {
        if (!unitTypes) return null;
        return unitTypes.unitTypes || null
    }, [unitTypes]);

    const unitTypeKeys = useMemo(() => {
        if (!unitTypeValues) return null;
        return Object.keys(unitTypeValues)
    }, [unitTypeValues]);

    const getLabel = (code: string) => {
        if (!code || !unitTypeValues || !unitTypeValues[code]) {
            return "";
        }
        return unitTypeValues[code].description || "";
    };

    if (error) return <div>Error loading unit types</div>;
    if (!unitTypeList) return <div>Loading...</div>;
    if (!unitTypeKeys || defaultUnitType === '') return <div></div>

    return (
        <FormCombobox
            {...props}
            data={unitTypeKeys}
            getLabel={getLabel}
        />
    );
};
