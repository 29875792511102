import { useState, useEffect, useCallback, useRef } from 'react';
import { useSearchParams } from '~/Services/Routes';
import { useRouter } from '~/Reducers/Router';
import { debounce } from 'react-ui-basics/Tools';
import { isDifferent } from 'react-ui-basics/Tools';
import {useAppDispatch} from "~/StoreTypes";


const EMPTY_ARRAY = [];

/**
* @deprecated please use `useFormData()`
**/
export const useFilters = (pageFilters, getEntitiesFunc, usePageState, shouldSkipLoading, scrollRef, filtersToExclude = [], dateOffsetField) => {
  const dispatch = useAppDispatch();
  const { previousLocation } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams(true);
  const [filters, setFilters] = useState({ ...pageFilters, ...parseSearchParams(searchParams, pageFilters) });
  const [canLoadMore, setCanLoadMore] = useState(true);

  const [skipLoading, setSkipLoading] = useState(shouldSkipLoading?.(previousLocation));

  const { ids = EMPTY_ARRAY, scrollPosition } = usePageState();

  const dateOffset = useRef('');

  const previousIdsCount = useRef(-1);

  useEffect(() => {
    const oldValue = previousIdsCount.current;
    previousIdsCount.current = ids.length;

    const newIdsCount = ids.length - oldValue;
    const limit = filters.limit || 1;
    setCanLoadMore(limit <= newIdsCount);
  }, [ids]);

  const getMore = useCallback(debounce((filters, idsOffset) => {
    const offset = !!dateOffsetField ? dateOffset.current : idsOffset;
    if (!offset) scrollRef?.current?.scrollTo(0, 0);
    const params = { ...filters, offset };
    if (!!getEntitiesFunc) {
      dispatch(getEntitiesFunc(params))
        .then((data) => {
          if (!!dateOffsetField && !!data?.[data.length - 1]?.[dateOffsetField]) {
            dateOffset.current = data?.[data.length - 1]?.[dateOffsetField];
          }
        });
    }
  }, 300), [getEntitiesFunc, dateOffsetField]);

  const updateFilters = useCallback((newParams) => {
    const parsedParams = parseSearchParams(newParams, pageFilters);
    setFilters({ ...filters, ...parsedParams });
    previousIdsCount.current = 0;
    setCanLoadMore(true);
  }, [pageFilters, filters]);

  useEffect(() => {
    if (!skipLoading) {
      const filteredSearchParams = { ...searchParams };
      filtersToExclude.forEach(it => delete filteredSearchParams[it]);
      updateFilters(filteredSearchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    const filteredSearchParams = { ...searchParams };
    filtersToExclude.forEach(it => delete filteredSearchParams[it]);
    const mergedFilters = { ...pageFilters, ...filteredSearchParams };
    if (isDifferent(mergedFilters, filters)) {
      updateFilters(mergedFilters);
    }
  }, [pageFilters]);

  useEffect(() => {
    if (skipLoading) {
      setSkipLoading(false);
      scrollRef?.current?.scrollTo(0, scrollPosition);
    } else {
      dateOffset.current = '';
      getMore(filters, 0);
    }
  }, [filters]);

  const onScrolledToBottom = useCallback(() => {
    getMore(filters, ids.length);
  }, [getMore, filters, ids]);

  const setFilter = useCallback(({ target }) => {
    let fieldValue;
    if (Array.isArray(pageFilters[target.id])) {
      if (Array.isArray(target.value)) {
        fieldValue = target.value.includes('0') ? '' : target.value.join('±');
      } else {
        fieldValue =  target.value.includes('0') ? '' : target.value;
      }
    } else if (typeof pageFilters[target.id] === 'boolean') {
      fieldValue = target.checked;
    } else {
      fieldValue = target.value;
    }

    // Handle null or undefined values (but not 0)
    if (fieldValue === null || fieldValue === undefined) {
      fieldValue = '';
    }
    setSearchParams({ ...searchParams, [target.id]: fieldValue });
  }, [pageFilters, searchParams, setSearchParams]);

  // reset filters to initial state and add new filters
  const resetFiltersTo = useCallback((newFilters) => {
    setFilters({ ...pageFilters, ...newFilters });
    setSearchParams({ ...newFilters });
  }, [pageFilters, setFilters, setSearchParams]);

  return { filters, setFilter, onScrolledToBottom, canLoadMore, resetFiltersTo };
};

export const parseSearchParams = (newParams, pageFilters) => {
  const params = {};
  Object.keys(newParams).forEach(key => {
    if (Array.isArray(pageFilters[key]) && typeof newParams[key] === 'string') {
      if (!!newParams[key].length) {
        params[key] = newParams[key].split('±');
      } else {
        params[key] = [];
      }
    } else if (typeof pageFilters[key] === 'boolean' && typeof newParams[key] === 'string') {
      params[key] = newParams[key].toLowerCase() === 'true';
    } else {
      params[key] = newParams[key];
    }
  });
  return params;
};
