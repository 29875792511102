import { FC, useMemo } from "react";
import { useIsAdmin, useIsInternalUser, useIsLogistics } from "~/Reducers/User";
import {
  ActiveField,
  Button,
  editControlsColumn,
  FormCheckbox,
  FormCombobox,
  FormInput,
  FormInputRange,
  Table,
} from "~/Components/UI";
import Toast from "~/Components/Toast";
import { saveAs } from "file-saver";
import {TbDownload, TbFilter, TbUpload} from "react-icons/tb";
import {params, useFormData, useMutate, usePageTitle, useToggle} from "~/Hooks";
import { FilterPanel } from "~/Components/Entities/FilterPanel";
import { LogisticsFilters, useLogisticsCosts } from "~/Data/LogisticsCosts";
import { LinearLoader } from "~/Components/Loaders";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import API, {
  Order,
  PortType,
  SeaFreightCostCategory,
  SeaFreightCostIncoterm,
  SeaFreightCostView,
} from "~/API";
import { LC, ls } from "~/Locales";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import PriceView from "~/Components/Views/PriceView";
import { IncotermView } from "~/Components/Views/IncotermView";
import { useFormRowEdit } from "~/Hooks/useFormRowEdit";
import {FormDateInput, FormDateInputRange} from "~/Components/UI/Forms/DateInput";
import { IncotermCombobox } from "~/Components/Comboboxes/IncotermCombobox";
import { PortCombobox } from "~/Components/Ports/PortCombobox";
import { CurrencyCombobox } from "~/Components/Comboboxes/CurrencyCombobox";
import {
  AddOnCostCombobox,
  AddOnCostView,
} from "~/Components/Comboboxes/AddOnCostCombobox";
import {useSortState} from "~/Hooks/useSortState";
import {AddLogisticsCostDialog} from "~/Pages/Logistics/LogisticsCosts/AddLogisticsCostDialog";
import {Link} from "react-router-dom";
import {seaFreightCostsImportRootPath} from "~/Services/Routes";
import * as z from "zod";
import {trackEvent} from "~/Services/Tracking";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
  onlyEmptyPrice: params.bool,
  portOfLoading: params.numberArray,
  incotermLoading: params.stringArray,
  portOfDischarge: params.numberArray,
  incotermDischarge: params.stringArray,
  transitTime: params.string,
  costPer40From: params.string,
  costPer40To: params.string,
  currency: params.stringArray,
  category: params.stringArray,
  destinationType: params.stringArray,
  vendor: params.string,
  validityDateFrom: params.date,
  validityDateTo: params.date,
  lastChangedFrom: params.date,
  lastChangedTo: params.date,
});

const useCanEdit = () => {
  const isAdmin = useIsAdmin();
  const isLogistics = useIsLogistics();
  return isAdmin || isLogistics;
};

const LogisticsCostsPage: FC = () => {
  usePageTitle("Logistics Costs");

  const canEdit = useCanEdit();
  const canSeeFilters = useIsInternalUser();

  const filtersOpen = useToggle();

  const filters = useFormData<Partial<LogisticsFilters>>({
    search: "",
    inactive: false,
    onlyEmptyPrice: false,
    portOfLoading: [],
    incotermLoading: [],
    portOfDischarge: [],
    incotermDischarge: [],
    transitTime: "",
    costPer40From: "",
    costPer40To: "",
    currency: [],
    category: [],
    destinationType: [],
    vendor: "",
    validityDateFrom: "",
    validityDateTo: "",
    lastChangedFrom: "",
    lastChangedTo: "",
  }, { routeParser });

  const sort = useSortState();

  const costs = useLogisticsCosts({
    ...filters.data,
    ...sort.data,
  });

  const onExport = useMutate(async () => {
    try {
      const exportData = await API.exportSeaFreightCosts({
        orderBy: "DATE_CREATED",
        order: Order.ASC,
        ...filters,
      });
      const blob = new Blob([exportData.data], { type: "text/csv;charset=utf-8;" });
      const timestampString = new Date().toLocaleString().replace(/[ ,]/g, "_");
      saveAs(blob, `logistics-costs-${timestampString}.csv`);
      trackEvent("Logistics Exported");
    } catch (error) {
      Toast.ApiError(error);
      throw error;
    }
  });

  const onScrolledToBottom = () => costs.setSize(costs.size + 1);

  const rowForm = useFormRowEdit<SeaFreightCostView>("id", async (data) => {
    await API.updateSeaFreightCost({ id: data.id }, { currencyDTHC: "", ...data });
    await costs.mutate();
    trackEvent("Logistics Cost Updated");
  }, {
    onChange: (oldData, newData) => {
      let returnData = { ...newData };
      if (oldData.category != newData.category)
        returnData.addons = []; // reset addons when category changes

      return returnData
    },
  });

  const emailsData = useMemo(() => costs.data?.flat() || [], [costs.data]);
  const table = useReactTable({
    data: emailsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["route"],
        right: ["editControls"],
      },
    },
    meta: {
      editRow: rowForm,
    },
  });

  return (
    <div className="flex-row gap-md flex overflow-hidden text-left">
      <div className="flex flex-col overflow-auto bg-black-12 rounded-xxs">
        <div className="flex-row gap-sm align-center flex-wrap px-lg py-md sticky top-0">
          {canEdit && (
            <AddLogisticsCostDialog refreshCosts={costs.mutate} />
          )}
          <FormInput
            id="search"
            formData={filters}
            showClear
            placeholder="title.search"
            className="desktop-w-min-8xl"
            type="search"
          />
          <FormCheckbox
            id="inactive"
            formData={filters}
            label="title.inactive"
          />
          <FormCheckbox
            id="onlyEmptyPrice"
            formData={filters}
            label="phrase.price.empty"
          />
          {canEdit && (
            <Button
              color="gray"
              label="title.import"
              leftIcon={<TbDownload size={16} />}
              asChild
            >
              <Link to={seaFreightCostsImportRootPath} />
            </Button>
          )}

          <Button
            color="gray"
            leftIcon={<TbUpload size={16} />}
            onClicked={onExport.mutate}
            disabled={onExport.loading}
            label="title.export"
          />

          {canSeeFilters && (
            <Button
              color="gray"
              leftIcon={<TbFilter size={16} />}
              label="title.filter"
              onClicked={filtersOpen.toggle}
              className="ml-auto"
            />
          )}
        </div>

        <LinearLoader loading={costs.isValidating} />
        <Table
          table={table}
          sort={sort}
          onScrolledBottom={onScrolledToBottom}
        />
      </div>

      {canSeeFilters && (
        <FilterPanel open={filtersOpen}>
          <IncotermCombobox
            id="incotermLoading"
            label="title.incoterm.-.loading"
            multiple
            showClear
            showSelectAll
            clearValue={[]}
            formData={filters}
          />

          <PortCombobox
            id="portOfLoading"
            label="title.port.of.loading"
            multiple
            showClear
            clearValue={[]}
            formData={filters}
          />

          <IncotermCombobox
            id="incotermDischarge"
            label="title.incoterm.-.discharge"
            multiple
            showClear
            showSelectAll
            clearValue={[]}
            formData={filters}
          />

          <PortCombobox
            id="portOfDischarge"
            label="title.port.of.discharge"
            multiple
            showClear
            clearValue={[]}
            formData={filters}
          />

          <FormCombobox
            id="destinationType"
            label="title.destination.type"
            showClear
            clearValue={[]}
            multiple
            showSelectAll
            data={
              Object.values(PortType)
                .filter(p => ![PortType.PORT_OF_DISCHARGE, PortType.PORT_OF_LOADING].includes(p))
            }
            getLabel={(p) => ls(`port.type.${p}`)}
            formData={filters}
          />

          <FormInput
            showClear
            label="title.transit.time"
            type="number"
            id="transitTime"
            formData={filters}
          />

          <FormInputRange
            formData={filters}
            idFrom="costPer40From"
            idTo="costPer40To"
            label="title.cost.per.40"
            type="number"
          />

          <CurrencyCombobox
            id="currency"
            label="title.currency"
            showSelectAll
            showClear
            multiple
            clearValue={[]}
            formData={filters}
          />

          <FormCombobox
            id="category"
            label="title.category"
            showClear
            clearValue={[]}
            multiple
            showSelectAll
            data={Object.values(SeaFreightCostCategory)}
            getLabel={(d) => ls(`logistics.cost.category.${d}`)}
            formData={filters}
          />

          <FormDateInputRange
            formData={filters}
            idFrom="validityDateFrom"
            idTo="validityDateTo"
            label="title.validity.date"
            showClear
          />

          <FormDateInputRange
            formData={filters}
            idFrom="lastChangedFrom"
            idTo="lastChangedTo"
            label="title.last.changed"
            showClear
          />

          <FormInput
            showClear
            label="title.vendor"
            id="vendor"
            formData={filters}
          />

          <Button
            color="gray"
            label="title.clear"
            onClicked={filters.clear}
          />
        </FilterPanel>
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<SeaFreightCostView>();

const columns = [
  columnHelper.display({
    id: "route",
    header: () => ls("title.route"),
    cellMemo: ({ row }) => {
      const name = useRouteNameForLocationCost(row);
      return <div className="text-no-wrap">{name}</div>;
    },
    size: 143,
  }),
  columnHelper.accessor("active", {
    header: () => ls("title.active"),
    cellMemo: ({ value }) => <ActiveField active={value} />,
    editCell: ({ editRow }) => (
      <FormCheckbox
        id="active"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("incotermLoading", {
    header: () => ls("title.incoterm.-.loading"),
    sortId: "incoterm_loading",
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        <IncotermView incoterm={value} />
      </div>
    ),
    editCell: ({ editRow }) => (
      <IncotermCombobox
        id="incotermLoading"
        showClear
        clearValue={null}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("portOfLoadingId", {
    header: () => ls("title.port.of.loading"),
    sortId: "portOfLoadingName",
    cellMemo: ({ row }) => row.portOfLoadingName || "-",
    editCell: ({ editRow }) => (
      <PortCombobox
        id="portOfLoadingId"
        showClear
        clearValue={null}
        formData={editRow.form}
        filters={{
          inactive: false,
          type: [PortType.PORT, PortType.PORT_OF_LOADING],
        }}
      />
    ),
  }),
  columnHelper.accessor("destinationLoadingId", {
    header: () => ls("title.destination.loading"),
    cellMemo: ({ row }) => row.destinationLoadingName || "-",
    sortId: "destinationLoadingName",
    editCell: ({ editRow }) => (
      <PortCombobox
        id="destinationLoadingId"
        showClear
        clearValue={null}
        formData={editRow.form}
        filters={{
          inactive: false,
          type: [PortType.DOOR, PortType.CY, PortType.RAMP],
        }}
      />
    ),
  }),
  columnHelper.accessor("incotermDischarge", {
    header: () => ls("title.incoterm.-.discharge"),
    sortId: "incoterm_discharge",
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        <IncotermView incoterm={value} />
      </div>
    ),
    editCell: ({ editRow }) => (
      <IncotermCombobox
        id="incotermDischarge"
        showClear
        clearValue={null}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("portOfDischargeId", {
    header: () => ls("title.port.of.discharge"),
    cellMemo: ({ row }) => row.portOfDischargeName || "-",
    sortId: "portOfDischargeName",
    editCell: ({ editRow }) => (
      <PortCombobox
        id="portOfDischargeId"
        showClear
        clearValue={null}
        formData={editRow.form}
        filters={{
          inactive: false,
          type: [PortType.PORT, PortType.PORT_OF_DISCHARGE],
        }}
      />
    ),
  }),
  columnHelper.accessor("destinationDischargeId", {
    header: () => ls("title.destination.discharge"),
    sortId: "destinationDischargeName",
    cellMemo: ({ row }) => row.destinationDischargeName || "-",
    editCell: ({ editRow }) => (
      <PortCombobox
        id="destinationDischargeId"
        showClear
        clearValue={null}
        formData={editRow.form}
        filters={{
          inactive: false,
          type: [PortType.DOOR, PortType.CY, PortType.RAMP],
        }}
      />
    ),
  }),
  columnHelper.accessor("transitDurationDays", {
    header: () => ls("title.transit.time"),
    sortId: "transit_duration_days",
    editCell: ({ editRow }) => (
      <FormInput
        id="transitDurationDays"
        type="number"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("pricePerContainer", {
    header: () => ls("title.cost.per.40"),
    sortId: "price_per_container",
    cellMemo: ({ value }) => <PriceView price={value} />,
    editCell: ({ editRow }) => (
      <FormInput
        id="pricePerContainer"
        type="number"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("currencyPrice", {
    header: () => ls("title.currency"),
    sortId: "currency_price",
    editCell: ({ editRow }) => (
      <CurrencyCombobox
        id="currencyPrice"
        showClear
        clearValue={null}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("category", {
    header: () => ls("title.category"),
    sortId: "category",
    cellMemo: ({ value }) =>
      value ? (
        <div className="text-no-wrap">
          <LC id={`logistics.cost.category.${value}`} />
        </div>
      ) : (
        "-"
      ),
    editCell: ({ editRow }) => (
      <FormCombobox
        id="category"
        showClear
        clearValue={null}
        data={Object.values(SeaFreightCostCategory)}
        getLabel={(d) => ls(`logistics.cost.category.${d}`)}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("addons", {
    header: () => ls("title.incl.add.on.costs"),
    cellMemo: ({ value }) => (
      <div className="w-8xl">
        {value?.map((c, i) => (
          <>
            <AddOnCostView id={c} />
            {value.length - 1 != i && ", "}
          </>
        ))}
      </div>
    ),
    editCell: ({ editRow }) => (
      <AddOnCostCombobox
        id="addons"
        type={editRow.form.data.category as any}
        multiple
        showClear
        clearValue={[]}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("dateExpired", {
    header: () => ls("title.valid.date"),
    sortId: "date_expired",
    cellMemo: ({ value }) => <DateView date={value} />,
    editCell: ({ editRow }) => (
      <FormDateInput
        id="dateExpired"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("forwarder", {
    header: () => ls("title.vendor"),
    sortId: "forwarder",
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
    editCell: ({ editRow }) => (
      <FormInput
        id="forwarder"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("notes", {
    header: () => ls("title.rate.notes"),
    sortId: "notes",
    cellMemo: ({ value }) => <div className="w-9xl">{value}</div>,
    editCell: ({ editRow }) => (
      <FormInput
        id="notes"
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("dateUpdated", {
    header: () => ls("title.date.last.updated"),
    sortId: "date_updated",
    cellMemo: ({ value }) => (
      <div className="text-no-wrap">
        <DateTimeView date={value} />
      </div>
    ),
  }),
  editControlsColumn(columnHelper),
];

export default LogisticsCostsPage;

const comboIncoterms = [
  SeaFreightCostIncoterm.FCA_COMBO,
  SeaFreightCostIncoterm.DDP_COMBO,
  SeaFreightCostIncoterm.DAP_COMBO,
];

export const useRouteNameForLocationCost = (cost: SeaFreightCostView) => {
  const {
    incotermLoading,
    incotermDischarge,
    portOfLoadingCode,
    portOfDischargeCode,
    portOfLoadingId,
    portOfDischargeId,
    destinationLoadingId,
    destinationDischargeId,
  } = cost;

  const nameParts = [];
  if (comboIncoterms.includes(incotermLoading) && destinationLoadingId)
    nameParts.push(destinationLoadingId);
  if (portOfLoadingId) nameParts.push(portOfLoadingCode);
  if (portOfDischargeId) nameParts.push(portOfDischargeCode);
  if (comboIncoterms.includes(incotermDischarge) && destinationDischargeId)
    nameParts.push(destinationDischargeId);
  return nameParts.join(" - ");
};
