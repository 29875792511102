import { FC, ReactNode } from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/Components/UI";
import { useMutate, useToggle } from "~/Hooks";

export const DeletePopover: FC<{
  trigger: ReactNode;
  children: ReactNode;
  align?: "center" | "end" | "start";
  deleteCall: () => Promise<any>;
}> = (props) => {
  const open = useToggle();
  const call = useMutate(props.deleteCall);

  return (
    <Popover open={open.value} onOpenChange={open.set}>
      <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
        {props.trigger}
      </PopoverTrigger>
      <PopoverContent align={props.align} onClick={(e) => e.stopPropagation()}>
        <div className="flex-col gap-md m-xs w-max-7xl">
          {props.children}
          <div className="flex flex-row gap-md">
            <Button
              color="red"
              label="title.delete"
              onClicked={() => call.mutate().then(open.toggleFalse)}
              loading={call.loading}
              className="flex"
            />
            <Button
              color="gray"
              label="title.cancel"
              onClicked={open.toggleFalse}
              className="flex"
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
