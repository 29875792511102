import { useCallback } from "react";
import { requestedOffersRootPath } from "~/Services/Routes";
import { Button } from "~/Components/UI";
import { useHistory } from "react-router-dom";


export const UpdateRFOButton = ({ offerId }) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    history.replace(`${requestedOffersRootPath}${offerId}/edit`);
  }, [history, offerId]);

  return (
    <Button
      color="gray"
      onClicked={onEdit}
      label="title.update.offer"
    />
  );
}