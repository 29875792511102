import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import {
  offersRootPath,
  requestedOffersRootPath,
  requestsRootPath,
  useSearchParams,
} from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { closeLeftMenu } from "~/Reducers/LeftMenu";
import { getRequestedOffers } from "~/Reducers/Offers";
import {
  useIsAdmin,
  useIsInternalUser,
  useIsUFPBuyer,
  useIsVendor,
} from "~/Reducers/User";
import { RequestsPageTableTypes } from "../Reducer";
import {
  clearData,
  RequestedOffersPageTableTypes,
  useDefaultPageFilters,
  useRequestedOffersPageReduxState,
} from "./Reducer";
import RequestedOffersPageRequestsTable from "./Components/Tables/RequestedOffersPageRequestsTable";
import RequestedOffersPageByStatusTable from "./Components/Tables/RequestedOffersPageByStatusTable";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import DetailsTabs from "~/Components/Old/DetailsTabs";
import FiltersButton from "~/Components/Old/FiltersButton";
import { PlusIcon } from "~/Components/Old/Icons";
import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import TextField from "~/Components/Old/TextField";
import { useFilters } from "~/Components/Old/useFilters";
import Filters from "./Components/Filters";
import { classNames } from "react-ui-basics/Tools";
import "./RequestedOffersPage.scss";
import { useIsMobile } from "~/Hooks/useIsMobile";
import {useAppDispatch} from "~/StoreTypes";

const SELECTED_TAB = 1;

const shouldSkipLoading = (previousLocation: string) => {
  const pattern = new RegExp(`^${requestedOffersRootPath}\\d+(\\/.*)?$`);
  return pattern.test(previousLocation);
};

const RequestedOffersPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showFilters, showHideFilters] = useToggle(false);
  const scrollRef = useRef();

  const [searchParams] = useSearchParams();

  const isInternalUser = useIsInternalUser();
  const isVendor = useIsVendor();

  const getAPIFunc = useMemo(() => {
    switch (searchParams.tableType) {
      case RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_STATUS:
        return null;
      default:
        return getRequestedOffers;
    }
  }, [searchParams.tableType]);

  const pageFilters = useDefaultPageFilters();

  const { filters, setFilter, onScrolledToBottom } = useFilters(
    pageFilters,
    getAPIFunc,
    useRequestedOffersPageReduxState,
    shouldSkipLoading,
    scrollRef,
  );

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, []);

  const onAdd = useCallback(async () => {
    history.push(`${isVendor ? offersRootPath : requestedOffersRootPath}new`);
  }, [history]);

  const onChangeTab = useCallback(
    (event) => {
      dispatch(clearData());
      setFilter(event);
    },
    [setFilter],
  );

  const onChangeRequestsTab = useCallback(
    (index) => {
      if (index !== SELECTED_TAB)
        history.replace(
          `${requestsRootPath}?tableType=${RequestsPageTableTypes.REQUESTS_PAGE_NONE}`,
        );
    },
    [history],
  );

  const isAdmin = useIsAdmin();
  const isBuyer = useIsUFPBuyer();
  const isMobile = useIsMobile();

  const shouldDisplayCreateButton = isVendor || isAdmin || isBuyer; // Am can't create RFOs

  return (
    <div className={"static-view requested-offers-page"}>
      <PageTitleContainer title={<LocalizedMessage id={"title.requests"} />} />

      {isInternalUser && (
        <DetailsTabs
          value={SELECTED_TAB}
          onChange={onChangeRequestsTab}
          className={"BasicPageTabsPanel"}
        >
          <LocalizedMessage id={"title.customers"} />
          <LocalizedMessage id={"title.vendors"} />
        </DetailsTabs>
      )}

      <div className={"full-page-table-card"}>
        <div className={"flex flex-col overflow-hidden"}>
          {!isMobile && (
            <div className={"table-top-bar"}>
              {shouldDisplayCreateButton && (
                <Button
                  view={"secondary"}
                  onClick={onAdd}
                  leftIcon={<PlusIcon size={16} />}
                  data-cy={"OfferButton"}
                >
                  {isVendor ? (
                    <LocalizedMessage id={"title.create.offer"} />
                  ) : (
                    <LocalizedMessage id={"title.request.offer"} />
                  )}
                </Button>
              )}

              <TextField
                id={"search"}
                showClearButton
                placeholder={"Search by ID, Product and Description"}
                className={"filter-search mr-xs"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />

              <div className={"flex-row align-center"}>
                <Checkbox
                  id={"onlyArchivedOrDeliveredOrExpired"}
                  value={filters.onlyArchivedOrDeliveredOrExpired}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.expired"} />
              </div>
              <div className={"flex-row align-center"}>
                <Checkbox
                  id={"onlyMy"}
                  value={filters.onlyMy}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.show.only.my.requests"} />
              </div>
              <SpaceFiller />
              <RequestedOffersPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {isMobile && (
            <div className={"table-top-bar flex-row"}>
              <RequestedOffersPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <TextField
                id={"search"}
                placeholder={"Search"}
                className={"flex"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {filters.tableType ===
            RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_NONE && (
            <RequestedOffersPageRequestsTable
              {...{ scrollRef, onScrolledToBottom }}
            />
          )}
          {filters.tableType ===
            RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_STATUS && (
            <RequestedOffersPageByStatusTable {...{ filters }} />
          )}
        </div>

        <RightPanel visible={showFilters}>
          <Filters {...{ showHideFilters, filters, setFilter, pageFilters }} />
        </RightPanel>
      </div>

      {isMobile && shouldDisplayCreateButton && (
        <div className={"bottom-right-container"}>
          <Button onClick={onAdd} leftIcon={<PlusIcon size={16} />}>
            {isVendor ? (
              <LocalizedMessage id={"title.create.offer"} />
            ) : (
              <LocalizedMessage id={"title.request.offer"} />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RequestedOffersPage;

const RequestedOffersPageTableTypeTabs: FC<{
  value: RequestedOffersPageTableTypes;
  onChange: (value: any) => void;
}> = ({ value, onChange }) => {
  const selectedTab = useMemo(
    () => Object.values(RequestedOffersPageTableTypes).indexOf(value),
    [value],
  );

  const onChangeTab = useCallback(
    (event) => {
      onChange({
        ...event,
        target: { id: "tableType", value: event.target.id },
      });
    },
    [onChange],
  );

  return (
    <div className={"RequestedOffersPageTableTypeTabs"}>
      <Button
        id={RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_NONE}
        view={selectedTab === 0 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 0 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.none"} />
      </Button>
      <Button
        id={RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_STATUS}
        view={selectedTab === 1 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 1 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.status"} />
      </Button>
    </div>
  );
};
