import { OfferProductView, OfferStatus, OfferView } from "~/API";
import { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useToggle } from "~/Hooks/Old";
import { LocalizedMessage } from "~/Locales";
import { offersRootPath } from "~/Services/Routes";
import { isOfferExpired, useOffer } from "~/Reducers/Offers";
import {
  useIsInternalUser,
  useIsVendor,
} from "~/Reducers/User";
import {
  calculateOfferProductsTotalValue,
  OfferShowUnread,
  OfferStatusLabel,
} from "./OffersHelper";
import Button from "~/Components/Old/Button";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  EyeIcon,
} from "~/Components/Old/Icons";
import IncotermDestinationLabel from "~/Components/Old/IncotermDestinationLabel";
import { LengthsView } from "~/Components/Old/LengthsView";
import PriceView from "~/Components/Views/PriceView";
import OfferArchiveDeleteButtons from "./OfferArchiveDeleteButtons";
import "./OffersPageNoneGroupingTable.scss";
import { useIsMobile } from "~/Hooks/useIsMobile";
import { classNames } from "react-ui-basics/Tools";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

export const OffersPageNoneGroupingTable = ({ ids }) => {
  const isInternalUser = useIsInternalUser();
  const isMobile = useIsMobile();

  if (isMobile)
    return ids.map((id) => <OfferRowMobile key={id} offerId={id} />);

  return (
    <table className={"basic-table NoTopOffset OffersListTable"}>
      <thead>
        <tr>
          <th></th>
          <th>
            <LocalizedMessage id={"title.o.id"} />
          </th>
          <th>
            <LocalizedMessage id={"title.status"} />
          </th>
          <th>
            <LocalizedMessage id={"title.ship.from"} />
          </th>
          {isInternalUser && (
            <th>
              <LocalizedMessage id={"title.vendor"} />
            </th>
          )}
          <th>
            <LocalizedMessage id={"title.buyer"} />
          </th>
          <th>
            <LocalizedMessage id={"title.products"} />
          </th>
          <th>
            <LocalizedMessage id={"title.incoterm"} />
          </th>
          <th className={"text-right"}>
            <LocalizedMessage id={"title.value"} />
          </th>
          <th>
            <LocalizedMessage id={"title.date.inco.destination"} />
          </th>
          <th>
            <LocalizedMessage
              id={isInternalUser ? "title.validity" : "title.validity.date"}
            />
          </th>
          <th>
            <LocalizedMessage id={"title.actions"} />
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody data-cy={"offers-list"}>
        {ids.map((id) => (
          <OfferRow key={id} offerId={id} />
        ))}
      </tbody>
    </table>
  );
};

const EMPTY_OFFER = {} as OfferView;

const OfferRow = memo<{ offerId: number }>(({ offerId }) => {
  const offer = useOffer(offerId) || EMPTY_OFFER;
  const history = useHistory();

  const isVendor = useIsVendor();

  const {
    vendorCompanyId = null,
    buyerName = "",
    incoterm = "",
    incotermAddressDestination = "",
    dateIncoDestination = "",
    validityDate = "",
    status = OfferStatus.O_DRAFT,
    products = [],
    currency = "",
    shipFromName = "",
    vendorCompanyName = "",
    incotermPortName = "",
    unreadChatMessages = 0,
  } = offer;

  const totalValue = calculateOfferProductsTotalValue(products);

  const onOpenOfferClick = useCallback(
    (event) => {
      event.stopPropagation();
      history.push(`${offersRootPath}${offerId}`);
    },
    [offerId, history],
  );

  const [expanded, toggleExpanded] = useToggle();

  const offerHasNoProducts = products.length === 0;
  const onOfferClick = useCallback(() => {
    if (!offerHasNoProducts) toggleExpanded();
  }, [offerHasNoProducts, toggleExpanded]);

  const isInternalUser = useIsInternalUser();
  const isDraft = status === OfferStatus.O_DRAFT;
  const isExpired = isOfferExpired(validityDate);

  return (
    <>
      <tr
        className={classNames(
          offerHasNoProducts ? "" : "OfferTableRow",
          OfferShowUnread(offer.readMark, isExpired) ? "row-unread" : "",
        )}
        onClick={onOfferClick}
      >
        <td>
          {" "}
          {offerHasNoProducts ? (
            ""
          ) : expanded ? (
            <ArrowDownIcon size={26} />
          ) : (
            <ArrowRightIcon size={26} />
          )}
        </td>
        <td className={"no-wrap"}>{`O-${offerId}`}</td>
        <td className={offer.readMark ? "" : "color-black-1"}>
          {isExpired ? (
            "Expired"
          ) : (
            <OfferStatusLabel offerRead={offer.readMark} offerStatus={status} />
          )}
        </td>
        <td>{shipFromName}</td>
        {isInternalUser && <td>{vendorCompanyId ? vendorCompanyName : "-"}</td>}
        <td>{buyerName}</td>
        <td> {products.length || 0} </td>
        <td>
          <IncotermDestinationLabel
            incoterm={incoterm}
            address={incotermAddressDestination}
            portName={incotermPortName}
          />
        </td>
        <td className={"text-right text-semibold no-wrap"}>
          <PriceView price={totalValue} currency={currency} decimals={0} />
        </td>

        <td>
          <DateView date={dateIncoDestination} />
        </td>
        <td>
          <DateTimeView date={validityDate} />
        </td>
        {/*<td> {uom || '-'} </td>*/}
        <td>
          <div className={"flex-row align-center justify-center"}>
            <Button
              view={"text"}
              className={"ActionButton mr-xs"}
              onClick={onOpenOfferClick}
              data-cy={"open-button"}
            >
              <EyeIcon size={20} />
            </Button>
            <OfferArchiveDeleteButtons {...{ offer }} />
          </div>
        </td>
        <td>
          {!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}
        </td>
      </tr>

      {expanded && (
        <tr className={classNames("OfferProductsHeaderRow")}>
          <td />
          <td>#</td>
          <td>Product</td>
          <td colSpan={2}>Description</td>
          {isVendor ? <td>Act T x W</td> : <td colSpan={3}>Group</td>}
          <td>Length</td>
          <td>Quantity</td>
          <td>UOM</td>
          <td colSpan={2} className={"text-right"}>
            Price
          </td>
        </tr>
      )}
      {expanded &&
        products.map((product, index) => (
          <ProductRow
            key={index}
            product={product}
            index={index}
            currency={currency}
          />
        ))}
    </>
  );
});

const OfferRowMobile = ({ offerId }) => {
  const offer = useOffer(offerId);
  const isExpired = isOfferExpired(offer.validityDate);
  const totalValue = calculateOfferProductsTotalValue(offer.products);
  const history = useHistory();
  const isInternalUser = useIsInternalUser();

  const openOffer = useCallback(
    (event) => {
      event.stopPropagation();
      history.push(`${offersRootPath}${offerId}`);
    },
    [offerId, history],
  );

  return (
    <div className="p-md flex-col br-bottom-black-10" onClick={openOffer}>
      <div className="flex flex-row justify-between align-center">
        <div>Offer {offerId}</div>

        <div className="flex-row gap-sm">
          <div
            className={classNames(
              "rounded-xxs px-xs py-xxs",
              offer.readMark ? "bg-black-10" : "bg-blue-10",
            )}
          >
            {isExpired ? (
              "Expired"
            ) : (
              <OfferStatusLabel
                offerStatus={offer.status}
                offerRead={offer.readMark}
                offerIsExpired={isExpired}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between align-start gap-lg mt-lg">
        <div className={"flex-col flex text-left"}>
          <div className={"text-sm color-text-3"}>
            <LocalizedMessage id={"title.ship.from.name"} />
          </div>
          <div className={"text-pre-wrap mt-xxs"}>
            {offer.shipFromName || "-"}
          </div>
        </div>

        {isInternalUser && (
          <div className={"flex-col flex text-left"}>
            <div className={"text-sm color-text-3"}>
              <LocalizedMessage id={"title.vendor.name"} />
            </div>
            <div className={"text-pre-wrap mt-xxs"}>
              {offer.vendorCompanyName || "-"}
            </div>
          </div>
        )}

        {!isInternalUser && (
          <div className={"flex-col flex text-left"}>
            <div className={"text-sm color-text-3"}>
              <LocalizedMessage id={"title.buyer"} />
            </div>
            <div className={"text-pre-wrap mt-xxs"}>
              {offer.buyerName || "-"}
            </div>
          </div>
        )}
      </div>
      <div
        className={"flex flex-row justify-between align-center gap-lg mt-lg"}
      >
        <div className={"text-pre-wrap mt-xxs color-orange-1"}>
          <PriceView
            price={totalValue}
            currency={offer.currency}
            decimals={0}
          />
        </div>
        <OfferArchiveDeleteButtons {...{ offer }} />
      </div>
    </div>
  );
};

interface ProductRowProps {
  product: OfferProductView;
  index: number;
  currency: string;
}

const ProductRow = memo<ProductRowProps>(({ index, product, currency }) => {
  const {
    productCode = "",
    productDescription = "",
    quantity = 1,
    uom = "",
    price = 0,
    lengthIds = [],
    productGroupName = "",
    productActWidth,
    productActThick,
  } = product;

  const isVendor = useIsVendor();

  return (
    <tr className={"OfferProductsRow"}>
      <td></td>
      <td> {index + 1} </td>
      <td> {productCode} </td>
      <td colSpan={2}> {productDescription} </td>
      {isVendor ? (
        <td>
          {" "}
          {productActWidth} x {productActThick}{" "}
        </td>
      ) : (
        <td colSpan={3}> {productGroupName} </td>
      )}
      <td>
        <LengthsView lengthIds={lengthIds} />
      </td>
      <td> {quantity} </td>
      <td> {uom} </td>
      <td colSpan={2} className={"text-right no-wrap"}>
        <PriceView price={price} currency={currency} />
      </td>
    </tr>
  );
});
