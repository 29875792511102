import { FC } from "react";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import PurchaseOrderUpdatePromiseDateDialog from "./PurchaseOrderUpdatePromiseDateDialog";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import { PurchaseOrderStatus } from "~/API";
import {useCurrentUser, useIsAdmin, useIsInternalCustomer} from "~/Reducers/User";

const PurchaseOrderBottomButtons: FC<{ orderId: number }> = ({ orderId }) => {
  const user = useCurrentUser();
  const isAdmin = useIsAdmin();
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(orderId, internalCustomer);

  if (
    order.isLoading ||
    order.data.status === PurchaseOrderStatus.COMPLETE ||
    order.data.status === PurchaseOrderStatus.VOID
  )
    return null;

  // only allow vendors or admins to update promise date
  if (order.data?.vendorCompanyId !== user.companyId && !isAdmin) return null;

  return (
    <>
      <BottomButtonsPanel>
        <PurchaseOrderUpdatePromiseDateDialog
          orderId={orderId}
        />
      </BottomButtonsPanel>
    </>
  );
};

export default PurchaseOrderBottomButtons;
