import {CompanyProductsFilters, useCompanyProducts} from "~/Data/Products/CompanyProducts";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";
import API, {MyProduct} from "~/API";

export const CompanyProductsComboBox = <T,>(
  props: {
      filters?: CompanyProductsFilters
  } & ExtendDataCombobox<T>,
) => (
  <ApiDataCombobox<T, any, MyProduct>
    {...props}
    getLabel={(p) => `${p.productCode} - ${p.description}`}
    getId={(c) => c.id}
    useListData={useCompanyProducts}
    getSingle={async (id) => (await API.getCompanyProducts({ ...props.filters, query: `ID == ${id}` }))[0]}
    filters={{ ...props.filters }}
  />
);
