import API, {
  DeliveryBuyerStatus,
  LocationType,
  Order,
  GroupPermission,
  SalesOrderView,
} from "~/API";
import {FC, useEffect, useMemo} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  getPathForPO,
  getPathForSO,
  useIsCompletedOrdersUrl,
} from "~/Services/Routes";
import {
  params,
  useDebounce,
  useFormData,
  usePageTitle,
  useToggle,
} from "~/Hooks";
import { LC, ls } from "~/Locales";
import { useIsMobile } from "~/Hooks/useIsMobile";
import { useIsInternalUser } from "~/Reducers/User";
import DateView from "~/Components/Views/DateView";
import { EmptyDeliveriesImageLazy } from "~/Components/Old/EmptyStates/EmptyStatesLazy";
import PriceView from "~/Components/Views/PriceView";
import {
  Button,
  Case,
  Checkbox,
  FormCheckbox,
  FormInput,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Match,
  Show,
  Table,
} from "~/Components/UI";
import { LinearLoader } from "~/Components/Loaders";
import { SalesOrderStatusBadge } from "~/Components/Orders/SalesOrderStatusBadge";
import { ToggleFilterButton } from "~/Components/Entities/ToggleFilterButton";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SalesOrderFilters, useSalesOrders } from "~/Data/Orders/SalesOrders";
import { FilterPanel } from "~/Components/Entities/FilterPanel";
import { CompanyCombobox } from "~/Components/Companies/CompanyCombobox";
import OrderStatusSelect from "~/Pages/Orders/Components/OrderStatusSelect";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import {FormDateInputRange} from "~/Components/UI/Forms/DateInput";
import {LocationCombobox} from "~/Components/Companies/LocationCombobox";
import {onScrollToBottom, trimUndefined} from "~/Utils";
import * as z from "zod";
import {UserName} from "~/Components/Users/UserName";
import {useSortState} from "~/Hooks/useSortState";
import {OrderDocumentHoverTable} from "~/Pages/Orders/Components/OrderDocumentHoverTable";
import {TbUpload} from "react-icons/tb";
import OrderDocumentsUploadDialog from "~/Pages/Orders/DetailsPage/Components/OrderDocumentsUploadDialog";
import {useAppDispatch, useAppSelector} from "~/StoreTypes";
import {clearSelectedOrders, isOrderSelected, toggleSelectedOrder} from "~/Pages/Orders/OrdersPageReducer";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";

const statusesCompleted = [DeliveryBuyerStatus.COMPLETE];
const statusesOpen = [
  DeliveryBuyerStatus.INQUIRY,
  DeliveryBuyerStatus.RESERVED,
];

const routeParamsParser = z.object({
  search: params.string,
  customer: params.numberArray,
  status: params.enumArray(DeliveryBuyerStatus),
  accountManager: params.numberArray,
  onlyMyOrders: params.bool,
  order: params.enum(Order),
  locations: params.numberArray,
  deliveryDateFrom: params.dateTime,
  deliveryDateTo: params.dateTime,
});

const SalesOrdersTab: FC<{ isOnlyCompleted?: boolean }> = (props) => {
  usePageTitle("Sales Orders");

  const history = useHistory();
  const dispatch = useAppDispatch();

  const isInternalUser = useIsInternalUser();
  const isMobile = useIsMobile();

  const sort = useSortState();
  const showFilters = useToggle();
  const filters = useFormData<SalesOrderFilters>({
    search: "",
    customer: [],
    status: statusesOpen,
    accountManager: [],
    onlyMyOrders: true,
    order: Order.DESC,
    locations: [],
    deliveryDateFrom: "",
    deliveryDateTo: "",
  }, {
    routeParser: routeParamsParser,
    onChange: (_oldData, newData) => {
      dispatch(clearSelectedOrders());
      return newData;
    },
    });
  const filterSearchDebounced = useDebounce(filters.data.search);

  const orders = useSalesOrders(isInternalUser, trimUndefined({
    ...filters.data,
    search: filterSearchDebounced,
    status: props.isOnlyCompleted ? statusesCompleted : filters.data.status,
    ...sort.data,
  }));

  const ordersList = useMemo(() => orders.data?.flat() || [], [orders.data]);
  const onScrolledToBottom = () => orders.setSize(orders.size + 1);
  const mobileScrolledToBottom = onScrollToBottom(onScrolledToBottom, 256);

  const emptyState =
    ordersList.length === 0 && !orders.isLoading && !orders.error;

  const onOpen = (row: SalesOrderView) => {
    history.push({ pathname: getPathForSO(row.id) });
  };

  // clear when page loads in case they are navigating from another page to avoid have
  // orders selected that are no longer on the page
  useEffect(() => {
    dispatch(clearSelectedOrders());
  }, []);

  const onClearSelection = () => dispatch(clearSelectedOrders());

  const selectedOrders = useAppSelector(state => state.ordersPageState.selectedOrders);
  const onUploadDocuments = async (attachments: any[]) => {
    await API.createMultipleSalesOrderDocuments({}, {
      documents: attachments.map((a) => ({
        type: a.type,
        attachmentId: a.id,
        number: 1,
      })),
      orderIds: selectedOrders
    });
    await orders.mutate();
    dispatch(clearSelectedOrders());
  }

  const toggleCompletedOrders = (value: boolean) => {
    if (value) {
      filters.onDataChange("status", statusesCompleted);
    } else {
      filters.onDataChange("status", statusesOpen);
    }
  };

  const isOnlyCompletedOrders =
    JSON.stringify(filters.data.status) === JSON.stringify(statusesCompleted);

  const columns = getColumns(isInternalUser);
  const table = useReactTable({
    data: ordersList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ["select"],
      },
      columnVisibility: {
        price: isInternalUser,
        purchaseOrder: isInternalUser,
        select: isInternalUser,
      },
    },
  });

  return (
    <div className="flex-col flex overflow-hidden">
      <div className="flex-row gap-lg flex overflow-hidden">
        <div className="flex-col overflow-hidden flex bg-black-12 rounded-xxs">
          <Match on={isMobile}>
            <Case v={false}>
              <div className="table-top-bar">
                <FormInput
                  id="search"
                  formData={filters}
                  showClear
                  placeholder="placeholder.order.list.search"
                  className="w-min-9xl"
                  type="search"
                />

                <Show when={isInternalUser}>
                  <FormCheckbox
                    id="onlyMyOrders"
                    formData={filters}
                    label="title.show.my.orders"
                  />
                  <Checkbox
                    id="completed"
                    value={isOnlyCompletedOrders}
                    onChange={toggleCompletedOrders}
                    label="title.completed"
                  />
                </Show>

                <div className="ml-auto">
                  <ToggleFilterButton onClicked={showFilters.toggle} />
                </div>
              </div>
            </Case>

            <Case v={true}>
              <div className="table-top-bar flex-row border-b-1 border-black-9 p-sm">
                <FormInput
                  id="search"
                  formData={filters}
                  placeholder="placeholder.search"
                  className="flex"
                />

                <Show when={isInternalUser}>
                  <ToggleFilterButton onClicked={showFilters.toggle} />
                </Show>
              </div>
            </Case>
          </Match>

          <LinearLoader loading={orders.isValidating} />
          {emptyState && <OrderEmptyState />}
          {!emptyState && !isMobile && (
            <Table
              table={table}
              onRowClick={onOpen}
              onScrolledBottom={onScrolledToBottom}
              scrollBottomOffset={400}
              className="flex no-wrap"
              sort={sort}
            />
          )}
          {!emptyState && isMobile && (
            <div className="overflow-auto" onScroll={mobileScrolledToBottom}>
              <div className="flex-col w-full text-left bg-color-black-12">
                {ordersList.map((order) => (
                  <OrderRowMobile key={order.id} order={order} />
                ))}
              </div>
            </div>
          )}
        </div>

        <Show when={isInternalUser}>
          <FilterPanel open={showFilters}>
            <CompanyCombobox
              id="customer"
              formData={filters}
              label="title.customer"
              placeholder="title.all"
              filters={{ query: "TYPE == 'BUYER' && active == true" }}
              multiple
              showClear
              clearValue={[]}
            />

            <OrderStatusSelect
              id="status"
              formData={filters}
              label="title.status"
              placeholder="title.all"
              multiple
              showClear
              clearValue={[]}
            />

            <LocationCombobox
              id="locations"
              formData={filters}
              label="title.ship.to"
              placeholder="title.all"
              multiple
              showClear
              clearValue={[]}
              filters={{ type: LocationType.SHIP_TO, companyId: filters.data.customer }}
            />

            <UserByPermissionCombobox
              id="accountManager"
              formData={filters}
              label="title.account.manager"
              placeholder="title.all"
              multiple
              showClear
              clearValue={[]}
              permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
            />

            <FormDateInputRange
              label="title.delivery.date"
              idFrom="deliveryDateFrom"
              idTo="deliveryDateTo"
              formData={filters}
              showClear
            />

            <Button color="gray" label="title.clear" onClicked={filters.clear} />
          </FilterPanel>
        </Show>

      </div>
      {isInternalUser && (
        <BottomButtonsPanel>
          {selectedOrders.length > 0 && (
            <Button
              label="title.clear.selection"
              color="gray"
              variant="text"
              onClicked={onClearSelection}
            />
          )}

          <OrderDocumentsUploadDialog
            desc={ls("order.multi.docs.desc", selectedOrders.length)}
            onSave={onUploadDocuments}
            trigger={
              <Button
                leftIcon={<TbUpload size={16} />}
                label="order.upload.docs"
                disabled={selectedOrders.length == 0}
              />
            }
          />
        </BottomButtonsPanel>
      )}
    </div>
  );
};

export default SalesOrdersTab;

const mcLetter = (val: string) => val ? `-${val[0]}` : "";

const columnHelper = createColumnHelper<SalesOrderView>();

const getColumns = (isInternalUser: boolean) => [
  columnHelper.display({ //INTERNAL ONLY
    id: "select",
    header: "",
    size: 0,
    cellMemo: ({ row }) => {
      const dispatch = useAppDispatch();
      const value = useAppSelector((state) => isOrderSelected(state, row.id));
      const onClick = () => {
        dispatch(toggleSelectedOrder(row.id));
      }

      return (
        <Checkbox
          value={value}
          onChange={onClick}
        />
      );
    }
  }),
  columnHelper.display({
    id: "number",
    sortId: "number",
    header: () => ls("title.order.#"),
    cellMemo: ({ row }) => `${row.number}${mcLetter(row.masterChild)}`,
    size: 0,
  }),
  columnHelper.accessor("buyerReferenceNumber", {
    sortId: "buyer_reference_number",
    header: () => isInternalUser ? ls("title.cust.po.#") : ls("title.your.po.#"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
    size: 0,
  }),
  columnHelper.accessor("buyerStatus", {
    sortId: "buyer_status",
    header: () => ls("title.status"),
    cellMemo: ({ value }) => <SalesOrderStatusBadge status={value} />,
    size: 0,
  }),
  columnHelper.accessor("locationName", {
    sortId: "location_name",
    minSize: 250,
    header: () => ls("title.ship.to"),
    cellMemo: ({ value }) => <div className="text-no-wrap">{value}</div>,
  }),
  columnHelper.display({
    id: "salesPerson",
    sortId: "sales_person_name",
    minSize: 150,
    header: () => ls("title.salesperson"),
    cellMemo: ({ row }) => (
      <>
        <div className="text-no-wrap">
          <UserName id={row.accountManagerUserId} /></div>
        {row.salesPersonUserId !== row.accountManagerUserId && (
          <div className="text-no-wrap mt-xxs">{row.salesPersonName}</div>
        )}
      </>
    ),
  }),
  columnHelper.display({ //INTERNAL ONLY
    id: "price",
    sortId: "total_sales_for_commission",
    header: () => ls("title.total.sales"),
    cellMemo: ({ row }) => <PriceView price={row.totalSalesForCommission} currency={row.currency} />
  }),
  columnHelper.accessor("documentCount", {
    header: () => ls("title.scanned.documents"),
    cellMemo: ({ value, row }) => (
      <HoverCard>
        <HoverCardTrigger>
          <div className="color-blue-1 cursor-pointer">
            {value}
          </div>
        </HoverCardTrigger>
        <HoverCardContent>
          <div onClick={(e) => e.stopPropagation()}>
            <OrderDocumentHoverTable id={row.id} isSalesOrder />
          </div>
        </HoverCardContent>
      </HoverCard>
    )
  }),
  columnHelper.display({
    id: "deliveryDate",
    sortId: "delivery_date",
    header: () => ls("title.delivery.date"),
    cellMemo: ({ row }) => <DateView date={row.importCustomsReleaseDate || row.dateEstimatedProvisionDate} />
  }),
  columnHelper.display({ // INTERNAL USER ONLY
    id: "purchaseOrder",
    sortId: "direct_po_number",
    header: () => ls("title.po.#"),
    cellMemo: ({ row }) => {
      const { purchaseOrderId, directPoNumber } = row;
      return (<>
        {!!purchaseOrderId && !!directPoNumber && directPoNumber !== "0" ? (
          <Link
            to={getPathForPO(purchaseOrderId)}
            className={"Link"}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {directPoNumber}
          </Link>
        ) : !!directPoNumber && directPoNumber !== "0" ? (
          directPoNumber
        ) : (
          "-"
        )}
      </>)
    }
  })
];

const OrderRowMobile: FC<{
  order: SalesOrderView;
}> = ({ order }) => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();

  const {
    number,
    buyerReferenceNumber,
    buyerStatus,
    locationName,
    masterChild,
  } = order;

  const onOpenOrder = () => {
    history.push({ pathname: getPathForSO(order.id) });
  };

  return (
    <div
      className="flex-col px-lg py-md border-b-1 border-black-9 overflow-hidden"
      onClick={onOpenOrder}
    >
      <div className="flex-row w-full">
        <div className="text-truncate flex-col flex mr-xxs">
          <div className="text-sm color-text-2">
            <LC id="title.sales.order.#" />
          </div>
          <div className="text-no-wrap mt-xxs">
            {number ? `${number}${mcLetter(masterChild)}` : "-"}
          </div>
          <div className="text-truncate mt-xxs"></div>
        </div>

        <div className="text-truncate flex-col flex mr-xxs">
          <div className="text-sm color-text-2">
            <LC
              id={isInternalUser ? "title.cust.po.#" : "title.your.po.#"}
            />
          </div>
          <div className="text-truncate mt-xxs">{buyerReferenceNumber}</div>
        </div>

        <div className="text-truncate flex-col mr-xxs">
          <SalesOrderStatusBadge status={buyerStatus} />
        </div>
      </div>

      <div className="text-sm mt-xs color-text-2">
        <LC id="title.ship.to" />
      </div>
      <div className="text-truncate mt-xxs">{locationName}</div>
    </div>
  );
};

export const OrderEmptyState: FC = () => {
  const isInternalUser = useIsInternalUser();
  const isCompleted = useIsCompletedOrdersUrl();

  return (
    <div className="flex-col w-full mt-lg">
      <div className="flex-col justify-center align-center">
        <EmptyDeliveriesImageLazy />
        {!isCompleted && (
          <>
            <div className="text-lg mt-xl">
              <LC
                id={
                  isInternalUser
                    ? "phrase.no.orders.onging.sales.1"
                    : "phrase.no.orders.onging.customer.1"
                }
              />
            </div>
            <div className="color-text-2 mt-md">
              <LC
                id={
                  isInternalUser
                    ? "phrase.no.orders.onging.sales.2"
                    : "phrase.no.orders.onging.customer.2"
                }
              />
            </div>
          </>
        )}
        {isCompleted && (
          <>
            <div className="text-lg mt-xl">
              <LC id="phrase.no.orders.completed.1" />
            </div>
            <div className="color-text-2 mt-md">
              <LC id="phrase.no.orders.completed.2" />
            </div>
            <div className="color-text-2">
              <LC id="phrase.no.orders.completed.3" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
