import {FC, ReactNode, useMemo} from "react";
import * as RadioPrimitive from "@radix-ui/react-radio-group";
import { TbCircleFilled } from "react-icons/tb";
import "./RadioGroup.scss";
import { For } from "~/Components/UI";
import { FormReturn } from "~/Hooks";

export interface RadioGroupProps<T> {
  id: string;
  value: T;
  data: T[];
  getLabel: (item: T) => ReactNode;
  getValue?: (item: T) => any;
  onChange: (id: string, value: T) => void;
}

const getValue = <T,>(item: T) => JSON.stringify(item);

export const RadioGroup = <T = any,>(props: RadioGroupProps<T>) => {
  const mappedItems = useMemo(
    () =>
      props.data.map((d) => ({
        data: d,
        label: props.getLabel(d),
        value: getValue(d),
      })),
    [props.data],
  );

  const value = getValue(props.value);
  const onValueChange = (value: string) => {
    props.onChange(props.id, mappedItems.find((i) => i.value === value)?.data);
  };

  return (
    <RadioPrimitive.Root
      value={value}
      onValueChange={onValueChange}
      className="flex-col gap-xs"
    >
      <For each={mappedItems}>
        {(item) => <RadioRow value={item.value} label={item.label} />}
      </For>
    </RadioPrimitive.Root>
  );
};

const RadioRow: FC<{
  value: string;
  label: ReactNode;
  description?: ReactNode;
}> = (props) => {
  return (
    <div className="flex-row align-center gap-sm">
      <RadioPrimitive.Item
        id={props.value}
        value={props.value}
        className="cursor-pointer radio-bg-color border-none focus-outline-black-2 focus-outline-2 rounded-full h-md w-md m-0 p-0 flex-row align-center justify-center"
      >
        <RadioPrimitive.Indicator asChild>
            <div>
                <TbCircleFilled size={10} className="color-black-12 m-0" />
            </div>
        </RadioPrimitive.Indicator>
      </RadioPrimitive.Item>
      <label className="cursor-pointer flex-col gap-xxs" htmlFor={props.value}>
          <div className="text-bold">{props.label}</div>
          {props.description && (
              <div className="color-text-3">{props.description}</div>
          )}
      </label>
    </div>
  );
};

export interface FormRadioGroupProps<T = any, K = any> {
  id: keyof T & string;
  data: K[];
  getLabel: (item: K) => string;
  formData: FormReturn<T>;
}

export const FormRadioGroup = <T = any, K = any>(
  props: FormRadioGroupProps<T, K>,
) => {
  const onChange = (id: string, value: K) => {
    return props.formData.onDataChange(id as keyof T, value);
  };

  return (
    <RadioGroup<K>
      id={props.id}
      value={props.formData.data[props.id] as K}
      data={props.data}
      getLabel={props.getLabel}
      onChange={onChange}
    />
  );
};

export interface DescriptiveRadioGroupProps<T> extends RadioGroupProps<T> {
    getDescription: (item: T) => ReactNode;
}

export const DescriptiveRadioGroup = <T = any,>(props: DescriptiveRadioGroupProps<T>) => {
    const mappedItems = useMemo(
        () =>
            props.data.map((d) => ({
                data: d,
                label: props.getLabel(d),
                description: props.getDescription(d),
                value: getValue(d),
            })),
        [props.data]
    );

    const value = getValue(props.value);
    const onValueChange = (value: string) => {
        props.onChange(props.id, mappedItems.find((i) => i.value === value)?.data);
    };

    return (
        <RadioPrimitive.Root
            value={value}
            onValueChange={onValueChange}
            className="flex-col gap-lg"
        >
            <For each={mappedItems}>
                {(item) => (
                    <RadioRow
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        description={item.description}
                    />
                )}
            </For>
        </RadioPrimitive.Root>
    );
};
