import { forwardRef, useMemo } from "react";
import * as RSelect from "@radix-ui/react-select";
import { TbCheck, TbSelector } from "react-icons/tb";
import { LC, LocalizedMessageKey } from "~/Locales";
import { For, Show } from "~/Components/UI";
import {FormReturn, useToggle} from "~/Hooks";
import { cn } from "~/Utils";

export interface SelectProps<T> {
  id: string;
  value: T;
  data: T[];
  getLabel: (item: T) => string;
  onChange: (id: string, value: T) => void;
  placeholder?: LocalizedMessageKey;
  label?: LocalizedMessageKey;
  error?: string;
  disabled?: boolean;
  containerClassName?: string;
  required?: boolean;
  dataCy?: string;
}

const getValue = <T,>(item: T) => JSON.stringify(item);

export const Select = <T,>(props: SelectProps<T>) => {
  const mappedItems = useMemo(
    () =>
      props.data.map((d) => ({
        data: d,
        label: props.getLabel(d),
        value: getValue(d),
      })),
    [props.data],
  );

  const value = getValue(props.value);
  const onValueChange = (value: string) => {
    props.onChange(props.id, mappedItems.find((i) => i.value === value)?.data);
  };

  const open = useToggle();

  return (
    <RSelect.Root onOpenChange={open.toggle} value={value} onValueChange={onValueChange}>
      <div className="flex-col" data-cy={props.dataCy}>
        <Show when={props.label !== undefined}>
          <div className="ml-md mb-xxs color-black-6 text-sm">
            <LC id={props.label} />
            {props.required && " *"}
          </div>
        </Show>

        <RSelect.Trigger
          className={cn(
            "border-1 rounded-xxs px-md py-xs flex-row justify-between align-center",
            "focus-outline-2 focus-outline-blue-1 focus-border-clear",
            props.disabled
              ? "color-text-2 bg-black-11 cursor-disabled"
              : "color-text-1 hover-bg-black-11-5 cursor-pointer bg-black-12",
            props.error
              ? "border-red-1 color-red-1"
              : "border-black-9 color-black-7",
            open.value && "outline-2 outline-blue-1 border-clear",
            props.containerClassName,
          )}
          disabled={props.disabled}
        >
          <Show when={!value}>
            <div className="text-italics color-text-2">
              <LC id={props.placeholder || "title.select"} />
            </div>
          </Show>

          <span className="flex text-left">
            {props.getLabel(props.value)}
          </span>

          <RSelect.Icon asChild>
            <TbSelector className="color-black-7 ml-xs" size={16} />
          </RSelect.Icon>
        </RSelect.Trigger>

        <Show when={props.error !== undefined}>
          <div className="ml-md mt-xxs color-red-1">{props.error}</div>
        </Show>
      </div>

      <RSelect.Portal>
        <RSelect.Content
          position="popper"
          className={cn(
            "animate-popover-content bg-black-12 border-1 border-black-9",
            "p-xxs rounded-xs shadow-lg h-max-7xl mt-xxs",
          )}
        >
          <RSelect.Viewport className="flex-col overflow-hidden">
            <div className="overflow-y-auto pr-xxs">
              <For each={mappedItems}>
                {(item) => (
                  <SelectItem
                    key={item.value}
                    selectedValue={value}
                    value={item.value}
                    label={item.label}
                  />
                )}
              </For>
            </div>
          </RSelect.Viewport>
        </RSelect.Content>
      </RSelect.Portal>
    </RSelect.Root>
  );
};

export const SelectItem = forwardRef<
  any,
  {
    value: string;
    selectedValue: string;
    label: string;
  }
>((props, ref) => {
  return (
    <RSelect.Item
      value={props.value}
      ref={ref}
      className={cn(
        "flex-row p-xs rounded-xxs align-center justify-between",
        "cursor-pointer focus-outline-none focus-bg-blue-10 focus-color-blue-1",
      )}
    >
      {props.label}
      <RSelect.ItemIndicator asChild>
        <TbCheck size={16} className="ml-xs" />
      </RSelect.ItemIndicator>
    </RSelect.Item>
  );
});

export interface FormSelectProps<T = any, K = any> extends Omit<SelectProps<K>, "value" | "onChange" | "error"> {
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormSelect = <T = any, K = any>(props: FormSelectProps<T, K>) => {
  const onChange = (id: string, value: K) => {
    return props.formData.onDataChange(id as keyof T, value);
  };

  return (
    <Select<K>
      {...props}
      value={props.formData.data[props.id] as K}
      onChange={onChange}
      error={props.formData.errors[props.id]?._errors?.join(", ")}
      required={props.formData.isFieldRequired(props.id, "") || props.required}
    />
  );
};
