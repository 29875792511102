import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {LC, LocalizedMessageKey, ls} from "~/Locales";
import { requestedOffersRootPath } from "~/Services/Routes";
import DashboardEmptyState from "../DashboardEmptyState";
import { OfferView } from "~/API";
import PriceView from "~/Components/Views/PriceView";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import {
  useOfferValue,
} from "~/Reducers/Offers";
import { useIncotermUsesAddress } from "~/Reducers/Incoterms";
import PortView from "~/Components/Ports/PortView";
import { LinearLoader } from "~/Components/Loaders";
import {Button, Card, Show} from "~/Components/UI";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {DashboardMobileField, DashboardTable} from "~/Pages/Dashboard/Components/DashboardTable";
import {OfferStatusBadge} from "~/Components/Offers/OfferStatusBadge";
import {useDashboardRequestsForOffers} from "~/Data/Requests/RequestsForOffers";
import {useIsAdmin} from "~/Reducers/User";
import {useIsMobile} from "~/Hooks";

const columnHelper = createColumnHelper<OfferView>();
const columns = [
  columnHelper.accessor("id", {
    header: ls("title.ro.id"),
    cell: (data) => <div className="text-semibold">RO-{data.getValue()}</div>,
  }),
  columnHelper.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <OfferStatusBadge status={data.getValue()} />,
  }),
  columnHelper.accessor("buyerName", {
    header: ls("title.buyer"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelper.display({
    id: "incoterm",
    header: ls("title.incoterm.destination"),
    cell: (data) => {
      const row = data.row.original;
      const canShowAddressField = useIncotermUsesAddress(row.incoterm);

      return (
        <div className="text-truncate">
          <LC id={`incoterm.${row.incoterm}` as LocalizedMessageKey} />
          {canShowAddressField ? (
            row.incotermAddressDestination ? (
              `/${row.incotermAddressDestination}`
            ) : (
              ""
            )
          ) : row.incotermPortIdDestination ? (
            <>
              /<PortView id={row.incotermPortIdDestination} />
            </>
          ) : (
            ""
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor("products", {
    header: ls("title.products"),
    cell: (data) => data.getValue().length,
  }),
  columnHelper.display({
    id: "value",
    header: ls("title.value"),
    cell: (data) => {
      const value = useOfferValue(data.row.original);
      return (
        <PriceView price={value} currency={data.row.original.currency} decimals={0} />
      )
    }
  }),
  columnHelper.accessor("validityDate", {
    header: ls("title.validity.date"),
    cell: (data) => <DateView date={data.getValue()}/>
  })
];

const DashboardVendorRequests = () => {
  const history = useHistory();
  const requests = useDashboardRequestsForOffers();
  const isAdmin = useIsAdmin();

  const showMobileTable = useIsMobile();

  const onShowAll = () => {
    history.push(requestedOffersRootPath);
  };

  const onRowClick = (row: OfferView) => {
    history.push(requestedOffersRootPath + row.id);
  }

  const table = useReactTable({
    data: requests.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const showEmptyState = requests.data?.length === 0 && !requests.isLoading;
  const showList = !requests.isLoading && !showEmptyState;
  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC id={isAdmin ? "title.vendor.requests" : "title.my.requests"} />
      </div>

      <LinearLoader loading={requests.isLoading} />
      <Show when={showEmptyState}>
        <DashboardEmptyState
          title="No Requests."
          description1="Once you have an request, it will be visible here."
        />
      </Show>

      {showList && (
        <>
          {!showMobileTable && (
            <DashboardTable table={table} onRowClick={onRowClick} />
          )}
          {showMobileTable && (
            <div className={"orders-table-mobile"}>
              {requests.data?.map((request) => (
                <RequestCellMobile key={request.id} request={request} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-md"
            onClicked={onShowAll}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardVendorRequests;

const RequestCellMobile = ({ request }: { request: OfferView }) => {
  const history = useHistory();

  const {
    id,
    status,
    currency,
    validityDate,
  } = request;

  const value = useOfferValue(request);

  const onOpen = useCallback(() => {
    history.push(requestedOffersRootPath + id);
  }, [id, history]);

  return (
    <div className="border-b-1 border-black-10 py-md flex-col gap-sm" onClick={onOpen}>
      <div className="flex-row w-full">
        <div className="text-truncate flex-col flex mr-xxs mt-xxs">
          RO-{id}
        </div>

        <DashboardMobileField label="title.value">
            <PriceView price={value} currency={currency} decimals={0} />
        </DashboardMobileField>

        <OfferStatusBadge status={status} />
      </div>

      <DashboardMobileField label="title.validity.date">
        <DateTimeView date={validityDate} />
      </DashboardMobileField>
    </div>
  );
};
