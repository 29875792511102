import {useSalesOrder} from "~/Data/Orders/SalesOrders";
import {Skeleton} from "~/Components/Loaders";
import {getPathForSO} from "~/Services/Routes";
import {FC} from "react";

export const SalesOrderLink: FC<{ id: number }> = (props) => {
  const so = useSalesOrder(props.id);

  if (so.isLoading)
    return <Skeleton className="w-4xl"/>

  return (
    <a
      href={getPathForSO(props.id)}
      target="_blank"
      rel="noreferrer noopener"
    >
      {so.data.number}
    </a>
  )
}