import { FC, useEffect } from "react";
import { Button, Dialog, Input } from "~/Components/UI";
import { TbPlus } from "react-icons/tb";
import { useFormData, useMutate, useToggle } from "~/Hooks";
import { ls } from "~/Locales";
import { ProductsComboBox } from "~/Components/Products/ProductsComboBox";
import { UOMComboboxForm } from "~/Components/Comboboxes/UOMCombobox";
import { ProductType } from "~/Reducers/Products";
import { useProductV3 } from "~/Data/Products/Products";
import { LengthByProductGroupCombobox } from "~/Components/Comboboxes/LengthByProductGroupCombobox";
import API, { ProductV2ProductType } from "~/API";
import * as z from "zod";
import { zodMsg } from "~/Utils";
import {trackEvent} from "~/Services/Tracking";

const validation = z
  .object({
    type: z.nativeEnum(ProductV2ProductType),
    productCode: z.number(zodMsg("error.required", ["product code"])),
    lengths: z.array(z.number()),
    uom: z.string().min(1, zodMsg("error.required", ["UOM"])),
  })
  .refine(
    (d) =>
      ![
        ProductV2ProductType.LUMBER,
        ProductV2ProductType.LINEAR_FOOTAGE,
      ].includes(d.type) || d.lengths.length > 0,
    {
      path: ["lengths"],
      message: "Must have at least one length",
    },
  );

export const AddMyProductDialog: FC<{
  refreshData: () => Promise<any>;
  companyId: number;
  locationId?: number;
}> = (props) => {
  const form = useFormData(
    {
      type: undefined,
      productCode: null,
      lengths: [],
      uom: "",
    },
    { validation },
  );
  const open = useToggle();
  const selectedProduct = useProductV3(form.data.productCode);
  const productType = selectedProduct.data?.productType;

  useEffect(() => {
    if (selectedProduct.data != undefined) {
      form.setData({
        type: selectedProduct.data.productType,
        productCode: form.data.productCode,
        lengths: [],
        uom: selectedProduct.data.standardUnitOfMeasure,
      });
    }
  }, [selectedProduct.data]);

  const canEditLength =
    selectedProduct.data?.productType == ProductV2ProductType.LUMBER ||
    selectedProduct.data?.productType == ProductV2ProductType.LINEAR_FOOTAGE;

  const canEditUom =
    selectedProduct.data?.productType == ProductV2ProductType.LUMBER ||
    selectedProduct.data?.productType == ProductV2ProductType.PANELS;

  const onAdd = useMutate(async () => {
    if (!form.isValid()) return;

    await API.createCompanyProduct(
      {},
      {
        uom:
          form.data.uom == ""
            ? selectedProduct.data.standardUnitOfMeasure
            : form.data.uom,
        productId: selectedProduct.data.id,
        companyId: props.companyId,
        locationId: props.locationId,
        lengthIds: form.data.lengths,
      },
    );
    await props.refreshData();
    open.toggleFalse();
    trackEvent("Added My Products to Company");
  });

  return (
    <Dialog
      title={ls("title.add.product")}
      open={open}
      onOpenChange={form.clear}
      trigger={
        <Button label="title.add.product" leftIcon={<TbPlus size={16} />} />
      }
    >
      <div className="desktop-w-min-8xl flex-col gap-sm">
        <ProductsComboBox
          id="productCode"
          formData={form}
          label="title.product.code"
        />
        {!canEditLength && (
          <Input
            id="lengthDisplay"
            value={
              productType == ProductV2ProductType.FINANCE
                ? "FI"
                : productType == ProductV2ProductType.PANELS
                  ? `${selectedProduct.data?.msrActLength}`
                  : "-"
            }
            disabled
            label="title.lengths"
          />
        )}
        {canEditLength && (
          <LengthByProductGroupCombobox
            groupId={selectedProduct.data?.productGroupId}
            id="lengths"
            formData={form}
            multiple
            showClear
            clearValue={[]}
            label="title.lengths"
            disabled={form.data.productCode == ""}
          />
        )}
        <UOMComboboxForm
          id="uom"
          formData={form}
          label="title.uom"
          productType={
            selectedProduct.data?.productType.valueOf() as ProductType
          }
          disabled={form.data.productCode == "" || !canEditUom}
        />

        {(onAdd.error || selectedProduct.error) && (
          <div className="color-red-1">
            There was an error creating the product!
          </div>
        )}
        <Button
          label="title.add.product"
          onClicked={onAdd.mutate}
          loading={onAdd.loading}
          disabled={selectedProduct.data == undefined}
        />
      </div>
    </Dialog>
  );
};
