import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import { isFutureDate } from "~/Data/Orders/OrderStatuses";
import { LC, LocalizedMessageKey } from "~/Locales";
import DateView from "~/Components/Views/DateView";
import { obtainShipViaType, ShipViaType } from "~/Data/ShipVia";
import { FC, ReactNode } from "react";
import { BiReceipt } from "react-icons/bi";
import {TbAnchor, TbShip, TbTrain, TbTruck} from "react-icons/tb";
import { Case, Match, Show } from "~/Components/UI";

const OrderGraphic: FC<{ orderId: number }> = ({ orderId }) => {
  const delivery = useSalesOrder(orderId) || undefined;
  const {
    dateContractSigned,
    dateEstimatedProvisionDate,
    importCustomsReleaseDate,
    importArrivalNoticeDate,
    vesselSailDate,
    purchaseOrderId,
    transportType,
  } = delivery.data || {};

  const ets = purchaseOrderId ? importArrivalNoticeDate : vesselSailDate;
  const originalEta = dateEstimatedProvisionDate;
  const revisedEta = importCustomsReleaseDate;
  const eta = revisedEta || originalEta;

  const shipViaType = obtainShipViaType(transportType);

  return (
    <div className="flex-col mx-lg overflow-hidden">
      <div className="relative overflow-auto w-full my-lg">
        <div className="flex-row justify-between text-left w-full">
          <div className="flex-row absolute top-0 right-0 bottom-0 left-0 justify-center align-center">
            <LineSpacer />
            <Match on={shipViaType}>
              <Case v={ShipViaType.Trucking}>
                <GraphicLine date={dateContractSigned} />
                <GraphicLine date={eta} />
              </Case>

              <Case v={ShipViaType.Sailing}>
                <GraphicLine date={dateContractSigned} />
                <GraphicLine date={ets} />
                <GraphicLine date={ets} />
                <GraphicLine date={eta} />
              </Case>

              <Case v={ShipViaType.Pickup}>
                <GraphicLine date={dateContractSigned} />
                <GraphicLine date={eta} />
              </Case>

              <Case v={ShipViaType.Rail}>
                <GraphicLine date={dateContractSigned} />
                <GraphicLine date={eta} />
              </Case>

              <Case default>
                <GraphicLine date={dateContractSigned} />
              </Case>
            </Match>
            <LineSpacer />
          </div>

          <Match on={shipViaType}>
            <Case v={ShipViaType.Trucking}>
              <GraphicIcon label="title.order.placed" date={dateContractSigned}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon
                label="title.eta"
                date={originalEta}
                revisedDate={revisedEta}
              >
                <TbTruck size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Rail}>
              <GraphicIcon label="title.order.placed" date={dateContractSigned}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon
                  label="title.eta"
                  date={originalEta}
                  revisedDate={revisedEta}
              >
                <TbTrain size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Sailing}>
              <GraphicIcon label="title.order.placed" date={dateContractSigned}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.ets" date={ets}>
                <TbShip size={24} />
              </GraphicIcon>
              <GraphicIcon
                label="title.eta"
                date={originalEta}
                revisedDate={revisedEta}
              >
                <TbAnchor size={24} />
              </GraphicIcon>
            </Case>

            <Case v={ShipViaType.Pickup}>
              <GraphicIcon label="title.order.placed" date={dateContractSigned}>
                <BiReceipt size={24} />
              </GraphicIcon>
              <GraphicIcon label="title.pickup.date" date={eta}>
                <TbTruck size={24} />
              </GraphicIcon>
            </Case>

            <Case default>
              <GraphicIcon label="title.order.placed" date={dateContractSigned}>
                <BiReceipt size={24} />
              </GraphicIcon>
            </Case>
          </Match>
        </div>
      </div>
    </div>
  );
};

export default OrderGraphic;

export const GraphicLine: FC<{ date: string }> = ({ date }) => {
  return (
    <div
      className={
        "border-top-dashed-2 flex h-0 mr-xxs mb-lg " +
        (isFutureDate(date) ? "border-blue-1" : "border-black-9")
      }
    />
  );
};

export const LineSpacer: FC = () => <div className="flex h-0 mr-xxs" />;

export const GraphicIcon: FC<{
  label: LocalizedMessageKey;
  date: string;
  revisedDate?: string;
  children: ReactNode;
}> = ({ label, date, revisedDate, children }) => {
  return (
    <div className="flex flex-col align-center overflow-hidden no-wrap">
      <div className="text-sm no-wrap color-text-2">
        <LC id={label}></LC>
      </div>
      <div
        className={
          "flex-row justify-center rounded-xxs z-10 my-md " +
          (isFutureDate(date)
            ? "bg-blue-1 color-black-12 p-sm"
            : "bg-black-9 color-black-12 p-sm")
        }
      >
        {children}
      </div>
      <div className="flex-col pb-lg">
        <div className="flex-row gap-sm">
          {revisedDate && <div>Original:</div>}
          <DateView date={date} />
        </div>
        <Show when={!!revisedDate}>
          <div className="flex-row gap-sm absolute bottom-0">
            <div>Revised:</div>
            <DateView date={revisedDate} />
          </div>
        </Show>
      </div>
    </div>
  );
};
