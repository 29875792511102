import {FC} from "react";
import {Link, useHistory} from "react-router-dom";
import {productCatalogueRootPath} from "~/Services/Routes";
import {TbAd2, TbExternalLink} from "react-icons/tb";
import {Button, HoverCard, HoverCardContent, HoverCardTrigger} from "~/Components/UI";
import {useSyndigoProduct} from "~/Data/Products/SyndigoProducts";
import {Skeleton} from "~/Components/Loaders";

export const ProductCatalogueDescriptionLink: FC<{
  description: string;
  hasSyndigo: boolean;
  productId: number;
  productCode: string;
}> = (props) => {
  if (!props.hasSyndigo)
    return (<div>{props.description}</div>)

  return (
      <HoverCard>
        <HoverCardTrigger>
          <Link
            to={`${productCatalogueRootPath}${props.productId}`}
            onClick={(e) => e.stopPropagation()}
            className="inline-flex-row gap-xxs"
          >
            <TbAd2 size={16} />
            {props.description}
          </Link>
        </HoverCardTrigger>
        <HoverCardContent>
          <SyndigoDataCard
            productId={props.productId}
            productCode={props.productCode}
          />
        </HoverCardContent>
      </HoverCard>
  )
}

const SyndigoDataCard: FC<{
  productId: number;
  productCode: string;
}> = (props) => {
  const syndigo = useSyndigoProduct(props.productCode);
  const history = useHistory();

  const onFullDetailsClick = () => {
    history.push({ pathname: `${productCatalogueRootPath}${props.productId}` });
  }

  if(syndigo.isLoading)
    return (
      <div className="desktop-w-11xl h-min-6xl flex-row gap-sm px-sm py-xxs">
        <Skeleton className="w-min-7xl h-min-7xl" />
        <div className="flex-col gap-sm py-sm w-full">
          <Skeleton className="w-full h-line-xl" />
          <Skeleton className="w-full h-line-md" />
          <Skeleton className="w-4xl h-line-md" />
          <Skeleton className="mt-auto w-full h-line-xl" />
        </div>
      </div>
    );

  if (syndigo.error)
    return (
      <div className="desktop-w-11xl h-6xl flex-row align-center justify-center color-red-1">
        Error Getting Product Data!
      </div>
    );

  const { assets, attributes } = syndigo.data;

  return (
    <div className="desktop-w-11xl h-min-6xl h-max-7xl flex-row gap-sm px-sm py-xxs">
      <img
        src={assets.ImageMain?.url}
        alt="main product image"
        className="fit-contain w-7xl h-7xl"
      />
      <div className="flex-col gap-sm py-sm w-full">
        <div className="text-xl text-bold">
          {attributes.ProductName}
        </div>
        <div className="color-text-2">
          {attributes.MarketingCopy?.slice(0, 300)}
          {attributes.MarketingCopy?.length > 300 &&  "..."}
        </div>
        <Button
          onClicked={onFullDetailsClick}
          color="gray"
          label="title.full.details"
          leftIcon={<TbExternalLink size={16}/>}
          className="mt-auto w-full"
        />
      </div>
    </div>
  );
}