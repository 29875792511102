import {ChangeEventHandler, FC} from "react";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { TbCircleFilled } from "react-icons/tb";
import "./Switch.scss";
import { LC, LocalizedMessageKey } from "~/Locales";
import { Show } from "~/Components/UI";
import { FormReturn } from "~/Hooks";
import { cn } from "~/Utils";

export interface SwitchProps {
  id: string;
  value: boolean;
  invertValue?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: LocalizedMessageKey;
  disabled?: boolean;
}

export const Switch: FC<SwitchProps> = (props) => {

  return (
    <div className="flex-row align-center">
      <SwitchPrimitive.Root
        checked={props.invertValue ? !props.value : props.value}
        onCheckedChange={(checked) => props.onChange({ target: { id: props.id, checked: props.invertValue ? !checked : checked }} as any)}
        className={cn(
          "rounded-md border-none align-center flex-row w-2xl",
          props.disabled
            ? "cursor-disabled switch-color-disabled"
            : "cursor-pointer switch-color",
        )}
        disabled={props.disabled}
        id={props.id}
      >
        <SwitchPrimitive.Thumb className="switch-animate flex-row align-center">
          <TbCircleFilled className="color-black-12" size={22} />
        </SwitchPrimitive.Thumb>
      </SwitchPrimitive.Root>
      <Show when={props.label !== undefined}>
        <label
          className={cn(
            "ml-xs text-bold",
            props.disabled ? "cursor-disabled color-black-7" : "cursor-pointer",
          )}
          htmlFor={props.id}
        >
          <LC id={props.label} />
        </label>
      </Show>
    </div>
  );
};

export interface FormSwitchProps<T> extends Omit<SwitchProps, "value" | "onChange">{
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormSwitch = <T = any,>(props: FormSwitchProps<T>) => {
  return (
    <Switch
      {...props}
      value={props.formData.data[props.id] as boolean}
      onChange={({ target }) => props.formData.onChecked(props.id, target.checked)}
    />
  );
};

export interface FormSwitchDescriptiveProps<T> extends FormSwitchProps<T> {
  description: string;
}

export const FormSwitchDescriptive = <T = any,>(props: FormSwitchDescriptiveProps<T>) => {
  return (
    <div className="flex-row gap-sm">
      <Switch
        {...props}
        label={undefined}
        value={props.formData.data[props.id] as boolean}
        onChange={({ target }) => props.formData.onChecked(props.id, target.checked)}
      />
      <label htmlFor={props.id} className={cn(props.disabled ? "cursor-disabled" : "cursor-pointer", "flex-col gap-xxs")}>
        <div className="text-bold">
          <LC id={props.label} />
        </div>
        <div className="color-text-3">{props.description}</div>
      </label>
    </div>
  );
};