import { Attachment } from "~/API";

export const findImageAndPDFAttachments = (attachments: Attachment[]): Attachment[] => {
  return attachments?.filter(it => {
    const name = it.name?.toLowerCase() || '';
    return name.endsWith('jpg') || name.endsWith('jpeg') || name.endsWith('png') || name.endsWith('pdf');
  }) || [];
};

export const findNonImageAttachments = (attachments: Attachment[]): Attachment[] => {
  return attachments?.filter(it => {
    const name = it.name?.toLowerCase() || '';
    return !name.endsWith('jpg') && !name.endsWith('jpeg') && !name.endsWith('png');
  }) || [];
};

export const isImage = (filename: string) => {
  if (typeof filename !== 'string') return false;
  const name = filename.toLowerCase();
  return name.endsWith('jpg') || name.endsWith('jpeg') || name.endsWith('png');
};
