import { useCallback } from "react";
import { OfferStatus } from "~/API";
import { Button } from "~/Components/UI";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { useToggle } from "~/Hooks/Old";
import { LocalizedMessage } from "~/Locales";
import { offersRootPath } from "~/Services/Routes";
import Toast from "~/Components/Toast";


export const VendorSubmitToPurchasingButton = ({ offerId, saveOffer, isOfferValid }) => {
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const history = useHistory();

  const onClick = useCallback(() => {
    const valid = isOfferValid();
    if (!valid) return;

    toggleConfirmSubmitDialog();
  }, [isOfferValid, toggleConfirmSubmitDialog]);

  const confirmOfferSubmit = useCallback(async () => {
    try {
      await saveOffer(OfferStatus.O_BUYER_REVIEW);
      Toast.Success(`Request ${offerId} submitted to purchasing`);
      history.replace(`${offersRootPath}${offerId}`);
    } catch (e) {
      console.error(e);
    }
  }, [saveOffer, offerId]);

  return <>
    <Button
      onClicked={onClick}
      data-cy={"VendorSubmitToPurchasingButton"}
      label="offer.submit.to.purchasing"
    />

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Submit To Purchasing'}
        description={'Do you want to Offer to your purchasing manager?'}
        description2={''}
        confirmTitle={<LocalizedMessage id={'title.send.offer'} />}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={confirmOfferSubmit}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>;
}