import API, { GroupPermission, UserGroupPermissionsView, CompanyType } from "~/API";
import { useEffect, useState } from "react";
import { useAppDispatch } from "~/StoreTypes";
import { getUserGroups, useUserGroupLabels } from "~/Reducers/UserGroups";
import { useIsAdmin } from "~/Reducers/User";
import { FormSelect, FormSelectProps } from "~/Components/UI";
import { useCompany } from "~/Data/Companies/Companies";

const UserGroupSelect = <T,>(
  props: {
    activeOnly?: boolean;
    companyId?: number;
    dataCy?: string;
  } & Omit<FormSelectProps<T, number>, "getLabel" | "data">,
) => {
  const isAdmin = useIsAdmin();
  const company = useCompany(props.companyId || null);
  const isVendor = company.data?.type == CompanyType.SELLER;

  const [groups, setGroups] = useState<UserGroupPermissionsView[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const groups = await dispatch(getUserGroups(!!props.activeOnly ? { query: 'ACTIVE == true' } : {}));
      if (!isAdmin) {
        const customerGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_CUSTOMER });
        const vendorGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_VENDOR });
        if (props.companyId) {
          setGroups(isVendor ? vendorGroups : customerGroups);
        } else {
          setGroups([...vendorGroups, ...customerGroups]);
        }

      } else if (props.companyId) {
        const customerGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_CUSTOMER });
        const vendorGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_VENDOR });
        const ufpSalesGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_SALES_CONTACT_PERSON });
        const adminGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_ADMIN });
        const ufpLogicticsGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_LOGISTICS });
        const ufpBuyerGroups = await API.getUserGroupsByPermission({ type: GroupPermission.CAN_BE_BUYER });

        const groupList = isVendor
          ? [...vendorGroups, ...ufpSalesGroups, ...adminGroups, ...ufpLogicticsGroups, ...ufpBuyerGroups]
          : [...customerGroups, ...ufpSalesGroups, ...adminGroups, ...ufpLogicticsGroups, ...ufpBuyerGroups];
        const groupListWithoutDups = [];
        groupList.forEach(group => {
          if (!groupListWithoutDups.some(it => it.id === group.id)) groupListWithoutDups.push(group);
        });
        setGroups(groupListWithoutDups);

      } else {
        setGroups(groups);
      }
    })();
  }, [isAdmin, props.companyId, isVendor]);

  const labels = useUserGroupLabels();
  const getLabel = (id: number) => labels[id] || "";

  return <FormSelect {...props}
    data={groups.map(it => it.id)}
    getLabel={getLabel}
  />;
};

export default UserGroupSelect;
