import { useState, useMemo } from "react";
import API from "~/API";
import Button from "~/Components/Old/Button";
import { CloseIcon, UploadIcon, CancelIcon } from "~/Components/Old/Icons";
import { LocalizedMessage } from "~/Locales";
import {
  useAsyncAttachHandler,
  useDetachHandler,
  fileSizeString,
} from "~/Utils/Forms";
import { addUploadedAttachmentsToReduxState } from "~/Reducers/Attachments";
import { classNames } from "react-ui-basics/Tools";
import Dropzone from "react-ui-basics/Dropzone";
import Scrollable, { SCROLLBAR_MODE_HIDDEN } from "react-ui-basics/Scrollable";
import IconButton from "~/Components/Old/IconButton";
import SquareImage from "./SquareImage";
import DialogOld from "~/Components/Old/DialogOld";
import "./ProductAttachmentUploadDialog.scss";
import {useAppDispatch} from "~/StoreTypes";

const ProductAttachmentUploadDialog = ({
  productId,
  restriction,
  onUpload,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState([]);

  const onUploadClick = () => {
    // attachments.forEach(attachment => {
    //   API.updateAttachment(attachment.id, { ...attachment, active: true },
    //     () => { },
    //   );
    // });
    dispatch(addUploadedAttachmentsToReduxState(attachments));
    onUpload?.(attachments);
  };

  const attachHandler = useAsyncAttachHandler(
    attachments,
    setAttachments,
    () => ({
      restriction: restriction || "COMPANY",
      // ref: 'PRODUCT',
      // refId: productId,
      active: true,
    }),
  );

  const detachHandler = useDetachHandler(attachments, setAttachments);

  const isAllAttachmentsAreLoaded = useMemo(() => {
    if (!attachments.length) return true;
    return attachments.every((attachment) => !attachment.progress);
  }, [attachments]);

  const attachmentsHaveBeenAdded = attachments.length > 0;
  const disableUploadButton =
    !isAllAttachmentsAreLoaded || !attachmentsHaveBeenAdded;

  //const accept = '.jpg,.jpeg,.png,.pdf';
  const accept = "*";

  return (
    <DialogOld
      className={"PhotoUploadDialogMainContainer"}
      onClickOutside={onClose}
    >
      <div
        className={classNames(
          "DialogContainer",
          attachmentsHaveBeenAdded && "Maximize",
        )}
      >
        <Button className={"CloseIcon"} onClick={onClose}>
          <CloseIcon size={16} />
        </Button>

        <div className={"text-2xl"}>
          <LocalizedMessage id={"title.upload.photos.and.files"} />
        </div>

        <Dropzone
          className={classNames(
            "UploadDragZone",
            "mt-lg",
            "dropHere",
            attachmentsHaveBeenAdded && "Minimize",
          )}
          droppable={true}
          accept={accept}
          multiple={true}
          onDrop={attachHandler}
        >
          <div className={"DragZoneContainer default"}>
            <UploadIcon
              className={classNames(
                "Icons",
                attachmentsHaveBeenAdded && "Hide",
              )}
              size={40}
            />
            <div
              className={classNames(
                "H3 InfoTitle",
                !attachmentsHaveBeenAdded && "mt-lg",
              )}
            >
              <LocalizedMessage id={"phrase.drag.and.drop.files.here"} />
            </div>
            {/* <div className={classNames('Text14 Grey mt-sm', attachmentsHaveBeenAdded && 'Hide')}>
              <LocalizedMessage id={'phrase.files.supported.photos.and.pdf'} />
            </div> */}
            <div className={"Text14 Black mt-sm"}>
              <LocalizedMessage id={"phrase.or"} />
            </div>
            <div className={"BlueUppercase mt-sm"}>
              <LocalizedMessage id={"phrase.browse.files"} />
            </div>
          </div>
          <div className={"DragZoneContainer dropHere"}>
            <div className={"text-lg InfoTitle"}>
              <LocalizedMessage id={"phrase.drop.files.here"} />
            </div>
          </div>
        </Dropzone>

        <div
          className={classNames(
            "UploadingContainer mt-lg",
            !attachmentsHaveBeenAdded && "Hide",
          )}
        >
          <div className={"UploadingHeader mb-lg"}>
            <div className={"text-xl"}>
              <LocalizedMessage id={"title.uploading.files"} />
            </div>
            <div className={"Text12 Grey"}>
              <LocalizedMessage id={"phrase.files.are.uploading"}>
                {attachments.length}
              </LocalizedMessage>
            </div>
          </div>
          <Scrollable horizontalScrollBarMode={SCROLLBAR_MODE_HIDDEN}>
            <div className={"UploadingAttachmentsContainer"}>
              {attachments.map((attachment) => (
                <AttachmentRow
                  key={attachment.id}
                  attachment={attachment}
                  detachHandler={detachHandler}
                />
              ))}
            </div>
          </Scrollable>
        </div>

        <div className={"ButtonsContainer mt-lg"}>
          <Button onClick={onUploadClick} disabled={disableUploadButton}>
            <LocalizedMessage id={"title.upload"} />
          </Button>
          <Button view={"secondary"} onClick={onClose}>
            <LocalizedMessage id={"title.cancel"} />
          </Button>
        </div>
      </div>

      <div className={"DialogContainerMobile"}>
        <div className={"ModalTitleContainer"}>
          <div className={"text-xl"}>
            <LocalizedMessage id={"title.upload.photos.and.files"} />
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon size={24} />
          </IconButton>
        </div>

        <div className={"ScrollContainerMobile"}>
          <div className={"AddPhotoButtonMobile"}>
            <Dropzone
              className={classNames(
                "UploadDragZone",
                "mt-lg",
                "dropHere",
                attachmentsHaveBeenAdded && "Minimize",
              )}
              droppable={true}
              accept={accept}
              multiple={true}
              onDrop={attachHandler}
            >
              <div className={"DragZoneContainer"}>
                <UploadIcon className={"Icons"} size={40} />
                <div className={"BlueUppercase mt-sm"}>
                  <LocalizedMessage id={"title.upload"} />
                </div>
              </div>
            </Dropzone>
          </div>

          <div
            className={classNames(
              "UploadingContainer mt-lg",
              !attachmentsHaveBeenAdded && "Hide",
            )}
          >
            <div className={"UploadingHeader mb-lg"}>
              <div className={"text-xl"}>
                <LocalizedMessage id={"title.uploading.files"} />
              </div>
              <div className={"Text12 Grey"}>
                <LocalizedMessage id={"phrase.files.are.uploading"}>
                  {attachments.length}
                </LocalizedMessage>
              </div>
            </div>

            <div className={"UploadingAttachmentsContainer"}>
              {attachments.map((attachment) => (
                <AttachmentRow
                  key={attachment.id}
                  attachment={attachment}
                  detachHandler={detachHandler}
                />
              ))}
            </div>

            <div className={"ButtonsContainer mt-lg"}>
              <Button onClick={onUploadClick} disabled={disableUploadButton}>
                <LocalizedMessage id={"title.upload"} />
              </Button>
              <Button view={"secondary"} onClick={onClose}>
                <LocalizedMessage id={"title.cancel"} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DialogOld>
  );
};

export default ProductAttachmentUploadDialog;

const AttachmentRow = ({ attachment, detachHandler }) => {
  return (
    <div key={attachment.id} className={"AttachmentContainer"}>
      <div className={"ThumbnailContainer"}>
        <SquareImage
          size="48"
          src={
            attachment.status === "READY"
              ? API.urlAttachmentThumbnail(attachment.id)
              : attachment.objectURL
          }
        />
      </div>
      <div className={"UploadingAttachmentDataContainer"}>
        <div className={"UploadingAttachmentData"}>
          <div className={"AttachmentDataLeft"}>
            <div className={"text-semibold AttachmentName"}>
              {attachment.name}
            </div>
            <div className={"text-sm AttachmentSize mt-xxs"}>
              {fileSizeString(attachment.size)}
            </div>
          </div>
          <div className={"AttachmentDataRight"}>
            <Button
              className={"CancelButton"}
              onClick={() => detachHandler(attachment)}
            >
              <CancelIcon className={"Icons"} size={24} />
            </Button>
          </div>
        </div>

        <div className={"UploadingProgressBar mt-xs"}>
          <div
            className="Completed"
            style={{
              width: `${attachment.progress ? attachment.progress : "100"}%`,
            }}
          />
        </div>

        <div className={"text-sm UploadingMessageProgress mt-xxs"}>
          {attachment.progress && (
            <LocalizedMessage id={"phrase.percent.uploading"}>
              {Number(attachment.progress).toFixed(0)}
            </LocalizedMessage>
          )}
          {!attachment.progress && (
            <LocalizedMessage id={"phrase.100%.uploaded"} />
          )}
        </div>
      </div>
    </div>
  );
};
