import { FC } from "react";
import Attachment from "~/Components/Old/Attachment";
import { LandingPage } from "~/API";
import { TbCheck, TbX } from "react-icons/tb";
import { Button, Case, Match } from "~/Components/UI";
import { LC } from "~/Locales";

export const LandingPagePreviewForm: FC<{
  attachments: any[];
  editingTrue: () => void;
  landingPage: LandingPage;
}> = (props) => (
  <div className="desktop-w-min-11xl flex-col gap-sm">
    <div className="ml-sm text-bold">Preview:</div>
    <LandingPagePreview
      attachment={props.attachments[props.landingPage.selectedImage]}
      title={props.landingPage.title}
    />
    <div className="flex-row justify-between">
      <ActiveCheckmark active={props.landingPage.active} />
      <Button label="title.edit" onClicked={props.editingTrue} />
    </div>
  </div>
);

export const LandingPagePreview: FC<{
  attachment: any;
  title: string;
}> = (props) => (
  <div className="bg-black-11-5 rounded-xxs p-lg text-center border-2 border-black-9">
    <div className="text-lg text-bold mb-md">{props.title}</div>
    <Attachment data={props.attachment} imageClassName="h-8xl object-fit" />
  </div>
);

const ActiveCheckmark: FC<{ active: boolean }> = (props) => (
  <div className="flex-row gap-sm align-center mx-sm text-bold">
    <Match on={props.active}>
      <Case v={true}>
        <TbCheck className="color-green-1" size={24} />
        <LC id="title.active" />
      </Case>

      <Case v={false}>
        <TbX className="color-red-1" size={24} />
        <LC id="title.inactive" />
      </Case>
    </Match>
  </div>
);
