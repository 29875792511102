import API from "~/API";
import { FC, useCallback, useEffect, useState } from 'react';
import { useToggle } from "~/Hooks/Old";
import { LocalizedMessage } from "~/Locales";
import {addFollower, removeFollower} from "~/Reducers/Offers";
import { useUserName } from "~/Reducers/Users";
import { useIsAdmin, useIsUFPAM, useCurrentUser } from "~/Reducers/User";
import Button from '~/Components/Old/Button';
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import TextField from "~/Components/Old/TextField";
import { classNames } from "react-ui-basics/Tools";
import "./OfferFeedbackPanel.scss";
import Toast from "~/Components/Toast";
import {useAppDispatch} from "~/StoreTypes";


interface OfferFeedbackPanelProps {
  buyerId: number;
  offerId: number;
}

enum FeedbackType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

const OfferFeedbackPanel: FC<OfferFeedbackPanelProps> = ({ offerId, buyerId }) => {
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const userName = useUserName(buyerId);
  
  const [myOfferFeedback, setMyOfferFeedback] = useState<FeedbackType>(null);
  
  useEffect(() => {
    API.getMyOfferFeedback({ id: offerId }).then((response) => {
      setMyOfferFeedback(response.value ? FeedbackType.LIKE : FeedbackType.DISLIKE);
    }).catch((error) => {
      if (error.status === 404) {
        setMyOfferFeedback(null);
        return;
      }
      console.error('### error = ', error.status);
    });
  }, [offerId]);
  
  const onLikeClick = useCallback(async () => {
    try {
      const response = await API.createOfferFeedback({ id: offerId }, { value: true, message: feedbackComment });
      setMyOfferFeedback(response.value ? FeedbackType.LIKE : FeedbackType.DISLIKE);
      await dispatch(addFollower(offerId, currentUser.id));
      Toast.Success("Feedback sent.");
    } catch (e) {
      console.error('### error = ', e);
    }
  }, []);
  
  const isUFPAM = useIsUFPAM();
  const isAdmin = useIsAdmin();
  const [showFeedbackDialog, toggleFeedbackDialog] = useToggle(false);
  
  const [feedbackComment, setFeedbackComment] = useState('');
  const onFieldChange = useCallback((event) => {
    setFeedbackComment(event.target.value);
  }, [setFeedbackComment]);
  
  const onConfirmSendFeedback = useCallback(async () => {
    try {
      const response = await API.createOfferFeedback({ id: offerId }, { value: false, message: feedbackComment });
      setMyOfferFeedback(response.value ? FeedbackType.LIKE : FeedbackType.DISLIKE);
      await dispatch(removeFollower(offerId, currentUser.id));
      toggleFeedbackDialog();
      setFeedbackComment('');
      Toast.Success("Feedback sent.");
    } catch (e) {
      Toast.ApiError(e);
    }
  }, [feedbackComment]);
  
  const isLike = myOfferFeedback === FeedbackType.LIKE;
  const isDislike = myOfferFeedback === FeedbackType.DISLIKE;
  
  return (
    <>
      {(isAdmin || isUFPAM) &&
        <div className={classNames('OfferFeedbackPanel', !!myOfferFeedback && 'OfferFeedbackPanelDisabled')}>
          What do you think of this offer? Give your feedback to {userName}
          <div className={"flex-row mt-sm"}>
            <Button view={'secondary'}
              disabled={isLike || !!myOfferFeedback}
              onClick={onLikeClick}
              className={
                classNames('ml-lg', 'OfferFeedbackPanelButton', isLike && 'Like')}>
              👍
            </Button>
            <Button view={'secondary'}
              disabled={isDislike || !!myOfferFeedback}
              onClick={toggleFeedbackDialog}
              className={
                classNames('ml-lg', 'OfferFeedbackPanelButton', isDislike && 'Dislike')}>
              👎
            </Button>
          </div>
        </div>
      }
      {showFeedbackDialog &&
        <ConfirmationDialog
          title={'Feedback'}
          description={`Thanks for the feedback. What is the reason for your negative review?`}
          description2={''}
          confirmTitle={'Send feedback'}
          closeTitle={<LocalizedMessage id={'title.cancel'} />}
          onConfirm={onConfirmSendFeedback}
          onClose={toggleFeedbackDialog}
        >
          <TextField id="externalComment"
            placeholder={'write your feedback here'}
            value={feedbackComment}
            onChange={onFieldChange}
            multiline
            rows={3}
          />
        </ConfirmationDialog>
      }
    </>);
};

export default OfferFeedbackPanel;