import { FC, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation as useRouteLocation,
  useParams,
} from "react-router-dom";
import { LocalizedMessageKey } from "~/Locales";
import { companiesRootPath, locationsRootPath } from "~/Services/Routes";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import { useLocation } from "~/Data/Locations";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";

import LocationDetailsGeneralTab from "~/Pages/Companies/LocationDetailsPage/Components/LocationDetailsGeneralTab";
import LocationDetailsUsersTab from "~/Pages/Companies/LocationDetailsPage/Components/LocationDetailsUsersTab";
import LocationMyProductsTab from "~/Pages/Companies/LocationDetailsPage/Components/LocationMyProductsTab";
import { LocationType } from "~/API";

const useSubRoutes = (isShipTo: boolean) => {
  return useMemo(() => {
    const array = [
      {
        tabName: "title.general" as LocalizedMessageKey,
        path: "/general",
        render: (props: any) => <LocationDetailsGeneralTab {...props} />,
      },
      {
        tabName: "title.users" as LocalizedMessageKey,
        path: "/users",
        render: (props: any) => <LocationDetailsUsersTab {...props} />,
      },
    ];
    if (!!isShipTo) {
      array.push({
        tabName: "title.my.products" as LocalizedMessageKey,
        path: "/my-products",
        render: (props: any) => <LocationMyProductsTab {...props} />,
      });
    }
    return array;
  }, [isShipTo]);
};

const LocationDetailsPage: FC = () => {
  const { pathname } = useRouteLocation();
  const { id: locationId } = useParams<{ id: string }>();
  const location = useLocation(+locationId);
  const isShipFrom = location.data?.type == LocationType.SHIP_FROM;

  const subRoutes = useSubRoutes(!isShipFrom);
  const selectedTab = Math.max(
    subRoutes.findIndex((tab) => pathname.includes(tab.path)),
    0,
  );

  if (location.isLoading) return <LoadingPage />;

  if (location.error)
    return <EndpointErrorPage status={location.error.status} item="location" />;

  return (
    <ScrollPage title={location.data.name}>
      <PageTitle
        type="back"
        parentUrl={`${companiesRootPath}${location.data.type == LocationType.SHIP_TO ? "customers" : "vendors"}/locations`}
      />
      <PageTabContainer title={location.data.name}>
        {subRoutes.map((r) => (
          <PageTab
            label={r.tabName}
            route={`${locationsRootPath}${locationId}${r.path}`}
            key={r.path}
            replace
          />
        ))}
      </PageTabContainer>

      <Switch>
        <Redirect
          exact
          from={`${locationsRootPath}${locationId}`}
          to={`${locationsRootPath}${locationId}/general`}
        />

        <Route path={`${locationsRootPath}:id/:tabType`}>
          {subRoutes[selectedTab]?.render({
            location: location.data,
            mutateLocation: location.mutate,
          })}
        </Route>
      </Switch>
    </ScrollPage>
  );
};

export default LocationDetailsPage;
