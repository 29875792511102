import { QuoteStatus } from "~/API";
import {FC, RefObject, useCallback, useMemo} from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  useQuote,
  useQuoteValue,
  useQuoteProductCodes,
  getQuotesForShipTo, isQuoteExpired
} from "~/Reducers/Quotes";
import {
  useQuotesPageReduxState,
  useQuotesPageByGroupReduxState,
  useShipTo,
  useDefaultPageFilters
} from "../../Reducer";
import Button from "~/Components/Old/Button";
import { DateTimeView } from "~/Components/Views/DateView";
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import {QuoteShowUnread, QuoteStatusLabel} from "../QuotesHelper";
import QuoteReference from "../QuoteReference";
import QuotesPageEmptyState from "../QuotesPageEmptyState";
import QuoteArchiveDeleteButton from "../QuoteArchiveDeleteButtons";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useFilters } from "~/Components/Old/useFilters";
import { classNames } from "react-ui-basics/Tools";
import './QuotesPageTableWithSubrows.scss';
import {useAMUserNameStrings} from "~/Reducers/Users";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";


const QuotesPageByShipToTable: FC<{
  scrollRef: RefObject<HTMLDivElement>,
  onScrolledToBottom: () => void
}> = ({ scrollRef, onScrolledToBottom }) => {
  const { ids, loading } = useQuotesPageReduxState();
  const emptyState = ids?.length === 0 && !loading;

  return emptyState
    ? <QuotesPageEmptyState />
    : <>
        {loading && <div className={'progress-bar'}><LinearProgress /></div>}
        <ScrollableForPagination ref={scrollRef} className={'flex bg-black-12 overflow-auto'} onScrolledToBottom={onScrolledToBottom}>

        <table className={'basic-table basic-table-clickable QuotesPageTableWithSubrows'}>
          <thead>
            <tr>
              <th />
              <th><LocalizedMessage id={'title.code'} /></th>
              <th><LocalizedMessage id={'title.ship.to'} /></th>
              <th><LocalizedMessage id={'title.customer'} /></th>
              <th><LocalizedMessage id={'title.quotes'} /></th>
              <th className={'text-right'}><LocalizedMessage id={'title.value'} /></th>
              <th colSpan={5} />
            </tr>
          </thead>
          {ids.map(shipToId => <ShipToRow key={shipToId} {...{ shipToId }} />)}
        </table>
      </ScrollableForPagination>
    </>
};

export default QuotesPageByShipToTable;


const ShipToRow: FC<{ shipToId: number }> = ({ shipToId }) => {
  const shipTo = useShipTo(shipToId) || undefined;

  const [expanded, expandCollapse] = useToggle(false);

  const { code, numberOfQuotes, totalValue, currency, name, companyName } = shipTo;
  const currencySymbol = useFindCurrencySymbol(currency);

  return (
    <>
      <tbody>
        <tr onClick={expandCollapse} className={'ClickableHeaderOrRow'}>
          <td>{expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}</td>
          <td>{code}</td>
          <td>{name}</td>
          <td>{companyName}</td>
          <td>{numberOfQuotes}</td>
          <td className={'text-right color-orange-1 no-wrap'}>{`${currencySymbol} ${formattedNumber(totalValue)}`}</td>
          <td colSpan={5} />
        </tr>
      </tbody>
      {expanded && <QuotesForShipTo shipToId={shipToId} />}
    </>
  );
};

const QUOTES_PER_PAGE = 10;
const filtersToExclude = ['shipTo', 'limit'];

const QuotesForShipTo: FC<{ shipToId: number }> = ({ shipToId }) => {
  const pageDataByGroupId = useQuotesPageByGroupReduxState(shipToId.toString());

  const defaultPageFilters = useDefaultPageFilters();

  const pageFilters = useMemo(() => ({ ...defaultPageFilters, shipTo: [shipToId], limit: QUOTES_PER_PAGE })
    , [shipToId, defaultPageFilters]);

  const pageDataByCustomerReduxFunc = useCallback(() => pageDataByGroupId, [pageDataByGroupId]);

  const { onScrolledToBottom, canLoadMore } = useFilters(pageFilters, getQuotesForShipTo, pageDataByCustomerReduxFunc, null, null, filtersToExclude);

  const { ids = [], loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  return (
    <tbody>
      <tr className={'QuoteHeaderRowAsSubrow'}>
        <td />
        <td><LocalizedMessage id={'title.q.id'} /></td>
        <td><LocalizedMessage id={'title.status'} /></td>
        <td><LocalizedMessage id={'title.salesperson'} /></td>
        <td><LocalizedMessage id={'title.products'} /></td>
        <td><LocalizedMessage id={'title.product.code'} /></td>
        <td><LocalizedMessage id={'title.reference'} /></td>
        <td className={'text-right'}><LocalizedMessage id={'title.value'} /></td>
        <td><LocalizedMessage id={'title.validity.date'} /></td>
        <td><LocalizedMessage id={'title.actions'} /></td>
        <td />
      </tr>
      {ids.map(quoteId => <QuoteRow key={quoteId} groupId={shipToId} {...{ quoteId }} />)}

      {showAdditionalRow &&
        <tr className={'QuoteRowAsSubrow'}>
          {!showLoader &&
            <td colSpan={11} className={'MoreButtonCell'}>
              <Button view={'text'} className={classNames('w-full', showTryAgain && 'DeleteButtonText')}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage id={showTryAgain ? 'title.try.again' : 'title.get.more'} />
              </Button>
            </td>
          }
          {showLoader && <td colSpan={11}><LinearProgress /></td>}
        </tr>
      }
    </tbody>
  );
};

const QuoteRow: FC<{
  quoteId: number,
  groupId: number
}> = ({ quoteId, groupId }) => {
  const history = useHistory();
  const quote = useQuote(quoteId) || undefined;
  const value = useQuoteValue(quoteId);
  const productCodes = useQuoteProductCodes(quoteId);
  const { id, status, offerId, currency, offerProductCount, products, salesOrderIds,
    requestForQuoteId, validityDate, accountManagerName, shipToAccountManagerName } = quote;
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = status === QuoteStatus.DRAFT;

  const onOpen = useCallback(() => history.push(`${quotesRootPath}${quoteId}`), [quoteId, history]);

  const isExpired = isQuoteExpired(validityDate);
  const managers = useAMUserNameStrings(accountManagerName, shipToAccountManagerName);

  return (
    <tr className={'QuoteRowAsSubrow'} onClick={onOpen}>
      <td className={classNames(QuoteShowUnread(quote.readMark, isExpired) ? "row-unread" : "")}/>
      <td>{`Q-${id}`}</td>
      <td>{isExpired ? 'Expired' : <QuoteStatusLabel status={status} />}</td>
      <td>{managers}</td>
      <td>{`${products?.length || 0}/${offerProductCount}`}</td>
      <td>{productCodes}</td>
      <td><QuoteReference offerId={offerId} requestForQuoteId={requestForQuoteId} salesOrderIds={salesOrderIds} simple /></td>
      <td className={'text-right no-wrap color-orange-1'}>
        {`${currencySymbol} ${formattedNumber(value) || '0'}`}
      </td>
      <td><DateTimeView date={validityDate} /></td>
      <td>
        <div className={'flex-row align-center justify-center'}>
          <QuoteArchiveDeleteButton {...{ quote, groupId }} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={quote.unreadMessagesCount} />}</td>
    </tr>
  );
};
