import { FC, ReactNode } from "react";
import { Table as TanTable } from "@tanstack/table-core/build/lib/types";
import { flexRender } from "@tanstack/react-table";
import { LC, LocalizedMessageKey } from "~/Locales";
import {cn} from "~/Utils";

export const DashboardTable: FC<{
  table: TanTable<any>;
  onRowClick?: (row: any, index: number) => void;
}> = (props) => {
  const onRowPressedHandler = (e: any, row: any, index: number) => {
    if (e.keyCode === 13) {
      // enter key
      props.onRowClick?.(row, index);
    }
  };

  return (
    <div className="bg-black-12 w-min-0 w-max-full relative">
      <table className=" text-left table-fixed border-collapse w-full" data-cy="dashboard-table">
        <thead className="bg-black-12 color-text-3 text-sm">
          {props.table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="py-xs px-xs upper-case">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {props.table.getRowModel().rows.map((row, i) => (
            <tr
              key={row.id}
              onClick={() => props.onRowClick(row.original, i)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => onRowPressedHandler(e, row.original, i)}
              className="my-sm hover-bg-black-11-5 transition-background cursor-pointer rounded-sm focus-outline-none focus-bg-black-11-5"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="py-sm px-xs table-align-middle text-no-wrap"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const DashboardMobileField: FC<{
  label: LocalizedMessageKey;
  className?: string;
  children: ReactNode;
}> = (props) => (
  <div className={cn("text-truncate flex-col flex", props.className)}>
    <div className="text-sm color-text-3">
      <LC id={props.label} />
    </div>
    <div className="text-truncate mt-xxs">{props.children}</div>
  </div>
);
