import { FC } from "react";
import { TbCheck, TbX } from "react-icons/tb";
import { UserGroupPermissionsView } from "~/API";
import { Button, Case, For, Match } from "~/Components/UI";
import { LC } from "~/Locales";

const UserGroupDisplayForm: FC<{
  userGroup: UserGroupPermissionsView;
  editingTrue: () => void;
}> = (props) => (
  <div className="desktop-w-min-11xl flex-col gap-lg">
    <ActiveCheckmark active={props.userGroup.active} />

    <div className="flex-col">
      <div className="text-bold">Permissions:</div>
      <ul>
        <For each={props.userGroup.permissions}>
          {(id) => <li key={id}>{id}</li>}
        </For>
      </ul>
    </div>

    <div className="flex-row justify-end">
      <Button onClicked={props.editingTrue} label="title.edit" />
    </div>
  </div>
);

export default UserGroupDisplayForm;

const ActiveCheckmark: FC<{ active: boolean }> = (props) => (
  <div className="flex-row gap-sm align-center mx-sm text-bold">
    <Match on={props.active}>
      <Case v={true}>
        <TbCheck className="color-green-1" size={24} />
        <LC id="title.active" />
      </Case>

      <Case v={false}>
        <TbX className="color-red-1" size={24} />
        <LC id="title.inactive" />
      </Case>
    </Match>
  </div>
);
