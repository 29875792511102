import { OfferStatus } from "~/API";
import {
  useIsAdmin,
  useIsUFPAM,
  useIsUFPBuyer,
} from "~/Reducers/User";
import { useIsReadOnly } from "../../../../Components/OffersHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { OfferDetailsButtonsPanelProps } from "../OfferDetailsButtonsPanel";
import { SendToVendorButton } from "../SharedComponents/SendToVendorButton";
import { UpdateRequestButton } from "../SharedComponents/UpdateRequestButton";
import { ReOpenRequestButton } from "../SharedComponents/ReOpenRequestButton";
import { SaveOfferButton } from "../SharedComponents/SaveOfferButton";
import Button from "~/Components/Old/Button";
import {LocalizedMessage} from "~/Locales";


export const InternalUsersRFOButtonsPanel = (props: OfferDetailsButtonsPanelProps) => {
  const {
    saveOffer,
    isOfferValid,
    offerId,
    hasChanges,
    loading,
    status,
    onBack,
  } = props;

  const readOnly = useIsReadOnly();
  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const isUFPAM = useIsUFPAM();


  const SaveButton = ({ label = null }) =>
    <SaveOfferButton hasChanges={hasChanges}
      label={label}
      loading={loading}
      saveOffer={saveOffer}
      status={status} />;

  let buttonsJSX = null;

  // Internal Users - RFO Details Page
  if (readOnly) {
    if (isAdmin || isUFPBuyer) {
      if (status === OfferStatus.RFO_DRAFT) {
        buttonsJSX = <>
          <UpdateRequestButton offerId={offerId} />
          <SendToVendorButton isOfferValid={isOfferValid} saveOffer={saveOffer} offerId={offerId} />
        </>
      }
      if (status === OfferStatus.RFO_SENT) {
        buttonsJSX = <> <UpdateRequestButton offerId={offerId} /> </>
      }

      if (status === OfferStatus.RFO_DECLINED) {
        buttonsJSX = <> <ReOpenRequestButton offerId={offerId} saveOffer={saveOffer} status={status} /> </>
      }

      if (status === OfferStatus.RFO_ARCHIVED) {
        buttonsJSX = <> <ReOpenRequestButton offerId={offerId} saveOffer={saveOffer} status={status} /> </>
      }

      if (status === OfferStatus.RFO_DRAFT_VENDOR) {
        buttonsJSX = null;
      }
    } else if (isUFPAM) {

    }

    // Internal Users - RFO Edit Page
  } else {
    if (status === OfferStatus.RFO_DRAFT) {
      buttonsJSX = <>
        <SaveButton />
        <Button view={'secondary'} onClick={onBack}>
          <LocalizedMessage id={'title.cancel'} />
        </Button>
      </>
    }
    if (status === OfferStatus.RFO_SENT) {
      buttonsJSX = <>
        <SaveButton label={'Save & Re-send to Vendor'} />
        <Button view={'secondary'} onClick={onBack}>
          <LocalizedMessage id={'title.cancel'} />
        </Button>
      </>

    }
  }
  return buttonsJSX ? <BottomButtonsPanel>{buttonsJSX}</BottomButtonsPanel> : null;
}


