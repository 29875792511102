import { RequestForQuoteStatus } from "~/API";
import { useCallback, useMemo } from "react";
import { localizedString } from "~/Locales";
import {
  useIsUFPBuyer,
  useIsCustomer,
} from "~/Reducers/User";
import { useRequestStatusSuffix } from "../../Pages/Requests/DetailsPage/Components/RequestsHelper";
import SelectWithoutPaging from "./SelectWithoutPaging";

const useLabels = () => {
  const suffix = useRequestStatusSuffix();
  return useMemo(
    () =>
      Object.values(RequestForQuoteStatus).reduce((a, key) => {
        a[key] = localizedString(`request.status.${suffix}.${key}`);
        return a;
      }, {}),
    [suffix],
  );
};

const amStatuses = [
  RequestForQuoteStatus.DRAFT_AM,
  RequestForQuoteStatus.REVIEW,
  RequestForQuoteStatus.SENT_TO_BUYER,
  RequestForQuoteStatus.OFFER_REQUESTED,
  RequestForQuoteStatus.OFFERED,
  RequestForQuoteStatus.QUOTED,
  RequestForQuoteStatus.ORDERED,
  RequestForQuoteStatus.ARCHIVED,
];

const customerStatuses = [
  RequestForQuoteStatus.DRAFT_CUSTOMER,
  RequestForQuoteStatus.REVIEW,
  RequestForQuoteStatus.SENT_TO_BUYER,
  RequestForQuoteStatus.OFFER_REQUESTED, // includes RequestForQuoteStatus.OFFERED for customer,
  RequestForQuoteStatus.QUOTED,
  RequestForQuoteStatus.ORDERED,
  RequestForQuoteStatus.ARCHIVED,
];

const buyerStatuses = [
  RequestForQuoteStatus.SENT_TO_BUYER,
  RequestForQuoteStatus.OFFER_REQUESTED,
  RequestForQuoteStatus.OFFERED,
  RequestForQuoteStatus.QUOTED,
  RequestForQuoteStatus.ORDERED,
  RequestForQuoteStatus.ARCHIVED,
];

const useStatusKeys = () => {
  const isUFPBuyer = useIsUFPBuyer();
  const isCustomer = useIsCustomer();
  if (isUFPBuyer) return buyerStatuses;
  if (isCustomer) return customerStatuses;
  return amStatuses;
};

const RequestStatusSelect = ({ onChange, multiple, value, ...props }) => {
  const isCustomer = useIsCustomer();
  const statusKeys = useStatusKeys();
  const labels = useLabels();

  const filteredValue = useMemo(() => {
    if (
      isCustomer &&
      multiple &&
      value?.includes?.(RequestForQuoteStatus.OFFERED)
    ) {
      return value.filter((status) => status !== RequestForQuoteStatus.OFFERED);
    } else {
      return value;
    }
  }, [isCustomer, value, multiple]);

  const onChangeSelection = useCallback(
    (event) => {
      const { target } = event;
      if (isCustomer && multiple && Array.isArray(target.value)) {
        let updatedStatuses = [...target.value];
        if (updatedStatuses.includes(RequestForQuoteStatus.OFFER_REQUESTED)) {
          if (!updatedStatuses.includes(RequestForQuoteStatus.OFFERED)) {
            updatedStatuses.push(RequestForQuoteStatus.OFFERED);
          }
        } else {
          updatedStatuses = updatedStatuses.filter(
            (status) => status !== RequestForQuoteStatus.OFFERED,
          );
        }
        onChange?.({ target: { id: target.id, value: updatedStatuses } });
      } else {
        onChange?.(event);
      }
    },
    [isCustomer, onChange, multiple],
  );

  return (
    <SelectWithoutPaging
      {...props}
      onChange={onChangeSelection}
      value={filteredValue}
      data={statusKeys}
      labels={labels}
      withFilter={false}
      multiple={multiple}
      skipLabelSorting
    />
  );
};

export default RequestStatusSelect;
