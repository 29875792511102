import {FC, ReactNode, useMemo} from "react";
import {
  Bar,
  BarChart as BarChartPrimitive,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import vars from "~/Styles/Vars";
import { cn } from "~/Utils";
import { Show } from "~/Components/UI";
import { TimeSeriesCategoricalData, TimeSeriesCategoricalMetric } from "~/API";

export const BarChart: FC<{
  dataset: TimeSeriesCategoricalMetric[];
  width: string | number;
  height: string | number;
  xAxisFormat?: (val: string) => string;
  categoryFormat?: (val: string) => ReactNode;
  tooltipLabelFormat?: (val: string) => ReactNode;
  tooltipDataFormat?: (val: number, data?: any, index?: number) => ReactNode;
  showTotal?: boolean;
  onbarClicked?: (data: any, index: number) => void;
  showLegend?: boolean;
  dataKey?: "count" | "value";
  loading?: boolean;
}> = (props) => {
  const categories = useMemo(() => {
    const ctg = props.dataset
        .flatMap(d => d.data.map(c => c.category))
        .filter((ct: string | null) => ct != null);
    return [...new Set(ctg)];
  }, [props.dataset]);

  const isTopBar = (data: TimeSeriesCategoricalData[], index: number) => {
    const topIndex = data
      .slice(index - data.length) // grab values above current index (inclusive)
      .reverse() // put order bars top to bottom
      .findIndex((k) => k.count > 0); // get the first that has more than zero

    return topIndex == data.length - index - 1; // index is first to have more than zero
  };

  const formatYAxis = (value) => {
    if (value > 1000000000) {
      return (value / 1000000000).toString() + 'B';
    } else if (value > 1000000) {
      return (value / 1000000).toString() + 'M';
    } else if (value > 1000) {
      return (value / 1000).toString() + 'K';
    } else {
      return value.toString();
    }
  };

  if (props.loading)
    return (
      <ResponsiveContainer width={props.width} height={props.height}>
        <BarChartPrimitive
          data={[1,4,3,5,4,7]}
          className="mr-lg relative"
        >
          <Bar
            dataKey={(dset) => dset}
            radius={[vars.size.xxs, vars.size.xxs, 0, 0]}
            fill={vars.colors.black115}
            animationDuration={0}
          />
          <XAxis dataKey="period" tickFormatter={() => ""} />
          <YAxis className="text-semibold" tickFormatter={() => ""} />
        </BarChartPrimitive>
      </ResponsiveContainer>
    );

  return (
    <ResponsiveContainer width={props.width} height={props.height}>
      <BarChartPrimitive data={[...props.dataset]} className="mr-lg">
        <Tooltip
          wrapperClassName="rounded-xxs text-left"
          animationEasing="ease-out"
          animationDuration={200}
          itemStyle={{ color: vars.colors.text2 }}
          content={(props1) => (
            <CustomTooltip
              showTotal={props.showTotal}
              tooltipLabelFormat={props.tooltipLabelFormat}
              tooltipDataNameFormat={props.categoryFormat}
              tooltipDataValueFormat={props.tooltipDataFormat}
              {...props1}
            />
          )}
          cursor={{ fill: vars.colors.black11 }}
        />
        {props.showLegend && (
          <Legend
            content={(props1) => (
              <div className="flex-row gap-sm justify-center">
                {props1.payload.reverse().map((d) => (
                  <div key={d.value} className="flex-row gap-xs align-center">
                    <div
                      className="rounded-full w-md h-md"
                      style={{ backgroundColor: d.color }}
                    />
                    <div className="text-semibold">
                      {props.categoryFormat == undefined
                        ? d.value
                        : props.categoryFormat(d.value)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        )}
        {categories.map((category, i) => (
          <Bar
            key={category}
            name={category}
            dataKey={(dset) => dset.data[i]?.[props.dataKey || "count"] || 0}
            fill={colors[i % colors.length]}
            stackId="a"
            animationDuration={250}
            animationBegin={i * 250}
            onClick={props.onbarClicked}
            className={cn(props.onbarClicked !== undefined && "cursor-pointer")}
          >
            {props.dataset.map((_cell, j) => (
              <Cell
                key={j}
                radius={
                  // round the top corners if all categories above are 0
                  isTopBar(props.dataset[j].data, i)
                    ? [vars.size.xxs, vars.size.xxs, 0, 0]
                    : ([0, 0, 0, 0] as any)
                }
              />
            ))}
          </Bar>
        ))}
        <XAxis dataKey="period" tickFormatter={props.xAxisFormat} />
        <YAxis className="text-semibold" allowDecimals={false} tickFormatter={formatYAxis} />
      </BarChartPrimitive>
    </ResponsiveContainer>
  );
};

export const CustomTooltip: FC<{
  active?: boolean;
  payload?: any[];
  label?: string;
  tooltipLabelFormat?: (val: string) => ReactNode;
  tooltipDataNameFormat?: (val: string) => ReactNode;
  tooltipDataValueFormat?: (val: number, data?: any, index?: number) => ReactNode;
  showTotal?: boolean;
}> = (props) => {
  let label: ReactNode = props.label;
  if (props.tooltipLabelFormat !== undefined)
    label = props.tooltipLabelFormat(props.label);

  if (props.active && props.payload && props.payload.length)
    return (
      <div
        className={cn(
          "bg-black-12 border-1 border-black-9 shadow-lg rounded-xxs",
          "text-left flex-col gap-xs px-sm py-xs",
        )}
      >
        <div className="text-lg text-bold">{label}</div>

        {props.payload.reverse().map((d, i) => (
          <div key={i} className="flex-row gap-xs align-center">
            <div
              className="rounded-full w-md h-md"
              style={{ backgroundColor: d.fill }}
            />
            <div className="text-semibold">
              {props.tooltipDataNameFormat == undefined
                ? d.name
                : props.tooltipDataNameFormat(d.name)}
              :
            </div>
            <div>
              {props.tooltipDataValueFormat == undefined
                ? d.value
                : props.tooltipDataValueFormat(d.value, props.payload, i)}
            </div>
          </div>
        ))}

        <Show when={props.showTotal}>
          <div className="flex-row gap-xs align-center">
            <div className="w-md" />
            <div className="text-semibold">Total:</div>
            <div>
              {props.payload.reduce((p: number, c: any) => p + c.value, 0)}
            </div>
          </div>
        </Show>
      </div>
    );

  return <></>;
};

const colors = [vars.colors.blue1, vars.colors.green1];