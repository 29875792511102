import useSWR from "swr";
import API, {Notification, NotificationRef, NotificationType} from "~/API";
import useSWRInfinite from "swr/infinite";
import {ScopedMutator} from "swr/_internal";
import {mutate} from "swr";
import {invalidateChats} from "~/Data/Chats";

export const useNotificationCount = () =>
  useSWR("api/notifications/count", () => API.getNotificationsUnreadCount());

const getNotificationListKey = (
  unreadOnly: boolean,
  offset: number,
  previousPageData: Notification[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/notifications", unreadOnly, offset };
};

const PAGE_SIZE = 15;

export const useNotifications = (unreadOnly: boolean) =>
  useSWRInfinite(
    (i, p) => getNotificationListKey(unreadOnly, i * PAGE_SIZE, p),
    (key) =>
      API.getNotifications({
        showUnreadOnly: key.unreadOnly,
        offset: key.offset,
        limit: PAGE_SIZE,
      }),
    { revalidateFirstPage: false },
  );

export const markNotificationRead = async (group: Notification[]) => {
  if (group[0].data.type === NotificationType.NEW_CHAT_MESSAGE) {
    await API.markAllNotificationsAsReadByTarget({
      type: NotificationType.NEW_CHAT_MESSAGE,
      ref: NotificationRef.CHAT,
      refId: group[0].data.refs[NotificationRef.CHAT]?.[0],
    }).then();
  } else {
    await Promise.all(
      group.map(async(notif) => {
        if (!notif.read) {
          await API.markNotificationAsRead({ id: notif.id }).then();
        }
      })
    )
  }
};

export const markChatNotificationsRead = async (chatId: number) => {
  try {
    await API.markAllNotificationsAsReadByTarget({
      type: NotificationType.NEW_CHAT_MESSAGE,
      ref: NotificationRef.CHAT,
      refId: `${chatId}`,
    });
    await API.readAllChatMessages({ id: chatId })
    invalidateChats(mutate)
    invalidateNotifications(mutate)
  } catch (e) {}
}

export const invalidateNotifications = (mutate: ScopedMutator) => {
  mutate("api/notifications/count");
  mutate(key => typeof key === "object" && key["route"] === "api/notifications");
}

export const markAllNotificationsAsRead = async () => {
  try {
    await API.markAllNotificationsAsRead();
    invalidateNotifications(mutate);
  } catch (e) {}
}

export const deleteNotification = async (id: number) => {
  try {
    await API.deleteNotification({id});
    invalidateNotifications(mutate);
  } catch (e) {}
}

export const deleteAllNotifications = async () => {
  try {
    await API.deleteAllNotifications();
    invalidateNotifications(mutate);
  } catch (e) {}
}
