import {FC, ReactNode} from "react";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const Tooltip: FC<{ children: ReactNode }> = (props) => {
  return (
    <TooltipPrimitive.TooltipProvider>
      <TooltipPrimitive.Root delayDuration={0}>
        {props.children}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.TooltipProvider>
  )
}

export const TooltipTrigger: FC<{ children: ReactNode }> = (props) => {
  return (
    <TooltipPrimitive.TooltipTrigger asChild>
      {props.children}
    </TooltipPrimitive.TooltipTrigger>
  )
}

export const TooltipContent: FC<{
  children: ReactNode,
  align?: "start" | "end" | "center"
}> = (props) => {
  return (
    <TooltipPrimitive.TooltipPortal>
      <TooltipPrimitive.Content
        align={props.align}
        className="animate-tooltip-content bg-black-1 opacity-90 rounded-xxs color-black-12 p-xs"
      >
        <TooltipPrimitive.Arrow className="color-black-1" />
        {props.children}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.TooltipPortal>
  )
}