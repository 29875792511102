import { useEffect } from "react";
import SelectWithPaging from "./SelectWithPaging";
import {
  useUsers, getUsers, getUser, getCompanyUsers,
  getUsersByPermissions, useUserLabelsWithCompany
} from "~/Reducers/Users";
import {useAppDispatch} from "~/StoreTypes";


const UserSelect = ({ companyId = null, byPermissions = null, ...props }) => {
  const dispatch = useAppDispatch();
  const users = useUsers();

  useEffect(() => {
    if (Array.isArray(props.value)) {
      props.value.forEach(userId => {
        if (!users[userId]) dispatch(getUser(userId));
      });
    } else {
      if (!users[props.value]) dispatch(getUser(props.value));
    }
  }, [props.value]);

  if (byPermissions) {
    return(
      <SelectWithPaging {...props}
        entityId={byPermissions}
        getData={getUsers}
        getDataByEntityId={getUsersByPermissions}
        useDataLabels={useUserLabelsWithCompany}
      />
    );
  }

  return (
    <SelectWithPaging {...props}
      entityId={companyId}
      getData={getUsers}
      getDataByEntityId={getCompanyUsers}
      useDataLabels={useUserLabelsWithCompany}
    />
  );
};

export default UserSelect;

