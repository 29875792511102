import { FC } from "react";
import { Button, Dialog, FormCombobox, FormInput } from "~/Components/UI";
import { ls } from "~/Locales";
import { TbPlus } from "react-icons/tb";
import { useFormData, useMutate, useToggle } from "~/Hooks";
import API, {
  PortType,
  SeaFreightCostCategory,
  SeaFreightCostUpdateRequest,
} from "~/API";
import * as z from "zod";
import { zodMsg } from "~/Utils";
import {FormDateInput} from "~/Components/UI/Forms/DateInput";
import {AddOnCostCombobox} from "~/Components/Comboboxes/AddOnCostCombobox";
import {CurrencyCombobox} from "~/Components/Comboboxes/CurrencyCombobox";
import {PortCombobox} from "~/Components/Ports/PortCombobox";
import {IncotermCombobox} from "~/Components/Comboboxes/IncotermCombobox";
import {trackEvent} from "~/Services/Tracking";

const validation = z.object({
  currencyPrice: z.string().min(1, zodMsg("error.required", ["Currency"])),
  portOfLoadingId: z.number(zodMsg("error.required", ["Port of loading"])),
  portOfDischargeId: z.number(zodMsg("error.required", ["Port of Discharge"])),
  category: z.nativeEnum(SeaFreightCostCategory, zodMsg("error.required", ["Category"])),
  dateExpired: z.string().min(1, zodMsg("error.required", ["Validity date"])),
});

export const AddLogisticsCostDialog: FC<{
  refreshCosts: () => Promise<any>;
}> = (props) => {
  const open = useToggle();
  const form = useFormData<Partial<SeaFreightCostUpdateRequest>>({
    additionalCostsPerContainer: 0,
    additionalCostsPerShipment: 0,
    addons: [],
    category: undefined,
    currencyAdditionalCostsPerContainer: "",
    currencyAdditionalCostsPerShipment: "",
    currencyDTHC: "",
    currencyGassing: "",
    currencyPrice: "",
    dateExpired: "",
    destinationDischargeId: undefined,
    destinationLoadingId: undefined,
    dthcPerContainer: 0,
    forwarder: "",
    gassingPerContainer: 0,
    incotermDischarge: undefined,
    incotermLoading: undefined,
    notes: "",
    portOfDischargeId: undefined,
    portOfLoadingId: undefined,
    pricePerContainer: undefined,
    active: true
  }, { validation });

  const addRoute = useMutate(async () => {
    if (form.isValid()) {
      await API.createSeaFreightCost({}, form.data as SeaFreightCostUpdateRequest);
      await props.refreshCosts();
      open.toggleFalse();
      trackEvent("Logistics Cost Added");
    }
  });

  return (
    <Dialog
      title={ls("title.add.route")}
      trigger={
        <Button leftIcon={<TbPlus size={16} />} label="title.add.route" />
      }
      open={open}
      onOpenChange={form.clear}
    >
      <div className="desktop-w-12xl flex-col gap-md">
        <div className="flex-row gap-md">
          <div className="flex flex-col gap-md">
            <IncotermCombobox
              label="title.incoterm.-.loading"
              id="incotermLoading"
              showClear
              clearValue={null}
              formData={form}
            />

            <PortCombobox
              label="title.port.of.loading"
              id="portOfLoadingId"
              showClear
              clearValue={null}
              formData={form}
              filters={{
                inactive: false,
                type: [PortType.PORT, PortType.PORT_OF_LOADING],
              }}
            />

            <PortCombobox
              label="title.destination.loading"
              id="destinationLoadingId"
              showClear
              clearValue={null}
              formData={form}
              filters={{
                inactive: false,
                type: [PortType.DOOR, PortType.CY, PortType.RAMP],
              }}
            />
          </div>

          <div className="flex flex-col gap-md">
            <IncotermCombobox
              label="title.incoterm.-.discharge"
              id="incotermDischarge"
              showClear
              clearValue={null}
              formData={form}
            />

            <PortCombobox
              label="title.port.of.discharge"
              id="portOfDischargeId"
              showClear
              clearValue={null}
              formData={form}
              filters={{
                inactive: false,
                type: [PortType.PORT, PortType.PORT_OF_DISCHARGE],
              }}
            />

            <PortCombobox
              label="title.destination.discharge"
              id="destinationDischargeId"
              showClear
              clearValue={null}
              formData={form}
              filters={{
                inactive: false,
                type: [PortType.DOOR, PortType.CY, PortType.RAMP],
              }}
            />
          </div>
        </div>

        <div className="flex-row gap-md">
          <FormInput
            label="title.transit.time"
            id="transitDurationDays"
            type="number"
            formData={form}
            containerClassName="flex"
          />

          <FormInput
            label="title.cost.per.40"
            id="pricePerContainer"
            type="number"
            formData={form}
            containerClassName="flex"
          />

          <CurrencyCombobox
            label="title.currency"
            id="currencyPrice"
            formData={form}
            containerClassName="flex"
          />
        </div>

        <FormCombobox
          label="title.category"
          id="category"
          showClear
          clearValue={null}
          data={Object.values(SeaFreightCostCategory)}
          getLabel={(d) => ls(`logistics.cost.category.${d}`)}
          formData={form}
        />

        <AddOnCostCombobox
          label="title.incl.add.on.costs"
          id="addons"
          type={form.data.category as any}
          multiple
          showClear
          clearValue={[]}
          formData={form}
        />

        <FormDateInput
          label="title.validity.date"
          id="dateExpired"
          formData={form}
        />

        <div className="flex-row gap-md align-center">
          <FormInput label="title.vendor" id="forwarder" formData={form} containerClassName="w-min-6xl" />

          <FormInput label="title.rate.notes" id="notes" formData={form} containerClassName="flex" />
        </div>

        {addRoute.error && (
          <div className="color-red-1">
            Something went wrong!
          </div>
        )}

        <Button
          label="title.add.route"
          onClicked={addRoute.mutate}
          loading={addRoute.loading}
        />
      </div>
    </Dialog>
);
}