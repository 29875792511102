import UserDetailsPageProfileTab from "~/Pages/Users/DetailsPage/Components/UserDetailsPageProfileTab";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { useCurrentUser } from "~/Reducers/User";
import { ScrollPage } from "~/Components/Layout/Pages";
import { FC } from "react";
import { useUser } from "~/Data/Users";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";

const MyProfilePage: FC = () => {
  const currentUser = useCurrentUser();
  const user = useUser(currentUser.id);

  if (user.isLoading || user.isValidating) return <LoadingPage />;

  if (user.error)
    return (
      <EndpointErrorPage
        status={user.error.status}
        item="user"
      />
    );

  return (
    <ScrollPage title="My Profile">
      <PageTitle title="title.user.profile" type="menu" />
      <UserDetailsPageProfileTab user={user.data} />
    </ScrollPage>
  );
};

export default MyProfilePage;
