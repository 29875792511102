import Vars from "../../../Styles/Vars";
import {OfferProductView, OfferStatus} from "~/API";
import { useMemo } from "react";
import { useParams, useLocation } from 'react-router-dom';
import {LocalizedMessage, LocalizedMessageKey, localizedString} from "~/Locales";
import { isOfferDraftStatus } from "~/Reducers/Offers";
import { useIsUFPAM, useIsUFPBuyer, useIsVendor } from "~/Reducers/User";
import {classNames} from "react-ui-basics/Tools";


export const offersStatusColors: { [key in OfferStatus]?: string } = {
  [OfferStatus.RFO_DRAFT]: Vars.colors.blue1,
  [OfferStatus.RFO_DRAFT_VENDOR]: Vars.colors.blue1,
	[OfferStatus.RFO_SENT]: Vars.colors.blue1,
	[OfferStatus.RFO_DECLINED]: Vars.colors.blue1,
	[OfferStatus.RFO_ARCHIVED]: Vars.colors.text2,
  [OfferStatus.O_DRAFT]: Vars.colors.blue1,
  [OfferStatus.O_DRAFT_VENDOR]: Vars.colors.blue1,
  [OfferStatus.O_BUYER_REVIEW]: Vars.colors.blue1,
	[OfferStatus.O_SALES_REVIEW]: Vars.colors.blue1,
	[OfferStatus.O_QUOTED]: Vars.colors.blue1,
	[OfferStatus.O_IN_PROGRESS]: Vars.colors.blue1,
	[OfferStatus.O_QUOTE_CLOSED]: Vars.colors.blue1,
	[OfferStatus.O_CLOSING]: Vars.colors.blue1,
  [OfferStatus.O_VENDOR_CONFIRMED]: Vars.colors.blue1,
  [OfferStatus.O_ORDERED]: Vars.colors.blue1,
  [OfferStatus.O_DELIVERED]: Vars.colors.blue1,
  [OfferStatus.O_ARCHIVED]: Vars.colors.text2,
};

export const OfferShowUnread = (read: boolean | undefined, isExpired: boolean) => {
  // don't show if not set or expired
  if (read === undefined || isExpired)
    return false;

  // show if unread
  return !read;
}

export const useOfferStatusSuffix = () => {
  const isUFPBuyer = useIsUFPBuyer();
  const isUFPSales = useIsUFPAM();
  const isVendor = useIsVendor();

  if (isUFPBuyer) return 'buyer';
  if (isUFPSales) return 'sales';
  if (isVendor) return 'vendor';

  return 'buyer'; // for admins
}

const useOfferStatusLabel = (offerStatus: OfferStatus) => {
  const suffix = useOfferStatusSuffix();
  return <LocalizedMessage id={`offer.status.${suffix}.${offerStatus}`} />;
}

export const localizedOfferStatusString = (offerStatus: OfferStatus, suffix: string): string => {
  return localizedString(`offer.status.${suffix}.${offerStatus}` as LocalizedMessageKey)
}

export const OfferStatusLabel = ({ offerStatus, offerRead, offerIsExpired, className, alwaysShowColor }: {
  offerStatus: OfferStatus;
  offerRead?: boolean;
  offerIsExpired?: boolean;
  className?: string;
  alwaysShowColor?: boolean,
}) => {
  const label = useOfferStatusLabel(offerStatus);

  const color = OfferShowUnread(offerRead, offerIsExpired) || alwaysShowColor ? offersStatusColors[offerStatus] : "black";

  return (
    <div style={{ color }} className={classNames(className, OfferShowUnread(offerRead, offerIsExpired) ? "text-bold" : "")}>
      {label}
    </div>
  );
}

export const calculateOfferProductsTotalValue = (products: OfferProductView[]) => {
  return products
    .map(product => product.price * product.quantity)
    .reduce((a, b) => a + b, 0)
}

export const isRFO = (status) => {
  return [
    OfferStatus.RFO_DRAFT,
    OfferStatus.RFO_DRAFT_VENDOR,
    OfferStatus.RFO_SENT,
    OfferStatus.RFO_DECLINED,
    OfferStatus.RFO_ARCHIVED
  ].includes(status);
};

export const useIsDraft = (status) => {
  return useMemo(() => isOfferDraftStatus(status), [status]);
};

export const useIsVendorConfirmationRequested = (status) => {
  return useMemo(() => [
    OfferStatus.O_CLOSING,
    OfferStatus.O_VENDOR_CONFIRMED,
    OfferStatus.O_ORDERED,
    OfferStatus.O_DELIVERED,
    OfferStatus.O_ARCHIVED,
  ].includes(status), [status]);
};

export const useIsEdit = () => {
  const { pathname } = useLocation();
  return pathname?.toLowerCase().includes('edit');
};

export const useIsNew = () => {
  const { id: offerId } = useParams<{id: string}>();
  return offerId?.toLowerCase() === 'new';
};

export const useIsMatching = () => {
  const { pathname } = useLocation();
  return pathname?.toLowerCase().includes('matching');
};

export const useIsReadOnly = () => {
  const isEdit = useIsEdit();
  const isNew = useIsNew();
  const isMatching = useIsMatching();
  return !isEdit && !isNew && !isMatching;
};
