import {
  Attachment,
  CreateQuoteProductRequest,
  CreateQuoteRequest,
  GroupPermission,
  QuoteProductUpdate,
  QuoteStatus,
  QuoteUpdate,
  QuoteView,
  RequestForQuoteStatus
} from "~/API";
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAppDispatch} from "~/StoreTypes";
import {useHistory, useParams} from 'react-router-dom';
import {LC, LocalizedMessage, LocalizedMessageKey, localizedString, ls} from "~/Locales";
import {quotesRootPath, requestedOffersRootPath, requestsRootPath, useSearchParams} from "~/Services/Routes";
import {deepCopyOf} from "~/Utils";
import {useFormState} from "~/Hooks/Old";
import {isComboIncoterm, useIncotermUsesAddress} from "~/Reducers/Incoterms";
import {useLocations} from "~/Reducers/Locations";
import {
  generateTodayPlusSevenDaysDateTimeInISO,
  getDateTimeEndOfTheDayInISO,
  getOfferAttachments,
  useOffers
} from "~/Reducers/Offers";
import {
  createQuote,
  getQuoteAttachments,
  isQuoteExpired,
  markQuoteRead,
  updateQuote,
  useQuote
} from "~/Reducers/Quotes";
import {createRequestForQuote, getRequestForQuoteAttachments, useRequestsForQuote} from "~/Reducers/RequestsForQuote";
import {useUserNames} from "~/Reducers/Users";
import {useCurrentUser, useIsInternalUser} from "~/Reducers/User";
import {findImageAndPDFAttachments} from "~/Utils/Attachments";
import {destinationNonPortTypes, destinationPortTypes} from "~/Data/Destinations";
import {QuoteStatusLabel, useIsEdit, useIsNew, useIsReadOnly} from "../Components/QuotesHelper";
import CompanyView from "~/Components/Views/CompanyView";
import DatePicker from "~/Components/Old/DatePicker";
import ImagesList from "~/Components/Old/ImagesList";
import IncotermDestinationLabel from "~/Components/Old/IncotermDestinationLabel";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import LocationName from "~/Components/Old/LocationName";
import PortSelect from "~/Components/Old/PortSelect";
import ProductImagesGallery from "~/Components/Old/ImagesGallery";
import QuoteReference from "../Components/QuoteReference";
import Select from "~/Components/Old/Select";
import TextField from "~/Components/Old/TextField";
import UnsavedChangesPrompt from "~/Components/Old/UnsavedChangesPrompt";
import UserName from "~/Components/Old/UserName";
import CustomerSelect from "~/Components/Old/CustomerSelect";
import LocationSelect from "~/Components/Old/LocationSelect";
import UserSelect from "~/Components/Old/UserSelect";
import AccountManagerSelect from "~/Components/Old/AccountManagerSelect";
import UserByLocationSelect from "~/Components/Old/UserByLocationSelect";
import VendorSelect from "~/Components/Old/VendorSelect";
import RequestSelect from "~/Components/Old/RequestSelect";
import OfferSelect from "~/Components/Old/OfferSelect";
import {WarningBanner} from "~/Components/Old/WarningBanner";
import ProductsList from "~/Pages/Offers/DetailsPage/Components/ProductsList";
import QuoteDetailsSummarySectionTable from "./Components/QuoteDetailsSummarySectionTable";
import QuoteDetailsManagers from './Components/QuoteDetailsManagers';
import QuoteFeedbackPanel from "./Components/QuoteFeedbackPanel";
import QuoteBottomButtons from "./Components/QuoteBottomButtons";
import dayjs from "dayjs";
import {usePort} from "~/Reducers/Ports";
import Toast from "~/Components/Toast";
import {ProductType} from "~/Reducers/Products";
import {invalidateChats} from "~/Data/Chats";
import {mutate} from "swr";
import {useCompany} from "~/Reducers/Companies";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "~/Components/UI/Tabs";
import {Card} from "~/Components/UI";
import PortView from "~/Components/Ports/PortView";
import {trackEvent} from "~/Services/Tracking";


const currencyArray = ['USD', 'EUR'];

const buyerSelectByPermissions = [GroupPermission.CAN_BE_SALES_CONTACT_PERSON, GroupPermission.CAN_BE_ADMIN];

const defaultQuote = {
  currency: 'USD',
  validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
};

const FieldWithLabel: FC<{ label: LocalizedMessageKey, children: ReactNode, dataCy?: String}> = ({ label, children, dataCy }) => {
  return (
    <div className="flex-col flex">
      <div className="text-sm color-text-2 mb-xxs">
        <LocalizedMessage id={label} />
      </div>
      <div className="text-left color-text-1 flex-col" data-cy={dataCy}>
        {children}
      </div>
    </div>
  )
}

const QuoteDetailsGeneralTab: FC<{
  onBack: () => void,
  showChat: boolean,
  toggleShowChat: () => void,
  pageHeader: string | ReactNode,
}> = ({onBack, showChat, toggleShowChat, pageHeader}) => {
  const dispatch = useAppDispatch();
  const isInternalUser = useIsInternalUser();
  const currentUser = useCurrentUser();
  const {id: quoteIdFromUrl} = useParams<{ id: string }>();

  const requestsReduxState = useRequestsForQuote();
  const offersReduxState = useOffers();

  const history = useHistory();

  const isNew = useIsNew();
  const viewMode = useIsReadOnly();
  const isEdit = useIsEdit();
  const quoteId = isNew ? null : Number(quoteIdFromUrl);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (isNew) {
      const {requestId, offerId} = searchParams;
      if (requestId) {
        onFieldChange({target: {id: 'requestForQuoteId', value: Number(requestId)}});
      } else if (offerId) {
        onFieldChange({target: {id: 'offerId', value: Number(offerId)}});
      }
    } else if(!quote?.readMark && !isNew) {
      dispatch(markQuoteRead(quoteId));
    }

  }, []);

  const initFormData = isNew ? defaultQuote : undefined;

  const quote = useQuote(quoteId) || undefined;

  const vendor = useCompany(quote?.vendorCompanyId)
  const isVendorDomestic = vendor?.isDomestic || false

  const [formData, setFormData, hasChanges] = useFormState<QuoteView>(quote, initFormData as QuoteView);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const onAttachmentListChange = useCallback((newAttachments: Attachment[]) => {
    setFormData(formData => ({...formData, attachments: newAttachments.map(it => it.id)}));
    setAttachments(newAttachments);
  }, [setFormData]);

  const [loading, setLoading] = useState(false);

  const imageGallery = useRef(null);

  const [requiredFieldsWarningMessage, setRequiredFieldsWarningMessage] = useState(null);

  const {
    status = isNew ? QuoteStatus.DRAFT : null,
    customerCompanyId,
    accountManagerUserId,
    shipToLocationId,
    currency = currencyArray[0],
    incoterm = null,
    incotermAddressDestination,
    incotermPortIdDestination,
    portOfLoadingId,
    expectedDeliveryDate = '',
    validityDate = '',
    offerId = null,
    requestForQuoteId = null,
    externalComment,
    internalComment,
    products = [],
    customerContacts = [],
    buyerUserId,
    vendorCompanyId,
    shipFromLocationId,
    dateCreated,
    dateUpdated,
    createdByUserAccountId,
    updatedByUserAccountId,
    salesOrderIds,
  } = formData || {};

  const isComboIncotermSelected = isComboIncoterm(incoterm);
  const canShowAddressField = useIncotermUsesAddress(incoterm) || isComboIncotermSelected;
  const canShowPortField = !useIncotermUsesAddress(incoterm) || isComboIncotermSelected;

  const isDraft = status === QuoteStatus.DRAFT;

  const isExpired = isQuoteExpired(validityDate);

  const locations = useLocations();

  useEffect(() => {
    if (quoteId) {
      dispatch(getQuoteAttachments(quoteId)).then(setAttachments);
    }
  }, [quoteId, dispatch, isNew, viewMode, isEdit]);

  const customerRef = useRef(null);
  const shipToRef = useRef(null);
  const accountManagerRef = useRef(null);
  const expectedDeliveryRef = useRef(null);
  const incotermRef = useRef(null);
  const incotermAddressRef = useRef(null);
  const incotermPortRef = useRef(null);
  const currencyRef = useRef(null);

  const isQuoteValid = useCallback((dataToCheck: CreateQuoteRequest | QuoteUpdate, lightCheck = true) => {
    if (!dataToCheck.customerCompanyId) customerRef.current?.jiggleIfInvalid();
    if (!dataToCheck.shipToLocationId) shipToRef.current?.jiggleIfInvalid();
    if (lightCheck) return dataToCheck.customerCompanyId && dataToCheck.shipToLocationId;

    if (!dataToCheck.accountManagerUserId) accountManagerRef.current?.jiggleIfInvalid();
    if (!dataToCheck.expectedDeliveryDate) expectedDeliveryRef.current?.jiggleIfInvalid();
    if (!dataToCheck.incoterm) incotermRef.current?.jiggleIfInvalid();
    if (!dataToCheck.incotermAddressDestination) incotermAddressRef.current?.jiggleIfInvalid();
    if (!dataToCheck.incotermPortIdDestination) incotermPortRef.current?.jiggleIfInvalid();
    if (!dataToCheck.currency) currencyRef.current?.jiggleIfInvalid();

    const isDestinationValid = canShowAddressField
      ? !!dataToCheck.incotermAddressDestination
      : !!dataToCheck.incotermPortIdDestination;

    const warnings = [
      {condition: !dataToCheck.customerCompanyId, message: 'Customer Missing'},
      {condition: !dataToCheck.shipToLocationId, message: 'Ship-To Missing'},
      {condition: !dataToCheck.incoterm, message: 'Incoterm Missing'},
      {condition: !isDestinationValid, message: canShowAddressField ? 'Address Missing' : 'Port Missing'},
      {condition: !dataToCheck.expectedDeliveryDate, message: 'Expected Delivery Date Missing'},
      {condition: !dataToCheck.accountManagerUserId, message: 'Account Manager Missing'},
      {condition: !dataToCheck.currency, message: 'Currency Missing'},
      {condition: dataToCheck.products.length === 0, message: 'Add at least one product'},
      {
        condition: dataToCheck.products.some((it: any) => !it.quantity && it.productType !== ProductType.FINANCE) || dataToCheck.products.length === 0,
        message: 'All products must have quantity'
      },
      {
        condition: dataToCheck.products.some((it: any) => !it.uom && it.productType !== ProductType.FINANCE) || dataToCheck.products.length === 0,
        message: 'All products must have Unit Of Measure'
      },
      {
        condition: dataToCheck.products.some((it: CreateQuoteProductRequest | QuoteProductUpdate) => !it.price) || dataToCheck.products.length === 0,
        message: 'All products must have price'
      },
    ].filter(warning => warning.condition)
      .map((warning, i) => <>{i !== 0 ? ',': ''} {warning.message}</>);

    const warningMessage = warnings.length > 0
      ? <>Please Fix Before Submitting: {warnings}</>
      : null;
    setRequiredFieldsWarningMessage(warningMessage);

    if (
      !dataToCheck.customerCompanyId
      || !dataToCheck.shipToLocationId
      || !dataToCheck.accountManagerUserId
      || !dataToCheck.expectedDeliveryDate
      || !dataToCheck.incoterm
      || !isDestinationValid
      || !dataToCheck.currency)
      return false;

    if (dataToCheck.products.length === 0) {
      Toast.Error("Add at least one product to submit quote.");
      return false;
    }
    // formData.products - all products must have quantity > 0
    if (dataToCheck.products.some((it: any) => !it.quantity && it.productType !== ProductType.FINANCE)) {
      Toast.Error("All products must have quantity.");
      return false;
    }
    if (dataToCheck.products.some((it: any) => !it.uom && it.productType !== ProductType.FINANCE)) {
      Toast.Error("All products must have Unit of Measure.");
      return false;
    }
    if (dataToCheck.products.some((it: CreateQuoteProductRequest | QuoteProductUpdate) => !it.price)) {
      Toast.Error("All products must have price.")
      return false;
    }
    return true;
  }, [canShowAddressField]);

  const onDiscard = useCallback(() => {
    if (!!quote?.id) {
      setFormData({...quote});
    }
  }, [quote, setFormData]);

  const saveQuote = useCallback(async (newStatus: QuoteStatus) => {
    const quoteToCreateOrUpdate: any = deepCopyOf({...formData, status: newStatus});
    if (quoteToCreateOrUpdate.expectedDeliveryDate)
      quoteToCreateOrUpdate.expectedDeliveryDate = quoteToCreateOrUpdate.expectedDeliveryDate.substring(0, 10); //only date

    quoteToCreateOrUpdate.products?.forEach((product: CreateQuoteProductRequest | QuoteProductUpdate) => {
      if (!product.price && product['priceCalculation'])
        product.price = Number(product['priceCalculation'].toFixed(2));
    });

    if (isInternalUser && !isQuoteValid(quoteToCreateOrUpdate, newStatus === QuoteStatus.DRAFT)) {
      Toast.Error("Please fill in all required fields");
      if (viewMode) {
        history.push(`${quotesRootPath}${quote.id}/edit`);
      }
      throw Error("Invalid Quote Form");
    }

    try {
      setLoading(true);
      const apiFunc = isNew ? createQuote : updateQuote;
      const quote = await dispatch(apiFunc(quoteToCreateOrUpdate));
      invalidateChats(mutate)

      if (isNew) {
        setFormData({} as QuoteView);
      }

      if (isNew || isEdit) {
        history.replace(`${quotesRootPath}${quote.id}`);
      }

    } finally {
      setLoading(false);
    }
  }, [formData, dispatch, isNew, isEdit, viewMode, history, isQuoteValid, quote?.id, isInternalUser, setFormData]);

  const onSave = useCallback(async () => {
    await saveQuote(
      isDraft ? status : (status === QuoteStatus.SENT ? status : QuoteStatus.INTERESTED)
    );

    if (isNew)
      trackEvent("Quote Created")
    else if(status === QuoteStatus.SENT)
      trackEvent("Quote Sent");
    else if(status === QuoteStatus.INTERESTED)
      trackEvent("Quote Marked Interested");
    else
      trackEvent("Quote Updated");
  }, [saveQuote, status, isDraft]);

  const onCopyQuote = useCallback(async () => {
    trackEvent("Quote Copied");
    history.replace(`${quotesRootPath}new`);
    setFormData(formData => ({
      ...formData,
      status: QuoteStatus.DRAFT,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      expectedDeliveryDate: undefined,
      validityDate: generateTodayPlusSevenDaysDateTimeInISO(),
      createdByUserAccountId: currentUser.id,
      updatedByUserAccountId: currentUser.id,
      salesOrderIds: [],
      attachments: [],
      unreadMessagesCount: 0,
      products: formData.products.map((prod => ({
        ...prod,
        calculatedPrice: undefined,
      })))
    }));
  }, []);

  const onRFOCreate = useCallback(() => {
    trackEvent("RFO Created from Quote");
    history.push(`${requestedOffersRootPath}new?quoteId=${quoteId}`);
  }, [history, quoteId]);

  const onShowMatchingTable = useCallback(async () => {
    try {
      if (quote.requestForQuoteId) {
        history.push(`${requestsRootPath}${quote.requestForQuoteId}/matching`);
      } else {
        const params = {
          ...quote,
          quotes: [],
          offers: [],
          status: RequestForQuoteStatus.DRAFT_AM,
          customerContactUserAccountIds: quote.customerContacts,
        };
        const createdRequest = await dispatch(createRequestForQuote(params));
        await dispatch(updateQuote({...quote, requestForQuoteId: createdRequest.id}));
        history.push(`${requestsRootPath}${createdRequest.id}/matching`);
      }
    } catch {
    }
  }, [quote, dispatch, history]);

  const onFormDataChange = useCallback((updatedData) => {
    setFormData({...formData, ...updatedData});
  }, [formData, setFormData]);

  const onFieldChange = useCallback(({target}) => {
    if (target.id === 'customerCompanyId') {
      onFormDataChange({
        [target.id]: target.value,
        shipToLocationId: null,
        customerContacts: [],
      });

    } else if (target.id === 'shipToLocationId') {
      const shipTo = locations?.[target.value];
      onFormDataChange({
        [target.id]: target.value,
        customerContacts: [],
        incoterm: shipTo?.incoterm || '',
        accountManagerUserId: shipTo?.accountManagerUserId || null,
        incotermPortIdDestination: shipTo?.portId || null,
      });

    } else if (target.id === 'shipFromLocationId') {
      const shipFrom = locations?.[target.value];
      onFormDataChange({
        [target.id]: target.value,
        portOfLoadingId: shipFrom.portId,
      });
    } else if (target.id === 'validityDate') {
      if (target.value) {
        // set validityDate to 23:59:59
        const dateTimeEndOfTheDay = getDateTimeEndOfTheDayInISO(target.value);
        onFormDataChange({[target.id]: dateTimeEndOfTheDay});
      }

    } else if (target.id === 'incoterm') {
      const dataToUpdate = {[target.id]: target.value} as any;
      if (isComboIncoterm(target.value) !== isComboIncotermSelected) {
        dataToUpdate.incotermPortIdDestination = '';
      }
      onFormDataChange(dataToUpdate);

    } else if (target.id === 'requestForQuoteId') {
      const request = requestsReduxState[target.value];
      if (request && isDraft) {
        dispatch(getRequestForQuoteAttachments(target.value)).then(onAttachmentListChange);
        onFormDataChange({
          [target.id]: target.value,
          incoterm: request.incoterm,
          incotermPortIdDestination: request.incotermPortIdDestination,
          incotermAddressDestination: request.incotermAddressDestination,
          validityDate: request.validityDate,
          expectedDeliveryDate: request.expectedDeliveryDate,
          externalComment: request.externalComment,
          internalComment: request.internalComment,
          currency: request.currency,
          customerCompanyId: request.customerCompanyId,
          shipToLocationId: request.shipToLocationId,
          products: request.products
        });
      } else {
        onFormDataChange({[target.id]: target.value,});
      }

    } else if (target.id === 'offerId') {
      const offer = offersReduxState[target.value];
      if (offer && isDraft) {
        dispatch(getOfferAttachments(target.value)).then(onAttachmentListChange);
        onFormDataChange({
          [target.id]: target.value,
          incoterm: offer.incoterm,
          incotermPortIdDestination: offer.incotermPortIdDestination,
          incotermAddressDestination: offer.incotermAddressDestination,
          validityDate: offer.validityDate,
          externalComment: offer.externalComment,
          internalComment: offer.internalComment,
          currency: offer.currency,
          vendorCompanyId: offer.vendorCompanyId,
          shipFromLocationId: offer.shipFromLocationId,
          products: offer.products
        });
      } else {
        onFormDataChange({[target.id]: target.value,});
      }

    } else {
      onFormDataChange({[target.id]: target.value});
    }
  }, [dispatch, onFormDataChange, locations, offersReduxState, requestsReduxState, isDraft,
    onAttachmentListChange, isComboIncotermSelected
  ]);

  const onProductAdd = useCallback((product, isMyProduct) => {
    const productId = isMyProduct ? product.productId : product.id;
    const lengthIds = isMyProduct ? product.lengthIds : [];

    const newProduct = {
      id: -(new Date().getTime()),
      productId,
      quoteId,
      productCode: product.productCode,
      productGroupId: product.productGroupId,
      productDescription: product.description,
      productType: product.productType,
      productActThick: product.msrActThick,
      productActWidth: product.msrActWidth,
      productActLength: product.msrActLength,
      uom: isMyProduct ? product.uom : product.standardUnitOfMeasure,
      lengthIds,
      quantity: product.productType === ProductType.FINANCE ? 0 : 1,
      price: product.price,
      currency: product.currency,
      unitsContainer: product.unitsContainer,
      active: true,
    };
    onFormDataChange({products: [...products, newProduct]});
  }, [onFormDataChange, products, quoteId]);

  const onProductFieldChange = useCallback((id, event) => {
    const {target} = event;
    let { id: field, value } = target;
    if (target.id == "lengthIds" && !Array.isArray(target.value)) {
      value = [value];
    }

    const productToUpdate = products.find(product => product.id === id);
    let updatedProduct = {...productToUpdate, [field]: value};
    if (
        target.id == "uom" &&
        value == "LF" &&
        productToUpdate.lengthIds.length > 1
    ) {
      updatedProduct = {
        ...updatedProduct,
        lengthIds: [productToUpdate.lengthIds[0]],
      };
    }

    const updatedProducts = products.map(product => product.id === id ? updatedProduct : product);
    onFormDataChange({products: updatedProducts});

  }, [onFormDataChange, products]);

  const onRemoveProduct = useCallback((id) => {
    const updatedProducts = products.filter(product => product.id !== id);
    onFormDataChange({products: updatedProducts});
  }, [onFormDataChange, products]);

  const todayDate = new Date();

  const incotermAddressField = incotermAddressDestination;
  const port = usePort(incotermPortIdDestination);

  const customerContactsList = useUserNames(customerContacts);

  const onEdit = useCallback(() => {
    history.replace(`${quotesRootPath}${quoteId}/edit`);
  }, [quoteId, history]);

  const hasOfferReference = !!offerId;

  const attachmentsForGallery = useMemo(() => findImageAndPDFAttachments(attachments), [attachments]);

  return (
    <div className="scroll-view">
      {pageHeader}
      {!viewMode && <UnsavedChangesPrompt hasChanges={hasChanges && !isNew && !loading} {...{onSave, onDiscard, onBack}} />}

      {requiredFieldsWarningMessage && <WarningBanner>
        {requiredFieldsWarningMessage}
      </WarningBanner>
      }

      {!isInternalUser && <QuoteDetailsManagers {...{showChat, toggleShowChat}} />}
      {!isInternalUser && status === QuoteStatus.SENT && <QuoteFeedbackPanel {...{quoteId, onBack}} />}

      {isExpired && <WarningBanner>This Quote is expired.</WarningBanner>}
      <div className={'flex-row flex-wrap align-start color-text-2 bg-black-12 rounded-xxs text-left'}>
        <div className={'flex flex-col p-lg gap-lg w-min-8xl'}>
          <FieldWithLabel label={'title.status'}>
            <QuoteStatusLabel {...{status}} />
          </FieldWithLabel>
          {isInternalUser &&
            <>
              {viewMode
                ? <FieldWithLabel label='title.customer'><CompanyView id={customerCompanyId}/></FieldWithLabel>
                : <CustomerSelect id={'customerCompanyId'} required
                                  selectRef={customerRef}
                                  label={localizedString('title.customer')}
                                  value={customerCompanyId}
                                  onChange={onFieldChange}
                />}

              {viewMode
                ? <FieldWithLabel label='title.ship.to'><LocationName id={shipToLocationId}/></FieldWithLabel>
                : <LocationSelect id="shipToLocationId" label={'Ship-To'} required
                                  selectRef={shipToRef}
                                  disabled={!customerCompanyId}
                                  companyId={customerCompanyId}
                                  value={shipToLocationId}
                                  onChange={onFieldChange}
                                  showCustomerCodes
                />}

              {viewMode
                ? <FieldWithLabel label='title.contact.person.customer'>{customerContactsList || '-'}</FieldWithLabel>
                : <UserByLocationSelect
                  id="customerContacts" label={'Contact Person'}
                                        disabled={!shipToLocationId}
                                        locationId={shipToLocationId}
                                        value={customerContacts}
                                        onChange={onFieldChange}
                                        multiple
                />
              }
            </>
          }
          {viewMode &&
            <>
              <FieldWithLabel label={'title.incoterm.destination'}>
                <IncotermDestinationLabel
                  incoterm={incoterm}
                  address={incotermAddressField}
                  portName={port?.name}
                />
              </FieldWithLabel>

              <FieldWithLabel label='title.currency'>{currency}</FieldWithLabel>

              <FieldWithLabel label={'title.expected.delivery'}>
                {!!expectedDeliveryDate ? dayjs(expectedDeliveryDate).format('DD MMM YYYY') : '-'}
              </FieldWithLabel>

              {isInternalUser && <FieldWithLabel label="title.validity.date">
                {!!validityDate ? dayjs(validityDate).format('DD MMM YYYY') : '-'}
              </FieldWithLabel>}
            </>
          }

          {!viewMode &&
            <>
              <div className={'flex-row align-center'}>
                <IncotermSelect id={'incoterm'} className={'flex'} required
                                label={localizedString('title.incoterm')}
                                value={incoterm}
                                onChange={onFieldChange}
                                selectRef={incotermRef}
                />
              </div>

              {canShowAddressField &&
                <TextField id={'incotermAddressDestination'} value={incotermAddressDestination}
                           label={localizedString('title.address')}
                           onChange={onFieldChange}
                           ref={incotermAddressRef}
                           disabled={isComboIncotermSelected}
                           required
                />
              }
              {canShowPortField &&
                <PortSelect id={'incotermPortIdDestination'} required
                            label={localizedString('title.port.of.discharge')}
                            value={incotermPortIdDestination}
                            selectRef={incotermPortRef}
                            onChange={onFieldChange}
                            byType={isComboIncotermSelected ? destinationNonPortTypes : destinationPortTypes}
                />
              }

              <div className={'flex-row align-center ValueField'}>
                <Select id={'currency'} className={'flex'} required
                        label={localizedString('title.currency')}
                        value={currency}
                        onChange={onFieldChange}
                        data={currencyArray}
                        selectRef={currencyRef}
                />
              </div>
              <FieldWithLabel label="title.expected.delivery" dataCy="ExpectedDeliveryDate">
                <DatePicker id="expectedDeliveryDate" required
                            mindate={todayDate}
                            date={expectedDeliveryDate}
                            onChange={onFieldChange}
                            inputRef={expectedDeliveryRef}
                            data-cy={"ExpectedDelivery"}
                            className={"w-full"}
                />
              </FieldWithLabel>
              <FieldWithLabel label="title.validity.date" dataCy="ValidityDate">
                <DatePicker id="validityDate"
                            mindate={todayDate}
                            date={validityDate}
                            onChange={onFieldChange}
                            data-cy={"ValidityDate"}
                />
              </FieldWithLabel>
            </>
          }
        </div>

        <div className={'flex flex-col p-lg gap-lg w-min-8xl'}>
          <div className={'flex-row align-center flex-wrap'}>
            <FieldWithLabel label='title.date.creation'>
              <div className="flex-row gap-sm">
                {dayjs(dateCreated).format('DD MMM YYYY')}
                <UserName id={createdByUserAccountId} className={'flex text-truncate'}/>
              </div>
            </FieldWithLabel>
            <FieldWithLabel label='title.date.last.updated'>
              <div className="flex-row gap-sm">
                {dayjs(dateUpdated).format('DD MMM YYYY')}
                <UserName id={updatedByUserAccountId} className={'flex text-truncate'}/>
              </div>
            </FieldWithLabel>
          </div>
          {isInternalUser &&
            <>
              {viewMode
                ? <FieldWithLabel label="title.reference">
                  <QuoteReference {...{requestForQuoteId, offerId, salesOrderIds}} />
                </FieldWithLabel>
                : <>
                  <RequestSelect id={'requestForQuoteId'}
                                 label={localizedString('title.request.reference')}
                                 value={requestForQuoteId}
                                 onChange={onFieldChange}
                                 includeValue={requestForQuoteId}
                                 showForReference
                  />
                  <OfferSelect id={'offerId'}
                               label={localizedString('title.offer.reference')}
                               value={offerId}
                               onChange={onFieldChange}
                               includeValue={offerId}
                               showForQuoteReference
                  />
                </>
              }
            </>
          }

          {/*{!readOnly &&*/}
          {/*  <Button view={'secondary'}><LocalizedMessage id={'title.request.to.buyer'} /></Button>*/}
          {/*}*/}

          {isInternalUser &&
            <>
              {viewMode
                ? <FieldWithLabel label="title.account.manager"><UserName id={accountManagerUserId}/></FieldWithLabel>
                : <AccountManagerSelect id={'accountManagerUserId'} required
                                        label={localizedString('title.account.manager')}
                                        value={accountManagerUserId}
                                        onChange={onFieldChange}
                                        selectRef={accountManagerRef}
                />
              }

              {viewMode
                ? <FieldWithLabel label="title.buyer"><UserName id={buyerUserId}/></FieldWithLabel>
                : <UserSelect id={'buyerUserId'} label={localizedString('title.buyer')}
                              value={buyerUserId}
                              onChange={onFieldChange}
                              byPermissions={buyerSelectByPermissions}
                />
              }

              {viewMode
                ? <FieldWithLabel label="title.vendor"><CompanyView id={vendorCompanyId}/></FieldWithLabel>
                : <VendorSelect id={'vendorCompanyId'} label={localizedString('title.vendor')}
                                value={vendorCompanyId}
                                onChange={onFieldChange}
                />
              }

              {viewMode
                ? <FieldWithLabel label="title.ship.from"><LocationName id={shipFromLocationId}/></FieldWithLabel>
                : <LocationSelect id="shipFromLocationId" label={'Ship-From'}
                                  companyId={vendorCompanyId}
                                  value={shipFromLocationId}
                                  onChange={onFieldChange}
                                  showCustomerCodes
                />
              }

              {viewMode
                ? <FieldWithLabel label="title.port.of.loading">
                    <PortView id={portOfLoadingId} />
                  </FieldWithLabel>
                : <PortSelect
                    id={'portOfLoadingId'}
                    label={localizedString('title.port.of.loading')}
                    value={portOfLoadingId}
                    selectRef={incotermPortRef}
                    onChange={onFieldChange}
                    byType={isComboIncotermSelected ? destinationNonPortTypes : destinationPortTypes}
                  />
              }
            </>
          }

          {viewMode &&
            <>
              <div className="flex-col">
                <div className={'text-sm color-text-2 flex mb-xxs'}>
                  {isInternalUser ? 'External Comment (ATTENTION: VISIBLE TO CUSTOMER):' : 'Comment'}
                </div>
                <div className={'value text-multiline'} data-cy={"ExternalComment"}>{externalComment || '-'}</div>
              </div>

              {isInternalUser &&
                <div className="flex-col">
                  <div className={'text-sm color-text-2 flex mb-xxs'}>{'Internal Comment (ATTENTION: VISIBLE TO UFP):'}</div>
                  <div className={'value text-multiline'}>{internalComment || '-'}</div>
                </div>
              }
            </>
          }

          {!viewMode &&
            <>
              <TextField id="externalComment" label={'External Comment (ATTENTION: VISIBLE TO CUSTOMER):'}
                         value={externalComment}
                         onChange={onFieldChange}
                         multiline
                         rows={5}
              />

              <TextField id="internalComment" label={'Internal Comment (ATTENTION: VISIBLE TO UFP):'}
                         value={internalComment}
                         onChange={onFieldChange}
                         multiline
                         rows={5}
              />
            </>
          }
        </div>

        <div className={'flex flex-col p-lg gap-lg w-min-8xl'}>
          <ImagesList
            images={attachments}
            onChange={onAttachmentListChange}
            readOnly={viewMode}
            onClick={(e: any, index: number) => imageGallery.current.showGallery(attachmentsForGallery, e.target, index)}
          />
        </div>
      </div>

      <Card className="mt-lg">
          {/*@ts-ignore */}
          <QuoteDetailsSummarySectionTable
            quote={formData}
            currency={currency}
            products={products}
            shipToLocationId={shipToLocationId}
            incotermPortIdDestination={incotermPortIdDestination}
            onProductFieldChange={onProductFieldChange}
            onRemoveProduct={onRemoveProduct}
            readOnly={viewMode}
          />
      </Card>

      {!viewMode && (
        <Card className="mt-lg">
          <Tabs defaultValue="my">
            <div className="flex-row gap-lg ml-md align-center mt-md sticky top-0">
              <div className="text-xl">
                <LC id="title.add.products" />
              </div>
              <TabsList>
                <TabsTrigger value="my">
                  <LC id="title.ship.to.products" />
                </TabsTrigger>
                <TabsTrigger value="vendor">
                  <LC id="title.vendor.products" />
                </TabsTrigger>
                <TabsTrigger value="all">
                  <LC id="title.all.products" />
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="my">
              <ProductsList
                onProductAdd={onProductAdd}
                companyId={customerCompanyId}
                locationId={shipToLocationId}
                canEdit={!viewMode}
                addProductTitle="title.add"
                type="my_products"
              />
            </TabsContent>
            <TabsContent value="vendor">
              <ProductsList
                onProductAdd={onProductAdd}
                companyId={vendorCompanyId}
                canEdit={!viewMode}
                addProductTitle="title.add"
                type="my_products"
              />
            </TabsContent>
            <TabsContent value="all">
              <ProductsList
                onProductAdd={onProductAdd}
                canEdit={!viewMode}
                addProductTitle="title.add"
                type="all"
              />
            </TabsContent>
          </Tabs>
        </Card>
      )}

      <ProductImagesGallery ref={imageGallery}/>

      <QuoteBottomButtons
        onBack={onBack}
        onSave={onSave}
        quoteId={quoteId}
        onEdit={onEdit}
        onRFOCreate={onRFOCreate}
        onShowMatchingTable={onShowMatchingTable}
        hasChanges={hasChanges}
        loading={loading}
        hasOfferReference={hasOfferReference}
        status={status}
        customerCompanyId={customerCompanyId}
        saveQuote={saveQuote}
        copyQuote={onCopyQuote}
        isVendorDomestic={isVendorDomestic}
      />
    </div>
  );
};

export default QuoteDetailsGeneralTab;
