import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import {FC, ReactNode} from "react";
import { LC, LocalizedMessageKey } from "~/Locales";
import {TbSquareCheckFilled, TbSquare, TbSquareMinusFilled} from "react-icons/tb";
import { Case, Match, Show } from "~/Components/UI";
import { cn } from "~/Utils";
import { FormReturn } from "~/Hooks";

export interface CheckboxProps {
  id?: string;
  label?: LocalizedMessageKey;
  children?: ReactNode;
  value: boolean | "indeterminate";
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const onChange = (value: boolean) => {
    if(!props.disabled)
      props.onChange(value === true);
  }

  return (
    <div className="flex-row align-center gap-xxs">
      <CheckboxPrimitive.Root
        id={props.id}
        checked={props.value}
        onCheckedChange={onChange}
        className={cn(
          props.disabled ? "cursor-disabled" : "cursor-pointer",
          "flex-row align-center",
          "bg-none border-none p-0 m-0",
          "rounded-xxs focus-outline-2 focus-outline-gray-4",
        )}
      >
        <Match on={props.value}>
          <Case v={true}>
            <TbSquareCheckFilled
              size={24}
              className={cn(
                "m-0",
                props.disabled ? "color-blue-9" : "color-blue-1"
              )}
            />
          </Case>
          <Case v={false}>
            <TbSquare
              size={24}
              className={cn(
                "m-0",
                props.disabled ? "color-black-9" : "color-black-4"
              )}
            />
          </Case>
          <Case v={"indeterminate"}>
            <TbSquareMinusFilled
              size={24}
              className={cn(
                "m-0",
                props.disabled ? "color-blue-9" : "color-blue-1"
              )}
            />
          </Case>
        </Match>
      </CheckboxPrimitive.Root>
      <Show when={props.label || props.children}>
        <label
          htmlFor={props.id}
          className={cn(
            props.disabled ? "color-black-7 cursor-disabled" : "cursor-pointer"
          )}
        >
          {props.label && <LC id={props.label} />}
          {props.children}
        </label>
      </Show>
    </div>
  );
};

export interface FormCheckboxProps<T> extends Omit<CheckboxProps, "id" | "value" | "onChange"> {
  id: keyof T & string;
  formData: FormReturn<T>;
}

export const FormCheckbox = <T = any,>(props: FormCheckboxProps<T>) => {
  return (
    <Checkbox
      {...props}
      value={props.formData.data[props.id] as (boolean | "indeterminate")}
      onChange={(value) => props.formData.onChecked(props.id, value)}
    />
  );
};
