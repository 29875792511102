import { ChatType, ChatUnreadView } from "~/API";
import { FC, Fragment, useMemo } from "react";
import { LocalizedMessage } from "~/Locales";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import {useCurrentUser, useIsInternalCustomer} from "~/Reducers/User";
import { UserName } from "~/Components/Users/UserName";
import {Button, Case, For, Match, Show} from "~/Components/UI";
import {TbExternalLink} from "react-icons/tb";
import {
  offersRootPath,
  ordersPathPurchase,
  ordersPathSales,
  ordersRootPath, quotesRootPath,
  requestedOffersRootPath
} from "~/Services/Routes";
import {useOffer} from "~/Reducers/Offers";
import {isRFO} from "~/Pages/Offers/Components/OffersHelper";

const ChatTitle: FC<{ chat: ChatUnreadView, showLink?: boolean }> = (props) => (
  <Match on={props.chat.type}>
    <Case v={ChatType.DIRECT}>
      <DirectChatRef chat={props.chat} />
    </Case>
    <Case v={ChatType.SALES_ORDER}>
      <SalesOrderRef refId={props.chat.refs.buyerDeliveries[0]} showLink={props.showLink} />
    </Case>
    <Case v={ChatType.PURCHASE_ORDER}>
      <PurchaseOrderRef refId={props.chat.refs.purchaseOrders[0]} showLink={props.showLink} />
    </Case>
    <Case v={ChatType.OFFER}>
      <OffersRef refId={props.chat.refs.offers[0]} showLink={props.showLink} />
    </Case>
    <Case v={ChatType.QUOTE}>
      <QuotesRef title={props.chat?.title} refId={props.chat.refs.quotes[0]} showLink={props.showLink} />
    </Case>
    <Case v={ChatType.REQUEST_FOR_QUOTE}>
      <Case default>{props.chat?.title}</Case>
    </Case>
    <Case default>{props.chat?.title}</Case>
  </Match>
);

export default ChatTitle;

const DirectChatRef: FC<{ chat: ChatUnreadView }> = (props) => {
  const currentUser = useCurrentUser();

  return useMemo(() => {
    const filtered =
      props.chat.participants?.filter((it) => it !== currentUser.id) || [];

    if (filtered.length !== 0) {
      return (
        <div className="text-truncate">
          <For each={filtered}>
            {(userId, index) => (
              <Fragment key={userId}>
                <UserName id={userId} />
                {index < filtered.length - 1 && <>&nbsp;&&nbsp;</>}
              </Fragment>
            )}
          </For>
        </div>
      );
    }
    return <UserName id={currentUser.id} />;
  }, [props.chat, currentUser]);
};

const SalesOrderRef: FC<{
  refId: number;
  showLink?: boolean;
}> = (props) => {
  const order = useSalesOrder(props.refId);
  const linkClicked = () => window.open(ordersRootPath + ordersPathSales + "/" + props.refId, "_blank");
  return (
    <div className="flex-row gap-xs">
      <LocalizedMessage id={"title.delivery.with.id"}>
        {order.data?.number}
      </LocalizedMessage>
      <Show when={props.showLink}>
        <Button
          color="gray"
          leftIcon={<TbExternalLink size={16} />}
          onClicked={linkClicked}
        />
      </Show>
    </div>
  );
};

const PurchaseOrderRef: FC<{
  refId: number;
  showLink?: boolean;
}> = (props) => {
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(props.refId, internalCustomer);
  const linkClicked = () => window.open(ordersRootPath + ordersPathPurchase + "/" + props.refId, "_blank");
  return (
    <div className="flex-row gap-xs">
      <LocalizedMessage id="title.po.with.id">
        {order.data?.number}
      </LocalizedMessage>
      <Show when={props.showLink}>
        <Button
          color="gray"
          leftIcon={<TbExternalLink size={16} />}
          onClicked={linkClicked}
        />
      </Show>
    </div>
  );
};

const OffersRef: FC<{
  refId: number;
  showLink?: boolean;
}> = (props) => {
  const offer = useOffer(props.refId);
  const isrfo = isRFO(offer?.status);
  const rootPath = isrfo ? requestedOffersRootPath : offersRootPath;
  const linkClicked = () => window.open(rootPath + props.refId, "_blank");

  return (
    <div className="flex-row gap-xs">
      <LocalizedMessage
        id={isrfo ? "title.request.offer.with.id" : "title.offer.with.id"}
      >
        {props.refId}
      </LocalizedMessage>
      <Show when={props.showLink}>
        <Button
          color="gray"
          leftIcon={<TbExternalLink size={16} />}
          onClicked={linkClicked}
        />
      </Show>
    </div>
  );
};

const QuotesRef: FC<{
  title: string;
  refId: number;
  showLink?: boolean;
}> = (props) => {
  const linkClicked = () => window.open(quotesRootPath + props.refId, "_blank");

  return (
    <div className="flex-row gap-xs">
      {props.title}
      <Show when={props.showLink}>
        <Button
          color="gray"
          leftIcon={<TbExternalLink size={16} />}
          onClicked={linkClicked}
        />
      </Show>
    </div>
  );
};
