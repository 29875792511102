import React, { FC } from "react";
import API, { Translation } from "~/API";
import { ToggleProps, useFormData, useMutateHook } from "~/Hooks";
import { Button, FormCombobox, FormInput, FormSwitch } from "~/Components/UI";
import {trackEvent} from "~/Services/Tracking";

const useAddTranslation = () =>
  useMutateHook<Translation, void, Translation>("api/translations", (args) =>
    API.createTranslation({}, args),
  );

export const AddTranslationForm: FC<{
  contexts: string[];
  open: ToggleProps;
  refreshData: () => void;
}> = (props) => {
  const addForm = useFormData<Partial<Translation>>({
    suggest: true,
    context: "",
    en: "",
    de: "",
  });
  const add = useAddTranslation();

  const onAddClicked = () =>
    add.call(addForm.data as Translation).then(() => {
      props.open.toggleFalse();
      props.refreshData();
      trackEvent("Dictionary Value Created");
    });

  return (
    <div className="desktop-w-9xl flex-col gap-md">
      <FormSwitch label="title.active" formData={addForm} id="suggest" />
      <FormCombobox
        formData={addForm}
        id="context"
        data={props.contexts}
        label="title.property"
        getLabel={(d) => d}
        className="desktop-w-min-8xl"
        align="end"
      />
      <FormInput
        id="en"
        formData={addForm}
        label="title.value"
        placeholder="title.value"
        className="desktop-w-min-9xl"
      />

      <Button
        label="title.add.value"
        loading={add.isMutating}
        onClicked={onAddClicked}
      />
    </div>
  );
};
