import {orNoop} from "./Tools";
import * as Sentry from '@sentry/react';

// eslint-disable-next-line
var global = global || window;
global && (global.FormData = global.originalFormData ? global.originalFormData : global.FormData);

const CONTENT_TYPE_APPLICATION_JSON = 'application/json';

const toKeyValue = (key, value) => encodeURIComponent(key) + '=' + encodeURIComponent(value !== null && typeof value === 'object' ? JSON.stringify(value) : value);

export const toRequestParams = (params) => (params && Object.keys(params).map(key => {
    const value = params[key];
    if (Array.isArray(value))
        return value.map(it => toKeyValue(key, it)).join('&')
    else
        return toKeyValue(key, value);
}).join('&')) || '';


export type Method = 'GET' | 'DELETE' | 'POST' | 'PUT'

type ErrorHandler = (e: FetchError, status: number) => void;

export class FetchOptions {
    method?: Method = 'GET'
    headers?: ({ [name: string]: string })
    params?: ({ [name: string]: any })
    body?: ({ [name: string]: any })
    multipart?: boolean = false
    async?: boolean = true
    withCredentials?: boolean = true
    timeout?: number
    token?: string
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text'
    onTimeout?: ((ev: ProgressEvent) => any)
    onSuccess?: ((t: any) => void)
    onError?: (ErrorHandler)
    onProgress?: ((ev: ProgressEvent) => any)
    provideCancel?: ((cancelFunction: () => void) => void)
}

const DEFAULT_OPTIONS = new FetchOptions()

export const fetch = <T>(url, options: FetchOptions = DEFAULT_OPTIONS) => {
    const method = options.method || 'GET'
    const params = options.params || {};
    if (method === 'GET' || method === 'DELETE') {
        const serializedData = toRequestParams(params);
        if (serializedData)
            url = url + "?" + serializedData;
    }

    const headers = {
        'Accept': CONTENT_TYPE_APPLICATION_JSON,
        ...(options.headers || {})
    };
    if (options.token)
        headers['token'] = options.token;

    let body: FormData | string

    if (method === 'POST' || method === 'PUT') {
        if (options.multipart) {
            let formData;
            if (options.body instanceof FormData) {
                formData = options.body;
            } else {
                formData = new FormData();
                Object.keys(options.body).forEach(name => {
                    let value = options.body[name];
                    if (Array.isArray(value)) {
                        value.forEach(it => formData.append(name, it))
                    } else if (value != null)
                        formData.append(name, value);
                });
            }
            body = formData;
        } else {
            headers['Content-Type'] = CONTENT_TYPE_APPLICATION_JSON;
            body = JSON.stringify(options.body || {}, (key, value) => {
                if (value !== null)
                    return value
            });
        }
    }

    let makeRequest = (success, error: ErrorHandler) => {
        const request = new XMLHttpRequest();

        const data = body;
        if (method === 'GET' && data) {
            let params = toRequestParams(data);
            if (params)
                url += "?" + params;
        }


        if (!!(options.withCredentials === void 0 ? true : options.withCredentials))
            request.withCredentials = true;

        const async = !!(options.async === void 0 ? true : options.async);
        request.open(method, url, async);

        if (options.responseType)
            request.responseType = options.responseType

        Object.keys(headers).forEach(key =>
          request.setRequestHeader(key, headers[key])
        );
        const onError = orNoop(error);

        if (options.timeout && async) {
            request.timeout = options.timeout;
            request.ontimeout = (e) => {
                if (options.onTimeout)
                    options.onTimeout(e);
                else
                    onError(new FetchError('timeout', -1, null), -1);
            };
        }

        const onProgress = options.onProgress;
        if (onProgress) {
            request.upload.onprogress = e => {
                if (e.lengthComputable)
                    onProgress(e);
            };
            request.onprogress = onProgress;
        }

        request.onload = () => {
            const responseText = (!request.responseType || request.responseType === 'text' || request.responseType === 'json' || request.responseType === 'document') ? request.response : '';
            const status = request.status;
            if (status >= 200 && status < 400) {
                try {
                    if (request.getResponseHeader('Content-Type') === 'application/json') {
                        orNoop(success)(JSON.parse(responseText || "{}"));
                    } else {
                        orNoop(success)(request.response);
                    }
                } catch (e) {
                    const message = `Unexpected exception while processing response for ${method} ${url}, status: ${status}, response: '${responseText}', exception:`;
                    console.log(message, e)
                    onError(new FetchError(message, status, responseText), status)
                    Sentry.captureException(e);
                }
            } else {
                const message = `Not ok response for ${method} ${url}, status: ${status}, response: '${responseText}'`;
                onError(new FetchError(message, status, responseText), status);
                Sentry.captureException(message);
            }
        };

        request.onerror = ev => {
            console.error(ev, request.status)
            onError(new FetchError('error', -1, null), -1);
            Sentry.captureException(ev);
        };

        try {
            if (method === 'POST' || method === 'PUT') {
                if (typeof data === 'string' || data instanceof FormData)
                    request.send(data);
                else
                    request.send(toRequestParams(data));
            } else
                request.send();
        } catch (e) {
            console.error(e, request.status)
            onError(new FetchError(e.message, -1, null, e), -1);
            Sentry.captureException(e);
        }

        if (options.provideCancel) {
            options.provideCancel(() => request.abort());
        }
        return request
    };

    return new Promise<T>((resolve, reject) => {
        const success = data => {
            options.onSuccess && options.onSuccess(data)
            resolve(data);
        };
        const error = (e, status) => {
            if (options.onError)
                options.onError(e, status);
            reject(e);
        };
        makeRequest(success, error);
    });
};

export class FetchError extends Error {
    status: number;
    responseText?: string;

    constructor(message, status: number, responseText?: string, cause?: Error) {
        super(message);
        this.status = status;
        this.responseText = responseText;
        this.cause = cause;
    }
}

export const GET = (url, params, token, onSuccess, onError, timeout, isAsync) => fetch(url, {
    method: 'GET',
    params,
    token,
    onSuccess,
    onError,
    multipart: false,
    async: isAsync,
    timeout
});

export const POST_MULTIPART = <R>(url, body, params, token, timeout, isAsync, onProgress, provideCancel): Promise<R> => fetch(url, {
    method: 'POST',
    body,
    params,
    token,
    multipart: true,
    async: isAsync,
    timeout,
    onProgress,
    provideCancel
});