import API, { PurchaseOrderItemView, PurchaseOrderItemPlantView } from "~/API";
import { userIsInternalCustomer } from './User';
import { useGetEntity } from "./Utils/Entity";
import {AppDispatch, RootState} from "~/StoreTypes";


const getSumOf = (items: (PurchaseOrderItemView | PurchaseOrderItemPlantView)[] = [], fieldName: string) => {
  let sum = 0;
  items.forEach(item => sum += Number(item?.[fieldName]));
  return sum;
};

const totalItemsPackages = (items: (PurchaseOrderItemView | PurchaseOrderItemPlantView)[]) => getSumOf(items, 'packages');

const purchaseOrderItemsSelectorCreator = (orderId: number) => (state: RootState) => state.purchaseOrdersItems[orderId];

export const usePurchaseOrderItems = (orderId: number, force = false) => useGetEntity(orderId, getPurchaseOrderItems, purchaseOrderItemsSelectorCreator, force);

export const usePurchaseOrderItemsTotalPackages = (orderId: number) => {
  const items = usePurchaseOrderItems(orderId);
  return totalItemsPackages(items);
};

const getPurchaseOrderItems = (orderId: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const isInternalCustomer = userIsInternalCustomer(getState().userReducer);
    const apiFunc = isInternalCustomer ? API.getPlantPurchaseOrderItems : API.getPurchaseOrderItems;
    const orderItems = await apiFunc({ id: orderId });
    dispatch({ type: 'ACTIONS_GET_PURCHASE_ORDER_ITEMS_SUCCESS', orderId, orderItems });
    return orderItems;
  } catch (error) { }
};

// Reducer
export interface PurchaseOrdersItemsState {
  [id: number]: PurchaseOrderItemView[] | PurchaseOrderItemPlantView[];
}

const INITIAL_STATE: PurchaseOrdersItemsState = {};

const reducer = (state: PurchaseOrdersItemsState, action: any): PurchaseOrdersItemsState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ACTIONS_GET_PURCHASE_ORDER_ITEMS_SUCCESS':
      return { ...state, [action.orderId]: action.orderItems };
    default:
      return state;
  }
};

export default reducer;
