import { Redirect, Route, Switch } from "react-router-dom";
import {
  chatsRootPath,
  chatsRootPathExternal,
  chatsRootPathInternal, videoCallPath,
} from "~/Services/Routes";
import { useIsInternalCustomer, useIsInternalUser } from "~/Reducers/User";
import { FC } from "react";
import { StaticPage } from "~/Components/Layout/Pages";
import { PageTab, PageTabContainer } from "~/Components/Nav/PageTabs";
import { ChatUnreadBadge } from "~/Components/Chats/ChatUnreadBadge";
import {Card, Show} from "~/Components/UI";
import { useChatsAllUnreadCount } from "~/Data/Chats";
import {ChatsPageLayout} from "~/Pages/Chats/Components/ChatsPageLayout";
import {VideoCall} from "~/Components/Old/VideoCall/VideoCall";

const ChatsPage: FC = () => {
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const unreadChats = useChatsAllUnreadCount();

  const showTabs = isInternalUser || isInternalCustomer;

  return (
    <StaticPage title="Chats">
      <Show when={showTabs}>
        <PageTabContainer title="title.chats" showNav>
          <PageTab
            route={chatsRootPathExternal}
            label={isInternalCustomer ? "title.quotes": "title.external"}
            rightIcon={
              <ChatUnreadBadge unread={unreadChats.data?.unreadCountExternal || 0} />
            }
          />
          <PageTab
            route={chatsRootPathInternal}
            label={isInternalCustomer ? "title.orders" : "title.internal"}
            rightIcon={
              <ChatUnreadBadge unread={unreadChats.data?.unreadCountInternal || 0} />
            }
          />
        </PageTabContainer>
      </Show>

      <Switch>
        <Redirect
          exact
          from={`${chatsRootPath}`}
          to={isInternalUser ? chatsRootPathInternal : chatsRootPathExternal}
        />

        <Route path={`*/:chatId/${videoCallPath}/:callId`}>
          <Card className="flex-col">
            <VideoCall />
          </Card>
        </Route>

        <Route path={chatsRootPathExternal}>
          <ChatsPageLayout internal={false} />
        </Route>
        <Route path={chatsRootPathInternal}>
          <ChatsPageLayout internal={true} />
        </Route>
      </Switch>
    </StaticPage>
  );
};

export default ChatsPage;
