import { FC } from "react";
import { OfferStatus } from "~/API";
import { cn } from "~/Utils";
import { useIsUFPAM, useIsUFPBuyer, useIsVendor } from "~/Reducers/User";
import {LC, LocalizedMessageKey} from "~/Locales";

export const offerStatusData: Record<OfferStatus, string> = {
  O_DRAFT: "bg-black-9 color-black-4",
  O_DRAFT_VENDOR: "bg-black-9 color-black-4",
  RFO_DRAFT: "bg-black-9 color-black-4",
  RFO_DRAFT_VENDOR: "bg-black-9 color-black-4",
  O_ARCHIVED: "bg-red-6 color-red-1",
  RFO_ARCHIVED: "bg-red-6 color-red-1",
  O_QUOTE_CLOSED: "bg-red-6 color-red-1",
  O_CLOSING: "bg-red-6 color-red-1",
  RFO_DECLINED: "bg-red-6 color-red-1",
  RFO_SENT: "bg-blue-8 color-blue-0",
  O_BUYER_REVIEW: "bg-blue-8 color-blue-0",
  O_SALES_REVIEW: "bg-blue-8 color-blue-0",
  O_VENDOR_CONFIRMED: "bg-pink-9 color-pink-1",
  O_QUOTED: "bg-purple-9 color-purple-1",
  O_IN_PROGRESS: "bg-orange-8 color-orange-0",
  O_ORDERED: "bg-green-8 color-green-0",
  O_DELIVERED: "bg-green-8 color-green-0",
};

export const OfferStatusBadge: FC<{ status: OfferStatus }> = (props) => {
  const classes = offerStatusData[props.status];
  const isUFPBuyer = useIsUFPBuyer();
  const isUFPSales = useIsUFPAM();
  const isVendor = useIsVendor();

  let role = "buyer";
  if (isUFPBuyer) role = "buyer";
  if (isUFPSales) role = "sales";
  if (isVendor) role = "vendor";

  return (
    <div className="flex-row">
      <div
        className={cn("py-xxs px-sm text-semibold text-sm rounded-sm text-truncate", classes)}
      >
        <LC id={`offer.status.${role}.${props.status}` as LocalizedMessageKey} />
      </div>
    </div>
  );
};
