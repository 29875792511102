import { FC, useMemo, useState } from "react";
import {
    MetricsCard,
    MetricsHeader,
    MetricStat,
    MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import { useFormData } from "~/Hooks";
import { PERIODS } from "~/Pages/Metrics/Data/MetricsData";
import { Button, FormSelect, Table } from "~/Components/UI";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import PriceView from "~/Components/Views/PriceView";
import { CompanyCombobox } from "~/Components/Companies/CompanyCombobox";
import {
    LocationType, NewProductSales,
    TimeSeriesCategoricalMetric,
} from "~/API";
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { TbArrowBackUp } from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import CompanyView from "~/Components/Views/CompanyView";
import {companiesRootPath, getPathForSO, locationsRootPath, productsRootPath} from "~/Services/Routes";
import {useCompanyProductsMetrics} from "~/Pages/Metrics/Data/CompanyProductsMetricsData";
import {LocationCombobox} from "~/Components/Companies/LocationCombobox";
import {ProductsComboBox} from "~/Components/Products/ProductsComboBox";
import LocationView from "~/Components/Locations/LocationView";
import {useNewProductSales} from "~/Data/Products/NewProductSales";
import {CompanyProductsComboBox} from "~/Components/Products/CompanyProductsComboBox";
import {LocationProductsCombobox} from "~/Components/Products/LocationProductsCombobox";
import ProductView from "~/Components/Views/ProductView";

interface Filters {
    period: PERIODS;
    dataKey: "value" | "count";
    company: number | undefined;
    location: number | undefined;
    product: number | undefined;
}

export const CompanyProductsMetricsCard: FC = () => {
    const filterForm = useFormData<Filters>({
        period: PERIODS.SIX_MONTHS,
        dataKey: "count",
        company: undefined,
        location: undefined,
        product: undefined
    });

    const metrics = useCompanyProductsMetrics({
        period: filterForm.data.period,
        companyId: filterForm.data.company,
        locationId: filterForm.data.location,
        productId: filterForm.data.product
    });

    const onDataKeyChange = (val: string) =>
        filterForm.onDataChange("dataKey", val);

    const totalPeriod = metrics.data?.reduce(
        (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.count, 0),
        0,
    );

    const totalValuePeriod = metrics.data?.reduce(
        (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.value, 0),
        0,
    );

    const [detailsIndex, setDetailsIndex] = useState<number | undefined>();
    const onBarClicked = (_: any, index: number) => setDetailsIndex(index);
    const onBack = () => setDetailsIndex(undefined);

    const useCompanyProducts = (filterForm.data.company && !filterForm.data.location)

    const useLocationProducts = (filterForm.data.location)

    const companyId = filterForm.data.company || 0;
    const locationId = filterForm.data.location || 0;


    return (
        <MetricsCard>
            {detailsIndex != undefined && (
                <SalesList
                    product={filterForm.data.product}
                    location={filterForm.data.location}
                    metrics={metrics.data[detailsIndex]}
                    onBack={onBack}
                    customer={filterForm.data.company}
                />
            )}
            {detailsIndex == undefined && (
                <>
                    <MetricsHeader title="New Products to an Existing Customer">
                        <CompanyCombobox
                            id="company"
                            formData={filterForm}
                            filters={{ query: "TYPE == 'BUYER' && ACTIVE == true" }}
                            placeholder="title.all.customers"
                            showClear
                            className="w-max-6xl"
                        />

                        <LocationCombobox
                            filters={{
                                ...filterForm.data.company ? { companyId: [filterForm.data.company] } : {},
                                type: LocationType.SHIP_TO
                            }}
                            id="location"
                            formData={filterForm}
                            placeholder="title.all.locations"
                            showClear/>

                        {!useCompanyProducts && !useLocationProducts && (
                            <ProductsComboBox
                                placeholder="title.all.products"
                                id="product"
                                formData={filterForm}
                                showClear/>
                        )}
                        {useCompanyProducts && (
                            <CompanyProductsComboBox id={"product"}
                                                     placeholder={"title.company.products"}
                                                     filters={{ companyId: companyId}}
                                                     formData={filterForm}
                                                     showClear
                            />
                        )}
                        {useLocationProducts && (
                            <LocationProductsCombobox id={"product"}
                                                      placeholder={"title.location.products"}
                                                      filters={{ locationId: locationId}}
                                                      formData={filterForm}
                                                      showClear
                            />
                        )}


                        <FormSelect
                            id="period"
                            formData={filterForm}
                            data={Object.values(PERIODS)}
                            getLabel={(id) => id}
                        />
                    </MetricsHeader>

                    <MetricStatContainer>
                        <MetricStat label={`Sales in the last ${filterForm.data.period}`} loading={metrics.isLoading}>
                            {totalPeriod}
                        </MetricStat>
                        <MetricStat label={`Total in the last ${filterForm.data.period}`} loading={metrics.isLoading}>
                            ${totalValuePeriod?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </MetricStat>
                    </MetricStatContainer>

                    <BarChart
                        dataset={metrics.data || [{ period: "", data: [] }]}
                        dataKey={filterForm.data.dataKey}
                        xAxisFormat={(date: string) =>
                            dayjs(date?.slice(0, 10)).format("MMM")
                        }
                        tooltipLabelFormat={(date: string) =>
                            dayjs(date?.slice(0, 10)).format("MMMM YYYY")
                        }
                        tooltipDataFormat={
                            filterForm.data.dataKey == "value"
                                ? (val: number) => <PriceView price={val} />
                                : undefined
                        }
                        width="100%"
                        height={vars.size["7xl"]}
                        loading={metrics.isLoading}
                        onbarClicked={onBarClicked}
                    />

                    <div className="flex-row align-center justify-end">
                        <Tabs
                            id="dataKey"
                            value={filterForm.data.dataKey}
                            onValueChange={onDataKeyChange}
                        >
                            <TabsList>
                                <TabsTrigger value="count">Count</TabsTrigger>
                                <TabsTrigger value="value">Value</TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </>
            )}
        </MetricsCard>
    );
};

const salesHelper = createColumnHelper<NewProductSales>();

const columns = [
    salesHelper.accessor("deliveryId", {
        header: "Order",
        cell: (data) => (
            <a
                href={`${getPathForSO(data.getValue())}`}
                target="_blank"
                rel="noreferrer noopener"
            >
                Go to order
            </a>
        ),
        size: 32,
    }),
    salesHelper.accessor("salesOrderNumber", {
        header: "Sales Order #",
        cell: (data) => (
            <>{data.getValue()}</>),
        size: 32,
    }),
    salesHelper.accessor("companyId", {
        header: "Customer",
        cell: (data) => ( <a
                href={`${companiesRootPath}${data.getValue()}`}
                target="_blank"
                rel="noreferrer noopener"
            > <CompanyView id={data.getValue()} />
            </a>
        ),
    }),
    salesHelper.accessor("locationId", {
        header: "Location",
        cell: (data) => ( <a
                href={`${locationsRootPath}${data.getValue()}`}
                target="_blank"
                rel="noreferrer noopener"
            > <LocationView id={data.getValue()} />
            </a>
        ),
    }),
    salesHelper.accessor("productId", {
        header: "Product",
        cell: (data) => ( <a
                href={`${productsRootPath}${data.getValue()}`}
                target="_blank"
                rel="noreferrer noopener"
            > <ProductView id={data.getValue()} />
            </a>
        ),
    })
];

const SalesList: FC<{
    onBack: () => void;
    customer: number | undefined;
    location: number | undefined;
    product: number | undefined;
    metrics: TimeSeriesCategoricalMetric;
}> = (props) => {
    const dateTo = useMemo(() => {
        dayjs.extend(utc)
        return dayjs.utc(props.metrics.period.slice(0,10))
            .endOf("month")
            .toISOString();
    }, [props.metrics.period]);

    const sales = useNewProductSales({
        from: props.metrics.period.slice(0, 10),
        to: dateTo.slice(0, 10),
        companyId: props.customer || ("" as any),
        shipToId: props.location || ("" as any),
        productId: props.product || ("" as any),
    });

    const table = useReactTable({
        data: sales.data?.flat() || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const onScrollToBottom = () => {
        sales.setSize(sales.size + 1);
    }

    return (
        <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
            <Button
                variant="text"
                color="gray"
                onClicked={props.onBack}
                leftIcon={<TbArrowBackUp size={16} />}
                label="title.back"
            />
            <div className="text-xl">
                New Product Sales{" "}
                {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
            </div>
            <LinearLoader loading={sales.isLoading} />
            <Table
                table={table}
                onScrolledBottom={onScrollToBottom}
                scrollBottomOffset={5}
                className="w-full"
            />
        </div>
    );
}