import { FC, useMemo, useState } from "react";
import {
    MetricsCard,
    MetricsHeader, MetricStat, MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import { UseRFQToQuotes } from "~/Pages/Metrics/Data/RFQToQuotesData";
import { useFormData } from "~/Hooks";
import { PERIODS } from "~/Pages/Metrics/Data/MetricsData";
import { Button, FormSelect, Table } from "~/Components/UI";
import dayjs from "dayjs";
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import PriceView from "~/Components/Views/PriceView";
import { CompanyCombobox } from "~/Components/Companies/CompanyCombobox";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import {
    GroupPermission,
    RequestForQuoteView,
    TimeSeriesCategoricalMetric,
} from "~/API";
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { UserName } from "~/Components/Users/UserName";
import { TbArrowBackUp } from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import CompanyView from "~/Components/Views/CompanyView";
import { useRequestsForQuotes } from "~/Data/Requests/RequestsForQuotes";
import {requestsRootPath} from "~/Services/Routes";

interface Filters {
    period: PERIODS;
    dataKey: "value" | "count";
    company: number | undefined;
    am: number | undefined;
}

export const RequestsToQuotesMetricsCard: FC = () => {
    const filterForm = useFormData<Filters>({
        period: PERIODS.SIX_MONTHS,
        dataKey: "count",
        company: undefined,
        am: undefined,
    });

    const metrics = UseRFQToQuotes(filterForm.data);

    const onDataKeyChange = (val: string) =>
        filterForm.onDataChange("dataKey", val);

    const totalPeriod = metrics.data?.reduce(
        (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.count, 0),
        0,
    );

    const totalValuePeriod = metrics.data?.reduce(
        (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.value, 0),
        0,
    );

    const [detailsIndex, setDetailsIndex] = useState<number | undefined>();
    const onBarClicked = (_: any, index: number) => setDetailsIndex(index);
    const onBack = () => setDetailsIndex(undefined);

    return (
        <MetricsCard>
            {detailsIndex != undefined && (
                <RequestsList
                    metrics={metrics.data[detailsIndex]}
                    onBack={onBack}
                    am={filterForm.data.am}
                    customer={filterForm.data.company}
                />
            )}
            {detailsIndex == undefined && (
                <>
                    <MetricsHeader title="Requests Turned into Quotes">
                        <UserByPermissionCombobox
                            id="am"
                            formData={filterForm}
                            permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
                            placeholder="title.all.ams"
                            className="w-max-7xl"
                            showClear
                        />
                        <CompanyCombobox
                            id="company"
                            formData={filterForm}
                            filters={{ query: "TYPE == 'BUYER' && ACTIVE == true" }}
                            placeholder="title.all.customers"
                            showClear
                            className="w-max-6xl"
                        />
                        <FormSelect
                            id="period"
                            formData={filterForm}
                            data={Object.values(PERIODS)}
                            getLabel={(id) => id}
                        />
                    </MetricsHeader>

                    <MetricStatContainer>
                        <MetricStat label={`Requests in the last ${filterForm.data.period}`} loading={metrics.isLoading}>
                            {totalPeriod}
                        </MetricStat>
                        <MetricStat label={`Total in the last ${filterForm.data.period}`} loading={metrics.isLoading}>
                            ${totalValuePeriod?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </MetricStat>
                    </MetricStatContainer>

                    <BarChart
                        dataset={metrics.data || [{ period: "", data: [] }]}
                        dataKey={filterForm.data.dataKey}
                        xAxisFormat={(date: string) =>
                            dayjs(date?.slice(0, 10)).format("MMM")
                        }
                        tooltipLabelFormat={(date: string) =>
                            dayjs(date?.slice(0, 10)).format("MMMM YYYY")
                        }
                        tooltipDataFormat={
                            filterForm.data.dataKey == "value"
                                ? (val: number) => <PriceView price={val} />
                                : undefined
                        }
                        width="100%"
                        height={vars.size["7xl"]}
                        loading={metrics.isLoading}
                        onbarClicked={onBarClicked}
                    />

                    <div className="flex-row align-center justify-end">
                        <Tabs
                            id="dataKey"
                            value={filterForm.data.dataKey}
                            onValueChange={onDataKeyChange}
                        >
                            <TabsList>
                                <TabsTrigger value="count">Count</TabsTrigger>
                                <TabsTrigger value="value">Value</TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </>
            )}
        </MetricsCard>
    );
};

const requestsHelper = createColumnHelper<RequestForQuoteView>();

const columns = [
    requestsHelper.accessor("id", {
        header: "ID",
        cell: (data) => (
            <a
                href={`${requestsRootPath}${data.getValue()}`}
                target="_blank"
                rel="noreferrer noopener"
            >
                RQ-{data.getValue()}
            </a>
        ),
        size: 32,
    }),
    requestsHelper.accessor("customerCompanyId", {
        header: "Customer",
        cell: (data) => <CompanyView id={data.getValue()} />,
    }),
    requestsHelper.accessor("accountManagerUserId", {
        header: "AM",
        cell: (data) => (
            <div className="text-no-wrap">
                <UserName id={data.getValue()} />
            </div>
        ),
    }),
];

const RequestsList: FC<{
    onBack: () => void;
    am: number | undefined;
    customer: number | undefined;
    metrics: TimeSeriesCategoricalMetric;
}> = (props) => {
    const dateTo = useMemo(() => {
        return dayjs(props.metrics.period.slice(0,10))
            .endOf("month")
            .toISOString();
    }, [props.metrics.period]);

    const requests = useRequestsForQuotes({
        customer: props.customer || ("" as any),
        accountManager: props.am || ("" as any),
        quoteCreationDateFrom: props.metrics.period,
        quoteCreationDateTo: dateTo,
        withExpired: true
    });

    const table = useReactTable({
        data: requests.data?.flat() || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const onScrollToBottom = () => requests.setSize(requests.size + 1);

    return (
        <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
            <Button
                variant="text"
                color="gray"
                onClicked={props.onBack}
                leftIcon={<TbArrowBackUp size={16} />}
                label="title.back"
            />
            <div className="text-xl">
                Requests Turned into Quotes{" "}
                {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
            </div>
            <LinearLoader loading={requests.isLoading} />
            <Table
                table={table}
                onScrolledBottom={onScrollToBottom}
                className="w-full"
            />
        </div>
    );
};
