import { useEffect, useMemo, useState } from "react";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import { useDebounce } from "~/Hooks";
import { SWRInfiniteResponse } from "swr/infinite";

/**
* Used as the type for preset data fetch wrap of ApiDataCombobox. Extend this if you want to allow the base combobox
* props, without the props that should be handled by your preset combobox props
**/
export type ExtendDataCombobox<T> = Omit<
  FormComboboxProps<T, number>,
  | "getLabel"
  | "getId"
  | "useListData"
  | "getSingle"
  | "filters"
  | "data"
  | "onScrollToBottom"
>;

/**
* Handles getting a SWRInfinite list from the API, and all the weird edge cases like:
* - fetching already selected
* - search debouncing
* - getting a list of labels
* - etc.
**/
export const ApiDataCombobox = <
  Form,
  Filters extends { search: string },
  DataType,
>(
  props: {
    useListData: (filters: Filters) => SWRInfiniteResponse<DataType[]>;
    getSingle: (id: number) => Promise<DataType>;
    getId: (data: DataType) => number;
    getLabel: (data: DataType) => string;
    filters: Filters;
  } & Omit<FormComboboxProps<Form, number>, "getLabel" | "data">,
) => {
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 200);

  const listData = props.useListData({
    ...props.filters,
    search: searchDebounce,
  });

  const dataIds = useMemo(
    () => listData.data?.flat().map(props.getId) || [],
    [listData.data],
  );

  const [dataLabels, setDataLabels] = useState<Record<string, string>>({});

  useEffect(() => {
    let labels = {};
    listData.data
      ?.flat()
      .forEach((d) => (labels[props.getId(d)] = props.getLabel(d)));
    setDataLabels((old) => ({ ...old, ...labels }));
  }, [dataIds]);

  // If there is a default company, manually load, as it might not be in the first page grabbed above
  useEffect(() => {
    addDefault().then();
  }, []);
  const addDefault = async () => {
    if (props.multiple) {
      const len = await Promise.all(
        (props.formData.data[props.id] as number[]).map(props.getSingle),
      );
      setDataLabels((old) => {
        let labels = {};
        len.forEach((d) => (labels[props.getId(d)] = props.getLabel(d)));
        return {
          ...old,
          ...labels,
        };
      });
    } else {
      const d = await props.getSingle(props.formData.data[props.id] as number);
      setDataLabels((old) => ({ ...old, [props.getId(d)]: props.getLabel(d) }));
    }
  };

  const getLabel = (id: number) => dataLabels[id];

  const onSearchChange = (val: string) => {
    setSearch(val);
    listData.setSize(1);
  };

  const onScrollToBottom = () => {
    listData.setSize(listData.size + 1);
  };

  return (
    <FormCombobox
      {...props}
      data={dataIds}
      getLabel={getLabel}
      onSearchChange={onSearchChange}
      onScrollToBottom={onScrollToBottom}
      loading={listData.isLoading}
      listLoading={!listData.isLoading && listData.isValidating}
    />
  );
};
