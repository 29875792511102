import {Show} from "~/Components/UI";
import {Skeleton} from "~/Components/Loaders";
import {FC} from "react";
import {useProductV3} from "~/Data/Products/Products";

const ProductView: FC<{
    id: number;
    className?: string;
}> = (props) => {
    const product = useProductV3(props.id);

    if (!props.id)
        return <></>;

    return (
        <Show when={product.data} fallback={<Skeleton className="w-max-6xl w-full h-line-md" />}>
            <div className={props.className}>{product.data?.description}</div>
        </Show>
    );
};

export default ProductView;
