import API, { CreateDocumentRequest } from "~/API";
import { useMutateHook } from "~/Hooks";

export const useSalesOrderDocumentDelete = (orderId: number) =>
  useMutateHook<{ docId: number }, any, any>(
    ["api/sales/order/documents", orderId],
    async (args) => API.deleteSalesOrderDocument({ id: args.docId }),
  );

export const useSalesOrderDocumentCreate = (orderId: number) =>
  useMutateHook<{ attachments: CreateDocumentRequest[]; }, any, any>(
    ["api/sales/order/documents", orderId],
    async (args) => Promise.all(
      args.attachments.map((attach) =>
        API.createSalesOrderDocument({ id: orderId }, attach),
      ),
    ),
  );
