import {Fragment, ReactNode} from "react";

export const ListJoin = <T,>(props: {
  data?: T[];
  separator?: ReactNode;
  render: (props: { data: T }) => ReactNode;
}) => {
  if (props.data == undefined || props.data.length == 0)
    return <></>;

  return (
    <>
      {props.data.map((d, i) => (
        <Fragment key={i}>
          <props.render data={d} />
          {i != props.data.length - 1 && (
            props.separator || ", "
          )}
        </Fragment>
      ))}
    </>
  );
}