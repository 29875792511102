import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { productGroupsRootPath } from "~/Services/Routes";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { GeneralProductGroupSection } from "./Components/GeneralProductGroupSection";
import { ProductsProductGroupSection } from "./Components/ProductsProductGroupSection";
import { DateTimeView } from "~/Components/Views/DateView";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { Button } from "~/Components/UI";
import { useProductGroup } from "~/Data/Products/ProductGroups";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import API, { ProductGroupView, ProductUfp } from "~/API";
import { useFormData, useMutate } from "~/Hooks";
import useSWRImmutable from "swr/immutable";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import { setProductPrices } from "~/Pages/Products/GroupsPage/Reducer";
import { mutate } from "swr";
import Toast from "~/Components/Toast";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {useCurrentUser, useIsAdmin, useIsUFPBuyer} from "~/Reducers/User";
import {trackEvent} from "~/Services/Tracking";
import {decimalToHundredthPercent, hundredthToDecimalPercent} from "~/Utils/UnitConversion";

const ProductGroupDetailsPage: FC = () => {
  const { id: groupId } = useParams<{ id: string }>();

  const productGroup = useProductGroup(+groupId);
  const products = useSWRImmutable(["api/products/by/group", groupId], () =>
    API.getProductsV3({ groupId: [+groupId], active: true }),
  );

  if (productGroup.error || products.error)
    return (
      <EndpointErrorPage
        status={productGroup.error?.status || productGroup.error?.status}
        item="product group"
      />
    );

  if (productGroup.isLoading || products.isLoading) return <LoadingPage />;

  return (
    <ProductGroupLoadedPage
      group={productGroup.data}
      products={products.data}
      refreshProductGroup={productGroup.mutate}
    />
  );
};

export default ProductGroupDetailsPage;

const ProductGroupLoadedPage: FC<{
  group: ProductGroupView;
  products: ProductUfp[];
  refreshProductGroup: () => void,
}> = (props) => {
  const dispatch = useAppDispatch();
  const productPrices = useAppSelector(
    (state) => state.productGroupsPage.productPrices,
  );

  const form = useFormData({
    ...props.group,
    productGroupMargin: decimalToHundredthPercent(props.group.productGroupMargin),
  });

  useEffect(() => {
    dispatch(
      setProductPrices(
        props.products.reduce((p, v) => ({ ...p, [v.id]: v.price }), {}),
      ),
    );
  }, []);

  const currentUser = useCurrentUser();
  const isUfpBuyer = useIsUFPBuyer();
  const isAdmin = useIsAdmin();
  const canEdit = (isUfpBuyer && props.group.buyerUserIds.includes(currentUser.id)) || isAdmin;

  const saveCall = useMutate(async () => {
    try {
      const newGroup = await API.updateProductGroup(
        { id: form.data.id },
        {
          ...form.data,
          productGroupMargin: hundredthToDecimalPercent(form.data.productGroupMargin),
          productPrices: Object.keys(productPrices).map((id) => ({
            id: +id,
            price: productPrices[id],
          })),
        },
      );
      await mutate(["api/products/by/group", form.data.id]);
      props.refreshProductGroup();
      form.setData({
        ...newGroup,
        productGroupMargin: decimalToHundredthPercent(newGroup.productGroupMargin),
      }, true);
      trackEvent("Product Group Edited");
    } catch (e) {
      Toast.ApiError(e);
      throw e;
    }
  });

  return (
    <ScrollPage title={props.group.name}>
      {canEdit && form.hasChanges && <UnsavedChangesDialog onSave={saveCall.mutate} />}
      <PageTitle type="back" parentUrl={productGroupsRootPath} />
      <div className=" text-xl flex-row flex-wrap align-baseline mx-lg mt-lg mb-xs">
        {props.group.name}
        <div className="ml-md color-text-3 text-md text-normal">
          Last Updated: <DateTimeView date={props.group.dateUpdated} />
        </div>
      </div>

      <div className="flex-col gap-lg text-left">
        <GeneralProductGroupSection
          form={form}
          canEdit={canEdit}
        />
        <ProductsProductGroupSection
          form={form}
          products={props.products}
          canEdit={canEdit}
        />
      </div>

      { canEdit &&
        <BottomButtonsPanel>
          <Button
            label="title.save.changes"
            disabled={!form.hasChanges}
            onClicked={saveCall.mutate}
            loading={saveCall.loading}
          />
        </BottomButtonsPanel>
      }
    </ScrollPage>
  );
};
