import { useCallback } from "react";
import { classNames } from "react-ui-basics/Tools";
import { SuccessIcon, InfoIcon, WarningIcon, AlertIcon, CloseIcon } from '~/Components/Old/Icons';
import './Notification.scss';


const iconByType = (type) => {
  if (type === 'info') {
    return <InfoIcon size={16} className={'NotificationMessageIcon'}/>;

  } else if (type === 'warning') {
    return <WarningIcon size={16} className={'NotificationMessageIcon'}/>;

  } else if (type === 'error') {
    return <AlertIcon size={16} className={'NotificationMessageIcon'}/>;

  }
  return <SuccessIcon size={16} className={'NotificationMessageIcon'}/>;
};

const containerStyleByType = (type) => {
  if (type === 'info') {
    return 'TBNotificationMainContainer TBNotificationInfoContainer';

  } else if (type === 'warning') {
    return 'TBNotificationMainContainer TBNotificationWarningContainer';

  } else if (type === 'error') {
    return 'TBNotificationMainContainer TBNotificationErrorContainer';

  }
  return 'TBNotificationMainContainer';
};

const Notification = (props) => {
  const mainContainerClassName = classNames(containerStyleByType(props.type), props.className);

  const onClick = useCallback((event) => event.stopPropagation?.(), []);

  return (
    <div {...props} className={mainContainerClassName} onClick={onClick}>
      {props.icon
        ? <div className={'NotificationMessageIcon'}>{props.icon}</div>
        : iconByType(props.type)
      }
      <div className={'NotificationMessageDescription'}>
        {props.children}
      </div>
      {props.onClose && <CloseIcon size={16} onClick={props.onClose} className={'NotificationMessageCloseIcon'}/>}
    </div>
  );
}

export default Notification;
