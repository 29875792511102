import {FC, useEffect, useMemo} from "react";
import { Button, Dialog } from "~/Components/UI";
import { useCurrentUser, useIsCustomer } from "~/Reducers/User";
import API, {
  CompanyType,
  MyProduct,
  OfferStatus,
  ProductPublicView,
  ProductV2ProductType,
  ProductV2UOM,
  RequestForQuoteStatus,
} from "~/API";
import { ls } from "~/Locales";
import { useFormData, useMutate } from "~/Hooks";
import { UOMComboboxForm } from "~/Components/Comboboxes/UOMCombobox";
import { ProductType } from "~/Reducers/Products";
import { LengthByProductGroupCombobox } from "~/Components/Comboboxes/LengthByProductGroupCombobox";
import { LocationCombobox } from "~/Components/Companies/LocationCombobox";
import { useMyProducts } from "~/Data/Products/MyProducts";
import { locationAddress } from "~/Data/Locations";
import dayjs from "dayjs";
import {offersRootPath, requestsRootPath} from "~/Services/Routes";
import { useHistory } from "react-router-dom";
import * as z from "zod";
import {useCompany} from "~/Data/Companies/Companies";
import {trackEvent} from "~/Services/Tracking";

const validation = z.object({
  locationId: z.number().nullable(),
  uom: z.string(),
  lengthIds: z.array(z.number()),
  isCompanyCustomer: z.boolean(),
}).refine(d => (!d.isCompanyCustomer || d.locationId != null), {
  path: ["locationId"],
  message: ls("error.required", ["location"])
}).refine(d => (!d.isCompanyCustomer || d.uom.length > 0), {
  path: ["uom"],
  message: ls("error.required", ["UOM"])
}).refine(d => (!d.isCompanyCustomer || d.lengthIds.length > 0), {
  path: ["lengthIds"],
  message: ls("error.min.one", ["length"])
});

export const CatalogueDetailsCreateEntity: FC<{
  product: ProductPublicView;
}> = (props) => {
  const currentUser = useCurrentUser();
  const isCustomer = useIsCustomer();
  const userCompany = useCompany(currentUser.companyId);
  const isCompanyCustomer = userCompany.data?.type == CompanyType.BUYER;
  const history = useHistory();

  const myProducts = useMyProducts();
  const myProductsProduct = useMemo(
    () => myProducts.data?.find((p) => p.productId == props.product.id),
    [myProducts.data],
  );

  const form = useFormData({
    locationId: null as number,
    lengthIds: [],
    uom: props.product.standardUnitOfMeasure,
    isCompanyCustomer,
  }, { validation });

  useEffect(() => form.setData({ ...form.data, isCompanyCustomer }), [isCompanyCustomer]);

  const limitLengths =
    myProductsProduct?.productType == ProductV2ProductType.LUMBER &&
    myProductsProduct?.uom == ProductV2UOM.LF;

  const createMyProductsCall = useMutate(async() => {
    if(form.isValid()) {
      let call: Promise<MyProduct[]>;
      if (isCompanyCustomer) {
        call = API.createMyProducts({}, [{
          productId: props.product.id,
          ...form.data
        }]);
      } else {
        call = API.addCatalogueProductsToCompany({}, {
          categoryNames: [],
          productIds: [props.product.id],
          companyId: currentUser.companyId,
          locationIds: [null],
        });
      }
      const prod = (await call)[0];
      myProducts.mutate(); // don't wait on this, just refresh in background
      await createCall.mutate(prod);
    }
  });

  const createCall = useMutate(async (product: MyProduct) => {
    const shipTo = await API.getLocation({
      id: product.locationId,
    });

    if (isCustomer) {
      const rq = await API.createRequestForQuote(
        {},
        {
          customerCompanyId: currentUser.companyId,
          shipToLocationId: product.locationId,
          incoterm: shipTo.incoterm || "",
          incotermAddressDestination: locationAddress(shipTo),
          incotermPortIdDestination: shipTo.portId,
          externalComment: "",
          internalComment: "",
          validityDate: dayjs().add(7, "day").endOf("day").toISOString(),
          expectedDeliveryDate: null,
          status: isCustomer
            ? RequestForQuoteStatus.DRAFT_CUSTOMER
            : RequestForQuoteStatus.DRAFT_AM,
          currency: "USD",
          customerContactUserAccountIds: shipTo.users || [],
          quotes: [],
          offers: [],
          products: [
            {
              ...product,
              lengthIds: limitLengths
                ? product.lengthIds.length > 0
                  ? [product.lengthIds[0]]
                  : []
                : product.lengthIds,
              price:
                product.price || product.productGroupPrice,
              active: true,
              cost:
                product.price || product.productGroupPrice,
              margin: 0,
              quantity: 0,
            },
          ],
        },
      );

      trackEvent("Created Request for Quote from Catalogue Details");
      history.push(`${requestsRootPath}${rq.id}/edit`);
    } else {
      const of = await API.createOffer(
        {},
        {
          vendorCompanyId: currentUser.companyId,
          shipFromLocationId: form.data.locationId,
          incoterm: "",
          incotermAddressDestination: "",
          incotermPortIdDestination: null,
          validityDate: null,
          dateIncoDestination: null,
          externalComment: "",
          internalComment: "",
          status: OfferStatus.O_DRAFT,
          currency: "USD",
          requestForQuoteId: null,
          quotes: [],
          products: [
            {
              ...product,
              lengthIds: limitLengths
                ? product.lengthIds.length > 0
                  ? [product.lengthIds[0]]
                  : []
                : product.lengthIds,
              price:
                product.price || product.productGroupPrice,
              cost:
                product.price || product.productGroupPrice,
              quantity: 0,
            },
          ]
        }
      );

      trackEvent("Created Offer from Catalogue Details");
      history.push(`${offersRootPath}${of.id}/edit`);
    }
  });

  return (
    <>
      {myProductsProduct != undefined && (
        <Button
          label={isCustomer ? "title.request.quote" : "title.create.offer"}
          onClicked={() => createCall.mutate(myProductsProduct)}
          loading={createCall.loading}
        />
      )}
      {myProductsProduct == undefined && (
        <Dialog
          trigger={
            <Button
              label={isCustomer ? "title.request.quote" : "title.create.offer"}
            />
        }
          title={ls(isCustomer ? "title.request.quote" : "title.create.offer")}
        >
          <div className="desktop-w-9xl flex-col gap-md">
            {isCompanyCustomer && (
              <>
                <LocationCombobox
                  id="locationId"
                  formData={form}
                  filters={{ onlyMy: true }}
                  label={isCustomer ? "title.ship.to" : "title.ship.from"}
                />

                <LengthByProductGroupCombobox
                  id="lengthIds"
                  formData={form}
                  label="title.lengths"
                  groupId={props.product.productGroupId}
                  multiple
                  showClear
                  clearValue={[]}
                />

                <UOMComboboxForm
                  id="uom"
                  formData={form}
                  label="title.uom"
                  productType={props.product.productType as ProductType}
                />
              </>
            )}

            <div className="color-text-3 text-semibold">
              This product will also be added to your "My Products" page
            </div>

            {createMyProductsCall.error && (
              <div className="color-red-1 text-semibold">
                Something went wrong!
              </div>
            )}

            <Button
              label={
                isCustomer ? "title.request.quote" : "title.create.offer"
              }
              onClicked={createMyProductsCall.mutate}
              loading={createMyProductsCall.loading}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
