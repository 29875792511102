import {FC, useMemo} from "react";
import {GroupPermission, UserGroupPermissionsView } from "~/API";
import { TbTrash } from "react-icons/tb";
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  For,
  FormSwitch,
  Show,
} from "~/Components/UI";
import { useFormData } from "~/Hooks";
import { z } from "zod";
import { zodMsg } from "~/Utils";
import {useEditUserGroup } from "~/Data/Admin/UserGroups";
import {trackEvent} from "~/Services/Tracking";

const editFormValidation = z.object({
  name: z.string().min(1, zodMsg("error.non.empty", ["Name"])),
  permissions: z
    .array(z.string())
    .min(1, zodMsg("error.min.one", ["Permission"])),
});

const UserGroupEditForm: FC<{
  userGroup: UserGroupPermissionsView;
  editingFalse: () => void;
}> = (props) => {
  const editForm = useFormData<UserGroupPermissionsView>(
    props.userGroup,
    {
      validation: editFormValidation,
    }
  );

  const save = useEditUserGroup();

  const allPermissions = Object.values(GroupPermission);
  const addablePermissions: GroupPermission[] = useMemo(
    () =>
      allPermissions.filter(
        (p) => !editForm.data.permissions.includes(p),
      ),
    [allPermissions, editForm.data.permissions],
  );

  const addPermission = (id: GroupPermission) => () => {
    editForm.onDataChange("permissions", [...editForm.data.permissions, id]);
  };

  const removePermission = (id: GroupPermission) => {
    editForm.onDataChange(
      "permissions",
      editForm.data.permissions.filter((p) => p !== id),
    );
  };

  const onSaveClicked = () => {
    if (editForm.isValid()) save
      .call(editForm.data)
      .then(props.editingFalse)
      .then(() => trackEvent("User Group Updated"));
  };

  return (
    <div className="desktop-w-min-11xl flex-col gap-lg">
      <FormSwitch id="active" formData={editForm} label="title.active" />

      <div className="flex-col">
        <div className="text-bold">Permissions:</div>

        <Show when={editForm.errors.permissions?._errors.length > 0}>
          <div className="color-red-1 mt-xs">
            {editForm.errors.permissions?._errors[0]}
          </div>
        </Show>

        <For each={editForm.data.permissions}>
          {(id) => <PermissionRow key={id} permission={id} remove={removePermission} />}
        </For>

        <div className="flex-row mt-md">
          <ButtonDropdown trigger="user.permission.add" align="start">
            <For each={addablePermissions}>
              {(p) => (
                <ButtonDropdownItem onClick={addPermission(p)} key={p}>
                  {p}
                </ButtonDropdownItem>
              )}
            </For>
          </ButtonDropdown>
        </div>
      </div>

      <div className="flex-row justify-end gap-sm">
        <Button
          onClicked={onSaveClicked}
          loading={save.isMutating}
          label="title.save"
        />
        <Button
          color="gray"
          label="title.cancel"
          onClicked={props.editingFalse}
          disabled={save.isMutating}
        />
      </div>
    </div>
  );
};

export default UserGroupEditForm;

const PermissionRow: FC<{
  permission: GroupPermission;
  remove: (id: GroupPermission) => void;
}> = (props) => {
  const onRemove = () => props.remove(props.permission);

  return (
    <>
      <div
        className="flex flex-row justify-between align-center my-xs mx-lg"
        key={props.permission}
      >
        {props.permission}
        <Button
          color="red"
          variant="text"
          leftIcon={<TbTrash size={22} />}
          onClicked={onRemove}
        />
      </div>
      <div className="flex border-b-1 border-black-10" />
    </>
  );
};
