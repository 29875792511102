import useSWR from "swr";
import API, {OfferStatus, OfferView, Order} from "~/API";
import useSWRInfinite from "swr/infinite";

export const offerStatusList = [
  OfferStatus.O_DRAFT,
  OfferStatus.O_DRAFT_VENDOR,
  OfferStatus.O_BUYER_REVIEW,
  OfferStatus.O_SALES_REVIEW,
  OfferStatus.O_QUOTED,
  OfferStatus.O_IN_PROGRESS,
  OfferStatus.O_QUOTE_CLOSED,
  OfferStatus.O_CLOSING,
  OfferStatus.O_VENDOR_CONFIRMED,
  OfferStatus.O_ORDERED,
  OfferStatus.O_DELIVERED,
  OfferStatus.O_ARCHIVED,
];
// Vendors can't see O_DRAFT status
const offerStatusListVendor = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT
].includes(status));

// AM(aka Sales) can't see O_DRAFT, O_DRAFT_Vendor, O_Buyer_Review statuses
const offerStatusListAM = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT,
  OfferStatus.O_DRAFT_VENDOR,
  OfferStatus.O_BUYER_REVIEW
].includes(status));

// Internal users can't see O_DRAFT_Vendor status
const offerStatusListInternalUsers = offerStatusList.filter(status => ![
  OfferStatus.O_DRAFT_VENDOR
].includes(status));

const getOfferStatuses = (isAm: boolean, isBuyer: boolean, isVendor: boolean, isInternal: boolean) => {
  if (isAm && !isBuyer) return offerStatusListAM;
  if (isVendor) return offerStatusListVendor;
  if (isInternal) return offerStatusListInternalUsers
  return [];
}

export const useDashboardOffers = (isAm: boolean, isBuyer: boolean, isVendor: boolean, isInternal: boolean) =>
  useSWR(
    ["api/offers/dashboard", isAm, isBuyer, isVendor, isInternal],
    async () =>
      await API.getOffers({
        limit: 10,
        onlyMy: true,
        status: getOfferStatuses(isAm, isBuyer, isVendor, isInternal),
      }),
  );

export interface OffersFilters {
  query?: string,
  search?: string,
  status?: Array<OfferStatus>,
  shipFrom?: Array<number>,
  valueFrom?: string,
  valueTo?: string,
  dateIncoDestinationFrom?: string,
  dateIncoDestinationTo?: string,
  validityDateFrom?: string,
  validityDateTo?: string,
  quoteDateFrom?: string,
  quoteDateTo?: string,
  poCreateDateFrom?: string,
  poCreateDateTo?: string,
  createdDateFrom?: string,
  createdDateTo?: string,
  port?: Array<number>,
  buyer?: Array<number>,
  createdBy?: Array<number>,
  firstQuoteAM?: number,
  vendor?: Array<number>,
  follower?: Array<number>,
  incoterm?: Array<string>,
  product?: Array<number>,
  productGroup?: Array<number>,
  productType?: Array<string>,
  onlyMy?: boolean,
  onlyArchivedOrDelivered?: boolean,
  onlyArchivedOrDeliveredOrExpired?: boolean,
  withoutArchivedOrDelivered?: boolean,
  withExpired?: boolean,
  onlyExpired?: boolean,
  onlyNotLinkedToRFQ?: boolean,
  orderBy?: string,
  order?: Order,
}

const PAGE_SIZE = 15;

const getOffersListKey = (
    filters: OffersFilters,
    offset: number,
    previousPageData: OfferView[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/offers", filters, offset };
};

export const useOffers = (filters: OffersFilters) =>
    useSWRInfinite(
        (i, p) => getOffersListKey(filters, i * PAGE_SIZE, p),
        (key) =>
            API.getOffers({
              ...filters,
              offset: key.offset,
              limit: PAGE_SIZE,
            }),
        {
          revalidateFirstPage: false,
        },
    );
