import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath, useSearchParams } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { closeLeftMenu } from "~/Reducers/LeftMenu";
import {
  useIsAdmin,
  useIsCustomer,
  useIsUFPAM,
  useIsUFPBuyer,
} from "~/Reducers/User";
import {
  getQuotes,
  getReferenceGroupsForQuotes,
  getCustomerGroupsForQuotes,
  getShipToGroupsForQuotes,
} from "~/Reducers/Quotes";
import {
  useQuotesPageReduxState,
  clearData,
  QuotesPageTableTypes,
  useDefaultPageFilters,
} from "./Reducer";
import QuotesPageQuotesTable from "./Components/Tables/QuotesPageQuotesTable";
import QuotesPageByStatusTable from "./Components/Tables/QuotesPageByStatusTable";
import QuotesPageByReferenceTable from "./Components/Tables/QuotesPageByReferenceTable";
import QuotesPageByCustomerTable from "./Components/Tables/QuotesPageByCustomerTable";
import QuotesPageByShipToTable from "./Components/Tables/QuotesPageByShipToTable";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import FiltersButton from "~/Components/Old/FiltersButton";
import { PlusIcon } from "~/Components/Old/Icons";
import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import TextField from "~/Components/Old/TextField";
import { useFilters } from "~/Components/Old/useFilters";
import Filters from "./Components/Filters";
import { useIsMobile } from "~/Hooks/useIsMobile";
import { usePageTitle } from "~/Hooks";
import {useAppDispatch} from "~/StoreTypes";

const shouldSkipLoading = (previousLocation: string) => {
  const pattern = new RegExp(`^${quotesRootPath}\\d+(\\/.*)?$`);
  return pattern.test(previousLocation);
};

const QuotesPage: FC = () => {
  usePageTitle("Quotes");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showFilters, showHideFilters] = useToggle(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();

  const getAPIFunc = useMemo(() => {
    switch (searchParams.tableType) {
      case QuotesPageTableTypes.QUOTES_PAGE_STATUS:
        return null;
      case QuotesPageTableTypes.QUOTES_PAGE_OFFER:
        return getReferenceGroupsForQuotes;
      case QuotesPageTableTypes.QUOTES_PAGE_CUSTOMER:
        return getCustomerGroupsForQuotes;
      case QuotesPageTableTypes.QUOTES_PAGE_SHIP_TO:
        return getShipToGroupsForQuotes;
      default:
        return getQuotes;
    }
  }, [searchParams.tableType]);

  const pageFilters = useDefaultPageFilters();

  const { filters, setFilter, onScrolledToBottom } = useFilters(
    pageFilters,
    getAPIFunc,
    useQuotesPageReduxState,
    shouldSkipLoading,
    scrollRef,
  );

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, []);

  const onAdd = useCallback(() => {
    history.push(`${quotesRootPath}new/edit`);
    scrollRef.current.scrollTo(0, 0);
  }, [history]);

  const onChangeTab = useCallback(
    (event) => {
      dispatch(clearData());
      setFilter(event);
    },
    [dispatch, setFilter],
  );

  const isSuperAdmin = useIsUFPAM();
  const isAccountManager = useIsAdmin();
  const isBuyer = useIsUFPBuyer();

  const showCreateQuoteButton = isSuperAdmin || isAccountManager || isBuyer;

  return (
    <div className={"static-view quotes-page"}>
      <PageTitleContainer title={<LocalizedMessage id={"title.quotes"} />} />

      <div className={"full-page-table-card rounded-xxs"}>
        <div className={"flex flex-col overflow-hidden bg-black-12"}>
          {!isMobile && (
            <div className={"table-top-bar"}>
              {showCreateQuoteButton && (
                <Button
                  view={"secondary"}
                  onClick={onAdd}
                  leftIcon={<PlusIcon size={16} />}
                  data-cy={"AddNewQuotesButton"}
                >
                  <LocalizedMessage id={"title.add.new.quote"} />
                </Button>
              )}
              <TextField
                id={"search"}
                showClearButton
                placeholder={"Search by ID, Product and Description"}
                className={"filter-search mr-xs"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />
              <div className={"flex-row align-center"}>
                <Checkbox
                  id={"onlyArchivedOrDeliveredOrExpired"}
                  value={filters.onlyArchivedOrDeliveredOrExpired}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.expired"} />
              </div>

              <div className={"flex-row align-center"}>
                <Checkbox
                  id={"onlyMy"}
                  value={filters.onlyMy}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.show.only.my.quotes"} />
              </div>
              <SpaceFiller />
              <QuotesPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {isMobile && (
            <div className={"table-top-bar flex-row"}>
              <QuotesPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <TextField
                id={"search"}
                placeholder={"Search"}
                className={"flex"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {filters.tableType === QuotesPageTableTypes.QUOTES_PAGE_NONE && (
            <QuotesPageQuotesTable {...{ scrollRef, onScrolledToBottom }} />
          )}
          {filters.tableType === QuotesPageTableTypes.QUOTES_PAGE_STATUS && (
            <QuotesPageByStatusTable {...{ filters }} />
          )}
          {filters.tableType === QuotesPageTableTypes.QUOTES_PAGE_OFFER && (
            <QuotesPageByReferenceTable
              {...{ scrollRef, onScrolledToBottom }}
            />
          )}
          {filters.tableType === QuotesPageTableTypes.QUOTES_PAGE_CUSTOMER && (
            <QuotesPageByCustomerTable {...{ scrollRef, onScrolledToBottom }} />
          )}
          {filters.tableType === QuotesPageTableTypes.QUOTES_PAGE_SHIP_TO && (
            <QuotesPageByShipToTable {...{ scrollRef, onScrolledToBottom }} />
          )}
        </div>

        <RightPanel visible={showFilters}>
          <Filters {...{ showHideFilters, filters, setFilter, pageFilters }} />
        </RightPanel>
      </div>

      {isMobile && (
        <div className={"bottom-right-container"}>
          {showCreateQuoteButton && (
            <Button onClick={onAdd} leftIcon={<PlusIcon size={16} />}>
              <LocalizedMessage id={"title.add.new.quote"} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default QuotesPage;

const QuotesPageTableTypeTabs: FC<{
  value: QuotesPageTableTypes;
  onChange: (event: any) => void;
}> = ({ value, onChange }) => {
  const selectedTab = useMemo(
    () => Object.values(QuotesPageTableTypes).indexOf(value),
    [value],
  );

  const onChangeTab = useCallback(
    (event) => {
      onChange({
        ...event,
        target: { id: "tableType", value: event.target.id },
      });
    },
    [onChange],
  );

  const isCustomer = useIsCustomer();

  return (
    <div className={"flex-row align-center mobile-w-full"}>
      <Button
        id={QuotesPageTableTypes.QUOTES_PAGE_NONE}
        view={selectedTab === 0 ? "secondary" : "text"}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.none"} />
      </Button>
      <Button
        id={QuotesPageTableTypes.QUOTES_PAGE_STATUS}
        view={selectedTab === 1 ? "secondary" : "text"}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.status"} />
      </Button>
      {!isCustomer && (
        <>
          <Button
            id={QuotesPageTableTypes.QUOTES_PAGE_OFFER}
            view={selectedTab === 2 ? "secondary" : "text"}
            onClick={onChangeTab}
          >
            <LocalizedMessage id={"title.offer"} />
          </Button>
          <Button
            id={QuotesPageTableTypes.QUOTES_PAGE_CUSTOMER}
            view={selectedTab === 3 ? "secondary" : "text"}
            onClick={onChangeTab}
          >
            <LocalizedMessage id={"title.customer"} />
          </Button>
          <Button
            id={QuotesPageTableTypes.QUOTES_PAGE_SHIP_TO}
            view={selectedTab === 4 ? "secondary" : "text"}
            onClick={onChangeTab}
          >
            <LocalizedMessage id={"title.ship.to"} />
          </Button>
        </>
      )}
    </div>
  );
};
