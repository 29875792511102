import { FC } from "react";
import { cn } from "~/Utils";

export const HDivider: FC<{ className?: string }> = (props) => (
  <div
    className={cn("mx-sm w-0 border-l-1 border-black-10", props.className)}
  />
);

export const Divider: FC<{ className?: string }> = (props) => (
  <div
    className={cn(
      "w-full h-0 border-b-1 mx-sm my-md border-black-10",
      props.className,
    )}
  />
);
