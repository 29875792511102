import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import {LC} from "~/Locales";
import {FC} from "react";

const SalesOrderMessage: FC<{ orderId: number }> = ({ orderId }) => {
  const salesOrder = useSalesOrder(orderId);

  if (!salesOrder.data?.buyerMessage) return null;

  return (
    <div className="flex-col px-lg my-lg text-left">
      <div className="text-sm">
        <LC id="title.customer.instructions" />
      </div>
      <div className="mt-xxs">{salesOrder.data.buyerMessage}</div>
    </div>
  );
};

export default SalesOrderMessage;
