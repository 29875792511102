import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import {useUsersByPermission} from "~/Data/Users";
import { useEffect, useMemo, useState } from "react";
import API, {GroupPermission} from "~/API";

export const UserByPermissionCombobox = <T,>(
    props: {
        excludeUsers?: number[];
        permissions: GroupPermission[]
    } & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
    const users = useUsersByPermission(props.permissions);

    const userIds = useMemo(
        () =>
            users.data
                ?.flat()
                .map((user) => user.id)
                .filter((id) => !(props.excludeUsers || []).includes(id)) || [],
        [users.data, props.excludeUsers],
    );

    const [userLabels, setUserLabels] = useState<Record<string, string>>({});

    useEffect(() => {
        let labels = {};
        users.data
            ?.flat()
            .forEach((user) => (labels[user.id] = `${user.name} ${user.surname} (${user.email})`));
        setUserLabels((old) => ({ ...old, ...labels }));
    }, [userIds]);

    const getLabel = (id: number) => userLabels[id];


    // If there is a default company, manually load, as it might not be in the first page grabbed above
    useEffect(() => {
        addDefault().then();
    }, []);
    const addDefault = async () => {
        if (props.multiple) {
            const len = await Promise.all(
              (props.formData.data[props.id] as number[]).map((id) => API.getUser({ id })),
            );
            setUserLabels((old) => {
                let labels = {};
                len.forEach((d) => (labels[d.id] = `${d.name} ${d.surname} (${d.email})`));
                return {
                    ...old,
                    ...labels,
                };
            });
        } else {
            const d = await API.getUser({ id: props.formData.data[props.id] as number });
            setUserLabels((old) => ({ ...old, [d.id]: `${d.name} ${d.surname} (${d.email})` }));
        }
    };
    return (
        <FormCombobox
            {...props}
            data={userIds}
            getLabel={getLabel}
            loading={users.isLoading}
            listLoading={!users.isLoading && users.isValidating}
        />
    );
};
