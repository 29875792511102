import {FC, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {
  usersRootPath,
} from "~/Services/Routes";
import { useIsAdmin } from "~/Reducers/User";
import {StaticPage} from "~/Components/Layout/Pages";
import {PageTitle} from "~/Components/Nav/PageTitle";
import {FilterPanel} from "~/Components/Entities/FilterPanel";
import {params, useDebounce, useFormData, useToggle} from "~/Hooks";
import {Button, FormCheckbox, FormCombobox, FormInput, Table} from "~/Components/UI";
import {TbFilter, TbPlus} from "react-icons/tb";
import {CompanyCombobox} from "~/Components/Companies/CompanyCombobox";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Registration, UserAccountView} from "~/API";
import { ls } from "~/Locales";
import {UserFilters, useUsers} from "~/Data/Users";
import {LinearLoader} from "~/Components/Loaders";
import {FormDateInputRange} from "~/Components/UI/Forms/DateInput";
import {UserGroupCombobox} from "~/Components/Users/UserGroupCombobox";
import * as z from "zod";
import {UsersTableColumns} from "~/Pages/Users/Components/UsersTable";

const routeParser = z.object({
  search: params.string,
  onlyInactive: params.bool,
  onlyMy: params.bool,
  company: params.numberArray,
  lastSeenFrom: params.string,
  lastSeenTo: params.string,
  status: params.stringArray,
  userGroup: params.numberArray,
});

const UsersPage: FC = () => {
  const history = useHistory();
  const filtersOpen = useToggle();
  const filters = useFormData<UserFilters>({
    search: "",
    onlyInactive: false,
    onlyMy: false,
    company: [],
    lastSeenFrom: "",
    lastSeenTo: "",
    status: [],
    userGroup: [],
  }, { routeParser });
  const searchDebounce = useDebounce(filters.data.search);

  const onOpenUser = (user: UserAccountView) => {
    history.push({ pathname: `${usersRootPath}${user.id}` });
  };

  const isAdmin = useIsAdmin();

  const onAddUser = () => {
    history.push(usersRootPath + "new");
  }

  const users = useUsers({
    ...filters.data,
    search: searchDebounce,
    orderBy: "email"
  }, 40);
  const data = useMemo(() => users.data?.flat() || [], [users.data]);
  const onScrolledToBottom = () => users.setSize(users.size + 1);

  const table = useReactTable({
    data,
    columns: UsersTableColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
      },
      columnPinning: {
        left: ["loginAs"],
        right: ["controls"],
      },
    },
  });

  return (
    <StaticPage title="Users">
      <PageTitle title="title.users" type="menu"/>

      <div className="flex-row gap-lg flex overflow-hidden">
        <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
          <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
            {isAdmin && (
              <Button
                label="title.add.user"
                leftIcon={<TbPlus size={16} />}
                onClicked={onAddUser}
                dataCy="addUserButton"
              />
            )}

            <FormInput
              id="search"
              formData={filters}
              showClear
              type="search"
              placeholder="title.search"
              className="desktop-w-min-8xl"
              dataCy="SearchBox"
            />

            <FormCheckbox
              id="onlyInactive"
              formData={filters}
              label="title.inactive"
            />
            <FormCheckbox
              id="onlyMy"
              formData={filters}
              label="title.show.only.my.users"
            />

            <Button
              onClicked={filtersOpen.toggle}
              label="title.filter"
              leftIcon={<TbFilter size={16} />}
              color="gray"
              className="ml-auto"
            />
          </div>

          <LinearLoader loading={users.isValidating} />

          <Table
            table={table}
            onRowClick={onOpenUser}
            onScrolledBottom={onScrolledToBottom}
          />
        </div>

        <FilterPanel open={filtersOpen}>
          <CompanyCombobox
            id="company"
            formData={filters}
            label="title.company"
            clearValue={[]}
            showClear
            multiple
          />
          <UserGroupCombobox
            id="userGroup"
            formData={filters}
            label="title.user.groups"
            clearValue={[]}
            showClear
            multiple
          />
          <FormDateInputRange
            label="title.last.seen"
            idFrom="lastSeenFrom"
            idTo="lastSeenTo"
            formData={filters}
            showClear
          />
          <FormCombobox
            id="status"
            formData={filters}
            label="title.status"
            data={Object.values(Registration)}
            getLabel={(l) => ls(`user.account.status.${l}`)}
            showClear
            multiple
            clearValue={[]}
          />
          <Button
            label="title.clear"
            color="gray"
            onClicked={filters.clear}
          />
        </FilterPanel>
      </div>
    </StaticPage>
  );
};

export default UsersPage;
