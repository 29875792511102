import { useEffect } from "react";

export const usePageTitle = (title?: string) => {
  useEffect(() => {
    if(title != undefined)
      document.title = `${title} - TimberBase`;

    return () => {
      document.title = "TimberBase";
    };
  }, [title]);
};
