import React, { FC, useEffect, useMemo, useRef } from "react";
import { ls } from "~/Locales";
import { useIsAdmin } from "~/Reducers/User";
import { ProductUfp } from "~/API";
import {params, useDebounce, useFormData, usePageTitle, useToggle} from "~/Hooks";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Button,
  Dialog,
  FormCheckbox,
  FormInput,
  Table,
} from "~/Components/UI";
import DateView from "~/Components/Views/DateView";
import { LinearLoader } from "~/Components/Loaders";
import {TbFilter, TbPlus} from "react-icons/tb";
import { useHistory } from "react-router-dom";
import { productsRootPath } from "~/Services/Routes";
import { ProductTypeCombobox } from "~/Components/Products/ProductTypeCombobox";
import AddProductDialog from "~/Pages/Products/ProductsPage/Components/AddProductDialog";
import { ProductV3Filters, useProductsV3 } from "~/Data/Products/Products";
import ProductFilters from "~/Pages/Products/ProductsPage/Components/ProductFilters";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";
import * as z from "zod";
import {useSortState} from "~/Hooks/useSortState";
import {trimUndefined} from "~/Utils";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
  productType: params.string,
  group: params.numberArray,
  category: params.stringArray,
  species: params.stringArray,
  surface: params.stringArray,
  finish: params.stringArray,
  grade: params.stringArray,
  dryness: params.stringArray,
  construction: params.stringArray,
  classCode: params.stringArray,
  lengthCode: params.stringArray,
});

const ProductsTab: FC = () => {
  usePageTitle("Products");

  const isAdmin = useIsAdmin();
  const addOpen = useToggle();
  const history = useHistory();

  const filterForm = useFormData<ProductV3Filters & { inactive: boolean }>({
    search: "",
    inactive: false,
    productType: "",
    group: [],
    category: [],
    species: [],
    surface: [],
    finish: [],
    grade: [],
    dryness: [],
    construction: [],
    classCode: [],
    lengthCode: [],
  }, { routeParser });
  const searchDebounce = useDebounce(filterForm.data.search);
  const showFilters = useToggle();
  const sort = useSortState();

  const scrollRef = useRef(null);
  useEffect(() => {
    products.setSize(1).then();
    scrollRef.current?.scrollTo?.(0, 0);
  }, [searchDebounce, filterForm.data.inactive]);
  const onScrolledToBottom = () => products.setSize(products.size + 1);

  const onRowClicked = (row: ProductUfp) => {
    history.push(`${productsRootPath}${row.id}`);
  };

  const products = useProductsV3(trimUndefined({
    ...filterForm.data,
    search: searchDebounce,
    active: !filterForm.data.inactive,
    ...sort.data,
  }), 40);

  const columns = useMemo(() => getColumns(), []);
  const data = useMemo(() => (products.data || []).flat(), [products.data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex-row gap-lg flex overflow-hidden">
      <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
        <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
          {isAdmin && (
            <Dialog
              title={ls("title.add.product")}
              open={addOpen}
              trigger={
                <Button
                  leftIcon={<TbPlus size={16} />}
                  label="title.add.product"
                />
              }
            >
              <AddProductDialog />
            </Dialog>
          )}
          <FormInput
            id="search"
            formData={filterForm}
            showClear
            type="search"
            placeholder="title.search"
            className="desktop-w-min-8xl"
          />
          <ProductTypeCombobox
            id="productType"
            formData={filterForm}
            showClear
            clearValue=""
            placeholder="title.product.type"
            className="w-min-6xl"
          />
          <FormCheckbox
            id="inactive"
            formData={filterForm}
            label="title.inactive"
          />

          <Button
            leftIcon={<TbFilter size={16} />}
            label="title.filter"
            color="gray"
            className="ml-auto"
            onClicked={showFilters.toggle}
          />
        </div>

        <LinearLoader loading={products.isValidating} />
        <Table
          table={table}
          onScrolledBottom={onScrolledToBottom}
          scrollRef={scrollRef}
          onRowClick={onRowClicked}
          sort={sort}
        />
      </div>
      <ProductFilters
        filterForm={filterForm}
        showFilters={showFilters}
      />
    </div>
  );
};

export default ProductsTab;

const columnHelper = createColumnHelper<ProductUfp>();

const getColumns = () => [
  columnHelper.accessor("productCode", {
    header: ls("title.code"),
    size: 0,
    sortId: "product_code",
  }),
  columnHelper.accessor("description", {
    header: ls("title.description"),
    size: 0,
    sortId: "description",
    cell: (data) => (
      <div className="text-no-wrap">
        <ProductCatalogueDescriptionLink
          description={data.row.original.description}
          productCode={data.row.original.productCode}
          hasSyndigo={data.row.original.hasSyndigo}
          productId={data.row.original.id}
        />
      </div>
    ),
  }),
  columnHelper.accessor("productGroup", {
    header: ls("title.product.group"),
    size: 512,
    sortId: "product_group_id",
  }),
  columnHelper.accessor("standardUnitOfMeasure", {
    header: ls("title.uom"),
    size: 0,
    sortId: "standard_unit_of_measure",
  }),
  columnHelper.accessor("dateUpdated", {
    header: ls("title.date.last.updated"),
    size: 0,
    cell: (data) => <DateView date={data.getValue()} />,
    sortId: "date_updated",
  }),
];
