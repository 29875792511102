import { FC, useMemo } from "react";
import { useIsAdmin, useIsLogistics } from "~/Reducers/User";
import API, { DutyCostView } from "~/API";
import { LC, ls } from "~/Locales";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ActiveField,
  Card,
  editControlsColumn,
  FormCheckbox,
  FormInput,
  Table,
} from "~/Components/UI";
import PriceView from "~/Components/Views/PriceView";
import { LinearLoader } from "~/Components/Loaders";
import {params, useDebounce, useFormData, usePageTitle} from "~/Hooks";
import useSWR from "swr";
import { useFormRowEdit } from "~/Hooks/useFormRowEdit";
import { useSortState } from "~/Hooks/useSortState";
import { trimUndefined } from "~/Utils";
import { AddDutyDialog } from "~/Pages/Logistics/Duties/AddDutyDialog";
import * as z from "zod";
import {trackEvent} from "~/Services/Tracking";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
});

const DutiesCostPage: FC = () => {
  usePageTitle("Duties");

  const isAdmin = useIsAdmin();
  const isLogistics = useIsLogistics();
  const canEdit = isAdmin || isLogistics;

  const sort = useSortState();
  const filters = useFormData<{ search?: string, inactive?: boolean }>({
    search: "",
    inactive: false,
  }, { routeParser });
  const searchDebounce = useDebounce(filters.data.search);

  const duties = useSWR(
    ["api/duties", filters.data.inactive, searchDebounce, sort.data],
    () =>
      API.getDutyCosts({
        search: searchDebounce,
        inactive: filters.data.inactive,
        ...trimUndefined(sort.data),
      }),
  );

  const editRow = useFormRowEdit<DutyCostView>("htsNumber", async (data) => {
    const newDutyCostParams = {
      ...data,
      softwoodLumberFeeMbfCurrency: "USD",
      softwoodLumberFeeCbmCurrency: "USD",
    };

    Object.keys(newDutyCostParams).forEach((key) => {
      if (newDutyCostParams[key] === "") {
        newDutyCostParams[key] = null;
      }
    });

    await API.updateDutyCost({ htsNumber: data.htsNumber }, newDutyCostParams);
    await duties.mutate();
    trackEvent("Duty Cost Updated");
  });

  const data = useMemo(() => duties.data?.flat() || [], [duties.data]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        right: ["editControls"],
      },
    },
    meta: {
      editRow,
    },
  });

  return (
    <Card className="flex flex-col gap-sm overflow-hidden">
      <div className="flex-row gap-md flex-wrap">
        {canEdit && <AddDutyDialog refreshDuties={duties.mutate} />}

        <FormInput
          id="search"
          formData={filters}
          placeholder="search.by.hts"
          containerClassName="w-8xl"
          type="search"
          showClear
        />

        <FormCheckbox id="inactive" formData={filters} label="title.inactive" />
      </div>

      <LinearLoader loading={duties.isValidating} />
      <Table table={table} sort={sort} />
    </Card>
  );
};

export default DutiesCostPage;

const columnHelper = createColumnHelper<DutyCostView>();

const columns = [
  columnHelper.accessor("active", {
    header: () => ls("title.active"),
    size: 0,
    cellMemo: ({ value }) => <ActiveField active={value} />,
    editCell: ({ editRow }) => (
      <FormCheckbox id="active" formData={editRow.form} />
    ),
  }),
  columnHelper.accessor("htsNumber", {
    header: ls("hts.number"),
    sortId: "hts_number",
    size: 0,
  }),
  columnHelper.accessor("dutyRate", {
    header: ls("duty.rate"),
    sortId: "duty_rate",
    size: 0,
    cellMemo: ({ value }) => (value != undefined ? <>{value}%</> : "-"),
    editCell: ({ editRow }) => (
      <FormInput
        id="dutyRate"
        type="number"
        rightIcon={<div className="color-text-3 px-md">%</div>}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("section301AdditionalTariffChina", {
    header: () => (
      <div className="text-pre-wrap">
        <LC id="section.301.tariff" />
      </div>
    ),
    sortId: "section_301_additional_tariff_china",
    size: 150,
    cellMemo: ({ value }) => (value != undefined ? <>{value}%</> : "-"),
    editCell: ({ editRow }) => (
      <FormInput
        id="section301AdditionalTariffChina"
        type="number"
        rightIcon={<div className="color-text-3 px-md">%</div>}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("softwoodLumberFeeMbf", {
    header: () => (
      <div className="text-pre-wrap">
        <LC id="softwood.lumber.fee.per.mbf" />
      </div>
    ),
    sortId: "softwood_lumber_fee_mbf",
    size: 150,
    cellMemo: ({ value }) => <PriceView price={value} />,
    editCell: ({ editRow }) => (
      <FormInput
        id="softwoodLumberFeeMbf"
        type="number"
        rightIcon={<div className="color-text-3 px-md">$</div>}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("softwoodLumberFeeCbm", {
    header: () => (
      <div className="text-pre-wrap">
        <LC id="softwood.lumber.fee.per.cbm" />
      </div>
    ),
    sortId: "softwood_lumber_fee_cbm",
    size: 150,
    cellMemo: ({ value }) => <PriceView price={value} />,
    editCell: ({ editRow }) => (
      <FormInput
        id="softwoodLumberFeeCbm"
        type="number"
        rightIcon={<div className="color-text-3 px-md">$</div>}
        formData={editRow.form}
      />
    ),
  }),
  columnHelper.accessor("addCvdNotCalculated", {
    header: () => (
      <div className="text-pre-wrap">
        <LC id="add.cvd.not.calculated" />
      </div>
    ),
    size: 150,
    sortId: "add_cvd_not_calculated",
    editCell: ({ editRow }) => (
      <FormInput id="addCvdNotCalculated" formData={editRow.form} />
    ),
  }),
  columnHelper.accessor("brazilUnderGspNotCalculated", {
    header: () => (
      <div className="text-pre-wrap">
        <LC id="brazil.under.gsp.not.calculated" />
      </div>
    ),
    size: 150,
    sortId: "brazil_under_gsp_not_calculated",
    editCell: ({ editRow }) => (
      <FormInput id="brazilUnderGspNotCalculated" formData={editRow.form} />
    ),
  }),
  editControlsColumn(columnHelper),
];
