import { FC } from "react";

const OrderPositionVolume: FC<{
  volume: number | null;
  volumeUom: string;
  metricQuantity: number | null;
  priceUom: string;
}> = ({ volume, volumeUom, metricQuantity, priceUom }) => {
  if (priceUom === "M3" && metricQuantity) {
    return <span className="text-no-wrap">{metricQuantity.toFixed(4)} M3</span>;
  } else if (priceUom !== "M3" && volume) {
    return (
      <span className="text-no-wrap">
        {volume.toFixed(4)} {volumeUom}
      </span>
    );
  } else {
    return <span>-</span>;
  }
};

export default OrderPositionVolume;
