import { useParams } from "react-router-dom";
import { LC } from "~/Locales";
import { usersRootPath } from "~/Services/Routes";
import UserName from "~/Components/Old/UserName";
import UserDetailsPageProfileTab from "./Components/UserDetailsPageProfileTab";
import { FC } from "react";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {useUser} from "~/Data/Users";
import {LoadingPage} from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";

const UserDetailsPage: FC = () => {
  const { id: userId } = useParams<{ id: string }>();
  const user = useUser(+userId);

  if (user.isValidating) return <LoadingPage />;

  if (user.error)
    return (
      <EndpointErrorPage
        status={user.error.status}
        item="user"
      />
    );

  return (
    <ScrollPage title={`${user.data.name} ${user.data.surname}`}>
      <PageTitle type="back" parentUrl={usersRootPath} />
      <div className="flex-row text-left text-xl mx-lg mt-lg mb-xs">
        <LC id="title.user" />
        &nbsp;
        {userId}
        &nbsp;-&nbsp;
        <UserName id={userId} />
      </div>

      <UserDetailsPageProfileTab user={user.data} />
    </ScrollPage>
  );
};

export default UserDetailsPage;
