import { FC } from "react";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {cn} from "~/Utils";

interface PriceLabelProps {
  price: number | string;
  currency?: string;
  decimals?: number;
  showDash?: boolean;
  className?: string;
}

const PriceView: FC<PriceLabelProps> = ({ price, currency, decimals = 2, showDash = true, className }) => {
  const currencySymbol = useFindCurrencySymbol(currency);

  if (price === null || price === undefined) return showDash ? <>-</> : null;

  const priceFormatted = formattedNumber(price, decimals);
  const finalPrice = isNaN(Number(price)) ? '-' : `${currencySymbol}` + priceFormatted;

  return (
    <span
      className={cn(
        "color-orange-1 no-wrap",
        className,
      )}
    >
      {finalPrice}
    </span>
  );
};

export default PriceView;
