import API, {Length} from "~/API";
import {useLengthsByProductGroup} from "~/Data/Products/Lengths";
import {ApiDataCombobox, ExtendDataCombobox} from "~/Components/UI/Forms/ApiDataCombobox";

export const LengthByProductGroupCombobox = <T,>(
  props: {
    groupId: number,
  } & ExtendDataCombobox<T>,
) => (
  <ApiDataCombobox<T, any, Length>
    {...props}
    getLabel={(l) => l.value}
    getId={(l) => l.id}
    useListData={useLengthsByProductGroup}
    getSingle={(id) => API.getLength({ id })}
    filters={{ groupId: props.groupId, inactive: false }}
  />
);