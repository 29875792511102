import { useCallback } from "react";
import { offersRootPath } from "~/Services/Routes";
import { Button } from "~/Components/UI";
import { useHistory } from "react-router-dom";


export const FindMyCustomersButton = ({ offerId }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.replace(`${offersRootPath}${offerId}/matching`);
  }, [history, offerId]);

  return (
    <Button
      onClicked={onClick}
      label="title.find.my.customers"
    />
  );
}