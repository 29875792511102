import { FC } from "react";
import { Case, Dialog, Match } from "~/Components/UI";
import API, { LandingPage } from "~/API";
import { LandingPagePreviewForm } from "./LandingPagePreview";
import LandingPageEditForm from "./LandingPageEditForm";
import { ToggleProps, useAsync, useToggle } from "~/Hooks";

const LandingPageCard: FC<{
  landingPage?: LandingPage;
  open: ToggleProps;
}> = (props) => {
  const editing = useToggle(false);

  const onOpenChange = async (_open: boolean) => editing.toggleFalse();

  if (props.landingPage === undefined)
    return <div/>

  return (
    <Dialog
      trigger={<></>}
      title={props.landingPage.name}
      onOpenChange={onOpenChange}
      open={props.open}
    >
      <DialogBody landingPage={props.landingPage} editing={editing} />
    </Dialog>
  );
};

// own component so we don't fetch attachments while the dialog is closed
const DialogBody: FC<{
  landingPage: LandingPage;
  editing: ToggleProps;
}> = (props) => {
  const attachments = useAsync(() =>
    API.getLandingPageAttachments({ id: props.landingPage.id }),
  );

  return (
    <Match on={props.editing.value}>
      <Case v={false}>
        <LandingPagePreviewForm
          attachments={attachments.value || []}
          editingTrue={props.editing.toggleTrue}
          landingPage={props.landingPage}
        />
      </Case>

      <Case v={true}>
        <LandingPageEditForm
          attachments={attachments.value || []}
          editingFalse={props.editing.toggleFalse}
          landingPage={props.landingPage}
          loadAttachments={attachments.refetch}
        />
      </Case>
    </Match>
  );
};

export default LandingPageCard;
