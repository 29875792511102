import { QuoteStatus } from "~/API";
import {FC, RefObject, useCallback, useMemo} from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath, offersRootPath, requestsRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from '~/Utils/PriceCalculation';
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  useQuote,
  useQuoteValue,
  useQuoteProductCodes,
  getQuotesForOffer,
  getQuotesForRequest, isQuoteExpired
} from "~/Reducers/Quotes";
import {
  useQuotesPageReduxState,
  useQuotesPageByGroupReduxState,
  useReference,
  useDefaultPageFilters
} from "../../Reducer";
import Button from "~/Components/Old/Button";
import { DateTimeView } from "~/Components/Views/DateView";
import { ArrowDownIcon, ArrowRightIcon, HidePassIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import {QuoteShowUnread, QuoteStatusLabel} from "../QuotesHelper";
import QuotesPageEmptyState from "../QuotesPageEmptyState";
import QuoteArchiveDeleteButton from "../QuoteArchiveDeleteButtons";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useFilters } from "~/Components/Old/useFilters";
import { classNames } from "react-ui-basics/Tools";
import dayjs from "dayjs";
import './QuotesPageTableWithSubrows.scss';
import {useAMUserNameStrings} from "~/Reducers/Users";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";


const QuotesPageByReferenceTable: FC<{
  scrollRef: RefObject<HTMLDivElement>,
  onScrolledToBottom: () => void
}> = ({ scrollRef, onScrolledToBottom }) => {
  const { ids, loading } = useQuotesPageReduxState();
  const emptyState = ids?.length === 0 && !loading;

  return emptyState
    ? <QuotesPageEmptyState />
    : <>
        {loading && <div className={'progress-bar'}><LinearProgress /></div>}
        <ScrollableForPagination ref={scrollRef} className={'flex bg-black-12 overflow-auto'} onScrolledToBottom={onScrolledToBottom}>
          <table className={'basic-table basic-table-clickable QuotesPageTableWithSubrows'}>
            <thead>
              <tr>
                <th />
                <th><LocalizedMessage id={'title.id'} /></th>
                <th><LocalizedMessage id={'title.type'} /></th>
                <th><LocalizedMessage id={'title.quotes'} /></th>
                <th><LocalizedMessage id={'title.buyer'} /></th>
                <th><LocalizedMessage id={'title.products'} /></th>
                <th className={'text-right'}><LocalizedMessage id={'title.value'} /></th>
                <th><LocalizedMessage id={'title.date.inco.destination'} /></th>
                <th><LocalizedMessage id={'title.validity.date'} /></th>
                <th />
                <th><LocalizedMessage id={'title.actions'} /></th>
                <th />
              </tr>
            </thead>
            {ids.map(referenceId => <ReferenceRow key={referenceId} {...{ referenceId }} />)}
          </table>
        </ScrollableForPagination>
      </>;
};

export default QuotesPageByReferenceTable;


const ReferenceRow: FC<{ referenceId: number }> = ({ referenceId }) => {
  const history = useHistory();
  const reference = useReference(referenceId) || undefined;

  const [expanded, expandCollapse] = useToggle(false);

  const onOpen = useCallback(() => {
    history.push(`${reference.type === "OFFER" ? offersRootPath : requestsRootPath}${referenceId}`);
  }, [referenceId, history]);

  const { numberOfQuotes, buyerName, numberOfProducts, totalValue, quoteCurrency, dateIncoDestination, validityDate } = reference;
  const currencySymbol = useFindCurrencySymbol(quoteCurrency);

  return (
    <>
      <tbody>
        <tr onClick={expandCollapse} className={'ClickableHeaderOrRow'}>
          <td>{expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}</td>
          <td>{reference.type === "OFFER" ? "O" : "RO"}-{referenceId}</td>
          <td>{reference.type}</td>
          <td>{numberOfQuotes}</td>
          <td>{buyerName}</td>
          <td>{numberOfProducts}</td>
          <td className={'text-right color-orange-1 no-wrap'}>{`${currencySymbol} ${formattedNumber(totalValue)}`}</td>
          <td>{!!dateIncoDestination ? dayjs(dateIncoDestination).format('DD MMM YYYY') : '-'}</td>
          {/*<td>{!!validityDate ? dayjs(validityDate).format('DD MMM YYYY') : '-'}</td>*/}
          <td><DateTimeView date={validityDate} /></td>
          <td />
          <td>
            <div className={'flex-row align-center justify-center'}>
              <Button view={'text'} className={'ActionButton'} onClick={onOpen}>
                <HidePassIcon size={20} />
              </Button>
            </div>
          </td>
          <td />
        </tr>
      </tbody>
      {expanded && <QuotesForReference referenceId={referenceId} type={reference.type} />}
    </>
  );
};

const QUOTES_PER_PAGE = 10;
const filtersToExclude = ['offer', 'request', 'limit'];

const QuotesForReference: FC<{ referenceId: number, type: "OFFER" | "REQUEST" }> = ({ referenceId, type }) => {
  const pageDataByGroupId = useQuotesPageByGroupReduxState(`${type[0]}${referenceId}`);

  const defaultPageFilters = useDefaultPageFilters();

  const pageFilters = useMemo(() => {
    return type === 'OFFER'
      ? { ...defaultPageFilters, offer: [referenceId], limit: QUOTES_PER_PAGE }
      : { ...defaultPageFilters, request: [referenceId], limit: QUOTES_PER_PAGE };
  }, [referenceId, defaultPageFilters, type]);

  const pageDataByStatusReduxFunc = useCallback(() => pageDataByGroupId, [pageDataByGroupId]);
  const getDataFunc = useMemo(() => type === "OFFER" ? getQuotesForOffer : getQuotesForRequest, [type]);

  const { onScrolledToBottom, canLoadMore } = useFilters(pageFilters, getDataFunc, pageDataByStatusReduxFunc, null, null, filtersToExclude);

  const { ids = [], loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  return (
    <tbody>
      <tr className={'QuoteHeaderRowAsSubrow'}>
        <td />
        <td><LocalizedMessage id={'title.q.id'} /></td>
        <td><LocalizedMessage id={'title.status'} /></td>
        <td><LocalizedMessage id={'title.ship.to'} /></td>
        <td><LocalizedMessage id={'title.customer'} /></td>
        <td colSpan={2}><LocalizedMessage id={'title.salesperson'} /></td>
        <td><LocalizedMessage id={'title.products'} /></td>
        <td><LocalizedMessage id={'title.product.code'} /></td>
        <td><LocalizedMessage id={'title.validity.date'} /></td>
        <td className={'text-right'}><LocalizedMessage id={'title.value'} /></td>
        <td><LocalizedMessage id={'title.actions'} /></td>
        <td />
      </tr>
      {ids.map(quoteId => <QuoteRow key={quoteId} groupId={referenceId} {...{ quoteId }} />)}

      {showAdditionalRow &&
        <tr className={'QuoteRowAsSubrow'}>
          {!showLoader &&
            <td colSpan={12} className={'MoreButtonCell'}>
              <Button view={'text'} className={classNames('w-full', showTryAgain && 'DeleteButtonText')}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage id={showTryAgain ? 'title.try.again' : 'title.get.more'} />
              </Button>
            </td>
          }
          {showLoader && <td colSpan={12}><LinearProgress /></td>}
        </tr>
      }
    </tbody>
  );
};

const QuoteRow: FC<{ quoteId: number, groupId: number }> = ({ quoteId, groupId }) => {
  const history = useHistory();
  const quote = useQuote(quoteId) || undefined;
  const value = useQuoteValue(quoteId);
  const productCodes = useQuoteProductCodes(quoteId);
  const { status, customerCompanyName, shipToName, currency,
    offerProductCount, products, validityDate, accountManagerName, shipToAccountManagerName } = quote;
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = status === QuoteStatus.DRAFT;

  const onOpen = useCallback(() => history.push(`${quotesRootPath}${quoteId}`), [quoteId, history]);

  const isExpired = isQuoteExpired(validityDate);
  const managers = useAMUserNameStrings(accountManagerName, shipToAccountManagerName);

  return (
    <tr className={'QuoteRowAsSubrow'} onClick={onOpen}>
      <td className={classNames(QuoteShowUnread(quote.readMark, isExpired) ? "row-unread" : "")}/>
      <td>{`Q-${quoteId}`}</td>
      <td>{isExpired ? 'Expired' : <QuoteStatusLabel status={status} />}</td>
      <td>{shipToName}</td>
      <td>{customerCompanyName}</td>
      <td colSpan={2}>{managers}</td>
      <td>{`${products?.length || 0}/${offerProductCount}`}</td>
      <td>{productCodes}</td>
      <td><DateTimeView date={validityDate} /></td>
      <td className={'text-right no-wrap color-orange-1'}>
        {`${currencySymbol} ${formattedNumber(value) || '0'}`}
      </td>
      <td>
        <div className={'flex-row align-center justify-center'}>
          <QuoteArchiveDeleteButton {...{ quote, groupId }} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={quote.unreadMessagesCount} />}</td>
    </tr>
  );
};
