import {FC} from "react";
import {Button} from "~/Components/UI";
import {TbFilter} from "react-icons/tb";

export const ToggleFilterButton: FC<{
  onClicked: () => void;
}> = (props) => {
  return (
    <Button
      leftIcon={<TbFilter size={16} />}
      color="gray"
      onClicked={props.onClicked}
      label="title.filter"
    />
  )
}