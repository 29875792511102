import {
  purchaseOrderStatuses,
  purchaseOrderStatusLabels,
} from "~/Data/Orders/OrderStatuses";
import {FormCombobox, FormComboboxProps} from "~/Components/UI";

const PurchaseOrderStatusSelect = <T,>(
  props: Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  return (
    <FormCombobox
      {...props}
      data={purchaseOrderStatuses}
      getLabel={(val) => purchaseOrderStatusLabels[val]}
    />
  );
};

export default PurchaseOrderStatusSelect;
