import { Button, DeleteDialog, Dialog, For, Show } from "~/Components/UI";
import { TbMessage2 } from "react-icons/tb";
import { FC } from "react";
import {
  useChatCreate,
  useChatRemoveParticipant,
  useChatUpdate,
} from "~/Data/Chats";
import { useCurrentUser } from "~/Reducers/User";
import { ChatType, ChatUnreadView } from "~/API";
import { UserCombobox } from "~/Components/Users/UserCombobox";
import {useFormData, useToggle} from "~/Hooks";
import { UserCard } from "~/Components/Users/UserCard";
import {useHistory} from "react-router-dom";
import {chatsRootPathInternal} from "~/Services/Routes";

export const NewChatButton = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const dialogOpen = useToggle();

  const addUserForm = useFormData({ user: undefined });
  const newChatForm = useFormData({ users: [currentUser.id] });

  const createChat = useChatCreate();

  const onAddUser = () => {
    newChatForm.onDataChange("users", [
      ...newChatForm.data.users,
      addUserForm.data.user,
    ]);
    addUserForm.clear();
  };

  const onRemoveUser = (id: number) => {
    newChatForm.onDataChange(
      "users",
      newChatForm.data.users.filter((user) => user !== id),
    );
  };

  const onChatCreate = async () => {
    const newChat = await createChat.call({
      title: "group chat",
      internal: true,
      type: ChatType.DIRECT,
      participants: newChatForm.data.users,
    });

    dialogOpen.toggleFalse();
    history.push(`${chatsRootPathInternal}${newChat.id}`);
  };

  return (
    <Dialog
      title="New Chat"
      trigger={
        <Button leftIcon={<TbMessage2 size={16} />}
                label="title.new.chat"
                data-cy={"New Chat"}/>
      }
      open={dialogOpen}
    >
      <div className="w-min-9xl">
        <div className="flex-row w-full gap-sm">
          <UserCombobox
            formData={addUserForm}
            id="user"
            className="flex"
            placeholder="title.select.user"
            excludeUsers={newChatForm.data.users}
            data-cy = {"userComboBox"}
          />
          <Button
            onClicked={onAddUser}
            disabled={addUserForm.data.user === undefined}
            label="title.add"
            data-cy = {"addUserToChat"}
          />
        </div>

        <div className="flex-col gap-sm my-md">
          <For each={newChatForm.data.users}>
            {(user, i) => (
              <div className="flex-row my-xs">
                <UserCard id={user}/>
                <Show when={i != 0}>
                  <Button
                    variant="text"
                    color="red"
                    label="title.remove"
                    onClicked={() => onRemoveUser(user)}
                    data-cy = {"removeChat"}
                  />
                </Show>
              </div>
            )}
          </For>
        </div>

        <div className="flex-row justify-end">
          <Button
            label="title.create"
            disabled={newChatForm.data.users.length < 2}
            onClicked={onChatCreate}
            loading={createChat.isMutating}
            data-cy = {"createChat"}
          />
        </div>
      </div>
    </Dialog>
  );
};

export const LeaveChatButton: FC<{ chatId: number; internal: boolean }> = (
  props,
) => {
  const currentUser = useCurrentUser();
  const leaveChat = useChatRemoveParticipant(props.chatId);

  const onClick = async () => {
    await leaveChat.call({ userId: currentUser.id });
  };

  return (
    <DeleteDialog
      title="title.leave.chat"
      deleteText="title.leave"
      trigger={<Button color="red" label="title.leave" />}
      onDelete={onClick}
    >
      <div className="w-min-8xl flex-col gap-xxs">
        <div className="text-bold">
          Are you sure you want to leave this chat?
        </div>
        <div>
          You will be removed from this chat and all its linked objects (e.g.
          Requests, Orders).
        </div>
      </div>
    </DeleteDialog>
  );
};

export const ArchiveChatButton: FC<{ chat: ChatUnreadView }> = (props) => {
  const updateChat = useChatUpdate(props.chat.id);

  const onClick = async () => {
    await updateChat.call({ ...props.chat, archived: !props.chat.archived });
  };

  if (props.chat.archived) {
    return <Button color="gray" label="title.unarchive" onClicked={onClick} />;
  }

  return (
    <DeleteDialog
      title="title.archive.chat"
      deleteText="title.archive"
      trigger={<Button color="gray" label="title.archive" />}
      onDelete={onClick}
    >
      <div className="w-min-8xl flex-col gap-xxs">
        <div className="text-bold">
          Are you sure you want to archive this chat?
        </div>
        <div>You can set this chat to active later.</div>
      </div>
    </DeleteDialog>
  );
};
