import useSWRImmutable from "swr/immutable";
import API from "~/API";

export const useAttachmentThumbnail = (id: number) => useSWRImmutable(
  ["api/attachment/thumbnail", id],
  async () => API.urlAttachmentThumbnail(id)
);

export const useAttachmentFull = (id: number) => useSWRImmutable(
  ["api/attachment/data", id],
  async () => API.urlAttachmentOpen(id)
);

export const useAttachment = (id: number) => useSWRImmutable(
  ["api/attachment", id],
  async () => API.getAttachment({ id }),
);