import {
  DetailedHTMLProps,
  FC,
  Fragment,
  memo,
  ReactNode,
  Ref,
  TdHTMLAttributes,
} from "react";
import {
  ColumnHelper,
  DisplayColumnDef,
  flexRender,
  Row,
  RowData,
  Table as TanTable,
} from "@tanstack/react-table";
import { cn, onScrollToBottom } from "~/Utils";
import { Case, Match } from "~/Components/UI/ConditionalHelpers";
import {
  TbCheck,
  TbChevronDown,
  TbChevronRight,
  TbPencil,
  TbSelector,
  TbSortAscending,
  TbSortDescending,
  TbX,
} from "react-icons/tb";
import { Button } from "~/Components/UI/Buttons/Button";
import { SortState } from "~/Hooks/useSortState";
import { Order } from "~/API";
import { FormRowEditHook } from "~/Hooks/useFormRowEdit";

declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    editRow?: FormRowEditHook<TData>;
  }
}

declare module '@tanstack/react-table' {
  interface ColumnDefBase<TData extends RowData, TValue = unknown> {
    /**
     * Limits number of redraws compared to regular cell, by cutting the
     * table/column props and memo-izing, which can have big performance gains
     * on any cell that just needs the row/column value (especially when having a row edit form).
     * Only use the default cell if you need table's meta props, or the table doesn't rerender frequently
    **/
    cellMemo?: (props: { value: TValue, row: TData }) => ReactNode,

    /**
     * SortId gets used by sortState, which you can use to pass orderBy to the backend.
     * this should match the backend table column name
    **/
    sortId?: string;

    /**
     * Renders if the row is being edited instead of the default cell.
     * Put a `editControlsColumn()` in the columns definition and pass
     * `useFormRowEdit()` hook to the tables meta `editRow` to enable it.
    **/
    editCell?: (props: { editRow: FormRowEditHook<TData> }) => ReactNode;
  }
}

export const Table = <R,>(props: {
  table: TanTable<R>;
  className?: string;
  onRowClick?: (row: R, index: number) => void;
  onScrolledBottom?: () => void;
  scrollBottomOffset?: number;
  scrollRef?: Ref<any>;
  sort?: SortState;
  renderSubComponent?: (props: { row: Row<R> }) => ReactNode;
  footerComponent?: ReactNode;
}) => {
  const onRowPressedHandler = (e: any, row: any, index: number) => {
    if (e.keyCode === 13) {
      // enter key
      props.onRowClick?.(row, index);
    }
  };

  const onScroll = onScrollToBottom(() => {
    props.onScrolledBottom?.();
  }, props.scrollBottomOffset || 0);

  return (
    <div
      ref={props.scrollRef}
      onScroll={onScroll}
      className={cn("bg-black-12 overflow-auto relative", props.className)}
    >
      <table className=" text-left border-collapse w-full">
        <thead className="color-text-3 bg-black-12 sticky top-0 border-table-header z-10">
          {props.table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    "py-sm px-md upper-case text-no-wrap",
                    header.column.getIsPinned() == "left" &&
                      "sticky bg-black-12 border-table-header",
                    header.column.getIsPinned() == "right" &&
                      "sticky bg-black-12 border-table-header",
                  )}
                  style={{
                    width: header.column.columnDef.size,
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.column.columnDef.maxSize,
                    left: header.column.getIsPinned() == "left" && `${header.column.getStart('left')}px`,
                    right: header.column.getIsPinned() == "right" && `${header.column.getStart('right')}px`,
                  }}
                >
                  <div className="flex-row gap-xs align-center">
                    {(props.sort == undefined || header.column.columnDef.sortId == undefined) &&
                      (header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          ))}

                    {props.sort != undefined && header.column.columnDef.sortId != undefined && (
                      <Button
                        variant="text"
                        color="gray"
                        className={cn(
                          props.sort.data.orderBy == header.column.columnDef.sortId
                            ? "color-text-2 hover-color-text-1"
                            : "color-text-3 hover-color-text-2")}
                        onClicked={() => {
                          props.sort.sortClicked(header.column.columnDef.sortId)
                          props.table.options.meta?.editRow?.reset();
                        }}
                        rightIcon={
                          <div>
                            {props.sort.data.orderBy != header.column.columnDef.sortId ? (
                              <TbSelector size={16} />
                            ) : props.sort.data.order == Order.DESC ? (
                              <TbSortDescending size={16} />
                            ) : (
                              <TbSortAscending size={16} />
                            )}
                          </div>
                        }
                      >
                        <div className="upper-case text-no-wrap text-bold">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </div>
                      </Button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-cy="table-body">
          {props.table.getRowModel().rows.map((row, i) => (
            <Fragment key={row.id}>
              <tr
                onClick={() => props.onRowClick?.(row.original, i)}
                role={props.onRowClick ? "button" : "row"}
                tabIndex={props.onRowClick ? 0 : -1}
                onKeyDown={(e) => onRowPressedHandler(e, row.original, i)}
                className={cn(
                  props.onRowClick &&
                    "hover-bg-black-11-5 cursor-pointer focus-outline-none focus-bg-black-11-5",
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    onClick={(e) =>
                      cell.column.getIsPinned() && e.stopPropagation()
                    }
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                      left: cell.column.getIsPinned() == "left" && `${cell.column.getStart('left')}px`,
                      right: cell.column.getIsPinned() == "right" && `${cell.column.getStart('right')}px`,
                    }}
                    className={cn(
                      "py-xs px-md table-align-middle border-b-1 border-black-10",
                      cell.column.getIsPinned() == "left" &&
                        "sticky bg-black-12 shadow-inset-r-1",
                      cell.column.getIsPinned() == "right" &&
                        "sticky bg-black-12 shadow-inset-l-1",
                    )}
                  >
                    {(props.table.options.meta?.editRow?.rowIndex == row.original[props.table.options.meta?.editRow?.idColumn] &&
                      cell.column.columnDef.editCell != undefined) ? (
                        // render edit field
                        <TableCellEditMemo
                            cell={cell.column.columnDef.editCell}
                            editRow={props.table.options.meta?.editRow}
                            key={cell.id}
                          />
                      ) : (
                        cell.column.columnDef.cellMemo != undefined ? (
                          <TableCellMemo
                            cell={cell.column.columnDef.cellMemo}
                            value={cell.getContext().getValue()}
                            row={cell.getContext().row.original}
                            key={cell.id}
                          />
                        ) : (
                          // render default
                          <cell.column.columnDef.cell {...cell.getContext()} />
                        )
                      )
                    }
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && <props.renderSubComponent row={row} />}
            </Fragment>
          ))}
        </tbody>
        <tfoot>
          {props.table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <td key={header.id} className="py-sm px-md">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </td>
              ))}
            </tr>
          ))}
          {props.footerComponent}
        </tfoot>
      </table>
    </div>
  );
};

const TableCellEditMemo = memo(
  (props: {
    editRow: FormRowEditHook<any>,
    cell: (props: { editRow: FormRowEditHook<any> }) => ReactNode
  }) => (
    <props.cell editRow={props.editRow} />
  )
);

const TableCellMemo = memo(
  (props: {
    value: any,
    row: any,
    cell: (props: { value: any, row: any }) => ReactNode
  }) => (
    <props.cell value={props.value} row={props.row} />
  )
);

export const TableCell = memo(
  (
    props: DetailedHTMLProps<
      TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >,
  ) => (
    <td
      {...props}
      className={cn(
        "py-xs px-md table-align-middle border-b-1 border-black-10",
        props.className,
      )}
    >
      {props.children}
    </td>
  ),
);

export const editControlsColumn = <T,>(_helper: ColumnHelper<T>, canEdit?: (row: T) => boolean): DisplayColumnDef<T, any> => ({
  id: "editControls",
  header: "",
  size: 48,
  cell: ({ row, table: { options: { meta: { editRow } } } }) => (
    <>
      {(canEdit == undefined || canEdit(row.original)) && (
        <Button
          color="gray"
          variant="text"
          leftIcon={<TbPencil size={16} />}
          onClicked={() =>
            editRow?.setRowIndex(row.original[editRow?.idColumn], row.original)
          }
        />
      )}
    </>
  ),
  editCell: ({ editRow }) => (
    <div className="flex-row gap-xs">
      <Button
        color="blue"
        leftIcon={<TbCheck size={16} />}
        loading={editRow?.submit.loading}
        onClicked={editRow?.submit.mutate}
      />
      <Button
        color="red"
        leftIcon={<TbX size={16} />}
        disabled={editRow?.submit.loading}
        onClicked={editRow?.reset}
      />
    </div>
  )
});

export const ActiveField: FC<{ active: boolean }> = (props) => (
  <div>
    <Match on={props.active}>
      <Case v={true}>
        <TbCheck className="color-green-1" size={20} />
      </Case>
      <Case v={false}>
        <TbX className="color-red-1" size={20} />
      </Case>
    </Match>
  </div>
);

export const DropdownButton: FC<{ row: Row<any> }> = (props) => (
  <Button
    color="gray"
    variant="text"
    leftIcon={
      props.row.getIsExpanded() ? (
        <TbChevronDown size={16} />
      ) : (
        <TbChevronRight size={16} />
      )
    }
    onClicked={() => props.row.toggleExpanded()}
  />
);
