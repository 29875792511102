import React, {FC, useMemo} from "react";
import { LC, LocalizedMessageKey } from "~/Locales";
import {Button, Dialog, DescriptiveRadioGroup} from "~/Components/UI";
import {useMutate, useToggle} from "~/Hooks";
import {ChatView, NotificationLevelEnum} from "~/API";
import {getChatParticipantNotificationLevel, useUpdateChatParticipant} from "~/Data/Chats";
import {useCurrentUser} from "~/Reducers/User";
import {TbSettings} from "react-icons/tb";

export const FollowButton: FC<{
  isMember: boolean;
  onFollow: () => Promise<any>;
  onUnfollow: () => Promise<any>;
  followLabel: LocalizedMessageKey;
  unfollowDialogTitle: LocalizedMessageKey;
  unfollowDialogText: LocalizedMessageKey;
  chat: ChatView;
}> = (props) => {
  const follow = useMutate(props.onFollow);
  const open = useToggle(false);
  const currentUserId = useCurrentUser()?.id;
  const chat = props.chat;
  const currentNotificationLevel = getChatParticipantNotificationLevel(currentUserId, chat?.id);
  const setSelectedNotificationLevel = useUpdateChatParticipant(currentUserId, chat?.id)

  const notificationLevels: (NotificationLevelEnum | null) [] = useMemo(
      () => ([...Object.values(NotificationLevelEnum), null]),
      []
  );

  if (props.isMember) {
    return (
       <div className="flex-row">
         <div className="bg-black-10 rounded-l-xxs">
           <Button
             onClicked={props.onUnfollow}
             label={"title.unfollow"}
             variant={"text"}
             color={"gray"}
           />
         </div>
         <div className="bg-black-10 rounded-r-xxs border-l-1 border-black-9">
           <Dialog
               open={open}
               trigger={<Button leftIcon={<TbSettings size={24}/>} variant={"text"} color={"gray"}/>}
               title={<LC id={"title.notification.settings"}/>}
           >
             <div className="desktop-w-min-9xl flex-col gap-sm">
               <DescriptiveRadioGroup
                 id="radioNotificationPreferences"
                 data={notificationLevels}
                 onChange={(id, value) => setSelectedNotificationLevel.call(value)}
                 getLabel={(level) => <LC id={`title.notification.config.option.${level}` as LocalizedMessageKey}/>}
                 getDescription={(level) => <LC id={`title.notification.config.option.description.${level}` as LocalizedMessageKey}/>}
                 value={currentNotificationLevel.data || null}
               />
             </div>
           </Dialog>
         </div>
       </div>
  );
  }

  return (
    <Button
      label={props.followLabel}
      onClicked={follow.mutate}
      loading={follow.loading}
      color="blue"
      variant="text"
    />
  );
};
