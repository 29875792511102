import { useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { useCallback, useMemo } from "react";
import {isCurrentUserInternal, UserReducerState} from "~/Reducers/User";
import { checkIfUserIsInternal } from "~/Reducers/Users";

// --------- Shared between Products & My Products ---------
export enum ProductsTypePath {
  Lumber = 'lumber',
  Panels = 'panels',
  LinearFoot = 'linear-foot',
  Specialities = 'specialities',
}

export const offersRootPath = '/offers/';

export const requestsRootPath = '/requests/';
export const requestedOffersRootPath = '/requested-offers/';

export const myProductsRootPath = '/my-products/';

export const productsRootPath = '/products/';

export const productGroupsRootPath = '/product-groups/';

export const productCatalogueRootPath = '/product-catalogue/';

export const addOnCostsRootPath = '/addon-costs';
export const dutyCostsRootPath = '/duty-costs';

// --------- Other ---------
export const passwordRequirementsResetPath = '/password-requirements-reset';
export const productsBuyerRootPath = '/marketplace/';
export const dashboardRootPath = '/dashboard/';
export const productsSellerRootPath = '/my-products/';
export const companyRootPath = '/company/';
export const usersCMSRootPath = '/cms/users/';
export const productsCMSRootPath = '/cms/products/';

export const companiesRootPath = '/companies/';
export const companiesPathCustomers = 'customers';
export const companiesPathVendors = 'vendors';

export const locationsRootPath = '/locations/';
export const locationsPathShipTo = 'ship-to';
export const locationsPathShipFrom = 'ship-from';

export const usersRootPath = '/users/';
export const dictionaryRootPath = "/dictionary";
export const lengthsRootPath = '/lengths';
export const randomLengthsRootPath = "/random-lengths";

export const userGroupsRootPath = '/user-groups';
export const userPermissionsRootPath = '/user-permissions';
export const emailsRootPath = '/emails/';
export const offersCMSRootPath = '/cms/offers/';
export const chatsRootPath = '/chats/';
export const chatsRootPathInternal = '/chats/internal/';
export const chatsRootPathExternal = '/chats/external/';

export const landingPagesRootPath = '/landing-pages/';

export const JobSchedulerPath = '/job-scheduler/';

export const requestsBuyerRootPath = '/offers/';
export const requestsSellerRootPath = '/my-requests/';
export const customRequestsSellerRootPath = '/my-offers-custom/';
export const requestsCMSRootPath = '/cms/offers/';

export const ordersRootPath = '/orders/';
export const ordersPathOngoing = 'ongoing';
export const ordersPathCompleted = 'completed';
export const ordersPathSales = 'sales';
export const ordersPathPurchase = 'purchase';

export const quotesRootPath = '/quotes/';

export const getPathForSO = (orderId: number) => `${ordersRootPath}${ordersPathSales}/${orderId}`;
export const getPathForPO = (orderId: number) => `${ordersRootPath}${ordersPathPurchase}/${orderId}`;

export const portsRootPath = '/ports';

export const seaFreightCostsRootPath = '/logistics-costs';
export const seaFreightCostsImportRootPath = '/import-logistics-costs';

export const videoCallPath = 'videoCall';

export const getChatsPathForUser = async (userId: number, currentUser: UserReducerState) => {
  const isUserInternal = await checkIfUserIsInternal(userId);
  const isSelfInternal = isCurrentUserInternal(currentUser);

  const isInternal = isSelfInternal && isUserInternal;
  return getChatsPath(isInternal);
};

export const getChatsPath = (isInternal: boolean) => {
  return (isInternal) ? chatsRootPathInternal : chatsRootPathExternal;
};

export const useIsRFOPath = () => {
  const location = useLocation();
  const requestedOffersPath = requestedOffersRootPath.replace('/', '');
  return location.pathname.includes(requestedOffersPath);
}

export const useIsCompaniesPath = () => {
  const match = useRouteMatch();
  return match.path.includes(companiesRootPath);
};

export const useIsCompaniesCustomersPath = () => {
  const match = useRouteMatch();
  return (match.path.includes(companiesRootPath + companiesPathCustomers)
    || match.path.includes(companiesRootPath + companiesPathVendors));
};

type SearchParams = {
  [p: string]: string;
};

export const useSearchParams = (replaceUri = false): [SearchParams, (params: any) => void] => {
  const { search } = useLocation();

  const searchParams = useMemo((): SearchParams => {
    const urlParams = new URLSearchParams(search);
    return Object.fromEntries(urlParams);
  }, [search]);

  const history = useHistory();
  const setSearchParams = useCallback((params) => {
    if (!params) return;
    const search = new URLSearchParams(params).toString();
    if (replaceUri) {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, []);

  return [searchParams, setSearchParams];
};

export const useIsCompletedOrdersUrl = () => {
  const { status } = useParams<{status: string}>();
  return status?.toLowerCase() === ordersPathCompleted;
};
