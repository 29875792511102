import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { LC } from "~/Locales";
import { validPassword, zodMsg } from "~/Utils";
import { dashboardRootPath } from "~/Services/Routes";
import BaseLoginPage from "./BaseLoginPage";
import { useFormData, usePageTitle } from "~/Hooks";
import { useAppDispatch } from "~/StoreTypes";
import { Button, FormInput, Input } from "~/Components/UI";
import { z } from "zod";
import {useUpdateCurrentUser} from "~/Data/CurrentUser";
import {useCurrentUser} from "~/Reducers/User";

const formValidation = z
  .object({
    password: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Password"]))
      .refine((p) => validPassword(p), zodMsg("password.req.desc")),
    confirmPassword: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Confirm Password"])),
  })
  .refine((d) => d.confirmPassword === d.password, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const ResetPasswordForRequirementsPage: FC = () => {
  usePageTitle("Password Reset");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();

  const updateUser = useUpdateCurrentUser(dispatch);

  const formData = useFormData(
    {
      password: "",
      confirmPassword: "",
    },
    { validation: formValidation },
  );

  const onSubmit = () => {
    if (!formData.isValid()) return;

    updateUser
      .call({ ...currentUser, password: formData.data.password })
      .then(() => history.replace(dashboardRootPath));
  };

  return (
    <BaseLoginPage>
      <div className="text-2xl">Password Update</div>

      <div className="mt-lg flex-col gap-sm">
        <div>
          <LC id="password.req.not.met" />
        </div>
        <div>
          <LC id="password.req.desc" />
        </div>
      </div>

      <div className="mt-lg flex-col gap-sm">
        <Input
          id="username"
          disabled
          value={currentUser?.email}
          label="your.email"
        />

        <FormInput
          id="password"
          formData={formData}
          label="set.your.password"
          type="password"
        />
        <FormInput
          id="confirmPassword"
          formData={formData}
          label="confirm.your.password"
          type="password"
        />
      </div>

      <Button
        disabled={!formData.data.password || !formData.data.confirmPassword}
        loading={updateUser.isMutating}
        onClicked={onSubmit}
        className="w-full mt-lg"
        label="title.change.password"
      />
    </BaseLoginPage>
  );
};

export default ResetPasswordForRequirementsPage;
