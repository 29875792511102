import {FC} from "react";
import {LC} from "~/Locales";
import {SeaFreightCostIncoterm} from "~/API";

export const IncotermView: FC<{
  incoterm: SeaFreightCostIncoterm | null | undefined;
}> = (props) => {

  if (props.incoterm == null)
    return <div>-</div>

  return (
    <LC id={`incoterm.${props.incoterm}`} />
  );
}