import { FC, useEffect, useMemo, useRef } from "react";
import { ls } from "~/Locales";
import { useIsAdmin } from "~/Reducers/User";
import API, { Length, LengthUpdate, Order } from "~/API";
import {
  params,
  useDebounce,
  useFormData,
  usePageTitle,
  useToggle,
} from "~/Hooks";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ActiveField,
  Button,
  Dialog, editControlsColumn,
  FormCheckbox,
  FormInput,
  Table,
} from "~/Components/UI";
import DateView from "~/Components/Views/DateView";
import { LinearLoader } from "~/Components/Loaders";
import { TbPlus } from "react-icons/tb";
import { LengthAddForm } from "~/Pages/Products/LengthsPage/LengthAddForm";
import { useLengths } from "~/Data/Products/Lengths";
import { UnsavedChangesDialog } from "~/Components/Nav/UnsavedChangesDialog";
import * as z from "zod";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {trackEvent} from "~/Services/Tracking";
import {useSortState} from "~/Hooks/useSortState";
import {trimUndefined} from "~/Utils";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
});

const LengthsTab: FC = () => {
  usePageTitle("Lengths");

  const isAdmin = useIsAdmin();

  const addOpen = useToggle();

  const filterForm = useFormData<{
    search?: string,
    inactive?: boolean
  }>({
    search: "",
    inactive: false,
  }, { routeParser });
  const searchDebounce = useDebounce(filterForm.data.search);

  const scrollRef = useRef(null);
  useEffect(() => {
    lengths.setSize(1).then();
    scrollRef.current?.scrollTo?.(0, 0);
  }, [searchDebounce, filterForm.data.inactive]);
  const sort = useSortState();
  const onScrolledToBottom = () => lengths.setSize(lengths.size + 1);

  const lengths = useLengths(trimUndefined({
    search: searchDebounce,
    inactive: filterForm.data.inactive,
    orderBy: "value",
    order: Order.ASC,
    ...sort.data,
  }));

  const editRow = useFormRowEdit<Length>("id", async (data) => {
    await API.updateLength(
      { id: data.id },
      data as LengthUpdate,
    );
    await lengths.mutate();
    trackEvent("Length Updated");
  });

  const columns = useMemo(() => getColumns(), []);

  const data = useMemo(() => (lengths.data || []).flat(), [lengths.data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editRow,
    },
    initialState: {
      columnVisibility: {
        edit: isAdmin,
      },
      columnPinning: {
        right: ["edit"],
      },
    },
  });

  return (
    <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
      {editRow.rowIndex != null && (
        <UnsavedChangesDialog onSave={editRow.submit.mutate} />
      )}
      <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
        {isAdmin && (
          <Dialog
            title={ls("title.add.length")}
            open={addOpen}
            trigger={
              <Button
                leftIcon={<TbPlus size={16} />}
                label="title.add.length"
              />
            }
          >
            <LengthAddForm open={addOpen} refreshData={lengths.mutate} />
          </Dialog>
        )}
        <FormInput
          id="search"
          formData={filterForm}
          showClear
          type="search"
          placeholder="title.search"
          className="desktop-w-min-8xl"
        />
        <FormCheckbox
          id="inactive"
          formData={filterForm}
          label="title.inactive"
        />
      </div>

      <LinearLoader loading={lengths.isValidating} />
      <Table
        table={table}
        onScrolledBottom={onScrolledToBottom}
        scrollRef={scrollRef}
        sort={sort}
      />
    </div>
  );
};

export default LengthsTab;

const columnHelper = createColumnHelper<Length>();

const getColumns = () => [
  columnHelper.accessor("active", {
    header: ls("title.active"),
    size: 0,
    cellMemo: ({ value }) => (
        <ActiveField active={value} />
    ),
    editCell: ({ editRow }) => (
      <FormCheckbox id="active" formData={editRow.form} />
    ),
  }),
  columnHelper.accessor("unit", {
    header: ls("title.unit"),
    sortId: "unit",
  }),
  columnHelper.accessor("value", {
    header: ls("title.length"),
    sortId: "value",
    editCell: ({ editRow }) => (
      <FormInput
        id="value"
        formData={editRow.form}
        className="w-min-4xl"
      />
    ),
  }),
  columnHelper.accessor("actual", {
    header: ls("title.actual"),
    sortId: "actual",
    editCell: ({ editRow }) => (
      <FormInput
        id="actual"
        formData={editRow.form}
        className="w-min-4xl"
        type="number"
      />
    ),
  }),
  columnHelper.accessor("nominal", {
    header: ls("title.nominal"),
    sortId: "nominal",
    editCell: ({ editRow }) => (
      <FormInput
        id="nominal"
        formData={editRow.form}
        className="w-min-4xl"
        type="number"
      />
    ),
  }),
  columnHelper.accessor("dateUpdated", {
    header: ls("title.date.last.updated"),
    size: 0,
    sortId: "date_updated",
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
  editControlsColumn(columnHelper),
];
