import { useHistory } from "react-router-dom";
import BaseLoginPage from "../BaseLoginPage";
import { FC } from "react";
import { usePageTitle } from "~/Hooks";
import {Button} from "~/Components/UI";

const RegistrationAlreadyPage: FC = () => {
  usePageTitle("Registration");
  const history = useHistory();

  return (
    <BaseLoginPage>
      <p>Hello!</p>

      <div>
        It seems like you've clicked on the activation link again. Your account
        has already been activated and is ready to use! If you run into any
        problems or have any questions, feel free to get in touch with us at{" "}
        <a href="mailto:hello@timberbase.com">hello@timberbase.com</a>.
      </div>

      <p>
        Thank you for joining and we appreciate you being a part of Timberbase!
      </p>

      <div>Best regards,</div>
      <div>The TimberBase Team</div>

      <Button
        color="gray"
        className="w-full mt-xl"
        onClicked={() => history.push("/sign-in")}
        label="title.back.to.login"
      />
    </BaseLoginPage>
  );
};

export default RegistrationAlreadyPage;
