import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  offersRootPath,
  useSearchParams,
} from "~/Services/Routes";
import {
  LocalizedMessage,
  localizedString,
} from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import { closeLeftMenu } from "~/Reducers/LeftMenu";

import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import { useFilters } from "~/Components/Old/useFilters";
import { useGetOffersExcludingRFOStatuses } from "~/Reducers/Offers";
import { useIsMobile } from "~/Hooks/useIsMobile";
import {
  useIsAdmin,
  useIsLogistics,
  useIsUFPBuyer,
  useIsVendor,
} from "~/Reducers/User";
import {
  clearData,
  OffersPageTableTypes,
  useGetOffersByStatusSummary,
  useOffersPageReduxState,
} from "./Reducer";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import { EmptyState } from "~/Components/Old/EmptyStates/EmptyState";
import { EmptyRequestsImageLazy } from "~/Components/Old/EmptyStates/EmptyStatesLazy";
import FiltersButton from "~/Components/Old/FiltersButton";
import { PlusIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import RightPanel from "~/Components/Layout/RightPanel";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import TextField from "~/Components/Old/TextField";
import { OffersPageNoneGroupingTable } from "./Components/OffersPageNoneGroupingTable";
import Filters from "./Components/Filters";
import OffersPageByStatusTable from "./Components/OffersPageByStatusTable";
import { classNames } from "react-ui-basics/Tools";
import "./OffersPage.scss";
import {useAppDispatch} from "~/StoreTypes";

const OFFERS_PER_PAGE = 40;

const OffersPage: FC = () => {
  const dispatch = useAppDispatch();

  const [showFilters, showHideFilters] = useToggle(false);

  const isUFPBuyer = useIsUFPBuyer();
  const isVendor = useIsVendor();
  const isUFPAdmin = useIsAdmin();
  const isUFPLogistics = useIsLogistics();
  const canSeeAddOfferButton =
    isUFPBuyer || isVendor || isUFPAdmin || isUFPLogistics;
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, []);

  const [searchParams] = useSearchParams();
  const { onlyArchivedOrDeliveredOrExpired } = searchParams;
  const withExpired = onlyArchivedOrDeliveredOrExpired === "true";

  const pageFilters = useMemo(
    () => ({
      search: "",
      onlyMy: true,
      tableType: OffersPageTableTypes.OFFERS_PAGE_NONE,
      limit: OFFERS_PER_PAGE,
      orderBy: "id",
      status: [],
      buyer: [],
      product: [],
      productGroup: [],
      vendor: [],
      port: [],
      incoterm: [],
      shipFrom: [],
      valueFrom: "",
      valueTo: "",
      dateIncoDestinationFrom: "",
      dateIncoDestinationTo: "",
      validityDateFrom: "",
      validityDateTo: "",
      onlyArchivedOrDeliveredOrExpired: false,
      withoutArchivedOrDelivered: !withExpired,
      withExpired,
    }),
    [withExpired],
  );

  const getOffersByStatusSummary = useGetOffersByStatusSummary();
  const getOffersExcludingRFOStatuses = useGetOffersExcludingRFOStatuses();

  const apiFunc = useMemo(() => {
    return searchParams.tableType === OffersPageTableTypes.OFFERS_PAGE_STATUS
      ? getOffersByStatusSummary
      : getOffersExcludingRFOStatuses;
  }, [
    searchParams.tableType,
    getOffersByStatusSummary,
    getOffersExcludingRFOStatuses,
  ]);

  const { filters, setFilter, onScrolledToBottom, resetFiltersTo } = useFilters(
    pageFilters,
    apiFunc,
    useOffersPageReduxState,
  );

  const scrollRef = useRef(null);

  const history = useHistory();
  const onAdd = useCallback(() => {
    history.push(`${offersRootPath}new`);
    scrollRef?.current?.scrollTo(0, 0);
  }, [history]);

  const isMobileScreen = useIsMobile();

  const { ids, loading, error, statuses } = useOffersPageReduxState();

  const emptyState =
    filters.tableType === OffersPageTableTypes.OFFERS_PAGE_NONE
      ? ids.length === 0 && !loading && !error
      : statuses.length === 0 && !loading && !error;

  const progressBar = loading && !isMobileScreen;

  const onChangeTab = useCallback(
    (event) => {
      dispatch(clearData());
      setFilter(event);
    },
    [setFilter, dispatch],
  );

  return (
    <div className={"static-view offers-page"}>
      <PageTitleContainer title={<LocalizedMessage id={"title.offers"} />} />

      <div className={"full-page-table-card rounded-xxs"}>
        <div className={"flex flex-col overflow-hidden bg-black-12"}>
          {!isMobile && (
            <div className={"table-top-bar"}>
              {canSeeAddOfferButton && (
                <Button
                  view={"secondary"}
                  leftIcon={<PlusIcon size={16} />}
                  onClick={onAdd}
                  data-cy={"AddOfferButton"}
                >
                  Add Offer
                </Button>
              )}
              <TextField
                id={"search"}
                showClearButton
                placeholder={localizedString(
                  "placeholder.search.by.code.and.description",
                )}
                className={classNames("filter-search")}
                type={"search"}
                onChange={setFilter}
                value={filters.search}
              />
              <div className={"flex-row align-center ml-xs"}>
                <Checkbox
                  id={"onlyArchivedOrDeliveredOrExpired"}
                  value={filters.onlyArchivedOrDeliveredOrExpired}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.expired"} />
              </div>
              <div className={"flex-row align-center ml-xs"}>
                <Checkbox
                  id={"onlyMy"}
                  value={filters.onlyMy}
                  onChange={setFilter}
                />
                My offers
              </div>
              <SpaceFiller />
              <OffersPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />

              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {isMobile && (
            <div className={"table-top-bar flex-row"}>
              <OffersPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <TextField
                id={"search"}
                showClearButton
                placeholder={localizedString("placeholder.search")}
                className={"flex"}
                type={"search"}
                onChange={setFilter}
                value={filters.search}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          <ScrollableForPagination
            estimatedElementHeight={50}
            numberOfElementsBeforeLoading={10}
            ref={scrollRef}
            className={"flex bg-black-12 overflow-auto"}
            onScrolledToBottom={onScrolledToBottom}
          >
            {progressBar && (
              <div className={"progress-bar"}>
                <LinearProgress />
              </div>
            )}
            {emptyState ? (
              <OffersEmptyState />
            ) : (
              <>
                {filters.tableType ===
                  OffersPageTableTypes.OFFERS_PAGE_NONE && (
                  <OffersPageNoneGroupingTable ids={ids} />
                )}
                {filters.tableType ===
                  OffersPageTableTypes.OFFERS_PAGE_STATUS && (
                  <OffersPageByStatusTable
                    filters={filters}
                    pageFilters={{ ...pageFilters }}
                  />
                )}
              </>
            )}
          </ScrollableForPagination>
        </div>

        <RightPanel visible={showFilters}>
          <Filters
            {...{
              showHideFilters,
              filters,
              setFilter,
              pageFilters,
              resetFiltersTo,
            }}
          />
        </RightPanel>
      </div>

      {isMobile && (
        <div className={"bottom-right-container"}>
          {canSeeAddOfferButton && (
            <Button leftIcon={<PlusIcon size={16} />} onClick={onAdd}>
              Add Offer
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default OffersPage;

const OffersPageTableTypeTabs: FC<{
  value: OffersPageTableTypes;
  onChange: (value: OffersPageTableTypes) => void;
}> = ({ value, onChange }) => {
  const selectedTab = useMemo(
    () => Object.values(OffersPageTableTypes).indexOf(value),
    [value],
  );

  const onChangeTab = useCallback(
    (event) => {
      onChange({
        ...event,
        target: { id: "tableType", value: event.target.id },
      });
    },
    [onChange],
  );

  return (
    <div className={"RequestsPageTableTypeTabs"}>
      <Button
        id={OffersPageTableTypes.OFFERS_PAGE_NONE}
        view={selectedTab === 0 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 0 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.none"} />
      </Button>
      <Button
        id={OffersPageTableTypes.OFFERS_PAGE_STATUS}
        view={selectedTab === 1 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 1 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.status"} />
      </Button>
    </div>
  );
};

const OffersEmptyState = () => {
  return (
    <EmptyState
      title={"No Offers"}
      description={"There are no offers matching your search criteria."}
      image={<EmptyRequestsImageLazy />}
    />
  );
};
