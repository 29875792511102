import { VideoCallIconButton } from "~/Components/Chats/VideoCallButton";
import ChatOpenButton from "~/Components/Chats/ChatOpenButton";
import DateView, {DateTimeView} from "~/Components/Views/DateView";
import { ls } from "~/Locales";
import {UserStatusBadge} from "~/Pages/Users/Components/UserStatusBadge";
import UserGroupName from "~/Components/Users/UserGroupName";
import { Link } from "react-router-dom";
import CompanyView from "~/Components/Views/CompanyView";
import {companiesRootPath} from "~/Services/Routes";
import {UserAvatarDirect} from "~/Components/Users/UserAvatar";
import {Button} from "~/Components/UI";
import API, {UserAccountView} from "~/API";
import {createColumnHelper} from "@tanstack/react-table";
import {trackEvent} from "~/Services/Tracking";

const columnHelper = createColumnHelper<UserAccountView>();

export const UsersTableColumns = [
  columnHelper.display({
    id: "loginAs",
    cell: ({ row: { original } }) => {
      const onLoginAsUserClick = async (_e: any) => {
        const login = await API.loginAsUser({ id: original.id });
        localStorage.setItem("LoginAsUser", JSON.stringify(login));
        window.open("/", "_blank");
        trackEvent("Login As User");
      };

      return (
        <Button
          label="title.login.as"
          color="gray"
          className="text-no-wrap"
          onClicked={onLoginAsUserClick}
        />
      );
    },
    size: 0,
  }),
  columnHelper.display({
    id: "avatar",
    cell: ({ row: { original } }) => (
      <UserAvatarDirect
        user={original}
      />
    ),
    size: 0,
  }),
  columnHelper.display({
    id: "name",
    header: ls("title.name"),
    cell: ({ row: { original } }) => (
      <div className="text-no-wrap">
        {original.name}
        {" "}
        {original.surname}
      </div>
    ),
    size: 256,
  }),
  columnHelper.accessor("email", {
    header: ls("title.email"),
    cell: (data) => (
      <div className="text-no-wrap">
        {data.getValue()}
      </div>
    ),
    size: 256,
  }),
  columnHelper.accessor("companyId", {
    header: ls("title.company"),
    cell: (data) => (
      <Link
        to={`${companiesRootPath}${data.getValue()}`}
        onClick={(e) => e.stopPropagation()}
        className="text-no-wrap"
      >
        <CompanyView id={data.getValue()} />
      </Link>
    ),
  }),
  columnHelper.accessor("userGroupId", {
    header: ls("title.user.group"),
    cell: (data) => (
      <div className="text-no-wrap">
        <UserGroupName id={data.getValue()} />
      </div>
    ),
  }),
  columnHelper.accessor("registrationStatus", {
    header: ls("title.status"),
    cell: (data) => (
      <UserStatusBadge
        status={data.getValue()}
      />
    ),
  }),
  columnHelper.accessor("lastActivityDate", {
    header: ls("title.last.seen"),
    cell: (data) => (
      <div className="text-no-wrap">
        <DateTimeView date={data.getValue()}/>
      </div>
    ),
  }),
  columnHelper.accessor("dateCreated", {
    header: ls("title.date.created"),
    cell: (data) => (
      <div className="text-no-wrap">
        <DateView date={data.getValue()}/>
      </div>
    ),
  }),
  columnHelper.display({
    id: "controls",
    cell: ({ row: { original } }) => (
      <div className="flex-row gap-xxs">
        <ChatOpenButton
          userId={original.id}
        />
        <VideoCallIconButton
          userId={original.id}
        />
      </div>
    ),
    size: 0,
  }),
];