import {FC} from "react";
import API from "~/API";
import {useLandingPageByName} from "~/Data/Admin/LandingPages";

const LandingPageComponent: FC<{ name: string }> = ({name}) => {
  const landingPage = useLandingPageByName(name);

  if (landingPage.data === undefined)
    return (<div className="flex flex-col bg-black-11-5"></div>)

  return (
    <div className="flex flex-col px-3xl py-4xl bg-black-11-5 mobile-hide">
      <div className="text-3xl">{landingPage.data.title}</div>
      {Array.isArray(landingPage.data.attachments) && landingPage.data.attachments.length !== 0 &&
        <img
          className="flex w-full min-h-2xl h-full fit-contain"
          alt=""
          src={API.urlAttachmentOpen(landingPage.data.attachments[landingPage.data.selectedImage].id)}
        />
      }
    </div>
  )
}

export default LandingPageComponent;