import { FC, Fragment, memo, useMemo } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import {
  offersRootPath,
  quotesRootPath,
  requestedOffersRootPath,
} from "~/Services/Routes";
import { useOfferAsReferenceText } from "~/Reducers/Offers";
import { useQuoteAsReferenceText } from "~/Reducers/Quotes";
import Button from "~/Components/Old/Button";

const SHORT_LIST_LENGTH = 4;

const RequestReference: FC<{
  quotes?: number[];
  offers?: number[];
  showDash?: boolean;
  simple?: boolean;
}> = ({ quotes = [], offers = [], showDash = true, simple = false }) => {
  const [showMore, toggleShowMore] = useToggle(false);

  const shouldShowMoreButton =
    quotes.length + offers.length > SHORT_LIST_LENGTH;

  const offersToShow = useMemo(() => {
    if (showMore || offers.length <= SHORT_LIST_LENGTH) return offers;
    return offers.slice(0, SHORT_LIST_LENGTH);
  }, [showMore, offers]);

  const quotesToShow = useMemo(() => {
    if (showMore || !shouldShowMoreButton) return quotes;
    return quotes.slice(0, SHORT_LIST_LENGTH - offersToShow.length);
  }, [showMore, shouldShowMoreButton, quotes, offersToShow]);

  if (!offers.length && !quotes.length) {
    return showDash ? <>{"-"}</> : null;
  }

  if (simple)
    return (
      <>
        {offers.map((offerId, index) => (
          <Fragment key={offerId}>
            {index !== 0 && <>{","}&nbsp;</>}
            <a
              href={`${offersRootPath}${offerId}`}
              target="_blank"
              rel="noreferrer noopener"
              onClick={stopPropagation}
            >
              {`O-${offerId}`}
            </a>
          </Fragment>
        ))}
        {quotes.map((quoteId, index) => (
          <Fragment key={quoteId}>
            {(index !== 0 || !!offers.length) && <>{","}&nbsp;</>}
            <a
              href={`${quotesRootPath}${quoteId}`}
              target="_blank"
              rel="noreferrer noopener"
              onClick={stopPropagation}
            >
              {`Q-${quoteId}`}
            </a>
          </Fragment>
        ))}
      </>
    );

  return (
    <div className={"flex-col"}>
      {offersToShow.map((offerId, index) => (
        <OfferReferenceText
          key={offerId}
          offerId={offerId}
          addBR={index !== 0}
        />
      ))}
      {quotesToShow.map((quoteId, index) => (
        <QuoteReferenceText
          key={quoteId}
          quoteId={quoteId}
          addBR={index !== 0 || !!offersToShow.length}
        />
      ))}
      {shouldShowMoreButton && (
        <div>
          <Button
            view={"text"}
            onClick={toggleShowMore}
            className={"px-0 mt-xs"}
          >
            <LocalizedMessage
              id={showMore ? "title.show.less" : "title.show.more"}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default RequestReference;

const stopPropagation = (event: any) => event.stopPropagation();

const OfferReferenceText = memo<{
  offerId: number;
  addBR?: boolean;
}>(({ offerId, addBR }) => {
  const text = useOfferAsReferenceText(offerId);
  const rootPath = text.startsWith("RO")
    ? requestedOffersRootPath
    : offersRootPath;
  return (
    <>
      {addBR && <br />}
      <a
        href={`${rootPath}${offerId}`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {text}
      </a>
    </>
  );
});

const QuoteReferenceText = memo<{
  quoteId: number;
  addBR?: boolean;
}>(({ quoteId, addBR }) => {
  const text = useQuoteAsReferenceText(quoteId);
  return (
    <>
      {addBR && <br />}
      <a
        href={`${quotesRootPath}${quoteId}`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {text}
      </a>
    </>
  );
});
