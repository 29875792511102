import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import {
  requestedOffersRootPath,
  requestsRootPath,
  useSearchParams,
} from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { closeLeftMenu } from "~/Reducers/LeftMenu";
import {
  getRequestsForQuote,
  getShipToGroupsForRequestsForQuote,
} from "~/Reducers/RequestsForQuote";
import {
  useIsAdmin,
  useIsCustomer,
  useIsInternalUser,
  useIsUFPAM,
} from "~/Reducers/User";
import { RequestedOffersPageTableTypes } from "./OffersPage/Reducer";
import {
  clearData,
  RequestsPageTableTypes,
  useDefaultPageFilters,
  useRequestsForQuotePageReduxState,
} from "./Reducer";
import RequestsPageRequestsTable from "./Components/Tables/RequestsPageRequestsTable";
import RequestsPageByStatusTable from "./Components/Tables/RequestsPageByStatusTable";
import RequestsPageByShipToTable from "./Components/Tables/RequestsPageByShipToTable";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import FiltersButton from "~/Components/Old/FiltersButton";
import DetailsTabs from "~/Components/Old/DetailsTabs";
import { PlusIcon } from "~/Components/Old/Icons";
import PageTitleContainer from "~/Components/Old/PageTitleContainer";
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import TextField from "~/Components/Old/TextField";
import { useFilters } from "~/Components/Old/useFilters";
import Filters from "./Components/Filters";
import { classNames } from "react-ui-basics/Tools";
import "./RequestsPage.scss";
import { useIsMobile } from "~/Hooks/useIsMobile";
import {useAppDispatch} from "~/StoreTypes";

const SELECTED_TAB = 0;

const shouldSkipLoading = (previousLocation: string) => {
  const pattern = new RegExp(`^${requestsRootPath}\\d+(\\/.*)?$`);
  return pattern.test(previousLocation);
};

const RequestsPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showFilters, showHideFilters] = useToggle(false);
  const scrollRef = useRef();

  const [searchParams] = useSearchParams();

  const isInternalUser = useIsInternalUser();

  const getAPIFunc = useMemo(() => {
    switch (searchParams.tableType) {
      case RequestsPageTableTypes.REQUESTS_PAGE_STATUS:
        return null;
      case RequestsPageTableTypes.REQUESTS_PAGE_SHIP_TO:
        return getShipToGroupsForRequestsForQuote;
      default:
        return getRequestsForQuote;
    }
  }, [searchParams.tableType]);

  const pageFilters = useDefaultPageFilters();

  const { filters, setFilter, onScrolledToBottom, resetFiltersTo } = useFilters(
    pageFilters,
    getAPIFunc,
    useRequestsForQuotePageReduxState,
    shouldSkipLoading,
    scrollRef,
  );

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, []);

  const onAdd = useCallback(() => {
    history.push(`${requestsRootPath}new/edit`);
  }, [history]);

  const onChangeTab = useCallback(
    (event) => {
      dispatch(clearData());
      setFilter(event);
    },
    [setFilter, dispatch],
  );

  const onChangeRequestsTab = useCallback(
    (index) => {
      if (index !== SELECTED_TAB) {
        history.replace(
          `${requestedOffersRootPath}?tableType=${RequestedOffersPageTableTypes.REQUESTED_OFFERS_PAGE_NONE}`,
        );
      }
    },
    [history],
  );

  const isAdmin = useIsAdmin();
  const isAm = useIsUFPAM();
  const isCustomer = useIsCustomer();
  const shouldDisplayCreateButton = isCustomer || isAdmin || isAm; // Buyer can't request quotes
  const isMobile = useIsMobile();

  return (
    <div className={"static-view requests-page"}>
      <PageTitleContainer title={<LocalizedMessage id={"title.requests"} />} />

      {isInternalUser && (
        <DetailsTabs
          value={SELECTED_TAB}
          onChange={onChangeRequestsTab}
          className={"BasicPageTabsPanel"}
        >
          <LocalizedMessage id={"title.customers"} />
          <LocalizedMessage id={"title.vendors"} />
        </DetailsTabs>
      )}

      <div className={"full-page-table-card"}>
        <div className={"flex flex-col overflow-hidden"}>
          {!isMobile && (
            <div className={"table-top-bar"}>
              {shouldDisplayCreateButton && (
                <Button
                  view={"secondary"}
                  onClick={onAdd}
                  leftIcon={<PlusIcon size={16} />}
                  data-cy={"RequestQuoteButton"}
                >
                  <LocalizedMessage id={"title.request.quote"} />
                </Button>
              )}

              <TextField
                id={"search"}
                showClearButton
                placeholder={"Search by ID, Product and Description"}
                className={"filter-search mr-xs"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />

              <div className={"flex-row align-center"}>
                <Checkbox
                  id={"onlyArchivedOrDeliveredOrExpired"}
                  value={filters.onlyArchivedOrDeliveredOrExpired}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.expired"} />
              </div>
              <div className={"flex-row align-center ml-xs"}>
                <Checkbox
                  id={"onlyMy"}
                  value={filters.onlyMy}
                  onChange={setFilter}
                />
                <LocalizedMessage id={"title.show.only.my.requests"} />
              </div>
              <SpaceFiller />
              <RequestsPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {isMobile && (
            <div className={"table-top-bar flex-row"}>
              <RequestsPageTableTypeTabs
                value={filters.tableType}
                onChange={onChangeTab}
              />
              <TextField
                id={"search"}
                showClearButton
                placeholder={"Search"}
                className={"flex"}
                type={"search"}
                onChange={setFilter}
                inputStyle={{ height: "30px" }}
                value={filters.search}
              />
              <FiltersButton
                showFilters={showFilters}
                toggleShowFilters={showHideFilters}
              />
            </div>
          )}

          {filters.tableType === RequestsPageTableTypes.REQUESTS_PAGE_NONE && (
            <RequestsPageRequestsTable {...{ scrollRef, onScrolledToBottom }} />
          )}
          {filters.tableType ===
            RequestsPageTableTypes.REQUESTS_PAGE_STATUS && (
            <RequestsPageByStatusTable {...{ filters }} />
          )}
          {filters.tableType ===
            RequestsPageTableTypes.REQUESTS_PAGE_SHIP_TO && (
            <RequestsPageByShipToTable {...{ scrollRef, onScrolledToBottom }} />
          )}
        </div>

        <RightPanel visible={showFilters}>
          <Filters
            {...{
              showHideFilters,
              filters,
              setFilter,
              pageFilters,
              resetFiltersTo,
            }}
          />
        </RightPanel>
      </div>

      {isMobile && shouldDisplayCreateButton && (
        <div className={"bottom-right-container"}>
          <Button onClick={onAdd} leftIcon={<PlusIcon size={16} />}>
            <LocalizedMessage id={"title.request.quote"} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default RequestsPage;

const RequestsPageTableTypeTabs: FC<{
  value: RequestsPageTableTypes;
  onChange: (newVal: any) => void;
}> = ({ value, onChange }) => {
  const selectedTab = useMemo(
    () => Object.values(RequestsPageTableTypes).indexOf(value),
    [value],
  );

  const onChangeTab = useCallback(
    (event) => {
      onChange({
        ...event,
        target: { id: "tableType", value: event.target.id },
      });
    },
    [onChange],
  );

  const isCustomer = useIsCustomer();

  return (
    <div className={"RequestsPageTableTypeTabs"}>
      <Button
        id={RequestsPageTableTypes.REQUESTS_PAGE_NONE}
        view={selectedTab === 0 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 0 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.none"} />
      </Button>
      <Button
        id={RequestsPageTableTypes.REQUESTS_PAGE_STATUS}
        view={selectedTab === 1 ? "secondary" : "text"}
        className={classNames(
          "MobileTabButton",
          selectedTab !== 1 && "MobileTabButtonInactive",
        )}
        onClick={onChangeTab}
      >
        <LocalizedMessage id={"title.status"} />
      </Button>
      {!isCustomer && (
        <Button
          id={RequestsPageTableTypes.REQUESTS_PAGE_SHIP_TO}
          view={selectedTab === 2 ? "secondary" : "text"}
          className={classNames(
            "MobileTabButton",
            selectedTab !== 2 && "MobileTabButtonInactive",
          )}
          onClick={onChangeTab}
        >
          <LocalizedMessage id={"title.ship.to"} />
        </Button>
      )}
    </div>
  );
};
