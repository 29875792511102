import { ProductType } from "~/Reducers/Products";
import { memo, useCallback } from "react";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import Checkbox from "~/Components/Old/Checkbox";
import { LengthsView } from "~/Components/Old/LengthsView";
import TextField from "~/Components/Old/TextField";
import { UOMSelect } from "~/Components/Old/UOMSelect";
import {RequestForQuoteProductView} from "~/API";


const ProductRow = memo<{
  product: any;
  currency: string;
  onProductFieldChange: (groupIndex: number, productIndex: number, id: string, value: any) => void;
  groupIndex: number;
  productIndex: number;
  requestProduct: RequestForQuoteProductView;
}>((props) => {
  const { product, currency, onProductFieldChange, groupIndex, productIndex, requestProduct } = props;

  const {
    uom = '',
    description = '',
    lengths = [],
    quantity = null,
    price = 0,
    selected = false,
    unitsContainer = 1,
    productCode,
    productType,
  } = product;

  const currencySymbol = useFindCurrencySymbol(currency);

  const containers = (Number(quantity || 0) / unitsContainer).toFixed(1);

  const onFieldChange = useCallback(({ target }) => {
    onProductFieldChange(groupIndex, productIndex, target.id, target.value);
  }, [onProductFieldChange, groupIndex, productIndex]);

  const onCheckboxChange = ({ target }) => {
    onProductFieldChange(groupIndex, productIndex, 'selected', target.checked);
  };

  const lengthsValues = lengths.map(l => l.value).join(', ');

  const isUomReadOnly = productType === ProductType.LINEAR_FOOTAGE || productType === ProductType.SPECIAL_PRODUCTS;

  return (
    <tr className={'MatchingProductsRow'}>
      <td></td>
      <td><Checkbox value={!!selected} onChange={onCheckboxChange} /></td>
      <td>{productCode}</td>
      <td colSpan={2}>{description}</td>
      <td>
        {!!requestProduct?.lengthIds?.length ? <LengthsView lengthIds={requestProduct?.lengthIds} /> : '-'}
      </td>
      <td><div className={'LengthCell'}></div>{lengthsValues}</td>
      <td><div className={'LengthCell'}><LengthsView lengthIds={requestProduct?.lengthIds} /></div></td>
      <td>
        {selected
          ? <TextField id="quantity"
            type={'number'} hideArrows float
            min={0} max={99999999}
            className={'ValueField MidsizeField'}
            value={quantity}
            onChange={onFieldChange}
          />
          : quantity}
      </td>
      <td>{containers}</td>
      <td>
        {selected
          ? <UOMSelect id="uom"
            className={'ValueField'}
            value={uom}
            productType={productType}
            onChange={onFieldChange}
            disabled={isUomReadOnly}
          />
          : uom
        }
      </td>
      <td className={'text-right color-orange-1 no-wrap'}>
        {selected
          ? <TextField id="price"
            type={'number'} hideArrows float
            min={0} max={99999999}
            className={'ValueField MidsizeField'}
            value={price}
            onChange={onFieldChange}
          />
          : `${currencySymbol}${price}`
        }
      </td>
    </tr>
  );
});

export default ProductRow;
