import {FC} from "react";

/**
 * @deprecated use the new `<LinearLoader />` component
 **/
const LinearProgress: FC = () => {
    return (
    <div className="w-full h-xxs rounded-xxs overflow-hidden">
      <div className="h-full w-full bg-blue-1 animate-linear-progress" />
    </div>
  );
};

export default LinearProgress;
