// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
import { fetch } from "~/API/ReST";

export const countryLabelToCode = (countryLabel: string) => {
  const foundCountry = countriesAndUnions.find(country => country.label === countryLabel);
  return (foundCountry ? foundCountry.code : null);
};

export const countryCodeToLabel = (countryCode: string) => {
  const foundCountry = countriesAndUnions.find(country => 
    (country.code.toLowerCase() === countryCode?.toLowerCase?.() || country.code3.toLowerCase() === countryCode?.toLowerCase?.())
  );
  return (foundCountry ? foundCountry.label : '');
};

export const add3digitCodesToArray = (codes: string[]) => {
  if (Array.isArray(codes)) {
    const updatedCodes = [];
    codes.forEach(code => {
      updatedCodes.push(code);
      const foundCountry = countries.find(country => country.code === code.toUpperCase());
      if (foundCountry?.code3) {
        updatedCodes.push(foundCountry.code3);
      }
    });
    return updatedCodes;
  }
  return codes;
};

export const getGeoInfo = (onSuccess: (info: {code: string, name: string}) => void) => {
  fetch('https://geolocation-db.com/json/', {
    withCredentials: false,
    onSuccess: (response) => {
      if (response && typeof (response.country_code) === 'string') {
        const normalizedCountry = countries.find(it => it.code === response.country_code);
        if (normalizedCountry) {
          onSuccess({
            code: normalizedCountry.code,
            name: normalizedCountry.id,
          });
        }
      }
    },
    onError: () => {
    },
  }).then();
};

const countryUnions = [
  { code: 'EU', code3: 'EU', label: 'European Union', phone: '', id: 'European Union' },
];
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
  { code: 'AD', code3: 'AND',label: 'Andorra', phone: '376', id: 'Andorra' },
  { code: 'AE', code3: 'ARE',label: 'United Arab Emirates', phone: '971', id: 'United Arab Emirates' },
  { code: 'AF', code3: 'AFG',label: 'Afghanistan', phone: '93', id: 'Afghanistan' },
  { code: 'AG', code3: 'ATG',label: 'Antigua and Barbuda', phone: '1-268', id: 'Antigua and Barbuda' },
  { code: 'AI', code3: 'AIA',label: 'Anguilla', phone: '1-264', id: 'Anguilla' },
  { code: 'AL', code3: 'ALB',label: 'Albania', phone: '355', id: 'Albania' },
  { code: 'AM', code3: 'ARM',label: 'Armenia', phone: '374', id: 'Armenia' },
  // { code: 'AN', code3: 'ANT', label: 'Netherlands Antilles', phone: '599', id: 'Netherlands Antilles' },
  { code: 'AO', code3: 'AGO',label: 'Angola', phone: '244', id: 'Angola' },
  { code: 'AQ', code3: 'ATA',label: 'Antarctica', phone: '672', id: 'Antarctica' },
  { code: 'AR', code3: 'ARG',label: 'Argentina', phone: '54', id: 'Argentina' },
  { code: 'AS', code3: 'ASM',label: 'American Samoa', phone: '1-684', id: 'American Samoa' },
  { code: 'AT', code3: 'AUT',label: 'Austria', phone: '43', id: 'Austria' },
  { code: 'AU', code3: 'AUS',label: 'Australia', phone: '61', suggested: true, id: 'Australia' },
  { code: 'AW', code3: 'ABW',label: 'Aruba', phone: '297', id: 'Aruba' },
  { code: 'AX', code3: 'ALA',label: 'Alland Islands', phone: '358', id: 'Åland Islands' },
  { code: 'AZ', code3: 'AZE',label: 'Azerbaijan', phone: '994', id: 'Azerbaijan' },
  { code: 'BA', code3: 'BIH',label: 'Bosnia and Herzegovina', phone: '387', id: 'Bosnia and Herzegovina' },
  { code: 'BB', code3: 'BRB',label: 'Barbados', phone: '1-246', id: 'Barbados' },
  { code: 'BD', code3: 'BGD',label: 'Bangladesh', phone: '880', id: 'Bangladesh' },
  { code: 'BE', code3: 'BEL',label: 'Belgium', phone: '32', id: 'Belgium' },
  { code: 'BF', code3: 'BFA',label: 'Burkina Faso', phone: '226', id: 'Burkina Faso' },
  { code: 'BG', code3: 'BGR',label: 'Bulgaria', phone: '359', id: 'Bulgaria' },
  { code: 'BH', code3: 'BHR',label: 'Bahrain', phone: '973', id: 'Bahrain' },
  { code: 'BI', code3: 'BDI',label: 'Burundi', phone: '257', id: 'Burundi' },
  { code: 'BJ', code3: 'BEN',label: 'Benin', phone: '229', id: 'Benin' },
  { code: 'BL', code3: 'BLM',label: 'Saint Barthelemy', phone: '590', id: 'Saint Barthélemy' },
  { code: 'BM', code3: 'BMU',label: 'Bermuda', phone: '1-441', id: 'Bermuda' },
  { code: 'BN', code3: 'BRN',label: 'Brunei Darussalam', phone: '673', id: 'Brunei' },
  { code: 'BO', code3: 'BOL',label: 'Bolivia', phone: '591', id: 'Bolivia' },
  { code: 'BQ', code3: 'BQ',label: 'Bonaire', phone: '599-7', id: 'Bonaire' },
  { code: 'BR', code3: 'BRA',label: 'Brazil', phone: '55', id: 'Brazil' },
  { code: 'BS', code3: 'BHS',label: 'Bahamas', phone: '1-242', id: 'Bahamas' },
  { code: 'BT', code3: 'BTN',label: 'Bhutan', phone: '975', id: 'Bhutan' },
  { code: 'BV', code3: 'BVT',label: 'Bouvet Island', phone: '47', id: 'Bouvet Island' },
  { code: 'BW', code3: 'BWA',label: 'Botswana', phone: '267', id: 'Botswana' },
  { code: 'BY', code3: 'BLR',label: 'Belarus', phone: '375', id: 'Belarus' },
  { code: 'BZ', code3: 'BLZ',label: 'Belize', phone: '501', id: 'Belize' },
  { code: 'CA', code3: 'CAN',label: 'Canada', phone: '1', suggested: true, id: 'Canada' },
  { code: 'CC', code3: 'CCK',label: 'Cocos (Keeling) Islands', phone: '61', id: 'Cocos (Keeling) Islands' },
  { code: 'CD', code3: 'COD',label: 'Congo, Democratic Republic of the', phone: '243', id: 'Democratic Republic of the Congo' },
  { code: 'CF', code3: 'CAF',label: 'Central African Republic', phone: '236', id: 'Central African Republic' },
  { code: 'CG', code3: 'COG',label: 'Congo, Republic of the', phone: '242', id: 'Congo' },
  { code: 'CH', code3: 'CHE',label: 'Switzerland', phone: '41', id: 'Switzerland' },
  { code: 'CI', code3: 'CIV',label: "Cote d'Ivoire", phone: '225', id: "Cote d'Ivoire" },
  { code: 'CK', code3: 'COK',label: 'Cook Islands', phone: '682', id: 'Cook Islands' },
  { code: 'CL', code3: 'CHL',label: 'Chile', phone: '56', id: 'Chile' },
  { code: 'CM', code3: 'CMR',label: 'Cameroon', phone: '237', id: 'Cameroon' },
  { code: 'CN', code3: 'CHN',label: 'China', phone: '86', id: 'China' },
  { code: 'CO', code3: 'COL',label: 'Colombia', phone: '57', id: 'Colombia' },
  { code: 'CR', code3: 'CRI',label: 'Costa Rica', phone: '506', id: 'Costa Rica' },
  { code: 'CU', code3: 'CUB',label: 'Cuba', phone: '53', id: 'Cuba' },
  { code: 'CV', code3: 'CPV',label: 'Cape Verde', phone: '238', id: 'Cape Verde' },
  { code: 'CW', code3: 'CUW',label: 'Curacao', phone: '599', id: 'Curaçao' },
  { code: 'CX', code3: 'CXR',label: 'Christmas Island', phone: '61', id: 'Christmas Island' },
  { code: 'CY', code3: 'CYP',label: 'Cyprus', phone: '357', id: 'Cyprus' },
  { code: 'CZ', code3: 'CZE',label: 'Czech Republic', phone: '420', id: 'Czech Republic' },
  { code: 'DE', code3: 'DEU',label: 'Germany', phone: '49', suggested: true, id: 'Germany' },
  { code: 'DJ', code3: 'DJI',label: 'Djibouti', phone: '253', id: 'Djibouti' },
  { code: 'DK', code3: 'DNK',label: 'Denmark', phone: '45', id: 'Denmark' },
  { code: 'DM', code3: 'DMA',label: 'Dominica', phone: '1-767', id: 'Dominica' },
  { code: 'DO', code3: 'DOM',label: 'Dominican Republic', phone: '1-809', id: 'Dominican Republic' },
  { code: 'DZ', code3: 'DZA',label: 'Algeria', phone: '213', id: 'Algeria' },
  { code: 'EC', code3: 'ECU',label: 'Ecuador', phone: '593', id: 'Ecuador' },
  { code: 'EE', code3: 'EST',label: 'Estonia', phone: '372', id: 'Estonia' },
  { code: 'EG', code3: 'EGY',label: 'Egypt', phone: '20', id: 'Egypt' },
  { code: 'EH', code3: 'ESH',label: 'Western Sahara', phone: '212', id: 'Western Sahara' },
  { code: 'ER', code3: 'ERI',label: 'Eritrea', phone: '291', id: 'Eritrea' },
  { code: 'ES', code3: 'ESP',label: 'Spain', phone: '34', id: 'Spain' },
  { code: 'ET', code3: 'ETH',label: 'Ethiopia', phone: '251', id: 'Ethiopia' },
  { code: 'FI', code3: 'FIN',label: 'Finland', phone: '358', id: 'Finland' },
  { code: 'FJ', code3: 'FJI',label: 'Fiji', phone: '679', id: 'Fiji' },
  { code: 'FK', code3: 'FLK',label: 'Falkland Islands (Malvinas)', phone: '500', id: 'Falkland Islands' },
  { code: 'FM', code3: 'FSM',label: 'Micronesia, Federated States of', phone: '691', id: 'Micronesia' },
  { code: 'FO', code3: 'FRO',label: 'Faroe Islands', phone: '298', id: 'Faroe Islands' },
  { code: 'FR', code3: 'FRA',label: 'France', phone: '33', suggested: true, id: 'France' },
  { code: 'GA', code3: 'GAB',label: 'Gabon', phone: '241', id: 'Gabon' },
  { code: 'GB', code3: 'GBR',label: 'United Kingdom', phone: '44', id: 'United Kingdom' },
  { code: 'GD', code3: 'GRD',label: 'Grenada', phone: '1-473', id: 'Grenada' },
  { code: 'GE', code3: 'GEO',label: 'Georgia', phone: '995', id: 'Georgia' },
  { code: 'GF', code3: 'GUF',label: 'French Guiana', phone: '594', id: 'French Guiana' },
  { code: 'GG', code3: 'GGY',label: 'Guernsey', phone: '44', id: 'Guernsey' },
  { code: 'GH', code3: 'GHA',label: 'Ghana', phone: '233', id: 'Ghana' },
  { code: 'GI', code3: 'GIB',label: 'Gibraltar', phone: '350', id: 'Gibraltar' },
  { code: 'GL', code3: 'GRL',label: 'Greenland', phone: '299', id: 'Greenland' },
  { code: 'GM', code3: 'GMB',label: 'Gambia', phone: '220', id: 'Gambia' },
  { code: 'GN', code3: 'GIN',label: 'Guinea', phone: '224', id: 'Guinea' },
  { code: 'GP', code3: 'GLP',label: 'Guadeloupe', phone: '590', id: 'Guadeloupe' },
  { code: 'GQ', code3: 'GNQ',label: 'Equatorial Guinea', phone: '240', id: 'Equatorial Guinea' },
  { code: 'GR', code3: 'GRC',label: 'Greece', phone: '30', id: 'Greece' },
  { code: 'GS', code3: 'SGS',label: 'South Georgia and the South Sandwich Islands', phone: '500', id: 'South Georgia and the South Sandwich Islands' },
  { code: 'GT', code3: 'GTM',label: 'Guatemala', phone: '502', id: 'Guatemala' },
  { code: 'GU', code3: 'GUM',label: 'Guam', phone: '1-671', id: 'Guam' },
  { code: 'GW', code3: 'GNB',label: 'Guinea-Bissau', phone: '245', id: 'Guinea-Bissau' },
  { code: 'GY', code3: 'GUY',label: 'Guyana', phone: '592', id: 'Guyana' },
  { code: 'HK', code3: 'HKG',label: 'Hong Kong', phone: '852', id: 'Hong Kong' },
  { code: 'HM', code3: 'HMD',label: 'Heard Island and McDonald Islands', phone: '672', id: 'Heard Island and McDonald Islands' },
  { code: 'HN', code3: 'HND',label: 'Honduras', phone: '504', id: 'Honduras' },
  { code: 'HR', code3: 'HRV',label: 'Croatia', phone: '385', id: 'Croatia' },
  { code: 'HT', code3: 'HTI',label: 'Haiti', phone: '509', id: 'Haiti' },
  { code: 'HU', code3: 'HUN',label: 'Hungary', phone: '36', id: 'Hungary' },
  { code: 'ID', code3: 'IDN',label: 'Indonesia', phone: '62', id: 'Indonesia' },
  { code: 'IE', code3: 'IRL',label: 'Ireland', phone: '353', id: 'Ireland' },
  { code: 'IL', code3: 'ISR',label: 'Israel', phone: '972', id: 'Israel' },
  { code: 'IM', code3: 'IMN',label: 'Isle of Man', phone: '44', id: 'Isle of Man' },
  { code: 'IN', code3: 'IND',label: 'India', phone: '91', id: 'India' },
  { code: 'IO', code3: 'IOT',label: 'British Indian Ocean Territory', phone: '246', id: 'British Indian Ocean Territory' },
  { code: 'IQ', code3: 'IRQ',label: 'Iraq', phone: '964', id: 'Iraq' },
  { code: 'IR', code3: 'IRN',label: 'Iran, Islamic Republic of', phone: '98', id: 'Iran' },
  { code: 'IS', code3: 'ISL',label: 'Iceland', phone: '354', id: 'Iceland' },
  { code: 'IT', code3: 'ITA',label: 'Italy', phone: '39', id: 'Italy' },
  { code: 'JE', code3: 'JEY',label: 'Jersey', phone: '44', id: 'Jersey' },
  { code: 'JM', code3: 'JAM',label: 'Jamaica', phone: '1-876', id: 'Jamaica' },
  { code: 'JO', code3: 'JOR',label: 'Jordan', phone: '962', id: 'Jordan' },
  { code: 'JP', code3: 'JPN',label: 'Japan', phone: '81', suggested: true, id: 'Japan' },
  { code: 'KE', code3: 'KEN',label: 'Kenya', phone: '254', id: 'Kenya' },
  { code: 'KG', code3: 'KGZ',label: 'Kyrgyzstan', phone: '996', id: 'Kyrgyzstan' },
  { code: 'KH', code3: 'KHM',label: 'Cambodia', phone: '855', id: 'Cambodia' },
  { code: 'KI', code3: 'KIR',label: 'Kiribati', phone: '686', id: 'Kiribati' },
  { code: 'KM', code3: 'COM',label: 'Comoros', phone: '269', id: 'Comoros' },
  { code: 'KN', code3: 'KNA',label: 'Saint Kitts and Nevis', phone: '1-869', id: 'Saint Kitts and Nevis' },
  { code: 'KP', code3: 'PRK',label: 'North Korea', phone: '850', id: 'North Korea' },
  { code: 'KR', code3: 'KOR',label: 'South Korea', phone: '82', id: 'South Korea' },
  { code: 'KW', code3: 'KWT',label: 'Kuwait', phone: '965', id: 'Kuwait' },
  { code: 'KY', code3: 'CYM',label: 'Cayman Islands', phone: '1-345', id: 'Cayman Islands' },
  { code: 'KZ', code3: 'KAZ',label: 'Kazakhstan', phone: '7', id: 'Kazakhstan' },
  { code: 'LA', code3: 'LAO',label: "Lao People's Democratic Republic", phone: '856', id: 'Laos' },
  { code: 'LB', code3: 'LBN',label: 'Lebanon', phone: '961', id: 'Lebanon' },
  { code: 'LC', code3: 'LCA',label: 'Saint Lucia', phone: '1-758', id: 'Saint Lucia' },
  { code: 'LI', code3: 'LIE',label: 'Liechtenstein', phone: '423', id: 'Liechtenstein' },
  { code: 'LK', code3: 'LKA',label: 'Sri Lanka', phone: '94', id: 'Sri Lanka' },
  { code: 'LR', code3: 'LBR',label: 'Liberia', phone: '231', id: 'Liberia' },
  { code: 'LS', code3: 'LSO',label: 'Lesotho', phone: '266', id: 'Lesotho' },
  { code: 'LT', code3: 'LTU',label: 'Lithuania', phone: '370', id: 'Lithuania' },
  { code: 'LU', code3: 'LUX',label: 'Luxembourg', phone: '352', id: 'Luxembourg' },
  { code: 'LV', code3: 'LVA',label: 'Latvia', phone: '371', id: 'Latvia' },
  { code: 'LY', code3: 'LBY',label: 'Libya', phone: '218', id: 'Libya' },
  { code: 'MA', code3: 'MAR',label: 'Morocco', phone: '212', id: 'Morocco' },
  { code: 'MC', code3: 'MCO',label: 'Monaco', phone: '377', id: 'Monaco' },
  { code: 'MD', code3: 'MDA',label: 'Moldova', phone: '373', id: 'Moldova' },
  { code: 'ME', code3: 'MNE',label: 'Montenegro', phone: '382', id: 'Montenegro' },
  { code: 'MF', code3: 'MAF',label: 'Saint Martin (French part)', phone: '590', id: 'Saint Martin' },
  { code: 'MG', code3: 'MDG',label: 'Madagascar', phone: '261', id: 'Madagascar' },
  { code: 'MH', code3: 'MHL',label: 'Marshall Islands', phone: '692', id: 'Marshall Islands' },
  { code: 'MK', code3: 'MKD',label: 'North Macedonia', phone: '389', id: 'Macedonia (FYROM)' },
  { code: 'ML', code3: 'MLI',label: 'Mali', phone: '223', id: 'Mali' },
  { code: 'MM', code3: 'MMR',label: 'Myanmar', phone: '95', id: 'Myanmar (Burma)' },
  { code: 'MN', code3: 'MNG',label: 'Mongolia', phone: '976', id: 'Mongolia' },
  { code: 'MO', code3: 'MAC',label: 'Macao', phone: '853', id: 'Macau' },
  { code: 'MP', code3: 'MNP',label: 'Northern Mariana Islands', phone: '1-670', id: 'Northern Mariana Islands' },
  { code: 'MQ', code3: 'MTQ',label: 'Martinique', phone: '596', id: 'Martinique' },
  { code: 'MR', code3: 'MRT',label: 'Mauritania', phone: '222', id: 'Mauritania' },
  { code: 'MS', code3: 'MSR',label: 'Montserrat', phone: '1-664', id: 'Montserrat' },
  { code: 'MT', code3: 'MLT',label: 'Malta', phone: '356', id: 'Malta' },
  { code: 'MU', code3: 'MUS',label: 'Mauritius', phone: '230', id: 'Mauritius' },
  { code: 'MV', code3: 'MDV',label: 'Maldives', phone: '960', id: 'Maldives' },
  { code: 'MW', code3: 'MWI',label: 'Malawi', phone: '265', id: 'Malawi' },
  { code: 'MX', code3: 'MEX',label: 'Mexico', phone: '52', id: 'Mexico' },
  { code: 'MY', code3: 'MYS',label: 'Malaysia', phone: '60', id: 'Malaysia' },
  { code: 'MZ', code3: 'MOZ',label: 'Mozambique', phone: '258', id: 'Mozambique' },
  { code: 'NA', code3: 'NAM',label: 'Namibia', phone: '264', id: 'Namibia' },
  { code: 'NC', code3: 'NCL',label: 'New Caledonia', phone: '687', id: 'New Caledonia' },
  { code: 'NE', code3: 'NER',label: 'Niger', phone: '227', id: 'Niger' },
  { code: 'NF', code3: 'NFK',label: 'Norfolk Island', phone: '672', id: 'Norfolk Island' },
  { code: 'NG', code3: 'NGA',label: 'Nigeria', phone: '234', id: 'Nigeria' },
  { code: 'NI', code3: 'NIC',label: 'Nicaragua', phone: '505', id: 'Nicaragua' },
  { code: 'NL', code3: 'NLD',label: 'Netherlands', phone: '31', id: 'Netherlands' },
  { code: 'NO', code3: 'NOR',label: 'Norway', phone: '47', id: 'Norway' },
  { code: 'NP', code3: 'NPL',label: 'Nepal', phone: '977', id: 'Nepal' },
  { code: 'NR', code3: 'NRU',label: 'Nauru', phone: '674', id: 'Nauru' },
  { code: 'NU', code3: 'NIU',label: 'Niue', phone: '683', id: 'Niue' },
  { code: 'NZ', code3: 'NZL',label: 'New Zealand', phone: '64', id: 'New Zealand' },
  { code: 'OM', code3: 'OMN',label: 'Oman', phone: '968', id: 'Oman' },
  { code: 'PA', code3: 'PAN',label: 'Panama', phone: '507', id: 'Panama' },
  { code: 'PE', code3: 'PER',label: 'Peru', phone: '51', id: 'Peru' },
  { code: 'PF', code3: 'PYF',label: 'French Polynesia', phone: '689', id: 'French Polynesia' },
  { code: 'PG', code3: 'PNG',label: 'Papua New Guinea', phone: '675', id: 'Papua New Guinea' },
  { code: 'PH', code3: 'PHL',label: 'Philippines', phone: '63', id: 'Philippines' },
  { code: 'PK', code3: 'PAK',label: 'Pakistan', phone: '92', id: 'Pakistan' },
  { code: 'PL', code3: 'POL',label: 'Poland', phone: '48', id: 'Poland' },
  { code: 'PM', code3: 'SPM',label: 'Saint Pierre and Miquelon', phone: '508', id: 'Saint Pierre and Miquelon' },
  { code: 'PN', code3: 'PCN',label: 'Pitcairn Islands', phone: '870', id: 'Pitcairn Islands' },
  { code: 'PR', code3: 'PRI',label: 'Puerto Rico', phone: '1', id: 'Puerto Rico' },
  { code: 'PS', code3: 'PSE',label: 'Palestine, State of', phone: '970', id: 'Palestine' },
  { code: 'PT', code3: 'PRT',label: 'Portugal', phone: '351', id: 'Portugal' },
  { code: 'PW', code3: 'PLW',label: 'Palau', phone: '680', id: 'Palau' },
  { code: 'PY', code3: 'PRY',label: 'Paraguay', phone: '595', id: 'Paraguay' },
  { code: 'QA', code3: 'QAT',label: 'Qatar', phone: '974', id: 'Qatar' },
  { code: 'RE', code3: 'REU',label: 'Reunion', phone: '262', id: 'Réunion' },
  { code: 'RO', code3: 'ROU',label: 'Romania', phone: '40', id: 'Romania' },
  { code: 'RS', code3: 'SRB',label: 'Serbia', phone: '381', id: 'Serbia' },
  { code: 'RU', code3: 'RUS',label: 'Russian Federation', phone: '7', id: 'Russia' },
  { code: 'RW', code3: 'RWA',label: 'Rwanda', phone: '250', id: 'Rwanda' },
  { code: 'SA', code3: 'SAU',label: 'Saudi Arabia', phone: '966', id: 'Saudi Arabia' },
  { code: 'SB', code3: 'SLB',label: 'Solomon Islands', phone: '677', id: 'Solomon Islands' },
  { code: 'SC', code3: 'SYC',label: 'Seychelles', phone: '248', id: 'Seychelles' },
  { code: 'SD', code3: 'SDN',label: 'Sudan', phone: '249', id: 'Sudan' },
  { code: 'SE', code3: 'SWE',label: 'Sweden', phone: '46', id: 'Sweden' },
  { code: 'SG', code3: 'SGP',label: 'Singapore', phone: '65', id: 'Singapore' },
  { code: 'SH', code3: 'SHN',label: 'Saint Helena', phone: '290', id: 'Saint Helena' },
  { code: 'SI', code3: 'SVN',label: 'Slovenia', phone: '386', id: 'Slovenia' },
  { code: 'SJ', code3: 'SJM',label: 'Svalbard and Jan Mayen', phone: '47', id: 'Svalbard and Jan Mayen' },
  { code: 'SK', code3: 'SVK',label: 'Slovakia', phone: '421', id: 'Slovakia' },
  { code: 'SL', code3: 'SLE',label: 'Sierra Leone', phone: '232', id: 'Sierra Leone' },
  { code: 'SM', code3: 'SMR',label: 'San Marino', phone: '378', id: 'San Marino' },
  { code: 'SN', code3: 'SEN',label: 'Senegal', phone: '221', id: 'Senegal' },
  { code: 'SO', code3: 'SOM',label: 'Somalia', phone: '252', id: 'Somalia' },
  { code: 'SR', code3: 'SUR',label: 'Suriname', phone: '597', id: 'Suriname' },
  { code: 'SS', code3: 'SSD',label: 'South Sudan', phone: '211', id: 'South Sudan' },
  { code: 'ST', code3: 'STP',label: 'Sao Tome and Principe', phone: '239', id: 'Sao Tome and Principe' },
  { code: 'SV', code3: 'SLV',label: 'El Salvador', phone: '503', id: 'El Salvador' },
  { code: 'SX', code3: 'SXM',label: 'Sint Maarten (Dutch part)', phone: '1-721', id: 'Sint Maarten' },
  { code: 'SY', code3: 'SYR',label: 'Syrian Arab Republic', phone: '963', id: 'Syria' },
  { code: 'SZ', code3: 'SWZ',label: 'Swaziland', phone: '268', id: 'Swaziland' },
  { code: 'TC', code3: 'TCA',label: 'Turks and Caicos Islands', phone: '1-649', id: 'Turks and Caicos Islands' },
  { code: 'TD', code3: 'TCD',label: 'Chad', phone: '235', id: 'Chad' },
  { code: 'TF', code3: 'ATF',label: 'French Southern and Antarctic Lands', phone: '262', id: 'French Southern and Antarctic Lands' },
  { code: 'TG', code3: 'TGO',label: 'Togo', phone: '228', id: 'Togo' },
  { code: 'TH', code3: 'THA',label: 'Thailand', phone: '66', id: 'Thailand' },
  { code: 'TJ', code3: 'TJK',label: 'Tajikistan', phone: '992', id: 'Tajikistan' },
  { code: 'TK', code3: 'TKL',label: 'Tokelau', phone: '690', id: 'Tokelau' },
  { code: 'TL', code3: 'TLS',label: 'Timor-Leste', phone: '670', id: 'East Timor' },
  { code: 'TM', code3: 'TKM',label: 'Turkmenistan', phone: '993', id: 'Turkmenistan' },
  { code: 'TN', code3: 'TUN',label: 'Tunisia', phone: '216', id: 'Tunisia' },
  { code: 'TO', code3: 'TON',label: 'Tonga', phone: '676', id: 'Tonga' },
  { code: 'TR', code3: 'TUR',label: 'Turkey', phone: '90', id: 'Turkey' },
  { code: 'TT', code3: 'TTO',label: 'Trinidad and Tobago', phone: '1-868', id: 'Trinidad and Tobago' },
  { code: 'TV', code3: 'TUV',label: 'Tuvalu', phone: '688', id: 'Tuvalu' },
  { code: 'TW', code3: 'TWN',label: 'Taiwan', phone: '886', id: 'Taiwan' },
  { code: 'TZ', code3: 'TZA',label: 'United Republic of Tanzania', phone: '255', id: 'Tanzania' },
  { code: 'UA', code3: 'UKR',label: 'Ukraine', phone: '380', id: 'Ukraine' },
  { code: 'UG', code3: 'UGA',label: 'Uganda', phone: '256', id: 'Uganda' },
  { code: 'US', code3: 'USA',label: 'United States', phone: '1', suggested: true, id: 'United States' },
  { code: 'UY', code3: 'URY',label: 'Uruguay', phone: '598', id: 'Uruguay' },
  { code: 'UZ', code3: 'UZB',label: 'Uzbekistan', phone: '998', id: 'Uzbekistan' },
  { code: 'VA', code3: 'VAT',label: 'Vatican City', phone: '379', id: 'Vatican City' },
  { code: 'VC', code3: 'VCT',label: 'Saint Vincent and the Grenadines', phone: '1-784', id: 'Saint Vincent and the Grenadines' },
  { code: 'VE', code3: 'VEN',label: 'Venezuela', phone: '58', id: 'Venezuela' },
  { code: 'VG', code3: 'VGB',label: 'British Virgin Islands', phone: '1-284', id: 'British Virgin Islands' },
  { code: 'VI', code3: 'VIR',label: 'U.S. Virgin Islands', phone: '1-340', id: 'U.S. Virgin Islands' },
  { code: 'VN', code3: 'VNM',label: 'Vietnam', phone: '84', id: 'Vietnam' },
  { code: 'VU', code3: 'VUT',label: 'Vanuatu', phone: '678', id: 'Vanuatu' },
  { code: 'WF', code3: 'WLF',label: 'Wallis and Futuna', phone: '681', id: 'Wallis and Futuna' },
  { code: 'WS', code3: 'WSM',label: 'Samoa', phone: '685', id: 'Samoa' },
  { code: 'YE', code3: 'YEM',label: 'Yemen', phone: '967', id: 'Yemen' },
  { code: 'YT', code3: 'MYT',label: 'Mayotte', phone: '262', id: 'Mayotte' },
  { code: 'ZA', code3: 'ZAF',label: 'South Africa', phone: '27', id: 'South Africa' },
  { code: 'ZM', code3: 'ZMB',label: 'Zambia', phone: '260', id: 'Zambia' },
  { code: 'ZW', code3: 'ZWE',label: 'Zimbabwe', phone: '263', id: 'Zimbabwe' },
];

export const countriesAndUnions = countries.concat(countryUnions);