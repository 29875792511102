import { FC } from "react";
import { useParams } from "react-router-dom";
import { ordersPathPurchase } from "~/Services/Routes";
import { useIsInternalCustomer, useIsInternalUser } from "~/Reducers/User";
import { ChatViewEntity } from "~/Components/Chats/ChatViewEntity";
import {useChatOrderExternal, useChatOrderInternal} from "~/Data/Chats";

const OrderChat: FC<{
  orderId: number;
  onClose: () => void;
}> = ({ orderId, onClose }) => {
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();

  const isPurchaseOrder =
    useParams<{ type: string }>()?.type === ordersPathPurchase;

  const internalChatOrderId =
    isInternalUser || isInternalCustomer ? orderId : null;

  const externalChatOrderId = !isInternalCustomer ? orderId : null;

  const chatInternal = useChatOrderInternal(
    internalChatOrderId,
    isPurchaseOrder,
    isInternalUser || isInternalCustomer
  );
  const chatExternal = useChatOrderExternal(
    externalChatOrderId,
    isPurchaseOrder
  );

  return (
    <ChatViewEntity
      onClose={onClose}
      chatExternal={chatExternal.data}
      chatInternal={chatInternal.data}
      showInternal={isInternalCustomer && chatInternal.data !== undefined}
    />
  );
};

export default OrderChat;
