import { memo, useCallback, useEffect } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import { destinationPortTypes, destinationNonPortTypes } from "~/Data/Destinations";
import { useIncotermUsesAddress, isComboIncoterm } from "~/Reducers/Incoterms";
import { getProductGroup, useProductGroups } from "~/Reducers/ProductGroups";
import { useRequestMatchingProducts } from "./Actions";
import Checkbox from "~/Components/Old/Checkbox";
import DateView from "~/Components/Views/DateView";
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import PortSelect from "~/Components/Old/PortSelect";
import TextField from "~/Components/Old/TextField";
import ProductRow from "./ProductRow";
import {
  RequestForQuoteProductView,
  RequestsForQuotesMatchedProduct,
  RequestsForQuotesMatchingResult
} from "~/API";
import {useAppDispatch} from "~/StoreTypes";


const VendorRow = memo<{
  groupIndex: number;
  requestId: number;
  vendor: RequestsForQuotesMatchingResult;
  currency: string;
  requestProducts: RequestForQuoteProductView[];
  products: (RequestsForQuotesMatchedProduct & { selected?: boolean })[];
  onProductsListLoaded: (vendorIndex: number, newProducts: any[]) => void;
  onProductFieldChange: (groupIndex: number, productIndex: number, id: string, val: any) => void;
  onVendorFieldChange: (groupIndex: number, vendor: any) => void;
}>((props) => {
  const { groupIndex, requestId, vendor, currency, requestProducts, products,
    onProductsListLoaded, onProductFieldChange, onVendorFieldChange } = props;

  const dispatch = useAppDispatch();

  const productGroups = useProductGroups();

  const {
    companyId = null,
    companyCode = '',
    incoterm = '',
    matchedProducts = 0,
    lastOfferReceived = '',
    lastPurchaseOrder = '',
    address = '',
    companyName = '',
    portId = '',
  } = vendor;

  const canShowAddressField = useIncotermUsesAddress(incoterm);
  const isComboIncotermSelected = isComboIncoterm(incoterm);

  const [expanded, toggleExpanded] = useToggle();
  const [productsLoaded] = useRequestMatchingProducts(requestId, { companyId });

  useEffect(() => {
    for (const product of productsLoaded) {
      dispatch(getProductGroup(product.productGroupId));
    }
  }, [productsLoaded]);

  useEffect(() => {
    const newProductsWithSellPrice = productsLoaded.map(product => {
      const requestProduct = requestProducts.find(it => it.id === product.requestForQuoteProductId);
      const productGroupMargin = productGroups[product.productGroupId]?.productGroupMargin || 0;
      
      return {
        ...product,
        price: requestProduct?.price || 0,
        productGroupMargin,
        quantity: requestProduct?.quantity
      };
    });
    onProductsListLoaded(groupIndex, newProductsWithSellPrice);
  }, [productsLoaded, requestProducts, productGroups]);

  const noProducts = matchedProducts === 0;

  const onCustomerClick = useCallback(() => {
    if (!noProducts) toggleExpanded();
  }, [toggleExpanded]);

  const someProductsSelected = products?.some(product => product.selected);
  const allProductsSelected = products?.every(product => product.selected);
  const noneProductsSelected = products?.every(product => !product.selected);

  const onFieldChange = useCallback(({ target }) => {
    const dataToUpdate: { [key: string]: any } = { [target.id]: target.value };
    if (target.id === 'incoterm') {
      if (isComboIncoterm(target.value) !== isComboIncotermSelected) dataToUpdate.portId = '';
    }
    onVendorFieldChange(groupIndex, dataToUpdate);
  }, [onVendorFieldChange, groupIndex, isComboIncotermSelected]);

  const onCheckboxChange = () => {
    if (allProductsSelected) {
      for (let i = 0; i < products.length; i++) {
        onProductFieldChange(groupIndex, i, 'selected', false);
      }
    } else if (noneProductsSelected || someProductsSelected) {
      for (let i = 0; i < products.length; i++) {
        onProductFieldChange(groupIndex, i, 'selected', true);
      }
    }
  };

  const greyCheckbox = someProductsSelected && !allProductsSelected;

  return (
    <>
      <tr className={noProducts ? '' : 'OfferMatchingTableRow'}>
        <td>
          <Checkbox value={!!someProductsSelected}
            className={greyCheckbox ? 'GreyCheckbox' : ''}
            onChange={onCheckboxChange}
          />
        </td>
        <td onClick={onCustomerClick}>
          {noProducts ? '' : expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}
        </td>
        <td>{companyId}</td>
        <td>{companyCode}</td>
        <td>{companyName}</td>
        <td>{matchedProducts}</td>
        <td><DateView date={lastOfferReceived} /></td>
        <td><DateView date={lastPurchaseOrder} /></td>
        <td colSpan={2}><IncotermSelect id={'incoterm'} value={incoterm} onChange={onFieldChange} /></td>
        <td colSpan={2}>{canShowAddressField
          ? <TextField id={'address'} value={address} onChange={onFieldChange} />
          : <PortSelect id={'portId'} value={portId} onChange={onFieldChange}
            byType={isComboIncotermSelected ? destinationNonPortTypes : destinationPortTypes}
          />
        }</td>
      </tr>

      {expanded &&
        <tr className={'RequestMatchingProductsHeaderRow'}>
          <td />
          <td />
          <td><LocalizedMessage id={'title.code'} /></td>
          <td colSpan={2}><LocalizedMessage id={'title.description'} /></td>
          <td><LocalizedMessage id={'title.length'} /></td>
          <td><LocalizedMessage id={'title.length.profile'} /></td>
          <td><LocalizedMessage id={'title.length.request'} /></td>
          <td><LocalizedMessage id={'title.quantity'} /></td>
          <td><LocalizedMessage id={'title.containers'} /></td>
          <td><LocalizedMessage id={'title.uom'} /></td>
          <td className={'text-right'}><LocalizedMessage id={'title.target.price.vendor'} /></td>
        </tr>
      }
      {expanded && products.map((product, index) =>
        <ProductRow key={index}
          productIndex={index}
          product={product}
          requestProduct={requestProducts.find(it => it.id === product.requestForQuoteProductId)}
          currency={currency}
          groupIndex={groupIndex}
          onProductFieldChange={onProductFieldChange}
        />)
      }
    </>
  );
});

export default VendorRow;
