import API, {CompanyUsersView} from "~/API";
import {FC} from "react";
import {LC} from "~/Locales";
import { DateTimeView } from "~/Components/Views/DateView";
import PriceView from "~/Components/Views/PriceView";
import {Card} from "~/Components/UI";
import useSWR from "swr";
import {LinearLoader} from "~/Components/Loaders";

const CompanyDetailsAccountsReceivablesCard: FC<{
  company: CompanyUsersView
}> = (props) => {
  const credit = useSWR(
    ["getCustomerCredit", props.company.id],
    () => API.getCustomerCredit({ customerId: props.company.id })
  );

  return (
    <Card className="flex-col gap-md text-left py-lg desktop-w-min-10xl">
      <div className="text-lg">
        <LC id="title.accounts.receivables" />
      </div>
      <LinearLoader loading={credit.isValidating} />

      <div>
        <div className="color-text-2">
          <LC id="title.last.updated.date" />
        </div>
        <DateTimeView date={credit.data?.dateUpdated} />
      </div>

      <div className="flex-row gap-sm">
        <div className="flex flex-col gap-sm">
          <div>
            <div className="color-text-2">
              <LC id="title.current.amount" />
            </div>
            <PriceView price={credit.data?.currentAmount} />
          </div>

          <div>
            <div className="color-text-2">
              <LC id="title.late.1.to.15" />
            </div>
            <PriceView price={credit.data?.late1To15} />
          </div>

          <div>
            <div className="color-text-2">
              <LC id="title.late.16.to.30" />
            </div>
            <PriceView price={credit.data?.late16To30} />
          </div>

          <div>
            <div className="color-text-2">
              <LC id="title.late.31.to.45" />
            </div>
            <PriceView price={credit.data?.late31To45} />
          </div>
        </div>

        <div className="flex flex-col gap-sm">
          <div>
            <div className="color-text-2">
              <LC id="title.late.46.plus" />
            </div>
            <PriceView price={credit.data?.late46Plus} />
          </div>

          <div>
            <div className="color-text-2">
              <LC id="title.total.amount" />
            </div>
            <PriceView price={credit.data?.totalAmount} />
          </div>

          <div>
            <div className="color-text-2">
              <LC id="title.unapplied.checks" />
            </div>
            <PriceView price={credit.data?.unappliedChecks} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CompanyDetailsAccountsReceivablesCard;
