import {FC} from "react";
import {Notification, NotificationType} from "~/API";
import {TbBellFilled} from "react-icons/tb";
import {NotificationCard, useLink} from "~/Components/Notifications/NotificationCard";
import {useChatMessage} from "~/Data/Chats";

export const SystemNotification: FC<{
  notification: Notification;
  markRead: () => void;
  refetchNotifications: () => void;
}> = ({ notification, markRead, refetchNotifications}) => {
  const link = useLink(notification.data.refs, NotificationType.SYSTEM_MESSAGE);
  const message = useChatMessage(Number(notification.data.refs?.["CHAT_MESSAGE"]?.[0]));

  return (
    <NotificationCard
      icon={<TbBellFilled size={16} />}
      link={link}
      markRead={markRead}
      dateCreated={notification.dateCreated}
      read={notification.read}
      notificationId = {notification.id}
      refetchNotifications={refetchNotifications}
    >
      {message?.data?.text}
    </NotificationCard>
  );
};
