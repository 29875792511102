import { FC } from "react";
import { QuoteStatus } from "~/API";
import { LC, LocalizedMessageKey } from "~/Locales";
import { cn } from "~/Utils";
import {
  useIsAdmin,
  useIsCustomer,
  useIsUFPAM,
  useIsUFPBuyer,
} from "~/Reducers/User";

export const quoteStatusData: Record<QuoteStatus, string> = {
  DRAFT: "bg-black-9 color-black-4",
  ARCHIVED: "bg-red-6 color-red-1",
  SENT: "bg-blue-8 color-blue-0",
  TO_BE_CONFIRMED: "bg-orange-8 color-orange-0",
  INTERESTED: "bg-pink-9 color-pink-1",
  ORDERED: "bg-purple-9 color-purple-1",
  DELIVERED: "bg-green-8 color-green-0",
};

export const QuoteStatusBadge: FC<{
  status: QuoteStatus;
}> = (props) => {
  const classes = quoteStatusData[props.status];
  const isBuyer = useIsUFPBuyer();
  const isAM = useIsUFPAM();
  const isCustomer = useIsCustomer();
  const isAdmin = useIsAdmin();

  let role: string;
  if (isBuyer) role = "buyer";
  else if (isCustomer) role = "customer";
  else if (isAM || isAdmin) role = "am";

  return (
    <div className="flex-row">
      <div
        className={cn(
          "py-xxs px-sm text-semibold text-sm rounded-sm text-truncate",
          classes,
        )}
      >
        <LC
          id={`quote.status.${role}.${props.status}` as LocalizedMessageKey}
        />
      </div>
    </div>
  );
};
