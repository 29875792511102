import { FC, useMemo } from "react";
import {
  Button,
  FormCheckbox,
  FormInput,
  FormSwitch,
  Table,
} from "~/Components/UI";
import { TbFilter } from "react-icons/tb";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import { LC } from "~/Locales";
import {params, useDebounce, useFormData, usePageTitle, useToggle} from "~/Hooks";
import { useHistory } from "react-router-dom";
import { companiesRootPath, locationsRootPath } from "~/Services/Routes";
import { LocationFilters, useLocations } from "~/Data/Locations";
import {
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { LinearLoader } from "~/Components/Loaders";
import {GroupPermission, LocationView, Order, PortType} from "~/API";
import {useSortState} from "~/Hooks/useSortState";
import {trimUndefined} from "~/Utils";
import { FilterPanel } from "~/Components/Entities/FilterPanel";
import { CountryCombobox } from "~/Components/Comboboxes/CountryCombobox";
import { PortCombobox } from "~/Components/Ports/PortCombobox";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import * as z from "zod";
import {getLocationTableColumns} from "~/Pages/Companies/Components/LocationTable";

const routeParser = z.object({
  search: params.string,
  inactive: params.bool,
  onlyMy: params.bool,
  country: params.stringArray,
  port: params.numberArray,
  accountManager: params.numberArray,
  withEmptyAmOnly: params.bool,
  orderBy: params.string,
  order: params.enum(Order),
});

export const LocationTabs: FC<{ type: "customers" | "vendors" }> = (props) => {
  const history = useHistory();
  const filtersOpen = useToggle();
  const filters = useFormData<LocationFilters>({
    search: "",
    onlyMy: false,
    inactive: false,
    country: [],
    port: [],
    accountManager: [],
    withEmptyAMOnly: false,
  }, { routeParser });
  const searchDebounce = useDebounce(filters.data.search);
  const isCustomers = props.type == "customers";
  usePageTitle(isCustomers ? "Ship-Tos" : "Ship-Froms");

  const sort = useSortState(filters);
  const companies = useLocations(
    trimUndefined({
      ...filters.data,
      query: props.type == "customers" ? "TYPE == 'SHIP_TO'" : "TYPE == 'SHIP_FROM'",
      search: searchDebounce,
      ...sort.data,
    }),
    40,
  );

  const columns = useMemo(() => getLocationTableColumns(isCustomers), [isCustomers]);
  const data = useMemo(() => companies.data?.flat() || [], [companies.data]);
  const onScrolledToBottom = () => companies.setSize(companies.size + 1);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        am: isCustomers,
        defaultMarginAsFraction: isCustomers,
      },
    },
  });

  const onRowClicked = (row: LocationView) => {
    history.push(`${locationsRootPath}${row.id}/general`);
  };

  const onCompaniesClicked = () => {
    history.push(`${companiesRootPath}${props.type}`);
  };

  return (
    <div className="flex-row gap-lg flex overflow-hidden">
      <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs">
        <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
          <FormInput
            id="search"
            formData={filters}
            showClear
            type="search"
            placeholder="title.search"
            className="desktop-w-min-8xl"
          />
          <FormCheckbox
            id="inactive"
            formData={filters}
            label="title.inactive"
          />
          <FormCheckbox
            id="onlyMy"
            formData={filters}
            label={
              isCustomers
                ? "title.show.only.my.ship.tos"
                : "title.show.only.my.ship.froms"
            }
          />

          <Tabs value="location">
            <TabsList className="mx-sm">
              <TabsTrigger value="company" onClick={onCompaniesClicked}>
                <LC id={isCustomers ? "title.customers" : "title.vendors"} />
              </TabsTrigger>
              <TabsTrigger value="location">
                <LC id={isCustomers ? "title.ship.tos" : "title.ship.froms"} />
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Button
            leftIcon={<TbFilter size={16} />}
            onClicked={filtersOpen.toggle}
            label="title.filter"
            color="gray"
            className="ml-auto"
          />
        </div>

        <LinearLoader loading={companies.isValidating} />

        <Table
          table={table}
          onScrolledBottom={onScrolledToBottom}
          onRowClick={onRowClicked}
          sort={sort}
        />
      </div>
      <FilterPanel open={filtersOpen}>
        <CountryCombobox
          id="country"
          formData={filters}
          label="title.country"
          multiple
          showClear
          clearValue={[]}
          showSelectAll
        />
        <PortCombobox
          id="port"
          formData={filters}
          filters={{
            type: isCustomers
              ? [PortType.PORT]
              : [PortType.CY, PortType.RAMP, PortType.DOOR],
            inactive: false,
          }}
          label={
            isCustomers
              ? "title.port"
              : "title.destination"
          }
          multiple
          showClear
          clearValue={[]}
        />
        {isCustomers && (
          <>
            <UserByPermissionCombobox
              id="accountManager"
              formData={filters}
              permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
              label="title.account.manager"
              multiple
              showClear
              clearValue={[]}
            />
            <FormSwitch
              id="withEmptyAMOnly"
              formData={filters}
              label="title.blank.am"
            />
          </>
        )}
        <Button label="title.clear" color="gray" onClicked={filters.clear} />
      </FilterPanel>
    </div>
  );
};
