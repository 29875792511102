
export const FILTER_ALL = -1;

export enum PERIODS {
    THREE_MONTHS = "3 Months",
    SIX_MONTHS  =  "6 Months",
    ONE_YEAR    =  "1 Year",
    TWO_YEARS   =  "2 Years",
}

export const periodToInterval = (period: PERIODS) => {

    let interval = "MONTH";
    if (period == PERIODS.THREE_MONTHS) interval = "MONTH";
    if (period == PERIODS.SIX_MONTHS) interval = "MONTH";
    if (period == PERIODS.ONE_YEAR) interval = "MONTH";
    if (period == PERIODS.TWO_YEARS) interval = "MONTH";

    const to = new Date(); // today
    const from = new Date();

    if (period === PERIODS.THREE_MONTHS)
        from.setMonth(from.getMonth() - 3);
    if (period === PERIODS.SIX_MONTHS)
        from.setMonth(from.getMonth() - 6);
    if (period === PERIODS.ONE_YEAR)
        from.setFullYear(from.getFullYear() - 1);
    if (period === PERIODS.TWO_YEARS)
        from.setFullYear(from.getFullYear() - 2);

    return {
        interval,
        from: from.toISOString().slice(0, 10), // slice removes timezone
        to: to.toISOString().slice(0, 10),
    };
}
