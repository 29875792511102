
export const applyMargin = (costs: number | string, margin: number | string) => {
  // sellPrice = costs/(1-margin)
  const priceNumber = Number(costs);
  const marginNumber = Number(margin) / 100;
  const result = priceNumber / (1 - marginNumber);
  return Number.isNaN(result) ? 0 : result;
}

export const calcMarginPercent = (costs: number | string, sellPrice: number | string) => {
  // sellPrice = costs/(1-margin)
  // 1-margin = costs/sellPrice
  // margin = 1 - costs/sellPrice
  const sellPriceNumber = Number(sellPrice);
  const costsNumber = Number(costs);
  const result = (1 - costsNumber / sellPriceNumber) * 100;
  if (Number.isNaN(result)) return '0';
  return Number.isFinite(result) ? result.toFixed(2) : '∞';
}

export const formattedNumber = (price: number | string, decimals: number = 0): string => {
  const priceNumber = Number(price);
  if (isNaN(priceNumber)) {
    return '-';
  }
  return priceNumber?.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Rounds number up to the nearest decimal
export const formattedCeilNumber = (price: number | string, decimals: number = 0): string => {
  const priceNumber = Number(price);
  if (isNaN(priceNumber) || priceNumber === null) {
    return '-';
  }

  // round number only if it has more than the given # of decimal places to avoid javascript math oddities
  let roundedNumber = priceNumber
  if (countDecimals(priceNumber) > decimals) {
    const multiplier = Math.pow(10, decimals)
    roundedNumber = Math.ceil(priceNumber * multiplier) / multiplier
  }

  return roundedNumber?.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function countDecimals(num: number): number {
  let count = 0
  let num_ = num
  while (num_ % 1 != 0) {
    count ++
    num_ = num_ * 10
  }
  return count
}