import { FC, ReactNode } from "react";
import { Button, Dialog } from "~/Components/UI";
import { Email } from "~/API";
import { DateTimeView } from "~/Components/Views/DateView";
import { ToggleProps, useToggle } from "~/Hooks";
import { LC, LocalizedMessageKey } from "~/Locales";
import {UserName} from "~/Components/Users/UserName";
import {useResendEmail} from "~/Data/Admin/Emails";
import {trackEvent} from "~/Services/Tracking";

const EmailCard: FC<{ email: Email; open: ToggleProps }> = (props) => {
  const emailResend = useResendEmail();
  const resent = useToggle(false);

  const onEmailResend = () => {
    emailResend.call({ id: props.email.id }).then(resent.toggleTrue);
    trackEvent("Email Resent");
  };

  return (
    <Dialog
      onOpenChange={resent.toggleFalse}
      open={props.open}
      title={props.email.subject}
      trigger={<div />}
    >
      <div className="desktop-w-min-11xl flex-col gap-sm">
        <div className="mx-lg gap-sm grid grid-col-min-content-1">
          <FieldDisplay label="title.trigger" value={props.email.trigger} />
          <FieldDisplay
            label="title.user"
            value={<UserName id={props.email.userAccountId} />}
          />
          <FieldDisplay label="title.to" value={props.email.to} />
          <FieldDisplay label="title.from" value={props.email.from} />
          <FieldDisplay
            label="title.date"
            value={<DateTimeView date={props.email.dateCreated} />}
          />
          <FieldDisplay label={"email.sentSuccessfully"} value={(props.email.sentSuccessfully) ? "True" : "False"}/>
        </div>

        <div
          className="border-black-9 rounded-xxs"
          dangerouslySetInnerHTML={{ __html: props.email.body }}
        />

        <Button
          onClicked={onEmailResend}
          disabled={resent.value}
          loading={emailResend.isMutating}
          label={resent.value ? "title.sent.email" : "title.resend.email"}
        />
      </div>
    </Dialog>
  );
};

const FieldDisplay: FC<{
  label: LocalizedMessageKey;
  value: ReactNode;
}> = (props) => (
  <>
    <div className="color-text-2 text-right">
      <LC id={props.label} />
    </div>
    <div className="text-bold">{props.value}</div>
  </>
);

export default EmailCard;
