import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LC, ls } from "~/Locales";
import { requestsRootPath } from "~/Services/Routes";
import DashboardEmptyState from "../DashboardEmptyState";
import { RequestForQuoteView } from "~/API";
import PriceView from "~/Components/Views/PriceView";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import { LinearLoader } from "~/Components/Loaders";
import { Button, Card } from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  useDashboardRequestsForQuote,
  useRequestForQuoteValue,
} from "~/Data/Requests/RequestsForQuotes";
import {DashboardMobileField, DashboardTable} from "~/Pages/Dashboard/Components/DashboardTable";
import { RFQStatusBadge } from "~/Components/RFQ/RFQStatusButton";
import {useIsAdmin} from "~/Reducers/User";
import {useIsMobile} from "~/Hooks";

const columnHelper = createColumnHelper<RequestForQuoteView>();

const columns = [
  columnHelper.accessor("id", {
    header: ls("title.rq.id"),
    cell: (data) => <div className="text-semibold">RQ-{data.getValue()}</div>,
  }),
  columnHelper.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <RFQStatusBadge status={data.getValue()} />,
  }),
  columnHelper.accessor("shipToName", {
    header: ls("title.ship.to"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelper.accessor("products", {
    header: ls("title.products"),
    cell: (data) => <>{data.getValue().length}</>,
  }),
  columnHelper.display({
    header: ls("title.value"),
    cell: (data) => {
      const value = useRequestForQuoteValue(data.row.original);
      return (
        <PriceView
          price={value}
          currency={data.row.original.currency}
          decimals={0}
        />
      );
    },
  }),
  columnHelper.accessor("validityDate", {
    header: ls("title.validity.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
];

const DashboardCustomerRequests: FC = () => {
  const history = useHistory();
  const requests = useDashboardRequestsForQuote();
  const isAdmin = useIsAdmin();

  const onOpen = (row: RequestForQuoteView) => {
    history.push(requestsRootPath + row.id);
  };

  const showMobileTable = useIsMobile();

  const onShowAll = () => {
    history.push(requestsRootPath);
  };

  const table = useReactTable({
    data: requests.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const showEmptyState = requests.data?.length === 0 && !requests.isLoading;
  const showList = !requests.isLoading && !showEmptyState;
  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC id={isAdmin ? "title.customer.requests" : "title.my.requests"} />
      </div>

      <LinearLoader loading={requests.isLoading} />
      {showEmptyState && (
        <DashboardEmptyState
          title="No Requests."
          description1="Once you have an request, it will be visible here."
        />
      )}

      {showList && (
        <>
          {!showMobileTable && (
            <DashboardTable table={table} onRowClick={onOpen} />
          )}
          {showMobileTable && (
            <div className="orders-table-mobile">
              {requests.data.map((request) => (
                <RequestCellMobile key={request.id} request={request} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-md"
            onClicked={onShowAll}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardCustomerRequests;

const RequestCellMobile = ({ request }) => {
  const history = useHistory();

  const {
    id,
    status,
    shipToName,
    // products,
    currency,
    validityDate,
  } = request;

  const value = useRequestForQuoteValue(id);

  const onOpen = useCallback(() => {
    history.push(requestsRootPath + id);
  }, [id, history]);

  return (
    <div className="border-b-1 border-black-10 py-md" onClick={onOpen}>
      <div className="flex-row w-full">
        <div className="text-truncate mt-xxs flex-col flex mr-xxs">
          RQ-{id}
        </div>

        <DashboardMobileField label="title.value">
          <PriceView price={value} currency={currency} decimals={0} />
        </DashboardMobileField>

        <div className="flex-col">
          <RFQStatusBadge status={status} />
        </div>
      </div>

      <div className="flex-row w-full">
        <DashboardMobileField label="title.ship.to">
          {shipToName}
        </DashboardMobileField>
      </div>

      <DashboardMobileField label="title.validity.date">
        <DateTimeView date={validityDate} />
      </DashboardMobileField>
    </div>
  );
};
