import { classNames } from "react-ui-basics/Tools";
import React, { FC, useEffect, useRef, useState } from "react";
import './IconButton.scss';

interface IconButtonProps {
  children: React.ReactNode;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

const IconButton: FC<IconButtonProps> = ({ children, className, size, onClick }) => {
  const [isRipple, setIsRipple] = useState(false);
  const [restartRipple, setRestartRipple] = useState(false);
  const rippleTimeout = useRef(null);

  useEffect(() => {
    if (isRipple) {
      clearTimeout(rippleTimeout.current);
      rippleTimeout.current = setTimeout(() => setIsRipple(false), 1000);
    }
  }, [isRipple, restartRipple]);

  useEffect(() => () => clearTimeout(rippleTimeout.current), []);

  useEffect(() => {
    if (restartRipple) {
      setRestartRipple(false);
    }
  },[restartRipple]);

  const handleClick = () => {
    if (isRipple) {
      setRestartRipple(true);
    } else {
      setIsRipple(true);
    }
    onClick?.();
  };

  return <button className={classNames('IconButton', className, size)} onClick={handleClick} type={'button'}>
    <span className={'IconContainer'}>{children}</span>
    {isRipple && <span className={'Ripple'} style={{
      animationName: restartRipple ? "none" : "ripple-effect"
    }} />}
  </button>
}

export default IconButton;
