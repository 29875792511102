import { FC } from "react";
import { RequestForQuoteStatus } from "~/API";
import { cn } from "~/Utils";
import {LC, LocalizedMessageKey} from "~/Locales";
import {useIsCustomer, useIsUFPBuyer} from "~/Reducers/User";

export const quoteStatusData: Record<RequestForQuoteStatus, string> = {
  DRAFT_AM: "bg-black-9 color-black-4",
  DRAFT_CUSTOMER: "bg-black-9 color-black-4",
  ARCHIVED: "bg-red-6 color-red-1",
  SENT_TO_BUYER: "bg-blue-8 color-blue-0",
  REVIEW: "bg-orange-8 color-orange-0",
  OFFER_REQUESTED: "bg-orange-8 color-orange-0",
  QUOTED: "bg-pink-9 color-pink-1",
  OFFERED: "bg-purple-9 color-purple-1",
  ORDERED: "bg-green-8 color-green-0",
};

export const RFQStatusBadge: FC<{ status: RequestForQuoteStatus }> = (
  props,
) => {
  const classes = quoteStatusData[props.status];
  const isUFPBuyer = useIsUFPBuyer();
  const isCustomer = useIsCustomer();

  let role = "am";
  if (isUFPBuyer) role = "buyer";
  if (isCustomer) role = "customer";

  return (
    <div className="flex-row">
      <div
        className={cn(
          "py-xxs px-sm text-semibold text-sm rounded-sm text-truncate",
          classes
        )}
      >
        <LC id={`request.status.${role}.${props.status}` as LocalizedMessageKey} />
      </div>
    </div>
  );
};
