import { FC, ReactNode } from "react";
import {Card, Show} from "~/Components/UI";
import {Skeleton} from "~/Components/Loaders";

export const MetricsCard: FC<{ children: ReactNode }> = (props) => (
    <Card className="flex-col justify-between gap-lg">{props.children}</Card>
);

export const MetricStatContainer: FC<{ children: ReactNode }> = (props) => (
    <div className="desktop-flex-row mobile-flex-col flex-wrap justify-between align-start px-lg gap-xl">
        {props.children}
    </div>
);

export const MetricStat: FC<{
    children: ReactNode;
    label: ReactNode;
    loading: boolean;
}> = (props) => (
    <div className="flex-row gap-xs align-baseline text-no-wrap">
        <div className="text-3xl">
            <Show
                when={!props.loading}
                fallback={<Skeleton className="w-2xl h-xl" />}
            >
                {props.children}
            </Show>
        </div>
        <div className="color-text-3">{props.label}</div>
    </div>
);

export const MetricsHeader: FC<{
    children: ReactNode;
    title: string;
}> = (props) => (
    <div className="flex-col justify-between gap-xs">
        <div className="text-xl text-left text-bold">{props.title}</div>
        <div className="flex-row align-center flex-wrap gap-sm">{props.children}</div>
    </div>
);
