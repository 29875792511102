import { LC } from "~/Locales";
import { usersRootPath } from "~/Services/Routes";
import UserDetailsPageProfileTab from "./Components/UserDetailsPageProfileTab";
import { FC } from "react";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";

const UserDetailsPage: FC = () => (
  <ScrollPage title="Add User">
    <PageTitle type="back" parentUrl={usersRootPath} />
    <div className="flex-row text-left text-xl mx-lg mt-lg mb-xs">
      <LC id="title.add.user" />
    </div>
    <UserDetailsPageProfileTab />
  </ScrollPage>
);

export default UserDetailsPage;
