import { FC } from "react";
import "./RequestChat.scss";
import { ChatViewEntity } from "~/Components/Chats/ChatViewEntity";
import {useChatRequestForQuote} from "~/Data/Chats";
import {useIsInternalUser} from "~/Reducers/User";

const RequestChat: FC<{
  onClose: () => void;
  requestId: number;
}> = ({ onClose, requestId }) => {
  const isInternalUser = useIsInternalUser();

  const chatInternal = useChatRequestForQuote(requestId, true, isInternalUser);
  const chatExternal = useChatRequestForQuote(requestId, false, isInternalUser);

  return (
    <ChatViewEntity
      onClose={onClose}
      chatExternal={chatExternal.data}
      chatInternal={chatInternal.data}
    />
  );
};

export default RequestChat;
