import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestedOffersRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { useIncotermUsesAddress } from "~/Reducers/Incoterms";
import {
  getRequestedOffersByStatus,
  isOfferExpired,
  useOfferProductCodes,
  useOfferValue,
} from "~/Reducers/Offers";
import { useIsInternalUser } from "~/Reducers/User";
import {
  getRequestedOffersStatusesTotalInfo,
  useDefaultPageFilters,
  useRequestedOffersPageByGroupReduxState,
  useRequestedOffersPageReduxState,
} from "../../Reducer";
import { useIsDraft } from "../RequestedOffersHelper";
import Button from "~/Components/Old/Button";
import {
  ArrowDownIcon,
  ArrowRightIcon,
} from "~/Components/Old/Icons";
import { IncotermName } from "~/Components/Old/IncotermSelect";
import LinearProgress from "~/Components/Old/LinearProgress";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useFilters } from "~/Components/Old/useFilters";
import {
  OfferShowUnread,
  OfferStatusLabel,
} from "~/Pages/Offers/Components/OffersHelper";
import OfferReference from "~/Pages/Offers/Components/OfferReference";
import RFOArchiveDeleteButtons from "../RFOArchiveDeleteButtons";
import { DateTimeView } from "~/Components/Views/DateView";
import { classNames } from "react-ui-basics/Tools";
import "../RequestedOffersPageTableWithSubrows.scss";
import {
  OffersByStatusSummary,
  OfferStatus,
  OfferView,
} from "~/API";
import {useAppDispatch} from "~/StoreTypes";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

const RequestedOffersPageByStatusTable: FC<{
  filters: any;
}> = ({ filters }) => {
  const dispatch = useAppDispatch();

  const scrollRef = useRef();

  useEffect(() => {
    dispatch(getRequestedOffersStatusesTotalInfo(filters));
  }, [filters]);

  const { statuses, loading } = useRequestedOffersPageReduxState();

  return (
    <ScrollableForPagination
      ref={scrollRef}
      className={"flex bg-black-12 overflow-auto"}
    >
      {loading && (
        <div className={"progress-bar"}>
          <LinearProgress />
        </div>
      )}

      <table className={"basic-table RequestedOffersPageTableWithSubrows"}>
        {statuses.map((status) => (
          <StatusRow key={status.status} {...{ status }} />
        ))}
      </table>
    </ScrollableForPagination>
  );
};

export default RequestedOffersPageByStatusTable;

const StatusRow: FC<{
  status: OffersByStatusSummary;
}> = ({ status }) => {
  const isInternalUser = useIsInternalUser();
  const [expanded, expandCollapse] = useToggle(false);

  const { numberOfOffers, totalValue, currency } = status;
  const currencySymbol = useFindCurrencySymbol(currency);

  return (
    <>
      <thead>
        <tr onClick={expandCollapse} className={"ClickableHeaderOrRow"}>
          <th>
            {expanded ? (
              <ArrowDownIcon size={26} />
            ) : (
              <ArrowRightIcon size={26} />
            )}
          </th>
          <th colSpan={!isInternalUser ? 2 : 4}>
            <OfferStatusLabel offerStatus={status.status} alwaysShowColor />
          </th>
          <th colSpan={2}>
            <div className={"flex-row align-center"}>
              <LocalizedMessage id={"title.number.of.requests"} />: &nbsp;
              <div className={"color-orange-1"}>{numberOfOffers}</div>
            </div>
          </th>
          <th>
            <div className={"flex-row align-center"}>
              <LocalizedMessage id={"title.total.value"} />: &nbsp;
              <div
                className={"color-orange-1"}
              >{`${currencySymbol} ${formattedNumber(totalValue)}`}</div>
            </div>
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
        </tr>
      </thead>
      {expanded && <OffersForStatus status={status.status} />}
    </>
  );
};

const ITEMS_PER_PAGE = 10;
const filtersToExclude = ["status", "limit"];
const EMPTY_ARRAY = [];

const OffersForStatus: FC<{
  status: OfferStatus;
}> = ({ status }) => {
  const isInternalUser = useIsInternalUser();
  const pageDataByGroupId = useRequestedOffersPageByGroupReduxState(status);

  const defaultPageFilters = useDefaultPageFilters();

  const pageFilters = useMemo(
    () => ({
      ...defaultPageFilters,
      status: [status],
      limit: ITEMS_PER_PAGE,
    }),
    [status, defaultPageFilters],
  );

  const pageDataByStatusReduxFunc = useCallback(
    () => pageDataByGroupId,
    [pageDataByGroupId],
  );

  const { onScrolledToBottom, canLoadMore } = useFilters(
    pageFilters,
    getRequestedOffersByStatus,
    pageDataByStatusReduxFunc,
    null,
    null,
    filtersToExclude,
  );

  const { offers = EMPTY_ARRAY, loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  return (
    <tbody>
      <tr className={"OfferRowAsSubrow"}>
        <th />
        <th>
          <LocalizedMessage id={"title.ro.id"} />
        </th>
        <th />
        {isInternalUser && (
          <th>
            <LocalizedMessage id={"title.ship.to"} />
          </th>
        )}
        <th>
          <LocalizedMessage
            id={!isInternalUser ? "title.buyer" : "title.vendor"}
          />
        </th>
        {!isInternalUser && (
          <th>
            <LocalizedMessage id={"title.incoterm.destination"} />
          </th>
        )}
        {isInternalUser && (
          <th>
            <LocalizedMessage id={"title.buyer"} />
          </th>
        )}
        <th>
          <LocalizedMessage id={"title.products"} />
        </th>
        <th>
          <LocalizedMessage id={"title.product.code"} />
        </th>
        {isInternalUser && (
          <th>
            <LocalizedMessage id={"title.reference"} />
          </th>
        )}
        <th className={"text-right"}>
          <LocalizedMessage id={"title.value"} />
        </th>
        <th>
          <LocalizedMessage id={"title.validity.date"} />
        </th>
        <th>
          <LocalizedMessage id={"title.actions"} />
        </th>
        <th />
      </tr>
      {offers.map((offer) => (
        <OfferRow key={offer.id} groupId={status} {...{ offer }} />
      ))}

      {showAdditionalRow && (
        <tr className={"OfferRowAsSubrow"}>
          {!showLoader && (
            <td colSpan={13} className={"MoreButtonCell"}>
              <Button
                view={"text"}
                className={classNames(
                  "w-full",
                  showTryAgain && "DeleteButtonText",
                )}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage
                  id={showTryAgain ? "title.try.again" : "title.get.more"}
                />
              </Button>
            </td>
          )}
          {showLoader && (
            <td colSpan={13}>
              <LinearProgress />
            </td>
          )}
        </tr>
      )}
    </tbody>
  );
};

const OfferRow: FC<{
  offer: OfferView;
  groupId: string;
}> = ({ offer, groupId }) => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const value = useOfferValue(offer);
  const productCodes = useOfferProductCodes(offer);
  const {
    status,
    vendorCompanyName,
    currency,
    products,
    requestForQuoteId,
    quotes,
    purchaseOrders,
    incoterm,
    incotermAddressDestination,
    incotermPortIdDestination,
    incotermPortName,
    buyerName,
    unreadChatMessages,
    validityDate,
    shipToName,
    accountManagerName,
  } = offer;

  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = useIsDraft(status);

  const onOpen = useCallback(
    () => history.push(`${requestedOffersRootPath}${offer.id}`),
    [offer, history],
  );

  const canShowAddressField = useIncotermUsesAddress(incoterm);

  const isExpired = isOfferExpired(validityDate);

  return (
    <tr className={"OfferRowAsSubrow"} onClick={onOpen}>
      <td
        className={classNames(
          OfferShowUnread(offer.readMark, isExpired) ? "row-unread" : "",
        )}
      />
      <td className={"no-wrap"}>{`RO - ${offer.id}`}</td>
      <td>{isExpired && "Expired"}</td>
      {isInternalUser && <td>{shipToName}</td>}
      {isInternalUser && <td>{vendorCompanyName}</td>}
      {!isInternalUser && <td>{buyerName}</td>}
      {!isInternalUser && (
        <td>
          <div className={"flex-row align-center"}>
            <IncotermName incoterm={incoterm} />
            {canShowAddressField ? (
              incotermAddressDestination ? (
                `/${incotermAddressDestination}`
              ) : (
                ""
              )
            ) : incotermPortIdDestination ? (
              <>/{incotermPortName}</>
            ) : (
              ""
            )}
          </div>
        </td>
      )}
      {isInternalUser && <td>{accountManagerName}</td>}
      <td>{`${products?.length || 0}`}</td>
      <td>{productCodes}</td>
      {isInternalUser && (
        <td>
          <OfferReference
            {...{ requestForQuoteId, purchaseOrders, quotes }}
            simple
          />
        </td>
      )}
      <td className={"text-right no-wrap color-orange-1"}>
        {`${currencySymbol} ${formattedNumber(value) || "0"}`}
      </td>
      <td>
        <DateTimeView date={validityDate} />
      </td>
      <td>
        <div className={"flex-row align-center justify-center"}>
          <RFOArchiveDeleteButtons {...{ offer, groupId }} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}</td>
    </tr>
  );
};
