import API from "~/API";
import useSWRImmutable from "swr/immutable";
import {PERIODS, periodToInterval} from "./MetricsData";

export interface OfferPoMetricFilters {
  period: PERIODS;
  am?: number;
  company?: number;
}

export const useOfferToPOMetrics = (filters: OfferPoMetricFilters) =>
  useSWRImmutable(
    ["api/metrics/offers/to/pos", filters],
    async () => API.getOffersToPurchaseOrdersMetrics({
      ...periodToInterval(filters.period),
      am: filters.am || "" as any,
      company: filters.company || "" as any,
    }),
  );