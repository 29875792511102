import { useEffect, useRef, useState } from "react";
import { LocalVideoTrack, Participant as TwilioParticipant, RemoteVideoTrack } from "twilio-video";
import { trackPubsToTracks } from "./LocalParticipant";
import { VideoCallUserPhoto } from "./UserPhoto";
import "./RemoteParticipant.scss";
import { classNames } from "react-ui-basics/Tools";
import { VClog } from "./VideoCallHelper";

type RemoteParticipantProps = {
  userId: number;
  participant?: TwilioParticipant;
  isLocalCameraOn?: boolean;
  isScreenSharingOn?: boolean;
}

export const RemoteParticipant = ({ userId, participant }: RemoteParticipantProps) => {

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | RemoteVideoTrack>();
  const [isCameraOn, setIsCameraOn] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();

  const updateTracks = (participant: TwilioParticipant) => {
    if (participant) {
      setVideoTracks(trackPubsToTracks(participant.videoTracks));
      setAudioTracks(trackPubsToTracks(participant.audioTracks));
    }
  }

  useEffect(() => {

    if (participant) {
      updateTracks(participant);
      // maybe update tracks here?
      participant.on("trackDisabled", (track) => {
        VClog("### [-VideoCall-] trackDisabled, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackEnabled", (track) => {
        VClog("### [-VideoCall-] trackEnabled, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackSubscribed", (track) => {
        VClog("### [-VideoCall-] trackSubscribed, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackUnsubscribed", (track) => {
        VClog("### [-VideoCall-] trackUnsubscribed, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackPublished", (track) => {
        VClog("### [-VideoCall-] trackPublished, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackUnpublished", (track) => {
        VClog("### [-VideoCall-] trackUnpublished, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackStarted", (track) => {
        VClog("### [-VideoCall-] trackStarted, participant = ", participant.identity);
        updateTracks(participant)
      });
      participant.on("trackStopped", (track) => {
        VClog("### [-VideoCall-] trackStopped, participant = ", participant.identity);
        updateTracks(participant)
      });
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant?.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    // Screen sharing has priority over user's camera
    let videoTrack: LocalVideoTrack | RemoteVideoTrack = videoTracks.find((videoTrack) => videoTrack.name === 'screen') || videoTracks[0];
    setVideoTrack(videoTrack);

  }, [videoTracks]);

  const trackDisabled = (track) => {
    if (track.kind === "video" && track.name !== 'screen') {
      setIsCameraOn(false);
    }
  }

  const trackEnabled = (track) => {
    if (track.kind === "video" && track.name !== 'screen') {
      setIsCameraOn(true);
    }
  }


  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      // maybe update tracks here?
      videoTrack.on('disabled', () => trackDisabled(videoTrack));
      videoTrack.on('enabled', () => trackEnabled(videoTrack));

      return () => {
        videoTrack.detach()
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  if (!participant) {
    return (
      <div className="RemoteParticipant">
        <div className={'VideoCallUserImageContainer'}>
          <VideoCallUserPhoto userId={userId}/>
        </div>
      </div>
    )
  }

  const shouldDisplayUserPhoto = !isCameraOn || !videoTrack;

  const VideoCallContainerClassNames = classNames("VideoCallContainer",
    (videoTrack?.name === 'screen') ? "" : "Camera",
    shouldDisplayUserPhoto ? "Hidden" : ""
  );

  return (
    <div className="RemoteParticipant">

      {shouldDisplayUserPhoto &&
        <div className={'VideoCallUserImageContainer'}><VideoCallUserPhoto userId={userId}/></div>
      }

      {videoTrack && <video className={VideoCallContainerClassNames}
                            ref={videoRef} autoPlay={true}/>}
      <audio ref={audioRef} autoPlay={true} muted={false}/>
      {/*play remote participant audio*/}
    </div>
  );
};