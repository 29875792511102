import React, { Component } from "react";
import ReactDOM from "react-dom";

/*
 * @Deprecated Please use new Dialog component instead
 */
export default class DialogOld extends Component {
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (
      !domNode ||
      domNode === event.target ||
      !domNode.contains(event.target)
    ) {
      this.props.onClickOutside && this.props.onClickOutside();
    }
  };

  render() {
    return <div className={this.props.className}>{this.props.children}</div>;
  }
}
