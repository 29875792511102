import {LocationType, GroupPermission} from '~/API';
import {FC, useCallback} from 'react';
import {LocalizedMessage, localizedString} from '~/Locales';
import {useSearchParams} from '~/Services/Routes';
import {useIsCustomer, useIsInternalUser} from "~/Reducers/User";
import {QuotesFilters, QuotesPageTableTypes} from '../Reducer';
import {CloseIcon, FilterIcon} from '~/Components/Old/Icons';
import AccountManagerSelect from '~/Components/Old/AccountManagerSelect';
import Button from "~/Components/Old/Button";
import CustomerSelect from '~/Components/Old/CustomerSelect';
import DatePicker from '~/Components/Old/DatePicker';
import IconButton from "~/Components/Old/IconButton";
import IncotermSelect from "~/Components/Old/IncotermSelect";
import LocationSelect from '~/Components/Old/LocationSelect';
import PortSelect from '~/Components/Old/PortSelect';
import ProductSelectPublic from "~/Components/Old/ProductSelectPublic";
import ProductGroupsSelectPublic from "~/Components/Old/ProductGroupsSelectPublic";
import QuoteStatusSelect from '~/Components/Old/QuoteStatusSelect';
import Switch from '~/Components/Old/Switch';
import TextField from '~/Components/Old/TextField';
import UserSelect from '~/Components/Old/UserSelect';
import UserSelectPublic from "~/Components/Old/UserSelectPublic";
import VendorSelect from '~/Components/Old/VendorSelect';
import { useIsMobile } from "~/Hooks/useIsMobile";


const MOBILE_CLEAR_BUTTON_ID = 'mobileClearButton';

const buyerPermissions = [GroupPermission.CAN_BE_SALES_CONTACT_PERSON, GroupPermission.CAN_BE_ADMIN];

const Filters: FC<{
  showHideFilters?: () => void;
  filters: QuotesFilters;
  setFilter: (event: any) => void;
  pageFilters: QuotesFilters;
}> = ({showHideFilters, filters, setFilter, pageFilters}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearParameters = useCallback(({target}) => {
    setSearchParams({
      ...pageFilters,
      tableType: searchParams.tableType || QuotesPageTableTypes.QUOTES_PAGE_NONE,
      onlyMy: filters.onlyMy
    });
    (target.id === MOBILE_CLEAR_BUTTON_ID) && showHideFilters?.();
  }, [searchParams.tableType, setSearchParams, showHideFilters, pageFilters, filters.onlyMy]);

  const {
    status, customer, shipTo, buyer, product, productGroup, port, vendor, withChangesOnly, incoterm,
    accountManager, valueFrom, valueTo, validityDateFrom, validityDateTo
  } = filters;

  const isInternalUser = useIsInternalUser();
  const isCustomer = useIsCustomer();
  const isMobile = useIsMobile();

  return (
    <div className={'FiltersRightPanelMainContainer'}>
      <div className={'FiltersRowContainer FiltersTitleContainer mb-md'}>
        <div className={'FiltersRowContainer'}>
          <FilterIcon className={'TitleIcon'} size={16}/>
          <LocalizedMessage id={'title.filter'}/>
        </div>
        <IconButton onClick={showHideFilters}>
          <CloseIcon size={20}/>
        </IconButton>
      </div>

      {isMobile &&
        <>
          <div className={'mt-md flex-row align-center'}>
            <Switch
              id={'onlyArchivedOrDeliveredOrExpired'}
              value={filters.onlyArchivedOrDeliveredOrExpired}
              onChange={setFilter}
            />
            <div className={'ml-xs'}><LocalizedMessage id={'title.inactive'}/></div>
          </div>

          <div className={'my-md flex-row align-center'}>
            <Switch
              id={'onlyMy'}
              value={filters.onlyMy}
              onChange={setFilter}
            />
            <div className={'ml-xs'}><LocalizedMessage id={'title.show.only.my.quotes'}/></div>
          </div>
        </>
      }

      <QuoteStatusSelect
        id={'status'} label={localizedString('title.status')}
        value={status}
        onChange={setFilter}
        multiple
        showAll
      />

      {!isCustomer && <CustomerSelect
        id={'customer'} label={localizedString('title.customer')}
        className={'mt-xs'}
        value={customer}
        onChange={setFilter}
        multiple
        showAll
      />}

      <LocationSelect
        id={'shipTo'} label={localizedString('title.ship.to')}
        className={'mt-xs'}
        value={shipTo}
        onChange={setFilter}
        filter={{type: LocationType.SHIP_TO}}
        showCustomerCodes
        multiple
        showAll
      />

      {isCustomer
        ? <UserSelectPublic
          id={'accountManager'} label={localizedString('title.account.manager')}
          className={'mt-xs'}
          value={accountManager}
          onChange={setFilter}
          multiple
          showAll
        />
        : <UserSelect
          id={'buyer'} label={localizedString('title.buyer')}
          className={'mt-xs'}
          value={buyer}
          onChange={setFilter}
          byPermissions={buyerPermissions}
          multiple
          showAll
        />}

      <ProductSelectPublic
        id={'product'} label={localizedString('title.product')}
        className={'mt-xs'}
        value={product}
        onChange={setFilter}
        multiple
        showAll
      />

      {!isCustomer && <ProductGroupsSelectPublic
        id={'productGroup'} label={localizedString('title.product.group')}
        className={'mt-xs'}
        value={productGroup}
        onChange={setFilter}
        multiple
        showAll
      />}

      <PortSelect
        id={'port'} label={localizedString('title.destination')}
        className={'mt-xs'}
        value={port}
        onChange={setFilter}
        multiple
        showAll
      />

      {isCustomer && <IncotermSelect
        id={'incoterm'} label={'Incoterm'}
        className={'mt-xs'}
        value={incoterm}
        onChange={setFilter}
        multiple
        showAll
      />}

      {!isCustomer && <VendorSelect
        id={'vendor'} label={localizedString('title.vendor')}
        className={'mt-xs'}
        value={vendor}
        onChange={setFilter}
        multiple
        showAll
      />}

      {isInternalUser &&
        <>
          <AccountManagerSelect
            id={'accountManager'} label={localizedString('title.account.manager')}
            className={'mt-xs'}
            value={accountManager}
            onChange={setFilter}
            multiple
            showAll
          />

          <div className={'text-sm color-text-2 text-left mt-xs'}><LocalizedMessage id={'title.value'}/></div>
          <div className={'flex-row align-center mt-xxs'}>
            <TextField
              id={'valueFrom'} type={'number'} hideArrows
              value={valueFrom}
              onChange={setFilter}
              max={999999999}
            />
            &nbsp;{'-'}&nbsp;
            <TextField
              id={'valueTo'} type={'number'} hideArrows
              value={valueTo}
              onChange={setFilter}
              max={999999999}
            />
          </div>

          <div className={'text-sm color-text-2 text-left mt-xs'}><LocalizedMessage id={'title.validity.date'}/></div>
          <div className={'flex-row align-center mt-xxs'}>
            <DatePicker id={'validityDateFrom'} date={validityDateFrom} onChange={setFilter}/>
            &nbsp;{'-'}&nbsp;
            <DatePicker id={'validityDateTo'} date={validityDateTo} onChange={setFilter} endOfDay/>
          </div>
        </>
      }

      <div className={'mt-md flex-row align-center'}>
        <Switch id={'withChangesOnly'} value={withChangesOnly} onChange={setFilter}/>
        <div className={'ml-xs'}><LocalizedMessage id={'title.with.changes.only'}/></div>
      </div>

      <div className={'ButtonsContainer mt-sm'}>
        <Button view={'text'} onClick={clearParameters}>
          <LocalizedMessage id={'title.clear'}/>
        </Button>
      </div>

      <div className={'ButtonsContainerMobile mt-sm'}>
        <Button className={'ApplyButton'} onClick={showHideFilters}>
          <LocalizedMessage id={'title.apply'}/>
        </Button>
        <Button id={MOBILE_CLEAR_BUTTON_ID} view={'text'} onClick={clearParameters}>
          <LocalizedMessage id={'title.clear'}/>
        </Button>
      </div>
    </div>
  );
};

export default Filters;
