import {QuoteStatus, QuoteView} from "~/API";
import {FC, RefObject, useCallback} from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { quotesRootPath } from "~/Services/Routes";
import { formattedCeilNumber} from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { useQuoteValue, useQuoteProductCodes, isQuoteExpired } from "~/Reducers/Quotes";
import { useQuotesPageReduxState } from "../../Reducer";
import LinearProgress from "~/Components/Old/LinearProgress";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import {QuoteShowUnread, QuoteStatusLabel} from "../QuotesHelper";
import QuoteArchiveDeleteButton from "../QuoteArchiveDeleteButtons";
import QuoteReference from "../QuoteReference";
import { useIsInternalUser } from "~/Reducers/User";
import QuotesPageEmptyState from "../QuotesPageEmptyState";
import { DateTimeView } from "~/Components/Views/DateView";
import {useAMUserNameStrings} from "~/Reducers/Users";
import { useIsMobile } from "~/Hooks/useIsMobile";
import {classNames} from "react-ui-basics/Tools";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";


const QuotesPageQuotesTable: FC<{
  scrollRef: RefObject<HTMLDivElement>,
  onScrolledToBottom: () => void
}> = ({ scrollRef, onScrolledToBottom }) => {
  const { quotes, loading } = useQuotesPageReduxState();
  const isInternalUser = useIsInternalUser();
  const isMobile = useIsMobile();

  const emptyState = quotes?.length === 0 && !loading;

  return emptyState
    ? <QuotesPageEmptyState />
    : (
      <>
        {loading && <div className={'progress-bar'}><LinearProgress /></div>}
        <ScrollableForPagination
          estimatedElementHeight={50}
          numberOfElementsBeforeLoading={10}
          ref={scrollRef}
          className={'flex bg-black-12 overflow-auto'}
          onScrolledToBottom={onScrolledToBottom}
        >
            { !isMobile &&
              <table className={'basic-table basic-table-clickable'}>
                <thead>
                  <tr>
                    <th><LocalizedMessage id={'title.q.id'} /></th>
                    <th><LocalizedMessage id={'title.status'} /></th>
                    <th><LocalizedMessage id={'title.ship.to'} /></th>
                    {isInternalUser && <th><LocalizedMessage id={'title.customer'} /></th>}
                    <th><LocalizedMessage id={'title.salesperson'} /></th>
                    <th><LocalizedMessage id={'title.products'} /></th>
                    <th><LocalizedMessage id={'title.product.code'} /></th>
                    {isInternalUser && <th><LocalizedMessage id={'title.reference'} /></th>}
                    <th className={'text-right'}><LocalizedMessage id={'title.value'} /></th>
                    <th><LocalizedMessage id={'title.validity.date'} /></th>
                    <th><LocalizedMessage id={'title.actions'} /></th>
                    <th />
                  </tr>
                </thead>
                <tbody data-cy="quotes-list">
                  {quotes.map(quote => <QuoteRow key={quote.id} {...{ quote }} />)}
                </tbody>
              </table>
            }
            {isMobile && quotes.map(quote => <QuoteRowMobile key={quote.id} quote={quote}/>)}
        </ScrollableForPagination>
      </>
    );
};

export default QuotesPageQuotesTable;

const QuoteRow: FC<{ quote: QuoteView }> = ({ quote }) => {
  const history = useHistory();
  const value = useQuoteValue(quote.id);
  const productCodes = useQuoteProductCodes(quote.id);
  const { status, customerCompanyName, shipToAccountManagerName, offerId, currency, offerProductCount, salesOrderIds,
    products, requestForQuoteId, validityDate, accountManagerName, shipToName, unreadMessagesCount } = quote;
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = status === QuoteStatus.DRAFT;

  const onOpen = useCallback(() => history.push(`${quotesRootPath}${quote.id}`), [quote.id, history]);

  const isInternalUser = useIsInternalUser();

  const isExpired = isQuoteExpired(validityDate);

  const managers = useAMUserNameStrings(accountManagerName, shipToAccountManagerName);

  return (
    <tr onClick={onOpen} className={classNames(QuoteShowUnread(quote.readMark, isExpired) ? "row-unread" : "")}>
      <td className={'no-wrap'}>{`Q-${quote.id}`}</td>
      <td>
        {isExpired ? 'Expired' : <QuoteStatusLabel status={status} read={quote.readMark} />}
      </td>
      <td>{shipToName}</td>
      {isInternalUser && <td>{customerCompanyName}</td>}
      <td>{managers}</td>
      <td>{`${products?.length || 0}`} {isInternalUser && `/${offerProductCount}`}</td>
      <td>{productCodes}</td>
      {isInternalUser && <td><QuoteReference offerId={offerId} salesOrderIds={salesOrderIds} requestForQuoteId={requestForQuoteId} simple /></td>}
      <td className={'text-right no-wrap color-orange-1'}>
        {`${currencySymbol} ${formattedCeilNumber(value, 2) || '0'}`}
      </td>
      <td><DateTimeView date={validityDate} /></td>
      <td>
        <div className={'flex-row align-center justify-center'}>
          <QuoteArchiveDeleteButton {...{ quote }} />
        </div>
      </td>
      <td>{!isDraft && <ChatUnreadBadge unread={unreadMessagesCount} />}</td>
    </tr>
  );
};

const QuoteRowMobile: FC<{ quote: QuoteView }> = ({ quote }) => {
    const isExpired = isQuoteExpired(quote.validityDate);
    const history = useHistory();
    const onOpen = useCallback(() => history.push(`${quotesRootPath}${quote.id}`), [quote.id, history]);
    const value = useQuoteValue(quote.id);
    const currencySymbol = useFindCurrencySymbol(quote.currency);
    const isInternalUser = useIsInternalUser();

    return (
        <div className="p-md flex-col br-bottom-black-10" onClick={onOpen}>
            <div className="flex flex-row justify-between align-center">
                <div>
                    Quote {quote.id}
                </div>

                <div className="flex-row gap-sm">
                    <div className="bg-blue-10 rounded-xxs px-xs py-xxs">
                        {isExpired ? 'Expired' : <QuoteStatusLabel status={quote.status} />}
                    </div>
                </div>
            </div>

            <div className="flex flex-row justify-between align-start gap-lg mt-lg">
                <div className={'flex-col flex text-left'}>
                    <div className={'text-sm color-text-3'}><LocalizedMessage id={'title.ship.to.name'}/></div>
                    <div className={'text-pre-wrap mt-xxs'}>{quote.shipToName || '-'}</div>
                </div>
                {isInternalUser &&
                    <div className={'flex-col flex text-left'}>
                        <div className={'text-sm color-text-3'}><LocalizedMessage id={'title.customer.name'}/></div>
                        <div className={'text-pre-wrap mt-xxs'}>{quote.customerCompanyName || '-'}</div>
                    </div>
                }
            </div>
            <div className={'flex flex-row justify-between align-center gap-lg mt-lg'}>
                <div className={'text-pre-wrap mt-xxs color-orange-1'}>
                    {`${currencySymbol} ${formattedCeilNumber(value, 2) || '0'}`}
                </div>
                <QuoteArchiveDeleteButton {...{ quote }} />
            </div>
        </div>
    )
}
