import { Notification, NotificationRef } from "~/API";
import { useUser } from "~/Reducers/Users";
import { useCompany } from "~/Reducers/Companies";
import { useCompanyLocations, useLocations } from "~/Reducers/Locations";
import { TbUser } from "react-icons/tb";
import { FC } from "react";
import { NotificationCard } from "~/Components/Notifications/NotificationCard";

export const NewUserCard: FC<{
  notification: Notification;
  markRead: () => void;
  refetchNotifications: () => void;
}> = ({ notification, markRead, refetchNotifications }) => {
  const link =
    `/chats/external/` + notification.data.refs[NotificationRef.CHAT]?.[0];
  const user = useUser(
    Number(notification.data.refs[NotificationRef.MARKETPLACE_USER]?.[0]),
  );

  const company = useCompany(user?.companyId);
  useCompanyLocations(user?.companyId);
  const locations = useLocations();
  const usersLocations = (user?.locations || [])
    .map((id) => locations[id]?.name)
    .filter((it) => !!it)
    .slice(0, 3);
  if (usersLocations.length === 3) usersLocations[2] = "...";

  if (link === "/") {
    console.log("unknown notification ref: ", notification.data);
    return null;
  }

  return (
    <NotificationCard
      icon={<TbUser size={20} />}
      link={link}
      dateCreated={notification.dateCreated}
      markRead={markRead}
      read={notification.read}
      notificationId = {notification.id}
      refetchNotifications={refetchNotifications}
    >
      <b>
        {user?.name || ""} {user?.surname || ""}
      </b>{" "}
      from {company?.legalName || ""} ({usersLocations.join(", ")}) has
      completed account registration. Click here to start chatting.
    </NotificationCard>
  );
};
