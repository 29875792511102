import {ChangeEvent, FC, ReactNode} from "react";
import { TbUpload } from "react-icons/tb";
import { Button } from "./Button";
import {LocalizedMessageKey} from "~/Locales";
import {Slot, Slottable} from "@radix-ui/react-slot";
import {useMutate} from "~/Hooks";

export const ButtonFileUpload: FC<{
  acceptedFileTypes: string[];
  onSubmitted: (files: File[]) => Promise<void> | void;
  isDisabled?: boolean;
  multiple?: boolean;
  label?: LocalizedMessageKey;
  className?: string;
  icon?: ReactNode;
  asChild?: boolean;
  children?: ReactNode;
}> = (props) => {
  const Comp = props.asChild ? Slot : "div";

  const handleFileSelected = useMutate(async (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files);
    await props.onSubmitted(files);
  });

  return (
    <div>
      <label htmlFor="file-upload" className="">
        <Button
          color="gray"
          label={props.label}
          leftIcon={props.icon || <TbUpload size={16} />}
          loading={handleFileSelected.loading}
          className={props.className}
          asChild
        >
          {<Comp>
            <Slottable>{props.children}</Slottable>
          </Comp>}
        </Button>
      </label>
      <input
        id="file-upload"
        type="file"
        accept={props.acceptedFileTypes.join(", ")}
        onChange={handleFileSelected.mutate}
        multiple={props.multiple}
        className="hide"
      />
    </div>
  );
};
