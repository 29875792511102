import { lazy, Suspense } from 'react';

const EmptyDeliveriesImage = lazy(() => import('./EmptyDeliveriesImage.jsx'));
const EmptyProductsImage = lazy(() => import("./EmptyProductsImage"));
const EmptyRequestsImage = lazy(() => import('./EmptyRequestsImage'));

export const EmptyDeliveriesImageLazy = () => {
  return <Suspense fallback={<div />}><EmptyDeliveriesImage /></Suspense>;
}

export const EmptyProductsImageLazy = () => {
  return <Suspense fallback={<div />}><EmptyProductsImage /></Suspense>;
};

export const EmptyRequestsImageLazy = () => {
  return <Suspense fallback={<div />}><EmptyRequestsImage /></Suspense>;
};

