import {FC, ReactNode, useEffect, useMemo} from "react";
import { useHistory } from "react-router-dom";
import { ls } from "~/Locales";
import { productGroupsRootPath } from "~/Services/Routes";
import { useIsAdmin, useIsUFPBuyer } from "~/Reducers/User";
import PriceView from "~/Components/Views/PriceView";
import {
  params,
  useDebounce,
  useFormData,
  useMutate,
  usePageTitle,
} from "~/Hooks";
import {
  Button, DropdownButton,
  FormCheckbox,
  FormInput,
  Input,
  Table,
  TableCell,
} from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import API, { ProductGroupView } from "~/API";
import DateView from "~/Components/Views/DateView";
import {
  TbCheck,
  TbPencil,
  TbX,
} from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import { DateInput } from "~/Components/UI/Forms/DateInput";
import useSWR, { mutate } from "swr";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import {
  ProductGroupState,
  setEditData,
  setPrice,
  setProductPrice,
  setProductPrices,
  setValidityDate,
} from "~/Pages/Products/GroupsPage/Reducer";
import { ProductTypeCombobox } from "~/Components/Products/ProductTypeCombobox";
import * as z from "zod";
import {UserName} from "~/Components/Users/UserName";
import {useProductGroups} from "~/Data/Products/ProductGroups";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {trackEvent} from "~/Services/Tracking";

const routeParser = z.object({
  search: params.string,
  productType: params.string,
  onlyMy: params.bool,
});

const ProductGroupsTab = () => {
  usePageTitle("Product Groups");

  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const edit = useAppSelector((state) => state.productGroupsPage);

  const filterForm = useFormData<{
    search?: string;
    onlyMy?: boolean;
    productType?: string;
  }>({ onlyMy: false }, { routeParser });
  const searchDebounce = useDebounce(filterForm.data.search);

  const onSave = async () => {
    await saveGroupPrices(edit);
    dispatch(setEditData(null));
  }

  const groups = useProductGroups({
    onlyMy: filterForm.data.onlyMy || false,
    productType: filterForm.data.productType || "",
    search: searchDebounce || "",
  });

  const canEdit = (filterForm.data.onlyMy && isUFPBuyer) || isAdmin;

  const onRowClicked = (row: ProductGroupView) => {
    history.push(`${productGroupsRootPath}${row.id}`);
  };

  const data = useMemo(() => groups.data?.flat() || [], [groups.data]);
  const columns = useMemo(() => getColumns(groups.mutate), [groups.mutate]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      columnVisibility: {
        edit: canEdit,
      },
      columnPinning: {
        left: ["dropdown"],
        right: ["edit"],
      },
    },
  });

  return (
    <div className="flex flex-col overflow-hidden">
      {edit.editData?.id && <UnsavedChangesDialog onSave={onSave} />}

      <div className="flex flex-col overflow-hidden bg-black-12 rounded-xxs align-start">
        <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
          <FormInput
            id="search"
            formData={filterForm}
            placeholder="placeholder.search.by.code.and.description"
            showClear
            type="search"
            className="w-min-8xl"
          />
          <ProductTypeCombobox
            id="productType"
            formData={filterForm}
            clearValue=""
            placeholder="title.product.type"
            className="w-min-6xl"
            showClear
          />
          <FormCheckbox
            id="onlyMy"
            formData={filterForm}
            label="phrase.show.only.my.product.groups"
          />
        </div>

        <LinearLoader loading={groups.isValidating} />

        <Table
          table={table}
          onRowClick={onRowClicked}
          className="w-full"
          renderSubComponent={ProductsSubTable}
        />
      </div>
    </div>
  );
};

export default ProductGroupsTab;

const columnHelper = createColumnHelper<ProductGroupView>();

const getColumns = (mutateGroups: () => Promise<any>) => [
  columnHelper.display({
    id: "dropdown",
    cell: DropdownButton,
    size: 0,
  }),
  columnHelper.accessor("productsCount", {
    header: "#",
    size: 0,
  }),
  columnHelper.accessor("name", {
    header: ls("title.product.group"),
    cell: (data) => <div className="text-no-wrap">{data.getValue()}</div>,
    size: 512,
  }),
  columnHelper.accessor("buyerUserIds", {
    header: ls("title.buyers"),
    cell: (data) => (
    <div className="flex-col">
      {data.getValue()?.slice(0, 3).map<ReactNode>((d, i) => (
        <div className="text-no-wrap">
          <UserName id={d}/>
          {i < data.getValue().length - 1 && ', '}
        </div>
      ))}
      {data.getValue()?.length > 3 && ` and ${data.getValue()?.length - 3} more`}
    </div>
    ),
  }),
  columnHelper.display({
    id: "buyTerms",
    header: ls("title.buying.term"),
    cell: ({ row }) => (
      <div className="text-no-wrap">
        <span>{row.original.incoterm || "-"}</span>
        <span>{" / "}</span>
        <span>{row.original.portName || row.original.address || "-"}</span>
      </div>
    ),
    size: 0,
  }),
  columnHelper.accessor("price", {
    header: ls("title.price"),
    cell: ({ row, getValue }) => {
      const dispatch = useAppDispatch();
      const editData = useAppSelector(
        (state) => state.productGroupsPage.editData,
      );

      const onPriceChange = (e: any) => {
        dispatch(setPrice(e.target.value));
      };

      if (editData?.id == row.original.id)
        return (
          <Input
            id="price"
            value={`${editData?.price}`}
            onChange={onPriceChange}
            type="number"
            className="w-min-4xl"
          />
        );

      return (
        <PriceView
          price={getValue() || null}
          currency={row.original.currency}
        />
      );
    },
    size: 0,
  }),
  columnHelper.accessor("unitOfMeasure", {
    header: ls("title.uom"),
    size: 0,
  }),
  columnHelper.accessor("validityDate", {
    header: ls("title.validity.date"),
    cell: ({ row, getValue }) => {
      const dispatch = useAppDispatch();
      const editData = useAppSelector(
        (state) => state.productGroupsPage.editData,
      );

      const onDateChange = (e: any) => {
        dispatch(setValidityDate(e.target.value));
      };

      if (editData?.id == row.original.id)
        return (
          <DateInput
            id="validityDate"
            value={editData?.validityDate || ""}
            onChange={onDateChange}
            className="w-min-4xl"
          />
        );

      return <DateView date={getValue()} />;
    },
    size: 0,
  }),
  columnHelper.accessor("dateUpdated", {
    header: ls("title.changed"),
    cell: (data) => <DateView date={data.getValue()} />,
    size: 0,
  }),
  columnHelper.display({
    id: "edit",
    header: "",
    size: 48,
    cell: ({ row }) => {
      const dispatch = useAppDispatch();
      const edit = useAppSelector((state) => state.productGroupsPage);

      const saveCall = useMutate(async () => {
        await saveGroupPrices(edit);
        await mutateGroups();
        await mutate(["api/products/from/group", edit.editData.id]);
        dispatch(setEditData(null));
        trackEvent("Product Group Edited");
      });

      const onEditClicked = () => dispatch(setEditData(row.original));
      const onCancelClicked = () => dispatch(setEditData(null));

      if (edit.editData?.id == row.original.id)
        return (
          <div className="flex-row gap-xs">
            <Button
              color="blue"
              leftIcon={<TbCheck size={16} />}
              loading={saveCall.loading}
              onClicked={saveCall.mutate}
            />
            <Button
              color="red"
              leftIcon={<TbX size={16} />}
              onClicked={onCancelClicked}
            />
          </div>
        );

      return (
        <Button
          color="gray"
          variant="text"
          leftIcon={<TbPencil size={16} />}
          onClicked={onEditClicked}
        />
      );
    },
  }),
];

const saveGroupPrices = async (edit: ProductGroupState) => {
  await API.updateProductGroupPrices(
    { id: edit.editData?.id },
    {
      price: edit.editData.price,
      validityDate: edit.editData.validityDate,
      productPrices: Object.keys(edit.productPrices).map((id) => ({
        id: +id,
        price: edit.productPrices[id],
      })),
    },
  );
}

const useProductsFromGroup = (groupId: number) =>
  useSWR(
    ["api/products/by/group", groupId],
    () =>
      API.getProductsV3({
        groupId: [groupId],
        active: true,
        limit: 1000,
      }),
    { revalidateOnFocus: false },
  );

const ProductsSubTable: FC<{
  row: Row<ProductGroupView>;
}> = (props) => {
  const dispatch = useAppDispatch();
  const productPrices = useAppSelector(
    (state) => state.productGroupsPage.productPrices,
  );

  const editId = useAppSelector(
    (state) => state.productGroupsPage.editData?.id,
  );
  const isEditing = editId == props.row.original.id;

  const products = useProductsFromGroup(props.row.original.id);

  const onPriceChange = (productId: number, price: number) => {
    dispatch(setProductPrice({ key: productId, price }));
  };

  useEffect(() => {
    if (isEditing)
      dispatch(
        setProductPrices(
          products.data?.reduce(
            (prev, prod) => ({
              ...prev,
              [prod.id]: prod.price,
            }),
            {},
          ) || {},
        ),
      );
    else if (editId == null) dispatch(setProductPrices({}));
  }, [isEditing, products.data]);

  if (products.isLoading)
    return (
      <tr>
        <td colSpan={9}>
          <LinearLoader loading />
        </td>
      </tr>
    );

  return (
    <>
      {products.data?.map((product) => (
        <tr className="bg-black-11-5 color-text-2" key={product.id}>
          <TableCell />
          <TableCell>{product.productCode}</TableCell>
          <TableCell colSpan={3}>{product.msrDescription}</TableCell>
          {!!product.rlPriceId && <TableCell>RL</TableCell>}
          {!isEditing && !product.rlPriceId && (
            <TableCell>
              <PriceView
                price={product.price || null}
                currency={product.currency}
              />
            </TableCell>
          )}
          {isEditing && !product.rlPriceId && (
            <TableCell>
              <Input
                id="price"
                value={`${productPrices[product.id]}`}
                onChange={(e) => onPriceChange(product.id, +e.target.value)}
                type="number"
                className="w-min-4xl"
              />
            </TableCell>
          )}
          {!!product.rlPriceId && <TableCell />}
          <TableCell colSpan={3} />
        </tr>
      ))}
    </>
  );
};
