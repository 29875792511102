import useSWRInfinite from "swr/infinite";
import API, {Length, Order} from "~/API";

export const getLengthListKey = (
  filters: LengthFilters,
  offset: number,
  previousPageData: Length[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/lengths", ...filters, offset };
};

const PAGE_SIZE = 40;

export interface LengthFilters {
  query?: string;
  search?: string;
  orderBy?: string;
  inactive?: boolean;
  order?: Order;
  offset?: number;
  limit?: number;
}

export const useLengths = (filters: LengthFilters) =>
  useSWRInfinite(
    (i, p) => getLengthListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getLengths({ ...filters, offset: key.offset, limit: PAGE_SIZE }),
    { revalidateOnFocus: false },
  );

export const getLengthByGroupListKey = (
  filters: { groupId: number; search: string; inactive: boolean },
  offset: number,
  previousPageData: Length[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/lengths/byProductGroup", ...filters, offset };
};

export const useLengthsByProductGroup = (filters: {
  groupId: number;
  search: string;
  inactive: boolean;
}) =>
  useSWRInfinite(
    (i, p) => getLengthByGroupListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getLengthsByProductGroup({
        ...filters,
        offset: key.offset,
        limit: PAGE_SIZE,
      }),
    { revalidateOnFocus: false },
  );
