import React, {FC, ReactNode} from 'react';
import dayjs from "dayjs";
import "dayjs/locale/de";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";

import admin_translations from "../Pages/Admin/admin_translations.json";
import api_type_translations from "./api_type_translations.json";
import company_translations from "../Pages/Companies/company_translations.json";
import error_translations from "./error_translations.json";
import general_translations from "./general_translations.json";
import logistics_translations from "../Pages/Logistics/logistics_translations.json";
import notification_translations from "../Components/Notifications/notification_translations.json";
import offer_translations from "../Pages/Offers/offer_translations.json";
import order_translations from "../Pages/Orders/orders_translations.json";
import products_translations from "../Pages/Products/products_translations.json";
import quote_translations from "../Pages/Quotes/quote_translations.json";
import request_translations from "../Pages/Requests/request_translations.json";
import user_translations from "../Pages/Users/user_translations.json";

dayjs.extend(relativeTime);
dayjs.extend(localeData);

export const locales = ["en", "de"] as const;
export type Locale = typeof locales[number];

const i18n = {
    ...admin_translations,
    ...api_type_translations,
    ...company_translations,
    ...error_translations,
    ...general_translations,
    ...logistics_translations,
    ...notification_translations,
    ...offer_translations,
    ...order_translations,
    ...products_translations,
    ...quote_translations,
    ...request_translations,
    ...user_translations,
    // add additional translation slices here
} as const;

export type LocalizedMessageKey = keyof typeof i18n;

const getUserPreferredLanguage = (): Locale => {
    const navigator = window.navigator;
    if (!navigator)
        return 'en';

    if (navigator.languages && navigator.languages.length && locales.findIndex((val) => val == navigator.languages[0]) !== -1)
        return navigator.languages[0] as Locale;

    if (navigator.language && locales.findIndex((val) => val == navigator.language as any) !== -1)
        return navigator.language as Locale;

    return 'en';
};

let userLocale: Locale | null = null;
let defaultLocale = getUserPreferredLanguage();

export const setLocale = (locale: Locale) => {
    userLocale = locale;
}

export const getLocale = () => userLocale || defaultLocale;

const formatTemplate = (template: string, ...args: ReactNode[]) => {
    const templateParts = template?.split("{}") || [];

    // join the template parts and args by alternating between them
    return templateParts.map((t, i) => [ t, args[i] || "" ]).flat();
};

const getTemplate = (templateKey: LocalizedMessageKey, locale: Locale) => {
    const template = i18n[templateKey];

    if (template === undefined)
        return templateKey; // default, just return key

    if (typeof template === "string" || typeof template === "number")
        return template; // template found, only english

    // object (multiple translations)
    if (template[locale] == undefined)
        return template["en"];

    return template[locale];
}

export const localizedString = (key: LocalizedMessageKey, ...args: ReactNode[]) => {
    if (typeof key === "string") {
        const template = getTemplate(key, userLocale || defaultLocale);
        return formatTemplate(template, args).join("");
    } else {
        return key;
    }
};

export const LocalizedMessage: FC<{
    id: LocalizedMessageKey,
    children?: ReactNode
}> = (props) => {
    let c = React.Children.toArray(props.children);
    const template = getTemplate(props.id, userLocale || defaultLocale);
    if (typeof props.id === "string") {
        return formatTemplate(template, c);
    } else {
        return props.id;
    }
}

export const ls = localizedString;
export const LC = LocalizedMessage;
