import API from '~/API';
import Environment from "./Environment";

export default class Network {
  static initializeAPI() {

    if (Environment.isLocalhost) {
      API.setBasePath(import.meta.env.VITE_BE_API_URL);
    } else {
      API.setBasePath(window.location.origin + '/api');
    }

    API.setTransport(API.Constants.TRANSPORT_WS);
    API.showLog(false);
    API.showStats(false);
    API.getWs().connect();
  }
}
