import { useEffect } from "react";
import { useCompanies, getCompany, getCustomerCompaniesForSelect, useCompanyLabels } from "~/Reducers/Companies";
import SelectWithPaging from "./SelectWithPaging";
import {useAppDispatch} from "~/StoreTypes";


const CustomerSelect = (props) => {
  const dispatch = useAppDispatch();
  const companies = useCompanies();

  useEffect(() => {
    if (Array.isArray(props.value)) {
      props.value.forEach(companyId => {
        if (!companies[companyId]) dispatch(getCompany(companyId));
      });
    } else {
      if (!companies[props.value]) dispatch(getCompany(props.value));
    }
  }, [props.value]);

  return <SelectWithPaging {...props}
    getData={getCustomerCompaniesForSelect}
    useDataLabels={useCompanyLabels}
  />;
};

export default CustomerSelect;
