
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useToggle } from '~/Hooks/Old';
import { LocalizedMessage } from '~/Locales';
import { quotesRootPath } from '~/Services/Routes';
import { useCompany } from '~/Reducers/Companies';
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import { useIncotermUsesAddress } from '~/Reducers/Incoterms';
import { ProductType } from "~/Reducers/Products";
import { calculateQuoteProductsTotalValue } from '~/Pages/Quotes/Components/QuotesHelper';
import { useOrderedQuotesForOffer } from "./Actions";
import CompanyView from '~/Components/Views/CompanyView';
import { IncotermName } from '~/Components/Old/IncotermSelect';
import { LengthsView } from '~/Components/Old/LengthsView';
import LocationName from '~/Components/Old/LocationName';
import PortView from '~/Components/Ports/PortView';
import PriceView from '~/Components/Views/PriceView';
import UserName from '~/Components/Old/UserName';
import './OrderedQuotesTable.scss';


const OrderedQuotesTable = ({ products }) => {
  const { id: offerId } = useParams<{id: string}>();
  const orderedQuotes = useOrderedQuotesForOffer(Number(offerId));

  const quotes = useMemo(() => {
    const productCodes = products.map(product => product.productCode);
    return orderedQuotes.filter(quote =>
      quote.products?.some(product => productCodes.includes(product.productCode))
    );
  }, [orderedQuotes, products]);

  if (!quotes.length) return null;

  return (
    <>
      <div className={'horizontal-line'} />
      <div className={'text-lg text-left ml-md'}>
        <LocalizedMessage id={'title.ordered.quotes.of.this.offer'} />
      </div>

      <table className={'basic-table OfferDetailsOrderedQuotesTable'}>
        <thead>
          <tr>
            <th />
            <th><LocalizedMessage id={'title.id'} /></th>
            <th><LocalizedMessage id={'title.customer'} /></th>
            <th><LocalizedMessage id={'title.ship.to'} /></th>
            <th><LocalizedMessage id={'title.ufp.am'} /></th>
            <th><LocalizedMessage id={'title.incoterm'} /></th>
            <th className={'text-right'}><LocalizedMessage id={'title.products'} /></th>
            <th><LocalizedMessage id={'title.quote'} /></th>
            <th className={'text-right'}><LocalizedMessage id={'title.total'} /></th>
          </tr>
        </thead>
        <tbody>
          {quotes.map(quote => <OrderedQuoteRow key={quote.id} {...{ quote }} />)}
        </tbody>
      </table>
    </>
  );
};

export default OrderedQuotesTable;


const OrderedQuoteRow = ({ quote }) => {
  const [expanded, toggleExpanded] = useToggle();

  const { id, customerCompanyId, shipToLocationId, accountManagerUserId, incoterm, products,
    incotermAddressDestination, incotermPortIdDestination, currency } = quote;

  const customerCompany = useCompany(customerCompanyId);
  const canShowAddressField = useIncotermUsesAddress(incoterm);

  const totalValue = calculateQuoteProductsTotalValue(products);

  return (
    <>
      <tr>
        <td onClick={toggleExpanded} className={'ArrowCell'}>
          {expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}
        </td>
        <td>{customerCompany?.code}</td>
        <td><CompanyView id={customerCompanyId} /></td>
        <td><LocationName id={shipToLocationId} /></td>
        <td><UserName id={accountManagerUserId} /></td>
        <td>
          <IncotermName incoterm={incoterm} />
          {canShowAddressField
            ? incotermAddressDestination ? `/${incotermAddressDestination}` : ''
            : incotermPortIdDestination ? <>/<PortView id={incotermPortIdDestination} /></> : ''}
        </td>
        <td className={'text-right'}>{products?.length}</td>
        <td><a href={`${quotesRootPath}${id}`} target="_blank" rel="noreferrer noopener">
          {`Q-${id}`}
        </a></td>
        <td className={'text-right text-lg'}><PriceView price={totalValue} currency={currency} decimals={0} /></td>
      </tr>
      {expanded && <OrderedQuoteSubRows {...{ quote }} />}
    </>
  );
};

const OrderedQuoteSubRows = ({ quote }) => {
  return (
    <tr>
      <td colSpan={9} className={'SubStringCell'}>
        <table className={'OfferDetailsOrderedQuotedSubTable'}>
          <tbody>
            <tr>
              <th><LocalizedMessage id={'title.code'} /></th>
              <th><LocalizedMessage id={'title.description'} /></th>
              <th><LocalizedMessage id={'title.length'} /></th>
              <th className={'text-right'}><LocalizedMessage id={'title.quantity'} /></th>
              <th><LocalizedMessage id={'title.uom'} /></th>
              <th className={'text-right'}><LocalizedMessage id={'title.sellprice'} /></th>
              <th className={'text-right'}><LocalizedMessage id={'title.subtotal'} /></th>
            </tr>
            {quote.products?.map((product, index) => <OrderedQuoteProductSubRow key={index} currency={quote.currency} {...{ product }} />)}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const OrderedQuoteProductSubRow = ({ product, currency }) => {
  const { productCode, productDescription, lengthIds, productType, quantity, uom, price } = product;

  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const subtotal = quantity * price;

  return (
    <tr>
      <td>{productCode}</td>
      <td>{productDescription}</td>
      <td>{showLength
        ? <LengthsView {...{ lengthIds }} />
        : '-'
      }</td>
      <td className={'text-right'}>{quantity}</td>
      <td>{uom}</td>
      <td className={'text-right'}><PriceView price={price} currency={currency} /></td>
      <td className={'text-right'}><PriceView price={subtotal} currency={currency} decimals={0} /></td>
    </tr>
  );
};
