import API, {FeatureFlag} from "~/API";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, useAppSelector} from "~/StoreTypes";

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState: { enabledFlags: [] },
  reducers: {
    setEnabledFlags: (state, action: PayloadAction<FeatureFlag[]>) => {
      state.enabledFlags = action.payload;
    }
  },
  selectors: {
    isFlagEnabled: (state, flag: FeatureFlag) => state.enabledFlags.includes(flag),
  }
});

export const loadEnabledFlags = async (dispatch: AppDispatch) => {
  const flags = await API.getEnabledFeatureFlags();
  dispatch(featureFlagsSlice.actions.setEnabledFlags(flags));
}

export const useIsFlagEnabled = (flag: FeatureFlag) =>
  useAppSelector((state) => featureFlagsSlice.selectors.isFlagEnabled(state, flag));

export default featureFlagsSlice.reducer;