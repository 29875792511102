import {useToggle} from "~/Hooks/Old";
import React, {useEffect, useState} from "react";
import { Button } from "~/Components/UI";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import Select from "~/Components/Old/Select";
import {LocalizedMessage, LocalizedMessageKey, ls} from "~/Locales";
import API, {ConfirmOfferRequest, OfferTypeOfPurchase} from "~/API";
import {getOffer, useOffer} from "~/Reducers/Offers";
import LinearProgress from "~/Components/Old/LinearProgress";
import DatePicker from "~/Components/Old/DatePicker";
import "./BuyerConfirmOfferButton.scss";
import TextField from "~/Components/Old/TextField";
import Toast from "~/Components/Toast";
import {useAppDispatch} from "~/StoreTypes";
import {useFormData} from "~/Hooks";
import {ShipViaCombobox} from "~/Components/Comboboxes/ShipViaCombobox";

const typeOfPurchaseKeys = Object.keys(OfferTypeOfPurchase);
const typeOfPurchaseLabels = Object.fromEntries(typeOfPurchaseKeys.map((key) => [key, ls(`offer.type.of.purchase.${key}` as LocalizedMessageKey)]));

const todayDate = new Date();

export const BuyerConfirmOfferButton = ({offerId, saveOffer}) => {
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const {quotes} = useOffer(offerId);

  const [requestData, setRequestData] = useState({
    typeOfPurchase: null,
    dueByDate: null,
    locationCode: null,
    quoteId: null,
  });

  const form = useFormData(
      {
        shipVia: '',
        unitTypeCode: null
      }
  )

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const showShipVia = requestData.typeOfPurchase !== null;
  const showDueByDate = requestData.typeOfPurchase === OfferTypeOfPurchase.STOCK_050 || requestData.typeOfPurchase === OfferTypeOfPurchase.PLANT_SALE;
  const showQuotes = requestData.typeOfPurchase === OfferTypeOfPurchase.PLANT_SALE;
  const showLocationCode = requestData.typeOfPurchase === OfferTypeOfPurchase.PLANT_SALE && requestData.quoteId;

  const isValidRequest = form.data.shipVia && requestData.typeOfPurchase && (!showDueByDate || requestData.dueByDate) && (!showQuotes || requestData.quoteId) && (!showLocationCode || requestData.locationCode);

  const onConfirm = async () => {
    setLoading(true);
    try {
      const request: ConfirmOfferRequest = {
        ...requestData,
        shipVia: form.data.shipVia,
        unitTypeCode: form.data.unitTypeCode
      }
      await API.confirmOffer({ id: offerId }, request);
      Toast.Success("Offer confirmed and order was successfully created.");
      toggleConfirmSubmitDialog();
      dispatch(getOffer(offerId));
    } catch (error) {
      Toast.ApiError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      let locationCode;
      if (requestData.quoteId) {
        const quote = await API.getQuote({ id: requestData.quoteId });
        if (quote && quote.shipToLocationId) {
          const location = await API.getLocation({ id: quote.shipToLocationId});
          locationCode = location?.locationCode;
        }
      }
      setRequestData(prevRequestData => ({
        ...prevRequestData,
        locationCode
      }));
    })();
  }, [requestData.quoteId]);

  const onFieldChange = ({target}) => {
    return setRequestData(prevData => ({
      ...prevData,
      [target.id]: target.value
    }));
  };
  const onDateChange = ({target}) => setRequestData(prevData => ({
    ...prevData,
    [target.id]: target.value.slice(0, 10)
  }));

  return <>
    <Button
      onClicked={toggleConfirmSubmitDialog}
      label="offer.confirm"
    />

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Offer Confirmation'}
        description={'Do you want to confirm this Offer? '}
        description2={'Once this offer is confirmed, the order will be created'}
        confirmTitle={'Confirm Offer'}
        closeTitle={<LocalizedMessage id={'title.cancel'}/>}
        onConfirm={onConfirm}
        onClose={toggleConfirmSubmitDialog}
        disableConfirmButton={loading || !isValidRequest}
      >
        <div className={'ConfirmOfferFieldsContainer'}>
          <div>
            <div className={'Title'}>Type of Purchase</div>
            <Select
              className={''}
              id={'typeOfPurchase'}
              value={requestData.typeOfPurchase}
              onChange={onFieldChange}
              data={typeOfPurchaseKeys}
              labels={typeOfPurchaseLabels}
            />
          </div>

          {showShipVia && <div>
            <ShipViaCombobox
                isDomestic={true}
                showUnits = {true}
                formData={form}
                label="title.ship.via"
            />

          </div>}

          {showDueByDate && <div>
                <div className={'Title'}>Due By Date</div>
                <DatePicker id={'dueByDate'}
                        formControlSx={{ minWidth:'100%',}}
                        date={requestData.dueByDate}
                        onChange={onDateChange}
                        mindate={todayDate}
                />
              </div>
          }

          {showQuotes &&
            <div>
              <div className={'Title'}>Quote</div>
              <Select className={''}
                      id={'quoteId'}
                      value={requestData.quoteId}
                      onChange={onFieldChange}
                      data={quotes}
                      labels={((id) => 'Q-' + id)}
              />
            </div>
          }

          {showLocationCode &&
            <div>
              <div className={'Title'}>Location Code</div>
              <TextField className={''}
                         id={"locationCode"}
                         value={requestData.locationCode}
                         onChange={onFieldChange}
              />
            </div>
          }

          {loading && <LinearProgress/>}
        </div>
      </ ConfirmationDialog>
    }
  </>;
}
