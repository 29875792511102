import useSWR from "swr";
import API, { ScheduledJobInfo } from "~/API";
import {useMutateHook} from "~/Hooks";

export const useJobs = () =>
  useSWR<ScheduledJobInfo[]>("api/job/info", () => API.getJobInfo());

export const useTriggerJob = () =>
  useMutateHook<{ jobKey: string }, ScheduledJobInfo[]>(
    "api/job/info",
    async ({ jobKey }) => {
      await API.triggerJob({}, { jobKey });
    },
    (data, args) => {
      let newData = [...data];
      const index = newData.findIndex(d => d.jobKey == args.jobKey);
      newData[index].isLocked = true;
      newData[index].lastStarted = new Date().toISOString();
      return newData;
    }
  );
