import { FC, useMemo, useState } from "react";
import { ScrollPage } from "~/Components/Layout/Pages";
import { useParams } from "react-router-dom";
import { Card } from "~/Components/UI";
import { productCatalogueRootPath } from "~/Services/Routes";
import { useSyndigoProduct } from "~/Data/Products/SyndigoProducts";
import { LoadingPage } from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";
import { useProductPublic } from "~/Data/Products/Products";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { Gallery, GalleryItem } from "~/Components/UI/Gallery";
import { CatalogueSpecificationCard } from "~/Pages/Products/CataloguePage/DetailsPage/Components/CatalogueSpecificationCard";
import { CatalogueDocumentsCard } from "~/Pages/Products/CataloguePage/DetailsPage/Components/CatalogueDocumentsCard";
import { CatalogueDescriptionCard } from "~/Pages/Products/CataloguePage/DetailsPage/Components/CatalogueDescriptionCard";

export const ProductCatalogueDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const product = useProductPublic(+id);
  const syndigoData = useSyndigoProduct(
    product.data ? product.data.productCode : null
  );

  const galleryItems: GalleryItem[] = useMemo(() => {
    if (!syndigoData.data) return [];
    const assets = syndigoData.data.assets;
    return [
      assets.ImageMain,
      assets.ImageProductView1,
      assets.ImageProductView2,
      assets.ImageProductView3,
      assets.ImageProductView4,
      assets.ImageProductView5,
      assets.VideoFeature1,
      assets.VideoFeature2,
    ]
      .filter((i) => i != undefined)
      .map((i) => ({
        imageUrl: i.url,
        thumbnailUrl: i.url + "&size=250&filetype=webp",
        format: i.format,
        fileName: i.fileName,
      }));
  }, [syndigoData]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  if (syndigoData.isLoading || product.isLoading) return <LoadingPage />;

  if (syndigoData.error || product.error)
    return (
      <EndpointErrorPage
        status={syndigoData.error?.status || 404}
        item="product"
      />
    );

  const attrib = syndigoData.data.attributes;
  const assets = syndigoData.data.assets;

  const hasDocument = [
    assets.Brochure,
    assets.PdfInstallationGuide,
    assets.PdfWarranty,
    assets.PdfTipsAndTricks,
    assets.PPdfUseAndCareManual,
  ].find((a) => a != undefined);

  return (
    <ScrollPage title={attrib.ProductName}>
      <div className="text-left px-lg pb-md">
        <PageTitle type="back" parentUrl={productCatalogueRootPath} />
        <div className="text-2xl mt-lg">{attrib.ProductName}</div>
        <div className="text-semibold ml-xxs">
          {product.data.productCode} - {product.data.description}
        </div>
      </div>

      <div className="desktop-flex-row mobile-flex-col flex-wrap gap-md text-left">
        <Card className="flex desktop-w-min-9xl h-min-11xl flex-col gap-sm">
          <Gallery
            imageClassName="w-full h-full h-max-12xl"
            items={galleryItems}
            selectedIndex={galleryIndex}
            setSelectedIndex={setGalleryIndex}
            showFullscreenDialog
          />
        </Card>

        <div className="flex flex-col gap-md desktop-w-min-10xl">
          <CatalogueDescriptionCard attrib={attrib} product={product.data} />

          {hasDocument && <CatalogueDocumentsCard assets={assets} />}
        </div>
      </div>

      <CatalogueSpecificationCard attrib={attrib} />
    </ScrollPage>
  );
};
