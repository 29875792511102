import { useCallback, useMemo } from "react";
import SelectWithoutPaging from "./SelectWithoutPaging";
import {
  useOfferStatusList,
  useOfferStatusListRFO,
} from "~/Reducers/Offers";
import {
  localizedOfferStatusString,
  useOfferStatusSuffix,
} from "../../Pages/Offers/Components/OffersHelper";
import { useIsVendor } from "~/Reducers/User";
import { OfferStatus } from "~/API";

// According to the list of statuses for vendor
// (https://docs.google.com/spreadsheets/d/1bSRHJCbnz7_7hWr4oPTUxU2R2MP6RwqEJpGs8M_xGcg/edit#gid=0)
// some statuses have the same name.
// e.g. Quoted for Vendor corresponds to O_Quoted, O_InProgress and O_QuoteClosed.
// So we need to filter out some statuses for vendor
// to make it work, in the onOfferStatusChange, if user selects "Quoted" - we are sending all 3 statuses to the API
// same for Offer to be confirmed which corresponds to O_Closing and O_VENDOR_CONFIRMED.
const OfferStatusesDuplicatedForVendor = [
  OfferStatus.O_IN_PROGRESS,
  OfferStatus.O_QUOTE_CLOSED,
  OfferStatus.O_VENDOR_CONFIRMED,
];
const OfferStatusSelect = ({ requestedOnly = false, ...props }) => {
  const { onChange } = props;
  const suffix = useOfferStatusSuffix();

  const isVendor = useIsVendor();

  const offerStatusList = useOfferStatusList();
  const offerStatusListRFO = useOfferStatusListRFO();

  const statuses = requestedOnly ? offerStatusListRFO : offerStatusList;

  const filteredStatuses = isVendor
    ? statuses.filter(
        (status) => !OfferStatusesDuplicatedForVendor.includes(status),
      )
    : statuses;

  // "O_IN_PROGRESS", "O_QUOTE_CLOSED", "O_QUOTED"
  const labels = useMemo(
    () =>
      statuses.reduce((acc, status) => {
        acc[status] = localizedOfferStatusString(status, suffix);
        return acc;
      }, {}),
    [statuses, suffix],
  );

  // see the elaborate comment above
  const onOfferStatusChange = useCallback(
    ({ target }) => {
      let newStatuses = [...target.value];

      // handle aggregation of O_QUOTED, O_IN_PROGRESS, O_VENDOR_CONFIRMED
      if (isVendor && Array.isArray(newStatuses)) {
        if (newStatuses.includes(OfferStatus.O_QUOTED)) {
          if (!newStatuses.includes(OfferStatus.O_IN_PROGRESS)) {
            newStatuses = [...newStatuses, OfferStatus.O_IN_PROGRESS];
          }
          if (!newStatuses.includes(OfferStatus.O_QUOTE_CLOSED)) {
            newStatuses = [...newStatuses, OfferStatus.O_QUOTE_CLOSED];
          }
        } else {
          newStatuses = newStatuses.filter(
            (status) =>
              ![OfferStatus.O_IN_PROGRESS, OfferStatus.O_QUOTE_CLOSED].includes(
                status,
              ),
          );
        }

        // handle aggregation of O_CLOSING, O_VENDOR_CONFIRMED
        if (newStatuses.includes(OfferStatus.O_CLOSING)) {
          if (!newStatuses.includes(OfferStatus.O_VENDOR_CONFIRMED)) {
            newStatuses = [...newStatuses, OfferStatus.O_VENDOR_CONFIRMED];
          }
        } else {
          // remove OfferStatus.O_VENDOR_CONFIRMED from array
          newStatuses = newStatuses.filter(
            (status) => status !== OfferStatus.O_VENDOR_CONFIRMED,
          );
        }
        onChange({ target: { id: target.id, value: newStatuses } });
      } else {
        onChange({ target: { id: target.id, value: target.value } });
      }
    },
    [onChange, isVendor],
  );

  let filteredValues = props.value;
  if (isVendor && Array.isArray(filteredValues)) {
    filteredValues = filteredValues.filter(
      (status) => !OfferStatusesDuplicatedForVendor.includes(status),
    );
  }

  return (
    <SelectWithoutPaging
      {...props}
      value={filteredValues}
      data={filteredStatuses}
      onChange={onOfferStatusChange}
      labels={labels}
      withFilter={false}
      skipLabelSorting
    />
  );
};

export default OfferStatusSelect;
