import React, { FC } from "react";
import OrderTitleContainer from "../OrderTitleContainer";

interface SalesOrderTitleContainerProps {
  isInternalUser: boolean;
  orderNumber: string;
  masterChild: string | undefined;
}

const SalesOrderTitleContainer: FC<SalesOrderTitleContainerProps> = ({
  isInternalUser,
  orderNumber,
  masterChild,
}) => {
  const labelKey = isInternalUser
    ? "title.sales.order.#.with.number"
    : "title.order.#.with.number";

  return (
    <OrderTitleContainer
      orderNumber={orderNumber}
      masterChild={masterChild}
      labelKey={labelKey}
    />
  );
};

export default SalesOrderTitleContainer;
