import API, {Email} from "~/API";
import useSWRInfinite from "swr/infinite";
import {useMutateHook} from "~/Hooks";
import {trimUndefined} from "~/Utils";


export interface EmailFilters {
  search?: string;
  toUser?: number;
  createDateFrom?: string;
  createDateTo?: string;
}

const getEmailListKey = (
  filters: EmailFilters,
  offset: number,
  previousPageData: Email[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/emails", filters, offset };
};

const PAGE_SIZE = 40;

export const useEmails = (filters: EmailFilters) =>
  useSWRInfinite(
    (i, p) => getEmailListKey(filters, i * PAGE_SIZE, p),
    (key) =>
      API.getEmails({
        ...trimUndefined(filters),
        offset: key.offset,
        limit: PAGE_SIZE,
      }),
  );

export const useResendEmail = () =>
  useMutateHook<{ id: number }, Email[]>(
    "api/emails",
    async (args) => { await API.resendEmail(args) },
  );
