import { FC } from "react";
import { LC } from "~/Locales";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import { params, useDebounce, useFormData, usePageTitle } from "~/Hooks";
import { FormInput } from "~/Components/UI";
import * as z from "zod";
import { RLLumberTable } from "~/Pages/Products/RandomLengthsPage/Components/RLLumberTable";
import { RLPanelTable } from "~/Pages/Products/RandomLengthsPage/Components/RLPanelTable";

const routeParser = z.object({
  search: params.string,
  tab: params.string,
});

const RandomLengthsTab: FC = () => {
  usePageTitle("Random Lengths");

  const filterForm = useFormData<{ search?: string; tab?: string }>(
    {
      search: "",
      tab: "lumber",
    },
    { routeParser },
  );

  const searchDebounced = useDebounce(filterForm.data.search);

  return (
    <Tabs
      value={filterForm.data.tab}
      onValueChange={(val) => filterForm.onDataChange("tab", val)}
      className="flex flex-col overflow-hidden bg-black-12 rounded-xxs"
    >
      <div className="flex-row gap-sm align-center flex-wrap px-lg py-md">
        <FormInput
          formData={filterForm}
          id="search"
          className="desktop-w-min-8xl"
          placeholder="title.search"
          type="search"
          showClear
        />

        <TabsList className="mx-sm">
          <TabsTrigger value="lumber">
            <LC id="title.lumber" />
          </TabsTrigger>
          <TabsTrigger value="panel">
            <LC id="title.panel" />
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value="lumber" className="flex-col overflow-hidden">
        <RLLumberTable search={searchDebounced} />
      </TabsContent>
      <TabsContent value="panel" className="flex-col overflow-hidden">
        <RLPanelTable search={searchDebounced} />
      </TabsContent>
    </Tabs>
  );
};

export default RandomLengthsTab;
