import {useCompany} from "~/Data/Companies/Companies";
import {Show} from "~/Components/UI";
import {Skeleton} from "~/Components/Loaders";
import {FC} from "react";

const CompanyView: FC<{
  id: number;
  className?: string;
}> = (props) => {
  const company = useCompany(props.id);

  if (!props.id)
    return <></>;

  return (
    <Show when={company.data} fallback={<Skeleton className="w-max-6xl w-full h-line-md" />}>
      <div className={props.className}>{company.data?.legalName}</div>
    </Show>
  );
};

export default CompanyView;
