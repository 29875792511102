import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface OrdersPageState {
  selectedOrders: number[];
}

const initialState: OrdersPageState = {
  selectedOrders: [],
};

export const ordersPageSlice = createSlice({
  name: "cataloguePage",
  initialState,
  reducers: {
    toggleSelectedOrder: (state, action: PayloadAction<number>) => {
      if (state.selectedOrders.includes(action.payload))
        state.selectedOrders = state.selectedOrders.filter((o) => o != action.payload);
      else
        state.selectedOrders.push(action.payload);
    },
    clearSelectedOrders: (state) => {
      state.selectedOrders = [];
    }
  },
  selectors: {
    isOrderSelected: (state, orderId: number) => {
      return state.selectedOrders.includes(orderId);
    }
  }
});

export const {
  toggleSelectedOrder,
  clearSelectedOrders,
} = ordersPageSlice.actions;

export const {
  isOrderSelected,
} = ordersPageSlice.getSelectors((state: any) => state.ordersPageState);

export default ordersPageSlice.reducer;