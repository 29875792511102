import {ChangeEventHandler, useState} from "react";
import * as z from "zod";
import {useSearchParams} from "~/Hooks/useSearchParams";

type ErrorMap<Type> = {
  [Property in keyof Type]: { _errors: string[] };
};

export interface FormReturn<T> {
  data: T;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onChecked: (id: keyof T, checked: boolean) => void;
  onDataChange: (id: keyof T, value: any) => void;
  setData: (newData: T, setDefault?: boolean, saveToRoute?: boolean) => void;
  clear: () => void;
  isValid: () => boolean;
  hasChanges: boolean;
  errors: ErrorMap<T>;
  isFieldRequired: (field: keyof T & string, emptyValue: any) => boolean;
}

export const useFormData = <T, F extends z.ZodType<T> = any>(
  initialData: T,
  params?: {
    validation?: z.ZodEffects<any> | z.ZodObject<any>,
    routeParser?: F,
    onChange?: (oldVal: T, newVal: T) => T,
  }
): FormReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams(params?.routeParser);
  const [init, setInit] = useState(initialData);
  const [data, setData] = useState<T>({ ...init, ...(params?.routeParser ? searchParams : {})});
  const [errors, setErrors] = useState<ErrorMap<T>>({} as ErrorMap<T>);

  const hasChanges = JSON.stringify(init) != JSON.stringify(data);

  const saveParam = (id: string, val: any) => {
    if(params?.routeParser) {
      setSearchParams({ ...searchParams, [id]: val });
    }
  }

  const changeData = (id: keyof T & string, value: any) => {
    if(params?.onChange)
      setData((prev) => params.onChange(prev, { ...prev, [id]: value }))
    else
      setData((prev) => ({ ...prev, [id]: value }));
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { id, value } = target;

    changeData(id as any, value);
    saveParam(id, value);

    // clear errors on change
    if (errors[id]) setErrors((prev) => ({ ...prev, [id]: { _errors: [] } }));
  };

  const onChecked = (id: keyof T & string, checked: boolean) => {
    changeData(id, checked);
    saveParam(id as string, checked);

    // clear errors on change
    if (errors[id]) setErrors((prev) => ({ ...prev, [id]: { _errors: [] } }));
  };

  const onDataChange = (id: keyof T & string, value: T) => {
    changeData(id, value);
    saveParam(id as string, value);

    // clear errors on change
    if (errors[id]) setErrors((prev) => ({ ...prev, [id]: { _errors: [] } }));
  };

  const clear = () => {
    setData(init);
    setErrors({} as ErrorMap<T>);
    setSearchParams({});
  };

  const isFieldRequired = (field: keyof T & string, emptyValue: any) => {
    if (params?.validation === undefined) return false;

    const result = params?.validation?.safeParse({ ...data, [field]: emptyValue });

    if(result.success)
      return false;

    return result.error.issues.find(e => e.path.includes(field)) != undefined;
  }

  const isValid = () => {
    if (params?.validation === undefined) return true;

    const result = params?.validation?.safeParse(data);

    if (result.success) return true;
    else {
      setErrors(result["error"].format() as ErrorMap<T>);
      return false;
    }
  };

  const setDataWithReset = (newVal: T, resetInitial: boolean, saveToRoute: boolean) => {
    setData(newVal);
    if (resetInitial)
      setInit(newVal);
    if (saveToRoute)
      setSearchParams({ ...newVal });
  }

  return {
    data,
    setData: setDataWithReset,
    onChange,
    onChecked,
    onDataChange,
    clear,
    isValid,
    hasChanges,
    errors,
    isFieldRequired,
  };
};
