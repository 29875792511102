import API, {LocationView } from '~/API';
import { useState, useEffect } from "react";
import { countryCodeToLabel } from '~/Data/Countries';
import { composedItems } from './Utils/Compose';
import { useGetEntity } from "./Utils/Entity";
import {AppDispatch, RootState, useAppSelector} from "~/StoreTypes";

export const ACTIONS_GET_LOCATIONS_SUCCESS = 'ACTIONS_GET_LOCATIONS_SUCCESS';

// hooks
const locationSelectorCreator = (locationId: number) => (state: RootState) => state.locations[locationId];
const companyLocationsSelectorCreator = (companyId: number) => (state: RootState) => state.locations.companyLocations[companyId];

export const useLocation = (locationId: number, force = false): LocationView => useGetEntity(locationId, getLocation, locationSelectorCreator, force);
export const useCompanyLocations = (companyId: number, force = false): number[] => useGetEntity(companyId, getCompanyLocationsForHook, companyLocationsSelectorCreator, force);
export const useLocations = () => useAppSelector(state => state.locations);

const useLocationKeys = () => {
  const [keys, setKeys] = useState([]);
  const locations = useLocations();
  useEffect(() => {
    setKeys(Object.keys(locations).map(locationId => Number(locationId)));
  }, [locations]);
  return keys;
};

export const useLocationLabels = () => {
  const [labels, setLabels] = useState({});
  const locations = useLocations();
  const locationKeys = useLocationKeys();
  useEffect(() => {
    setLabels(locationKeys.reduce(
      (a, option) => ({ ...a, [option]: locationLabel(locations[option]) }), {}
    ));
  }, [locationKeys]);
  return labels;
};

export const useLocationLabelsWithCustomerCode = () => {
  const [labels, setLabels] = useState({});
  const locations = useLocations();
  const locationKeys = useLocationKeys();
  useEffect(() => {
    setLabels(locationKeys.reduce(
      (a, option) => ({ ...a, [option]: locationLabelWithCustomerCode(locations[option]) }), {}
    ));
  }, [locationKeys]);
  return labels;
};

export const useLocationLabelsWithLocationCode = () => {
  const [labels, setLabels] = useState({});
  const locations = useLocations();
  const locationKeys = useLocationKeys();
  useEffect(() => {
    setLabels(locationKeys.reduce(
      (a, option) => ({ ...a, [option]: locationLabelWithLocationCode(locations[option]) }), {}
    ));
  }, [locationKeys]);
  return labels;
};

const locationLabel = (location: LocationView) => {
  return `(${location?.code}) ${location?.name}`;
};

const locationLabelWithCustomerCode = (location: LocationView) => {
  const addressString = locationAddressString(location);
  return `(${location?.code}) ${addressString}`;
};

const locationLabelWithLocationCode = (location: LocationView) => {
  const addressString = locationAddressString(location);
  return `(${location?.locationCode}) ${addressString}`;
};

const locationAddressString = (location: LocationView) => {
  const name = !!location?.name
    ? (location.name?.length > 20 ? location.name.slice(0, 17) + '...' : location.name)
    : location?.name;
  const addressParts = [name, location?.addressCity, location?.addressRegion, location?.addressCountry].filter(it => !!it);
  return addressParts.join(' - ');
}


export const locationAddress = (location: LocationView) => {
  if (!location) return '';

  const { addressCountry, addressRegion, addressCity, addressStreet, addressZip } = location;
  const countryName = countryCodeToLabel(addressCountry);
  const addressParts = [addressStreet, addressZip, addressCity, addressRegion, countryName].filter(it => !!it);
  return (addressParts.join(', '));
};

export const useLocationAddress = (locationId: number) => {
  const location = useLocation(locationId);
  return locationAddress(location);
};

export const getLocationsForSelect = (filters: any = { offset: 0 }) => async (dispatch: AppDispatch) => {
  try {
    const locations = await API.getLocations(filters);
    dispatch({ type: 'ACTIONS_GET_LOCATIONS_FOR_SELECT_SUCCESS', locations, offset: filters.offset });
    return locations;
  } catch (error) {}
};

export const getLocation = (locationId: number) => async (dispatch: AppDispatch) => {
  if (!locationId)
    return;

  try {
    const location = await API.getLocation({ id: locationId });
    dispatch({ type: 'ACTIONS_GET_LOCATION_SUCCESS', location });
    return location;
  } catch {}
};

const getCompanyLocationsForHook = (companyId: number) => async (dispatch: AppDispatch) => {
  if (!companyId) return;
  try {
    const locations = await API.getCompanyLocations({ id: companyId });
    dispatch({ type: 'ACTIONS_GET_COMPANY_LOCATIONS_SUCCESS', locations, companyId });
    return locations;
  } catch {}
};

export const getCompanyLocations = (companyId: number, filters: any = {}) => async (dispatch: AppDispatch) => {
  if (!companyId) return;
  try {
    const locations = await API.getCompanyLocations({ id: companyId, ...filters});
    dispatch({ type: 'ACTIONS_GET_COMPANY_LOCATIONS_SUCCESS', locations, companyId });
    return locations;
  } catch {}
};

export const getCompaniesLocations = (companyIds: number[] = [], filters: any = {}) => async (dispatch: AppDispatch) => {
  if (!companyIds?.length) return;
  try {
    const locations = await API.getLocations({ ...filters, companyId: companyIds });
    dispatch({ type: 'ACTIONS_GET_COMPANIES_LOCATIONS_SUCCESS', locations, companyIds });
    return locations;
  } catch {}
};

export interface LocationsState {
  companyLocations: {
    [id: number]: number[]
  }
  [id: number]: LocationView;
}

// reducer
const INITIAL_STATE: LocationsState = {
  companyLocations: {}
};

const reducer = (state: LocationsState, action: any): LocationsState => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case ACTIONS_GET_LOCATIONS_SUCCESS:
    case 'ACTIONS_GET_LOCATIONS_FOR_SELECT_SUCCESS':
    case 'ACTIONS_GET_COMPANIES_LOCATIONS_SUCCESS':
      return { ...state, ...composedItems(action.locations) };

    case 'ACTIONS_GET_LOCATION_SUCCESS':
    case 'ACTIONS_CREATE_LOCATION_SUCCESS':
    case 'ACTIONS_UPDATE_LOCATION_SUCCESS':
      return { ...state, [action.location.id]: action.location };

    case 'ACTIONS_GET_COMPANY_LOCATIONS_SUCCESS':
      return {
        ...state,
        ...composedItems(action.locations),
        companyLocations: {
          ...state.companyLocations,
          [action.companyId]: action.locations.map((it: LocationView) => it.id)
        }
      };

    default:
      return state;
  }
};

export default reducer;
