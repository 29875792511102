import { FC, useMemo } from "react";
import {useToggle} from "~/Hooks";
import { usePurchaseOrder } from "~/Data/Orders/PurchaseOrders";
import {
  usePurchaseOrderItems,
  usePurchaseOrderItemsTotalPackages,
} from "~/Reducers/PurchaseOrdersItems";
import { useIsInternalCustomer } from "~/Reducers/User";
import { LC, ls } from "~/Locales";
import OrderPositionVolume from "../OrderPositionVolume";
import OrderPositionPricePerUnit from "../OrderPositionPricePerUnit";
import PriceView from "~/Components/Views/PriceView";
import {
  PurchaseOrderItemPlantView,
  PurchaseOrderItemView,
  PurchaseOrderPlantView,
  PurchaseOrderView,
} from "~/API";
import {TbChevronDown, TbChevronUp} from "react-icons/tb";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Table} from "~/Components/UI";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";

const PurchaseOrderItems: FC<{ orderId: number }> = ({ orderId }) => {
  const items = usePurchaseOrderItems(orderId) || [];
  const showMore = useToggle();

  const itemsToShow = useMemo(
    () => (showMore.value ? items : items.slice(0, 6)),
    [items, showMore.value],
  );

  if (itemsToShow.length === 0) return null;

  return (
    <div className="flex-col p-lg text-left overflow-hidden">
      <div className="text-xl color-black-1">
        <LC id="title.items" />
      </div>
      <PositionsTable items={itemsToShow} orderId={orderId} />
      {items.length > 6 && (
        <div
          className="cursor-pointer color-blue-1 mt-md"
          onClick={showMore.toggle}
        >
          <LC
            id={showMore.value ? "title.view.less.items" : "title.view.all.items"}
          />
          {showMore.value && <TbChevronUp size={16} />}
          {!showMore.value && <TbChevronDown size={16} />}
        </div>
      )}
    </div>
  );
};

export default PurchaseOrderItems;

const PositionsTable: FC<{
  items: PurchaseOrderItemView[] | PurchaseOrderItemPlantView[];
  orderId: number;
}> = ({ items, orderId }) => {
  const internalCustomer = useIsInternalCustomer();
  const purchaseOrder = usePurchaseOrder(orderId, internalCustomer) || undefined;

  const { currency, millCostTotal } =
    (purchaseOrder.data as PurchaseOrderView & PurchaseOrderPlantView) || {};

  const totalPackages = usePurchaseOrderItemsTotalPackages(orderId);

  const columns = useMemo(
    () => createColumns(internalCustomer, totalPackages, millCostTotal, currency),
    [internalCustomer, totalPackages, millCostTotal, currency]
  );
  const table = useReactTable({
    data: (items || []) as any,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex-col overflow-x-auto w-full mt-lg">
      <Table table={table} />
    </div>
  );
};

const columnHelper = createColumnHelper<PurchaseOrderItemView & PurchaseOrderItemPlantView>();

const createColumns = (isInternalCustomer: boolean, totalPackages: number, millCostTotal: number, currency: string) => [
  columnHelper.accessor("productCode", {
    header: () => ls("title.product"),
    footer: () => <div className="text-bold"><LC id="title.total" /></div>
  }),
  columnHelper.accessor("productType", {
    header: () => ls("title.type"),
  }),
  columnHelper.accessor("description", {
    header: () => ls("title.description"),
    cell: ({ row: { original } }) => (
      <span className="text-no-wrap">
        <ProductCatalogueDescriptionLink
          description={original.description}
          productCode={original.productCode}
          hasSyndigo={original.hasSyndigo}
          productId={original.productId}
        />
      </span>
    )
  }),
  columnHelper.accessor("lengthCode", {
    header: () => ls("title.length"),
  }),
  columnHelper.accessor("piecesPerPackage", {
    header: () => <div className="text-right"><LC id="title.pieces.per.package"/></div>,
    cell: (data) => <div className="text-right">{data.getValue()}</div>,
  }),
  columnHelper.accessor("packages", {
    header: () => <div className="text-right"><LC id="title.total.packages"/></div>,
    cell: (data) => <div className="text-right">{data.getValue()}</div>,
    footer: () => <div className="text-right">{totalPackages}</div>,
  }),
  columnHelper.display({
    id: "totalPieces",
    header: () => <div className="text-right"><LC id="title.total.pieces"/></div>,
    cell: ({ row: { original } }) => (
      <div className="text-right">{
      original.packages && original.piecesPerPackage
        ? original.packages * original.piecesPerPackage
        : "-"
      }</div>
    ),
  }),
  columnHelper.display({
    id: "totalVol",
    header: () => <div className="text-right"><LC id="title.volume"/></div>,
    cell: ({ row: { original } }) => (
      <div className="text-right">
        <OrderPositionVolume
          volume={isInternalCustomer ? original.plantVolume : original.volume}
          volumeUom={isInternalCustomer ? original.plantCostUom : original.volumeUom}
          metricQuantity={isInternalCustomer ? original.plantVolume : original.metricQuantity}
          priceUom={isInternalCustomer ? original.plantCostUom : original.buyUom}
        />
      </div>
    ),
  }),
  columnHelper.display({
    id: "buyCost",
    header: () => <div className="text-right"><LC id="title.price"/></div>,
    cell: ({ row: { original } }) => (
      <div className="text-right">
        <OrderPositionPricePerUnit
          priceUom={isInternalCustomer ? original.plantCostUom : original.buyUom}
          currency={isInternalCustomer ? original.plantCostCurrency : original.currency}
          price={isInternalCustomer ? original.plantCost : original.buyCost}
        />
      </div>
    ),
  }),
  columnHelper.display({
    id: "subTotal",
    header: () => <div className="text-right"><LC id="title.subtotal"/></div>,
    cell: ({ row: { original } }) => {
      const costTotal = isInternalCustomer
        ? (original.plantCost || 0) * (original.plantVolume || 0)
        : original.millCostTotal;

      return (
        <div className="text-right">
          <PriceView
            price={costTotal || 0}
            currency={isInternalCustomer ? original.plantCostCurrency : original.currency}
          />
        </div>
      )
    },
    footer: () => (
      <div className="text-right">
        <PriceView price={millCostTotal} currency={currency} />
      </div>
    )
  }),
];
