import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useLocations } from "~/Data/Locations";
import { useFormData } from "~/Hooks";
import { Card, FormInput, Table } from "~/Components/UI";
import { LinearLoader } from "~/Components/Loaders";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { getLocationTableColumns } from "~/Pages/Companies/Components/LocationTable";
import { CompanyType, CompanyUsersView, LocationView } from "~/API";
import { locationsRootPath } from "~/Services/Routes";

const CompanyDetailsLocationsTab: FC<{
  company: CompanyUsersView;
}> = (props) => {
  const history = useHistory();
  const filters = useFormData({ search: "" });
  const isCustomers = props.company.type == CompanyType.BUYER;

  const locations = useLocations({
    ...filters.data,
    companyId: [props.company.id],
  }, 100);

  const columns = useMemo(() => getLocationTableColumns(isCustomers), [isCustomers]);
  const data = useMemo(() => locations.data?.flat() || [], [locations.data]);
  const onScrolledToBottom = () => locations.setSize(locations.size + 1);

  const onRowClicked = (row: LocationView) => {
    history.push(`${locationsRootPath}${row.id}/general`);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        am: isCustomers,
        defaultMarginAsFraction: isCustomers,
        active: false,
        customerCode: false,
      },
    },
  });

  return (
    <Card>
      <div className="flex-row gap-md p-md">
        <FormInput
          id="search"
          formData={filters}
          className="desktop-w-min-8xl"
          placeholder="title.search"
          showClear
          type="search"
        />
      </div>

      <LinearLoader loading={locations.isValidating} />

      <Table
        table={table}
        onScrolledBottom={onScrolledToBottom}
        onRowClick={onRowClicked}
      />
    </Card>
  );
};

export default CompanyDetailsLocationsTab;
