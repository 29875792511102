import LocalStorage from "./LocalStorage";

export const loadUserPreferences = () => {
  const lastUserId = LocalStorage.getItem('lastUserId');
  if (lastUserId) {
    const userSettingsString = LocalStorage.getItem(lastUserId) || '{}';
    const userSettings = JSON.parse(userSettingsString);
    return { lastUserId, ...userSettings };
  }
  return {};
};

export const saveUserPreferences = (userSettings = {}) => {
  const lastUserId = LocalStorage.getItem('lastUserId');
  if (lastUserId) {
    const oldSettingsString = LocalStorage.getItem(lastUserId) || '{}';
    const oldSettings = JSON.parse(oldSettingsString);
    const finalSettings = { ...oldSettings, ...userSettings };
    const finalSettingsString = JSON.stringify(finalSettings);
    LocalStorage.setItem(lastUserId, finalSettingsString);
  }
};

export const setLastUserId = (lastUserId: number) => {
  LocalStorage.setItem('lastUserId', lastUserId);
};
