const getItem = (key: string) => {
  return localStorage.getItem(key);
};

const setItem = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

const component = { getItem, setItem };

export default component;
