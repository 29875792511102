import {FC, ReactNode} from "react";
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

export const HoverCard: FC<{ children: ReactNode, openDelay?: number }> = (props) => {
  return (
    <HoverCardPrimitive.Root openDelay={props.openDelay}>
      {props.children}
    </HoverCardPrimitive.Root>
  )
}

export const HoverCardTrigger: FC<{ children: ReactNode }> = (props) => {
  return (
    <HoverCardPrimitive.Trigger asChild>
      {props.children}
    </HoverCardPrimitive.Trigger>
  )
}

export const HoverCardContent: FC<{ children: ReactNode }> = (props) => {
  return (
    <HoverCardPrimitive.Portal>
      <HoverCardPrimitive.Content className="animate-popover-content bg-black-12 rounded-xs color-text-1 p-xs shadow-lg  border-1 border-black-9 mt-xs">
        {props.children}
      </HoverCardPrimitive.Content>
    </HoverCardPrimitive.Portal>
  )
}