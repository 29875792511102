import { useHistory } from "react-router-dom";
import { getChatsPathForUser } from "~/Services/Routes";
import { useCurrentUser } from "~/Reducers/User";
import {useChatCreateDirectWithUser} from "~/Data/Chats";
import {FC} from "react";
import {Button} from "~/Components/UI";
import {TbMessage} from "react-icons/tb";

const ChatOpenButton: FC<{
  userId: number,
  className?: string,
}> = ({ userId, className }) => {
  const history = useHistory();

  const getDirectChatId = useChatCreateDirectWithUser(userId);
  const currentUser = useCurrentUser();

  const onChatClick = async () => {
    if (userId) {
      const chatId = await getDirectChatId();
      const linkToChat = await getChatsPathForUser(userId, currentUser);
      history.push(linkToChat+`${chatId}`);
    }
  };

  const onButtonClick = (event: any) => {
    event.stopPropagation();
    onChatClick();
  };

  return (
    <Button
      color="gray"
      onClicked={onButtonClick}
      leftIcon={<TbMessage size={16} />}
      className={className}
    />
  );
};

export default ChatOpenButton;
