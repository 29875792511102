import {Skeleton} from "~/Components/Loaders";
import {FC} from "react";
import {useLocation} from "~/Data/Locations";

const LocationView: FC<{
  id: number;
  showCode?: boolean;
  showLocationCode?: boolean;
  className?: string;
}> = (props) => {
    const location = useLocation(props.id);

    if (!props.id)
        return <></>;

    if (location.isLoading)
      return <Skeleton className="w-max-6xl w-full h-line-md" />

    return (
      <div className={props.className}>
        {props.showCode && <span>{`${location.data.code} - `}</span>}
        {props.showLocationCode && <span>{`${location.data.locationCode} - `}</span>}
        <span>{location.data.name}</span>
      </div>
    );
}

export default LocationView;
