import { useEffect, useRef, useState } from "react";

export const useDebounce = <T>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceCall = (func: () => void, delay = 500) => {
  const timerRef = useRef<NodeJS.Timeout>();

  return () => {
    timerRef.current = setTimeout(() => func(), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  };
};
