import {memo, useCallback, Fragment, FC} from "react";
import { LocalizedMessage } from "~/Locales";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { formattedCeilNumber } from '~/Utils/PriceCalculation';
import {
  ProductType,
  ProductTypeLabel,
  productTypesArray
} from "~/Reducers/Products";
import {useIsInternalUser} from "~/Reducers/User";
import Button from "~/Components/Old/Button";
import Checkbox from "~/Components/Old/Checkbox";
import { DeleteIcon } from "~/Components/Old/Icons";
import LengthSelect from "~/Components/Old/LengthSelect";
import { LengthsView } from "~/Components/Old/LengthsView";
import TextField from "~/Components/Old/TextField";
import { UOMSelect } from "~/Components/Old/UOMSelect";
import { calculateQuoteProductsTotalValue } from "../../Components/QuotesHelper";
import { classNames } from "react-ui-basics/Tools";
import { useLengthsByIds } from "~/Reducers/Lengths";
import {getContainers, UOM} from "~/Utils/UnitConversion";
import { useProductPublic } from "~/Reducers/ProductsPublic";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";
import {CalculatedPriceTooltip} from "~/Components/Entities/CalculatedPriceTooltip";
import PriceView from "~/Components/Views/PriceView";
import {ProductV2UOM, QuoteProductView, QuoteView} from "~/API";
import {Input} from "~/Components/UI";

const QuoteDetailsSummarySectionTable: FC<{
  readOnly: boolean,
  quote: QuoteView,
  products: QuoteProductView[],
  currency: string,
  shipToLocationId: number,
  incotermPortIdDestination: number,
  onProductFieldChange: (id: number, event: any) => void,
  onRemoveProduct: (id: number) => void,
}> = memo((props) => {
  const { readOnly, products, currency } = props;
  const isInternalUser = useIsInternalUser();
  const currencySymbol = useFindCurrencySymbol(currency);

  if (!products.length) return (
    <div className={'mt-md'}>There are no Products in this quote yet. Add your first Product now!</div>
  );

  const totalValue = calculateQuoteProductsTotalValue(products);
  const totalValueFormatted = formattedCeilNumber(totalValue, 2);

  return (
    <table className='basic-table'>
      {productTypesArray.map((productType, index) => {
        const filteredProducts = products.filter((product) => product.productType === productType);
        return (filteredProducts.length > 0 &&
          <Fragment key={index}>
            <thead>
              <tr className="border-none">
                <td className={'text-xl'} colSpan={!readOnly ? 13 : (isInternalUser ? 10 : 9)}>
                  <LocalizedMessage id={'title.quote.summary'} /> -
                  <span className={'text-lg text-left color-blue-1'}> {ProductTypeLabel[productType]}</span>
                </td>
              </tr>
            </thead>
            <ProductsByTypeTable {...props} products={filteredProducts} productType={productType} />
          </Fragment>
        );
      })}

      <tbody>
        <tr className={'text-xl upper-case border-none'}>
          <td /><td /><td /><td /><td /><td /><td />
          {!readOnly && <><td /><td /></>}
          <td><LocalizedMessage id={'title.total'} /></td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </table>
  );
});

export default QuoteDetailsSummarySectionTable;


const ProductsByTypeTable: FC<{
  quote: QuoteView,
  products: QuoteProductView[],
  onProductFieldChange: (id: number, event: any) => void,
  onRemoveProduct: (id: number) => void,
  currency: string,
  productType: string,
  readOnly: boolean,
  shipToLocationId: number,
  incotermPortIdDestination: number,
}> = memo((props) => {
  const { products, onProductFieldChange, onRemoveProduct, currency, productType, readOnly, quote } = props;

  const showLength = productType !== ProductType.SPECIAL_PRODUCTS;

  const sortedProducts = products.sort((a, b) => a.id - b.id);
  const currencySymbol = useFindCurrencySymbol(currency);

  const totalValue = calculateQuoteProductsTotalValue(sortedProducts);
  const totalValueFormatted = formattedCeilNumber(totalValue, 2);

  return (
    <>
      <thead>
        <tr>
          {!readOnly && <th />}
          <th><LocalizedMessage id={'title.code'} /></th>
          <th><LocalizedMessage id={'title.description'} /></th>
          <th>{showLength && <LocalizedMessage id={'title.length'} />}</th>
          <th><LocalizedMessage id={'title.uom'} /></th>
          <th><LocalizedMessage id={'title.quantity'} /></th>
          <th><LocalizedMessage id={'title.containers'} /></th>
          {!readOnly && <th className={'text-right'}><LocalizedMessage id={'title.price'} /></th>}
          <th className={'text-right'}> {!readOnly ? 'Adapted Price' : <LocalizedMessage id={'title.price'} />}</th>
          <th />
          <th className={'text-right'}><LocalizedMessage id={'title.subtotal'} /></th>
          {!readOnly && <th><LocalizedMessage id={'title.actions'} /></th>}
        </tr>
      </thead>

      <tbody>
        {sortedProducts.map((product, index) =>
          <OfferProductRow
            key={index}
            product={product}
            currency={currency}
            quote={quote}
            onProductFieldChange={onProductFieldChange}
            onRemoveProduct={onRemoveProduct}
            readOnly={readOnly}
          />
        )}

        <tr className={'text-lg border-none'}>
          <td /><td /><td /><td /><td /><td /><td />
          {!readOnly && <><td /><td /></>}
          <td> <LocalizedMessage id={'title.subtotal'} /> </td>
          <td className={'text-right color-orange-1 no-wrap'}>
            <b>{currencySymbol}{totalValueFormatted}</b>
          </td>
          {!readOnly && <td />}
        </tr>
      </tbody>
    </>
  );
});

const OfferProductRow: FC<{
  quote: QuoteView,
  product: QuoteProductView,
  currency: string,
  onProductFieldChange: (id: number, event: any) => void,
  onRemoveProduct: (id: number) => void,
  readOnly: boolean,
}> = (props) => {
  const { product, currency, onProductFieldChange, onRemoveProduct, readOnly, quote } = props;
  const isInternalUser = useIsInternalUser();
  const currencySymbol = useFindCurrencySymbol(currency);

  const {
    id = -1,
    productCode = '',
    productActLength = '',
    quantity = 1,
    uom = '',
    price = 0,
    lengthIds = [],
    productType = '',
    active,
    productGroupId = null,
  } = product;

  const onFieldChange = useCallback((event) => {
    onProductFieldChange(id, event);
  }, [id, onProductFieldChange]);

  const onActiveCheckboxChange = useCallback((event) => {
    const { target } = event;
    onProductFieldChange(id, { ...event, target: { id: 'active', value: target.checked } });
  }, [id, onProductFieldChange]);

  const onRemoveClick = useCallback(() => {
    onRemoveProduct(id);
  }, [id, onRemoveProduct]);

  const subtotal = productType == ProductType.FINANCE ? +price : quantity * price;

  const subtotalFormatted = formattedCeilNumber(subtotal, 2);
  const priceRounded = price && Number(price).toFixed(2);

  const isUomReadOnly = productType === ProductType.LINEAR_FOOTAGE || productType === ProductType.SPECIAL_PRODUCTS;

  const isPanels = productType === ProductType.PANELS;
  const isFinance = productType === ProductType.FINANCE;
  const showLength = productType !== ProductType.SPECIAL_PRODUCTS && !isFinance;
  const showLengthForPanels = isPanels && showLength;
  const showLengthForOthers = !isPanels && showLength;

  const lengths = useLengthsByIds(lengthIds);
  const productData = useProductPublic(product?.productId);

  const isLumber = productType === ProductType.LUMBER;
  const uomIsLf = uom === UOM.LF;
  const limitLengths = uomIsLf && isLumber;
  const editableUOM = !isFinance;
  const showQuantity = !isFinance;
  const showContainers = !isFinance;

  const changeCalculatedPrice = (value: number) => {
    onFieldChange({ target: { id: "priceCalculation", value } });
  }

  return (
    <>
      <tr className={classNames(!active && 'bg-black-10 opacity-70')}>
        {!readOnly && <td> <Checkbox value={active} onChange={onActiveCheckboxChange} /> </td>}
        <td> {productCode} </td>
        <td>
          <ProductCatalogueDescriptionLink
            description={product.productDescription}
            hasSyndigo={product.hasSyndigo}
            productCode={product.productCode}
            productId={product.productId}
          />
        </td>
        {readOnly && <td>
          {showLength
            ? <LengthsView lengthIds={lengthIds} />
            : '-'
          }
        </td>}
        {!readOnly &&
          <td>
            {showLengthForPanels && <TextField value={productActLength} disabled={true} />}
            {showLengthForOthers && active &&
              <LengthSelect id={'lengthIds'} disabled={!active}
                value={lengthIds}
                multiple={!limitLengths}
                byGroup={productGroupId}
                onChange={onFieldChange}
                inlineSelected
              />
            }
            {showLengthForOthers && !active && <LengthsView lengthIds={lengthIds} />}
            {!showLength && '-'}
          </td>
        }
        <td>
          {readOnly || !editableUOM
            ? <div className='flex-row align-center AlignItemsCenter'> {uom} </div>
            : <UOMSelect id="uom"
              value={uom}
              productType={productType}
              onChange={onFieldChange}
              disabled={isUomReadOnly}
              required={true}
            />
          }
        </td>
        <td>
          {showQuantity &&
            <>
            {readOnly
              ? <div className='flex-row align-center'> {quantity} </div>
              : <TextField id="quantity"
                type='number' hideArrows float
                className="w-min-3xl w-max-4xl"
                value={quantity}
                onChange={onFieldChange}
                min={0} max={99999999}
              />}
            </>
          }
          {!showQuantity && '-'}
        </td>
        <td>
          {
            showContainers
              ? getContainers(productData, lengths?.[0], quantity, uom as ProductV2UOM).toFixed(2)
              : '-'
          }
        </td>
        <td className='text-right'>
          {isInternalUser ? (
            <CalculatedPriceTooltip
              priceModel={{
                productId: product.productId,
                quoteUOM: product.uom as ProductV2UOM,
                currency: props.currency,
                incotermDestination: quote.incoterm,
                shipToPortId: quote.incotermPortIdDestination,
                shipToLocationId: quote.shipToLocationId,
                shipFromPortId: quote.portOfLoadingId,
                offerId: quote.offerId,
                lengthId: product.lengthIds,
              }}
              price={price}
              showAdaptedPrice={readOnly}
              changeCalculatedPrice={changeCalculatedPrice}
            />
          ) : (
            <PriceView
              price={priceRounded}
              currency={currency}
              className="text-bold"
            />
          )}
        </td>
        {!readOnly && (
          <td className={'text-right'}>
            <Input
              id="price"
              placeholder={product["priceCalculation"]}
              type="number"
              className="w-min-3xl w-max-5xl mr-xxs"
              value={price as any}
              onChange={onFieldChange}
              dataCy="quote-product-price"
            />
          </td>
        )}
        <td>{currency}{uom && <>/{uom}</>}</td>
        <td className={'text-right color-orange-1 no-wrap'}>
          <b>{currencySymbol}{subtotalFormatted}</b>
        </td>
        {!readOnly &&
          <td>
            <div className={'flex-row align-center'}>
              <Button view={'text'}
                className={'ActionButton DeleteButtonText'}
                onClick={onRemoveClick}>
                <DeleteIcon size={16} />
              </Button>
            </div>
          </td>
        }
      </tr>
    </>
  );
};
