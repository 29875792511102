import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {ProductGroupView} from "~/API";

export interface ProductGroupState {
  editData: ProductGroupView | null;
  productPrices: Record<number, number>;
}

const initialState: ProductGroupState = {
  editData: null,
  productPrices: {},
}

// Editing needs to be handled in redux instead of table editForm due to some funky
// re-rendering caused by the expanded table sub-component depending on table meta.
export const productGroupSlice = createSlice({
  name: 'productGroupPage',
  initialState,
  reducers: {
    setEditData: (state, action: PayloadAction<ProductGroupView>) => {
      state.editData = action.payload;
      state.productPrices = {};
    },
    setPrice: (state, action: PayloadAction<number>) => {
      state.editData.price = action.payload;
      Object.keys(state.productPrices).forEach(k => {
        state.productPrices[k] = action.payload;
      });
    },
    setProductPrice: (state, action: PayloadAction<{ key: number, price: number }>) => {
      state.productPrices[action.payload.key] = action.payload.price;
    },
    setAllProductPrices: (state, action: PayloadAction<number>) => {
      Object.keys(state.productPrices).forEach(k => {
        state.productPrices[k] = action.payload;
      });
    },
    setProductPrices: (state, action: PayloadAction<Record<number, number>>) => {
      state.productPrices = action.payload;
    },
    setValidityDate: (state, action: PayloadAction<string>) => {
      state.editData.validityDate = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setEditData, setPrice, setAllProductPrices, setProductPrice, setValidityDate, setProductPrices } = productGroupSlice.actions

export default productGroupSlice.reducer