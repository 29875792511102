import { FC, useMemo, useState } from "react";
import { useIsAdmin } from "~/Reducers/User";
import LandingPageAdd from "./Components/LandingPageAdd";
import { LandingPage } from "~/API";
import { useDebounce, useToggle } from "~/Hooks";
import {ActiveField, FullPageCard, Input, Show, Table} from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PageTitle } from "~/Components/Nav/PageTitle";
import { LinearLoader } from "~/Components/Loaders";
import { useLandingPages } from "~/Data/Admin/LandingPages";
import { continuousIncludes } from "~/Utils";
import LandingPageCard from "~/Pages/Admin/LandingPages/Components/LandingPageCard";
import {StaticPage} from "~/Components/Layout/Pages";

const columnHelper = createColumnHelper<LandingPage>();

const columns = [
  columnHelper.accessor("active", {
    header: "",
    cell: (data) => <ActiveField active={data.getValue()} />,
  }),
  columnHelper.accessor("name", {
    cell: (data) => data.getValue(),
  }),
  columnHelper.accessor("title", {
    cell: (data) => data.getValue(),
  }),
];

const LandingPagesPage: FC = () => {
  const isAdmin = useIsAdmin();
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 100);

  const landingPages = useLandingPages();

  const onSearchChange = ({ target }) => setSearch(target.value);

  const filteredLandingPages = useMemo(
    () =>
      landingPages.data?.filter((it) =>
        continuousIncludes(it.name.toUpperCase(), searchDebounce.toUpperCase()),
      ) || [],
    [landingPages.data, searchDebounce],
  );

  const table = useReactTable({
    data: filteredLandingPages,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [openId, setOpenId] = useState<number | undefined>();
  const cardOpen = useToggle();
  const onRowClicked = (_row: LandingPage, index: number) => {
    cardOpen.toggleTrue();
    setOpenId(index);
  };

  return (
    <StaticPage title="Landing Pages">
      <PageTitle type="menu" title="title.landing.pages" />

      <FullPageCard>
        <div className="flex-row p-md gap-sm">
          <Show when={isAdmin}>
            <LandingPageAdd />
          </Show>

          <Input
            id="search"
            placeholder={"placeholder.search.by.name"}
            showClear
            className="desktop-w-8xl"
            onChange={onSearchChange}
            type="search"
            value={search}
          />
        </div>

        <LinearLoader loading={landingPages.isLoading} />
        <Table table={table} onRowClick={onRowClicked} />

        { landingPages.data && openId !== undefined &&
          <LandingPageCard
            open={cardOpen}
            landingPage={landingPages.data[openId] || undefined}
          />
        }
      </FullPageCard>
    </StaticPage>
  );
};

export default LandingPagesPage;
