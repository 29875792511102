import {AppDispatch} from "~/StoreTypes";
import {ChatMessage, ChatMessageType, ChatMessageView, getWs} from "~/API";
import {receivedVideoCallEnded, videoCallInvitationUpdate} from "~/Reducers/VideoCall";
import Environment from "~/Services/Environment";
import {newAppVersionDetected} from "~/Components/Banners/reducer";
import BuildInfo from "~/BuildInfo";
import {ACTIONS_ATTACHMENT_UPDATED} from "~/Reducers/Attachments";
import {invalidateNotifications} from "~/Data/Notifications";

export const initWsHandlers = (dispatch: AppDispatch, mutate: any) => {
  const handlers = getWs().handlers;
  handlers.ChatMessageCreated = (async (message: ChatMessage) => {
    mutate(["api/chat/messages", message.chatId], (currentData: ChatMessageView[]) => currentData == null ? [message] : [...currentData, message]);
    if (message.type === ChatMessageType.CALL_INVITATION) {
      dispatch(videoCallInvitationUpdate(message));
    }
    try {
      mutate("api/chat/unread/count");
    } catch {}
  });

  handlers.ChatMessageUpdated = ((message: ChatMessage) => {
    mutate(["api/chat/messages", message.chatId], (currentData: ChatMessageView[]) => currentData == null ? [message] : [...currentData, message]);
    if (message.type === ChatMessageType.CALL_INVITATION) {
      dispatch(videoCallInvitationUpdate(message));
    }
  });
  handlers.ChatParticipantsUpdated = ((chat: ChatMessage) => {
    mutate(["api/chat/participants", chat.id]);
  });

  handlers.NotificationCreated = ((data: Notification) => {
    invalidateNotifications(mutate);
  });
  handlers.NotificationUpdated = ((data: Notification) => {
    invalidateNotifications(mutate);
  });

  handlers.AttachmentUpdated = ((data: Notification) => {
    dispatch({ type: ACTIONS_ATTACHMENT_UPDATED, data });
  });

  handlers.IncomingVideoCall = (() => {
  });
  handlers.VideoCallEnded = (() => {
    dispatch(receivedVideoCallEnded());
  });
  handlers.VideoCallDeclined = (() => {
  });

  handlers.AppInfo = (data: any) => {
    if (!Environment.isLocalhost && !Environment.isStage)
      newAppVersionDetected(data.indexMD5)(dispatch);
  };
  handlers.BranchUpdated = (data: any) => {
    if (window.location.hostname.startsWith(data.branch + '.'))
      newAppVersionDetected(data.indexMD5)(dispatch);
  };

  if (Environment.isStage) {
    newAppVersionDetected(BuildInfo.revision)(dispatch);
  }
};
