import {NotificationData} from "~/API";
import {Button, ConfirmDialog, DeleteDialog} from "~/Components/UI";
import {useToggle} from "~/Hooks";
import {FC} from "react";
import {deleteAllNotifications, markAllNotificationsAsRead} from "~/Data/Notifications";
import {TbTrash, TbEye} from "react-icons/tb";
import {trackEvent} from "~/Services/Tracking";

const NotificationDeleteReadButtons: FC<{
    refetchNotifications: () => void;
}> = (props) => {

    const openDeleteDialog = useToggle(false);
    const openMarkReadDialog = useToggle(false);

    const onConfirmDelete = (): Promise<void> => {
        return new Promise(async () => {
            await deleteAllNotifications()
            openDeleteDialog.toggleFalse()
            props.refetchNotifications();
            trackEvent("Notifications Deleted All");
        });
    };

    const onConfirmMarkAsRead = (): Promise<void> => {
        return new Promise(async () => {
            await markAllNotificationsAsRead()
            openMarkReadDialog.toggleFalse()
            props.refetchNotifications();
            trackEvent("Notifications Read All");
        });
    };

    return(
        <>
            <div className="flex-row justify-center gap-xs mt-auto-top mt-auto py-xs">


            {openMarkReadDialog && (
                <ConfirmDialog
                    title={"phrase.notifications.mark.all"}
                    confirmText={"phrase.notifications.mark.read"}
                    onConfirm={onConfirmMarkAsRead}
                    children={"This action is irreversible."}
                    trigger=
                        {<Button
                        color="gray"
                        onClicked={(e) => {
                            e.stopPropagation();
                            openMarkReadDialog.toggleTrue;
                        }}
                        label={"phrase.notifications.mark.read"}>
                            <TbEye size={16}/>
                        </Button>}
                 />
            )}

            {openDeleteDialog && (
                <DeleteDialog
                    title={"phrase.notifications.delete.all.notifications"}
                    deleteText={"title.delete"}
                    onDelete={onConfirmDelete}
                    children={"This action is irreversible."}
                    trigger=
                        {<Button
                        color="red"
                        onClicked={(e) => {
                            e.stopPropagation();
                            openDeleteDialog.toggleTrue;
                        }}
                        label={"phrase.notifications.delete.all"}>
                            <TbTrash size={16}/>
                        </Button>}
                />
            )}
            </div>
        </>
    );
};

export default NotificationDeleteReadButtons;
