import { useLengthsByIds } from "~/Reducers/Lengths";
import React from "react";

export const LengthsView = ({ lengthIds }) => {
  const lengths = useLengthsByIds(lengthIds);
  const lengthValues = lengths?.map(length => length.value);
  return (
    <>
      {lengthValues.map((value, index) => (
        <React.Fragment key={index}>
          {value}
          {index < lengthValues.length - 1 && <>, </>}
        </React.Fragment>
      ))}
    </>
  );
};