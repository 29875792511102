const Vars = {
  mobileScreen: 900,

  font: "DM Sans",

  size: {
    "base":  16,
    "xxs":   4,
    "xs":    8,
    "sm":    12,
    "md":    16,
    "lg":    24,
    "xl":    32,
    "2xl":   48,
    "3xl":   64,
    "4xl":   96,
    "5xl":   128,
    "6xl":   192,
    "7xl":   256,
    "8xl":   384,
    "9xl":   512,
    "10xl":  640,
    "11xl":  768
  },

  fontSize: {
    "xs": 10,
    "sm": 12,
    "md": 14,
    "lg": 16,
    "xl": 18,
    "2xl": 24,
    "3xl": 30,
    "4xl": 36,
    "5xl": 48,
    "6xl": 60,
    "7xl": 72
  },

  lineHeight: {
    "xs": 14,
    "sm": 16,
    "md": 20,
    "lg": 24,
    "xl": 28,
    "2xl": 32,
    "3xl": 36,
    "4xl": 40,
    "5xl": 1,
    "6xl": 1,
    "7xl": 1
  },
  
  colors: {
    backgroundMain: "#f9f9f9",
    
    text1: "#1c1f31",
    text2: "#777983",
    text3: "#a4a5ad",
    
    black1: "#071c28",
    black4: "#60626f",
    black5: "#777983",
    black7: "#a4a5ad",
    black9: "#d2d2d6",
    black10: "#e8e9ea",
    black11: "#f1f4f4",
    black115: "#f7f9f9",
    black12: "#ffffff",
    
    blue1: "#3f87de",
    blue2: "#5293e1",
    blue3: "#659fe5",
    blue4: "#79abe8",
    blue5: "#8cb7eb",
    blue6: "#9fc3ef",
    blue7: "#b2cff2",
    blue8: "#c5dbf5",
    blue9: "#d9e7f8",
    blue10: "#ecf3fc",
    
    green1: "#8bc34a",
    green2: "#97c95c",
    green3: "#a2cf6e",
    green9: "#e8f3db",
    green10: "#f3f9ed",
    
    orange1: "#ee752f",
    orange2: "#f08344",
    orange3: "#f19159",
    orange4: "#f39e6d",
    orange5: "#f5ac82",
    orange6: "#f7ba97",
    orange7: "#f8c8ac",
    orange8: "#fad6c1",
    orange9: "#fce3d5",
    orange10: "#fdf1ea",
    
    red1: "#e25241",
    red2: "#e56354",
    red3: "#e87567",
    red4: "#eb867a",
    red5: "#ee978d",
    
    purple1: "#613add",
    purple2: "#4f2fcb",
    purple9: "#efebfc",
    
    pink1: "#de3f8b",
    pink9: "#fcecf6",
    
    yellow1: "#eead2f",
    yellow9: "#fffee3",
    
    transparent: "#0000"
  }
}

export default Vars;
