import { useCurrentUser } from "~/Reducers/User";
import { ChatMessageType, ChatUnreadView } from "~/API";
import { FC } from "react";
import {ChatMessageParsed} from "~/Components/Chats/ChatMessageParsed";

const ChatsLastMessage: FC<{
  chat: ChatUnreadView;
}> = (props) => {
  const currentUser = useCurrentUser();
  if (props.chat?.lastMessage?.text) {
    let userName = props.chat.lastMessage.userName;

    const { type } = props.chat.lastMessage;
    if (type === ChatMessageType.SYSTEM_MESSAGE || type == ChatMessageType.FIELD_CHANGE) {
      userName = "TimberBase";
    }

    if (currentUser.id === props.chat.lastMessage.userAccountId) {
      userName = "You";
    }

    return (
      <>
        {`${userName}: `}
        <ChatMessageParsed message={props.chat.lastMessage} linkClasses="" textOnly />
      </>
    );
  }
  return <></>;
};

export default ChatsLastMessage;
