import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LC, ls } from "~/Locales";
import {
  getPathForSO,
  ordersPathSales,
  ordersRootPath,
  useIsCompaniesPath,
} from "~/Services/Routes";
import { useIsInternalUser } from "~/Reducers/User";
import DashboardEmptyState from "../DashboardEmptyState";
import PriceView from "~/Components/Views/PriceView";
import {
  useChatUnreadCountForOrder,
} from "../DashboardHelper";
import { LinearLoader } from "~/Components/Loaders";
import {Button, Card} from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SalesOrderView } from "~/API";
import DateView from "~/Components/Views/DateView";
import {DashboardMobileField, DashboardTable} from "~/Pages/Dashboard/Components/DashboardTable";
import { SalesOrderStatusBadge } from "~/Components/Orders/SalesOrderStatusBadge";
import {useDashboardSalesOrders} from "~/Data/Orders/SalesOrders";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {useIsMobile} from "~/Hooks";

const columnHelper = createColumnHelper<SalesOrderView>();

const columns = [
  columnHelper.accessor("number", {
    header: ls("title.order.#"),
    cell: (data) => <div className="text-semibold">{data.getValue()}</div>
  }),
  columnHelper.accessor("buyerStatus", {
    header: ls("title.status"),
    cell: (data) => <div className="flex-row"><SalesOrderStatusBadge status={data.getValue()} /></div>,
  }),
  columnHelper.accessor("locationName", {
    header: ls("title.ship.to"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelper.display({
    id: "shipTerms",
    header: ls("title.ship.terms"),
    cell: (data) => {
      const { shipTerms, shipTermsLoc } = data.row.original;
      return (<div className="text-truncate">
        {(shipTerms && shipTermsLoc
          ? `${shipTerms} - ${shipTermsLoc}`
          : shipTerms) || "-"}
      </div>);
    },
  }),
  columnHelper.accessor("dateEstimatedProvisionDate", {
    header: ls("title.delivery.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
  columnHelper.accessor("totalSalesForCommission", {
    header: ls("title.amount"),
    cell: (data) => <PriceView price={data.getValue()} />,
  }),
  columnHelper.display({
    id: "unreadCount",
    header: ls("title.chat"),
    cell: (data) => {
      const unreadCount = useChatUnreadCountForOrder(data.row.original, false);
      return <ChatUnreadBadge unread={unreadCount} />;
    },
  }),
];

const DashboardSalesOrders = () => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const showMobileTable = useIsMobile();

  const orders = useDashboardSalesOrders(isInternalUser);

  const onShowSalesOrders = () => {
    history.push({ pathname: ordersRootPath + ordersPathSales });
  };

  const onRowClick = (row: SalesOrderView) => {
    history.push(getPathForSO(row.id));
  }

  const table = useReactTable({
    data: orders.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const showEmptyState = orders.data?.length === 0 && !orders.isLoading;
  const showList = !orders.isLoading && !showEmptyState;
  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC
          id={
            isInternalUser
              ? "title.sales.orders"
              : "title.my.orders"
          }
        />
      </div>

      <LinearLoader loading={orders.isLoading} />
      {showEmptyState && <EmptyState />}

      {showList && (
        <>
          {!showMobileTable && <DashboardTable table={table} onRowClick={onRowClick} />}

          {showMobileTable && (
            <div className={"orders-table-mobile"}>
              {orders.data?.map((order) => (
                <OrderCellMobile key={order.id} order={order} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-lg"
            onClicked={onShowSalesOrders}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardSalesOrders;

const EmptyState = () => {
  const isCompaniesHub = useIsCompaniesPath();
  if (isCompaniesHub)
    return <DashboardEmptyState title="No Sales Orders" description1="" />;

  return (
    <DashboardEmptyState
      title={<LC id="phrase.no.orders.onging.customer.1" />}
      description1={<LC id="phrase.no.orders.onging.customer.2" />}
    />
  );
};

const OrderCellMobile = ({ order }) => {
  const history = useHistory();

  const {
    id,
    number,
    buyerStatus,
    locationName,
    buyerReferenceNumber,
    shipTerms,
    shipTermsLoc,
    currency,
    totalSalesForCommission,
  } = order;

  const unreadCount = useChatUnreadCountForOrder(order, false);

  const shipTermsDisplay =
    (shipTerms && shipTermsLoc
      ? `${shipTerms} - ${shipTermsLoc}`
      : shipTerms) || "-";

  const onOpen = useCallback(() => {
    history.push(getPathForSO(id));
  }, [id]);

  return (
    <div className="border-b-1 border-black-10 py-md flex-col gap-sm" onClick={onOpen}>
      <div className="flex-row w-full gap-sm">
        <DashboardMobileField label="title.sales.order.#" className="mr-xxs">
          <div className="text-semibold text-truncate mt-xxs">{number || "-"}</div>
        </DashboardMobileField>

        <DashboardMobileField label="title.your.po.#">
          {buyerReferenceNumber}
        </DashboardMobileField>

        <div className="text-truncate flex-col mr-xxs">
          <SalesOrderStatusBadge status={buyerStatus} />
          <ChatUnreadBadge unread={unreadCount} />
        </div>
      </div>

      <DashboardMobileField label="title.ship.to">
        {locationName}
      </DashboardMobileField>

      <div className="flex-row w-full mt-xs">
        <DashboardMobileField label="title.ship.terms">
          {shipTermsDisplay}
        </DashboardMobileField>

        <DashboardMobileField label="title.amount">
            <PriceView price={totalSalesForCommission} currency={currency} />
        </DashboardMobileField>
      </div>
    </div>
  );
};
