import { useCallback } from "react";
import { requestedOffersRootPath } from "~/Services/Routes";
import Button from "~/Components/Old/Button";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";


export const UpdateRequestButton = ({ offerId }) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    history.replace(`${requestedOffersRootPath}${offerId}/edit`);
  }, [history, offerId]);

  return <Button view={'secondary'} onClick={onEdit}>
    <LocalizedMessage id={'title.update.request'} />
  </Button>
}

