import {FC} from "react";
import {useUserGroup} from "~/Data/Users";
import {Show} from "~/Components/UI";
import {Skeleton} from "~/Components/Loaders";


const UserGroupName: FC<{
  id?: number;
  className?: string;
}> = (props) => {
  const userGroup = useUserGroup(props.id);

  if (!props.id)
    return <></>;

  return (
    <Show when={userGroup.data} fallback={<Skeleton className="w-max-6xl w-full h-line-md" />}>
      <div className={props.className}>{userGroup.data?.name}</div>
    </Show>
  );
};

export default UserGroupName;
