import Vars from "~/Styles/Vars";
import { useParams } from 'react-router-dom';
import {QuoteProductView, QuoteStatus} from "~/API";
import {LocalizedMessage, LocalizedMessageKey} from "~/Locales";
import { useIsAdmin, useIsCustomer, useIsUFPAM, useIsUFPBuyer } from "~/Reducers/User";
import {classNames} from "react-ui-basics/Tools";


const quotesStatusColors = {
  [QuoteStatus.DRAFT]: Vars.colors.blue1,
  [QuoteStatus.SENT]: Vars.colors.blue1,
  [QuoteStatus.INTERESTED]: Vars.colors.blue1,
  [QuoteStatus.TO_BE_CONFIRMED]: Vars.colors.blue1,
  [QuoteStatus.ORDERED]: Vars.colors.blue1,
  [QuoteStatus.DELIVERED]: Vars.colors.blue1,
  [QuoteStatus.ARCHIVED]: Vars.colors.black5,
};

interface QuoteStatusLabelProps {
  status: QuoteStatus,
  className?: string,
  read?: boolean,
  isExpired?: boolean,
  alwaysShowColor?: boolean,
}

export const QuoteShowUnread = (read: boolean | undefined, isExpired: boolean | undefined) => {
  // don't show if not set or expired
  if (read === undefined || isExpired)
    return false;

  // show if unread
  return !read;
}

export const QuoteStatusLabel = (props: QuoteStatusLabelProps) => {
  const { status, className, read, isExpired, alwaysShowColor } = props;
  const isBuyer = useIsUFPBuyer()
  const isAM = useIsUFPAM()
  const isCustomer = useIsCustomer()
  const isAdmin = useIsAdmin();

  let role: string;
  if (isBuyer)
    role = 'buyer';
  else if (isCustomer)
    role = 'customer';
  else if (isAM || isAdmin)
    role = 'am';

  const color = QuoteShowUnread(read, isExpired) || alwaysShowColor ? quotesStatusColors[status] : "black";

  return (
    <div style={{ color }} className={classNames(className, QuoteShowUnread(read, isExpired) ? "text-bold" : "")}>
      {role && <LocalizedMessage id={`quote.status.${role}.${status}` as LocalizedMessageKey} />}
      {!role && status}
    </div>
  );
};

export const calculateQuoteProductsTotalValue = (products: QuoteProductView[]) => {
  const totalValue = products
    .filter(product => product.active)
    .map(product => {
      if (product.productType == "FINANCE")
        return +product.price;
      return product.price * product.quantity
    })
    .reduce((a, b) => a + b, 0);
  return isNaN(totalValue) ? 0 : totalValue;
};

export const useIsNew = () => {
  const { id: quoteId } = useParams<{id: string}>();
  return quoteId?.toLowerCase() === 'new';
};

export const useIsEdit = () => {
  const { edit } = useParams<{edit: string}>();
  return edit?.toLowerCase() === 'edit';
};

export const useIsReadOnly = () => {
  const isNew = useIsNew();
  const isEdit = useIsEdit();
  return !isNew && !isEdit;
};
