import {useCurrentUser} from "~/Reducers/User";
import {FormCombobox, FormComboboxProps} from "~/Components/UI";
import {useLocations} from "~/Data/Locations";

export const UserLocationsCombobox = <T,>(
  props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const currentUser = useCurrentUser();

  const keys = currentUser.locations;
  const locations = useLocations({ onlyMy: true, limit: 1000 });
  const getLabel = (d: number) => {
    const loc = locations.data?.flat().find(l => l.id == d);
    if (loc != undefined) {
      return `(${loc.code}) ${loc.name}`;
    }

    return `${d}`;
  };

  return <FormCombobox {...props} data={keys} getLabel={getLabel} />;
};