import Vars from "~/Styles/Vars";
import { RequestForQuoteStatus } from "~/API";
import { FC, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  LocalizedMessage,
  LocalizedMessageKey,
} from "~/Locales";
import {
  useIsCustomer,
  useIsUFPBuyer,
} from "~/Reducers/User";
import { cn } from "~/Utils";

const requestsStatusColors = {
  [RequestForQuoteStatus.DRAFT_AM]: Vars.colors.blue1,
  [RequestForQuoteStatus.DRAFT_CUSTOMER]: Vars.colors.blue1,
  [RequestForQuoteStatus.REVIEW]: Vars.colors.blue1,
  [RequestForQuoteStatus.SENT_TO_BUYER]: Vars.colors.blue1,
  [RequestForQuoteStatus.OFFER_REQUESTED]: Vars.colors.blue1,
  [RequestForQuoteStatus.OFFERED]: Vars.colors.blue1,
  [RequestForQuoteStatus.QUOTED]: Vars.colors.blue1,
  [RequestForQuoteStatus.ORDERED]: Vars.colors.blue1,
  [RequestForQuoteStatus.ARCHIVED]: Vars.colors.black5,
};

export const useRequestStatusSuffix = () => {
  const isUFPBuyer = useIsUFPBuyer();
  const isCustomer = useIsCustomer();

  return useMemo(() => {
    if (isUFPBuyer) return "buyer";
    if (isCustomer) return "customer";
    return "am";
  }, [isUFPBuyer, isCustomer]);
};

export const RequestShowUnread = (read: boolean, isExpired: boolean) => {
  // don't show if not set or expired
  if (read === undefined || isExpired) return false;

  // show if unread
  return !read;
};

export const RequestStatusLabel: FC<{
  status: RequestForQuoteStatus;
  className?: string;
  read?: boolean;
  isExpired?: boolean;
  alwaysShowColor?: boolean;
}> = (props) => {
  const { status, className, read, isExpired, alwaysShowColor } = props;
  const suffix = useRequestStatusSuffix();
  const color =
    RequestShowUnread(read, isExpired) || alwaysShowColor
      ? requestsStatusColors[status]
      : "black";

  return (
    <div
      style={{ color }}
      className={cn(
        className,
        RequestShowUnread(read, isExpired) ? "text-bold" : "",
      )}
    >
      <LocalizedMessage
        id={`request.status.${suffix}.${status}` as LocalizedMessageKey}
      />
    </div>
  );
};

export const useIsNew = () => {
  const { id: requestId } = useParams<{ id: string }>();
  return useMemo(() => requestId?.toLowerCase() === "new", [requestId]);
};

export const useIsReadOnly = () => {
  const { pathname } = useLocation();
  return useMemo(() => !pathname?.toLowerCase().includes("edit"), [pathname]);
};

export const useIsMatching = () => {
  const { pathname } = useLocation();
  return useMemo(
    () => !!pathname?.toLowerCase().includes("matching"),
    [pathname],
  );
};

export const useIsDraft = (status: RequestForQuoteStatus) => {
  return [
    RequestForQuoteStatus.DRAFT_AM,
    RequestForQuoteStatus.DRAFT_CUSTOMER,
  ].includes(status);
};

export const useIsOfferRequested = (status: RequestForQuoteStatus) => {
  return [
    RequestForQuoteStatus.OFFER_REQUESTED,
    RequestForQuoteStatus.OFFERED,
    RequestForQuoteStatus.QUOTED,
    RequestForQuoteStatus.ORDERED,
    RequestForQuoteStatus.ARCHIVED,
  ].includes(status);
};
