import API, {
  Order,
  Quote,
  QuotesByCustomerSummary,
  QuotesByOfferSummary,
  QuotesByShipToSummary, QuotesByStatusSummary,
  QuoteStatus,
  QuoteUpdate, QuoteView
} from "~/API";
import {useMemo} from "react";
import {useSearchParams} from "~/Services/Routes";
import {
  ACTIONS_CREATE_QUOTE_SUCCESS,
  ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_ERROR,
  ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_START,
  ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_SUCCESS,
  ACTIONS_GET_QUOTES_BY_GROUP_ERROR,
  ACTIONS_GET_QUOTES_BY_GROUP_START,
  ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS,
  ACTIONS_GET_QUOTES_ERROR,
  ACTIONS_GET_QUOTES_START,
  ACTIONS_GET_QUOTES_SUCCESS,
  ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_ERROR,
  ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_START,
  ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_SUCCESS,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_ERROR,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_START,
  ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_SUCCESS,
} from "~/Reducers/Quotes";
import {composedItems} from "~/Reducers/Utils/Compose";
import {deleteQuote, updateQuote} from "~/Reducers/Quotes";
import {useIsAdmin, useIsUFPAM} from "~/Reducers/User";
import {AppDispatch, useAppSelector} from "~/StoreTypes";
import Toast from "~/Components/Toast";
import {ls} from "~/Locales";

export const useShouldShowArchiveButton = (status: QuoteStatus) => {
  const isAdmin = useIsAdmin();
  const isUFPAM = useIsUFPAM();
  const isArchived = status === QuoteStatus.ARCHIVED || status === QuoteStatus.DELIVERED;
  return (isUFPAM || isAdmin) && !isArchived;
};

export const useQuotesPageReduxState = () => useAppSelector(state => state.quotesPage);
export const useQuotesPageByGroupReduxState = (groupId: string) =>
  useAppSelector(state => state.quotesPage.quoteIdsByGroups[groupId]) || {ids: [], loading: false, error: null};

export const useReference = (refId: number) => useAppSelector(state => state.quotesPage.references[refId]);
export const useCustomer = (customerId: number) => useAppSelector(state => state.quotesPage.customers[customerId]);
export const useShipTo = (shipToId: number) => useAppSelector(state => state.quotesPage.shipTos[shipToId]);

export interface GetQuotesByStatusFilters {
  search?: string,
  status?: Array<QuoteStatus>,
  offer?: Array<number>,
  customer?: Array<number>,
  shipTo?: Array<number>,
  buyer?: Array<number>,
  valueFrom?: string,
  valueTo?: string,
  validityDateFrom?: string,
  validityDateTo?: string,
  product?: Array<number>,
  productGroup?: Array<number>,
  vendor?: Array<number>,
  port?: Array<number>,
  accountManager?: Array<number>,
  incoterm?: Array<string>,
  withChangesOnly?: boolean,
  onlyMy?: boolean,
  onlyArchivedOrDeliveredOrExpired?: boolean,
  withoutArchivedOrDelivered?: boolean,
  withExpired?: boolean
}

export interface QuotesFilters {
  query?: string,
  search?: string,
  status?: Array<QuoteStatus>,
  offer?: Array<number>,
  customer?: Array<number>,
  shipTo?: Array<number>,
  buyer?: Array<number>,
  accountManager?: Array<number>,
  valueFrom?: string,
  valueTo?: string,
  validityDateFrom?: string,
  validityDateTo?: string,
  product?: Array<number>,
  productGroup?: Array<number>,
  vendor?: Array<number>,
  port?: Array<number>,
  incoterm?: Array<string>,
  withChangesOnly?: boolean,
  onlyMy?: boolean,
  onlyNotLinkedToOffer?: boolean,
  onlyNotLinkedToRFQ?: boolean,
  onlyArchivedOrDeliveredOrExpired?: boolean,
  withoutArchivedOrDelivered?: boolean,
  withExpired?: boolean,
  orderBy?: string,
  order?: Order,
  offset?: number,
  limit?: number
}

export const getQuoteStatusesTotalInfo = (filters: GetQuotesByStatusFilters) => async (dispatch: AppDispatch) => {
  try {
    dispatch({type: 'ACTIONS_QUOTES_PAGE_GET_STATUSES_START'});
    const statuses = await API.getQuotesByStatusSummary(filters);
    dispatch({type: 'ACTIONS_QUOTES_PAGE_GET_STATUSES_SUCCESS', statuses});
  } catch (error) {
    dispatch({type: 'ACTIONS_QUOTES_PAGE_GET_STATUSES_ERROR', error});
  }
};

export const deleteQuoteEntity = (quoteId: number, groupId: string, onDelete: () => void) => async (dispatch: AppDispatch) => {
  try {
    await dispatch(deleteQuote(quoteId));
    dispatch({type: 'ACTIONS_QUOTES_PAGE_DELETE_OFFER', quoteId, groupId});
    onDelete();
    Toast.Success(ls('phrase.quote.deleted.successfully', quoteId));
  } catch {
  }
};

export const archiveQuote = (quote: {
  id: number
} & QuoteUpdate, groupId: string, onArchive: () => void) => async (dispatch: AppDispatch) => {
  try {
    await dispatch(updateQuote(quote));
    dispatch({type: 'ACTIONS_QUOTES_PAGE_ARCHIVE_OFFER', quoteId: quote.id, groupId});
    onArchive();
    Toast.Success(ls('phrase.quote.archived.successfully', quote.id));
  } catch {
  }
};

export const clearData = () => ({type: 'ACTIONS_QUOTES_PAGE_CLEAR_DATA'});

export enum QuotesPageTableTypes {
  QUOTES_PAGE_NONE = 'QUOTES_PAGE_NONE',
  QUOTES_PAGE_STATUS = 'QUOTES_PAGE_STATUS',
  QUOTES_PAGE_OFFER = 'QUOTES_PAGE_OFFER',
  QUOTES_PAGE_CUSTOMER = 'QUOTES_PAGE_CUSTOMER',
  QUOTES_PAGE_SHIP_TO = 'QUOTES_PAGE_SHIP_TO',
}

export const useDefaultPageFilters = (): QuotesFilters => {
  const [searchParams] = useSearchParams();
  const withExpired = searchParams?.onlyArchivedOrDeliveredOrExpired?.toLowerCase() === 'true';

  // export const defaultPageFilters = {
  return useMemo(() => ({
    search: '',
    onlyMy: true,
    tableType: QuotesPageTableTypes.QUOTES_PAGE_NONE,
    limit: 40,
    status: [],
    offer: [],
    customer: [],
    shipTo: [],
    buyer: [],
    accountManager: [],
    product: [],
    productGroup: [],
    port: [],
    incoterm: [],
    vendor: [],
    valueFrom: '',
    valueTo: '',
    validityDateFrom: '',
    validityDateTo: '',
    withChangesOnly: false,
    onlyArchivedOrDeliveredOrExpired: false,
    withoutArchivedOrDelivered: !withExpired,
    withExpired
  }), [withExpired]);
};

export interface QuotesPageState {
  ids: number[];
  statuses: QuotesByStatusSummary[];
  quoteIdsByGroups: { [id: string]: { ids: number[], loading: boolean, error: any } };
  quotes: QuoteView[];
  references: { [id: number]: QuotesByOfferSummary & { type: "OFFER" | "REQUEST" } };
  customers: { [id: number]: QuotesByCustomerSummary };
  shipTos: { [id: number]: QuotesByShipToSummary };
  loading: boolean;
  error: any;
}

// Reducer
const INITIAL_STATE: QuotesPageState = {
  ids: [], // - ids
  statuses: [], // - quote statuses info
  quoteIdsByGroups: {},
  quotes: [],
  references: {},
  customers: {},
  shipTos: {},
  loading: false,
  error: null,
};

const reducer = (state: QuotesPageState, action: any): QuotesPageState => {
  state = state || INITIAL_STATE;

  switch (action.type) {

    case ACTIONS_CREATE_QUOTE_SUCCESS:
      return {...state, ids: [...new Set([action.quote.id, ...state.ids])]};

    case ACTIONS_GET_QUOTES_START:
    case 'ACTIONS_QUOTES_PAGE_GET_STATUSES_START':
    case ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_START:
    case ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_START:
    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_START:
      return {...state, loading: true, error: null};

    case ACTIONS_GET_QUOTES_SUCCESS: {
      const loadedIds = action.quotes.map((quote: Quote) => quote.id);
      return {
        ...state,
        ids: !!action.offset ? [...new Set([...state.ids, ...loadedIds])] : loadedIds,
        quotes: !!action.offset ? [...state.quotes, ...action.quotes] : action.quotes,
        loading: false,
        error: null
      };
    }

    case ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_SUCCESS: {
      const loadedIds = action.quoteReferences.map((ref: QuotesByOfferSummary & { type: "OFFER" | "REQUEST" }) => ref.id);
      return {
        ...state,
        ids: !!action.offset ? [...new Set([...state.ids, ...loadedIds])] : loadedIds,
        references: {...state.references, ...composedItems(action.quoteReferences)},
        loading: false,
        error: null
      };
    }

    case ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_SUCCESS: {
      const loadedIds = action.companies.map((company: QuotesByCustomerSummary) => company.id);
      return {
        ...state,
        ids: !!action.offset ? [...new Set([...state.ids, ...loadedIds])] : loadedIds,
        customers: {...state.customers, ...composedItems(action.companies)},
        loading: false,
        error: null
      };
    }

    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_SUCCESS: {
      const loadedIds = action.locations.map((location: QuotesByShipToSummary) => location.id);
      return {
        ...state,
        ids: !!action.offset ? [...new Set([...state.ids, ...loadedIds])] : loadedIds,
        shipTos: {...state.shipTos, ...composedItems(action.locations)},
        loading: false,
        error: null
      };
    }

    case ACTIONS_GET_QUOTES_ERROR:
    case 'ACTIONS_QUOTES_PAGE_GET_STATUSES_ERROR':
    case ACTIONS_GET_REFERENCE_GROUPS_FOR_QUOTES_ERROR:
    case ACTIONS_GET_CUSTOMER_GROUPS_FOR_QUOTES_ERROR:
    case ACTIONS_GET_SHIP_TO_GROUPS_FOR_QUOTES_ERROR:
      return {...state, loading: false, error: action.error};

    case ACTIONS_GET_QUOTES_BY_GROUP_START:
      return {
        ...state,
        quoteIdsByGroups: {
          ...state.quoteIdsByGroups,
          [action.groupId]: {...state.quoteIdsByGroups[action.groupId], loading: true, error: null}
        }
      };

    case ACTIONS_GET_QUOTES_BY_GROUP_SUCCESS: {
      const loadedIds = action.quotes.map((quote: Quote) => quote.id);
      const oldIds = state.quoteIdsByGroups[action.groupId]?.ids || [];
      return {
        ...state,
        quoteIdsByGroups: {
          ...state.quoteIdsByGroups,
          [action.groupId]: {
            ids: !!action.offset ? [...new Set([...oldIds, ...loadedIds])] : loadedIds,
            loading: false,
            error: null
          }
        }
      };
    }

    case ACTIONS_GET_QUOTES_BY_GROUP_ERROR:
      return {
        ...state,
        quoteIdsByGroups: {
          ...state.quoteIdsByGroups,
          [action.groupId]: {...state.quoteIdsByGroups[action.groupId], loading: false, error: action.error}
        }
      };

    case 'ACTIONS_QUOTES_PAGE_DELETE_OFFER': {
      if (!!action.groupId) {
        const updatedData = {
          ...state.quoteIdsByGroups[action.groupId],
          ids: state.quoteIdsByGroups[action.groupId]?.ids?.filter(id => id !== action.quoteId),
        };
        return {
          ...state,
          quoteIdsByGroups: {...state.quoteIdsByGroups, [action.groupId]: updatedData}
        };

      } else {
        return {
          ...state,
          ids: state.ids.filter(id => id !== action.quoteId),
        };
      }
    }

    case 'ACTIONS_QUOTES_PAGE_ARCHIVE_OFFER': {
      if (!!action.groupId) {
        const updatedGroupData = {
          ...state.quoteIdsByGroups[action.groupId],
          ids: state.quoteIdsByGroups[action.groupId]?.ids?.filter(id => id !== action.quoteId),
        };
        return {
          ...state,
          quoteIdsByGroups: {
            ...state.quoteIdsByGroups,
            [action.groupId]: updatedGroupData,
          }
        };

      } else {
        return state;
      }
    }

    case 'ACTIONS_QUOTES_PAGE_GET_STATUSES_SUCCESS':
      return {...state, statuses: action.statuses, loading: false, error: null};

    case 'ACTIONS_QUOTES_PAGE_CLEAR_DATA':
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
