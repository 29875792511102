import API, {CreateDocumentRequest} from "~/API";
import {useMutateHook} from "~/Hooks";

export const usePurchaseOrderDocumentDelete = (orderId: number) =>
  useMutateHook<{ docId: number }, any, any>(
    ["api/purchase/order/documents", orderId],
    async (args) => API.deleteSalesOrderDocument({ id: args.docId })
  );

export const usePurchaseOrderDocumentCreate = (orderId: number) =>
  useMutateHook<{ attachments: CreateDocumentRequest[]; }, any, any>(
    ["api/purchase/order/documents", orderId],
    async (args) => Promise.all(
      args.attachments.map((attach) =>
        API.createPurchaseOrderDocument({ id: orderId }, attach),
      ),
    ),
  );