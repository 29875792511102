import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  useCurrentUser,
  useIsInternalUser,
} from "~/Reducers/User";
import UserParticipantList from "~/Components/Users/UserParticipantList";
import OrderHeader from "./Components/OrderHeader";
import SalesOrderMessage from "./Components/SalesOrders/SalesOrderMessage";
import OrderGraphic from "./Components/OrderGraphic";
import SalesOrderShippingDetails from "./Components/SalesOrders/SalesOrderShippingDetails";
import OrderItems from "./Components/OrderItems";
import OrderDocuments from "./Components/OrderDocuments";
import OrderEvents from "./Components/OrderEvents";
import OrderCompany from "./Components/OrderCompany";
import OrderChat from "./Components/OrderChat";
import RightPanel from "~/Components/Layout/RightPanel";
import {
  ordersPathCompleted,
  ordersPathOngoing,
  ordersPathSales,
  ordersRootPath,
} from "~/Services/Routes";
import PurchaseOrderShippingDetails from "./Components/PurchaseOrders/PurchaseOrderShippingDetails";
import SalesOrderTitleContainer from "./Components/SalesOrders/SalesOrderTitleContainer";
import OrderInvoices from "./Components/OrderInvoices";
import { DateTimeView } from "~/Components/Views/DateView";
import {useUsersList} from "~/Data/Users";
import {getSalesOrderChatId, useChatOrderUnread} from "~/Data/Chats";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {PageTitle} from "~/Components/Nav/PageTitle";
import {ScrollPage} from "~/Components/Layout/Pages";
import {TbMessage2} from "react-icons/tb";
import {Button, Show} from "~/Components/UI";
import {FollowButton} from "~/Components/Entities/FollowButton";
import {useToggle} from "~/Hooks";
import {useAddSalesOrderMember, useRemoveSalesOrderMember, useSalesOrder} from "~/Data/Orders/SalesOrders";
import {LoadingPage} from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";

const SalesOrderDetailsPage: FC = () => {
  const isInternalUser = useIsInternalUser();
  const orderId = Number(useParams<{ id: string }>().id);
  const order = useSalesOrder(orderId);
  const chatUnread = useChatOrderUnread(orderId, false);
  const currentUserId = useCurrentUser()?.id;

  const chat = getSalesOrderChatId(orderId)

  const isCompleted = useIsCompleted();
  const showChat = useToggle();

  const membersList = useUsersList(order.data?.members || []);

  const parentUrl = isInternalUser
    ? ordersRootPath + ordersPathSales
    : ordersRootPath +
    ordersPathSales +
    "/" +
    (isCompleted ? ordersPathCompleted : ordersPathOngoing);

  const addMember = useAddSalesOrderMember(orderId);
  const removeMember = useRemoveSalesOrderMember(orderId);

  const isMember = order.data?.members.includes(currentUserId);
  const onFollow = () => addMember.call(currentUserId);
  const onUnfollow = () => removeMember.call(currentUserId);

  if(order.error)
    return (
      <EndpointErrorPage
        status={order.error?.status}
        item="sales order"
      />
    )

  if(order.isLoading)
    return <LoadingPage />

  return (
    <>
      <ScrollPage title={`Order  #${order.data.number}`}>
        <PageTitle type="back" parentUrl={parentUrl} />
        <div className="flex-row flex-wrap align-center mx-lg mt-lg mb-xs">
          <div className="text-xl">
            <SalesOrderTitleContainer
              isInternalUser={isInternalUser}
              orderNumber={order.data.number}
              masterChild={order.data.masterChild}
            />
          </div>
          <div className="color-text-3 ml-sm mr-auto">
            Last Updated <DateTimeView date={order.data.dateUpdated} />
          </div>

          <FollowButton
            {...{ isMember, onFollow, onUnfollow }}
            chat={chat.data}
            followLabel="title.follow.order"
            unfollowDialogTitle="phrase.unfollow.order.confirm.1"
            unfollowDialogText="phrase.unfollow.order.confirm.2"
          />

          {isMember && (
            <>
              <UserParticipantList
                members={membersList.data || []}
                onAddMember={addMember.call}
                onRemoveMember={removeMember.call}
                includeCompany={order.data.companyId}
              />
              <Button
                variant="text"
                leftIcon={<TbMessage2 size={20} />}
                rightIcon={<ChatUnreadBadge unread={chatUnread} />}
                color={showChat.value ? "blue" : "gray"}
                onClicked={showChat.toggle}
                label="title.chat"
              />
            </>
          )}
        </div>

        <div className="flex-col gap-lg">
          <div className="bg-black-12 rounded-xxs">
            <OrderCompany orderId={orderId} isSales />
            <OrderHeader orderId={orderId} />
            <SalesOrderMessage orderId={orderId} />
            <OrderGraphic orderId={orderId} />

            <Show when={order.data.purchaseOrderId} fallback={<SalesOrderShippingDetails orderId={orderId} />}>
              <PurchaseOrderShippingDetails orderId={order.data.purchaseOrderId} />
            </Show>
          </div>

          <div className="bg-black-12 rounded-xxs">
            <OrderItems orderId={orderId} />
          </div>

          <div className="bg-black-12 rounded-xxs">
            <OrderInvoices orderId={orderId} />
          </div>

          <div className="bg-black-12 rounded-xxs">
            <OrderDocuments orderId={orderId} isSales />
          </div>

          <div className="bg-black-12 rounded-xxs">
            <OrderEvents orderId={orderId} />
          </div>
        </div>
      </ScrollPage>

      <Show when={isMember}>
        <RightPanel visible={showChat.value}>
          <OrderChat orderId={orderId} onClose={showChat.toggle} />
        </RightPanel>
      </Show>
    </>
  );
};

export default SalesOrderDetailsPage;

const useIsCompleted = () => {
  const { type } = useParams<{ type: string }>();
  return type?.toLowerCase() === "completed";
};
