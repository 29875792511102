import {FC} from "react";
import { useHistory } from "react-router-dom";
import {
  joinCallWithUser,
  useGetLinkToVideoCall,
} from "~/Reducers/VideoCall";
import {useAppDispatch} from "~/StoreTypes";
import {TbVideo} from "react-icons/tb";
import {Button} from "~/Components/UI";

export const VideoCallButton: FC<{
  userId: number;
  chatId: number;
  disabled?: boolean;
  className?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getLinkToVideoCall = useGetLinkToVideoCall(props.userId);

  const onJoinCallClick = async () => {
    if (props.userId) {
      const linkToVideoCall = await getLinkToVideoCall(props.chatId);
      dispatch(joinCallWithUser(props.userId, props.chatId));
      history.push(linkToVideoCall);
    }
  }

  return (
    <Button
      leftIcon={<TbVideo size={16}/>}
      label="title.start.call"
      onClicked={onJoinCallClick}
      disabled={props.disabled}
    />
  )
}

export const VideoCallIconButton: FC<{
  userId: number;
  chatId?: number;
  className?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getLinkToVideoCall = useGetLinkToVideoCall(props.userId);

  const onJoinCallClick = async () => {
    if (props.userId) {
      const linkToVideoCall = await getLinkToVideoCall(props.chatId);
      dispatch(joinCallWithUser(props.userId, props.chatId));
      history.push(linkToVideoCall);
    }
  }

  return (
    <Button
      leftIcon={<TbVideo size={16}/>}
      color="gray"
      onClicked={onJoinCallClick}
    />
  )
}
