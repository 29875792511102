import { FC } from "react";
import { LC } from "~/Locales";
import { GroupPermission } from "~/API";
import { FormInput } from "~/Components/UI";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import { CountryCombobox } from "~/Components/Comboboxes/CountryCombobox";

export const CompanyForm: FC<{
  formData: any;
  isVendor: boolean;
}> = (props) => {
  return (
    <>
      <div className="flex-col gap-sm flex mt-lg">
        <div className="text-lg text-left">
          <LC id="title.company.details" />
        </div>

        <FormInput
          id="legalName"
          formData={props.formData}
          label="company.legal.name"
        />

        {props.isVendor && (
          <>
            <UserByPermissionCombobox
              id="accountManagerUserId"
              formData={props.formData}
              label="title.buyer"
              showClear
              permissions={[GroupPermission.CAN_BE_SALES_CONTACT_PERSON]}
            />

            <UserByPermissionCombobox
              id="additionalResponsibles"
              formData={props.formData}
              label="title.additional.responsibles"
              multiple
              showClear
              clearValue={[]}
              permissions={[GroupPermission.CAN_BE_BUYER]}
              excludeUsers={[props.formData.data.accountManagerUserId]}
            />
          </>
        )}

        <FormInput
          id="addressStreet"
          formData={props.formData}
          label="title.address.1"
        />
        <FormInput
          id="addressStreet2"
          formData={props.formData}
          label="title.address.2"
        />
        <FormInput
          id="addressCity"
          label="title.city"
          formData={props.formData}
        />

        <div className="flex-row gap-md">
          <FormInput
            id="addressRegion"
            label="title.state"
            containerClassName="flex"
            formData={props.formData}
          />

          <FormInput
            id="addressZip"
            label="title.postcode"
            formData={props.formData}
          />
        </div>

        <CountryCombobox
          id="addressCountry"
          formData={props.formData}
          label="title.country"
          dataCy={"CountryComboBox"}
        />
      </div>
    </>
  );
};
