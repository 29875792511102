import { OfferStatus, OfferUpdate, OfferView } from "~/API";
import { FC, useCallback } from "react";
import { LocalizedMessage } from "~/Locales";
import { useToggle } from "~/Hooks/Old";
import { archiveRFO, deleteRFO, useShouldShowArchiveButton } from "../Reducer";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import DeleteDialog from "~/Components/Old/DeleteDialog";
import { DeleteIcon, InboxArchiveIcon } from "~/Components/Old/Icons";
import {useAppDispatch} from "~/StoreTypes";
import {trackEvent} from "~/Services/Tracking";

const RFOArchiveDeleteButtons: FC<{
  offer: OfferView;
  groupId?: string | null;
}> = ({ offer, groupId = null }) => {
  const dispatch = useAppDispatch();
  const { status } = offer;
  const isDraft = status === OfferStatus.RFO_DRAFT; // RFO_DRAFT only. We should archive RFO_DRAFT_VENDOR

  const [showDeleteConfirmation, toggleDeleteDialog] = useToggle();
  const [showArchiveConfirmation, toggleArchiveDialog] = useToggle();

  const onConfirmDelete = useCallback(() => {
    dispatch(deleteRFO(offer.id, groupId, toggleDeleteDialog));
    trackEvent("Request for Offer Deleted");
  }, [offer.id, groupId, dispatch, toggleDeleteDialog]);

  const onConfirmArchive = useCallback(() => {
    dispatch(
      archiveRFO(
        { ...offer, status: OfferStatus.RFO_ARCHIVED } as {
          id: number;
        } & OfferUpdate,
        groupId,
        toggleArchiveDialog,
      ),
    );
    trackEvent("Request for Offer Archived");
  }, [offer, groupId, dispatch, toggleArchiveDialog]);

  const shouldShowArchiveButton = useShouldShowArchiveButton(status);

  return (
    <>
      {isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton DeleteButtonText"}
          onClick={(e) => {
            e.stopPropagation();
            toggleDeleteDialog();
          }}
        >
          <DeleteIcon size={20} />
        </Button>
      )}

      {!isDraft && shouldShowArchiveButton && (
        <Button
          view={"text"}
          className={"ActionButton ArchiveButton"}
          onClick={(e) => {
            e.stopPropagation();
            toggleArchiveDialog();
          }}
        >
          <InboxArchiveIcon size={20} />
        </Button>
      )}

      {showDeleteConfirmation && (
        <DeleteDialog
          title={<LocalizedMessage id={"title.delete.request"} />}
          description={
            <LocalizedMessage id={"phrase.delete.rfo"}>
              {offer.id}
            </LocalizedMessage>
          }
          description2={
            <LocalizedMessage id={"phrase.this.actions.can.not.be.undone"} />
          }
          confirmTitle={<LocalizedMessage id={"title.delete"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onDelete={onConfirmDelete}
          onClose={toggleDeleteDialog}
        />
      )}
      {showArchiveConfirmation && (
        <ConfirmationDialog
          title={<LocalizedMessage id={"title.archive.request"} />}
          description={
            <LocalizedMessage id={"phrase.archive.rfo"}>
              {offer.id}
            </LocalizedMessage>
          }
          description2={""}
          confirmTitle={<LocalizedMessage id={"title.archive"} />}
          closeTitle={<LocalizedMessage id={"title.cancel"} />}
          onConfirm={onConfirmArchive}
          onClose={toggleArchiveDialog}
        />
      )}
    </>
  );
};

export default RFOArchiveDeleteButtons;
