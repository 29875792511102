import { FC, Ref, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestsRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  getRequestsForQuoteForShipTo,
  isRequestExpired,
  useRequestForQuote,
  useRequestForQuoteProductCodes,
  useRequestForQuoteValue,
} from "~/Reducers/RequestsForQuote";
import {
  useDefaultPageFilters,
  useRequestsForQuotePageByGroupReduxState,
  useRequestsForQuotePageReduxState,
  useShipTo,
} from "../../Reducer";
import {
  RequestShowUnread,
  RequestStatusLabel,
  useIsDraft,
} from "../../DetailsPage/Components/RequestsHelper";
import Button from "~/Components/Old/Button";
import { ArrowDownIcon, ArrowRightIcon } from "~/Components/Old/Icons";
import LinearProgress from "~/Components/Old/LinearProgress";
import RequestArchiveDeleteButtons from "../RequestArchiveDeleteButtons";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useFilters } from "~/Components/Old/useFilters";
import { classNames } from "react-ui-basics/Tools";
import "./RequestsPageTableWithSubrows.scss";
import { DateTimeView } from "~/Components/Views/DateView";
import { useAMUserNameStrings } from "~/Reducers/Users";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

const RequestsPageByShipToTable: FC<{
  scrollRef: Ref<any>;
  onScrolledToBottom: () => void;
}> = ({ scrollRef, onScrolledToBottom }) => {
  const { ids, loading } = useRequestsForQuotePageReduxState();

  return (
    <ScrollableForPagination
      ref={scrollRef}
      className={"flex bg-black-12 overflow-auto"}
      onScrolledToBottom={onScrolledToBottom}
    >
      {loading && (
        <div className={"progress-bar"}>
          <LinearProgress />
        </div>
      )}

      <table className={"basic-table RequestsPageTableWithSubrows"}>
        <thead>
          <tr>
            <th />
            <th>
              <LocalizedMessage id={"title.code"} />
            </th>
            <th>
              <LocalizedMessage id={"title.ship.to"} />
            </th>
            <th>
              <LocalizedMessage id={"title.customer"} />
            </th>
            <th>
              <LocalizedMessage id={"title.requests"} />
            </th>
            <th className={"text-right"}>
              <LocalizedMessage id={"title.value"} />
            </th>
            <th colSpan={4} />
          </tr>
        </thead>
        {ids.map((shipToId) => (
          <ShipToRow key={shipToId} {...{ shipToId }} />
        ))}
      </table>
    </ScrollableForPagination>
  );
};

export default RequestsPageByShipToTable;

const ShipToRow = ({ shipToId }) => {
  const shipTo = useShipTo(shipToId) || undefined;

  const [expanded, expandCollapse] = useToggle(false);

  const { code, numberOfRequests, totalValue, currency } = shipTo || {};
  const currencySymbol = useFindCurrencySymbol(currency);

  return (
    <>
      <tbody>
        <tr onClick={expandCollapse} className={"ClickableHeaderOrRow"}>
          <td>
            {expanded ? (
              <ArrowDownIcon size={26} />
            ) : (
              <ArrowRightIcon size={26} />
            )}
          </td>
          <td>{code}</td>
          <td>{shipTo?.name}</td>
          <td>{shipTo?.companyName}</td>
          <td>{numberOfRequests}</td>
          <td
            className={"text-right color-orange-1 no-wrap"}
          >{`${currencySymbol} ${formattedNumber(totalValue)}`}</td>
          <td colSpan={4} />
        </tr>
      </tbody>
      {expanded && <RequestsForShipTo shipToId={shipToId} />}
    </>
  );
};

const ITEMS_PER_PAGE = 10;
const filtersToExclude = ["shipTo", "limit"];

const RequestsForShipTo = ({ shipToId }) => {
  const pageDataByGroupId = useRequestsForQuotePageByGroupReduxState(shipToId);

  const defaultPageFilters = useDefaultPageFilters();
  const pageFilters = useMemo(
    () => ({
      ...defaultPageFilters,
      shipTo: [shipToId],
      limit: ITEMS_PER_PAGE,
    }),
    [shipToId, defaultPageFilters],
  );

  const pageDataByCustomerReduxFunc = useCallback(
    () => pageDataByGroupId,
    [pageDataByGroupId],
  );

  const { onScrolledToBottom, canLoadMore } = useFilters(
    pageFilters,
    getRequestsForQuoteForShipTo,
    pageDataByCustomerReduxFunc,
    null,
    null,
    filtersToExclude,
  );

  const { ids = [], loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  return (
    <tbody>
      <tr className={"RequestRowAsSubrow"}>
        <th />
        <th>
          <LocalizedMessage id={"title.rq.id"} />
        </th>
        <th>
          <LocalizedMessage id={"title.status"} />
        </th>
        <th>
          <LocalizedMessage id={"title.salesperson"} />
        </th>
        <th>
          <LocalizedMessage id={"title.products"} />
        </th>
        <th>
          <LocalizedMessage id={"title.product.code"} />
        </th>
        <th className={"text-right"}>
          <LocalizedMessage id={"title.value"} />
        </th>
        <th>
          <LocalizedMessage id={"title.validity.date"} />
        </th>
        <th>
          <LocalizedMessage id={"title.actions"} />
        </th>
        <th />
      </tr>
      {ids.map((requestId) => (
        <RequestRow key={requestId} groupId={shipToId} {...{ requestId }} />
      ))}

      {showAdditionalRow && (
        <tr className={"RequestRowAsSubrow"}>
          {!showLoader && (
            <td colSpan={10} className={"MoreButtonCell"}>
              <Button
                view={"text"}
                className={classNames(
                  "w-full",
                  showTryAgain && "DeleteButtonText",
                )}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage
                  id={showTryAgain ? "title.try.again" : "title.get.more"}
                />
              </Button>
            </td>
          )}
          {showLoader && (
            <td colSpan={10}>
              <LinearProgress />
            </td>
          )}
        </tr>
      )}
    </tbody>
  );
};

const RequestRow: FC<{
  requestId: number;
  groupId: string;
}> = ({ requestId, groupId }) => {
  const history = useHistory();
  const request = useRequestForQuote(requestId) || undefined;
  const value = useRequestForQuoteValue(requestId);
  const productCodes = useRequestForQuoteProductCodes(requestId);
  const {
    id,
    status,
    currency,
    products,
    validityDate,
    unreadChatMessages,
    accountManagerName,
    shipToAccountManagerName,
  } = request || {};
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = useIsDraft(status);

  const onOpen = useCallback(
    () => history.push(`${requestsRootPath}${requestId}`),
    [history, requestId],
  );

  const isExpired = isRequestExpired(validityDate);

  const managers = useAMUserNameStrings(
    accountManagerName,
    shipToAccountManagerName,
  );

  return (
    <tr className={"RequestRowAsSubrow"} onClick={onOpen}>
      <td
        className={classNames(
          RequestShowUnread(request?.readMark, isExpired) ? "row-unread" : "",
        )}
      />
      <td className={"no-wrap"}>{`RQ-${id}`}</td>
      <td>{isExpired ? "Expired" : <RequestStatusLabel status={status} />}</td>
      <td>{managers}</td>
      <td>{products?.length || 0}</td>
      <td>{productCodes}</td>
      <td className={"text-right no-wrap color-orange-1"}>
        {`${currencySymbol} ${formattedNumber(value) || "0"}`}
      </td>
      <td>
        <DateTimeView date={validityDate} />
      </td>
      <td>
        <div className={"flex-row align-center justify-center"}>
          <RequestArchiveDeleteButtons {...{ request, groupId }} />
        </div>
      </td>
      <td>
        {!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}
      </td>
    </tr>
  );
};
