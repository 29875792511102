import LeftMenuIcon from "~/Components/Old/LeftMenuIcon";
import './PageTitleContainer.scss';
import SpaceFiller from "./SpaceFiller";
import { useUserPreferences } from "~/Reducers/UserPreferences";
import {FC, ReactNode} from "react";
import {NotificationButton} from "~/Components/Notifications/NotificationButton";

/**
 * @deprecated use ~/Components/Nav/PageTitle
**/
const PageTitleContainer: FC<{
    title?: string | ReactNode,
    children?: string | ReactNode,
    menuButton?: string | ReactNode,
}> = ({ title, children = null, menuButton = null }) => {
    const settings = useUserPreferences()
    const showBellNotifications = !!settings.showBellNotifications;
    return <div className={'PageTitleContainer'}>
        <>
            {!!menuButton ? menuButton : <LeftMenuIcon/>}
            <div className={'text-xl PageTitleText'}>{title}</div>
        </>
        <SpaceFiller/>
        {children}
        { showBellNotifications &&
        <NotificationButton/>
        }
        {/*<TopBarDivider />*/}

        {/*<AppLanguageSelect />*/}
    </div>
}

export default PageTitleContainer;