import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OfferMatchingResult, OfferProductView, OfferView, ProductV2UOM} from "~/API";
import dayjs from "dayjs";

export interface OfferMatchingProduct {
  id: number;
  quantity: number;
  uom: ProductV2UOM;
  sellPrice: number;
  model: OfferProductView;
}

export interface OfferMatchingLocation {
  id: number
  selected: boolean | "indeterminate";
  incoterm: string;
  destination: string;
  portId: number;
  deliveryDate: string;
  containers: number;
  quoteValue: number;
  products: number[];
  model: OfferMatchingResult;
}

export interface OfferMatchingState {
  matchingOnly: boolean;
  offer: OfferView | null;
  products: OfferMatchingProduct[];
  locations: OfferMatchingLocation[];
}

const initialState: OfferMatchingState = {
  matchingOnly: true,
  offer: null,
  locations: [],
  products: [],
};

export const offerMatchingSlice = createSlice({
  name: "offerMatchingState",
  initialState,
  reducers: {
    setMatchingOffer: (state, action: PayloadAction<OfferView>) => {
      state.offer = action.payload;
    },
    setMatchingOnly: (state, action: PayloadAction<boolean>) => {
      state.matchingOnly = action.payload;
    },
    setMatchingProducts: (state, action: PayloadAction<OfferProductView[]>) => {
      state.products = action.payload.map((p) => ({
        id: p.id,
        quantity: p.quantity,
        sellPrice: p.price,
        uom: p.uom,
        model: p
      }));
    },
    setMatchingLocations: (state, action: PayloadAction<{
      rows: OfferMatchingResult[];
      offer: OfferView;
    }>) => {
      state.locations = action.payload.rows.map((l) => {
        let deliveryDate = null;
        if (action.payload.offer.dateIncoDestination != null) {
          deliveryDate = dayjs(action.payload.offer.dateIncoDestination);
          if (l?.transitTimeDays)
            deliveryDate.add(l.transitTimeDays, 'day');
        }

        return {
          id: l.locationId,
          selected: false,
          products: [],
          deliveryDate: deliveryDate?.toISOString() || "",
          destination: l.address,
          portId: l.portId,
          incoterm: l.incoterm,
          containers: 0,
          quoteValue: 0,
          model: l,
        }
      });
    },
    setMatchingProductField: (state, action: PayloadAction<{ id: number, fieldId: string, value: any }>) => {
      const index = state.products.findIndex((p) => p.id == action.payload.id);
      state.products[index][action.payload.fieldId] = action.payload.value;
    },
    setMatchingLocationField: (state, action: PayloadAction<{ id: number, fieldId: string, value: any }>) => {
      const index = state.locations.findIndex((p) => p.id == action.payload.id);
      state.locations[index][action.payload.fieldId] = action.payload.value;
    },
    toggleSelectedLocation: (state, action: PayloadAction<number>) => {
      const index = state.locations.findIndex((p) => p.id == action.payload);

      if (state.locations[index].selected == true) {
        state.locations[index].products = [];
        state.locations[index].selected = false;
      } else {
        if (state.matchingOnly)
          state.locations[index].products = state.locations[index].model.matchedProducts;
        else
          state.locations[index].products = state.products.map((p) => p.id);

        state.locations[index].selected = true;
      }
    },
    toggleSelectedProduct: (state, action: PayloadAction<{ locationId: number, productId: number }>) => {
      const index = state.locations.findIndex((p) => p.id == action.payload.locationId);
      const prodIndex = state.locations[index].products.findIndex((p) => p == action.payload.productId);

      if (prodIndex == -1) // toggle true
        state.locations[index].products.push(action.payload.productId);
      else // toggle false
        state.locations[index].products.splice(prodIndex, 1);


      // determine the location total selected state
      const targetLength = state.matchingOnly ? state.locations[index].model.matchedProducts.length : state.products.length;
      if (state.locations[index].products.length == 0)
        state.locations[index].selected = false;
      else if (state.locations[index].products.length == targetLength)
        state.locations[index].selected = true;
      else
        state.locations[index].selected = "indeterminate";
    },
    unselectMatchingProducts: (state) => {
      state.locations.forEach((l) => {
        l.products = [];
      });
    }
  },
  selectors: {
    locationContainerTotal: (state, locationId: number) => {
      const index = state.locations.findIndex((p) => p.id == locationId);

      const selectedProducts = state.products.filter((p) => state.locations[index].products.includes(p.id));
      return selectedProducts.reduce((prev, prod) => prev + (prod.quantity / prod.model.unitsContainer), 0).toFixed(1);
    },
    locationPriceTotal: (state, locationId: number) => {
      const index = state.locations.findIndex((p) => p.id == locationId);

      const selectedProducts = state.products.filter((p) => state.locations[index].products.includes(p.id));
      return selectedProducts.reduce((prev, prod) => prev + (prod.quantity * prod.sellPrice), 0).toFixed(2);
    },
    matchingQuotesLength: (state) => {
      return state.locations.filter((l) => l.products.length > 0).length;
    },
  },
});

export const {
  setMatchingOffer,
  setMatchingOnly,
  setMatchingProducts,
  setMatchingLocations,
  setMatchingProductField,
  setMatchingLocationField,
  toggleSelectedLocation,
  toggleSelectedProduct,
  unselectMatchingProducts,
} = offerMatchingSlice.actions;

export const {
  locationContainerTotal,
  locationPriceTotal,
  matchingQuotesLength,
} = offerMatchingSlice.selectors;

export default offerMatchingSlice.reducer;