import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "~/Reducers/User";
import { LC, ls } from "~/Locales";
import { dashboardRootPath } from "~/Services/Routes";
import { validPassword, zodMsg } from "~/Utils";
import { Registration } from "~/API";
import BaseLoginPage from "./BaseLoginPage";
import { useFormData, usePageTitle } from "~/Hooks";
import { useAppDispatch } from "~/StoreTypes";
import { Button, FormInput, Input } from "~/Components/UI";
import { z } from "zod";
import { useUpdateCurrentUser } from "~/Data/CurrentUser";
import { useCompanyLocations } from "~/Data/Companies/CompanyLocations";
import {useCompany} from "~/Data/Companies/Companies";

const formValidation = z
  .object({
    password: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Password"]))
      .refine((p) => validPassword(p), zodMsg("password.req.desc")),
    confirmPassword: z
      .string()
      .min(1, zodMsg("error.non.empty", ["Confirm Password"])),
  })
  .refine((d) => d.confirmPassword === d.password, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const CompleteRegistrationPage: FC = () => {
  usePageTitle("Registration");

  const history = useHistory();
  const dispatch = useAppDispatch();
  const updateUser = useUpdateCurrentUser(dispatch);

  const [submitted, setSubmitted] = useState(false);
  const form = useFormData(
    {
      password: "",
      confirmPassword: "",
    },
    { validation: formValidation },
  );

  const currentUser = useCurrentUser();

  const company = useCompany(currentUser.companyId);
  const locations = useCompanyLocations(currentUser.companyId);

  const usersLocations = locations.data
    ?.map((loc) => loc.name)
    .filter((it) => !!it)
    .join(", ") || "";

  const onSubmit = () => {
    if (!form.isValid()) return;

    updateUser
      .call({
        ...currentUser,
        password: form.data.password,
        registrationStatus: Registration.REGISTERED,
      })
      .then(() => {
        history.replace(dashboardRootPath);
        setSubmitted(true);
      });
  };

  return (
    <BaseLoginPage>
      {!submitted && (
        <div className="flex-col gap-md">
          <div className="text-2xl">
            <LC id="complete.registration" />
          </div>

          <Input
            id="name"
            value={`${currentUser.name} ${currentUser.surname}`}
            disabled
            label="your.name"
          />
          <Input
            id="company"
            value={company.data?.legalName}
            disabled
            label="title.company"
          />
          <Input
            id="locations"
            value={usersLocations}
            disabled
            label="title.locations"
          />
          <Input
            id="email"
            value={currentUser.email}
            disabled
            label="your.email"
          />

          <FormInput
            id="password"
            formData={form}
            type="password"
            autoComplete="new-password"
            label="set.your.password"
          />
          <FormInput
            id="confirmPassword"
            formData={form}
            type="password"
            autoComplete="new-password"
            label="confirm.your.password"
          />

          <div className="text-sm">
            <LC id="phrase.by.signin.up.i.agree">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={ls("phrase.privacy.policy.link")}
              >
                <LC id="phrase.privacy.policy" />
              </a>
            </LC>
          </div>

          <Button
            disabled={!form.data.password || !form.data.confirmPassword}
            onClicked={onSubmit}
            loading={updateUser.isMutating}
            label="create.account"
          />

          <Button
            color="gray"
            onClicked={() => history.push("/sign-in")}
            label="title.back.to.login"
          />
        </div>
      )}
    </BaseLoginPage>
  );
};

export default CompleteRegistrationPage;
