import SelectWithPaging from "./SelectWithPaging";
import {
  getProductPublic,
  getProductsPublic,
  useProductPublicCodeLabels,
  useProductsPublic
} from "~/Reducers/ProductsPublic";
import useFetchMissingItems from "./useFetchMissingItems";


const ProductSelectPublic = (props) => {
  const products = useProductsPublic();
  useFetchMissingItems(props.value, products, getProductPublic);

  return <SelectWithPaging {...props}
    getData={getProductsPublic}
    useDataLabels={useProductPublicCodeLabels}
  />;
};

export default ProductSelectPublic;
