import useSWRInfinite from "swr/infinite";
import API, {MyProduct} from "~/API";


export interface CompanyProductsFilters {
    companyId: number,
    query?: string,
    search?: string,
    offset?: number,
    limit?: number
}

const PAGE_SIZE = 15;

const getNewCompanyProductSalesKey = (
    filters: CompanyProductsFilters,
    offset: number,
    previousPageData: MyProduct[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: `api/v3/products/companyProducts/${filters.companyId}`, filters, offset };
};
export const useCompanyProducts = (filters: CompanyProductsFilters) =>
    useSWRInfinite(
        (i, p) => getNewCompanyProductSalesKey(filters, i * PAGE_SIZE, p),
        (key) =>
            API.getCompanyProducts({
                ...filters,
                offset: key.offset,
                limit: PAGE_SIZE,
            }),

        {
            revalidateFirstPage: false,
        },
    );

