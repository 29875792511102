import { useCallback, useEffect, useState } from "react"

export const useAsync = <T>(callback: () => Promise<T>, dependencies = []) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(undefined);
  const [value, setValue] = useState<T | undefined>();

  const callbackMemoized = useCallback(() => {
    setLoading(true);
    setError(undefined);
    callback()
      .then(setValue)
      .catch(setError)
      .finally(() => setLoading(false))
  }, dependencies)

  useEffect(() => {
    callbackMemoized()
  }, [callbackMemoized])

  return { loading, error, value, refetch: callbackMemoized }
}