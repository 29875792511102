import Button from "~/Components/Old/Button";
import Vars from "~/Styles/Vars";
import { useCurrentUser } from "~/Reducers/User";
import {TbUserFilled} from "react-icons/tb";
import {FC, MouseEventHandler} from "react";

import './Banner.scss';

const LoggedInAsAnotherUserBanner: FC = () => {

  const logout: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    sessionStorage.setItem("LoginAsUser", null)
    window.location.reload()
  };

  const user = useCurrentUser();
  const show = !!user.loginAsUser;

  return show
    ? <div className={`banner banner-red ${show ? 'show' : ''}`}>
      <div className="flex-row gap-sm color-red-6 align-center text-bold">
        <TbUserFilled size={Vars.size.lg} />
        <div>
          You are logged-in as {user.name} {user.surname}
        </div>
      </div>

      <Button view="gray" className={'logout'} onClick={logout}>
        Exit this view
      </Button>
    </div>
    : null;
}

export default LoggedInAsAnotherUserBanner;