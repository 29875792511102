import {FC, useState} from "react";
import { useHistory } from 'react-router-dom';
import { useToggle } from "~/Hooks/Old";
import API from "~/API";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import LinearProgress from "~/Components/Old/LinearProgress";
import { LocalizedMessage } from "~/Locales";
import TextField from "~/Components/Old/TextField";
import {getPathForSO} from "~/Services/Routes";
import Toast from "~/Components/Toast";
import {ShipViaCombobox} from "~/Components/Comboboxes/ShipViaCombobox";
import {useFormData} from "~/Hooks";

export const AMConfirmQuoteButton: FC<{ quoteId: number, isDomestic: boolean }> = ({ quoteId, isDomestic }) => {
  const form = useFormData(
      {
        shipVia: '',
        unitTypeCode: null,
      }
  )

  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();

  const [loading, setLoading] = useState(false);

  const [customerPo, setCustomerPo] = useState(`Q-${quoteId}`);

  const history = useHistory();

  const onConfirm = async () => {
    setLoading(true);
    try {
      const order = await API.confirmQuote({ id: quoteId }, {
        shipVia: form.data.shipVia, customerPo: customerPo})
      Toast.Success("Quote is confirmed and order was successfully created.");
      toggleConfirmSubmitDialog();
      history.push(getPathForSO(order.id));
    } catch (error) {
      Toast.ApiError(error);
    }
    finally {
      setLoading(false);
    }
  }


  const onFieldChange = ({ target }) => {
    setCustomerPo(target.value);
  }


  return <>
    <Button onClick={toggleConfirmSubmitDialog}>
      <LocalizedMessage id={'title.confirm.quote'} />
    </Button>

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Quote Confirmation'}
        description={'Do you want to confirm this Quote? '}
        description2={'Once this quote is confirmed, the order will be created'}
        confirmTitle={'Confirm Quote'}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={onConfirm}
        onClose={toggleConfirmSubmitDialog}
        disableConfirmButton={loading || !form.data.shipVia}
      >
        <>
          <ShipViaCombobox
            isDomestic={isDomestic}
            showUnits = {false}
            formData={form}
            label="title.ship.via"
          />

          <TextField id={'customerPo'}
            label={'Customer PO'}
            className={'w-full mt-lg'}
            onChange={onFieldChange}
            value={customerPo}
          />
          {loading && <LinearProgress />}
        </>
      </ ConfirmationDialog>
    }
  </>;
}