import API, { DeliveryItem } from "~/API";
import { useGetEntity_deprecated } from "./Utils/Entity";
import {AppDispatch, RootState} from "~/StoreTypes";


const getSumOf = (items: DeliveryItem[] = [], fieldName: string) => {
  let sum = 0;
  items.forEach(item => sum += Number(item?.[fieldName]));
  return sum;
};

const totalItemsPackages = (items: DeliveryItem[]) => getSumOf(items, 'pkgTotal');

const orderItemsSelectorCreator = (orderId: number) => (state: RootState) => state.ordersItems[orderId];

export const useOrderItems = (orderId: number, force = false) => useGetEntity_deprecated(orderId, getOrderItems, orderItemsSelectorCreator, force);

export const useOrderItemsTotalPackages = (orderId: number) => {
  const items = useOrderItems(orderId);
  return totalItemsPackages(items);
};

const getOrderItems = (orderId: number, onSuccess?: () => void, onError?: (error: any) => void) => async (dispatch: AppDispatch) => {
  try {
    const orderItems = await API.getSalesOrderItems({ id: orderId });
    dispatch({ type: 'ACTIONS_GET_ORDER_ITEMS_SUCCESS', orderId, orderItems });
    onSuccess?.();
    return orderItems;
  } catch (error) {
    onError?.(error);
  }
};

// Reducer
export interface OrderItemsState {
  [id: number]: DeliveryItem[];
}

const INITIAL_STATE: OrderItemsState = {};

const reducer = (state: OrderItemsState, action: any): OrderItemsState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ACTIONS_GET_ORDER_ITEMS_SUCCESS':
      return { ...state, [action.orderId]: action.orderItems };
    default:
      return state;
  }
};

export default reducer;
