import { FC } from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useIsAdmin, useIsInternalUser} from "~/Reducers/User";
import { ChatFilters, useChats } from "~/Data/Chats";
import { LinearLoader } from "~/Components/Loaders";
import { ChatsCard } from "~/Pages/Chats/Components/ChatsCard";
import {cn, onScrollToBottom} from "~/Utils";
import {
  Button,
  For,
  FormInput,
  FormSwitch,
  Popover,
  PopoverContent,
  PopoverTrigger, Show,
} from "~/Components/UI";
import { useDebounce, useFormData } from "~/Hooks";
import {NewChatButton} from "~/Pages/Chats/Components/ChatButtons";
import {UserCombobox} from "~/Components/Users/UserCombobox";
import {CompanyCombobox} from "~/Components/Companies/CompanyCombobox";

const ChatsList: FC<{
  internal: boolean;
  className?: string;
}> = (props) => {
  const isAdmin = useIsAdmin();
  const isInternalUser = useIsInternalUser();
  const location = useLocation();
  const isActiveId = (id: string) => location.pathname.includes(id);

  const searchForm = useFormData<ChatFilters>({
    search: "",
    showUnreadOnly: false,
    showArchived: false,
    company: undefined,
    user: undefined,
    showAllChats: isAdmin
  });
  const searchDebounce = useDebounce(searchForm.data);

  const chats = useChats(searchDebounce, props.internal);

  const onScroll = onScrollToBottom(() => {
    chats.setSize(chats.size + 1).then();
  }, 256);

  return (
    <div className={cn("flex bg-black-12 w-min-8xl py-xs px-sm overflow-hidden flex-col gap-sm rounded-xxs", props.className)}>
      <div className="flex-row gap-sm mt-sm">
        <FormInput
          id="search"
          formData={searchForm}
          placeholder="title.search"
          type="search"
          showClear
          containerClassName="flex"
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button label="title.filter" color="gray" />
          </PopoverTrigger>
          <PopoverContent align="end" className="flex-col gap-sm px-sm py-xs w-max-8xl">
            <UserCombobox
              id="user"
              formData={searchForm}
              className="w-full"
              placeholder="title.select.user"
              showClear
              data-cy={"userComboBoxSelect"}
            />
            <CompanyCombobox
              id="company"
              className="w-full"
              placeholder="title.select.company"
              showClear
              formData={searchForm}
            />
            <FormSwitch
              id="showUnreadOnly"
              label="phrase.only.unread"
              formData={searchForm}
            />
            <FormSwitch
              id="showArchived"
              label="title.show.archived.chat"
              formData={searchForm}
            />
            <Button
              color="gray"
              label="title.clear"
              onClicked={searchForm.clear}
            />
          </PopoverContent>
        </Popover>
      </div>

      <LinearLoader loading={chats.isLoading} />
      <div
        className="overflow-y-auto flex flex-col gap-sm pr-xs py-xxs pl-xxs"
        onScroll={onScroll}
      >
        <For each={chats.data?.flat()}>
          {(chat) => <ChatsCard active={isActiveId(`${chat.id}`)} key={chat.id} chat={chat} internal={props.internal} />}
        </For>
        <LinearLoader loading={chats.isValidating && !chats.isLoading} />
      </div>

      <Show when={props.internal && isInternalUser}>
        <NewChatButton />
      </Show>
    </div>
  );
};

export default ChatsList;