import { FC, Ref, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import { requestsRootPath } from "~/Services/Routes";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import {
  isRequestExpired,
  useRequestForQuote,
  useRequestForQuoteProductCodes,
  useRequestForQuoteValue,
} from "~/Reducers/RequestsForQuote";
import { useRequestsForQuotePageReduxState } from "../../Reducer";
import {
  RequestShowUnread,
  RequestStatusLabel,
  useIsDraft,
} from "../../DetailsPage/Components/RequestsHelper";
import LinearProgress from "~/Components/Old/LinearProgress";
import RequestArchiveDeleteButtons from "../RequestArchiveDeleteButtons";
import ScrollableForPagination from "~/Components/Old/ScrollableForPagination";
import { useIsCustomer } from "~/Reducers/User";
import { DateTimeView } from "~/Components/Views/DateView";
import { useAMUserNameStrings } from "~/Reducers/Users";
import { useIsMobile } from "~/Hooks/useIsMobile";
import { classNames } from "react-ui-basics/Tools";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";

const RequestsPageRequestsTable: FC<{
  scrollRef: Ref<any>;
  onScrolledToBottom: () => void;
}> = ({ scrollRef, onScrolledToBottom }) => {
  const { ids, loading } = useRequestsForQuotePageReduxState();
  const isCustomer = useIsCustomer();
  const isMobile = useIsMobile();

  return (
    <ScrollableForPagination
      estimatedElementHeight={50}
      numberOfElementsBeforeLoading={10}
      ref={scrollRef}
      className={"flex bg-black-12 overflow-auto"}
      onScrolledToBottom={onScrolledToBottom}
    >
      {loading && (
        <div className={"progress-bar"}>
          <LinearProgress />
        </div>
      )}

      {!isMobile && (
        <table className={"basic-table"}>
          <thead>
            <tr>
              <th>
                <LocalizedMessage id={"title.rq.id"} />
              </th>
              <th>
                <LocalizedMessage id={"title.status"} />
              </th>
              {!isCustomer && (
                <th>
                  <LocalizedMessage id={"title.customer"} />
                </th>
              )}
              <th>
                <LocalizedMessage id={"title.ship.to"} />
              </th>
              <th>
                <LocalizedMessage id={"title.salesperson"} />
              </th>
              <th>
                <LocalizedMessage id={"title.products"} />
              </th>
              <th>
                <LocalizedMessage id={"title.product.code"} />
              </th>
              <th className={"text-right"}>
                <LocalizedMessage id={"title.value"} />
              </th>
              <th>
                <LocalizedMessage id={"title.validity.date"} />
              </th>
              <th>
                <LocalizedMessage id={"title.actions"} />
              </th>
              <th />
            </tr>
          </thead>
          <tbody data-cy="requests-list">
            {ids.map((requestId) => (
              <RequestRow key={requestId} {...{ requestId }} />
            ))}
          </tbody>
        </table>
      )}
      {isMobile &&
        ids.map((id) => <RequestRowMobile key={id} requestId={id} />)}
    </ScrollableForPagination>
  );
};

export default RequestsPageRequestsTable;

const RequestRow: FC<{
  requestId: number;
}> = ({ requestId }) => {
  const history = useHistory();
  const request = useRequestForQuote(requestId) || undefined;
  const value = useRequestForQuoteValue(requestId);
  const productCodes = useRequestForQuoteProductCodes(requestId);
  const {
    status,
    customerCompanyName,
    unreadChatMessages,
    shipToName,
    currency,
    products,
    validityDate,
    accountManagerName,
    shipToAccountManagerName,
  } = request || {};
  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = useIsDraft(status);

  const onOpen = useCallback(
    () => history.push(`${requestsRootPath}${requestId}`),
    [history, requestId],
  );

  const isCustomer = useIsCustomer();

  const isExpired = isRequestExpired(validityDate);

  const managers = useAMUserNameStrings(
    accountManagerName,
    shipToAccountManagerName,
  );

  return (
    <tr
      className={classNames(
        RequestShowUnread(request?.readMark, isExpired) ? "row-unread" : "",
      )}
      onClick={onOpen}
    >
      <td className={"no-wrap"}>{`RQ-${requestId}`}</td>
      <td>
        {isExpired ? (
          "Expired"
        ) : (
          <RequestStatusLabel
            status={status}
            read={request?.readMark}
            isExpired={isExpired}
          />
        )}
      </td>
      {!isCustomer && <td>{customerCompanyName}</td>}
      <td>{shipToName}</td>
      <td>{managers}</td>
      <td>{`${products?.length || 0}`}</td>
      <td>{productCodes}</td>
      <td className={"text-right no-wrap color-orange-1"}>
        {`${currencySymbol} ${formattedNumber(value) || "0"}`}
      </td>
      <td>
        <DateTimeView date={validityDate} />
      </td>
      <td>
        <div className={"flex-row align-center - justify-center"}>
          <RequestArchiveDeleteButtons {...{ request }} />
        </div>
      </td>
      <td>
        {!isDraft && (
          <ChatUnreadBadge unread={unreadChatMessages} />
        )}
      </td>
    </tr>
  );
};

const RequestRowMobile: FC<{
  requestId: number;
}> = ({ requestId }) => {
  const request = useRequestForQuote(requestId) || undefined;
  const isExpired = isRequestExpired(request?.validityDate);
  const value = useRequestForQuoteValue(requestId);
  const history = useHistory();
  const isCustomer = useIsCustomer();
  const currencySymbol = useFindCurrencySymbol(request?.currency);

  const openRQ = () => history.push(`${requestsRootPath}${requestId}`);

  return (
    <div className="p-md flex-col br-bottom-black-10" onClick={openRQ}>
      <div className="flex flex-row justify-between align-center">
        <div>Request {requestId}</div>

        <div className="flex-row gap-sm">
          <div
            className={classNames(
              "border-r-xxs px-xs py-xxs",
              RequestShowUnread(request?.readMark, isExpired)
                ? "bg-blue-10"
                : "bg-black-10",
            )}
          >
            {isExpired ? (
              "Expired"
            ) : (
              <RequestStatusLabel
                status={request?.status}
                read={request?.readMark}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between align-start gap-lg mt-lg">
        {!isCustomer && (
          <div className={"flex-col flex text-left"}>
            <div className={"text-sm color-text-3"}>
              <LocalizedMessage id={"title.company.name"} />
            </div>
            <div className={"text-pre-wrap mt-xxs"}>
              {request?.customerCompanyName || "-"}
            </div>
          </div>
        )}

        <div className={"flex-col flex text-left"}>
          <div className={"text-sm color-text-3"}>
            <LocalizedMessage id={"title.ship.to.name"} />
          </div>
          <div className={"text-pre-wrap mt-xxs"}>
            {request?.shipToName || "-"}
          </div>
        </div>
      </div>
      <div
        className={"flex flex-row justify-between align-center gap-lg mt-lg"}
      >
        <div className={"text-pre-wrap mt-xxs color-orange-1"}>
          {`${currencySymbol} ${formattedNumber(value) || "0"}`}
        </div>
        <RequestArchiveDeleteButtons {...{ request }} />
      </div>
    </div>
  );
};
