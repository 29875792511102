import { FC, useMemo, useState } from "react";
import {
  MetricsCard,
  MetricsHeader,
  MetricStat,
  MetricStatContainer,
} from "~/Pages/Metrics/Components/MetricsCard";
import { useFormData } from "~/Hooks";
import { PERIODS } from "~/Pages/Metrics/Data/MetricsData";
import { Button, FormSelect, Table } from "~/Components/UI";
import dayjs from "dayjs";
import vars from "~/Styles/Vars";
import { BarChart } from "~/Components/Charts/BarChart";
import { Tabs, TabsList, TabsTrigger } from "~/Components/UI/Tabs";
import PriceView from "~/Components/Views/PriceView";
import { CompanyCombobox } from "~/Components/Companies/CompanyCombobox";
import { UserByPermissionCombobox } from "~/Components/Users/UserByPermissionCombobox";
import { GroupPermission, OfferView, TimeSeriesCategoricalMetric } from "~/API";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TbArrowBackUp } from "react-icons/tb";
import { LinearLoader } from "~/Components/Loaders";
import CompanyView from "~/Components/Views/CompanyView";
import { offersRootPath } from "~/Services/Routes";
import { useOffersCreatedMetrics } from "~/Pages/Metrics/Data/OffersMetrics";
import { offerStatusList, useOffers } from "~/Data/Offers";
import { UserName } from "~/Components/Users/UserName";

interface Filters {
  period: PERIODS;
  dataKey: "value" | "count";
  buyer: number | undefined;
  createdBy: number | undefined;
  vendor: number | undefined;
}

export const OffersCreatedMetricsCard: FC = () => {
  const filterForm = useFormData<Filters>({
    period: PERIODS.SIX_MONTHS,
    dataKey: "count",
    buyer: undefined,
    createdBy: undefined,
    vendor: undefined,
  });

  const metrics = useOffersCreatedMetrics({
    period: filterForm.data.period,
    buyer: filterForm.data.buyer,
    createdBy: filterForm.data.createdBy,
    vendor: filterForm.data.vendor,
  });

  const onDataKeyChange = (val: string) =>
    filterForm.onDataChange("dataKey", val);

  const totalPeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.count, 0),
    0,
  );

  const totalValuePeriod = metrics.data?.reduce(
    (prev: number, cur) => prev + cur.data.reduce((p, c) => p + c.value, 0),
    0,
  );

  const [detailsIndex, setDetailsIndex] = useState<number | undefined>();
  const onBarClicked = (_: any, index: number) => setDetailsIndex(index);
  const onBack = () => setDetailsIndex(undefined);

  return (
    <MetricsCard>
      {detailsIndex != undefined && (
        <OffersList
          metrics={metrics.data[detailsIndex]}
          onBack={onBack}
          buyer={filterForm.data.buyer}
          createdBy={filterForm.data.createdBy}
          vendor={filterForm.data.vendor}
        />
      )}
      {detailsIndex == undefined && (
        <>
          <MetricsHeader title="New Offers">
            <UserByPermissionCombobox
              id="buyer"
              formData={filterForm}
              permissions={[GroupPermission.CAN_BE_BUYER]}
              placeholder="title.all.buyers"
              className="w-max-7xl"
              showClear
            />
            <UserByPermissionCombobox
              id="createdBy"
              formData={filterForm}
              permissions={[
                GroupPermission.CAN_BE_BUYER,
                GroupPermission.CAN_BE_VENDOR,
                GroupPermission.CAN_BE_ADMIN,
              ]}
              placeholder="title.created.by"
              className="w-max-7xl"
              showClear
            />
            <CompanyCombobox
              id="vendor"
              formData={filterForm}
              filters={{ query: "TYPE == 'SELLER' && ACTIVE == true" }}
              placeholder="title.all.vendors"
              showClear
              className="w-max-6xl"
            />
            <FormSelect
              id="period"
              formData={filterForm}
              data={Object.values(PERIODS)}
              getLabel={(id) => id}
            />
          </MetricsHeader>
          <MetricStatContainer>
            <MetricStat
              label={`Offers in the last ${filterForm.data.period}`}
              loading={metrics.isLoading}
            >
              {totalPeriod}
            </MetricStat>
            <MetricStat
              label={`Total in the last ${filterForm.data.period}`}
              loading={metrics.isLoading}
            >
              $
              {totalValuePeriod?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MetricStat>
          </MetricStatContainer>
          <BarChart
            dataset={metrics.data || [{ period: "", data: [] }]}
            dataKey={filterForm.data.dataKey}
            xAxisFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMM")
            }
            tooltipLabelFormat={(date: string) =>
              dayjs(date?.slice(0, 10)).format("MMMM YYYY")
            }
            tooltipDataFormat={
              filterForm.data.dataKey == "value"
                ? (val: number) => <PriceView price={val} />
                : undefined
            }
            width="100%"
            height={vars.size["7xl"]}
            loading={metrics.isLoading}
            onbarClicked={onBarClicked}
          />
          <div className="flex-row align-center justify-end">
            <Tabs
              id="dataKey"
              value={filterForm.data.dataKey}
              onValueChange={onDataKeyChange}
            >
              <TabsList>
                <TabsTrigger value="count">Count</TabsTrigger>
                <TabsTrigger value="value">Value</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </>
      )}
    </MetricsCard>
  );
};

const offersHelper = createColumnHelper<OfferView>();

const columns = [
  offersHelper.accessor("id", {
    header: "ID",
    cell: (data) => (
      <a
        href={`${offersRootPath}${data.getValue()}`}
        target="_blank"
        rel="noreferrer noopener"
        className="text-no-wrap"
      >
        O-{data.getValue()}
      </a>
    ),
    size: 32,
  }),
  offersHelper.accessor("vendorCompanyId", {
    header: "Vendor",
    cell: (data) => <CompanyView id={data.getValue()} />,
  }),
  offersHelper.accessor("buyerUserId", {
    header: "Buyer",
    cell: (data) => (
      <div className="text-no-wrap">
        <UserName id={data.getValue()} />
      </div>
    ),
  }),
  offersHelper.accessor("createdByUserAccountId", {
    header: "Created By",
    cell: (data) => (
      <div className="text-no-wrap">
        <UserName id={data.getValue()} />
      </div>
    ),
  }),
];

const OffersList: FC<{
  onBack: () => void;
  buyer: number | undefined;
  createdBy: number | undefined;
  vendor: number | undefined;
  metrics: TimeSeriesCategoricalMetric;
}> = (props) => {
  const dateTo = useMemo(() => {
    return dayjs(props.metrics.period.slice(0, 10))
      .endOf("month")
      .toISOString();
  }, [props.metrics.period]);

  const offers = useOffers({
    vendor: props.vendor || ("" as any),
    buyer: props.buyer || ("" as any),
    createdBy: props.createdBy || ("" as any),
    createdDateFrom: props.metrics.period,
    createdDateTo: dateTo,
    withExpired: true,
    status: offerStatusList,
  });

  const table = useReactTable({
    data: offers.data?.flat() || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onScrollToBottom = () => offers.setSize(offers.size + 1);

  return (
    <div className="px-xs flex-col gap-sm align-start h-9xl overflow-hidden">
      <Button
        variant="text"
        color="gray"
        onClicked={props.onBack}
        leftIcon={<TbArrowBackUp size={16} />}
        label="title.back"
      />
      <div className="text-xl">
        New Offers{" "}
        {dayjs(props.metrics.period?.slice(0, 10)).format("MMMM YYYY")}
      </div>
      <LinearLoader loading={offers.isLoading} />
      <Table
        table={table}
        onScrolledBottom={onScrollToBottom}
        className="w-full"
      />
    </div>
  );
};
