import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LC, ls } from "~/Locales";
import { quotesRootPath } from "~/Services/Routes";
import DashboardEmptyState from "../DashboardEmptyState";
import { QuoteView } from "~/API";
import PriceView from "~/Components/Views/PriceView";
import DateView, { DateTimeView } from "~/Components/Views/DateView";
import { LinearLoader } from "~/Components/Loaders";
import { Button, Card } from "~/Components/UI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  DashboardMobileField,
  DashboardTable,
} from "~/Pages/Dashboard/Components/DashboardTable";
import { useDashboardQuotes, useQuoteValue } from "~/Data/Quotes";
import { QuoteStatusBadge } from "~/Components/Quotes/QuoteStatusBadge";
import {useIsMobile} from "~/Hooks";

const columnHelper = createColumnHelper<QuoteView>();

const columns = [
  columnHelper.accessor("id", {
    header: ls("title.q.id"),
    cell: (data) => <div className="text-semibold">Q-{data.getValue()}</div>,
  }),
  columnHelper.accessor("status", {
    header: ls("title.status"),
    cell: (data) => <QuoteStatusBadge status={data.getValue()} />,
  }),
  columnHelper.accessor("shipToName", {
    header: ls("title.ship.to"),
    cell: (data) => <div className="text-truncate">{data.getValue()}</div>,
  }),
  columnHelper.accessor("products", {
    header: ls("title.products"),
    cell: (data) => <>{data.getValue().length}</>,
  }),
  columnHelper.display({
    header: ls("title.value"),
    cell: (data) => {
      const value = useQuoteValue(data.row.original);
      return (
        <PriceView
          price={value}
          currency={data.row.original.currency}
          decimals={0}
        />
      );
    },
  }),
  columnHelper.accessor("validityDate", {
    header: ls("title.validity.date"),
    cell: (data) => <DateView date={data.getValue()} />,
  }),
];

const DashboardCustomerQuotes: FC = () => {
  const quotes = useDashboardQuotes();
  const history = useHistory();

  const showMobileTable = useIsMobile();

  const table = useReactTable({
    data: quotes.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onShowAll = () => {
    history.push(quotesRootPath);
  };

  const onRowOpen = (row: QuoteView) => {
    history.push(quotesRootPath + row.id);
  };

  const showEmptyState = quotes.data?.length === 0 && !quotes.isLoading;
  const showList = !quotes.isLoading && !showEmptyState;

  return (
    <Card className="flex-col">
      <div className="text-xl mb-lg">
        <LC id="title.my.quotes" />
      </div>

      <LinearLoader loading={quotes.isLoading} />
      {showEmptyState && (
        <DashboardEmptyState
          title="No Quotes."
          description1="Once you have an quote, it will be visible here."
        />
      )}

      {showList && (
        <>
          {!showMobileTable && (
            <DashboardTable table={table} onRowClick={onRowOpen} />
          )}
          {showMobileTable && (
            <div className="flex-col overflow-hidden">
              {quotes.data.map((quote) => (
                <QuoteCellMobile key={quote.id} quote={quote} />
              ))}
            </div>
          )}

          <div className="flex" />
          <Button
            color="gray"
            className="w-full mt-md"
            onClicked={onShowAll}
            label="title.show.all"
          />
        </>
      )}
    </Card>
  );
};

export default DashboardCustomerQuotes;

const QuoteCellMobile = ({ quote }: { quote: QuoteView }) => {
  const history = useHistory();

  const { id, status, currency, validityDate } = quote;
  const value = useQuoteValue(quote);

  const onOpen = useCallback(() => {
    history.push(quotesRootPath + id);
  }, [id, history]);

  return (
    <div className="border-b-1 border-black-10 py-md" onClick={onOpen}>
      <div className="flex-row w-full">
        <div className="text-truncate flex-col flex mr-xxs">
          <div className="text-truncate mt-xxs">Q-{id}</div>
          <br />
        </div>

        <DashboardMobileField label="title.value" className="mr-xxs">
          <PriceView price={value} currency={currency} decimals={0} />
        </DashboardMobileField>

        <div className="text-truncate flex-col mr-xxs">
          <QuoteStatusBadge status={status} />
        </div>
      </div>

      <DashboardMobileField label="title.validity.date">
        <DateTimeView date={validityDate} />
      </DashboardMobileField>
    </div>
  );
};
