import API, {LocationType, LocationView, Order} from "~/API";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import {countriesAndUnions} from "~/Data/Countries";

export interface LocationFilters {
    type?: LocationType,
    query?: string,
    search?: string,
    location?: Array<number>,
    country?: Array<string>,
    port?: Array<number>,
    accountManager?: Array<number>,
    withEmptyAMOnly?: boolean,
    companyId?: Array<number>,
    onlyMy?: boolean,
    inactive?: boolean,
    orderBy?: string,
    order?: Order,
    offset?: number,
    limit?: number
}

const PAGE_SIZE = 15;

export const useLocation = (id: number | undefined | null) =>
    useSWR(
        id == null ? null : ["api/locations", id],
        async () => await API.getLocation({ id }),
    );

const getLocationsKey = (
    filters: LocationFilters,
    offset: number,
    previousPageData: LocationView[] | undefined,
) => {
    if (previousPageData && !previousPageData.length) return null;
    return { route: "api/locations", filters, offset };
};

export const useLocations = (filters: LocationFilters, pageSize?: number) =>
    useSWRInfinite(
        (i, p) => getLocationsKey(filters, i * (pageSize || PAGE_SIZE), p),
        (key) =>
            API.getLocations({
                ...filters,
                offset: key.offset,
                limit: pageSize || PAGE_SIZE,
            }),
    );

export const useCompanyLocations = (id: number) => useSWR(
  ["/api/locations/company"],
  () => API.getCompanyLocations({ id })
);

export const locationAddress = (location: LocationView | undefined) => {
  if (!location) return '';

  const { addressCountry, addressRegion, addressCity, addressStreet, addressZip } = location;
  const countryName = countryCodeToLabel(addressCountry);
  const addressParts = [addressStreet, addressZip, addressCity, addressRegion, countryName].filter(it => !!it);
  return (addressParts.join(', '));
};

export const countryCodeToLabel = (countryCode: string) => {
  const foundCountry = countriesAndUnions.find(country =>
    (country.code.toLowerCase() === countryCode?.toLowerCase?.() || country.code3.toLowerCase() === countryCode?.toLowerCase?.())
  );
  return (foundCountry ? foundCountry.label : '');
};
