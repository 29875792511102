import {
  useCallback,
  useRef,
  useState,
} from "react";
import { isDifferent, isFunction } from "react-ui-basics/Tools";
import {useDeepCompare} from "~/Hooks";

/**
* @deprecated please use new `useToggle` in `~/Hooks`
**/
export const useToggle = (initialValue: boolean = false): [boolean, ()=>void] => {
  const [value, setValue] = useState(initialValue);

  const toggleValue = useCallback(() => setValue(val => !val), []);
  return [value, toggleValue];
}

type MapStateFunction<S> = (prevState: S) => S;
type SetStateAction<S> = S | MapStateFunction<S>;
type Dispatch<A> = (value: A) => void;
type SetStateFunction<T> = Dispatch<SetStateAction<T>>;

const printChanges = <T>(source: T, next: T) => {
  const keys = (source !== null && source !== undefined) ? Object.keys(source) : [];
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (isDifferent(source[key], next[key])) {
      console.log(key, source[key], '!=', next[key]);
    }
  }
};

export const useFormState = <T>(source: T, initialValue = undefined, updateMapper?: (prev: T, next: T) => T): [T, SetStateFunction<T>, boolean] => {
  const [state, setState] = useState<T>(initialValue as T);
  const hasChanges = useRef(false);
  const sourceRef = useRef(source);
  sourceRef.current = source;
  const stateRef = useRef(source);
  stateRef.current = state;

  useDeepCompare((prev) => {
    if (source) {
      let next: any;
      const state = stateRef.current;
      const old = prev && prev[0];
      if (!old) {
        next = { ...source, ...state };
      } else {
        next = { ...state };
        const keys = Object.keys(source);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (isDifferent(source[key], old[key])) {
            next[key] = source[key];
            // console.log('source[key] !== old[key]', key, source[key], old[key])
          }
        }
      }

      if (updateMapper)
        next = updateMapper(state, next);

      // debugger
      hasChanges.current = isDifferent(source, next);

      // console.log('useFormState. source changed: ', source, 'prev source', old, 'new state', next, 'hasChanges: ', hasChanges.current);
      if (hasChanges.current) {
        printChanges(source, next);
      }
      setState(next);
    }
  }, [source]);

  const setStateWithChanges = useCallback<SetStateFunction<T>>((newValue) => {
    if (isFunction(newValue)) {
      setState((prevState) => {
        let next = (newValue as MapStateFunction<T>)(prevState);
        hasChanges.current = isDifferent(next, sourceRef.current);
        // console.log('useFormState.set source', sourceRef.current, 'new state', next, 'hasChanges: ', hasChanges.current);
        if (hasChanges.current) {
          printChanges(sourceRef.current, next);
        }
        return next;
      });
    } else {
      hasChanges.current = isDifferent(newValue, sourceRef.current);
      // console.log('setStateWithChanges new:', newValue, 'source', source, isDifferent(newValue, source));
      // console.log('useFormState.set source', sourceRef.current, 'new state', newValue, 'hasChanges: ', hasChanges.current);
      if (hasChanges.current) {
        printChanges(sourceRef.current, newValue);
      }
      setState(newValue);
    }
  }, []);

  return [state, setStateWithChanges, hasChanges.current];
};

