import {FC} from "react";
import {Card, FormInput} from "~/Components/UI";
import {FormReturn} from "~/Hooks";
import {ProductUfp} from "~/API";
import {LocalizedMessageKey} from "~/Locales";

export const MSRProductDetailsSection: FC<{
  form: FormReturn<Partial<ProductUfp>>;
}> = (props) => {
  return (
    <Card className="flex-col gap-sm">
      <div className="text-lg">MSR Fields</div>

      <div className="flex-row gap-xl flex-wrap">
        <div className="flex flex-col gap-md w-min-8xl">
          <FormInput
            id="msrHarmonizedCode"
            formData={props.form}
            label="title.harmonize.code"
            disabled
          />

          <div className="flex-row gap-sm flex-wrap">
            <FormInput
              id="msrNomThick"
              formData={props.form}
              label="title.nom.thick"
              containerClassName="flex"
              disabled
            />
           <FormInput
              id="msrNomWidth"
              formData={props.form}
              label="title.nom.width"
              containerClassName="flex"
              disabled
            />
          </div>

          <div className="flex-row gap-sm flex-wrap">
            <FormInput
              id="msrActThick"
              formData={props.form}
              label="title.act.thick"
              containerClassName="flex"
              disabled
            />
            <FormInput
              id="msrActWidth"
              formData={props.form}
              label="title.act.width"
              containerClassName="flex"
              disabled
            />
            <FormInput
              id="msrActLength"
              formData={props.form}
              label="title.act.length"
              containerClassName="flex"
              disabled
            />
          </div>

          <CodeDesc
            codeId="msrLengthCode"
            codeLabel="title.length.code"
            descId="msrLengthDesc"
            descLabel="title.length.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrSpeciesCode"
            codeLabel="title.species.code"
            descId="msrSpeciesDesc"
            descLabel="title.species.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrGradeCode"
            codeLabel="title.grade.code"
            descId="msrGradeDesc"
            descLabel="title.grade.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrTypeCode"
            codeLabel="title.type.code"
            descId="msrTypeDesc"
            descLabel="title.type.desc"
            form={props.form}
          />

          <FormInput
            id="msrSizeCode"
            formData={props.form}
            label="title.size.code"
            disabled
          />
          <CodeDesc
            codeId="msrTreatmentCode"
            codeLabel="title.treatment.code"
            descId="msrTreatmentDesc"
            descLabel="title.treatment.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrGroupCode"
            codeLabel="title.group.code"
            descId="msrGroupDesc"
            descLabel="title.group.desc"
            form={props.form}
          />
        </div>
        <div className="flex flex-col gap-md w-min-8xl">
          <CodeDesc
            codeId="msrSurfaceCode"
            codeLabel="title.surface.code"
            descId="msrSurfaceDesc"
            descLabel="title.surface.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrDrynessCode"
            codeLabel="title.dryness.code"
            descId="msrDrynessDesc"
            descLabel="title.dryness.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrFlnThkCode"
            codeLabel="title.fln.thk.code"
            descId="msrFlnThkDesc"
            descLabel="title.fln.thk.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrDepWidCode"
            codeLabel="title.dep.wid.code"
            descId="msrDepWidDesc"
            descLabel="title.dep.wid.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrMoeCode"
            codeLabel="title.moe.code"
            descId="msrMoeDesc"
            descLabel="title.moe.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrConstructionCode"
            codeLabel="title.construction.code"
            descId="msrConstructionDesc"
            descLabel="title.construction.desc"
            form={props.form}
          />

          <FormInput
            id="msrThicknessCode"
            formData={props.form}
            label="title.thickness.code"
            disabled
          />

          <CodeDesc
            codeId="msrCategoryCode"
            codeLabel="title.category.code"
            descId="msrCategoryDesc"
            descLabel="title.category.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrClassCode"
            codeLabel="title.class.code"
            descId="msrClassDesc"
            descLabel="title.class.desc"
            form={props.form}
          />

          <CodeDesc
            codeId="msrTreatingCode"
            codeLabel="title.treating.code"
            descId="msrTreatingDesc"
            descLabel="title.treating.desc"
            form={props.form}
          />
        </div>
      </div>
    </Card>
  );
};

const CodeDesc: FC<{
  codeId: keyof
  ProductUfp & string;
  codeLabel: LocalizedMessageKey;
  descId: keyof
  ProductUfp & string;
  descLabel: LocalizedMessageKey;
  form: FormReturn<Partial<ProductUfp>>;
}> = (props) => (
  <div className="flex-row gap-sm flex-wrap">
    <FormInput
      id={props.codeId}
      formData={props.form}
      label={props.codeLabel}
      containerClassName="w-5xl"
      disabled
    />
    <FormInput
      id={props.descId}
      formData={props.form}
      label={props.descLabel}
      containerClassName="flex"
      disabled
    />
  </div>
);
