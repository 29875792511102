import {ToggleProps} from "~/Hooks";
import {FC, ReactNode} from "react";
import RightPanel from "~/Components/Layout/RightPanel";
import {Button} from "~/Components/UI";
import {TbFilterFilled, TbSquareXFilled} from "react-icons/tb";
import {LC} from "~/Locales";

export const FilterPanel: FC<{
  open: ToggleProps;
  children: ReactNode;
}> = (props) => {
  return (
    <RightPanel visible={props.open.value} className="rounded-xxs p-md w-min-min">
      <div className="flex-row align-center justify-between gap-sm mb-md">
        <div className="flex-row align-center text-xl gap-sm">
          <TbFilterFilled size={18} className="bg-black-11 color-black-7 p-xxs rounded-xxs" />
          <LC id="title.filter"/>
        </div>
        <Button
          color="gray"
          variant="text"
          leftIcon={<TbSquareXFilled size={24}/>}
          onClicked={props.open.toggleFalse}
        />
      </div>

      <div className="flex-col gap-md">
        {props.children}
      </div>
    </RightPanel>
  )
}