import {FC, ReactNode} from "react";
import { LC, LocalizedMessageKey } from "~/Locales";
import {Button, Case, Match, Show} from "~/Components/UI";
import BackButton from "~/Components/Nav/BackButton";
import {TbMenu2} from "react-icons/tb";
import {useAppDispatch} from "~/StoreTypes";
import {openLeftMenu} from "~/Reducers/LeftMenu";
import {NotificationButton} from "~/Components/Notifications/NotificationButton";

export const PageTitle: FC<{
  type: "menu" | "back";
  parentUrl?: string;
  onBack?: () => void;
  title?: LocalizedMessageKey;
  titleComp?: ReactNode;
}> = (props) => {
  const dispatch = useAppDispatch();

  const onLeftMenuClicked = () => dispatch(openLeftMenu());

  return (
    <div className="flex-row justify-between gap-xs mx-sm mb-xs align-center">
      <div className="flex-row">
        <Match on={props.type}>
          <Case v="menu">
            <Button
              color="gray"
              variant="text"
              className="desktop-hide"
              onClicked={onLeftMenuClicked}
              leftIcon={<TbMenu2 size={20}/>}
            />
          </Case>
          <Case v="back">
            <BackButton parentUrl={props.parentUrl || ""} onBack={props.onBack} />
          </Case>
        </Match>

        <Show when={props.title}>
          <div className="text-xl ml-lg" data-cy="page-title">
            <LC id={props.title} />
          </div>
        </Show>
        <Show when={props.titleComp}>
          <div className="ml-lg" data-cy="page-title-comp">
            {props.titleComp}
          </div>
        </Show>
      </div>

      <NotificationButton />
    </div>
  );
}
