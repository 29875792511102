import { useToggle } from "~/Hooks/Old";
import { useCallback } from "react";
import Button from "~/Components/Old/Button";
import { LocalizedMessage } from "~/Locales";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { SaveOfferCallbackType } from "../OfferDetailsButtonsPanel";
import { OfferStatus } from "~/API";
import { useHistory } from "react-router-dom";
import { requestedOffersRootPath } from "~/Services/Routes";
import Toast from "~/Components/Toast";


interface SendToVendorButtonProps {
  isOfferValid: () => boolean;
  saveOffer: SaveOfferCallbackType;
  offerId: number;
}

export const SendToVendorButton = ({ isOfferValid, saveOffer, offerId }: SendToVendorButtonProps) => {
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();
  const history = useHistory();

  const onClick = useCallback(() => {
    const valid = isOfferValid();
    if (!valid) return;

    toggleConfirmSubmitDialog();
  }, [isOfferValid, toggleConfirmSubmitDialog]);

  const confirmOfferSubmit = useCallback(async () => {
    toggleConfirmSubmitDialog();

    await saveOffer(OfferStatus.RFO_SENT);
    Toast.Success(`Offer ${offerId} sent to Vendor`);
    history.push(`${requestedOffersRootPath}`);
  }, [toggleConfirmSubmitDialog, saveOffer, offerId, history]);

  return <>
    <Button onClick={onClick} data-cy={"SendToVendorButton"}><LocalizedMessage id={'title.send.to.vendor'} /></Button>

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Send Request to Vendor'}
        description={'Do you want to send this Request to the Vendor?'}
        description2={''}
        confirmTitle={<LocalizedMessage id={'title.send.request'} />}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={confirmOfferSubmit}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>
}

