import Button from "./Button";
import IconButton from "./IconButton";
import { CloseIcon } from './Icons';
import './ConfirmationDialog.scss';
import { classNames } from "react-ui-basics/Tools";

export interface ConfirmationDialogProps {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  description2?: string;
  confirmTitle: string | JSX.Element;
  closeTitle: string | JSX.Element;
  onConfirm?: () => void;
  onDiscard?: () => void;
  onClose?: () => void;
  children?: any;
  className?: string;
  disableConfirmButton?: boolean;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    description,
    description2,
    confirmTitle,
    closeTitle,
    onConfirm,
    onDiscard,
    onClose,
    children,
    className,
    disableConfirmButton,
  } = props;

  const divStyle = classNames('ConfirmationDialogMainContainer', className);
  return (
    <div className={divStyle} onClick={(event => event.stopPropagation())}>
      <div className={'ScrollContainer'}>
        <div className={'DialogContainer'}>
          <div className="flex-row align-center">
            <div className={'text-bold text-xl'}>{title}</div>

            <Button className={'CloseIcon'} onClick={onClose}>
              <CloseIcon size={16} />
            </Button>
          </div>

          {description && <div className={'text-lg mt-xl text-left'}>{description}</div>}
          {description2 && <div className={'text-lg text-left'}>{description2}</div>}
          
          <div className={'mt-sm mb-sm'}>
            {children}
          </div>
          
          {(!!onConfirm || !!onDiscard) &&
            <div className={'ButtonsContainer mt-xl'}>
              <Button view={'secondary'} onClick={onDiscard || onClose}>{closeTitle}</Button>
              <Button onClick={onConfirm} data-cy={"ConfirmButton"}
                disabled={disableConfirmButton}
              >{confirmTitle}</Button>
            </div>
          }
        </div>
      </div>
      
      <div className={'DialogContainerMobile'}>
        <div className={'ModalTitleContainer'}>
          <div className={'text-xl'}>{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon size={24} />
          </IconButton>
        </div>
        
        <div className={'ScrollContainerMobile'}>
          {description && <div className={'text-lg'}>{description}</div>}
          {description2 && <div className={'text-lg'}>{description2}</div>}
          
          <div className={'mt-sm mb-sm'}>
            {children}
          </div>
          
          
          {(onConfirm || onDiscard) &&
            <div className={'mt-xl'}>
              <Button className={'w-full'}
                onClick={onConfirm}
                disabled={disableConfirmButton}
              >{confirmTitle}</Button>
              <Button view={'secondary'}
                className={'mt-md w-full'}
                onClick={onDiscard || onClose}
              >
                {closeTitle}
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
