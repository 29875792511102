import { FC, ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Vars from "./Vars";
import vars from "./Vars";

const MUITheme: FC<{ children: ReactNode }> = (props) => {
  // @ts-ignore
  // @ts-ignore
  const theme = createTheme({
    typography: {
      fontFamily: '"DM Sans", sans-serif',
    },
    palette: {
      primary: {
        main: Vars.colors.blue1,
        dark: Vars.colors.blue2,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiTooltip: {
        styleOverrides: {
        tooltip: {
          backgroundColor: vars.colors.black1
        }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #D2D2D6",
            overflow: "auto",
            height: "38px",
            minHeight: "38px",
          },
          scroller: { marginBottom: "-1px", overflowY: "hidden" },
          indicator: { backgroundColor: "#3F87DE", height: "1px" },
        },
      },
      MuiTab: {
        variants: [
          {
            //@ts-ignore
            props: { variant: "details" },
            style: {
              minWidth: 0,
              color: "#1c1f31",
              opacity: 1,
              padding: "0px 12px",
              height: "38px",
              minHeight: "38px",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "bold",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              "&.MuiButtonBase-root": {
                backgroundColor: "#F7F9F9",
              },
              "&:hover": {
                opacity: 1,
              },
              "&.Mui-selected": {
                color: "#3f87de",
                backgroundColor: "#ffffff",
              },
            },
          },
        ],
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            boxShadow: `0 ${Vars.size.xxs}px ${Vars.size.xxs}px rgba(0, 0, 0, 0.25);`,
            border: "1px solid #e1e1e1;",
            borderRadius: Vars.size.xxs,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            borderRadius: Vars.size.xxs,

            "&:hover": {
              backgroundColor: Vars.colors.blue9,
            },
          },
          today: {
            borderStyle: "none !important",
            backgroundColor: Vars.colors.blue10,
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            borderRadius: Vars.size.xxs,
            fontSize: Vars.fontSize.md,

            "&:hover": {
              backgroundColor: Vars.colors.blue9,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            minWidth: "128px",
          },
          input: {
            fontSize: Vars.fontSize.md,
            lineHeight: `${Vars.lineHeight.md}px`,
            padding: `0 ${Vars.size.xxs}px`,
            "::placeholder": {
              color: Vars.colors.black5,
              opacity: 1,
            },
          },
          tag: {
            borderRadius: Vars.size.xxs,
            lineHeight: `${Vars.lineHeight.sm}px`,
            padding: `${Vars.size.xxs}px`,
            margin: ` ${Vars.size.xxs}px`,
            height: "unset",
            "& svg": {
              height: `${Vars.size.md}px`,
              width: `${Vars.size.md}px`,
            },
          },
          inputRoot: {
            padding: `0 ${Vars.size.xxs}px`,
            ":disabled": {
              backgroundColor: "black !important",
            },
          },
          popper: {
            boxShadow: `0 ${Vars.size.xxs}px ${Vars.size.xxs}px rgba(0, 0, 0, 0.25);`,
            border: "1px solid #e1e1e1;",
            borderRadius: `${Vars.size.xxs}px`,
            minWidth: Vars.size["6xl"],
          },
          listbox: {
            fontSize: Vars.fontSize.md,
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
export default MUITheme;
