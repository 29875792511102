import { useState, useEffect } from "react";

const SquareImage = (props) => {
  const { src, size } = props
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setWidth(image.width);
      setHeight(image.height);
    };
    image.src = src;
  }, [src]);

  if (!width || !height)
    return null;

  let ratio = width / height;

  let imageStyle = {
    position: 'relative',
  };
  if (height > width) {
    let h = size / ratio;
    let offset = size / 2 - h / 2;
    imageStyle.top = `${offset}px`;
    imageStyle.width = `${size}px`;
  } else {
    let w = size * ratio;
    let offset = size / 2 - w / 2;
    imageStyle.left = `${offset}px`;
    imageStyle.height = `${size}px`;
  }

  return <div style={{
    maxWidth: `${size}px`,
    minWidth: `${size}px`,
    maxHeight: `${size}px`,
    minHeight: `${size}px`,
    overflow: 'hidden',
    borderRadius: '4px',
  }}>
    <img {...props} src={src} style={imageStyle} alt=""/>
  </div>;
};

export default SquareImage;