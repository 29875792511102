import { FC, Fragment, useMemo, useState } from "react";
import {
  Button,
  Card,
  Checkbox, editControlsColumn,
  FormInput,
  Table,
} from "~/Components/UI";
import { ProductTypeCombobox } from "~/Components/Products/ProductTypeCombobox";
import {params, useDebounce, useFormData, useMutate} from "~/Hooks";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import API, {
  MyProduct,
  OfferStatus,
  ProductV2ProductType,
  RequestForQuoteStatus,
} from "~/API";
import { ls } from "~/Locales";
import useSWR from "swr";
import { LinearLoader } from "~/Components/Loaders";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import {useCurrentUser, useIsCustomer, useIsInternalUser, useIsVendor} from "~/Reducers/User";
import { UserLocationsCombobox } from "~/Components/Locations/UserLocationCombobox";
import { locationAddress, useLocation } from "~/Data/Locations";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { offersRootPath, requestsRootPath } from "~/Services/Routes";
import { UOMComboboxForm } from "~/Components/Comboboxes/UOMCombobox";
import { ProductType } from "~/Reducers/Products";
import { LengthByProductGroupCombobox } from "~/Components/Comboboxes/LengthByProductGroupCombobox";
import { TbTrash } from "react-icons/tb";
import { DeletePopover } from "~/Components/DeletePopover";
import { AddMyProductDialog } from "~/Pages/Products/MyProductsPage/AddMyProductDialog";
import {UnsavedChangesDialog} from "~/Components/Nav/UnsavedChangesDialog";
import {ProductCatalogueDescriptionLink} from "~/Components/Products/ProductCatalogueDescriptionLink";
import * as z from "zod";
import {useFormRowEdit} from "~/Hooks/useFormRowEdit";
import {trackEvent} from "~/Services/Tracking";

const routeParser = z.object({
  search: params.string,
  productType: params.string,
  shipTo: params.string,
});

export const MyProductsTab: FC<{
  type: "page" | "location" | "company";
  locationId?: number;
  companyId?: number;
}> = (props) => {
  const currentUser = useCurrentUser();
  const isCustomer = useIsCustomer();
  const isVendor = useIsVendor();
  const showAsRequest = props.type == "location" || isCustomer;
  const history = useHistory();

  const filters = useFormData<{
    search?: string,
    productType?: string,
    shipTo?: string
  }>(
    {
      search: "",
      productType: "",
      shipTo: ""
    },
    { routeParser }
  );
  const searchDebounce = useDebounce(filters.data.search);

  const myProducts = useSWR(
    ["api/my/products", searchDebounce, filters.data.productType, filters.data.shipTo, props.type],
    () => {
      if (props.type == "page") {
        if(isVendor || filters.data.shipTo == "")
          return API.getMyProducts({
            search: searchDebounce,
            query: (filters.data.productType
              ? `PRODUCT_TYPE == '${filters.data.productType}'`
              : ""),
            limit: 1000,
          });

        return API.getMyProductsByLocation({
          locationId: +filters.data.shipTo,
          search: searchDebounce,
          query: (filters.data.productType
            ? `PRODUCT_TYPE == '${filters.data.productType}'`
            : ""),
          limit: 1000,
        });
      }

      if (props.type == "company")
        return API.getCompanyProducts({
          companyId: props.companyId || 0,
          search: searchDebounce,
          query: filters.data.productType
            ? `PRODUCT_TYPE == '${filters.data.productType}'`
            : "",
          limit: 1000,
        });

      // location
      return API.getCompanyProductsByLocation({
        locationId: props.locationId || +filters.data.shipTo,
        search: searchDebounce,
        query: filters.data.productType
          ? `PRODUCT_TYPE == '${filters.data.productType}'`
          : "",
        limit: 1000,
      });
    },
  );

  const [selectedIds, setSelectedIds] = useState([]);
  const columns = useMemo(
    () => getColumns(selectedIds, setSelectedIds, myProducts.mutate),
    [selectedIds, setSelectedIds],
  );
  const data = useMemo(() => myProducts.data || [], [myProducts.data]);

  const clearSelected = () => setSelectedIds([]);

  const editRow = useFormRowEdit<MyProduct>("id", async (data) => {
    await API.updateMyProduct(
      { id: data.id },
      {
        productId: data.productId,
        uom: data.uom,
        lengthIds: data.lengthIds,
      },
    );
    await myProducts.mutate();
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editRow,
    },
    initialState: {
      columnPinning: {
        left: ["select"],
        right: ["editControls", "delete"],
      },
    },
  });

  const shipToId =
    props.type == "location"
      ? props.locationId
      : isCustomer && currentUser.locations.length === 1
        ? currentUser.locations[0]
        : null;
  const shipTo = useLocation(shipToId);

  const customerCompanyId =
    props.type == "location"
      ? shipTo.data?.companyId
      : isCustomer
        ? currentUser.companyId
        : null;

  const vendorCompanyId =
    props.type == "company"
      ? props.companyId
      : isVendor
        ? currentUser.companyId
        : null;

  const onCreate = useMutate(async () => {
    if (showAsRequest) {
      const createdRequest = await API.createRequestForQuote(
        {},
        {
          customerCompanyId,
          shipToLocationId: shipToId,
          incoterm: shipTo.data?.incoterm || "",
          incotermAddressDestination: locationAddress(shipTo.data),
          incotermPortIdDestination: shipTo.data?.portId,
          externalComment: "",
          internalComment: "",
          validityDate: dayjs().add(7, "day").endOf("day").toISOString(),
          expectedDeliveryDate: null,
          status: isCustomer
            ? RequestForQuoteStatus.DRAFT_CUSTOMER
            : RequestForQuoteStatus.DRAFT_AM,
          currency: "USD",
          customerContactUserAccountIds: shipTo.data?.users || [],
          quotes: [],
          offers: [],
          products: selectedIds.map((pId) => {
            const product = myProducts.data.find((p) => p.productId == pId);
            const price = product.price || product.productGroupPrice;
            return {
              ...product,
              price,
              active: true,
              cost: price,
              margin: 0,
              quantity: 0,
            };
          }),
        },
      );
      history.push(`${requestsRootPath}${createdRequest.id}/edit`);
    } else {
      const createdOffer = await API.createOffer(
        {},
        {
          vendorCompanyId,
          shipFromLocationId: null,
          incoterm: "",
          incotermAddressDestination: "",
          incotermPortIdDestination: null,
          validityDate: null,
          dateIncoDestination: null,
          externalComment: "",
          internalComment: "",
          status: OfferStatus.O_DRAFT,
          currency: "USD",
          requestForQuoteId: null,
          quotes: [],
          products: selectedIds.map((pId) => {
            const product = myProducts.data.find((p) => p.productId == pId);
            const price = product.price || product.productGroupPrice;
            return {
              productId: product.productId,
              uom: product.uom,
              lengthIds: product.lengthIds,
              price,
              active: true,
              cost: price,
              margin: 0,
              quantity: 0,
            };
          }),
        },
      );
      history.push(`${offersRootPath}${createdOffer.id}/edit`);
    }

    const entityName = showAsRequest ? "Request for Quote" : "Offer";
    const pageName = props.type == "page" ? "My Products" : (
      props.type == "company" ? "Company My Products" : "Location My Products"
    );
    trackEvent(`Created ${entityName} from ${pageName} Page`);
  });

  return (
    <>
      <Card className="flex flex-col gap-sm overflow-hidden text-left">
        <div className="flex-row align-center gap-md">
          {props.type != "page" && (
            <AddMyProductDialog
              refreshData={myProducts.mutate}
              companyId={props.companyId}
              locationId={props.locationId}
            />
          )}
          <FormInput
            id="search"
            formData={filters}
            type="search"
            showClear
            placeholder="placeholder.search"
            className="desktop-w-min-8xl"
          />
          {props.type == "page" && isCustomer && (
            <UserLocationsCombobox
              id="shipTo"
              placeholder="title.ship.tos"
              showClear
              clearValue={""}
              formData={filters}
            />
          )}
          <ProductTypeCombobox
            id="productType"
            formData={filters}
            placeholder="title.product.type"
            clearValue=""
            showClear
            className="desktop-w-min-6xl"
          />
        </div>

        <LinearLoader loading={myProducts.isValidating} />
        <Table table={table} />
        {editRow.rowIndex != null && <UnsavedChangesDialog onSave={editRow.submit.mutate} />}
      </Card>
      <BottomButtonsPanel>
        {selectedIds.length != 0 && (
          <Button
            label="title.clear.selection"
            color="gray"
            variant="text"
            onClicked={clearSelected}
          />
        )}
        <Button
          onClicked={onCreate.mutate}
          loading={onCreate.loading}
          disabled={selectedIds.length == 0}
          label={showAsRequest ? "title.create.request" : "title.create.offer"}
        />
      </BottomButtonsPanel>
    </>
  );
};

const columnHelper = createColumnHelper<MyProduct>();

const getColumns = (
  selectedIds: number[],
  setSelectedIds: (val: number[]) => void,
  refreshData: () => void,
) => [
  columnHelper.display({
    id: "select",
    cellMemo: ({ row }) => (
      <Checkbox
        value={selectedIds.includes(row.productId)}
        onChange={(val) => {
          if (val) {
            setSelectedIds([...selectedIds, row.productId]);
          } else {
            setSelectedIds(
              selectedIds.filter((i) => i != row.productId),
            );
          }
        }}
      />
    ),
    size: 0,
  }),
  columnHelper.accessor("productCode", {
    header: ls("title.code"),
    size: 0,
  }),
  columnHelper.accessor("description", {
    header: ls("title.description"),
    cellMemo: ({ row }) => (
      <ProductCatalogueDescriptionLink
        description={row.description}
        productCode={row.productCode}
        hasSyndigo={row.hasSyndigo}
        productId={row.productId}
      />
    ),
    size: 250,
  }),
  columnHelper.accessor("productGroup", {
    header: ls("title.group"),
    size: 400,
  }),
  columnHelper.display({
    id: "lengths",
    header: ls("title.lengths"),
    cellMemo: ({ row }) => (
      <div>
        {row.lengthValues.map((l, i) => (
          <Fragment key={l}>
            {i != 0 && ", "}
            {l}
          </Fragment>
        ))}
        {row.lengthValues.length == 0 && "-"}
      </div>
    ),
    editCell: ({ editRow }) => (
      (
        editRow.form.data.productType === ProductV2ProductType.LUMBER ||
        editRow.form.data.productType === ProductV2ProductType.LINEAR_FOOTAGE
      ) ?
        <LengthByProductGroupCombobox
          groupId={editRow.form.data?.productGroupId}
          id="lengthIds"
          formData={editRow.form}
          className="w-min-4xl"
          multiple
          clearValue={[]}
          showClear
        />
        : "-"
    ),
  }),
  columnHelper.accessor("uom", {
    header: ls("title.uom"),
    size: 0,
    editCell: ({ editRow }) => (
      <UOMComboboxForm
        id="uom"
        formData={editRow.form}
        className="w-min-4xl"
        productType={
          editRow.form.data?.productType?.valueOf() as ProductType
        }
      />
    ),
  }),
  editControlsColumn(columnHelper),
  columnHelper.display({
    id: "delete",
    header: "",
    size: 24,
    cell: (data) => {
      const deleteProduct = useMutate(async () => {
        await API.deleteCompanyProducts({ id: data.row.original.id });
      });
      const isInternalUser = useIsInternalUser();

      return (
        <div className="flex-row gap-xxs">
          { isInternalUser &&
            <DeletePopover
              align="end"
              deleteCall={() => deleteProduct.mutate().then(refreshData)}
              trigger={
                <Button
                  color="red"
                  leftIcon={<TbTrash size={16} />}
                  variant="text"
                />
              }
            >
              Are you sure you want to delete "{data.row.original.description}"?
            </DeletePopover>
          }
        </div>
      );
    },
  }),
];
