import RBCheckbox from 'react-ui-basics/Checkbox';
import './Checkbox.scss';


/**
 * @Deprecated please use `~/Components/UI/Checkbox`
**/
const Checkbox = (props) => {
  return (
    <RBCheckbox {...props} />
  );
};

export default Checkbox;
