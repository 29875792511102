import { FC, useEffect, useMemo } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import API, { OfferProductView, OfferView } from "~/API";
import { LC, ls } from "~/Locales";
import {
  Button,
  Checkbox,
  Combobox,
  Input,
  Table,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/Components/UI";
import useSWR from "swr";
import { LinearLoader } from "~/Components/Loaders";
import DateView from "~/Components/Views/DateView";
import {
  useIncotermKeys,
  useIncotermLabels,
  useIncotermUsesAddress,
} from "~/Reducers/Incoterms";
import { DateInput } from "~/Components/UI/Forms/DateInput";
import { PortCombobox } from "~/Components/Ports/PortCombobox";
import { useFormData } from "~/Hooks";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import {
  locationContainerTotal,
  locationPriceTotal,
  matchingQuotesLength,
  OfferMatchingLocation,
  setMatchingLocationField,
  setMatchingLocations,
  setMatchingOffer,
  setMatchingOnly,
  setMatchingProducts,
  toggleSelectedLocation,
  unselectMatchingProducts,
} from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingReducer";
import PriceView from "~/Components/Views/PriceView";
import { TbAlertCircle, TbChevronDown, TbChevronRight } from "react-icons/tb";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import Toast from "~/Components/Toast";
import { OfferMatchingProductTable } from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingProductTable";

export const OfferMatchingTable: FC<{
  offerId: number;
  offer: OfferView;
  inMyProducts: boolean;
  offerProducts: OfferProductView[];
  showSendQuotesDialog: boolean;
  toggleShowSendQuotesDialog: () => void;
  onQuotesCreated: () => void;
}> = (props) => {
  const dispatch = useAppDispatch();

  const matchingShipTos = useSWR(
    ["api/offer/matching/shipTos", props.offerId, props.inMyProducts],
    () =>
      API.getOfferMatchingShipTos({
        id: props.offerId,
        inMyProducts: props.inMyProducts,
      }),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    dispatch(setMatchingOnly(props.inMyProducts));
  }, [props.inMyProducts]);

  useEffect(() => {
    dispatch(setMatchingProducts(props.offerProducts));
  }, [props.offerProducts]);

  useEffect(() => {
    dispatch(setMatchingOffer(props.offer));
    dispatch(
      setMatchingLocations({
        rows: matchingShipTos.data || [],
        offer: props.offer,
      }),
    );
  }, [props.offer, matchingShipTos.data]);

  const quoteProducts = useAppSelector(
    (state) => state.offerMatchingState.products,
  );
  const quoteLocations = useAppSelector(
    (state) => state.offerMatchingState.locations,
  );
  const matchingQuotes = useAppSelector(matchingQuotesLength);

  const onConfirmSendQuotes = async () => {
    const requestQuotes = quoteLocations
      .filter((l) => l.products.length > 0)
      .map((l) => ({
      accountManagerUserId: l.model.accountManagerUserId,
      customerCompanyId: l.model.companyId,
      shipToLocationId: l.model.locationId,
      incoterm: l.incoterm,
      incotermAddressDestination: l.model.address,
      incotermPortIdDestination: l.portId,
      expectedDeliveryDate: l.deliveryDate,
      products: l.products.map((productId) => {
        const product = quoteProducts.find((p) => p.id == productId);
        return {
          productId: product.model.productId,
          uom: product.uom,
          lengthIds: product.model.lengthIds,
          quantity: product.quantity ? Number(product.quantity) : 0,
          price: product.sellPrice,
          cost: product.model.cost,
          margin: 0,
          currency: props.offer.currency,
          active: true,
        };
      }),
    }));

    try {
      const quotes = await API.createQuotesFromOffer(
        { id: Number(props.offerId) },
        requestQuotes,
      );
      Toast.Success(`${quotes.length} quotes sent successfully`);
      dispatch(unselectMatchingProducts());
      props.onQuotesCreated();
    } catch (error) {
      Toast.ApiError(error);
    } finally {
      props.toggleShowSendQuotesDialog();
    }
  };

  const columns = useMemo(() => getColumns(), []);

  const table = useReactTable({
    columns,
    data: quoteLocations,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      columnPinning: {
        left: ["selected", "expander"],
      },
    },
  });

  return (
    <>
      <LinearLoader loading={matchingShipTos.isValidating} />
      <Table table={table} renderSubComponent={OfferMatchingProductTable} className="h-max-12xl" />
      {props.showSendQuotesDialog && (
        <ConfirmationDialog
          title={"Send Quotes"}
          description={`Do you want to send your quotes to ${matchingQuotes} customers?`}
          description2={""}
          confirmTitle={"Send Quotes"}
          closeTitle={<LC id={"title.cancel"} />}
          onConfirm={onConfirmSendQuotes}
          onClose={props.toggleShowSendQuotesDialog}
        />
      )}
    </>
  );
};

const columnHelper = createColumnHelper<OfferMatchingLocation>();

const getColumns = () => [
  columnHelper.accessor("selected", {
    header: "",
    size: 56,
    cellMemo: ({ value, row }) => {
      const dispatch = useAppDispatch();
      const onChange = () => dispatch(toggleSelectedLocation(row.id));

      return <Checkbox value={value} onChange={onChange} />;
    },
  }),
  columnHelper.display({
    id: "expander",
    size: 56,
    cell: (data) => (
      <Button
        color="gray"
        variant="text"
        leftIcon={
          data.row.getIsExpanded() ? (
            <TbChevronDown size={16} />
          ) : (
            <TbChevronRight size={16} />
          )
        }
        onClicked={() => data.row.toggleExpanded()}
      />
    ),
  }),
  columnHelper.accessor("model.companyCode", {
    id: "companyCode",
    header: ls("title.code"),
    size: 72,
  }),
  columnHelper.accessor("model.companyName", {
    header: ls("title.company.name"),
    minSize: 200,
  }),
  columnHelper.accessor("model.locationName", {
    header: ls("title.ship.to"),
    minSize: 200,
  }),
  columnHelper.accessor("incoterm", {
    header: ls("title.incoterm.-.discharge"),
    cellMemo: ({ value, row }) => {
      const dispatch = useAppDispatch();
      const keys = useIncotermKeys();
      const labels = useIncotermLabels();

      const onChange = (value: any) => {
        dispatch(
          setMatchingLocationField({
            id: row.id,
            fieldId: "incoterm",
            value: value,
          }),
        );
      };

      return (
        <Combobox
          id="incoterm"
          value={value}
          setValue={onChange}
          data={keys}
          getLabel={(l) => labels[l]}
        />
      );
    },
  }),
  columnHelper.display({
    id: "destination",
    header: ls("title.port.of.discharge"),
    minSize: 200,
    cellMemo: ({ row }) => {
      const dispatch = useAppDispatch();
      const canShowAddressField = useIncotermUsesAddress(row.incoterm);
      const form = useFormData(
        { portId: row.portId },
        {
          onChange: (_oldData, newData) => {
            dispatch(
              setMatchingLocationField({
                id: row.id,
                fieldId: "portId",
                value: newData.portId,
              }),
            );
            return newData;
          },
        },
      );

      const onDestinationChange = (evt: any) => {
        dispatch(
          setMatchingLocationField({
            id: row.id,
            fieldId: "destination",
            value: evt.target.value,
          }),
        );
      };

      if (canShowAddressField)
        return (
          <Input
            id="destination"
            value={row.destination}
            onChange={onDestinationChange}
          />
        );

      return <PortCombobox id="portId" formData={form} />;
    },
  }),
  columnHelper.display({
    id: "deliveryDate",
    header: ls("title.expected.delivery.date"),
    cellMemo: ({ row }) => {
      const dispatch = useAppDispatch();

      const onDateChange = (evt: any) => {
        dispatch(
          setMatchingLocationField({
            id: row.id,
            fieldId: "deliveryDate",
            value: evt.target.value,
          }),
        );
      };

      return (
        <div className="flex-row gap-xxs align-center">
          {row.model.transitTimeDays == undefined && (
            <Tooltip>
              <TooltipTrigger>
                <div className="color-orange-1">
                  <TbAlertCircle size={20} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                Transit time for logistics is missing! Please add the relevant
                transit time.
              </TooltipContent>
            </Tooltip>
          )}
          <DateInput value={row.deliveryDate} onChange={onDateChange} />
        </div>
      );
    },
  }),
  columnHelper.accessor("containers", {
    header: ls("title.containers"),
    cellMemo: ({ row }) => {
      return useAppSelector((state) => locationContainerTotal(state, row.id));
    },
  }),
  columnHelper.accessor("quoteValue", {
    header: ls("title.quote.value"),
    cellMemo: ({ row }) => {
      const total = useAppSelector((state) =>
        locationPriceTotal(state, row.id),
      );
      return <PriceView price={total} />;
    },
  }),
  columnHelper.accessor("model.matchedProducts", {
    header: ls("title.matched"),
    size: 0,
    cellMemo: ({ value }) => value?.length || 0,
  }),
  columnHelper.accessor("model.lastQuoteReceived", {
    header: ls("title.last.quote.received"),
    size: 0,
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
  columnHelper.accessor("model.lastOrder", {
    header: ls("title.last.order"),
    size: 0,
    cellMemo: ({ value }) => <DateView date={value} />,
  }),
];
