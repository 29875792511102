import API, {SeaFreightCostView} from "~/API";
import {composedItems} from './Utils/Compose';
import {useGetEntity} from "./Utils/Entity";
import {AppDispatch, RootState} from "~/StoreTypes";

export const ACTIONS_GET_SEA_FREIGHT_COSTS_START = 'ACTIONS_GET_SEA_FREIGHT_COSTS_START';
export const ACTIONS_GET_SEA_FREIGHT_COSTS_SUCCESS = 'ACTIONS_GET_SEA_FREIGHT_COSTS_SUCCESS';
export const ACTIONS_GET_SEA_FREIGHT_COSTS_ERROR = 'ACTIONS_GET_SEA_FREIGHT_COSTS_ERROR';
export const ACTIONS_UPDATE_SEA_FREIGHT_COST_SUCCESS = 'ACTIONS_UPDATE_SEA_FREIGHT_COST_SUCCESS';
export const ACTIONS_CREATE_SEA_FREIGHT_COST_SUCCESS = 'ACTIONS_CREATE_SEA_FREIGHT_COST_SUCCESS';
export const ACTIONS_GET_SEA_FREIGHT_COST_SUCCESS = 'ACTIONS_GET_SEA_FREIGHT_COST_SUCCESS';

const seaFreightCostsSelectorCreator = (id: number) => (state: RootState) => state.seaFreightCosts[id];

export const useSeaFreightCost = (id: number, force = false) => useGetEntity(id, getSeaFreightCost, seaFreightCostsSelectorCreator, force);

const getSeaFreightCost = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const seaFreightCost = await API.getSeaFreightCost({ id });
    dispatch({ type: ACTIONS_GET_SEA_FREIGHT_COST_SUCCESS, seaFreightCost });
    return seaFreightCost;
  } catch (error) {
  }
};

export type SeaFreightCostsState = {
  [id: number]: SeaFreightCostView;
};

const INITIAL_STATE = {};

const reducer = (state: SeaFreightCostsState, action: any): SeaFreightCostsState => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case ACTIONS_GET_SEA_FREIGHT_COSTS_SUCCESS:
      return { ...state, ...composedItems(action.seaFreightCosts) };

    case ACTIONS_GET_SEA_FREIGHT_COST_SUCCESS:
    case ACTIONS_UPDATE_SEA_FREIGHT_COST_SUCCESS:
    case ACTIONS_CREATE_SEA_FREIGHT_COST_SUCCESS:
      return {
        ...state,
        [action.seaFreightCost.id]: {
          ...state[action.seaFreightCost.id],
          ...action.seaFreightCost
        }
      };

    default:
      return state;
  }
};

export default reducer;
