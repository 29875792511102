import { FC } from "react";
import { Button, Dialog, FormInput } from "~/Components/UI";
import { useFormData, useToggle } from "~/Hooks";
import { LC } from "~/Locales";
import { LandingPage } from "~/API";
import { z } from "zod";
import { zodMsg } from "~/Utils";
import {useCreateLandingPage} from "~/Data/Admin/LandingPages";
import {trackEvent} from "~/Services/Tracking";

const addFormValidation = z.object({
  name: z.string().min(1, zodMsg("error.non.empty", ["Name"])),
  title: z.string().min(1, zodMsg("error.non.empty", ["Title"])),
});

export const LandingPageAdd: FC = () => {
  const addForm = useFormData<LandingPage>(
    {
      active: true,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      id: 0,
      selectedImage: 0,
      name: "",
      title: "",
    },
    {
      validation: addFormValidation,
    }
  );

  const open = useToggle(false);

  const createCall = useCreateLandingPage();

  const onOpenChange = (_open: boolean) => addForm.clear();

  const onCreateClicked = () => {
    if (addForm.isValid()) createCall
      .call(addForm.data)
      .then(open.toggleFalse)
      .then(() => trackEvent("Landing Page Added"));
  };

  return (
    <Dialog
      title={<LC id="title.landing.pages.add" />}
      onOpenChange={onOpenChange}
      open={open}
      trigger={<Button label="title.landing.pages.add" />}
    >
      <div className="flex-col gap-sm desktop-w-min-9xl">
        <FormInput<LandingPage>
          id="name"
          formData={addForm}
          label="title.name"
          placeholder="title.name"
        />

        <FormInput<LandingPage>
          id="title"
          formData={addForm}
          label="title.title"
          placeholder="title.title"
        />

        <div className="flex-row justify-end mt-lg">
          <Button
            onClicked={onCreateClicked}
            loading={createCall.isMutating}
            label="title.create"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default LandingPageAdd;
