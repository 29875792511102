import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  useCurrentUser,
  useIsInternalCustomer,
  useIsInternalUser,
} from "~/Reducers/User";
import UserParticipantList from "~/Components/Users/UserParticipantList";
import PurchaseOrderHeader from "./Components/PurchaseOrders/PurchaseOrderHeader";
import SalesOrderMessage from "./Components/SalesOrders/SalesOrderMessage";
import PurchaseOrderGraphic from "./Components/PurchaseOrders/PurchaseOrderGraphic";
import PurchaseOrderItems from "./Components/PurchaseOrders/PurchaseOrderItems";
import OrderDocuments from "./Components/OrderDocuments";
import OrderCompany from "./Components/OrderCompany";
import OrderChat from "./Components/OrderChat";
import RightPanel from "~/Components/Layout/RightPanel";
import {
  ordersPathCompleted,
  ordersPathOngoing,
  ordersPathPurchase,
  ordersRootPath,
} from "~/Services/Routes";
import PurchaseOrderShippingDetails from "./Components/PurchaseOrders/PurchaseOrderShippingDetails";
import PurchaseOrderTitleContainer from "./Components/PurchaseOrders/PurchaseOrderTitleContainer";
import PurchaseOrderBottomButtons from "./Components/PurchaseOrders/PurchaseOrderBottomButtons";
import { DateTimeView } from "~/Components/Views/DateView";
import {useUsersList} from "~/Data/Users";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {getPurchaseOrderChatId, useChatOrderUnread} from "~/Data/Chats";
import {ScrollPage} from "~/Components/Layout/Pages";
import {PageTitle} from "~/Components/Nav/PageTitle";
import {FollowButton} from "~/Components/Entities/FollowButton";
import {Button} from "~/Components/UI";
import {TbMessage2} from "react-icons/tb";
import {useToggle} from "~/Hooks";
import {useAddPurchaseOrderMember, usePurchaseOrder, useRemovePurchaseOrderMember} from "~/Data/Orders/PurchaseOrders";
import {LoadingPage} from "~/Pages/ErrorPages/LoadingPage";
import EndpointErrorPage from "~/Pages/ErrorPages/EndpointErrorPage";

const PurchaseOrderDetailsPage: FC = () => {
  const isInternalUser = useIsInternalUser();
  const isInternalCustomer = useIsInternalCustomer();
  const orderId = Number(useParams<{ id: string }>().id);
  const order = usePurchaseOrder(orderId, isInternalCustomer);
  const chatUnread = useChatOrderUnread(orderId, true);
  const currentUserId = useCurrentUser()?.id;

  const chat = getPurchaseOrderChatId(orderId)

  const membersList = useUsersList(order.data?.members || []);

  const isCompleted = useIsCompleted();
  const showChat = useToggle();

  const parentUrl = isInternalUser
    ? ordersRootPath + ordersPathPurchase
    : ordersRootPath +
    ordersPathPurchase +
    "/" +
    (isCompleted ? ordersPathCompleted : ordersPathOngoing);

  const addMember = useAddPurchaseOrderMember(orderId, isInternalCustomer);
  const removeMember = useRemovePurchaseOrderMember(orderId, isInternalCustomer);

  const isMember = order.data?.members.includes(currentUserId);
  const onFollow = () => addMember.call(currentUserId);
  const onUnfollow = () => removeMember.call(currentUserId);

  if(order.error)
    return (
      <EndpointErrorPage
        status={order.error?.status}
        item="purchase order"
      />
    )

  if(order.isLoading)
    return <LoadingPage />

  return (
    <>
      <ScrollPage title={`PO  #${order.data.number}`}>
        <PageTitle type="back" parentUrl={parentUrl} />
        <div className="flex-row flex-wrap align-center mx-lg mt-lg mb-xs">
          <div className="text-xl">
            <PurchaseOrderTitleContainer
              isInternalUser={isInternalUser}
              orderNumber={order.data.number}
              masterChild={order.data.masterChild}
            />
          </div>
          <div className="color-text-3 ml-sm mr-auto">
            Last Updated <DateTimeView date={order.data.dateUpdated} />
          </div>

          <FollowButton
            {...{ isMember, onFollow, onUnfollow}}
            chat={chat.data}
            followLabel="title.follow.order"
            unfollowDialogTitle="phrase.unfollow.order.confirm.1"
            unfollowDialogText="phrase.unfollow.order.confirm.2"
          />

          {isMember && (
            <>
              <UserParticipantList
                members={membersList.data || []}
                onAddMember={addMember.call}
                onRemoveMember={removeMember.call}
              />
              <Button
                variant="text"
                leftIcon={<TbMessage2 size={20} />}
                rightIcon={<ChatUnreadBadge unread={chatUnread} />}
                color={showChat.value ? "blue" : "gray"}
                onClicked={showChat.toggle}
                label="title.chat"
              />
            </>
          )}
        </div>

        <div className="bg-black-12 rounded-xxs">
          <OrderCompany orderId={orderId} />
          <PurchaseOrderHeader orderId={orderId} />
          <SalesOrderMessage orderId={orderId} />
          <PurchaseOrderGraphic orderId={orderId} />
          <PurchaseOrderShippingDetails orderId={orderId} />
        </div>

        <div className="bg-black-12 rounded-xxs mt-lg">
          <PurchaseOrderItems orderId={orderId} />
        </div>

        {!isInternalCustomer && (
          <div className="bg-black-12 rounded-xxs mt-lg">
            <OrderDocuments orderId={orderId} />
          </div>
        )}

        <PurchaseOrderBottomButtons orderId={orderId} />
      </ScrollPage>

      {isMember && (
        <RightPanel visible={showChat.value}>
          <OrderChat orderId={orderId} onClose={showChat.toggle} />
        </RightPanel>
      )}
    </>
  );
};

export default PurchaseOrderDetailsPage;

const useIsCompleted = () => {
  const { type } = useParams<{ type: string }>();
  return type?.toLowerCase() === "completed";
};
