import API from "~/API";
import useSWRImmutable from "swr/immutable";
import {PERIODS, periodToInterval} from "./MetricsData";

export interface CompanyProductsMetricFilters {
    period: PERIODS;
    companyId?: number;
    locationId?: number;
    productId?: number;
    productCode?: string;
}
export const useCompanyProductsMetrics22 = (filters: CompanyProductsMetricFilters) =>
    useSWRImmutable(
        ["api/metrics/companies", filters],
        async () => API.getCompanyProductsMetrics({
            ...periodToInterval(filters.period),
            companyId: filters.companyId || "" as any,
            shipToId: filters.locationId || "" as any,
            productId: filters.productId || "" as any,
        }),
    );

export const useCompanyProductMetrics2 = (filters: CompanyProductsMetricFilters) =>
    useSWRImmutable(
        ["api/metrics/companies", filters],
        async () => {
            const validParams: {
              interval: string;
              from: string;
              to: string;
              companyId?: number;
              shipToId?: number;
              productId?: number;
              productCode?: string;
            } = {
                ...periodToInterval(filters.period)
            };

            if (filters.companyId) {
                validParams.companyId = filters.companyId;
            }

            if (filters.locationId) {
                validParams.shipToId = filters.locationId;
            }

            if (filters.productId) {
                validParams.productId = filters.productId;
            }

            if (filters.productCode) {
                validParams.productCode = filters.productCode;
            }

            return API.getCompanyProductsMetrics(validParams);
        }
    );

interface CompanyProductsMetricFilters2 {
    companyId?: number;
    locationId?: number;
    productId?: number;
    productCode?: string;
    period: PERIODS; // Assuming this property is required
}

export const useCompanyProductsMetrics = (filters: CompanyProductsMetricFilters2) =>
    useSWRImmutable(
        ["api/metrics/companies", filters],
        async () => {
            const validParams: Record<string, any> = {};
            const intervalParams = periodToInterval(filters.period)
            validParams.interval = intervalParams.interval
            validParams.from = intervalParams.from
            validParams.to = intervalParams.to

            if (filters.companyId !== undefined) {
                validParams.companyId = filters.companyId;
            }
            if (filters.locationId !== undefined) {
                validParams.shipToId = filters.locationId;
            }
            if (filters.productId !== undefined) {
                validParams.productId = filters.productId;
            }
            if (filters.productCode !== undefined) {
                validParams.productCode = filters.productCode;
            }
            // @ts-ignore
            return API.getCompanyProductsMetrics(validParams);
        }
    );