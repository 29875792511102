import { FC } from "react";
import { UserAvatar } from "~/Components/Users/UserAvatar";
import { UserName } from "~/Components/Users/UserName";
import UserEmail from "~/Components/Users/UserEmail";

export const UserCard: FC<{ id: number }> = (props) => (
  <div className="flex-row flex">
    <UserAvatar id={props.id} />
    <div className="flex-col flex text-left text-truncate justify-between ml-xs mr-xs">
      <div className="text-truncate">
        <UserName id={props.id} />
      </div>
      <div className="text-sm color-text-2">
        <UserEmail id={props.id} />
      </div>
    </div>
  </div>
);
