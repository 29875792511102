import API, {ProductGroupPublicView} from "~/API";
import {composedItems} from "./Utils/Compose";
import {deepCopyOf} from "~/Utils";
import {useGetEntity, useGetEntityList} from "./Utils/Entity";
import {useMemo} from "react";
import {AppDispatch, RootState} from "~/StoreTypes";

const PUBLIC_PRODUCT_GROUPS_PER_PAGE_COUNT = 40;

const productGroupsPublicSelector = (state: RootState) => state.productGroupsPublic;
const productGroupPublicSelectorCreator = (productId: number) => (state: RootState) => state.productGroupsPublic[productId];

export const useProductGroupsPublic = () => useGetEntityList(getProductGroupsPublic, productGroupsPublicSelector);

export const useProductGroupPublic = (productGroupId: number) => useGetEntity(productGroupId, getProductGroupPublic, productGroupPublicSelectorCreator);

interface productGroupsPublicFilters {
  offset?: number;
  limit?: number;
  groupId?: number[];
}

export const getProductGroupsPublic = (filters: productGroupsPublicFilters = {}) => async (dispatch: AppDispatch) => {
  const params = {
    ...filters,
    offset: filters?.offset || 0,
    limit: filters?.limit || PUBLIC_PRODUCT_GROUPS_PER_PAGE_COUNT,
  };

  try {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_START' });
    const productGroups = await API.getProductGroupsPublic(params);
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_SUCCESS', productGroups: productGroups, offset: params.offset });
    return productGroups;
  } catch (error) {
    dispatch({ type: 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_ERROR', error });
  }
};

const getProductGroupPublic = (productId: number) => async (dispatch: AppDispatch) => {
  try {
    const productGroup = await API.getProductGroupPublic({ id: productId });
    dispatch({ type: 'ACTIONS_GET_PRODUCT_GROUP_PUBLIC_SUCCESS', productGroup });
    return deepCopyOf(productGroup);
  } catch (error) {
  }
};

// export const useProductGroupsPublicKeys = () => {
//   const productGroups = useProductGroupsPublic();
//   return useMemo(() => Object.keys(productGroups), [productGroups]);
// };

export const useProductGroupsPublicLabels = () => {
  const groups = useProductGroupsPublic();
  delete groups['error'];

  return useMemo(
    () => Object.keys(groups).reduce((acc, key) => ({...acc, [key]: groups[key].name}), {}),
    [groups]
  );
};

export interface ProductGroupsPublicState {
  [id: number]: ProductGroupPublicView;
  error?: any;
}

// reducer
const INITIAL_STATE: ProductGroupsPublicState = {};

const reducer = (state = INITIAL_STATE, action: any): ProductGroupsPublicState => {
  switch (action.type) {
    case 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_START':
      return { ...state, error: null };
    case 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_SUCCESS':
      const loadedProductGroups = action.productGroups.map((productGroup: ProductGroupPublicView) => (!!state[productGroup.id] ? { ...state[productGroup.id], ...productGroup } : productGroup));
      return { ...state, ...composedItems(loadedProductGroups) };
    case 'ACTIONS_GET_PRODUCT_GROUP_PUBLIC_SUCCESS':
      return { ...state, [action.productGroup.id]: action.productGroup };
    case 'ACTIONS_GET_PUBLIC_PRODUCT_GROUPS_ERROR':
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export default reducer;