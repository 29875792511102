import API from "~/API";
import useSWRImmutable from "swr/immutable";
import {PERIODS, periodToInterval} from "./MetricsData";

export interface OfferMetricFilters {
    period: PERIODS;
    am?: number;
    company?: number;
}

export const useOffersToQuotesMetrics = (filters: OfferMetricFilters) =>
    useSWRImmutable(
        ["api/metrics/offers/to/quotes", filters],
        async () => API.getOffersToQuotesMetrics({
            ...periodToInterval(filters.period),
            am: filters.am || "" as any,
            company: filters.company || "" as any,
        }),
    );

export interface OfferCreatedMetricFilters {
  period: PERIODS;
  buyer?: number;
  createdBy?: number;
  vendor?: number;
}

export const useOffersCreatedMetrics = (filters: OfferCreatedMetricFilters) =>
  useSWRImmutable(
    ["api/metrics/offers/created", filters],
    async () => API.getOffersCreatedMetrics({
      ...periodToInterval(filters.period),
      buyer: filters.buyer || "" as any,
      createdBy: filters.createdBy || "" as any,
      vendor: filters.vendor || "" as any,
    }),
  );
