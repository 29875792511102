import React, { FC, useEffect } from "react";
import { closeLeftMenu } from "~/Reducers/LeftMenu";
import {LC} from "~/Locales";
import { usePageTitle } from "~/Hooks";
import {useAppDispatch} from "~/StoreTypes";

const NoMatchPage: FC = () => {
  usePageTitle("404");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(closeLeftMenu());
  });

  return (
    <div className="m-2xl text-left">
      <div className="text-3xl">Something Went Wrong!</div>
      <div className="text-lg color-text-2 mt-sm">
        <LC id="placeholder.page.not.found" />
      </div>
    </div>
  );
};

export default NoMatchPage;
