import { OfferStatus } from "~/API";
import { useHistory } from "react-router-dom";
import { offersRootPath } from "~/Services/Routes";
import { useIsAdmin, useIsUFPAM, useIsUFPBuyer } from "~/Reducers/User";
import {
  useIsMatching,
  useIsReadOnly,
} from "../../../../Components/OffersHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { Button } from "~/Components/UI";
import { UpdateOfferButton } from "../SharedComponents/UpdateOfferButton";
import { SubmitOfferToAMButton } from "../SharedComponents/SubmitOfferToAMButton";
import { SaveOfferButton } from "../SharedComponents/SaveOfferButton";
import { FindMyCustomersButton } from "../SharedComponents/FindMyCustomersButton";
import { BuyerConfirmOfferButton } from "../SharedComponents/BuyerConfirmOfferButton";
import { RequestVendorConfirmationButton } from "../SharedComponents/RequestVendorConfirmationButton";
import { ReOpenOfferButton } from "../SharedComponents/ReOpenOfferButton";
import {useCompany} from "~/Data/Companies/Companies";

export const InternalUsersOffersButtonsPanel = (props) => {
  const {
    saveOffer,
    onBack,
    isOfferValid,
    offerId,
    hasChanges,
    loading,
    status,
    toggleShowSendQuotesDialog,
    disableSendQuotesButton,
    copyOffer,
    isExpired,
    vendorCompanyId,
  } = props;

  const readOnly = useIsReadOnly();
  const isMatching = useIsMatching();

  const isAdmin = useIsAdmin();
  const isUFPBuyer = useIsUFPBuyer();
  const isUFPAM = useIsUFPAM();

  const history = useHistory();

  const vendor = useCompany(vendorCompanyId);
  const requireApprovalForOrder = vendor.data == undefined ? true : vendor.data.requireOrderApproval;

  const statusIn = (statuses: OfferStatus[]) => statuses.includes(status);

  const SaveButton = ({ label = null }) => (
    <SaveOfferButton
      hasChanges={hasChanges}
      label={label}
      loading={loading}
      saveOffer={saveOffer}
      status={status}
    />
  );

  const CopyButton = () => (
    <Button onClicked={copyOffer} label="title.copy.offer" />
  );

  if (isMatching) {
    return (
      <BottomButtonsPanel>
        <Button
          onClicked={toggleShowSendQuotesDialog}
          disabled={disableSendQuotesButton}
          label="title.submit.quotes"
        />
        <Button color="gray" onClicked={onBack} label="title.cancel" />
      </BottomButtonsPanel>
    );
  } else {
    // Internal Users - Offer Details Page
    if (readOnly) {
      if (status === OfferStatus.O_ARCHIVED || isExpired) {
        return (
          <BottomButtonsPanel>
            <CopyButton />
            <ReOpenOfferButton offerId={offerId} saveOffer={saveOffer} />
          </BottomButtonsPanel>
        );
      } else {
        return (
          <BottomButtonsPanel>
            {(isAdmin || isUFPBuyer) && (
              <>
                {statusIn([
                  OfferStatus.O_DRAFT,
                  OfferStatus.O_BUYER_REVIEW,
                  OfferStatus.O_SALES_REVIEW,
                  OfferStatus.O_QUOTED,
                  OfferStatus.O_IN_PROGRESS,
                  OfferStatus.O_QUOTE_CLOSED,
                  OfferStatus.O_CLOSING,
                  OfferStatus.O_VENDOR_CONFIRMED,
                ]) && <UpdateOfferButton offerId={offerId} />}

                <CopyButton />

                {statusIn([
                  OfferStatus.O_DRAFT,
                  OfferStatus.O_BUYER_REVIEW,
                ]) && (
                  <>
                    <SubmitOfferToAMButton
                      offerId={offerId}
                      isOfferValid={isOfferValid}
                      saveOffer={saveOffer}
                    />
                    <FindMyCustomersButton offerId={offerId} />
                  </>
                )}

                {statusIn([
                  OfferStatus.O_SALES_REVIEW,
                  OfferStatus.O_QUOTED,
                  OfferStatus.O_IN_PROGRESS,
                  OfferStatus.O_QUOTE_CLOSED,
                ]) && (
                  <RequestVendorConfirmationButton
                    offerId={offerId}
                    saveOffer={saveOffer}
                    isOfferValid={isOfferValid}
                  />
                )}

                {status == OfferStatus.O_VENDOR_CONFIRMED && (
                  <>
                    <Button
                      onClicked={async () => {
                        await saveOffer(
                          OfferStatus.O_ARCHIVED,
                          `Offer ${offerId} archived`,
                        );
                        history.replace(`${offersRootPath}${offerId}`);
                      }}
                      label="offer.decline"
                    />
                  </>
                )}
              </>
            )}

            {status == OfferStatus.O_VENDOR_CONFIRMED || (
              !requireApprovalForOrder && statusIn([
                OfferStatus.O_QUOTED,
                OfferStatus.O_BUYER_REVIEW,
                OfferStatus.O_SALES_REVIEW,
                OfferStatus.O_IN_PROGRESS,
                OfferStatus.O_CLOSING,
              ])
            ) && (
              <BuyerConfirmOfferButton
                offerId={offerId}
                saveOffer={saveOffer}
              />
            )}

            {(isAdmin || isUFPAM) &&
              statusIn([
                OfferStatus.O_SALES_REVIEW,
                OfferStatus.O_QUOTED,
                OfferStatus.O_ORDERED,
                OfferStatus.O_IN_PROGRESS,
                OfferStatus.O_QUOTE_CLOSED,
                OfferStatus.O_CLOSING,
              ]) && <FindMyCustomersButton offerId={offerId} />}
          </BottomButtonsPanel>
        );
      }
    }
    // Internal Users - Offer Edit Page
    else {
      return (
        <BottomButtonsPanel>
          {statusIn([OfferStatus.O_DRAFT, OfferStatus.O_BUYER_REVIEW]) && (
            <SaveButton />
          )}

          {statusIn([
          OfferStatus.O_SALES_REVIEW,
          OfferStatus.O_QUOTED,
          OfferStatus.O_IN_PROGRESS,
          OfferStatus.O_QUOTE_CLOSED
          ]) && (
            <SaveButton label="Save & Re-Send to Vendor & Sales" />
          )}

          { status == OfferStatus.O_CLOSING &&
            <SaveButton label="Save & Request Vendor Confirmation again " />
          }

          { status == OfferStatus.O_VENDOR_CONFIRMED && <>
            <Button
              onClicked={async () => {
                await saveOffer(
                  OfferStatus.O_SALES_REVIEW,
                  `Offer ${offerId} re-sent to Sales`,
                );
                history.replace(`${offersRootPath}${offerId}`);
              }}
              label="offer.save.and.resend.sales"
            />
            <Button
              onClicked={async () => {
                await saveOffer(
                  OfferStatus.O_CLOSING,
                  `Requested Vendor Confirmation`,
                );
                history.replace(`${offersRootPath}${offerId}`);
              }}
              label="offer.save.and.resend.vendor"
            />
          </>
          }

          <Button color="gray" onClicked={onBack} label="title.cancel" />
        </BottomButtonsPanel>
      );
    }
  }
};
