import {QuoteStatus, QuoteView} from "~/API";
import {FC, useCallback} from "react";
import {LocalizedMessage} from "~/Locales";
import {useToggle} from "~/Hooks/Old";
import {archiveQuote, deleteQuoteEntity, useShouldShowArchiveButton} from "../Reducer";
import Button from "~/Components/Old/Button";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import DeleteDialog from "~/Components/Old/DeleteDialog";
import {DeleteIcon, InboxArchiveIcon} from "~/Components/Old/Icons";
import {useAppDispatch} from "~/StoreTypes";
import {trackEvent} from "~/Services/Tracking";


const QuoteArchiveDeleteButton: FC<{
  quote: QuoteView,
  groupId?: any | null,
}> = ({quote, groupId = null}) => {
  const dispatch = useAppDispatch();
  const {status} = quote;
  const isDraft = status === QuoteStatus.DRAFT;

  const [showDeleteConfirmation, toggleDeleteDialog] = useToggle();
  const [showArchiveConfirmation, toggleArchiveDialog] = useToggle();

  const onConfirmDelete = useCallback(() => {
    dispatch(deleteQuoteEntity(quote.id, groupId, toggleDeleteDialog));
    trackEvent("Delete Quote");
  }, [quote.id, groupId, toggleDeleteDialog, dispatch]);

  const onConfirmArchive = useCallback(() => {
    dispatch(archiveQuote({...quote, status: QuoteStatus.ARCHIVED}, groupId, toggleArchiveDialog));
    trackEvent("Archive Quote");
  }, [quote, groupId, toggleArchiveDialog, dispatch]);

  const shouldShowArchiveButton = useShouldShowArchiveButton(status);

  return (
    <>
      {isDraft && shouldShowArchiveButton &&
        <Button view={'text'} className={'ActionButton DeleteButtonText'}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDeleteDialog();
                }}>
          <DeleteIcon size={20}/>
        </Button>
      }

      {!isDraft && shouldShowArchiveButton &&
        <Button view={'text'} className={'ActionButton ArchiveButton'}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleArchiveDialog();
                }}>
          <InboxArchiveIcon size={20}/>
        </Button>
      }

      {showDeleteConfirmation &&
        <DeleteDialog
          title={'Delete Quote'}
          description={`Are you sure you want to delete quote ${quote.id}?`}
          description2={<LocalizedMessage id={'phrase.this.actions.can.not.be.undone'}/>}
          confirmTitle={<LocalizedMessage id={'title.delete'}/>}
          closeTitle={<LocalizedMessage id={'title.cancel'}/>}
          onDelete={onConfirmDelete}
          onClose={toggleDeleteDialog}
        />
      }
      {showArchiveConfirmation &&
        <ConfirmationDialog
          title={'Archive Quote'}
          description={`Are you sure you want to archive quote ${quote.id}?`}
          description2={''}
          confirmTitle={<LocalizedMessage id={'title.archive'}/>}
          closeTitle={<LocalizedMessage id={'title.cancel'}/>}
          onConfirm={onConfirmArchive}
          onClose={toggleArchiveDialog}
        />
      }
    </>
  );
};

export default QuoteArchiveDeleteButton;
