import {Attachment, AttachmentRestriction, DocumentType} from "~/API";
import {useState, useMemo, useCallback, FC, ReactNode} from "react";
import {LC, ls, LocalizedMessageKey} from "~/Locales";
import {
  useAsyncAttachHandler,
  useDetachHandler,
  fileSizeString,
} from "~/Utils/Forms";
import {
  canUserUploadDoc,
  UPLOADABLE_DOCS_KEYS,
} from "~/Data/Documents/DocumentTypes";
import {
  useIsInternalUser,
  useIsVendor,
} from "~/Reducers/User";
import {
  TbCircleCheckFilled,
  TbRefresh,
  TbTrash,
} from "react-icons/tb";
import {Button, Dialog, Select, DragAndDropUpload} from "~/Components/UI";
import {useMutate, useToggle} from "~/Hooks";

const OrderDocumentsUploadDialog: FC<{
  title?: string;
  desc?: string;
  trigger: ReactNode;
  onSave: (attachments: Attachment[]) => Promise<any>;
}> = (props) => {
  const [attachments, setAttachments] = useState<any[]>([]);
  const dialogOpen = useToggle();

  const onOpenChange = () => setAttachments([]);

  const close = () => {
    attachments.forEach(
      (attachment) => !!attachment.progress && detachHandler(attachment),
    );

    setAttachments([]);
    dialogOpen.toggleFalse();
  };

  const save = useMutate(async () => {
    await props.onSave(attachments);
    dialogOpen.toggleFalse();
  });

  const attachHandler = useAsyncAttachHandler(
    attachments,
    setAttachments,
    () => ({
      active: true,
      restriction: AttachmentRestriction.REFERENCE,
      ref: null,
      refId: null,
      type: null,
    }),
  );

  const detachHandler = useDetachHandler(attachments, setAttachments);

  const onTypeChange = useCallback(
    (attachmentId, type) => {
      const attachmentIndex = attachments.findIndex(
        (it) => it.id === attachmentId,
      );
      if (attachmentIndex !== -1) {
        attachments[attachmentIndex] = {
          ...attachments[attachmentIndex],
          type,
        };
        setAttachments([...attachments]);
      }
    },
    [attachments],
  );

  const isAttachmentsReadyToSave = useMemo(() => {
    if (!attachments.length) return false;
    return attachments.every(
      (attachment) =>
        !attachment.progress && !!attachment.type,
    );
  }, [attachments]);

  const attachmentsHaveBeenAdded = attachments.length > 0;
  const accept = [".pdf", ".xlsx", ".docx", ".msg"];

  return (
    <Dialog
      title={props.title || ls("title.upload.documents")}
      open={dialogOpen}
      onOpenChange={onOpenChange}
      trigger={props.trigger}
    >
      <div className="desktop-w-min-11xl flex-col gap-md">
        {props.desc != undefined && (
          <div className="text-bold color-text-2">
            {props.desc}
          </div>
        )}
        {attachmentsHaveBeenAdded && (
          <div className="flex-col gap-sm mr-xxs mobile-h-max-full mobile-h-full">
            {attachments.map((attachment) => (
              <AttachmentRow
                key={attachment.id}
                attachment={attachment}
                detachHandler={detachHandler}
                onTypeChange={onTypeChange}
              />
            ))}
          </div>
        )}

        <DragAndDropUpload
          acceptedFileTypes={accept}
          onFilesUploaded={attachHandler}
          multiple
        />

        <div className="flex-row gap-xxs justify-end">
          <Button
            onClicked={save.mutate}
            disabled={!isAttachmentsReadyToSave}
            loading={save.loading}
            label="title.save"
          />
          <Button
            color="gray"
            className="ml-sm"
            onClicked={close}
            label="title.cancel"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default OrderDocumentsUploadDialog;

const AttachmentRow: FC<{
  attachment: any;
  detachHandler: (attachment: any) => void;
  onTypeChange: (attachmentId: number, type: DocumentType) => void;
}> = ({ attachment, detachHandler, onTypeChange }) => {
  const changeType = useCallback(
    (id: string, value: DocumentType) => {
      onTypeChange?.(attachment.id, value);
    },
    [attachment, onTypeChange],
  );

  return (
    <div className="flex-row gap-md">
      <div className="flex flex-col overflow-hidden">
        <div className="flex-row py-xxs align-center overflow-hidden">
          {!attachment.progress && (
            <TbCircleCheckFilled size={24} className="color-green-1 mr-xxs" />
          )}
          {attachment.progress && (
            <TbRefresh size={24} className="color-blue-1 mr-xxs" />
          )}
          <div className="text-semibold flex mr-md text-truncate">
            {attachment.name}
          </div>

          {attachment.progress && (
            <div className="text-sm color-text-2">
              <LC id="phrase.percent.uploading">
                {attachment.progress.toFixed(1)}
              </LC>
            </div>
          )}
          {!attachment.progress && (
            <>
              <DocumentTypeSelect
                value={attachment.type}
                checkSellerOnlyFlag={true}
                className="flex"
                onChange={changeType}
              />
              <div className="text-sm color-text-2 ml-xs">
                {fileSizeString(attachment.size)}
              </div>
              <div
                className="color-red-1 hover-color-red-0 cursor-pointer ml-xs"
                onClick={() => detachHandler(attachment)}
              >
                <TbTrash size={24} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const DocumentTypeSelect: FC<{
  checkSellerOnlyFlag?: boolean;
  value: DocumentType;
  onChange: (id: string, value: DocumentType) => void;
  className?: string;
}> = ({ checkSellerOnlyFlag, ...props }) => {
  const isInternal = useIsInternalUser();
  const isVendor = useIsVendor();

  const keys = UPLOADABLE_DOCS_KEYS.filter((key) =>
    canUserUploadDoc(key, isInternal, isVendor),
  );

  const labels = keys.reduce((a, key) => {
    a[key] = ls(
      `contract.docs.name.${key}` as LocalizedMessageKey,
    );
    return a;
  }, {});

  return (
    <Select
      {...props}
      id=""
      data={keys}
      getLabel={(i: string) => labels[i]}
      placeholder="title.select.type"
    />
  );
};
