import { useCallback, useState } from "react";

export interface ToggleProps {
  value: boolean;
  toggle: () => void;
  toggleTrue: () => void;
  toggleFalse: () => void;
  set: (value: boolean) => void;
}

export const useToggle = (initialValue: boolean = false): ToggleProps => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => setValue((prev) => !prev), []);
  const toggleTrue = useCallback(() => setValue((_) => true), []);
  const toggleFalse = useCallback(() => setValue((_) => false), []);
  const set = useCallback((v: boolean) => setValue(v), []);

  return { value, toggle, toggleTrue, toggleFalse, set };
};
