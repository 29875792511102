import { FC } from "react";
import { TbLoader2 } from "react-icons/tb";
import {cn} from "~/Utils";

export const LinearLoader: FC<{ loading: boolean }> = (props) => {
  if (!props.loading) return <div className="w-full h-xxs h-min-xxs" />;

  return (
    <div className="w-full h-xxs h-min-xxs rounded-xxs overflow-hidden">
      <div className="h-full w-full bg-blue-1 animate-linear-progress" />
    </div>
  );
};

export const Spinner: FC<{ loading: boolean; size?: number; className?: string }> = (props) => {
  if (!props.loading) return <></>;
  return <TbLoader2 size={props.size || 24} className={cn("animate-spin color-blue-1", props.className)} />;
};

export const Skeleton: FC<{
  className?: string;
}> = (props) => (
  <div className={cn("rounded-xxs bg-black-10 overflow-hidden relative animate-shimmer", props.className || "w-full h-md")} />
);
