import {FC, useEffect, useMemo, useState} from "react";
import { LocalizedMessageKey, ls } from "~/Locales";
import API, {MyProduct, ProductUfp} from "~/API";
import { ProductCatalogueDescriptionLink } from "~/Components/Products/ProductCatalogueDescriptionLink";
import { Button, FormInput, Table } from "~/Components/UI";
import {TbChevronLeft, TbChevronRight, TbPlus} from "react-icons/tb";
import {useDebounce, useFormData} from "~/Hooks";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { LinearLoader } from "~/Components/Loaders";
import {useIsCustomer, useIsVendor} from "~/Reducers/User";
import {ProductTypeCombobox} from "~/Components/Products/ProductTypeCombobox";
import useSWR from "swr";

const LIMIT = 15;

const ProductsList: FC<{
  type: "all" | "my_products";
  onProductAdd: (product: ProductUfp, isMyProduct: boolean) => void;
  canEdit: boolean;
  addProductTitle?: LocalizedMessageKey;
  companyId?: number;
  locationId?: number;
}> = (props) => {
  const isVendor = useIsVendor();
  const isCustomerUser = useIsCustomer();

  const [page, setPage] = useState(0);
  const onGetNext = () => setPage(page + 1);
  const onGetPrev = () => setPage(page - 1);

  useEffect(() => {
    setPage(0);
  }, [props.companyId, props.locationId, props.type]);

  const filters = useFormData({
    search: "",
    type: undefined,
  }, {
    onChange: (_oldData, newData) => {
      setPage(0);
      return newData;
    }
  });
  const searchDebounce = useDebounce(filters.data.search);

  const products = useSWR(
    {
      route: "api/add/products/section",
      type: props.type,
      companyId: props.companyId,
      locationId: props.locationId,
      filterType: filters.data.type,
      search: searchDebounce,
      offset: page * LIMIT
    },
    () => {
      const apiFunc = (() => {
      if (props.type == "all") {
        return API.getProductsV3;
      } else {
        if (isVendor || (isCustomerUser && props.locationId == undefined)) {
          return API.getMyProducts;
        }
        if (isCustomerUser && props.locationId) {
          return API.getMyProductsByLocation;
        }

        if (props.locationId) {
          return API.getCompanyProductsByLocation;
        }

        if (props.companyId) {
          return API.getCompanyProducts;
        }

        return (_filters: any) => [];
      }
    })();

      return apiFunc({
        search: searchDebounce,
        productType: filters.data.type || "",
        companyId: props.companyId || "",
        locationId: props.locationId || "",
        active: true,
        offset: page * LIMIT,
        limit: LIMIT,
      } as any);
    },
  );

  const columns = useMemo(
    () => getColumns(
      props.onProductAdd,
      props.type == "my_products",
      props.addProductTitle
    ),
    [props.onProductAdd, props.addProductTitle],
  );
  const data = useMemo(() => products.data || [], [products.data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        add: props.canEdit,
      },
    },
  });

  return (
    <div>
      <div className="flex-row p-sm gap-md align-center justify-start">
        <FormInput
          id="search"
          formData={filters}
          placeholder="placeholder.search.by.code.and.description"
          className="w-min-8xl"
          type="search"
          showClear
        />
        <ProductTypeCombobox
          id="type"
          formData={filters}
          placeholder="title.product.type"
          className="w-min-6xl"
          showClear
        />
      </div>

      <LinearLoader loading={products.isValidating} />
      <Table
        table={table}
        className="h-min-12xl"
        />
        <div className="w-full flex-row gap-sm justify-end align-center">
          <div>
            Products {page * LIMIT + 1} - {(page + 1) * LIMIT}
          </div>
          <Button
            onClicked={onGetPrev}
            label="title.previous"
            leftIcon={<TbChevronLeft size={16}/>}
            disabled={products.isValidating || page == 0}
          />
          <Button
            onClicked={onGetNext}
            label="title.next"
            rightIcon={<TbChevronRight size={16}/>}
            disabled={products.isValidating || products.data && products.data.length != LIMIT}
          />
        </div>
    </div>
  );
};
export default ProductsList;

const columnHelper = createColumnHelper<ProductUfp>();

export const getColumns = (
  onProductAdd: (product: ProductUfp, myProducts: boolean) => void,
  myProducts: boolean,
  addProductTitle?: LocalizedMessageKey,
) => [
  columnHelper.accessor("productCode", {
    header: ls("title.code"),
    size: 0,
  }),
  columnHelper.accessor("productGroup", {
    header: ls("title.product.group"),
    size: 400,
  }),
  columnHelper.accessor("productType", {
    header: ls("title.product.type"),
  }),
  columnHelper.display({
    id: "description",
    header: ls("title.description"),
    cell: ({ row }) => (
      <ProductCatalogueDescriptionLink
        description={row.original.description}
        productCode={row.original.productCode}
        hasSyndigo={row.original.hasSyndigo}
        productId={myProducts ? (row.original as MyProduct).productId : row.original.id}
      />
    ),
    size: 400,
  }),
  columnHelper.accessor("standardUnitOfMeasure", {
    header: ls("title.uom"),
    size: 0,
  }),
  columnHelper.display({
    id: "add",
    size: 0,
    cell: ({ row }) => (
      <Button
        color="gray"
        dataCy="AddProductButton"
        onClicked={() => onProductAdd(row.original, myProducts)}
        leftIcon={<TbPlus size={16} />}
        label={addProductTitle || "title.offer"}
      />
    ),
  }),
];
