import { classNames } from 'react-ui-basics/Tools';
import './Button.scss';
import React, {FC} from "react";

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  leftIcon?: React.ReactElement,
  rightIcon?: React.ReactElement,
  view?: "text" | "secondary" | "gray" | "primary" | string,
  className?: string,
  type?: "button" | "submit" | "reset",
}

/**
 * @Deprecated please use `~/Components/UI/Button`
**/
const Button: FC<ButtonProps> = (props) => {
  const { leftIcon, rightIcon, view, children, ...buttonProps } = props;
  const { className, type } = props;
  const buttonType = type || 'button';
  const buttonClassName = classNames('text-sm text-bold upper-case',
    view === 'text' && 'TBButtonTextMainContainer',
    view === 'secondary' && 'TBButtonSecondaryMainContainer',
    view === 'gray' && 'TBButtonGrayMainContainer',
    (!view || view === 'primary') && 'TBButtonPrimaryMainContainer',
    className
  );
  return (
    <button { ...buttonProps } type={buttonType} className={buttonClassName}>
      {leftIcon && <div className={'TBButtonLeftIcon'}>{leftIcon}</div>}
      {children}
      {rightIcon && <div className={'TBButtonRightIcon'}>{rightIcon}</div>}
    </button>
  );
};

export default Button;
