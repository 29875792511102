import { FC } from "react";
import { Card } from "~/Components/UI";
import {MapStringToSyndigoProductAssetViewModel} from "~/API";

export const CatalogueDocumentsCard: FC<{
  assets: MapStringToSyndigoProductAssetViewModel
}> = ({ assets }) => {
  return (
    <Card className="flex flex-basis-min-content flex-col gap-xxs">
      <div className="text-xl">Additional Information</div>
      <ul>
        <DocLink href={assets.Brochure?.url} name="Brochure" />
        <DocLink
          href={assets.PdfInstallationGuide?.url}
          name="Installation Guide"
        />
        <DocLink href={assets.PdfWarranty?.url} name="Warranty" />
        <DocLink href={assets.PdfTipsAndTricks?.url} name="Tips And Tricks" />
        <DocLink
          href={assets.PdfUseAndCareManual?.url}
          name="Use and Care Manual"
        />
      </ul>
    </Card>
  );
};

const DocLink: FC<{
  href?: string;
  name: string;
}> = (props) => (
  <>
    {props.href && (
      <li>
        <a href={props.href} target="_blank">
          {props.name}
        </a>
      </li>
    )}
  </>
);
