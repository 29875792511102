import { useIsInternalUser } from "~/Reducers/User";
import { useIsRFOPath } from "~/Services/Routes";
import { InternalUsersRFOButtonsPanel } from "./ButtonsPanelRFO/InternalUsersRFOButtonsPanel";
import { ExternalUsersRFOButtonsPanel } from "./ButtonsPanelRFO/ExternalUsersRFOButtonsPanel";
import { ExternalUsersOffersButtonsPanel } from "./ButtonsPanelOffers/ExternalUsersOffersButtonsPanel";
import { InternalUsersOffersButtonsPanel } from "./ButtonsPanelOffers/InternalUsersOffersButtonsPanel";
import { OfferStatus } from "~/API";

export type SaveOfferCallbackType = (offerStatus: OfferStatus) => Promise<void>;

export interface OfferDetailsButtonsPanelProps {
  saveOffer: SaveOfferCallbackType;
  copyOffer: () => void;
  onBack: () => void;
  isOfferValid: () => boolean;
  offerId: number;
  toggleShowSendQuotesDialog: () => void;
  disableSendQuotesButton: boolean;
  hasChanges: boolean;
  loading: boolean;
  status: OfferStatus;
  isExpired: boolean;
  vendorCompanyId: number;
}

export const OfferDetailsButtonsPanel = (props: OfferDetailsButtonsPanelProps) => {
  const isInternalUser = useIsInternalUser();
  const isRFO = useIsRFOPath();

  if (isRFO) {
    if (isInternalUser) {
      return <InternalUsersRFOButtonsPanel {...props} />
    } else {
      return <ExternalUsersRFOButtonsPanel {...props} />
    }
  }
  // regular Offers
  else {
    if (isInternalUser) {
      return <InternalUsersOffersButtonsPanel {...props} />
    } else {
      return <ExternalUsersOffersButtonsPanel {...props} />
    }
  }
};

