import { PERIODS, periodToInterval } from "./MetricsData";
import useSWRImmutable from "swr/immutable";
import API from "~/API";

export interface QuoteMetricFilters {
    period: PERIODS;
    am?: number;
    company?: number;
}

export const useQuoteMetrics = (filters: QuoteMetricFilters) =>
    useSWRImmutable(
        ["api/metrics/quotes/count", filters],
        async () => API.getQuoteMetrics({
            ...periodToInterval(filters.period),
            managerId: filters.am || "" as any,
            buyerId: filters.company || "" as any,
        }),
    );
