import { useEffect, useMemo, useState } from "react";
import {
  useLocations,
  getLocation,
  getLocationsForSelect,
  getCompanyLocations,
  getCompaniesLocations,
  useLocationLabels,
  useLocationLabelsWithCustomerCode,
  useLocationLabelsWithLocationCode
} from "~/Reducers/Locations";
import { useCurrentUser, useIsVendor } from "~/Reducers/User";
import { useIsExternalUser, useIsCustomer } from "~/Reducers/User";
import SelectWithPaging from "./SelectWithPaging";
import SelectWithoutPaging from "./SelectWithoutPaging";
import {useAppDispatch} from "~/StoreTypes";

const LOCATION_LABEL_MODES = {
  CODE_AND_NAME: 'CODE_AND_NAME',
  CODE_AND_ADDRESS: 'CODE_AND_ADDRESS',
  LOCATIONCODE_AND_ADDRESS: 'LOCATIONCODE_AND_ADDRESS'
};

const LocationSelect = ({ showCustomerCodes = false, showLocationCodes = false, ...props }) => {
  const dispatch = useAppDispatch();
  const locations = useLocations();
  const isExternalUser = useIsExternalUser();

  useEffect(() => {
    if (Array.isArray(props.value)) {
      props.value.forEach(locationId => {
        if (!locations[locationId]) dispatch(getLocation(locationId));
      });
    } else {
      if (!locations[props.value]) dispatch(getLocation(props.value));
    }
  }, [props.value]);

  let locationLabelMode = LOCATION_LABEL_MODES.CODE_AND_NAME;
  if (showCustomerCodes) {
    locationLabelMode = LOCATION_LABEL_MODES.CODE_AND_ADDRESS;
  } else if (showLocationCodes) {
    locationLabelMode = LOCATION_LABEL_MODES.LOCATIONCODE_AND_ADDRESS;
  }
  if (isExternalUser) {
    return <LocationSelectForExternalUsers locationLabelMode={locationLabelMode} {...props} />;
  } else {
    return <LocationSelectForInternalUsers locationLabelMode={locationLabelMode} {...props} />;
  }
};

export default LocationSelect;


const HIDDEN_FALSE_QUERY = '&& HIDDEN == false';

const LocationSelectForExternalUsers = ({ locationLabelMode = LOCATION_LABEL_MODES.CODE_AND_NAME, ...props }) => {
  const dispatch = useAppDispatch();
  const isCustomer = useIsCustomer();
  const isVendor = useIsVendor();
  const currentUser = useCurrentUser();
  const labels = getDataLabels({ locationLabelMode });
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    dispatch(getCompanyLocations(currentUser.companyId, { query: `ACTIVE == true ${isVendor ? HIDDEN_FALSE_QUERY : ''}` }))
      .then((locations) => setKeys(locations.map(it => it.id)));
  }, []);

  const keysToShow = useMemo(() => {
    return isCustomer ? currentUser.locations : keys;
  }, [isCustomer, currentUser, keys]);

  return <SelectWithoutPaging {...props}
    data={keysToShow}
    labels={labels()}
    skipLabelSorting
    data-cy={"LocationSelect"}
  />;
};


const LocationSelectForInternalUsers = ({ locationLabelMode = LOCATION_LABEL_MODES.CODE_AND_NAME, companyId = null, ...props }) => {
  const byEntityFunc = Array.isArray(companyId) ? getCompaniesLocations : getCompanyLocations;

  return <SelectWithPaging {...props}
    entityId={companyId}
    getData={getLocationsForSelect}
    getDataByEntityId={byEntityFunc}
    filter={{ ...props.filter, query: `ACTIVE == true` }}
    useDataLabels={getDataLabels({ locationLabelMode })}
    data-cy={"LocationSelect"}
  />;
};

const getDataLabels = ({ locationLabelMode }) => {
  let dataLabels;
  switch (locationLabelMode) {
    case LOCATION_LABEL_MODES.CODE_AND_NAME:
      dataLabels = useLocationLabels;
      break;
    case LOCATION_LABEL_MODES.LOCATIONCODE_AND_ADDRESS:
      dataLabels = useLocationLabelsWithLocationCode;
      break;
    case LOCATION_LABEL_MODES.CODE_AND_ADDRESS:
      dataLabels = useLocationLabelsWithCustomerCode;
      break;
    default:
      dataLabels = useLocationLabels;
      break;
  }
  return dataLabels;
};
