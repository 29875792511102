import API, {
  CompanyType,
  LocationType,
  NotificationLevelEnum,
  GroupPermission,
  Registration,
  UserAccountView,
} from "~/API";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LC, LocalizedMessageKey } from "~/Locales";
import { formatRegistrationStatus } from "~/Reducers/Users";
import {
  useCurrentUser,
  useIsAdmin,
  useIsUFPAM,
  useIsUFPBuyer,
} from "~/Reducers/User";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import {
  Button,
  DeleteDialog,
  FormInput,
  FormSwitch,
  FormSwitchDescriptive,
  DescriptiveRadioGroup,
} from "~/Components/UI";
import UserGroupSelect from "~/Components/Users/UserGroupSelect";
import { usersRootPath } from "~/Services/Routes";
import { savePreferences } from "~/Reducers/UserPreferences";
import { useAppDispatch } from "~/StoreTypes";
import { TriggerInvitationForm } from "./UserTriggerInvitation";
import { UserChangePasswordButton } from "./UserChangePasswordButton";
import { useFormData } from "~/Hooks";
import { LocationCombobox } from "~/Components/Companies/LocationCombobox";
import { CompanyCombobox } from "~/Components/Companies/CompanyCombobox";
import { zodMsg } from "~/Utils";
import * as z from "zod";
import {
  useCreateUser,
  useRemoveUser,
  useUpdateUser,
  useUpdateUserSettings,
} from "~/Data/Users";
import UserDetailsEmailSettings from "./UserDetailsEmailSettings";
import UserProfilePhoto from "./UserProfilePhoto";
import { UnsavedChangesDialog } from "~/Components/Nav/UnsavedChangesDialog";
import { CompanyForm } from "~/Pages/Users/DetailsPage/Components/UserCompanyForm";
import { LocationForm } from "~/Pages/Users/DetailsPage/Components/UserLocationForm";
import Toast from "~/Components/Toast";
import {trackEvent} from "~/Services/Tracking";

const EXTERNAL_USER_PERMISSIONS = [
  GroupPermission.CAN_BE_CUSTOMER,
  GroupPermission.CAN_BE_VENDOR,
];
const VENDOR_USER_PERMISSIONS = [GroupPermission.CAN_BE_VENDOR];

const userFormValidation = (isCustomer: boolean, isCreate: boolean) =>
  z
    .object({
      name: z.string().min(1, zodMsg("error.non.empty", ["First Name"])),
      surname: z.string().min(1, zodMsg("error.non.empty", ["Last Name"])),
      email: z.string().email(zodMsg("error.invalid.email")),
      userGroupId: z.number().min(0, zodMsg("error.required", ["User Group"])),
      locations: z.array(z.number()),
      companyId: z.number().optional(),
      useExistingCompany: z.boolean(),
    })
    .refine((arg) => !arg.useExistingCompany || !!arg.companyId, {
      message: "A Company is Required",
      path: ["companyId"],
    })
    .refine((arg) => !isCustomer || arg.locations.length > 0 || isCreate, {
      message: "Customers Require at least one location",
      path: ["locations"],
    });

const companyFormValidation = z
  .object({
    legalName: z.string().min(1, zodMsg("error.non.empty", ["Name"])),
    addressStreet: z.string(),
    addressCity: z.string(),
    addressRegion: z.string(),
    addressZip: z.string(),
    addressCountry: z.string(),

    addLocation: z.boolean(),
    locationUseCompanyAddress: z.boolean(),
    locationAddressStreet: z.string(),
    locationAddressCity: z.string(),
    locationAddressRegion: z.string(),
    locationAddressZip: z.string(),
    locationAddressCountry: z.string(),
  })
  .refine(
    (arg) =>
      !arg.addLocation ||
      arg.locationUseCompanyAddress ||
      arg.locationAddressStreet.length > 0,
    {
      message: "Street is Required",
      path: ["locationAddressStreet"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      !arg.locationUseCompanyAddress ||
      arg.addressStreet.length > 0,
    {
      message: "Street is Required",
      path: ["addressStreet"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      arg.locationUseCompanyAddress ||
      arg.locationAddressCity.length > 0,
    {
      message: "City is Required",
      path: ["locationAddressCity"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      !arg.locationUseCompanyAddress ||
      arg.addressCity.length > 0,
    {
      message: "City is Required",
      path: ["addressCity"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      arg.locationUseCompanyAddress ||
      arg.locationAddressZip.length > 0,
    {
      message: "Zip is Required",
      path: ["locationAddressZip"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      !arg.locationUseCompanyAddress ||
      arg.addressZip.length > 0,
    {
      message: "Zip is Required",
      path: ["addressZip"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      arg.locationUseCompanyAddress ||
      arg.locationAddressRegion.length > 0,
    {
      message: "Region is Required",
      path: ["locationAddressRegion"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      !arg.locationUseCompanyAddress ||
      arg.addressRegion.length > 0,
    {
      message: "Region is Required",
      path: ["addressRegion"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      arg.locationUseCompanyAddress ||
      arg.locationAddressCountry.length > 0,
    {
      message: "Country is Required",
      path: ["locationAddressCountry"],
    },
  )
  .refine(
    (arg) =>
      !arg.addLocation ||
      !arg.locationUseCompanyAddress ||
      arg.addressCountry.length > 0,
    {
      message: "Country is Required",
      path: ["addressCountry"],
    },
  )
  .refine((arg) => !arg.addLocation || arg.addressCountry.length > 0, {
    message: "Country is Required",
    path: ["addressCountry"],
  });

const UserDetailsPageProfileTab: FC<{
  user?: UserAccountView;
  onBack?: () => void;
}> = ({ user, onBack }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const history = useHistory();

  const isAdmin = useIsAdmin();
  const isAM = useIsUFPAM();
  const isBuyer = useIsUFPBuyer();
  const isInternalUser = isAM || isBuyer || isAdmin;
  const userIdIsCurrentUser = user?.id === currentUser.id;
  const isUserDetails = pathname?.includes("/users/");
  const [userPermissions, setUserPermissions] = useState<GroupPermission[]>([]);

  const userForm = useFormData(
    {
      name: "",
      surname: "",
      email: "",
      registrationStatus: undefined as Registration | undefined,
      companyId: undefined as number | undefined,
      locations: [] as number[],
      active: true,
      photoAttachmentId: undefined as number | undefined,
      sr: null as number,
      showBellNotifications: true,
      useExistingCompany: true,
      ...(user || {}),
    },
    {
      validation: userFormValidation(
        userPermissions.includes(GroupPermission.CAN_BE_CUSTOMER),
        !user,
      ),
    },
  );

  const companyForm = useFormData(
    {
      accountManagerUserId: currentUser.id,
      additionalResponsibles: [] as number[],
      legalName: "",
      addressStreet: "",
      addressStreet2: "",
      addressCity: "",
      addressRegion: "",
      addressZip: "",
      addressCountry: "",
      type: CompanyType.BUYER,
      addLocation: false,

      locationUseCompanyAddress: false,
      locationName: "",
      locationAccountManagerUserId: currentUser.id,
      locationAdditionalResponsibles: [],
      locationAddressStreet: "",
      locationAddressStreet2: "",
      locationAddressCity: "",
      locationAddressRegion: "",
      locationAddressZip: "",
      locationAddressCountry: "",
      locationIncoterm: "",
      locationPortId: undefined,
      locationDefaultMarginAsFraction: undefined,
      locationPaymentTermsDescription: "",
      locationType: null,

      billToUseLocationAddress: false,
      billToName: "",
      billToAddressStreet1: "",
      billToAddressStreet2: "",
      billToAddressCity: "",
      billToAddressRegion: "",
      billToAddressZip: "",
      billToAddressCountry: "",
    },
    {
      validation: companyFormValidation,
      onChange: (_oldVal, newVal) => {
        let result = { ...newVal };

        if (result.locationUseCompanyAddress)
          result = {
            ...result,
            locationAddressStreet: result.addressStreet,
            locationAddressStreet2: result.addressStreet2,
            locationAddressCity: result.addressCity,
            locationAddressRegion: result.addressRegion,
            locationAddressZip: result.addressZip,
            locationAddressCountry: result.addressCountry,
          };

        if (result.billToUseLocationAddress)
          result = {
            ...result,
            billToAddressStreet1: result.locationAddressStreet,
            billToAddressStreet2: result.locationAddressStreet2,
            billToAddressCity: result.locationAddressCity,
            billToAddressRegion: result.locationAddressRegion,
            billToAddressZip: result.locationAddressZip,
            billToAddressCountry: result.locationAddressCountry,
          };

        return result;
      },
    },
  );

  const emailForm = useFormData({
    chatEmailOptOut: false,
    orderNewEmailOptOut: false,
    orderUpdateEmailOptOut: false,
    quoteNewEmailOptOut: false,
    quoteUpdateEmailOptOut: false,
  });

  useEffect(() => {
    getPermissions().then();
  }, [userForm.data.userGroupId]);

  const getPermissions = async () => {
    if (!!userForm.data.userGroupId) {
      const group = await API.getUserGroup({ id: userForm.data.userGroupId });

      const groupPermissions = group.permissions.filter(p => group.permissions.includes(p));
      setUserPermissions(groupPermissions);
    } else {
      setUserPermissions([]);
    }
  };

  const isDeletedUser = !userForm.data.active;
  const canEdit = !isUserDetails || isAdmin;
  const isInvitationPending =
    user?.registrationStatus == Registration.EMAIL_SENT;
  const useExistingCompany = userForm.data.useExistingCompany;

  // don't show locations for a vendor user (vendor user is on a company level)
  const isVendor = VENDOR_USER_PERMISSIONS.every((p) =>
    userPermissions.includes(p),
  );
  const isCustomer = userPermissions.includes(GroupPermission.CAN_BE_CUSTOMER);

  useEffect(() => {
    if (user) return;

    let companyType = null;
    let locationType = null;
    if (isVendor) {
      companyType = CompanyType.SELLER;
      locationType = LocationType.SHIP_FROM;
    }
    if (isCustomer) {
      companyType = CompanyType.BUYER;
      locationType = LocationType.SHIP_TO;
    }

    companyForm.onDataChange("type", companyType);
    companyForm.onDataChange("locationType", locationType);
  }, [isVendor, isCustomer, user]);

  const save = () => {
    if (userForm.hasChanges) {
      if (!userForm.isValid()) return;

      // also check the company form if the user is new
      if (!useExistingCompany && !companyForm.isValid()) return;

      updateUserInfo();
    }

    if (emailForm.hasChanges) {
      if (!emailForm.isValid()) return;

      updateUserEmailSettings.call(emailForm.data).then(() => {
        emailForm.setData(emailForm.data, true);
      });
    }
  };

  const updateUser = useUpdateUser();
  const createUser = useCreateUser();
  const updateUserEmailSettings = useUpdateUserSettings(user?.id);
  const loading =
    updateUser.isMutating ||
    createUser.isMutating ||
    updateUserEmailSettings.isMutating;

  const updateUserInfo = () => {
    let params = {
      ...userForm.data,
      id: user?.id,
      username: userForm.data.email,
      password: "",
      language: "en",
    };
    if (!useExistingCompany) {
      const d = companyForm.data;
      params["company"] = d;

      if (companyForm.data.addLocation) {
        params["location"] = {
          name: d.locationName,
          addressCountry: d.locationAddressCountry,
          addressRegion: d.locationAddressRegion,
          addressCity: d.locationAddressCity,
          addressStreet: d.locationAddressStreet,
          addressStreet2: d.locationAddressStreet2,
          addressZip: d.locationAddressZip,
          type: d.locationType,
          accountManagerUserId: d.locationAccountManagerUserId,
          additionalResponsibles: d.locationAdditionalResponsibles,
          incoterm: d.locationIncoterm,
          portId: d.locationPortId,
          defaultMarginAsFraction: d.locationDefaultMarginAsFraction,
          billToName: d.billToName,
          billToAddressCity: d.billToAddressCity,
          billToAddressCountry: d.billToAddressCountry,
          billToAddressRegion: d.billToAddressRegion,
          billToAddressStreet1: d.billToAddressStreet1,
          billToAddressStreet2: d.billToAddressStreet2,
          billToAddressZip: d.billToAddressZip,
          paymentTermsDescription: d.locationPaymentTermsDescription,
          useCompanyAddress: d.locationUseCompanyAddress,
          useLocationAddress: d.billToUseLocationAddress,
        };
      } else {
        params["location"] = {
          type: d.locationType,
          accountManagerUserId: d.locationAccountManagerUserId,
        };
      }
    }

    if (!user) {
      createUser
        .call({
          ...params,
          sendInvitation: false,
        })
        .then((user) => {
          history.replace(usersRootPath + user.id);
          trackEvent("User Created");
        })
        .catch(Toast.ApiError);
    } else {
      updateUser
        .call(params)
        .then((user) => {
          dispatch(
            savePreferences({
              showBellNotifications: user.showBellNotifications,
            }),
          );
          userForm.setData(userForm.data, true);
          trackEvent("User Updated");
        })
        .catch(Toast.ApiError);
    }
  };

  const saveAndSendInvite = () => {
    updateUser
      .call({
        ...userForm.data,
        id: user.id,
        registrationStatus: Registration.EMAIL_SENT,
        password: "",
        language: "en",
      })
      .then(() => {
        history.push(usersRootPath);
        trackEvent("User Invite Sent");
      })
      .catch(Toast.ApiError);
  };

  const removeUser = useRemoveUser(user?.id || -1);
  const onDeleteUser = useCallback(async () => {
    try {
      await removeUser.call({});
      history.replace(usersRootPath);
      trackEvent("User Removed");
    } catch (e) {
      Toast.ApiError(e);
    }
  }, []);

  const shouldShowSandRCode =
    !EXTERNAL_USER_PERMISSIONS.every((p) => userPermissions.includes(p)) &&
    !!userForm.data.userGroupId;
  const shouldShowLocationsSelect =
    (isCustomer || isVendor) && !!userForm.data.userGroupId;

  const notificationLevels = useMemo(
    () => Object.values(NotificationLevelEnum),
    [],
  );

  const handleNotificationLevelChange = async (
    level: NotificationLevelEnum,
  ) => {
    userForm.onDataChange("defaultNotificationLevel", level);
  };

  return (
    <>
      {(userForm.hasChanges || emailForm.hasChanges) &&
        !createUser.isMutating &&
        !removeUser.isMutating && <UnsavedChangesDialog onSave={save} />}

      <div className="flex bg-black-12 rounded-xxs overflow-auto p-xl">
        {user && (
          <UserProfilePhoto
            userId={user.id}
            id="photoAttachmentId"
            userForm={userForm}
            disabled={!canEdit}
            className="mb-2xl"
          />
        )}

        <div className="flex-row flex-wrap text-left gap-xl">
          <div className="flex flex-basis-8xl flex-col gap-md">
            <div className="text-semibold text-left text-xl">
              Account Details
            </div>
            <div className="flex-row w-full">
              <FormInput
                id="name"
                formData={userForm}
                label="title.first.name"
                containerClassName="flex mr-md"
                disabled={!canEdit}
              />
              <FormInput
                id="surname"
                formData={userForm}
                label="title.last.name"
                containerClassName="flex-2"
                disabled={!canEdit}
              />
            </div>
            <FormInput
              id="email"
              label="title.email"
              formData={userForm}
              disabled={!isInternalUser || !canEdit}
            />

            {isAdmin && (
              <>
                <div className="flex-row align-baseline">
                  <div className="text-bold mr-xs">
                    <LC id="title.user.reg.status.label" />
                  </div>
                  <div>
                    {formatRegistrationStatus(userForm.data.registrationStatus)}
                  </div>
                  {isInvitationPending && canEdit && <TriggerInvitationForm user={user} />}
                </div>
                <div className="flex-row align-baseline">
                  <div className="text-semibold flex-row align-center text-left gap-sm">
                    <div className="text-bold">
                      <LC id="title.active" />:
                    </div>
                    {userForm.data.active ? "True" : "False"}
                  </div>
                </div>
                {!isUserDetails && !isDeletedUser && (
                  <div>
                    <UserChangePasswordButton
                      userId={user.id}
                      email={userForm.data.email}
                    />
                  </div>
                )}
              </>
            )}

            <div className="text-semibold text-left text-xl">
              <LC id="title.user.company.permissions" />
            </div>
            {!user && (
              <FormSwitch
                id="useExistingCompany"
                formData={userForm}
                label="title.existing.company"
              />
            )}

            {(useExistingCompany || user) && (
              <CompanyCombobox
                id="companyId"
                formData={userForm}
                filters={{ onlyMy: !isAdmin }}
                label="title.company"
                disabled={!isInternalUser || !canEdit || isDeletedUser}
                dataCy="CompanyComboBox"
              />
            )}

            {isInternalUser && (
              <>
                <UserGroupSelect
                  id="userGroupId"
                  formData={userForm}
                  label="title.user.group"
                  activeOnly
                  disabled={
                    (!!user && !isAdmin) ||
                    (!userForm.data.companyId && useExistingCompany) ||
                    isDeletedUser
                  }
                  dataCy={"UserGroupSelect"}
                />
                {shouldShowSandRCode && (
                  <FormInput
                    id="sr"
                    formData={userForm}
                    type="number"
                    min={0}
                    max={999999999}
                    disabled={!isAdmin || isDeletedUser}
                    label="title.s.andr.code"
                  />
                )}
              </>
            )}

            {useExistingCompany && shouldShowLocationsSelect && (
              <LocationCombobox
                id="locations"
                formData={userForm}
                label="title.locations"
                multiple
                showClear
                filters={{
                  companyId: [userForm.data.companyId],
                }}
                disabled={!isInternalUser || !canEdit || isDeletedUser}
              />
            )}

            {!useExistingCompany && !user && (
              <>
                <CompanyForm formData={companyForm} isVendor={isVendor} />

                <div className={"text-semibold text-left mt-2xl text-xl"}>
                  <LC id={isVendor ? "title.ship.from" : "title.ship.to"} />
                </div>
                <FormSwitch
                  id="addLocation"
                  formData={companyForm}
                  label={isVendor ? "title.add.ship.from" : "title.add.ship.to"}
                />

                {companyForm.data.addLocation && (
                  <LocationForm formData={companyForm} isShipFrom={isVendor} />
                )}
              </>
            )}
          </div>

          <div className="flex-2 flex-basis-8xl flex-row justify-center">
            <div className="flex-col gap-md">
              {(isAdmin || userIdIsCurrentUser) && user && (
                <>
                  <div
                    id="user-preferences"
                    className="text-semibold text-left mt-2xl text-xl"
                  >
                    Notification Preferences
                  </div>
                  <FormSwitchDescriptive
                    id="showBellNotifications"
                    formData={userForm}
                    label="title.show.notifications.bell"
                    description="Displays bell notifications."
                  />
                  <DescriptiveRadioGroup
                    id="radioNotificationPreferences"
                    data={notificationLevels}
                    onChange={(id, value) =>
                      handleNotificationLevelChange(value)
                    }
                    getLabel={(level) => (
                      <LC
                        id={
                          `title.notification.config.option.${level}` as LocalizedMessageKey
                        }
                      />
                    )}
                    getDescription={(level) => (
                      <LC
                        id={
                          `title.notification.config.option.description.${level}` as LocalizedMessageKey
                        }
                      />
                    )}
                    value={userForm.data.defaultNotificationLevel}
                  />
                </>
              )}

              {(isAdmin || userIdIsCurrentUser) && user && !isDeletedUser && (
                <UserDetailsEmailSettings
                  user={user}
                  emailForm={emailForm}
                  isCurrentUser={userIdIsCurrentUser}
                  isVendor={isVendor}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {canEdit && (
        <BottomButtonsPanel>
          {isUserDetails && user && userForm.data.active && (
            <DeleteDialog
              title="title.inactivate.user"
              onDelete={onDeleteUser}
              deleteText="title.inactivate"
              trigger={
                <Button
                  color="red"
                  label="title.inactivate.user"
                  data-cy={"InactivateUserButton"}
                />
              }
            >
              <div className="text-bold">
                <LC id={"phrase.user.delete.dialog.1"} />
              </div>
              <div>
                <LC id={"phrase.user.delete.dialog.2"} />
              </div>
            </DeleteDialog>
          )}

          {isUserDetails && !user && (
            <Button color="gray" onClicked={onBack} label="title.cancel" />
          )}

          {userForm.data.registrationStatus != Registration.NOT_SENT && (
            <Button
              onClicked={save}
              disabled={!userForm.hasChanges && !emailForm.hasChanges}
              loading={loading}
              label={user ? "title.save.changes" : "title.create.user"}
              data-cy={"CreatingUserOrSave"}
            />
          )}

          {isUserDetails &&
            user &&
            userForm.data.registrationStatus === Registration.NOT_SENT && (
              <Button
                onClicked={saveAndSendInvite}
                loading={loading}
                label="title.send.invite.button.title"
                data-cy={"SendInviteButton"}
              />
            )}
        </BottomButtonsPanel>
      )}
    </>
  );
};

export default UserDetailsPageProfileTab;
