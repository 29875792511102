import useSWR from "swr";
import API, { LandingPage } from "~/API";
import { useMutateHook } from "~/Hooks";

export const useLandingPages = () =>
  useSWR("api/landing/pages", async () => API.getLandingPages());

export const useLandingPageByName = (name: string) =>
  useSWR(["api/landing/pageByName", name], async () => {
    const landingPage = await API.getLandingPageByName({ name });
    const attachments = await API.getLandingPageAttachments({
      id: landingPage.id,
    });
    return { ...landingPage, attachments };
  });

export const useCreateLandingPage = () =>
  useMutateHook<LandingPage, LandingPage[]>(
    "api/landing/pages",
    async (params) => {
      await API.createLandingPage({}, params);
    },
    (old, params) => [...old, params],
  );

export const useUpdateLandingPage = () =>
  useMutateHook<LandingPage, LandingPage[]>(
    "api/landing/pages",
    async (params) => {
      await API.updateLandingPage({ id: params.id }, params);
    },
    (oldData, args) => {
      const index = oldData.findIndex((d) => d.id === args.id);
      const newData = [...oldData];
      newData[index] = args;
      return newData;
    },
  );
