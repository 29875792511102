import { OfferView } from "~/API";
import { useCallback, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { LocalizedMessage } from "~/Locales";
import { offersRootPath } from "~/Services/Routes";
import { useToggle } from "~/Hooks/Old";
import { formattedNumber } from "~/Utils/PriceCalculation";
import { useFindCurrencySymbol } from "~/Reducers/Currencies";
import { useIsInternalUser } from "~/Reducers/User";
import {
  useOffersPageByGroupReduxState,
  useOffersPageReduxState,
} from "../Reducer";
import {OfferShowUnread, OfferStatusLabel} from "./OffersHelper";
import Button from "~/Components/Old/Button";
import { DateTimeView } from "~/Components/Views/DateView";
import { ArrowDownIcon, ArrowRightIcon, HidePassIcon } from "~/Components/Old/Icons";
import IncotermDestinationLabel from "~/Components/Old/IncotermDestinationLabel";
import LinearProgress from "~/Components/Old/LinearProgress";
import OfferReference from "./OfferReference";
import OfferArchiveDeleteButtons from "./OfferArchiveDeleteButtons";
import { useFilters } from "~/Components/Old/useFilters";
import {
  getRequestedOffersByStatus, isOfferDraftStatus, isOfferExpired,
  useOffer,
  useOfferProductCodes,
  useOfferValue
} from "~/Reducers/Offers";
import { classNames } from "react-ui-basics/Tools";
import './OffersPageTableWithSubrows.scss';
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";


const OffersPageByStatusTable = ({ filters, pageFilters }) => {

  const { statuses } = useOffersPageReduxState();

  return (
    <table className={'basic-table OffersPageTableWithSubrows'}>
      {statuses.map(status => <StatusRow key={status.status} {...{ status, filters, pageFilters }} />)}
    </table>
  );
};

export default OffersPageByStatusTable;


const StatusRow = ({ status, filters }) => {
  const [expanded, expandCollapse] = useToggle(false);

  const { numberOfOffers, totalValue, currency } = status;
  const currencySymbol = useFindCurrencySymbol(currency);

  const isInternalUser = useIsInternalUser();

  return (
    <>
      <thead>
        <tr onClick={expandCollapse} className={'ClickableHeaderOrRow'}>
          <th>{expanded ? <ArrowDownIcon size={26} /> : <ArrowRightIcon size={26} />}</th>
          <th colSpan={2}><OfferStatusLabel offerStatus={status.status} alwaysShowColor /></th>
          <th colSpan={3}>
            <div className={'flex-row align-center'}>
              Number of Offers:
              &nbsp;
              <div className={'color-orange-1'}>{numberOfOffers}</div>
            </div>
          </th>
          <th>
            <div className={'flex-row align-center'}>
              <LocalizedMessage id={'title.total.value'} />:
              &nbsp;
              <div className={'color-orange-1'}>{`${currencySymbol} ${formattedNumber(totalValue)}`}</div>
            </div>
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
          {isInternalUser && <th />}
        </tr>
      </thead>
      {expanded && <OffersForStatus status={status.status} filters={filters} />}
    </>
  );
};

const ITEMS_PER_PAGE = 10;
const filtersToExclude = ['status', 'limit'];
const EMPTY_ARRAY = [];

const OffersForStatus = ({ status, filters }) => {
  const pageDataByGroupId = useOffersPageByGroupReduxState(status);

  const isInternalUser = useIsInternalUser();

  const pageFilters = useMemo(() => ({ ...filters, status: [status], limit: ITEMS_PER_PAGE })
    , [status, filters]);
  const pageDataByStatusReduxFunc = useCallback(() => pageDataByGroupId, [pageDataByGroupId]);

  const { onScrolledToBottom, canLoadMore } = useFilters(
    pageFilters, getRequestedOffersByStatus, pageDataByStatusReduxFunc, null, null, filtersToExclude);

  const { ids = EMPTY_ARRAY, loading, error } = pageDataByGroupId;

  const showTryAgain = !loading && !!error;
  const showGetMore = !loading && !error && canLoadMore;
  const showLoader = !!loading;
  const showAdditionalRow = showTryAgain || showGetMore || showLoader;

  return (
    <tbody>
      <tr className={'OfferRowAsSubrow'}>
        <th />
        <th><LocalizedMessage id={'title.o.id'} /></th>
        <th />
        {/*<th><LocalizedMessage id={'title.ship.to'} /></th>*/}
        {/*<th><LocalizedMessage id={'title.vendor'} /></th>*/}
        <th><LocalizedMessage id={'title.buyer'} /></th>
        <th><LocalizedMessage id={'title.ship.from'} /></th>
        <th>Incoterm Destination</th>
        <th><LocalizedMessage id={'title.products'} /></th>
        <th><LocalizedMessage id={'title.product.code'} /></th>
        {isInternalUser && <th><LocalizedMessage id={'title.reference'} /></th>}
        <th className={'text-right'}><LocalizedMessage id={'title.value'} /></th>
        <th><LocalizedMessage id={'title.validity.date'} /></th>
        <th><LocalizedMessage id={'title.actions'} /></th>
        <th />
      </tr>
      {ids.map(offerId => <OfferRow key={offerId} groupId={status} {...{ offerId }} />)}

      {showAdditionalRow &&
        <tr className={'OfferRowAsSubrow'}>
          {!showLoader &&
            <td colSpan={13} className={'MoreButtonCell'}>
              <Button view={'text'} className={classNames('w-full', showTryAgain && 'DeleteButtonText')}
                onClick={onScrolledToBottom}
              >
                <LocalizedMessage id={showTryAgain ? 'title.try.again' : 'title.get.more'} />
              </Button>
            </td>
          }
          {showLoader && <td colSpan={13}><LinearProgress /></td>}
        </tr>
      }
    </tbody>
  );
};

const EMPTY_OBJECT = {};

const OfferRow = ({ offerId, groupId }) => {
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const offer = useOffer(offerId) || EMPTY_OBJECT as OfferView;
  const value = useOfferValue(offerId);
  const productCodes = useOfferProductCodes(offerId);
  const {
    status,
    validityDate,
    shipFromName,
    currency,
    products,
    requestForQuoteId,
    quotes,
    purchaseOrders,
    buyerName = "",
    incoterm = '',
    incotermAddressDestination = '',
    incotermPortName = '',
    unreadChatMessages = 0,
  } = offer;

  const currencySymbol = useFindCurrencySymbol(currency);
  const isDraft = isOfferDraftStatus(status);
  const isExpired = isOfferExpired(validityDate);

  const onOpen = useCallback(() => history.push(`${offersRootPath}${offerId}`), [offerId, history]);

  return (
    <tr className={classNames('OfferRowAsSubrow', OfferShowUnread(offer.readMark, isExpired) ? "row-unread" : "")}>
      <td />
      <td className={'no-wrap'}>{`O-${offerId}`}</td>
      <td>{isExpired && 'Expired'}</td>
      <td>{buyerName}</td>
      <td>{shipFromName}</td>
      <td>
        <IncotermDestinationLabel
          incoterm={incoterm}
          address={incotermAddressDestination}
          portName={incotermPortName}
        />
      </td>
      <td>{`${products?.length || 0}`}</td>
      <td>{productCodes}</td>
      {isInternalUser && <td><OfferReference {...{ requestForQuoteId, purchaseOrders, quotes }} simple /></td>}
      <td className={'text-right no-wrap color-orange-1'}>
        {`${currencySymbol} ${formattedNumber(value) || '0'}`}
      </td>
      <td><DateTimeView date={validityDate} /></td>
      <td>
        <div className={'flex-row align-center justify-center'}>
          <Button view={'text'} className={'ActionButton mr-xs'} onClick={onOpen}>
            <HidePassIcon size={20} />
          </Button>
          <OfferArchiveDeleteButtons {...{ offer, groupId }} />
        </div>
      </td>
      <td className={"ClickableHeaderOrRow"}>
        {!isDraft && <ChatUnreadBadge unread={unreadChatMessages} />}
      </td>
    </tr>
  );
};
