import {FC} from "react";
import ChatsList from "~/Pages/Chats/Components/ChatsList";
import {Route, Switch} from "react-router-dom";
import {ChatLayout} from "~/Pages/Chats/Components/ChatLayout";

export const ChatsPageLayout: FC<{ internal: boolean }> = (props) => {
  return (
    <div className="flex-row w-full h-full gap-md overflow-hidden">
      <Switch>
        <Route path="/chats/*/:chatId">
          <ChatsList internal={props.internal} className="mobile-hide" />
          <div className="flex-3 bg-black-12 p-md py-xs px-sm overflow-hidden flex-row justify-center rounded-xxs">
            <ChatLayout internal={props.internal} />
          </div>
        </Route>
        <Route path="/">
          <ChatsList internal={props.internal} />
          <div className="mobile-hide flex-3 h-full"/>
        </Route>
      </Switch>
    </div>
  );
};