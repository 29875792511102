interface WithId {
  id: number | string
}

export interface ById<T> {
  [id: number | string]: T;
}

export const composedItems = <T extends WithId>(items: T[]):ById<T> => {
  const itemsDictionary = {} as ById<T>;
  items.forEach(item => {
    if (item) itemsDictionary[item.id] = item;
  });
  return itemsDictionary;
};
