import React from "react";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";
import {useIncotermKeys, useIncotermLabels} from "~/Reducers/Incoterms";

export const IncotermCombobox = <T,>(
  props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  const keys = useIncotermKeys();
  const labels = useIncotermLabels();

  return <FormCombobox {...props} data={keys} getLabel={(l) => labels[l]} />;
};
