import { RequestForQuoteStatus } from "~/API";
import { useMemo } from "react";
import {
  useRequestsForQuote,
  getRequestForQuote,
  getRequestsForQuote,
  useRequestForQuoteAsReferenceLabels
} from "~/Reducers/RequestsForQuote";
import SelectWithPaging from "./SelectWithPaging";
import useFetchMissingItems from "./useFetchMissingItems";


const RequestSelect = ({ showForReference = false, includeValue = null, ...props }) => {
  const requests = useRequestsForQuote();

  useFetchMissingItems(props.value, requests, getRequestForQuote);

  const filter = useMemo(() => {
    const filterToReturn = { query: '' };
    if (showForReference) {
      Object.values(RequestForQuoteStatus)
        .filter(it => it !== RequestForQuoteStatus.DRAFT_AM
          && it !== RequestForQuoteStatus.DRAFT_CUSTOMER
          && it !== RequestForQuoteStatus.ARCHIVED)
        .forEach(it => {
          if (filterToReturn.query.length !== 0) {
            filterToReturn.query = filterToReturn.query + ' || ';
          }
          filterToReturn.query = filterToReturn.query + `STATUS == '${it}'`;
        });

      if (includeValue) {
        filterToReturn.query = filterToReturn.query + ` || ID == ${includeValue}`;
      }
    }

    return filterToReturn;
  }, [showForReference, includeValue]);

  return <SelectWithPaging {...props}
    getData={getRequestsForQuote}
    useDataLabels={useRequestForQuoteAsReferenceLabels}
    filter={{ orderBy: 'DATE_CREATED', ...filter, ...props.filter }}
  />;
};

export default RequestSelect;
