import { FC } from "react";
import { ScrollPage } from "~/Components/Layout/Pages";
import { PageTitle } from "~/Components/Nav/PageTitle";
import {ls} from "~/Locales";

import {CompanyProductsMetricsCard} from "~/Pages/Metrics/Components/CompanyProductsMetricsCard";
import {NewUserMetricsCard} from "~/Pages/Metrics/Components/NewUserMetricsCard";
import {CompaniesMetricsCard} from "~/Pages/Metrics/Components/CompaniesMetricsCard";
import {QuoteMetricsCard} from "~/Pages/Metrics/Components/QuoteMetricsCard";
import {RequestsToQuotesMetricsCard} from "./Components/RequestsToQuotesMetricsCard";
import {QuotesToOrdersMetricsCard} from "./Components/QuotesToOrders";
import {OffersCreatedMetricsCard} from "~/Pages/Metrics/Components/OffersCreatedMetricsCard";
import {OffersToQuotesMetricsCard} from "~/Pages/Metrics/Components/OffersToQuotesMetricsCard";
import {OfferToPOMetricsCard} from "~/Pages/Metrics/Components/OfferToPOMetricsCard";
import {LeadsToSalesMetricsCard} from "~/Pages/Metrics/Components/LeadsToSalesMetricsCard";
import {SalesOrderTransactionsMetricsCard} from "~/Pages/Metrics/Components/SalesOrderTransactionsMetricsCard";
import {PurchaseOrderTransactionsMetricsCard} from "~/Pages/Metrics/Components/PurchaseOrderTransactionsMetricsCard";

import "./MetricsPage.scss";

export const MetricsPage: FC = () => {
  return (
    <ScrollPage title={ls("title.metrics")}>
      <PageTitle title="title.metrics" type="menu" />
      <div className="metrics-page-grid gap-lg">
        <NewUserMetricsCard />
        <CompaniesMetricsCard />
        <OffersCreatedMetricsCard />
        <OffersToQuotesMetricsCard />
        <OfferToPOMetricsCard />
        <RequestsToQuotesMetricsCard />
        <QuoteMetricsCard />
        <QuotesToOrdersMetricsCard />
        <CompanyProductsMetricsCard />
        <LeadsToSalesMetricsCard />
        <SalesOrderTransactionsMetricsCard />
        <PurchaseOrderTransactionsMetricsCard />
      </div>
    </ScrollPage>
  );
};
