import React from "react";
import { countries } from "~/Data/Countries";
import { FormCombobox, FormComboboxProps } from "~/Components/UI";

const keys = countries.map((it) => it.code);
const labels = countries.reduce(
  (a, option) => ({ ...a, [option.code]: `${option.label} (${option.code})` }),
  {},
);

export const CountryCombobox = <T,>(
  props: {} & Omit<FormComboboxProps<T, number>, "getLabel" | "data">,
) => {
  return <FormCombobox {...props} data={keys} getLabel={(l) => labels[l] || l} />;
};
