import { resolveScac } from "~/Data/Scac";
import { useSalesOrder } from "~/Data/Orders/SalesOrders";
import OrderShippingDetails from "../OrderShippingDetails";
import {
  obtainShipViaType,
  ShipViaType,
} from "~/Data/ShipVia";
import { FC } from "react";

const SalesOrderShippingDetails: FC<{ orderId: number }> = ({ orderId }) => {
  const order = useSalesOrder(orderId);
  const {
    transportType,
    vesselNumber,
    vesselName,
    vesselVoyageNumber,
    vesselShippingLine,
    vesselLoadPort,
    vesselDischargePort,
    vesselEquipment,
  } = order.data || {};

  const shipViaType = obtainShipViaType(transportType);

  if (shipViaType !== ShipViaType.Sailing || !vesselNumber) {
    return null;
  }
  const containerNumbers = vesselEquipment
    ? vesselEquipment.split(",")
    : undefined;

  const shippingDetails = {
    shippingLine: vesselShippingLine
      ? resolveScac(vesselShippingLine)
      : undefined,
    vesselAndVoyage: vesselName
      ? vesselName + (vesselVoyageNumber?.length && " - ") + vesselVoyageNumber
      : undefined,
    containerNumbers: containerNumbers,
    waybillNumber: undefined,
    loadPort: vesselLoadPort,
    dischargePort: vesselDischargePort,
  };

  return <OrderShippingDetails shippingDetails={shippingDetails} />;
};

export default SalesOrderShippingDetails;
