import {FC} from "react";
import {Card} from "~/Components/UI";
import {useIsInternalUser} from "~/Reducers/User";
import {
  CatalogueDetailsAddMyProducts
} from "~/Pages/Products/CataloguePage/DetailsPage/Components/CatalogueDetailsAddMyProducts";
import {ProductPublicView} from "~/API";
import {
  CatalogueDetailsCreateEntity
} from "~/Pages/Products/CataloguePage/DetailsPage/Components/CatalogueDetailsCreateEntity";

export const CatalogueDescriptionCard: FC<{
  attrib: Record<string, string>;
  product: ProductPublicView;
}> = ({ attrib, product }) => {
  const isInternalUser = useIsInternalUser();

  return (
    <Card className="flex flex-col flex-basis-min-content">
      <div className="text-xl">Description</div>
      <div className="mt-md color-text-2 text-lg text-normal">
        <div>{attrib.CustomerFacingDescription}</div>
        <div>{attrib.MarketingCopy}</div>
        <ul>
          {attrib.BenefitBullet1 && <li>{attrib.BenefitBullet1}</li>}
          {attrib.BenefitBullet2 && <li>{attrib.BenefitBullet2}</li>}
          {attrib.BenefitBullet3 && <li>{attrib.BenefitBullet3}</li>}
          {attrib.BenefitBullet4 && <li>{attrib.BenefitBullet4}</li>}
          {attrib.BenefitBullet5 && <li>{attrib.BenefitBullet5}</li>}
          {attrib.BenefitBullet6 && <li>{attrib.BenefitBullet6}</li>}
          {attrib.BenefitBullet7 && <li>{attrib.BenefitBullet7}</li>}
          {attrib.BenefitBullet8 && <li>{attrib.BenefitBullet8}</li>}
          {attrib.BenefitBullet9 && <li>{attrib.BenefitBullet9}</li>}
          {attrib.BenefitBullet10 && <li>{attrib.BenefitBullet10}</li>}
        </ul>
      </div>
      {!isInternalUser && (
        <div className="flex flex-row gap-sm align-end justify-end">
          <CatalogueDetailsAddMyProducts product={product} />
          <CatalogueDetailsCreateEntity product={product} />
        </div>
      )}
    </Card>
  );
};
