import { Notification, NotificationRef, NotificationType } from "~/API";
import { isDifferent } from "react-ui-basics/Tools";
import { useState } from "react";
import { Case, For, Match, Show, Switch } from "~/Components/UI";
import { TbBellX } from "react-icons/tb";
import { LC } from "~/Locales";
import { SystemNotification } from "~/Components/Notifications/Cards/SystemNotification";
import { ChatNotification } from "~/Components/Notifications/Cards/ChatNotification";
import { NewUserCard } from "~/Components/Notifications/Cards/NewUserNotification";
import { QuoteNotification } from "~/Components/Notifications/Cards/QuoteNotification";
import { OfferNotification } from "~/Components/Notifications/Cards/OfferNotification";
import {
  savePreferences,
  useUserPreferences,
} from "~/Reducers/UserPreferences";
import { useAppDispatch } from "~/StoreTypes";
import { onScrollToBottom } from "~/Utils";
import {markNotificationRead, useNotifications} from "~/Data/Notifications";
import {LinearLoader} from "~/Components/Loaders";
import {ChangeNotification} from "~/Components/Notifications/Cards/ChangeNotification";
import NotificationDeleteReadButtons from "~/Components/Notifications/NotificationDeleteReadButtons";

const isSameTarget = (a: Notification, b: Notification) => {
  if (a.data.type !== b.data.type) return false;

  if (a.data.type === NotificationType.NEW_CHAT_MESSAGE) {
    return !isDifferent(
      a.data.refs[NotificationRef.CHAT],
      b.data.refs[NotificationRef.CHAT],
    );
  }

  return false;
};

const groupNotifications = (
  notifications: Notification[],
): Notification[][] => {
  const groups = [];
  let group: Notification[] = null;
  notifications.forEach((it) => {
    if (group == null || !isSameTarget(it, group[0])) {
      group = [];
      groups.push(group);
    }
    group.push(it);
  });

  return groups;
};

export default () => {
  const dispatch = useAppDispatch();
  const [onlyUnread, setOnlyUnread] = useState(true);
  const notifications = useNotifications(onlyUnread);
  const notificationGroups = groupNotifications(notifications.data?.flat() || []);

  const refetchNotifications = () => notifications.mutate(notifications.data, { revalidate: () => true });
  const userPreferences = useUserPreferences();

  const onSilentMode = ({ target }) => {
    dispatch(savePreferences({ silentMode: target.checked }));
  };

  const onUnreadOnly = ({ target }) => {
    setOnlyUnread(target.checked);
    refetchNotifications();
  };

  const onListScroll = onScrollToBottom(() => {
    notifications.setSize(notifications.size + 1).then();
  }, 256);

  return (
    <div className="flex w-max-9xl flex-col">
      <div className="p-sm flex-row gap-sm justify-between">
        <Switch
          id="silentMode"
          onChange={onSilentMode}
          value={userPreferences.silentMode}
          label="phrase.silent.mode"
        />
        <Switch
          id="onlyUnread"
          onChange={onUnreadOnly}
          value={onlyUnread}
          label="phrase.only.unread"
        />
      </div>

      <LinearLoader loading={notifications.isLoading} />
      <Show when={notificationGroups?.length > 0} fallback={<EmptyState />}>
        <div className="overflow-hidden">
          <div
            onScroll={onListScroll}
            className="overflow-y-auto h-max-9xl flex-col p-xs gap-sm h-min-0"
          >
            <For each={notificationGroups}>
              {(group) => (
                <Match on={group[0].data.type}>
                  <Case v={NotificationType.NEW_CHAT_MESSAGE}>
                    <ChatNotification
                      key={group[0].id}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      notification={group[0]}
                      count={group.length}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                  <Case v={NotificationType.USER_REGISTERED}>
                    <NewUserCard
                      key={group[0].id}
                      notification={group[0]}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                  <Case v={NotificationType.QUOTE_FEEDBACK}>
                    <QuoteNotification
                      key={group[0].id}
                      notification={group[0]}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                  <Case v={NotificationType.OFFER_FEEDBACK}>
                    <OfferNotification
                      key={group[0].id}
                      notification={group[0]}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                  <Case v={NotificationType.SYSTEM_MESSAGE}>
                    <SystemNotification
                      key={group[0].id}
                      notification={group[0]}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                  <Case v={NotificationType.FIELD_CHANGE}>
                    <ChangeNotification
                      key={group[0].id}
                      notification={group[0]}
                      markRead={() => markNotificationRead(group).then(refetchNotifications)}
                      refetchNotifications = {refetchNotifications}
                    />
                  </Case>
                </Match>
              )}
            </For>
          </div>
        </div>
      </Show>
      <NotificationDeleteReadButtons
          refetchNotifications = {refetchNotifications}
      />
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="flex flex-col gap-lg color-black-7 h-full align-center justify-center">
      <TbBellX size={32} />
      <div className="text-lg">
        <LC id="phrase.no.notifications.1" />
      </div>
    </div>
  );
};
