import {OfferStatus, OfferView} from "~/API";
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {LocalizedMessage} from '~/Locales';
import {offersRootPath, requestedOffersRootPath, useIsRFOPath} from '~/Services/Routes';
import {useToggle} from "~/Hooks/Old";
import {closeLeftMenu} from "~/Reducers/LeftMenu";
import {useAppDispatch} from "~/StoreTypes";
import {addFollower, markOfferRead, removeFollower, useOffer} from "~/Reducers/Offers";
import {useRouter} from '~/Reducers/Router';
import {useCurrentUser, useIsInternalUser} from "~/Reducers/User";
import {useIsEdit, useIsMatching, useIsNew} from "../Components/OffersHelper";
import Button from '~/Components/Old/Button';
import DetailsBackButton from "~/Components/Old/DetailsBackButton";
import {FollowButton} from "~/Components/Entities/FollowButton";
import {ChatIcon} from '~/Components/Old/Icons';
import UserParticipantList from '~/Components/Users/UserParticipantList';
import PageTitleContainer from '~/Components/Old/PageTitleContainer';
import RightPanel from "~/Components/Layout/RightPanel";
import SpaceFiller from "~/Components/Old/SpaceFiller";
import Vars from "~/Styles/Vars";
import OfferDetailsTab from './OfferDetailsTab';
import OfferChat from "./Components/OfferChat";
import './OfferDetailsPage.scss';
import {useUsersList} from "~/Data/Users";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {getOfferChatId, useChatOffer} from "~/Data/Chats";
import {trackEvent} from "~/Services/Tracking";


const OfferDetailsPage: FC = () => {
  const history = useHistory();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const {id: offerIdFromUrl} = useParams<{id: string}>();

  const isRFO = useIsRFOPath();
  const offersListPath = isRFO ? requestedOffersRootPath : offersRootPath;

  const isInternalUser = useIsInternalUser();

  const offerId = offerIdFromUrl === 'new' ? null : Number(offerIdFromUrl);
  const isEdit = useIsEdit();
  const isNew = useIsNew();
  const isMatching = useIsMatching();

  const chatExternal = useChatOffer(offerId, false, isInternalUser);

  useEffect(() => {
    dispatch(closeLeftMenu());

    if (!offer.readMark && !isNew)
      dispatch(markOfferRead(offerId));
  }, []);

  const offer = useOffer(offerId, true) || {} as OfferView;
  const {
    status = OfferStatus.O_DRAFT,
    members = [],
  } = offer;
  const membersList = useUsersList(members);

  const onBack = useCallback(() => {
    if (!!offerId && (isEdit || isMatching)) {
      history.replace(`${offersListPath}${offerId}`);
    } else if (!router.hasHistory) {
      history.replace(isRFO ? requestedOffersRootPath : offersListPath);
    } else {
      history.goBack();
    }
  }, [router, history, offerId, isEdit, isRFO, offersListPath]);

  useEffect(() => {
    setChatsExist(!!chatExternal.data);
  }, [chatExternal.data]);

  const [chatsExist, setChatsExist] = useState(false);
  const [showChatPanel, toggleShowChatPanel] = useToggle(false);

  const onAddMember = useCallback((userId) => {
    dispatch(addFollower(offerId, userId));
    trackEvent("Offer Follower Added");
  }, [offerId, dispatch]);

  const onRemoveMember = useCallback((userId) => {
    dispatch(removeFollower(offerId, userId));
    trackEvent("Offer Follower Removed");
  }, [offerId, dispatch]);

  const isMember = useMemo(() => members?.some(memberId => memberId === currentUser.id), [members, currentUser]);
  const showMembers = !isNew && isMember;
  const onFollow = useCallback(async () => onAddMember(currentUser.id), [onAddMember, currentUser]);
  const onUnfollow = useCallback(async () => onRemoveMember(currentUser.id), [onRemoveMember, currentUser]);

  const chat = getOfferChatId(offerId);
  return (
    <>

      <div className={'scroll-view'}>
        <PageTitleContainer menuButton={
          <DetailsBackButton {...{onBack}} />
        } title={''}>
        </PageTitleContainer>

        <div className={'flex-row flex-wrap align-center mx-lg mt-lg mb-xs'}>
          <div className="text-xl">
            {isInternalUser
              ? <LocalizedMessage id={isRFO ? 'title.rfo.id.with.number' : 'title.offer.id.with.number'}>
                {offerId}
              </LocalizedMessage>
              : <>
                <LocalizedMessage id={
                  isRFO
                    ? status === OfferStatus.RFO_DRAFT_VENDOR ? 'title.offer.id.with.number' : 'title.request.id.with.number'
                    : 'title.offer.id.with.number'
                }>
                  {offerId}
                </LocalizedMessage>
              </>
            }
            {/*<OfferStatusLabel offerStatus={status} className={'ml-md H3'} />*/}
          </div>

          <SpaceFiller/>

          {!isNew &&
            <FollowButton
              {...{isMember, onFollow, onUnfollow}}
              chat={chat.data}
              followLabel={isRFO ? 'title.follow.request.offer' : 'title.follow.offer'}
              unfollowDialogTitle={isRFO ? 'phrase.unfollow.request.offer.confirm.1' : 'phrase.unfollow.offer.confirm.1'}
              unfollowDialogText={isRFO ? 'phrase.unfollow.request.offer.confirm.2' : 'phrase.unfollow.offer.confirm.2'}
            />
          }
          {showMembers &&
            <>
              <UserParticipantList
                members={membersList.data || []}
                onAddMember={onAddMember}
                onRemoveMember={onRemoveMember}
              />
              {chatsExist && <Button view={'text'} className={'px-0 mx-sm'}
                                     leftIcon={<ChatIcon size={16}/>}
                                     rightIcon={<ChatUnreadBadge unread={offer.unreadChatMessages}/>}
                                     style={{color: showChatPanel ? Vars.colors.blue1 : Vars.colors.text1}}
                                     onClick={toggleShowChatPanel}>
                <LocalizedMessage id={'title.chat'}/>
              </Button>
              }
            </>
          }
        </div>
        <OfferDetailsTab {...{onBack}} />
      </div>

      {chatsExist && <RightPanel visible={showChatPanel}>
        <OfferChat onClose={toggleShowChatPanel} offerId={offerId}/>
      </RightPanel>}
    </>
  );
};

export default OfferDetailsPage;

