import {FC, ReactNode, useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {cn} from "~/Utils";
import dayjs from "dayjs";
import {MapNotificationRefToStringArray, NotificationRef, NotificationType} from "~/API";
import {ordersPathPurchase, ordersPathSales, ordersRootPath} from "~/Services/Routes";
import {useChat} from "~/Data/Chats";
import {Button} from "~/Components/UI";
import { TbTrash, TbEye, TbEyeClosed} from "react-icons/tb";
import {deleteNotification} from "~/Data/Notifications";
import {trackEvent} from "~/Services/Tracking";

export const NotificationCard: FC<{
  icon: ReactNode;
  link: string;
  dateCreated: string;
  markRead: () => void;
  read: boolean;
  children: ReactNode;
  notificationId: number;
  refetchNotifications: () => void;
}> = (props) => {

  const onConfirmDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    deleteNotification(props.notificationId).then(props.refetchNotifications);
    trackEvent("Notification Deleted");
  };

  const onMarkRead = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.markRead();
    props.refetchNotifications();
    trackEvent("Notification Read");
  };


  return (
    <Link
        to={props.link}
        onClick={props.markRead }
        className={cn(
            props.read && "color-text-3 hover-bg-black-11",
            !props.read && "color-text-1 bg-black-11 hover-bg-black-10",
            "flex-none rounded-xs gap-sm p-sm flex-row cursor-pointer text-no-decoration h-min-2xl "
        )}
    >
      <div>{props.icon}</div>
      <div className="flex-col flex">
        <div className="overflow-wrap-anywhere">{props.children}</div>
        <div className="color-text-3">{dayjs(props.dateCreated).fromNow()}</div>
      </div>

      {!props.read &&
        <Button
            color="gray"
            variant="text"
            onClicked={onMarkRead}
            leftIcon={<TbEye size={16}/>}
        />
      }

      {props.read &&
        <Button
            color="gray"
            variant="text"
            leftIcon={<TbEyeClosed size={16}/>}
        />
      }

      <Button
        color="red"
        variant="text"
        onClicked={onConfirmDelete}
        leftIcon={<TbTrash size={16}/>}
      />
    </Link>
  );
};

export const useLink = (
  refs: MapNotificationRefToStringArray,
  type: NotificationType,
) => {
  const [chatId, setChatId] = useState<number | undefined>(undefined);
  const chat = useChat(chatId);

  useEffect(() => {
    if (
      type === NotificationType.NEW_CHAT_MESSAGE &&
      !!refs[NotificationRef.CHAT]?.[0]
    ) {
      setChatId(Number(refs[NotificationRef.CHAT]?.[0]));
    }
  }, [refs, type]);

  return useMemo(() => {
    if (refs[NotificationRef.DELIVERY_BUYER]) {
      const rootPath = ordersRootPath + ordersPathSales + "/";
      return rootPath + refs[NotificationRef.DELIVERY_BUYER]?.[0];
    }
    if (refs[NotificationRef.PURCHASE_ORDER]) {
      const rootPath = ordersRootPath + ordersPathPurchase + "/";
      return rootPath + refs[NotificationRef.PURCHASE_ORDER]?.[0];
    }
    if (refs[NotificationRef.PRODUCT_SELLER])
      return "/my-products/" + refs[NotificationRef.PRODUCT_SELLER]?.[0];
    if (refs[NotificationRef.REQUEST_BUYER])
      return "/requests/" + refs[NotificationRef.REQUEST_BUYER]?.[0];
    if (refs[NotificationRef.REQUEST_SELLER])
      return "/requests/" + refs[NotificationRef.REQUEST_SELLER]?.[0];
    if (refs[NotificationRef.REQUEST])
      return "/offers/" + refs[NotificationRef.REQUEST]?.[0];
    if (refs[NotificationRef.MARKETPLACE_USER]) return "/marketplace";
    if (refs[NotificationRef.QUOTE])
      return "/quotes/" + refs[NotificationRef.QUOTE]?.[0];
    if (refs[NotificationRef.OFFER])
      return "/offers/" + refs[NotificationRef.OFFER]?.[0];
    if (refs[NotificationRef.CHAT])
      return (
        `/chats/${chat.data?.internal ? "internal" : "external"}/` +
        refs[NotificationRef.CHAT]?.[0]
      );

    return "/";
  }, [refs, chat.data]);
};
