import React, { FC, useEffect, useState } from "react";
import DateView from "~/Components/Views/DateView";
import {
  usePurchaseOrder,
  usePurchaseOrderConfirmPromiseDate,
  usePurchaseOrderUpdatePromiseDate
} from "~/Data/Orders/PurchaseOrders";
import DividerWithText from "~/Components/Layout/DividerWithText";
import Toast from "~/Components/Toast";
import {Button, Dialog} from "~/Components/UI";
import {useToggle} from "~/Hooks";
import {DateInput} from "~/Components/UI/Forms/DateInput";
import {useIsInternalCustomer} from "~/Reducers/User";
import {trackEvent} from "~/Services/Tracking";

let todayDate = new Date();

const PurchaseOrderUpdatePromiseDateDialog: FC<{
  orderId: number;
}> = ({ orderId }) => {
  const open = useToggle();
  const internalCustomer = useIsInternalCustomer();
  const order = usePurchaseOrder(orderId, internalCustomer);
  const [updatedDate, setUpdatedDate] = useState<string | null>(null);
  const updatePromiseDate = usePurchaseOrderUpdatePromiseDate(orderId);
  const confirmPromiseDate = usePurchaseOrderConfirmPromiseDate(orderId);

  useEffect(() => {
    setUpdatedDate(order.data?.promiseDateRevised ?? order.data?.promiseDate);
  }, [order.data]);

  const onUpdate = () => {
    updatePromiseDate.call({ promiseDate: updatedDate })
      .then(() => Toast.Success("Promise Date Updated."))
      .then(open.toggleFalse)
      .then(() => trackEvent("Updated PO Promise Date"))
      .catch(Toast.ApiError);
  };

  const onConfirm = () => {
    confirmPromiseDate.call({})
      .then(() => Toast.Success("Promise Date Confirmed."))
      .then(open.toggleFalse)
      .then(() => trackEvent("Confirmed PO Promise Date"))
      .catch(Toast.ApiError);
  };

  const disableUpdateButton =
    updatedDate == null
    || updatedDate === ""
    || new Date(updatedDate) < todayDate
    || confirmPromiseDate.isMutating;

  // slice removes timezone
  const onUpdatedDate = (evt: any) =>
    setUpdatedDate(evt.target.value.slice(0, 10));

  return (
    <Dialog
      trigger={<Button label="title.update.promise.date" color="gray" />}
      title={"Update Promise Date"}
      open={open}
    >
      <div className="flex-col gap-lg mt-lg">
        <div className="flex-row gap-sm align-center">
          <div className="text-bold">Current Promise Date:</div>
          <DateView date={order.data.promiseDateRevised ?? order.data.promiseDate} />
          <div className="flex flex-row justify-end">
            <Button
              onClicked={onConfirm}
              loading={confirmPromiseDate.isMutating}
              disabled={updatePromiseDate.isMutating}
              label="title.confirm"
            />
          </div>
        </div>
        <DividerWithText>or</DividerWithText>
        <div className="flex-row gap-sm align-center">
          <div className="text-bold">Revised Promise Date:</div>
          <DateInput
            id={"promiseDate"}
            value={updatedDate}
            onChange={onUpdatedDate}
            minDate={todayDate}
          />
          <div className="flex flex-row justify-end">
            <Button
              onClicked={onUpdate}
              loading={updatePromiseDate.isMutating}
              disabled={disableUpdateButton}
              label="title.update"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PurchaseOrderUpdatePromiseDateDialog;
