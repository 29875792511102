import {FC, useCallback} from "react";
import { useHistory } from 'react-router-dom';
import { useRouter } from "~/Reducers/Router";
import {Button} from "~/Components/UI";
import {TbArrowBackUp} from "react-icons/tb";


const BackButton: FC<{
  parentUrl: string;
  onBack?: () => void;
}> = (props) => {
  const history = useHistory();
  const router = useRouter();

  const onBackClick = useCallback(() => {
    if (!router.hasHistory) {
      history.replace(props.parentUrl);
    } else {
      history.goBack();
    }
  }, [router, history, props.parentUrl]);

  return (
    <Button
      variant='text'
      color="gray"
      leftIcon={<TbArrowBackUp size={16} />}
      onClicked={props.onBack || onBackClick}
      label="title.back"
    />
  );
};

export default BackButton;
