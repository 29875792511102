import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { LocalizedMessage } from "~/Locales";
import API from "~/API";
import { useFormData, useMutate } from "~/Hooks";
import { Button, Case, FormInput, Match } from "~/Components/UI";
import {productsRootPath} from "~/Services/Routes";
import {trackEvent} from "~/Services/Tracking";

const AddProductDialog: FC = () => {
  const history = useHistory();
  const editForm = useFormData({ code: "" });

  const getCode = useMutate(() =>
    API.getProductFromMsr({ code: editForm.data.code }),
  );

  const onSyncClicked = async () => {
    await getCode.mutate();
    history.push(productsRootPath + `new/${editForm.data.code}`);
    trackEvent("Add Product from MSR");
  }

  return (
    <div className="desktop-w-8xl flex-col gap-sm">
      <FormInput
        id="code"
        placeholder="title.code"
        formData={editForm}
        type="number"
        label="title.product.code"
      />
      <div className="color-red-1 text-semibold">
        <Match on={getCode.error?.status}>
          <Case v={undefined}>
            <div />
          </Case>
          <Case v={404}>
            <LocalizedMessage id={`phrase.failed.product.import.404`} />
          </Case>
          <Case v={409}>
            <LocalizedMessage id={`phrase.failed.product.import.409`} />
          </Case>
          <Case v={500}>
            <LocalizedMessage id={`phrase.failed.product.import.5xx`} />
          </Case>
          <Case default>
            Failed to Import Product
          </Case>
        </Match>
      </div>

      <Button
        loading={getCode.loading}
        label="title.sync.from.msr"
        onClicked={onSyncClicked}
      />
    </div>
  );
};

export default AddProductDialog;
