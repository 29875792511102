import {FC, forwardRef, ReactNode, useEffect, useRef} from "react";
import {NavLink, useLocation} from "react-router-dom";
import { LocalizedMessage, ls } from "~/Locales";
import {useIsAdmin, useIsCustomer, useIsVendor} from "~/Reducers/User";
import {
  myProductsRootPath,
  offersRootPath,
  productCatalogueRootPath,
  productsRootPath
} from "~/Services/Routes";
import {
  TbChartLine,
  TbClipboardList,
  TbClockCog,
  TbFilePencil, TbFlag, TbLayout2,
  TbLayoutGrid,
  TbLogout,
  TbMail,
  TbMessage,
  TbPhotoEdit,
  TbReportMoney,
  TbShip,
  TbTags,
  TbUser, TbUsers,
  TbUsersGroup
} from "react-icons/tb";
import {BiBuildings, BiReceipt} from "react-icons/bi";
import {MdOutlinePrivacyTip} from "react-icons/md";
import {useAppDispatch} from "~/StoreTypes";
import {logoutCurrentUser} from "~/Data/CurrentUser";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {useChatsAllUnreadCount} from "~/Data/Chats";
import {RiMegaphoneLine} from "react-icons/ri";
import {sentryFeedback} from "~/Services/Tracking";
import {cn} from "~/Utils";
import {closeLeftMenu} from "~/Reducers/LeftMenu";
import {Slot, Slottable} from "@radix-ui/react-slot";

export const LeftMenuButton = forwardRef<any, {
  onClick?: () => void;
  label: ReactNode;
  icon: ReactNode;
  minimized: boolean;
  active?: boolean;
  asChild?: boolean;
  children?: ReactNode;
}>((props, ref) => {
  const Comp = props.asChild ? Slot : "button";

  return (
    <Comp
      onClick={props.onClick}
      ref={ref}
      className={cn(
        "flex-1 flex-row gap-xs align-center text-no-decoration",
        "py-xxs px-sm mobile-p-md mobile-text-lg mx-xs my-xxs",
        "rounded-xxs cursor-pointer transition-background",
        "color-text-2 bg-black-12 hover-color-blue-1 hover-bg-blue-10 border-none",
        props.active && "bg-blue-10 color-blue-1",
        props.minimized && "justify-center",
      )}
    >
      <Slottable>{props.children}</Slottable>

      {props.icon}
      {!props.minimized && (
        <span className="text-semibold">
          {props.label}
        </span>
      )}
    </Comp>
  );
});

const LeftMenuItem: FC<{
  path: string,
  text: ReactNode,
  leftIcon: ReactNode,
  onClick?: () => void,
  showAsActive?: boolean,
  minimized: boolean
}> = (props) => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const routeMatches = pathname.includes(props.path);

  const onClick = () => {
    props.onClick?.();
    dispatch(closeLeftMenu());
  }

  return (
    <LeftMenuButton
      label={props.text}
      minimized={props.minimized}
      icon={props.leftIcon}
      onClick={onClick}
      active={props.showAsActive || routeMatches}
      asChild
    >
      <NavLink to={props.path} />
    </LeftMenuButton>
  );
};

const LeftMenuDirectLink: FC<{
  link: string,
  text: string | JSX.Element,
  leftIcon: JSX.Element,
  minimized: boolean
}> = (props) => (
  <LeftMenuButton
    label={props.text}
    minimized={props.minimized}
    icon={props.leftIcon}
    asChild
  >
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer"
    />
  </LeftMenuButton>
);

const MenuSection: FC<{
  header?: JSX.Element,
  children: JSX.Element | JSX.Element[],
}> = ({header, children}) => {
  return (
    <div className="mt-lg flex-col">
      {header && <div className='text-bold'>{header}</div>}
      {children}
    </div>
  );
};

export const ExternalUserMyViewSection: FC<{ minimized: boolean }> = ({minimized}) => {
  const unreadChats = useChatsAllUnreadCount();

  const isCustomer = useIsCustomer();
  const isVendor = useIsVendor();
  return (
    <MenuSection>
      <LeftMenuItem
        path={"/dashboard"}
        minimized={minimized}
        text={<LocalizedMessage id={'title.dashboard'}/>}
        leftIcon={<TbLayout2 className={'MenuItemIcon'} size={24}/>}
      />

      <LeftMenuItem
        path="/chats"
        minimized={minimized}
        text={<div className={'flex-row align-center text-truncate'}>
          <div className={'flex'}><LocalizedMessage id={'title.chats'}/></div>
          <ChatUnreadBadge className='ml-xs' unread={unreadChats.data?.unreadCount || 0}/>
        </div>}
        leftIcon={<div>
          <TbMessage className={'menu-icon'} size={24}/>
        </div>}
      />

      {isCustomer && <LeftMenuItem
        path={'/requests'}
        minimized={minimized}
        text={<LocalizedMessage id={'title.my.requests'}/>}
        leftIcon={<TbFilePencil className={'menu-icon'} size={24}/>}
      />}
      {isVendor && <LeftMenuItem
        path={'/requested-offers'}
        minimized={minimized}
        text={<LocalizedMessage id={'title.my.requests'}/>}
        leftIcon={<TbFilePencil className={'menu-icon'} size={24}/>}
      />}

      {isVendor &&
        <LeftMenuItem
          path={offersRootPath}
          minimized={minimized}
          text={<LocalizedMessage id={'title.my.offers'}/>}
          leftIcon={<TbReportMoney className={'menu-icon'} size={24}/>}
        />
      }

      {isCustomer && <LeftMenuItem
        path={'/quotes'}
        minimized={minimized}
        text={<LocalizedMessage id={'title.my.quotes'}/>}
        leftIcon={<TbClipboardList className={'menu-icon'} size={24}/>}
      />}

      <LeftMenuItem
        path="/orders"
        minimized={minimized}
        text={<LocalizedMessage id={'title.my.orders'}/>}
        leftIcon={<BiReceipt className={'menu-icon'} size={24}/>}
      />

      <LeftMenuItem
        path={myProductsRootPath}
        minimized={minimized}
        text={<LocalizedMessage id={'title.my.products'}/>}
        leftIcon={<TbTags className={'menu-icon'} size={24}/>}
      />

      <LeftMenuItem
        path={productCatalogueRootPath}
        minimized={minimized}
        text={<LocalizedMessage id={'title.catalogue'}/>}
        leftIcon={<TbLayoutGrid className={'menu-icon'} size={24}/>}
      />
    </MenuSection>
  );
};

export const UFPMyViewSection: FC<{ minimized: boolean }> = ({minimized}) => {
  const {pathname} = useLocation();
  const unreadChats = useChatsAllUnreadCount();

  const requestsIsActive = pathname.includes('/requests') || pathname.includes('/requested-offers');
  const companiesIsActive = pathname.includes('/companies') || pathname.includes('/locations');
  const usersIsActive = pathname.includes('/users') && !pathname.includes('companies') && !pathname.includes('locations');
  const logisticsIsActive = pathname.includes('/ports') || pathname.includes('/truck')
    || pathname.includes('/logistics-costs') || pathname.includes('/addon-costs') || pathname.includes('/duty-costs');
  const metricsIsActive = pathname.includes("/metrics");

  return (
    <MenuSection>
      <LeftMenuItem
        path={"/dashboard"}
        minimized={minimized}
        text={<LocalizedMessage id={'title.dashboard'}/>}
        leftIcon={<TbLayout2 className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path="/chats"
        minimized={minimized}
        text={<div className={'flex-row align-center text-truncate'}>
          <div className={'flex'}><LocalizedMessage id={'title.chats'}/></div>
          <ChatUnreadBadge className='ml-xs' unread={unreadChats.data?.unreadCount || 0}/>
        </div>}
        leftIcon={<div>
          <TbMessage className={'menu-icon'} size={24}/>
        </div>}
      />
      <LeftMenuItem
        path="/companies"
        minimized={minimized}
        text={<LocalizedMessage id={'title.companies'}/>}
        leftIcon={<BiBuildings className={'menu-icon'} size={24}/>}
        showAsActive={companiesIsActive}
      />
      <LeftMenuItem
        path="/users"
        minimized={minimized}
        text={<LocalizedMessage id={'title.users'}/>}
        leftIcon={<TbUsers className={'menu-icon'} size={24}/>}
        showAsActive={usersIsActive}
      />
      <LeftMenuItem
        path={'/requests'}
        minimized={minimized}
        text={<LocalizedMessage id={'title.requests'}/>}
        leftIcon={<TbFilePencil className={'menu-icon'} size={24}/>}
        showAsActive={requestsIsActive}
      />
      <LeftMenuItem
        path={offersRootPath}
        minimized={minimized}
        text={<LocalizedMessage id={'title.offers'}/>}
        leftIcon={<TbReportMoney className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path={'/quotes'}
        minimized={minimized}
        text={<LocalizedMessage id={'title.quotes'}/>}
        leftIcon={<TbClipboardList className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path="/orders"
        minimized={minimized}
        text={<LocalizedMessage id={'title.orders'}/>}
        leftIcon={<BiReceipt className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path={productsRootPath}
        minimized={minimized}
        text={"Products"}
        showAsActive={
          pathname.includes('/products') || pathname.includes('/product-groups')
          || pathname.includes('/lengths') || pathname.includes('/dictionary')
          || pathname.includes('/random-lengths')
        }
        leftIcon={<TbTags className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        minimized={minimized}
        path={productCatalogueRootPath}
        text={<LocalizedMessage id={'title.catalogue'}/>}
        leftIcon={<TbLayoutGrid className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        minimized={minimized}
        path="/ports"
        text={<LocalizedMessage id={'title.logistics'}/>}
        leftIcon={<TbShip className={'menu-icon'} size={24}/>}
        showAsActive={logisticsIsActive}
      />
      <LeftMenuItem
        minimized={minimized}
        path="/metrics"
        text={<LocalizedMessage id={'title.metrics'}/>}
        leftIcon={<TbChartLine className={'menu-icon'} size={24}/>}
        showAsActive={metricsIsActive}
      />
    </MenuSection>
  );
};

export const AdminSection: FC<{ minimized: boolean }> = ({minimized}) => {
  const {pathname} = useLocation();
  const isAdmin = useIsAdmin();

  return (
    <MenuSection
      header={
        <>
          {minimized ?
            <LocalizedMessage id='title.admin.first.letter'/> :
            <div className="flex-row align-center ml-lg"><LocalizedMessage id='title.admin'/></div>}
        </>
      }
    >
      {isAdmin &&
        <LeftMenuItem
          path={"/user-groups"}
          minimized={minimized}
          text={<LocalizedMessage id={'title.user.groups'}/>}
          showAsActive={pathname.includes('/user-groups') || pathname.includes('/user-permissions')}
          leftIcon={<TbUsersGroup className={'menu-icon'} size={24}/>}
        />
      }
      <LeftMenuItem
        path={"/emails"}
        minimized={minimized}
        text={<LocalizedMessage id={'title.emails'}/>}
        leftIcon={<TbMail className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path={"/landing-pages"}
        minimized={minimized}
        text={<LocalizedMessage id={'title.landing.pages'}/>}
        leftIcon={<TbPhotoEdit className={'menu-icon'} size={24}/>}
      />
      <LeftMenuItem
        path="/feature-flags"
        text="Feature Flags"
        minimized={minimized}
        leftIcon={<TbFlag className={'menu-icon'} size={24} />}
      />
      <LeftMenuItem
        path={"/job-scheduler"}
        text={"Job Scheduler"}
        minimized={minimized}
        leftIcon={<TbClockCog className={'menu-icon'} size={24}/>}
      />
    </MenuSection>
  );
};

export const BottomSection: FC<{ minimized: boolean }> = ({minimized}) => {
  const {pathname} = useLocation();
  const dispatch = useAppDispatch();

  const onLogOut = () => logoutCurrentUser(dispatch);

  const feedbackRef = useRef();
  useEffect(() => {
    if (feedbackRef.current) {
      return sentryFeedback.attachTo(feedbackRef.current);
    }
    return () => {};
  }, [feedbackRef]);

  return (
    <>
      <MenuSection>
        <LeftMenuItem
          path="/profile/personal"
          text={<LocalizedMessage id={'title.my.profile'}/>}
          showAsActive={pathname.includes('/profile')}
          minimized={minimized}
          leftIcon={<TbUser className={'menu-icon'} size={24}/>}
        />
        <LeftMenuButton
          icon={<RiMegaphoneLine size={24}/>}
          minimized={minimized}
          label={ls("title.bug.report")}
          ref={feedbackRef}
        />
        <LeftMenuDirectLink
          text={<LocalizedMessage id={'title.privacy'}/>}
          minimized={minimized}
          link="https://timberbase.com/privacy-statement/"
          leftIcon={<MdOutlinePrivacyTip className={'menu-icon'} size={24}/>}
        />
        <LeftMenuItem
          path="/sign-in"
          text={<LocalizedMessage id={'title.logout'}/>}
          minimized={minimized}
          onClick={onLogOut}
          leftIcon={<TbLogout className={'menu-icon'} size={24}/>}
        />
      </MenuSection>
    </>
  );
};
