import { useCallback } from 'react';
import LinearProgress from '~/Components/Old/LinearProgress';


const SelectLoaderRow = () => {
  const onClick = useCallback((event) => event.stopPropagation(), []);
  return <LinearProgress onClick={onClick} />;
};

export default SelectLoaderRow;
