import useSWR from "swr";
import API, {Port, PortType} from "~/API";
import useSWRInfinite from "swr/infinite";
import {trimUndefined} from "~/Utils";

export const usePort = (id: number) =>
  useSWR(["api/port", id], async () => await API.getPortById({ id }));

export const usePorts = (filters: { inactive?: boolean, type?: PortType[] }) =>
  useSWR(["api/ports/all", filters], () => API.getAllPorts(filters));

export interface PortFilters {
  type?: Array<PortType>,
  inactive?: boolean,
  search?: string,
  country?: Array<string>,
  offset?: number,
  limit?: number
}

const PAGE_SIZE = 40;

const getPortsKey = (
  filters: PortFilters,
  offset: number,
  previousPageData: Port[] | undefined,
) => {
  if (previousPageData && !previousPageData.length) return null;
  return { route: "api/ports", filters, offset };
};

export const usePortsPaged = (filters: PortFilters, pageSize?: number) => useSWRInfinite(
  (i, p) => getPortsKey(filters, i * (pageSize || PAGE_SIZE), p),
  (key) => API.getAllPorts({
        ...trimUndefined(filters),
        offset: key.offset,
        limit: pageSize || PAGE_SIZE,
    })
  );