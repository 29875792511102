import {useState, useEffect, FC} from "react";
import { Prompt, useHistory } from "react-router-dom";
import {Button, Dialog} from "~/Components/UI";
import {useToggle} from "~/Hooks";

export const UnsavedChangesDialog: FC<{
  onBack?: () => void;
  onSave?: () => (void | Promise<void>);
}> = (props) => {
  const history = useHistory();
  const [promptProps, setPromptProps] = useState({ locationToLeave: undefined, actionToLeave: "" });
  const open = useToggle();
  const [showPrompt, setShowPrompt] = useState(true);

  useEffect(() => {
    const beforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = false;
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, []);

  const leaveWithoutPrompting = () => {
    const { locationToLeave, actionToLeave } = promptProps;
    if (!locationToLeave && !actionToLeave) {
      props.onBack?.();
    } else if (actionToLeave === "POP") {
      history.goBack();
    } else if (actionToLeave === "REPLACE") {
      history.replace(locationToLeave);
    } else {
      history.push(locationToLeave);
    }
  }

  const onSaveChanges = async () => {
    try {
      await props.onSave?.();
      leaveWithoutPrompting();
    } catch {}
  };

  const onCancel = () => {
    setShowPrompt(true);
    open.toggleFalse();
  }

  return (
    <>
      <Prompt
        when={showPrompt}
        message={(location, action) => {
          open.toggleTrue();
          setShowPrompt(false);
          setPromptProps({
            locationToLeave: location,
            actionToLeave: action,
          });
          return false;
        }}
      />

      <Dialog
        title="Save Changes?"
        open={open}
        trigger={<div />}
      >
        <div className="flex-col gap-sm p-sm desktop-w-9xl">
          <div>
            It looks like you have unsaved changes. Do you want to save them before leaving this page?
          </div>
          <div className="flex-row gap-sm mt-lg justify-end">
            <Button
              label="title.save"
              onClicked={onSaveChanges}
            />
            <Button
              label="title.dont.save"
              onClicked={leaveWithoutPrompting}
              color="red"
            />
            <Button
              label="title.cancel"
              onClicked={onCancel}
              color="gray"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
