import { OfferStatus } from "~/API";
import { useIsVendor } from "~/Reducers/User";
import { useIsReadOnly } from "../../../../Components/OffersHelper";
import BottomButtonsPanel from "~/Components/Layout/BottomButtonsPanel";
import { VendorSubmitToPurchasingButton } from "../SharedComponents/VendorSubmitToPurchasingButton";
import { OfferDetailsButtonsPanelProps } from "../OfferDetailsButtonsPanel";
import { UpdateOfferButton } from "../SharedComponents/UpdateOfferButton";
import { SaveOfferButton } from "../SharedComponents/SaveOfferButton";
import { VendorConfirmOfferButton } from "../SharedComponents/VendorConfirmOfferButton";
import Button from "~/Components/Old/Button";
import {LocalizedMessage} from "~/Locales";


export const ExternalUsersOffersButtonsPanel = (props: OfferDetailsButtonsPanelProps) => {
  const {
    saveOffer,
    isOfferValid,
    offerId,
    hasChanges,
    loading,
    status,
    copyOffer,
  } = props;

  const readOnly = useIsReadOnly();
  const isVendor = useIsVendor();

  const SaveButton = ({ label = null }) =>
    <SaveOfferButton hasChanges={hasChanges}
      label={label}
      loading={loading}
      saveOffer={saveOffer}
      status={status} />;

  const CopyButton = () =>
    <Button view="primary" onClick={copyOffer}>
      <LocalizedMessage id="title.copy.offer" />
    </Button>

  let buttonsJSX = null;

  if (isVendor) {
    if (readOnly) {
      // External Users - Offer Details Page
      if (status === OfferStatus.O_DRAFT_VENDOR) {
        buttonsJSX = <>
          <UpdateOfferButton offerId={offerId} />
          <CopyButton />
          <VendorSubmitToPurchasingButton offerId={offerId} saveOffer={saveOffer} isOfferValid={isOfferValid} />
        </>
      } else if (status === OfferStatus.O_CLOSING) {
        buttonsJSX = <>
          <CopyButton />
          <VendorConfirmOfferButton offerId={offerId} saveOffer={saveOffer} />
        </>
      }

      // External Users - Offer Edit Page
    } else {
      if (status === OfferStatus.O_DRAFT_VENDOR) {
        buttonsJSX = <>
          <SaveButton />
          <VendorSubmitToPurchasingButton offerId={offerId} saveOffer={saveOffer} isOfferValid={isOfferValid} />
        </>
      }
    }
  } else {
    // other external user types will go here.
    // but for now, only Vendors can see offers
  }

  return buttonsJSX ? <BottomButtonsPanel>{buttonsJSX}</BottomButtonsPanel> : null;
}