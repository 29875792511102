import {useHistory, useLocation} from "react-router-dom";
import {useCallback, useMemo} from "react";
import * as z from "zod";

export const useSearchParams = <T extends z.ZodTypeAny>(zodSchema?: T, replaceUri = false): [T, (params: any) => void] => {
  const { search } = useLocation();

  const searchParams = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const urlObj = Object.fromEntries(urlParams);
    if(zodSchema != undefined) {
      const parsed = zodSchema.safeParse(urlObj);
      return (parsed.success ? parsed.data : undefined) as z.infer<T>;
    }

    return urlObj as any;
  }, [search]);

  const history = useHistory();
  const setSearchParams = useCallback((params) => {
    if (!params) return;
    const search = new URLSearchParams(params).toString();
    if (replaceUri) {
      history.replace({ search });
    } else {
      history.push({ search });
    }
  }, []);

  return [searchParams, setSearchParams];
};

export const zodStringToArray = <T extends z.ZodTypeAny>(schema: T) => {
  return z.preprocess((obj) => {
    if (Array.isArray(obj)) {
      return obj;
    } else if (typeof obj === "string") {
      if(obj.length == 0)
        return [];
      return obj.split(",");
    } else {
      return undefined;
    }
  }, z.array(schema));
};

export const zodStringToBool = <T extends z.ZodTypeAny>(schema: T) => {
  return z.preprocess((obj) => {
    if (obj instanceof Boolean) {
      return obj;
    } else if (typeof obj === "string") {
      if (obj.toLowerCase() == "true")
        return true
      else if (obj.toLowerCase() == "false")
        return false
      else
        return undefined;
    } else {
      return undefined;
    }
  }, z.boolean(schema));
};

export const params = {
  numberArray: zodStringToArray(z.coerce.number()).optional(),
  stringArray: zodStringToArray(z.string()).optional(),
  enumArray: (enumType: any) => zodStringToArray(z.nativeEnum(enumType)).optional(),
  bool: zodStringToBool(z.boolean()).optional(),
  string: z.string().optional(),
  enum: (enumType: any) => z.nativeEnum(enumType).optional(),
  date: z.string().date().optional(),
  dateTime: z.string().datetime().optional(),
}