import {SeaFreightCostIncoterm} from "~/API";
import {useMemo} from "react";
import {localizedString} from "~/Locales";
import {useAppSelector} from "~/StoreTypes";

export const ShipVia = {
  CON: 'CONTAINER',
  CPU: 'CUSTOMER PICK-UP',
  T: 'TRUCK',
  V: 'VESSEL',
}

export const ShipViaDomestic = {
  CON: 'CONTAINER',
  CPU: 'CUSTOMER PICK-UP',
  T: 'TRUCK',
  R: 'RAIL',
  V: 'VESSEL',
  LTL: 'LESS THAN TRUCK',
  BT: 'B TRAIN'
}

const useIncoterms = () => useAppSelector(state => state.incoterms);

export const useIsValidIncoterm = (incotermId: SeaFreightCostIncoterm | string) => incotermId in SeaFreightCostIncoterm;

export const useIncotermKeys = () => {
  return useIncoterms();
};

export const useIncotermLabels = () => {
  const keys = useIncoterms();
  return useMemo(() => {
    return keys.reduce((a, key) => ({...a, [key]: localizedString(`incoterm.${key}`)}), {});
  }, [keys]);
};

export const isComboIncoterm = (incotermId: SeaFreightCostIncoterm | string) => {
  return (incotermId === SeaFreightCostIncoterm.FCA_COMBO
    || incotermId === SeaFreightCostIncoterm.DAP_COMBO
    || incotermId === SeaFreightCostIncoterm.DDP_COMBO);
};

export const useIncotermUsesAddress = (incotermId: SeaFreightCostIncoterm | string) => {
  return (incotermId === SeaFreightCostIncoterm.FCA
    || incotermId === SeaFreightCostIncoterm.DAP
    || incotermId === SeaFreightCostIncoterm.DDP);
};

// reducer
const INITIAL_STATE = [
  SeaFreightCostIncoterm.FCA,
  SeaFreightCostIncoterm.FCA_COMBO,
  SeaFreightCostIncoterm.FOB,
  SeaFreightCostIncoterm.CIF,
  SeaFreightCostIncoterm.CFR,
  SeaFreightCostIncoterm.DAP,
  SeaFreightCostIncoterm.DAP_COMBO,
  SeaFreightCostIncoterm.DDP,
  SeaFreightCostIncoterm.DDP_COMBO,
];

const reducer = (state: SeaFreightCostIncoterm[], _: any) => {
  state = state || INITIAL_STATE;
  return state;
};

export default reducer;
