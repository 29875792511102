import {FC} from "react";
import {ChatUnreadView} from "~/API";
import ChatTitle from "../../../Components/Chats/ChatTitle";
import {ChatsIcon} from "./ChatsIcon";
import ChatsLastMessage from "~/Pages/Chats/Components/ChatsLastMessage";
import {ChatUnreadBadge} from "~/Components/Chats/ChatUnreadBadge";
import {cn} from "~/Utils";
import {useHistory} from "react-router-dom";
import {chatsRootPathExternal, chatsRootPathInternal} from "~/Services/Routes";
import {DateViewRelative} from "~/Components/Views/DateView";
import {Show} from "~/Components/UI";
import {markChatNotificationsRead} from "~/Data/Notifications";
import {mutate} from "swr";

export const ChatsCard: FC<{ active: boolean, internal: boolean, chat: ChatUnreadView }> = (props) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`${props.internal ? chatsRootPathInternal : chatsRootPathExternal}${props.chat.id}`);
    markChatNotificationsRead(props.chat.id)
    props.chat.unread = 0
    mutate("api/chat/unread/count")
  }

  return (
    <button onClick={onClick} className={cn(
      "border-none focus-outline-2 hover-bg-black-11",
      "cursor-pointer rounded-xxs px-sm py-xs flex-row gap-sm transition-background",
      props.active ? "bg-black-10" : "bg-none"
      )}>
      <div className="flex-row align-start">
        <ChatsIcon chat={props.chat} />
      </div>
      <div className={cn("w-full flex-col text-left overflow-hidden gap-xxs", props.chat.unread === 0 && "color-text-1", props.chat.archived && "color-text-3" )}>
        <div className={cn("flex-row align-center justify-between text-bold", props.chat.unread !== 0 && "text-bold", props.chat.archived && "color-text-3")}>
          <ChatTitle chat={props.chat} />
          <ChatUnreadBadge unread={props.chat.unread} />
        </div>
        <div className="flex-col overflow-hidden text-sm">
          <div className="text-truncate">
            <ChatsLastMessage chat={props.chat} />
          </div>
          <Show when={props.chat?.lastMessage?.dateUpdated !== undefined}>
            <DateViewRelative date={props.chat?.lastMessage?.dateUpdated} />
          </Show>
        </div>
      </div>
    </button>
  );
}