import { FC } from "react";
import { cn } from "~/Utils";

export const ChatUnreadBadge: FC<{ className?: string, unread: number }> = (props) => {
  if (props.unread === 0)
    return <></>

  const unread = props.unread > 99 ? "99+" : props.unread;

  return (<div className={cn("flex-row", props.className)}>
    <div
      className={cn(
        "bg-orange-1 rounded-xxs py-xxs px-xs text-xs color-orange-10",
        props.unread === 0 && "hide",
      )}
    >
      {unread}
    </div>
  </div>)
};
