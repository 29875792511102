import { FC } from "react";
import { useMutate, useToggle } from "~/Hooks";
import { LC, ls } from "~/Locales";
import { Button, Dialog } from "~/Components/UI";
import API, {Registration, UserAccountView} from "~/API";

export const TriggerInvitationForm: FC<{
  user: UserAccountView;
}> = (props) => {
  const { active, id, email } = props.user;

  const open = useToggle();
  const sendInvite = useMutate(() => API.resendWelcomeMail({ id }));

  const onConfirm = () => {
    sendInvite.mutate().then(open.toggleFalse);
  };

  return (
    <Dialog
      title={ls("title.send.invitation")}
      open={open}
      trigger={<Button className="ml-lg" label="title.send.invitation" />}
    >
      <div className="desktop-w-9xl flex-col gap-lg">
        {!sendInvite.loading && (
          <>
            {!active && (
              <LC id={"phrase.confirm.invitation.failure.inactive.account"} />
            )}
            {active && props.user.registrationStatus == Registration.REGISTERED && (
              <LC id={"phrase.confirm.invitation.invalid.status"} />
            )}
            {active && props.user.registrationStatus != Registration.REGISTERED && (
              <LC id={"phrase.confirm.invitation.message"}>{email}</LC>
            )}
          </>
        )}
        {sendInvite.error && (
          <>
            <LC id={"phrase.confirm.invitation.failure"} />
          </>
        )}

        <div className="flex-row justify-end gap-sm">
          <Button
            loading={sendInvite.loading}
            label="title.send.invitation"
            onClicked={onConfirm}
          />
          <Button
            label="title.cancel"
            color="gray"
            onClicked={open.toggleFalse}
          />
        </div>
      </div>
    </Dialog>
  );
};
