import { FC, ReactNode } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { LocalizedMessageKey } from "~/Locales";
import { Button } from "./Button";
import { TbCaretDownFilled } from "react-icons/tb";
import { cn } from "~/Utils";

export const ButtonDropdown: FC<{
  trigger: LocalizedMessageKey;
  children: ReactNode;
  align?: "start" | "center" | "end";
  color?: "blue" | "red" | "green" | "gray";
}> = (props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          label={props.trigger}
          color={props.color}
          leftIcon={<TbCaretDownFilled size={16} />}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={cn(
            "animate-popover-content bg-black-12 border-1 border-black-9",
            "p-xxs rounded-xs shadow-lg mt-xxs",
          )}
          align={props.align || "end"}
        >
          <div className="flex-col w-min-7xl h-max-7xl overflow-hidden">
            <div className="overflow-y-auto pr-xxs">{props.children}</div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const ButtonDropdownItem: FC<{
  children: ReactNode;
  onClick?: () => void;
}> = (props) => {
  return (
    <DropdownMenu.Item
      onClick={props.onClick}
      className={cn(
        "flex-row p-xs rounded-xxs align-center justify-between",
        "cursor-pointer focus-outline-none focus-bg-black-10",
      )}
    >
      {props.children}
    </DropdownMenu.Item>
  );
};
