import useSWR from "swr";
import API, {
  UserGroup,
  UserGroupPermissionsView,
} from "~/API";
import { useMutateHook } from "~/Hooks";

export const useUserGroups = (query?: string) =>
  useSWR(["api/user/groups", query], async () => await API.getUserGroups({ query: query || "" }));

export const useCreateUserGroup = () =>
  useMutateHook<UserGroup, UserGroupPermissionsView[]>(
    "api/user/groups",
    async (args) => {
      await API.createUserGroup({}, args);
    },
    (old, args) => [{ ...args, permissions: [], users: [] }, ...old],
  );

export const useEditUserGroup = () =>
  useMutateHook<
    UserGroupPermissionsView & { id: number },
    UserGroupPermissionsView[]
  >(
    "api/user/groups",
    async (args) => {
      await API.updateUserGroup({ id: args.id }, args);
    },
    (old, args) => {
      const index = old.findIndex((d) => d.id === args.id);
      const newData = [...old];
      newData[index] = args;
      return newData;
    },
  );
