import {FC, useState} from "react";
import {
  OfferMatchingLocation,
  OfferMatchingProduct,
  setMatchingProductField,
  toggleSelectedProduct,
} from "~/Pages/Offers/DetailsPage/Components/OfferMatching/OfferMatchingReducer";
import { Row } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "~/StoreTypes";
import {
  Checkbox,
  Input,
  TableCell,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/Components/UI";
import { ls } from "~/Locales";
import { TbAlertCircle } from "react-icons/tb";
import { LengthsView } from "~/Components/Old/LengthsView";
import {UOMCombobox} from "~/Components/Comboboxes/UOMCombobox";
import {CalculatedPriceTooltip} from "~/Components/Entities/CalculatedPriceTooltip";
import {PercentageView} from "~/Components/Views/PercentageView";

export const OfferMatchingProductTable: FC<{
  row: Row<OfferMatchingLocation>;
}> = (props) => {
  const quoteProducts = useAppSelector(
    (state) => state.offerMatchingState.products,
  );
  const onlyMy = useAppSelector(
    (state) => state.offerMatchingState.matchingOnly,
  );

  const products = onlyMy
    ? quoteProducts.filter((p) =>
        props.row.original.model.matchedProducts.includes(p.id),
      )
    : quoteProducts;

  return (
    <>
      <tr className="bg-black-11-5">
        <TableCell
          className="sticky left-0 bg-black-11-5 shadow-inset-r-1"
          style={{ width: 56 }}
        />
        <TableCell
          className="sticky bg-black-11-5 shadow-inset-r-1"
          style={{ width: 56, left: 56 }}
        />
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.code")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case" colSpan={2}>
          {ls("title.description")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.length.offer")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.uom")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.containers")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.quantity")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case no-wrap">
          {ls("title.adapted.price")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.sellprice")}
        </TableCell>
        <TableCell className="text-bold color-text-3 upper-case">
          {ls("title.margin")}
        </TableCell>
        <TableCell colSpan={2} />
      </tr>
      {products?.map((p) => (
        <ProductSubRow row={p} location={props.row.original} key={p.id} />
      ))}
    </>
  );
};

const ProductSubRow: FC<{
  location: OfferMatchingLocation;
  row: OfferMatchingProduct;
}> = (props) => {
  const dispatch = useAppDispatch();

  const [calcPrice, setCalcPrice] = useState({
    total: 0,
    subTotal: 0,
    valid: false
  });
  const onCalcChange = (total: number, subTotal: number, validTotal: boolean) => {
    setCalcPrice({
      total,
      subTotal,
      valid: validTotal,
    });
  }

  const selected = props.location.products.includes(props.row.id);
  const selectProduct = () =>
    dispatch(
      toggleSelectedProduct({
        locationId: props.location.id,
        productId: props.row.id,
      }),
    );

  const onChange = (evt: any) => {
    dispatch(
      setMatchingProductField({
        id: props.row.id,
        fieldId: evt.target.id,
        value: evt.target.value,
      }),
    );
  };

  const onUomChange = (value: string) => {
    dispatch(setMatchingProductField({
      id: props.row.id,
      fieldId: "uom",
      value: value,
    }));
  };

  const offer = useAppSelector((state) => state.offerMatchingState.offer);

  return (
    <tr className="bg-black-11-5 color-black-4" key={props.row.id}>
      <TableCell
        className="sticky left-0 bg-black-11-5 shadow-inset-r-1"
        style={{ width: 56 }}
      />
      <TableCell
        className="sticky left-0 bg-black-11-5 shadow-inset-r-1"
        style={{ width: 56, left: 56 }}
      >
        <Checkbox value={selected} onChange={selectProduct} />
      </TableCell>
      <TableCell>
        <div className="flex-row gap-xxs align-center">
          {!props.location.model.matchedProducts.includes(props.row.id) && (
            <div className="flex-row">
              <Tooltip>
                <TooltipTrigger>
                  <div className="color-orange-1 cursor-pointer">
                    <TbAlertCircle size={20} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div>
                    Product is not in this Ship-To's "my products" section.
                  </div>
                  <div>
                    It will be automatically added to their products when the quote is created.
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>
          )}
          {props.row.model.productCode}
        </div>
      </TableCell>
      <TableCell colSpan={2}>{props.row.model.productDescription}</TableCell>
      <TableCell>
        <LengthsView lengthIds={props.row.model.lengthIds} />
      </TableCell>
      <TableCell>
        <UOMCombobox
          id="uom"
          value={props.row.uom}
          setValue={onUomChange}
          productType={props.row.model.productType as any}
          disabled={!selected}
        />
      </TableCell>
      <TableCell>
        {(props.row.quantity / props.row.model.unitsContainer).toFixed(1)}
      </TableCell>
      <TableCell>
        <Input
          id="quantity"
          value={props.row.quantity.toString()}
          onChange={onChange}
          type="number"
          disabled={!selected}
        />
      </TableCell>
      <TableCell>
        <div className="flex-row gap-xxs align-center">
          <Input
            id="sellPrice"
            value={props.row.sellPrice.toString()}
            onChange={onChange}
            disabled={!selected}
            type="number"
          />
          {selected &&
            (props.row.sellPrice as any) != calcPrice.total && (
              <Tooltip>
                <TooltipTrigger>
                  <div className="color-orange-1">
                    <TbAlertCircle size={20} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div>
                    Adapted price is different from calculated sell price.
                  </div>
                  <div>Check for accuracy before submitting quote.</div>
                </TooltipContent>
              </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell>
        <CalculatedPriceTooltip
          priceModel={{
            offerId: offer.id,
            offerProductId: props.row.model.id,
            productId: props.row.model.productId,
            quoteUOM: props.row.uom,
            currency: offer.currency,
            lengthId: props.row.model.lengthIds,
            incotermDestination: props.location.incoterm,
            shipFromPortId: offer.shipFromLocationId,
            shipToPortId: props.location.portId,
            shipToLocationId: props.location.id,
          }}
          price={props.row.sellPrice}
          showAdaptedPrice={false}
          changeCalculatedPrice={onCalcChange}
        />
      </TableCell>
      <TableCell>
        <div className="flex-row gap-xxs align-center">
          {props.row.sellPrice != 0 && calcPrice.subTotal != 0 ? (
            <PercentageView fractionalValue={(props.row.sellPrice / calcPrice.subTotal) - 1} decimals={2} />
          ) : (
            <span>-%</span>
          )}
          {!calcPrice.valid && (
            <div className="flex-row align-center">
              <Tooltip>
                <TooltipTrigger>
                  <div className="color-orange-1 flex-row align-center">
                    <TbAlertCircle size={20} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  Price calculation is incomplete,
                  so margin will not be accurate
                </TooltipContent>
              </Tooltip>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell colSpan={2} />
    </tr>
  );
};
