import React from 'react';
import Size from "react-ui-basics/Size";
import SpinningProgress from "react-ui-basics/SpinningProgress";
import CircleProgress from "react-ui-basics/CircleProgress";
import  "react-ui-basics/CircleProgress.css";
import { preventDefault, classNames } from "react-ui-basics/Tools";
import './Attachment.scss'
import API from "~/API";


export const isImage = filename => {
  if (typeof filename !== 'string') return false;
  const name = filename.toLowerCase();
  return name.endsWith('jpg') || name.endsWith('jpeg') || name.endsWith('png');
};
const isReady = data => data && (data.status === 'READY' || data.status === 'PROCESSING');
export const isUploading = data => data && data.status === 'UPLOADING';

const nameToString = data => {
  let name = data.name;
  if (data.hasThumbnail)
    name = name.substring(0, name.lastIndexOf('.'));

  return name.length > 33 ? name.substring(0, 30) + '...' : name;
};

class Attachment extends React.Component {
  render() {
    const { data, className, children, imgRef, imageClassName } = this.props;
    const ready = isReady(data);
    const image = ready && isImage(data.name);
    const id = ready && data.id;
    return (
      <div className={classNames('Attachment', className, !ready && 'notready')}>
        {data && (
          <React.Fragment>
            {image && (
              <a className="image" onClick={this.onClick} onMouseDown={this.onMouseDown} href={API.urlAttachmentOpen(id, data.name)} target="_blank" rel="noopener noreferrer">
                <img ref={imgRef}
                  src={API.urlAttachmentThumbnail(id)}
                  alt={data.name} className={imageClassName} />
                {children}
              </a>
            )}
            {!ready && isImage(data.name) && (<React.Fragment>
              <img src={data.objectURL} alt={data.name}/>
              {(!data.progress || data.progress >= 100) && <SpinningProgress/>}
              {data.progress < 100 && <CircleProgress value={data.progress}/>}
              {children}
            </React.Fragment>)}
            {!image && ready && (
              <React.Fragment>
                <a className={classNames("other", data.hasThumbnail && 'hasThumbnail')} target="_blank" rel="noopener noreferrer"
                  onClick={this.onClick} onMouseDown={this.onMouseDown}
                  href={API.urlAttachmentOpen(id, data.name)}>
                  {data.hasThumbnail && <img src={API.urlAttachmentThumbnail(id)} alt={data.name} />}
                  <div className="meta">
                    <div className="typeAndSize">
                      <div className="type">
                        {(s => {
                          const i = s.lastIndexOf('.');
                          if (i < 0 || s.length - i > 8 || s.length - i < 3) return null;
                          return s.substring(i + 1);
                        })(data.name)}
                      </div>
                      <Size value={data.size} />
                    </div>
                    <div className="name">{nameToString(data)}</div>
                  </div>
                  {children}
                </a>
              </React.Fragment>
            )}
            {!ready && !isImage(data.name) && <>
              <div className="meta">
                <div className="typeAndSize">
                  <div className="type">
                    {(s => {
                      const i = s.lastIndexOf('.');
                      if (i < 0 || s.length - i > 8 || s.length - i < 3) return null;
                      return s.substring(i + 1);
                    })(data.name)}
                  </div>
                  <Size value={data.size} />
                </div>
                <div className="name">{nameToString(data)}</div>
                {children}
              </div>
              {data.progress >= 100 && <SpinningProgress/>}
              {data.progress < 100 && <CircleProgress value={data.progress}/>}
            </>}
          </React.Fragment>
        )}
      </div>
    )
  }

  onClick = (e) => {
    if (!isReady(this.props.data)) {
      preventDefault(e);
      return;
    }

    this.props.onClick && this.props.onClick(e, this.props.data);
  };
  onMouseDown = (e) => {
    if (!isReady(this.props.data)) {
      preventDefault(e);
      return;
    }

    this.props.onMouseDown && this.props.onMouseDown(e, this.props.data);
  };
}

export default Attachment;
