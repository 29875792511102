import React, {FC, ReactNode} from "react";
import {cn} from "~/Utils";
import "./DividerWithText.scss";

const DividerWithText: FC<{
    children?: ReactNode,
    className?: string
}> = (props) => {
    return (
        <div className={cn("divider-with-text", props.className)}>
            {props.children}
        </div>
    )
}

export default DividerWithText