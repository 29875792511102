import { useToggle } from "~/Hooks/Old";
import { OfferStatus } from "~/API";
import { offersRootPath } from "~/Services/Routes";
import { Button } from "~/Components/UI";
import ConfirmationDialog from "~/Components/Old/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { SaveOfferCallbackType } from "../OfferDetailsButtonsPanel";
import { LocalizedMessage } from "~/Locales";
import Toast from "~/Components/Toast";


interface SubmitOfferToAMButtonProps {
  isOfferValid: () => boolean;
  saveOffer: SaveOfferCallbackType;
  offerId: string;
}

export const SubmitOfferToAMButton = ({ isOfferValid, saveOffer, offerId }: SubmitOfferToAMButtonProps) => {
  const [showSubmitConfirmation, toggleConfirmSubmitDialog] = useToggle();
  const history = useHistory();

  const onClick = () => {
    const valid = isOfferValid();
    if (valid) {
      toggleConfirmSubmitDialog();
    } else {
      if (!history.location.pathname.includes('edit')) {
        history.replace(`${offersRootPath}${offerId}/edit`);
      }
    }
  };

  const confirmOfferSubmit = async () => {
    toggleConfirmSubmitDialog();
    try {
      await saveOffer(OfferStatus.O_SALES_REVIEW);
      Toast.Success(`Offer ${offerId} sent to Account Manager`);
      history.push(`${offersRootPath}`);
    } catch { }
  };

  return <>
    <Button
      onClicked={onClick}
      data-cy={"SubmitOfferToAM"}
      label="title.submit.offer.to.am"
    />

    {showSubmitConfirmation &&
      <ConfirmationDialog
        title={'Submit Offer to Account Manager'}
        description={'Do you want to send this offer to Account Manager?'}
        description2={''}
        confirmTitle={<LocalizedMessage id={'title.send.offer'} />}
        closeTitle={<LocalizedMessage id={'title.cancel'} />}
        onConfirm={confirmOfferSubmit}
        onClose={toggleConfirmSubmitDialog}
      />
    }
  </>;
};